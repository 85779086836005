import { ChatState } from '@/models/chat';
import { RootState } from '@/models/state';

export const selectMessageSound = (state: RootState): string | null =>
  state.chatNew.messageSound;

export const selectSideBarOpen = (state: RootState): boolean =>
  state.chatNew.sideBarOpen;

export const selectView = (state: RootState): ChatState['view'] =>
  state.chatNew.view;
