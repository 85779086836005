import { useEffect } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { membersEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPut } from '@/api/fetcher';
import { Member, Members, PartialMember } from '@/models/member';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { setMembers } from '@/redux/members/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

import useUser from './useUser';

export const API = Object.freeze({
  listMembers: async (): Promise<Members> => callGet(endpoints.members),

  updateMember: async (updates: PartialMember): Promise<Member> =>
    callPut(endpoints.updateMember(updates.user_id), updates),

  deleteMember: async (member: PartialMember): Promise<Member> =>
    callDelete(endpoints.updateMember(member.user_id)),
});

const useMembers = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const accountSlug = useSelector(selectAccountSlug);
  const { user } = useUser();
  const {
    data: members,
    status: listStatus,
    isSuccess: membersSuccess,
  } = useQuery<Members, Error>({
    queryKey: [endpoints.members, accountSlug],
    queryFn: () => API.listMembers(),

    enabled: !!accountSlug,
  });
  useEffect(() => {
    if (membersSuccess) {
      dispatch(setMembers(members.members));
    }
  }, [membersSuccess, members, dispatch]);

  const { mutate: updateMember, status: updateStatus } = useMutation<
    Member,
    Error,
    PartialMember
  >({
    mutationFn: API.updateMember,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.members, accountSlug],
      });
      dispatch(addTemporalToast('success', t('members.member_updated')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteMember, status: deleteStatus } = useMutation<
    Member,
    Error,
    PartialMember
  >({
    mutationFn: API.deleteMember,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.members, accountSlug],
      });
      dispatch(addTemporalToast('success', t('members.member_deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const member = members?.members?.find((m) => m.user_id === user?.user_id);
  return {
    members: members?.members,
    member,
    updateMember,
    listStatus,
    updateStatus,
    deleteMember,
    deleteStatus,
  };
};

export default useMembers;
