import { Tab, tabClasses, TabOwnProps } from '@mui/base/Tab';
import { TabsList as TabsListUnstyled } from '@mui/base/TabsList';
import { styled } from '@mui/system';

type CustomTabProps = TabOwnProps & {
  hasError: boolean;
};

export const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'hasError' && prop !== 'value',
})<CustomTabProps>(({ theme, value, hasError, disabled }) => {
  return {
    cursor: disabled ? 'not-allowed' : 'pointer',
    minWidth: '78px',
    maxHeight: '31px',
    minHeight: '31px',
    padding: '4px 6px',
    paddingRight: value === 0 ? '4px' : '2px',
    paddingLeft: value === 0 ? '12px' : '6px',
    borderRadius: '4px',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    transform: 'translateY(1px)',
    zIndex: '0',
    backgroundColor: 'var(--surface-primary-white)',
    display: 'flex',
    justifyContent: value === 0 ? 'flex-start' : 'space-between',
    border: '1px solid transparent',
    transition: 'background-color 0.2s ease-in-out',

    ...theme.typography['body-regular'],

    '& > .close_icon': {
      opacity: '0',
      transition: 'opacity 0.2s ease-in-out',
      width: '16px',
      height: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'translateY(2px)',

      '&:hover': {
        opacity: '1',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderRadius: '50%',

        '& > svg': {
          stroke: 'var(--icon-hover-blue)',
        },
      },
    },

    '&:hover': {
      backgroundColor: 'var(--surface-secondary-gray-light)',
    },

    '&:hover .close_icon': {
      opacity: '1',
    },

    '&:focus': {
      color: 'var(--text-default-black)',
      backgroundColor: 'var(--surface-secondary-gray-light)',
    },

    [`&.${tabClasses.selected}`]: {
      backgroundColor: 'var(--surface-primary-white)',
      border: hasError
        ? '1px solid var(--icon-default-error)'
        : '1px solid var(--border-default-gray)',
      borderBottom: 'none',
      color: 'var(--text-default-black)',
      display: 'flex',
      justifyContent: value === 0 ? 'flex-start' : 'space-between',
      marginBottom: '-1px',
      '& > .close_icon': {
        opacity: '1',
        transform: 'translateY(1px)',
      },
      '& > .tab_name': {
        transform: 'translateY(-1px)',
      },
    },
  };
});

// this way the component doesn't unmount when the tab is changed, keeping the state.
const TabPanelUnstyled = ({ value, active, children, ...props }) => {
  return (
    <div
      role="tabpanel"
      hidden={!active}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      style={{
        zIndex: 1,
        position: 'relative',
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export const TabPanel = styled(TabPanelUnstyled)(({ theme }) => {
  return {
    width: '100%',
    zIndex: 1,
    position: 'relative',
    ...theme.typography['body-regular'],
  };
});

export const TabsList = styled(TabsListUnstyled)(() => ({
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  alignContent: 'space-between',
  marginBottom: '1px',
  position: 'relative',
  zIndex: 4,
}));

export const TabsListStyle = {
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  alignContent: 'space-between',
  marginBottom: '1px',
  position: 'relative',
  zIndex: 4,
};
