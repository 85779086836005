import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Bundle = ({
  size = 24,
  color = 'var(--icon-default-gray)',
  disabled,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke={color}
      className={`${styles.icon_delete} ${disabledClass}`}
    >
      <path
        d="M12 2 2 7l10 5 10-5-10-5ZM2 17l10 5 10-5M2 12l10 5 10-5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bundle;
