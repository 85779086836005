import { Fragment, useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';

import Button from '../Button/Button/Button';
import Close from '../Icons/Close';
import { IconsByState, Variants } from '../Icons/IconsByState';
import LinkExternal from '../Icons/LinkExternal';

import s from './Banner.module.scss';

export type Props = {
  children?: React.ReactNode;
  variant?: Variants;
  isStatic?: boolean;
  relativePosition?: boolean;
  title?: string;
  centered?: boolean;
  hasIcon?: boolean;
  size?: 'xs' | 'small' | 'medium';
  padding?: 'small' | 'regular';
  primaryButton?: { label: string; onClick: () => void; isLoading?: boolean };
  secondaryButton?: { label: string; onClick: () => void; isLoading?: boolean };
};

export type Dismissable = {
  dismissable: boolean;
  onDismiss?: () => void;
};

export type CustomDismiss = {
  customDismiss: string;
  customDismissAction: () => void;
};

export type WithReferences<T = { dialogId: string; label: string }> = {
  references?: T[];
  onRefClick?: (key: keyof T) => void;
  referenceProp?: string;
  labelKey?: string;
};

export type WithOptions = {
  options?: string[];
};

export function Banner(props: Props): JSX.Element;
export function Banner(props: Props & Dismissable): JSX.Element;
export function Banner(props: Props & CustomDismiss): JSX.Element;
export function Banner<T>(props: Props & WithReferences<T>): JSX.Element;
export function Banner(props: Props & WithOptions): JSX.Element;
export function Banner<T>({
  children,
  dismissable,
  variant = 'info',
  size,
  isStatic = false,
  relativePosition = false,
  hasIcon = true,
  onDismiss,
  title,
  centered,
  customDismiss,
  customDismissAction,
  references = null,
  options = [],
  onRefClick,
  referenceProp = 'dialogId',
  padding = 'regular',
  labelKey = 'label',
  primaryButton,
  secondaryButton,
}: Props & CustomDismiss & Dismissable & WithReferences<T> & WithOptions) {
  const [dismissed, setDismissed] = useState(false);

  const handleDismiss = useCallback(() => {
    setDismissed(true);
    if (onDismiss) {
      onDismiss();
    }
  }, [onDismiss]);

  return (
    <div
      className={cn(s.banner, s[variant], {
        [s.dismissed]: dismissed,
        [s.static]: isStatic,
        [s.relative]: relativePosition,
        [s.centered]: centered,
        [s.noBorderRadius]: centered,
        [s.smallPadding]: padding === 'small',
        [s.withTitle]: title,
        [s[size]]: size,
      })}
    >
      {hasIcon && <div className={s.icon}>{IconsByState[variant]}</div>}
      <div>
        {title && (
          <div
            className={cn(s.title, {
              [s.hasChildren]: !!children,
            })}
          >
            <Typography variant="body-semi-bold">{title}</Typography>
          </div>
        )}
        <div className={s.content}>
          <Typography variant="body-regular">{children}</Typography>
        </div>
        {references && (
          <div className={s.references}>
            {references?.map((reference, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${reference[labelKey]}_${i}`}>
                <Typography variant="body-regular">
                  <button
                    type="button"
                    onClick={() => onRefClick(reference[referenceProp])}
                  >
                    {reference[labelKey]}
                    <LinkExternal />
                  </button>
                </Typography>
              </Fragment>
            ))}
          </div>
        )}
        {options && (
          <ul className={s.options}>
            {options?.map((option) => (
              <Typography
                component="li"
                key={option}
                color="var(--text-default-gray)"
                variant="label-regular"
              >
                {option}
              </Typography>
            ))}
          </ul>
        )}

        {(primaryButton || secondaryButton) && (
          <div className={s.buttonContainer}>
            {primaryButton && (
              <Button
                variant="tertiary"
                size="xs"
                onClick={primaryButton.onClick}
                isLoading={primaryButton.isLoading}
              >
                {primaryButton.label}
              </Button>
            )}
            {secondaryButton && (
              <Button
                variant="secondary"
                size="xs"
                onClick={secondaryButton.onClick}
                isLoading={secondaryButton.isLoading}
              >
                {secondaryButton.label}
              </Button>
            )}
          </div>
        )}
      </div>

      {dismissable && (
        <button
          onClick={handleDismiss}
          type="button"
          className={cn(s.dismiss, s[variant], {
            [s.right]: centered,
          })}
        >
          <Close />
        </button>
      )}

      {customDismissAction && (
        <div className={s.customDismiss}>
          <Button
            onClick={customDismissAction}
            variant="tertiary"
            size="small"
            className={s.button}
          >
            {customDismiss}
          </Button>
        </div>
      )}
    </div>
  );
}
