export const Text = (props) => {
  const { attributes, children, leaf } = props;
  const wrapperComponents = [];
  if (leaf.bold) {
    wrapperComponents.push((children) => <strong>{children}</strong>);
  }

  if (leaf.italic) {
    wrapperComponents.push((children) => <em>{children}</em>);
  }

  const modifiedChildren = wrapperComponents.reduce(
    (wrappedChildren, wrapper) => wrapper(wrappedChildren),
    children
  );

  const handleStyle = leaf.isHandle
    ? { color: 'var(--text-default-blue)' }
    : {};

  return (
    <span className={props.classNames} {...attributes} style={handleStyle}>
      {modifiedChildren}
    </span>
  );
};
