import { useEffect } from 'react';

import { FieldErrors, UseFormSetFocus, FieldValues } from 'react-hook-form';

const useFocusOnInput = (
  firstInput: string,
  errors: FieldErrors,
  setFocus: UseFormSetFocus<FieldValues>
) => {
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstErrorField = Object.keys(errors)[0];
      setFocus(firstErrorField);
    } else {
      setFocus(firstInput);
    }
  }, [errors, firstInput, setFocus]);
};

export default useFocusOnInput;
