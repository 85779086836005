const isMacOS = () => /Mac/.test(navigator.userAgent);

const deleteShortcut = isMacOS() ? 'Fn + Del' : 'Del';

const useOperatingSystem = () => {
  const metaKey = isMacOS() ? '⌘' : 'Ctrl';

  return {
    isMacOS,
    metaKey,
    deleteShortcut,
  };
};

export default useOperatingSystem;
