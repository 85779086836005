import { useCallback } from 'react';

import cn from 'classnames';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import IconizedDialog from '@/components/atoms/IconizedDialog/IconizedDialog';
import Event from '@/components/atoms/Icons/Event';
import Intent from '@/components/atoms/Icons/Intent';
import Unknown from '@/components/atoms/Icons/Unknown';
import { Presence } from '@/models/presence';

import { SubnavType } from '../SubNav';

import styles from './TreeView.module.scss';

export interface ItemProps {
  id: string;
  link: string;
  name: string;
  collection: string;
  hasSuggestions?: boolean;
  isOver?: boolean;
  isOrphan?: boolean;
  needsAction?: boolean;
  whoIsViewing?: Presence[];
  selected: boolean;
  type?: 'intent' | 'event' | 'unknown';
  subnavType?: SubnavType;
}
export const renderDialogIcon = (type: string) => {
  if (type === 'intent') {
    return <Intent />;
  }
  if (type === 'event') {
    return <Event />;
  }
  if (type === 'unknown') {
    return <Unknown />;
  }
  return null;
};

function Item({
  name,
  id,
  link,
  collection,
  hasSuggestions = false,
  needsAction,
  isOrphan,
  whoIsViewing = [],
  selected,
  type,
  subnavType,
}: ItemProps) {
  const { t } = useTranslation();
  const [{ isDragging }, dragRef] = useDrag({
    item: { id, name, collection },
    type: 'treeItem',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const isOffTopics = id === 'offtopics';
  const isRecommendation =
    collection === t('intent.recommendations.collection');

  const showDot = !isRecommendation && (hasSuggestions || needsAction);

  const dotColor = useCallback(() => {
    if (hasSuggestions) {
      return 'var(--icon-default-blue)';
    }
    if (needsAction && subnavType === 'intents') {
      return 'var(--icon-default-warning)';
    }
    return null;
  }, [hasSuggestions, subnavType, needsAction]);

  return (
    <div
      ref={dragRef}
      className={cn(styles.treeItem, {
        [styles.isDragging]: isDragging,
      })}
    >
      <NavLink
        className={({ isActive }) =>
          cn(styles.link, {
            [styles.active]: isActive,
            [styles.isOrphan]: isOrphan,
            [styles.selected]: selected,
            [styles.hasIcon]: subnavType === 'dialogs',
          })
        }
        end
        to={link}
        title={name}
      >
        <IconizedDialog
          dialogName={name}
          type={type}
          showDot={showDot}
          isOffTopics={isOffTopics}
          whoIsViewing={whoIsViewing}
          dotColor={dotColor}
        />
      </NavLink>
    </div>
  );
}
export default Item;
