import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useTitle } from 'react-use';

import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import DisabledAnalytics from '@/modules/analytics/components/DisabledAnalytics';
import Filters from '@/modules/analytics/components/filters/Filters';
import { AnalyticsType } from '@/modules/analytics/models';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import SessionsFieldset from './fieldsets/SessionsFieldset/SessionsFieldset';

export default function Logs() {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  useTitle(t('pages.logs', { 0: brain?.name }));
  const { account } = useAccount();

  const isAnalyticsDisabled = account?.opt_out;

  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'logs', actions.READ)
  );

  // This is a workaround to hide the version tile on AI Agents page
  // We will remove this when we will totally replace Brains with AI Agents
  const location = useLocation();
  const isAIAgentsPath = location.pathname.includes('/ai-agents');

  useEffect(() => {
    pageView(PageName.BRAIN_LOGS);
  }, []);

  const [showLogs, setShowLogs] = useState(true);

  if (isAnalyticsDisabled) {
    return <DisabledAnalytics />;
  }

  return (
    <PermissionsWrapper module="logs">
      <PageContentWrapper
        newPlain2={canRead}
        readOnly={!canRead}
        noGutters={isAIAgentsPath}
      >
        <Filters type={AnalyticsType.LOGS} setShowLogs={setShowLogs} />
        <SessionsFieldset show={showLogs} />
      </PageContentWrapper>
    </PermissionsWrapper>
  );
}
