import { Icon } from '@/models/icon';

const AttachmentVideo = ({
  color = 'var(--icon-default-blue)',
  size = 40,
}: Icon) => {
  return (
    <svg
      viewBox="0 0 40 40"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m25.694 16.46-7.5-5a1.249 1.249 0 0 0-1.944 1.04v10a1.25 1.25 0 0 0 1.944 1.04l7.5-5a1.251 1.251 0 0 0 0-2.08Zm-6.944 3.704v-5.32l3.997 2.656-3.997 2.664Zm15-13.914H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h27.5a2.5 2.5 0 0 0 2.5-2.5V8.75a2.5 2.5 0 0 0-2.5-2.5Zm0 20H6.25V8.75h27.5v17.5Zm2.5 6.25A1.25 1.25 0 0 1 35 33.75H5a1.25 1.25 0 0 1 0-2.5h30a1.25 1.25 0 0 1 1.25 1.25Z"
        fill={color}
      />
    </svg>
  );
};

export default AttachmentVideo;
