import { Icon } from '@/models/icon';

const Billing = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M2 6.57143H14H2ZM4.66667 10.1429H5.33333H4.66667ZM8 10.1429H8.66667H8ZM4 13H12C12.5304 13 13.0391 12.7742 13.4142 12.3724C13.7893 11.9705 14 11.4255 14 10.8571V5.14286C14 4.57454 13.7893 4.02949 13.4142 3.62763C13.0391 3.22576 12.5304 3 12 3H4C3.46957 3 2.96086 3.22576 2.58579 3.62763C2.21071 4.02949 2 4.57454 2 5.14286V10.8571C2 11.4255 2.21071 11.9705 2.58579 12.3724C2.96086 12.7742 3.46957 13 4 13Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Billing;
