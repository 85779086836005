import { t } from 'i18next';
import { Accept } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import Audio from '@/components/atoms/Icons/Audio';
import Billing from '@/components/atoms/Icons/Billing';
import Carousel from '@/components/atoms/Icons/Carousel';
import Condition from '@/components/atoms/Icons/Condition';
import Email from '@/components/atoms/Icons/Email';
import Entities from '@/components/atoms/Icons/Entities';
import Event from '@/components/atoms/Icons/Event';
import Fallback from '@/components/atoms/Icons/Fallback';
import File from '@/components/atoms/Icons/File';
import FilePreview from '@/components/atoms/Icons/FilePreview';
import GoogleSheet from '@/components/atoms/Icons/GoogleSheet';
import Handover from '@/components/atoms/Icons/Handover';
import Image from '@/components/atoms/Icons/Image';
import Intent from '@/components/atoms/Icons/Intent';
import JumpTo from '@/components/atoms/Icons/JumpTo';
import Pause from '@/components/atoms/Icons/Pause';
import Question from '@/components/atoms/Icons/Question';
import Replay from '@/components/atoms/Icons/Replay';
import { ResetIcon } from '@/components/atoms/Icons/Reset';
import Resolve from '@/components/atoms/Icons/Resolve';
import SetVariables from '@/components/atoms/Icons/SetVariables';
import Survey from '@/components/atoms/Icons/Survey';
import SurveyPreview from '@/components/atoms/Icons/SurveyPreview';
import Text from '@/components/atoms/Icons/Text';
import IconToolIntent from '@/components/atoms/Icons/ToolIntent';
import IconToolSlot from '@/components/atoms/Icons/ToolSlot';
import IconToolTag from '@/components/atoms/Icons/ToolTag';
import IconToolWebview from '@/components/atoms/Icons/ToolWebview';
import Url from '@/components/atoms/Icons/Url';
import URLPreview from '@/components/atoms/Icons/URLPreview';
import Video from '@/components/atoms/Icons/Video';
import Webhooks from '@/components/atoms/Icons/Webhooks';
import WebviewPreview from '@/components/atoms/Icons/WebviewPreview';
import { CarouselCard } from '@/models/action';
import { DollarIcon } from '@/modules/TryIt/Icons/DollarIcon';
import { LAST_ACTIONS } from '@/util/constants';
import { getCarouselPlaceholder, getWebviewsUrl } from '@/util/util';

interface IconByTypeProps {
  id: string;
  type?: string;
  size?: number;
  color?: string;
  stepsMode?: boolean;
}
export const TYPES = {
  NODE: 'node',
  ACTION: 'action',
  CONDITION: 'condition',
  QUESTION: 'question',
  NOREQUISITE: 'norequisite',
  CONDITION_BOX: 'condition-box',
  OPTION: 'option',
} as const;

export const NO_REQUISITE_INDEX = -2;

export const iconByType = ({
  id,
  size,
  color,
  type,
  stepsMode = false,
}: IconByTypeProps) => {
  switch (id) {
    case 'intent':
      return <Intent size={size} color={color} />;
    case 'unknown':
    case 'unknown_root':
      return <Fallback size={size} color={color} />;
    case 'condition':
      return <Condition size={size} color={color} />;
    case 'text':
      return <Text size={size} color={color} />;
    case 'handover':
      return <Handover size={size} color={color} />;
    case 'survey':
      return <Survey size={size} color={color} />;
    case 'reset':
      return stepsMode ? (
        <DollarIcon size={size} color={color} />
      ) : (
        <ResetIcon size={size} color={color} />
      );
    case 'pause':
      return <Pause size={size} color={color} />;
    case 'event':
      return type === TYPES.ACTION ? (
        <JumpTo size={size} color={color} />
      ) : (
        <Event size={size} color={color} />
      );
    case 'jump_to':
      return <JumpTo size={size} color={color} />;
    case 'webhook':
      return <Webhooks size={size} color={color} />;
    case 'video':
      return <Video size={size} color={color} />;
    case 'image':
      return <Image size={size} color={color} />;
    case 'audio':
      return <Audio size={size} />;
    case 'file':
      return <File size={size} color={color} />;
    case 'url':
      return <Url size={size} color={color} />;
    case 'close':
      return <Resolve size={size} color={color} />;
    case 'replay':
      return <Replay size={size} color={color} />;
    case 'webview':
      return <IconToolWebview size={size} color={color} />;
    case 'carousel':
      return <Carousel size={size} color={color} />;
    case 'tag':
      return <IconToolTag size={size} color={color} />;
    case 'email':
      return <Email size={size} color={color} />;
    case 'googlesheet':
      return <GoogleSheet size={size} color={color} />;
    case 'any':
      return <IconToolSlot size={size} color={color} />;
    case 'question':
      return <Question size={size} color={color} />;
    case 'set_variables':
      return stepsMode ? (
        <DollarIcon size={size} color={color} />
      ) : (
        <SetVariables size={size} color={color} />
      );
    case 'payment':
      return <Billing size={size} color={color} />;
    case 'billing':
      return <Billing size={size} color={color} />;
    case 'entity':
      return <Entities size={size} color={color} />;
    default:
      return <IconToolIntent size={size} color={color} />;
  }
};

export const previewIconByType = (id: string) => {
  switch (id) {
    case 'file':
      return <FilePreview />;
    case 'webview':
      return <WebviewPreview />;
    case 'survey':
      return <SurveyPreview />;
    case 'url':
      return <URLPreview />;
  }
};

export const randId = (length = 4) => {
  return Math.random()
    .toString(36)
    .substring(2, 2 + length);
};

export const SYS_CONTEXT_VARIABLES = [
  '$sys-channel',
  '$sys-unknown_counter',
  '$sys-user_message_counter',
  '$sys-desk',
  '$sys-business',
  '$tags',
];

export const USER_INFO_VARIABLES = ['$user.verified', '$user.external_id'];

export const LEAD_DATA_VARIABLES = [
  '$user.display_name',
  '$user.email',
  '$user.phone',
  '$user.address',
  '$user.language',
];

export const VARIABLE_LABELS = {
  '$user.display_name': 'Name',
  '$user.email': 'Email',
  '$user.phone': 'Phone',
  '$user.address': 'Address',
  '$user.language': 'Language',
  '$user.verified': 'Verified',
  '$user.external_id': 'External ID',
  '$sys-channel': 'Channel',
  '$sys-unknown_counter': 'Unknown counter',
  '$sys-user_message_counter': 'User message counter',
  '$sys-desk': 'Environment',
  '$sys-business': 'Business hours',
} as const;

export const REVERSE_VARIABLE_LABELS = {
  Name: '$user.display_name',
  Email: '$user.email',
  Phone: '$user.phone',
  Address: '$user.address',
  Language: '$user.language',
  Verified: '$user.verified',
  'External ID': '$user.external_id',
  Channel: '$sys-channel',
  'Unknown counter': '$sys-unknown_counter',
  'User message counter': '$sys-user_message_counter',
  Environment: '$sys-desk',
  'Business hours': '$sys-business',
};

export const EXAMPLE_VARIABLES_VALUE = {
  '$user.display_name': 'Test name',
  '$user.email': 'test@email.com',
  '$user.phone': '1234567890',
  '$user.address': '123 Test St.',
  '$user.language': 'en',
  '$user.verified': 'true',
  '$user.external_id': '1234567890',
  '$sys-unknown_counter': '1',
  '$sys-user_message_counter': '2',
} as const;

export const getVariableLabel = (variable: string) => {
  if (VARIABLE_LABELS[variable]) {
    return VARIABLE_LABELS[variable];
  }

  return variable;
};

export const generateDefaultCarouselCard = (id: number): CarouselCard => ({
  title: t('dialog.carousel.example_title', { 0: id }),
  subtitle: t('dialog.carousel.example_subtitle', { 0: id }),
  media: {
    type: 'image',
    url: getCarouselPlaceholder({ id }),
  },
  buttons: [
    {
      type: 'postback',
      value: t('actions.generate_name', { 0: randId() }),
      label: `${t('dialog.carousel.button')} 1`,
      id: randId(6),
    },
  ],
});

export const DEFAULT_HEIGHT = 'tall';
export const DEFAULT_RANDOM_URL = 'https://picsum.photos/300';

export const defaultPropsByType = (id: string, randomValue?: string) => {
  const name = t('actions.generate_name', { 0: randId() });
  const text = t('actions.generate_text', { 0: randId() });
  switch (id) {
    // Triggers
    case 'intent':
      return { name, requisites: [], actions: [], intent: randomValue };
    case 'event':
      return {
        name,
        requisites: [],
        actions: [],
        trigger_node_id: randomValue,
      };
    case 'unknown':
      return { name, actions: [] };

    // Actions
    case 'condition':
      return {
        match: 'any',
        rules: [],
        actions: [],
        condition_id: uuidv4(),
        name: t('actions.generate_condition', { 0: randId() }),
      };
    case 'text':
      return { texts: [text] };
    case 'video':
      return {
        type: 'video',
        name,
        url: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
        size: 2299653,
      };
    case 'image':
      return { name, url: DEFAULT_RANDOM_URL };
    case 'url':
      return { url: DEFAULT_RANDOM_URL };
    case 'audio':
      return {
        name,
        url: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
      };
    case 'file':
      return {
        type: 'file',
        name,
        url: 'https://www.claymath.org/sites/default/files/official_problem_description.pdf',
        size: 10000000,
      };
    case 'pause':
      return { duration: 3, show_typing: true };
    case 'carousel':
      return {
        activeCard: 0,
        cards: [generateDefaultCarouselCard(1)],
      };
    case 'survey':
      return {
        name: t('actions.types.survey'),
        label: t('dialog.survey_label'),
        url: `${getWebviewsUrl()}/common/survey`,
        height: DEFAULT_HEIGHT,
      };
    case 'reset':
      return { name };
    case 'handover':
      return { name, external: false };
    case 'tag':
      return { tags: [t('dialog.pre-fill.tag')] };
    case 'webview': {
      return {
        name,
        label: t('dialog.carousel.button'),
        url: DEFAULT_RANDOM_URL,
        height: DEFAULT_HEIGHT,
        trigger_node_id: randomValue,
      };
    }
    case 'email':
      return {
        from: 'no-reply@moveo.ai',
        to: ['test@mail.com'],
        subject: t('dialog.pre-fill.subject'),
        body: text,
      };
    case 'googlesheet':
      return {
        sheet_id: t('dialog.pre-fill.sheet_id'),
        spreadsheet_id: t('dialog.pre-fill.spreadsheet_id'),
        inputs: [t('dialog.pre-fill.input')],
      };
    case 'webhook':
      return {
        webhook_id: randomValue,
        fallback: [
          {
            type: 'text',
            texts: [text],
          },
        ],
      };
    case 'set_variables':
      return {
        variables: [
          {
            variable: t('dialog.pre-fill.set_variable'),
            value: t('dialog.pre-fill.value'),
          },
        ],
      };
    default:
      return {};
  }
};

export type ItemsType = {
  title: string;
  id: string;
  type: 'node' | 'condition' | 'action';
};

// new toolkit
export const START_WITH = [
  {
    title: 'actions.types.intent',
    id: 'intent',
    type: TYPES.NODE,
  },
  {
    title: 'actions.types.event',
    id: 'event',
    type: TYPES.NODE,
  },
  {
    title: 'actions.types.unknown',
    id: 'unknown',
    type: TYPES.NODE,
  },
];
export const RESPONSES = [
  {
    title: 'actions.types.text',
    id: 'text',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.image',
    id: 'image',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.carousel',
    id: 'carousel',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.webview',
    id: 'webview',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.video',
    id: 'video',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.file',
    id: 'file',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.survey',
    id: 'survey',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.url',
    id: 'url',
    type: TYPES.ACTION,
  },
];
export const OPERATIONS = [
  {
    title: 'actions.types.question',
    id: 'question',
    type: TYPES.ACTION,
  },
  { title: 'actions.types.jump_to', id: 'event', type: TYPES.ACTION },
  {
    title: 'actions.types.condition',
    id: 'condition',
    type: TYPES.CONDITION,
  },
  {
    title: 'actions.types.handover',
    id: 'handover',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.tag',
    id: 'tag',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.pause',
    id: 'pause',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.set_variables',
    id: 'set_variables',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.reset_variables',
    id: 'reset',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.resolve',
    id: 'close',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.replay',
    id: 'replay',
    type: TYPES.ACTION,
  },
];
export const EXTENSIONS = [
  {
    title: 'actions.types.webhook',
    id: 'webhook',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.googlesheet',
    id: 'googlesheet',
    type: TYPES.ACTION,
  },
  {
    title: 'actions.types.email',
    id: 'email',
    type: TYPES.ACTION,
  },
];

export const generateOption = () => ({
  text: t('dialog.example_option_text', { 0: randId() }),
  label: t('dialog.example_option_label', { 0: randId() }),
  option_id: uuidv4(),
});

export const generateRequisite = () => ({
  save_as: randId(),
  requisite_id: uuidv4(),
  actions: [
    {
      type: 'text',
      texts: [t('actions.generate_text', { 0: randId() })],
    },
  ],
});

export const generateRule = () => ({
  name: `$${randId()}`,
  operator: 'not_exist',
  rule_id: uuidv4(),
});

export const generateResetVariable = () => randId();

export const generateConditionTextAction = () =>
  ({
    texts: [t('actions.generate_text', { 0: randId() })],
    type: 'text',
    action_id: uuidv4(),
    node_id: uuidv4(),
  }) as const;

/**
 * Replaces an element at a specified index in an array with a new element, returning a new array.
 *
 * @param {Array} arr - The original array.
 * @param {number} index - The index of the element to replace.
 * @param {*} newElement - The new element to insert at the specified index.
 * @returns {Array} A new array with the specified element replaced by the new element.
 *
 * @example
 * const arr = [1, 2, 3, 4, 5];
 * const newArr = replaceArrayElement(arr, 2, 'new');
 * log(newArr); // [1, 2, 'new', 4, 5]
 */
export const replaceArrayElement = (arr, index, newElement) => {
  if (index === 0) {
    return [newElement, ...arr.slice(1)];
  }

  if (index === arr.length - 1) {
    return [...arr.slice(0, -1), newElement];
  }

  return [...arr.slice(0, index), newElement, ...arr.slice(index + 1)];
};

// Accepted file formats for the dropzone of the File action.
export const ACCEPTED_FILE_FORMATS: Accept = {
  // Images
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  ' image/gif': ['.gif'],
  // Documents
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  // Spreadsheets
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  // Presentations
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  // Archives
  'application/zip': ['.zip'],
  'application/x-rar-compressed': ['.rar'],
  // Text
  'text/plain': ['.txt', '.text'],
  // Audio
  'audio/mpeg': ['.mp3'],
  'audio/wav': ['.wav'],
  'audio/x-ms-wma': ['.wma'],
  // Video
  'video/mp4': ['.mp4'],
};

/**
 * Returns an empty option object with a unique identifier.
 * @returns {Object} An object representing an empty option.
 * @property {string} text - The text of the option.
 * @property {string} label - The label of the option.
 * @property {string} option_id - The unique identifier of the option.
 */
export const getEmptyOption = () => ({
  text: '',
  label: '',
  option_id: uuidv4(),
});

export const isFinalAction = (type: string) => {
  return LAST_ACTIONS.includes(type);
};

/**
 * Get a random element from an array.
 *
 * @template T
 * @param {T[]} arr - The input array.
 * @returns {T | null} - A random element from the array, or `null` if the array is empty.
 */
export function getRandomElement<T>(arr: T[]): T | null {
  if (!arr || arr.length === 0) {
    return null;
  }

  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}
