import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography/Typography';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { Form, DepartmentRouting } from '@/modules/departments/models';

import styles from './RoutingSelect.module.scss';

interface Props {
  control: Control<Form>;
  department_id?: string;
}
const RoutingSelect = ({ control, department_id = '' }: Props) => {
  const { t } = useTranslation();
  const options = [
    {
      value: DepartmentRouting.BALANCED,
      label: (
        <TitleSubtitle
          noGutters
          title={t('departments.mode.balanced')}
          subtitle={t('departments.mode.balanced_description')}
        />
      ),
      id: `${department_id}-${2}`,
    },
    {
      value: DepartmentRouting.ROUND_ROBIN,
      label: (
        <TitleSubtitle
          noGutters
          title={t('departments.mode.round_robin')}
          subtitle={t('departments.mode.round_robin_description')}
        />
      ),
      id: `${department_id}-${0}`,
    },
    {
      value: DepartmentRouting.MANUAL,
      label: (
        <TitleSubtitle
          noGutters
          title={t('departments.mode.manual')}
          subtitle={t('departments.mode.manual_description')}
        />
      ),
      id: `${department_id}-${1}`,
    },
  ];
  return (
    <>
      <Typography
        className={styles.label}
        component="p"
        variant="label-caps-large"
      >
        {t('departments.assignment_mode')}
      </Typography>

      <Box className={styles.radioWrapper}>
        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <>
                {options.map((option) => {
                  return (
                    <OptionRadioButton
                      key={`${department_id}-${option.value}`}
                      option={{
                        value: option.value,
                        label: option.label,
                        id: option.id,
                      }}
                      isSelected={option.value === value}
                      onChange={onChange}
                    />
                  );
                })}
              </>
            );
          }}
          name="routing"
          control={control}
        />
      </Box>
    </>
  );
};

export default RoutingSelect;
