import { memo, useCallback, useEffect } from 'react';

import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import AvatarUpload from '@/components/atoms/Avatar/AvatarUpload/AvatarUpload';
import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import Input from '@/components/atoms/Input/Input';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  WebWidgetIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { errorMessage, integrationRulesWebWidget } from '@/util/validator';

import LauncherOptions from './LauncherOptions';

import styles from './LauncherAppearance.module.scss';

type Form = {
  launcher_image_checkbox: boolean;
  launcher_type: 'bubble' | 'help' | 'feedback' | 'question';
  focus_trap: boolean;
  auto_open: boolean;
  welcome_message: string;
  bubble_image_url: string;
};

const LauncherAppearance = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, WebWidgetIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const { updateIntegration, updateStatus } =
    useIntegrations<WebWidgetIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );
  const dispatch = useDispatch();

  const formMethods = useForm<Form>();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    control,
    setValue,
  } = formMethods;

  const [launcherImageActive, launcherType, bubbleImageUrl] = useWatch({
    control,
    name: ['launcher_image_checkbox', 'launcher_type', 'bubble_image_url'],
  });

  useEffect(() => {
    reset({
      welcome_message: integration?.config?.welcome_message ?? '',
      launcher_image_checkbox: !!integration?.config?.bubble_image,
      launcher_type: integration?.config?.launcher?.type || 'bubble',
      focus_trap: integration?.config?.focus_trap || false,
      auto_open: integration?.config?.auto_open || false,
      bubble_image_url: integration?.config?.bubble_image || '',
    });
  }, [
    reset,
    integration?.config?.bubble_image,
    integration?.config?.launcher?.type,
    integration?.config?.welcome_message,
    integration?.config?.focus_trap,
    integration?.config?.auto_open,
  ]);

  const handleUpdateIntegration = useCallback(() => {
    const {
      welcome_message,
      launcher_image_checkbox,
      focus_trap,
      auto_open,
      launcher_type,
      bubble_image_url,
    } = formMethods.getValues();
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration?.config,
          welcome_message: welcome_message || undefined,
          focus_trap,
          auto_open,
          launcher: launcher_type
            ? {
                ...integration?.config?.launcher,
                type: launcher_type,
              }
            : undefined,
          bubble_image: launcher_image_checkbox ? bubble_image_url : undefined,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, formMethods, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  const handleAvatarUpdate = useCallback(
    (data) => {
      setValue('bubble_image_url', data?.avatar, { shouldDirty: true });
    },
    [setValue]
  );

  return (
    <>
      <Accordion
        title={t('integrations.web_launcher.title')}
        subtitle={t('integrations.web_launcher.subtitle')}
        footerText={t('integrations.web_launcher.footer')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
      >
        <TitleSubtitle
          title={t('integrations.web_launcher.title2')}
          subtitle={t('integrations.web_launcher.subtitle2')}
        />
        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <LauncherOptions
                onChange={onChange}
                value={value}
                isDisabled={launcherImageActive || !canWrite}
              />
            );
          }}
          name="launcher_type"
          control={control}
        />
        <div className={styles.launcherImage}>
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  label={t('integrations.web_launcher.custom_bubble')}
                  onChange={onChange}
                  checked={value}
                  size="small"
                  disabled={
                    launcherType === 'help' ||
                    launcherType === 'feedback' ||
                    !canWrite
                  }
                />
              );
            }}
            name="launcher_image_checkbox"
            control={control}
          />

          {launcherImageActive && (
            <AvatarUpload
              onUpdate={handleAvatarUpdate}
              label="bubbleImage"
              avatar={bubbleImageUrl}
            />
          )}
        </div>

        <TitleSubtitle
          title={t('integrations.web_launcher.teaser.title')}
          subtitle={t('integrations.web_launcher.teaser.subtitle')}
        />

        <div className={styles.launcherOptions__title}>
          {t('integrations.web_launcher.teaser.body')}
        </div>
        <div className={styles.input}>
          <Input
            name="welcome_message"
            register={register(
              'welcome_message',
              integrationRulesWebWidget.welcomeMessage
            )}
            errorMessage={
              errorMessage({
                field: errors['welcome_message'],
                maxLength: integrationRulesWebWidget.welcomeMessage?.maxLength,
              }) ?? errors['welcome_message']?.message
            }
            placeholder={t('integrations.web_launcher.teaser.placeholder')}
            size="medium"
            data-testid="welcome_message"
            trimValue
            readOnly={!canWrite}
          />
        </div>

        <div className={styles.focusTrap}>
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  label={
                    <TitleSubtitle
                      title={t('integrations.web_launcher.focus.title')}
                      subtitle={t('integrations.web_launcher.focus.subtitle')}
                      noGutters
                    />
                  }
                  onChange={onChange}
                  checked={value}
                  data-testid="focus_trap"
                  disabled={!canWrite}
                />
              );
            }}
            name="focus_trap"
            control={control}
          />
        </div>

        <div>
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  label={
                    <TitleSubtitle
                      title={t('integrations.web_launcher.auto_open.title')}
                      subtitle={t(
                        'integrations.web_launcher.auto_open.subtitle'
                      )}
                      noGutters
                    />
                  }
                  onChange={onChange}
                  checked={value}
                  disabled={!canWrite}
                />
              );
            }}
            name="auto_open"
            control={control}
          />
        </div>
      </Accordion>
    </>
  );
};

export default memo(LauncherAppearance);
