import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import useDesks from '@/hooks/useDesks';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import useOverViewUsage from '@/modules/analytics/hooks/useOverviewUsage';
import { setDeskId } from '@/redux/session/actions';
import { selectDeskId } from '@/redux/session/selectors';

const useHomeInit = () => {
  const { desks } = useDesks();
  const sessionDeskId = useSelector(selectDeskId);
  const { accountUserChecklist, markAsComplete } = useHomeCheckList();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessionDeskId && !isEmpty(desks)) {
      dispatch(setDeskId(desks[0]?.desk_id));
    }
  }, [desks, dispatch, sessionDeskId]);

  useEffect(() => {
    if (desks && desks.length > 0) {
      markAsComplete(AccountUserPrefsEnum.ENVIRONMENT_SETUP, true);
    }
  }, [accountUserChecklist, desks, markAsComplete]);

  const messages = useOverViewUsage({
    intervalProps: {
      type: 'dialogs',
      startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
    skip: accountUserChecklist[AccountUserPrefsEnum.RECEIVE_MESSAGES],
    skipPrevious: true,
  });

  useEffect(() => {
    if (messages?.data?.counts && +messages.data.counts > 99) {
      markAsComplete(AccountUserPrefsEnum.RECEIVE_MESSAGES);
    }
  }, [accountUserChecklist, messages?.data?.counts, markAsComplete]);
};

export default useHomeInit;
