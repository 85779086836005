import { useCallback, useContext, useEffect, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  invitationsEndpoints as endpoints,
  accountsEndpoints,
  membersEndpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { SocketContext } from '@/contexts/rtm';
import {
  DeleteInvitation,
  Invitation,
  Invitations,
  NewInvitation,
  RespondInvitation,
  UpdateInvitation,
} from '@/models/member';
import { actions } from '@/models/permissions';
import { SocketEvent } from '@/models/rtm';
import { RootState } from '@/models/state';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountId, selectAccountSlug } from '@/redux/session/selectors';

import { useAccount } from './useAccount';
import useUser from './useUser';

export const API = Object.freeze({
  listUserInvitations: async () => callGet(endpoints.userInvitations),

  listAccountInvitations: async () => callGet(endpoints.accountInvitations),

  createInvitation: async (updates: NewInvitation) =>
    callPost(endpoints.invitations, updates),

  updateInvitation: async (updates: UpdateInvitation) =>
    callPut(endpoints.invitations, updates),

  respondInvitation: async (updates: RespondInvitation) =>
    callPut(endpoints.userInvitations, updates),

  deleteInvitation: async (updates: DeleteInvitation) =>
    callDelete(endpoints.deleteInvitations(updates.email)),
});

export const useInvitations = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const accountSlug = useSelector(selectAccountSlug);
  const accountId = useSelector(selectAccountId);
  const { accounts } = useAccount();
  const { user } = useUser();

  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'invitations', actions.READ)
  );

  const { data: userInvitations } = useQuery<Invitations, Error>({
    queryKey: [endpoints.userInvitations],
    queryFn: API.listUserInvitations,
    enabled: !!accountSlug || accounts?.length === 0,
  });

  const { data: accountInvitations } = useQuery<Invitations, Error>({
    queryKey: [endpoints.accountInvitations, accountSlug],
    queryFn: API.listAccountInvitations,
    enabled: !!accountSlug && canRead,
  });
  const {
    mutate: createInvitation,
    mutateAsync: createInvitationAsync,
    status: createStatus,
  } = useMutation<Invitation, Error, NewInvitation>({
    mutationFn: API.createInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.accountInvitations, accountSlug],
      });
      dispatch(addTemporalToast('success', t('invitations.invite_sent')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: updateInvitation, status: updateStatus } = useMutation<
    Invitation,
    Error,
    UpdateInvitation
  >({
    mutationFn: API.updateInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [endpoints.accountInvitations, accountSlug],
      });
      dispatch(addTemporalToast('success', t('invitations.invite_updated')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: respondInvitation, status: respondStatus } = useMutation<
    Invitation,
    Error,
    RespondInvitation
  >({
    mutationFn: API.respondInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [accountsEndpoints.accounts, user?.user_id],
      });
      queryClient.invalidateQueries({ queryKey: [endpoints.userInvitations] });
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteInvitation, status: deleteStatus } = useMutation<
    Invitation,
    Error,
    DeleteInvitation
  >({
    mutationFn: API.deleteInvitation,
    onSuccess: (_, updates) => {
      queryClient.setQueryData<Invitations>(
        [endpoints.accountInvitations, accountSlug],
        (prev) => ({
          invitations: (prev?.invitations || []).filter(
            (item) => item.email !== updates.email
          ),
        })
      );
      dispatch(addTemporalToast('success', t('invitations.invite_deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const handleInvitationCreated = useCallback(
    ({ data }) => {
      if (data.account_id === accountId) {
        return;
      }
      queryClient.invalidateQueries({ queryKey: [endpoints.userInvitations] });
    },
    [accountId, queryClient]
  );

  const handleInvitationUpdated = useCallback(
    ({ data }) => {
      if (data.account_id === accountId) {
        return;
      }
      queryClient.invalidateQueries({ queryKey: [endpoints.userInvitations] });
    },
    [accountId, queryClient]
  );

  const handleInvitationRemoved = useCallback(
    ({ data }) => {
      if (data.account_id === accountId) {
        return;
      }
      queryClient.invalidateQueries({ queryKey: [endpoints.userInvitations] });
    },
    [accountId, queryClient]
  );
  const { socket } = useContext(SocketContext);
  const [invisible, setInvisible] = useState(true);

  const handleInvitationAccepted = useCallback(
    ({ data }) => {
      if (data.account_id !== accountId) {
        setInvisible(false);
      }

      queryClient.setQueryData<Partial<Invitations>>(
        [endpoints.accountInvitations, accountSlug],
        (prev) => ({
          invitations: (prev?.invitations || []).filter(
            (item) => item.invitation_code !== data.invitation_code
          ),
        })
      );

      queryClient.invalidateQueries({
        queryKey: [membersEndpoints.members, accountSlug],
      });
    },
    [accountId, accountSlug, queryClient]
  );

  const handleInvitationRejected = useCallback(
    ({ data }) => {
      queryClient.setQueryData<Partial<Invitations>>(
        [endpoints.accountInvitations, accountSlug],
        (prev) => ({
          invitations: (prev?.invitations || []).filter(
            (item) => item.invitation_code !== data.invitation_code
          ),
        })
      );

      queryClient.invalidateQueries({
        queryKey: [membersEndpoints.members, accountSlug],
      });
    },
    [accountSlug, queryClient]
  );

  useEffect(() => {
    socket?.on(SocketEvent.invitations_accepted, handleInvitationAccepted);
    return () => {
      socket?.off(SocketEvent.invitations_accepted, handleInvitationAccepted);
    };
  }, [handleInvitationAccepted, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.invitations_created, handleInvitationCreated);
    return () => {
      socket?.off(SocketEvent.invitations_created, handleInvitationCreated);
    };
  }, [handleInvitationCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.invitations_updated, handleInvitationUpdated);
    return () => {
      socket?.off(SocketEvent.invitations_updated, handleInvitationUpdated);
    };
  }, [handleInvitationUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.invitations_removed, handleInvitationRemoved);
    return () => {
      socket?.off(SocketEvent.invitations_removed, handleInvitationRemoved);
    };
  }, [handleInvitationRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.invitations_rejected, handleInvitationRejected);
    return () => {
      socket?.off(SocketEvent.invitations_rejected, handleInvitationRejected);
    };
  }, [handleInvitationRejected, socket]);

  return {
    invitations: userInvitations?.invitations,
    hasInvitations: userInvitations?.invitations?.length > 0,
    accountInvitations: accountInvitations?.invitations,
    updateInvitation,
    updateStatus,
    respondInvitation,
    respondStatus,
    createInvitation,
    createInvitationAsync,
    createStatus,
    deleteInvitation,
    deleteStatus,
    handleInvisibleBadge: { invisible, setInvisible },
  };
};
