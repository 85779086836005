import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  MODAL_CONFIRMATION,
  MODAL_PUBLISH_VERSION,
  VERSION_DELETE,
} from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import useVersions from '@/hooks/useVersions';
import { RootState } from '@/models/state';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { clearIntentExpressions } from '@/redux/expressions/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { clearDialogNodes } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { clearEntityValues } from '@/redux/values/actions';

export const useVersionsTable = () => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const dispatch = useDispatch();

  const { versions, restoreVersion } = useVersions(brainId);
  const isDialogDirty = useSelector((state: RootState) => state.nodes.dirty);
  const isEntityDirty = useSelector((state: RootState) => state.values.dirty);
  const isIntentDirty = useSelector(
    (state: RootState) => state.expressions.dirty
  );

  const clearDirty = useCallback(() => {
    if (isDialogDirty) {
      dispatch(clearDialogNodes());
    }
    if (isEntityDirty) {
      dispatch(clearEntityValues());
    }
    if (isIntentDirty) {
      dispatch(clearIntentExpressions());
    }
  }, [dispatch, isDialogDirty, isEntityDirty, isIntentDirty]);

  const handleCreateVersion = () => {
    if (brain.status !== 'available') {
      const error = new Error(t('version.brain_not_trained'));
      dispatch(addErrorTemporalToast(error));
      return;
    }

    dispatch(pushModal(MODAL_PUBLISH_VERSION));
  };

  const handleDeleteVersion = useCallback(
    (versionNumber: number) => {
      const modalProps = {
        versionNumber,
      };

      dispatch(pushModal(VERSION_DELETE, modalProps));
    },
    [dispatch]
  );

  const handleRestoreVersion = useCallback(
    (versionNumber: number) => {
      const modalProps = {
        title: t('prompts.unsaved_changes.title'),
        subtitle: t('version.restore_subtitle'),

        onConfirm: async () => {
          clearDirty();
          restoreVersion(versionNumber, {
            onSuccess: () => {
              dispatch(popModal());
            },
          });
        },
        primaryButtonText: t('version.restore'),
      };
      dispatch(pushModal(MODAL_CONFIRMATION, modalProps));
    },
    [clearDirty, dispatch, restoreVersion, t]
  );

  // draft is not counted as a version
  const noVersions = versions?.length === 1;

  return {
    noVersions,
    handleCreateVersion,
    handleDeleteVersion,
    handleRestoreVersion,
  };
};
