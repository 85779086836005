import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const CustomInputField = ({
  inputParams,
  searchIcon,
  placeholder,
  inputRef,
}) => {
  return (
    <TextField
      {...inputParams}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        ...inputParams.InputProps,
        startAdornment: searchIcon ? (
          <InputAdornment position="start">{searchIcon}</InputAdornment>
        ) : undefined,
      }}
      placeholder={placeholder}
      inputRef={inputRef}
    />
  );
};

export default CustomInputField;
