import { Icon } from '@/models/icon';

const Notifications = ({
  color = 'var(--icon-default-white)',
  size = 24,
}: Icon) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8A6 6 0 1 0 6 8c0 7-3 9-3 9h18s-3-2-3-9ZM13.73 21a1.999 1.999 0 0 1-3.46 0"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="16.77"
        cy="6.5"
        r="4.75"
        fill="transparent"
        stroke="transparent"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Notifications;
