const Chain = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="none">
      <circle cx="21.5" cy="21.5" r="21.5" fill="#0A3451" />
      <path
        fill="#0A3451"
        stroke="#fff"
        strokeWidth="2"
        d="M20.816 26.317c-1.458-1.278-1.766-2.72-1.669-3.914.144-1.759 1.422-3.147 2.63-4.433l3.838-4.088c.26-.277.523-.554.837-.77.945-.647 3.285-1.733 5.973.858.15.145.288.29.414.431 2.51 2.839-1.09 6.513-3.638 9.32l-.61.67c-1.232 1.359-2.574 2.823-4.395 3.028-1.018.114-2.222-.088-3.38-1.102Z"
      />
      <g filter="url(#a)">
        <path
          stroke="#fff"
          strokeWidth="2"
          d="M20.974 16.821s-.666-.656-1.955-.571c-1.48.098-2.655 1.087-3.724 2.115l-3.735 3.592C9.998 23.459 8.43 25.36 9.007 27.45c.207.754.63 1.556 1.415 2.307a4.327 4.327 0 0 0 1.895 1.056c2.156.58 4.178-.908 5.789-2.453l5.06-4.854a3.348 3.348 0 0 0-.111-4.935"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="17.312"
          height="17.299"
          x="7.885"
          y="15.142"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="-.1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.0392157 0 0 0 0 0.203922 0 0 0 0 0.317647 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_106_3060"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy=".5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.0392157 0 0 0 0 0.203922 0 0 0 0 0.317647 0 0 0 1 0" />
          <feBlend
            in2="effect1_dropShadow_106_3060"
            result="effect2_dropShadow_106_3060"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_106_3060"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Chain;
