import { useTranslation } from 'react-i18next';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import Docs from '@/components/atoms/Icons/Docs';
import { EVENT_RESOLVE_DOCS_URL } from '@/util/constants';

import ToolkitWrapper from '../../ToolkitWrapper';

const ToolkitActionClose = () => {
  const { t } = useTranslation();
  return (
    <ToolkitWrapper type="close">
      <ContextualHelp
        preventClose
        name="action-close"
        title={t('dialog.resolve.title')}
        links={[
          {
            label: t('docs.docs'),
            url: EVENT_RESOLVE_DOCS_URL,
            icon: <Docs size={16} color="currentColor" />,
          },
        ]}
      >
        {t('dialog.resolve.body')}
      </ContextualHelp>
    </ToolkitWrapper>
  );
};

export default ToolkitActionClose;
