import { memo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';

import styles from './TileBrain.module.scss';

type Props = {
  isBrain?: boolean;
  noMargin?: boolean;
};

function TileBrainSkeleton({ isBrain = true, noMargin = false }: Props) {
  return (
    <div
      className={cn(styles.link, styles.tile, {
        tile: true,
        'tile--mr0': noMargin,
      })}
    >
      <div className={`${styles.wrapper}`}>
        <div className={styles.icon}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={18}
            height={20}
          />
        </div>
        <div className={styles.info}>
          <span>
            <span className={styles.name}>
              <Skeleton animation="wave" width={200} height={24} />
            </span>
          </span>
          <span className={styles.desc}>
            <Skeleton animation="wave" width={270} height={20} />
          </span>
          <span className={styles.footer}>
            <Skeleton animation="wave" width={200} height={24} />
            <span className={styles.details}>
              <Skeleton animation="wave" width={80} height={24} />
            </span>
          </span>
        </div>
        <div className={styles.menu}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={32}
            height={32}
          />
          {isBrain && (
            <Skeleton
              animation="wave"
              variant="circular"
              width={32}
              height={32}
              sx={{
                marginLeft: 1,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(TileBrainSkeleton);
