import { Icon } from '@/models/icon';

const Fallback = ({ size, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.6 6.714a2.574 2.574 0 0 1 5 .857c0 1.2-.822 2.208-1.934 2.492-.349.09-.64.463-.64.937"
      />
      <rect
        width="16"
        height="16"
        x="1"
        y="1"
        stroke={color}
        strokeDasharray="4 4"
        rx="8"
      />
      <circle cx="9" cy="12.933" r=".533" fill={color} />
    </svg>
  );
};

export default Fallback;
