import cn from 'classnames';
import { useSelector } from 'react-redux';

import { selectRuleCondition } from '@/modules/rules/redux/selectors';
import { isConditionNested } from '@/modules/rules/util';

import ConditionRuleGroup from './ConditionRuleGroup';

import styles from './Condition.module.scss';

const Condition = () => {
  const _condition = useSelector(selectRuleCondition);
  const isNested = isConditionNested(_condition);

  if (!isNested) {
    return (
      <div
        className={cn(styles.conditionWrapper, {
          [styles.empty]: !_condition?.conditions.length,
        })}
      >
        <div className={styles.rule}>
          <ConditionRuleGroup singleCondition={_condition} outerIndex={0} />
        </div>
      </div>
    );
  }

  if (isNested) {
    return (
      <div
        className={cn(styles.conditionWrapper, {
          [styles.empty]: !_condition?.conditions.length,
        })}
      >
        {_condition.conditions.map((condition, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.conditionInnerWrapper} key={index}>
            <div className={styles.rule}>
              <ConditionRuleGroup
                singleCondition={condition}
                outerIndex={index}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default Condition;
