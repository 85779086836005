import { useEffect, useRef } from 'react';

import Avatar from '@mui/material/Avatar';

import useFileSubmit from '@/components/atoms/FileUpload/useFileSubmit';
import Edit from '@/components/atoms/Icons/Edit';
import BrainIcon from '@/components/atoms/Icons/Robot';
import Spinner from '@/components/atoms/Spinner/Spinner';
import useFileUpload from '@/hooks/useFileUpload';

import styles from './AvatarUpload.module.scss';

interface AvatarUploadProps {
  onUpdate: (avatar: { avatar?: string }) => void;
  name?: string;
  label: string;
  avatar?: string;
  freeForm?: boolean;
}
/**
 * @AvatarUpload freeForm - if true, the user can upload free-form aspect ratio images
 */
const AvatarUpload = ({
  onUpdate,
  label,
  avatar,
  name,
  freeForm = false,
}: AvatarUploadProps) => {
  const updatedAvatarRef = useRef(false);
  const { croppedFile, onAvatarUpload } = useFileUpload(
    freeForm ? 'free' : undefined
  );
  const { data, isLoading, uploadFile } = useFileSubmit({
    stream: true,
  });

  useEffect(() => {
    if (croppedFile) {
      uploadFile(croppedFile);
    }
  }, [croppedFile, uploadFile]);

  useEffect(() => {
    if (data?.url && updatedAvatarRef.current === false) {
      onUpdate({ avatar: data?.url });
      updatedAvatarRef.current = true;
    }
  }, [data?.url, onUpdate]);

  const muiStyles = {
    root: {
      height: '72px',
      width: '72px',
      borderRadius: '50%',
    },
    img: {
      height: '72px',
      width: '72px',
      borderRadius: '50%',
      objectFit: 'cover',
    },
  };

  const handleAvatarUpload = (e) => {
    updatedAvatarRef.current = false;
    onAvatarUpload(e);
  };

  return (
    <div className={styles.avatar}>
      <div className={styles.imageHolder}>
        {isLoading && <Spinner />}
        {avatar && (
          <Avatar
            alt={name}
            src={avatar}
            sx={{
              ...muiStyles.root,
              '.MuiAvatar-img': {
                ...muiStyles.img,
              },
            }}
          />
        )}
        {!avatar && (
          <div className={styles.brain}>
            <BrainIcon size={35} color="var(--text-default-gray)" />
          </div>
        )}
        <div className={styles.edit}>
          <Edit />
        </div>
        <input
          type="file"
          accept=".png,.jpeg,.jpg"
          aria-label={label}
          onChange={handleAvatarUpload}
        />
      </div>
    </div>
  );
};

export default AvatarUpload;
