import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Select from '@/components/atoms/Select/Select';
import { usePreferences } from '@/hooks/usePreferences';
import useUser from '@/hooks/useUser';
import { isRequired } from '@/util/validator';

type Form = {
  language: string;
};

const SystemLanguage = () => {
  const { t } = useTranslation();
  const { supportedLanguageOptions } = useUser();
  const {
    userPreferences,
    updateUserPreferences,
    updateUserPreferencesStatus,
  } = usePreferences();
  const formMethods = useForm<Form>({
    mode: 'onChange',
  });
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = formMethods;
  const isLoading = userPreferences === undefined;

  useEffect(() => {
    if (userPreferences?.language) {
      reset({ language: String(userPreferences?.language) });
    }
  }, [reset, userPreferences?.language]);

  const onSubmit = useCallback(
    ({ language }: Form) => updateUserPreferences({ language }),
    [updateUserPreferences]
  );

  return (
    <FormFieldset
      title={t('profile.language.title')}
      subtitle={t('profile.language.subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateUserPreferencesStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Select
        name="language"
        label={t('field.language')}
        register={register('language', isRequired)}
        errorMessage={errors.language?.message}
        options={supportedLanguageOptions}
        size="full"
      />
    </FormFieldset>
  );
};

export default SystemLanguage;
