// https://gist.github.com/Josantonius/b455e315bc7f790d14b136d61d9ae469

export const languageCodes = {
  aa: 'Afar',
  ab: 'Abkhazian',
  af: 'Afrikaans',
  am: 'Amharic',
  ar: 'Arabic',
  as: 'Assamese',
  ay: 'Aymara',
  az: 'Azeri',
  ba: 'Bashkir',
  be: 'Belarusian',
  bg: 'Bulgarian',
  bh: 'Bihari',
  bi: 'Bislama',
  bn: 'Bengali',
  bo: 'Tibetan',
  br: 'Breton',
  ca: 'Catalan',
  co: 'Corsican',
  cs: 'Czech',
  cy: 'Welsh',
  da: 'Danish',
  de: 'German',
  div: 'Divehi',
  dz: 'Bhutani',
  el: 'Greek',
  en: 'English',
  eo: 'Esperanto',
  es: 'Spanish',
  et: 'Estonian',
  eu: 'Basque',
  fa: 'Farsi',
  fi: 'Finnish',
  fj: 'Fiji',
  fo: 'Faeroese',
  fr: 'French',
  fy: 'Frisian',
  ga: 'Irish',
  gd: 'Gaelic',
  gl: 'Galician',
  gn: 'Guarani',
  gu: 'Gujarati',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  hr: 'Croatian',
  hu: 'Hungarian',
  hy: 'Armenian',
  ia: 'Interlingua',
  id: 'Indonesian',
  ie: 'Interlingue',
  ik: 'Inupiak',
  is: 'Icelandic',
  it: 'Italian',
  ja: 'Japanese',
  jw: 'Javanese',
  ka: 'Georgian',
  kk: 'Kazakh',
  kl: 'Greenlandic',
  km: 'Cambodian',
  kn: 'Kannada',
  ko: 'Korean',
  kok: 'Konkani',
  ks: 'Kashmiri',
  ku: 'Kurdish',
  ky: 'Kirghiz',
  kz: 'Kyrgyz',
  la: 'Latin',
  ln: 'Lingala',
  lo: 'Laothian',
  lt: 'Lithuanian',
  lv: 'Latvian',
  mg: 'Malagasy',
  me: 'Montenegrin',
  mi: 'Maori',
  mk: 'FYRO Macedonian',
  ml: 'Malayalam',
  mn: 'Mongolian',
  mo: 'Moldavian',
  mr: 'Marathi',
  ms: 'Malay',
  mt: 'Maltese',
  my: 'Burmese',
  na: 'Nauru',
  ne: 'Nepali (India)',
  nl: 'Dutch',
  'nl-be': 'Dutch (Belgium)',
  'nn-no': 'Norwegian',
  no: 'Norwegian (Bokmal)',
  oc: 'Occitan',
  om: '(Afan)/Oromoor/Oriya',
  or: 'Oriya',
  pa: 'Punjabi',
  pl: 'Polish',
  ps: 'Pashto/Pushto',
  pt: 'Portuguese',
  'pt-br': 'Portuguese (Brazil)',
  qu: 'Quechua',
  rm: 'Rhaeto-Romanic',
  rn: 'Kirundi',
  ro: 'Romanian',
  'ro-md': 'Romanian (Moldova)',
  ru: 'Russian',
  'ru-md': 'Russian (Moldova)',
  rw: 'Kinyarwanda',
  sa: 'Sanskrit',
  sb: 'Sorbian',
  sd: 'Sindhi',
  sg: 'Sangro',
  sh: 'Serbo-Croatian',
  si: 'Singhalese',
  sk: 'Slovak',
  sl: 'Slovenian',
  sm: 'Samoan',
  sn: 'Shona',
  so: 'Somali',
  sq: 'Albanian',
  sr: 'Serbian',
  ss: 'Siswati',
  st: 'Sesotho',
  su: 'Sundanese',
  sv: 'Swedish',
  'sv-fi': 'Swedish (Finland)',
  sw: 'Swahili',
  sx: 'Sutu',
  syr: 'Syriac',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thai',
  ti: 'Tigrinya',
  tk: 'Turkmen',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tonga',
  tr: 'Turkish',
  ts: 'Tsonga',
  tt: 'Tatar',
  tw: 'Twi',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  vo: 'Volapuk',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  zh: 'Chinese',
  zu: 'Zulu',
};

export const languageCodesLabelToValue = {
  Afar: 'aa',
  Abkhazian: 'ab',
  Afrikaans: 'af',
  Amharic: 'am',
  Arabic: 'ar',
  Assamese: 'as',
  Aymara: 'ay',
  Azeri: 'az',
  Bashkir: 'ba',
  Belarusian: 'be',
  Bulgarian: 'bg',
  Bihari: 'bh',
  Bislama: 'bi',
  Bengali: 'bn',
  Tibetan: 'bo',
  Breton: 'br',
  Catalan: 'ca',
  Corsican: 'co',
  Czech: 'cs',
  Welsh: 'cy',
  Danish: 'da',
  German: 'de',
  Divehi: 'div',
  Bhutani: 'dz',
  Greek: 'el',
  English: 'en',
  Esperanto: 'eo',
  Spanish: 'es',
  Estonian: 'et',
  Basque: 'eu',
  Farsi: 'fa',
  Finnish: 'fi',
  Fiji: 'fj',
  Faeroese: 'fo',
  French: 'fr',
  Frisian: 'fy',
  Irish: 'ga',
  Gaelic: 'gd',
  Galician: 'gl',
  Guarani: 'gn',
  Gujarati: 'gu',
  Hausa: 'ha',
  Hebrew: 'he',
  Hindi: 'hi',
  Croatian: 'hr',
  Hungarian: 'hu',
  Armenian: 'hy',
  Interlingua: 'ia',
  Indonesian: 'id',
  Interlingue: 'ie',
  Inupiak: 'ik',
  Icelandic: 'is',
  Italian: 'it',
  Japanese: 'ja',
  Javanese: 'jw',
  Georgian: 'ka',
  Kazakh: 'kk',
  Greenlandic: 'kl',
  Cambodian: 'km',
  Kannada: 'kn',
  Korean: 'ko',
  Konkani: 'kok',
  Kashmiri: 'ks',
  Kurdish: 'ku',
  Kirghiz: 'ky',
  Kyrgyz: 'kz',
  Latin: 'la',
  Lingala: 'ln',
  Laothian: 'lo',
  Slovenian: 'sl',
  Lithuanian: 'lt',
  Latvian: 'lv',
  Malagasy: 'mg',
  Maori: 'mi',
  'FYRO Macedonian': 'mk',
  Malayalam: 'ml',
  Mongolian: 'mn',
  Montenegrin: 'me',
  Moldavian: 'mo',
  Marathi: 'mr',
  Malay: 'ms',
  Maltese: 'mt',
  Burmese: 'my',
  Nauru: 'na',
  'Norwegian (Bokmal)': 'no',
  'Nepali (India)': 'ne',
  Dutch: 'nl',
  'Dutch (Belgium)': 'nl-be',
  Norwegian: 'nn-no',
  Occitan: 'oc',
  '(Afan)/Oromoor/Oriya': 'om',
  Oriya: 'or',
  Punjabi: 'pa',
  Polish: 'pl',
  'Pashto/Pushto': 'ps',
  Portuguese: 'pt',
  'Portuguese (Brazil)': 'pt-br',
  Quechua: 'qu',
  'Rhaeto-Romanic': 'rm',
  Kirundi: 'rn',
  Romanian: 'ro',
  'Romanian (Moldova)': 'ro-md',
  Russian: 'ru',
  'Russian (Moldova)': 'ru-md',
  Kinyarwanda: 'rw',
  Sanskrit: 'sa',
  Sorbian: 'sb',
  Sindhi: 'sd',
  Sangro: 'sg',
  'Serbo-Croatian': 'sh',
  Singhalese: 'si',
  Slovak: 'sk',
  Samoan: 'sm',
  Shona: 'sn',
  Somali: 'so',
  Albanian: 'sq',
  Serbian: 'sr',
  Siswati: 'ss',
  Sesotho: 'st',
  Sundanese: 'su',
  Swedish: 'sv',
  'Swedish (Finland)': 'sv-fi',
  Swahili: 'sw',
  Sutu: 'sx',
  Syriac: 'syr',
  Tamil: 'ta',
  Telugu: 'te',
  Tajik: 'tg',
  Thai: 'th',
  Tigrinya: 'ti',
  Turkmen: 'tk',
  Tagalog: 'tl',
  Tswana: 'tn',
  Tonga: 'to',
  Turkish: 'tr',
  Tsonga: 'ts',
  Tatar: 'tt',
  Twi: 'tw',
  Ukrainian: 'uk',
  Urdu: 'ur',
  Uzbek: 'uz',
  Vietnamese: 'vi',
  Volapuk: 'vo',
  Wolof: 'wo',
  Xhosa: 'xh',
  Yiddish: 'yi',
  Yoruba: 'yo',
  Chinese: 'zh',
  Zulu: 'zu',
};

// https://gist.github.com/keeguon/2310008
export const countryCodes = {
  af: { code: 'af', name: 'Afghanistan' },
  ax: { code: 'ax', name: 'Åland Islands' },
  al: { code: 'al', name: 'Albania' },
  dz: { code: 'dz', name: 'Algeria' },
  as: { code: 'as', name: 'American Samoa' },
  ad: { code: 'ad', name: 'AndorrA' },
  ao: { code: 'ao', name: 'Angola' },
  ai: { code: 'ai', name: 'Anguilla' },
  aq: { code: 'aq', name: 'Antarctica' },
  ag: { code: 'ag', name: 'Antigua and Barbuda' },
  ar: { code: 'ar', name: 'Argentina' },
  am: { code: 'am', name: 'Armenia' },
  aw: { code: 'aw', name: 'Aruba' },
  au: { code: 'au', name: 'Australia' },
  at: { code: 'at', name: 'Austria' },
  az: { code: 'az', name: 'Azerbaijan' },
  bs: { code: 'bs', name: 'Bahamas' },
  bh: { code: 'bh', name: 'Bahrain' },
  bd: { code: 'bd', name: 'Bangladesh' },
  bb: { code: 'bb', name: 'Barbados' },
  by: { code: 'by', name: 'Belarus' },
  be: { code: 'be', name: 'Belgium' },
  bz: { code: 'bz', name: 'Belize' },
  bj: { code: 'bj', name: 'Benin' },
  bm: { code: 'bm', name: 'Bermuda' },
  bt: { code: 'bt', name: 'Bhutan' },
  bo: { code: 'bo', name: 'Bolivia' },
  ba: { code: 'ba', name: 'Bosnia and Herzegovina' },
  bw: { code: 'bw', name: 'Botswana' },
  bv: { code: 'bv', name: 'Bouvet Island' },
  br: { code: 'br', name: 'Brazil' },
  io: { code: 'io', name: 'British Indian Ocean Territory' },
  bn: { code: 'bn', name: 'Brunei Darussalam' },
  bg: { code: 'bg', name: 'Bulgaria' },
  bf: { code: 'bf', name: 'Burkina Faso' },
  bi: { code: 'bi', name: 'Burundi' },
  kh: { code: 'kh', name: 'Cambodia' },
  cm: { code: 'cm', name: 'Cameroon' },
  ca: { code: 'ca', name: 'Canada' },
  cv: { code: 'cv', name: 'Cape Verde' },
  ky: { code: 'ky', name: 'Cayman Islands' },
  cf: { code: 'cf', name: 'Central African Republic' },
  td: { code: 'td', name: 'Chad' },
  cl: { code: 'cl', name: 'Chile' },
  cn: { code: 'cn', name: 'China' },
  cx: { code: 'cx', name: 'Christmas Island' },
  cc: { code: 'cc', name: 'Cocos (Keeling) Islands' },
  co: { code: 'co', name: 'Colombia' },
  km: { code: 'km', name: 'Comoros' },
  cg: { code: 'cg', name: 'Congo' },
  cd: { code: 'cd', name: 'Congo, Democratic Republic' },
  ck: { code: 'ck', name: 'Cook Islands' },
  cr: { code: 'cr', name: 'Costa Rica' },
  ci: { code: 'ci', name: 'Cote D"Ivoire' },
  hr: { code: 'hr', name: 'Croatia' },
  cu: { code: 'cu', name: 'Cuba' },
  cy: { code: 'cy', name: 'Cyprus' },
  cz: { code: 'cz', name: 'Czech Republic' },
  dk: { code: 'dk', name: 'Denmark' },
  dj: { code: 'dj', name: 'Djibouti' },
  dm: { code: 'dm', name: 'Dominica' },
  do: { code: 'do', name: 'Dominican Republic' },
  ec: { code: 'ec', name: 'Ecuador' },
  eg: { code: 'eg', name: 'Egypt' },
  sv: { code: 'sv', name: 'El Salvador' },
  gq: { code: 'gq', name: 'Equatorial Guinea' },
  er: { code: 'er', name: 'Eritrea' },
  ee: { code: 'ee', name: 'Estonia' },
  et: { code: 'et', name: 'Ethiopia' },
  fk: { code: 'fk', name: 'Falkland Islands (Malvinas)' },
  fo: { code: 'fo', name: 'Faroe Islands' },
  fj: { code: 'fj', name: 'Fiji' },
  fi: { code: 'fi', name: 'Finland' },
  fr: { code: 'fr', name: 'France' },
  gf: { code: 'gf', name: 'French Guiana' },
  pf: { code: 'pf', name: 'French Polynesia' },
  tf: { code: 'tf', name: 'French Southern Territories' },
  ga: { code: 'ga', name: 'Gabon' },
  gm: { code: 'gm', name: 'Gambia' },
  ge: { code: 'ge', name: 'Georgia' },
  de: { code: 'de', name: 'Germany' },
  gh: { code: 'gh', name: 'Ghana' },
  gi: { code: 'gi', name: 'Gibraltar' },
  gr: { code: 'gr', name: 'Greece' },
  gl: { code: 'gl', name: 'Greenland' },
  gd: { code: 'gd', name: 'Grenada' },
  gp: { code: 'gp', name: 'Guadeloupe' },
  gu: { code: 'gu', name: 'Guam' },
  gt: { code: 'gt', name: 'Guatemala' },
  gg: { code: 'gg', name: 'Guernsey' },
  gn: { code: 'gn', name: 'Guinea' },
  gw: { code: 'gw', name: 'Guinea-Bissau' },
  gy: { code: 'gy', name: 'Guyana' },
  ht: { code: 'ht', name: 'Haiti' },
  hm: { code: 'hm', name: 'Heard Island and Mcdonald Islands' },
  va: { code: 'va', name: 'Holy See (Vatican City State)' },
  hn: { code: 'hn', name: 'Honduras' },
  hk: { code: 'hk', name: 'Hong Kong' },
  hu: { code: 'hu', name: 'Hungary' },
  is: { code: 'is', name: 'Iceland' },
  in: { code: 'in', name: 'India' },
  id: { code: 'id', name: 'Indonesia' },
  ir: { code: 'ir', name: 'Iran' },
  iq: { code: 'iq', name: 'Iraq' },
  ie: { code: 'ie', name: 'Ireland' },
  im: { code: 'im', name: 'Isle of Man' },
  il: { code: 'il', name: 'Israel' },
  it: { code: 'it', name: 'Italy' },
  jm: { code: 'jm', name: 'Jamaica' },
  jp: { code: 'jp', name: 'Japan' },
  je: { code: 'je', name: 'Jersey' },
  jo: { code: 'jo', name: 'Jordan' },
  kz: { code: 'kz', name: 'Kazakhstan' },
  ke: { code: 'ke', name: 'Kenya' },
  ki: { code: 'ki', name: 'Kiribati' },
  kp: { code: 'kp', name: 'Korea (North)' },
  kr: { code: 'kr', name: 'Korea (South)' },
  xk: { code: 'xk', name: 'Kosovo' },
  kw: { code: 'kw', name: 'Kuwait' },
  kg: { code: 'kg', name: 'Kyrgyzstan' },
  la: { code: 'la', name: 'Laos' },
  lv: { code: 'lv', name: 'Latvia' },
  lb: { code: 'lb', name: 'Lebanon' },
  ls: { code: 'ls', name: 'Lesotho' },
  lr: { code: 'lr', name: 'Liberia' },
  ly: { code: 'ly', name: 'Libyan Arab Jamahiriya' },
  li: { code: 'li', name: 'Liechtenstein' },
  lt: { code: 'lt', name: 'Lithuania' },
  lu: { code: 'lu', name: 'Luxembourg' },
  mo: { code: 'mo', name: 'Macao' },
  mk: { code: 'mk', name: 'Macedonia' },
  mg: { code: 'mg', name: 'Madagascar' },
  mw: { code: 'mw', name: 'Malawi' },
  my: { code: 'my', name: 'Malaysia' },
  mv: { code: 'mv', name: 'Maldives' },
  ml: { code: 'ml', name: 'Mali' },
  mt: { code: 'mt', name: 'Malta' },
  mh: { code: 'mh', name: 'Marshall Islands' },
  mq: { code: 'mq', name: 'Martinique' },
  mr: { code: 'mr', name: 'Mauritania' },
  mu: { code: 'mu', name: 'Mauritius' },
  yt: { code: 'yt', name: 'Mayotte' },
  mx: { code: 'mx', name: 'Mexico' },
  fm: { code: 'fm', name: 'Micronesia' },
  md: { code: 'md', name: 'Moldova' },
  mc: { code: 'mc', name: 'Monaco' },
  mn: { code: 'mn', name: 'Mongolia' },
  ms: { code: 'ms', name: 'Montserrat' },
  ma: { code: 'ma', name: 'Morocco' },
  mz: { code: 'mz', name: 'Mozambique' },
  mm: { code: 'mm', name: 'Myanmar' },
  na: { code: 'na', name: 'Namibia' },
  nr: { code: 'nr', name: 'Nauru' },
  np: { code: 'np', name: 'Nepal' },
  nl: { code: 'nl', name: 'Netherlands' },
  an: { code: 'an', name: 'Netherlands Antilles' },
  nc: { code: 'nc', name: 'New Caledonia' },
  nz: { code: 'nz', name: 'New Zealand' },
  ni: { code: 'ni', name: 'Nicaragua' },
  ne: { code: 'ne', name: 'Niger' },
  ng: { code: 'ng', name: 'Nigeria' },
  nu: { code: 'nu', name: 'Niue' },
  nf: { code: 'nf', name: 'Norfolk Island' },
  mp: { code: 'mp', name: 'Northern Mariana Islands' },
  no: { code: 'no', name: 'Norway' },
  om: { code: 'om', name: 'Oman' },
  pk: { code: 'pk', name: 'Pakistan' },
  pw: { code: 'pw', name: 'Palau' },
  ps: { code: 'ps', name: 'Palestinian Territory, Occupied' },
  pa: { code: 'pa', name: 'Panama' },
  pg: { code: 'pg', name: 'Papua New Guinea' },
  py: { code: 'py', name: 'Paraguay' },
  pe: { code: 'pe', name: 'Peru' },
  ph: { code: 'ph', name: 'Philippines' },
  pn: { code: 'pn', name: 'Pitcairn' },
  pl: { code: 'pl', name: 'Poland' },
  pt: { code: 'pt', name: 'Portugal' },
  pr: { code: 'pr', name: 'Puerto Rico' },
  qa: { code: 'qa', name: 'Qatar' },
  re: { code: 're', name: 'Reunion' },
  ro: { code: 'ro', name: 'Romania' },
  ru: { code: 'ru', name: 'Russian Federation' },
  rw: { code: 'rw', name: 'Rwanda' },
  sh: { code: 'sh', name: 'Saint Helena' },
  kn: { code: 'kn', name: 'Saint Kitts and Nevis' },
  lc: { code: 'lc', name: 'Saint Lucia' },
  pm: { code: 'pm', name: 'Saint Pierre and Miquelon' },
  vc: { code: 'vc', name: 'Saint Vincent and the Grenadines' },
  ws: { code: 'ws', name: 'Samoa' },
  sm: { code: 'sm', name: 'San Marino' },
  st: { code: 'st', name: 'Sao Tome and Principe' },
  sa: { code: 'sa', name: 'Saudi Arabia' },
  sn: { code: 'sn', name: 'Senegal' },
  rs: { code: 'rs', name: 'Serbia' },
  me: { code: 'me', name: 'Montenegro' },
  sc: { code: 'sc', name: 'Seychelles' },
  sl: { code: 'sl', name: 'Sierra Leone' },
  sg: { code: 'sg', name: 'Singapore' },
  sk: { code: 'sk', name: 'Slovakia' },
  si: { code: 'si', name: 'Slovenia' },
  sb: { code: 'sb', name: 'Solomon Islands' },
  so: { code: 'so', name: 'Somalia' },
  za: { code: 'za', name: 'South Africa' },
  gs: { code: 'gs', name: 'South Georgia and the South Sandwich Islands' },
  es: { code: 'es', name: 'Spain' },
  lk: { code: 'lk', name: 'Sri Lanka' },
  sd: { code: 'sd', name: 'Sudan' },
  sr: { code: 'sr', name: 'Suriname' },
  sj: { code: 'sj', name: 'Svalbard and Jan Mayen' },
  sz: { code: 'sz', name: 'Swaziland' },
  se: { code: 'se', name: 'Sweden' },
  ch: { code: 'ch', name: 'Switzerland' },
  sy: { code: 'sy', name: 'Syrian Arab Republic' },
  tw: { code: 'tw', name: 'Taiwan, Province of China' },
  tj: { code: 'tj', name: 'Tajikistan' },
  tz: { code: 'tz', name: 'Tanzania' },
  th: { code: 'th', name: 'Thailand' },
  tl: { code: 'tl', name: 'Timor-Leste' },
  tg: { code: 'tg', name: 'Togo' },
  tk: { code: 'tk', name: 'Tokelau' },
  to: { code: 'to', name: 'Tonga' },
  tt: { code: 'tt', name: 'Trinidad and Tobago' },
  tn: { code: 'tn', name: 'Tunisia' },
  tr: { code: 'tr', name: 'Turkey' },
  tm: { code: 'tm', name: 'Turkmenistan' },
  tc: { code: 'tc', name: 'Turks and Caicos Islands' },
  tv: { code: 'tv', name: 'Tuvalu' },
  ug: { code: 'ug', name: 'Uganda' },
  ua: { code: 'ua', name: 'Ukraine' },
  ae: { code: 'ae', name: 'United Arab Emirates' },
  gb: { code: 'gb', name: 'United Kingdom' },
  us: { code: 'us', name: 'United States' },
  um: { code: 'um', name: 'United States Minor Outlying Islands' },
  uy: { code: 'uy', name: 'Uruguay' },
  uz: { code: 'uz', name: 'Uzbekistan' },
  vu: { code: 'vu', name: 'Vanuatu' },
  ve: { code: 've', name: 'Venezuela' },
  vn: { code: 'vn', name: 'Viet Nam' },
  vg: { code: 'vg', name: 'Virgin Islands, British' },
  vi: { code: 'vi', name: 'Virgin Islands, U.S.' },
  wf: { code: 'wf', name: 'Wallis and Futuna' },
  eh: { code: 'eh', name: 'Western Sahara' },
  ye: { code: 'ye', name: 'Yemen' },
  zm: { code: 'zm', name: 'Zambia' },
  zw: { code: 'zw', name: 'Zimbabwe' },
};

export const languageAndFlagByCountryCode: Record<
  string,
  { flag: string; name: string; display_only?: true }
> = {
  en: { flag: `🇺🇸`, name: 'English' },
  el: { flag: `🇬🇷`, name: 'Greek' },
  gr: { flag: `🇬🇷`, name: 'Greek', display_only: true },
  me: { flag: `🇲🇪`, name: 'Montenegrin' },
  es: { flag: `🇪🇸`, name: 'Spanish' },
  'pt-br': { flag: `🇧🇷`, name: 'Brazilian Portuguese' },
  it: { flag: `🇮🇹`, name: 'Italian' },
  fr: { flag: `🇫🇷`, name: 'French' },
  da: { flag: `🇩🇰`, name: 'Danish' },
  de: { flag: `🇩🇪`, name: 'German' },
  ro: { flag: `🇷🇴`, name: 'Romanian' },
  bg: { flag: `🇧🇬`, name: 'Bulgarian' },
  cs: { flag: `🇨🇿`, name: 'Czech' },
  pl: { flag: `🇵🇱`, name: 'Polish' },
  id: { flag: `🇮🇩`, name: 'Indonesian' },
  nl: { flag: '🇳🇱', name: 'Dutch' },
  sr: { flag: '🇷🇸', name: 'Serbian' },
  pt: { flag: `🇵🇹`, name: 'Portuguese' },
  sv: { flag: '🇸🇪', name: 'Swedish' },
  ru: { flag: '🇷🇺', name: 'Russian' },
  sq: { flag: '🇦🇱', name: 'Albanian' },
  ar: { flag: '🇦🇪', name: 'Arabic' },
  ja: { flag: '🇯🇵', name: 'Japanese' },
  tr: { flag: '🇹🇷', name: 'Turkish' },
  zh: { flag: '🇨🇳', name: 'Chinese' },
};
