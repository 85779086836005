import { memo } from 'react';

import Typography from '@mui/material/Typography/Typography';
import { Trans, useTranslation } from 'react-i18next';

import { Welcome } from '@/components/pages/LoginSignup/icons/Welcome';

import styles from './RIghtContainer.module.scss';

interface props {
  variant: 'regular' | 'welcome';
  showBranding?: boolean;
}

const IconComponent = memo(() => {
  return (
    <img
      src="/assets/memberInvitation/curves.svg"
      alt="Curves"
      className={styles.svg}
    />
  );
});
IconComponent.displayName = 'IconComponent';

const RightContainer = ({
  variant = 'regular',
  showBranding = false,
}: props) => {
  const { t } = useTranslation();

  if (variant === 'welcome') {
    return (
      <div className={styles.welcome}>
        <Welcome />
      </div>
    );
  }
  return (
    <>
      <IconComponent />
      {showBranding && (
        <>
          <div className={styles.text}>
            <Typography component="h2" textAlign="center">
              <Typography
                variant="h1-bold"
                fontWeight={600}
                fontSize={36}
                color="var(--text-default-black)"
              >
                {t('login.title.embrace')}{' '}
              </Typography>
              <Typography
                variant="h1-bold"
                color="var(--text-default-blue)"
                fontWeight={900}
                fontSize={36}
              >
                {t('login.title.automation')}
              </Typography>
              <br />

              <Typography
                variant="h1-bold"
                color="var(--text-default-blue)"
                fontWeight={900}
                fontSize={36}
              >
                {t('login.title.empower')}{' '}
              </Typography>
              <Typography
                variant="h1-bold"
                fontWeight={600}
                fontSize={36}
                color="var(--text-default-black)"
              >
                {t('login.title.customers')}
              </Typography>
            </Typography>

            <Typography
              component="h3"
              variant="h2-regular"
              color="var(--text-default-gray)"
              textAlign="center"
            >
              <Trans i18nKey="login.subtitle" />
            </Typography>
          </div>

          <section className={styles.trusted}>
            <Typography
              color="rgba(10, 52, 81, 0.4)"
              variant="label-caps-large"
              component="h3"
            >
              {t('login.trusted_by')}
            </Typography>

            <img
              src="/assets/brands/viva-avis-betano.svg"
              alt={t('login.trusted_brands.row_one')}
              className={styles.trusted__rowOne}
            />
            <img
              src="/assets/brands/edenred-skroutz-stoiximan.svg"
              alt={t('login.trusted_brands.row_two')}
              className={styles.trusted__rowOne}
            />
          </section>
        </>
      )}
    </>
  );
};
export default RightContainer;
