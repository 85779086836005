const WebPreview = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="48"
      fill="none"
      viewBox="0 0 59 48"
    >
      <rect width="59" height="48" fill="#8FADDA" rx="4" />
      <g filter="url(#a)">
        <rect width="34" height="34" x="13" y="7" fill="#fff" rx="8" />
        <path
          fill="#D9D9D9"
          d="M20.003 35.465c-.129.828 4.243 1.515 9.764 1.535 5.649.02 10.362-.688 10.23-1.535-.128-.828-4.708-1.484-10.23-1.465-5.39.02-9.639.656-9.764 1.465Z"
        />
        <path
          fill="#EFEFEF"
          stroke="#1B66D6"
          strokeWidth=".5"
          d="M20.003 21.767c-.129 5.522 4.243 10.102 9.764 10.23 5.649.131 10.361-4.581 10.23-10.23-.128-5.52-4.708-9.893-10.23-9.764-5.391.125-9.639 4.373-9.764 9.764Z"
        />
        <path
          stroke="#1B66D6"
          strokeWidth=".5"
          d="M20 22.23h19.994M30.23 31.994s-7.44-.93-7.44-10.23c0-8.382 7.44-9.764 7.44-9.764m0 19.994s7.439-1.395 7.439-10.23c0-8.846-7.44-9.764-7.44-9.764m0 19.994s-3.72-1.86-3.72-10.23c0-8.369 3.72-9.764 3.72-9.764m0 19.994s3.72-2.325 3.72-10.23c0-8.369-3.72-9.764-3.72-9.764m-8.37 15.81s2.79-2.325 8.37-2.325c5.308 0 7.905 2.325 7.905 2.325"
        />
        <path
          stroke="#1B66D6"
          strokeWidth=".5"
          d="M21.86 16.185s3.72 2.325 8.37 2.325c5.579 0 7.904-2.325 7.904-2.325"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="36"
          height="38"
          x="12"
          y="6"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1021_46124"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend
            in2="effect1_dropShadow_1021_46124"
            result="effect2_dropShadow_1021_46124"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1021_46124"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default WebPreview;
