import { Icon } from '@/models/icon';

const Assist = ({ size = 32, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#assisticon)">
        <path
          fill={color}
          d="M17.795 8.13h-6.548c-.427 0-.821.07-1.175.208a3.064 3.064 0 0 0-.977.623 2.847 2.847 0 0 0-.666.915c-.148.331-.221.7-.221 1.1v5.984c0 .4.073.769.221 1.1.148.33.37.638.666.915.304.277.632.492.986.622.353.139.74.208 1.166.208h2.81c.329 0 .6.254.6.561a.73.73 0 0 1-.033.193c-.107.37-.222.738-.353 1.092-.14.377-.288.73-.452 1.077-.082.177-.18.346-.288.523a11.584 11.584 0 0 0 1.619-.823 11.136 11.136 0 0 0 1.47-1.077c.46-.4.871-.838 1.249-1.307a.606.606 0 0 1 .477-.223h2.415c.427 0 .813-.07 1.166-.208a3.19 3.19 0 0 0 .986-.623c.296-.277.518-.584.666-.915.148-.33.222-.7.222-1.1v-5.983c0-.4-.074-.77-.222-1.1a2.847 2.847 0 0 0-.666-.915 3.054 3.054 0 0 0-.977-.623 3.205 3.205 0 0 0-1.175-.208h-2.966v-.015Zm-1.14 7.027h-1.56V15c0-.269.029-.488.09-.655.06-.17.15-.322.269-.46.119-.14.387-.384.803-.734.222-.183.333-.35.333-.503a.471.471 0 0 0-.133-.357c-.09-.084-.225-.126-.406-.126a.644.644 0 0 0-.482.196c-.128.13-.209.358-.244.682l-1.595-.2c.055-.594.268-1.072.638-1.434.372-.362.941-.543 1.708-.543.598 0 1.079.127 1.446.378.498.342.748.797.748 1.365 0 .236-.064.465-.194.684-.127.219-.391.487-.788.803-.276.223-.452.4-.524.535a1.12 1.12 0 0 0-.108.527Zm-1.615.42h1.673v1.25H15.04v-1.25Zm-.827-8.57h6.548c.575 0 1.117.1 1.627.293a4.21 4.21 0 0 1 1.372.87c.41.384.723.814.928 1.283.206.47.312.977.312 1.523v5.984a3.76 3.76 0 0 1-.312 1.522c-.205.47-.517.9-.928 1.285-.411.384-.871.669-1.372.869a4.503 4.503 0 0 1-1.627.292h-2.12c-.37.438-.772.854-1.199 1.23a11.64 11.64 0 0 1-1.635 1.2c-.583.362-1.208.677-1.873.946-.657.27-1.364.492-2.103.677a.623.623 0 0 1-.6-.17.536.536 0 0 1 .05-.791c.344-.285.64-.577.878-.87.23-.284.42-.576.559-.876v-.008c.156-.323.296-.654.419-.984.041-.116.082-.239.123-.362h-2.02c-.576 0-1.118-.1-1.628-.292a4.212 4.212 0 0 1-1.372-.87 3.915 3.915 0 0 1-.928-1.284A3.81 3.81 0 0 1 7 16.953v-5.984c0-.538.107-1.046.312-1.522.206-.47.518-.9.929-1.285.41-.384.87-.677 1.372-.869A4.503 4.503 0 0 1 11.239 7h2.966l.008.008Z"
        />
      </g>
      <defs>
        <clipPath id="assisticon">
          <path fill={color} d="M7 7h18v18H7z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Assist;
