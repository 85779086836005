import { Icon } from '@/models/icon';

const TickSmall = ({ size = 12, color = '#6E6E6E' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m4.5 6 1 1 2-2m3 1a4.5 4.5 0 1 1-9.002 0A4.5 4.5 0 0 1 10.5 6Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default TickSmall;
