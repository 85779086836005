import { InputHTMLAttributes } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import {
  Control,
  Controller,
  Path,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';

import HelpTooltip from '../HelpTooltip/HelpTooltip';

import styles from './Input.module.scss';
import 'react-phone-input-2/lib/style.css';

export interface PhoneInputProps<FormType extends FieldValues>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'name'> {
  label?: string;
  error?: boolean;
  control: Control<FormType>;
  name: Path<FormType>;
  tooltip?: string;
  readOnly?: boolean;
  ellipsis?: boolean;
  errorMessage?: React.ReactNode | string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  isLoading?: boolean;
  trimValue?: boolean;
  rules?: UseControllerProps<FormType>['rules'];
}

const PhoneInput = <FormType extends FieldValues>({
  name,
  control,
  label,
  id = `${name}-phone-input`,
  size = 'medium',
  error,
  tooltip,
  errorMessage,
  isLoading,
  rules,
  ...props
}: PhoneInputProps<FormType>) => {
  const hasError = error || !!errorMessage;

  return (
    <div
      className={cn(styles.input, styles[size], styles.input_phone, {
        [styles.withLabel]: label,
      })}
    >
      {label && (
        <label
          className={cn({
            [styles.input__label]: true,
            [styles.input__label__disabled]: props.disabled,
          })}
          htmlFor={id}
        >
          {label}
          {props.required && '*'}
          <HelpTooltip tooltip={tooltip} />
        </label>
      )}
      {!isLoading ? (
        <Controller<FormType>
          name={name}
          control={control}
          rules={rules}
          render={({ field: { value, onChange, name, ref } }) => (
            <ReactPhoneInput
              value={value as string}
              country={'us'}
              onChange={onChange}
              disableDropdown={props.readOnly}
              enableLongNumbers
              inputProps={{
                ...props,
                ref: ref,
                name: name,
                className: cn({
                  [styles.input__input]: true,
                  [styles.input__input__phone]: true,
                  [styles.input__readOnly]: props.readOnly,
                  [styles.input__readOnly__ellipsis]:
                    props.readOnly && props.ellipsis,
                  [styles.input__input__error]: hasError,
                  [styles[size]]: size,
                  [styles.withLabel]: label,
                }),
              }}
            />
          )}
        />
      ) : (
        <Skeleton variant="text" height={40} />
      )}
      {errorMessage && (
        <p className={styles.input__input__error__message}>{errorMessage}</p>
      )}
    </div>
  );
};

export default PhoneInput;
