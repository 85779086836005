import { useContext, useEffect, useCallback } from 'react';

import { SocketContext } from '@/contexts/rtm';
import useUser from '@/hooks/useUser';
import { SocketEvent } from '@/models/rtm';

import { useRtm } from './useRtm';

export const useRtmCollection = (collection_id: string) => {
  const { socket, isConnected } = useContext(SocketContext);
  const { create, update, remove } = useRtm();
  const { user } = useUser();
  const userId = user?.user_id;

  const handleDatasourceCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { collection_id, datasource_id } = data;
        create('datasources', collection_id, datasource_id);
      }
    },
    [create, userId]
  );

  const handleDatasourceUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { collection_id, datasource_id } = data;
        update('datasources', collection_id, datasource_id);
      }
    },
    [userId, update]
  );

  const handleDatasourceRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { collection_id, datasource_id } = data;
        remove('datasources', collection_id, datasource_id);
      }
    },
    [userId, remove]
  );

  useEffect(() => {
    socket?.on(SocketEvent.datasources_created, handleDatasourceCreated);
    return () => {
      socket?.off(SocketEvent.datasources_created, handleDatasourceCreated);
    };
  }, [handleDatasourceCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.datasources_updated, handleDatasourceUpdated);
    return () => {
      socket?.off(SocketEvent.datasources_updated, handleDatasourceUpdated);
    };
  }, [handleDatasourceUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.datasources_removed, handleDatasourceRemoved);
    return () => {
      socket?.off(SocketEvent.datasources_removed, handleDatasourceRemoved);
    };
  }, [handleDatasourceRemoved, socket]);

  useEffect(() => {
    if (!socket || !collection_id || !isConnected) {
      return;
    }
    socket.emit(SocketEvent.subscribe_collection, { collection_id });
    return () => {
      socket.emit(SocketEvent.unsubscribe_collection, { collection_id });
    };
  }, [isConnected, collection_id, socket]);
};
