import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { callGet } from '@/api/fetcher';
import { Integration, IntercomIntegration } from '@/models/integration';
import {
  addTemporalToast,
  safelyGetErrorMessage,
} from '@/modules/notifications/redux/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { INTERCOM_AUTHORIZATION_URL } from '@/util/constants';

import { useIntegrations } from './useIntegrations';

const createCallbackURL = () => {
  return `${window?.location?.origin}/www/api/integrations/intercom/callback`;
};

export const createOAuthURL = async (
  slug: string,
  integration?: Integration,
  collection_id?: string,
  datasource_name?: string,
  is_new_onboarding?: boolean,
  lang?: string,
  brain_id?: string
) => {
  const { desk_id, integration_id } = integration ?? {};

  const client_id = await callGet(
    `/www/api/integrations/intercom/client-id/${
      collection_id ? 'collection' : 'integration'
    }`
  );
  const callbackUrl = createCallbackURL();
  const queryParams = new URLSearchParams({
    redirect_uri: callbackUrl,
    client_id,
    state: JSON.stringify({
      desk_id,
      integration_id,
      slug,
      collection_id,
      datasource_name,
      is_new_onboarding,
      lang,
      brain_id,
    }),
  });

  return `${INTERCOM_AUTHORIZATION_URL}?${queryParams}`;
};

export const useIntercom = () => {
  const { t } = useTranslation();
  const { deskId, integrationId } = useParams();
  const slug = useSelector(selectAccountSlug);
  const dispatch = useDispatch();
  const loc = useLocation();

  const { integration, updateStatus, updateIntegration } =
    useIntegrations<IntercomIntegration>(deskId, integrationId);

  const isActive = integration?.active;

  const isUpdated = updateStatus === 'success';
  const isUpdating = updateStatus === 'pending';
  const error = updateStatus === 'error';

  const getIsConnected = useCallback(() => {
    return integration?.config?.access_token != null;
  }, [integration?.config?.access_token]);

  const [isConnected, setIsConnected] = useState(getIsConnected());

  useEffect(() => {
    if (error) {
      dispatch(addTemporalToast('error', safelyGetErrorMessage(error)));
    }
  }, [dispatch, error]);

  const updateIntercomIntegration = useCallback(
    (i: IntercomIntegration) => {
      updateIntegration(i);
    },
    [updateIntegration]
  );

  const handleOAuthClick = useCallback(async () => {
    const intercomUrl = await createOAuthURL(slug, integration);
    window.open(intercomUrl, '_self');
  }, [slug, integration]);

  useEffect(() => {
    setIsConnected(getIsConnected());
  }, [integration, getIsConnected]);

  useEffect(() => {
    const oauthError = new URLSearchParams(loc.search).get('error');
    if (oauthError) {
      dispatch(
        addTemporalToast('error', t('integrations.intercom.errors.generic'))
      );
    }
  }, [dispatch, loc.search, t]);

  return {
    integration,
    isActive,
    isUpdating,
    isUpdated,
    isConnected,
    updateIntercomIntegration,
    handleOAuthClick,
  };
};
