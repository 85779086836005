import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router';

import { AccessHeader } from '@/components/organisms/Headers/AccessHeader';
import { SettingsNavigation } from '@/components/organisms/Navbar/SettingsNavigation/SettingsNavigation';
import { ManageAccessMembers } from '@/components/pages/ManageAccess/ManageAccessMembers';
import NotFound from '@/components/pages/NotFound/NotFound';
import { Teams } from '@/components/pages/Teams/Teams';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

const AccountSettingsRouter: React.FC = () => {
  const canAssign = useSelector((state: RootState) =>
    getPermissions(state, 'roles', actions.ASSIGN)
  );

  const isReadOnly = !canAssign;
  return (
    <Layout withSidebar>
      <SettingsNavigation />
      <AccessHeader />

      <PageContentWrapper
        fullHeight={!isReadOnly}
        newPlain2={isReadOnly}
        readOnly={isReadOnly}
      >
        <Routes>
          <Route path="/" element={<Navigate to="member-access" replace />} />
          <Route path="/member-access" element={<ManageAccessMembers />} />
          <Route path="/team-access" element={<Teams />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </PageContentWrapper>
    </Layout>
  );
};

export default AccountSettingsRouter;
