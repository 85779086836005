import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import IconClose from '@/components/atoms/Icons/Close';
import { useTemplates } from '@/hooks/useTemplates';
import { BrainLanguage } from '@/models/brain';
import { BreadcrumpIcon } from '@/modules/onboarding/icons/BreadcrumpIcon';
import Separator from '@/modules/onboarding/icons/Separator';
import { popModal } from '@/redux/modals/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { aiAgentSchemas } from '@/util/validator';

import { Step1 } from './Step1';
import { Step2 } from './Step2';

import styles from './ModalAIAgentCreate.module.scss';

type BreadcrumbState = 'current' | 'not-reached' | 'completed';
type Step1Form = {
  brain_type: string;
};
type Step2Form = {
  name: string;
  language: BrainLanguage;
  provider: string;
  language_model_id: string;
};

export type Form = Step1Form & Partial<Step2Form>;

// Constants
const BASIC_STEPS = [
  { component: Step1, validationSchema: aiAgentSchemas.step1Schema },
  {
    component: Step2,
    validationSchema: aiAgentSchemas.step2Schema,
  },
] as const;

export const ModalAIAgentCreate = () => {
  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createBrainFromTemplateAsync } = useTemplates();

  // Redux selectors
  const slug = useSelector(selectAccountSlug);

  // Local state
  const [step, setStep] = useState(1);

  // Local variables
  const BREADCRUMB_TEXTS = [
    t('ai_agents.modal.steps.type'),
    t('ai_agents.modal.steps.setup'),
  ] as const;
  const isLastStep = step === 2;
  const currentSchema = BASIC_STEPS[step - 1]
    .validationSchema as yup.ObjectSchema<Form>;
  const CurrentStepComponent = BASIC_STEPS[step - 1].component;

  // console.log({ step, isLastStep });
  // RHF
  const methods = useForm<Form>({
    resolver: yupResolver(currentSchema) as Resolver<Form>,
    mode: 'onChange',
  });
  const {
    handleSubmit,
    trigger,
    formState: { isSubmitting, isValid },
    getValues,
  } = methods;

  // Handlers
  const increaseStep = async () => {
    if (step === 2) {
      return;
    }
    const isStepValid = await trigger();
    if (isStepValid) {
      setStep((prev) => prev + 1);
    }
  };

  const decreaseStep = async () => {
    if (step === 1) {
      return;
    }

    setStep((prev) => prev - 1);
  };

  const closeModal = () => {
    dispatch(popModal());
  };

  const onSubmit = async (data: Form) => {
    // Remove the default language model if the value is 'Default'
    if (data.language_model_id === 'Default') {
      data.language_model_id = undefined;
    }

    await createBrainFromTemplateAsync(
      { ...data, brain_id: 'playground' },
      {
        onSuccess: (brain) => {
          dispatch(popModal());
          navigate(`/${slug}/ai-agents/${brain.brain_id}`);
        },
      }
    );
  };

  // Helpers
  const getBreadcrumbState = (breadCrumbIndex: number) => {
    if (breadCrumbIndex + 1 === step) {
      return 'current';
    } else if (breadCrumbIndex <= step - 1) {
      return 'completed';
    } else {
      return 'not-reached';
    }
  };

  const getStateConfig = (state: BreadcrumbState) => {
    const configMap = {
      current: {
        textColor: 'var(--icon-default-blue)',
        fill: '',
        stroke: 'var(--icon-default-blue)',
      },
      completed: {
        textColor: 'var(--text-default-gray)',
        fill: 'var(--text-default-gray)',
        stroke: 'var(--text-default-gray)',
      },
      'not-reached': {
        textColor: 'var(--text-placeholder-gray)',
        fill: 'var(--text-placeholder-gray)',
        stroke: 'var(--text-placeholder-gray)',
      },
    };

    return configMap[state];
  };

  return (
    <Modal
      aria-labelledby="create-ai-agent-modal-title"
      aria-describedby="create-ai-agent-modal-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={true}>
        <div className={styles.modal}>
          <IconButton
            className={styles.modal__closeIcon}
            onClick={closeModal}
            ariaLabel={t('modals.close')}
          >
            <IconClose color="var(--icon-default-white)" size={20} />
          </IconButton>

          <section className={styles.modal__content}>
            <header className={styles.modal__header}>
              <Typography
                variant="h2-semi-bold"
                component="h1"
                id="create-ai-agent-modal-title"
              >
                {t('ai_agents.modal.title')}
              </Typography>

              <Breadcrumbs
                className={styles.breadcrumbs}
                separator={<Separator />}
              >
                {BREADCRUMB_TEXTS.map((breadcrumb, index) => {
                  const config = getStateConfig(getBreadcrumbState(index));

                  return (
                    <div key={breadcrumb} className={styles.breadcrumbs__item}>
                      <BreadcrumpIcon
                        fill={config.fill}
                        stroke={config.stroke}
                      />
                      <Typography
                        color={config.textColor}
                        variant="label-caps-large"
                      >
                        {breadcrumb}
                      </Typography>
                    </div>
                  );
                })}
              </Breadcrumbs>
            </header>

            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="create-ai-agent-modal-description"
              >
                <div className={styles.modal__step}>
                  <CurrentStepComponent />
                </div>

                <footer className={styles.modal__footer}>
                  <div className={styles.modal__buttons}>
                    {step === 2 && (
                      <Button
                        onClick={decreaseStep}
                        variant="tertiary"
                        type="button"
                        size="medium"
                      >
                        {t('common.back')}
                      </Button>
                    )}

                    <Button
                      onClick={() => {
                        closeModal();
                      }}
                      variant="tertiary"
                      type="button"
                      size="large"
                    >
                      {t('common.cancel')}
                    </Button>

                    <Button
                      onClick={() => {
                        if (!isLastStep) {
                          increaseStep();
                        }
                      }}
                      disabled={!isValid}
                      isLoading={isSubmitting}
                      size="large"
                      type={isLastStep ? 'submit' : 'button'}
                    >
                      {isLastStep ? t('common.create') : t('common.continue')}
                    </Button>
                  </div>
                </footer>
              </form>
            </FormProvider>
          </section>

          <aside className={styles.modal__preview}>
            <img
              src={`/assets/aiAgents/modal/${getValues('brain_type') ?? 'default'}.png`}
              width="400"
              height="650"
              alt=""
            />
          </aside>
        </div>
      </Fade>
    </Modal>
  );
};
