import { useCallback, useMemo, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';

import styles from './DropdownList.module.scss';

interface Props {
  setOption: (data: string) => void;
  option: string;
  options: { value: string; label: string }[];
  translationPrefix: string;
  label: string;
  placeholderAsValue?: string;
  placeholder?: string;
  uppercaseLabel?: boolean;
}

const Dropdown = ({
  setOption,
  option,
  options,
  translationPrefix,
  label,
  placeholderAsValue,
  placeholder,
  uppercaseLabel = false,
}: Props) => {
  const { t } = useTranslation();
  const [hasClickedOption, setHasClickedOption] = useState(false);
  const handleOptionClick = useCallback(
    (data) => {
      setHasClickedOption(true);
      const { value, label } = data;
      if (label === placeholderAsValue) {
        setOption('');
      } else {
        setOption(value);
      }
    },
    [placeholderAsValue, setOption]
  );

  const newOptions = useMemo(
    () =>
      placeholderAsValue
        ? [
            {
              value: placeholderAsValue,
              label: placeholderAsValue,
            },
            ...options,
          ]
        : options,
    [options, placeholderAsValue]
  );

  const text = useCallback(() => {
    if (hasClickedOption) {
      if (option === '') {
        return placeholderAsValue;
      }
      return option;
    } else {
      return placeholder ? placeholder : newOptions[0]?.value;
    }
  }, [hasClickedOption, newOptions, option, placeholder, placeholderAsValue]);

  return (
    <div className={styles.labelWrapper}>
      <span
        className={cn({
          [styles.label]: true,
          [styles.uppercased]: uppercaseLabel,
        })}
      >
        {t(`${translationPrefix}${label}`)}
      </span>
      <DropdownList
        optionClick={handleOptionClick}
        options={newOptions}
        size="medium"
        height="large"
        selected={text()}
        renderValue
        translationPrefix={translationPrefix}
        noGutter
        fullWidth
        optionsFullWidth
        doubleBorder
        isEmpty={option === ''}
      >
        {t(`${translationPrefix}${text()}`)}
      </DropdownList>
    </div>
  );
};

export default Dropdown;
