import { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';

import styles from './ModalTemplateMessage.module.scss';

interface Props<FormType> {
  headerVariablesCount: number;
  bodyVariablesCount: number;
  register: UseFormRegister<FormType>;
  errors: FieldErrors<FormType>;
}
export const TemplateVariables = <FormType,>({
  headerVariablesCount,
  bodyVariablesCount,
  register,
  errors,
}: Props<FormType>) => {
  const { t } = useTranslation();
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [headerVariablesCount, bodyVariablesCount]);

  return (
    <Box my={2}>
      <Typography component="p" variant="label-semi-bold">
        {t('try_it.add_variables')}
      </Typography>
      <div className={styles.buttonsContainer}>
        {Array(headerVariablesCount)
          .fill('')
          .map((_, i) => {
            const varPosition = `{{${i + 1}}}`;
            return (
              <div
                key={varPosition}
                className={cn(styles.buttonContainer, styles.withPrefix)}
              >
                <Input readOnly value={varPosition} tabIndex={-1} />
                <Input
                  error={Boolean(errors['headerParameters']?.[i]?.name)}
                  id={`headerParameters.${i}.name`}
                  {...register(`headerParameters.${i}.name` as Path<FormType>, {
                    required: t('validation.required'),
                  })}
                  ref={(e) => {
                    register(
                      `headerParameters.${i}.name` as Path<FormType>
                    ).ref(e); // set ref from register
                    if (i === 0) {
                      firstInputRef.current = e; // keep the local ref for custom use
                    }
                  }}
                />
              </div>
            );
          })}
        {Array(bodyVariablesCount)
          .fill('')
          .map((_, i) => {
            const varPosition = `{{${headerVariablesCount + i + 1}}}`;
            return (
              <div
                key={varPosition}
                className={cn(styles.buttonContainer, styles.withPrefix)}
              >
                <Input readOnly value={varPosition} tabIndex={-1} />
                <Input
                  {...register(`bodyParameters.${i}.name` as Path<FormType>, {
                    required: t('validation.required'),
                  })}
                  ref={(e) => {
                    register(`bodyParameters.${i}.name` as Path<FormType>).ref(
                      e
                    ); // set ref from register
                    if (i === 0 && headerVariablesCount === 0) {
                      firstInputRef.current = e; // keep the local ref for custom use
                    }
                  }}
                  error={Boolean(errors['bodyParameters']?.[i]?.name)}
                  id={`bodyParameters.${i}.name`}
                />
              </div>
            );
          })}
      </div>
    </Box>
  );
};
