import { useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useClickAway } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import ChevronDown from '@/components/atoms/Icons/ChevronDown';
import Create from '@/components/atoms/Icons/Create';
import FiltersIcon from '@/components/atoms/Icons/Filters';
import { AnalyticsType } from '@/modules/analytics/models';
import { delay } from '@/util/util';

import { filterFiltersByUrl } from '../../../constants';
import useContentFilters from '../../../hooks/useContentFilters';
import FavoriteFilters from '../FavoriteFilters/FavoriteFilters';
import FilterRow from '../FilterRow';
import FilterSearchList from '../FilterSearchList';

import styles from './ContentFilters.module.scss';

interface Props {
  type: AnalyticsType;
  filterOpen: boolean;
  setFilterOpen: (value: boolean) => void;
}

const ContentFilters = ({ type, filterOpen, setFilterOpen }: Props) => {
  const [showList, setShowList] = useState(false);
  const [openDropdownType, setOpenDropdownType] = useState(null);
  const { t } = useTranslation();
  const {
    filters,
    formMethods,
    fields,
    append,
    remove,
    filterOptions,
    getValues,
    availableOptions,
    handleFilterSave,
    handleFilterClear,
  } = useContentFilters(type, setFilterOpen);

  const searchListRef = useRef(null);
  const location = useLocation();

  useClickAway(searchListRef, async () => {
    await delay(100);
    setShowList(false);
  });

  const updatedFilters = useMemo(
    () =>
      filters.filter((f) => {
        if (type === AnalyticsType.ACCOUNT) {
          if (f.agentIds) {
            return (
              location.pathname.includes('/agents-performance') &&
              !location.pathname.includes('/human-agents-effectiveness')
            );
          }

          return (
            !f.deskIds &&
            !f.brainIds &&
            filterFiltersByUrl(f.type, location.pathname, t)
          );
        }
        return true;
      }),
    [filters, location.pathname, t, type]
  );

  return (
    <div className={styles.filters_container}>
      <button
        className={cn(styles.filters_button, {
          [styles.selected]: filterOpen,
          [styles.showEmptyState]:
            type === AnalyticsType.ACCOUNT && updatedFilters.length === 0,
        })}
        onClick={() => {
          setFilterOpen(true);
        }}
      >
        <FiltersIcon color={'var(--icon-default-blue)'} />
        <p>
          {t('analytics.filter', {
            0: updatedFilters.length > 0 ? `/ ${updatedFilters.length}` : '',
          })}
        </p>
        <div className={styles.chevron}>
          <ChevronDown />
        </div>
      </button>
      {filterOpen && (
        <div
          className={cn(styles.filters_menu, {
            [styles.isAccountAnalytics]: type === AnalyticsType.ACCOUNT,
          })}
        >
          <div className={styles.main}>
            <FormProvider {...formMethods}>
              <div className={styles.header}>
                <p className={styles.title}>
                  {t('analytics.select_filters').toUpperCase()}
                </p>
                {type !== AnalyticsType.ACCOUNT && (
                  <FavoriteFilters type={type} />
                )}
              </div>
              <div className={styles.filters}>
                {fields.map((field, index) => (
                  <FilterRow
                    type={field.type}
                    index={index}
                    remove={remove}
                    key={field.id}
                    filterOptions={filterOptions}
                    openDropdown={openDropdownType === field.type}
                    setOpenDropdown={setOpenDropdownType}
                  />
                ))}
              </div>
            </FormProvider>
            <div
              className={cn(styles.add_button, {
                [styles.not_empty]: !isEmpty(getValues('filters')),
                [styles.hidden]: isEmpty(availableOptions),
              })}
            >
              <Button
                size="small"
                variant="tertiary"
                onClick={() => {
                  setShowList((prev) => !prev);
                }}
              >
                <Create color="var(--icon-default-blue)" />
                {t('analytics.select_filters')}
              </Button>
              {showList && (
                <div ref={searchListRef}>
                  <FilterSearchList
                    className={styles.filter_list}
                    options={availableOptions}
                    onClick={(option) => {
                      append({
                        [option.value]:
                          option.input || option.valuePrefix
                            ? ''
                            : option.trueLabel
                              ? false
                              : [],
                        type: option.value,
                      });
                      setOpenDropdownType(option.value);
                      setShowList(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.footer}>
            <Button size="small" variant="tertiary" onClick={handleFilterClear}>
              {t('common.clear_close')}
            </Button>
            <Button size="small" onClick={handleFilterSave}>
              {t('common.save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentFilters;
