import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import {
  Bar,
  GeneralRow,
  getAnalyticsRange,
  prepareBar,
} from '@/util/analytics';
import { parseFilter } from '@/util/util';

import { ResponseRatingType } from './useRatingCount';
import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

export type RatingIntervalProps = {
  skip?: boolean;
  type: ResponseRatingType | 'growth';
};

interface Row {
  date: string;
  median_rating: number;
  avg_rating: number;
  num_more_than_3: number;
  num_ratings: number;
}

interface ResultRow extends Row {
  growth: number;
}

interface ResponseTimeHook {
  isLoading: boolean;
  currentError?: ApolloError;
  refetchCurrent?: () => void;
  data?: {
    series: Bar[];
  };
}
interface Rows {
  rows: GeneralRow[];
}

export const RATINGS_INTERVAL = gql`
  query GetDailyRatings(
    $accountId: uuid
    $brainIds: _uuid
    $channels: _text
    $deskIds: _uuid
    $endDate: timestamp
    $isTest: Boolean
    $startDate: timestamp
    $limit: Int
  ) {
    rows: get_daily_ratings(
      limit: $limit
      args: {
        account_id: $accountId
        brain_ids: $brainIds
        channels: $channels
        desk_ids: $deskIds
        end_time: $endDate
        is_test: $isTest
        start_time: $startDate
      }
    ) {
      avg_rating
      date
      median_rating
      num_ratings: ratings_count
      num_more_than_3: ratings_count_more_than_3
    }
  }
`;

/**
 * Initializes an array of objects representing each day between the given start and end dates.
 * Each object contains placeholder values for rating statistics.
 *
 */
export const initializeInterval = (
  startDate?: string | null,
  endDate?: string | null
) => {
  if (!startDate || !endDate) {
    return undefined;
  }
  const start = moment(startDate);
  const end = moment(endDate);

  const interval = new Array(end.diff(start, 'days') + 1);
  const currDay = start;
  for (let i = 0; i < interval.length; i += 1) {
    interval[i] = {
      avg_rating: 0,
      median_rating: 0,
      num_more_than_3: 0,
      num_ratings: 0,
      date: currDay.format('YYYY-MM-DD'),
      growth: 0,
    };
    currDay.add(1, 'day');
  }

  return [...interval];
};

/**
 * Updates the given interval with the data array.
 *
 * @param interval The initialized array for each day between startDate and endDate
 * @param data The results from the query with the days where there was data
 */
export const updateIntervalWithQueryResults = (
  interval?: GeneralRow[],
  data?: GeneralRow[]
): ResultRow[] | undefined => {
  if (!interval || !data) {
    return undefined;
  }
  const newInterval = cloneDeep(interval) as ResultRow[];
  const startDate = moment(newInterval[0]?.date);
  for (let i = 0; i < data.length; i += 1) {
    const offset = moment(data[i]?.date).diff(startDate, 'days');

    let growth = 0;
    if (offset < newInterval.length && offset >= 0) {
      growth = Math.round(
        (100 * data[i].num_more_than_3) / data[i].num_ratings
      );
    }
    if (newInterval[offset]) {
      newInterval[offset].avg_rating += data[i]?.avg_rating || 0;
      newInterval[offset].median_rating += data[i]?.median_rating || 0;
      newInterval[offset].num_more_than_3 += data[i]?.num_more_than_3 || 0;
      newInterval[offset].num_ratings += data[i]?.num_ratings || 0;
      newInterval[offset].growth += growth;
    }
  }
  return newInterval;
};

export const formatResponse = (
  type: ResponseRatingType | 'growth',
  currentTotal?: GeneralRow[]
): { series: Bar[] } => {
  if (!currentTotal) {
    return undefined;
  }

  const { newBar: newSeries } = prepareBar({
    currentTotal,
    type,
    disableGrouping: true,
  });

  const result = newSeries.map((bar) => {
    return {
      ...bar,
      primary: bar.primary / bar.difference,
    };
  });

  return {
    series: result,
  };
};

const useRatingsInterval = ({
  skip,
  type,
}: RatingIntervalProps): ResponseTimeHook => {
  const accountId = useSelector(selectAccountId);
  const filters = useSelector(selectFilters);

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {},
        {
          ...getAnalyticsRange(filters.startDate, filters.endDate),
          accountId,
          intervalInDays: '1 day',
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error: currentError,
    refetch: refetchCurrent,
  } = useQuery<Rows, QueryVariables>(RATINGS_INTERVAL, {
    variables,
    skip: skip || !accountId || !filters.filtersLoaded,
  });

  const currentRows = currentData?.rows;

  const data = useMemo(() => {
    const currentInterval = initializeInterval(
      filters.startDate,
      filters.endDate
    );
    const updatedCurrent = updateIntervalWithQueryResults(
      currentInterval,
      currentRows
    );

    if (
      !currentRows ||
      currentRows.length == 0 ||
      (currentRows.length == 1 && !currentRows[0].num_ratings)
    ) {
      return null;
    }
    return {
      ...formatResponse(type, updatedCurrent),
    };
  }, [currentRows, filters.endDate, filters.startDate, type]);

  const onRefetchCurrent = useCallback(() => {
    refetchCurrent(variables);
  }, [refetchCurrent, variables]);
  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (currentError) {
    return {
      isLoading: false,
      currentError,
      refetchCurrent: onRefetchCurrent,
    };
  }
  return { isLoading: false, data };
};

export default useRatingsInterval;
