import { Icon } from '@/models/icon';

const Home = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3047 6.77452L8.90477 0.374602C8.66475 0.13474 8.3393 0 7.99998 0C7.66065 0 7.33521 0.13474 7.09519 0.374602L0.695271 6.77452C0.575837 6.89307 0.481161 7.03418 0.41675 7.18964C0.352339 7.34511 0.319477 7.51183 0.320076 7.68011V15.36C0.320076 15.5297 0.387504 15.6925 0.507526 15.8126C0.627548 15.9326 0.790332 16 0.960068 16H6.08C6.24974 16 6.41252 15.9326 6.53254 15.8126C6.65257 15.6925 6.71999 15.5297 6.71999 15.36V10.8801H9.27996V15.36C9.27996 15.5297 9.34739 15.6925 9.46741 15.8126C9.58743 15.9326 9.75022 16 9.91995 16H15.0399C15.2096 16 15.3724 15.9326 15.4924 15.8126C15.6125 15.6925 15.6799 15.5297 15.6799 15.36V7.68011C15.6805 7.51183 15.6476 7.34511 15.5832 7.18964C15.5188 7.03418 15.4241 6.89307 15.3047 6.77452ZM14.3999 14.72H10.5599V10.2401C10.5599 10.0703 10.4925 9.90755 10.3725 9.78753C10.2525 9.66751 10.0897 9.60008 9.91995 9.60008H6.08C5.91027 9.60008 5.74748 9.66751 5.62746 9.78753C5.50744 9.90755 5.44001 10.0703 5.44001 10.2401V14.72H1.60006V7.68011L7.99998 1.28019L14.3999 7.68011V14.72Z"
        fill={color}
      />
    </svg>
  );
};
export default Home;
