import { Icon } from '@/models/icon';

const Italics = ({ size = 18, color = 'var(--icon-default-black)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
    >
      <path
        fill={color}
        d="M14.25 4.25V3H6.125v1.25h3.213L6.606 13H3v1.25h8.125V13H7.912l2.732-8.75h3.606Z"
      />
    </svg>
  );
};

export default Italics;
