import { useCallback } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  usersRolesEndpoints as endpoints,
  membersEndpoints,
} from '@/api/endpoints';
import { callGet, callPost, callDelete } from '@/api/fetcher';
import { Members } from '@/models/member';
import { Roles, PartialRole } from '@/models/role';
import { Departments } from '@/modules/departments/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { selectAccountId, selectAccountSlug } from '@/redux/session/selectors';
interface UpdateProps {
  user_id: string;
  role_ids: string[];
}

interface RemoveProps {
  user_id: string;
  role_id: string;
}

const API = {
  listRoles: async (user_id: string): Promise<Roles> =>
    callGet(endpoints.roles(user_id)),
  assignRoles: async ({ user_id, role_ids }: UpdateProps): Promise<Roles> =>
    callPost(endpoints.roles(user_id), { role_ids }),
  removeRole: async ({ user_id, role_id }: RemoveProps): Promise<PartialRole> =>
    callDelete(endpoints.role(user_id, role_id)),
  userDepartments: async (id: string): Promise<Departments> =>
    callGet(endpoints.userDepartments(id)),
};

export const onRoleAssigned = (
  queryClient: QueryClient,
  resp: Roles,
  user_id: string,
  accountSlug: string
) => {
  queryClient.setQueryData<Members>(
    [membersEndpoints.members, accountSlug],
    (prev: Members) => {
      const members = prev.members.map((m) => {
        if (m.user_id === user_id) {
          return {
            ...m,
            role_ids: [...m.role_ids, ...resp.roles.map((r) => r.role_id)],
          };
        }
        return m;
      });
      return { members };
    }
  );
};

export const onRoleRemoved = (
  queryClient: QueryClient,
  resp: PartialRole,
  user_id: string,
  accountSlug: string
) => {
  queryClient.setQueryData<Members>(
    [membersEndpoints.members, accountSlug],
    (prev: Members) => {
      const members = prev.members.map((m) => {
        if (m.user_id === user_id) {
          return {
            ...m,
            role_ids: m.role_ids.filter((r) => r !== resp.role_id),
          };
        }
        return m;
      });
      return { members };
    }
  );
};

export const useUserRoles = (user_id: string) => {
  const accountId = useSelector(selectAccountId);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const accountSlug = useSelector(selectAccountSlug);

  const { data: roles, status: listStatus } = useQuery<Roles, Error>({
    queryKey: [endpoints.roles(user_id), accountId],
    queryFn: () => API.listRoles(user_id),
    enabled: !!accountId && !!user_id,
  });

  const { data: userDepartments } = useQuery<Departments>({
    queryKey: [endpoints.userDepartments(user_id)],
    queryFn: () => API.userDepartments(user_id),
    enabled: !!user_id,
  });

  const { mutateAsync: assignRoles, status: updateStatus } = useMutation<
    Roles,
    Error,
    UpdateProps
  >({
    mutationFn: API.assignRoles,
    onSuccess: (resp, variables) => {
      onRoleAssigned(queryClient, resp, variables.user_id, accountSlug);
      dispatch(addTemporalToast('success', t('roles.role_assigned')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutateAsync: removeRole, status: removeStatus } = useMutation<
    PartialRole,
    Error,
    RemoveProps
  >({
    mutationFn: API.removeRole,
    onSuccess: (resp, variables) => {
      onRoleRemoved(queryClient, resp, variables.user_id, accountSlug);
      dispatch(addTemporalToast('success', t('roles.role_removed')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const getUserDepartments = useCallback(
    (desk_id: string) =>
      userDepartments?.departments?.filter((d) => d.desk_id === desk_id),
    [userDepartments?.departments]
  );

  return {
    roles: roles?.roles,
    getUserDepartments,
    listStatus,
    assignRoles,
    removeRole,
    updateStatus,
    removeStatus,
  };
};
