import { useTranslation } from 'react-i18next';

import AvatarFieldset from '@/components/atoms/Fieldset/templates/AvatarFieldset';
import useUser from '@/hooks/useUser';

const Avatar = () => {
  const { t } = useTranslation();
  const { user, updateUser } = useUser();

  const isLoading = user === undefined;

  return (
    <AvatarFieldset
      title={t('profile.your_avatar')}
      subtitle={t('profile.avatar_subtitle')}
      isLoading={isLoading}
      onUpdate={updateUser}
      name={user?.name}
      helpText={t('profile.avatar_help')}
      avatar={user?.avatar}
      isUserAvatar
    />
  );
};

export default Avatar;
