import { useMemo } from 'react';

import { ApolloError, DocumentNode, gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '@/models/state';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { parseFilter, shouldUseSlowQueries } from '@/util/util';

import { filterFiltersByUrl } from '../constants';

export interface IntervalRow {
  counts: number;
  date: string;
}

interface Rows {
  rows: IntervalRow[];
}

interface IntervalHook {
  refetch?: () => void;
  isLoading: boolean;
  error?: ApolloError;
  data?: IntervalRow[];
}

export type IntervalType = 'dialogs' | 'users' | 'sessions';

interface Props {
  type: IntervalType;
  value?: string;
  startDate?: string;
  endDate?: string;
  is_contained?: boolean;
  is_covered?: boolean;
  is_brain?: boolean;
  is_brain_effectiveness?: boolean;
  deskIds?: string;
}

export const LOG_REQUESTS_INTERVAL = gql`
  query LogRequestsIntervalV2(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $intervalInDays: interval
    $isTest: Boolean
    $minNumUserMessages: Int
  ) {
    rows: log_requests_interval_counts_v2(
      args: {
        account_id: $accountId
        interval_span: $intervalInDays
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      counts: interval_counts
      date: per_interval
    }
  }
`;

export const LOG_REQUESTS_INTERVAL_FAST = gql`
  query LogRequestsIntervalV2Fast(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
  ) {
    rows: log_requests_interval_counts_v2_fast(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        is_test: $isTest
      }
    ) {
      counts: interval_counts
      date: per_interval
    }
  }
`;

export const USERS_INTERVAL = gql`
  query LogsUsersIntervalV2(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $intervalInDays: interval
    $isTest: Boolean
    $minNumUserMessages: Int
  ) {
    rows: users_interval_counts_v2(
      args: {
        account_id: $accountId
        interval_span: $intervalInDays
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      counts: interval_counts
      date: per_interval
    }
  }
`;

export const USERS_INTERVAL_FAST = gql`
  query LogsUsersIntervalV2Fast(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
  ) {
    rows: users_interval_counts_v2_fast(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        is_test: $isTest
      }
    ) {
      counts: interval_counts
      date: per_interval
    }
  }
`;

export const LOGS_SESSIONS_INTERVAL = gql`
  query LogsSessionsIntervalV2(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $intervalInDays: interval
    $isContained: Boolean
    $isCovered: Boolean
    $isTest: Boolean
    $minNumUserMessages: Int
    $agentIds: _text
    $events: _text
  ) {
    rows: log_sessions_interval_counts_v2(
      args: {
        interval_span: $intervalInDays
        start_time: $startDate
        end_time: $endDate
        account_id: $accountId
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_contained: $isContained
        is_covered: $isCovered
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
        agent_ids: $agentIds
        events: $events
      }
    ) {
      counts: interval_counts
      date: per_interval
    }
  }
`;

export const LOGS_SESSIONS_INTERVAL_FAST = gql`
  query LogsSessionsIntervalV2Fast(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $startDate: timestamp
    $endDate: timestamp
    $isContained: Boolean
    $isCovered: Boolean
    $isTest: Boolean
    $isBrain: Boolean
  ) {
    rows: log_sessions_interval_counts_v2_fast(
      args: {
        start_time: $startDate
        end_time: $endDate
        account_id: $accountId
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        is_contained: $isContained
        is_covered: $isCovered
        is_test: $isTest
        is_brain: $isBrain
      }
    ) {
      counts: interval_counts
      date: per_interval
    }
  }
`;

export const QUERIES = {
  dialogs: LOG_REQUESTS_INTERVAL,
  users: USERS_INTERVAL,
  sessions: LOGS_SESSIONS_INTERVAL,
  dialogs_fast: LOG_REQUESTS_INTERVAL_FAST,
  users_fast: USERS_INTERVAL_FAST,
  sessions_fast: LOGS_SESSIONS_INTERVAL_FAST,
};

const getQueryFromVariables = (
  type: IntervalType,
  variables,
  is_brain_effectiveness: boolean
): { query: DocumentNode; is_fast: boolean } => {
  if (shouldUseSlowQueries(variables, { is_brain_effectiveness })) {
    return { query: QUERIES[type], is_fast: false };
  }
  return { query: QUERIES[`${type}_fast`], is_fast: true };
};

/**
 * Initializes an array with an element for each day between startDate and endDate.
 *
 * @param startDate The start date
 * @param endDate The end date
 */
const initializeInterval = (
  startDate?: string | null,
  endDate?: string | null
) => {
  if (!startDate || !endDate) {
    return undefined;
  }
  const start = moment(startDate);
  const end = moment(endDate);

  const interval = new Array(end.diff(start, 'days') + 1);
  const currDay = start;
  for (let i = 0; i < interval.length; i += 1) {
    interval[i] = {
      counts: 0,
      date: currDay.format('YYYY-MM-DD'),
    };
    currDay.add(1, 'day');
  }

  return interval;
};

/**
 * Updates the given interval with the data array.
 *
 * @param interval The initialized array for each day between startDate and endDate
 * @param data The results from the query with the days where there was data
 */
const updateIntervalWithQueryResults = (
  interval?: IntervalRow[],
  data?: IntervalRow[]
): IntervalRow[] | undefined => {
  if (!interval || !data) {
    return undefined;
  }
  const startDate = moment(interval[0].date);
  for (let i = 0; i < data.length; i += 1) {
    const offset = moment(data[i]?.date).diff(startDate, 'days');

    if (offset < interval.length && offset >= 0) {
      interval[offset].counts += data[i].counts;
    }
  }
  return interval;
};

const useInterval = (
  {
    type,
    startDate,
    endDate,
    is_contained,
    is_covered,
    is_brain,
    is_brain_effectiveness,
    deskIds,
  }: Props,
  skip = false
): IntervalHook => {
  const accountId = useSelector(selectAccountId);

  const filters = useSelector((state: RootState) => {
    return {
      analytics: state.analytics.accountAnalytics,
      startDate: startDate || state.analytics.startDate,
      endDate: endDate || state.analytics.endDate,
    };
  }, shallowEqual);

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {},
        {
          ...getAnalyticsRange(filters.startDate, filters?.endDate),
          accountId,
          isCovered: is_covered ?? undefined,
          isContained: is_contained ?? undefined,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          })),
        deskIds ? { deskIds } : {}
      ),
    [
      accountId,
      deskIds,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      is_contained,
      is_covered,
      location.pathname,
    ]
  );

  const { query, is_fast } = getQueryFromVariables(
    type,
    variables,
    is_brain_effectiveness
  );

  const extraVars = is_fast
    ? { isBrain: is_brain }
    : {
        intervalInDays: '1 day',
        events: is_brain ? '{message:brain_send}' : undefined,
      };

  const {
    loading,
    data: rawData,
    error,
    refetch,
  } = useQuery<Rows, QueryVariables>(query, {
    variables: { ...variables, ...extraVars },
    skip: skip || !accountId,
  });
  const rows = rawData?.rows;

  const data = useMemo(() => {
    if (!rows) {
      return undefined;
    }
    const interval = initializeInterval(filters.startDate, filters.endDate);
    return updateIntervalWithQueryResults(interval, rows);
  }, [rows, filters.startDate, filters.endDate]);

  if (loading || error) {
    return {
      refetch,
      isLoading: loading,
      error,
    };
  }
  return { isLoading: false, data };
};

export default useInterval;
