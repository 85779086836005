import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Input from '@/components/atoms/Input/Input';
import QrCodeGenerator from '@/components/atoms/QrCodeGenerator/QrCodeGenerator';
import { useViber } from '@/hooks/useViber';

import styles from './ViberInfo.module.scss';

interface Props {
  deskId: string;
  integrationId: string;
}
const ViberInfo = ({ deskId, integrationId }: Props) => {
  const { t } = useTranslation();
  const { viberData, isLoading, error } = useViber(deskId, integrationId);
  const viberUri = `viber://pa?chatURI=${viberData?.uri}`;
  const image = `data:image/jpeg;base64,${viberData?.imagebase64}`;
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t('integrations.viber_info.overview')}
      </div>
      <div className={styles.header}>
        <div className={styles.card}>
          {isLoading && <Skeleton variant="circular" width={64} height={64} />}
          {error && <Avatar />}
          {!isLoading && !error && (
            <img
              src={viberData?.icon}
              alt="Viber Icon"
              referrerPolicy="no-referrer"
            />
          )}

          <div>
            {isLoading && (
              <>
                <Skeleton variant="text" width={100} height={16} />
                <Skeleton variant="text" width={100} height={16} />
              </>
            )}
            {!isLoading && (
              <>
                <p className={styles.name}>
                  {viberData?.name || t('integrations.viber_info.account_name')}
                </p>
                <p className={styles.uri}>Uri: {viberData?.uri || '-'}</p>
              </>
            )}

            {!isLoading && (
              <CopyButton
                className="primary"
                disabled={error}
                data={viberUri}
                iconPosition="left"
              >
                {t('integrations.viber_info.deep_link')}
              </CopyButton>
            )}
          </div>
        </div>
        {!isLoading && !error && (
          <div className={styles.qrcode}>
            <QrCodeGenerator url={viberUri} image={image} />
          </div>
        )}
      </div>
      <div className={styles.dataContainer}>
        <Input
          name="followers"
          isLoading={isLoading}
          label={t('integrations.viber_info.followers')}
          value={viberData?.subscribers_count}
          size="medium"
          readOnly
        />
        <Input
          name="category"
          isLoading={isLoading}
          label={t('integrations.viber_info.category')}
          value={viberData?.category}
          size="medium"
          readOnly
        />
        <Input
          name="subcategory"
          isLoading={isLoading}
          label={t('integrations.viber_info.subcategory')}
          value={viberData?.subcategory}
          size="medium"
          readOnly
        />
        <Input
          name="country"
          isLoading={isLoading}
          label={t('integrations.viber_info.country')}
          value={viberData?.country}
          size="medium"
          readOnly
        />
      </div>
    </div>
  );
};

export default ViberInfo;
