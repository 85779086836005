import { useState, useCallback } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Input from '@/components/atoms/Input/Input';
import QrCodeGenerator from '@/components/atoms/QrCodeGenerator/QrCodeGenerator';
import useFacebook from '@/hooks/useFacebook';
import { FacebookIntegration } from '@/models/integration';

import styles from './MetaInfo.module.scss';

interface Props {
  integration: FacebookIntegration;
}
const placeholder = (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clipAvatar)">
      <path
        d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z"
        fill="#E3F3FF"
      />
      <path
        d="M22.4 14.4a6.4 6.4 0 11-12.8 0 6.4 6.4 0 0112.8 0zM16 24C7.163 24 0 31.163 0 40h32c0-8.837-7.163-16-16-16z"
        fill="#1B66D6"
      />
    </g>
    <defs>
      <clipPath id="clipAvatar">
        <path
          d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z"
          fill="var(--icon-default-white)"
        />
      </clipPath>
    </defs>
  </svg>
);

const MetaInfo = ({ integration }: Props) => {
  const { t } = useTranslation();
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);
  const { infoData, infoIsLoading, image, imageLoading } =
    useFacebook(errorLoadingImage);

  const pageName = integration?.config?.page_name;
  const pageId = integration?.config?.page_id;
  const page = integration?.config.pages?.find(
    (page) => page?.name === pageName
  );
  const error = null;
  const isLoading = !page || infoIsLoading || imageLoading;

  const renderImage = useCallback(() => {
    if (errorLoadingImage && !image?.picture) {
      return placeholder;
    }

    if (!isLoading && !error) {
      return (
        <img
          src={errorLoadingImage ? image?.picture : page?.picture}
          alt="Meta page icon"
          referrerPolicy="no-referrer"
          onError={() => setErrorLoadingImage(true)}
        />
      );
    }
  }, [errorLoadingImage, image?.picture, isLoading, page?.picture]);

  // FIXME: call the meta info endpoint to retrieve the data to show here.
  // See https://moveoai.atlassian.net/browse/DEV-1408
  const getPageUrl = useCallback(() => {
    if (integration?.type === 'facebook') {
      return `http://m.me/${pageId}`;
    }
    if (integration?.type === 'instagram' && infoData?.username) {
      return `https://ig.me/m/${infoData?.username}`;
    }
    return null;
  }, [infoData?.username, integration?.type, pageId]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('integrations.facebook.overview')}</div>
      <div className={styles.header}>
        <div className={styles.card}>
          {isLoading && <Skeleton variant="circular" width={64} height={64} />}
          {error && <Avatar />}
          {renderImage()}
          <div className={styles.cardInfo}>
            {isLoading && (
              <>
                <Skeleton variant="text" width={100} height={16} />
                <Skeleton variant="text" width={100} height={16} />
              </>
            )}
            {!isLoading && <p className={styles.name}>{pageName}</p>}
            {!isLoading && getPageUrl() && (
              <CopyButton
                className="primary"
                disabled={error}
                data={getPageUrl()}
                iconPosition="left"
              >
                {t('integrations.viber_info.deep_link')}
              </CopyButton>
            )}
          </div>
        </div>
        {!isLoading && !error && getPageUrl() && (
          <div className={styles.qrcode}>
            <QrCodeGenerator url={getPageUrl()} image={page?.picture} />
          </div>
        )}
      </div>
      <div className={styles.dataContainer}>
        <Input
          isLoading={isLoading}
          label={t('integrations.facebook.page_id')}
          value={pageId}
          size="medium"
          readOnly
        />
      </div>
      <div className={styles.dataContainer}>
        <Input
          isLoading={isLoading}
          label={t('integrations.facebook.num_followers')}
          value={infoData?.followers_count ?? t('common.not_available')}
          size="medium"
          readOnly
        />
      </div>
    </div>
  );
};

export default MetaInfo;
