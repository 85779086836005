import { Icon } from '@/models/icon';

export const TriggerMessageIcon = ({ size = 16 }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="var(--icon-default-gray)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".7"
        clipPath="url(#a-trigger-message-icon)"
      >
        <path
          fill="#DEDEDE"
          d="M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Z"
        />
        <path d="m6.667 5.333 4 2.667-4 2.667V5.333Z" />
      </g>
      <defs>
        <clipPath id="a-trigger-message-icon">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
