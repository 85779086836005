import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DialogTooltip from '@/components/organisms/Dialogs/DialogTooltip/DialogTooltip';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { removeOption, selectOption } from '@/redux/nodes/actions';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import styles from './Option.module.scss';
interface OptionProps {
  label: string;
  actionId: string;
  index: number;
  extendedWidth?: boolean;
}

function Option({
  label,
  index,
  actionId,
  extendedWidth = false,
}: OptionProps) {
  const dispatch = useDispatch();

  const isSelected = useSelector(
    (state: RootState) =>
      actionId === state.nodes.selectedActionId &&
      index === state.nodes.selectedOptionIndex
  );

  const onClick = useCallback(
    (e) => {
      preventClickThrough(e);
      dispatch(selectOption({ actionId, index }));
    },
    [dispatch, actionId, index]
  );

  const onKeyUp = useCallback(
    (e) => {
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        dispatch(selectOption({ actionId, index }));
      } else if (e.key === 'Delete') {
        const deleteProps = {
          subtitle: (
            <Trans i18nKey="dialog.delete_option_warning" values={[label]} />
          ),
          confirm: false,
          onDelete: () => {
            dispatch(removeOption({ actionId, index }));
            dispatch(popModal());
          },
        };
        dispatch(pushModal(MODAL_DELETE, deleteProps));
      }
    },
    [dispatch, actionId, index, label]
  );

  const onDelete = useCallback(() => {
    const deleteProps = {
      subtitle: (
        <Trans i18nKey="dialog.delete_option_warning" values={[label]} />
      ),
      confirm: false,
      onDelete: () => {
        dispatch(removeOption({ actionId, index }));
        dispatch(popModal());
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [actionId, dispatch, index, label]);

  return (
    <DialogTooltip onDelete={onDelete} type="option">
      <div
        className={cn(styles.option, {
          [styles['option--selected']]: isSelected,
          [styles['option--extended']]: extendedWidth,
        })}
        onClick={onClick}
        onKeyUp={onKeyUp}
        role="button"
        tabIndex={0}
      >
        <Typography variant="body-regular">{label}</Typography>
      </div>
    </DialogTooltip>
  );
}

export default Option;
