import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';

export const selectUsedNodes = (state: RootState) =>
  state.usedNodesCache.usedNodes;

export const selectUsedNode = createSelector(
  [selectUsedNodes, (_, props: { brainId: string; name: string }) => props],
  (usedNodes, { brainId, name }) => {
    return usedNodes[`${brainId}_${name}`];
  }
);
