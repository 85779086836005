import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Routes, Route, useParams, Navigate } from 'react-router';

import Conversations from '@/components/pages/Conversations/Conversations';
import ConversationNew from '@/modules/humanChat/components/Conversation/ConversationNew';
import { SubNavConversationNew } from '@/modules/humanChat/components/SubNavConversation';
import { resetView } from '@/modules/humanChat/redux/actions';
import { setDeskId } from '@/redux/session/actions';

import NotFound from '../NotFound/NotFound';

import styles from './ConversationsRouter.module.scss';

const ConversationsRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { deskId } = useParams();

  useEffect(() => {
    if (deskId) {
      dispatch(setDeskId(deskId));
    }
  }, [dispatch, deskId]);

  useEffect(() => {
    return () => {
      dispatch(resetView());
    };
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <SubNavConversationNew />
      <Routes>
        <Route path="/" element={<Navigate to="conversations" replace />} />
        <Route path="/conversations" element={<Conversations />} />
        <Route path="/conversations/:sessionId" element={<ConversationNew />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default ConversationsRouter;
