import { PerformanceBar } from './PerformanceBar/PerformanceBar';

import s from './Wrapper.module.scss';

interface Props {
  phrases: number;
  optimalPhrases: number;
  children: React.ReactNode;
}

export const Wrapper = ({ phrases, children, optimalPhrases }: Props) => {
  return (
    <div className={s.wrapper}>
      <PerformanceBar phrases={phrases} optimalPhrases={optimalPhrases} />
      <span>{children}</span>
    </div>
  );
};
