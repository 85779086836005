export const Opener = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="var(--icon-default-gray)"
        strokeWidth="1.5"
        d="M8.706 5.5H19a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H8.706m0-13H5a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h3.706m0-13v13"
      />
    </svg>
  );
};
