import { useMemo, ReactNode, Ref } from 'react';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { PopperProps } from '@mui/material/Popper';
import cn from 'classnames';
import {
  Controller,
  Control,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomInputField from './AutocompleteInputField';
import {
  autocompleteStyles,
  GroupHeader,
  GroupItems,
  StyledPopper,
} from './Components';
import OptionContent from './OptionContent';
import { Size } from '../AutoComplete/styles';
import HelpTooltip from '../HelpTooltip/HelpTooltip';
import Close from '../Icons/Close';

import styles from './Autocomplete.module.scss';

interface OptionType {
  label: string;
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface AutoCompleteNewProps<T extends FieldValues> {
  options?: OptionType[];
  control: Control<T>;
  name: string;
  rules?: Omit<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RegisterOptions<T, any>,
    'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  label?: string;
  required?: boolean;
  id?: string;
  hasError?: boolean;
  errorMessage?: string;
  size?: Size;
  placeholder?: string;
  searchIcon?: ReactNode;
  renderIconInFront?: (iconTypeKey: string) => ReactNode;
  renderIconInBack?: (iconTypeKey: string) => ReactNode;
  groupByProp?: string;
  tooltip?: string;
  getOptionDisabled?: (option: OptionType) => boolean;
  iconTypeKey?: string;
  isOptionEqualToValue?: (option: OptionType, value) => boolean;
  getOptionLabel?: (option) => string;
  groupByLabelProp?: boolean;
  shouldRenderGroup?: boolean;
  enableNewEntry?: boolean;
  width?: number;
  clearIcon?: ReactNode;
  inputRef?: Ref<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const defaultIsOptionEqualToValue = (option: OptionType, value) => {
  if (typeof value === 'string') {
    return option.label === value;
  } else if (typeof value === 'object') {
    return option.label === value.label;
  }
  return false;
};

const defaultGetOptionLabel = (option) => option.label;

const filter = createFilterOptions<OptionType>();

const AutoCompleteNew = <T extends FieldValues>({
  options = [],
  control,
  name,
  rules = undefined,
  label = undefined,
  required = false,
  id = undefined,
  hasError = false,
  errorMessage = undefined,
  size = 'small',
  placeholder,
  searchIcon = undefined,
  renderIconInFront = undefined,
  renderIconInBack = undefined,
  groupByProp = undefined,
  tooltip = undefined,
  getOptionDisabled = undefined,
  iconTypeKey = 'node_type',
  isOptionEqualToValue = defaultIsOptionEqualToValue,
  getOptionLabel = defaultGetOptionLabel,
  groupByLabelProp = true,
  shouldRenderGroup = true,
  enableNewEntry = false,
  width = 312,
  clearIcon = <Close size={12} />,
  inputRef = null,
  ...autoCompleteProps
}: AutoCompleteNewProps<T>) => {
  const { t } = useTranslation();
  const renderIcons = (position, _, iconTypeKey) => {
    if (position === 'front') {
      return renderIconInFront && renderIconInFront(iconTypeKey);
    } else if (position === 'back') {
      return renderIconInBack && renderIconInBack(iconTypeKey);
    }
  };

  const unlistedEntry = groupByLabelProp
    ? 'unlisted_entry'
    : t('common.unlisted_entry');

  const renderGroup = (params: {
    key: string;
    group: string;
    children: ReactNode;
  }) => {
    return (
      <li key={params.key}>
        {shouldRenderGroup && <GroupHeader>{params.group}</GroupHeader>}
        <GroupItems>{params.children}</GroupItems>
      </li>
    );
  };

  // This prevents the Popper from re-rendering and scrolling to the top
  const memoizedPopper = useMemo(
    () => (popperProps: PopperProps) => {
      return StyledPopper({ ...popperProps, width });
    },
    [width]
  );

  //TODO trim values for very long name
  return (
    <>
      {label && (
        <label className={cn(styles.label)} htmlFor={id}>
          {label}
          {required && '*'}
          <HelpTooltip tooltip={tooltip} />
        </label>
      )}
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            {...autoCompleteProps}
            autoHighlight
            id={id}
            // Styling props
            clearIcon={clearIcon}
            forcePopupIcon={false}
            sx={() => ({
              ...autocompleteStyles(size as Size, hasError),
              ...autoCompleteProps.sx,
            })}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 4],
                    },
                  },
                ],
              },
            }}
            PopperComponent={memoizedPopper}
            // Core props
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={(event, data, reason, details) => {
              field.onChange(data);

              if (autoCompleteProps.onChange) {
                autoCompleteProps.onChange(event, data, reason, details);
              }
            }}
            isOptionEqualToValue={isOptionEqualToValue}
            groupBy={
              groupByProp
                ? (option) =>
                    groupByLabelProp
                      ? t(`auto_complete.group_labels.${option[groupByProp]}`)
                      : option[groupByProp]
                : undefined
            }
            getOptionDisabled={getOptionDisabled}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (!enableNewEntry) {
                return filtered;
              }

              const { inputValue } = params;
              const isExisting = options.some(
                (option) => inputValue === option.label
              );
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  type: unlistedEntry,
                  label: inputValue,
                  value: inputValue,
                  dialog_name: unlistedEntry,
                });
              }

              return filtered;
            }}
            // Render props
            renderGroup={renderGroup}
            renderInput={(params) => {
              return (
                <CustomInputField
                  inputParams={params}
                  searchIcon={searchIcon}
                  placeholder={placeholder}
                  inputRef={inputRef}
                />
              );
            }}
            renderOption={(props, option) => {
              return (
                <span {...props} key={option.value ?? option}>
                  <OptionContent
                    option={option}
                    iconTypeKey={iconTypeKey}
                    ariaSelected={props['aria-selected']}
                    t={t}
                    renderIcons={renderIcons}
                    unlistedEntry={unlistedEntry}
                  />
                </span>
              );
            }}
          />
        )}
      />

      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </>
  );
};

export default AutoCompleteNew;
