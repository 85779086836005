import { Icon } from '@/models/icon';

export const DisambiguationIcon = ({ size = 16 }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#5D85B7"
        d="M12.319 10.762c-3.58-.04-3.865-.482-4.372-1.875-.582-1.601-1.809-2.668-4.734-2.668V8.67c1.451.045 2.057-.279 3.38 2.528.898 1.905 3.23 2.052 5.726 2.016v-2.452Z"
      />
      <path
        fill="#1B66D6"
        d="M12.414 3.998c-3.291.07-3.896 1.192-4.65 2.387-.714 1.132-1.626 2.01-4.551 2.01V5.944c1.451-.045 2.553-.513 3.457-2.107C7.7 2.02 9.919 1.51 12.414 1.547v2.45Z"
      />
      <path
        fill="#1B66D6"
        d="M2.74 9.38c.96 0 1.739-.89 1.739-1.988 0-1.098-.779-1.988-1.74-1.988-.96 0-1.739.89-1.739 1.988C1 8.49 1.779 9.38 2.74 9.38ZM12.231 1l2.625 1.775-2.625 1.763V1Z"
      />
      <path fill="#5D85B7" d="m11.938 10 2.624 2.006L11.938 14v-4Z" />
    </svg>
  );
};

export const DisambiguationIconGray = ({ size = 14 }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#C8CCD1"
        d="M11.319 10.262c-3.58-.04-3.865-.482-4.372-1.875-.582-1.601-1.809-2.668-4.734-2.668V8.17c1.451.045 2.057-.279 3.38 2.528.898 1.905 3.23 2.052 5.726 2.016v-2.452Z"
      />
      <path
        fill="#A2A9B1"
        d="M11.414 3.498c-3.291.07-3.896 1.192-4.65 2.387-.714 1.132-1.626 2.01-4.551 2.01V5.444c1.451-.045 2.553-.513 3.457-2.107C6.7 1.52 8.919 1.01 11.414 1.047v2.45Z"
      />
      <path
        fill="#A2A9B1"
        d="M1.74 8.88c.96 0 1.739-.89 1.739-1.988 0-1.098-.779-1.988-1.74-1.988C.78 4.904 0 5.794 0 6.892 0 7.99.779 8.88 1.74 8.88ZM11.231.5l2.625 1.775-2.625 1.763V.5Z"
      />
      <path fill="#C8CCD1" d="m10.938 9.5 2.624 2.006-2.624 1.994v-4Z" />
    </svg>
  );
};
