import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Ellipse = ({
  size = 8,
  color = 'var(--icon-default-gray)',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      viewBox="0 0 8 8"
      className={disabledClass}
      width={size}
      height={size}
      fill={color}
      shapeRendering="geometricPrecision"
    >
      <circle cx="4" cy="4" r="4" />
    </svg>
  );
};

export default Ellipse;
