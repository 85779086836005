import Fieldset from './Fieldset';
import FieldsetAvatar from './FieldsetAvatar';
import FieldsetCheckbox from './FieldsetCheckbox';
import FieldsetContent from './FieldsetContent';
import FieldsetFooter from './FieldsetFooter';
import FieldsetFooterAction from './FieldsetFooterAction';
import FieldsetFooterStatus from './FieldsetFooterStatus';
import FieldsetSubtitle from './FieldsetSubtitle';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Fieldset,
  Content: FieldsetContent,
  Footer: FieldsetFooter,
  FooterStatus: FieldsetFooterStatus,
  FooterAction: FieldsetFooterAction,
  Avatar: FieldsetAvatar,
  Checkbox: FieldsetCheckbox,
  Subtitle: FieldsetSubtitle,
};
