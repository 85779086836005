import { Icon } from '@/models/icon';

const ExportAnalytics = ({
  size = 24,
  color = 'var(--icon-default-blue)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
      width={size}
      height={size}
      fill="none"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#a)"
      >
        <path d="M5.667 12.765 8 15.098l2.333-2.333M8 8.973v6.125M13.18 12.526a2.916 2.916 0 0 0-1.68-5.303h-.735a4.666 4.666 0 1 0-8.014 4.253" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M1 1.973h14v14H1z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExportAnalytics;
