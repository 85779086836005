import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Input from '@/components/atoms/Input/Input';
import ReorderWrapper from '@/components/atoms/ReorderWrapper/ReorderWrapper';
import Select from '@/components/atoms/Select/Select';
import ToolkitAccordionWrapper from '@/components/organisms/Toolkit/ToolkitAccordionWrapper';
import useDialogs from '@/hooks/useDialogs';
import { CarouselButton as CarouselButtonType } from '@/models/action';
import {
  isAlertThisCardButton,
  selectHasCarouselDialogErrors,
} from '@/redux/dialogAlerts/selectors';
import {
  DEFAULT_HEIGHT,
  DEFAULT_RANDOM_URL,
  getRandomElement,
  randId,
} from '@/redux/dialogs/helper';
import { updateCardButton } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter, scrollToElementById } from '@/util/util';
import { carouselButtonSchema } from '@/util/validator';

import { defaultActionOptions } from './constants';
import CarouselDefaultActionNew from './SelectDefaultAction';

interface CarouselButtonProps {
  index: number;
  option: CarouselButtonType;
  actionId: string;
  cardIndex: number | null;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  dropItem: () => void;
  getIndex: () => number;
  handleDeleteButton: (index: number) => void;
  disabled: boolean;
  updateErrors?: (name: string, value: string, index?: number) => void;
}

const CarouselButton = ({
  option,
  index,
  actionId,
  cardIndex,
  handleDeleteButton,
  moveItem,
  dropItem,
  getIndex,
  disabled,
  updateErrors,
}: CarouselButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultValues = {
    ...option,
  };

  const brainId = useSelector(selectBrainId);
  const { eventsOptions } = useDialogs(brainId);
  const randomTriggerNodeId = getRandomElement(eventsOptions)?.value;
  const blockReordering = useSelector(selectHasCarouselDialogErrors(actionId));
  const isAlertThisIndex = useSelector(isAlertThisCardButton(cardIndex, index));

  const {
    formState: { errors },
    getValues,
    register,
    unregister,
    control,
    trigger,
    setValue,
  } = useForm<CarouselButtonType>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      carouselButtonSchema(option?.hasDuplicatedLabels)
    ) as Resolver<CarouselButtonType>,
  });

  const [openOption, setOpenOption] = useState(isAlertThisIndex);
  if (isAlertThisIndex) {
    scrollToElementById(`label-${index}-${cardIndex}`);
  }

  const toggleButtonAccordion = useCallback(() => {
    setOpenOption((prev) => !prev);
  }, []);

  const updateRedux = useCallback(() => {
    dispatch(
      updateCardButton({
        actionId,
        cardIndex,
        buttonIndex: index,
        action: { ...getValues() },
      })
    );
  }, [actionId, cardIndex, dispatch, getValues, index]);

  const handleDelete = useCallback(() => {
    // Clear errors
    updateErrors(`label-${index}-${cardIndex}`, '');
    updateErrors(`buttonUrl-${index}-${cardIndex}`, '');
    updateErrors(`value-${index}-${cardIndex}`, '');

    // Update redux
    handleDeleteButton(index);
  }, [cardIndex, handleDeleteButton, index, updateErrors]);

  const handleButtonTypeChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const type = e.target.value;

      if (type === 'postback') {
        setValue('value', t('actions.generate_text', { 0: randId() }), {
          shouldValidate: true,
        });

        unregister(['url', 'height', 'trigger_node_id']);

        updateErrors('buttonUrl', '');
      } else if (type === 'phone') {
        setValue('value', '123456789', {
          shouldValidate: true,
        });

        unregister(['url', 'height', 'trigger_node_id']);

        updateErrors('buttonUrl', '');
      } else if (type === 'webview') {
        setValue('height', DEFAULT_HEIGHT);
        setValue('url', DEFAULT_RANDOM_URL, {
          shouldValidate: true,
        });
        setValue('trigger_node_id', randomTriggerNodeId);

        unregister('value');

        updateErrors('value', '');
      } else if (type === 'url') {
        setValue('url', DEFAULT_RANDOM_URL, {
          shouldValidate: true,
        });

        unregister(['value', 'height', 'trigger_node_id']);

        updateErrors('value', '');
      }

      updateRedux();
    },
    [randomTriggerNodeId, setValue, t, unregister, updateErrors, updateRedux]
  );

  // Dialog Errors
  const labelErrorMessage = capitalizeFirstLetter(errors.label?.message);
  const urlErrorMessage = capitalizeFirstLetter(errors?.['url']?.message);
  const valueErrorMessage = capitalizeFirstLetter(errors.value?.message);

  useEffect(() => {
    trigger();
  }, [trigger, option.hasDuplicatedLabels]);

  useEffect(() => {
    if (cardIndex === null) return;
    updateErrors(`label-${index}-${cardIndex}`, labelErrorMessage, cardIndex);
  }, [index, labelErrorMessage, updateErrors, cardIndex]);

  useEffect(() => {
    if (cardIndex === null) return;
    updateErrors(`buttonUrl-${index}-${cardIndex}`, urlErrorMessage, cardIndex);
  }, [urlErrorMessage, updateErrors, index, cardIndex]);

  useEffect(() => {
    if (cardIndex === null) return;
    updateErrors(`value-${index}-${cardIndex}`, valueErrorMessage, cardIndex);
  }, [valueErrorMessage, updateErrors, index, cardIndex]);

  return (
    <ReorderWrapper
      dragId={`carousel-button-${cardIndex}`}
      index={index}
      moveItem={moveItem}
      dropItem={dropItem}
      getIndex={getIndex}
      placeholderHeight={36}
      canDrag={!openOption && !blockReordering}
    >
      <ToolkitAccordionWrapper
        variant="grey"
        title={option.label}
        onChange={toggleButtonAccordion}
        expanded={openOption}
        handleDelete={handleDelete}
        isNested
        height="small"
        disabled={disabled}
      >
        <Box pt="var(--space-12)">
          <Input
            error={!!errors?.label}
            errorMessage={labelErrorMessage}
            label={t('common.label')}
            name="label"
            onChange={updateRedux}
            placeholder={t('dialog.carousel.button')}
            register={register('label')}
            size="small"
            id={`label-${index}-${cardIndex}`}
          />

          <Stack spacing="var(--space-12)">
            <Select
              id={`${actionId}-button-action-${index}-${cardIndex}`}
              name="type"
              onChange={handleButtonTypeChange}
              register={register('type')}
              options={defaultActionOptions}
              size="small-full"
            />
            <CarouselDefaultActionNew
              errors={errors}
              updateRedux={updateRedux}
              actionId={actionId}
              register={register}
              index={index}
              cardIndex={cardIndex}
              control={control}
            />
          </Stack>
        </Box>
      </ToolkitAccordionWrapper>
    </ReorderWrapper>
  );
};

export default memo(CarouselButton);
