import { Icon } from '@/models/icon';

const AttachmentFile = ({ color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      width="28"
      height="15"
      viewBox="0 0 28 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.75 10v3.75a1.25 1.25 0 0 1-2.5 0v-2.5h-7.5A1.25 1.25 0 0 1 16.5 10V2.5H2.75v11.25a1.25 1.25 0 0 1-2.5 0V2.5A2.5 2.5 0 0 1 2.75 0h15a1.25 1.25 0 0 1 .884.366l8.75 8.75a1.252 1.252 0 0 1 .366.884Zm-4.267-1.25L19 4.267V8.75h4.483Z"
        fill={color}
      />
    </svg>
  );
};

export default AttachmentFile;
