import { useCallback, useState } from 'react';

import cn from 'classnames';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { addTemporalToast } from '@/modules/notifications/redux/actions';

import fs from '..';

type ButtonProps = React.ComponentPropsWithoutRef<typeof Button>;

type Props = React.ComponentPropsWithoutRef<typeof fs.Fieldset> & {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void> | void;
  helpText?: React.ReactNode;
  submitLabel?: React.ReactNode;
  notifyError?: string | boolean;
  fullContentWidth?: boolean;
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
  hasBadge?: boolean;
  integrationActive?: boolean;
};

const FormFieldset = ({
  title,
  subtitle,
  children,
  onSubmit,
  helpText,
  notifyError,
  fullContentWidth,
  isLoading: isLoadingFieldset,
  primaryButton,
  secondaryButton,
  externalLink,
  fullWidth,
  hasBadge,
  integrationActive,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        await onSubmit(event);
      } catch (error) {
        console.error(error);
        if (notifyError) {
          const message =
            typeof notifyError === 'string'
              ? notifyError
              : error.message || 'There has been an error submitting the form';
          dispatch(addTemporalToast('error', message));
        }
      } finally {
        setIsLoading(false);
      }
    },
    [onSubmit, dispatch, notifyError]
  );

  return (
    <fs.Fieldset
      title={title}
      subtitle={subtitle}
      externalLink={externalLink}
      isLoading={isLoadingFieldset}
      fullWidth={fullWidth}
      hasBadge={hasBadge}
      integrationActive={integrationActive}
    >
      <form onSubmit={handleSubmit}>
        {/* Prevent implicit submission of the form
         See https://stackoverflow.com/a/51507806*/}
        <button
          aria-hidden="true"
          className="fieldset__hidden"
          disabled
          type="submit"
        ></button>

        <div className={cn({ fieldset__container: !fullContentWidth })}>
          {children}
        </div>
        <fs.Footer>
          {helpText && <fs.FooterStatus>{helpText}</fs.FooterStatus>}
          <fs.FooterAction>
            {secondaryButton && (
              <Button variant="secondary" {...secondaryButton} />
            )}
            <Button isLoading={isLoading} {...primaryButton} />
          </fs.FooterAction>
        </fs.Footer>
      </form>
    </fs.Fieldset>
  );
};

export default FormFieldset;
