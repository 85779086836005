import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import { BRAIN_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';

interface Props {
  brain?: Brain;
}

const DeleteBrainFieldset = ({ brain }: Props) => {
  const { t } = useTranslation();
  const brainId = brain?.brain_id;
  const brainName = brain?.name;
  const { slug } = useAccount();
  const { deleteStatus } = useBrains(brainId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const onDelete = useCallback<() => void>(() => {
    const warnProps = {
      brainName,
      brainId,
      onDeleted: () => {
        navigate(`/${slug}/brains`);
      },
    };
    dispatch(pushModal(BRAIN_DELETE, warnProps));
  }, [slug, brainName, brainId, dispatch, navigate]);

  return (
    <FormFieldset
      title={t('brains.delete_brain')}
      subtitle={t('brains.delete_brain_warning')}
      primaryButton={{
        children: t('common.delete'),
        variant: 'danger',
        isLoading: deleteStatus === 'pending',
        disabled: !canWrite,
      }}
      onSubmit={onDelete}
      isLoading={!brain}
    />
  );
};

export default DeleteBrainFieldset;
