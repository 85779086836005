import { memo, ReactNode } from 'react';

import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { Module } from '@/models/permissions';

interface Props {
  tabValue: number;
  module: Module;
  children: ReactNode;
  thisTab: number;
}

export const Wrapper = memo(
  ({ tabValue, module, children, thisTab }: Props) => {
    return (
      <PermissionsWrapper module={module}>
        <div
          role="tabpanel"
          hidden={tabValue !== thisTab}
          id={`devtools-tabpanel-${thisTab}`}
          aria-labelledby={`devtools-tab-${thisTab}`}
        >
          {children}
        </div>
      </PermissionsWrapper>
    );
  }
);

Wrapper.displayName = 'Wrapper';
