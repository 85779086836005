import { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import useUser from '@/hooks/useUser';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import { selectAllAgents } from '@/redux/presence/selectors';
import { selectDeskId } from '@/redux/session/selectors';

import { setView } from '../redux/actions';
import { selectView } from '../redux/selectors';

export const useMessageList = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [urlSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const deskId = useSelector(selectDeskId);
  const { departments } = useDepartments(deskId);
  const agents = useSelector(selectAllAgents);
  const agentIdFromParams = urlSearchParams.get('agent_id');
  const brainIdFromParams = urlSearchParams.get('brain_id');
  const departmentIdFromParams = urlSearchParams.get('department_id');
  const category = urlSearchParams.get('category');

  const {
    selectedAgent,
    selectedDepartment,
    selectedStatus,
    selectedBrain,
    title: selectedTitle,
  } = useSelector(selectView);

  useEffect(() => {
    const defaultView = {
      selectedAgent: '',
      selectedBrain: '',
      selectedStatus: 'open',
      title: '',
      selectedDepartment: '',
    };

    const handleDispatch = (overrides) => {
      dispatch(setView({ ...defaultView, ...overrides }));
    };

    switch (category) {
      case 'me':
        handleDispatch({
          selectedAgent: user?.user_id,
          title: category,
        });
        break;

      case 'unassigned':
        handleDispatch({
          selectedAgent: 'null',
          selectedBrain: 'null',
          title: category,
        });
        break;

      case 'all':
        handleDispatch({ title: category });
        break;

      case 'spam':
      case 'trash':
        handleDispatch({
          selectedStatus: category,
          title: category,
        });
        break;

      default:
        break;
    }

    if (brainIdFromParams) {
      handleDispatch({
        selectedBrain: 'brain',
      });
    }

    if (agentIdFromParams) {
      handleDispatch({
        selectedAgent: agentIdFromParams,
      });
    }

    if (departmentIdFromParams) {
      handleDispatch({
        selectedDepartment: departmentIdFromParams,
      });
    }
  }, [
    agentIdFromParams,
    departmentIdFromParams,
    dispatch,
    category,
    user?.user_id,
    brainIdFromParams,
    t,
  ]);

  const getTitleFromSelectedTitle = useCallback(() => {
    return selectedTitle ? t(`conversation.${selectedTitle}`) : null;
  }, [selectedTitle, t]);

  const getTitleFromSelectedAgent = useCallback(() => {
    return selectedAgent
      ? agents?.find((agent) => agent.agent_id === selectedAgent)?.name
      : null;
  }, [selectedAgent, agents]);

  const getTitleFromSelectedBrain = useCallback(() => {
    return selectedBrain ? t('conversation.assistant') : null;
  }, [selectedBrain, t]);

  const getTitleFromSelectedDepartment = useCallback(() => {
    return selectedDepartment
      ? departments?.find(
          (department) => department.department_id === selectedDepartment
        )?.name
      : null;
  }, [selectedDepartment, departments]);

  const title = useMemo(() => {
    return (
      getTitleFromSelectedTitle() ||
      getTitleFromSelectedAgent() ||
      getTitleFromSelectedBrain() ||
      getTitleFromSelectedDepartment() ||
      selectedStatus
    );
  }, [
    getTitleFromSelectedTitle,
    getTitleFromSelectedAgent,
    getTitleFromSelectedBrain,
    getTitleFromSelectedDepartment,
    selectedStatus,
  ]);

  return {
    title,
  };
};
