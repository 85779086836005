import { Icon } from '@/models/icon';

const Unassign = ({ color = 'var(--icon-default-gray)', size = 24 }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={size}
      height={size}
      viewBox="0 0 65.518 65.518"
      fill={color}
    >
      <path d="M32.759 0C14.696 0 0 14.695 0 32.759s14.695 32.759 32.759 32.759 32.759-14.695 32.759-32.759S50.822 0 32.759 0zM6 32.759C6 18.004 18.004 6 32.759 6c6.648 0 12.734 2.443 17.419 6.472L12.472 50.178C8.443 45.493 6 39.407 6 32.759zm26.759 26.759a26.617 26.617 0 0 1-15.895-5.248l37.405-37.405a26.617 26.617 0 0 1 5.248 15.895c.001 14.754-12.003 26.758-26.758 26.758z" />
    </svg>
  );
};

export default Unassign;
