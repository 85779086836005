import cn from 'classnames';
import { useDrag, useDrop } from 'react-dnd';

import styles from './ReorderWrapper.module.scss';
interface Item {
  index: number;
}

interface ReorderWrapperProps {
  dragId: string;
  children: React.ReactNode;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  dropItem: () => void;
  getIndex: () => number;
  placeholderHeight?: number;
  canDrag?: boolean;
}

const ReorderWrapper = ({
  dragId,
  children,
  index,
  moveItem,
  dropItem,
  getIndex,
  placeholderHeight = 24,
  canDrag = true,
}: ReorderWrapperProps) => {
  const [, drop] = useDrop({
    accept: dragId,
    hover: (hoveredItem: Item) => {
      const dragIndex = hoveredItem.index;
      const hoverIndex = index;

      if (dragIndex !== hoverIndex) {
        moveItem(dragIndex, hoverIndex);
        hoveredItem.index = getIndex();
      }
    },
    drop: () => {
      dropItem();
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    item: { index: getIndex() },
    type: dragId,
    isDragging: (monitor) => {
      const draggedItem = monitor.getItem();
      return draggedItem && draggedItem.index === getIndex();
    },
    canDrag: () => canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div className={styles.wrapper}>
      <div
        style={{ height: `${placeholderHeight}px` }}
        className={cn(styles.placeholder, {
          [styles.enabled]: isDragging,
        })}
      ></div>
      <div
        ref={dragPreview}
        className={cn({ [styles.isDragging]: isDragging })}
      >
        <div ref={drop}>
          <div ref={drag}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ReorderWrapper;
