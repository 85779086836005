import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAccount } from '@/hooks/useAccount';
import useExpressions from '@/hooks/useExpressions';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIntentRecommendation from '@/hooks/useIntentRecommendation';
import useIntents from '@/hooks/useIntents';
import { TreeItem } from '@/redux/dialogs/selectors';
import { selectIntentDraft } from '@/redux/expressions/selectors';
import { selectIntentName } from '@/redux/session/selectors';
import { UsedNodes } from '@/redux/usedNodes/actions';
import { selectUsedNodes } from '@/redux/usedNodes/selectors';

import SubNav from './SubNav';
import { groupIntents, makeCollectionName } from './utils';

interface CollectionProps {
  collection: string;
  new_collection: string;
}

interface TreeItemRecommended extends TreeItem {
  hasSuggestions?: boolean;
  needsAction?: boolean;
}

export type Collections = {
  [key: string]: TreeItemRecommended[];
};

const SubNavIntent = () => {
  const { t } = useTranslation();
  const { intentName, brainId } = useParams();
  const { slug } = useAccount();
  const {
    intents,
    listStatus,
    updateCollection,
    updateIntent,
    createDraftIntent,
    createStatus,
    optimalPhrases,
    handleCollectionUpdate,
  } = useIntents(brainId, intentName);
  const usedNodes = useSelector(selectUsedNodes) as UsedNodes;

  const intentDraft = useSelector(selectIntentDraft, shallowEqual);

  const sessionIntentName = useSelector(selectIntentName);

  const [lastCreatedCollection, setLastCreatedCollection] =
    useState<string>('');

  const { isDraft } = useExpressions(brainId, intentName);
  const { recommendedIntents } = useIntentRecommendation(brainId, intentName);
  const { ai_agents } = useFeatureFlag();

  const collections = useMemo(
    () =>
      groupIntents(
        slug,
        brainId,
        intents,
        recommendedIntents,
        t,
        usedNodes,
        sessionIntentName,
        optimalPhrases,
        intentDraft,
        isDraft,
        ai_agents
      ),
    [
      brainId,
      intentDraft,
      intents,
      isDraft,
      optimalPhrases,
      recommendedIntents,
      sessionIntentName,
      slug,
      t,
      usedNodes,
      ai_agents,
    ]
  );

  const onCreateCollection = useCallback(
    ({ id }) => {
      const collectionName = makeCollectionName(collections);
      updateIntent(
        {
          name: id,
          intent: { collection: collectionName },
        },
        {
          onSuccess: () => {
            setLastCreatedCollection(collectionName);
          },
        }
      );
    },
    [collections, updateIntent]
  );

  const handleCollectionClick = useCallback(
    (data: CollectionProps) => {
      updateCollection(data);
    },
    [updateCollection]
  );

  const handleCreateClick = useCallback(async () => {
    createDraftIntent();
  }, [createDraftIntent]);

  return (
    <SubNav
      title={t('common.intent')}
      navTitle={t('common.intents')}
      collections={collections}
      skeleton={listStatus === 'pending'}
      onCreateClick={handleCreateClick}
      onCollectionUpdate={handleCollectionUpdate}
      onCreateCollection={onCreateCollection}
      onCollectionSubmit={handleCollectionClick}
      lastCreatedCollection={lastCreatedCollection}
      isCreating={createStatus === 'pending'}
      subnavType="intents"
      whoIsViewing={[]}
    />
  );
};

export default SubNavIntent;
