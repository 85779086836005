import styles from './BranchList.module.scss';

const Ecclipse = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      fill="none"
      viewBox="0 0 9 9"
    >
      <circle cx="4.5" cy="4.5" r="3" stroke="#1B66D6" strokeWidth="1.5" />
    </svg>
  );
};

const BranchIntersection = () => {
  return (
    <>
      <div className={styles.intersectionWrapper}>
        <Ecclipse />
      </div>
    </>
  );
};

export default BranchIntersection;
