import cn from 'classnames';

import Link from '@/components/atoms/Link/Link';

import { LinkType } from '../ContextualHelp/ContextualHelp';

import styles from './TitleSubtitle.module.scss';

export interface TitleSubtitleProps {
  title?: string;
  subtitle: string | React.ReactNode;
  externalLink?: LinkType;
  noGutters?: boolean;
  size?: 'medium' | 'large';
}

const TitleSubtitle = ({
  title,
  subtitle,
  externalLink,
  noGutters = false,
  size = 'medium',
}: TitleSubtitleProps) => {
  return (
    <>
      <div
        className={cn(styles.root, styles[size], {
          [styles.noGutters]: noGutters,
        })}
      >
        {title && <span className={styles.root__title}>{title}</span>}
        <span className={styles.root__subtitle}>
          {subtitle}{' '}
          {externalLink && (
            <Link key={externalLink.label} href={externalLink.url} external>
              {externalLink.label}
            </Link>
          )}
        </span>
      </div>
    </>
  );
};

export default TitleSubtitle;
