import { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { callPost } from '@/api/fetcher';
import useCollections from '@/hooks/useCollections';
import useDatasources from '@/hooks/useDatasources';
import { createOAuthURL } from '@/hooks/useIntercom';
import {
  Datasource,
  DatasourceType,
  WebsiteConfig,
  IntercomConfig,
} from '@/models/collections';
import { OptionBase } from '@/models/common';
import { Integration } from '@/models/integration';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import {
  selectCreatedBrainId,
  selectDeskId,
  selectIntegrationId,
  selectLanguage,
} from '@/modules/onboarding/redux/selectors';
import { popModal } from '@/redux/modals/actions';
import {
  selectAccountSlug,
  selectCollectionId,
  selectDatasourceId,
} from '@/redux/session/selectors';
import { generateNextName, encodeBase64 } from '@/util/util';

import { datasourceOptions } from './SelectDataSourceType';
import { Form, validateDatasourceUrl, formattedUrls } from './utils';

export const steps = (t) => [
  { id: 0, completed: false, label: t('collections.select_source') },
  { id: 1, completed: false, label: t('integrations.configure') },
  { id: 2, completed: false, label: t('integrations.connect') },
];

const emptyDomain = [
  {
    name: '',
  },
];

type Props = {
  isImportWebsite?: boolean;
  isImportZendesk?: boolean;
  zendeskSubdomain?: string;
  isImportIntercom?: boolean;
  onDatasourceCreatedSuccess?: () => void;
  onDatasourceCreatedError?: () => void;
  onClose?: () => void;
  initialSubdomain?: string;
  submitCountRef?: React.MutableRefObject<number>;
};

export const useModalDatasource = ({
  isImportWebsite = false,
  isImportZendesk = false,
  zendeskSubdomain,
  isImportIntercom = false,
  onDatasourceCreatedSuccess,
  onDatasourceCreatedError,
  onClose,
  initialSubdomain,
  submitCountRef,
}: Props) => {
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();
  const [pageStep, setPageStep] = useState(0);
  const [selected, setSelected] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const collectionId = useSelector(selectCollectionId);
  const datasourceId = useSelector(selectDatasourceId);

  // for onboarding
  const desk_id = useSelector(selectDeskId);
  const integration_id = useSelector(selectIntegrationId);
  const brain_id = useSelector(selectCreatedBrainId);
  const language = useSelector(selectLanguage);

  const [zendeskLanguages, setZendeskLanguages] = useState<OptionBase[]>([]);
  const { collection } = useCollections(collectionId);
  const [externalKbState, setExternalKbState] = useState(null);

  const {
    datasource,
    createDatasource,
    updateDatasource,
    datasources,
    datasourceNames,
  } = useDatasources(collectionId, datasourceId);
  const dispatch = useDispatch();

  const initialName = `${t('common.my')} ${datasourceOptions(t)[0].value}`;
  const defaultValues = useMemo(
    () => ({
      name: initialName,
      integration: null,
      website: {
        seed_urls: emptyDomain,
        exclude_urls: emptyDomain,
        sitemap_urls: '',
      },
      intercom: null,
      zendesk: {
        subdomain: '',
        locale: '',
        access_token: '',
      },
    }),
    [initialName]
  );

  const formMethods = useForm<Form>({
    mode: 'onChange',
    defaultValues,
  });

  const { watch, getValues, trigger, setError, reset } = formMethods;

  const name = watch('name');

  useEffect(() => {
    if (initialSubdomain || isImportZendesk) {
      reset({
        ...defaultValues,
        name: `${t('common.my')} ${datasourceOptions(t)[2].value}`,
        zendesk: { subdomain: zendeskSubdomain || initialSubdomain || '' },
      } as Form);
    }
  }, [
    datasources,
    defaultValues,
    initialName,
    initialSubdomain,
    isImportZendesk,
    reset,
    zendeskSubdomain,
    t,
  ]);

  useEffect(() => {
    if (isImportWebsite) {
      setPageStep(1);
      setSelected(0);
    }
  }, [isImportWebsite, setPageStep, setSelected]);

  useEffect(() => {
    if (isImportZendesk) {
      setPageStep(1);
      setSelected(2);
    }
  }, [isImportZendesk, setPageStep, setSelected]);

  useEffect(() => {
    if (isImportIntercom) {
      setPageStep(1);
      setSelected(3);
    }
  }, [isImportIntercom, setPageStep, setSelected]);

  const openIntercomOauth = async () => {
    const intercomUrl = await createOAuthURL(
      slug,
      isImportIntercom
        ? ({ desk_id, integration_id } as Integration)
        : undefined,
      collectionId,
      name,
      isImportIntercom ? true : false,
      isImportIntercom ? language : undefined,
      isImportIntercom ? brain_id : undefined
    );
    window.open(intercomUrl, '_self');
  };

  const onSecondaryClick = () => {
    if (onClose) {
      onClose();
    }
    if (isImportWebsite || isImportZendesk || isImportIntercom) {
      dispatch(popModal());
    }
    if (pageStep > 0) {
      setPageStep((prev) => prev - 1);
    }
  };

  // Validates if the name of the datasource already exists
  useEffect(() => {
    if (name) {
      trigger('name');
    }
  }, [selected, name, trigger]);

  const onPrimaryClick = async () => {
    if (isLoading) {
      return;
    }
    const zendeskEmail = 'test@email.com';
    const zendeskAccessToken = '1234567890';
    const isDuplicateName = datasourceNames.includes(name?.toLowerCase());

    const newDatasourceName = isDuplicateName
      ? generateNextName(datasources, name)
      : name;

    if (pageStep === 0 && datasourceOptions(t)[selected]?.id !== 'doc') {
      setPageStep((prev) => prev + 1);
    } else if (
      pageStep === 1 &&
      datasourceOptions(t)[selected]?.id === 'intercom'
    ) {
      setIsLoading(true);
      const values = getValues();
      const config = values?.intercom as IntercomConfig;

      if (config) {
        const newDatasource: Partial<Datasource> = {
          type: DatasourceType.INTERCOM_KB,
          name: newDatasourceName,
          config,
        };
        createDatasource(newDatasource, {
          onSuccess: (d) => {
            if (onDatasourceCreatedSuccess) {
              onDatasourceCreatedSuccess();
            } else {
              navigate(
                `/${slug}/collections/${collectionId}/datasources/${d.datasource_id}`
              );
            }
            dispatch(popModal());
          },
          onError: () => {
            if (onDatasourceCreatedError) {
              onDatasourceCreatedError();
            }
            setIsLoading(false);
          },
        });
      } else {
        openIntercomOauth();
      }
    } else if (
      pageStep === 1 &&
      datasourceOptions(t)[selected]?.id === 'zendesk'
    ) {
      setIsLoading(true);
      const values = getValues();

      const encodedInfo = encodeBase64(
        `${zendeskEmail}/token:${zendeskAccessToken}`
      );
      try {
        const zendeskData = await callPost(
          `/www/url-validation/${values.zendesk.subdomain}`,
          { encodedInfo }
        );

        const filteredOptions = zendeskData.locales
          .filter((locale: string) => locale.includes(collection?.language))
          .map((key: string) => ({
            label: t([key], { ns: 'languages' }),
            value: key,
          }));

        setZendeskLanguages(filteredOptions);
        setIsLoading(false);
        setPageStep((prev) => prev + 1);
      } catch {
        setIsLoading(false);
        const error = new Error(t('collections.locale_error'));
        dispatch(addErrorTemporalToast(error));
      }
    } else if (
      pageStep === 2 &&
      datasourceOptions(t)[selected]?.id === 'zendesk'
    ) {
      const values = getValues();
      if (!values.zendesk.locale) {
        dispatch(popModal());
        return;
      }
      setIsLoading(true);
      const newDatasource: Partial<Datasource> = {
        type: DatasourceType.ZENDESK_KB,
        name: newDatasourceName,
        config: {
          access_token: zendeskAccessToken,
          subdomain: values.zendesk.subdomain,
          locales: [values.zendesk.locale],
          email: zendeskEmail,
        },
      };

      createDatasource(newDatasource, {
        onSuccess: (d) => {
          if (onDatasourceCreatedSuccess) {
            onDatasourceCreatedSuccess();
          } else {
            navigate(
              `/${slug}/collections/${collectionId}/datasources/${d.datasource_id}`
            );
          }
          dispatch(popModal());
        },
        onError: () => {
          if (onDatasourceCreatedError) {
            onDatasourceCreatedError();
          }
          setIsLoading(false);
        },
      });
    } else if (
      !isImportWebsite &&
      pageStep === 1 &&
      datasourceOptions(t)[selected]?.id === 'web'
    ) {
      setIsLoading(true);
      submitCountRef.current += 1;

      const values = getValues();

      const {
        urls: seed_urls,
        error: seedError,
        externalKb,
      } = await validateDatasourceUrl(
        formattedUrls(values.website.seed_urls),
        setError,
        'seed_urls',
        t,
        collectionId,
        submitCountRef.current
      );

      const exclude_urls = formattedUrls(values.website.exclude_urls);

      if (seedError) {
        setIsLoading(false);
        if (externalKb) {
          setExternalKbState(externalKb);
        }
        return;
      }

      const newDatasource: Partial<Datasource> = {
        type: DatasourceType.WEBSITE,
        name: newDatasourceName,
        config: {
          seed_urls,
          exclude_urls,
          sitemap_urls: values.website.sitemap_urls
            ? [values.website.sitemap_urls]
            : undefined,
        },
      };
      createDatasource(newDatasource, {
        onSuccess: (d) => {
          navigate(
            `/${slug}/collections/${collectionId}/datasources/${d.datasource_id}`
          );
          dispatch(popModal());
        },
        onError: () => {
          setIsLoading(false);
        },
      });
    } else if (pageStep === 0 && datasourceOptions(t)[selected]?.id === 'doc') {
      const newDatasource: Partial<Datasource> = {
        type: DatasourceType.FILES,
        name: newDatasourceName,
        config: {},
      };
      createDatasource(newDatasource, {
        onSuccess: (d) => {
          navigate(
            `/${slug}/collections/${collectionId}/datasources/${d.datasource_id}`
          );
          dispatch(popModal());
        },
      });
    }
    if (isImportWebsite) {
      setIsLoading(true);

      const values = getValues();
      const { urls: seed_urls, error: seedError } = await validateDatasourceUrl(
        formattedUrls(values.website.seed_urls),
        setError,
        'seed_urls',
        t,
        collectionId
      );

      const { urls: exclude_urls, error: excludeError } =
        await validateDatasourceUrl(
          formattedUrls(values.website.exclude_urls),
          setError,
          'exclude_urls',
          t,
          collectionId
        );

      if (seedError || excludeError) {
        setIsLoading(false);
        return;
      }

      const config = datasource.config as WebsiteConfig;

      const newDatasource: Datasource = {
        ...datasource,
        config: {
          ...datasource.config,
          seed_urls: [...seed_urls],
          exclude_urls: [...exclude_urls],
          sitemap_urls: values.website.sitemap_urls
            ? [...config.sitemap_urls, values.website.sitemap_urls]
            : config.sitemap_urls,
        },
      };

      updateDatasource(newDatasource, {
        onSuccess: () => {
          dispatch(popModal());
        },
        onError: () => {
          setIsLoading(false);
        },
      });
    }
  };

  const resetExternalKbState = useCallback(() => {
    setExternalKbState(null);
  }, []);

  return {
    onPrimaryClick,
    onSecondaryClick,
    isLoading,
    selected,
    setSelected,
    setPageStep,
    formMethods,
    pageStep,
    zendeskLanguages,
    externalKbState,
    resetExternalKbState,
  };
};
