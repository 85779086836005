import React from 'react';

import styles from './SidebarBox.module.scss';

interface Props {
  children: React.ReactNode;
}
export const SidebarBox = ({ children }: Props) => {
  return <section className={styles.container}>{children}</section>;
};
