import Menu, { MenuProps } from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/system';

export const MENU_STYLES = {
  '& .MuiPaper-root': {
    width: 'fit-content',
    padding: 'var(--space-8)',
    boxShadow: 'var(--shadow-primary)',
    border: '0.5px solid var(--border-default-gray)',
    borderRadius: 'var(--border-radius-secondary)',
  },
  '& .MuiDivider-root': {
    height: '1px',
    margin: 'var(--space-4) 0',
  },
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiMenuItem-root': {
    padding: 'calc(var(--space-12) / 2) var(--space-8)',
    color: 'var(--text-default-black)',
    gap: 'var(--space-8)',
    height: 'auto',

    '& + .MuiDivider-root': {
      margin: 'var(--space-4) 0',
    },

    svg: {
      color: 'var(--icon-default-gray)',
    },
  },
};

const StyledMenu = styled(Menu)(() => MENU_STYLES);

const CustomModal = styled(Modal)(() => {
  return {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  };
});

export const CustomMenu = ({ ...props }: MenuProps) => {
  return (
    <StyledMenu
      slots={{ root: CustomModal }}
      MenuListProps={{ disablePadding: true }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={Zoom}
      {...props}
    />
  );
};
