import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Editor } from 'slate';
import { useSlate, useSlateSelection } from 'slate-react';

import Link from '@/components/atoms/Icons/Link';
import { popModal, pushModal } from '@/redux/modals/actions';
import { urlRulesWithContextVariables } from '@/util/validator';

import { StyleButton } from './StyleButton';
import { MODAL_EDIT_MULTIPLE } from '../../Modals/ModalConductor';
import {
  changeLink,
  getLinkText,
  insertLink,
  isLinkActive,
  unwrapLink,
} from '../utils';

export const LinkButton = ({ handleBlur }) => {
  const { t } = useTranslation();
  const selection = useSlateSelection();
  const editor = useSlate();
  const newText = selection ? Editor.string(editor, selection) : '';

  const handleLinkButtonClick = useCallback(
    (event, props) => {
      if (event.button !== 0) {
        return;
      }
      const { dispatch, editor, selectedText } = props;
      const linkProps = {
        title: t('rich_text_editor.add_link'),
        primaryButtonText: t('common.save'),
        onEdit: ({ url, text }) => {
          try {
            if (url) {
              if (isLinkActive(editor)) {
                changeLink(editor, text, url);
              } else {
                insertLink(editor, url, text);
              }
            } else {
              unwrapLink(editor);
            }
          } catch (error) {
            console.error(
              'Error occurred in handleLinkButtonClick onEdit:',
              error
            );
          }
          dispatch(popModal());
          setTimeout(() => {
            if (handleBlur) {
              handleBlur();
            }
          }, 0);
        },
        fields: [
          {
            fieldName: 'text',
            fieldValue: isLinkActive(editor)
              ? getLinkText(editor, 'text')
              : selectedText,
          },
          {
            fieldName: 'url',
            fieldValue: getLinkText(editor, 'link'),
            rules: urlRulesWithContextVariables.url,
          },
        ],
      };
      dispatch(pushModal(MODAL_EDIT_MULTIPLE, linkProps));
    },
    [handleBlur, t]
  );

  return (
    <StyleButton
      type="link"
      Icon={Link}
      onClick={handleLinkButtonClick}
      selectedText={newText}
    />
  );
};
