/* eslint-disable no-prototype-builtins */
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

import { Action } from '@/models/action';
import { Condition, Node, Requisite } from '@/models/node';

export type CopiedObject = Node | Condition | Requisite | Action;

export class CopyPasteController {
  private static readonly ids = [
    'node_id',
    'action_id',
    'condition_id',
    'requisite_id',
    'rule_id',
  ];

  private static readonly signature = 'moveo-signature';

  static updateIds = (obj: CopiedObject): CopiedObject => {
    if (!obj) {
      return null;
    }
    const clonedObj = this.clone(obj);
    this.traverse(clonedObj);
    return clonedObj;
  };

  static removeKey = (obj: CopiedObject, property: string): CopiedObject => {
    if (!obj) {
      return null;
    }
    const clonedObj = this.clone(obj);
    if (clonedObj.hasOwnProperty(property)) {
      delete clonedObj[property];
    }
    return clonedObj;
  };

  static addKey = (
    obj: CopiedObject,
    property: string,
    value: string
  ): CopiedObject => {
    if (!obj) {
      return null;
    }
    const clonedObj = this.clone(obj);
    clonedObj[property] = value;
    return clonedObj;
  };

  static checkSignature = (obj: CopiedObject): boolean => {
    return obj.hasOwnProperty(this.signature);
  };

  static getObjectType = (obj: CopiedObject): string => {
    if (obj.hasOwnProperty('condition_id')) {
      return 'condition';
    }

    if (obj.hasOwnProperty('requisite_id')) {
      return 'requisite';
    }

    if (obj.hasOwnProperty('node_id')) {
      return 'node';
    }

    if (obj.hasOwnProperty('action_id')) {
      return 'action';
    }

    return 'unknown';
  };

  private static traverse = (o: CopiedObject): void => {
    for (const i in o) {
      if (this.ids.includes(i)) {
        o[i] = uuidv4();
      } else if (Array.isArray(o[i])) {
        o[i].forEach((item) => {
          this.traverse(item);
        });
      } else if (typeof o[i] === 'object' && o[i] !== null) {
        this.traverse(o[i]);
      }
    }
  };

  private static clone = (obj: CopiedObject): CopiedObject => {
    return cloneDeep(obj);
  };
}
