import { useCallback, useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import Input from '@/components/atoms/Input/Input';
import { useAccount } from '@/hooks/useAccount';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Brain } from '@/models/brain';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { modelNameMapper } from '@/modules/developerTools/pages/languageModels/helper';
import { popModal } from '@/redux/modals/actions';
import { resolveBrainsPath } from '@/util/util';

import ModalDelete from './ModalDelete';
import ModalWarn from './ModalWarn';

import styles from './ModalLanguageModelDelete.module.scss';

export const ModalLanguageModelDelete = ({ languageModelId }) => {
  const navigate = useNavigate();
  const { slug } = useAccount();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();
  const [deleteName, setDeleteName] = useState('');
  const [enableButton, setEnableButton] = useState(false);

  const {
    deleteLanguageModel,
    deleteStatus,
    languageModelBrains,
    languageModel,
  } = useLanguageModels(languageModelId);

  const handleDeleteOnCharge = useCallback((event) => {
    setDeleteName(event.target.value);
  }, []);

  const handleDelete = useCallback(() => {
    deleteLanguageModel(languageModelId, {
      onSettled: () => {
        dispatch(popModal());
      },
    });
  }, [deleteLanguageModel, dispatch, languageModelId]);

  useEffect(() => {
    setEnableButton(
      deleteName.trim().toUpperCase() ===
        t('common.delete').trim().toUpperCase()
    );
  }, [deleteName, t]);

  const subtitle = (
    <Trans
      i18nKey="developer_tools.delete_language_models"
      values={[languageModel?.name]}
    />
  );

  if (languageModelBrains?.length === 0) {
    return <ModalDelete subtitle={subtitle} confirm onDelete={handleDelete} />;
  }

  return (
    <ModalWarn
      primaryButtonDisable={!enableButton}
      onPrimarySubmit={handleDelete}
      primaryButtonText={t('common.delete')}
      title={t('developer_tools.delete_language_model', {
        0: modelNameMapper(languageModel?.provider),
      })}
    >
      {deleteStatus === 'pending' ? (
        <span className={styles.spinner}>
          <CircularProgress size={40} />
        </span>
      ) : (
        <>
          <span className={styles.banner}>
            <Banner<Partial<Brain>>
              relativePosition
              variant="critical"
              referenceProp="brain_id"
              references={languageModelBrains}
              labelKey="name"
              onRefClick={(id) => {
                dispatch(popModal());
                navigate(resolveBrainsPath(`/${slug}/brains/${id}`, ai_agents));
              }}
            >
              <Trans
                i18nKey={'developer_tools.delete_warning'}
                values={{ 0: languageModel?.model }}
              />
            </Banner>
          </span>
          <Input
            placeholder={t('common.delete')}
            autoFocus
            size="medium"
            onChange={handleDeleteOnCharge}
            label={t('common.type_to_delete', { 0: t('common.delete') })}
          />
        </>
      )}
    </ModalWarn>
  );
};
