import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import LinkExternal from '@/components/atoms/Icons/LinkExternal';
import { useAccount } from '@/hooks/useAccount';
import useDialogNodesCache from '@/hooks/useDialogNodesCache';
import useExpressions from '@/hooks/useExpressions';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIntents from '@/hooks/useIntents';
import { pushModal } from '@/redux/modals/actions';
import { resolveBrainsPath } from '@/util/util';

import { Wrapper } from './Wrapper';
import { MODAL_CONNECT_INTENT } from '../../Modals/ModalConductor';

import styles from './Wrapper.module.scss';

interface Props {
  phraseCount: number;
  intentName: string;
  brainId: string;
  isLoading: boolean;
}

const MINIMUM_EXPRESSIONS_LENGTH = 0;

export const Performance = ({
  phraseCount,
  intentName,
  brainId,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { intent, optimalPhrases } = useIntents(brainId, intentName);
  const { isDraft } = useExpressions(brainId, intentName);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { findUsedNodes } = useDialogNodesCache();
  const { slug } = useAccount();
  const { ai_agents } = useFeatureFlag();
  const usedIn = findUsedNodes({ brainId, name: intentName });
  const nLength = usedIn?.length;

  const handleCreateDialog = () => {
    dispatch(pushModal(MODAL_CONNECT_INTENT, { intentName, brainId }));
  };

  return (
    <Wrapper phrases={phraseCount} optimalPhrases={optimalPhrases}>
      {isLoading && !isDraft && <Skeleton width={140} height={45} />}
      {nLength > 0 && !isLoading && (
        <div className={styles.nodes}>
          <Typography variant="body-semi-bold" color="var(--text-default-gray)">
            {t('intent.connected_flows')}
          </Typography>
          {usedIn.map((x) => (
            <button
              key={x.nodeId}
              type="button"
              onClick={() =>
                navigate(
                  resolveBrainsPath(
                    `/${slug}/brains/${brainId}/dialogs/${x.dialogId}`,
                    ai_agents
                  )
                )
              }
            >
              {x.label}
              <LinkExternal />
            </button>
          ))}
        </div>
      )}
      {!nLength &&
        !isLoading &&
        intent?.expressions?.length > MINIMUM_EXPRESSIONS_LENGTH &&
        intentName !== 'offtopics' && (
          <Banner
            variant="warning"
            customDismiss={t('common.connect')}
            customDismissAction={handleCreateDialog}
            relativePosition
          >
            {t('intent.no_flow')}
          </Banner>
        )}
    </Wrapper>
  );
};
