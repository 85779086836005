import { Icon } from '@/models/icon';

export const Spam = ({ color = 'currentColor', size = 16 }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.4"
        d="M8 4.5v4.2"
      />
      <circle
        cx="8"
        cy="11.5"
        r=".5"
        fill="var(--icon-default-gray)"
        stroke={color}
        strokeWidth=".4"
      />
      <path
        stroke={color}
        d="M10.917 1H5.083a.2.2 0 0 0-.142.059L1.06 4.94A.2.2 0 0 0 1 5.083v5.834a.2.2 0 0 0 .059.142L4.94 14.94a.2.2 0 0 0 .142.059h5.834a.2.2 0 0 0 .142-.059l3.882-3.882a.2.2 0 0 0 .059-.142V5.083a.2.2 0 0 0-.059-.142L11.06 1.06A.2.2 0 0 0 10.917 1Z"
      />
    </svg>
  );
};
