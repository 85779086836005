import { useMemo } from 'react';

import { Control, FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import useDialogs from '@/hooks/useDialogs';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import styles from './CarouselWebview.module.scss';
interface EventProps<FormType> {
  updateRedux: () => void;
  register: UseFormRegister<FormType>;
  errors?: FieldErrors<FormType>;
  index?: number;
  cardIndex?: number;
  control: Control<FormType>;
}

const CarouselWebview = <FormType,>({
  // onBlur,
  updateRedux,
  register,
  errors,
  index,
  cardIndex,
}: EventProps<FormType>) => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);

  const { eventsOptions } = useDialogs(brainId);
  const heightOptions = useMemo(
    () => [
      { value: 'tall', label: t('dialog.webview.tall') },
      { value: 'compact', label: t('dialog.webview.compact') },
      { value: 'full', label: t('dialog.webview.full') },
    ],
    [t]
  );

  return (
    <>
      <Input
        error={!!errors?.['url']}
        errorMessage={capitalizeFirstLetter(errors?.['url']?.message)}
        name="url"
        onChange={updateRedux}
        placeholder={t('dialog.carousel.url_placeholder')}
        register={register('url' as Path<FormType>)}
        size="small"
      />
      <div className={styles.wrapper}>
        <Select
          label={t('dialog.trigger_node_placeholder')}
          placeholder={t('dialog.select_trigger_node')}
          name="trigger_node_id"
          id={`trigger_node_id-carousel-${index ? index : ''}-${cardIndex}`}
          onChange={updateRedux}
          options={eventsOptions}
          error={!!errors?.['trigger_node_id']}
          register={register('trigger_node_id' as Path<FormType>)}
          enablePlaceholder
          size="small-full"
        />
        <Select
          label={t('dialog.height_placeholder')}
          placeholder={t('common.select_an_option')}
          name="height"
          id={`height-carousel-${index ? index : ''}-${cardIndex}`}
          onChange={updateRedux}
          options={heightOptions}
          error={!!errors?.['height']}
          register={register('height' as Path<FormType>)}
          enablePlaceholder
          size="small-full"
        />
      </div>
    </>
  );
};

export default CarouselWebview;
