import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';

const selectAnalytics = (state: RootState) => state.analytics.accountAnalytics;

const selectAnalyticsStartDate = (state: RootState) =>
  state.analytics.startDate;
const selectAnalyticsEndDate = (state: RootState) => state.analytics.endDate;

const selectFiltersLoaded = (state: RootState) => state.analytics.filtersLoaded;
const selectLogs = (state: RootState) => state.analytics.logs;

export const selectFilters = createSelector(
  [
    selectAnalytics,
    selectAnalyticsStartDate,
    selectAnalyticsEndDate,
    selectFiltersLoaded,
    selectLogs,
  ],
  (analytics, startDate, endDate, filtersLoaded, logs) => ({
    analytics,
    startDate,
    endDate,
    filtersLoaded,
    logs,
  })
);

const selectFilterPage = (state: RootState, filterPage: string) =>
  state[filterPage];

export const selectStartDate = createSelector(
  [selectFilterPage],
  (filterPage) => filterPage.startDate
);

export const selectEndDate = createSelector(
  [selectFilterPage],
  (filterPage) => filterPage.endDate
);

const selectAuditLogsStartDate = (state: RootState) =>
  state.auditLogs.startDate;
const selectAuditLogsEndDate = (state: RootState) => state.auditLogs.endDate;

export const selectAnalyticsDates = createSelector(
  [selectAnalyticsStartDate, selectAnalyticsEndDate],
  (startDate, endDate) => ({ startDate, endDate })
);

export const selectAuditLogsDates = createSelector(
  [selectAuditLogsStartDate, selectAuditLogsEndDate],
  (startDate, endDate) => ({ startDate, endDate })
);
