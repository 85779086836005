import { forwardRef, ReactNode } from 'react';

import Button from '@/components/atoms/Button/Button/Button';

import styles from './ConditionsModal.module.scss';

interface Props {
  children?: ReactNode | string;
  title: string;
  buttonText: string;
  handleSubmit: () => void;
  isDirty?: boolean;
}

const ConditionsModal = forwardRef<HTMLDivElement, Props>(
  (
    { children, title, buttonText, handleSubmit, isDirty = false }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div className={styles.wrapper} ref={ref}>
        <div className={styles.title}>{title}</div>
        <div className={styles.children}>{children}</div>
        <div className={styles.footer}>
          <Button
            variant="tertiary"
            onClick={handleSubmit}
            onKeyDown={handleSubmit}
            disabled={isDirty}
            size="small"
          >
            {buttonText}
          </Button>
        </div>
      </div>
    );
  }
);

ConditionsModal.displayName = 'ConditionsModal';

export default ConditionsModal;
