import { Entity } from '@/models/tryIt';

import styles from '../MessageNode/MessageNode.module.scss';

/**
 * Highlight entities in the text by wrapping them in buttons.
 * @param text - The input text.
 * @param entities - An array of entities.
 * @param onGoToEntity - Callback function when an entity button is clicked.
 * @returns The text with highlighted entities.
 */
export const highlightEntities = (
  text: string,
  entities: Entity[] | undefined,
  onGoToEntity: (entity: string) => void
): React.ReactNode[] => {
  if (!entities || entities.length === 0 || !text) {
    return [text];
  }

  // Sort entities by start position
  const sortedEntities = [...entities].sort((a, b) => a.start - b.start);

  // Create a map of characters to entity indices
  const entityMap = new Map<number, number>();
  sortedEntities.forEach((entity, index) => {
    for (let i = entity.start; i < entity.end; i++) {
      entityMap.set(i, index);
    }
  });

  const output: React.ReactNode[] = [];
  const chars = Array.from(text); // Correctly iterate over characters

  for (let i = 0; i < chars.length; i++) {
    const entityIdx = entityMap.get(i);
    if (entityIdx !== undefined) {
      const entity = sortedEntities[entityIdx];
      if (i === entity.start) {
        // Insert the entity as a button
        output.push(
          <button
            key={`entity-${entity.start}-${entity.end}`}
            type="button"
            onClick={() => onGoToEntity(entity.entity)}
            className={styles.entity}
          >
            {text.substring(entity.start, entity.end)}
          </button>
        );
        // Skip the entity's characters
        i = entity.end - 1;
      }
    } else {
      output.push(chars[i]);
    }
  }
  return output;
};
