import { IconInterface } from '@/models/icon';

export const Azure = ({ size = 24 }: IconInterface) => (
  <svg
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <path
      d="M37.782 15.453H59.48l-22.525 66.74a3.46 3.46 0 0 1-3.277 2.353H16.79a3.455 3.455 0 0 1-3.274-4.559l20.986-62.18a3.46 3.46 0 0 1 3.279-2.354Z"
      fill="url(#a)"
    />
    <path
      d="M69.313 60.218H34.904a1.593 1.593 0 0 0-1.087 2.757l22.11 20.637c.643.6 1.491.934 2.371.934h19.484l-8.47-24.329Z"
      fill="#0078D4"
    />
    <path
      d="M37.782 15.453a3.431 3.431 0 0 0-3.286 2.4L13.543 79.93a3.45 3.45 0 0 0 3.257 4.615h17.323a3.701 3.701 0 0 0 2.841-2.417l4.178-12.314 14.925 13.92a3.53 3.53 0 0 0 2.222.81H77.7l-8.513-24.328-24.818.006 15.19-44.77H37.781Z"
      fill="url(#b)"
    />
    <path
      d="M65.496 17.803a3.454 3.454 0 0 0-3.273-2.35H38.04a3.455 3.455 0 0 1 3.273 2.35L62.3 79.987a3.455 3.455 0 0 1-3.273 4.56H83.21a3.454 3.454 0 0 0 3.273-4.56L65.496 17.803Z"
      fill="url(#c)"
    />
    <defs>
      <linearGradient
        id="a"
        x1="45.69"
        y1="20.573"
        x2="23.156"
        y2="87.145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#114A8B" />
        <stop offset="1" stopColor="#0669BC" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="52.729"
        y1="51.597"
        x2="47.517"
        y2="53.36"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity=".3" />
        <stop offset=".071" stopOpacity=".2" />
        <stop offset=".321" stopOpacity=".1" />
        <stop offset=".623" stopOpacity=".05" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="49.862"
        y1="18.632"
        x2="74.598"
        y2="84.532"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3CCBF4" />
        <stop offset="1" stopColor="#2892DF" />
      </linearGradient>
    </defs>
  </svg>
);
