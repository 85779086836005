import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useAccount } from '@/hooks/useAccount';
import { LogSessionContent, LogSessionsPreview } from '@/hooks/useLogs';
import { LogsQueryVariables } from '@/modules/analytics/models';
import {
  selectChannelUserId,
  selectSessionId,
} from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

interface Avatar {
  author_type?: 'brain' | 'visitor' | 'agent';
  src?: string;
}

const MESSAGE_LENGTH = 15;

const SESSIONS_PREVIEW = gql`
  query GetSessions(
    $accountId: uuid
    $channelUserId: String
    $endDate: timestamp
    $startDate: timestamp
  ) {
    rows: get_sessions(
      args: {
        account_id: $accountId
        channel_user_id: $channelUserId
        start_time: $startDate
        end_time: $endDate
      }
    ) {
      session_id
    }
  }
`;

export const SESSION_CONTENT = gql`
  query GetSessionContent($sessionId: String) {
    rows: get_session_content(args: { session_id: $sessionId }) {
      messages
    }
  }
`;

export const useRelatedConversations = (sessionId?: string) => {
  const channelUserId = useSelector(selectChannelUserId);
  const { account } = useAccount();
  const currentSessionId = useSelector(selectSessionId);

  const dateRange = useMemo(() => {
    return {
      startDate: moment()
        .subtract(account?.max_log_retention, 'days')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
  }, [account?.max_log_retention]);

  const { data: relatedConversations } = useQuery<
    LogSessionsPreview,
    LogsQueryVariables
  >(SESSIONS_PREVIEW, {
    variables: {
      channelUserId,
      accountId: account?.account_id,
      ...dateRange,
    },
    skip: !channelUserId || !account?.account_id,
  });

  const { data: sessionContent } = useQuery<
    LogSessionContent,
    LogsQueryVariables
  >(SESSION_CONTENT, {
    variables: {
      sessionId,
    },
    skip: !sessionId,
  });

  // Pick only messages events
  const messages =
    sessionContent?.rows[0]?.messages?.filter((row) => {
      return (
        row?.event === 'message:send' ||
        row?.event === 'message:brain_send' ||
        row?.event === 'message:received'
      );
    }) ?? [];

  // Get last message to show in preview
  const lastMessage = messages[messages?.length - 1];

  // Type guard to get text from last message
  const text = lastMessage?.message as {
    text?: string;
    responses?: { text?: string; type?: string }[];
    attachments?: { type?: string }[];
  };

  const relatedConversation = useMemo(() => {
    if (!lastMessage) return null;
    const avatar: Avatar = {};
    let message = '';

    if (text?.text) {
      message = text?.text;
    } else if (text?.responses) {
      message = text?.responses[0]?.text ?? text?.responses[0]?.type;
    } else if (text?.attachments) {
      message = capitalizeFirstLetter(text?.attachments[0]?.type);
    }

    // Format avatar regarding the origin of the last message
    if (lastMessage?.brain_id || lastMessage?.brain_parent_id) {
      avatar.author_type = 'brain';
    } else if (lastMessage?.agent_id) {
      avatar.author_type = 'agent';
      avatar.src = lastMessage?.agent_id;
    } else {
      avatar.author_type = 'visitor';
    }
    // Trim message if it's too long
    if (message?.length > MESSAGE_LENGTH) {
      message = message?.slice(0, MESSAGE_LENGTH) + '...';
    }

    return {
      avatar,
      last_message: message,
      last_message_at: lastMessage?.time,
    };
  }, [lastMessage, text]);

  return {
    relatedConversations: relatedConversations?.rows?.filter(
      (row) => row.session_id !== currentSessionId
    ),
    relatedConversation,
  };
};
