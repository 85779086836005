import { useTranslation } from 'react-i18next';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import Input from '@/components/atoms/Input/Input';
import useUser from '@/hooks/useUser';

import styles from './styles.module.scss';

const InvitationsEmpty = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const invitationLink = user?.email ?? '';

  return (
    <PlainFieldset
      title={t('invitations.empty')}
      subtitle={t('invitations.empty_subtitle')}
    >
      <div className={styles.email}>
        <Input
          label="email"
          placeholder=""
          size="large"
          defaultValue={invitationLink}
          readOnly
        />
        <CopyButton className={styles.copy_email} data={invitationLink} />
      </div>
    </PlainFieldset>
  );
};

export default InvitationsEmpty;
