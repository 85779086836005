import {
  useState,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
  useRef,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { RootState } from '@/models/state';
import { AnalyticsType } from '@/modules/analytics/models';
import { selectFiltersByType } from '@/modules/TryIt/redux/selectors';
import { applyFilters as applyFiltersInAuditLogs } from '@/redux/auditLogs/actions';
import { selectAuditLogFilters } from '@/redux/auditLogs/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { urlSafeBase64Encode } from '@/util/util';

import { formatChannelFilters } from './useContentFilters';
import { applyFilters } from '../redux/actions';

interface Option {
  value: string;
  label: string;
  checked: boolean;
}

interface Props {
  items: { [key: string]: string | string[] }[];
  dateOpen: boolean;
  filterOpen: boolean;
  setDateOpen: Dispatch<SetStateAction<boolean>>;
  key: string;
  filterKey: string;
}

const useExternalFilter = ({
  items,
  dateOpen,
  key,
  filterKey,
  filterOpen,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [urlParams] = useSearchParams();
  const [showItems, setShowItems] = useState(false);
  const slug = useSelector(selectAccountSlug);

  const [selectedItems, setSelectedItems] = useState<Array<Option>>([]);

  const [itemOptions, setItemOptions] = useState<Array<Option>>([]);

  const { pathname } = useLocation();
  const isAnalyticsFilter = pathname?.includes('/analytics');

  const filters = useSelector(
    (state: RootState) =>
      isAnalyticsFilter
        ? selectFiltersByType(state, AnalyticsType.ACCOUNT)
        : selectAuditLogFilters(state),
    shallowEqual
  );

  const itemsRef = useRef(null);

  useEffect(() => {
    setSelectedItems([]);
    setItemOptions([]);
  }, [slug]);

  const computeOptions = useCallback(() => {
    const options = items?.map((item) => {
      const checked = !!filters
        ?.find((filter) => !!filter?.[filterKey])
        ?.[
          filterKey
        ]?.find((filter: Omit<Option, 'checked'>) => item[key] === filter.value);

      return {
        value: item[key] as string,
        label: item.name as string,
        checked,
      };
    });
    setItemOptions(options != undefined ? options : []);
    setSelectedItems(options?.filter((item) => item.checked));
  }, [filterKey, filters, items, key]);

  useEffect(() => {
    const storeItem = filters.find((filter) => filter.type === filterKey)?.[
      filterKey
    ];

    if (!isEqual(itemsRef.current, storeItem) && itemOptions?.length > 0) {
      computeOptions();
      itemsRef.current = storeItem;
    }
  }, [computeOptions, filterKey, filters, itemOptions?.length, items, key]);

  useEffect(() => {
    if (itemOptions?.length === 0 && items && items.length > 0) {
      computeOptions();
    }
  }, [computeOptions, itemOptions?.length, items]);

  const applyItems = useCallback(() => {
    const preUpdatedItems = itemOptions
      ?.filter(({ checked }) => checked)
      .map(({ value, label }) => ({
        value,
        label,
      }));
    let updatedItemsWithChecked = [];
    let updatedItems = itemOptions.reduce((acc, { value, label, checked }) => {
      updatedItemsWithChecked = [
        ...updatedItemsWithChecked,
        { value, label, checked },
      ];

      if (checked) {
        acc.push({ value, label });
      }
      return acc;
    }, []);

    // When all items are unchecked, we don't want to apply any filter
    if (updatedItems.length === 0) {
      updatedItems = preUpdatedItems;
    }

    let newFilters = [];
    const existingItemFilter = filters.find(
      (filter) => filter.type === filterKey
    );

    setItemOptions(updatedItemsWithChecked);
    setSelectedItems(updatedItemsWithChecked?.filter((item) => item.checked));

    if (existingItemFilter) {
      newFilters = formatChannelFilters(filters)?.reduce((acc, f) => {
        if (
          f[filterKey] &&
          typeof f[filterKey] === 'object' &&
          updatedItems?.length === 0
        ) {
          return acc;
        } else if (f[filterKey]) {
          acc.push({
            type: filterKey,
            [filterKey]: updatedItems.map(({ value, label }) => ({
              value,
              label,
            })),
          });
        } else {
          acc.push(f);
        }
        return acc;
      }, []);
    } else {
      if (updatedItems && updatedItems.length > 0) {
        newFilters = [
          ...formatChannelFilters(filters),
          {
            type: filterKey,
            [filterKey]: updatedItems.map(({ value, label }) => ({
              value,
              label,
            })),
          },
        ];
      } else {
        newFilters = [...formatChannelFilters(filters)];
      }
    }

    dispatch(
      isAnalyticsFilter
        ? applyFilters({
            filters: newFilters,
            type: AnalyticsType.ACCOUNT,
          })
        : applyFiltersInAuditLogs({
            filters: newFilters,
          })
    );

    navigate(
      {
        ...window.location,
        search: `${urlParams.get('date') ? `date=${urlParams.get('date')}` : ''}${
          !isEmpty(newFilters)
            ? `&filters=${urlSafeBase64Encode(JSON.stringify(newFilters))}`
            : ''
        }`,
      },
      {
        replace: true,
      }
    );

    setShowItems(false);
  }, [
    itemOptions,
    filters,
    dispatch,
    isAnalyticsFilter,
    urlParams,
    filterKey,
    navigate,
  ]);

  const handleClearItems = useCallback(() => {
    const filteredOptions = items?.map((item) => {
      return {
        value: item[key] as string,
        label: item.name as string,
        checked: false,
      };
    });
    setItemOptions(filteredOptions);
    setSelectedItems([]);
  }, [items, key]);

  const getItemsDisplayValue = useCallback(
    (none, all) => {
      if (
        selectedItems &&
        items &&
        selectedItems?.length > 0 &&
        selectedItems?.length !== items?.length
      ) {
        return (
          <>
            {selectedItems[0]?.label}
            {selectedItems?.length > 1 ? ` +${selectedItems?.length - 1}` : ''}
          </>
        );
      }
      if (selectedItems?.length === items?.length) {
        return `${all} (${items ? items?.length : ''})`;
      }

      return none;
    },
    [items, selectedItems]
  );

  const toggleItems = useCallback(() => {
    if (!dateOpen && !filterOpen) {
      setShowItems(true);
    }
  }, [dateOpen, filterOpen]);

  const handleItemsChange = useCallback((options: Option[]) => {
    setSelectedItems(options?.filter((env) => env.checked));
    setItemOptions(options);
  }, []);

  const noItemsSelected =
    itemOptions?.filter((item) => item.checked).length === 0;

  return {
    handleClearItems,
    applyItems,
    toggleItems,
    showItems,
    itemOptions,
    setShowItems,
    getItemsDisplayValue,
    handleItemsChange,
    noItemsSelected,
  };
};

export default useExternalFilter;
