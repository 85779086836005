import { useEffect, useRef, useState } from 'react';

import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import styles from './ModalAutoBrain.module.scss';

const AutoBrainLoading = () => {
  const [text, setText] = useState(0);
  const intervalRef = useRef(null);
  const { t } = useTranslation();

  const texts = [
    t('auto_brain.quote1'),
    t('auto_brain.quote2'),
    t('auto_brain.quote3'),
    t('auto_brain.quote4'),
  ];

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setText((prev) => {
        if (prev === texts.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 8000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [texts.length]);

  return (
    <div className={styles.loading}>
      <div className={styles.svgContainer}>
        <Lottie
          options={{
            path: '/lottie/brain.json',
            loop: true,
          }}
        />
      </div>

      <Fade key={texts[text]} in appear timeout={1000}>
        <Typography>{texts[text]}</Typography>
      </Fade>
    </div>
  );
};

export default AutoBrainLoading;
