import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Analytics = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      className={styles.icon}
    >
      <path d="M7.35 2A6.019 6.019 0 1014 8.65H7.35V2z" />
      <path d="M13.694 5.975h-3.67v-3.67a6.034 6.034 0 013.67 3.67z" />
    </svg>
  );
};

export default Analytics;
