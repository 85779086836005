export const ErrorDescription = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 168 115"
      width="168"
      height="115"
      fill="none"
    >
      <path
        fill="#367CFF"
        d="M74.38 113.564c26.091 0 47.242-21.151 47.242-47.243 0-26.091-21.151-47.242-47.243-47.242-26.091 0-47.243 21.15-47.243 47.242s21.152 47.243 47.243 47.243Z"
        opacity=".2"
      />
      <path
        fill="#1B66D6"
        d="M13.726 59.517a32.432 32.432 0 0 0 9.997 8.786 32.432 32.432 0 0 0 12.678 4.05c4.436.45 8.875-.04 13.009-1.438 4.133-1.398 7.862-3.67 10.927-6.658l-7.06-6.86a19.665 19.665 0 0 1-7.442 4.537 21.021 21.021 0 0 1-8.86.981 22.088 22.088 0 0 1-8.637-2.757 22.088 22.088 0 0 1-6.81-5.984l-7.802 5.343Z"
      />
      <path
        fill="#0A3451"
        d="M2.605 52.664a2.699 2.699 0 0 0-.344 1.493 2.91 2.91 0 0 0 .52 1.485 12.136 12.136 0 0 0 3.23 3.167c1.272.849 2.69 1.453 4.176 1.779 3 .658 6.092.14 8.594-1.439a10.59 10.59 0 0 0 3.111-2.999 10.483 10.483 0 0 0 1.638-3.982c.538-2.891-.138-5.925-1.88-8.434a3.115 3.115 0 0 0-1.883-1.24 2.925 2.925 0 0 0-2.175.37 2.675 2.675 0 0 0-1.207 1.764c-.14.731.025 1.5.46 2.138.854 1.232 1.186 2.72.922 4.139a5.154 5.154 0 0 1-2.33 3.425c-1.227.775-2.744 1.029-4.216.706a6 6 0 0 1-3.634-2.426 3.103 3.103 0 0 0-2.572-1.33c-.5.005-.984.135-1.406.378-.422.242-.768.589-1.005 1.007v-.001Z"
      />
      <path
        fill="#1B66D6"
        d="M112.668 74.693a30.742 30.742 0 0 1-15.577-4.225l4.988-8.53a21.104 21.104 0 0 0 26.393-4.2l7.383 6.566a30.851 30.851 0 0 1-20.531 10.277c-.887.075-1.772.112-2.656.112Z"
      />
      <path
        fill="#0A3451"
        d="M138.199 65.932a11.638 11.638 0 0 1-8.951-19.053 2.962 2.962 0 0 1 5.236 1.607 2.967 2.967 0 0 1-.666 2.17 5.71 5.71 0 0 0 8.804 7.274 2.962 2.962 0 0 1 5.236 1.608 2.966 2.966 0 0 1-.666 2.169 11.564 11.564 0 0 1-7.87 4.172 11.8 11.8 0 0 1-1.123.053Z"
      />
      <path
        fill="#1B66D6"
        d="m49.17 60.862 6.55 37.047A20.698 20.698 0 0 0 76.099 115 20.693 20.693 0 0 0 96.34 98.607l8.019-37.745h-55.19Z"
      />
      <path
        fill="#0A3451"
        d="M76.764 69.02c15.24 0 27.594-3.652 27.594-8.158 0-4.506-12.354-8.159-27.594-8.159-15.24 0-27.595 3.653-27.595 8.159s12.355 8.158 27.595 8.158Z"
      />
      <path
        fill="#1B66D6"
        d="M103.727 38.964c0 11.995-12.073 17.767-26.963 17.767s-26.963-5.772-26.963-17.767 12.073-25.672 26.963-25.672 26.963 13.677 26.963 25.672Z"
      />
      <path
        fill="#fff"
        d="M91.5 24.408H76.163a8.704 8.704 0 0 0 0 17.409H91.5a8.705 8.705 0 1 0 0-17.409Z"
      />
      <path
        fill="#0A3451"
        d="M80.208 26.945a1.32 1.32 0 0 0-.513.599c-.09.23-.079.453.032.622.19.285.3.62.32.985.022.365-.044.753-.196 1.142-.15.388-.383.77-.684 1.123-.3.353-.663.67-1.067.932-.403.263-.84.465-1.285.597-.444.132-.887.19-1.304.17a2.721 2.721 0 0 1-1.124-.284 1.956 1.956 0 0 1-.769-.693c-.11-.17-.31-.27-.556-.28a1.32 1.32 0 0 0-.756.225 1.32 1.32 0 0 0-.513.599c-.09.23-.079.453.031.622a3.11 3.11 0 0 0 1.223 1.097c.515.265 1.121.417 1.782.447a6.305 6.305 0 0 0 2.069-.27 7.859 7.859 0 0 0 2.036-.947 7.85 7.85 0 0 0 1.692-1.477 6.301 6.301 0 0 0 1.086-1.781c.24-.617.348-1.232.315-1.81A3.11 3.11 0 0 0 81.52 27a.576.576 0 0 0-.227-.2.805.805 0 0 0-.329-.081 1.17 1.17 0 0 0-.38.05c-.13.04-.258.099-.376.176ZM97.115 31.423a1.295 1.295 0 0 0-.751-.177c-.24.024-.43.134-.527.306a1.903 1.903 0 0 1-.71.719 2.658 2.658 0 0 1-1.079.34 3.887 3.887 0 0 1-1.283-.092 4.859 4.859 0 0 1-1.29-.508 4.86 4.86 0 0 1-1.096-.848 3.89 3.89 0 0 1-.736-1.056 2.66 2.66 0 0 1-.26-1.102c0-.357.086-.689.255-.977.098-.171.095-.39-.007-.608a1.295 1.295 0 0 0-.536-.556 1.295 1.295 0 0 0-.751-.176c-.24.024-.43.134-.527.305a3.024 3.024 0 0 0-.4 1.55c.002.567.143 1.16.415 1.747a6.175 6.175 0 0 0 1.167 1.674 7.71 7.71 0 0 0 1.739 1.344c.65.37 1.344.643 2.044.807.7.164 1.392.213 2.035.146.644-.067 1.226-.25 1.713-.537.488-.287.871-.674 1.128-1.138a.56.56 0 0 0 .071-.286.783.783 0 0 0-.078-.322 1.141 1.141 0 0 0-.216-.308c-.092-.095-.2-.18-.32-.247Z"
      />
      <path
        fill="#1B66D6"
        d="m76.757 4.008-1.197.059.59 12.066 1.198-.058-.59-12.067Z"
      />
      <path
        fill="#0A3451"
        d="M76.202 7.122a2.191 2.191 0 1 0 0-4.383 2.191 2.191 0 0 0 0 4.383ZM111.629 44.413V3.466c0-.919.368-1.8 1.023-2.45A3.515 3.515 0 0 1 115.125 0h48.596c.928 0 1.817.365 2.473 1.015a3.45 3.45 0 0 1 1.025 2.451v30.532a3.45 3.45 0 0 1-1.025 2.45 3.513 3.513 0 0 1-2.473 1.016h-39.487l-11.014 7.763a1.015 1.015 0 0 1-1.444-.294.985.985 0 0 1-.147-.52Z"
      />
      <path
        fill="#fff"
        d="M138.128 30.892a1.84 1.84 0 1 0 0-3.68 1.84 1.84 0 0 0 0 3.68ZM138.043 25.062a1.197 1.197 0 0 1-1.197-1.198v-4.446a1.195 1.195 0 0 1 .916-1.163c.052-.013 5.408-1.369 5.408-4.972 0-2.262-1.854-3.843-4.509-3.843-2.874 0-4.352 2.875-4.413 2.996a1.197 1.197 0 0 1-2.146-1.061c.088-.176 2.18-4.328 6.559-4.328 4 0 6.902 2.621 6.902 6.234 0 4.354-4.37 6.348-6.323 7.022v3.561a1.199 1.199 0 0 1-1.197 1.198Z"
      />
    </svg>
  );
};
