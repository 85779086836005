import Typography from '@mui/material/Typography';

interface Props {
  children: React.ReactNode;
}
const FieldsetSubtitle: React.FC<Props> = ({ children }: Props) => {
  return (
    <Typography variant="body" className="fieldset__subtitle">
      {children}
    </Typography>
  );
};

export default FieldsetSubtitle;
