import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AvatarFieldset from '@/components/atoms/Fieldset/templates/AvatarFieldset';
import { useAccount } from '@/hooks/useAccount';
import { Account } from '@/models/account';
import { addTemporalToast } from '@/modules/notifications/redux/actions';

interface Props {
  readOnly?: boolean;
}

const Avatar = ({ readOnly = false }: Props) => {
  const { t } = useTranslation();
  const { account, updateAccount } = useAccount();
  const dispatch = useDispatch();

  const onUpdate = useCallback(
    (variables: Partial<Account>) => {
      updateAccount(variables, {
        onSuccess: () => {
          dispatch(addTemporalToast('success', t('account.account_updated')));
        },
      });
    },
    [dispatch, t, updateAccount]
  );

  return (
    <AvatarFieldset
      title={t('account.avatar.title')}
      subtitle={t('account.avatar.subtitle')}
      isLoading={!account}
      onUpdate={onUpdate}
      name={account?.name}
      helpText={t('account.avatar.help')}
      avatar={account?.avatar}
      readOnly={readOnly}
    />
  );
};

export default Avatar;
