import { useCallback, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';

import Modal from './Modal';

interface Form {
  message: string;
}
interface Props {
  onSend: (form: { message?: string }) => Promise<void>;
}

const ModalAccess = ({ onSend }: Props) => {
  const { t } = useTranslation();

  const { handleSubmit, register } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (form: Form) => {
      try {
        setIsLoading(true);
        await onSend(form);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    },
    [onSend]
  );

  return (
    <Modal
      size="small"
      title={t('permissions.permission_request')}
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('common.send')}
      isSubmitting={isLoading}
      autoFocus
    >
      <form autoComplete="off">
        <div className="input__container">
          <TextAreaAsInput
            name="message"
            register={register('message')}
            placeholder={t('permissions.request_placeholder')}
          />
        </div>
      </form>
    </Modal>
  );
};

export default ModalAccess;
