import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import { MODAL_FORM } from '@/components/organisms/Modals/ModalConductor';
import { TileBrainSkeleton } from '@/components/organisms/Tile';
import TileCollection from '@/components/organisms/Tile/TileCollection/TileCollection';
import TileList from '@/components/organisms/Tile/TileList';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useCollections from '@/hooks/useCollections';
import { Collection } from '@/models/collections';
import { actions } from '@/models/permissions';
import { EventName, PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView, trackEvent } from '@/segment/segment';
import { COLLECTION_LANGUAGES, languageByCountryCode } from '@/util/constants';
import { collectionRules } from '@/util/validator';

import { CollectionCreateTile } from './CollectionCreateTile';
import CollectionsHeader from './CollectionsHeader';
import { useCollectionHelper } from './useCollectionsHelper';

const Collections = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    collections,
    createCollection,
    createStatus,
    collectionNames,
    deleteCollection,
    updateCollection,
  } = useCollections();
  const slug = useSelector(selectAccountSlug);
  useCollectionHelper();
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );

  useTitle(t('pages.collections', { 0: slug }));

  useEffect(() => {
    pageView(PageName.COLLECTIONS);
  }, []);

  const handleClick = () => {
    const languageOptions = Object.keys(languageByCountryCode)
      .filter((lang) => COLLECTION_LANGUAGES.includes(lang))
      .map((i) => ({
        value: i.toLowerCase(),
        label: t(`languages.${i.toLowerCase()}`, {
          defaultValue: languageByCountryCode[i],
        }),
      }));
    trackEvent(EventName.ClickCreateCollection);
    const modalProps = {
      resource: t('collections.collection'),
      primaryButtonText: t('common.create'),
      fields: [
        {
          fieldName: 'name',
          fieldValue: '',
          rules: collectionRules.name(collectionNames),
        },
        {
          fieldName: 'language',
          type: 'select',
          fieldValue: languageOptions[0]?.value,
          placeholder: t('common.select_an_option'),
          options: languageOptions,
          rules: {
            required: true,
          },
        },
      ],
      onCreate: (data: Partial<Collection>) => {
        createCollection(data, {
          onSuccess: (data) => {
            navigate(`/${slug}/collections/${data.collection_id}`);
            dispatch(popModal());
          },
        });
      },
    };
    dispatch(pushModal(MODAL_FORM, modalProps));
  };

  function renderSkeletons(n = 3) {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => <TileBrainSkeleton key={i} />)
    );
  }

  const renderTiles = useCallback(() => {
    return collections?.map((collection) => (
      <TileCollection
        collection={collection}
        key={collection.collection_id}
        deleteCollection={deleteCollection}
        updateCollection={updateCollection}
      />
    ));
  }, [collections, deleteCollection, updateCollection]);

  return (
    <>
      <CollectionsHeader onClick={handleClick} />
      <PageContentWrapper newPlain readOnly={!canWrite}>
        <ContextualHelp
          title={t('collections.contextual_title')}
          name="about_collections"
        >
          {t('collections.contextual_message')}
        </ContextualHelp>

        <TileList>
          <CollectionCreateTile onClick={handleClick} />

          {!collections && renderSkeletons(3)}
          {collections && renderTiles()}
          {createStatus === 'pending' && renderSkeletons(1)}
        </TileList>
      </PageContentWrapper>
    </>
  );
};

export default Collections;
