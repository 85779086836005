import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useInterval } from '@/hooks/useWindowInterval';

export const getFavicon = () => {
  let favicon;
  const nodeList = document.getElementsByTagName('link');
  for (let i = 0; i < nodeList.length; i++) {
    if (nodeList[i].getAttribute('rel') === 'shortcut icon') {
      favicon = nodeList[i];
    }
  }
  return favicon;
};

const interval = 1500;

export const usePleaseStay = (isActive): void => {
  const { t } = useTranslation();

  // State vars
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  const [titleIndex, setTitleIndex] = useState<number>(0);

  // Ref vars
  const originalDocumentTitle = useRef<string>();

  const titles = [
    originalDocumentTitle.current,
    t('conversation.you_have_unread_messages'),
  ];
  // The logic to restore default title and favicon.
  const restoreDefaults = () => {
    setShouldAnimate(false);
    setTimeout(() => {
      if (originalDocumentTitle.current) {
        document.title = originalDocumentTitle.current;
      }
    }, interval);
  };

  const modifyDocumentTitle = () => {
    document.title = titles[titleIndex];
    setTitleIndex(titleIndex === titles.length - 1 ? 0 : titleIndex + 1);
  };

  // On mount of this hook, save current default title
  useEffect(() => {
    originalDocumentTitle.current = document.title;
  }, []);

  // State change effects
  useEffect(() => {
    // Change in isActive change the shouldAnimate value
    if (!isActive) {
      restoreDefaults();
    } else {
      setShouldAnimate(isActive);
    }
    // Update title index
    setTitleIndex(0);
  }, [isActive]);

  // Change title and favicon at specified interval
  useInterval(
    () => {
      modifyDocumentTitle();
      // this is 1 because we append the existing favicon on mount - see above
    },
    shouldAnimate ? interval : null
  );
};
