import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IntentIcon from '@/components/atoms/Icons/Intent';
import Intents from '@/components/atoms/Icons/Intents';
import { useAccount } from '@/hooks/useAccount';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Standalone as StandaloneIcon } from '@/modules/TryIt/Icons/Standalone';
import { popModal } from '@/redux/modals/actions';
import { resolveBrainsPath } from '@/util/util';

import styles from '../../LogSections/DetailsSection/DetailsSection.module.scss';

interface Props {
  brainId: string;
  isPlannerIntentOfftopic: boolean;
  intentName?: string;
  plannerIntent?: string;
}

export const PossibleMatchedIntents = ({
  brainId,
  isPlannerIntentOfftopic,
  intentName,
  plannerIntent,
}: Props) => {
  const { slug } = useAccount();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ai_agents } = useFeatureFlag();

  const closeModal = () => {
    dispatch(popModal());
  };
  const intent = plannerIntent || intentName;

  const isOfftopic = intent === 'offtopics' || isPlannerIntentOfftopic;
  return (
    <>
      {isOfftopic ? (
        <div className={styles.title}>
          <IntentIcon color="var(--icon-default-gray)" />
          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('try_it.no_intent_ai_agent')}
          </Typography>
        </div>
      ) : (
        <div className={styles.title}>
          <Intents />
          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
            className={styles.plannerIntentContainer}
          >
            {t('common.intent')}{' '}
            <Typography variant="body-regular">
              <Box className={styles.plannerIntent}>
                <StandaloneIcon />
                <NavLink
                  to={resolveBrainsPath(
                    `/${slug}/brains/${brainId}/intents/${intent}`,
                    ai_agents
                  )}
                  className={styles.link}
                  onClick={closeModal}
                >
                  {intent}
                </NavLink>
              </Box>
            </Typography>{' '}
            {t('try_it.selected_ai_agent')}
          </Typography>
        </div>
      )}
    </>
  );
};
