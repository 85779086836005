import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Sunco = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          d="M23 14.3c0 2.2-.125 4.4-1.5 6.2h-19c-1.375-1.8-1.5-4-1.5-6.2C1 8.335 5.925 3.5 12 3.5s11 4.835 11 10.8Z"
          fill="#E3BF39"
        />
      </svg>
    );
  }
);

Sunco.displayName = 'Sunco';

export default Sunco;
