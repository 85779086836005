import { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AIAgentType } from '@/modules/aiAgents/components/AIAgentType/AIAgentType';
import { AI_AGENT_TYPES } from '@/modules/aiAgents/constants';

import { Form } from './ModalAIAgentCreate';

import styles from './ModalAIAgentCreate.module.scss';

export const Step1 = () => {
  const { control, setValue, unregister, getValues } = useFormContext<Form>();

  useEffect(() => {
    unregister('name');
    unregister('language');
    unregister('provider');
    unregister('language_model_id');
  }, [unregister]);

  return (
    <Controller
      name="brain_type"
      control={control}
      defaultValue={getValues('brain_type') ?? null}
      render={({ field }) => {
        return (
          <div className={styles.step1}>
            {AI_AGENT_TYPES.map((type) => {
              return (
                <AIAgentType
                  type={type}
                  handleClick={(e) => {
                    e.preventDefault();
                    setValue('brain_type', type, {
                      shouldValidate: true,
                    });
                  }}
                  isSelected={field.value === type}
                  key={type}
                />
              );
            })}
          </div>
        );
      }}
    />
  );
};
