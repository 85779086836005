import { Icon } from '@/models/icon';

export const Unassigned = ({ color = 'currentColor', size = 16 }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 9.333A2.667 2.667 0 1 0 8 4a2.667 2.667 0 0 0 0 5.333Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.004.008c-.533 0-1.055.053-1.56.152a.5.5 0 1 0 .194.981 7.032 7.032 0 0 1 2.732 0 .5.5 0 0 0 .194-.98 8.032 8.032 0 0 0-1.56-.153Zm4.443 1.347a.5.5 0 0 0-.556.831 7.037 7.037 0 0 1 1.93 1.931.5.5 0 1 0 .832-.556 8.037 8.037 0 0 0-2.206-2.206Zm-8.33.831a.5.5 0 0 0-.556-.83A8.037 8.037 0 0 0 1.355 3.56a.5.5 0 1 0 .831.556 7.037 7.037 0 0 1 1.931-1.93Zm11.73 4.258a.5.5 0 1 0-.98.194 7.033 7.033 0 0 1 0 2.732.5.5 0 0 0 .98.194 8.031 8.031 0 0 0 0-3.12Zm-14.706.194a.5.5 0 1 0-.98-.194 8.032 8.032 0 0 0 0 3.12.5.5 0 1 0 .98-.194 7.032 7.032 0 0 1 0-2.732Zm13.512 5.809a.5.5 0 1 0-.831-.556 7.054 7.054 0 0 1-.413.555 1.719 1.719 0 0 0-.531-.823c-.598-.512-1.394-.79-2.211-.79H5.333c-.817 0-1.613.278-2.21.79-.289.247-.445.54-.53.816a7.037 7.037 0 0 1-.407-.548.5.5 0 1 0-.83.556 8.037 8.037 0 0 0 2.205 2.206.5.5 0 0 0 .556-.831 7.015 7.015 0 0 1-.618-.465l.001-.024v-.065c-.001-.328-.003-.65.273-.886.403-.345.963-.549 1.56-.549h5.334c.597 0 1.157.204 1.56.55.275.236.274.557.273.885v.095a7.02 7.02 0 0 1-.61.459.5.5 0 1 0 .557.83 8.037 8.037 0 0 0 2.206-2.205Zm-8.015 2.42a.5.5 0 1 0-.194.98 8.04 8.04 0 0 0 3.12 0 .5.5 0 0 0-.194-.98 7.033 7.033 0 0 1-2.732 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
