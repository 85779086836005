import { useCallback, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import OopsIcon from '@/components/atoms/Icons/Oops';
import { PageName } from '@/models/segment';
import { pageView } from '@/segment/segment';

import styles from './Oops.module.scss';

interface Props {
  resetErrorBoundary: () => void;
}

const Oops: React.FC<Props> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();
  useTitle(t('pages.error'));
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    resetErrorBoundary();
    navigate('/');
  }, [navigate, resetErrorBoundary]);

  useEffect(() => {
    pageView(PageName.ERROR_500);
  }, []);

  return (
    <div className={styles.container}>
      <OopsIcon />
      <Typography
        component="div"
        fontWeight={500}
        fontSize="var(--space-36)"
        lineHeight="var(--space-44)"
        className={styles.header}
      >
        {t('error_page.header')}
      </Typography>
      <Typography
        variant="h3-regular"
        className={styles.subheader}
        component="div"
      >
        {t('error_page.subheader')}
      </Typography>
      <div className={styles.button}>
        <Button onClick={onButtonClick}>{t('error_page.return_home')}</Button>
      </div>
    </div>
  );
};

export default Oops;
