import { useCallback, useRef, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import { ModalProps } from '@mui/material/Modal';
import Select from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty';
import { MuiChipsInput } from 'mui-chips-input';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { MODAL_WARN } from '@/components/organisms/Modals/ModalConductor';
import { OptionBase } from '@/models/common';
import { pushModal } from '@/redux/modals/actions';
import { deleteValue, updateValue, createValue } from '@/redux/values/actions';
import { selectById } from '@/redux/values/selectors';

import Trash from '../../../atoms/Icons/Trash';

import './Value.scss';

const buildItem = (
  value: string,
  type: 'synonym' | 'pattern',
  data: string[]
) => {
  if (!value) {
    return;
  }
  let examples = data.map((e) => e.trim()).filter((e) => e);
  examples = [...new Set(examples)].sort();

  if (!value.trim() || examples.length === 0) {
    return null;
  }

  const item = {
    type,
    value,
  };

  if (type === 'synonym') {
    return { ...item, synonyms: examples };
  }

  return { ...item, patterns: examples };
};

interface Props {
  id: string;
  readOnly?: boolean;
  findConnectedDialogs?: (value: string) => OptionBase<{ dialogId: string }>[];
  handleDialogNameClick?: (dialogName: string) => void;
}

export function ValuePure({
  id,
  findConnectedDialogs,
  handleDialogNameClick,
  readOnly = false,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entity = useSelector((state) => selectById(state, id));
  const { value, type, synonyms, patterns } = entity ?? {};

  const [inputValue, setInputValue] = useState(value || '');
  const [inputType, setInputType] = useState(type || 'synonym');
  const [inputData, setInputData] = useState<string[]>(
    type === 'synonym' ? synonyms || [] : patterns || []
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const typeRef = useRef(type);
  const onBlur = useCallback(() => {
    const newValue = buildItem(inputValue, inputType, inputData);
    if (!id && inputData.length) {
      if (!inputValue) {
        inputRef.current.focus();
        return;
      }
      dispatch(createValue({ newValue }));
      return;
    }

    dispatch(updateValue({ id, newValue }));
  }, [dispatch, id, inputData, inputType, inputValue]);

  const handleInputChange = useCallback(() => {
    setInputValue(inputRef.current.value);
  }, []);

  const handleTypeChange = useCallback((e) => {
    setInputType(e.target.value);
  }, []);

  const handleDataChange = useCallback(
    (newArray: string[]) => {
      const lastValue = newArray[newArray.length - 1];
      if (inputType === 'pattern') {
        try {
          const _ = new RegExp(lastValue);
        } catch (error) {
          console.error('Invalid regular expression:', error.message);
          return;
        }
      }
      const newInputData = [...new Set(newArray)];
      const newValue = buildItem(inputValue, inputType, newInputData);
      if (id) {
        dispatch(updateValue({ id, newValue }));
      } else {
        dispatch(createValue({ newValue }));
      }
      setInputData(newInputData);
    },
    [inputType, inputValue, id, dispatch]
  );

  const onDeleteHandle = useCallback(() => {
    const usedDialogs = findConnectedDialogs(value);
    if (!isEmpty(usedDialogs)) {
      const warning = (
        <Banner
          relativePosition
          variant="critical"
          references={usedDialogs}
          onRefClick={handleDialogNameClick}
          referenceProp="url"
        >
          <Trans i18nKey="entity.ref_warning" values={[value]} />
        </Banner>
      );
      const warnProps = {
        title: t('common.warning'),
        children: warning,
        primaryButtonText: t('common.close'),
      };

      dispatch(pushModal(MODAL_WARN, warnProps));
    } else {
      dispatch(deleteValue({ id }));
    }
  }, [dispatch, findConnectedDialogs, handleDialogNameClick, id, t, value]);

  const showError = !!id && inputData.length === 0;

  return (
    <li className="entity__input">
      <InputBase
        className="entity__input__value"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={onBlur}
        placeholder={t('entity.type_value')}
        inputProps={{
          'aria-label': 'naked',
          style: { textOverflow: 'ellipsis' },
        }}
        disabled={readOnly}
        inputRef={inputRef}
      />
      <Select
        className="entity__input__type"
        value={inputType}
        onChange={handleTypeChange}
        onBlur={onBlur}
        input={
          <InputBase margin="none" inputProps={{ 'aria-label': 'naked' }} />
        }
        disabled={readOnly}
        MenuProps={{
          PaperProps: {
            style: {
              padding: '8px',
            },
          },
          slotProps: {
            root: {
              slotProps: {
                backdrop: { style: { opacity: '0%' } },
              } as ModalProps['slotProps'],
            },
          },
        }}
        inputRef={typeRef}
      >
        <MenuItem value="synonym" className="entity__input__option">
          {t('entity.synonym')}
        </MenuItem>
        <MenuItem value="pattern" className="entity__input__option">
          {t('entity.pattern')}
        </MenuItem>
      </Select>
      <MuiChipsInput
        value={inputData}
        onChange={handleDataChange}
        placeholder={t('entity.type', {
          0:
            inputType === 'synonym' ? t('entity.synonym') : t('entity.pattern'),
        })}
        disabled={readOnly}
        hideClearAll
        error={showError}
        clearInputOnBlur
        className="entity__input__data"
      />
      {!readOnly && id && (
        <IconButton onClick={onDeleteHandle} ariaLabel="delete-entity-value">
          <Trash />
        </IconButton>
      )}
    </li>
  );
}

export default ValuePure;
