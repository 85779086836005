import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './WebchatAppearance.module.scss';

interface Props {
  color: string;
  onClick: () => void;
  disabled?: boolean;
}

const ColorPickerButton = ({ color, onClick, disabled }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        aria-label={t('integrations.web_ui.color_picker')}
        className={cn(styles.colorBlock, { [styles.disabled]: disabled })}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <span>{color}</span>
        <span
          style={{
            backgroundColor: color,
          }}
          className={cn(styles.colorBox)}
        ></span>
      </div>
    </>
  );
};

export default ColorPickerButton;
