import { Icon } from '@/models/icon';

export const Power = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        d="M7.5 8V3a.5.5 0 1 1 1 0v5a.5.5 0 1 1-1 0Zm3.773-4.919a.5.5 0 0 0-.546.838C12.17 4.86 13 6.349 13 8A5 5 0 1 1 3 8c0-1.652.829-3.14 2.273-4.081a.5.5 0 0 0-.546-.838C2.994 4.211 2 6.004 2 8a6 6 0 1 0 12 0c0-1.996-.994-3.789-2.727-4.919Z"
        fill={color}
      />
    </svg>
  );
};
