import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as API from '@/api/client';
import { User, UserState } from '@/models/user';
import {
  defaultStatus,
  loadingStatus,
  failedStatus,
  successStatus,
} from '@/util/api-statuses';

// Async action to login a user
export const loadUser = createAsyncThunk<User>(
  'user/login',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.loadUser();
      return response as User;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
      });
    }
  }
);

// Async action to logout a user
export const logoutUser = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    try {
      await API.logout();
    } catch (error) {
      return rejectWithValue({
        message: error.message,
      });
    }
  }
);

// Initial state
const initialState: UserState = {
  user: null,
  loginStatus: defaultStatus,
  logoutStatus: defaultStatus,
};

// User slice
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser() {
      return initialState;
    },
    setIframeView: (state: UserState, payload) => {
      state.iframeView = payload.payload;
    },
    clearInvitation: (state: UserState) => {
      state.user.invitation = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling login user actions
      .addCase(loadUser.pending, (state) => {
        state.loginStatus = loadingStatus;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loginStatus = successStatus;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loginStatus = failedStatus(action.payload);
      })
      // Handling logout user actions
      .addCase(logoutUser.pending, (state) => {
        state.logoutStatus = loadingStatus;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
        state.logoutStatus = successStatus;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.logoutStatus = failedStatus(action.payload);
      });
  },
});

export const { resetUser, setIframeView, clearInvitation } = slice.actions;
export default slice.reducer;
