import { Routes, Route } from 'react-router';

import Layout from '@/components/templates/Layout/Layout';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { Broadcasts } from '@/modules/broadcast/pages/Broadcasts';

import { BroadcastRouter } from './BroadcastRouter';

export const BroadcastsRouter: React.FC = () => {
  return (
    <Layout>
      <PermissionsWrapper module="broadcasts">
        <Routes>
          <Route path="/" element={<Broadcasts />} />
          <Route path="/:broadcastId/*" element={<BroadcastRouter />} />
        </Routes>
      </PermissionsWrapper>
    </Layout>
  );
};
