/* eslint-disable @typescript-eslint/no-explicit-any */
/* source code: https://github.com/chenckang/react-json-pretty/blob/master/src/JSONPretty.tsx */

import * as React from 'react';

import stringify from 'json-stable-stringify';

interface ITheme {
  [key: string]: string;
}
interface IProps extends React.HTMLAttributes<HTMLElement> {
  json?: any;
  data?: any;
  replacer?: (key: string, value: any) => any | null;
  space?: number | string;
  themeClassName?: string;
  theme?: ITheme;
  silent?: boolean;
  onJSONPrettyError?: (e: Error) => void;
  mainStyle?: string;
  keyStyle?: string;
  stringStyle?: string;
  valueStyle?: string;
  booleanStyle?: string;
  errorStyle?: string;
}

function getStyleValue(name: string, theme: ITheme, styles: any): string {
  const extra = styles[name + 'Style'] || '';
  const style = theme ? theme[name] || '' : '';
  return extra ? `${extra};${style}` : style;
}

function getStyle(name: string, theme: ITheme, styles: any): string {
  const value = getStyleValue(name, theme, styles);
  return value ? ` style="${value}"` : '';
}

const xssmap: { [key: string]: string } = {
  '"': '&quot;',
  "'": '&apos;',
  '&': '&amp;',
  '>': '&gt;',
  '<': '&lt',
};

function xss(s: string): string {
  if (!s) {
    return s;
  }

  return s.replace(/<|>|&|"|'/g, (m) => {
    return xssmap[m];
  });
}

class JSONPretty extends React.Component<IProps, {}> {
  public static defaultProps = {
    data: '',
    json: '',
    silent: true,
    space: 2,
    themeClassName: '__json-pretty__',
  };

  public render() {
    const {
      json,
      data,
      replacer,
      space,
      themeClassName,
      theme,
      onJSONPrettyError,
      onError,
      silent,
      mainStyle,
      keyStyle,
      valueStyle,
      stringStyle,
      booleanStyle,
      errorStyle,
      ...rest
    } = this.props;

    const styles = {
      mainStyle,
      keyStyle,
      valueStyle,
      stringStyle,
      booleanStyle,
      errorStyle,
    };

    let obj = data || json;

    // See https://facebook.github.io/react/warnings/unknown-prop.html
    if (typeof obj === 'string') {
      try {
        obj = JSON.parse(obj);
      } catch (e) {
        if (!silent) {
          console.warn(`[react-json-pretty]: ${e.message}`);
        }

        if (onJSONPrettyError) {
          onJSONPrettyError(e);
        }

        if (!onJSONPrettyError && onError) {
          onError(e);
          console.warn(
            'JSONPretty#onError is deprecated, please use JSONPretty#onJSONPrettyError instead'
          );
        }

        return (
          <div
            {...rest}
            dangerouslySetInnerHTML={{
              __html: `<pre class="__json-pretty-error__"${getStyle(
                'error',
                theme,
                styles
              )}>${xss(obj)}</pre>`,
            }}
          ></div>
        );
      }
    }

    return (
      <div
        {...rest}
        dangerouslySetInnerHTML={{
          __html: `<pre class="${themeClassName}"${getStyle(
            'main',
            theme,
            styles
          )}>${this._pretty(theme, obj, replacer, +space, styles)}</pre>`,
        }}
      ></div>
    );
  }

  private _pretty(
    theme: ITheme,
    obj: any,
    replacer: (k: string, v: any) => any,
    space: number,
    styles: any
  ) {
    const regLine =
      /^( *)("[^"]+": )?("[^"]*"|[\w.+-]*)?([,[{]|\[\s*\],?|\{\s*\},?)?$/gm;

    const text = stringify(obj, { space: 2 });

    if (!text) {
      return text;
    }

    return text
      .replace(/&/g, '&amp;')
      .replace(/\\"([^,])/g, '\\&quot;$1')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(regLine, this._replace.bind(null, theme, styles));
  }

  private _replace(
    theme: ITheme,
    styles: any,
    match: any,
    ind: string,
    key: string,
    val: string,
    tra: string
  ) {
    const spanEnd = '</span>';
    const keySpan = `<span class="__json-key__"${getStyle(
      'key',
      theme,
      styles
    )}>`;
    const valSpan = `<span class="__json-value__"${getStyle(
      'value',
      theme,
      styles
    )}>`;
    const strSpan = `<span class="__json-string__"${getStyle(
      'string',
      theme,
      styles
    )}>`;
    const booSpan = `<span class="__json-boolean__"${getStyle(
      'boolean',
      theme,
      styles
    )}>`;

    let sps = ind || '';
    if (key) {
      sps =
        sps + '"' + keySpan + key.replace(/^"|":\s$/g, '') + spanEnd + '": ';
    }

    if (val) {
      if (val === 'true' || val === 'false') {
        sps = sps + booSpan + val + spanEnd;
      } else {
        sps = sps + (val[0] === '"' ? strSpan : valSpan) + val + spanEnd;
      }
    }

    return sps + (tra || '');
  }
}

export default JSONPretty;
