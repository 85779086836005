import { createSelector } from '@reduxjs/toolkit';

import {
  RecommendedExpression,
  RecommendedIntent,
} from '@/models/recommendations';
import { RootState } from '@/models/state';

interface Props {
  brainId: string;
  intentName: string;
}

const sortExpressionsByConfidence = (expressions: RecommendedExpression[]) => {
  return [...expressions].sort(
    (a, b) => a?.prediction_confidence - b?.prediction_confidence
  );
};

/**
 * Sort the recommended expressions by the following criteria:
 * First return all the expressions that had a different predicted_intent
 * Then return the expressions that had the same predicted_intent sorted by ascending prediction_confidence
 * Limit the number of same predicted_intent to maxRecommendedPhrases
 */
export const sortObjectsByIntent = (
  items: RecommendedExpression[] = [],
  intentName: string,
  maxRecommendedPhrases: number
) => {
  if (items.length === 0) return [];

  const differentIntentItems = sortExpressionsByConfidence(
    items.filter((obj) => obj.predicted_intent !== intentName)
  );

  const sameIntentItems = sortExpressionsByConfidence(
    items.filter((obj) => obj.predicted_intent === intentName)
  ).slice(0, maxRecommendedPhrases - differentIntentItems.length);

  return differentIntentItems.concat(sameIntentItems);
};

/**
 * If the selected intent is a recommended intent, return it with sorted expressions
 */
export const selectRecommendedIntent: (
  state: RootState,
  props: Props
) => RecommendedIntent = createSelector(
  (state: RootState) => state.recommendations,
  (_: RootState, props: Props) => props,
  (recommendations, { brainId, intentName }) => {
    const recc = recommendations[brainId]?.intents?.find(
      (i) => i.intent === intentName
    );

    return recc
      ? { ...recc, expressions: sortExpressionsByConfidence(recc.expressions) }
      : null;
  }
);

/**
 * Checks if intent is a new recommendation that has been accepted
 */
export const selectIsNewIntentDraft: (
  state: RootState,
  props: Props
) => boolean = createSelector(
  (state: RootState) => state.recommendations,
  (_: RootState, props: Props) => props,
  (recommendations, { brainId, intentName }) => {
    const acceptedExpressions = recommendations[brainId]?.intents
      ?.find((i) => i.intent === intentName && i.flag === 'accepted')
      ?.expressions.map((e) => e.expression);
    return !!acceptedExpressions;
  }
);

/**
 * Returns all expressions that don't have a flag
 */
export const selectFilteredExpressions: (
  state: RootState,
  props: Props
) => { text: string }[] = createSelector(
  selectRecommendedIntent,
  (_: RootState, props: Props) => props,
  (recommendedIntent) =>
    (recommendedIntent?.expressions || [])
      .filter((e) => !e.flag)
      .map((e) => ({ text: e.expression }))
);

/**
 * Returns all visible expressions of a recommended intent
 */
export const selectVisibleExpressions = createSelector(
  selectRecommendedIntent,
  (_: RootState, props: Props & { optimalPhrases: number }) => props,
  (state: RootState) => state.expressions.allIds?.length,

  (recommendedIntent, props, numberOfExpressions) => {
    const maxRecommendedPhrases = props.optimalPhrases - numberOfExpressions;
    const filteredExpressions = recommendedIntent?.expressions?.filter(
      (e) => !e.flag
    );
    const expressions = sortObjectsByIntent(
      filteredExpressions,
      props.intentName,
      maxRecommendedPhrases
    )?.map((e) => ({
      text: e.expression,
    }));
    return expressions;
  }
);

export const selectRecommendation: (
  state: RootState,
  brainId: string
) => {
  recommendedIntents: RecommendedIntent[];
  isRecommmedationsDirty: boolean;
  isRecommendationsEnabled: boolean;
} = createSelector(
  (state: RootState) => state.recommendations,
  (_: RootState, brainId: string) => brainId,

  (recommendations, brainId) => ({
    recommendedIntents: recommendations[brainId]?.intents,
    isRecommmedationsDirty: recommendations[brainId]?.dirty,
    isRecommendationsEnabled: recommendations[brainId]?.enabled,
  })
);

export const selectRecommendations = (state: RootState) =>
  state.recommendations;
