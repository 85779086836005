type NumberIconProps = {
  number: number;
  size?: 'small' | 'large';
  color?: string;
};

export const NumberIcon = ({
  number,
  size = 'small',
  color = 'var(--icon-default-black)',
}: NumberIconProps) => {
  const svgSize = size === 'small' ? 16 : 24;

  switch (number) {
    case 1:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5ZM8.75 5v6a.5.5 0 0 1-1 0V5.937l-.723.482a.5.5 0 1 1-.554-.832l1.5-1A.5.5 0 0 1 8.75 5Z"
          />
        </svg>
      );
    case 2:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5Zm1.598-5.796L7.5 10.5h2a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.4-.8l2.698-3.597a1 1 0 1 0-1.741-.938.5.5 0 0 1-.943-.334 2 2 0 1 1 3.484 1.87v.003Z"
          />
        </svg>
      );
    case 3:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5Zm2-4a2.25 2.25 0 0 1-3.857 1.574.5.5 0 1 1 .714-.699A1.249 1.249 0 1 0 7.75 8.25a.5.5 0 0 1-.41-.787l1.2-1.713H6.5a.5.5 0 0 1 0-1h3a.5.5 0 0 1 .41.787L8.596 7.412A2.254 2.254 0 0 1 10 9.5Z"
          />
        </svg>
      );
    case 4:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5ZM10 9h-.5V5a.5.5 0 0 0-.894-.307l-3.5 4.5A.5.5 0 0 0 5.5 10h3v1a.5.5 0 0 0 1 0v-1h.5a.5.5 0 0 0 0-1ZM8.5 9H6.522L8.5 6.457V9Z"
          />
        </svg>
      );
    case 5:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5Zm-.576-8-.262 1.571c.192-.047.39-.071.588-.071a2.25 2.25 0 1 1 0 4.5 2.22 2.22 0 0 1-1.607-.65.5.5 0 1 1 .714-.7 1.234 1.234 0 0 0 .893.35 1.25 1.25 0 0 0 0-2.5 1.233 1.233 0 0 0-.893.35.5.5 0 0 1-.85-.432l.5-3A.5.5 0 0 1 7 4.5h2.5a.5.5 0 0 1 0 1H7.424Z"
          />
        </svg>
      );
    case 6:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5ZM8 7h-.114L8.93 5.256a.5.5 0 0 0-.858-.512L6.056 8.119A2.25 2.25 0 1 0 8 7Zm0 3.5A1.25 1.25 0 1 1 8 8a1.25 1.25 0 0 1 0 2.5Z"
          />
        </svg>
      );
    case 7:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5Zm1.91-8.287a.5.5 0 0 1 .062.458l-2 5.5a.5.5 0 0 1-.942-.342L8.786 6H6.5a.5.5 0 0 1 0-1h3a.5.5 0 0 1 .41.213Z"
          />
        </svg>
      );
    case 8:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5Zm1.363-5.788a2 2 0 1 0-2.726 0 2.25 2.25 0 1 0 2.726 0ZM7 6.25a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1 4.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z"
          />
        </svg>
      );
    case 9:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgSize}
          height={svgSize}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill={color}
            d="M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5Zm0 12A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5Zm1.125-8.699a2.25 2.25 0 1 0-1.01 4.195l-1.044 1.75a.5.5 0 0 0 .357.754.5.5 0 0 0 .503-.24L9.95 7.876a2.253 2.253 0 0 0-.824-3.074Zm-.042 2.574a1.25 1.25 0 1 1-2.163-1.25 1.25 1.25 0 0 1 2.162 1.25h.001Z"
          />
        </svg>
      );
    default:
      return null;
  }
};
