import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

// Manually import the locales for moment.js and i18next.
import 'moment/dist/locale/el';
import 'moment/dist/locale/es';
import 'moment/dist/locale/pt-br';
import { languageByCountryCode } from '@/util/constants';

import { formatLanguageCodes } from './helper';

export const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'], // Added 'navigator' as a fallback detection method
  caches: ['localStorage'],
  lookupLocalStorage: 'moveoLanguage',
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    ns: ['common', 'templates', 'languages', 'timezones'],
    defaultNS: 'common',
    preload: [
      localStorage.getItem('moveoLanguage') || navigator.language.split('-')[0],
    ],
    supportedLngs: Object.keys(languageByCountryCode),
    detection: DETECTION_OPTIONS,
    lowerCaseLng: true,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    backend: {
      loadPath: function (lng: string[], ns: string) {
        const formattedLng = formatLanguageCodes(lng);

        return `/locales/${formattedLng}/${ns}.json`;
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

// Changes the moment.js locale to the adapt to the current language.
i18n.on('languageChanged', function (language) {
  moment.locale(language);
});

export default i18n;
