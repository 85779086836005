import { createSelector } from '@reduxjs/toolkit';

import { EntityValue } from '@/models/entity';
import { RootState } from '@/models/state';
import { Values } from '@/models/values';

export const selectValues = createSelector(
  (state: RootState) => state.values.allIds,
  (state: RootState) => state.values.byId,
  (allIds, byId) => allIds?.map((id: string) => byId[id])
);

export const selectById = (state, id: string) => state.values.byId?.[id];

export const selectEntityDraft = createSelector(
  selectValues,
  (state: RootState) => state.values,
  (values: EntityValue[], draft: Values) => {
    return {
      new_entity: draft.entityName,
      previous_entity: draft.previousName,
      values,
    };
  }
);
