import Fuse from 'fuse.js';
import { useTranslation } from 'react-i18next';

export const useAutocomplete = () => {
  const { t } = useTranslation();

  const onInputBlur = (
    targetValue: string,
    multiple: boolean,
    freeSolo: boolean,
    value
  ) => {
    if (multiple && freeSolo && Array.isArray(value) && targetValue) {
      let newValue: string[];
      if (targetValue.split(',').length > 1) {
        newValue = [
          ...value.map((x) => x.trim()),
          ...targetValue.split(',').map((x) => x.trim()),
        ];
      } else {
        newValue = [...value.map((x) => x.trim()), targetValue.trim()];
      }
      return newValue;
    }
  };

  const onMultipleBlur = (
    value: string | string[] | Record<string, string | boolean>
  ) => {
    if (Array.isArray(value)) {
      let newValue: string[] = [];

      const lastValue = value[value.length - 1];
      if (typeof lastValue === 'string' && lastValue.split(',').length > 1) {
        newValue = [
          ...value.slice(0, -1).map((x) => x.trim()),
          ...lastValue.split(',').map((x) => x.trim()),
        ];
      } else if (lastValue && typeof lastValue === 'string') {
        newValue = [...value.slice(0, -1), lastValue.trim()];
      } else if (lastValue) {
        newValue = [...value.slice(0, -1), lastValue];
      }
      return newValue;
    }
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  };

  const getFilterOptions = (
    optionsToFilter,
    params,
    disableAddNew,
    disablePattern,
    addNewSymbol
  ) => {
    const fuse = new Fuse(optionsToFilter, {
      keys: ['label'],
      shouldSort: false,
      threshold: 0.2,
      ignoreLocation: false,
    });
    const result = params.inputValue
      ? fuse.search(params.inputValue)
      : optionsToFilter;

    const filteredItems = result.map((r) => r?.item ?? r);
    // Suggest the creation of a new value
    if (
      params.inputValue !== '' &&
      !disableAddNew &&
      (disablePattern ? !disablePattern.exec(params.inputValue) : true)
    ) {
      const inputValue =
        addNewSymbol && params.inputValue.startsWith(addNewSymbol)
          ? params.inputValue
          : `${addNewSymbol}${params.inputValue}`;

      const labelValue =
        addNewSymbol && params.inputValue.startsWith(addNewSymbol)
          ? params.inputValue
          : `${addNewSymbol}${params.inputValue}`;

      filteredItems.push({
        inputValue: '' + inputValue,
        label: t('common.add_new', { 0: labelValue }),
      });
    }
    return filteredItems;
  };
  return { onInputBlur, getFilterOptions, onMultipleBlur };
};
