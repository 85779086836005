import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Globe = forwardRef<SVGSVGElement, Icon>(
  ({ size = 16, color = 'var(--icon-default-gray)', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon_stroke"
      >
        <path
          d="M14 8a6 6 0 01-6 6m6-6a6 6 0 00-6-6m6 6h-4m-2 6a6 6 0 01-6-6m6 6c1.105 0 2-2.687 2-6m-2 6c-1.105 0-2-2.687-2-6s.895-6 2-6m0 0c1.105 0 2 2.687 2 6M8 2a6 6 0 00-6 6m0 0h8"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);
Globe.displayName = 'Globe';

export default Globe;
