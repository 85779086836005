import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  subscribersEndpoints as endpoints,
  broadcastsEndpoints,
} from '@/api/endpoints';
import { callGet, callPut } from '@/api/fetcher';
import { IntegrationType } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { Subscriber, Subscribers } from '@/modules/broadcast/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';

import { useBroadcasts } from './useBroadcasts';
import { selectIsDraft, selectValidSubscribers } from '../redux/selectors';

interface UpdateProps {
  subscribers: Partial<Subscriber>[];
  channel: IntegrationType;
  broadcast_id: string;
}

interface ErrorSubscriber {
  field: string;
  message: string;
  index: number;
  value: string;
}

interface VerifyProps {
  channel: IntegrationType;
  subscribers: Partial<Subscriber>[];
}
export const API = Object.freeze({
  listSubscribers: async (
    broadcastId: string,
    cursor?: { cursor: string },
    filter?: string,
    filterStatus?: string
  ): Promise<Subscribers> =>
    callGet(
      endpoints.subscribers(broadcastId, cursor.cursor, filter, filterStatus)
    ),

  updateSubscribers: async ({
    broadcast_id,
    ...data
  }: UpdateProps): Promise<Subscribers> =>
    callPut(endpoints.subscribersUpsert(broadcast_id), data),
  verifySubscribers: async ({
    ...data
  }: UpdateProps): Promise<ErrorSubscriber[]> =>
    callPut(endpoints.subscribersVerify, data),
});

export const onSubscribersUpdated = (
  queryClient: QueryClient,
  broadcast_id: string,
  subscribers: Subscribers
) => {
  queryClient.setQueryData<Subscribers>(
    [endpoints.subscribers(broadcast_id)],
    () => subscribers
  );
};

export const useSubscribers = (
  broadcastId?: string,
  filter = '',
  filterStatus = null
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'broadcasts', actions.READ)
  );

  const isDraft = useSelector(selectIsDraft);
  const validSubscribers = useSelector(selectValidSubscribers);
  const { broadcast: APIbroadcast } = useBroadcasts(broadcastId);

  const {
    data: subscribers,
    status: listStatus,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [endpoints.subscribers(broadcastId), filter, filterStatus],
    queryFn: async ({ pageParam }) => {
      return API.listSubscribers(broadcastId, pageParam, filter, filterStatus);
    },
    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
    enabled: !!broadcastId && canRead && broadcastId !== 'draft',
    refetchInterval: 7 * 1000 /* 7 seconds */,
  });

  const { mutate: verifySubscribers, status: verifyStatus } = useMutation<
    ErrorSubscriber[],
    Error,
    VerifyProps
  >({
    mutationFn: API.verifySubscribers,
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutateAsync: updateSubscribersAsync, status: updateStatus } =
    useMutation<Subscribers, Error, UpdateProps>({
      mutationFn: API.updateSubscribers,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            endpoints.subscribers(broadcastId),
            broadcastsEndpoints.broadcast(broadcastId),
          ],
        });

        dispatch(
          addTemporalToast('success', t('broadcasts.recipients_updated'))
        );
      },
      onError: (error) => {
        dispatch(addErrorTemporalToast(error));
      },
    });

  const BATCH_SIZE = 5000;

  const updateBroadcastSubscribersAsync = async ({
    broadcast_id,
    subscribers,
    channel,
  }) => {
    if (subscribers.length === 0) {
      await updateSubscribersAsync({
        broadcast_id,
        subscribers: [],
        channel,
      });
    } else {
      for (let i = 0; i < subscribers.length; i += BATCH_SIZE) {
        const batch = subscribers.slice(i, i + BATCH_SIZE);
        try {
          await updateSubscribersAsync({
            broadcast_id,
            subscribers: batch,
            channel,
          });
        } catch (error) {
          console.error('Failed to update batch', error);
          dispatch(addErrorTemporalToast(error));
        }
      }
    }
  };
  const validSubscribersCount = isDraft
    ? validSubscribers.length
    : APIbroadcast?.subscribers_count + validSubscribers.length;

  return {
    verifySubscribers,
    verifyStatus,
    subscribers,
    fetchNextPage,
    hasNextPage,
    listStatus,
    isLoading,
    updateStatus,
    updateSubscribersAsync: updateBroadcastSubscribersAsync,
    validSubscribersCount,
  };
};
