import { useCallback } from 'react';

import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import BrainIcon from '@/components/atoms/Icons/Brain';
import Collection from '@/components/atoms/Icons/Collection';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Tile from '@/components/organisms/Tile/Tile';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Brain } from '@/models/brain';
import { selectAccountSlug } from '@/redux/session/selectors';
import { COLLECTION_LANGUAGES } from '@/util/constants';
import { preventClickThrough, resolveBrainsPath } from '@/util/util';

import styles from './BrainTile.module.scss';

interface Props {
  brain?: Brain;
  brain_version?: number;
  collection_name?: string;
  collection_id?: string;
}
const BrainTile = ({
  brain,
  brain_version,
  collection_id,
  collection_name,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const lastTrained = brain?.last_trained;
  const features = useFeatureFlag();

  const enableBrainCollections =
    !features?.enable_collections_for_rules &&
    COLLECTION_LANGUAGES.includes(brain?.language);

  const handleCollectionClick = useCallback(
    (e) => {
      preventClickThrough(e);
      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${brain.brain_id}/collections`,
          features.ai_agents
        )
      );
    },
    [brain?.brain_id, navigate, slug, features.ai_agents]
  );

  if (!brain?.brain_parent_id) {
    return (
      <Tile className={styles.tile}>
        <div className={styles.wrapper}>
          <Button>{t('brains.add_brain')}</Button>
        </div>
      </Tile>
    );
  }

  return (
    <Link
      to={resolveBrainsPath(
        `/${slug}/brains/${brain?.brain_parent_id}`,
        features.ai_agents
      )}
    >
      <Tile className={styles.tile}>
        <div className={styles.wrapper}>
          <BrainIcon size={24} />
          <div className={styles.content}>
            <div className={styles.info}>
              <div className={styles.header}>
                <Typography variant="body-semi-bold">{brain?.name}</Typography>{' '}
                <Typography
                  variant="label-regular"
                  className={styles.country}
                >{`(${brain?.language})`}</Typography>
              </div>
              <Typography variant="label-regular" className={styles.lastEdited}>
                {t('brains.last_trained')}:{' '}
                {lastTrained ? moment(lastTrained).fromNow() : '-'}
              </Typography>
            </div>
            <div className={styles.status}>
              <StatusBadge
                withIcon={false}
                variant="info"
                label={
                  brain_version === 0
                    ? t('common.draft')
                    : t('common.version_with_number', { 0: brain_version })
                }
              />
            </div>
          </div>
        </div>
        {enableBrainCollections && (
          <button
            className={styles.collectionWrapper}
            onClick={handleCollectionClick}
          >
            {collection_id ? (
              <Typography variant="label-regular" className={styles.collection}>
                <Collection size={16} color={'var(--icon-default-blue)'} />
                {collection_name}
              </Typography>
            ) : (
              <Typography variant="label-regular" className={styles.collection}>
                {t('collections.connect_collection')}
              </Typography>
            )}
          </button>
        )}
      </Tile>
    </Link>
  );
};

export default BrainTile;
