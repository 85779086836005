import { Icon } from '@/models/icon';

const Rules = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="7.5" stroke={color} />
      <path
        fill={color}
        d="M3.68 5.313h3.437v.628H5.754v4.434h1.363V11H3.68v-.625h1.332V5.941H3.68v-.628Zm7.968 3.175H9.281V11h-.73V5.312h3.492v.618H9.281v1.945h2.367v.613Z"
      />
    </svg>
  );
};

export default Rules;
