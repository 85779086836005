import Typography from '@mui/material/Typography';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import AvatarGroups from '@/components/atoms/Avatar/AvatarGroup/AvatarGroup';
import Dot from '@/components/atoms/Icons/Dot';
import { renderDialogIcon } from '@/components/organisms/SubNav/TreeView/Item';
import { Presence } from '@/models/presence';

import styles from './IconizedDialog.module.scss';

type Props = {
  dialogName: string;
  type: 'intent' | 'event' | 'unknown';
  showDot?: boolean;
  isOffTopics?: boolean;
  whoIsViewing?: Presence[];
  dotColor?: () => string;
};

const IconizedDialog = ({
  dialogName,
  type,
  showDot,
  isOffTopics,
  whoIsViewing,
  dotColor,
}: Props) => {
  return (
    <>
      {renderDialogIcon(type)}

      <Typography className={styles.name}>{dialogName}</Typography>

      {showDot && !isOffTopics && <Dot color={dotColor()} size={8} />}

      {whoIsViewing?.length ? (
        <span className={styles.avatars}>
          <AvatarGroups max={2}>
            {whoIsViewing.map((x) => (
              <Avatar key={x.agent_id} userId={x.agent_id} size="small" />
            ))}
          </AvatarGroups>
        </span>
      ) : null}
    </>
  );
};

export default IconizedDialog;
