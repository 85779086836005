import { useSelector } from 'react-redux';

import { selectUsedNodes } from '@/redux/usedNodes/selectors';

interface Props {
  brainId: string;
  name: string;
}
/* This hook is for retrieving the used nodes from the cache */
const useDialogNodesCache = () => {
  const usedNodes = useSelector(selectUsedNodes);

  const findUsedNodes = ({ brainId, name }: Props) => {
    if (!usedNodes) {
      return null;
    }
    return usedNodes[`${brainId}_${name}`];
  };

  return { findUsedNodes };
};

export default useDialogNodesCache;
