import { useCallback, useEffect } from 'react';

import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Info from '@/components/atoms/Icons/Info';
import Input from '@/components/atoms/Input/Input';
import { useAccount } from '@/hooks/useAccount';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { submitWithTrimming } from '@/util/util';
import { isValidAccountSlug } from '@/util/validator';

type Form = {
  slug: string;
};

interface Props {
  readOnly?: boolean;
}

const SlugFieldset = ({ readOnly = false }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account, updateAccount, updateStatus, checkSlugAsync } = useAccount();
  const formMethods = useForm<Form>({
    mode: 'onBlur',
    delayError: 100,
    defaultValues: {
      slug: '',
    },
  });

  const {
    register,
    trigger,
    formState: { errors, isDirty },
    reset,
  } = formMethods;

  const dispatch = useDispatch();

  const isLoading = account === undefined;

  useEffect(() => {
    if (account?.slug) {
      reset({ slug: account.slug });
    }
  }, [account?.slug, reset]);

  const onSubmit = useCallback(
    ({ slug }: Form) =>
      updateAccount(
        { slug: slug.toLowerCase() },
        {
          onSuccess: (account) => {
            dispatch(
              addTemporalToast('success', t('account.slug_updated_refreshing'))
            );
            // Redirect to the new URL in 3 seconds
            setTimeout(() => {
              navigate(`/${account.slug}/account/information`);
            }, 3000);
          },
        }
      ),
    [dispatch, navigate, t, updateAccount]
  );

  const validateSlug = useCallback(
    async (value: string) => {
      if (value.length === 0) {
        return t('account.slug_errors.empty');
      }

      if (value.length > 31) {
        return t('account.slug_errors.max_chars');
      }

      if (value === account?.slug) {
        return true;
      }

      if (!isValidAccountSlug(value)) {
        return t('account.slug_errors.name');
      }

      const isAvailable = await checkSlugAsync(value);
      if (!isAvailable) {
        return t('account.slug_errors.not_available', { 0: value });
      }

      return isAvailable;
    },
    [account?.slug, checkSlugAsync, t]
  );

  const renderSlugAvailableIcon = useCallback(() => {
    if (errors.slug?.message) {
      return <Info color="var(--icon-default-error)" />;
    }
    return null;
  }, [errors.slug?.message]);

  return (
    <FormFieldset
      title={t('account.slug')}
      subtitle={t('account.slug_subtitle')}
      primaryButton={{
        children: t('common.save'),
        isLoading: updateStatus === 'pending',
        disabled: !isDirty,
      }}
      onSubmit={submitWithTrimming(formMethods, onSubmit)}
      isLoading={isLoading}
      helpText={t('account.slug_help')}
    >
      <Input
        name="slug"
        readOnly={readOnly}
        register={register('slug', {
          validate: validateSlug,
        })}
        onChange={debounce(async () => {
          await trigger('slug');
        }, 500)}
        errorMessage={errors.slug?.message}
        size="large"
        iconRight={renderSlugAvailableIcon()}
      />
    </FormFieldset>
  );
};

export default SlugFieldset;
