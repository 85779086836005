import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Front = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        viewBox="-55 0 366 366"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
      >
        <defs>
          <linearGradient
            x1="12.519%"
            y1="85.213%"
            x2="88.228%"
            y2="10.023%"
            id="fr"
          >
            <stop stopColor="#FF0057" stopOpacity=".16" offset="0%" />
            <stop stopColor="#FF0057" offset="86.135%" />
          </linearGradient>
        </defs>
        <path
          d="M0 60.854C0 27.245 27.245 0 60.854 0h195.143v86.6c0 16.804-13.623 30.427-30.427 30.427h-79.762c-15.805.25-28.565 13.033-28.781 28.846v189.41c0 16.804-13.622 30.427-30.427 30.427H0V60.854Z"
          fill="#001B38"
        />
        <circle
          fill="url(#fr)"
          transform="rotate(90 147.013 147.015)"
          cx="147.013"
          cy="147.015"
          r="78.993"
        />
        <circle
          fill="url(#fr)"
          opacity=".5"
          transform="rotate(90 147.013 147.015)"
          cx="147.013"
          cy="147.015"
          r="78.993"
        />
      </svg>
    );
  }
);

Front.displayName = 'Front';

export default Front;
