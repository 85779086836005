import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  teamsEndpoints,
  teamsRolesEndpoints as endpoints,
} from '@/api/endpoints';
import { callGet, callPost, callDelete } from '@/api/fetcher';
import { PartialRole, Role, Roles } from '@/models/role';
import { Team, Teams } from '@/models/team';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

interface UpdateProps {
  team_id: string;
  role_ids: string[];
}

interface RemoveProps {
  team_id: string;
  role_id: string;
}

const API = {
  listRoles: async (team_id: string): Promise<Roles> =>
    callGet(endpoints.roles(team_id)),
  assignRoles: async ({ team_id, role_ids }): Promise<Roles> =>
    callPost(endpoints.roles(team_id), { role_ids }),
  removeRole: async ({ team_id, role_id }): Promise<PartialRole> =>
    callDelete(endpoints.role(team_id, role_id)),
};

export const onRoleAssigned = (
  queryClient: QueryClient,
  resp: Roles,
  team_id: string,
  accountSlug: string
) => {
  queryClient.setQueryData<Teams>(
    [teamsEndpoints.teams, accountSlug],
    ({ teams }: { teams: Team[] }) => {
      const newRoleids = resp.roles.map((r) => r.role_id);
      const updatedTeams = teams.map((t) => {
        if (t.team_id === team_id) {
          return {
            ...t,
            role_ids: [...t.role_ids, ...newRoleids],
          };
        }
        return t;
      });
      return { teams: updatedTeams };
    }
  );

  queryClient.setQueryData<Roles>(
    [endpoints.roles(team_id), accountSlug],
    (prev: { roles: Role[] }) => {
      return {
        roles: [...(prev?.roles || []), ...resp.roles],
      };
    }
  );
};

export const onRoleRemoved = (
  queryClient: QueryClient,
  resp: PartialRole,
  team_id: string,
  accountSlug: string
) => {
  queryClient.setQueryData<Teams>(
    [teamsEndpoints.teams, accountSlug],
    (prev: Teams) => {
      const updatedTeams = prev.teams.map((t) => {
        if (t.team_id === team_id) {
          return {
            ...t,
            role_ids: t.role_ids.filter((r) => r !== resp.role_id),
          };
        }
        return t;
      });
      return { teams: updatedTeams };
    }
  );

  queryClient.setQueryData<Roles>(
    [endpoints.roles(team_id), accountSlug],
    ({ roles }: { roles: Role[] }) => {
      const updatedRoles = roles.filter((r) => r.role_id !== resp.role_id);
      return { roles: updatedRoles };
    }
  );
};

export const useTeamRoles = (team_id?: string) => {
  const accountSlug = useSelector(selectAccountSlug);
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data: roles, status: listStatus } = useQuery<Roles, Error>({
    queryKey: [endpoints.roles(team_id), accountSlug],
    queryFn: () => API.listRoles(team_id),
    enabled: !!accountSlug && !!team_id,
  });

  const { mutateAsync: assignRoles, status: updateStatus } = useMutation<
    Roles,
    Error,
    UpdateProps
  >({
    mutationFn: API.assignRoles,
    onSuccess: (resp, variables) => {
      onRoleAssigned(queryClient, resp, variables.team_id, accountSlug);
      dispatch(addTemporalToast('success', t('roles.role_assigned')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutateAsync: removeRole, status: removeStatus } = useMutation<
    PartialRole,
    Error,
    RemoveProps
  >({
    mutationFn: API.removeRole,
    onSuccess: (resp, variables) => {
      onRoleRemoved(queryClient, resp, variables.team_id, accountSlug);
      dispatch(addTemporalToast('success', t('roles.role_removed')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    roles: roles?.roles,
    listStatus,
    assignRoles,
    removeRole,
    updateStatus,
    removeStatus,
  };
};
