import { useCallback, useEffect } from 'react';

import cn from 'classnames';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import StatusDot from '@/components/atoms/StatusDot/StatusDot';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { submitWithTrimming } from '@/util/util';
import { brainRules, errorMessage } from '@/util/validator';

import styles from './styles.module.scss';

type Form = {
  name: string;
  description: string;
};

interface InformationProps {
  brain?: Brain;
}

const InformationFieldset = ({ brain }: InformationProps) => {
  const { t } = useTranslation();
  const brain_id = brain?.brain_id;

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const { updateBrain, updateStatus } = useBrains(brain_id);
  const formMethods = useForm<Form>({ mode: 'onChange' });
  const {
    register,
    reset,
    formState: { errors, isDirty },
  } = formMethods;
  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      updateBrain({ ...form, brain_id: brain?.brain_id });
    },
    [brain?.brain_id, updateBrain]
  );

  useEffect(() => {
    if (brain?.brain_id) {
      reset({
        name: brain?.name,
        description: brain?.description,
      });
    }
  }, [brain?.brain_id, brain?.description, brain?.name, reset]);

  return (
    <div className={styles.brain_settings_fields}>
      <FormFieldset
        title={t('brains.info.title')}
        subtitle={t('brains.info.subtitle')}
        primaryButton={{
          children: t('common.save'),
          isLoading: updateStatus === 'pending',
          disabled: !isDirty,
        }}
        onSubmit={submitWithTrimming(formMethods, onSubmit)}
        isLoading={!brain}
      >
        <Input
          name="name"
          label={t('common.name')}
          register={register('name', brainRules.name)}
          errorMessage={errorMessage({
            field: errors.name,
            maxLength: brainRules.name.maxLength,
          })}
          placeholder={t('common.name_placeholder')}
          readOnly={!canWrite}
        />
        <Input
          name="description"
          label={t('common.description')}
          register={register('description', brainRules.description)}
          errorMessage={errorMessage({
            field: errors.description,
            maxLength: brainRules.description.maxLength,
          })}
          placeholder={t('common.description_placeholder')}
          size="large"
          readOnly={!canWrite}
        />
        <div className={styles.brain_field__label}>{t('common.status')}</div>
        <span
          className={cn([
            styles.brain_field__data,
            styles.brain_field__data_status,
          ])}
        >
          <StatusDot status={brain?.status} showLabel />
        </span>

        <div className={styles.brain_field__label}>{t('common.created')}</div>
        <div className={styles.brain_field__data}>
          {moment(brain?.created).format('MM.DD.YY')}
        </div>
        <div className={styles.brain_field__label}>
          {t('brains.last_modified')}
        </div>
        <div className={styles.brain_field__data}>
          {moment(brain?.updated).fromNow()}
        </div>
        <div className={styles.brain_field__label}>
          {t('brains.last_trained')}
        </div>
        <div className={styles.brain_field__data}>
          {brain?.last_trained ? moment(brain.last_trained).fromNow() : '-'}
        </div>
      </FormFieldset>
    </div>
  );
};

export default InformationFieldset;
