import { FunctionComponent, useCallback } from 'react';

import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useFocused, useSlate } from 'slate-react';

import { isLeftClickEvent, preventClickThrough } from '@/util/util';

import {
  isLinkActive,
  toggleMark,
  isMarkActive,
  toggleBlock,
  isBlockActive,
} from '../utils';

import styles from '../RichTextEditor.module.scss';

interface IconProps {
  size: number;
  color: string;
}

interface CreateStyleButtonProps {
  type: string;
  Icon: FunctionComponent<IconProps>;
  onClick?: (event: MouseEvent, props: unknown) => void;
  selectedText?: string;
  size?: number;
}

// Factory function to create a slate.js button
export const StyleButton: FunctionComponent<CreateStyleButtonProps> = ({
  type,
  Icon,
  onClick,
  selectedText,
  size = 18,
  ...props
}) => {
  const editor = useSlate();
  const isEditorFocused = useFocused();
  const dispatch = useDispatch();
  const typeOfActive = useCallback(() => {
    if (type === 'bold' || type === 'italic' || type === 'underline') {
      return isMarkActive(editor, type);
    } else if (type === 'link') {
      return isLinkActive(editor);
    } else if (type === 'ol_list' || type === 'ul_list') {
      return isBlockActive(editor, type);
    }
  }, [editor, type]);
  const handleClick = useCallback(
    (event) => {
      if (!isLeftClickEvent(event)) {
        return;
      }

      preventClickThrough(event);
      if (onClick && isEditorFocused) {
        onClick(event, { selectedText, dispatch, editor, ...props });
      } else {
        if (type === 'ol_list' || type === 'ul_list') {
          toggleBlock(editor, type);
        } else {
          toggleMark(editor, type);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, editor, onClick, props, selectedText, type, isEditorFocused]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleClick(event);
      }
    },
    [handleClick]
  );

  return (
    <span
      {...props}
      role="button"
      tabIndex={0}
      className={cn(styles.action, {
        [styles.active]: isEditorFocused && typeOfActive(),
        [styles.disabled]: !isEditorFocused,
      })}
      onMouseDown={handleClick}
      onKeyDown={handleKeyDown}
    >
      <span>
        <Icon
          size={size}
          color={
            isEditorFocused && typeOfActive()
              ? 'var(--icon-default-blue)'
              : 'var(--icon-default-black)'
          }
        />
      </span>
    </span>
  );
};
