import { useCallback, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import Input from '@/components/atoms/Input/Input';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import {
  DEFAULT_HOURS,
  extractIntervalsFromHours,
} from '@/components/pages/BusinessHours/utils';
import { useAccount } from '@/hooks/useAccount';
import { API as businessHoursApi } from '@/hooks/useBusinessHours';
import useDesks from '@/hooks/useDesks';
import { popModal } from '@/redux/modals/actions';
import { errorMessage, deskRules } from '@/util/validator';

import Modal from './Modal';

const ModalDeskCreation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { createDesk, createStatus } = useDesks();
  const { slug } = useAccount();

  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });
  const [serviceDesk, setServiceDesk] = useState(true);

  const onSubmit = useCallback(
    (newDesk) => {
      createDesk(
        {
          name: newDesk.name,
          is_service_desk: serviceDesk,
        },
        {
          onSuccess: (desk) => {
            navigate(`/${slug}/environments/${desk.desk_id}`);
            businessHoursApi.addBusinessHour(desk.desk_id, {
              name: t('business_hours.default'),
              timezone: moment.tz.guess(),
              is_default: true,
              intervals: extractIntervalsFromHours([DEFAULT_HOURS]),
              holidays: [],
            });
            dispatch(popModal());
          },
        }
      );
    },
    [createDesk, dispatch, navigate, serviceDesk, slug, t]
  );

  return (
    <Modal
      title={t('environments.modal.create_title')}
      onPrimarySubmit={handleSubmit(onSubmit)}
      isSubmitting={createStatus === 'pending'}
      primaryButtonText={t('common.create')}
      autoFocus
    >
      <form autoComplete="off">
        <div className="inputModal__container">
          <Input
            name="name"
            label={t('common.name')}
            register={register('name', deskRules(t).name)}
            errorMessage={errorMessage({
              field: errors.name,
              ...deskRules(t).name,
            })}
            placeholder={t('common.name_placeholder')}
            size="medium"
            autoFocus
          />
        </div>

        <div className="inputModal__container">
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setServiceDesk((prev) => !prev)}
                checked={serviceDesk}
                color="primary"
              />
            }
            label={
              <TitleSubtitle
                title={t('environments.modal.human_chat')}
                subtitle={t('environments.modal.human_chat_subtitle')}
                noGutters
              />
            }
          />
        </div>
      </form>
    </Modal>
  );
};

export default ModalDeskCreation;
