import { Brain } from '@/models/brain';

/**
 * Recursively updates all URL properties within a given object from HTTP to HTTPS.
 * This function iterates over the properties of the object. If a property named 'url'
 * is found and is a string, it replaces 'http://' with 'https://'. For properties that
 * are objects, it recursively applies the same update. Other properties are left unchanged.
 *
 * @param {Partial<Brain>} obj - The object containing URL properties to be updated.
 * @returns {Partial<Brain>} The original object with its URL properties updated to HTTPS.
 */
export const updateBrainsUrls = (obj: Partial<Brain>) => {
  for (const key in obj) {
    if (key === 'url' && typeof obj[key] === 'string') {
      obj[key] = obj[key].replace(/http:\/\//g, 'https://');
    } else if (obj[key] && typeof obj[key] === 'object') {
      updateBrainsUrls(obj[key]);
    }
  }
  return obj;
};
