import { RootState } from '@/models/state';
import { ActionType } from '@/modules/rules/model';

import { isConditionNested } from '../util';

export const selectRule = (state: RootState) => state.rule;

export const selectRuleTriggers = (state: RootState) => state.rule.triggers;

export const selectHasRuleTriggers = (state: RootState) =>
  state.rule.triggers.length > 0;

export const selectTriggerIsOpen = (state: RootState, index) =>
  state.rule.triggers[index]?.isOpen;

export const selectRuleActions = (state: RootState) => state.rule.actions;

export const selectHasRuleActions = (state: RootState) => {
  return state.rule.actions.length > 0;
};

export const selectRuleCondition = (state: RootState) => state.rule.condition;

export const selectIsConditionOpen = (state: RootState, indexes) => {
  const { outerIndex, index } = indexes;
  const isNested = isConditionNested(state.rule.condition);
  if (isNested) {
    return state.rule.condition.conditions[outerIndex].conditions[index]
      .isConditionOpen;
  } else {
    return state.rule.condition.conditions[index].isConditionOpen;
  }
};

export const selectIsAnyConiditionOpen = (state: RootState) => {
  const isOpen = (obj) => {
    const result = [];
    const isNested = isConditionNested(obj);
    if (isNested) {
      Object.keys(obj)?.forEach((key) => {
        if (key === 'conditions') {
          obj[key]?.forEach((condition) => {
            condition.conditions.forEach((c) => {
              result.push(c.isConditionOpen);
            });
          });
        }
      });
    } else {
      Object.keys(obj)?.forEach((key) => {
        if (key === 'conditions') {
          obj[key]?.forEach((condition) => {
            result.push(condition.isConditionOpen);
          });
        }
      });
    }
    return result.some((x) => x === true);
  };
  return isOpen(state.rule.condition);
};

export const selectTriggerOptions = (state: RootState) => state.rule.options;

export const selectIsConditionError = (state: RootState) => {
  const isError = (obj) => {
    const result = [];
    const isNested = isConditionNested(obj);
    if (isNested) {
      Object.keys(obj)?.forEach((key) => {
        if (key === 'conditions') {
          obj[key]?.forEach((condition) => {
            condition.conditions.forEach((c) => {
              result.push(c.isError);
            });
          });
        }
      });
    } else {
      Object.keys(obj)?.forEach((key) => {
        if (key === 'conditions') {
          obj[key]?.forEach((condition) => result.push(condition.isError));
        }
      });
    }
    return result.some((x) => x === true);
  };
  return isError(state.rule.condition);
};

const isError = (arr) => {
  const result = [];
  arr?.forEach((action) => result.push(action.isError));
  return result.some((x) => x === true);
};

export const selectIsActionError = (state: RootState) => {
  return isError(state.rule.actions);
};

export const selectIsTriggerError = (state: RootState) => {
  return isError(state.rule.triggers);
};

export const selectActionIsOpen = (state: RootState, index) =>
  state.rule.actions[index]?.isOpen;

export const selectConditionHover = (state: RootState) =>
  state.rule.condition.isHover;

export const selectIsRuleDirty = (state: RootState) => state.rule.dirty;

export const selectActionsOptions = (state: RootState) =>
  state.rule.actionsOptions;

export const selectIsAssignBrainSelected = (state: RootState) =>
  state.rule.actions.some((action) => action.type === ActionType.ASSIGN_BRAIN);
