import { HTMLAttributes } from 'react';

import { Icon } from '@/models/icon';

export const DollarIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon & HTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <rect width="15" height="15" x=".5" y=".5" stroke={color} rx="7.5" />
      <path
        fill={color}
        d="M7.775 12V4h.512v8h-.512Zm1.478-5.44a.853.853 0 0 0-.369-.638c-.214-.152-.493-.228-.837-.228-.242 0-.45.036-.622.11a.92.92 0 0 0-.397.296.722.722 0 0 0-.05.775.829.829 0 0 0 .253.25c.106.067.224.123.353.169.13.046.26.084.391.115l.6.15c.242.057.474.133.697.228.225.096.426.217.603.363.18.146.32.322.425.528.104.206.156.448.156.725 0 .375-.096.705-.287.99-.192.284-.47.506-.832.666-.36.159-.796.238-1.309.238-.498 0-.93-.077-1.297-.231a1.93 1.93 0 0 1-.856-.675c-.204-.296-.315-.657-.331-1.082h1.14a.97.97 0 0 0 .207.557c.12.148.278.258.471.33.196.074.415.11.657.11.252 0 .473-.037.662-.112.192-.077.342-.184.45-.319a.77.77 0 0 0 .166-.481.612.612 0 0 0-.147-.413 1.076 1.076 0 0 0-.403-.275 3.885 3.885 0 0 0-.6-.2l-.728-.187c-.527-.136-.944-.34-1.25-.616-.304-.277-.457-.645-.457-1.103 0-.377.103-.707.307-.99.206-.284.486-.504.84-.66a2.918 2.918 0 0 1 1.203-.237c.455 0 .853.079 1.194.237.344.156.614.374.81.653.195.277.296.596.303.956H9.253Z"
      />
    </svg>
  );
};
