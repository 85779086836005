import { Icon } from '@/models/icon';

const Bold = ({ size = 18, color = 'var(--icon-default-black)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
    >
      <path
        fill={color}
        d="M10.406 14.625H4.625V3.375h5.313a3.281 3.281 0 0 1 2.5 5.406 3.281 3.281 0 0 1-2.032 5.844ZM6.5 12.75h3.894a1.408 1.408 0 0 0 .994-2.4 1.407 1.407 0 0 0-.994-.412H6.5v2.812Zm0-4.688h3.438a1.406 1.406 0 1 0 0-2.812H6.5v2.813Z"
      />
    </svg>
  );
};

export default Bold;
