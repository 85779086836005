import { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_VIDEO } from '@/components/organisms/Modals/ModalConductor';
import useHomeCheckList, {
  AccountUserPrefsEnum,
  INAPPHELP_KEYS,
} from '@/hooks/useHomeCheckList';
import useLocalStorage from '@/hooks/useLocalStorage';
import { RootState } from '@/models/state';
import { hideHelper, showHelper } from '@/redux/helpers/actions';
import { selectHelper } from '@/redux/helpers/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import {
  selectAccountSlug,
  selectCollectionId,
} from '@/redux/session/selectors';
import { selectIframeView } from '@/redux/user/selectors';

export const useCollectionHelper = () => {
  const { accountUserChecklist, markAsComplete, getSteps } = useHomeCheckList();
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dismissVideo, setDismissVideo] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_COLLECTION],
    false
  );
  const collectionId = useSelector(selectCollectionId);
  const showWhatisCollectionVideo = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_COLLECTION])
  );
  const isIframeView = useSelector(selectIframeView);
  const steps = useMemo(
    () =>
      getSteps(
        accountUserChecklist,
        slug,
        undefined,
        undefined,
        undefined,
        undefined,
        collectionId
      ),
    [accountUserChecklist, collectionId, getSteps, slug]
  );

  useEffect(() => {
    if (
      !dismissVideo &&
      accountUserChecklist &&
      !accountUserChecklist[AccountUserPrefsEnum.WHAT_IS_COLLECTION]
    ) {
      dispatch(
        showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_COLLECTION])
      );
      return () => {
        dispatch(popModal());
      };
    }
  }, [accountUserChecklist, dismissVideo, dispatch, showWhatisCollectionVideo]);

  const handleCloseVideo = useCallback(() => {
    setDismissVideo(true);
    dispatch(
      hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_COLLECTION])
    );
  }, [dispatch, setDismissVideo]);

  useEffect(() => {
    if (showWhatisCollectionVideo && steps?.[1] && !isIframeView) {
      const step = steps[1]?.steps[0];
      dispatch(
        pushModal(MODAL_VIDEO, {
          title: t(step?.label),
          url: step.video,
          onClick: () => {
            handleCloseVideo();
            dispatch(popModal());
          },
          onPlayClick: () => {
            markAsComplete(step?.checklistStep);
          },
          onClose: handleCloseVideo,
          primaryButtonText: t('home.checklist.lets_create'),
        })
      );
      return () => {
        dispatch(popModal());
      };
    }
  }, [
    dispatch,
    handleCloseVideo,
    markAsComplete,
    showWhatisCollectionVideo,
    steps,
    t,
    isIframeView,
  ]);
};
