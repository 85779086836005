import { useCallback, useState } from 'react';

import { TypographyVariant } from '@mui/material/styles';
import dotize from 'dotize';
import pick from 'lodash/pick';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import Header from '@/components/organisms/Header/Header';
import usePrompt from '@/hooks/usePrompt';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useBundles } from '@/modules/bundles/hooks/useBundles';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { getRestrictedNames } from '@/util/util';
import { LENGTH_XS, getEditableTextValidationSchema } from '@/util/validator';

import { revertDirty, setDraftBundle } from '../redux/actions';
import { selectBundle, selectIsBundleDirty } from '../redux/selectors';

export const BundleHeader = ({ name }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const slug = useSelector(selectAccountSlug);
  const deskId = useSelector(selectDeskId);
  const {
    updateBundle,
    addBundle,
    isLoading: isBundleLoading,
    bundles,
  } = useBundles(deskId);
  const draftBundle = useSelector(selectBundle);
  const isDirty = useSelector(selectIsBundleDirty);
  const location = useLocation();
  const isDraft = location.pathname.includes('draft');
  const dispatch = useDispatch();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'bundles', actions.WRITE)
  );

  const redirectToBundles = useCallback(() => {
    navigate(`/${slug}/environments/${deskId}/context_bundles`);
  }, [deskId, navigate, slug]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    if (isDraft) {
      addBundle(
        {
          ...pick(draftBundle, ['name', 'desk_id', 'description']),
          context: dotize.backward(draftBundle.context),
        },
        {
          onSuccess: () => {
            redirectToBundles();
          },
        }
      );
    } else {
      updateBundle(
        { ...draftBundle, context: dotize.backward(draftBundle.context) },
        {
          onSuccess: () => {
            redirectToBundles();
          },

          onSettled: () => {
            setIsLoading(false);
            dispatch(revertDirty({ dirtyState: false }));
          },
        }
      );
    }
  }, [
    addBundle,
    dispatch,
    draftBundle,
    isDraft,
    redirectToBundles,
    updateBundle,
  ]);

  usePrompt(isDirty, t('common.header_prompt'), undefined, handleSubmit);

  const handleNameSubmit = useCallback(
    (text: string) => {
      dispatch(
        setDraftBundle({
          ...draftBundle,
          name: text,
          dirty: true,
        })
      );
    },
    [dispatch, draftBundle]
  );

  // Filters out the current bundle name from the list of restricted values
  const restrictedValues = getRestrictedNames(bundles, name);

  const validationSchema = getEditableTextValidationSchema(
    LENGTH_XS,
    restrictedValues,
    t('environment.environment_name')
  );

  const editableTextProps = {
    defaultValue: name,
    onSubmit: handleNameSubmit,
    variant: 'h2-semi-bold' as TypographyVariant,
    canEdit: canWrite,
    validationSchema,
  };

  return (
    <Header>
      <Header.BreadCrumbs />
      <Header.Body>
        <Header.Title
          title={
            <EditableText<typeof validationSchema> {...editableTextProps} />
          }
          onBackClick={redirectToBundles}
          isLoading={isBundleLoading}
        />
        <Header.Actions>
          <Button variant="secondary" onClick={redirectToBundles}>
            {t('common.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!isDirty || !canWrite}
            isLoading={isLoading}
          >
            {t('common.save_and_close')}
          </Button>
        </Header.Actions>
      </Header.Body>
    </Header>
  );
};

export default BundleHeader;
