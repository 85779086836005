import { Icon } from '@/models/icon';

const Folder = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width={size}
      height={size}
    >
      <path
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.667 12.667A1.334 1.334 0 0 1 13.334 14H2.667a1.334 1.334 0 0 1-1.333-1.333V3.333A1.333 1.333 0 0 1 2.667 2h3.334l1.333 2h6a1.333 1.333 0 0 1 1.333 1.333v7.334Z"
      />
    </svg>
  );
};

export default Folder;
