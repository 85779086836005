import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Calendar from '@/components/atoms/Icons/Calendar';
import Edit from '@/components/atoms/Icons/Edit';
import Success from '@/components/atoms/Icons/SuccessTick';

import styles from './DateFilterItem.module.scss';

interface Props {
  selected: boolean;
  onClick: (item: { title_key: string }) => void;
  item: {
    title_key: string;
    days?: number;
    single?: boolean;
  };
  isLast: boolean;
  isLastSelected: boolean;
  disabled: boolean;
}

const getSvgColor = (selected: boolean, disabled: boolean) => {
  if (selected) {
    return 'var(--icon-default-blue)';
  }
  if (disabled) {
    return 'var(--icon-disabled-gray)';
  }
  return 'var(--text-default-gray)';
};

const DateFilterItem = ({
  selected,
  onClick,
  item,
  isLast,
  isLastSelected,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const svgColor = getSvgColor(selected, disabled);

  return (
    <Tooltip
      enterDelay={300}
      title={disabled ? t('billing.date_filter_disabled') : ''}
      arrow
    >
      <button
        className={cn({
          [styles.button]: true,
          [styles.selected]: selected,
          [styles.isLastSelected]: isLastSelected,
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
        onClick={() => onClick(item)}
      >
        {isLast ? <Edit color={svgColor} /> : <Calendar color={svgColor} />}
        <p>{t(item.title_key)}</p>
        {selected && (
          <div className={styles.success}>
            <Success />
          </div>
        )}
      </button>
    </Tooltip>
  );
};

export default DateFilterItem;
