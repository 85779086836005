import { Icon } from '@/models/icon';

const Dialogs = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
    >
      <g fill={color} clipPath="url(#a)">
        <path
          stroke={color}
          strokeWidth=".4"
          d="M7.827 1.841a7.112 7.112 0 0 0-3.725 1.524c-.356.287-.932.879-1.195 1.227-.734.978-1.191 2.1-1.372 3.37-.047.323-.047 1.402 0 1.725.192 1.348.712 2.559 1.526 3.561.268.332.871.915 1.197 1.16.994.744 2.107 1.199 3.378 1.38.323.046 1.402.046 1.725 0a7.224 7.224 0 0 0 3.378-1.38 9.002 9.002 0 0 0 1.197-1.16c.68-.835 1.217-1.958 1.422-2.972.118-.58.14-.808.14-1.452 0-.646-.022-.871-.14-1.452-.208-1.022-.74-2.134-1.422-2.972a8.995 8.995 0 0 0-1.197-1.159A7.147 7.147 0 0 0 9.485 1.88c-.343-.052-1.307-.077-1.658-.039Zm1.274.439a6.551 6.551 0 0 1 4.016 1.874c1.057 1.049 1.68 2.3 1.904 3.835.05.323.05 1.347 0 1.67-.15 1.02-.496 1.957-1.01 2.74-.294.447-.508.71-.867 1.071-1.027 1.027-2.3 1.655-3.81 1.877-.323.05-1.348.05-1.671 0-1.044-.154-1.937-.482-2.74-1.011a5.959 5.959 0 0 1-1.095-.893 6.521 6.521 0 0 1-1.893-4.865 6.476 6.476 0 0 1 1.92-4.397c1.126-1.126 2.496-1.759 4.15-1.915a8.71 8.71 0 0 1 1.096.014Z"
        />
        <path d="M8.113 4.855a1.24 1.24 0 0 0-.712.656c-.081.171-.09.213-.09.455 0 .244.009.283.09.451.111.227.303.415.543.527.097.045.197.09.225.099.045.014.047.075.047.787v.772h-1.31c-.886 0-1.329.01-1.368.03-.122.065-.133.166-.133 1.091 0 .805-.003.873-.048.887a3.392 3.392 0 0 0-.225.098c-.24.112-.432.3-.543.527-.08.168-.089.208-.089.452 0 .255.006.28.103.48.128.26.312.442.574.566.175.084.205.09.492.09.287 0 .318-.006.493-.09.245-.115.446-.317.56-.564.083-.177.089-.208.089-.496 0-.284-.006-.32-.078-.46a1.238 1.238 0 0 0-.554-.522 1.174 1.174 0 0 0-.25-.098c-.048 0-.05-.05-.05-.757v-.757h5.149V10.61l-.14.05c-.272.098-.495.3-.642.578-.059.112-.067.163-.067.435 0 .289.005.32.09.496.113.247.314.449.559.564.175.084.206.09.492.09.287 0 .318-.006.493-.09.746-.356.921-1.293.342-1.828-.153-.14-.386-.27-.542-.3l-.084-.017v-.87c0-.92-.011-1.02-.133-1.085-.04-.02-.482-.03-1.367-.03H8.718v-1.53l.147-.053c.318-.112.537-.314.682-.625.064-.14.075-.197.075-.43 0-.24-.008-.283-.089-.457a1.271 1.271 0 0 0-.562-.586c-.162-.08-.209-.092-.46-.098a1.313 1.313 0 0 0-.398.031Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5.823h16v16H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Dialogs;
