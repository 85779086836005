import { useMemo } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Banner } from '@/components/atoms/Banner/Banner';
import { renderCroppedURL } from '@/util/util';

import Modal from './Modal';

import styles from './ModalIngestionReport.module.scss';

interface Props {
  data: {
    title: string;
    value: string;
    urls?: string[];
  }[];
  hasBanner?: boolean;
}

const ModalIngestionReport = ({ data, hasBanner = false }: Props) => {
  const { t } = useTranslation();

  const box1Data = useMemo(() => data?.slice(0, 3), [data]);
  const box2Data = useMemo(() => data?.slice(3), [data]);

  const renderBox = (boxData, isFirst = false) => (
    <div className={styles.box}>
      {boxData?.map(({ title, value, urls = [] }) => {
        const isExluded = title === t('collections.urls_excluded');
        const isNotIngested = title === t('collections.urls_not_ingested');
        const isTooltipNeeded = isExluded || isNotIngested;
        const urlsFormatted = urls
          .map((url) => renderCroppedURL(url.split(': ')[1], 40, 15))
          .join('\n');
        const displayTitle = !isFirst && title ? `URLs ${title}` : title;

        const rowContent = (
          <div
            key={title}
            className={cn(styles.crawlRow, {
              [styles.pointer]: isTooltipNeeded && urls.length > 0,
            })}
          >
            <Typography
              color="var(--text-default-gray)"
              variant="label-semi-bold"
            >
              {displayTitle}
            </Typography>
            <Typography color="var(--text-default-black)">{value}</Typography>
          </div>
        );

        return isTooltipNeeded && urls.length > 0 ? (
          <Tooltip title={urlsFormatted} placement="top">
            {rowContent}
          </Tooltip>
        ) : (
          rowContent
        );
      })}
    </div>
  );

  return (
    <Modal title={t('collections.ingestion_report')} passiveModal size="medium">
      <div
        className={cn(styles.crawlContainer, { [styles.hasBanner]: hasBanner })}
      >
        {renderBox(box1Data, true)}
        {renderBox(box2Data)}
      </div>
      {hasBanner && (
        <Banner relativePosition variant="neutral">
          <Typography>{t('collections.report_banner')}</Typography>
        </Banner>
      )}
    </Modal>
  );
};

export default ModalIngestionReport;
