import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
interface Props {
  onReload: () => void;
}

const AnalyticsError = ({ onReload }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="analytics-empty">
      <svg
        viewBox="0 0 105 106"
        width="105"
        height="104"
        xmlns="http://www.w3.org/2000/svg"
        fill="var(--surface-tertiary-blue)"
      >
        <path d="M52.517 103.951c-4.495 0-9.021-.594-13.494-1.792-27.666-7.41-44.139-35.944-36.719-63.61 3.583-13.388 12.18-24.592 24.19-31.525C38.514.092 52.506-1.753 65.904 1.83c27.656 7.41 44.139 35.945 36.719 63.61a52.092 52.092 0 0 1-5.84 13.59 2.61 2.61 0 0 1-3.573.87 2.601 2.601 0 0 1-.87-3.573 46.824 46.824 0 0 0 5.269-12.232c3.233-12.063 1.569-24.656-4.664-35.468-6.243-10.812-16.324-18.55-28.387-21.772-12.063-3.223-24.655-1.57-35.467 4.674-10.812 6.244-18.55 16.324-21.773 28.376-6.678 24.89 8.152 50.573 33.04 57.24 12.445 3.339 25.387 1.453 36.443-5.3a2.598 2.598 0 0 1 2.714 4.431c-8.31 5.078-17.586 7.675-26.998 7.675Z" />
        <path d="M52.464 59.791a2.594 2.594 0 0 1-2.597-2.597V28.627a2.594 2.594 0 0 1 2.597-2.597 2.594 2.594 0 0 1 2.597 2.597v28.567a2.601 2.601 0 0 1-2.597 2.597ZM52.464 67.582a2.601 2.601 0 0 1 2.597 2.597v5.194a2.601 2.601 0 0 1-2.597 2.597 2.601 2.601 0 0 1-2.597-2.597v-5.194a2.594 2.594 0 0 1 2.597-2.597Z" />
      </svg>
      <p className="analytics-empty-message">{t('common.something_wrong')}</p>
      <p className="analytics-empty-subMessage">
        {t('analytics.wait_and_try_again')}
        <br />
        <Button onClick={onReload} variant="tertiary" size="small" underline>
          {t('common.reload')}
        </Button>
      </p>
    </div>
  );
};

export default AnalyticsError;
