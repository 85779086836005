import { memo, useEffect } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Banner } from '@/components/atoms/Banner/Banner';
import DialogEditor from '@/components/organisms/Dialogs/DialogEditor';
import SubHeaderDialog from '@/components/organisms/SubHeader/SubHeaderDialog';
import SubNavDialog from '@/components/organisms/SubNav/SubNavDialog';
import { makeItemsName } from '@/components/organisms/SubNav/utils';
import Toolkit from '@/components/organisms/Toolkit';
import useDialogNodes from '@/hooks/useDialogNodes';
import useDialogs from '@/hooks/useDialogs';
import usePrompt from '@/hooks/usePrompt';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import {
  clearDialogNodes,
  setDialog,
  setDialogAsync,
} from '@/redux/nodes/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { setDialogId } from '@/redux/session/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import useDialogHelper from './useDialogHelper';

import styles from './Dialog.module.scss';

const Dialog: React.FC = memo(() => {
  const { t } = useTranslation();
  useDialogHelper();
  const { dialogId } = useParams();
  const isDraft = dialogId === 'draft';
  const brainId = useSelector(selectBrainId);
  const { dialog, saveDialog, dialogs } = useDialogs(brainId, dialogId);
  const dispatch = useDispatch();
  useTitle(t('pages.dialog', { 0: dialog?.name }));

  useDialogNodes({ type: 'dialog' });

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  useEffect(() => {
    pageView(PageName.DIALOG);
  }, []);

  const {
    isDirty,
    selectedNodeId,
    selectedActionId,
    selectedRequisiteIndex,
    dialog_id,
  } = useSelector(
    (state: RootState) => ({
      isDirty: state.nodes.dirty,
      selectedNodeId: state.tryIt.selectedNodeId,
      selectedActionId: state.tryIt.selectedActionId,
      selectedRequisiteIndex: state.tryIt.selectedRequisiteIndex,
      dialog_id: state.nodes.dialogId,
    }),
    shallowEqual
  );

  usePrompt(isDirty, t('prompts.leave_warning'), undefined, saveDialog);

  useEffect(() => {
    if (dialog?.dialog_id && !isDraft) {
      dispatch(setDialog(dialog));
      dispatch(setDialogId(dialog?.dialog_id));
    }
    return () => {
      if (dialog?.dialog_id) {
        dispatch(clearDialogNodes());
      }
    };
  }, [dispatch, dialog?.dialog_id, dialog, isDraft]);

  useEffect(() => {
    if (isDraft) {
      const newDialogName = makeItemsName(dialogs, 'name', 'Dialog_name');
      dispatch(
        setDialog({
          name: newDialogName,
          nodes: [],
        })
      );
    }
  }, [brainId, dialogs, dispatch, isDraft]);

  // Update selection
  useEffect(() => {
    if (dialog?.dialog_id && dialog_id) {
      dispatch(setDialogAsync());
    }
  }, [
    dispatch,
    dialog_id,
    selectedNodeId,
    selectedActionId,
    selectedRequisiteIndex,
    dialog?.dialog_id,
  ]);

  return (
    <>
      {!canWrite && (
        <Banner variant="neutral" relativePosition centered padding="small">
          {t('permissions.read_only')}
        </Banner>
      )}
      <div
        className={cn(styles.wrapper, {
          [styles.readOnly]: !canWrite,
        })}
      >
        <SubNavDialog />
        <SubHeaderDialog />
        <DialogEditor />
        <div className={styles.toolkitContainer}>
          <Toolkit />
        </div>
      </div>
    </>
  );
});

Dialog.displayName = 'Dialog';

export default Dialog;
