export const Welcome = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="520"
      height="594"
      fill="none"
      viewBox="0 0 520 594"
    >
      <path
        fill="#EFF3FE"
        d="M90.878 345.159c-20.09 10.305-40.589 21.793-54.7 41.089-4.707 6.436-8.757 14.252-8.348 22.62.763 15.604 16.455 24.898 30.157 24.927 13.7.028 27.03-5.829 40.73-5.786 7.072.021 15.922 3.925 15.829 11.996-.055 4.824-3.454 8.622-6.605 11.834-24.184 24.65-48.367 49.301-72.556 73.951-8.057 8.213-16.368 16.821-20.561 28.181-4.193 11.359-2.983 26.327 5.694 33.683 5.712 4.845 13.298 5.482 20.418 5.623 32.333.658 64.499-5.015 96.299-11.699 112.444-23.625 222.797-60.123 328.883-108.753 19.426-8.905 40.279-19.847 49.527-41.266 10.68-24.756-.714-58.588-23.185-68.816-13.428-6.119-28.525-4.371-42.741-7.371-14.216-2.999-29.461-14.111-29.61-30.592-.236-27.267 38.455-37.616 39.565-64.855.589-14.423-10.904-26.61-23.265-29.744-12.362-3.133-25.201.644-37.283 4.973 0-.014-219.132 64.297-308.248 110.005Z"
      />
      <path
        stroke="#F9B74B"
        strokeWidth="2"
        d="m384.736 191.423 1.198-.354v.085l1.338-.48c12.092-4.332 24.665-7.995 36.699-4.944 11.992 3.039 23.079 14.868 22.512 28.733-.531 13.048-9.98 22.127-19.76 31.525l-.221.212c-9.715 9.336-19.707 19.033-19.585 33.168.155 17.163 15.975 28.518 30.404 31.562 5.41 1.141 10.965 1.605 16.437 2.062 1.791.15 3.574.299 5.339.47 7.205.701 14.199 1.782 20.757 4.77 10.92 4.97 19.232 15.725 23.491 28.369 4.257 12.638 4.417 27.026-.81 39.141-9.079 21.027-29.577 31.838-49.025 40.753-106.02 48.599-216.302 85.074-328.672 108.683l.205.979-.205-.979c-31.79 6.682-63.86 12.334-96.073 11.679-7.13-.142-14.377-.794-19.792-5.387-4.098-3.474-6.49-8.799-7.33-14.718-.839-5.914-.113-12.328 1.928-17.856 4.107-11.129 12.26-19.594 20.336-27.826 24.164-24.625 48.322-49.25 72.48-73.875l.076-.077c3.117-3.177 6.831-7.244 6.892-12.522.051-4.466-2.393-7.747-5.686-9.869-3.271-2.108-7.445-3.128-11.14-3.139-6.966-.022-13.82 1.454-20.55 2.904l-.029.006c-6.776 1.459-13.428 2.89-20.153 2.876-6.642-.014-13.78-2.279-19.334-6.424-5.54-4.136-9.461-10.104-9.825-17.552l-1 .049 1-.049c-.393-8.022 3.494-15.605 8.156-21.981l-.807-.59.808.59c13.956-19.085 34.255-30.482 54.348-40.79 44.479-22.813 121.47-50.296 187.4-72.088 32.953-10.892 63.122-20.356 85.053-27.095a3851.595 3851.595 0 0 1 26.035-7.905c3.082-.923 5.479-1.635 7.105-2.116Z"
      />
      <path
        fill="#1B66D6"
        d="M445.647 405.296h-85.905c-6.302-5.457-6.736-17.751-.204-23.239 5.034-4.23 12.645-3.099 17.377 1.302a19.703 19.703 0 0 1-.054-10.043c1.539-5.916 4.959-11.931 10.181-14.324 13.327-6.101 22.797 6.682 24.694 13.829 3.412-5.444 11.045-8.321 15.836-4.656 6.069 4.64 5.794 11.958.953 17.21 6.45-1.304 11.308-.298 15.782 4.912 3.41 3.966 3.931 10.725 1.34 15.009Z"
        opacity=".1"
      />
      <g filter="url(#a)">
        <path
          fill="#EFF3FE"
          d="M254.299 71.957H138.314c-9.595 0-17.373 7.732-17.373 17.269v271.025c0 9.537 7.778 17.269 17.373 17.269h115.985c9.594 0 17.373-7.732 17.373-17.269V89.226c0-9.537-7.779-17.269-17.373-17.269Z"
        />
        <path
          fill="#fff"
          fillOpacity=".8"
          d="M254.242 77.35H138.367c-6.588 0-11.929 5.308-11.929 11.858v270.657c0 6.549 5.341 11.858 11.929 11.858h115.875c6.589 0 11.93-5.309 11.93-11.858V89.208c0-6.55-5.341-11.859-11.93-11.859Z"
        />
        <path
          fill="#EFF3FE"
          d="M167.684 77.35h57.236v4.815c0 .684-.165 1.361-.487 1.993a5.283 5.283 0 0 1-1.389 1.69c-.594.483-1.3.867-2.077 1.129a7.701 7.701 0 0 1-2.451.396h-44.428c-1.699 0-3.328-.549-4.529-1.525-1.201-.977-1.875-2.302-1.875-3.683V77.35Z"
        />
      </g>
      <path
        fill="#DA977D"
        d="M287.955 391.025a19517.06 19517.06 0 0 1-12.475-2.308l1.891-12.008s11.431 2.119 12.444 2.303l-1.86 12.013Z"
      />
      <path
        fill="#9AABB6"
        d="M268.943 402.543c-.049.324-.035.654.039.972.075.317.209.615.394.873.186.259.418.473.683.63.265.157.557.253.858.282.417.04.836-.051 1.207-.26.371-.21.68-.53.889-.923l6.476-13.2a2.152 2.152 0 0 0 .085-1.673 11.94 11.94 0 0 1-.781-6.887c.401-1.8 1.26-3.442 2.479-4.737a.913.913 0 0 0 .249-.422.974.974 0 0 0 .002-.5.909.909 0 0 0-.245-.424.8.8 0 0 0-.416-.215l-6.303-1.146a1.323 1.323 0 0 0-.56.019 1.384 1.384 0 0 0-.511.25c-.153.119-.283.27-.382.444-.1.174-.167.367-.197.569l-3.966 26.348Z"
      />
      <path
        fill="#0A3451"
        d="M323.436 358.548c9.524.425 20.119.645 29.72.827 3.003.062 5.872 1.357 8.03 3.623 2.158 2.267 3.443 5.336 3.598 8.59.154 3.254-.834 6.451-2.766 8.945-1.932 2.495-4.663 4.103-7.644 4.499l-71.86 9.467-2.928-23.041 71.539-11.981 1.942 25.672c-9.643.68-19.971 1.392-29.632 1.823l.001-28.424Z"
      />
      <path
        fill="#DA977D"
        d="M341.562 385.98c-9.92-1.566-10.908-1.853-11.234-1.948l2.914-11.764c-.033-.009-.075-.022-.127-.034.813.192 5.856 1.027 10.062 1.691l-1.615 12.055Z"
      />
      <path
        fill="#9AABB6"
        d="M353.952 396.451a2.566 2.566 0 0 1-.103 1.9 2.357 2.357 0 0 1-.56.759 2.137 2.137 0 0 1-.793.453c-.403.126-.83.123-1.231-.006a2.185 2.185 0 0 1-1.034-.723l-8.668-11.626a2.099 2.099 0 0 1-.35-.768 2.17 2.17 0 0 1-.024-.857c.54-2.298.387-4.725-.438-6.922-.708-1.684-1.838-3.119-3.262-4.141a.868.868 0 0 1-.318-.362.97.97 0 0 1-.09-.491.945.945 0 0 1 .167-.467.832.832 0 0 1 .371-.297l5.988-2.422a1.327 1.327 0 0 1 1.098.044c.171.085.325.206.453.356s.228.327.293.518l8.501 25.052Z"
      />
      <path
        fill="#0A3451"
        d="M307.479 384.883c-11.965-1.033-24.106-1.443-36.104-1.677l1.042-23.44c22.433 3.306 45.172 6.439 67.722 8.705l-2.485 23.105c-22.427-3.43-45.217-6.06-67.75-8.447-13.111-1.276-12.668-22.669.374-23.399 12.206-.754 24.542-1.691 36.685-3.265l.516 28.418Z"
      />
      <path
        fill="#0A3451"
        d="m334.361 354.99 1.583 28.833-45.71-.583 3.451-25.526 40.676-2.724Z"
      />
      <path
        fill="#DA977D"
        d="M321.429 299.872s-4.3-2.004-4.3-14.26l-8.643-.955s1.782 10.829-2.263 14.676l15.206.539Z"
      />
      <path
        fill="#000"
        d="m307.797 296.897 6.63-6.497-5.561.432a17.928 17.928 0 0 1-1.069 6.065Z"
        opacity=".15"
      />
      <path
        fill="#DA977D"
        d="M321.606 280.462c-.31 3.746-1.904 7.409-4.817 8.869-3.166 1.7-6.668 2.534-10.201 2.429-9.382 0-9.276-23.155-5.189-28.014 4.087-4.859 17.287-3.187 19.432 4.142 1.108 3.791 1.07 9.009.775 12.574Z"
      />
      <path
        fill="#0F1011"
        d="M307.993 278.806c.428 0 .775-.377.775-.841 0-.464-.347-.84-.775-.84-.427 0-.774.376-.774.84 0 .464.347.841.774.841ZM301.865 278.806c.427 0 .774-.377.774-.841 0-.464-.347-.84-.774-.84-.428 0-.775.376-.775.84 0 .464.347.841.775.841ZM300.36 276.3a.347.347 0 0 1-.251-.109.421.421 0 0 1-.024-.545.354.354 0 0 1 .241-.134l2.044-.209a.339.339 0 0 1 .266.089.418.418 0 0 1 .048.555.355.355 0 0 1-.247.142l-2.043.21-.034.001ZM309.516 276.3a.275.275 0 0 1-.057-.005l-2.056-.348a.347.347 0 0 1-.247-.153.423.423 0 0 1-.019-.447.396.396 0 0 1 .1-.115.347.347 0 0 1 .278-.065l2.056.348a.358.358 0 0 1 .23.145.423.423 0 0 1 .078.275.407.407 0 0 1-.115.259.351.351 0 0 1-.246.107l-.002-.001Z"
      />
      <path
        fill="#fff"
        d="M308.138 284.594s-2.825.58-4.88 0c0 0 .169 2.036 2.44 2.036s2.44-2.036 2.44-2.036Z"
      />
      <path
        fill="#0F1011"
        d="M314.608 271.45c.461 16.923 2.725 18.473 2.725 18.473s7.378-5.754 7.626-15.963c.233-9.572-6.634-14.599-11.068-15.268-16.847-2.542-17.438 13.961-17.438 13.961 8.455 1.573 19.971-1.025 19.971-1.025"
      />
      <path
        fill="#DA977D"
        d="M312.164 279.658c.011.937.362 1.832.977 2.49a3.153 3.153 0 0 0 2.307 1.028c.864 0 1.693-.369 2.308-1.028a3.715 3.715 0 0 0 .977-2.49 3.786 3.786 0 0 0-.235-1.373 3.535 3.535 0 0 0-.711-1.164 3.205 3.205 0 0 0-1.073-.771 3.011 3.011 0 0 0-1.265-.255c-2.34.124-3.285 1.595-3.285 3.563Z"
      />
      <path
        fill="#0F1011"
        d="M322.672 273.96c4.136 0 7.489-3.637 7.489-8.124 0-4.488-3.353-8.125-7.489-8.125-4.136 0-7.488 3.637-7.488 8.125 0 4.487 3.352 8.124 7.488 8.124Z"
      />
      <path
        fill="#000"
        d="M304.9 282.917a.225.225 0 0 1-.058-.007l-1.906-.453a.258.258 0 0 1-.102-.051.292.292 0 0 1-.106-.208.292.292 0 0 1 .014-.121l.999-3.062a.282.282 0 0 1 .137-.158.251.251 0 0 1 .201-.009.278.278 0 0 1 .15.144.312.312 0 0 1 .015.217l-.897 2.752 1.61.383a.268.268 0 0 1 .159.114c.038.058.056.13.049.201a.303.303 0 0 1-.087.184.253.253 0 0 1-.178.074Z"
        opacity=".15"
      />
      <path
        fill="#0A3451"
        d="M278.351 351.166a5.263 5.263 0 0 1-2.61-.702 5.788 5.788 0 0 1-2.01-1.937 6.413 6.413 0 0 1-.949-2.728 6.575 6.575 0 0 1 .329-2.895l11.055-31.453 7.797 3.225 4.602-6.801 9.048 7.21-22.734 33.594a5.763 5.763 0 0 1-1.991 1.83 5.262 5.262 0 0 1-2.537.657Z"
      />
      <path
        fill="#0A3451"
        d="m346.063 344.63-8.437-36.341c-.43-2.343-1.523-4.483-3.127-6.119-2.444-2.651-6.662-4.362-11.806-4.368-.385 3.254-18.596 3.266-18.735.075a52.2 52.2 0 0 0-1.572.041c-17.74.829-9.945 17.512-9.773 33.604.208 18.829-4.481 22.379-2.171 28.133 2.24 5.587 46.26 7.907 46.848-1.92.138-2.311-.469-4.514-1.289-6.988l1.568.949a5.456 5.456 0 0 0 3.264.787 5.565 5.565 0 0 0 3.126-1.289 6.33 6.33 0 0 0 1.958-2.94 6.83 6.83 0 0 0 .146-3.624Z"
      />
      <g fill="#FAFAFA" opacity=".6">
        <path d="M278.351 351.166a5.263 5.263 0 0 1-2.61-.702 5.788 5.788 0 0 1-2.01-1.937 6.413 6.413 0 0 1-.949-2.728 6.575 6.575 0 0 1 .329-2.895l11.055-31.453 7.797 3.225 4.602-6.801 9.048 7.21-22.734 33.594a5.763 5.763 0 0 1-1.991 1.83 5.262 5.262 0 0 1-2.537.657Z" />
        <path d="m346.063 344.63-8.437-36.341c-.43-2.343-1.523-4.483-3.127-6.119-2.444-2.651-6.662-4.362-11.806-4.368-.385 3.254-18.596 3.266-18.735.075a52.2 52.2 0 0 0-1.572.041c-17.74.829-9.945 17.512-9.773 33.604.208 18.829-4.481 22.379-2.171 28.133 2.24 5.587 46.26 7.907 46.848-1.92.138-2.311-.469-4.514-1.289-6.988l1.568.949a5.456 5.456 0 0 0 3.264.787 5.565 5.565 0 0 0 3.126-1.289 6.33 6.33 0 0 0 1.958-2.94 6.83 6.83 0 0 0 .146-3.624Z" />
      </g>
      <path
        fill="#DA977D"
        d="M303.957 297.877c.139 3.191 1.738 7.883 6.673 8.237 6.576.47 11.677-5.058 12.062-8.312h-.016c-6.475 0-14.943.011-18.719.075ZM297.457 308.746c.278.406.043.986-1.459 2.703-.936 1.074-2.08 2.564-2.925 3.69l-7.651-3.167c.545-2.448 1.417-5.824 2.23-6.59 1.305-1.23 6.133 1.137 6.133 1.137s.524-1.74 1.208-3.539c.786-2.071 1.785-4.223 2.438-3.869.631.342.165 2.483-.556 4.78-.925 2.958-2.278 6.172-2.278 6.172s2.176-2.311 2.86-1.317Z"
      />
      <path
        fill="#E7EBEE"
        d="m298.242 326.985 6.48-1.122a1.75 1.75 0 0 0 .714-.299c.216-.154.4-.354.539-.59a2.138 2.138 0 0 0 .224-1.605l-4.078-15.878a1.987 1.987 0 0 0-.775-1.14 1.715 1.715 0 0 0-1.278-.29l-6.482 1.122a1.745 1.745 0 0 0-.713.299c-.216.153-.4.354-.539.59-.14.235-.232.5-.27.776-.039.277-.023.559.045.829l4.078 15.878c.121.47.397.876.776 1.14.379.264.834.367 1.279.29Z"
      />
      <path
        fill="#DA977D"
        d="m308.677 322.823-3.822 5.627s-10.274-2.235-11.238-10.822c-.111-.989-.015-3.724 1.114-3.357 2.941.956 6.6 5.852 9.166 6.503-.588-1.138-1.299-2.744-.839-3.038 1.212-.772 4.47 3.889 5.619 5.087Z"
      />
      <path
        fill="#E8F0FB"
        d="M214.302 324.628a2 2 0 0 1-2 2h-72.335c-.498 0-.979.186-1.347.522l-4.508 4.105c-1.284 1.17-3.346.259-3.346-1.478v-51.976a2 2 0 0 1 2-2h79.536a2 2 0 0 1 2 2v46.827Z"
      />
      <path
        fill="#8FCCFF"
        d="M259.415 236.194c0 1.69-1.966 2.618-3.271 1.545l-4.102-3.373a1.995 1.995 0 0 0-1.27-.456h-70.995a2 2 0 0 1-2-2v-48.716a2 2 0 0 1 2-2h77.638a2 2 0 0 1 2 2v53Z"
      />
      <path
        fill="#E8F0FB"
        d="M130.766 153.394c0 1.604 1.793 2.555 3.121 1.656l7.174-4.858a2.002 2.002 0 0 1 1.121-.344h68.377a2 2 0 0 0 2-2v-42.896a2 2 0 0 0-2-2h-77.793a2 2 0 0 0-2 2v48.442Z"
      />
      <path
        fill="#1B66D6"
        d="M133.652 192.183H20.109a4 4 0 0 0-4 4v66.132a4 4 0 0 0 4 4h113.543a4 4 0 0 0 4-4v-66.132a4 4 0 0 0-4-4Z"
        opacity=".3"
      />
      <path
        fill="#fff"
        d="M133.652 192.183H20.109a4 4 0 0 0-4 4v66.132a4 4 0 0 0 4 4h113.543a4 4 0 0 0 4-4v-66.132a4 4 0 0 0-4-4Z"
      />
      <path
        fill="#fff"
        d="M124.942 223.955H28.817a2.942 2.942 0 1 0 0 5.883h96.125a2.942 2.942 0 1 0 0-5.883ZM125.531 238.075H28.228a2.353 2.353 0 1 0 0 4.707h97.303a2.354 2.354 0 0 0 0-4.707ZM124.942 251.018H28.817a2.942 2.942 0 0 0 0 5.884h96.125a2.942 2.942 0 0 0 0-5.884Z"
      />
      <path
        fill="#1B66D6"
        d="M29.875 216.074h53.203a4 4 0 0 0 4-4v-6.478a4 4 0 0 0-4-4H29.875a4 4 0 0 0-4 4v6.478a4 4 0 0 0 4 4Z"
        opacity=".2"
      />
      <path
        fill="#1B66D6"
        d="m35.817 213.265-2.655-1.514-2.658 1.514.509-3.206-2.15-2.271 2.97-.467 1.329-2.917 1.326 2.917 2.97.467-2.15 2.271.51 3.206ZM47.474 213.265l-2.656-1.514-2.657 1.514.508-3.206-2.15-2.271 2.97-.467 1.33-2.917 1.325 2.917 2.97.467-2.149 2.271.509 3.206ZM59.134 213.265l-2.656-1.514-2.657 1.514.508-3.206-2.15-2.271 2.97-.467 1.33-2.917 1.325 2.917 2.97.467-2.149 2.271.509 3.206ZM70.786 213.265l-2.655-1.514-2.658 1.514.509-3.206-2.15-2.271 2.97-.467 1.329-2.917 1.326 2.917 2.97.467-2.15 2.271.51 3.206ZM82.446 213.265l-2.655-1.514-2.658 1.514.509-3.206-2.15-2.271 2.97-.467 1.329-2.917 1.326 2.917 2.97.467-2.15 2.271.51 3.206Z"
      />
      <path
        fill="#1B66D6"
        d="M133.597 192.183H20.055a4 4 0 0 0-4 4v66.132a4 4 0 0 0 4 4h113.543a4 4 0 0 0 4-4v-66.132a4 4 0 0 0-4.001-4Z"
        opacity=".1"
      />
      <path
        fill="#fff"
        d="M124.888 223.955H28.762a2.942 2.942 0 1 0 0 5.883h96.126a2.942 2.942 0 0 0 0-5.883ZM125.476 238.075H28.174a2.353 2.353 0 1 0 0 4.707h97.302a2.353 2.353 0 0 0 0-4.707ZM124.888 251.018H28.762a2.942 2.942 0 1 0 0 5.884h96.126a2.942 2.942 0 0 0 0-5.884ZM91.775 61.963c17.143 0 31.04-13.788 31.04-30.797S108.918.37 91.775.37s-31.04 13.788-31.04 30.797 13.897 30.797 31.04 30.797Z"
      />
      <path
        fill="#8FCCFF"
        d="M91.775 61.963c17.143 0 31.04-13.788 31.04-30.797S108.918.37 91.775.37s-31.04 13.788-31.04 30.797 13.897 30.797 31.04 30.797Z"
      />
      <path
        fill="#8FCCFF"
        d="M91.777 51.056c11.072 0 20.047-8.905 20.047-19.89 0-10.985-8.975-19.89-20.047-19.89-11.071 0-20.046 8.905-20.046 19.89 0 10.985 8.975 19.89 20.046 19.89Z"
      />
      <path
        fill="#fff"
        d="M102.574 23.326c-2.851-2.948-7.572-2.285-10.544.543l-.187.186-.202-.2c-2.91-2.892-7.632-3.656-10.547-.769a7.37 7.37 0 0 0-2.188 5.231 7.37 7.37 0 0 0 2.18 5.234l10.75 10.475a.045.045 0 0 0 .063 0l10.675-10.456a7.369 7.369 0 0 0 2.076-5.122 7.37 7.37 0 0 0-2.076-5.122Z"
      />
      <path
        fill="#FFC930"
        d="M280.121 93.387c15.461-8.915 35.271-3.684 44.238 11.684 8.968 15.369 3.706 35.058-11.755 43.975-15.46 8.914-35.267 3.681-44.238-11.685-8.968-15.368-3.703-35.057 11.755-43.974Z"
      />
      <path
        fill="#fff"
        d="M313.47 116.302c.276 1.209-.297 2.436-1.356 3.048-.034.018-.016.052-.016.052a2.678 2.678 0 0 1 1.36 1.735c.273 1.209-.3 2.436-1.36 3.048-.03.018-.012.048-.012.048.845.497 1.433 1.352 1.443 2.329 0 1.325-.928 2.457-2.209 2.768l-7.329 1.793a33.198 33.198 0 0 1-7.951.953 2.238 2.238 0 0 1-2.165-1.653l-3.608-14.574a2.088 2.088 0 0 1 .233-1.586c1.381-2.421 5.577-9.877 6.34-10.702 4.055-3.066 3.393 6.327 3.369 8.348.018.031.018.031.052.012.67-.173 8.579-2.085 8.579-2.085 2.649-.758 4.817 2.518 2.793 4.497-.031.018-.012.048.037.064.882.258 1.611.992 1.8 1.905ZM287.567 115.27l4.383 17.671a1.647 1.647 0 0 1-1.176 1.96l-6.196 1.526a1.66 1.66 0 0 1-1.972-1.169l-4.402-17.704c-.205-.865.325-1.723 1.176-1.961l6.248-1.51c.87-.201 1.82.32 1.939 1.187Z"
      />
      <path
        fill="#A1BFEC"
        d="M452.072 404.85h-26.236c-4.56-5.927-12.556-17.883-7.388-22.989 2.351-2.318 4.935-.113 7.337 3.838-.825-7.747 0-15.147 5.456-17.309 4.964-1.963 7.187 3.97 8.045 11.93 2.446-4.528 5.662-7.713 9.855-7.463 6.004.355 4.555 8.266 1.042 17.024 2.665-2 5.451-2.985 8.165-2.108 5.689 1.844.379 9.723-6.276 17.077Z"
      />
      <path
        fill="#F9F9F9"
        d="M448.066 404.849h-17.49c-3.04-3.997-8.371-12.06-4.926-15.504 1.567-1.563 3.29-.076 4.892 2.589-.55-5.225 0-10.215 3.637-11.674 3.309-1.324 4.791 2.678 5.363 8.046 1.631-3.054 3.775-5.202 6.57-5.033 4.003.239 3.037 5.574.695 11.481 1.777-1.349 3.634-2.013 5.443-1.421 3.793 1.243.253 6.557-4.184 11.516Z"
        opacity=".3"
      />
      <defs>
        <filter
          id="a"
          width="169.73"
          height="327.562"
          x="111.941"
          y="61.957"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="2" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.8375 0 0 0 0 0.8375 0 0 0 0 0.8375 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_16444"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-1" dy="-2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0.839216 0 0 0 0 0.839216 0 0 0 0.25 0" />
          <feBlend
            in2="effect1_dropShadow_8_16444"
            result="effect2_dropShadow_8_16444"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_8_16444"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
