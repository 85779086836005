import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import IntegrationsHeader from '@/components/organisms/Headers/IntegrationsHeader';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { useAccount } from '@/hooks/useAccount';
import useDesks from '@/hooks/useDesks';
import { getDocsUrl } from '@/util/constants';

import CreateDeskTile from '../Desks/CreateDeskTile';

const IntegrationsEmpty = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { desks, listStatus } = useDesks();

  const { slug } = useAccount();
  const { slug: urlSlug } = useParams();

  useEffect(() => {
    if (slug === urlSlug && listStatus === 'success' && desks.length > 0) {
      navigate(`/${urlSlug}/environments/${desks[0].desk_id}/integrations`, {
        replace: true,
      });
    }
  }, [desks, listStatus, slug, urlSlug, navigate]);

  return (
    <Layout>
      <PermissionsWrapper module="integrations">
        <IntegrationsHeader />
        <PageContentWrapper newPlain>
          <ContextualHelp
            title={t('integrations.empty.title')}
            name="integrations-help"
            links={[
              {
                label: t('integrations.empty.link'),
                url: getDocsUrl('/docs/integrations/overview'),
              },
            ]}
            size="full"
          >
            {t('integrations.empty.help')}
          </ContextualHelp>
          <CreateDeskTile />
        </PageContentWrapper>
      </PermissionsWrapper>
    </Layout>
  );
};

export default IntegrationsEmpty;
