import { Icon } from '@/models/icon';

export const SendMessageIcon = ({
  size = 34,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 34 34"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#send-a)"
      >
        <path d="m31.172 17.123-15.556-.135m15.556.135L12 26.15l3.616-9.161-3.455-9.223 19.01 9.358Z" />
      </g>
      <defs>
        <clipPath id="send-a">
          <path fill="#fff" d="M17.178.03 34 17.148 16.882 33.97.06 16.852z" />
        </clipPath>
      </defs>
    </svg>
  );
};
