import { TypographyOptions } from '@mui/material/styles/createTypography';

const BASE_STYLES = {
  fontSize: 'var(--space-14)',
  letterSpacing: '0',
  lineHeight: '1.4',
};

const typography = {
  'body-regular': {
    ...BASE_STYLES,
    fontWeight: '400',
  },
  'body-medium': {
    ...BASE_STYLES,
    fontWeight: '500',
  },
  'body-semi-bold': {
    ...BASE_STYLES,
    fontWeight: '600',
  },
  'body-bold': {
    ...BASE_STYLES,
    fontWeight: '700',
  },
} as TypographyOptions;

export { typography };
