export type Size = 'xxs' | 'xs' | 'small' | 'medium' | 'large' | 'xlarge';

const tags = {
  primary: {
    '&.MuiChip-deletable': {
      backgroundColor: 'var(--cta-default-blue)',
      color: 'var(--text-default-white)',
      '&:hover': {
        backgroundColor: 'var(--cta-hover-blue)',
        color: 'var(--text-default-white)',
        '& .MuiChip-deleteIcon': {
          borderRadius: '4px',
          color: 'var(--text-default-gray)',
          '&:hover': {
            color: 'var(--cta-hover-gray)',
          },
        },
      },
    },
  },
  default: {
    '&.MuiChip-deletable': {
      borderRadius: 'var(--space-20)',
      backgroundColor: 'var(--surface-secondary-gray)',
    },
  },
  secondary: {
    '&.MuiChip-deletable': {
      backgroundColor: 'var(--surface-tertiary-blue-light)',
      '&:hover': {
        backgroundColor: 'var(--surface-tertiary-blue-light)',
      },
      '& .MuiChip-deleteIcon': {
        color: 'var(--text-default-blue)',
        borderRadius: '4px',
        '&:hover': {
          color: 'var(--text-hover-blue)',
        },
      },
    },
  },
};

export const getSize = (size: Size) => {
  switch (size) {
    case 'xxs':
      return 30;
    case 'xs':
      return 32;
    case 'small':
      return 36;
    case 'medium':
      return 40;
    case 'xlarge':
      return 44;
    default:
      return 40;
  }
};

export const getStyles = (
  size: Size,
  variant: 'primary' | 'secondary' | 'default',
  multiple: boolean
) => ({
  '& .MuiAutocomplete-inputRoot': {
    minHeight: `${getSize(size)}px`,
    height: 'auto',
  },

  inputRoot: {
    '&.MuiOutlinedInput-root.MuiAutocomplete-hasClearIcon': {
      paddingRight: multiple ? '65px' : '40px',
    },
  },
  input: {
    padding: '6px 8px',
    height: '100%',
    flex: '1 1',
  },
  tag: tags[variant],
});
