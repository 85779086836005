import { useTranslation } from 'react-i18next';

import Event from '@/components/atoms/Icons/Event';
import Intent from '@/components/atoms/Icons/Intent';

import { TooltipButton } from './TooltipButton';

export const ConvertButton = ({ type, onTriggerConvert }) => {
  const { t } = useTranslation();
  const inner =
    type === 'event' ? (
      <Intent color="var(--icon-default-white)" />
    ) : (
      <Event color="var(--icon-default-white)" />
    );
  const title =
    type === 'event' ? t('dialog.convert_event') : t('dialog.convert_text');

  return (
    <TooltipButton title={title} onClick={onTriggerConvert}>
      {inner}
    </TooltipButton>
  );
};
