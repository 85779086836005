import { useEffect } from 'react';

import { shallowEqual, useSelector } from 'react-redux';

import { User } from '@/models/user';
import { selectUser } from '@/redux/user/selectors';

export const useBeamer = () => {
  const user = useSelector(selectUser, shallowEqual) as User | null;

  const isBeamerConfigSet = window.beamer_config !== undefined;

  useEffect(() => {
    if (!user || isBeamerConfigSet) {
      return;
    }

    window.beamer_config = {
      product_id: 'ohnQggJb49523',
      selector: '.beamer',
      //---------------Visitor Information---------------
      user_firstname: user.name,
      user_email: user.email,
      user_id: user.user_id,
    };
    const script = document.createElement('script');
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script.async = true;
    script.id = 'moveo-changelog';
    document.body.appendChild(script);
  }, [user, isBeamerConfigSet]);
};
