import { memo, useCallback, useState } from 'react';

import dotize from 'dotize';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import FileUpload from '@/components/atoms/FileUpload/FileUpload';
import Input from '@/components/atoms/Input/Input';
import { useAccount } from '@/hooks/useAccount';
import { UploadedFile } from '@/models/server';
import { popModal } from '@/redux/modals/actions';
import { selectDeskId } from '@/redux/session/selectors';
import { errorMessage, ruleRules } from '@/util/validator';

import Modal from '../../../components/organisms/Modals/Modal';
import { setDraftBundle } from '../redux/actions';

function ModalBundleCreation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [importedBundle, setImportedBundle] = useState({});
  const deskId = useSelector(selectDeskId);
  const { slug } = useAccount();
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
    },
  });
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (bundle) => {
      dispatch(
        setDraftBundle({
          ...bundle,
          context: importedBundle,
          dirty: Object.keys(importedBundle).length > 0 ? true : false,
        })
      );

      navigate(`/${slug}/environments/${deskId}/context_bundles/draft`);
      dispatch(popModal());
    },
    [deskId, dispatch, importedBundle, navigate, slug]
  );

  const onJsonFileUpload = useCallback(async ({ data }: UploadedFile) => {
    setImportedBundle(dotize.convert(data));
  }, []);

  const disabled = !isDirty || !isValid;

  return (
    <Modal
      title={t('bundles.create_a_bundle')}
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('common.create')}
      primaryButtonDisable={disabled}
      autoFocus
    >
      <form autoComplete="off">
        <div className="input__container">
          <Input
            name="name"
            label={t('common.name')}
            register={register('name', ruleRules.name)}
            errorMessage={errorMessage({
              field: errors.name,
              maxLength: ruleRules.name.maxLength,
            })}
            placeholder={t('common.name_placeholder')}
            autoFocus
          />
        </div>
        <div className="input__container">
          <Input
            name="description"
            label={t('common.description')}
            register={register('description', ruleRules.description)}
            errorMessage={errorMessage({
              field: errors.description,
              maxLength: ruleRules.description.maxLength,
            })}
            placeholder={t('common.description_placeholder')}
          />
        </div>
        <FileUpload
          message={t('bundles.drop_exported_brain')}
          onFileUploaded={onJsonFileUpload}
          parseJson
          accept={{ 'application/json': ['.json'] }}
          onFileRemoved={() => setImportedBundle({})}
        />
      </form>
    </Modal>
  );
}

export default memo(ModalBundleCreation);
