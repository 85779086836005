import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { Presence } from '@/models/presence';

import { StyledBadge } from './components';

type AgentIconProps = {
  agent: Presence;
};

export const AgentIcon = ({ agent }: AgentIconProps) => {
  return (
    <ListItemAvatar sx={{ minWidth: 'var(--space-16)' }}>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        status={agent.status}
      >
        <Avatar
          alt={`Avatar ${agent?.name || ''}`}
          src={agent.avatar}
          sx={{ width: 16, height: 16 }}
        />
      </StyledBadge>
    </ListItemAvatar>
  );
};
