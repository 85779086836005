import { Icon } from '@/models/icon';

const Tick = ({
  color = 'var(--icon-default-white)',
  backgroundColor = 'var(--icon-default-blue)',
  size = 24,
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        backgroundColor,
        borderRadius: '50%',
      }}
    >
      <path
        d="M4.66663 8.00033L6.99996 10.3337L11.6666 5.66699"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Tick;
