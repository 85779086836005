import capitalize from 'lodash/capitalize';

import { Member, RoleType } from '@/models/member';
import { Role } from '@/models/role';

export const getRoleName = (member: Member, roles: Role[]) => {
  if (!member || !roles) {
    return null;
  }
  if (member.role !== RoleType.RBAC) {
    return capitalize(member.role);
  }

  const roleNames = member.role_ids?.map((role_id: string) => {
    const name = roles?.find((role) => role.role_id === role_id)?.name;
    return name;
  });
  return roleNames?.join(', ') || '-';
};
