import { ClipboardEvent } from 'react';

import { TFunction } from 'i18next';
import { UseFormSetError } from 'react-hook-form';

import { callGet } from '@/api/fetcher';
import { DatasourceType } from '@/models/collections';
import { DatasourceConfig, IntercomConfig } from '@/models/datasource';
import { Integration } from '@/models/integration';
import { preventClickThrough } from '@/util/util';

interface FormattedUrlProps {
  error: boolean;
  urls: string[];
  externalKb: {
    type: 'zendesk_kb' | 'intercom_kb';
  } | null;
}

export type Form = {
  name: string;
  integration?: Integration;
  website?: {
    seed_urls?: {
      name: string;
    }[];

    exclude_urls?: {
      name: string;
    }[];
    sitemap_urls?: string;
  };
  intercom?: IntercomConfig;
  zendesk: {
    subdomain: string;
    locale: string;
    email: string;
    access_token: string;
  };
};

const Errors = {
  invalid_url: 'invalid_url',
  unknown_url: 'unknown_url',
  internal_error: 'internal_error',
  max_redirects: 'max_redirects',
  cf_challenge: 'cf_challenge',
  not_found: 'not_found',
} as const;

type Result = {
  datasource?: { type: DatasourceType; config: Partial<DatasourceConfig> };
  error?: string;
  error_code?: keyof typeof Errors;
};

export const validateDatasourceUrl = async (
  urls: string[],
  setError: UseFormSetError<Form>,
  key: 'seed_urls' | 'exclude_urls',
  t: TFunction,
  collectionId: string,
  submitCount?: number
): Promise<FormattedUrlProps> => {
  let error = false;
  let externalKb = null;
  const newUrls = await Promise.all(
    urls.map(async (url, index: number) => {
      // If the url is empty, we don't need to validate it
      if (!url) return null;

      try {
        // Request to validate the url
        const result: Result = await callGet(
          `/www/api/v1/collections/${collectionId}/datasources/identify?url=${encodeURIComponent(
            url
          )}`
        );

        // Handle errors
        if (result?.error_code) {
          setError(`website.${key}.${index}.name`, {
            message: t(`errors.${result.error_code}`),
          });
          error = true;
          return url;
        }

        // If the datasource is a website, we need to check if it's a zendesk or intercom knowledge base
        if (
          result?.datasource?.type === 'intercom_kb' ||
          result?.datasource?.type === 'zendesk_kb'
        ) {
          if (submitCount === 1) {
            error = true;
            externalKb = {
              type: result.datasource.type,
              config: result.datasource?.config,
            };
            return '';
          }
        }

        if (
          result.datasource?.config &&
          'seed_urls' in result.datasource.config
        ) {
          return result.datasource.config.seed_urls?.[0] || url;
        }

        return url;
      } catch (err) {
        setError(`website.${key}.${index}.name`, {
          message: t('errors.invalidUrl'),
        });
        error = true;
        return url;
      }
    })
  );
  return { error, urls: newUrls, externalKb };
};

export const appendHttps = (data: string) => {
  if (!data) return '';

  return `https://${data}`;
};

export const formattedUrls = (values: { name: string }[]) => {
  return values.filter((u) => u.name).map((u) => appendHttps(u.name));
};

export const cleanHttpFromPastedUrl = (e: ClipboardEvent<HTMLInputElement>) => {
  preventClickThrough(e);
  const text = e.clipboardData.getData('text/plain');
  const cleanedLink = text.replace(/^https?:\/\//i, '');
  document.execCommand('insertText', false, cleanedLink.trim());
};
