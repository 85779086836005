import { useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Autocomplete from '@/components/atoms/AutocompleteNew/AutocompleteNew';
import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import useDialogs from '@/hooks/useDialogs';
import { TextAction } from '@/models/action';
import { Dialog } from '@/models/dialog';
import { popModal } from '@/redux/modals/actions';
import { modalConnectIntentRules } from '@/util/validator';

import Modal from '../Modal';

import styles from './ModalConnectIntent.module.scss';

const cleanString = (str: string) => {
  if (!str) {
    return '';
  }
  return str.replace(/[^a-zA-Z0-9 ]/g, ' ');
};
interface Props {
  intentName: string;
  brainId: string;
}

interface FormType {
  dialog_name: string;
  trigger: string;
  response: string;
  collection: {
    value: string;
    label: string;
  };
}

function ModalConnectIntent({ intentName, brainId }: Props) {
  const { t } = useTranslation();
  const { dialogs, createDialog, createStatus } = useDialogs(brainId);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: {
      dialog_name: cleanString(intentName) ?? '',
      trigger: intentName ?? '',
      response: '',
      collection: { value: '', label: '' },
    },
  });

  const onSubmit = () => {
    const { dialog_name, trigger, response, collection } = getValues();
    const node_id = uuidv4();
    const actions = response
      ? [
          {
            action_id: uuidv4(),
            type: 'text' as TextAction['type'],
            texts: [response],
            node_id,
          },
        ]
      : [];

    const dialog: Partial<Dialog> = {
      name: dialog_name,
      collection:
        typeof collection === 'string' ? collection : collection?.value,
      nodes: [
        {
          type: 'intent',
          intent: trigger,
          node_id,
          name: dialog_name,
          actions,
        },
      ],
    };
    createDialog(dialog, {
      onSuccess: () => {
        dispatch(popModal());
      },
    });
  };

  const registry = useMemo(
    () => ({
      dialog_name: {
        ...register('dialog_name', modalConnectIntentRules(t).dialog_name),
      },
      trigger: {
        ...register('trigger', modalConnectIntentRules(t).trigger),
      },
      response: {
        ...register('response', modalConnectIntentRules(t).response),
      },
    }),
    [register, t]
  );

  const collections = useMemo(
    () =>
      [
        ...new Set(
          dialogs
            ?.map((d) => d.collection)
            .filter((d) => !!d && d !== '_orphans')
        ),
      ].map((d) => ({
        value: d,
        label: d,
        type: t('subnav.folder'),
      })),
    [dialogs, t]
  );

  return (
    <Modal
      title={t('intent.create_dialog')}
      subtitle={t('intent.create_dialog_subtitle')}
      onPrimarySubmit={handleSubmit(onSubmit)}
      onSecondarySubmit={() => dispatch(popModal())}
      primaryButtonDisable={!isValid}
      isSubmitting={createStatus === 'pending'}
    >
      <form autoComplete="off">
        <Input
          label={t('intent.dialog_name')}
          name="name"
          errorMessage={errors?.dialog_name?.message}
          {...registry.dialog_name}
        />
        <Input
          label={t('intent.dialog_trigger')}
          name="intentName"
          readOnly
          {...registry.trigger}
        />

        <div className={styles.input}>
          <Autocomplete
            name="collection"
            control={control}
            options={collections}
            label={t('subnav.folder')}
            placeholder={t('subnav.folder_placeholder')}
            size="medium"
            freeSolo
            groupByProp="type"
            groupByLabelProp={false}
            rules={modalConnectIntentRules(t).collection}
          />
        </div>
        <TextAreaAsInput
          height="medium"
          label={t('intent.response_description')}
          name="response"
          placeholder={t('dialog.type_a_text_reply')}
          {...registry.response}
        />
      </form>
    </Modal>
  );
}

export default ModalConnectIntent;
