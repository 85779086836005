import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { callGet } from '@/api/fetcher';
import { ViberIntegration } from '@/models/integration';
import { selectAccountSlug } from '@/redux/session/selectors';

import { useIntegrations } from './useIntegrations';

interface ViberData {
  name: string;
  uri: string;
  icon: string;
  category: string;
  subcategory: string;
  country: string;
  subscribers_count: number;
  imagebase64?: string;
}

export const endpoint = (slug: string, deskId: string, integrationId: string) =>
  `/www/api/integrations/viber/${slug}/desks/${deskId}/integrations/${integrationId}/viber`;

export const useViber = (deskId: string, integrationId: string) => {
  const slug = useSelector(selectAccountSlug);

  const { integration } = useIntegrations<ViberIntegration>(
    deskId,
    integrationId
  );

  const { data, isLoading } = useQuery<ViberData>({
    queryKey: [
      endpoint(slug, deskId, integrationId),
      integration?.config?.access_token,
    ],
    queryFn: () => callGet(endpoint(slug, deskId, integrationId)),
    enabled: !!deskId && !!integrationId,
  });
  return {
    viberData: data,
    isLoading,
    error: !isLoading && !data?.uri,
  };
};
