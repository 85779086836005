/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
interface Props<T> {
  options: T[];
}

export const useCommandPallete = <T>({ options }: Props<T>) => {
  const [searchResults, setSearchResults] = useState<T[]>(options || []);
  const [searchString, setSearchString] = useState('');
  const [noResultSearchLength, setNoResultSearchLength] = useState(0);
  const [lastSearchStringLength, setLastSearchStringLength] = useState(0);

  return {
    searchResults,
    setSearchResults,
    searchString,
    setSearchString,
    noResultSearchLength,
    setNoResultSearchLength,
    lastSearchStringLength,
    setLastSearchStringLength,
  };
};
