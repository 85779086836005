import { memo } from 'react';

import { useWhatsappTesting } from '@/hooks/useWhatsappTesting';

import AddNumberSubModal from './AddNumberSubModal/AddNumberSubModal';
import LoadingSubModal from './LoadingSubModal/LoadingSubModal';
import ScanAndChatSubModal from './ScanAndChatSubModal/ScanAndChatSubModal';
import SelectNumberSubModal from './SelectNumberSubModal/SelectNumberSubModal';

type ModalWhatsAppTestProps = {
  brainId: string;
};

const ModalWhatsAppTest = ({ brainId }: ModalWhatsAppTestProps) => {
  const {
    modalState,
    selectedNumber,
    phoneNumbers,
    brainName,
    hasPermission,
    onNumberCreate,
    onNumberCreateError,
    onNumberSelect,
    onAddNumberClick,
    onBackClick,
  } = useWhatsappTesting(brainId);

  if (modalState === 'pending') {
    return <LoadingSubModal />;
  }

  if (modalState === 'selecting') {
    return (
      <SelectNumberSubModal
        hasPermission={hasPermission}
        phoneNumbers={phoneNumbers}
        onNumberSelect={onNumberSelect}
        onAddNumberClick={onAddNumberClick}
      />
    );
  }

  if (modalState === 'creating') {
    return (
      <AddNumberSubModal
        onCreate={onNumberCreate}
        onCreateError={onNumberCreateError}
      />
    );
  }

  return (
    <ScanAndChatSubModal
      hasPermission={hasPermission}
      selectedNumber={selectedNumber}
      onBackClick={onBackClick}
      brainName={brainName}
    />
  );
};

export default memo(ModalWhatsAppTest);
