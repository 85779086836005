import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ActiveFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/ActiveFieldset';
import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import { useAccordion } from '@/hooks/useAccordion';
import { AccordionType, WebWidgetIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import AdvancedSettings from './Accordion/AdvancedSettings/AdvancedSettings';
import ConnectAndSecure from './Accordion/ConnectAndSecure/ConnectAndSecure';
import LauncherAppearance from './Accordion/LauncherAppearance/LauncherAppearance';
import StartBehavior from './Accordion/StartBehavior/StartBehavior';
import VisitorInformation from './Accordion/VisitorInformation/VisitorInformation';
import WebchatAppearance from './Accordion/WebchatAppearance/WebchatAppearance';
import WebchatPosition from './Accordion/WebchatPosition/WebchatPosition';

const WebWidget = () => {
  const { t } = useTranslation();
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const { toggleAccordion, integration, registerAccordion } =
    useAccordion<WebWidgetIntegration>(canWrite);

  return (
    <div>
      <LauncherAppearance
        type={AccordionType.WEB_LAUNCHER_APPEARANCE}
        registerAccordion={registerAccordion}
        toggleAccordion={toggleAccordion}
        integration={integration}
      />
      <WebchatAppearance
        type={AccordionType.WEB_CHAT_APPEARANCE}
        registerAccordion={registerAccordion}
        integration={integration}
        toggleAccordion={toggleAccordion}
      />
      <StartBehavior
        type={AccordionType.START_BEHAVIOR}
        registerAccordion={registerAccordion}
        integration={integration}
        toggleAccordion={toggleAccordion}
      />
      <WebchatPosition
        type={AccordionType.WEB_CHAT_POSITION}
        registerAccordion={registerAccordion}
        integration={integration}
        toggleAccordion={toggleAccordion}
      />
      <VisitorInformation
        type={AccordionType.VISITOR_INFORMATION}
        registerAccordion={registerAccordion}
        integration={integration}
        toggleAccordion={toggleAccordion}
      />
      <ConnectAndSecure
        type={AccordionType.WEB_CONNECT_AND_SECURE}
        registerAccordion={registerAccordion}
        integration={integration}
        toggleAccordion={toggleAccordion}
      />
      <AdvancedSettings
        type={AccordionType.ADVANCED_SETTINGS}
        registerAccordion={registerAccordion}
        integration={integration}
        toggleAccordion={toggleAccordion}
      />
      <ActiveFieldset
        title={t('integrations.web_state.title')}
        subtitle={t('integrations.web_state.subtitle')}
        integration={integration}
      />
      <DeleteFieldset integration={integration} disabled={!canWrite} />
    </div>
  );
};

export default WebWidget;
