import { Icon } from '@/models/icon';

const Sales = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 16 17"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 10.5a1.333 1.333 0 0 1-1.333 1.333h-8L2 14.5V3.833A1.333 1.333 0 0 1 3.333 2.5h9.334A1.333 1.333 0 0 1 14 3.833V10.5Z" />
    </svg>
  );
};

export default Sales;
