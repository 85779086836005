import './Tags.scss';

import { ReactElement } from 'react';

import DeleteIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';

import Chip from '@/components/atoms/Chip/Chip';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import { preventClickThrough } from '@/util/util';

interface TagsProps {
  label?: string;
  customVariant?: 'neutral' | 'highlighted' | 'subdued';
  onClick: (tag: string) => void | undefined;
  icon?: ReactElement;
  tooltip?: string;
  tags?: string[];
  placeholder?: string;
  name: string;
}

function Tags({
  name,
  label,
  tooltip,
  tags = [],
  customVariant = 'highlighted',
  placeholder,
  icon,
  onClick,
}: TagsProps) {
  const handleClick =
    (tag: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      preventClickThrough(e);
      onClick(tag);
    };

  return (
    <div className="tags">
      {label && (
        <Typography
          component="label"
          variant="label-caps-small"
          className="tags__label"
          htmlFor={name}
        >
          <label>
            {label}
            <HelpTooltip tooltip={tooltip} />
          </label>
        </Typography>
      )}
      {isEmpty(tags) && placeholder ? (
        <Typography component="p" variant="label-regular">
          {placeholder}
        </Typography>
      ) : (
        tags.map((t: string) => (
          <Chip
            key={t}
            customVariant={customVariant}
            size="small"
            onDelete={handleClick(t)}
            deleteIcon={<button tabIndex={0}>{icon || <DeleteIcon />}</button>}
            label={t}
          />
        ))
      )}
    </div>
  );
}
export default Tags;
