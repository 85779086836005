import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Move = ({
  size = 24,
  color = 'currentColor',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={disabledClass}
      fill="none"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19.5 12.938h-2.424a.938.938 0 0 0-.663.274l-2.263 2.263a.938.938 0 0 1-.663.275h-2.974a.938.938 0 0 1-.663-.275l-2.263-2.263a.938.938 0 0 0-.663-.274H4.5m15 0V6.375A1.875 1.875 0 0 0 17.625 4.5H6.375A1.875 1.875 0 0 0 4.5 6.375v6.563h15Zm0 0v4.687a1.875 1.875 0 0 1-1.875 1.875H6.375A1.875 1.875 0 0 1 4.5 17.625v-4.688h15Z" />
    </svg>
  );
};

export default Move;
