import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { callPost } from '@/api/fetcher';
import { Dialog } from '@/models/dialog';
import { Entity, PartialEntity } from '@/models/entity';
import { Intent } from '@/models/intent';
import { EventName } from '@/models/segment';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { setPhrases } from '@/redux/auto-ai/actions';
import { showHelper } from '@/redux/helpers/actions';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId, selectIntentName } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';
import { autoIntentLanguages } from '@/util/constants';

import useBrains from './useBrains';
import useDialogs from './useDialogs';
import useEntities from './useEntities';
import useExpressions from './useExpressions';
import { AccountUserPrefsEnum, INAPPHELP_KEYS } from './useHomeCheckList';
import useIntents from './useIntents';
import useLocalStorage from './useLocalStorage';

export const endpoints = Object.freeze({
  dialog: (brainId: string) => `/www/api/v1/brains/${brainId}/auto/dialog`,
  expressions: (brainId: string) => `/www/api/v1/brains/${brainId}/auto/intent`,
});

type AutoBrain = {
  dialog?: Dialog;
  intents?: Intent[];
  entities?: Entity[];
};

type AutoIntent = {
  expressions: string[];
  total: number;
};
type AutoIntentResponse = {
  intent: {
    expressions: string[];
  };
};

const STOP_AUTO_NUMBER = 5;
const TOTAL_AUTO_NUMBER = 5;

const API = Object.freeze({
  callAutoDialog: async (brainId: string, prompt: string): Promise<AutoBrain> =>
    callPost(endpoints.dialog(brainId), { prompt }),
  callAutoExpressions: async (
    brainId: string,
    body: AutoIntent
  ): Promise<AutoIntentResponse> =>
    callPost(endpoints.expressions(brainId), body),
});

/**
 * Hook to handle the auto dialog generation
 */
export const useAutoBrain = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [generatedDialog, setGeneratedDialog] = useState<AutoBrain>();

  const [dismissTestHelp, _] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.TEST_DIALOG],
    false
  );

  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const intentName = useSelector(selectIntentName);

  const { expressions } = useExpressions(brainId, intentName);
  const [error, setError] = useState(null);

  const { createNewIntent, deleteIntent, intents } = useIntents(brainId);
  const { createEntity, deleteEntity, entities } = useEntities(brainId);
  const { createDialog } = useDialogs(brainId);

  const generateAutoDialog = async (prompt: string) => {
    setIsLoading(true);

    try {
      const response = await API.callAutoDialog(brainId, prompt);

      const autoIntent = response.intents?.[0]?.intent;

      const foundIntent = intents.find((i) => i.intent === autoIntent);

      if (!foundIntent && response?.dialog) {
        setGeneratedDialog({
          entities: response?.entities ?? [],
          intents: response?.intents ?? [],
          dialog: response?.dialog,
        });
      } else {
        setError({
          message: 'Error processing the request',
          code: 'auto-brain-generate-dialog',
          extra: {
            [foundIntent?.intent]: foundIntent?.intent,
            code: 'service_unavailable',
            internal_message: 'Intent conflict while generating new resource',
          },
        });
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetResults = () => {
    setError(null);
    setGeneratedDialog(undefined);
    setIsLoading(false);
  };

  const handleError = () => {
    generatedDialog?.entities?.map((entity: Partial<Entity>) => {
      return deleteEntity({ entityName: entity.entity, brainId });
    });
    generatedDialog?.intents?.map((intent: Partial<Intent>) => {
      return deleteIntent({ intentName: intent.intent, brainId });
    });
    dispatch(popModal());
  };

  /**
   * Adds the generated dialog to the brain
   */
  const addDialogToBrain = async () => {
    setIsSubmitting(true);

    if (!generatedDialog.dialog) {
      return;
    }

    const newIntent = { ...generatedDialog.intents?.[0], collection: '' };

    try {
      // 1. Create the intent
      await createNewIntent(newIntent);

      // 2. Create the entities
      if (generatedDialog?.entities?.length > 0) {
        await Promise.all(
          generatedDialog?.entities?.map(async (entity: Partial<Entity>) => {
            const foundEntity = entities.find(
              (i) => i.entity === entity.entity
            );
            if (!foundEntity) {
              const newEntity: PartialEntity = {
                ...entity,
                collection: '',
              };

              return createEntity({ brainId, newEntity });
            }
          })
        );
      }

      // 3. Create the dialog
      const newDialog: Dialog = {
        ...generatedDialog.dialog,
        collection: '',
      };

      createDialog(newDialog, {
        onSuccess: () => {
          if (!dismissTestHelp) {
            dispatch(
              showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.TEST_DIALOG])
            );
          }
          setIsSubmitting(false);
          trackEvent(EventName.AutoDialogCompleted);
          dispatch(
            addTemporalToast(
              'success',
              'Your brain has been updated successfully'
            )
          );
          dispatch(popModal());
        },
        onError: async () => handleError(),
      });
    } catch (e) {
      handleError();
    }
  };

  const getAutoExpressions = async (text: string) => {
    const isAutoAIAvailable =
      expressions?.length < STOP_AUTO_NUMBER &&
      expressions?.length > 0 &&
      autoIntentLanguages.includes(brain?.language);

    if (isAutoAIAvailable) {
      setIsLoading(true);
      setError(null);
      try {
        const response = await API.callAutoExpressions(brainId, {
          expressions: [...expressions, text],
          total: TOTAL_AUTO_NUMBER,
        });
        setIsLoading(false);
        const autoExpressions = response.intent.expressions.map((e) => ({
          text: e,
        }));
        dispatch(setPhrases({ brainId, autoExpressions }));
      } catch (error) {
        console.error(error);
        setError(error);
        setIsLoading(false);
      }
    }
  };

  return {
    addDialogToBrain,
    generateAutoDialog,
    getAutoExpressions,
    resetResults,
    isLoading,
    isSubmitting,
    generatedDialog,
    error,
  };
};
