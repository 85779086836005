import { useCallback, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Autocomplete, {
  Option,
} from '@/components/atoms/AutoComplete/AutoComplete';
import Callout from '@/components/atoms/Callout/Callout';
import useMembers from '@/hooks/useMembers';
import { useTeamUsers } from '@/hooks/useTeamUsers';
import { RoleType } from '@/models/member';
import { Team } from '@/models/team';
import { popModal } from '@/redux/modals/actions';
import { invitationRules, errorMessage } from '@/util/validator';

import Modal from '../Modal';

interface Form {
  members: {
    label: string;
    value: string;
  }[];
}

interface Props {
  team: Team;
  type: 'add' | 'remove';
}

const ModalTeamMembers = ({ type, team }: Props) => {
  const { t } = useTranslation();
  const { team_id, name } = team || {};
  const { users, addUsers, removeUser } = useTeamUsers(team_id);
  const { members } = useMembers();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      members: [],
    },
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const memberOptions = useMemo(
    () =>
      members?.reduce((acc, user) => {
        const isNotTeamMember =
          users?.findIndex((u) => u.user_id === user.user_id) == -1;
        if (type === 'add' ? isNotTeamMember : !isNotTeamMember) {
          acc.push({
            value: user.user_id,
            label: user.name,
            description: user.email,
          });
        }
        return acc;
      }, [] as Option[]),
    [members, type, users]
  );

  const onSubmit = useCallback(
    async (form: Form) => {
      setIsLoading(true);
      const user_ids = form.members.map((r) => r.value) as string[];
      if (type == 'add') {
        const newTeam = {
          user_ids,
          team_id,
          name,
        };
        addUsers(newTeam, {
          onSuccess: () => {
            dispatch(popModal());
          },
        });
      }
      if (type === 'remove') {
        await Promise.race(
          user_ids.map((user_id: string) => {
            return removeUser({
              team_id: team_id,
              user_id,
            });
          })
        );

        dispatch(popModal());
      }
    },
    [addUsers, dispatch, name, removeUser, team_id, type]
  );

  const placeholder =
    type === 'add'
      ? t('teams.invite_members_placeholder')
      : t('teams.remove_members_placeholder');

  const title =
    type === 'add' ? t('teams.add_members_to') : t('teams.remove_members_from');

  const membersWithoutRole = watch('members').reduce((acc, user) => {
    const { team_ids, role_ids, role } = members.find(
      (m) => m.user_id === user.value
    );

    if (team_ids.length == 0 || (team_ids.length == 1 && team_id)) {
      if (role_ids.length === 0 && role !== RoleType.OWNER) {
        acc.push(`"${user.label}"`);
      }
    }
    return acc;
  }, [] as string[]);

  return (
    <Modal
      size="medium"
      preventClose
      title={
        <>
          {title}
          <span className="formattedSpan">{name}</span>
        </>
      }
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={type === 'add' ? t('common.add') : t('common.remove')}
      isSubmitting={isLoading}
      autoFocus
      danger={type === 'remove'}
    >
      <form autoComplete="off">
        <div className="input__container">
          <Autocomplete<Form>
            control={control}
            name="members"
            label={t('common.members')}
            disabled={!members}
            options={memberOptions}
            placeholder={placeholder}
            multiple
            size="large"
            labelLarge
            disableAddNew
            variant="secondary"
            error={!!errors?.members?.message}
            errorMessage={errorMessage({
              field: errors.members,
            })}
            rules={invitationRules(t).roles}
          />
        </div>
      </form>
      {membersWithoutRole.length > 0 && type === 'remove' && (
        <Callout variant="error">
          {t('teams.remove_member_warning', {
            0: membersWithoutRole?.join(', '),
            name,
          })}
        </Callout>
      )}
    </Modal>
  );
};

export default ModalTeamMembers;
