import React from 'react';

import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Bar, prepareBar } from '@/util/analytics';

import useInterval, { IntervalRow } from './useInterval';
import AnalyticsTooltip from '../components/tooltip/AnalyticsTooltip';
import { selectFilters } from '../redux/selectors';

type PieData = {
  id: string;
  label: string;
  value: number;
  count: number;
};

interface Containment {
  pie?: PieData[];
  bar?: Bar[];
  totalCount?: number;
  PieTooltip?: React.ReactElement;
  BarTooltips?: React.ReactElement[];
}
interface ContainmentHook extends Containment {
  isLoading: boolean;
  error?: ApolloError;
}

export const formatContainment = (
  t,
  total?: IntervalRow[],
  unknown?: IntervalRow[]
): Containment => {
  if (!total || !unknown) {
    return {};
  }

  const pie = [
    {
      id: 'not-contained',
      label: t('analytics.total_not_contained'),
      count: 0,
      value: 0,
    },
    {
      id: 'contained',
      label: t('analytics.total_containment'),
      count: 0,
      value: 0,
    },
  ];

  const {
    newBar: bar,
    currentTotalCounts,
    previousTotalCounts,
  } = prepareBar({
    currentTotal: total,
    previousTotal: unknown,
    shouldComputeDifference: true,
  });

  pie[0].count = previousTotalCounts;
  pie[1].count = currentTotalCounts;

  // Calculate the percentage for the pie chart
  pie[0].value = currentTotalCounts
    ? Math.floor((pie[0].count / (pie[1].count + pie[0].count)) * 100)
    : 0;
  pie[1].value = currentTotalCounts
    ? Math.ceil((pie[1].count / (pie[1].count + pie[0].count)) * 100)
    : 0;

  return { pie, bar, totalCount: currentTotalCounts };
};

const useContainment = (): ContainmentHook => {
  const total = useInterval({
    type: 'sessions',
    is_brain: true,
    is_brain_effectiveness: true,
  });
  const totalNotContained = useInterval({
    type: 'sessions',
    is_contained: false,
    is_brain_effectiveness: true,
  });
  const { startDate, endDate } = useSelector(selectFilters);
  const { t } = useTranslation();

  if (total.isLoading || totalNotContained.isLoading) {
    return {
      isLoading: true,
    };
  }

  if (total.error || totalNotContained.error) {
    return {
      isLoading: false,
      error: total.error || totalNotContained.error,
    };
  }

  const containment = formatContainment(
    t,
    total?.data,
    totalNotContained?.data
  );
  const pieTooltipData = [
    {
      title: t('analytics.charts_tooltip.percent_contain'),
      value: `${containment.pie?.[1].value}%`,
    },
    {
      title: t('analytics.charts_tooltip.total'),
      value: `${containment.pie?.[0].count + containment.pie?.[1].count}`,
    },
    {
      title: t('analytics.charts_tooltip.contain'),
      value: `${containment.pie?.[1].count}`,
    },
    {
      title: t('analytics.charts_tooltip.not_contain'),
      value: `${containment.pie?.[0].count}`,
    },
  ];

  const PieTooltip = (
    <AnalyticsTooltip
      data={pieTooltipData}
      startDate={startDate}
      endDate={endDate}
    />
  );

  const BarTooltips = containment?.bar?.map((bar) => {
    const [startDate, endDate] = bar.date.split('-');
    const tooltipData = [
      {
        title: t('analytics.charts_tooltip.percent_contain'),
        value: `${Math.ceil(
          (100 * bar['primary']) / (bar['primary'] + bar['secondary'])
        )}%`,
      },
      {
        title: t('analytics.charts_tooltip.total'),
        value: `${bar['primary'] + bar['secondary']}`,
      },
      {
        title: t('analytics.charts_tooltip.contain'),
        value: `${bar['primary']}`,
      },
      {
        title: t('analytics.charts_tooltip.not_contain'),
        value: `${bar['secondary']}`,
      },
    ];

    return (
      <AnalyticsTooltip
        key={`containment-${bar.date}`}
        data={tooltipData}
        isBar
        startDate={startDate}
        endDate={endDate}
      />
    );
  });

  return {
    isLoading: false,
    ...containment,
    PieTooltip,
    BarTooltips,
  };
};

export default useContainment;
