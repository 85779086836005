import { ElementType, createElement, useCallback, useMemo } from 'react';

import { InfiniteData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Robot from '@/components/atoms/Icons/Robot';
import Trash from '@/components/atoms/Icons/Trash';
import useUser from '@/hooks/useUser';
import { useUserRoles } from '@/hooks/useUserRoles';
import {
  CountsKeys,
  AgentCounts,
  DepartmentCounts,
  Conversations,
} from '@/models/chat';
import { actions } from '@/models/permissions';
import { Presence } from '@/models/presence';
import { RootState } from '@/models/state';
import { Department } from '@/modules/departments/models';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId } from '@/redux/session/selectors';

import { conversationKeys } from './useConversationsNew';
import { useQueryObserver } from './useQueryObserver';
import { AgentIcon } from '../components/LeftSidebar/Drawer/DrawerItem/AgentIcon';
import { AllConversations } from '../icons/AllConversations';
import { Inbox } from '../icons/Inbox';
import { Spam } from '../icons/Spam';
import { Unassigned } from '../icons/Unassigned';
import { MenuItem } from '../models';

export type MenuType = MenuItem[];

interface Props {
  counts?: Record<CountsKeys, number>;
  agentCounts?: AgentCounts;
  agents?: Presence[];
  departments?: Department[];
  departmentCounts?: DepartmentCounts;
  onPinClick?: (key: string) => void;
  onItemClick?: (key: string) => void;
}

const iconResolver: Record<CountsKeys & 'brain', ElementType> = {
  inbox: Inbox,
  all: AllConversations,
  unassigned: Unassigned,
  spam: Spam,
  trash: Trash,
  brain: createElement(Robot),
};

export const useNavMenu = ({
  counts,
  agentCounts,
  agents,
  departments,
  departmentCounts,
  onPinClick,
  onItemClick,
}: Props) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const deskId = useSelector(selectDeskId);

  const { getUserDepartments } = useUserRoles(user?.user_id);

  const canReadOthers = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.READ_OTHERS)
  );

  const canReadDepartmentsOthers = useSelector((state: RootState) =>
    getPermissions(state, 'conversations.read.departments', actions.OTHERS)
  );

  const { data: myData } = useQueryObserver<InfiniteData<Conversations>>(
    conversationKeys.byAgent(deskId, 'open', 'newest', user?.user_id)
  );

  const { data: unassignedData } = useQueryObserver<
    InfiniteData<Conversations>
  >(conversationKeys.unassigned(deskId, 'open', 'newest'));

  const myUnreadCount = useMemo(
    () =>
      myData?.pages?.[0]?.conversations?.filter(
        ({ agent_unread_count }) => agent_unread_count > 0
      ).length,
    [myData?.pages]
  );

  const unassignedCount = useMemo(
    () => unassignedData?.pages?.[0]?.conversations?.length,
    [unassignedData?.pages]
  );

  const userDepartments = getUserDepartments(deskId);

  const notification = useCallback(
    (key) => {
      if (key === 'inbox') {
        return myUnreadCount > 0;
      }
      if (key === 'unassigned') {
        return unassignedCount > 0;
      }
      return false;
    },
    [myUnreadCount, unassignedCount]
  );

  const mainMenu = useMemo(() => {
    if (!counts) return [];
    return Object.keys(counts).map((key) => {
      const adjustedKey = key === 'inbox' ? 'me' : key;
      return {
        text: t(`conversation.${adjustedKey}`),
        icon: iconResolver[key],
        onClick: () => onItemClick(adjustedKey),
        id: key,
        sum: `${counts[key]}`,
        hasNotification: notification(key),
        disabled: key === 'all' && !canReadOthers,
      };
    });
  }, [counts, canReadOthers, t, notification, onItemClick]);

  const brainCount = agentCounts?.brain || { open: 0 };

  const agentMenu = useMemo(() => {
    if (!agents) return [];
    const assistant = {
      text: t('conversation.assistant'),
      icon: iconResolver['brain'],
      onClick: () => onItemClick('brain'),
      onPinClick: () => onPinClick('brain'),
      id: 'brain',
      sum: `${brainCount.open}`,
      pinned: true,
    };
    return [
      assistant,
      ...agents.map((agent) => {
        const isBrainAgent = agent?.name === 'brain';
        const agentCount = agentCounts?.agents?.find(
          (item) => item.agent_id === agent?.agent_id
        ) || { open: 0 };

        return {
          text: isBrainAgent ? t('conversation.assistant') : agent?.name,
          icon: () => createElement(AgentIcon, { agent }),
          onClick: () => onItemClick(agent?.agent_id),
          onPinClick: () => onPinClick(agent?.agent_id),
          id: agent?.agent_id,
          sum: `${agentCount.open}`,
          // should be disabled if can not read others except for brain
          disabled: !canReadOthers,
        };
      }),
    ];
  }, [
    t,
    brainCount.open,
    agents,
    onItemClick,
    onPinClick,
    agentCounts?.agents,
    canReadOthers,
  ]);

  const departmentsMenu = useMemo(() => {
    if (!departments) return [];
    return departments.map((department) => {
      const departmentCount = departmentCounts?.departments?.find(
        (item) => item.department_id === department?.department_id
      );
      const isUserDepartment = !!userDepartments?.find(
        (item) => item.department_id === department?.department_id
      );

      return {
        text: department?.name,
        onClick: () => onItemClick(department?.department_id),
        onPinClick: () => onPinClick(department?.department_id),
        id: department?.department_id,
        sum: departmentCount ? `${departmentCount.open}` : null,
        icon: () =>
          createElement(
            'span',
            {
              width: 16,
              height: 16,
            },
            department?.icon
          ),
        disabled: !canReadDepartmentsOthers && !isUserDepartment,
        pinned: isUserDepartment,
      };
    });
  }, [
    canReadDepartmentsOthers,
    departmentCounts?.departments,
    departments,
    onItemClick,
    onPinClick,
    userDepartments,
  ]);

  return {
    mainMenu,
    agentMenu,
    departmentsMenu,
  };
};
