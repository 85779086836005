import React from 'react';

import { AccordionState } from './types';

export const createInitialState = (initialPanels: string[]): AccordionState => {
  if (!initialPanels || initialPanels.length === 0) {
    return {};
  }
  const initialState: AccordionState = {};
  initialPanels.forEach((panel) => {
    initialState[panel] = {
      isExpanded: false,
      isDirty: false,
      ref: React.createRef<HTMLDivElement>(),
    };
  });
  return initialState;
};
