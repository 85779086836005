import { useMemo, useState } from 'react';

import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { withInlines } from '@/components/organisms/RichTextEditor/utils';

export const useRichTextEditor = () => {
  const [editors, setEditors] = useState(() => {
    const initialEditors = Array(4)
      .fill(0)
      .map(() => withInlines(withReact(withHistory(createEditor()))));
    return initialEditors;
  });

  const editor = useMemo(
    () => withInlines(withReact(withHistory(createEditor()))),
    []
  );

  const addEditor = () => {
    if (editors.length >= 4) {
      return;
    }
    setEditors([
      ...editors,
      withInlines(withReact(withHistory(createEditor()))),
    ]);
  };

  const removeEditor = (index) => {
    if (editors.length <= 1) {
      return;
    }
    setEditors((prev) => prev.filter((_, i) => i !== index));
  };

  const triggerKey = useMemo(() => '/', []);

  return {
    editor,
    editors,
    triggerKey,
    addEditor,
    removeEditor,
  };
};
