import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react';

import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useDebounce from '@/hooks/useDebounce';
import { useMacros } from '@/hooks/useMacros';
import useMessages from '@/hooks/useMessages';
import { useConversationsNew } from '@/modules/humanChat/hooks/useConversationsNew';
import {
  setFocusIndex,
  setFilteredOptions,
  decrementFocusIndex,
  incrementFocusIndex,
} from '@/redux/macros/actions';
import {
  filteredOptionsSelector,
  focusIndexSelector,
} from '@/redux/macros/selectors';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';
import { delay, selectType, isKeyEnter } from '@/util/util';

import { ChatContext } from '../../context';

export type UploadedData = { name: string; url: string; id?: string };

export const useChatBox = () => {
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const options = useSelector(filteredOptionsSelector);
  const focusIndex = useSelector(focusIndexSelector);
  const dispatch = useDispatch();
  const { macros } = useMacros();
  const [input, setInput] = useState('');
  const [showQuickResponses, setShowQuickResponses] = useState(false);
  const { updateTyping, conversation, getStatus, updateConversation } =
    useConversationsNew({ deskId, sessionId });
  const { t } = useTranslation();
  const { createMessage, createStatus } = useMessages(deskId, sessionId);
  const inputRef = useRef<HTMLDivElement>(null);
  const typingRef = useRef<boolean>();
  const { chat, setChat } = useContext(ChatContext);

  const debouncedInput = useDebounce(input, 1000);

  useEffect(() => {
    if (!isNil(debouncedInput) && typingRef.current) {
      updateTyping('stop');
      typingRef.current = false;
    }
  }, [debouncedInput, updateTyping]);

  // files meta-data from dropzone
  const [files, setFiles] = useState([]);

  // uploaded data
  const [uploadedData, setUploadedData] = useState<UploadedData[]>([]);

  const [uploading, setUploading] = useState<boolean>(false);

  const filteredOptions = useMemo(
    () =>
      macros?.filter((option) =>
        `${option?.name} ${option?.text}`
          .toLowerCase()
          .includes(input.toLowerCase().replace('/', ''))
      ),
    [input, macros]
  );
  const updateInputText = useCallback(
    (text = '') => {
      inputRef.current.innerText = text;
      setInput(text);
      setChat({ ...chat, [sessionId]: text });
    },
    [chat, sessionId, setChat]
  );

  const updateFilesUploaded = useCallback(
    (files: UploadedData[]) => {
      setUploadedData(files);
    },
    [setUploadedData]
  );

  useEffect(() => {
    if (conversation?.status === 'closed' && input !== '') {
      updateInputText('');
      setFiles([]);
    }
  }, [conversation?.status, input, sessionId, setFiles, updateInputText]);

  useEffect(() => {
    dispatch(setFilteredOptions(filteredOptions));
    dispatch(setFocusIndex(0));
  }, [dispatch, filteredOptions]);

  const handleClick = useCallback(
    async (e) => {
      // Prevents the form from submitting and refreshing the page
      e.preventDefault();

      setUploadedData([]);
      setFiles([]);

      if (input && input.trim() !== '') {
        createMessage(
          { text: input.trim() },
          {
            onSuccess: () => {
              updateInputText('');
            },

            onSettled: async () => {
              if (uploadedData.length > 0) {
                setUploading(true);
                for (const data of uploadedData) {
                  createMessage({
                    text: data.name,
                    body: { type: selectType(data.url), url: data.url },
                  });
                  await delay(100);
                }
                setUploading(false);
              }
            },
          }
        );
      }

      if (!isNil(input)) {
        updateTyping('stop');
        typingRef.current = false;
      }

      if ((!input || input?.trim() === '') && uploadedData.length > 0) {
        setUploading(true);
        for (const data of uploadedData) {
          await delay(5000);
          createMessage({
            text: data.name,
            body: { type: selectType(data.url), url: data.url },
          });
          await delay(100);
        }
        setUploading(false);
      }
    },
    [createMessage, input, updateInputText, updateTyping, uploadedData]
  );

  const handleKeyDown = useCallback(
    (e) => {
      // Prevents enter key from creating a new line
      // Allow only "Shift+Enter" to create a new line
      if (isKeyEnter(e) && !e.shiftKey && !showQuickResponses) {
        e.preventDefault();
      }
    },
    [showQuickResponses]
  );

  const handleKeyPress = useCallback(
    async (event) => {
      if (isKeyEnter(event) && showQuickResponses) {
        event.preventDefault();
        updateInputText(options[focusIndex].text);
        setShowQuickResponses(false);
        return;
      }

      if (
        isKeyEnter(event) &&
        !event.shiftKey && // Send message only on "Enter" without "Shift"
        !showQuickResponses &&
        !(getStatus === 'pending' || uploading || createStatus === 'pending')
      ) {
        event.preventDefault();
        handleClick(event);
        return;
      }

      if (event.code === 'Slash' && input?.length === 1) {
        setShowQuickResponses(true);
        return;
      }

      if (
        showQuickResponses &&
        event.code === 'Backspace' &&
        (options?.length === 0 || input?.length === 1)
      ) {
        setShowQuickResponses(false);
        return;
      }

      if (showQuickResponses) {
        if (event.code === 'ArrowUp') {
          dispatch(decrementFocusIndex());
        } else if (event.code === 'ArrowDown') {
          dispatch(incrementFocusIndex());
        }
      }
    },
    [
      showQuickResponses,
      getStatus,
      uploading,
      createStatus,
      input?.length,
      updateInputText,
      options,
      focusIndex,
      handleClick,
      dispatch,
    ]
  );

  const handleChange = useCallback(() => {
    if (conversation?.status === 'closed' && conversation?.state?.active) {
      updateConversation({ status: 'open' });
    }
    if (!typingRef.current) {
      updateTyping('start');
      typingRef.current = true;
    }
    if (showQuickResponses && /\n/.test(inputRef.current.innerText)) {
      return;
    }

    if (inputRef.current) {
      setInput(inputRef.current.innerText.trim());
      setChat({ ...chat, [sessionId]: inputRef.current.innerText });
    }
  }, [
    chat,
    conversation?.state?.active,
    conversation?.status,
    sessionId,
    setChat,
    showQuickResponses,
    updateConversation,
    updateTyping,
  ]);

  const changeInputTo = useCallback(
    (text: string) => {
      updateInputText(text);
      setTimeout(() => inputRef.current.focus(), 0);
    },
    [updateInputText]
  );

  const renderMessage = useCallback(() => {
    if (getStatus === 'pending') {
      return null;
    }
    if (conversation?.state?.active && conversation?.status === 'open') {
      return t('chatBox.open');
    }
    if (conversation?.state?.active && conversation?.status === 'closed') {
      return t('chatBox.closed');
    }
    if (conversation?.state?.active) {
      return t('chatBox.active');
    }
    return t('chatBox.default');
  }, [conversation?.state?.active, conversation?.status, getStatus, t]);

  //TODO UPdate this
  const acceptedFiles = useMemo(
    () => [
      '.png',
      '.jpg',
      '.jpeg',
      '.doc',
      '.docx',
      '.pdf',
      '.xls',
      '.xlsx',
      '.mp3',
      '.mp4',
      '.wav',
      '.wma',
      '.csv',
      '.txt',
      '.ppt',
      '.pptx',
      '.zip',
      '.rar',
    ],

    []
  );

  return {
    handleChange,
    handleKeyPress,
    handleKeyDown,
    handleClick,
    placeholder: renderMessage(),
    isClosed: conversation?.state?.active && conversation?.status === 'closed',
    isUnavailable: !conversation?.state?.active && getStatus !== 'pending',
    isActive: conversation?.state?.active,
    isLoading:
      getStatus === 'pending' || uploading || createStatus === 'pending',
    isButtonDisabled:
      ((input?.length === 0 || isNil(input)) && !uploadedData.length) ||
      uploading,
    inputRef,
    setFiles,
    setUrls: setUploadedData,
    files,
    uploading,
    setUploading,
    acceptedFiles,
    totalFiles: uploadedData.length,
    showQuickResponses,
    setShowQuickResponses,
    changeInputTo,
    filteredOptions,
    updateInputText,
    updateFilesUploaded,
  };
};
