import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

import Pie from '@/components/organisms/Analytics/Pie/Pie';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { numberFormatter } from '@/util/util';

import useSessionsPerChannel from '../../../hooks/useSessionsPerChannel';

import styles from './SessionsPerChannel.module.scss';

const SessionsPerChannel = () => {
  const { t } = useTranslation();
  const { error, isLoading, data, onRefetch, totalCounts } =
    useSessionsPerChannel();
  const title = t('analytics.sessionsPerChannel');
  const isEmpty = !error && !data && !isLoading;

  return (
    <AnalyticsFieldset
      title={title}
      fullWidth
      error={error}
      isEmpty={isEmpty}
      hasFooter={false}
      onReload={onRefetch}
      isLoading={isLoading}
      data={data}
    >
      <div className={styles.container}>
        {data && (
          <>
            <div className={styles.pie}>
              <Pie
                data={data}
                innerRadius={0}
                hasCenterMetric={false}
                padAngle={0.7}
                multiColor
                hasMargin
                arcLabelsRadiusOffset={0.7}
                enableArcLinkLabels
                enableArcLabels
                arcLinkLabelsDiagonalLength={16}
                arcLinkLabelsStraightLength={10}
                arcLabelsSkipAngle={10}
                arcLinkLabelsSkipAngle={10}
              />
            </div>
            <ul className={styles.list}>
              <li>
                <p>{t('analytics.total_conversations')} : </p>
                <Tooltip
                  arrow
                  title={
                    totalCounts !== numberFormatter(totalCounts)
                      ? totalCounts
                      : ''
                  }
                >
                  <span>{numberFormatter(totalCounts)}</span>
                </Tooltip>
              </li>
              {data?.map((x) => (
                <li key={x.id}>
                  <p>{x.label} : </p>{' '}
                  <Tooltip
                    arrow
                    title={
                      totalCounts !== numberFormatter(totalCounts)
                        ? totalCounts
                        : ''
                    }
                  >
                    <span>{numberFormatter(x.count)}</span>
                  </Tooltip>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </AnalyticsFieldset>
  );
};

export default SessionsPerChannel;
