import { useCallback } from 'react';

import cn from 'classnames';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  TYPES,
  generateConditionTextAction,
  generateRule,
  randId,
} from '@/redux/dialogs/helper';
import { addCondition, addNode, moveNode } from '@/redux/nodes/actions';
import { selectUnknownNodes } from '@/redux/nodes/selectors';

import styles from './Placeholder.module.scss';

interface PlaceholderProps {
  parentId?: string;
  show?: boolean;
  label?: string;
  branch?: boolean;
  accept?: string;
  isRootNodeUnknown?: boolean;
  conditionsLength?: number;
}

function Placeholder({
  parentId = null,
  accept = TYPES.NODE,
  label = null,
  show = false,
  branch = false,
  conditionsLength,
}: PlaceholderProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const unknownNodes = useSelector(selectUnknownNodes);
  const dropNode = useCallback(
    (item) => {
      if (item.subType === 'condition') {
        const condition = {
          ...item.props,
          type: item.subType,
          condition_id: item.id,
          // Generate a rule for the condition
          rules: [generateRule()],
          // Generate a text action for the condition
          actions: [generateConditionTextAction()],
        };
        dispatch(addCondition({ condition, nodeId: parentId }));

        // Add an extra else condition along with the first condition
        if (conditionsLength === 0) {
          const elseCondition = {
            ...item.props,
            type: item.subType,
            condition_id: uuidv4(),
            match: 'else',
            name: t('actions.generate_condition', { 0: randId() }),
            // Generate a text action for the condition
            actions: [generateConditionTextAction()],
          };

          dispatch(
            addCondition({ condition: elseCondition, nodeId: parentId })
          );
        }
      } else if (item.move) {
        dispatch(moveNode({ nodeId: item.nodeId, parentId }));
      } else {
        const node = {
          ...item.props,
          type: item.subType,
          node_id: item.id,
          enhance: item.subType === 'unknown' ? true : undefined,
        };
        if (parentId) {
          node.parent_id = parentId;
        }
        dispatch(addNode({ node }));
      }
    },
    [conditionsLength, dispatch, parentId, t]
  );

  const canDropItem = useCallback(
    (item, parentId) => {
      // Block droping more than 1 fallback at the same level
      const nodeHasUnknown = unknownNodes.some(
        (node) => node?.parent_id === parentId
      );

      if (item.subType === 'unknown' && (!parentId || nodeHasUnknown)) {
        return false;
      }

      if (!item.move) {
        return true;
      }

      if (parentId && parentId !== item.nodeId && parentId !== item.parentId) {
        return true;
      }

      return false;
    },
    [unknownNodes]
  );

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    canDrop: (item) => canDropItem(item, parentId),
    drop: dropNode,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const className = `placeholder${branch ? '-branch' : ''}`;

  return (
    <div
      ref={drop}
      className={cn({
        [styles[className]]: true,
        [styles[`${className}--over`]]: isOver,
        [styles[`${className}--show`]]: show,
        [styles[`${className}--can-drop`]]: canDrop,
        [styles[`${className}--condition`]]:
          canDrop && accept === TYPES.CONDITION,
        [styles[`${className}--conditionIsOver`]]:
          isOver && accept === TYPES.CONDITION,
      })}
    >
      {label && <p>{label}</p>}
    </div>
  );
}

export default Placeholder;
