import { useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import { DefaultHeatMapDatum, HeatMapSerie } from '@nivo/heatmap';
import { useSelector } from 'react-redux';

import { AnalyticsQueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { parseFilter } from '@/util/util';

import { selectFilters } from '../redux/selectors';

interface Row {
  hour: number;
  counts: number;
  weekday: number;
}

interface Rows {
  rows: Row[];
}

type Day = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

type MessagesByHour = {
  [key in Day]: number;
} & {
  hour: string;
};

interface Response {
  isLoading: boolean;
  error?: ApolloError;
  refetch?: () => void;
  messages?: HeatMapSerie<DefaultHeatMapDatum, Record<string, never>>[];
  maxMessageCount?: number | undefined;
}

const numberToDay: Record<number, Day> = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

const numberToHour: Record<number, string> = {
  0: '12am',
  1: '2am',
  2: '4am',
  3: '6am',
  4: '8am',
  5: '10am',
  6: '12pm',
  7: '2pm',
  8: '4pm',
  9: '6pm',
  10: '8pm',
  11: '10pm',
};

export const MESSAGES = gql`
  query MessagesPerHourV2(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
    $minNumUserMessages: Int
  ) {
    rows: requests_hourly_counts_v2(
      args: {
        account_id: $accountId
        desk_ids: $deskIds
        brain_parent_ids: $brainIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        start_time: $startDate
        end_time: $endDate
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      counts
      weekday
      hour
    }
  }
`;

const convertUTCtoLocal = (hours: number) => {
  const now = new Date();
  const utcNow = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), hours)
  );
  return utcNow.getHours();
};

const getHourMatrix = (): MessagesByHour[] => {
  const matrix = new Array(12);
  for (let i = 0; i < 12; i += 1) {
    matrix[i] = {
      Sun: 0,
      Mon: 0,
      Tue: 0,
      Wed: 0,
      Thu: 0,
      Fri: 0,
      Sat: 0,
      hour: numberToHour[i],
    };
  }
  return matrix;
};

interface FormatMessages {
  messages: HeatMapSerie<DefaultHeatMapDatum, Record<string, never>>[];
  maxMessageCount: number | undefined;
}

const formatMessages = (rawData?: Row[]): FormatMessages => {
  const completeMatrix = getHourMatrix();
  if (!rawData) {
    return { messages: undefined, maxMessageCount: undefined };
  }
  let maxMessageCount = 0;
  for (let i = 0; i < rawData.length; i += 1) {
    const { hour, counts, weekday } = rawData[i];
    const day = numberToDay[weekday];
    const hourRow = Math.floor(convertUTCtoLocal(hour) / 2);
    completeMatrix[hourRow][day] += counts;
    if (maxMessageCount < completeMatrix[hourRow][day]) {
      maxMessageCount = completeMatrix[hourRow][day];
    }
  }
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const finalMatrix = [];

  completeMatrix?.forEach((row) => {
    const rowData = { id: row.hour, data: [] };

    days.forEach((day) => {
      rowData.data.push({ x: day, y: row[day] || null });
    });

    finalMatrix.push({
      ...rowData,
      //Puts sunday as the last day of the week
      data: rowData.data.slice(1).concat(rowData.data[0]),
    });
  });

  return {
    messages: finalMatrix,
    maxMessageCount,
  };
};

const useMessagesHeatMap = (): Response => {
  const accountId = useSelector(selectAccountId);

  const { startDate, endDate, analytics, filtersLoaded } =
    useSelector(selectFilters);

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(startDate, endDate),
          accountId,
        },
        ...analytics.map((filter) => ({
          [filter.type]: parseFilter(filter),
        }))
      ),
    [accountId, analytics, endDate, startDate]
  );

  const { loading, data, error, refetch } = useQuery<
    Rows,
    AnalyticsQueryVariables
  >(MESSAGES, {
    variables,
    skip: !accountId || !filtersLoaded,
  });
  const { messages, maxMessageCount } = formatMessages(data?.rows);
  return {
    isLoading: loading,
    error,
    refetch,
    messages,
    maxMessageCount,
  };
};

export default useMessagesHeatMap;
