import { useCallback, useState } from 'react';

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import styled from '@mui/system/styled';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Divider } from '@/components/organisms/Divider/Divider';
import { DrawerList } from '@/modules/humanChat/components/LeftSidebar/Drawer/DrawerList';
import { useNav } from '@/modules/humanChat/hooks/useNav';
import { selectView } from '@/modules/humanChat/redux/selectors';
import { capitalizeFirstLetter, noop } from '@/util/util';

import ChatNavigationItem from './ChatNavigationItem';

import styles from './NavigationIframe.module.scss';

export const MENU_STYLES = {
  '& .MuiPaper-root': {
    boxShadow: 'var(--shadow-primary)',
    border: '0.5px solid #CFEAFC',
    borderRadius: 'var(--border-radius-secondary)',
  },
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiMenuItem-root': {
    padding: 'calc(var(--space-12) / 2) var(--space-8)',
    color: 'var(--text-default-black)',
    gap: 'var(--space-8)',
    height: 'auto',

    '& + .MuiDivider-root': {
      margin: 'var(--space-4) 0',
    },

    svg: {
      color: 'var(--icon-default-gray)',
    },
  },
};

const StyledMenu = styled(Menu)(() => MENU_STYLES);

const CustomModal = styled(Modal)(() => {
  return {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  };
});

const ChatNavigationIframe = () => {
  const {
    conversationsMenu,
    filteredTeammatesMenu,
    teammatesMenu,
    pinnedAgents,
    departmentsMenu,
    pinnedDepartments,
    filteredDepartmentsMenu,
  } = useNav();
  const { selectedAgent, selectedDepartment, title, selectedBrain } =
    useSelector(selectView);

  const selected = useCallback(
    (id: string) => {
      if (title) {
        const converted = title === 'me' ? 'inbox' : title;
        return converted === id;
      }

      if (selectedAgent) {
        return selectedAgent === id;
      }

      if (selectedBrain) {
        return selectedBrain === id;
      }

      if (selectedDepartment) {
        return selectedDepartment === id;
      }
    },
    [selectedAgent, selectedBrain, selectedDepartment, title]
  );

  const { t } = useTranslation();
  const [agentsAnchor, setAgentsAnchor] = useState<null | HTMLElement>(null);

  const handleAgentsMenuClick = (e) => {
    setAgentsAnchor(e.currentTarget);
  };

  const handleAgentsCloseMenu = () => {
    setAgentsAnchor(null);
  };
  const [departmentsAnchor, setDepartmentsAnchor] =
    useState<null | HTMLElement>(null);

  const handleDepartmentsMenuClick = (e) => {
    setDepartmentsAnchor(e.currentTarget);
  };

  const handleDepartmentsCloseMenu = () => {
    setDepartmentsAnchor(null);
  };

  return (
    <nav className={cn(styles.navbar)}>
      <ul className={cn(styles.navigationList)}>
        {conversationsMenu.map((conversation) => {
          const { hasNotification, text, icon, onClick, sum, id } =
            conversation;
          return (
            <ChatNavigationItem
              hasNotification={hasNotification}
              text={t(text)}
              icon={icon}
              onClick={onClick}
              sum={sum}
              key={id}
              selected={selected(id)}
              title={capitalizeFirstLetter(t('conversation.conversations'))}
            />
          );
        })}
        <Divider vertical />
        {filteredTeammatesMenu.map((mate) => {
          const { text, icon, onClick, sum, id } = mate;
          return (
            <ChatNavigationItem
              text={t(text)}
              icon={icon}
              onClick={onClick}
              selected={selected(id)}
              sum={sum}
              key={id}
              title={t('conversation.teammates')}
            />
          );
        })}

        <ChatNavigationItem
          icon={<MoreHoriz />}
          onClick={handleAgentsMenuClick}
          selected={Boolean(agentsAnchor)}
          menu
        />
        <StyledMenu
          slots={{ root: CustomModal }}
          className={styles.menu}
          open={Boolean(agentsAnchor)}
          anchorEl={agentsAnchor}
          onClose={handleAgentsCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <DrawerList
            headerTitle=""
            items={teammatesMenu}
            pinnedItems={pinnedAgents}
            onBackClick={noop}
            showNav={false}
          />
        </StyledMenu>
        {Boolean(departmentsMenu.length) && (
          <>
            <Divider vertical />
            {filteredDepartmentsMenu.map(({ text, icon, onClick, sum, id }) => {
              return (
                <ChatNavigationItem
                  text={t(text)}
                  icon={icon}
                  onClick={onClick}
                  selected={selected(id)}
                  sum={sum}
                  key={id}
                />
              );
            })}
            <ChatNavigationItem
              icon={<MoreHoriz />}
              onClick={handleDepartmentsMenuClick}
              selected={Boolean(departmentsAnchor)}
              menu
            />
            <StyledMenu
              slots={{ root: CustomModal }}
              className={styles.menu}
              open={Boolean(departmentsAnchor)}
              anchorEl={departmentsAnchor}
              onClose={handleDepartmentsCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <DrawerList
                headerTitle=""
                items={departmentsMenu}
                pinnedItems={pinnedDepartments}
                onBackClick={noop}
                showNav={false}
              />
            </StyledMenu>
          </>
        )}
      </ul>
    </nav>
  );
};

export default ChatNavigationIframe;
