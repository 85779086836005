export const defaultActionOptions = [
  {
    label_key: 'actions.types.url',
    value: 'url',
  },
  {
    label_key: 'actions.types.postback',
    value: 'postback',
  },
  {
    label_key: 'actions.types.webview',
    value: 'webview',
  },
  {
    label_key: 'actions.types.phone',
    value: 'phone',
  },
];

export const mediaOptions = [
  {
    label_key: 'actions.types.image',
    value: 'image',
  },
  {
    label_key: 'actions.types.video',
    value: 'video',
  },
];

export const MAX_CAROUSEL_CARDS = 6;

export const MAX_CAROUSEL_CARD_BUTTONS = 3;
