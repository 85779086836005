import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { numberFormatter, parseFilter } from '@/util/util';

import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

export interface Row {
  meaningful_sessions: number;
  nonmeaningful_sessions: number;
  total_sessions: number;
  meaningful_brain_sessions: number;
  meaningful_collection_sessions: number;
}

interface Data {
  value: number;
  label: number | string;
}
export interface List {
  meaningful_sessions: Data;
  nonmeaningful_sessions: Data;
  total_sessions: Data;
  meaningful_brain_sessions: Data;
  meaningful_collection_sessions: Data;
}
interface Rows {
  rows: Row[];
}

interface Pie {
  id: string;
  count: number;
  label: string;
  value: number;
}
interface MeaningFulSessionsHook {
  isLoading: boolean;
  error?: ApolloError;
  onRefetch?: () => void;
  pie?: Pie[];
  list?: List;
}

export const SESSIONS_METERING_FAST = gql`
  query SessionsMeteringFast(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $startDate: timestamp
    $endDate: timestamp
  ) {
    rows: sessions_metering_fast(
      args: {
        start_time: $startDate
        end_time: $endDate
        account_id: $accountId
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
      }
    ) {
      meaningful_sessions
      nonmeaningful_sessions
      meaningful_brain_sessions
      meaningful_collection_sessions
      total_sessions
    }
  }
`;

export const formatSessions = (rows: Row[], t: (key: string) => string) => {
  const pie = [
    {
      id: 'non-meaningful-conversations',
      label: t('analytics.non_meaningful'),
      count: 0,
      value: 0,
    },
    {
      id: 'meaningful-conversations',
      label: t('analytics.meaningful'),
      count: 0,
      value: 0,
    },
  ] satisfies Pie[];

  const list = {} as List;

  if (!rows || rows.length === 0) {
    return { pie, list };
  }
  const {
    meaningful_sessions,
    nonmeaningful_sessions,
    meaningful_brain_sessions,
    meaningful_collection_sessions,
    total_sessions,
  } = rows[0];

  // Calculate the percentage for the pie chart
  pie[0].value = total_sessions
    ? Math.floor((nonmeaningful_sessions / total_sessions) * 100)
    : 0;
  pie[1].value = total_sessions
    ? Math.ceil((meaningful_sessions / total_sessions) * 100)
    : 0;
  pie[0].count = nonmeaningful_sessions;
  pie[1].count = meaningful_sessions;

  list['total_sessions'] = {
    label: numberFormatter(total_sessions),
    value: total_sessions,
  };
  list['meaningful_brain_sessions'] = {
    label: numberFormatter(meaningful_brain_sessions),
    value: meaningful_brain_sessions,
  };
  list['nonmeaningful_sessions'] = {
    label: numberFormatter(nonmeaningful_sessions),
    value: nonmeaningful_sessions,
  };
  list['meaningful_collection_sessions'] = {
    label: numberFormatter(meaningful_collection_sessions),
    value: meaningful_collection_sessions,
  };
  list['meaningful_sessions'] = {
    label: numberFormatter(meaningful_sessions),
    value: meaningful_sessions,
  };

  return { pie, list };
};

const useMeaningfulSessions = (): MeaningFulSessionsHook => {
  const { t } = useTranslation();
  const accountId = useSelector(selectAccountId);
  const filters = useSelector(selectFilters);

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {},
        {
          ...getAnalyticsRange(filters.startDate, filters.endDate),
          accountId,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  const {
    loading,
    data: rawData,
    error,
    refetch,
  } = useQuery<Rows, QueryVariables>(SESSIONS_METERING_FAST, {
    variables,
    skip: !accountId || !filters.filtersLoaded,
  });

  const rows = rawData?.rows;

  const { pie, list } = useMemo(() => formatSessions(rows, t), [rows, t]);

  const onRefetch = useCallback(() => {
    refetch(variables);
  }, [refetch, variables]);

  if (loading || error) {
    return {
      isLoading: loading,
      error,
      onRefetch,
    };
  }

  return {
    isLoading: loading,
    pie,
    list,
  };
};

export default useMeaningfulSessions;
