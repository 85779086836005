import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Props, Dismissable } from '@/components/atoms/Banner/Banner';

const slice = createSlice({
  name: 'banners',
  initialState: {
    isOpen: false,
    props: null,
  },
  reducers: {
    openBanner: (state, action: PayloadAction<Props & Dismissable>) => {
      state.isOpen = true;
      state.props = action.payload;
    },

    closeBanner: (state) => {
      state.isOpen = false;
      state.props = null;
    },
  },
});

export const { openBanner, closeBanner } = slice.actions;
export default slice.reducer;
