import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Whatsapp from '@/components/atoms/Icons/Whatsapp';
import Input from '@/components/atoms/Input/Input';
import QrCodeGenerator from '@/components/atoms/QrCodeGenerator/QrCodeGenerator';
import { useWhatsapp } from '@/hooks/useWhatsapp';

import styles from './WhatsappInfo.module.scss';

const WhatsappInfo = () => {
  const { t } = useTranslation();
  const { data: whatsappData, isLoading, error, uri } = useWhatsapp();

  const svgString = useMemo(
    () => encodeURIComponent(renderToStaticMarkup(<Whatsapp />)),
    []
  );
  const image = `data:image/svg+xml,${svgString}`;

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('integrations.whatsapp.overview')}</div>
      <div className={styles.header}>
        <div className={styles.card}>
          <div>
            {isLoading && (
              <>
                <Skeleton variant="text" width={100} height={16} />
                <Skeleton variant="text" width={100} height={16} />
              </>
            )}
            {uri && (
              <>
                <p className={styles.name}>
                  {whatsappData?.verified_name ||
                    t('integrations.whatsapp.account_name')}
                </p>
                <p className={styles.uri}>Uri: {uri}</p>
                <CopyButton
                  className="primary"
                  disabled={error}
                  data={uri}
                  iconPosition="left"
                >
                  {t('integrations.whatsapp.deep_link')}
                </CopyButton>
              </>
            )}
          </div>
        </div>
        {uri && !error && (
          <div className={styles.qrcode}>
            <QrCodeGenerator url={uri} image={image} />
          </div>
        )}
      </div>
      <div className={styles.dataContainer}>
        <Input
          isLoading={isLoading}
          label={t('integrations.whatsapp.phone_disp')}
          value={whatsappData?.display_phone_number}
          size="medium"
          readOnly
        />
        <Input
          isLoading={isLoading}
          label={t('integrations.whatsapp.verif_status')}
          value={whatsappData?.code_verification_status}
          size="medium"
          readOnly
        />
        <Input
          isLoading={isLoading}
          label={t('integrations.whatsapp.quality_rating')}
          value={whatsappData?.quality_rating}
          size="medium"
          readOnly
        />
      </div>
    </div>
  );
};

export default WhatsappInfo;
