import { useCallback, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import useDesks from '@/hooks/useDesks';

import Brand from '../../Icons/Navigation/Brand';
import Whatsapp from '../../Icons/Whatsapp';
import Button from '../Button/Button';

import styles from './ButtonTest.module.scss';

export type ButtonTestType = {
  disabled: boolean;
  onClickMoveo: () => void;
  onClickWhatsApp: () => void;
};

const ButtonTest = ({
  disabled,
  onClickMoveo,
  onClickWhatsApp,
}: ButtonTestType) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>();

  const { desks } = useDesks();

  const hasWhatsApp = useMemo(
    () =>
      !!desks?.find((desk) =>
        desk?.integrations?.find((int) => int.type === 'whatsapp')
      ),
    [desks]
  );

  useClickAway(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const callAndClose = useCallback(
    (onClick: () => void) => () => {
      onClick();
      setIsOpen(false);
    },
    []
  );

  const onTestClick = useCallback(() => {
    if (hasWhatsApp) {
      setIsOpen((prev) => !prev);
    } else {
      onClickMoveo();
    }
  }, [hasWhatsApp, onClickMoveo]);

  return (
    <div className={styles.container} ref={ref}>
      <Button variant="secondary" disabled={disabled} onClick={onTestClick}>
        {t('common.test')}
      </Button>
      {isOpen && (
        <div className={styles.dropdown}>
          <button onClick={callAndClose(onClickMoveo)}>
            <Brand size={16} color="var(--icon-default-blue)" />{' '}
            {t('common.test_moveo')}
          </button>
          {hasWhatsApp && (
            <button onClick={callAndClose(onClickWhatsApp)}>
              <Whatsapp size={16} /> {t('common.test_whatsapp')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ButtonTest;
