import { useCallback, useEffect, useRef } from 'react';

import cn from 'classnames';
import Confetti from 'react-confetti';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useWindowSize } from 'react-use';

import { Banner } from '@/components/atoms/Banner/Banner';
import ChatNavigationIframe from '@/components/organisms/MainNavigation/IframeNavigation/ChatNavigationIframe';
import MainNavigationIframe from '@/components/organisms/MainNavigation/IframeNavigation/MainNavigationIframe';
import MainNavigation from '@/components/organisms/MainNavigation/MainNavigation';
import { useNewVersion } from '@/hooks/useNewVersion';
import { RootState } from '@/models/state';
import { useBillingBanner } from '@/modules/billing/hooks/useBillingBanner';
import { closeBanner } from '@/redux/banners/actions';
import { disableConfetti } from '@/redux/confetti/actions';
import { isConfettiActive } from '@/redux/confetti/selectors';
import { selectIframeView } from '@/redux/user/selectors';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
  withSidebar?: boolean;
  mainNavigation?: boolean;
}

const Layout = ({
  children,
  withSidebar = false,
  mainNavigation = true,
}: LayoutProps) => {
  const dispatch = useDispatch();
  useNewVersion();
  useBillingBanner();
  const banners = useSelector(
    (state: RootState) => state.banners,
    shallowEqual
  );

  const iframeView = useSelector(selectIframeView);
  const { pathname } = useLocation();
  const isChatPage = pathname.includes('/chats');

  const isConfettiEnabled = useSelector(isConfettiActive);

  const { width, height } = useWindowSize();

  const onDismiss = useCallback(() => {
    dispatch(closeBanner());
  }, [dispatch]);

  const confettiTimeout = useRef<NodeJS.Timeout>(null);
  useEffect(() => {
    if (isConfettiEnabled && !confettiTimeout.current) {
      confettiTimeout.current = setTimeout(
        () => dispatch(disableConfetti()),
        4000
      );
    } else {
      confettiTimeout.current = null;
    }
  }, [isConfettiEnabled, dispatch]);

  return (
    <div className={styles.container}>
      {isConfettiEnabled && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          initialVelocityY={6}
        />
      )}
      <div
        className={cn(styles.wrapper, {
          [styles.hasBanner]: banners.isOpen,
          [styles.smallBanner]:
            banners.isOpen && banners.props.padding === 'small',
        })}
      >
        {banners.isOpen && (
          <Banner {...banners.props} isStatic onDismiss={onDismiss} />
        )}

        {mainNavigation && !iframeView && <MainNavigation />}
        {mainNavigation && iframeView && !isChatPage && (
          <MainNavigationIframe />
        )}
        {mainNavigation && iframeView && isChatPage && <ChatNavigationIframe />}

        <div
          className={cn(styles.main, {
            [styles.withSidebar]: withSidebar,
            [styles.withMainNavigation]: mainNavigation,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
