import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import IconClose from '@/components/atoms/Icons/Close';
import Input from '@/components/atoms/Input/Input';
import useBrains from '@/hooks/useBrains';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';
import { LENGTH_M, MAX_DIALOG_NAME_LENGTH } from '@/util/validator';

import styles from './ModalCustomGuidelineCreate.module.scss';

type Form = {
  title: string;
  description: string;
};

export const ModalCustomGuidelineCreate = () => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(popModal());
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
  } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const onSubmit = (data: Form) => {
    closeModal();
    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        custom_guidelines: [
          ...(brain?.guidelines?.custom_guidelines ?? []),
          {
            title: data.title,
            subtitle: data.description,
            body: '',
            created_at: Date.now(),
          },
        ],
      },
    });
  };

  return (
    <Modal
      aria-labelledby="create-custom-guideline-title"
      aria-describedby="create-custom-guideline-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={true}>
        <div className={styles.modal}>
          <IconButton
            className={styles.modal__closeIcon}
            onClick={closeModal}
            ariaLabel={t('modals.close')}
          >
            <IconClose color="var(--icon-default-gray)" size={20} />
          </IconButton>

          <section className={styles.modal__content}>
            <header>
              <Typography
                variant="h2-semi-bold"
                component="h1"
                id="create-custom-guideline-title"
              >
                {t('ai_agents.knowledge.add_new_guideline')}
              </Typography>
            </header>

            <form
              onSubmit={handleSubmit(onSubmit)}
              id="create-custom-guideline-description"
            >
              <Input
                {...register('title', {
                  required: t('validation.required'),
                  maxLength: {
                    value: MAX_DIALOG_NAME_LENGTH,
                    message: t('validation.less_than', {
                      0: MAX_DIALOG_NAME_LENGTH,
                    }),
                  },
                })}
                label={t('common.title')}
                placeholder={t('common.type_value')}
                error={!!errors.title}
                errorMessage={capitalizeFirstLetter(errors.title?.message)}
              />

              <Input
                {...register('description', {
                  required: t('validation.required'),
                  maxLength: {
                    value: LENGTH_M,
                    message: t('validation.less_than', {
                      0: LENGTH_M,
                    }),
                  },
                })}
                label={t('common.description')}
                placeholder={t('common.type_value')}
                error={!!errors.description}
                errorMessage={capitalizeFirstLetter(
                  errors.description?.message
                )}
              />

              <footer className={styles.modal__footer}>
                <Button
                  onClick={() => {
                    closeModal();
                  }}
                  variant="tertiary"
                  type="button"
                  size="large"
                >
                  {t('common.cancel')}
                </Button>

                <Button
                  disabled={!isDirty}
                  isLoading={isSubmitting}
                  size="large"
                  type="submit"
                >
                  {t('ai_agents.knowledge.add_guideline')}
                </Button>
              </footer>
            </form>
          </section>
        </div>
      </Fade>
    </Modal>
  );
};
