import { Icon } from '@/models/icon';

const ToolIntent = ({
  size = 28,
  color = 'var(--icon-default-blue)',
}: Icon) => {
  return (
    <svg
      viewBox="0 0 28 28"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.728 18.19l.113.068.106-.077a3.052 3.052 0 011.77-.578 3.052 3.052 0 011.794.575c.524.377.918.91 1.125 1.524.207.615.218 1.28.031 1.901a3.099 3.099 0 01-1.074 1.561 3.054 3.054 0 01-3.583.105 3.094 3.094 0 01-1.162-1.495 3.125 3.125 0 01-.079-1.9l.04-.146-.13-.078-2.507-1.506-.134-.08-.111.11a4.143 4.143 0 01-2.933 1.22 4.143 4.143 0 01-2.933-1.22l-.11-.11-.135.08-2.508 1.506-.132.08.043.147c.078.269.12.546.123.826 0 .613-.18 1.211-.518 1.72-.338.51-.817.907-1.378 1.141a3.045 3.045 0 01-3.346-.671 3.124 3.124 0 01-.666-3.378 3.092 3.092 0 011.132-1.39 3.051 3.051 0 011.705-.522 3.052 3.052 0 011.77.578l.106.077.113-.068 2.671-1.594.137-.081-.049-.152a3.943 3.943 0 01-.187-1.16c.004-.928.312-1.83.877-2.562a4.16 4.16 0 012.245-1.488l.15-.039V8.268l-.133-.048a3.077 3.077 0 01-1.626-1.353 3.122 3.122 0 01.67-3.938 3.055 3.055 0 013.958 0 3.122 3.122 0 01.67 3.938 3.077 3.077 0 01-1.626 1.353l-.133.048v2.846l.15.039c.89.232 1.68.755 2.245 1.488.565.733.873 1.634.876 2.562a3.945 3.945 0 01-.187 1.16l-.048.152.136.08 2.672 1.595zM4.554 21.781a1.284 1.284 0 001.63-.161 1.304 1.304 0 00-.419-2.119 1.281 1.281 0 00-1.407.282 1.302 1.302 0 00-.28 1.415c.098.237.263.44.476.584zM14.71 4.229a1.284 1.284 0 00-1.63.162 1.304 1.304 0 00.418 2.118 1.282 1.282 0 001.408-.282 1.302 1.302 0 00.28-1.415 1.297 1.297 0 00-.476-.583zm-2.04 12.97a2.367 2.367 0 003.007-.299 2.408 2.408 0 00-.773-3.912 2.364 2.364 0 00-2.595.52 2.416 2.416 0 00-.516 2.613c.18.44.485.814.877 1.078zm9.328 4.584a1.284 1.284 0 001.63-.162 1.304 1.304 0 00-.418-2.119 1.282 1.282 0 00-1.407.282 1.301 1.301 0 00-.28 1.415c.098.237.263.44.475.584z"
        fill={color}
        stroke={color}
        strokeWidth=".4"
      />
    </svg>
  );
};

export default ToolIntent;
