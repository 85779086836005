import { useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { popModal } from '@/redux/modals/actions';

import Modal from './Modal';

type Props = {
  onImport: () => void;
  onBlur?: () => void;
  subtitle?: string;
  warning?: React.ReactNode;
};

function ModalImport({ onImport, onBlur, subtitle, warning }: Props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const handleImport = () => {
    onImport();
    dispatch(popModal());
  };

  const onChange = () => {
    setChecked(!checked);
  };

  return (
    <Modal
      title={t('prompts.unsaved_changes.title')}
      subtitle={subtitle}
      onPrimarySubmit={handleImport}
      onSecondarySubmit={onBlur}
      danger
      primaryButtonText={t('common.import_anyway')}
      primaryButtonDisable={!checked}
    >
      {warning}
      {
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              name="checkbox"
              color="primary"
            />
          }
          label={t('dialog.import_dialog_ok')}
        />
      }
    </Modal>
  );
}
export default ModalImport;
