import { forwardRef } from 'react';

import cn from 'classnames';

import './Spinner.scss';

interface SpinnerProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

const Spinner: React.FC<SpinnerProps> = forwardRef(
  (
    { className = '', size = 'small', ...svgProps }: SpinnerProps,
    ref: React.Ref<HTMLSpanElement>
  ) => (
    <span className={cn('spinner', size, className)} ref={ref}>
      <svg
        {...svgProps}
        className="spinner_svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z" />
      </svg>
    </span>
  )
);

Spinner.displayName = 'Spinner';

export default Spinner;
