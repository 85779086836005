import { useCallback, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Switch from '@/components/atoms/Switch/Switch';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { languageAndFlagByCountryCode } from '@/util/languageCodes';

import styles from './styles.module.scss';

type Form = {
  greeklish: boolean;
};

interface LanguageProps {
  brain?: Brain;
}

const LanguageFieldset = ({ brain }: LanguageProps) => {
  const { t } = useTranslation();
  const brain_id = brain?.brain_id;

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const { updateBrain, updateStatus } = useBrains(brain_id);
  const {
    control,
    handleSubmit,
    resetField,
    formState: { isDirty },
  } = useForm<Form>();

  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      updateBrain({ ...form, brain_id: brain?.brain_id });
    },
    [brain?.brain_id, updateBrain]
  );

  useEffect(() => {
    if (brain?.brain_id) {
      resetField('greeklish', { defaultValue: brain?.greeklish });
    }
  }, [brain?.brain_id, brain?.greeklish, resetField]);

  return (
    <div className={styles.brain_settings_fields}>
      <FormFieldset
        title={t('field.language')}
        subtitle={t('brains.language_subtitle')}
        primaryButton={{
          children: t('common.save'),
          isLoading: updateStatus === 'pending',
          disabled: !isDirty || brain?.language !== 'el',
        }}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={!brain}
        helpText={t('brains.language_help')}
      >
        <div className={styles.brain_field__label}>{t('field.language')}</div>
        <div className={styles.brain_field__data}>
          {languageAndFlagByCountryCode[brain?.language]?.name ||
            t('common.unknown')}
        </div>
        <div className={styles.brain_field__label}>
          {brain?.language === 'el' && (
            <Controller
              render={({ field: { value, onChange } }) => {
                return (
                  <Switch
                    label={t('brain.greeklish')}
                    checked={value}
                    disabled={!brain || !canWrite}
                    size="medium"
                    onChange={onChange}
                  />
                );
              }}
              name="greeklish"
              control={control}
            />
          )}
        </div>
      </FormFieldset>
    </div>
  );
};

export default LanguageFieldset;
