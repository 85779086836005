import {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import InputBase from '@mui/material/InputBase';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Tick from '@/components/atoms/Icons/Tick';
import { Option } from '@/modules/rules/model';

import styles from './ConditionDropdownButton.module.scss';

type DropdownOption = Option & {
  disabled?: boolean;
};

type Props = {
  options: DropdownOption[];
  index?: number;
  handleOptionClick: (x: string) => void;
  searchBar?: boolean;
  selected: string;
  height?: 'large' | 'xlarge' | 'medium';
  lowerCase?: boolean;
  renderValue?: boolean;
  translationPrefix?: string;
  fullWidth?: boolean;
  alignment?: 'right' | 'left';
};
const muiStyles = {
  root: {
    borderRadius: 'var(--border-radius-base)',
    border: '1px solid var(--border-default-gray)',
    height: '30px',
    paddingLeft: '8px',
    marginBottom: '8px',
    fontSize: 'var(--space-12)',
    lineHeight: 'var(--space-16)',
    backgroundColor: 'var(--surface-primary-white)',
    width: '100%',
  },
};

const ConditionOptionsModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      options,
      index = 0,
      handleOptionClick,
      searchBar = false,
      selected,
      height,
      lowerCase = false,
      renderValue = false,
      translationPrefix = '',
      fullWidth = false,
      alignment = 'left',
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');

    const handleTyping = useCallback((e) => {
      setQuery(e.target.value.toLowerCase().trim());
    }, []);
    const optionRefs = useRef([]);
    optionRefs.current = options.map(
      (_, i) => optionRefs.current[i] ?? createRef()
    );

    const selectedIndex = options.findIndex((x) => x.label === selected);

    useEffect(() => {
      if (selectedIndex > -1) {
        optionRefs.current[selectedIndex].current?.scrollIntoView({
          block: 'nearest',
        });
      }
    }, [selectedIndex]);

    const handleClick = useCallback(
      (x) => {
        handleOptionClick(x);
      },
      [handleOptionClick]
    );

    const generateText = useCallback(
      (option) => {
        if (renderValue) {
          return t(`${translationPrefix}${option.value}`);
        }
        return option.label;
      },
      [renderValue, t, translationPrefix]
    );

    const filteredOptions = options?.filter((option) =>
      generateText(option)?.toLowerCase()?.includes(query)
    );

    return (
      <div
        className={cn(styles.modal, {
          [styles.searchBar]: searchBar,
          [styles[height]]: height,
          [styles.fullWidth]: fullWidth,
          [styles.right]: alignment === 'right',
        })}
        ref={ref}
      >
        {searchBar && (
          <div className={styles.input}>
            <InputBase
              onChange={handleTyping}
              sx={muiStyles.root}
              autoFocus
              placeholder={t('common.search_dots')}
            />
          </div>
        )}
        {filteredOptions.map((x, i) => {
          const isSelected = x.value === selected;
          const isDisabled = x.disabled;
          return (
            <span
              key={x.label}
              className={cn(styles.option, {
                [styles.selected]: isSelected,
                [styles.searchBar]: searchBar,
                [styles.lowerCase]: lowerCase,
                [styles.disabled]: isDisabled,
              })}
              onClick={() => {
                if (!isDisabled) {
                  handleClick({ value: x.value, label: x.label, index });
                }
              }}
              onKeyDown={() => {
                if (!isDisabled) {
                  handleClick({ value: x.value, label: x.label, index });
                }
              }}
              role="button"
              tabIndex={0}
              ref={optionRefs.current[i]}
            >
              {generateText(x)}
              {isSelected && (
                <Tick
                  size={18}
                  backgroundColor="var(--surface-primary-white)"
                  color="var(--icon-default-blue)"
                />
              )}
            </span>
          );
        })}
      </div>
    );
  }
);

ConditionOptionsModal.displayName = 'ConditionOptionsModal';

export default ConditionOptionsModal;
