import cn from 'classnames';
import { useSelected } from 'slate-react';

import styles from '../RichTextEditor.module.scss';

export const LinkComponent = ({ attributes, children, element }) => {
  const selected = useSelected();
  return (
    <a
      {...attributes}
      href={element.link}
      className={cn(styles.linkElement, { [styles.selected]: selected })}
    >
      {children}
    </a>
  );
};
