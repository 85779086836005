import 'react-image-crop/dist/ReactCrop.css';
import { useCallback, useRef, useState } from 'react';

import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';

export type AspectRatio = 'horizontal' | 'square' | 'free';

interface ImageCropProps {
  file: string;
  onUpload: (name: unknown) => void;
  aspectRatio: AspectRatio;
}

const getAspectRatio = (aspectRatio: AspectRatio) => {
  if (aspectRatio === 'horizontal') {
    return 16 / 9;
  }
  if (aspectRatio === 'free') {
    return undefined;
  }
  return 1;
};
const ImageCrop = ({ file, onUpload, aspectRatio }: ImageCropProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [crop, setCrop] = useState<Crop>(null);
  const getCroppedImg = useCallback(
    (image: HTMLImageElement, cropped: Partial<Crop>) => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;

      const scaleY = image.naturalHeight / image.height;

      const { width, height, x, y } = cropped;
      if (width && height && x !== undefined && y !== undefined) {
        canvas.width = width;
        canvas.height = height;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
        ctx.drawImage(
          image,
          x * scaleX,
          y * scaleY,
          width * scaleX,
          height * scaleY,
          0,
          0,
          width,
          height
        );
        return new Promise((resolve) => {
          canvas.toBlob((blob: Blob | null) => {
            if (!blob) {
              console.error('Canvas is empty');
              return;
            }
            resolve(blob);
          }, 'image/jpeg');
        });
      }
      return 0;
    },
    []
  );

  const onChange = useCallback((newCrop: Crop) => {
    setCrop(newCrop);
  }, []);

  const onComplete = useCallback(
    async (_crop: Crop) => {
      if (imgRef.current && _crop.width && _crop.height) {
        const croppedImageUrl = await getCroppedImg(imgRef.current, _crop);
        onUpload(croppedImageUrl);
      }
    },
    [getCroppedImg, imgRef, onUpload]
  );

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      imgRef.current = e.currentTarget;
      const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
      const defaultCrop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 90,
          },
          getAspectRatio(aspectRatio),
          width,
          height
        ),
        width,
        height
      );

      setCrop(defaultCrop);
    },
    [aspectRatio]
  );

  return (
    <ReactCrop
      keepSelection
      crop={crop}
      aspect={getAspectRatio(aspectRatio)}
      onChange={onChange}
      onComplete={onComplete}
    >
      <img src={file} alt="" onLoad={onImageLoad} />
    </ReactCrop>
  );
};

export default ImageCrop;
