import { useCallback, useDeferredValue, useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import { RichHeader } from '@/components/organisms/Headers/RichHeader/RichHeader';
import { TableInfiniteLoader } from '@/components/organisms/InfiniteLoader/TableInfiniteLoader';
import {
  MODAL_FORM,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { TileBrainSkeleton, TileCreation } from '@/components/organisms/Tile';
import TileBroadcast from '@/components/organisms/Tile/TileBroadcast/TileBroadcast';
import TileList from '@/components/organisms/Tile/TileList';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import useDesks from '@/hooks/useDesks';
import { WhatsAppIntegration } from '@/models/integration';
import { actions as permissionsActions } from '@/models/permissions';
import { EventName, PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView, trackEvent } from '@/segment/segment';
import { capitalizeFirstLetter } from '@/util/util';

import { useBroadcasts } from '../hooks/useBroadcasts';
import { LocationState } from '../models';
import { broadcastRules } from '../utils';

interface CreateModalProps {
  name: string;
  channel: string;
}

export const Broadcasts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const slug = useSelector(selectAccountSlug);
  const [filter, setFilter] = useState<string>();
  const defferedFilter = useDeferredValue(filter);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'broadcasts', permissionsActions.WRITE)
  );
  const { getAllIntegrations, desks } = useDesks();
  const navigate = useNavigate();

  useEffect(() => {
    pageView(PageName.BROADCASTS);
  }, []);

  useTitle(t('pages.brains', { 0: slug }));

  const {
    broadcasts,
    createStatus,
    deleteBroadcast,
    updateBroadcast,
    broadcastNames,
    fetchNextPage,
    hasNextPage,
  } = useBroadcasts(undefined, defferedFilter);

  function renderSkeletons(n = 3) {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => <TileBrainSkeleton key={i} />)
    );
  }

  const handleCreateClick = useCallback(() => {
    trackEvent(EventName.ClickCreateBroadcast);
    getAllIntegrations('whatsapp').then(
      (waIntegrations: WhatsAppIntegration[]) => {
        const options = waIntegrations
          .filter((i) => i.config?.access_token)
          .map((i) => {
            const deskName = desks.find(
              (d) => d.desk_id === i.desk_id && i.config?.access_token
            )?.name;
            return {
              label: `${capitalizeFirstLetter(i.name)} > ${deskName}`,
              value: i.integration_id,
            };
          });

        const createModalProps = {
          primaryButtonText: t('broadcasts.add_broadcast'),
          title: t('broadcasts.add_broadcast'),
          onCreate: async (data: CreateModalProps) => {
            const integration = waIntegrations.find(
              (o) => o.integration_id === data.channel
            );
            const state: LocationState = {
              name: data.name,
              integration_id: data.channel,
              channel: integration?.type,
              deskId: integration?.desk_id,
            };
            navigate(`/${slug}/broadcasts/draft`, {
              state,
            });
            dispatch(popModal());
          },
          fields: [
            {
              fieldName: 'name',
              fieldValue: '',
              rules: broadcastRules.name(broadcastNames),
            },
            {
              fieldName: 'channel',
              fieldValue: '',
              type: 'select',
              options,
              rules: broadcastRules.channel,
            },
          ],
        };
        if (options.length > 0) {
          dispatch(pushModal(MODAL_FORM, createModalProps));
        } else {
          const warnProps = {
            title: t('broadcasts.add_broadcast'),
            children: (
              <>
                <Typography variant="subheading-semi-bold" component="p">
                  {t('broadcasts.not_connected_integrations')}
                </Typography>
                <Typography mt={2}>
                  {t('broadcasts.no_integrations')}
                </Typography>
              </>
            ),
            primaryButtonText: t('broadcasts.go_to_integrations'),
            onPrimarySubmit: () => {
              navigate(`/${slug}/integrations`);
              dispatch(popModal());
            },
          };

          dispatch(pushModal(MODAL_WARN, warnProps));
        }
      }
    );
  }, [broadcastNames, desks, dispatch, getAllIntegrations, navigate, slug, t]);

  const getTooltipTitle = () => {
    if (!canWrite) {
      return t('broadcasts.no_permission');
    }

    return '';
  };

  return (
    <PermissionsWrapper module="broadcasts">
      <RichHeader
        setFilter={setFilter}
        disabled={!canWrite}
        onButtonClick={handleCreateClick}
        tooltip={getTooltipTitle()}
        name={t('main_navigation.broadcast')}
      />

      <PageContentWrapper newPlain readOnly={!canWrite}>
        <ContextualHelp
          title={t('broadcasts.contextual_title')}
          name="about_broadcasts"
        >
          {t('broadcasts.contextual_message')}
        </ContextualHelp>
        <TileList>
          <TableInfiniteLoader
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            skeleton={renderSkeletons(1)}
          >
            <TileCreation
              title={t('broadcasts.add_broadcast')}
              onCreateClick={handleCreateClick}
            />
            {!broadcasts && renderSkeletons(3)}
            {broadcasts?.map((broadcast) => (
              <TileBroadcast
                broadcast={broadcast}
                key={broadcast.broadcast_id}
                deleteBroadcast={deleteBroadcast}
                updateBroadcast={updateBroadcast}
              />
            ))}
            {createStatus === 'pending' && renderSkeletons(1)}
          </TableInfiniteLoader>
        </TileList>
      </PageContentWrapper>
    </PermissionsWrapper>
  );
};
