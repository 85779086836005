import { Icon } from '@/models/icon';

export const LockIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.889 7H4.11C3.497 7 3 7.57 3 8.273v4.454C3 13.43 3.497 14 4.111 14h7.778c.614 0 1.111-.57 1.111-1.273V8.273C13 7.57 12.502 7 11.889 7ZM5 7V4.778c0-.737.316-1.443.879-1.964A3.126 3.126 0 0 1 8 2c.796 0 1.559.293 2.121.814.563.52.879 1.227.879 1.964V7"
      />
    </svg>
  );
};

export const UnlockIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.084 7.334H4.306c-.613 0-1.11.57-1.11 1.273v4.454c0 .703.497 1.273 1.11 1.273h7.778c.614 0 1.111-.57 1.111-1.273V8.607c0-.703-.497-1.273-1.11-1.273ZM5.362 6.83V4.61c0-.69.278-1.354.782-1.864a3.135 3.135 0 0 1 1.944-.902 3.237 3.237 0 0 1 2.102.527 2.764 2.764 0 0 1 1.172 1.683"
      />
    </svg>
  );
};
