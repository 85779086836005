import React, { useEffect, forwardRef, useRef } from 'react';

import { useTranslation } from 'react-i18next';

interface Props {
  indeterminate?: boolean;
  onClick: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const TableSelection: React.FC<Props> = forwardRef(
  (
    { indeterminate = false, ...rest }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);
    const { t } = useTranslation();

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return (
      <input
        type="radio"
        ref={combinedRef}
        {...rest}
        title={t('common.select_item')}
        data-testid="table-selection-input"
      />
    );
  }
);

TableSelection.displayName = 'TableSelection';
