import { useCallback, useEffect, useRef, useState } from 'react';

import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Switch from '@/components/atoms/Switch/Switch';
import { useAccount } from '@/hooks/useAccount';
import { usePreferences } from '@/hooks/usePreferences';
import { EventName } from '@/models/segment';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { trackEvent } from '@/segment/segment';

const EmailNotification = () => {
  const { t } = useTranslation();
  const [weeklyEmail, setWeeklyEmail] = useState(true);
  const dispatch = useDispatch();

  const {
    updateAccountUserPreferences,
    updateAccountUserPreferencesStatus,
    accountUserPrefs,
    accountUserPrefStatus,
  } = usePreferences();
  const { account } = useAccount();
  const accountName = account?.name;
  const initialWeeklyEmail = useRef(true);

  useEffect(() => {
    if (accountUserPrefs && !isNil(accountUserPrefs?.weekly_email)) {
      setWeeklyEmail(!!accountUserPrefs?.weekly_email);
      initialWeeklyEmail.current = !!accountUserPrefs?.weekly_email;
    } else {
      setWeeklyEmail(true);
      initialWeeklyEmail.current = true;
    }
  }, [accountUserPrefs, accountUserPrefs?.weekly_email]);

  const onSubmit = useCallback(() => {
    trackEvent(EventName.ClickEmailNotification, { weekly_email: weeklyEmail });
    updateAccountUserPreferences(
      {
        ...accountUserPrefs,
        weekly_email: weeklyEmail,
      },
      {
        onSuccess: () => {
          initialWeeklyEmail.current = weeklyEmail;
          dispatch(
            addTemporalToast('success', t('notifications.email_updated'))
          );
        },
      }
    );
  }, [
    accountUserPrefs,
    dispatch,
    t,
    updateAccountUserPreferences,
    weeklyEmail,
  ]);

  return (
    <FormFieldset
      title={t('notifications.weekly_email')}
      subtitle={t('notifications.weekly_email_subtitle', { 0: accountName })}
      primaryButton={{
        children: t('common.save'),
        isLoading: updateAccountUserPreferencesStatus === 'pending',
        disabled: initialWeeklyEmail.current === weeklyEmail,
      }}
      onSubmit={onSubmit}
      isLoading={accountUserPrefStatus === 'pending'}
    >
      <Switch
        name="weekly_email"
        label={t('common.enabled')}
        checked={weeklyEmail}
        size="medium"
        onChange={() => setWeeklyEmail((c) => !c)}
      />
    </FormFieldset>
  );
};

export default EmailNotification;
