import { useCallback } from 'react';

import cn from 'classnames';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { TYPES, isFinalAction } from '@/redux/dialogs/helper';
import { addAction, moveAction } from '@/redux/nodes/actions';

import styles from './Placeholder.module.scss';

interface PlatceHolderProps {
  nodeId: string;
  requisiteIndex?: number;
  conditionIndex?: number;
  beforeActionId?: string;
  show?: boolean;
  label?: string;
  accept?: string;
  isLast?: boolean;
}

function Placeholder({
  accept = TYPES.ACTION,
  nodeId,
  show = false,
  label = '',
  beforeActionId = null,
  requisiteIndex = null,
  conditionIndex = null,
  isLast = false,
}: PlatceHolderProps) {
  const dispatch = useDispatch();

  const dropActionInNode = useCallback(
    (item) => {
      if (item.move) {
        return dispatch(
          moveAction({
            source: item,
            target: {
              nodeId,
              beforeActionId,
              requisiteIndex,
              conditionIndex,
            },
          })
        );
      }

      const newAction = {
        ...item.props,
        type: item.subType,
        action_id: item.id,
      };

      return dispatch(
        addAction({
          nodeId,
          beforeActionId,
          requisiteIndex,
          conditionIndex,
          newAction,
        })
      );
    },
    [requisiteIndex, nodeId, dispatch, beforeActionId, conditionIndex]
  );

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: dropActionInNode,
    canDrop: (item) => {
      if (item.subType) {
        return !isFinalAction(item.subType) || isLast;
      }
      return !isFinalAction(item.type);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      className={cn({
        [styles.placeholder]: true,
        [styles['placeholder--over']]: isOver,
        [styles['placeholder--can-drop']]: canDrop,
        [styles['placeholder--show']]: show,
      })}
    >
      {label && <p>{label}</p>}
    </div>
  );
}

export default Placeholder;
