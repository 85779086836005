import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { callGet } from '@/api/fetcher';
import { addToast, removeToast } from '@/modules/notifications/redux/actions';
import { isSkippingAppVersion } from '@/redux/appVersion/selectors';

export const useNewVersion = () => {
  const dispatch = useDispatch();
  const updateLater = useSelector(isSkippingAppVersion);

  const { data: version } = useQuery({
    queryKey: [],
    queryFn: () => callGet('/healthcheck'),
    refetchInterval: 300000,
  });
  useEffect(() => {
    if (
      version !== undefined &&
      !updateLater &&
      version?.version !== window.IMAGE_TAG
    ) {
      dispatch(removeToast('update'));
      dispatch(addToast({ type: 'update', id: 'update' }));
    }
  }, [version, updateLater, dispatch]);
};
