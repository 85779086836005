import { ModalKind, ModalsState } from '@/models/modals';
/**
 * General modal actions
 */
export const PUSH_MODAL = 'MODALS/PUSH_MODAL';
export const POP_MODAL = 'MODALS/POP_MODAL';
export const RESET_MODALS = 'MODALS/RESET_MODALS';

const defaultState: ModalsState = [];

const reducer = (
  state = defaultState,
  {
    kind,
    type,
    props,
  }: Partial<{ kind: ModalKind; type: string; props: unknown }>
) => {
  switch (type) {
    case PUSH_MODAL: {
      return [
        ...state,
        {
          kind: kind as ModalKind,
          props,
        },
      ];
    }
    case POP_MODAL: {
      return [...state.filter((e, i) => i !== state.length - 1)];
    }
    case RESET_MODALS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

/**
 * Pushes current modal to be displayed
 * @param  {string} kind  a key in the modal hash defined in ModalConductors.js
 * @param  {object} props the individual modal's react props as a JS object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pushModal = (kind: ModalKind, props?: any) => ({
  type: PUSH_MODAL,
  kind: kind as ModalKind,
  props,
});

export const popModal = () => ({
  type: POP_MODAL,
});

export const resetModals = () => ({
  type: RESET_MODALS,
});

export default reducer;
