import { useCallback, useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import TileHeader from '@/components/atoms/TilesHeader/TilesHeader';
import { RULE_CREATE } from '@/components/organisms/Modals/ModalConductor';
import { TileBrainSkeleton, TileCreation } from '@/components/organisms/Tile';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useDesks from '@/hooks/useDesks';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import RulesHeader from '@/modules/rules/components/RulesHeader/RulesHeader';
import TileRule from '@/modules/rules/components/TileRule';
import TileRuleList from '@/modules/rules/components/TileRuleList';
import { useRules } from '@/modules/rules/hooks/useRules';
import { Rule, Rules as RulesType } from '@/modules/rules/model';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import DefaultConversationAssignment from '../../components/DefaultConversationAssignment/DefaultConversationAssignment';

export default function Rules() {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const dispatch = useDispatch();
  const { ai_agents } = useFeatureFlag();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );

  useEffect(() => {
    pageView(PageName.RULES);
  }, []);

  const deskId = useSelector(selectDeskId);

  const { rules, reorderRule, reorderStatus } = useRules(deskId);
  const { desk } = useDesks(deskId);
  useTitle(t('pages.rules', { 0: desk?.name }));
  const [localRules, setLocalRules] = useState<RulesType['rules']>(undefined);
  const showCreateRuleModal = useCallback(() => {
    dispatch(pushModal(RULE_CREATE));
  }, [dispatch]);

  useEffect(() => {
    setLocalRules(cloneDeep(rules?.rules));
  }, [rules?.rules]);

  const resetLocalRules = useCallback(() => {
    setLocalRules(cloneDeep(rules?.rules));
  }, [rules?.rules]);

  const reorderLocalRule = useCallback(
    (index: number) => (item) => {
      const r = localRules[item.index];
      const temp = cloneDeep(localRules);
      temp.splice(item.index, 1);
      temp.splice(index, 0, r);
      setLocalRules(temp);
    },
    [localRules]
  );

  const getIndex = useCallback(
    (rule) => () =>
      localRules.indexOf(localRules.find((r) => r.rule_id === rule.rule_id)),
    [localRules]
  );

  // TODO rule skeleton
  function renderSkeletons(n = 3) {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TileBrainSkeleton isBrain={false} noMargin={true} key={i} />
        ))
    );
  }

  function renderTiles() {
    return localRules.map((rule: Rule, index: number) => {
      return (
        <TileRule
          rule={rule}
          slug={slug}
          getIndex={getIndex(rule)}
          key={rule.rule_id}
          reorderLocalRule={reorderLocalRule(index)}
          resetLocalRules={resetLocalRules}
          reorderRule={reorderRule}
          reorderIsLoading={reorderStatus === 'pending'}
        />
      );
    });
  }

  return (
    <>
      <RulesHeader />
      <PageContentWrapper newPlain readOnly={!canWrite}>
        <ContextualHelp
          title={t('rules.contextual_help.title')}
          name="about_rules"
        >
          {t(`rules.contextual_help.message${ai_agents ? '_ai_agent' : ''}`)}
        </ContextualHelp>

        <TileHeader highPriority text={t('rules.high_priority')} />
        <TileRuleList>
          {!rules?.rules || !localRules ? renderSkeletons(3) : renderTiles()}
        </TileRuleList>
        {canWrite && (
          <TileCreation
            title={t('rules.create_rule')}
            onCreateClick={showCreateRuleModal}
          />
        )}
        {desk?.brain_parent_id && <DefaultConversationAssignment />}
      </PageContentWrapper>
    </>
  );
}
