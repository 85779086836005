import { useCallback, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import AvatarUpload from '@/components/atoms/Avatar/AvatarUpload/AvatarUpload';
import Input from '@/components/atoms/Input/Input';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import { AccordionFieldsetProps, ViberIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { errorMessage, integrationRulesViber } from '@/util/validator';

import styles from './ViberChatAppearance.module.scss';

type Form = {
  welcome_trigger_message: string;
  avatar_url: string;
};

const ViberChatAppearance = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, ViberIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const deskId = integration?.desk_id;
  const integrationId = integration?.integration_id;

  const { updateIntegration, updateStatus } = useIntegrations<ViberIntegration>(
    deskId,
    integrationId
  );
  const dispatch = useDispatch();

  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    trigger,
    setValue,
    control,
  } = formMethods;

  const avatarUrl = useWatch({ name: 'avatar_url', control });

  useEffect(() => {
    reset({
      welcome_trigger_message:
        integration?.config?.welcome_trigger_message || '',
      avatar_url: integration?.config?.avatar || '',
    });
  }, [
    integration?.config?.avatar,
    integration?.config?.welcome_trigger_message,
    reset,
  ]);

  const handleUpdateIntegration = useCallback(() => {
    const { welcome_trigger_message, avatar_url } = getValues();
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration.config,
          welcome_trigger_message: welcome_trigger_message || undefined,
          avatar: avatar_url || undefined,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, getValues, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  const handleAvatarUpdate = useCallback<(data: { avatar: string }) => void>(
    (data) => {
      setValue('avatar_url', data?.avatar, { shouldDirty: true });
    },
    [setValue]
  );

  return (
    <>
      <Accordion
        title={t('integrations.viber_chat_appearance.title')}
        subtitle={t('integrations.viber_chat_appearance.subtitle')}
        disabled={!isDirty}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
      >
        <div className={styles.wrapper}>
          <TitleSubtitle
            title={t(
              'integrations.viber_chat_appearance.welcome_trigger_message.title'
            )}
            subtitle={t(
              'integrations.viber_chat_appearance.welcome_trigger_message.subtitle'
            )}
          />
          <div className={styles.textArea}>
            <Input
              id="welcome_trigger_message"
              name="welcome_trigger_message"
              placeholder={t(
                'integrations.viber_chat_appearance.welcome_trigger_message.placeholder'
              )}
              size="large"
              readOnly={!canWrite}
              register={register('welcome_trigger_message', {
                maxLength: {
                  value: 64,
                  message: '',
                },
              })}
              trimValue
              errorMessage={errorMessage({
                field: errors.welcome_trigger_message,
                maxLength:
                  integrationRulesViber.welcomeTriggerMessage.maxLength,
              })}
            />
          </div>
        </div>
        <div
          className={cn(styles.wrapper, {
            [styles.disabled]: !canWrite,
          })}
        >
          <Typography
            component="div"
            variant="label-caps-small"
            color="var(--text-default-gray)"
            pb="var(--space-12)"
          >
            {t('integrations.viber_chat_appearance.avatar.title')}
          </Typography>
          <AvatarUpload
            onUpdate={handleAvatarUpdate}
            label={'userAvatar'}
            avatar={avatarUrl}
          />
        </div>
      </Accordion>
    </>
  );
};

export default ViberChatAppearance;
