import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_PUBLIC_KEY } from '@/util/constants';

const promise = loadStripe(STRIPE_PUBLIC_KEY);

const WithStripeElements = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>
) => {
  // eslint-disable-next-line react/display-name
  return (props: P) => (
    <Elements stripe={promise}>
      <Component {...props} />
    </Elements>
  );
};

export default WithStripeElements;
