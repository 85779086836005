import { useTranslation } from 'react-i18next';

import { isVideo } from '@/util/util';

const Card = ({ media }) => {
  const { t } = useTranslation();

  if (!media) {
    return null;
  }

  if (isVideo(media.url)) {
    return <video width={318} height={167} src={media.url} muted />;
  } else {
    return (
      <img
        src={media.url}
        alt={t('dialog.carousel_first')}
        referrerPolicy="no-referrer"
      />
    );
  }
};

export default Card;
