import { createSelector } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';

import { RootState } from '@/models/state';

export const selectIsActionSelected = (actionId: string) =>
  createSelector(
    (state: RootState) => state.nodes.selectedActionId,
    (state: RootState) => state.nodes.selectedOptionIndex,
    (selectedActionId, selectedOptionIndex) =>
      actionId === selectedActionId && selectedOptionIndex === null
  );

export const selectIsIdInFlow = (id: string) =>
  createSelector(
    (state: RootState) => state.nodes.selectedFlowIds,
    (selectedFlowIds) => selectedFlowIds.includes(id)
  );

const selectIsAnyRequisiteSelected = createSelector(
  (state: RootState) => state.nodes.selectedRequisiteId,
  (state: RootState) => state.nodes.selectedRequisiteIndex,
  (selectedRequisiteId, selectedRequisiteIndex) =>
    !isNil(selectedRequisiteId) || !isNil(selectedRequisiteIndex)
);

const selectIsNodeOrConditionSelected = createSelector(
  (state: RootState) => state.nodes.selectedNodeId,
  (state: RootState) => state.nodes.selectedConditionIndex,
  (selectedNodeId, selectedConditionIndex) => {
    return isNil(selectedNodeId) && isNil(selectedConditionIndex);
  }
);

export const selectIsNodeConditionRequisiteSelected = createSelector(
  [selectIsAnyRequisiteSelected, selectIsNodeOrConditionSelected],
  (anyRequisiteSelected, nodeOrConditionSelected) => {
    const result = anyRequisiteSelected || nodeOrConditionSelected;
    return result;
  }
);

export const selectUnknownNodes = createSelector(
  (state: RootState) => state.nodes.nodes,
  (nodes) => Object.values(nodes).filter((node) => node.type === 'unknown')
);

export const selectSelectedCarouselIndex = (state: RootState) =>
  state.nodes.selectedCarouselIndex;

export const selectNodes = (state: RootState) => state.nodes;
const selectValues = (state: RootState) => state.values;
const selectExpressions = (state: RootState) => state.expressions;

export const selectIsDirty = createSelector(
  [selectNodes, selectValues, selectExpressions],
  (nodes, values, expressions) =>
    nodes.dirty || values.dirty || expressions.dirty
);

export const selectNodeIds = createSelector(
  (state: RootState) => state.nodes.nodes,
  (nodes) => nodes.reduce((acc, node) => [...acc, node.node_id], [])
);
export const selectIsNodesDirty = (state) => state.nodes.dirty;
export const selectDraftDialogName = (state) => state.nodes.dialogName;
