import { PageName } from '@/models/segment';

import HeatMapFieldset from '../components/fieldsets/HeatMapFieldset/HeatMapFieldset';
import ScoreCardsFieldset from '../components/fieldsets/ScoreCardsFieldset/ScoreCardsFieldset';
import SessionsPerChannel from '../components/fieldsets/SessionsPerChannel/SessionsPerChannel';
import Filters from '../components/filters/AccountFilters/AccountFilters';
import useSegments from '../hooks/useSegment';

export const UsageReport = () => {
  useSegments(PageName.ANALYTICS_USAGE);
  return (
    <>
      <Filters />
      <ScoreCardsFieldset />
      <HeatMapFieldset />
      <SessionsPerChannel />
    </>
  );
};
