import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const ArrowDown = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 16 16"
      width={size}
      height={size}
      stroke={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.icon}
    >
      <g opacity="0.64">
        <path
          d="M4 6L8 10L12 6"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ArrowDown;
