import { useMemo } from 'react';

import Typography from '@mui/material/Typography';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import useMembers from '@/hooks/useMembers';

import styles from './Events.module.scss';

export const NameAvatar = ({ id, name }: { id: string; name?: string }) => {
  const { members } = useMembers();

  // user can also be a brain, that is why we do not use `member` directly
  const user = useMemo(
    () => members?.find((member) => member.user_id === id),
    [members, id]
  );

  return (
    <div className={styles.nameAvatar}>
      <Avatar src={user?.avatar} altText={user?.name} size="small" />
      <Typography variant="label-semi-bold" color="var(--text-default-gray)">
        {name || user?.name}
      </Typography>
    </div>
  );
};
