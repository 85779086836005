import { memo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';

import styles from './MessageStatus.module.scss';

interface Props {
  created: string | null;
  authorType: string;
  shouldShow: boolean;
}
const MessageStatus = ({ created, authorType, shouldShow }: Props) => {
  const dateCreated = new Date(created);

  return (
    shouldShow && (
      <div
        className={cn(styles.messageStatus, {
          [styles.visitor]: authorType === 'visitor',
        })}
      >
        <Typography variant="label-regular">
          {dateCreated.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Typography>
      </div>
    )
  );
};

export default memo(MessageStatus);
