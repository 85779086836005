import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';

import {
  CustomerSupportSetup,
  CustomerSupportSummary,
} from './CustomerSupportSetup';
import { SellerSetup, SellerSummary } from './SellerSetup';

import styles from './Setup.module.scss';

export const Setup = () => {
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);

  const isCustomerSupportBrain = brain?.brain_type === 'support';
  const isDebtCollectionBrain = brain?.brain_type === 'debt_collection';

  return (
    <div className={styles.setup}>
      <div className={styles.setup__main}>
        {isCustomerSupportBrain ? (
          <CustomerSupportSetup />
        ) : (
          <SellerSetup isDebtCollection={isDebtCollectionBrain} />
        )}
      </div>

      <div className={styles.setup__summary}>
        {isCustomerSupportBrain ? (
          <CustomerSupportSummary />
        ) : (
          <SellerSummary isDebtCollection={isDebtCollectionBrain} />
        )}
      </div>
    </div>
  );
};
