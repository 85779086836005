import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate, useParams } from 'react-router';

import Collection from '@/components/pages/Collection/Collection';
import Datasource from '@/components/pages/Datasource/Datasource';
import { useRtmCollection } from '@/hooks/rtm/useRtmCollection';
import { setCollectionId } from '@/redux/session/actions';

const CollectionRouter = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();

  useRtmCollection(collectionId);

  useEffect(() => {
    if (collectionId) {
      dispatch(setCollectionId(collectionId));
    }
    return () => {
      dispatch(setCollectionId(null));
    };
  }, [dispatch, collectionId]);

  return (
    <Routes>
      <Route path="/" element={<Collection />} />
      <Route path="/datasource" element={<Navigate to="/" />} />
      <Route path="/datasources/:datasourceId" element={<Datasource />} />
    </Routes>
  );
};

export default CollectionRouter;
