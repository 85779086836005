import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Disconnect = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      viewBox="0 0 24 24"
      className={`${styles.icon} ${disabledClass}`}
      width={size}
      height={size}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      shapeRendering="geometricPrecision"
    >
      <path d="M18.36 6.64a9 9 0 11-12.73 0M12 2v10" />
    </svg>
  );
};
export default Disconnect;
