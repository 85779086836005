import { Icon } from '@/models/icon';

const Integrations = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.667 5.513V2.82c0-1.06-.427-1.486-1.487-1.486h-2.693C9.427 1.333 9 1.76 9 2.82v2.693C9 6.573 9.427 7 10.487 7h2.693c1.06 0 1.487-.426 1.487-1.487ZM7 5.68V2.654c0-.94-.427-1.32-1.487-1.32H2.82c-1.06 0-1.487.38-1.487 1.32v3.02c0 .946.427 1.32 1.487 1.32h2.693C6.573 7 7 6.62 7 5.68ZM7 13.18v-2.693C7 9.427 6.573 9 5.513 9H2.82c-1.06 0-1.487.427-1.487 1.487v2.693c0 1.06.427 1.487 1.487 1.487h2.693C6.573 14.667 7 14.24 7 13.18Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        d="M9.667 11.666h4M11.667 13.666v-4"
      />
    </svg>
  );
};

export default Integrations;
