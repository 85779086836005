import { Icon } from '@/models/icon';

const SortIcon = ({
  size = 20,
  color = 'var(--icon-default-gray)',
  rotate,
}: Icon & { rotate?: boolean }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      width={size}
      height={size}
      style={{
        transform: rotate ? 'rotate(180deg) ' : '',
      }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.833 8.333 10 12.5l4.167-4.167H5.833Z" fill={color} />
    </svg>
  );
};

export default SortIcon;
