const Directory = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="67"
      height="57"
      viewBox="0 0 67 57"
    >
      <path
        fill="#DEE8F5"
        d="M44.612 2.378v3.09a1.981 1.981 0 0 1-1.981 1.98H2.866A1.981 1.981 0 0 0 .885 9.43v45.193a1.982 1.982 0 0 0 1.981 1.98h61.267a1.981 1.981 0 0 0 1.982-1.98V2.378A1.982 1.982 0 0 0 64.133.396H46.595a1.982 1.982 0 0 0-1.983 1.982Z"
      />
      <path fill="#fff" d="M34.41 20.915h-1.82V43.14h1.82V20.915Z" />
      <path fill="#fff" d="M44.613 31.117H22.388v1.82h22.225v-1.82Z" />
    </svg>
  );
};

export default Directory;
