import moment from 'moment';

import { BusinessHoursForm } from './BusinessHour';

type Days =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export const frequencyOptions = (t: (key: string) => string) => [
  {
    value: 'everyday',
    label: t('business_hours.frequency.everyday'),
  },
  {
    value: 'weekdays',
    label: t('business_hours.frequency.weekdays'),
  },
  {
    value: 'weekends',
    label: t('business_hours.frequency.weekends'),
  },
  {
    value: 'monday',
    label: t('business_hours.frequency.monday'),
  },
  {
    value: 'tuesday',
    label: t('business_hours.frequency.tuesday'),
  },
  {
    value: 'wednesday',
    label: t('business_hours.frequency.wednesday'),
  },
  {
    value: 'thursday',
    label: t('business_hours.frequency.thursday'),
  },
  {
    value: 'friday',
    label: t('business_hours.frequency.friday'),
  },
  {
    value: 'saturday',
    label: t('business_hours.frequency.saturday'),
  },
  {
    value: 'sunday',
    label: t('business_hours.frequency.sunday'),
  },
];

export const TIME_DROPDOWN_INTERVAL = 30;

/**
 * creates an array of time intervals of the form [{ label: '1:10 PM', value: '790' }, { label: '1:20 PM', value: '800' }]
 * @param interval - the interval in minutes, e.g. 10minutes
 * @param addMidnightValue - if true, the array will include the '11:59 PM' label and correspoding value
 */
export const timeIntervalsMaker = (
  interval: number,
  addMidnightValue = false
): { label: string; value: string }[] => {
  const times = Array.from(
    {
      length: (24 * 60) / interval,
    },
    (v, index) => {
      let minuteString = '';
      let timeDifferenceFromMidnight = 0;

      let hour = Math.floor((index * interval) / 60);

      const minute = index * interval - hour * 60;

      if (minute < 10) {
        minuteString = `0${minute}`;
      }

      timeDifferenceFromMidnight = hour * 60 + minute;

      let label = 'am';
      if (hour >= 12) {
        label = 'pm';
        hour -= 12;
      }
      if (hour === 0) {
        hour = 12;
      }

      const time = `${hour}:${minuteString || minute} ${label}`;

      return {
        label: time,
        value: `${timeDifferenceFromMidnight}`,
      };
    }
  );

  if (addMidnightValue) {
    times.push({ label: '11:59 PM', value: '1439' });
  }

  return times;
};

/**
 * helper function for creating a base for the time interval
 */
export const intervalBase = (frequency: Days): number => {
  switch (frequency) {
    case 'monday':
      return 0;
    case 'tuesday':
      return 1440;
    case 'wednesday':
      return 2880;
    case 'thursday':
      return 4320;
    case 'friday':
      return 5760;
    case 'saturday':
      return 7200;
    case 'sunday':
      return 8640;
    default:
      return 0;
  }
};

/**
 * converts user input into an array of time intervals for business hours
 * example: Monday 9:00 AM to 5:00 PM -> [{ start: 540, end: 1020}]
 */
export const extractIntervalsFromHours = (
  hours
): { start: number; end: number }[] => {
  const intervals = hours.reduce((acc, hour) => {
    const { from, to, frequency } = hour;
    const frequencyValue = frequency.value;
    const fromTime = Number(from.value);
    const toTime = Number(to.value);

    if (frequency.value === 'weekdays') {
      const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
      weekdays.forEach((day: Days) => {
        const base = intervalBase(day);
        const interval = { start: base + fromTime, end: base + toTime };
        acc.push(interval);
      });
      return acc;
    }

    if (frequency.value === 'weekends') {
      const weekends = ['saturday', 'sunday'];
      weekends.forEach((day: Days) => {
        const base = intervalBase(day);
        const interval = { start: base + fromTime, end: base + toTime };
        acc.push(interval);
      });
      return acc;
    }

    if (frequency.value === 'everyday') {
      const everyday = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ];
      everyday.forEach((day: Days) => {
        const base = intervalBase(day);
        const interval = { start: base + fromTime, end: base + toTime };
        acc.push(interval);
      });
      return acc;
    }

    const base = intervalBase(frequencyValue);
    const interval = { start: base + fromTime, end: base + toTime };
    return [...acc, interval];
  }, []);

  return intervals;
};

/**
 * helper function
 */
const compareTimeIntervals = (a, b) => {
  if (a.start < b.start) {
    return -1;
  }
  if (a.start > b.start) {
    return 1;
  }
  return 0;
};

/**
 * converts minutes to hour form e.g 590 -> '1:30 PM'
 */
export const minutesToHours = (minutes: number) => {
  let hour = Math.floor(minutes / 60);
  let label = 'am';
  if (hour >= 12) {
    label = 'pm';
    hour -= 12;
  }
  if (hour === 0) {
    hour = 12;
  }
  const minutesLeft = minutes % 60;
  const hours = `${hour}:${
    minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft
  } ${label}`;
  return hours;
};

/**
 * helper function
 */
const minutesToDay = (minutes: number): string => {
  if (minutes >= 0 && minutes < 1440) {
    return 'monday';
  }
  if (minutes >= 1440 && minutes < 2880) {
    return 'tuesday';
  }
  if (minutes >= 2880 && minutes < 4320) {
    return 'wednesday';
  }
  if (minutes >= 4320 && minutes < 5760) {
    return 'thursday';
  }
  if (minutes >= 5760 && minutes < 7200) {
    return 'friday';
  }
  if (minutes >= 7200 && minutes < 8640) {
    return 'saturday';
  }
  if (minutes >= 8640 && minutes < 10080) {
    return 'sunday';
  }
};

/**
 * helper function
 */
const capitalizeFirstLetter = (str: string) => {
  if (!str) {
    return '';
  }
  return str[0].toUpperCase() + str.slice(1);
};

/**
 * converts orchestrators time intervals to business hours
 */
export const intervalsObjectToHours = (
  intervals: { start: number; end: number }[]
) => {
  const hours = [];
  const newIntervals = [...intervals];
  newIntervals.sort(compareTimeIntervals);

  // WEEKENDS
  if (
    newIntervals.length === 2 &&
    newIntervals[0].start >= 7200 &&
    newIntervals[0].start === newIntervals[1].start - 1440 &&
    newIntervals[0].end === newIntervals[1].end - 1440
  ) {
    hours.push({
      frequency: { label: 'Weekends', value: 'weekends' },
      from: {
        label: minutesToHours(newIntervals[0].start - 7200),
        value: `${newIntervals[0].start - 7200}`,
      },
      to: {
        label: minutesToHours(newIntervals[0].end - 7200),
        value: `${newIntervals[0].end - 7200}`,
      },
    });
    return hours;
  }

  //WEEKDAYS
  if (
    newIntervals.length === 5 &&
    newIntervals[0].start >= 0 &&
    newIntervals[4].end < 7200 &&
    newIntervals[0].start === newIntervals[1].start - 1440 &&
    newIntervals[0].end === newIntervals[1].end - 1440 &&
    newIntervals[1].start === newIntervals[2].start - 1440 &&
    newIntervals[1].end === newIntervals[2].end - 1440 &&
    newIntervals[2].start === newIntervals[3].start - 1440 &&
    newIntervals[2].end === newIntervals[3].end - 1440 &&
    newIntervals[3].start === newIntervals[4].start - 1440 &&
    newIntervals[3].end === newIntervals[4].end - 1440
  ) {
    hours.push({
      frequency: { label: 'Weekdays', value: 'weekdays' },
      from: {
        label: minutesToHours(newIntervals[0].start),
        value: `${newIntervals[0].start}`,
      },
      to: {
        label: minutesToHours(newIntervals[0].end),
        value: `${newIntervals[0].end}`,
      },
    });
    return hours;
  }

  //EVERYDAY
  if (
    newIntervals.length === 7 &&
    newIntervals[0].start >= 0 &&
    newIntervals[6].end < 10080 &&
    newIntervals[0].start === newIntervals[1].start - 1440 &&
    newIntervals[0].end === newIntervals[1].end - 1440 &&
    newIntervals[1].start === newIntervals[2].start - 1440 &&
    newIntervals[1].end === newIntervals[2].end - 1440 &&
    newIntervals[2].start === newIntervals[3].start - 1440 &&
    newIntervals[2].end === newIntervals[3].end - 1440 &&
    newIntervals[3].start === newIntervals[4].start - 1440 &&
    newIntervals[3].end === newIntervals[4].end - 1440 &&
    newIntervals[4].start === newIntervals[5].start - 1440 &&
    newIntervals[4].end === newIntervals[5].end - 1440 &&
    newIntervals[5].start === newIntervals[6].start - 1440 &&
    newIntervals[5].end === newIntervals[6].end - 1440
  ) {
    hours.push({
      frequency: { label: 'Everyday', value: 'everyday' },
      from: {
        label: minutesToHours(newIntervals[0].start),
        value: `${newIntervals[0].start}`,
      },
      to: {
        label: minutesToHours(newIntervals[0].end),
        value: `${newIntervals[0].end}`,
      },
    });
    return hours;
  }

  intervals.forEach((interval) => {
    const day = minutesToDay(interval.start) as Days;
    const minutesStart = interval.start - intervalBase(day);
    const minutesEnd = interval.end - intervalBase(day);
    hours.push({
      frequency: { label: capitalizeFirstLetter(day), value: day },
      from: {
        label: minutesToHours(minutesStart),
        value: `${minutesStart}`,
      },
      to: { label: minutesToHours(minutesEnd), value: `${minutesEnd}` },
    });
  });
  return hours;
};

/**
 * converts Date Object to day of year
 */
export const daysIntoYear = (arg) => {
  if (!arg) {
    return null;
  }
  return moment(new Date(arg)).dayOfYear();
};

/**
 * converts days of year to Date object
 */
export const getDateFromDayOfYear = (day: number) => {
  return new Date(Date.UTC(moment().year(), 0, day));
};

/**
 * converts react hook form holidays to business hours
 */
export const transformHolidaysToOrchestrator = (
  values: Pick<BusinessHoursForm, 'holidays'>
) => {
  if (!values.holidays) {
    return [];
  }
  return values.holidays.map((holiday) => {
    return {
      name: holiday.holidayName,
      start: daysIntoYear(holiday.dates.startDate),
      end:
        daysIntoYear(holiday.dates.endDate) ||
        daysIntoYear(holiday.dates.startDate),
    };
  });
};

export const DEFAULT_HOURS = {
  frequency: { label: 'Weekdays', value: 'weekdays' },
  from: { label: '9:00 am', value: '540' },
  to: { label: '5:00 pm', value: '1020' },
};
