import { Icon } from '@/models/icon';

const CheckboxSelected = ({
  size = 16,
  color = 'var(--icon-default-blue)',
}: Icon) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
    >
      <rect x="2" y="2" width="12" height="12" rx="2" fill={color} />
      <path
        d="M4.962 8.4 6.663 10l4.253-4"
        stroke="var(--icon-default-white)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckboxSelected;
