import Box from '@mui/material/Box';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useTemplateMessage } from '@/modules/humanChat/hooks/useTemplateMessage';
import { getPermissions } from '@/redux/permissions/selectors';

import { useChatBox } from './useChatBox';
import FileUploadChatBox from '../FileUploadChatBox/FileUploadChatBox';

import styles from './ChatBox.module.scss';

const ChatBox = () => {
  const {
    isClosed,
    isLoading,
    isUnavailable,
    setFiles,
    setUrls,
    files,
    uploading,
    setUploading,
    totalFiles,
  } = useChatBox();
  const { t } = useTranslation();
  const { handleClick: handleTemplateClick, showTemplateMessage } =
    useTemplateMessage();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );

  return (
    <>
      {showTemplateMessage && (
        <Box mx={2} className={styles.templateBanner}>
          <Banner
            variant="info"
            customDismiss="Browse"
            customDismissAction={handleTemplateClick}
            relativePosition
            hasIcon={true}
          >
            {t('chatBox.template_message')}
          </Banner>
        </Box>
      )}
      <section
        className={cn(styles.chatBox, {
          [styles.isUnavailable]: isUnavailable || showTemplateMessage,
          [styles.isLoading]: isLoading,
          [styles.isClosed]: isClosed,
        })}
      >
        <form
          className={cn(styles.body, {
            [styles.readOnly]: !canWrite,
          })}
        >
          <FileUploadChatBox
            stream
            setFiles={setFiles}
            setUrls={setUrls}
            files={files}
            uploading={uploading}
            setUploading={setUploading}
            // FIXME: This is a hack to make the file upload work
            // accept={acceptedFiles}
            totalFiles={totalFiles}
          />
        </form>
      </section>
    </>
  );
};

export default ChatBox;
