import { useCallback, useEffect, useMemo } from 'react';

import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import Input from '@/components/atoms/Input/Input';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import { AccordionFieldsetProps, FrontIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import SelectUser from '@/modules/humanChat/components/SelectUser/SelectUser';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import {
  errorMessage,
  frontIntegrationConfig,
  removeEmptyFields,
} from '@/util/validator';

import styles from './FrontMode.module.scss';

type Form = {
  mode: 'agent' | 'system';
  teammate_id: string;
  sender_name: string;
};

const FrontMode = ({
  type,
  integration,
  registerAccordion,
  toggleAccordion,
}: AccordionFieldsetProps<Form, FrontIntegration>) => {
  const { t } = useTranslation();

  const { updateIntegration, updateStatus } = useIntegrations<FrontIntegration>(
    integration?.desk_id,
    integration?.integration_id
  );
  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const teammates = integration?.config?.teammates;

  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const dispatch = useDispatch();

  const {
    reset,
    control,
    register,
    formState: { isDirty, errors },
    handleSubmit,
    trigger,
  } = formMethods;

  const { field: mode } = useController({
    name: 'mode',
    control,
  });

  const { field: teammate_id } = useController({
    name: 'teammate_id',
    control,
    rules: frontIntegrationConfig.teammate_id,
  });

  useEffect(() => {
    reset({
      mode: integration?.config?.mode,
      sender_name: integration?.config?.sender_name || '',
      teammate_id: integration?.config?.teammate_id || '',
    });
  }, [
    reset,
    integration?.config?.mode,
    integration?.config?.teammate_id,
    integration?.config?.sender_name,
  ]);

  const handleUpdateIntegration = useCallback(
    (values: Form) => {
      const config = removeEmptyFields(values);
      updateIntegration(
        {
          ...integration,
          config: {
            ...integration.config,
            ...config,
          },
        },
        {
          onSuccess: () => {
            if (expanded === type) {
              dispatch(setExpanded(false));
            }
          },
        }
      );
    },
    [dispatch, expanded, integration, type, updateIntegration]
  );

  usePrompt(
    isDirty,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  const onModeChange = useCallback(
    (event) => mode.onChange(event.target.value),
    [mode]
  );

  const onTeammateChange = useCallback(
    (index: number) => teammate_id.onChange(teammates?.[index]?.id),
    [teammates, teammate_id]
  );

  const modeOptions = useMemo(
    () => [
      {
        id: 'agent-mode',
        value: 'agent',
        label: (
          <TitleSubtitle
            title={t('integrations.front.mode.agent.title')}
            subtitle={t('integrations.front.mode.agent.subtitle')}
            noGutters
          />
        ),
      },
      {
        id: 'system-mode',
        value: 'system',
        label: (
          <TitleSubtitle
            title={t('integrations.front.mode.system.title')}
            subtitle={t('integrations.front.mode.system.subtitle')}
            noGutters
          />
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  return (
    <>
      <Accordion
        title={t('integrations.front.mode.title')}
        subtitle={t('integrations.front.mode.subtitle')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
        readOnly={!canWrite}
      >
        <div className={styles.radioGroup}>
          <OptionRadioButton
            key={`${modeOptions[0].id}-${mode.value}`}
            option={modeOptions[0]}
            size="medium"
            onChange={onModeChange}
            isSelected={mode.value === 'agent'}
            disableSelectedStyles
            disabled={!canWrite}
          />
          <OptionRadioButton
            key={`${modeOptions[1].id}-${mode.value}`}
            option={modeOptions[1]}
            size="medium"
            onChange={onModeChange}
            isSelected={mode.value === 'system'}
            disableSelectedStyles
            disabled={!canWrite}
          />
        </div>
        {mode.value === 'system' && (
          <>
            <TitleSubtitle
              title={t('integrations.front.sender_name.title')}
              subtitle={t('integrations.front.sender_name.subtitle')}
            />
            <Input
              id="sender_name"
              name="sender_name"
              placeholder={t('integrations.front.sender_name.placeholder')}
              size="large"
              register={register(
                'sender_name',
                frontIntegrationConfig.sender_name
              )}
              trimValue
              readOnly={!canWrite}
              errorMessage={
                errorMessage({
                  field: errors.sender_name,
                }) || null
              }
            />
          </>
        )}
        {mode.value === 'agent' && (
          <>
            <TitleSubtitle
              title={t('integrations.front.teammate.title')}
              subtitle={t('integrations.front.teammate.subtitle')}
            />
            <SelectUser
              activeIndex={teammates?.findIndex(
                (t) => t.id === teammate_id.value
              )}
              type="members"
              options={teammates}
              onSelect={onTeammateChange}
            />
          </>
        )}
      </Accordion>
    </>
  );
};

export default FrontMode;
