import Typography from '@mui/material/Typography';

interface Props {
  children: React.ReactNode;
}
const FieldsetFooterStatus: React.FC<Props> = ({ children }: Props) => {
  return (
    <Typography variant="subtitle2" className="fieldset__footer__status">
      {children}
    </Typography>
  );
};

export default FieldsetFooterStatus;
