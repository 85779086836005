import { Icon } from '@/models/icon';

const Ex = ({ size = 16, color = 'var(--icon-default-error)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="m12 4-8 8M4 4l8 8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Ex;
