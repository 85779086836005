import { useCallback, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import DropdownButton from '@/modules/rules/components/DropdownButton/DropdownButton';
import { removeTrigger } from '@/modules/rules/redux/actions';
import {
  selectTriggerOptions,
  selectRuleTriggers,
} from '@/modules/rules/redux/selectors';
import { getPermissions } from '@/redux/permissions/selectors';

import OperatorMenu from './OperatorMenu';
import TriggerOptionsModal from '../DropdownButton/TriggerOptionsModal';

import styles from './Triggers.module.scss';

type TriggerEvent = string;

export type TriggerEvents = TriggerEvent[];

const Triggers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );

  const [showTriggerOptions, setShowTriggerOptions] = useState(false);
  const triggers = useSelector(selectRuleTriggers);
  const options = useSelector(selectTriggerOptions);
  const showAddTrigger = triggers.length > 0 ? false : true;
  const addModalRef = useRef<HTMLDivElement | null>(null);
  const handleClose = useCallback(
    (trigger) => {
      dispatch(removeTrigger(trigger));
    },
    [dispatch]
  );

  const handleAddTrigger = useCallback(() => {
    setShowTriggerOptions(true);
  }, []);

  return (
    <>
      {showAddTrigger && (
        <Typography
          component="div"
          className={styles.interactive}
          role="button"
          onClick={handleAddTrigger}
          onKeyDown={() => {}}
          tabIndex={0}
          variant="body-semi-bold"
          color="var(--text-default-blue)"
        >
          {t('rules.add_trigger')}
        </Typography>
      )}
      {showTriggerOptions && (
        <TriggerOptionsModal
          options={options}
          ref={addModalRef}
          index={0}
          handleOptionClick={() => setShowTriggerOptions(false)}
        />
      )}
      {!showAddTrigger && (
        <div
          className={cn(styles.outerWrapper, {
            [styles.readOnly]: !canWrite,
          })}
        >
          {triggers.map((trigger, index) => {
            const { type, delay_in_seconds } = trigger;
            return (
              <div className={styles.innerWrapper} key={type}>
                {index !== 0 && <OperatorMenu operator="or" disabled />}
                <DropdownButton
                  closeButtonCallbackFn={() => handleClose(trigger)}
                  grouped={
                    triggers.length - 1 === index &&
                    options.length - 1 !== index
                  }
                  index={index}
                  triggerType={type}
                  delay_in_seconds={String(delay_in_seconds)}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Triggers;
