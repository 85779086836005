import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import Input from '@/components/atoms/Input/Input';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import { AccordionFieldsetProps, ViberIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { getDocsUrl } from '@/util/constants';
import { errorMessage, integrationRulesViber } from '@/util/validator';

import styles from './ViberConnect.module.scss';

type Form = {
  access_token: string;
  name: string;
};

const ViberConnect = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, ViberIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const { updateIntegration, updateStatus } = useIntegrations<ViberIntegration>(
    integration?.desk_id,
    integration?.integration_id
  );
  const dispatch = useDispatch();

  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    trigger,
  } = formMethods;

  useEffect(() => {
    reset({
      access_token: integration?.config?.access_token || '',
      name: integration?.config?.name || '',
    });
  }, [integration?.config?.name, integration?.config?.access_token, reset]);

  const handleUpdateIntegration = useCallback(() => {
    const { access_token, name } = getValues();
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration.config,
          access_token,
          name,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, getValues, integration, type, updateIntegration]);

  usePrompt(
    isDirty,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [reset, expanded, isDirty, type]);

  return (
    <>
      <Accordion
        title={t('integrations.viber_connect.title')}
        subtitle={t('integrations.viber_connect.subtitle')}
        footerText={t('integrations.viber_connect.footer_text')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
        readOnly={!canWrite}
      >
        <TitleSubtitle
          title={t('integrations.viber_connect.access_token.title')}
          subtitle={t('integrations.viber_connect.access_token.subtitle')}
          externalLink={{
            label: t('integrations.viber_connect.access_token.external_link'),
            url: getDocsUrl('/docs/integrations/viber'),
          }}
        />
        <div className={styles.textArea}>
          <Input
            id="access_token"
            name="access_token"
            placeholder={t(
              'integrations.viber_connect.access_token.placeholder'
            )}
            size="large"
            register={register('access_token', {
              required: {
                value: true,
                message: t('validation.required'),
              },
              maxLength: {
                value: 64,
                message: t('validation.max_length', { max: 64 }),
              },
            })}
            trimValue
            readOnly={!canWrite}
            errorMessage={
              (errorMessage({
                field: errors.access_token,
                maxLength: integrationRulesViber.accessToken.maxLength,
              }) &&
                errors.access_token.message) ||
              null
            }
          />
        </div>

        <TitleSubtitle
          title={t('integrations.viber_connect.name.title')}
          subtitle={t('integrations.viber_connect.name.subtitle')}
        />
        <div className={styles.textArea}>
          <Input
            id="name"
            name="name"
            placeholder={t('integrations.viber_connect.name.placeholder')}
            size="large"
            readOnly={!canWrite}
            register={register('name', {
              required: {
                value: true,
                message: t('validation.required'),
              },
              maxLength: {
                value: 28,
                message: t('validation.max_length', { max: 28 }),
              },
            })}
            trimValue
            errorMessage={
              (errorMessage({
                field: errors.name,
                maxLength: integrationRulesViber.name.maxLength,
              }) &&
                errors.name.message) ||
              null
            }
          />
        </div>
      </Accordion>
    </>
  );
};

export default ViberConnect;
