import { Icon } from '@/models/icon';

const Privacy = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
      stroke={color}
      strokeLinecap="round"
    >
      <path
        strokeLinejoin="round"
        d="M8 14.667S13.333 12 13.333 8V3.333L8 1.333l-5.333 2V8C2.667 12 8 14.667 8 14.667Z"
      />
      <path d="M5.7 8.156 7.518 10.2 10.7 5.6" />
    </svg>
  );
};

export default Privacy;
