import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import Lightbulb from '@/components/atoms/Icons/Lightbulb';
import { skipAppVersion } from '@/redux/appVersion/actions';

import { removeToast } from '../redux/actions';

import styles from './Notification.module.scss';

const NewVersionNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleRefresh = () => {
    navigate(0);
  };
  const handleLater = () => {
    dispatch(removeToast('update'));
    dispatch(skipAppVersion());
  };

  return (
    <Alert
      icon={false}
      severity="info"
      className={cn(styles.alert, styles.isUpdate)}
    >
      <div className={styles.update_container}>
        <div className={styles.update_icon}>
          <Lightbulb size={16} color="var(--icon-default-blue)" />
        </div>

        <div className={styles.update_body}>
          <div className={styles.update_text_box}>
            <Typography variant={'body2'} className={styles.update_label}>
              {t('update_notification.title')}
            </Typography>
            <Typography className={styles.update_subtitle}>
              {t('update_notification.subtitle')}
            </Typography>
          </div>

          <div className={styles.update_button_field}>
            <Button size="xs" variant="tertiary" onClick={handleLater}>
              {t('update_notification.button2')}
            </Button>
            <Button size="xs" variant="secondary" onClick={handleRefresh}>
              {t('update_notification.button1')}
            </Button>
          </div>
        </div>
      </div>
    </Alert>
  );
};
export default NewVersionNotification;
