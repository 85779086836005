import Facebook from '@/components/atoms/Icons/Facebook';
import Viber from '@/components/atoms/Icons/Viber';
import Whatsapp from '@/components/atoms/Icons/Whatsapp';
import Zendesk from '@/components/atoms/Icons/Zendesk';

import Front from './Front';
import IconInstagram from './Instagram';
import IconIntercom from './Intercom';
import IconLiveperson from './LivePerson';
import IconSlack from './Slack';
import IconSunco from './Sunco';
import IconTelegram from './Telegram';
import IconWebWidget from './WebWidget';

export const integrationIconByType = {
  viber: Viber,
  facebook: Facebook,
  slack: IconSlack,
  whatsapp: Whatsapp,
  zendesk: Zendesk,
  liveperson: IconLiveperson,
  intercom: IconIntercom,
  web: IconWebWidget,
  telegram: IconTelegram,
  instagram: IconInstagram,
  sunco: IconSunco,
  front: Front,
};
