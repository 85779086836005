import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useTitle } from 'react-use';

import Loading from '@/components/atoms/Loading/Loading';
import NotFound from '@/components/pages/NotFound/NotFound';
import { useAccount } from '@/hooks/useAccount';
import { selectIsTempUser, selectUserId } from '@/redux/user/selectors';

/**
 * Loads the accounts and redirects to the first one.
 */
const Accounts = () => {
  const { t } = useTranslation();
  useTitle(t('pages.accounts'));
  const userId = useSelector(selectUserId);

  const { accounts, accountsStatus, lastUsedAccountSlug } = useAccount();
  const isTempUser = useSelector(selectIsTempUser);

  if (isTempUser) {
    return <Navigate to="/signup/welcome" />;
  }

  // We need the userId to be loaded before checking  the lastUsedAccountSlug
  if (!userId || !accounts || accountsStatus === 'pending') {
    return <Loading />;
  }
  if (accounts && accounts.length > 0) {
    return (
      <Navigate to={`/${lastUsedAccountSlug ?? accounts[0]?.slug}/`} replace />
    );
  }

  // Re-direct user to the invitations page if there are no accounts
  if (accounts.length === 0) {
    return <Navigate to="/profile/invitations" replace />;
  }

  return <NotFound />;
};

export default Accounts;
