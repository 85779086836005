import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { Expressions } from '@/models/expressions';

const notById = (id) => (i) => i !== id;
const toObject = (acc, e) => {
  acc[e.id] = e;
  return acc;
};

const addId = (e) => ({ id: uuidv4(), text: e });

const defaultState: Expressions = {
  byId: {},
  allIds: null,
  intentName: null,
  previousName: null,

  dirty: false,
};

const slice = createSlice({
  name: 'expressions',
  initialState: defaultState,
  reducers: {
    setIntent: (state, action) => {
      const { intent, expressions } = action.payload;
      const all = expressions.map(addId);

      state.byId = all.reduce(toObject, {});
      state.allIds = all.map((e) => e.id);
      state.intentName = intent;
      state.dirty = false;
    },

    createExpression: (state, action) => {
      const { newText } = action.payload;
      const id = uuidv4();

      state.allIds = [id, ...(state.allIds || [])];
      state.byId[id] = { id, text: newText };
      state.dirty = true;
    },

    updateExpression: (state, action) => {
      const { newText, id } = action.payload;

      state.byId[id] = { id, text: newText };
      state.dirty = true;
    },
    appendExpressions: (state, action) => {
      const { newExpressions } = action.payload;

      newExpressions.forEach((expression) => {
        const id = uuidv4();
        state.allIds = [id, ...(state.allIds || [])];
        state.byId[id] = { id, text: expression };
        state.dirty = true;
      });
    },

    deleteExpression: (state, action) => {
      const { id } = action.payload;

      delete state.byId[id];
      state.allIds = state.allIds.filter(notById(id));
      state.dirty = true;
    },

    updateIntentData: (state, action) => {
      const { new_name, previousName } = action.payload;
      state.previousName = previousName;
      state.intentName = new_name;
      state.dirty = true;
    },

    importExpressions: (state, action) => {
      const expressions = action.payload;
      const all = expressions.map(addId);

      state.byId = all.reduce(toObject, {});
      state.allIds = all.map((e) => e.id);
      state.dirty = true;
    },

    clearIntentExpressions: () => defaultState,
  },
});

// Extract and export each action creator by name
export const {
  setIntent,
  updateIntentData,
  createExpression,
  deleteExpression,
  updateExpression,
  importExpressions,
  clearIntentExpressions,
  appendExpressions,
} = slice.actions;

// Export the reducer, either as a default or named export
export default slice.reducer;
