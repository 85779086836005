export enum DepartmentRouting {
  MANUAL = 'manual',
  ROUND_ROBIN = 'round-robin',
  BALANCED = 'balanced',
}

export type Department = {
  department_id: string;
  desk_id: string;
  icon: string;
  name: string;
  routing: DepartmentRouting;
  business_hours_id: string;
};

export type DepartmentUser = {
  department_id: string;
  user_id: string;
};

export type DepartmentUsers = {
  users: DepartmentUser[];
};

export type Departments = {
  departments: Department[];
};

export type Form = {
  name: string;
  icon: string;
  users: DepartmentUser[];
  routing: DepartmentRouting;
};
