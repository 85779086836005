import { Icon } from '@/models/icon';

const Question = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M7.414 15.547c0 1.205-.282 2.793 1.609 2.793h8.925a.99.99 0 0 1 .596.2l4.017 3.007V10.513c0-2.164-.058-3.03-1.357-4.114-1.144-.955-2.048-.802-3.731-.802"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M7.876 6.4s.43-.875 1.207-1.185c.882-.353 1.951-.029 2.457.776.47.748.413 1.814-.216 2.436-.628.621-1.595.652-1.595 1.293v.905m-7.565 7.393V3.965c0-1.077 1.014-1.918 2.09-1.918 4.14 0 7.803.065 11.467.065 1.297 0 1.552 1.044 1.552 1.853v8.665c0 1.207-.209 2.177-1.552 2.177H6.783a.99.99 0 0 0-.596.2l-4.023 3.011ZM10.681 15.063h8.759"
      />
      <path
        fill={color}
        d="M18.182 11.743h-.75v1.5h.75v-1.5Zm1.393 1.5a.75.75 0 0 0 0-1.5v1.5Zm-1.393 0h1.393v-1.5h-1.393v1.5ZM18.182 8.958h-.75v1.5h.75v-1.5Zm1.393 1.5a.75.75 0 0 0 0-1.5v1.5Zm-1.393 0h1.393v-1.5h-1.393v1.5Z"
      />
      <circle cx="9.824" cy="12.493" r=".696" fill={color} />
    </svg>
  );
};

export default Question;
