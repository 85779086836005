import { useCallback, useRef, useState } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import Search from '@/components/atoms/Icons/Search';
import Input from '@/components/atoms/Input/Input';
import { OptionBase } from '@/models/common';

import styles from './SearchBar.module.scss';

type SearchOption = OptionBase;

interface SearchBarProps {
  options?: Array<SearchOption>;
  setFilter?: (filter: string) => void;
  onClick?: (option: SearchOption) => void;
  showAllOptions?: boolean;
  alwaysExpanded?: boolean;
  className?: string;
}

/**
 * @param options Uses the options array to create a dropdown
 * @param setFilter Uses the setFilter action to filter the components to render
 */
function SearchBar({
  options,
  setFilter,
  onClick,
  showAllOptions = false,
  className,
  alwaysExpanded,
}: SearchBarProps) {
  const { t } = useTranslation();
  const targetRef = useRef(null);
  const buttonRef = useRef(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const showSearchInput =
    alwaysExpanded || isHovered || isFocused || searchFilter;
  const showOptions = showAllOptions || searchFilter;

  const handleClick = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <div
      className={cn(
        className,
        styles.container,
        `${showSearchInput ? styles.expanded : ''}`
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {showSearchInput && (
        <Input
          iconRight={<Search />}
          ref={targetRef}
          placeholder={t('common.search_dots')}
          onBlur={() => {
            setIsHovered(false);
          }}
          onChange={(event) => {
            setSearchFilter(event.target.value.toLowerCase());
            if (setFilter) {
              setFilter(event.target.value.toLowerCase());
            }
          }}
        />
      )}
      {showSearchInput && options && showOptions && (
        <div className={styles.option_wrapper}>
          {options
            .filter((option) =>
              option.label.toLowerCase().includes(searchFilter)
            )
            .map((option) => (
              <button
                key={option.value}
                className={styles.option_button}
                onClick={() => onClick(option)}
              >
                {option.label}
              </button>
            ))}
          {(searchFilter === '' && !showAllOptions && (
            <div className={styles.empty}> {t('brains.no_filter')}</div>
          )) ||
            (isEmpty(
              options.filter((option) =>
                option.label.toLowerCase().includes(searchFilter)
              )
            ) && <div className={styles.empty}> {t('brains.no_result')}</div>)}
        </div>
      )}
      {!showSearchInput && (
        <button
          ref={buttonRef}
          className={styles.search_button}
          onClick={handleClick}
          onMouseEnter={() => setIsHovered(true)}
        >
          <Search></Search>
        </button>
      )}
    </div>
  );
}

export default SearchBar;
