import { useCallback, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Box from '@mui/system/Box';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import { useAccount } from '@/hooks/useAccount';
import { useAllRules } from '@/hooks/useAllRules';
import { useBundles } from '@/modules/bundles/hooks/useBundles';
import { Rule } from '@/modules/rules/model';
import { popModal } from '@/redux/modals/actions';

import ModalDelete from '../../../components/organisms/Modals/ModalDelete';
import ModalWarn from '../../../components/organisms/Modals/ModalWarn';

const ModalBundleDelete = ({ bundleId, deskId, name }: Props) => {
  const navigate = useNavigate();
  const { deleteBundle } = useBundles(deskId, bundleId);
  const dispatch = useDispatch();
  const { status, getRulesConnectedToBundle } = useAllRules();
  const { slug } = useAccount();

  const handleDelete = useCallback(() => {
    deleteBundle(bundleId, {
      onSettled: () => {
        dispatch(popModal());
      },
    });
  }, [bundleId, deleteBundle, dispatch]);

  const subtitle = <Trans i18nKey="bundles.delete_warning" values={[name]} />;

  const connectedRules = useMemo(
    () => getRulesConnectedToBundle(bundleId),
    [bundleId, getRulesConnectedToBundle]
  );

  if (status !== 'pending' && connectedRules.length === 0) {
    return <ModalDelete confirm subtitle={subtitle} onDelete={handleDelete} />;
  }

  return (
    <ModalWarn>
      {status === 'pending' ? (
        <Box
          sx={{
            height: '80px',
            width: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Banner<Partial<Rule>>
          relativePosition
          variant="critical"
          referenceProp="rule_id"
          references={connectedRules}
          labelKey="name"
          onRefClick={(rule_id) => {
            dispatch(popModal());
            navigate(`/${slug}/environments/${deskId}/rules/${rule_id}`);
          }}
        >
          <Trans
            i18nKey={'bundles.delete_warning_not_empty'}
            values={{ bundle: name }}
          />
        </Banner>
      )}
    </ModalWarn>
  );
};

export default ModalBundleDelete;

type Props = {
  bundleId: string;
  deskId: string;
  name: string;
};
