import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PlainFooterFieldset from '@/components/atoms/Fieldset/templates/PlainFooterFieldset';
import { selectIframeView } from '@/redux/user/selectors';

interface Props {
  onAuthClick: () => void;
  disabled?: boolean;
}

const IntercomRedirectFieldset = ({ onAuthClick, disabled }: Props) => {
  const { t } = useTranslation();
  const isIframeView = useSelector(selectIframeView);
  return (
    <PlainFooterFieldset
      title={t('integrations.intercom.redirect_title')}
      subtitle={
        isIframeView
          ? t('integrations.intercom.redirect_subtitle').replace(
              'Moveo needs',
              'We need'
            )
          : t('integrations.intercom.redirect_subtitle')
      }
      primaryButton={{
        children: t('integrations.intercom.redirect_connect'),
        onClick: onAuthClick,
        disabled,
      }}
      fullContentWidth
    ></PlainFooterFieldset>
  );
};

export default IntercomRedirectFieldset;
