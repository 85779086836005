import { useQueryClient, QueryClient } from '@tanstack/react-query';

import {
  brainsEndpoints,
  businessHoursEndpoints,
  collectionsEndpoints,
  datasourceEndpoints,
  desksEndpoints,
  dialogsEndpoints,
  entitiesEndpoints,
  integrationsEndpoints,
  intentsEndpoints,
  rulesEndpoints,
  versionsEndpoints,
  webhooksEndpoints,
  bundlesEndpoints,
  broadcastsEndpoints,
} from '@/api/endpoints';
import {
  onBrainRemoved,
  onBrainCreated,
  onBrainUpdated,
  API as brainAPI,
} from '@/hooks/useBrains';
import {
  API as businessHoursAPI,
  onBusinessHourCreated,
  onBusinessHourUpdated,
  onBusinessHourRemoved,
} from '@/hooks/useBusinessHours';
import {
  onCollectionRemoved,
  onCollectionCreated,
  onCollectionUpdated,
  API as collectionAPI,
} from '@/hooks/useCollections';
import {
  onDatasourceRemoved,
  onDatasourceCreated,
  onDatasourceUpdated,
  API as datasourceAPI,
} from '@/hooks/useDatasources';
import {
  onDeskRemoved,
  onDeskCreated,
  onDeskUpdated,
  API as deskAPI,
} from '@/hooks/useDesks';
import {
  API as dialogAPI,
  onDialogCreated,
  onDialogUpdated,
  onDialogRemoved,
} from '@/hooks/useDialogs';
import {
  API as entityAPI,
  onEntityCreated,
  onEntityUpdated,
  onEntityRemoved,
} from '@/hooks/useEntities';
import {
  API as integrationAPI,
  onIntegrationCreated,
  onIntegrationUpdated,
  onIntegrationRemoved,
} from '@/hooks/useIntegrations';
import {
  API as intentAPI,
  onIntentCreated,
  onIntentUpdated,
  onIntentRemoved,
} from '@/hooks/useIntents';
import {
  API as versionAPI,
  onVersionCreated,
  onVersionRemoved,
} from '@/hooks/useVersions';
import {
  onWebhookRemoved,
  onWebhookCreated,
  onWebhookUpdated,
  API as webhookAPI,
} from '@/hooks/useWebhooks';
import { BusinessHour } from '@/models/businessHours';
import { Dialog } from '@/models/dialog';
import { Entity } from '@/models/entity';
import { Intent } from '@/models/intent';
import { Module } from '@/models/permissions';
import {
  onBroadcastRemoved,
  onBroadcastCreated,
  onBroadcastUpdated,
  API as broadcastAPI,
} from '@/modules/broadcast/hooks/useBroadcasts';
import {
  API as bundlesAPI,
  onBundleCreated,
  onBundleUpdated,
  onBundleRemoved,
} from '@/modules/bundles/hooks/useBundles';
import { Bundle } from '@/modules/bundles/models';
import {
  API as ruleAPI,
  onRuleCreated,
  onRuleUpdated,
  onRuleRemoved,
} from '@/modules/rules/hooks/useRules';
import { Rule } from '@/modules/rules/model';

type handlerObj = {
  [key in Module]?: {
    API: (parentId: string, id?: string | number) => Promise<unknown>;
    endpoints: (parentId: string, id?: string | number) => string;
    create: (
      queryClient: QueryClient,
      payload: unknown,
      payload2?: unknown
    ) => void;
    update?: (queryClient: QueryClient, payload: unknown) => void;
    remove: (
      queryClient: QueryClient,
      parentId: string,
      id: string | number
    ) => void;
    rename?: (queryClient: QueryClient, name: string, payload: unknown) => void;
  };
};

const handler: handlerObj = {
  brains: {
    API: brainAPI.getBrain,
    endpoints: brainsEndpoints.brain,
    create: onBrainCreated,
    update: onBrainUpdated,
    remove: onBrainRemoved,
  },
  broadcasts: {
    API: broadcastAPI.getBroadcast,
    endpoints: broadcastsEndpoints.broadcast,
    create: onBroadcastCreated,
    update: onBroadcastUpdated,
    remove: onBroadcastRemoved,
  },
  collections: {
    API: collectionAPI.getCollection,
    endpoints: collectionsEndpoints.collection,
    create: onCollectionCreated,
    update: onCollectionUpdated,
    remove: onCollectionRemoved,
  },
  datasources: {
    API: datasourceAPI.getDatasource,
    endpoints: datasourceEndpoints.datasource,
    create: onDatasourceCreated,
    update: onDatasourceUpdated,
    remove: onDatasourceRemoved,
  },
  desks: {
    API: deskAPI.getDesk,
    endpoints: desksEndpoints.desk,
    create: onDeskCreated,
    update: onDeskUpdated,
    remove: onDeskRemoved,
  },
  integrations: {
    API: integrationAPI.getIntegration,
    endpoints: integrationsEndpoints.integration,
    create: onIntegrationCreated,
    update: onIntegrationUpdated,
    remove: onIntegrationRemoved,
  },
  entities: {
    API: entityAPI.getEntity,
    endpoints: entitiesEndpoints.entity,
    create: (queryClient: QueryClient, newEntity: Entity) =>
      onEntityCreated(queryClient, newEntity, newEntity.brain_id),
    update: (queryClient: QueryClient, newEntity: Entity) =>
      onEntityUpdated(queryClient, newEntity.entity, newEntity),
    remove: onEntityRemoved,
    rename: onEntityUpdated,
  },
  intents: {
    API: intentAPI.getIntent,
    endpoints: intentsEndpoints.intent,
    create: (queryClient: QueryClient, newIntent: Intent) =>
      onIntentCreated(queryClient, newIntent, newIntent.brain_id),
    update: (queryClient: QueryClient, newIntent: Intent) =>
      onIntentUpdated(queryClient, newIntent.intent, newIntent),
    remove: onIntentRemoved,
    rename: onIntentUpdated,
  },
  dialogs: {
    API: dialogAPI.getDialog,
    endpoints: dialogsEndpoints.dialog,
    create: (queryClient: QueryClient, newDialog: Dialog) =>
      onDialogCreated(queryClient, newDialog, newDialog.brain_id),
    update: (queryClient: QueryClient, newDialog: Dialog) =>
      onDialogUpdated(queryClient, newDialog.brain_id, newDialog),
    remove: onDialogRemoved,
  },
  webhooks: {
    API: webhookAPI.getWebhook,
    endpoints: webhooksEndpoints.webhook,
    create: onWebhookCreated,
    update: onWebhookUpdated,
    remove: onWebhookRemoved,
  },
  versions: {
    API: (id, v: number) => versionAPI.getVersion(id, v),
    endpoints: (id, v: number) => versionsEndpoints.version(id, v),
    create: onVersionCreated,
    remove: (queryClient: QueryClient, id: string, v: number) =>
      onVersionRemoved(queryClient, id, v),
  },
  rules: {
    API: ruleAPI.getRule,
    endpoints: rulesEndpoints.rule,
    create: (queryClient: QueryClient, newRule: Rule) =>
      onRuleCreated(queryClient, newRule, newRule.desk_id),
    update: (queryClient: QueryClient, newRule: Rule) =>
      onRuleUpdated(queryClient, newRule, newRule.desk_id),
    remove: onRuleRemoved,
  },
  business_hours: {
    API: businessHoursAPI.getBusinessHour,
    endpoints: businessHoursEndpoints.businessHour,
    create: (queryClient: QueryClient, newBusinessHour: BusinessHour) =>
      onBusinessHourCreated(
        queryClient,
        newBusinessHour.desk_id,
        newBusinessHour
      ),
    update: (queryClient: QueryClient, newBusinessHour: BusinessHour) =>
      onBusinessHourUpdated(
        queryClient,
        newBusinessHour.desk_id,
        newBusinessHour
      ),
    remove: onBusinessHourRemoved,
  },
  bundles: {
    API: bundlesAPI.getBundle,
    endpoints: bundlesEndpoints.bundle,
    create: (queryClient: QueryClient, newBundle: Bundle) =>
      onBundleCreated(queryClient, newBundle.desk_id, newBundle),
    update: (queryClient: QueryClient, newBundle: Bundle) =>
      onBundleUpdated(queryClient, newBundle.desk_id, newBundle),
    remove: onBundleRemoved,
  },
};

export const useRtm = () => {
  const queryClient = useQueryClient();

  const create = async (type: Module, parentId: string, id?: string) => {
    try {
      const newData = await queryClient.fetchQuery({
        queryKey: [handler[type].endpoints(parentId, id)],
        queryFn: () => handler[type].API(parentId, id),
      });

      if (type === 'versions') {
        handler[type].create(queryClient, parentId, newData);
      } else {
        handler[type].create(queryClient, newData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const update = async (type: string, parentId: string, id?: string) => {
    try {
      const newData = await queryClient.fetchQuery({
        queryKey: [handler[type].endpoints(parentId, id)],
        queryFn: () => handler[type].API(parentId, id),
        staleTime: 0,
      });

      handler[type].update(queryClient, newData);
    } catch (error) {
      console.error(error);
    }
  };

  const remove = async (type: string, parentId: string, id?: string) => {
    try {
      handler[type].remove(queryClient, parentId, id);
    } catch (error) {
      console.error(error);
    }
  };

  const rename = async (
    type: string,
    parentId: string,
    oldName: string,
    newName: string
  ) => {
    try {
      const newData = await queryClient.fetchQuery({
        queryKey: [handler[type].endpoints(parentId, newName)],
        queryFn: () => handler[type].API(parentId, newName),
        staleTime: 0,
      });

      handler[type].rename(queryClient, oldName, newData);
    } catch (error) {
      console.error(error);
    }
  };

  return { create, update, remove, rename };
};
