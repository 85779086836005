import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useMembers from '@/hooks/useMembers';
import { Member } from '@/models/member';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { parseFilter } from '@/util/util';

import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

interface Row {
  agent_id: string;
  agent_name: string;
  avg_response_time: number;
  median_response_time: number;
  max_response_time: number;
  min_response_time: number;
  avg_rating: number;
  ratings_more_than_3: number;
  closed_conversations: number;
  participated_sessions: number;
  ratings: number;
}

interface ResultRow {
  agent_id: string;
  agent_name: string;
  avg_response_time: number;
  median_response_time: number;
  max_response_time: number;
  min_response_time: number;
  avg_rating: number | string;
  ratings: number;
  ratings_more_than_3: string | number;
  closed_conversations: number;
  participated_sessions: number;
}
interface Rows {
  rows: Row[];
}

interface AgentsPerformanceHook {
  isLoading: boolean;
  error?: ApolloError;
  refetchCurrent?: () => void;
  data?: ResultRow[];
}

export type ResponseType =
  | 'median_response_time'
  | 'average_response_time'
  | 'min_response_time'
  | 'max_response_time';

export type AgentsPerformanceProps = {
  skip?: boolean;
  skipPrevious?: boolean;
  type: ResponseType;
};

const formatData = (
  current?: Row[],
  members?: Member[]
): ResultRow[] | undefined => {
  if (!current || current.length === 0 || !members) {
    return undefined;
  }

  const result = current.reduce((acc, item) => {
    acc.push({
      agent_id: item.agent_id,
      agent_name: item.agent_name,
      avg_response_time: item.avg_response_time,
      median_response_time: item.median_response_time,
      max_response_time: item.max_response_time,
      min_response_time: item.min_response_time,
      avg_rating: item.ratings ? (item?.avg_rating ?? 0) : 'N/A',
      ratings_more_than_3: item.ratings
        ? Math.ceil((item.ratings_more_than_3 / item.ratings) * 100)
        : 0,
      closed_conversations: item.closed_conversations,
      participated_sessions: item.participated_sessions,
      ratings: item.ratings,
    });
    return acc;
  }, [] as ResultRow[]);
  return result;
};

export const AGENTS_PERFORMANCE = gql`
  query GetAgentsPerformance(
    $accountId: uuid
    $agentIds: _text
    $channels: _text
    $deskIds: _uuid
    $endDate: timestamp
    $isTest: Boolean
    $minNumUserMessages: Int
    $startDate: timestamp
  ) {
    rows: get_agents_performance(
      args: {
        account_id: $accountId
        agent_ids: $agentIds
        channels: $channels
        desk_ids: $deskIds
        end_time: $endDate
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
        start_time: $startDate
      }
    ) {
      agent_id
      agent_name
      avg_rating
      avg_response_time
      closed_conversations
      max_response_time
      median_response_time
      min_response_time
      participated_sessions
      ratings
      ratings_more_than_3
    }
  }
`;

const useAgentsPerformance = ({
  skip,
}: AgentsPerformanceProps): AgentsPerformanceHook => {
  const accountId = useSelector(selectAccountId);
  const filters = useSelector(selectFilters);
  const { members } = useMembers();

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters.endDate),
          accountId,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error,
    refetch: refetchCurrent,
  } = useQuery<Rows, QueryVariables>(AGENTS_PERFORMANCE, {
    variables,
    skip: skip || !accountId || !filters.filtersLoaded,
  });

  const currentRows = currentData?.rows;

  const data = useMemo(() => {
    const formatedData = formatData(currentRows, members);
    const result = formatedData?.map((item) => {
      const name =
        members?.find(({ user_id }) => user_id === item.agent_id)?.name ??
        item.agent_name;
      return {
        ...item,
        name,
      };
    });
    return result;
  }, [currentRows, members]);

  const onRefetchCurrent = useCallback(() => {
    refetchCurrent(variables);
  }, [refetchCurrent, variables]);

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (error) {
    return {
      isLoading: false,
      error,
      refetchCurrent: onRefetchCurrent,
    };
  }

  return { isLoading: false, data };
};

export default useAgentsPerformance;
