/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { Values } from '@/models/values';

const notById = (id) => (i) => i !== id;
const toObject = (acc, e) => {
  acc[e.id] = e;
  return acc;
};

const addId = (e) => ({ id: uuidv4(), ...e });

const defaultState: Values = {
  byId: {},
  allIds: null,
  entityName: null,
  previousName: null,
  dirty: false,
};

const slice = createSlice({
  name: 'values',
  initialState: defaultState,
  reducers: {
    setEntity: (state, action) => {
      const { entity, values } = action.payload;
      const all = values.map(addId);

      state.byId = all.reduce(toObject, {});
      state.allIds = all.map((e) => e.id);
      state.entityName = entity;
      state.dirty = false;
    },

    createValue: (state, action) => {
      const { newValue } = action.payload;
      const id = uuidv4();

      state.allIds.push(id);
      state.byId[id] = { ...newValue, id };
      state.dirty = true;
    },

    updateValue: (state, action) => {
      const { newValue, id } = action.payload;

      state.byId[id] = { ...newValue, id };
      state.dirty = true;
    },

    deleteValue: (state, action) => {
      const { id } = action.payload;

      delete state.byId[id];
      state.allIds = state.allIds.filter(notById(id));
      state.dirty = true;
    },

    updateEntityData: (state, action) => {
      const { new_name, previousName } = action.payload;
      state.previousName = previousName;
      state.entityName = new_name;
      state.dirty = true;
    },

    importValues: (state, action) => {
      const values = action.payload;
      const all = values.map(addId);

      state.byId = all.reduce(toObject, {});
      state.allIds = all.map((e) => e.id);
      state.dirty = true;
    },

    clearEntityValues: () => defaultState,
  },
});

// Extract and export each action creator by name
export const {
  setEntity,
  updateEntityData,
  createValue,
  deleteValue,
  updateValue,
  importValues,
  clearEntityValues,
} = slice.actions;

// Export the reducer, either as a default or named export
export default slice.reducer;
