interface ExpandProps {
  size?: number;
  color?: string;
}
const Expand = ({
  size = 16,
  color = 'var(--icon-default-blue)',
}: ExpandProps) => {
  return (
    <svg
      width={size}
      height={size}
      strokeWidth="1.333"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2h4v4M6 14H2v-4M14 2L9.333 6.667M2 14l4.667-4.667"
        stroke={color}
      />
    </svg>
  );
};

export default Expand;
