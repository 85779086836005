import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Exit = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      className={`${styles.icon_delete} ${styles.rotate_90}`}
    >
      <path
        d="M4.5 15.75v.938A2.812 2.812 0 007.313 19.5h9.375a2.812 2.812 0 002.812-2.813v-.937m-3.75-7.5L12 4.5m0 0L8.25 8.25M12 4.5v11.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Exit;
