import { useState, useEffect, useCallback, useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Switch from '@/components/atoms/Switch/Switch';
import Table from '@/components/atoms/Table/Table';
import { ZendeskDepartment, ZendeskIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import styles from './styles.module.scss';

interface Departments {
  isLoading: boolean;
  departments: ZendeskDepartment[];
  updateIntegration: (data: ZendeskIntegration) => void;
  integration: ZendeskIntegration;
  isUpdating: boolean;
}

const DepartmentFieldsets = ({
  isLoading,
  departments,
  updateIntegration,
  integration,
  isUpdating,
}: Departments) => {
  const { t } = useTranslation();
  const [zDepartments, setZDepartments] = useState([]);
  const [initialZDepartments, setInitialZDepartments] = useState([]);
  const [noDepsToggle, setNoDepsToggle] = useState(
    integration?.config?.process_without_department || false
  );
  const [initialNoDeps, setInitialNoDeps] = useState(
    integration?.config?.process_without_department || false
  );

  useEffect(() => {
    setInitialNoDeps(integration?.config?.process_without_department || false);
  }, [integration?.config?.process_without_department]);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  useEffect(() => {
    if (departments) {
      const d = departments
        .map((d) => ({
          ...d,
          selected: d.selected || false,
        }))
        .sort(
          (a, b) =>
            Number(a.enabled) - Number(b.enabled) ||
            Number(a.selected) - Number(b.selected)
        );
      setZDepartments(d);
      setInitialZDepartments(cloneDeep(d));
    }
  }, [departments]);

  const handleMoveoToggle = useCallback(
    (index: number, e) => {
      setZDepartments((prev) => {
        const newDepartments = [...prev];
        newDepartments[index].selected = e.target.checked;
        return newDepartments;
      });
    },
    [setZDepartments]
  );

  const getColumns = useMemo(
    () => [
      {
        Header: t('common.name'),
        accessor: 'name',
        disableSortBy: true,
        width: 262,
        Cell: ({ value, row }) => {
          return (
            <div className={styles.departmentName}>
              {value}
              <HelpTooltip tooltip={row.original.description} />
            </div>
          );
        },
      },
      {
        Header: 'Zendesk',
        accessor: 'enabled',
        Cell: ({ value }) => (
          <StatusBadge
            label={value ? t('common.enabled') : t('common.disabled')}
            withIcon={false}
            variant={value ? 'success' : 'warning'}
          />
        ),
        disableSortBy: true,
        width: 90,
      },
      {
        Header: t('zendesk.department_id'),
        accessor: 'ccapi_id',
        disableSortBy: true,
        Cell: ({ value }) => (
          <div className={styles.copyButton}>
            <CopyButton data={value} iconSize={14} className={styles.copy}>
              <span className={styles.copy}>{`${t('common.copy')} id`}</span>
            </CopyButton>
          </div>
        ),
        width: 122,
      },
      {
        Header: 'Moveo',
        accessor: 'selected',
        Cell: ({ row, value }) => (
          <Switch
            name="active"
            checked={value}
            onChange={(e) => handleMoveoToggle(row.index, e)}
            disabled={!row.original.enabled || !canWrite || isUpdating}
          />
        ),
        disableSortBy: true,
        sortInverted: true,
        width: 76,
      },
    ],
    [canWrite, handleMoveoToggle, t, isUpdating]
  );

  const renderDepartments = () => {
    if (isLoading) {
      return (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={200}
          height={20}
        />
      );
    }

    return departments?.length > 0 ? (
      <Table
        columns={getColumns}
        data={zDepartments}
        sortable
        filterable={zDepartments?.length > 5}
        noGutters
        flexLayout
      />
    ) : (
      <p>{t('integrations.zendesk.no_departments')}</p>
    );
  };

  const isDirty =
    !isEqual(initialZDepartments, zDepartments) ||
    noDepsToggle !== initialNoDeps;

  return (
    <FormFieldset
      title={t('integrations.zendesk.departments_title')}
      subtitle={t('integrations.zendesk.departments_subtitle')}
      isLoading={isUpdating}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
      }}
      onSubmit={() =>
        updateIntegration({
          ...integration,
          config: {
            ...integration.config,
            process_without_department: noDepsToggle,
            departments: zDepartments.map((x) => ({
              ...x,
              description: x.description || undefined,
            })),
          },
        })
      }
    >
      <div className={styles.departments}>
        {renderDepartments()}
        <div className={styles.secondaryHeader}>
          <Typography variant="label-caps-large">
            {t('integrations.zendesk.without_department')}
          </Typography>
          <Typography variant="label-caps-large">Moveo</Typography>
        </div>
        <div className={styles.secondaryTable}>
          <Typography>
            {t('integrations.zendesk.process_tickets_without_department')}
          </Typography>
          <span>
            <Switch
              name="process_tickets_without_department"
              checked={noDepsToggle}
              onChange={() => setNoDepsToggle(!noDepsToggle)}
              disabled={!canWrite || isUpdating}
            />
          </span>
        </div>
      </div>
    </FormFieldset>
  );
};

export default DepartmentFieldsets;
