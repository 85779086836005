import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Close from '@/components/atoms/Icons/Global/Close';
import Success from '@/components/atoms/Icons/Global/Success';
import IconAI from '@/components/atoms/Icons/Other/AI';
import AutoGenerationAI from '@/components/atoms/Icons/Other/AutoGenerationAI';
import Input from '@/components/atoms/Input/Input';
import { TYPE } from '@/models/recommendations';
import { TypingIndicator } from '@/modules/humanChat/components/Conversation/MessageArea/TypingIndicator/TypingIndicator';

import styles from './RecommendationsTable.module.scss';

export interface Props {
  items: { text: string }[];
  type: TYPE;
  onSaveClick: (text: string) => void;
  onRejectClick: (text: string) => void;
  onSaveAllClick?: () => void;
  onRejectAllClick?: () => void;
  isLoadingNew?: boolean;
}

const RecommendationsTable = ({
  items,
  type,
  onSaveClick,
  onSaveAllClick,
  onRejectClick,
  onRejectAllClick,
  isLoadingNew = false,
}: Props) => {
  const isAuto = type === TYPE.AUTO;
  const { t } = useTranslation();
  const title = isAuto
    ? t('brains.recommendations')
    : t('intent.recommendations.expressions_title');
  const subtitle = isAuto
    ? t('intent.recommendations.auto_subtitle')
    : t('intent.recommendations.expressions_subtitle');

  return (
    <Fade in>
      <div
        className={cn(styles.container, {
          [styles.isAuto]: isAuto,
        })}
      >
        <div className={styles.header}>
          {isAuto ? (
            <IconAI size={24} />
          ) : (
            <AutoGenerationAI size={24} color="var(--icon-default-blue)" />
          )}
          {!isLoadingNew ? (
            <>
              <Typography variant="body-semi-bold">{title}</Typography>
              <Typography>{subtitle}</Typography>
            </>
          ) : (
            <div className={styles.loading}>
              <TypingIndicator color="var(--icon-default-purple)" />
            </div>
          )}
        </div>
        <ul>
          {items?.map(({ text }) => {
            return (
              <li className={styles.item} key={text}>
                <Input readOnly size="large" value={text} />
                <IconButton
                  ariaLabel="Reject"
                  onClick={() => onRejectClick(text)}
                >
                  <Close size={16} />
                </IconButton>
                <IconButton
                  ariaLabel="Accept"
                  onClick={() => onSaveClick(text)}
                >
                  <Success size={16} />
                </IconButton>
              </li>
            );
          })}
        </ul>
        {onSaveAllClick && onRejectAllClick && (
          <div className={styles.buttonContainer}>
            <Button variant="tertiary" size="small" onClick={onRejectAllClick}>
              {t('intent.recommendations.reject_all')}
            </Button>
            <Button variant="tertiary" size="small" onClick={onSaveAllClick}>
              {t('intent.recommendations.accept_all')}
            </Button>
          </div>
        )}
      </div>
    </Fade>
  );
};

export default RecommendationsTable;
