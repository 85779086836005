import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { useModalTryIt } from '@/hooks/useModalTryIt';
import { useRelatedConversations } from '@/modules/humanChat/hooks/useRelatedConversations';

import { Avatar } from '../Avatar/Avatar/Avatar';
import Button from '../Button/Button/Button';
import Play from '../Icons/Play';
import Robot from '../Icons/Robot';

import styles from './RelatedConversationsTile.module.scss';

type Props = {
  session_id: string;
};

export const RelatedConversationsTile = ({ session_id }: Props) => {
  const { handleAccountReplayClick } = useModalTryIt();
  const { relatedConversation } = useRelatedConversations(session_id);

  if (!relatedConversation) {
    return <Skeleton width={'100%'} height={'var(--space-40)'} />;
  }

  const timeFormatted = moment(relatedConversation?.last_message_at).fromNow();

  return (
    <li className={styles.tile}>
      <div className={styles.avatar}>
        {relatedConversation?.avatar?.author_type === 'brain' ? (
          <Robot size={16} />
        ) : (
          <Avatar
            src={
              relatedConversation?.avatar?.author_type === 'visitor'
                ? relatedConversation?.avatar?.src
                : undefined
            }
            userId={
              relatedConversation?.avatar?.author_type === 'agent'
                ? relatedConversation?.avatar?.src
                : undefined
            }
            size="small"
          />
        )}
      </div>

      <Typography
        color="var(--text-default-gray)"
        variant="label-regular"
        className={styles.last_message}
      >
        {relatedConversation.last_message}
      </Typography>

      <Typography
        variant="label-caps-small"
        className={styles.time}
        color="var(--text-placeholder-gray)"
      >
        {timeFormatted}
      </Typography>

      <Button
        onClick={() => handleAccountReplayClick(session_id)}
        className={styles.replay_button}
      >
        <Play size={16} color="var(--icon-default-white)" />
      </Button>
    </li>
  );
};
