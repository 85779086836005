import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import { ADD_CARD } from '@/components/organisms/Modals/ModalConductor';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { pushModal } from '@/redux/modals/actions';

import PaymentMethodsTable from './PaymentMethodsTable';

const PaymentMethodsFieldset = () => {
  const { t } = useTranslation();
  const { paymentMethods } = useBilling();
  const dispatch = useDispatch();

  const isLoading = paymentMethods === undefined;

  const canAddMoreCards = useMemo(() => {
    const length = paymentMethods?.length ?? 0;
    return length < 3;
  }, [paymentMethods]);

  const handleClick = useCallback(() => {
    dispatch(pushModal(ADD_CARD, {}));
  }, [dispatch]);

  return (
    <FormFieldset
      title={t('billing.payment_title')}
      subtitle={t('billing.payment_subtitle')}
      primaryButton={{
        children: t('billing.payment_add_card'),
        disabled: !canAddMoreCards,
      }}
      onSubmit={handleClick}
      helpText={t('billing.payment_help')}
      isLoading={isLoading}
      fullContentWidth
    >
      <PaymentMethodsTable />
    </FormFieldset>
  );
};

export default PaymentMethodsFieldset;
