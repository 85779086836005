import { Icon } from '@/models/icon';

const ArrowLeft = ({
  size = 24,
  color = 'var(--icon-default-black)',
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12H21M10 19.5L3 12L10 19.5ZM3 12L10 4.5L3 12Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
