import { useCallback, useEffect } from 'react';

import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Create from '@/components/atoms/Icons/Create';
import Delete from '@/components/atoms/Icons/Delete';
import Input from '@/components/atoms/Input/Input';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  FacebookIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { urlPattern } from '@/util/validator';

import styles from './WhitelistDomains.module.scss';

type Form = {
  domains: { name: string }[];
};

const WhitelistDomains = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, FacebookIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();
  const { updateIntegration, updateStatus } =
    useIntegrations<FacebookIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );

  const getDefaultValues = useCallback((domains: string[]) => {
    if (!domains) {
      return [{ name: '' }];
    }
    return domains.reduce(
      function (accumulator, domain) {
        const currentObject = { name: domain };
        accumulator.push(currentObject);
        return accumulator;
      },
      domains.length > 0 ? [] : [{ name: '' }]
    );
  }, []);

  const formMethods = useForm<Form>({ mode: 'onSubmit' });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    control,
    handleSubmit,
    trigger,
  } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'domains',
  });

  useEffect(() => {
    reset({
      domains: getDefaultValues(integration?.config?.domains),
    });
  }, [getDefaultValues, integration?.config?.domains, reset]);

  const values = useWatch({
    control,
    name: 'domains',
  });

  const handleCreate = useCallback(() => {
    if (canWrite) {
      append({ name: '' });
    }
  }, [append, canWrite]);

  const handleDelete = useCallback(
    (index) => {
      if (canWrite) {
        remove(index);
      }
    },
    [canWrite, remove]
  );

  const handleUpdateIntegration = useCallback(() => {
    const domains = formMethods
      .getValues()
      .domains.map((domain) => domain.name.trim())
      .filter((x) => x !== '');
    const uniqDomains = [...new Set(domains)];
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration.config,
          domains: uniqDomains.length > 0 ? uniqDomains : undefined,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, formMethods, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  return (
    <>
      <Accordion
        title={t('integrations.whitelist_domains.title')}
        subtitle={t('integrations.whitelist_domains.subtitle')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
      >
        <div>
          {fields.map((domain, index) => (
            <div className={styles.input} key={domain.id}>
              <Input
                key={domain.id}
                register={register(`domains.${index}.name`, {
                  pattern: {
                    value: urlPattern,
                    message: t('validation.invalid_url'),
                  },
                })}
                errorMessage={
                  (errors?.domains && errors?.domains[index]?.name?.message) ||
                  null
                }
                placeholder="https://"
                size="large"
                trimValue
                disabled={!canWrite}
              />
              <IconButton
                ariaLabel={t('common.create')}
                onClick={handleCreate}
                disabled={values?.[index]?.name === ''}
              >
                <Create />
              </IconButton>
              {fields.length > 1 && (
                <IconButton
                  ariaLabel={t('common.delete')}
                  onClick={() => handleDelete(index)}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      </Accordion>
    </>
  );
};

export default WhitelistDomains;
