import { Icon } from '@/models/icon';

const Replay = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        d="M13.5 10.18S13.274 12.908 10 14c-3.768 1.256-7.876-.996-7.997-5.494-.2-7.461 8.497-7.826 10.5-4.5"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        d="M11 4.18h2v-2M6.5 6.218v3.925a.52.52 0 0 0 .808.432l2.944-1.962a.52.52 0 0 0 0-.865L7.308 5.785a.52.52 0 0 0-.808.433Z"
      />
    </svg>
  );
};

export default Replay;
