import { Icon } from '@/models/icon';

export const AmendIcon = ({
  size = 12,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width={size}
      height={size}
      fill="none"
      className="icon_fill"
    >
      <path
        fill={color}
        d="M10.655 3.44 8.561 1.343a.75.75 0 0 0-1.061 0L1.72 7.125a.743.743 0 0 0-.22.53V9.75a.75.75 0 0 0 .75.75h2.095a.744.744 0 0 0 .53-.22l5.78-5.78a.75.75 0 0 0 0-1.06Zm-6.31 6.31H2.25V7.655L6.375 3.53 8.47 5.625 4.345 9.75ZM9 5.094 6.905 3 8.03 1.875l2.095 2.094L9 5.094ZM8.89 10.39l1.5-1.5a.375.375 0 1 0-.53-.53L8.625 9.595l-.484-.485a.375.375 0 1 0-.531.53l.75.75a.375.375 0 0 0 .53 0Z"
      />
    </svg>
  );
};
