import { useTranslation } from 'react-i18next';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import Input from '@/components/atoms/Input/Input';
import { useAccount } from '@/hooks/useAccount';

import styles from './AccountIdFieldset.module.scss';

const AccountIdFieldset = () => {
  const { account } = useAccount();
  const { t } = useTranslation();
  const hasAccountId = !!account?.account_id;
  return (
    <PlainFieldset title={t('account.id')} isLoading={!hasAccountId}>
      <div className={styles.account_id}>
        <Input
          placeholder=""
          size="large"
          defaultValue={account?.account_id}
          readOnly
        />
        <CopyButton
          className={styles.copy_account_id}
          data={account?.account_id}
        />
      </div>
    </PlainFieldset>
  );
};

export default AccountIdFieldset;
