import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import Star from '@/components/atoms/Icons/Star';
import useFavorites from '@/modules/analytics/hooks/useFavorites';
import { AnalyticsType } from '@/modules/analytics/models';

import FavoriteDropdown from './FavoriteDropdown/FavoriteDropdown';

interface Props {
  type: AnalyticsType;
}

const FavoriteFilters = ({ type }: Props) => {
  const { t } = useTranslation();
  const {
    ref,
    favorites,
    showFavorites,
    setShowFavorites,
    onClickSaveFilters,
    onFavoriteClick,
    onFavoriteDelete,
  } = useFavorites(type);
  return (
    <div ref={ref}>
      <Button
        onClick={() => setShowFavorites((prev) => !prev)}
        variant="tertiary"
      >
        <Star size={24} color="var(--icon-default-blue)" />
        <Typography variant="label-semi-bold" component="p" m="0">
          {t('analytics.favorites', { 0: favorites.length })}
        </Typography>
      </Button>
      {showFavorites && (
        <FavoriteDropdown
          onSave={onClickSaveFilters}
          onClick={onFavoriteClick}
          onDelete={onFavoriteDelete}
          favorites={favorites}
        />
      )}
    </div>
  );
};

export default FavoriteFilters;
