import { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { selectUserId } from '@/redux/user/selectors';

/**
 * Looks for a value in localStorage, if it doesn't exist, it will use the initialValue
 * The value is stored in localStorage under the key `moveo_${userId}` which means
 * that the user needs to be in Redux
 */
function useLocalStorage<UserPreferenceType>(
  property: string,
  initialValue: UserPreferenceType
): [UserPreferenceType, (value: UserPreferenceType) => void] {
  // we need user to be in Redux
  const userId = useSelector(selectUserId);
  const key = `moveo_${userId || 'unknown'}`;

  const readLocal = useCallback(() => {
    if (typeof window === 'undefined') {
      return {};
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : {};
    } catch (error) {
      return {};
    }
  }, [key]);

  const [storedValue, setStoredValue] = useState<UserPreferenceType>(
    readLocal()[property] ?? initialValue
  );

  useEffect(() => {
    setStoredValue(readLocal()[property] ?? initialValue);
  }, [initialValue, property, readLocal]);

  const setValue = useCallback(
    (value: UserPreferenceType) => {
      const userPref = readLocal();
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      const newPref = { ...userPref, [property]: valueToStore };
      setStoredValue(valueToStore);

      window.localStorage.setItem(key, JSON.stringify(newPref));
    },
    [key, property, readLocal, storedValue]
  );

  return [storedValue, setValue];
}

export default useLocalStorage;
