import { ReactNode } from 'react';

import MuiBadge, { BadgeProps } from '@mui/material/Badge';

type Props = {
  children: ReactNode;
  color?: 'primary' | 'secondary' | 'error';
  variant?: 'standard' | 'dot';
  content?: ReactNode;
};

export type RestProps = Omit<Partial<BadgeProps>, keyof Props>;

export const Badge = ({
  children,
  variant = 'standard',
  content,
  color = 'primary',
  ...rest
}: Props & RestProps) => {
  return (
    <MuiBadge
      variant={variant}
      badgeContent={content}
      color={color}
      classes={rest.classes}
      invisible={rest.invisible}
      overlap={rest.overlap}
      className={rest.className}
      sx={rest.sx}
    >
      {children}
    </MuiBadge>
  );
};
