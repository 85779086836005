import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

interface ExtendedIcon extends Icon {
  innerColor?: string;
}

export const ErrorIcon = forwardRef<SVGSVGElement, ExtendedIcon>(
  (
    {
      size = 16,
      color = 'var(--icon-default-error)',
      innerColor = 'var(--icon-default-white)',
      ...props
    },
    ref
  ) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#erroricon)">
          <path
            d="m2.197 9.697 4.606 4.606a2.4 2.4 0 0 0 3.394 0l4.606-4.606a2.4 2.4 0 0 0 0-3.394l-4.606-4.606a2.4 2.4 0 0 0-3.394 0L2.197 6.303a2.4 2.4 0 0 0 0 3.394Z"
            fill={color}
          />
          <path
            stroke={innerColor}
            strokeWidth="1.4"
            strokeLinecap="round"
            d="M8.533 4.7v3.933"
          />
          <circle cx="8.533" cy="11.367" r=".7" fill={innerColor} />
        </g>
        <defs>
          <clipPath id="erroricon">
            <path fill="#fff" transform="translate(.5)" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

ErrorIcon.displayName = 'ErrorIcon';
