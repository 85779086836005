import { Icon } from '@/models/icon';

const AI = ({ size = 16, color = 'var(--icon-default-purple)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M22 12h-6.895l-2.457 9L7.733 3l-2.457 9H2"
      />
      <path
        fill={color}
        d="M16.59 10H15.5l1.986-7h1.261l1.988 7h-1.09l-1.507-5.564h-.044L16.591 10Zm.036-2.745h2.975v1.019h-2.975V7.255ZM22.5 3v7h-1.022V3H22.5Z"
      />
    </svg>
  );
};

export default AI;
