import isNil from 'lodash/isNil';

function formatCents(cents = 0, divideBy?) {
  return (cents / (divideBy || 100)).toFixed(2);
}

function getPrice(quantity = 0, unitPriceInCents = 0) {
  return formatCents(quantity * unitPriceInCents);
}

function getTotal(invoice) {
  const total = invoice?.items?.reduce(
    (acc, item) => acc + (item.quantity || 0) * item.price.unit_amount,
    0
  );
  return (!isNil(total) && formatCents(total)) || null;
}

export { formatCents, getPrice, getTotal };
