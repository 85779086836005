import { ReactNode } from 'react';

import { useMetaTags } from 'react-metatags-hook';

type HeadManagerProps = {
  title: string;
  description: string;
  children?: ReactNode;
};

const HeadManager = ({ title, description, children }: HeadManagerProps) => {
  useMetaTags(
    {
      title,
      description,
      twitter: {
        title,
        description,
      },
      openGraph: {
        title,
        description,
      },
    },
    []
  );

  return <>{children}</>;
};
export default HeadManager;
