import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import { MODAL_DEPARTMENT_CREATE } from '@/components/organisms/Modals/ModalConductor';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { createInitialState } from '@/hooks/useAccordionState/helpers';
import { useAccordionState } from '@/hooks/useAccordionState/useAccordionState';
import { pushModal } from '@/redux/modals/actions';
import { selectDeskId } from '@/redux/session/selectors';

import DepartmentTile from '../components/DepartmentTile/DepartmentTile';
import { useDepartments } from '../hooks/useDepartments';
import Department from '../icons/Department';

const Departments = () => {
  const deskId = useSelector(selectDeskId);
  const { departments, listStatus } = useDepartments(deskId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(pushModal(MODAL_DEPARTMENT_CREATE));
  };

  const { state, setExpanded, setInitState, setOnSubmit, setDirty } =
    useAccordionState({});

  useEffect(() => {
    if (departments) {
      const newState = createInitialState(
        departments.map((d) => d.department_id)
      );
      setInitState(newState);
    }
  }, [departments, setInitState]);

  const isLoading = listStatus === 'pending';
  return (
    <PageContentWrapper fullHeight>
      <ContextualHelp
        title={t('departments.help_title')}
        name="departments-help"
        size="full"
      >
        {t('departments.help_body')}
      </ContextualHelp>

      {departments?.length === 0 && !isLoading && (
        <TileEmptyPage
          title={t('departments.no_department_yet')}
          notClickable
          body={
            <>
              {t('departments.to_assign')} <br />{' '}
              <Button onClick={handleClick} variant="tertiary">
                {t('collections.create_a_department')}
              </Button>
            </>
          }
          icon={<Department size={100} />}
        />
      )}

      {Object.keys(state).length > 0 &&
        departments?.map((department) => {
          const panelId = department.department_id;

          return (
            <DepartmentTile
              key={department.department_id}
              department={department}
              isExpanded={state[panelId]?.isExpanded || false}
              panelId={panelId}
              setExpanded={setExpanded(panelId)}
              ref={state[panelId]?.ref}
              setOnSubmit={setOnSubmit}
              setDirty={setDirty}
            />
          );
        })}
    </PageContentWrapper>
  );
};

export default Departments;
