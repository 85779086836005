import { useCallback } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/models/state';
import { removeToast } from '@/modules/notifications/redux/actions';

import Notification from './Notification';
import { ToastAction } from '../models';

import styles from './Notification.module.scss';

const NotificationConductor = () => {
  const notifications = useSelector(
    (state: RootState) => state.notifications,
    shallowEqual
  ) as ToastAction[];

  const dispatch = useDispatch();

  const handleClose = useCallback(
    (id: string) => dispatch(removeToast(id)),
    [dispatch]
  );

  return (
    <div className={styles.conductor}>
      {/* {notifications[notifications.length - 1]?.id === lastId && */}
      {notifications.map(
        ({ id, type, message, autoRemove, action, avatar, duration }) => (
          <Notification
            key={id}
            type={type}
            avatar={avatar}
            autoRemove={autoRemove}
            message={message}
            action={action}
            onClose={() => {
              handleClose(id);
            }}
            duration={duration}
            animate={id === notifications[notifications.length - 1]?.id}
          />
        )
      )}
    </div>
  );
};

export default NotificationConductor;
