import { useCallback, useEffect, useMemo, KeyboardEvent } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import Add from '@/components/atoms/Icons/Add';
import Input from '@/components/atoms/Input/Input';
import Switch from '@/components/atoms/Switch/Switch';
import Table from '@/components/atoms/Table/Table';
import Tags from '@/components/atoms/Tags/Tags';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  FrontIntegration,
  FrontInbox,
  FrontTag,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { isKeyEnter } from '@/util/util';
import { removeEmptyFields } from '@/util/validator';

import styles from './FrontPreferences.module.scss';

type Form = {
  sender_name: string;
  teammate_id: string;
  inboxes: FrontInbox[];
  tag: string;
  tracked_tags: string[];
  suggested_tags: string[];
};

const FrontPreferences = ({
  type,
  integration,
  registerAccordion,
  toggleAccordion,
}: AccordionFieldsetProps<Form, FrontIntegration>) => {
  const { t } = useTranslation();

  const { updateIntegration, updateStatus } = useIntegrations<FrontIntegration>(
    integration?.desk_id,
    integration?.integration_id
  );
  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const dispatch = useDispatch();

  const {
    control,
    register,
    reset,
    setValue,
    formState: { errors, isDirty },
    handleSubmit,
    trigger,
  } = formMethods;

  const { inboxes, tracked_tags, tag, suggested_tags } = useWatch({ control });

  useEffect(() => {
    reset({
      inboxes: integration?.config?.inboxes?.sort(
        (a, b) => Number(a.selected) - Number(b.selected)
      ),
      tag: '',
      tracked_tags: integration?.config?.tracked_tags || [],
      suggested_tags: (integration?.config?.tags || [])
        .map((t: FrontTag) => t.name)
        .filter((t: string) => !integration?.config?.tracked_tags?.includes(t)),
    });
  }, [
    reset,
    integration?.config?.sender_name,
    integration?.config?.teammate_id,
    integration?.config?.inboxes,
    integration?.config?.tracked_tags,
    integration?.config?.tags,
  ]);

  const handleUpdateIntegration = useCallback(
    (values: Form) => {
      const config = removeEmptyFields(
        pick(values, ['inboxes', 'sender_name', 'teammate_id', 'tracked_tags'])
      );
      updateIntegration(
        {
          ...integration,
          config: {
            ...integration.config,
            ...config,
          },
        },
        {
          onSuccess: () => {
            if (expanded === type) {
              dispatch(setExpanded(false));
            }
          },
        }
      );
    },
    [dispatch, expanded, integration, type, updateIntegration]
  );

  usePrompt(
    isDirty,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  const getColumns = useMemo(
    () => [
      {
        Header: t('common.name'),
        accessor: 'name',
        disableSortBy: true,
        width: 262,
        Cell: ({ value }) => <div className={styles.inboxName}>{value}</div>,
      },
      {
        Header: (
          <div className={styles.switchColumn}>{t('common.moveo_ai')}</div>
        ),
        accessor: 'selected',
        Cell: ({ row }) => (
          <div className={styles.switchColumn}>
            <Controller
              name={`inboxes.${row.index}.selected`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={onChange}
                  disabled={!canWrite || !integration}
                />
              )}
            />
          </div>
        ),
        disableSortBy: true,
        sortInverted: true,
        width: 76,
      },
    ],
    [t, control, canWrite, integration]
  );

  const renderInboxes = () => {
    if (!integration) {
      return (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={200}
          height={20}
        />
      );
    }

    return inboxes?.length > 0 ? (
      <Table
        columns={getColumns}
        data={inboxes}
        sortable
        filterable={inboxes?.length > 5}
        noGutters
        flexLayout
      />
    ) : (
      <p>{t('integrations.front.inboxes.not_found')}</p>
    );
  };

  const onKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (isKeyEnter(e)) {
        if (tag) {
          setValue('tag', '');
          setValue('tracked_tags', uniq([...(tracked_tags || []), tag]), {
            shouldDirty: true,
          });
          setValue(
            'suggested_tags',
            suggested_tags.filter((t) => t !== tag),
            { shouldDirty: true }
          );
        }
      }
    },
    [setValue, suggested_tags, tag, tracked_tags]
  );

  const handleOnTagDelete = useCallback(
    (tag: string) => {
      if (canWrite) {
        if (integration?.config?.tags?.find((t: FrontTag) => t.name === tag)) {
          setValue('suggested_tags', [...suggested_tags, tag], {
            shouldDirty: true,
          });
        }
        setValue(
          'tracked_tags',
          tracked_tags.filter((t) => t !== tag),
          { shouldDirty: true }
        );
      }
    },
    [
      canWrite,
      integration?.config?.tags,
      setValue,
      suggested_tags,
      tracked_tags,
    ]
  );

  const handleOnTagAdd = useCallback(
    (tag: string) => {
      if (canWrite) {
        setValue(
          'suggested_tags',
          suggested_tags.filter((t) => t !== tag),
          { shouldDirty: true }
        );
        setValue('tracked_tags', uniq([...(tracked_tags || []), tag]), {
          shouldDirty: true,
        });
      }
    },
    [canWrite, setValue, suggested_tags, tracked_tags]
  );

  return (
    <>
      <Accordion
        title={t('integrations.front.pref.title')}
        subtitle={t('integrations.front.pref.subtitle')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
        readOnly={!canWrite}
      >
        <>
          <div className={styles.section}>
            <TitleSubtitle
              title={t('integrations.front.inboxes.title')}
              subtitle={t('integrations.front.inboxes.subtitle')}
            />
            <div className={styles.inboxes}>{renderInboxes()}</div>
          </div>
          <TitleSubtitle
            title={t('common.tags')}
            subtitle={t('integrations.front.tags.subtitle')}
          />
          <Input
            name="tag"
            disabled={!integration || tracked_tags?.length > 50}
            label={t('integrations.front.tags.add')}
            error={!!errors.tag}
            onKeyUp={onKeyUp}
            size="large"
            errorMessage={!!errors.tag && t('integrations.zendesk.error_tag')}
            register={register('tag')}
            tooltip={t('integrations.front.tags.type')}
            placeholder=""
            autoComplete="off"
            readOnly={!canWrite}
          />
          <div
            className={cn(styles.tags, {
              [styles.readOnly]: !canWrite,
            })}
          >
            <Tags
              name="tracked_tags"
              label={t('common.tags')}
              tooltip={t('integrations.front.tags.monitor')}
              tags={tracked_tags}
              placeholder={t('integrations.front.tags.monitor_all')}
              onClick={handleOnTagDelete}
            />
          </div>
          <div
            className={cn(styles.tags, {
              [styles.readOnly]: !canWrite,
            })}
          >
            <Tags
              name="suggested_tags"
              icon={<Add />}
              label={t('integrations.front.tags.front_tags')}
              tooltip={t('integrations.front.tags.front_tooltip')}
              tags={suggested_tags}
              placeholder={t('integrations.front.tags.empty_front')}
              onClick={handleOnTagAdd}
            />
          </div>
        </>
      </Accordion>
    </>
  );
};

export default FrontPreferences;
