import { useCallback, useState } from 'react';

import dotize from 'dotize';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import Modal from '@/components/organisms/Modals/Modal';
import { Bundle } from '@/modules/bundles/models';
import { popModal } from '@/redux/modals/actions';

import { setDraftBundle } from '../../redux/actions';

interface Props {
  conflicts: string[];
  draftBundle: Partial<Bundle>;
  json: Bundle['context'];
}

import styles from './ModalMergeConflicts.module.scss';

const ModalMergeConflicts = ({ conflicts, draftBundle, json }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('0');

  const handleChangeSelected = useCallback(() => {
    setSelected((selected) => (selected === '0' ? '1' : '0'));
  }, []);

  const handleSubmit = useCallback(() => {
    const replaceDuplicates = {
      ...draftBundle?.context,
      ...dotize.convert(json),
    };

    const keepDuplicates = {
      ...dotize.convert(json),
      ...draftBundle?.context,
    };

    dispatch(
      setDraftBundle({
        ...draftBundle,
        context: selected === '0' ? replaceDuplicates : keepDuplicates,
        dirty: true,
      })
    );
    dispatch(popModal());
  }, [dispatch, draftBundle, json, selected]);

  return (
    <Modal
      primaryButtonText={t('common.continue')}
      title={t('bundles.merge_conflict_modal.title')}
      onPrimarySubmit={handleSubmit}
    >
      <div className={styles.conflicts}>
        <span className={styles.names}>
          {t('bundles.merge_conflict_modal.keys')}:
        </span>
        {conflicts?.map((c, index) => (
          <span key={c}>
            {' '}
            {c}
            {index !== conflicts.length - 1 && <span>,</span>}
          </span>
        ))}
      </div>
      <div className={styles.options}>
        <OptionRadioButton
          option={{
            value: '0',
            id: 0,
            label: (
              <TitleSubtitle
                title={t('bundles.merge_conflicts_modal.option_0.title')}
                subtitle={t('bundles.merge_conflicts_modal.option_0.subtitle')}
                noGutters
              />
            ),
          }}
          isSelected={selected === '0'}
          setSelected={handleChangeSelected}
          disableSelectedStyles
        />
        <OptionRadioButton
          option={{
            value: '1',
            id: 1,
            label: (
              <TitleSubtitle
                title={t('bundles.merge_conflicts_modal.option_1.title')}
                subtitle={t('bundles.merge_conflicts_modal.option_1.subtitle')}
                noGutters
              />
            ),
          }}
          isSelected={selected === '1'}
          setSelected={handleChangeSelected}
          disableSelectedStyles
        />
      </div>
    </Modal>
  );
};

export default ModalMergeConflicts;
