import React, { PropsWithChildren, forwardRef } from 'react';

import cn from 'classnames';

import styles from './Tile.module.scss';

interface Props {
  className?: string;
}
const Tile = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, className = '' }, ref) => {
    return (
      <div className={cn(styles.wrapper, className)} ref={ref}>
        {children}
      </div>
    );
  }
);

Tile.displayName = 'Tile';
export default Tile;
