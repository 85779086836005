import { useCallback } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import useDesks from '@/hooks/useDesks';
import { Desk } from '@/models/desk';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';

interface Props {
  desk?: Desk;
}

const DeleteDeskFieldset = ({ desk }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deleteDesk, deleteStatus } = useDesks();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'desks', actions.WRITE)
  );

  const onDelete = useCallback(() => {
    const deleteProps = {
      subtitle: (
        <Trans i18nKey="environments.delete_with_name" values={[desk?.name]} />
      ),
      confirm: true,
      onDelete: () => {
        deleteDesk(desk?.desk_id);
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [deleteDesk, desk?.desk_id, desk?.name, dispatch]);

  return (
    <FormFieldset
      title={t('environments.delete_title', { 0: desk?.name })}
      subtitle={t('environments.delete_subtitle')}
      primaryButton={{
        children: t('environment.delete_environment'),
        isLoading: deleteStatus === 'pending',
        variant: 'danger',
        disabled: !canWrite,
      }}
      onSubmit={onDelete}
      isLoading={!desk}
    />
  );
};

export default DeleteDeskFieldset;
