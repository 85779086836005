import { useCallback, useMemo, useState } from 'react';

import { useMacros } from '@/hooks/useMacros';

import EditResponse from './EditResponse';
import Responses from './Responses';
import Modal from '../Modal';

interface Props {
  changeInputTo: (value: string) => void;
}

export default function ModalCannedResponses({ changeInputTo }: Props) {
  const { macros } = useMacros();

  const [selectedMacro, setSelectedMacro] = useState(0);
  const [isNewMacro, setIsNewMacro] = useState(true);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [query, setQuery] = useState('');

  const handleTyping = useCallback((e) => {
    setQuery(e.target.value.toLowerCase().trim());
  }, []);

  const filteredOptions = useMemo(
    () =>
      macros?.filter((option) =>
        `${option?.name} ${option?.text}`.toLowerCase().includes(query)
      ),
    [macros, query]
  );

  const handleEdit = useCallback(() => {
    setShowCreateNew(true);
    setIsNewMacro(false);
  }, []);

  return (
    <Modal
      hideBackdrop={!showCreateNew}
      cleanModal
      size="medium"
      fixedHeight
      disableRestoreFocus
    >
      {!showCreateNew && (
        <Responses
          handleTyping={handleTyping}
          setSelectedMacro={setSelectedMacro}
          filteredOptions={filteredOptions}
          selectedMacro={selectedMacro}
          handleEdit={handleEdit}
          setShowCreateNew={setShowCreateNew}
          changeInputTo={changeInputTo}
        />
      )}
      {showCreateNew && (
        <EditResponse
          isNewMacro={isNewMacro}
          filteredOptions={filteredOptions}
          selectedMacro={selectedMacro}
          setShowCreateNew={setShowCreateNew}
          setIsNewMacro={setIsNewMacro}
        />
      )}
    </Modal>
  );
}
