import { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import { useIntegrations } from '@/hooks/useIntegrations';
import { Integration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { integrationSessionTimeoutSchema } from '@/util/validator';

type Form = {
  session_timeout?: number;
};
interface Props {
  integration?: Integration;
}

const SessionTimeoutFieldset = ({ integration }: Props) => {
  const { t } = useTranslation();

  const { updateIntegration, updateStatus } = useIntegrations(
    integration.desk_id,
    integration.integration_id
  );

  const canWrite = useSelector((state: RootState) =>
    getPermissions(
      state,
      'integrations',
      actions.WRITE,
      integration.integration_id
    )
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      session_timeout: integration?.config?.session_timeout / 60,
    },
    resolver: yupResolver(integrationSessionTimeoutSchema),
  });

  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      updateIntegration({
        ...integration,
        config: {
          ...integration.config,
          session_timeout: form?.session_timeout * 60,
        },
      });
    },
    [integration, updateIntegration]
  );

  return (
    <FormFieldset
      title={t('integrations.session_timeout.title')}
      subtitle={t('integrations.session_timeout.subtitle')}
      primaryButton={{
        children: t('common.save'),
        isLoading: updateStatus === 'pending',
        disabled: !isDirty || !isValid,
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={!integration}
    >
      <Input
        name="session_timeout"
        label={t('common.minutes')}
        register={register('session_timeout', {
          setValueAs: (v) => parseInt(v),
        })}
        errorMessage={errors.session_timeout?.message}
        placeholder={t('integrations.session_timeout.placeholder')}
        size="small"
        type="number"
        readOnly={!canWrite}
      />
    </FormFieldset>
  );
};

export default SessionTimeoutFieldset;
