import { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';

import JumpTo from '@/components/atoms/Icons/JumpTo';
import { NodeStack } from '@/models/tryIt';
import { groupBy } from '@/modules/TryIt/utils/helper';

import styles from '../../MessageNode/MessageNode.module.scss';

type ResponsesWrapperProps = {
  type?: 'node' | 'webhook';
  nodes_stack: NodeStack[];
} & React.PropsWithChildren<{}>;

const getJumpToNodes = (array: NodeStack[]) => {
  if (array.length === 0) return [];

  const grouped = groupBy(array, 'request_id');
  const length = grouped.length;

  const lastMessagePath = grouped[length - 1];

  // Exclude the first node
  const jumpToNodes = lastMessagePath.slice(1);

  return jumpToNodes.map((node) => node.name);
};

export const ResponsesWrapper = ({
  type = 'node',
  nodes_stack = [],
  children,
}: ResponsesWrapperProps) => {
  const options = useMemo(() => {
    return getJumpToNodes(nodes_stack).map((option) => ({
      label: option,
      startIcon: <JumpTo size={12} />,
    }));
  }, [nodes_stack]);

  if (nodes_stack.length === 0) return children;

  // For backwards compatibility, if the request_id is not present, return children
  if (!nodes_stack?.[0]?.request_id) {
    return children;
  }

  return (
    <div
      className={cn({
        [styles.responsesWrapper]: true,
        [styles.withNodes]: options.length > 0,
        [styles.responsesWrapper__webhook]: type === 'webhook',
      })}
    >
      {options.length > 0 && (
        <div className={styles.dashes}>
          {options.map((option, index) => (
            <Typography
              variant="label-regular"
              // eslint-disable-next-line react/no-array-index-key
              key={`${option.label}-${index}`}
              color="var(--text-default-gray-light)"
              className={styles.dashes__item}
            >
              {option.startIcon}
              {option.label}
            </Typography>
          ))}
        </div>
      )}
      {children}
    </div>
  );
};
