import { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import Documentation from '@/components/atoms/Icons/Global/Documentation';
import { LanguageModelTile } from '@/modules/developerTools/components/LanguageModelTile/LanguageModelTile';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { LANGUAGE_MODELS_DOCS_URL } from '@/util/constants';

import styles from './LanguageModels.module.scss';

export const LanguageModels = () => {
  const { t } = useTranslation();
  const { languageModels, uncreatedProviders } = useLanguageModels();
  const sortByEnabled = useMemo(
    () =>
      languageModels?.sort((a, b) => Number(b.enabled) - Number(a.enabled)) ||
      [],
    [languageModels]
  );

  return (
    <>
      <ContextualHelp
        title={t('developer_tools.contextual_help.language_models_title')}
        name="about_language_models"
        links={[
          {
            icon: <Documentation color="currentColor" />,
            label: t('docs.docs'),
            url: LANGUAGE_MODELS_DOCS_URL,
          },
        ]}
      >
        {t('developer_tools.contextual_help.language_models_message')}
      </ContextualHelp>
      {languageModels?.length > 0 && (
        <>
          <span className={styles.active}>
            <Typography
              variant="h3-semi-bold"
              component="h3"
              className={styles.title}
            >
              {t('developer_tools.connected_models')}
            </Typography>
          </span>
          <div className={styles.models}>
            {sortByEnabled.map((model) => (
              <LanguageModelTile
                model={model}
                key={`${model.provider}:${model.model}`}
              />
            ))}
          </div>
        </>
      )}
      {languageModels?.length > 0 && (
        <Typography
          variant="h3-semi-bold"
          component="h3"
          className={styles.title}
        >
          {t('developer_tools.add_more')}
        </Typography>
      )}
      <div className={styles.models}>
        {uncreatedProviders.map((p) => (
          <LanguageModelTile provider={p} key={p} />
        ))}
      </div>
    </>
  );
};
