import { SVGProps } from 'react';

import { Icon } from '@/models/icon';

const File = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  ...props
}: SVGProps<SVGSVGElement> & Icon) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M9 12h6-6zm0 4h6-6zm8 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default File;
