import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Select from '@/components/atoms/Select/Select';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { isRequired } from '@/util/validator';

const invoiceLanguageOptions: React.ComponentPropsWithoutRef<
  typeof Select
>['options'] = [
  { value: 'ar-AR', label: 'Arabic' },
  { value: 'da-DK', label: 'Danish' },
  { value: 'de-DE', label: 'German' },
  { value: 'en-US', label: 'English' },
  { value: 'es-ES', label: 'Spanish' },
  { value: 'fi-FI', label: 'Finnish' },
  { value: 'fr-FR', label: 'French' },
  { value: 'he-IL', label: 'Hebrew' },
  { value: 'it-IT', label: 'Italian' },
  { value: 'ja-JP', label: 'Japanese' },
  { value: 'nl-NL', label: 'Dutch' },
  { value: 'pt-BR', label: 'Portuguese' },
  { value: 'no-NO', label: 'Norwegian' },
  { value: 'sv-SE', label: 'Swedish' },
];

type Form = {
  invoiceLanguage: string;
};

const InvoiceRecipientFieldset = () => {
  const { t } = useTranslation();
  const { customer, updateCustomer, updateCustomerStatus } = useBilling();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: { invoiceLanguage: 'en-US' },
  });

  const isLoading = customer === undefined;

  useEffect(() => {
    if (customer?.preferred_locales?.[0]) {
      reset({ invoiceLanguage: customer.preferred_locales[0] });
    }
  }, [customer, reset]);

  const onSubmit = useCallback(
    async ({ invoiceLanguage }: Form) =>
      updateCustomer({ preferred_locales: [invoiceLanguage] }),
    [updateCustomer]
  );

  return (
    <FormFieldset
      title={t('billing.invoice_title')}
      subtitle={t('billing.invoice_subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateCustomerStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Select
        name="invoiceLanguage"
        label={t('field.language')}
        register={register('invoiceLanguage', isRequired)}
        errorMessage={errors.invoiceLanguage?.message}
        options={invoiceLanguageOptions}
        size="full"
      />
    </FormFieldset>
  );
};

export default InvoiceRecipientFieldset;
