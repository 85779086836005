import { useCallback, useMemo, useState } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import Select from '@/components/atoms/Select/Select';
import TabsTable from '@/components/atoms/TabsTable/TabsTable';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { durationFormat } from '@/util/util';

import useResponseTimes, {
  ResponseType,
} from '../../../hooks/useResponseTimes';

import styles from './ResponseTimeOverview.module.scss';

export const getOptions = (t: TFunction) => [
  {
    label: t('analytics.average'),
    value: 'average_time',
  },
  {
    label: t('analytics.median'),
    value: 'median_time',
  },
  {
    label: t('analytics.minimum'),
    value: 'min_time',
  },
  {
    label: t('analytics.maximum'),
    value: 'max_time',
  },
];

const ResponseTimeOverview = () => {
  const { t } = useTranslation();

  const [showPrevious, setShowPrevious] = useState(false);
  const [optionValue, setOptionValue] = useState(
    getOptions(t)[0]?.value as ResponseType
  );
  const title = t('analytics.human_resp');

  const firstResponseTime = useResponseTimes({
    intervalProps: {
      firstMessage: true,
      events: '{message:send}',
    },
    skipPrevious: !showPrevious,
    type: optionValue,
  });

  const responseTime = useResponseTimes({
    intervalProps: {
      events: '{message:send}',
    },
    skipPrevious: !showPrevious,
    type: optionValue,
  });

  const handleReload = useCallback(() => {
    if (firstResponseTime?.currentError) {
      firstResponseTime.refetchCurrent();
    }
    if (responseTime?.currentError) {
      responseTime.refetchCurrent();
    }
  }, [firstResponseTime, responseTime]);

  const handlePreviousClick = () => {
    setShowPrevious((prev) => !prev);
  };

  const handleOptionClick = useCallback((e) => {
    setOptionValue(e.target.value);
  }, []);

  const items = useMemo(() => {
    const label = getOptions(t).find(
      (item) => item.value === optionValue
    )?.label;
    return [
      {
        ...firstResponseTime,
        data: {
          ...firstResponseTime.data,
          counts: firstResponseTime.data?.counts
            ? durationFormat(t, firstResponseTime.data?.counts)
            : t('common.na'),
          title: `${label} ${t('analytics.first_response_time')}`,
        },
      },
      {
        ...responseTime,

        data: {
          ...responseTime.data,
          counts: responseTime.data?.counts
            ? durationFormat(t, responseTime.data?.counts)
            : t('common.na'),
          title: `${label} ${t('analytics.response_time')}`,
        },
      },
    ];
  }, [firstResponseTime, optionValue, responseTime, t]);

  const isError = firstResponseTime?.currentError && responseTime?.currentError;

  const isFirstResponseEmpty =
    !firstResponseTime?.currentError &&
    !firstResponseTime.isLoading &&
    !firstResponseTime?.data?.counts;

  const isResponseEmpty =
    !responseTime.isLoading &&
    !responseTime?.currentError &&
    !responseTime?.data?.counts;

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      error={isError}
      onReload={handleReload}
      isEmpty={isFirstResponseEmpty && isResponseEmpty}
      data={items}
    >
      <div className={styles.checkbox}>
        <Select
          options={getOptions(t)}
          value={optionValue}
          onChange={handleOptionClick}
          size="small"
        />
        <Checkbox
          label={<TitleSubtitle subtitle={t('analytics.compare_prev')} />}
          onChange={handlePreviousClick}
          checked={showPrevious}
          sameLevel
        />
      </div>
      <div className={styles.container}>
        <TabsTable items={items} invertArrow />
      </div>
    </AnalyticsFieldset>
  );
};

export default ResponseTimeOverview;
