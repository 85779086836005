import { Icon } from '@/models/icon';

const Sync = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M4.582 9H4V4l.582 5zm0 0a8.001 8.001 0 0115.356 2M4.582 9H9m10.419 6H20v5l-.581-5zm0 0a8.002 8.002 0 01-15.357-2m15.357 2H15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Sync;
