import { Icon } from '@/models/icon';

const Intents = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
    >
      <g fill={color} clipPath="url(#a)">
        <path
          stroke={color}
          strokeWidth=".2"
          d="M6.85 14.777a6.067 6.067 0 0 1-3.145-1.67 6.068 6.068 0 0 1-1.672-3.145h.758a5.323 5.323 0 0 0 1.439 2.62 5.333 5.333 0 0 0 2.62 1.438v.757Zm5.408-5.583a.37.37 0 1 1 0-.742h2.87a.37.37 0 1 1 0 .742h-2.87ZM8.372 4.566a.37.37 0 1 1-.743 0V1.694a.37.37 0 1 1 .743 0v2.872Zm0 11.386a.371.371 0 1 1-.743 0v-2.873c0-.488.743-.488.743 0v2.873Zm-7.5-6.758a.37.37 0 1 1 0-.742h2.87a.37.37 0 1 1 0 .742H.872ZM2.032 7.66a6.067 6.067 0 0 1 1.67-3.145A6.06 6.06 0 0 1 6.85 2.845v.76a5.323 5.323 0 0 0-2.62 1.437A5.308 5.308 0 0 0 2.79 7.661h-.758Zm7.118-4.817a6.06 6.06 0 0 1 3.146 1.67 6.057 6.057 0 0 1 1.67 3.147h-.758A5.318 5.318 0 0 0 11.77 5.04a5.314 5.314 0 0 0-2.62-1.438v-.758Zm4.817 7.118a6.063 6.063 0 0 1-1.671 3.145A6.06 6.06 0 0 1 9.15 14.78v-.758a5.342 5.342 0 0 0 4.059-4.06h.758Z"
        />
        <path
          fillRule="evenodd"
          d="M8.001 7.323a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .823h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Intents;
