import { useTranslation } from 'react-i18next';

import { InformationFieldNew } from '../InformationField/InformationFieldNew';

interface Props {
  rating: 1 | 2 | 3 | 4 | 5;
  feedback: string;
}

import styles from './RatingSection.module.scss';

const RatingSection = ({ rating, feedback }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <InformationFieldNew
        type="rating"
        label={t('analytics.fields.rating')}
        value={rating}
      />
      <InformationFieldNew
        type="feedback"
        label={t('common.feedback')}
        value={feedback}
      />
    </div>
  );
};

export default RatingSection;
