import { useEffect } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { macrosEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { Macro, Macros } from '@/models/macros';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { setInitialOptions } from '@/redux/macros/actions';
import { selectDeskId } from '@/redux/session/selectors';

const API = Object.freeze({
  listMacros: async (deskId: string): Promise<Macros> =>
    callGet(endpoints.macros(deskId)),

  getMacro: async (deskId: string, macroId: string): Promise<Macro> =>
    callGet(endpoints.macro(deskId, macroId)),

  createMacro: async (deskId: string, macro: Macro): Promise<Macro> =>
    callPost(endpoints.macros(deskId), macro),

  updateMacro: async (deskId: string, macro: Macro): Promise<Macro> =>
    callPut(endpoints.macro(deskId, macro.macro_id), macro),

  deleteMacro: async (deskId: string, macroId: string): Promise<Macro> =>
    callDelete(endpoints.macro(deskId, macroId)),
});

export const useMacros = (macroId?: string) => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    data: macros,
    status: listStatus,
    isSuccess: macrosSuccess,
  } = useQuery<Macros, Error>({
    queryKey: [endpoints.macros(deskId)],
    queryFn: () => API.listMacros(deskId),
    enabled: !!deskId,
  });
  useEffect(() => {
    if (macrosSuccess) {
      dispatch(setInitialOptions(macros));
    }
  }, [macrosSuccess, macros, dispatch]);

  const { data: macro, status: macroStatus } = useQuery<Macro, Error>({
    queryKey: [endpoints.macro(deskId, macroId)],
    queryFn: () => API.getMacro(deskId, macroId),
    enabled: !!macroId && !!deskId,
    initialData: macros?.macros.find((macro) => macro.macro_id === macroId),
  });

  const { mutate: updateMacro, status: updateStatus } = useMutation<
    Macro,
    Error,
    Macro
  >({
    mutationFn: (macro) => API.updateMacro(deskId, macro),
    onSuccess: (resp) => {
      queryClient.setQueryData<Macro>(
        [endpoints.macro(deskId, macroId)],
        (prev) => ({ ...prev, ...resp })
      );

      queryClient.setQueryData<Macros>([endpoints.macros(deskId)], (prev) => {
        const result = {
          ...prev,
          macros: (prev?.macros || []).map((macro) =>
            macro.macro_id === resp.macro_id ? resp : macro
          ),
        };
        dispatch(setInitialOptions(result));
        return result;
      });

      dispatch(
        addTemporalToast('success', t('macros.macro_updated', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: addMacro, status: addStatus } = useMutation<
    Macro,
    Error,
    Macro
  >({
    mutationFn: (macro) => API.createMacro(deskId, macro),
    onSuccess: (resp) => {
      queryClient.setQueryData<Macros>([endpoints.macros(deskId)], (prev) => {
        const result = {
          macros: [
            ...(prev.macros || []).filter((m) => m.macro_id !== macroId),
            resp,
          ],
        };
        dispatch(setInitialOptions(result));
        return result;
      });
      dispatch(
        addTemporalToast('success', t('macros.macro_created', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteMacro, status: deleteStatus } = useMutation<
    Macro,
    Error,
    string
  >({
    mutationFn: (macroId) => API.deleteMacro(deskId, macroId),
    onSuccess: (resp) => {
      queryClient.setQueryData<Macros>([endpoints.macros(deskId)], (prev) => {
        const result = {
          macros: (prev?.macros || []).filter(
            (m) => m.macro_id !== resp.macro_id
          ),
        };
        dispatch(setInitialOptions(result));
        return result;
      });
      dispatch(addTemporalToast('success', `Macro deleted`));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    macros: macros?.macros,
    macro,
    listStatus,
    macroStatus,
    addMacro,
    addStatus,
    updateMacro,
    updateStatus,
    deleteMacro,
    deleteStatus,
  };
};
