import { useQuery } from '@tanstack/react-query';

import { callGet } from '@/api/fetcher';
import { Features } from '@/models/account';

import { useAccount } from './useAccount';

const useFeatureFlag = (): Features => {
  const { account } = useAccount();

  const { data: launchdarklyFeatures } = useQuery({
    queryKey: [`/www/features/${account?.account_id}`],
    queryFn: () => callGet(`/www/features/${account?.account_id}`),
    enabled: !!account,
  });
  return {
    ...(account?.features || {}),
    ...(launchdarklyFeatures || {}),
  };
};

export default useFeatureFlag;
