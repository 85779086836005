import Email from '@/components/organisms/Dialogs/Action/Email/Email';
import GoogleSheet from '@/components/organisms/Dialogs/Action/GoogleSheet/GoogleSheet';
import JumpTo from '@/components/organisms/Dialogs/Action/JumpTo/JumpTo';
import Pause from '@/components/organisms/Dialogs/Action/Pause/Pause';
import Reset from '@/components/organisms/Dialogs/Action/Reset/Reset';
import SetVariables from '@/components/organisms/Dialogs/Action/SetVariables/SetVariables';
import Tag from '@/components/organisms/Dialogs/Action/Tag/Tag';
import Text from '@/components/organisms/Dialogs/Action/Text/Text';
import ToolkitActionClose from '@/components/organisms/Toolkit/ToolkitOperations/ToolkitActionClose/ToolkitActionClose';
import ToolkitActionReplay from '@/components/organisms/Toolkit/ToolkitOperations/ToolkitActionReplay/ToolkitActionReplay';
import { Action } from '@/models/action';

import Audio from './Audio';
import Preview from './Preview';
import Webhook from './Webhook/Webhook';

export interface ActionContentProps {
  action: Action;
  actionId: string;
}

function ActionContent({ action }: { action: Action }) {
  const type = action?.type;

  switch (type) {
    case 'text':
      return <Text action={action} />;
    case 'pause':
      return <Pause action={action} />;
    case 'set_variables':
      return <SetVariables action={action} />;
    case 'event':
      return <JumpTo action={action} />;
    case 'webhook':
      return <Webhook action={action} />;
    case 'reset':
      return <Reset action={action} />;
    case 'audio':
      return <Audio action={action} />;
    case 'tag':
      return <Tag action={action} />;
    case 'email':
      return <Email action={action} />;
    case 'googlesheet':
      return <GoogleSheet action={action} />;
    case 'question':
      return <></>;
    case 'close':
      return <ToolkitActionClose />;
    case 'replay':
      return <ToolkitActionReplay />;
    case 'video':
    case 'file':
    case 'url':
    case 'webview':
    case 'image':
    case 'survey':
      return <Preview action={action} />;
    default:
      console.error(`Action type: ${type} unknown`);
      return null;
  }
}

export default ActionContent;
