import { useCallback, useMemo } from 'react';

import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  QueryClient,
  useQueryClient,
  InfiniteData,
} from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SetRequired, PartialDeep } from 'type-fest';

import {
  conversationsEndpoints as endpoints,
  messagesEndpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPatch, callPut } from '@/api/fetcher';
import { useAccount } from '@/hooks/useAccount';
import useUser from '@/hooks/useUser';
import { Agent } from '@/models/account';
import {
  Conversations,
  Conversation,
  SessionActivity,
  ConversationStatusType,
  Sort,
} from '@/models/chat';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import { endpoints as eventsEndpoints } from '@/modules/humanChat/hooks/useConversationEvents';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';

import { endpoints as convCountsEndpoints } from '../hooks/useConversationsCounts';

// ['conversations', deskId, status, sort, agentId, brainId, departmentId]
export const conversationKeys = {
  base: (deskId: string) => ['conversations', deskId] as const,
  unassigned: (deskId: string, status: string, sort: string) =>
    [
      ...conversationKeys.base(deskId),
      status,
      sort,
      'null',
      'null',
      '',
    ] as const,
  byStatus: (deskId: string, status: string, sort: string) =>
    [...conversationKeys.base(deskId), status, sort, '', '', ''] as const,
  byAgent: (deskId: string, status: string, sort: string, agentId: string) =>
    [...conversationKeys.base(deskId), status, sort, agentId, '', ''] as const,
  byBrain: (deskId: string, status: string, sort: string, brainId: string) =>
    [...conversationKeys.base(deskId), status, sort, '', brainId, ''] as const,
  byDepartment: (
    deskId: string,
    status: string,
    sort: string,
    departmentId: string
  ) =>
    [
      ...conversationKeys.base(deskId),
      status,
      sort,
      '',
      '',
      departmentId,
    ] as const,
};

export const statuses = ['open', 'closed', 'spam', 'trash'] as const;
export const sorts = ['newest', 'oldest'] as const;

type listConversationsProps = {
  deskId: string;
  sort: Sort;
  cursor: string;
  agentId: string;
  brainId: string;
  status: 'open' | 'closed' | 'spam' | 'trash';
  departmentId: string;
};

export const API = Object.freeze({
  listConversations: async ({
    deskId,
    sort,
    cursor,
    agentId,
    brainId,
    status,
    departmentId,
  }: listConversationsProps): Promise<Conversations> => {
    return callGet(
      endpoints.conversations({
        id: deskId,
        sort,
        status,
        agent_id: agentId,
        department_id: departmentId,
        brain_id: brainId,
        cursor,
      })
    );
  },

  getConversation: async (
    deskId: string,
    sessionId: string
  ): Promise<Conversation> =>
    callGet(endpoints.conversation(deskId, sessionId)),

  updateConversation: async (
    deskId: string,
    sessionId: string,
    data: Partial<Conversation>
  ): Promise<Conversation> =>
    callPut(endpoints.conversation(deskId, sessionId), data),

  deleteConversation: async (
    deskId: string,
    sessionId: string
  ): Promise<Conversation> =>
    callDelete(endpoints.conversation(deskId, sessionId)),

  updateTyping: async (
    deskId: string,
    sessionId: string,
    data: { action: 'start' | 'stop'; author_type: 'agent' }
  ): Promise<Conversation> =>
    callPatch(endpoints.typing(deskId, sessionId), data),

  updateRead: async (
    deskId: string,
    sessionId: string,
    data: {
      timestamp: number;
      author_type: 'visitor';
    }
  ): Promise<Conversation> =>
    callPatch(endpoints.read(deskId, sessionId), data),
});

type FnProps = {
  queryClient: QueryClient;
  conversation: Conversation;
  deskId: string;
  agents?: Agent[];
  shouldPreventAddToCache?: boolean;
};

/**
 * Updates a conversation if it's already in the cache.
 */
const updateCache = (
  queryClient: QueryClient,
  conversation: SetRequired<PartialDeep<Conversation>, 'session_id' | 'desk_id'>
) => {
  queryClient.setQueryData<Conversation>(
    [endpoints.conversation(conversation.desk_id, conversation.session_id)],
    (prev) => {
      // Don't update if prev doesn't exist
      if (!prev) {
        return prev;
      }
      return merge(prev, conversation);
    }
  );
};

/**
 * Removes a conversation from the QueryClient cache.
 */
const removeFromCache = ({
  queryClient,
  conversation,
  deskId,
  agents,
}: FnProps) => {
  const { session_id, assignee_agent_id, assignee_brain_id, department_id } =
    conversation;

  const removeConversationFromCache = (key: readonly string[]) => {
    queryClient.setQueryData<InfiniteData<Conversations>>(key, (prev) => {
      if (!prev) {
        return prev;
      }
      const newPagesArray = prev.pages.map((page) => ({
        ...page,
        conversations: page.conversations.filter(
          (c) => c.session_id !== session_id
        ),
      }));

      return {
        pages: newPagesArray,
        pageParams: prev.pageParams,
      };
    });
  };

  statuses.forEach((status) => {
    sorts.forEach((sort) => {
      removeConversationFromCache(
        conversationKeys.byStatus(deskId, status, sort)
      );
    });
  });

  // Removes the conversation from the agent
  if (assignee_agent_id) {
    statuses.forEach((status) => {
      sorts.forEach((sort) => {
        agents?.forEach((agent) => {
          removeConversationFromCache(
            conversationKeys.byAgent(deskId, status, sort, agent.agent_id)
          );
          // search for the conversation in the unassigned cache and remove it
          removeConversationFromCache(
            conversationKeys.unassigned(deskId, status, sort)
          );
          // remove conversation from virtual assistant
          removeConversationFromCache(
            conversationKeys.byBrain(deskId, status, sort, 'brain')
          );
        });
      });
    });
  }

  // Removes the conversation from the brain
  if (assignee_brain_id) {
    statuses.forEach((status) => {
      sorts.forEach((sort) => {
        removeConversationFromCache(
          conversationKeys.byBrain(deskId, status, sort, 'brain')
        );
        // search for the conversation in the unassigned cache and remove it
        removeConversationFromCache(
          conversationKeys.unassigned(deskId, status, sort)
        );
        // remove conversation from agents cache
        agents?.forEach((agent) => {
          removeConversationFromCache(
            conversationKeys.byAgent(deskId, status, sort, agent.agent_id)
          );
        });
      });
    });
  }

  if (!assignee_agent_id && !assignee_brain_id) {
    statuses.forEach((status) => {
      sorts.forEach((sort) => {
        // search for the conversation in the unassigned cache and remove it
        removeConversationFromCache(
          conversationKeys.unassigned(deskId, status, sort)
        );
        // remove conversation from virtual assistant
        removeConversationFromCache(
          conversationKeys.byBrain(deskId, status, sort, 'brain')
        );
      });
    });
  }

  if (department_id) {
    statuses.forEach((status) => {
      sorts.forEach((sort) => {
        removeConversationFromCache(
          conversationKeys.byDepartment(deskId, status, sort, department_id)
        );
      });
    });
  }

  // Invalidates the single conversation
  queryClient.invalidateQueries({
    queryKey: [endpoints.conversation(deskId, session_id)],
  });
};

/**
 * Updates a given conversation within paginated conversation data.
 *
 * @param prev - The previous InfiniteData<Conversations> data
 * @param conversation - The conversation to update
 * @return An array where the first element is the new InfiniteData<Conversations> data
 * with the conversation updated, and the second element is a boolean
 * indicating whether the session was found.
 */
export const updateConversationInPages = (
  prev: InfiniteData<Conversations> | undefined,
  conversation: PartialDeep<Conversation>
): [InfiniteData<Conversations> | null, boolean] => {
  if (!prev || Object.keys(prev).length === 0) return [undefined, false];

  const previous = cloneDeep(prev);
  const c = cloneDeep(conversation);

  let found = false;
  for (const page of previous.pages) {
    for (const conv of page.conversations) {
      if (conv.session_id === c.session_id) {
        merge(conv, c);
        found = true;
        break;
      }
    }
    if (found) break;
  }

  return [{ pages: previous.pages, pageParams: previous.pageParams }, found];
};

/**
 * Adds a new conversation to the cache or updates it if it already exists.
 */
const addToCache = ({ queryClient, conversation, deskId }: FnProps) => {
  updateCache(queryClient, conversation);
  const { assignee_agent_id, assignee_brain_id, status, department_id } =
    conversation;
  const addConversationToCache = (key: readonly string[]) => {
    queryClient.setQueryData<InfiniteData<Conversations>>(key, (prev) => {
      if (!prev) {
        return prev;
      }

      const [newPrev, updated] = updateConversationInPages(prev, conversation);

      // If conversation was not updated, add it to the first page
      if (!updated) {
        newPrev.pages?.[0].conversations.unshift(conversation);
      }

      return {
        pages: newPrev.pages,
        pageParams: newPrev.pageParams,
      };
    });
  };

  // all, spam, trash, closed
  sorts.forEach((sort) => {
    statuses.forEach((s) => {
      if (s === status) {
        addConversationToCache(conversationKeys.byStatus(deskId, status, sort));
      }
    });
  });

  // Add to brain
  if (assignee_brain_id) {
    sorts.forEach((sort) => {
      statuses.forEach((s) => {
        if (s === status) {
          addConversationToCache(
            conversationKeys.byBrain(deskId, status, sort, 'brain')
          );
        }
      });
    });
  }

  // Add to agent
  if (assignee_agent_id) {
    sorts.forEach((sort) => {
      statuses.forEach((s) => {
        if (s === status) {
          addConversationToCache(
            conversationKeys.byAgent(deskId, status, sort, assignee_agent_id)
          );
        }
      });
    });
  }

  // Add to unassigned
  if (!assignee_agent_id && !assignee_brain_id) {
    sorts.forEach((sort) => {
      statuses.forEach((s) => {
        if (s === status) {
          addConversationToCache(
            conversationKeys.unassigned(deskId, status, sort)
          );
        }
      });
    });
  }

  // Add to department
  if (department_id) {
    sorts.forEach((sort) => {
      statuses.forEach((s) => {
        if (s === status) {
          addConversationToCache(
            conversationKeys.byDepartment(deskId, status, sort, department_id)
          );
        }
      });
    });
  }
};

/**
 * Invalidates the conversation count queries.
 */
const invalidateCounts = (queryClient: QueryClient, deskId: string) => {
  queryClient.invalidateQueries({
    queryKey: [convCountsEndpoints.counts(deskId)],
  });

  queryClient.invalidateQueries({
    queryKey: [convCountsEndpoints.agentCounts(deskId)],
  });

  queryClient.invalidateQueries({
    queryKey: [convCountsEndpoints.departmentCounts(deskId)],
  });
};

/**
 * Debounces the invalidation of the conversation count queries by 30 seconds.
 */
const debouncedSlowInvalidateCounts = debounce(
  (queryClient: QueryClient, deskId: string) =>
    invalidateCounts(queryClient, deskId),
  30_000 // 30 seconds
);

/**
 * Short debounced function to invalidate the conversation count queries.
 */
const debouncedInvalidateCounts = debounce(
  (queryClient: QueryClient, deskId: string) =>
    invalidateCounts(queryClient, deskId),
  5_000 // 5 seconds
);

const manageConversationCache = async (
  queryClient: QueryClient,
  conversation: Conversation,
  deskId: string,
  agents: Agent[],
  shouldPreventAddToCache?: boolean
): Promise<void> => {
  // conversation
  updateCache(queryClient, conversation);

  //conversations
  removeFromCache({ queryClient, deskId, conversation, agents });
  if (!shouldPreventAddToCache) {
    addToCache({ queryClient, conversation, deskId });
  }

  // Debounce depending on the assignee
  if (conversation.assignee_brain_id) {
    debouncedSlowInvalidateCounts(queryClient, deskId);
  } else {
    debouncedInvalidateCounts(queryClient, deskId);
  }
};

/**
 * Add the conversation
 */
export const onConversationCreated = async ({
  queryClient,
  conversation,
  deskId,
  shouldPreventAddToCache,
}: FnProps) => {
  if (conversation.desk_id !== deskId) {
    return;
  }

  updateCache(queryClient, conversation);

  if (!shouldPreventAddToCache) {
    addToCache({
      queryClient,
      conversation,
      deskId,
    });
  }

  debouncedSlowInvalidateCounts(queryClient, deskId);
};

/**
 * Add the conversation
 */

export const onConversationUpdated = async ({
  queryClient,
  conversation,
  deskId,
  agents,
  shouldPreventAddToCache,
}: FnProps) => {
  if (conversation.desk_id !== deskId) {
    return;
  }
  await manageConversationCache(
    queryClient,
    conversation,
    deskId,
    agents,
    shouldPreventAddToCache
  );
  // update conversation events and messages
  queryClient.invalidateQueries({
    queryKey: [eventsEndpoints.events(deskId, conversation.session_id)],
  });
  queryClient.invalidateQueries({
    queryKey: [messagesEndpoints.messages(deskId, conversation.session_id)],
  });
};

/**
 * Handles conversation state changes on a handover event.
 */
export const onConversationHandover = ({
  queryClient,
  conversation,
  deskId,
  agents,
  shouldPreventAddToCache,
}: FnProps): void => {
  if (conversation.desk_id !== deskId) {
    return;
  }

  manageConversationCache(
    queryClient,
    conversation,
    deskId,
    agents,
    shouldPreventAddToCache
  );
};

/**
 * Handles conversation state changes on a resolved event.
 */
export const onConversationResolved = ({
  queryClient,
  conversation,
  deskId,
  agents,
}: FnProps) => {
  if (conversation.desk_id !== deskId) {
    return;
  }

  manageConversationCache(queryClient, conversation, deskId, agents);
};

/**
 * Updates the last activity time of a conversation.state in the cache.
 */
export const onVisitorActivity = (
  queryClient: QueryClient,
  activity: SessionActivity,
  deskId: string,
  agents: Agent[]
) => {
  const { session_id, timestamp } = activity;
  const conversation = {
    session_id,
    desk_id: deskId,
    state: { last_activity_at: new Date(timestamp).toISOString() },
  };
  // Update individual conversation cache
  updateCache(queryClient, conversation);
  const updateConversation = (key: readonly string[]) => {
    queryClient.setQueryData<InfiniteData<Conversations>>(key, (prev) => {
      if (!prev) {
        return prev;
      }
      const [newPrev] = updateConversationInPages(prev, conversation);
      return {
        pages: newPrev.pages,
        pageParams: newPrev.pageParams,
      };
    });
  };
  // Update the conversation for every status
  statuses.forEach((status) => {
    sorts.forEach((sort) => {
      updateConversation(conversationKeys.byStatus(deskId, status, sort));
    });
  });
  // Update on agents cache
  agents?.forEach((agent) => {
    statuses.forEach((status) => {
      sorts.forEach((sort) => {
        updateConversation(
          conversationKeys.byAgent(deskId, status, sort, agent.agent_id)
        );
      });
    });
  });
  // Update on brain cache
  statuses.forEach((status) => {
    sorts.forEach((sort) => {
      updateConversation(
        conversationKeys.byBrain(deskId, status, sort, 'brain')
      );
    });
  });
  // Update on unassigned cache
  statuses.forEach((status) => {
    sorts.forEach((sort) => {
      updateConversation(conversationKeys.unassigned(deskId, status, sort));
    });
  });
};

/**
 * Handles updates to the conversation cache when a conversation is reopened.
 */
export const onConversationReopened = (
  queryClient: QueryClient,
  conversation: Conversation,
  deskId: string,
  agents?: Agent[]
) => {
  if (conversation.desk_id !== deskId) {
    return;
  }
  manageConversationCache(queryClient, conversation, deskId, agents);
};

type Props = {
  deskId: string;
  sessionId?: string;
  selectedStatus?: ConversationStatusType;
  selectedAgent?: string;
  selectedDepartment?: string;
  selectedBrain?: string;
  selectedSort?: 'newest' | 'oldest';
};

export const useConversationsNew = ({
  deskId,
  sessionId,
  selectedStatus,
  selectedSort,
  selectedAgent,
  selectedDepartment,
  selectedBrain,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { agents } = useAccount();
  const { departments } = useDepartments(deskId);

  const {
    data: conversations,
    status: listStatus,

    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'conversations',
      deskId,
      selectedStatus,
      selectedSort,
      selectedAgent,
      selectedBrain,
      selectedDepartment,
    ],

    queryFn: async ({ pageParam }) => {
      return API.listConversations({
        deskId,
        status: selectedStatus,
        agentId: selectedAgent,
        departmentId: selectedDepartment,
        brainId: selectedBrain,
        sort:
          selectedSort === 'newest' ? '-last_message_at' : 'last_message_at',
        cursor: pageParam?.cursor,
      });
    },
    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
    enabled: !!deskId,
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const { data: conversation, status: getStatus } = useQuery<
    Conversation,
    Error
  >({
    queryKey: [endpoints.conversation(deskId, sessionId)],
    queryFn: () => API.getConversation(deskId, sessionId),
    enabled: !!deskId && !!sessionId,
  });

  const { mutate: updateConversation, status: updateStatus } = useMutation<
    Conversation,
    Error,
    Partial<Conversation>
  >({
    mutationFn: (data) => API.updateConversation(deskId, sessionId, data),
    onSuccess: (resp) => {
      updateCache(queryClient, resp);
      if (resp.status === 'trash') {
        removeFromCache({
          queryClient,
          conversation: resp,
          deskId,
          agents,
        });

        addToCache({
          queryClient,
          conversation: resp,
          deskId,
        });
      }
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteConversation } = useMutation<
    Conversation,
    Error,
    string
  >({
    mutationFn: (id) => API.deleteConversation(deskId, id),
    onSuccess: (resp) => {
      dispatch(
        addTemporalToast('success', t('conversation.conversation_deleted'))
      );
      removeFromCache({
        queryClient,
        conversation: resp,
        deskId,
        agents,
      });
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: updateTyping } = useMutation<
    Conversation,
    Error,
    'start' | 'stop'
  >({
    mutationFn: (action) =>
      API.updateTyping(deskId, sessionId, { action, author_type: 'agent' }),
  });

  const { mutate: updateRead } = useMutation<Conversation, Error>({
    mutationFn: () =>
      API.updateRead(deskId, sessionId, {
        timestamp: Date.now(),
        // visitor messages were read
        author_type: 'visitor',
      }),
    retry: 0,
    onSuccess: () => {
      updateCache(queryClient, {
        session_id: sessionId,
        desk_id: deskId,
        agent_unread_count: 0,
      });

      const updateConvs = (key: readonly string[]) => {
        queryClient.setQueryData<InfiniteData<Conversations>>(key, (prev) => {
          const [newPrev] = updateConversationInPages(prev, {
            session_id: sessionId,
            desk_id: deskId,
            agent_unread_count: 0,
          });
          if (!newPrev) {
            return newPrev;
          }
          return { pages: newPrev.pages, pageParams: newPrev.pageParams };
        });
      };

      // Update the conversation in the 'all' cache
      statuses.forEach((status) => {
        sorts.forEach((sort) =>
          updateConvs(conversationKeys.byStatus(deskId, status, sort))
        );
      });

      // Update the conversation in the agent cache
      statuses.forEach((status) => {
        sorts.forEach((sort) =>
          updateConvs(
            conversationKeys.byAgent(deskId, status, sort, user?.user_id)
          )
        );
      });

      // Update the conversation for department cache
      departments?.forEach((department) => {
        statuses.forEach((status) => {
          sorts.forEach((sort) =>
            updateConvs(
              conversationKeys.byDepartment(
                deskId,
                status,
                sort,
                department.department_id
              )
            )
          );
        });
      });
    },
  });

  const closeConversation = useCallback(() => {
    updateConversation(
      { status: 'closed' },
      {
        onSuccess: () => {
          dispatch(
            addTemporalToast('success', t('conversation.conv_resolved'))
          );
        },
      }
    );
  }, [dispatch, updateConversation, t]);

  const reopenConversation = useCallback(() => {
    updateConversation(
      { status: 'open' },
      {
        onSuccess: () => {
          dispatch(
            addTemporalToast('success', t('conversation.conv_reopened'))
          );
        },
      }
    );
  }, [dispatch, updateConversation, t]);

  const conversationsLength = conversations?.pages?.[0]?.conversations.length;

  const unassignedCount = useMemo(
    () =>
      conversations?.pages?.[0]?.conversations?.filter(
        ({ assignee_brain_id, assignee_agent_id }) =>
          assignee_agent_id === null && assignee_brain_id === null
      ).length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversations?.pages, conversationsLength]
  );

  const flatConversations = useMemo(
    () =>
      [...(conversations?.pages ?? [])].flatMap((page) => page.conversations),
    [conversations?.pages]
  );

  return {
    context: conversation?.context,
    conversation,
    conversations: flatConversations,
    getStatus,
    hasNextPage,
    isActive: conversation?.state?.active,
    isFetching,
    isFetchingNextPage,
    isStatusOpen: conversation?.status === 'open',
    listStatus,
    unassignedCount,
    updateStatus,
    closeConversation,
    fetchNextPage,
    reopenConversation,
    updateConversation,
    deleteConversation,
    updateRead,
    updateTyping,
  };
};
