const Save = ({ size = 16, color = '#1B66D6' }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.667 14H3.333A1.334 1.334 0 0 1 2 12.667V3.333A1.333 1.333 0 0 1 3.333 2h7.334L14 5.333v7.334A1.334 1.334 0 0 1 12.667 14Z" />
      <path d="M11.333 14V8.667H4.666V14M4.667 2v3.333H10" />
    </svg>
  );
};
export default Save;
