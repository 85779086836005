import { memo, useCallback, useEffect, useRef, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/IconButton/IconButton';
import ExportIcon from '@/components/atoms/Icons/Export';
import Trash from '@/components/atoms/Icons/Trash';
import { isKeyEnter } from '@/util/util';

import styles from './KeyValueRow.module.scss';

const muiStyles = {
  keyRoot: {
    borderRight: '1px dashed var(--border-default-gray)',
    height: '100%',
    paddingLeft: '16px',
    fontfamily: 'var(--font-primary)',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-20)',
    flex: '1',
    color: 'var(--text-default-gray)',
  },
  valueRoot: {
    height: '100%',
    paddingLeft: '16px',
    fontfamily: 'var(--font-primary)',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-20)',
    flex: '2',
    color: 'var(--text-default-gray)',
  },
};

interface Props {
  itemKey: string;
  itemValue: string;
  index: number;
  keys: string[];
  onAdd: (
    key: string,
    value: string,
    options?: Record<string, string | number>
  ) => void;
  onUpdate: (
    oldKey: string,
    itemKey: string,
    itemValue: string,
    options?: Record<string, string | number>
  ) => void;
  onKeyBlur?: (
    keyRef: string,
    options?: Record<string, string | number> | string,
    itemValue?: string
  ) => void;
  onDelete: (key: string) => void;
  onUpload?: (
    key: string,
    oldKey: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  size?: 'small' | 'medium';
  showDelete?: boolean;
  error?: boolean;
  readOnly?: boolean;
}

const KeyValueRow = memo(
  ({
    itemKey,
    itemValue,
    index,
    keys,
    onAdd,
    onUpdate,
    onKeyBlur,
    onDelete,
    onUpload,
    size = 'medium',
    showDelete = true,
    error,
    readOnly,
  }: Props) => {
    const { t } = useTranslation();
    const keyRef = useRef<HTMLInputElement>();
    const valueRef = useRef<HTMLInputElement>();
    const [isKeyEmpty, setIsKeyEmpty] = useState(itemKey === '');

    useEffect(() => {
      keyRef.current.value = itemKey;
      valueRef.current.value = itemValue;
    }, [itemKey, itemValue]);

    const handleKeyChange = useCallback((e) => {
      const text = e.target.value.trim();
      keyRef.current.value = text;
      setIsKeyEmpty(keyRef.current.value === '');
    }, []);

    const handleValueChange = useCallback((e) => {
      valueRef.current.value = e.target.value;
    }, []);

    useEffect(() => {
      if (keys.length && !itemKey) {
        keyRef.current.focus();
      }
    }, [itemKey, keys.length]);

    const onValueBlur = useCallback(() => {
      if (itemKey === '' && itemValue === '') {
        if (keyRef.current.value !== '' && valueRef.current.value !== '') {
          onAdd(keyRef.current.value, valueRef.current.value, { index });

          keyRef.current.value = '';
          valueRef.current.value = '';
        }
        return;
      }

      onUpdate(itemKey, keyRef.current.value, valueRef.current.value, {
        focus: 'value',
        index,
      });
    }, [index, itemKey, itemValue, onAdd, onUpdate]);

    const handleKeyBlur = useCallback(() => {
      if (itemKey && itemKey !== keyRef.current.value && keyRef.current.value) {
        onKeyBlur?.(keyRef.current.value, {
          index,
          focus: 'key',
        });
      }

      if (index !== keys.length && !keyRef.current.value) {
        keyRef.current.focus();
      }

      if (index === keys.length) {
        onKeyBlur?.(keyRef.current.value, {
          index,
          focus: 'key',
        });
      }
    }, [itemKey, index, keys.length, onKeyBlur]);

    const onKeyUp = useCallback((e) => {
      if (isKeyEnter(e)) {
        e.target.blur();
        valueRef.current.focus();
      }
    }, []);

    const onValueUp = useCallback((e) => {
      if (isKeyEnter(e)) {
        e.target.blur();
      }
    }, []);

    const handleDelete = useCallback(() => {
      onDelete(itemKey);
    }, [itemKey, onDelete]);

    const handleUpload = async (e) => {
      onUpload?.(keyRef.current.value, itemKey, e);
    };

    return (
      <div
        className={cn(styles.wrapper, styles[size], {
          [styles.error]: error,
        })}
      >
        <InputBase
          inputRef={keyRef}
          inputProps={{ 'aria-label': 'naked' }}
          margin="none"
          fullWidth
          defaultValue={itemKey}
          placeholder={t('common.type_key')}
          autoFocus={!itemKey}
          onKeyUp={onKeyUp}
          onChange={handleKeyChange}
          onBlur={handleKeyBlur}
          sx={muiStyles.keyRoot}
          disabled={readOnly}
        />
        <InputBase
          inputRef={valueRef}
          inputProps={{ 'aria-label': 'naked' }}
          margin="none"
          fullWidth
          defaultValue={itemValue}
          placeholder={t('common.type_value')}
          onKeyUp={onValueUp}
          onBlur={onValueBlur}
          onChange={handleValueChange}
          sx={muiStyles.valueRoot}
          disabled={readOnly}
        />

        {onUpload && !isKeyEmpty && (
          <div className={styles.uploadContainer}>
            <input onChange={handleUpload} type="file" />
            <IconButton onClick={() => {}} ariaLabel={t('common.import')}>
              <ExportIcon size={18} />
            </IconButton>
          </div>
        )}

        {showDelete && !readOnly && (
          <IconButton onClick={handleDelete} ariaLabel={t('common.delete')}>
            <Trash size={18} />
          </IconButton>
        )}

        {error && (
          <span className={styles.errorText}>{t('bundles.duplicate_key')}</span>
        )}
      </div>
    );
  }
);

KeyValueRow.displayName = 'KeyValueRow';

export default KeyValueRow;
