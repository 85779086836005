import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClipboardState {
  clipboard: Record<string, unknown> | null;
}

const defaultState: ClipboardState = {
  clipboard: null,
} as const;

const slice = createSlice({
  name: 'clipboard',
  initialState: defaultState,
  reducers: {
    setClipboard: (
      state,
      action: PayloadAction<Record<string, unknown> | null>
    ) => {
      state.clipboard = action.payload;
    },
  },
});

export const { setClipboard } = slice.actions;

export default slice.reducer;
