import { useState, useEffect } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import useUser from '@/hooks/useUser';
import { selectDeskId } from '@/redux/session/selectors';

import { conversationKeys, API } from './useConversationsNew';

export const usePrefetchConversations = () => {
  const deskId = useSelector(selectDeskId);
  const [fetchAll, setFetchAll] = useState(true);
  const [fetchUnassigned, setFetchUnassigned] = useState(true);
  const [fetchMe, setFetchMe] = useState(true);
  const [urlSearchParams] = useSearchParams();
  const category = urlSearchParams.get('category');
  const { user } = useUser();

  const { data: myConversations } = useInfiniteQuery({
    queryKey: conversationKeys.byAgent(deskId, 'open', 'newest', user?.user_id),
    queryFn: ({ pageParam }) => {
      return API.listConversations({
        deskId,
        status: 'open',
        agentId: user?.user_id,
        departmentId: '',
        brainId: '',
        sort: '-last_message_at',
        cursor: pageParam.cursor,
      });
    },
    enabled: fetchMe && category !== 'me' && !!deskId && !!user,
    staleTime: Infinity,
    refetchOnMount: 'always',
    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
  });

  // fetch `all` conversations only once on mount. We want all messages to be available
  const { data: allConversations } = useInfiniteQuery({
    queryKey: conversationKeys.byStatus(deskId, 'open', 'newest'),
    queryFn: ({ pageParam }) => {
      return API.listConversations({
        deskId,
        status: 'open',
        agentId: '',
        departmentId: '',
        brainId: '',
        sort: '-last_message_at',
        cursor: pageParam.cursor,
      });
    },

    enabled: fetchAll && category !== 'all' && !!deskId,
    staleTime: Infinity,
    refetchOnMount: 'always',
    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
  });

  // fetch `unassigned` conversations only once on mount. We want unassigned messages to be available
  const { data: unassignedConversations } = useInfiniteQuery({
    queryKey: conversationKeys.unassigned(deskId, 'open', 'newest'),
    queryFn: ({ pageParam }) => {
      return API.listConversations({
        deskId,
        status: 'open',
        agentId: 'null',
        departmentId: '',
        brainId: 'null',
        sort: '-last_message_at',
        cursor: pageParam.cursor,
      });
    },

    enabled: fetchUnassigned && category !== 'unassigned' && !!deskId,
    staleTime: Infinity,
    refetchOnMount: 'always',
    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
  });

  useEffect(() => {
    if (fetchAll && allConversations?.pages.length) {
      setFetchAll(false);
    }
  }, [allConversations, fetchAll]);

  useEffect(() => {
    if (fetchUnassigned && unassignedConversations?.pages.length) {
      setFetchUnassigned(false);
    }
  }, [unassignedConversations, fetchUnassigned]);

  useEffect(() => {
    if (fetchMe && myConversations?.pages.length) {
      setFetchMe(false);
    }
  }, [myConversations, fetchMe]);
};
