import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import pick from 'lodash/pick';

import { Dialog } from '@/models/dialog';
import { Entity } from '@/models/entity';
import { Intent } from '@/models/intent';

const defaultState = {
  usedNodes: null,
};

export type Types = 'intent' | 'entity' | 'dialog';

type CacheAction = PayloadAction<{
  brainId: string;
  ieData: Intent[] | Entity[] | null;
  type: Types;
  dialogs: Dialog[];
}>;

export type UsedNodes = {
  [key: string]: {
    label: string;
    dialogId: string;
    nodeId: string;
  }[];
};

export type AllActionsType = {
  action_id: string;
  type: string;
  tags?: string[];
  node_id: string;
  dialog_id: string;
  requisite_idx: number | null;
  dialog_name: string;
  if_no_requisite?: true;
  trigger_node_id?: string | null;
}[];

const all = (dialogs: Dialog[], type: 'intent') => {
  const allNodes = [];
  if (!dialogs) {
    return undefined;
  }
  for (let d = 0; d < dialogs.length; d += 1) {
    for (let n = 0; n < dialogs[d].nodes.length; n += 1) {
      if (dialogs[d].nodes[n].type === type) {
        allNodes.push({
          node_name: dialogs[d].nodes[n].name,
          dialog_id: dialogs[d].dialog_id,
          intent: dialogs[d].nodes[n]?.intent,
          node_id: dialogs[d].nodes[n]?.node_id,
        });
      }
    }
  }
  return allNodes;
};

// returns an array that contains all the actions from all the dialogs (including conditions and requisites)
const getActions = (dialogs: Dialog[]) => {
  const allActions = [];
  if (!dialogs || dialogs.length === 0) {
    return undefined;
  }
  for (let d = 0; d < dialogs.length; d += 1) {
    for (let n = 0; n < dialogs[d].nodes.length; n += 1) {
      for (let a = 0; a < dialogs[d].nodes[n].actions?.length; a += 1) {
        const action = dialogs[d].nodes[n].actions[a];

        const tags = action.type === 'tag' ? action.tags : undefined;
        const trigger_node_id =
          action.type === 'event' ? action.trigger_node_id : null;

        allActions.push({
          action_id: dialogs[d].nodes[n].actions[a].action_id,
          type: dialogs[d].nodes[n].actions[a].type,
          tags,

          node_id: dialogs[d].nodes[n].node_id,
          dialog_id: dialogs[d].dialog_id,
          requisite_idx: null,
          trigger_node_id,
          dialog_name: dialogs[d].name,
        });
      }
      const conditions = dialogs[d]?.nodes[n]?.conditions;
      if (conditions && conditions.length) {
        for (let c = 0; c < conditions?.length; c += 1) {
          for (let a = 0; a < conditions[c].actions?.length; a += 1) {
            const action = conditions[c].actions[a];
            const trigger_node_id =
              action.type === 'event' ? action.trigger_node_id : null;
            const tags = action.type === 'tag' ? action.tags : undefined;
            allActions.push({
              action_id: conditions[c].actions[a].action_id,
              type: conditions[c].actions[a].type,
              tags,
              node_id: dialogs[d].nodes[n].node_id,
              dialog_id: dialogs[d].dialog_id,
              requisite_idx: null,
              trigger_node_id,
              dialog_name: dialogs[d].name,
            });
          }
        }
      }
      const requisites = dialogs[d]?.nodes[n]?.requisites;
      if (requisites && requisites.length) {
        for (let r = 0; r < dialogs[d].nodes[n].requisites?.length; r += 1) {
          for (let a = 0; a < requisites[r].actions?.length; a += 1) {
            allActions.push({
              ...pick(requisites[r].actions[a], ['action_id', 'type', 'tags']),
              node_id: dialogs[d].nodes[n].node_id,
              dialog_id: dialogs[d].dialog_id,
              requisite_idx: r,
              dialog_name: dialogs[d].name,
            });
          }
        }
      }
    }
  }
  return allActions as AllActionsType;
};

function getUsedNodes(allNodes, type, name, uniqueLabels) {
  return allNodes?.reduce((acc, node) => {
    if (node[type] !== name) {
      return acc;
    }
    if (!uniqueLabels.has(node.node_id)) {
      uniqueLabels.add(node.node_id);
      return [
        ...acc,
        {
          label: node.node_name,
          dialogId: node.dialog_id,
          nodeId: node.node_id,
        },
      ];
    }
    return acc;
  }, []);
}

function getUsedDialogs(dialogs, entity) {
  const usedDialogs = [];
  dialogs?.forEach((dialog) => {
    for (const node of dialog.nodes || []) {
      const req = node?.requisites?.find((r) => r?.check_for === `@${entity}`);
      if (req) {
        usedDialogs.push({
          dialogId: dialog.dialog_id,
          label: dialog.name,
        });
        return;
      }
      const rule = (node?.conditions || [])
        .flatMap((c) => c?.rules || [])
        .find((r) => r?.name === `@${entity}`);
      if (rule) {
        usedDialogs.push({
          dialogId: dialog.dialog_id,
          label: dialog.name,
        });
        return;
      }
    }
  });
  return usedDialogs;
}

const slice = createSlice({
  name: 'usedNodesCache',
  initialState: defaultState,
  reducers: {
    setUsedNodesCache: (state, action: CacheAction) => {
      const { brainId, ieData, type, dialogs } = action.payload;
      const uniqueLabels = new Set();
      const cache = {};

      switch (type) {
        case 'intent': {
          const allNodes = all(dialogs, type);

          ieData.forEach((x) => {
            const name = x.intent;
            const usedNodes = getUsedNodes(allNodes, type, name, uniqueLabels);
            cache[`${brainId}_${name}`] = usedNodes;
          });
          state.usedNodes = cache;
          break;
        }

        case 'entity': {
          for (const entityObj of ieData as Entity[]) {
            const entity = entityObj.entity;
            const usedDialogs = getUsedDialogs(dialogs, entity);
            cache[`${brainId}_${entity}`] = usedDialogs;
          }
          state.usedNodes = cache;
          break;
        }

        case 'dialog': {
          const allActions = getActions(dialogs);
          for (const action of allActions) {
            const usedNodes =
              cache[`${brainId}_${action.trigger_node_id}`] || [];
            const isDuplicate = usedNodes.some(
              (item) => item.label === action.dialog_name
            );
            if (!isDuplicate) {
              usedNodes.push({
                dialogId: action.dialog_id,
                label: action.dialog_name,
              });
              cache[`${brainId}_${action.trigger_node_id}`] = usedNodes;
            }
          }
          state.usedNodes = cache;
          break;
        }
      }
    },

    removeUsedNodesCache: (state) => {
      state.usedNodes = null;
    },
  },
});
export const { setUsedNodesCache, removeUsedNodesCache } = slice.actions;

export default slice.reducer;
