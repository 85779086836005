export interface CodeBlockProps {
  children: React.ReactNode;
  isPreTag?: boolean;
}

import styles from './CodeBlock.module.scss';

const CodeBlock = ({ children, isPreTag = false }: CodeBlockProps) => {
  return (
    <code className={styles.block}>
      {isPreTag ? <pre>{children}</pre> : children}
    </code>
  );
};

export default CodeBlock;
