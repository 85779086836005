interface Props {
  text?: string;
  vertical?: boolean;
}

import Typography from '@mui/material/Typography';
import cn from 'classnames';

import styles from './Divider.module.scss';

export const Divider = ({ text, vertical }: Props) => {
  return (
    <div
      className={cn(styles.lineWrapper, {
        [styles.vertical]: vertical,
      })}
    >
      <div className={cn(styles.line, { [styles.vertical]: vertical })}>
        <span
          className={cn(styles.bar, {
            [styles.vertical]: vertical,
          })}
        ></span>
        {text && (
          <Typography className={styles.text} variant="label-semi-bold">
            {text}
          </Typography>
        )}
        {!vertical && <span className={styles.bar}></span>}
      </div>
    </div>
  );
};
