import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { userEndpoints, usersRolesEndpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { User } from '@/models/user';
import {
  Department,
  DepartmentUser,
  DepartmentUsers,
  Departments,
} from '@/modules/departments/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
interface AddMembersProps {
  department_id: string;
  user_ids: string[];
}

export const endpoints = Object.freeze({
  departments: (desk_id: string) => `/www/api/v1/desks/${desk_id}/departments`,
  department: (desk_id: string, department_id: string) =>
    `/www/api/v1/desks/${desk_id}/departments/${department_id || '-'}`,
  departmentUsers: (desk_id: string, department_id: string) =>
    `/www/api/v1/desks/${desk_id}/departments/${department_id}/users`,
  departmentUser: (desk_id: string, department_id: string, user_id: string) =>
    `/www/api/v1/desks/${desk_id}/departments/${department_id}/users/${user_id}`,
});

export const API = Object.freeze({
  listDepartments: async (deskId: string): Promise<Departments> =>
    callGet(endpoints.departments(deskId)),
  listDepartmentUsers: async (
    deskId: string,
    departmentId: string
  ): Promise<DepartmentUsers> =>
    callGet(endpoints.departmentUsers(deskId, departmentId)),

  getDepartment: async (
    deskId: string,
    departmentId: string
  ): Promise<Department> => callGet(endpoints.department(deskId, departmentId)),

  createDepartment: async (
    deskId: string,
    newDepartment: Partial<Department>
  ): Promise<Department> =>
    callPost(endpoints.departments(deskId), newDepartment),

  updateDepartment: async (
    deskId: string,
    department: Partial<Department>
  ): Promise<Department> =>
    callPut(endpoints.department(deskId, department.department_id), department),

  deleteDepartment: async (
    deskId: string,
    departmentId: string
  ): Promise<Department> =>
    callDelete(endpoints.department(deskId, departmentId)),

  addMembers: async (
    deskId: string,
    departmentId: string,
    user_ids: string[]
  ): Promise<DepartmentUsers> =>
    callPost(endpoints.departmentUsers(deskId, departmentId), { user_ids }),

  removeMember: async (
    deskId: string,
    departmentId: string,
    user_id: string
  ): Promise<DepartmentUser> =>
    callDelete(endpoints.departmentUser(deskId, departmentId, user_id)),
});

export const onDepartmentUpdated = (
  queryClient: QueryClient,
  deskId: string,
  department: Department
) => {
  queryClient.setQueryData<Department>(
    [endpoints.department(deskId, department.department_id)],
    (prev: Department) => ({ ...prev, ...department })
  );

  const queryKey = [endpoints.departments(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Departments>(queryKey, (prev: Departments) => ({
      departments: (prev?.departments || []).map((item) =>
        item.department_id === department.department_id
          ? { ...item, ...department }
          : item
      ),
    }));
  }

  const user = queryClient.getQueryData<User>([userEndpoints.user]);
  const key = usersRolesEndpoints.userDepartments(user.user_id);

  if (key) {
    queryClient.invalidateQueries({ queryKey: [key] });
  }
};

export const onDepartmentCreated = (
  queryClient: QueryClient,
  deskId: string,
  department: Department
) => {
  const queryKey = [endpoints.departments(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Departments>(queryKey, (prev) => ({
      departments: [
        ...(prev?.departments || []).filter(
          (item) => item.department_id !== department.department_id
        ),
        department,
      ],
    }));
  }
};

export const onDepartmentRemoved = (
  queryClient: QueryClient,
  deskId: string,
  department_id: string
) => {
  const queryKey = [endpoints.departments(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Departments>(queryKey, (prev: Departments) => ({
      departments: (prev?.departments || []).filter(
        (acc) => acc.department_id !== department_id
      ),
    }));
  }
};

export const onMembersAdded = (
  queryClient: QueryClient,
  deskId: string,
  department_id: string,
  data: DepartmentUsers
) => {
  const queryKey = [endpoints.departmentUsers(deskId, department_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<DepartmentUsers>(queryKey, (prev) => ({
      users: [...(prev?.users || []), ...data.users],
    }));
  }
};

export const onMemberRemoved = (
  queryClient: QueryClient,
  deskId: string,
  data: {
    department_id: string;
    user_id: string;
  }
) => {
  const queryKey = [endpoints.departmentUsers(deskId, data.department_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<DepartmentUsers>(
      queryKey,
      (prev: DepartmentUsers) => {
        return {
          users: (prev?.users || []).filter(
            (acc) => acc.user_id !== data.user_id
          ),
        };
      }
    );
  }
};

export const useDepartments = (deskId: string, departmentId?: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: departments, status: listStatus } = useQuery<
    Departments,
    Error
  >({
    queryKey: [endpoints.departments(deskId)],
    queryFn: () => API.listDepartments(deskId),
    enabled: !!deskId,
  });

  const { data: department, status: getStatus } = useQuery<Department, Error>({
    queryKey: [endpoints.department(deskId, departmentId)],
    queryFn: () => API.getDepartment(deskId, departmentId),
    enabled: !!departmentId && !!deskId,
  });

  const { data: departmentUsers } = useQuery<DepartmentUsers, Error>({
    queryKey: [endpoints.departmentUsers(deskId, departmentId)],
    queryFn: () => API.listDepartmentUsers(deskId, departmentId),
    enabled: !!departmentId && !!deskId,
  });

  const { mutate: updateDepartment, status: updateStatus } = useMutation<
    Department,
    Error,
    Partial<Department>
  >({
    mutationFn: (department) => API.updateDepartment(deskId, department),
    onSuccess: (resp) => {
      onDepartmentUpdated(queryClient, deskId, resp);
      dispatch(
        addTemporalToast('success', t('departments.updated', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createDepartment, status: createStatus } = useMutation<
    Department,
    Error,
    Partial<Department>
  >({
    mutationFn: (department) => API.createDepartment(deskId, department),
    onSuccess: (resp) => {
      onDepartmentCreated(queryClient, deskId, resp);
      dispatch(
        addTemporalToast('success', t('departments.created', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteDepartment, status: deleteStatus } = useMutation<
    Department,
    Error,
    string
  >({
    mutationFn: (id) => API.deleteDepartment(deskId, id),
    onSuccess: (resp) => {
      onDepartmentRemoved(queryClient, deskId, resp.department_id);
      dispatch(addTemporalToast('success', t('departments.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: addMembersToDepartment, status: addMemberStatus } =
    useMutation<DepartmentUsers, Error, AddMembersProps>({
      mutationFn: (data) =>
        API.addMembers(deskId, data.department_id, data.user_ids),
      onSuccess: (resp, variables) => {
        onMembersAdded(queryClient, deskId, variables.department_id, resp);
      },
      onError: (error) => {
        dispatch(addErrorTemporalToast(error));
      },
    });

  const { mutate: removeMemberFromDepartment } = useMutation<
    DepartmentUser,
    Error,
    DepartmentUser
  >({
    mutationFn: (data) =>
      API.removeMember(deskId, data.department_id, data.user_id),
    onSuccess: (resp) => {
      onMemberRemoved(queryClient, deskId, resp);
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    departments: departments?.departments,
    departmentUsers: departmentUsers?.users,
    department,
    getStatus,
    listStatus,
    updateDepartment,
    updateStatus,
    createDepartment,
    createStatus,
    deleteDepartment,
    deleteStatus,
    addMembersToDepartment,
    addMemberStatus,
    removeMemberFromDepartment,
  };
};
