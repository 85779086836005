import { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { capitalizeFirstLetter, removeEmptyObjects } from '@/util/util';

import { ContextVariable } from './ContextVariable';
import { useVariablesSection } from './useVariablesSection';
import { updateContext } from '../../redux/actions';
import { selectContext, selectIsTryItReplay } from '../../redux/selectors';
import { StateContextVariable } from '../../types';
import { ContextSectionWrapper } from '../ContextSectionWrapper/ContextSectionWrapper';

type Props = {
  canEdit: boolean;
};

const EMPTY_VARIABLE = {
  isLocked: false,
  label: null,
  value: null,
};

// This function is used to sort the context variables alphabetically by label
const sortContextVariables = (a, b) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

export const VariablesSection = ({ canEdit }: Props) => {
  // Redux
  const storeContext = useSelector(selectContext);
  const isReplay = useSelector(selectIsTryItReplay);

  // Custom hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formattedContextVariables } = useVariablesSection();

  const defaultStateVariables: StateContextVariable[] =
    formattedContextVariables.map((variable) => ({
      ...variable,
      isLocked: false,
    }));

  // Local state
  const [stateVariables, setStateVariables] = useState(defaultStateVariables);

  // Local variables
  const hideResetIcon =
    !canEdit || isReplay || (stateVariables.length === 0 && canEdit);

  useEffect(() => {
    setStateVariables((prev) => {
      const newVariables = formattedContextVariables.map((variable) => ({
        ...variable,
        isLocked:
          prev.find((x) => x.label === variable.label)?.isLocked || false,
      }));

      return newVariables;
    });
  }, [formattedContextVariables]);

  const toggleLock = (index: number) => {
    setStateVariables((prev) => {
      return prev.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            isLocked: !item.isLocked,
          };
        }
        return item;
      });
    });
  };

  const resetUnlockedVariables = () => {
    setStateVariables((prev) => {
      const filtered = prev.filter((item) => item.isLocked);
      const removed = prev.filter((item) => !item.isLocked);

      const oldContext = cloneDeep(storeContext).slice(0, -1) ?? [];
      let newContext = cloneDeep(storeContext[storeContext.length - 1]) ?? {};

      removed.forEach((item) => {
        newContext = set(newContext, item.label, null);
      });

      dispatch(updateContext([...oldContext, removeEmptyObjects(newContext)]));

      return filtered;
    });
  };

  const hasEmptyVariables =
    formattedContextVariables.length === 0 ||
    formattedContextVariables.some((variable) => variable.value === null);

  if ((!canEdit || isReplay) && hasEmptyVariables) return null;

  return (
    <ContextSectionWrapper
      title={capitalizeFirstLetter(t('common.context_variables'))}
      onClick={resetUnlockedVariables}
      hideIcon={hideResetIcon}
    >
      <Box display="flex" flexDirection="column" rowGap="var(--space-4)">
        {/* List of context variables */}
        {stateVariables.sort(sortContextVariables).map((variable, index) => (
          <ContextVariable
            canEdit={canEdit && !isReplay}
            key={variable.label}
            variable={variable}
            onButtonClick={() => toggleLock(index)}
          />
        ))}

        {/* New context variable field */}
        {canEdit && !isReplay && (
          <ContextVariable canEdit={canEdit} variable={EMPTY_VARIABLE} isLast />
        )}
      </Box>
    </ContextSectionWrapper>
  );
};
