import { useState, useCallback, Fragment } from 'react';

import { useForm, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@/components/atoms/AutoComplete/AutoComplete';
import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import Select from '@/components/atoms/Select/Select';
import { OptionBase } from '@/models/common';
import { submitWithTrimming } from '@/util/util';
import { errorMessage } from '@/util/validator';

import Modal from './Modal';

import styles from './ModalForm.module.scss';

type Props = {
  onCreate: (data) => void;
  resource: string;
  title: string;
  size?: 'small' | 'medium' | 'large';
  primaryButtonText?: string;
  mode?: 'onChange' | 'onSubmit';
  fields?: {
    fieldName: string;
    label: string;
    fieldValue: string;
    readOnly?: boolean;
    rules?: Exclude<
      RegisterOptions,
      'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    >;
    type?: 'input' | 'select' | 'autocomplete' | 'textarea';
    options?: OptionBase[];
    placeholder?: string;
  }[];
  critical?: React.ReactNode;
  banner?: React.ReactNode;
  preventClose?: boolean;
};

const ModalForm = ({
  onCreate,
  resource,
  title,
  fields = [],
  critical,
  size,
  mode = 'onChange',
  banner = null,
  primaryButtonText,
  preventClose,
}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm({
    mode,
    defaultValues: fields.reduce(
      (acc, e) => {
        return {
          ...acc,
          [e.fieldName]: e.fieldValue,
        };
      },
      {} as Record<string, string>
    ),
  });

  const {
    formState: { errors },
    control,
    register,
  } = formMethods;

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        await onCreate(data);
      } catch (error) {
        console.error(error);
      }
    },
    [onCreate]
  );

  return (
    <Modal
      title={title ?? t('modals.create', { 0: resource })}
      onPrimarySubmit={submitWithTrimming(formMethods, onSubmit)}
      primaryButtonText={primaryButtonText ?? t('common.create')}
      isSubmitting={isLoading}
      size={size}
      preventClose={preventClose}
    >
      <div>
        {fields.map(
          (
            {
              fieldName,
              fieldValue,
              readOnly = false,
              rules,
              type = 'input',
              options = [],
              placeholder = '',
              label = '',
            },
            i
          ) => (
            <Fragment key={fieldName}>
              {type === 'input' && (
                <Input
                  name={fieldName}
                  label={label || fieldName.replace('_', ' ')}
                  register={register(fieldName, rules)}
                  errorMessage={errorMessage({
                    field: errors[fieldName],
                    ...rules,
                  })}
                  readOnly={readOnly}
                  placeholder={fieldValue}
                  autoFocus={i === 0}
                />
              )}

              {type === 'select' && (
                <Select
                  name={fieldName}
                  options={options}
                  size="full"
                  label={label || fieldName.replace('_', ' ')}
                  register={register(fieldName, rules)}
                  errorMessage={errorMessage({
                    field: errors[fieldName],
                    ...rules,
                  })}
                  placeholder={placeholder}
                />
              )}
              {type === 'autocomplete' && (
                <Autocomplete
                  control={control}
                  name={fieldName}
                  labelLarge
                  label={label || fieldName}
                  options={options ?? []}
                  placeholder={placeholder}
                  multiple
                  disableAddNew
                  size="large"
                  rules={rules}
                  variant="secondary"
                  error={!!errors?.[fieldName]?.message}
                  errorMessage={errorMessage({
                    field: errors?.[fieldName],
                  })}
                />
              )}
              {type === 'textarea' && (
                <div className={styles.textarea_container}>
                  <TextAreaAsInput
                    name={fieldName}
                    placeholder={t('try_it.modal.type_here')}
                    size="large"
                    rows={12}
                    height="auto"
                    register={register(fieldName, rules)}
                    trimValue
                    errorMessage={errorMessage({
                      field: errors[fieldName],
                      ...rules,
                    })}
                    tooltip={t('integrations.security.tooltip')}
                  />
                </div>
              )}
            </Fragment>
          )
        )}
      </div>
      {critical && <div>{critical}</div>}
      {banner && <div>{banner}</div>}
    </Modal>
  );
};

export default ModalForm;
