const Line = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="192" fill="none">
      <path
        stroke="#C8C8C8"
        strokeDasharray="8 8"
        strokeWidth="3"
        d="m1.5.992 1 191"
      />
    </svg>
  );
};

export default Line;
