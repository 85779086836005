import cn from 'classnames';

import s from './styles.module.scss';

type Props = {
  children?: React.ReactNode;
  variant?: 'neutral' | 'primary' | 'error' | 'success' | 'warning';
};

const Callout = ({ children, variant = 'neutral' }: Props) => {
  return (
    <div className={cn(s.callout, s[variant])}>
      <div className={s.line} />
      <div className={s.background} />
      <div className={s.content}>{children}</div>
    </div>
  );
};

export default Callout;
