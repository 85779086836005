import { useEffect } from 'react';

import Avatar from '@mui/material/Avatar';

import Spinner from '@/components/atoms/Spinner/Spinner';
import useFileUpload from '@/hooks/useFileUpload';

import useFileSubmit from '../FileUpload/useFileSubmit';
interface Props {
  onUpdate: (avatar: { avatar?: string }) => void;
  name?: string;
  label: string;
  avatar?: string;
  isUserAvatar?: boolean;
  readOnly?: boolean;
}

const FieldsetAvatar = ({
  label,
  onUpdate,
  avatar,
  name,
  isUserAvatar,
  readOnly,
}: Props) => {
  const { croppedFile, onAvatarUpload } = useFileUpload();
  const { data, isLoading, uploadFile } = useFileSubmit({
    stream: true,
    uploadPath: isUserAvatar ? `/www/media/me/upload` : undefined,
  });

  useEffect(() => {
    if (croppedFile) {
      uploadFile(croppedFile);
    }
  }, [croppedFile, uploadFile]);

  useEffect(() => {
    if (data) {
      onUpdate({ avatar: data.url });
    }
  }, [data, onUpdate]);

  return (
    <div className="fieldset__avatar">
      {isLoading && <Spinner />}
      <Avatar alt={name} src={avatar} />
      <input
        type="file"
        accept=".png,.jpeg,.jpg"
        aria-label={label}
        disabled={readOnly}
        onChange={onAvatarUpload}
      />
    </div>
  );
};

export default FieldsetAvatar;
