import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import ProgressBar from '@/components/atoms/ProgressBar/ProgressBar';
import {
  useBillingState,
  getPercentage,
} from '@/modules/billing/hooks/useBillingState';
import { selectAccountSlug } from '@/redux/session/selectors';

import styles from './BillingFieldset.module.scss';

const BillingFieldset = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const billingState = useBillingState();

  const { days_left, plan_id, has_expired } = billingState ?? {};
  const slug = useSelector(selectAccountSlug);

  const handleClick = () => {
    navigate(`/${slug}/account/billing`, {
      state: { shouldTriggerAction: Date.now() },
    });
  };

  if (plan_id !== 'trial') {
    return null;
  }

  const title = has_expired
    ? t('home.billing.upgrade')
    : t('home.billing.currently_trial', { 0: plan_id });
  const label = has_expired
    ? t('home.billing.free_expired')
    : t('home.billing.upgrade_within', { 0: days_left });

  return (
    <PlainFieldset fullWidth>
      <div className={styles.fieldContent}>
        <div className={styles.content}>
          <Typography variant="subheading-semi-bold">{title}</Typography>
          <ProgressBar
            percentage={getPercentage(days_left)}
            variant={billingState?.has_expired ? 'error' : 'info'}
          />
          <Typography className={cn({ [styles.expired]: has_expired })}>
            {label}
          </Typography>
        </div>

        <Button onClick={handleClick}> ✨ {t('billing.upgrade')}</Button>
      </div>
    </PlainFieldset>
  );
};

export default BillingFieldset;
