import { useState, useCallback, useEffect } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import FileUpload from '@/components/atoms/FileUpload/FileUpload';
import Select from '@/components/atoms/Select/Select';
import StatusDot from '@/components/atoms/StatusDot/StatusDot';
import Switch from '@/components/atoms/Switch/Switch';
import useBrains from '@/hooks/useBrains';
import useDesks from '@/hooks/useDesks';
import useIntentRecommendation from '@/hooks/useIntentRecommendation';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { UploadedFile } from '@/models/server';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { FILES_BASE_URL } from '@/util/constants';

import styles from './styles.module.scss';

type Props = {
  brain?: Brain;
};

const sourceTypes = [
  { value: 'logs', label_key: 'brains.auto_ai.source.logs' },
  { value: 'brain', label_key: 'brains.auto_ai.source.brain' },
  { value: 'desk', label_key: 'brains.auto_ai.source.desk' },
];

const RecommendationFieldset = ({ brain }: Props) => {
  const { t } = useTranslation();
  const brainId = brain?.brain_id;
  const { brains } = useBrains();
  const { desks } = useDesks();
  const { recommendation, configureRecommendation, configureStatus } =
    useIntentRecommendation(brainId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const [enabled, setEnabled] = useState(false);
  const [sourceType, setSourceType] = useState<'brain' | 'logs' | 'desk'>(
    'brain'
  );
  const [selectedBrainId, setSelectedBrainId] = useState('');
  const [selectedDeskId, setSelectedDeskId] = useState('');
  const [logs, setLogs] = useState('');

  useEffect(() => {
    if (!isEmpty(recommendation)) {
      setEnabled(recommendation.enabled);
      setSourceType(recommendation.source_type);
      setSelectedBrainId(recommendation?.source_brain_id);
      setSelectedDeskId(recommendation?.source_desk_id || '');
      setLogs(recommendation.source_logs || '');
    }
    if (!recommendation?.source_brain_id) {
      setSelectedBrainId(brainId);
    }
  }, [brainId, recommendation]);

  const onSubmit = useCallback(() => {
    configureRecommendation({
      enabled,
      source_type: sourceType,
      source_brain_id: sourceType === 'brain' ? selectedBrainId : null,
      source_desk_id: sourceType === 'desk' ? selectedDeskId : null,
      source_logs: sourceType === 'logs' ? logs : null,
    });
  }, [
    configureRecommendation,
    enabled,
    sourceType,
    selectedBrainId,
    selectedDeskId,
    logs,
  ]);

  const handleEnableChange = useCallback(() => {
    setEnabled(!enabled);
  }, [enabled]);

  const handleSourceTypeChange = useCallback((e) => {
    setSourceType(e.target.value);
  }, []);

  const handleBrainSelectChange = useCallback((e) => {
    setSelectedBrainId(e.target.value);
  }, []);

  const handleDeskSelectChange = useCallback((e) => {
    setSelectedDeskId(e.target.value);
  }, []);

  const handleFileUpload = useCallback(async ({ url }: UploadedFile) => {
    setLogs(url);
  }, []);

  const brainOptions =
    brains?.map(({ brain_id, name }) => ({
      value: brain_id,
      label: name,
    })) || [];

  const deskOptions =
    desks?.map(({ desk_id, name }) => ({
      value: desk_id,
      label: name,
    })) || [];

  const isConfigValid =
    (sourceType === 'brain' && selectedBrainId) ||
    (sourceType === 'desk' && selectedDeskId) ||
    (sourceType === 'logs' && logs);

  const enableButton =
    isConfigValid &&
    ((recommendation?.enabled ?? false) !== enabled ||
      (recommendation?.source_type ?? 'brain') !== sourceType ||
      (sourceType === 'brain' &&
        (recommendation?.source_brain_id ?? brainId) !== selectedBrainId) ||
      (sourceType === 'desk' &&
        recommendation?.source_desk_id !== selectedDeskId) ||
      (sourceType === 'logs' && recommendation?.source_logs !== logs));

  const statusCode = recommendation?.status_code?.toLowerCase();
  const statusDescription = statusCode
    ? t(
        `brains.auto_ai.errors.${statusCode}`,
        t('brains.auto_ai.errors.default')
      )
    : undefined;

  return (
    <div className={styles.brain_settings_fields}>
      <FormFieldset
        title={t('brains.auto_ai.title')}
        subtitle={t('brains.auto_ai.subtitle')}
        isLoading={!brainId}
        primaryButton={{
          children: t('common.save'),
          disabled: !enableButton || !canWrite,
          isLoading: configureStatus === 'pending',
        }}
        onSubmit={onSubmit}
      >
        <div className={styles.brain_field__data}>
          <Switch
            name="enabled"
            label={t('common.enabled')}
            checked={enabled}
            size="medium"
            onChange={handleEnableChange}
            disabled={!canWrite}
          />
        </div>

        {enabled && (
          <>
            <Select
              required
              name="source_type"
              options={sourceTypes}
              placeholder={t('brains.auto_ai.source_placeholder')}
              label={t('brains.auto_ai.source_type')}
              onChange={handleSourceTypeChange}
              value={sourceType}
              size="large"
              disabled={!canWrite}
            />
            {sourceType === 'brain' && (
              <Select
                required
                name="brains"
                options={brainOptions}
                placeholder={t('brains.auto_ai.select_brain')}
                label="brains"
                onChange={handleBrainSelectChange}
                value={selectedBrainId}
                size="large"
                disabled={!canWrite}
              />
            )}
            {sourceType === 'desk' && (
              <>
                <Select
                  required
                  name="desks"
                  options={deskOptions}
                  placeholder={t('brains.auto_ai.select_desk')}
                  label="desks"
                  onChange={handleDeskSelectChange}
                  value={selectedDeskId}
                  size="large"
                  disabled={!canWrite}
                />
                <p className="small">{t('brains.auto_ai.source_desk_help')}</p>
              </>
            )}
            {sourceType === 'logs' && (
              <div className="input__container">
                <FileUpload
                  message={t('brains.auto_ai.drag_and_drop')}
                  onFileUploaded={handleFileUpload}
                  maxSizeInBytes={50000000} // 50MB
                  stream
                  uploadPath={`${FILES_BASE_URL}/brains/${brainId}/upload`}
                  accept={{
                    'text/cvs': ['.csv'],
                    'application/vnd.ms-excel': ['.xls'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                      ['.xlsx'],
                  }}
                  // This is kind of a hack but we store files
                  // as ${timestamp}___${fileName}
                  fileName={logs?.split('___')?.[1]}
                  parseJson={false}
                />
                <p className="small">{t('brains.auto_ai.source_logs_help')}</p>
              </div>
            )}

            {recommendation?.last_trained && (
              <>
                <div className={styles.brain_field__label}>
                  {t('brains.last_trained')}:{' '}
                </div>
                <div className={styles.brain_field__data}>
                  {moment(recommendation.last_trained).fromNow()}
                </div>
              </>
            )}
            {recommendation?.status && (
              <>
                <div className={styles.brain_field__label}>
                  {t('common.status')}
                </div>
                <span
                  className={cn([
                    styles.brain_field__data,
                    styles.brain_field__data_status,
                  ])}
                >
                  <StatusDot status={recommendation?.status} showLabel />
                </span>
              </>
            )}
            {statusDescription && (
              <>
                <div className={styles.brain_field__label}>
                  {t('brains.auto_ai.status_desc')}
                </div>
                <span
                  className={cn([
                    styles.brain_field__data,
                    styles.brain_field__data,
                  ])}
                >
                  <p className="small">{statusDescription || ''}</p>
                </span>
              </>
            )}
          </>
        )}
      </FormFieldset>
    </div>
  );
};

export default RecommendationFieldset;
