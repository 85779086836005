import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ConnectionStatus from '@/components/atoms/Accordion/templates/ConnectionStatusAccordion';
import ActiveFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/ActiveFieldset';
import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import { useAccordion } from '@/hooks/useAccordion';
import { useFront } from '@/hooks/useFront';
import { AccordionType, FrontIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import FrontMode from './components/FrontMode';
import FrontOauthFieldset from './components/FrontOauthFieldset';
import FrontPreferences from './components/FrontPreferences';

const Front = () => {
  const { t } = useTranslation();
  const stateRef = useRef(null);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const { toggleAccordion, integration, accordions, registerAccordion } =
    useAccordion<FrontIntegration>(canWrite);

  const { isConnected, onOAuthClick } = useFront();

  if (!integration) {
    return null;
  }

  if (!isConnected) {
    return (
      <>
        <FrontOauthFieldset disabled={!canWrite} onAuthClick={onOAuthClick} />
        <DeleteFieldset integration={integration} />
      </>
    );
  }

  return (
    <>
      <ConnectionStatus
        type={AccordionType.VERIFICATION_STATUS}
        status={integration?.status}
        toggleAccordion={toggleAccordion}
        registerAccordion={registerAccordion}
        conSettingsRef={
          accordions?.current[AccordionType.CONNECTION_SETTINGS]?.ref
        }
        stateRef={stateRef.current}
      />
      <FrontMode
        type={AccordionType.FRONT_MODE}
        integration={integration}
        toggleAccordion={toggleAccordion}
        registerAccordion={registerAccordion}
      />
      <FrontPreferences
        type={AccordionType.FRONT_PREFERENCES}
        integration={integration}
        toggleAccordion={toggleAccordion}
        registerAccordion={registerAccordion}
      />
      <div ref={stateRef}>
        <ActiveFieldset
          integration={integration}
          title={t('integrations.status.title')}
          subtitle={t('integrations.status.subtitle')}
        />
      </div>
      <DeleteFieldset integration={integration} disabled={!canWrite} />
    </>
  );
};

export default Front;
