import { PropsWithChildren } from 'react';

type StatusIconProps = {
  status: Variants;
};

import { IconsByState, Variants } from '@/components/atoms/Icons/IconsByState';

import styles from './StatusIcon.module.scss';

export const StatusIcon = ({
  status,
  children,
}: PropsWithChildren<StatusIconProps>) => {
  return (
    <span className={styles.wrapper}>
      {IconsByState[status]}
      {children}
    </span>
  );
};
