import SessionsFieldset from '@/components/pages/Logs/fieldsets/SessionsFieldset/SessionsFieldset';
import { PageName } from '@/models/segment';
import Filters from '@/modules/analytics/components/filters/AccountFilters/AccountFilters';

import useSegments from '../hooks/useSegment';

export const HumanChatLogs = () => {
  useSegments(PageName.ANALYTICS_ACCOUNT_CHAT_LOGS);
  return (
    <>
      <Filters />
      <SessionsFieldset show />
    </>
  );
};
