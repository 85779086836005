import { TypographyOptions } from '@mui/material/styles/createTypography';

const BASE_STYLES = {
  fontSize: 'var(--space-12)',
  letterSpacing: '0',
  lineHeight: '1.4',
} as const;

const typography = {
  ['label-caps-small']: {
    ...BASE_STYLES,
    fontSize: 'var(--space-10)',
    textTransform: 'uppercase',
    fontWeight: '600',
  },

  ['label-caps-large']: {
    ...BASE_STYLES,
    textTransform: 'uppercase',
    fontWeight: '600',
  },

  ['label-regular']: {
    ...BASE_STYLES,
    fontWeight: '400',
  },

  ['label-semi-bold']: {
    ...BASE_STYLES,
    fontWeight: '600',
  },
  ['label-bold']: {
    ...BASE_STYLES,
    fontWeight: '700',
  },
} as TypographyOptions;

export { typography };
