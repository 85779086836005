import React, { useCallback, useEffect, useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab/Tab';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import Input from '@/components/atoms/Input/Input';
import Inputs from '@/components/atoms/Inputs/Inputs';
import Link from '@/components/atoms/Link/Link';
import useDatasources from '@/hooks/useDatasources';
import { WebsiteConfig } from '@/models/collections';
import { popModal, pushModal } from '@/redux/modals/actions';
import {
  selectCollectionId,
  selectDatasourceId,
} from '@/redux/session/selectors';
import { EXCLUDED_URLS_URL, SEED_URLS_URL } from '@/util/constants';
import { datasourceRules, errorMessage } from '@/util/validator';

import { IntercomDetect, ZendeskDetect } from './ExternalKbDetect';
import { MODAL_DATASOURCE_ADD } from '../../ModalConductor';
import { Form, cleanHttpFromPastedUrl } from '../utils';

import styles from './ImportUrls.module.scss';

const tabListStyle = {
  height: 40,
  marginBottom: 'var(--space-16)',
};

const tabPanelStyle = {
  padding: 0,
  paddingBottom: 'var(--space-16)',
};

const tabStyle = {
  margin: 0,
  color: 'var(--text-default-black)',
  fontWeight: '400',
  padding: '0 var(--space-12)',
  marginRight: 'var(--space-12)',
  '&.Mui-selected': {
    color: 'var(--text-default-black)',
    fontWeight: '600',
  },
};

type UrlType = 'seed' | 'single' | 'exclude';

type ImportUrlsProps = {
  setEnablePrimaryButton: (enable: boolean) => void;
  externalKb: { type: string; config?: { subdomain: string } };
  resetForm: () => void;
  submitCountRef: React.MutableRefObject<number>;
};

const ImportUrls = ({
  setEnablePrimaryButton,
  externalKb,
  resetForm,
  submitCountRef,
}: ImportUrlsProps) => {
  const showDetectionBanner = submitCountRef.current === 1;
  const collectionId = useSelector(selectCollectionId);
  const datasourceId = useSelector(selectDatasourceId);
  const { datasource } = useDatasources(collectionId, datasourceId);
  const seedUrls = (datasource?.config as WebsiteConfig)?.seed_urls;
  const excludeUrls = (datasource?.config as WebsiteConfig)?.exclude_urls;
  const [tab, setTab] = useState('1');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors, isDirty, isValid },
    getValues,
    reset,
    setFocus,
  } = useFormContext<Form>();

  const {
    fields: seedUrlFields,
    prepend: seedUrlPrepend,
    append: seedUrlAppend,
    remove: seedUrlRemove,
  } = useFieldArray({
    control,
    name: 'website.seed_urls',
  });

  const {
    fields: excludeUrlFields,
    prepend: excludeUrlPrepend,
    append: excludeUrlAppend,
    remove: excludeUrlRemove,
  } = useFieldArray({
    control,
    name: 'website.exclude_urls',
  });

  useEffect(() => {
    const currentValues = getValues();

    // Handle seedUrls
    if (seedUrls?.length > 0) {
      const newSeedUrls = seedUrls.map((url) => {
        const cleanedUrl = url.endsWith('/') ? url.slice(0, -1) : url;
        return {
          name: cleanedUrl.replace(/^https?:\/\//i, ''),
        };
      });

      newSeedUrls.unshift({ name: '' });
      newSeedUrls.forEach((url) => seedUrlAppend(url));

      // Update currentValues for reset
      currentValues.website.seed_urls = newSeedUrls;
    }

    // Handle excludedUrls
    if (excludeUrls?.length > 0) {
      const newExcludeUrls = excludeUrls.map((url) => {
        const cleanedUrl = url.endsWith('/') ? url.slice(0, -1) : url;
        return {
          name: cleanedUrl.replace(/^https?:\/\//i, ''),
        };
      });

      newExcludeUrls.unshift({ name: '' });
      newExcludeUrls.forEach((url) => {
        excludeUrlAppend(url);
      });

      // Update currentValues for reset
      currentValues.website.exclude_urls = newExcludeUrls;
    }

    reset(currentValues);
    const timer = setTimeout(() => {
      setFocus('website.seed_urls.0.name');
    }, 0);

    return () => clearTimeout(timer);
  }, [
    seedUrls,
    excludeUrls,
    getValues,
    reset,
    excludeUrlAppend,
    seedUrlAppend,
    setFocus,
  ]);

  useEffect(() => {
    if (tab === '1') {
      setFocus('website.seed_urls.0.name');
    } else if (tab === '2') {
      setFocus('website.exclude_urls.0.name');
    }
  }, [setFocus, tab]);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleCreate = useCallback(
    (type: UrlType) => {
      resetForm();
      if (type === 'seed') {
        seedUrlPrepend({ name: '' });
      } else {
        excludeUrlPrepend({ name: '' });
      }
    },
    [excludeUrlPrepend, resetForm, seedUrlPrepend]
  );

  const handleDelete = useCallback(
    (type: UrlType, index: number) => {
      resetForm();
      if (type === 'seed') {
        seedUrlRemove(index);
      } else {
        excludeUrlRemove(index);
      }
    },
    [excludeUrlRemove, resetForm, seedUrlRemove]
  );

  const seedValues = useWatch({
    control,
    name: 'website.seed_urls',
  });

  const excludeValues = useWatch({
    control,
    name: 'website.exclude_urls',
  });

  const { website } = getValues();

  const seedUrlsNumber =
    website?.seed_urls.filter(({ name }) => name !== '')?.length ?? 0;

  const excludeUrlsNumber =
    website?.exclude_urls.filter(({ name }) => name !== '')?.length ?? 0;

  const isEmpty = seedUrlsNumber === 0 && !website?.sitemap_urls;

  // Conditions to show the detection banners
  const detectedZendeskSubdomain =
    showDetectionBanner && externalKb?.type === 'zendesk_kb';
  const detectIntercomSubdomain =
    showDetectionBanner && externalKb?.type === 'intercom_kb';

  const handleSwitchToExternalKbClick = ({
    isImportZendesk,
    isImportIntercom,
    subdomain,
  }: {
    isImportZendesk?: boolean;
    isImportIntercom?: boolean;
    subdomain?: string;
  }) => {
    dispatch(popModal());
    dispatch(
      pushModal(MODAL_DATASOURCE_ADD, {
        isImportZendesk,
        isImportIntercom,
        zendeskSubdomain: subdomain,
      })
    );
  };

  useEffect(() => {
    if (errors?.website || isEmpty || !isDirty) {
      setEnablePrimaryButton(false);
    } else {
      setEnablePrimaryButton(true);
    }
  }, [errors?.website, isDirty, isEmpty, isValid, setEnablePrimaryButton]);

  const getValidator = useCallback(
    (urls) => ({
      ...datasourceRules.url,
      validate: {
        ...datasourceRules.url.validate,
        isIncluded: (v) =>
          !urls.map((url) => url.name).includes(v) ||
          t('collections.url_exists'),
      },
    }),
    [t]
  );

  return (
    <TabContext value={tab}>
      <TabList onChange={handleChange} sx={tabListStyle}>
        <Tab
          label={`${t('collections.seed_url')}
             ${seedUrlsNumber > 0 ? `(${seedUrlsNumber})` : ''}
            `}
          value="1"
          sx={tabStyle}
        />

        <Tab
          label={`${t('collections.exclude_url')}
             ${excludeUrlsNumber > 0 ? `(${excludeUrlsNumber})` : ''}
            `}
          value="2"
          sx={tabStyle}
        />

        <Tab
          label={`${t('collections.sitemap')}
            ${website.sitemap_urls.length ? '(1)' : ''}
           `}
          value="3"
          sx={tabStyle}
        />
      </TabList>
      <div className={styles.container}>
        <TabPanel value="1" sx={tabPanelStyle}>
          <Banner variant="neutral" relativePosition padding="small">
            <Trans
              i18nKey="collections.seed_url_description"
              components={{
                link1: <Link href={SEED_URLS_URL} external />,
              }}
            />
          </Banner>
          <div className={styles.inputsContainer}>
            <Inputs
              key={tab}
              fields={seedUrlFields}
              errors={errors.website?.seed_urls}
              register={register}
              values={seedValues}
              onCreate={() => handleCreate('seed')}
              onDelete={(index) => handleDelete('seed', index)}
              namePrefix={'website.seed_urls'}
              validator={getValidator(seedValues)}
              onPaste={cleanHttpFromPastedUrl}
              placeholder="example.com"
              prefix="https://"
              onChange={resetForm}
            />

            {detectedZendeskSubdomain && (
              <ZendeskDetect
                onClick={() =>
                  handleSwitchToExternalKbClick({
                    isImportZendesk: true,
                    subdomain: externalKb?.config?.subdomain,
                  })
                }
              />
            )}

            {detectIntercomSubdomain && (
              <IntercomDetect
                onClick={() =>
                  handleSwitchToExternalKbClick({ isImportIntercom: true })
                }
              />
            )}
          </div>
        </TabPanel>

        <TabPanel value="2" sx={tabPanelStyle}>
          <Banner variant="neutral" relativePosition padding="small">
            <Trans
              i18nKey={'collections.exclude_url_description'}
              components={{
                link1: <Link href={EXCLUDED_URLS_URL} external />,
              }}
            />
          </Banner>

          <div className={styles.inputsContainer}>
            <Inputs
              fields={excludeUrlFields}
              errors={errors.website?.exclude_urls}
              register={register}
              values={excludeValues}
              onCreate={() => handleCreate('exclude')}
              onDelete={(index) => handleDelete('exclude', index)}
              namePrefix={'website.exclude_urls'}
              validator={getValidator(excludeValues)}
              onPaste={cleanHttpFromPastedUrl}
              placeholder="example.com"
              prefix="https://"
              onChange={resetForm}
            />
          </div>
        </TabPanel>

        <TabPanel value="3" sx={tabPanelStyle}>
          <Banner variant="neutral" relativePosition padding="small">
            {t('collections.sitemap_description')}
          </Banner>
          <div className={styles.inputsContainer}>
            <Input
              name="website.sitemap_urls"
              size="small"
              register={register('website.sitemap_urls', datasourceRules.url)}
              errorMessage={errorMessage({
                field: errors.website?.sitemap_urls,
                maxLength: datasourceRules.url.maxLength,
              })}
              autoFocus
            />
          </div>
        </TabPanel>
      </div>
    </TabContext>
  );
};

export default ImportUrls;
