export const listStyles = {
  width: '300px',
  maxHeight: '368px',
  height: 'auto',
  overflow: 'auto',
  border: '1px solid var(--border-default-gray)',
  boxShadow: 'var(--shadow-primary)',
  borderRadius: 'var(--space-4)',
  color: 'var(--text-default-gray)',
  position: 'relative',
  '& ul': { padding: 0 },
  bgcolor: 'background.paper',
  padding: 'var(--space-4)',
};

export const listSubheaderStyles = {
  backgroundColor: 'var(--surface-primary-white)',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const listItemButtonStyles = {
  height: '32px',
  '&.Mui-selected': {
    backgroundColor: 'var(--surface-secondary-gray)',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'var(--surface-secondary-gray)',
  },
  display: 'flex',
  alignItems: 'center',
};

export const typographyStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1.2,

  '& .locker': {
    transform: 'translateY(2px)',
    display: 'inline-flex',
  },
};
