import { useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';

import { Badge } from '@/components/atoms/Badge/Badge';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Environment from '@/components/atoms/Icons/Environment';
import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import ListItem from '@/components/atoms/ListItem/ListItem';
import Resizable from '@/components/atoms/Resizable/Resizable';
import { useAccount } from '@/hooks/useAccount';
import useDesks from '@/hooks/useDesks';
import { useInvitations } from '@/hooks/useInvitations';
import { usePermissions } from '@/hooks/usePermissions';
import { RoleType } from '@/models/member';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import { personalSettings, accountSettings } from './constants';

import styles from './SettingsNavigation.module.scss';

const MIN_WIDTH = 192;
const MAX_WIDTH = 224;

export const SettingsNavigation = () => {
  const location = useLocation();
  const { slug, accountRole } = useAccount();
  const { hasInvitations } = useInvitations();
  const { handleCreateDesk } = useDesks();
  const { desks, maxDesksReached } = useDesks();
  const { t } = useTranslation();
  const { permissions } = usePermissions();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const canReadBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.READ)
  );
  const canDelete = useSelector((state: RootState) =>
    getPermissions(state, 'accounts', actions.DELETE)
  );

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'desks', actions.WRITE)
  );
  const isOwner = accountRole === RoleType.OWNER;

  const canSeeAuditLogs =
    isOwner ||
    permissions?.find(
      ({ permission, allow }) => permission === 'audit_logs.read' && allow
    );

  const handleDesksClick = () => {
    setIsCollapsed((prev) => !prev);
  };

  const disableCreateDesk = maxDesksReached || !canWrite;
  const disableCreateDeskTooltip = maxDesksReached
    ? t('limits.desks_reached')
    : t('desks.no_permission');

  const allowedSettings = accountSettings.filter(({ title_key }) => {
    if (title_key === 'pages.billing_simple' && !canReadBilling) {
      return false;
    }

    if (title_key === 'common.danger_zone' && !canDelete) {
      return false;
    }

    if (!canSeeAuditLogs && title_key === 'pages.activity_logs') {
      return false;
    }

    if (!canReadBilling && title_key === 'pages.invoices') {
      return false;
    }

    if (!isOwner && title_key === 'common.data_privacy') {
      return false;
    }
    return true;
  });

  return (
    <div className={cn(styles.container)}>
      <Resizable
        name="accountSettings"
        height={window.innerHeight}
        className={cn(styles.resizable, styles.sidebar)}
        max={MAX_WIDTH}
        min={MIN_WIDTH}
        defaultWidth={MAX_WIDTH}
      >
        <div className={cn(styles.listsContainer)}>
          <ul>
            <ListItem text={t('profile.account_settings')} type="title" />
            {allowedSettings.map(({ title_key, link, icon }) => {
              return (
                <NavLink to={`/${slug}${link}`} key={title_key}>
                  <ListItem
                    mainIcon={icon}
                    active={location.pathname.includes(link)}
                    text={t(title_key)}
                  />
                </NavLink>
              );
            })}
          </ul>
          <ul>
            <ListItem text={t('profile.personal_settings')} type="title" />
            {personalSettings.map(({ title_key, icon, link }) => {
              if (link.includes('invitations')) {
                return (
                  <NavLink to={link} key={title_key}>
                    <ListItem
                      mainIcon={icon}
                      active={location.pathname.includes(link)}
                    >
                      <Badge
                        variant="dot"
                        color="error"
                        invisible={!hasInvitations}
                        overlap="rectangular"
                      >
                        {t(title_key)}
                      </Badge>
                    </ListItem>
                  </NavLink>
                );
              }

              return (
                <NavLink to={link} key={title_key}>
                  <ListItem
                    mainIcon={icon}
                    active={location.pathname.includes(link)}
                    text={t(title_key)}
                  />
                </NavLink>
              );
            })}
          </ul>
          <ul
            className={cn(styles.desksContainer, {
              [styles.isCollapsed]: isCollapsed,
            })}
          >
            <ListItem
              text={t('common.environments')}
              type="title"
              isCollapsible
              onItemClick={handleDesksClick}
              icons={[
                <IconButton
                  onClick={handleCreateDesk}
                  key={0}
                  tooltip={disableCreateDesk ? disableCreateDeskTooltip : ''}
                  disabled={disableCreateDesk}
                  ariaLabel={t('environments.create')}
                >
                  <PlusCircle
                    color={
                      !disableCreateDesk
                        ? 'var(--icon-default-blue)'
                        : 'var(--icon-default-gray)'
                    }
                  />
                </IconButton>,
              ]}
            />
            <div className={cn(styles.desks)}>
              {!isCollapsed && (
                <>
                  {desks?.length > 0 ? (
                    desks?.map(({ name, desk_id }) => {
                      return (
                        <NavLink
                          to={`/${slug}/environments/${desk_id}`}
                          key={desk_id}
                        >
                          <ListItem
                            mainIcon={<Environment />}
                            active={location.pathname.includes(desk_id)}
                            text={name}
                          />
                        </NavLink>
                      );
                    })
                  ) : (
                    <ListItem disabled text={t('environments.no_env_yet')} />
                  )}
                </>
              )}
            </div>
          </ul>
        </div>
      </Resizable>
    </div>
  );
};
