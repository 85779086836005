import { Props } from '@/models/icon';

export const UserPlusIcon = ({
  size = 24,
  color = 'var(--icon-default-gray)',
  ...props
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <g clipPath="url(#user-plus-icon)">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.732 7.5a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Zm3.25-4.75a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Zm-3.5 12a4.75 4.75 0 0 0-4.75 4.75v2a.75.75 0 0 0 1.5 0v-2a3.25 3.25 0 0 1 3.25-3.25h7a3.25 3.25 0 0 1 3.25 3.25v2a.75.75 0 0 0 1.5 0v-2a4.75 4.75 0 0 0-4.75-4.75h-7Zm15-7a.75.75 0 0 1 .75.75v2.25h2.25a.75.75 0 0 1 0 1.5h-2.25v2.25a.75.75 0 0 1-1.5 0v-2.25h-2.25a.75.75 0 0 1 0-1.5h2.25V8.5a.75.75 0 0 1 .75-.75Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="user-plus-icon">
        <path fill="#fff" d="M.482.5h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
