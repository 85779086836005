import React, { useCallback, useEffect } from 'react';

import MaterialModal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import IconButton from '@/components/atoms/IconButton/IconButton';
import IconClose from '@/components/atoms/Icons/Close';
import { createMediaUrl } from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachment';
import { popModal } from '@/redux/modals/actions';

import styles from './ModalMediaPreview.module.scss';
interface Props {
  url?: string;
  file?: Buffer;
  title: string;
  mime_type: string;
}

export const ModalMediaPreview = ({ url, title, mime_type, file }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [mediaUrl, setMediaUrl] = React.useState<string>(url);

  useEffect(() => {
    if (file) {
      const { url: createdUrl, cleanup } = createMediaUrl(
        file as Buffer,
        mime_type
      );
      if (createdUrl) {
        setMediaUrl(createdUrl);
      }
      return cleanup;
    }
  }, [mime_type, file]);

  const onClose = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  const getMedia = () => {
    if (mime_type?.includes('image')) {
      return <img src={mediaUrl} alt={title} />;
    }
    if (mime_type?.includes('video')) {
      return <video src={mediaUrl} controls />;
    }
    if (mime_type?.includes('audio')) {
      return <audio src={mediaUrl} controls />;
    }
    return null;
  };

  return (
    <MaterialModal open className="modal" onClose={onClose} disableRestoreFocus>
      <>
        <div className="modal__close">
          <IconButton onClick={onClose} ariaLabel={t('modals.close')} autoFocus>
            <IconClose size={20} color="var(--icon-default-white)" />
          </IconButton>
        </div>
        <div className={styles.container}>{getMedia()}</div>
      </>
    </MaterialModal>
  );
};
