import { Icon } from '@/models/icon';

const Brain = ({ size = 32, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m16.667 9.334-6.667 8h6l-.667 5.333 6.667-8h-6l.667-5.333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Brain;
