import { Icon } from '@/models/icon';

const At = ({ size = 12, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 12 12"
    >
      <rect width={size} height={size} fill={color} rx="6" />
      <path
        fill="#fff"
        d="M6.196 10c-.648 0-1.223-.089-1.725-.267-.5-.175-.92-.434-1.264-.777a3.319 3.319 0 0 1-.78-1.255c-.178-.495-.267-1.06-.267-1.696 0-.62.09-1.175.27-1.669.18-.493.44-.913.784-1.26a3.42 3.42 0 0 1 1.257-.8C4.965 2.092 5.525 2 6.152 2c.6 0 1.129.094 1.587.282a3.29 3.29 0 0 1 1.154.775c.314.328.55.704.709 1.129.159.424.238.873.238 1.345 0 .33-.02.654-.063.97-.041.313-.119.597-.232.852-.11.253-.272.456-.483.609-.209.15-.48.229-.815.235a1.65 1.65 0 0 1-.518-.063 1.056 1.056 0 0 1-.426-.232.686.686 0 0 1-.213-.414h-.038a.958.958 0 0 1-.25.345 1.301 1.301 0 0 1-.45.25c-.179.062-.388.087-.626.076-.264-.01-.501-.07-.712-.179a1.63 1.63 0 0 1-.543-.451 2.098 2.098 0 0 1-.342-.684 3.034 3.034 0 0 1-.119-.878c0-.31.045-.586.135-.831.09-.247.212-.459.367-.637.157-.18.337-.322.54-.426A1.925 1.925 0 0 1 6.3 3.894c.183.038.336.095.457.173.124.077.201.162.232.254h.041v-.352h.772v2.91c0 .166.042.306.128.42a.442.442 0 0 0 .373.173c.19 0 .34-.07.446-.21.108-.14.185-.355.229-.646.046-.29.069-.663.069-1.116a3.05 3.05 0 0 0-.129-.897 2.506 2.506 0 0 0-.975-1.358 2.817 2.817 0 0 0-.816-.383 3.532 3.532 0 0 0-1.006-.134c-.5 0-.944.077-1.333.232a2.633 2.633 0 0 0-.985.661 2.893 2.893 0 0 0-.605 1.038 4.2 4.2 0 0 0-.204 1.352c0 .52.07.983.21 1.386.14.402.347.74.621 1.016s.613.485 1.016.627c.404.145.869.217 1.396.217.234 0 .464-.021.69-.063.225-.04.426-.086.602-.138.178-.05.31-.09.398-.122l.22.687c-.13.06-.303.12-.518.178a5.993 5.993 0 0 1-.696.145 4.923 4.923 0 0 1-.737.056Zm-.289-2.61c.272 0 .49-.054.656-.162a.903.903 0 0 0 .36-.486c.076-.218.113-.49.113-.816-.002-.317-.042-.57-.122-.759a.747.747 0 0 0-.364-.407c-.163-.084-.373-.126-.63-.126-.236 0-.438.058-.605.173-.165.115-.292.27-.38.467a1.66 1.66 0 0 0-.131.655c.002.253.037.492.106.715.071.222.186.402.345.54.161.138.379.207.652.207Z"
      />
    </svg>
  );
};

export default At;
