import { useCallback, useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import EmptyResults from '@/components/atoms/EmptyResults/EmptyResults';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Table from '@/components/atoms/Table/Table';
import { usePermissions } from '@/hooks/usePermissions';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';
import { PLAN_IDS } from '@/util/constants';

import styles from './styles.module.scss';

export default function AccountInvoices() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);

  useTitle(t('pages.invoices'));

  const { listStatus } = usePermissions();
  const { pastInvoices, billingState } = useBilling();

  const isPlanEnterprise = billingState?.plan_id === PLAN_IDS.ENTERPRISE;

  const calculateFilterOptions = (opts) =>
    Array.from(new Set(opts.map((o) => o.calcValues.year))).map((v) => ({
      label: `${v}`,
      value: `${v}`,
    }));

  const options = useMemo(() => {
    return pastInvoices.map((inv) => {
      const start = moment.unix(inv.period_start);
      const dateFrom = start;
      const dateTo = moment.unix(inv.period_end);

      return {
        ...inv,
        calcValues: {
          dateFrom,
          dateTo,
          year: `${start.year()}`,
        },
      };
    });
  }, [pastInvoices]);

  const shouldShowTable = !isPlanEnterprise && !!options?.length;
  const yearOptions = useMemo(
    () => [...calculateFilterOptions(options || [])],
    [options]
  );
  const [selectedYear, setSelectedYear] = useState<string | null>();
  const canReadPage = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.READ)
  );

  useEffect(() => {
    pageView(PageName.BILLING);
  }, []);

  useEffect(() => {
    if (!selectedYear) {
      setSelectedYear(yearOptions[0]?.value);
    }
  }, [yearOptions, selectedYear]);

  const filteredOptions = useMemo(() => {
    const opts = options || [];
    return opts.filter((o) => o.calcValues.year === selectedYear);
  }, [selectedYear, options]);

  const getColumns = useMemo(
    () => [
      {
        Header: t('billing.invoices.period'),
        accessor: 'period',
        disableSortBy: true,
        Cell: (props) => {
          const { dateFrom, dateTo } = props.row.original.calcValues;
          if (!dateFrom.isSame(dateTo, 'day')) {
            return (
              <>
                {dateFrom.format('MMM Do')} -{' '}
                {moment(dateTo).subtract(1, 'days').format('MMM Do')}
              </>
            );
          }
          return (
            <>
              {' '}
              {dateFrom.format('MMM Do')} -{' '}
              {moment
                .unix(props.row.original.period_end)
                .endOf('month')
                .format('MMM Do')}
            </>
          );
        },
      },
      {
        Header: t('invoices.payment_status'),
        accessor: 'paid',
        disableSortBy: true,
        Cell: (props) => {
          return (
            <StatusBadge
              variant={props.value ? 'success' : 'warning'}
              label={props.value ? t('invoices.paid') : t('invoices.failed')}
            />
          );
        },
      },
      {
        Header: t('invoices.amount'),
        accessor: 'total',
        disableSortBy: true,
        Cell: (props) => {
          return <>{t('billing.invoice.total', { val: props.value / 100 })}</>;
        },
      },
      {
        Header: '',
        accessor: 'hosted_invoice_url',
        disableSortBy: true,
        Cell: (props) => {
          return (
            <>
              {props.value && (
                <a
                  href={props.value}
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('invoices.view_invoice')}
                </a>
              )}
            </>
          );
        },
      },
    ],
    [t]
  );

  const handleFilterChange = useCallback((value: string) => {
    setSelectedYear(value);
  }, []);

  if (listStatus === 'success' && !canReadPage) {
    navigate(`/${slug}/account/information`);
  }

  if (listStatus === 'pending') {
    return null;
  }

  const billingMail = t('contact.billing_email');

  const isTableEmpty = options !== null && !options?.length;

  return (
    <>
      <PlainFieldset fullWidth isLoading={false}>
        <Typography variant="subheading-semi-bold" className={styles.heading}>
          {t('invoices.payment_history')}
        </Typography>
        {!shouldShowTable && <div className={styles.headingPlaceholder} />}
        <div
          className={classNames({
            [styles.tableContainer]: true,
            [styles.tableContainer]: true,
          })}
        >
          {shouldShowTable && (
            <Table
              data={filteredOptions || []}
              columns={getColumns}
              sortable
              filterable={true}
              searchable={false}
              filterableIcon={false}
              filterOptions={yearOptions}
              onFilterChange={handleFilterChange}
              searchPlaceholder={t('members.search_placeholder')}
            />
          )}
        </div>
        <div className={styles.disclaimer}>
          {isPlanEnterprise ? (
            <>
              <Trans
                i18nKey="invoices.contact"
                values={[billingState?.plan_name]}
              />{' '}
              <a
                href={`mailto:${billingMail}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {billingMail}
              </a>
            </>
          ) : (
            <>
              {isTableEmpty && (
                <div className={styles.invoicesNotFound}>
                  <EmptyResults
                    key="empty_result"
                    message={t('invoices.no_invoices')}
                    subMessage=" "
                  />
                </div>
              )}
              {t('invoices.missing_invoice_disclaimer')}{' '}
              <a
                href={`mailto:${billingMail}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {billingMail}
              </a>
            </>
          )}
        </div>
      </PlainFieldset>
    </>
  );
}
