import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAccount } from '@/hooks/useAccount';
import useMembers from '@/hooks/useMembers';
import { popModal } from '@/redux/modals/actions';

import Modal from './Modal';

interface Props {
  user_id: string;
}

const ModalAccountTransfer = ({ user_id }: Props) => {
  const { t } = useTranslation();
  const { transferOwnership, transferOwnershipStatus } = useAccount();
  const { members } = useMembers();
  const newOwner = members.find((m) => m.user_id === user_id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (transferOwnershipStatus === 'success') {
      dispatch(popModal());
    }
  }, [transferOwnershipStatus, dispatch]);

  const onSubmit = () => {
    transferOwnership({ new_owner_id: user_id });
  };

  return (
    <Modal
      title={t('account.transfer.title')}
      onPrimarySubmit={onSubmit}
      primaryButtonText={t('account.transfer.action')}
      secondaryButtonText={t('common.back_to_safety')}
      autoFocus
      isSubmitting={transferOwnershipStatus === 'pending'}
    >
      {t('account.transfer.warning', { name: newOwner?.name })}
    </Modal>
  );
};

export default ModalAccountTransfer;
