import { PageName } from '@/models/segment';

import ResponseFieldset from '../components/fieldsets/ResponseFieldset/ResponseFieldset';
import ResponseTimeOverview from '../components/fieldsets/ResponseTimeOverview/ResponseTimeOverview';
import Filters from '../components/filters/AccountFilters/AccountFilters';
import useSegments from '../hooks/useSegment';

export const HumanAgentsEffectiveness = () => {
  useSegments(PageName.ANALYTICS_HUMAN_EFECTIVENESS);
  return (
    <>
      <Filters />
      <ResponseTimeOverview />
      <ResponseFieldset isFirstMessage />
      <ResponseFieldset />
    </>
  );
};
