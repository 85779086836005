import { useCallback } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';

import Tick from '../Icons/Tick';
import TickBase from '../Icons/TickBase';
import Spinner from '../Spinner/Spinner';

import styles from './CheckBoxTile.module.scss';

type CommonProps = {
  title: string;
  text?: string;
  onClick: () => void;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  loading?: boolean;
  submitLoading?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  iconLeft?: React.ReactNode;
};
type Info = { info: string; infoColor: string };
type Tick = { hasTick: boolean; checked: boolean };
type IconRight = { iconRight?: React.ReactNode };
type AllProps = Info & Tick & CommonProps & IconRight;

export function CheckBoxTile(props: CommonProps): JSX.Element;
export function CheckBoxTile(props: Info & CommonProps): JSX.Element;
export function CheckBoxTile(props: Tick & CommonProps): JSX.Element;
export function CheckBoxTile(props: IconRight & CommonProps): JSX.Element;
export function CheckBoxTile(props: AllProps) {
  const {
    size,
    checked,
    info,
    iconLeft,
    text,
    hasTick,
    title,
    infoColor,
    onClick,
    disabled,
    loading,
    iconRight,
    submitLoading,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);
  return (
    <>
      <button
        className={cn(styles.container, styles[size], {
          [styles.checked]: checked,
          [styles.withInfo]: info,
          [styles.disabled]: disabled,
          [styles.spaceBetween]: iconRight,
          [styles.hasIcon]: iconLeft,
        })}
        type="button"
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {submitLoading && (
          <div className={styles.spinner}>
            <Spinner size="small" />
          </div>
        )}

        {iconLeft && (
          <span className={cn(styles.iconLeft, submitLoading && styles.hidden)}>
            {iconLeft}
          </span>
        )}

        <div
          className={cn(
            styles.content,
            {
              [styles.noText]: !text,
              [styles.withTick]: hasTick,
              [styles.noPaddingTop]: iconRight,
              [styles.info]: Boolean(info),
              [styles.hasIcon]: iconLeft,
            },
            submitLoading && styles.hidden
          )}
        >
          {loading ? (
            <Skeleton variant="text" width={80} height={24} />
          ) : (
            <>
              <span
                className={cn(styles.title, { [styles.disabled]: disabled })}
              >
                {title}
              </span>
            </>
          )}

          {loading ? (
            <>
              <Skeleton variant="text" width={200} height={20} />
              <Skeleton variant="text" width={240} height={20} />
              <Skeleton variant="text" width={180} height={20} />
            </>
          ) : (
            <>
              <span
                className={cn(styles.text, submitLoading && styles.hidden, {
                  [styles.disabled]: disabled,
                })}
              >
                {text}
              </span>
            </>
          )}
        </div>

        {iconRight && (
          <span
            className={cn(styles.iconRight, submitLoading && styles.hidden)}
          >
            {iconRight}
          </span>
        )}

        <span className={cn(styles.tickIcon, submitLoading && styles.hidden)}>
          {hasTick && (
            <>{checked ? <Tick size={16} /> : <TickBase size={16} />}</>
          )}

          {info && (
            <>
              {loading ? (
                <Skeleton variant="text" width={80} height={24} />
              ) : (
                <span
                  style={{ backgroundColor: `${infoColor}` }}
                  className={styles.info}
                >
                  {info}
                </span>
              )}
            </>
          )}
        </span>
      </button>
    </>
  );
}
