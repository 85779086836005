import { Icon } from '@/models/icon';

type Props = Icon & {
  extraLarge?: boolean;
};

const Calendar = ({ size = 16, color = 'white', extraLarge }: Props) => {
  if (extraLarge) {
    return (
      <svg
        width="100"
        height="100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <path
          d="M79.624 16.667H21.29A8.333 8.333 0 0 0 12.957 25v58.333a8.333 8.333 0 0 0 8.333 8.334h58.334a8.333 8.333 0 0 0 8.333-8.334V25a8.333 8.333 0 0 0-8.333-8.333ZM67.124 8.333V25M33.79 8.333V25M12.957 41.667h75"
          stroke="#1B66D6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333ZM10.667 1.333V4M5.333 1.333V4M2 6.667h12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Calendar;
