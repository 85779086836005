import { Icon } from '@/models/icon';

const Invoices = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      viewBox="0 0 16 17"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.333 2.085H4A1.333 1.333 0 002.667 3.42v10.666A1.333 1.333 0 004 15.42h8a1.334 1.334 0 001.333-1.334v-8l-4-4z"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.333 2.085v4h4"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.7"
        d="M8 7.227v6.111M9.389 8.339H7.305a.972.972 0 000 1.944h1.39a.972.972 0 110 1.945H6.332"
      ></path>
    </svg>
  );
};
export default Invoices;
