import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const EmptyCircle = ({
  size = 10,
  color = 'var(--icon-default-gray)',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      viewBox="0 0 10 10"
      className={`${styles.icon} ${disabledClass}`}
      width={size}
      height={size}
      stroke={color}
      strokeWidth="0.7px"
      fill="none"
      shapeRendering="geometricPrecision"
    >
      <circle cx="5" cy="5" r="3.5" />
    </svg>
  );
};

export default EmptyCircle;
