import { Icon } from '@/models/icon';

const MoreHorizontal = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      viewBox="0 0 20 20"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      className="stroke"
    >
      <path
        d="M10 10.833a.833.833 0 100-1.666.833.833 0 000 1.666zM15.833 10.833a.833.833 0 100-1.666.833.833 0 000 1.666zM4.167 10.833a.833.833 0 100-1.666.833.833 0 000 1.666z"
        strokeWidth="1.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MoreHorizontal;
