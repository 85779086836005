import { forwardRef, useCallback } from 'react';

import cn from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form';

import styles from './OptionRadioButton.module.scss';

export interface OptionRadioButtonProps {
  option: {
    id: string | number;
    label: string | React.ReactNode | JSX.Element;
    value: string;
    icon?: React.ReactNode;
  };
  isSelected: boolean;
  register?: UseFormRegisterReturn;
  setSelected?: (number) => void;
  size?: 'medium' | 'large';
  onChange?: (event) => void;
  disableSelectedStyles?: boolean;
  disabled?: boolean;
  isTile?: boolean;
}

const OptionRadioButton = forwardRef<HTMLInputElement, OptionRadioButtonProps>(
  (
    {
      option,
      isSelected,
      register,
      setSelected,
      size,
      onChange = () => {},
      disableSelectedStyles = false,
      disabled,
      isTile,
    },
    ref
  ) => {
    const handleClick = useCallback(() => {
      if (setSelected) {
        setSelected(option.id);
      }
    }, [option.id, setSelected]);

    return (
      <>
        <label
          htmlFor={`option-${option.id}`}
          className={cn(styles.optionContainer, styles[size], {
            [styles.isSelected]: isSelected && !disableSelectedStyles,
            [styles.isTile]: isTile,
          })}
        >
          <div className={cn(styles.nameContainer, styles[size])}>
            <input
              type="radio"
              name={`option-${option.value}`}
              value={option.value}
              disabled={disabled}
              id={`option-${option.id}`}
              ref={ref}
              {...register}
              onChange={(event) => {
                register?.onChange(event);
                onChange(event);
              }}
              onClick={handleClick}
              checked={isSelected}
            />
            {option?.icon}
            {option.label}
          </div>
        </label>
      </>
    );
  }
);
OptionRadioButton.displayName = 'OptionRadioButton';

export default OptionRadioButton;
