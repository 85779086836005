import { useCallback } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { useIntegrations } from '@/hooks/useIntegrations';
import { Integration } from '@/models/integration';
import { pushModal } from '@/redux/modals/actions';

interface Props {
  integration?: Integration;
  disabled?: boolean;
  subtitle?: string | JSX.Element;
}

const DeleteFieldset = ({ integration, disabled = false, subtitle }: Props) => {
  const { t } = useTranslation();
  const integration_id = integration?.integration_id;
  const desk_id = integration?.desk_id;

  const { deleteIntegration, deleteStatus } = useIntegrations(
    desk_id,
    integration_id
  );

  const dispatch = useDispatch();
  const onDelete = useCallback<() => void>(() => {
    const deleteProps = {
      subtitle: (
        <Trans
          i18nKey="integrations.fieldsets.delete_fieldset.warn_message"
          values={{ 0: integration?.type }}
        />
      ),
      warning:
        integration?.type == 'whatsapp' ? (
          <Banner relativePosition variant="critical">
            {t('integrations.whatsapp.delete_warning')}
          </Banner>
        ) : null,
      confirm: true,
      onDelete: () => {
        deleteIntegration({ desk_id, integration_id });
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [
    integration?.type,
    t,
    dispatch,
    deleteIntegration,
    desk_id,
    integration_id,
  ]);

  return (
    <FormFieldset
      title={t('integrations.fieldsets.delete_fieldset.title')}
      subtitle={
        subtitle || t('integrations.fieldsets.delete_fieldset.subtitle')
      }
      primaryButton={{
        children: t('integrations.fieldsets.delete_fieldset.button'),
        variant: 'danger',
        isLoading: deleteStatus === 'pending',
        disabled,
      }}
      onSubmit={onDelete}
      isLoading={!integration}
    />
  );
};

export default DeleteFieldset;
