import { useCallback, useMemo, useState } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import Select from '@/components/atoms/Select/Select';
import TabsTable from '@/components/atoms/TabsTable/TabsTable';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { OptionBase } from '@/models/common';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';

import useRatingCount, {
  ResponseRatingType,
} from '../../../hooks/useRatingCount';

import styles from './RatingCount.module.scss';

type Option = OptionBase<{ value: ResponseRatingType }>;

export const getOptions = (t: TFunction) =>
  [
    {
      label: t('analytics.average'),
      value: 'avg_rating',
    },
    {
      label: t('analytics.median'),
      value: 'median_rating',
    },
  ] as Option[];

const RatingCount = () => {
  const { t } = useTranslation();
  const [showPrevious, setShowPrevious] = useState(false);
  const [optionValue, setOptionValue] = useState(getOptions(t)[0]?.value);
  const title = t('analytics.rating');

  const brainRatings = useRatingCount({
    intervalProps: {
      type: 'onlyBrain',
    },
    skipPrevious: !showPrevious,
    type: optionValue,
  });

  const humanRatings = useRatingCount({
    intervalProps: {
      type: 'onlyHuman',
    },
    skipPrevious: !showPrevious,
    type: optionValue,
  });

  const ratings = useRatingCount({
    skipPrevious: !showPrevious,
    type: optionValue,
  });

  const handleReload = useCallback(() => {
    if (brainRatings?.currentError) {
      brainRatings.refetchCurrent();
    }
    if (humanRatings?.currentError) {
      humanRatings.refetchCurrent();
    }
  }, [brainRatings, humanRatings]);

  const handleOptionClick = useCallback((e) => {
    setOptionValue(e.target.value);
  }, []);

  const handlePreviousClick = () => {
    setShowPrevious((prev) => !prev);
  };

  const isError = brainRatings?.currentError && humanRatings?.currentError;

  const isLoading = brainRatings?.isLoading && humanRatings?.isLoading;

  const items = useMemo(
    () => [
      {
        ...humanRatings,
        data: { ...humanRatings.data, title: t('analytics.human_agents') },
      },
      {
        ...brainRatings,
        data: { ...brainRatings.data, title: t('common.brains') },
      },
      {
        ...ratings,
        data: { ...ratings.data, title: t('analytics.overall') },
      },
    ],
    [brainRatings, humanRatings, ratings, t]
  );

  const isEmpty = !items[0]?.data.counts && !items[1]?.data.counts;

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      error={isError}
      onReload={handleReload}
      isEmpty={isEmpty}
      isLoading={isLoading}
      data={items}
    >
      <div className={styles.checkbox}>
        <Select
          options={getOptions(t)}
          value={optionValue}
          onChange={handleOptionClick}
          size="small"
        />
        <Checkbox
          label={<TitleSubtitle subtitle={t('analytics.compare_prev')} />}
          onChange={handlePreviousClick}
          checked={showPrevious}
          sameLevel
        />
      </div>
      <TabsTable items={items} />
    </AnalyticsFieldset>
  );
};

export default RatingCount;
