import { useCallback } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Switch from '@/components/atoms/Switch/Switch';
import { useIntegrations } from '@/hooks/useIntegrations';
import { SuncoIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

type Form = {
  create_ticket_on_session_expired: boolean;
};
interface Props {
  integration?: SuncoIntegration;
}

export const TicketWhenExpiredFieldset = ({ integration }: Props) => {
  const { t } = useTranslation();

  const { updateIntegration, updateStatus } = useIntegrations<SuncoIntegration>(
    integration.desk_id,
    integration.integration_id
  );

  const canWrite = useSelector((state: RootState) =>
    getPermissions(
      state,
      'integrations',
      actions.WRITE,
      integration.integration_id
    )
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      create_ticket_on_session_expired: false,
    },
  });

  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      updateIntegration({
        ...integration,
        config: {
          ...integration.config,
          create_ticket_on_session_expired:
            form.create_ticket_on_session_expired,
        },
      });
    },
    [integration, updateIntegration]
  );

  return (
    <FormFieldset
      title={t('integrations.sunco.ticket_creation')}
      subtitle={t('integrations.sunco.ticket_creation_subtitle')}
      primaryButton={{
        children: t('common.save'),
        isLoading: updateStatus === 'pending',
        disabled: !isDirty || !isValid,
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={!integration}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <Switch
              label={t('integrations.fieldsets.active_fieldset.active')}
              checked={value}
              disabled={!integration || !canWrite}
              size="medium"
              onChange={onChange}
            />
          );
        }}
        name="create_ticket_on_session_expired"
        control={control}
      />
    </FormFieldset>
  );
};
