interface ShrinkProps {
  size?: number;
  color?: string;
}
const Shrink = ({
  size = 16,
  color = 'var(--icon-default-blue)',
}: ShrinkProps) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth="1.333"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2.667 9.333h4v4m6.666-6.666h-4v-4m0 4L14 2M2 14l4.667-4.667" />
    </svg>
  );
};

export default Shrink;
