import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import Carousel from '@/components/organisms/Dialogs/Action/Carousel/Carousel';
import { RootState } from '@/models/state';
import { selectActions } from '@/redux/dialogs/selectors';

import Action from './Action/Action';

type Props = {
  nodeId: string;
  conditionIndex?: number;
  requisiteIndex?: number;
};

const ActionList = ({
  nodeId,
  conditionIndex = null,
  requisiteIndex = null,
}: Props) => {
  const actions = useSelector(
    (state: RootState) =>
      selectActions(state, { nodeId, conditionIndex, requisiteIndex }),
    isEqual
  );

  if (!actions || actions.length === 0) {
    return null;
  }

  return (
    <>
      {actions.map((action, actionIndex) => {
        if (action.type === 'carousel') {
          return (
            <Carousel
              key={action.action_id}
              nodeId={nodeId}
              actionId={action.action_id}
              action={action}
              conditionIndex={conditionIndex}
              requisiteIndex={requisiteIndex}
              actionIndex={actionIndex}
            />
          );
        }
        return (
          <Action
            key={action.action_id}
            nodeId={nodeId}
            actionId={action.action_id}
            action={action}
            conditionIndex={conditionIndex}
            requisiteIndex={requisiteIndex}
            actionIndex={actionIndex}
          />
        );
      })}
    </>
  );
};

export default ActionList;
