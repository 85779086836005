import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useHomeCheckList, {
  AccountUserPrefsEnum,
  INAPPHELP_KEYS,
} from '@/hooks/useHomeCheckList';
import useLocalStorage from '@/hooks/useLocalStorage';
import { RootState } from '@/models/state';
import { hideHelper } from '@/redux/helpers/actions';
import { selectHelper } from '@/redux/helpers/selectors';

const useSubNavDialogHelper = () => {
  const dispatch = useDispatch();
  const { accountUserChecklist } = useHomeCheckList();

  const showTooltip = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.GENERATE_DIALOG])
  );

  const [_, setDismissHelp] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.GENERATE_DIALOG],
    accountUserChecklist[AccountUserPrefsEnum.GENERATE_DIALOG]
  );

  const handleDismissTooltip = useCallback(() => {
    setDismissHelp(true);
    dispatch(hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.GENERATE_DIALOG]));
  }, [dispatch, setDismissHelp]);

  return {
    showTooltip,
    handleDismissTooltip,
  };
};

export default useSubNavDialogHelper;
