/**
 * General modal actions
 */
export const CONFETTI_ENABLED = 'CONFETTI/ENABLED';
export const CONFETTI_DISABLED = 'CONFETTI/DISABLE';

const defaultState = { enabled: false };

const reducer = (state = defaultState, { type }: Partial<{ type: string }>) => {
  switch (type) {
    case CONFETTI_ENABLED:
      return {
        enabled: true,
      };
    case CONFETTI_DISABLED:
      return {
        enabled: false,
      };
    default: {
      return state;
    }
  }
};

export const enableConfetti = () => ({
  type: CONFETTI_ENABLED,
});

export const disableConfetti = () => ({
  type: CONFETTI_DISABLED,
});

export default reducer;
