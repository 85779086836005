import { IconInterface } from '@/models/icon';

export const DeveloperToolsIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: IconInterface) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      d="m3.646 6.854-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708.708L2.707 4.5l1.647 1.646a.5.5 0 0 1-.708.708Zm2.5 0a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 0 0-.708.708L7.793 4.5 6.146 6.146a.5.5 0 0 0 0 .708ZM12.5 3H11a.5.5 0 1 0 0 1h1.5v9h-9V9a.5.5 0 0 0-1 0v4a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1Z"
    />
  </svg>
);
