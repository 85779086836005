export const SetupIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M13.867 5.197a6.507 6.507 0 1 1-1.638-2.135l1.417-1.417a.5.5 0 1 1 .708.708l-6 6a.5.5 0 1 1-.708-.708L9.38 5.913a2.5 2.5 0 1 0 1.117 1.943.5.5 0 0 1 1-.057 3.5 3.5 0 1 1-1.4-2.603l1.423-1.422a5.493 5.493 0 1 0 1.445 1.855.5.5 0 1 1 .903-.431Z"
      />
    </svg>
  );
};
