export const KnowledgeIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="m14.478 12.16-2.074-9.863a1 1 0 0 0-1.188-.775l-2.925.63a1.005 1.005 0 0 0-.77 1.187L9.597 13.2a1 1 0 0 0 1.188.776l2.925-.628a1.006 1.006 0 0 0 .77-1.19ZM8.5 3.133V3.13l2.925-.625.208.992-2.925.629-.208-.99Zm.414 1.967 2.926-.628.209.994-2.924.629-.211-.995Zm.415 1.973 2.926-.628.831 3.952-2.926.629-.831-3.953Zm4.171 5.297-2.925.625-.208-.992 2.925-.629.208.99v.006ZM6.5 2h-3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1Zm-3 1h3v1h-3V3Zm0 2h3v6h-3V5Zm3 8h-3v-1h3v1Z"
      />
    </svg>
  );
};
