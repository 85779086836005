import { Icon } from '@/models/icon';

const ZoomReset = ({
  size = 16,
  color = 'var(--icon-default-black)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.081 5.797-.012-2a1.333 1.333 0 0 0-1.342-1.325l-2 .012m.074 12 2-.012a1.333 1.333 0 0 0 1.325-1.342l-.012-2m-12 .074.012 2a1.333 1.333 0 0 0 1.342 1.325l2-.012m-.074-12-2 .012A1.333 1.333 0 0 0 2.07 3.871l.012 2"
      />
    </svg>
  );
};

export default ZoomReset;
