import cn from 'classnames';

import styles from './ToolkitControlsWrapper.module.scss';

type Props = {
  items: {
    label?: string;
    icon: React.ReactNode;
    onClick?: () => void;
    className?: string;
    shouldFocus?: boolean;
  }[];
};

const ToolkitControlsWrapper = ({ items }: Props) => {
  return (
    <ul className={styles.toolkit__controls}>
      {items.map((item, index: number) => (
        <li
          key={item.label || index}
          className={cn(styles.toolkit__controls__button, item.className)}
        >
          <button
            tabIndex={item.shouldFocus ? 0 : -1}
            type="button"
            aria-label={item.label}
            onClick={item?.onClick}
            className={cn({ [styles.defaultCursor]: !item.shouldFocus })}
          >
            {item.icon}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default ToolkitControlsWrapper;
