import { memo, useCallback, useMemo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ButtonCard from '@/components/atoms/Button/ButtonCard/ButtonCard';
import ThemeAuto from '@/components/atoms/Icons/ThemeAuto';
import ThemeDark from '@/components/atoms/Icons/ThemeDark';
import ThemeLight from '@/components/atoms/Icons/ThemeLight';

import ThemeButton from './ThemeButton/ThemeButton';
import { OptionItem } from '../LauncherAppearance/LauncherOptions';

import styles from './WebchatAppearance.module.scss';

type ColorThemeOptionsProps = {
  onChange: (value) => void;
  value: string;
  disabled?: boolean;
};

export const themeOptions = {
  '0': 'light',
  '1': 'dark',
  '2': 'auto',
  '3': 'monocai',
  '4': 'dracula',
} as const;

const ColorThemeOptions = ({
  onChange,
  value,
  disabled = false,
}: ColorThemeOptionsProps) => {
  const { t } = useTranslation();

  const items: OptionItem[] = useMemo(
    () => [
      {
        id: 0,
        child: (
          <ThemeButton name={t('integrations.web_ui.theme_light')}>
            <ThemeLight />
          </ThemeButton>
        ),
        isTheme: true,
        value: 'light',
      },
      {
        id: 1,
        child: (
          <ThemeButton name={t('integrations.web_ui.theme_dark')}>
            <ThemeDark />
          </ThemeButton>
        ),
        isTheme: true,
        value: 'dark',
      },
      {
        id: 2,
        child: (
          <ThemeButton name={t('integrations.web_ui.theme_auto')}>
            <ThemeAuto />
          </ThemeButton>
        ),
        isTheme: true,
        value: 'auto',
      },
    ],
    [t]
  );

  const handleCardClick = useCallback(
    (index) => {
      if (!disabled) {
        onChange(themeOptions[index]);
      }
    },
    [disabled, onChange]
  );

  return (
    <div
      className={cn(styles.themeOptions, {
        [styles.disabled]: disabled,
      })}
    >
      {items.map((item) => {
        return (
          <ButtonCard
            active={value === item.value}
            onClick={handleCardClick}
            key={item.id}
            index={item.id}
            isTheme={item.isTheme}
            isButtonLike={item.isButtonLike}
          >
            {item.child}
          </ButtonCard>
        );
      })}
    </div>
  );
};

export default memo(ColorThemeOptions);
