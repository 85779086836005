import { SyntheticEvent, useCallback, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import Autocomplete from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import { Banner } from '@/components/atoms/Banner/Banner';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import Docs from '@/components/atoms/Icons/Docs';
import Input from '@/components/atoms/Input/Input';
import { useBrainVariables } from '@/hooks/useBrainVariables';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import { Action } from '@/models/action';
import { OptionBase } from '@/models/common';
import { RootState } from '@/models/state';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { GOOGLE_SHEET_DOCS_URL } from '@/util/constants';
import { addDollarSign, capitalizeFirstLetter } from '@/util/util';
import { googleSheetToolkitSchema } from '@/util/validator';

import DraggableItem from './DraggableItem';
import { useGoogleSheet } from './useGoogleSheet';
import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitGoogleSheet.module.scss';

type Option = OptionBase<{ type: string }>;

type Form = {
  spreadsheet_id: string;
  sheet_id: string;
  input: Option;
};

const MIN_COLUMNS = 1;
const MAX_COLUMNS = 15;

const getOptionValue = (option: Option) => {
  return addDollarSign(option?.value ?? '');
};

// Debounce the onSave function to prevent multiple calls
const useDebouncedSave = (saveFn: () => void, delay: number) => {
  const debouncedSaveRef = useRef(debounce(saveFn, delay));

  const debouncedSave = useCallback(() => {
    debouncedSaveRef.current();
  }, []);

  return debouncedSave;
};

// Handle onKeyDown event for debounced onSave function
const useHandleChange = (debouncedOnSave: () => void) => {
  return useCallback(() => {
    debouncedOnSave();
  }, [debouncedOnSave]);
};

const ToolkitGoogleSheet = () => {
  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { contextVariables: autoCompleteOptions } = useBrainVariables();
  const { updateReduxAction } = useGoogleSheet();

  // Redux selectors
  const { type, actionId, sheet_id, spreadsheet_id, inputs } = useSelector(
    (state: RootState) => {
      const selectedAction = selectSelectedAction(state) as Extract<
        Action,
        { type: 'googlesheet' }
      >;
      const { action_id, type, spreadsheet_id, sheet_id, inputs } =
        selectedAction;

      return {
        type,
        actionId: action_id,
        spreadsheet_id: spreadsheet_id ?? '',
        sheet_id: sheet_id ?? '',
        inputs: inputs || [],
      };
    },
    shallowEqual
  );
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  // RHF
  const {
    formState: { errors },
    getValues,
    control,
    register,
    trigger,
    resetField,
    setFocus,
    setValue,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      spreadsheet_id: spreadsheet_id,
      sheet_id: sheet_id,
      input: { value: '', label: '', type: '' },
    },
    resolver: yupResolver(
      googleSheetToolkitSchema
    ) as unknown as Resolver<Form>,
  });
  useFocusOnInput('spreadsheet_id', errors, setFocus);
  const spreadsheetIdErrorMessage = errors?.spreadsheet_id?.message;
  const sheetIdErrorMessage = errors?.sheet_id?.message;
  const inputErrorMessage = errors?.input?.value?.message;

  // Callbacks
  const handleDragItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === hoverIndex) {
        return inputs;
      }

      const draggedItem = inputs[dragIndex];
      const newList = cloneDeep(inputs);
      newList.splice(dragIndex, 1);
      newList.splice(hoverIndex, 0, draggedItem);
      updateReduxAction(actionId, 'inputs', newList);

      return inputs;
    },
    [actionId, inputs, updateReduxAction]
  );

  const handleDropItem = useCallback(() => {
    updateReduxAction(actionId, 'inputs', inputs);
  }, [actionId, inputs, updateReduxAction]);

  const handleDeleteItem = useCallback(
    (id: string) => {
      const newList = inputs.filter((item: string) => item !== id);
      updateReduxAction(actionId, 'inputs', newList);
    },
    [actionId, inputs, updateReduxAction]
  );

  const getIndex = useCallback(
    (item: string) => () => {
      return inputs.indexOf(item);
    },
    [inputs]
  );

  const updateErrors = useCallback(
    (key: string, value: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.googlesheet'),
            body: capitalizeFirstLetter(value),
            type,
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, parentNodeId, t, type]
  );

  const handleAutoCompleteChange = useCallback(
    async (_: SyntheticEvent, option: Option) => {
      if (!option) {
        return;
      }

      // Display toast if the number of columns is less than the maximum
      if (inputs.length === MAX_COLUMNS) {
        resetField('input');
        dispatch(
          addTemporalToast(
            'error',
            t('dialog.google.max_columns', { max: MAX_COLUMNS })
          )
        );
        return;
      }

      // Display toast if the number of columns is less than the maximum
      if (inputs.length === MAX_COLUMNS - 1) {
        dispatch(
          addTemporalToast(
            'warning',
            t('dialog.google.max_columns', { max: MAX_COLUMNS })
          )
        );
      }

      const selectedVariable = getOptionValue(option);

      // Add dollar sign to value before validation
      setValue('input.value', addDollarSign(selectedVariable));

      const isAutocompleteValid = await trigger('input');
      // Show error message if the input is not valid
      if (!isAutocompleteValid) {
        return;
      }

      resetField('input');
      const isDuplicate = inputs.includes(selectedVariable);

      if (isDuplicate) {
        dispatch(
          addTemporalToast('warning', t('dialog.google.duplicate_variable'))
        );
        return;
      }

      dispatch(
        updateAction({
          actionId,
          action: {
            inputs: [...inputs, selectedVariable],
          },
        })
      );
    },
    [actionId, dispatch, inputs, resetField, setValue, t, trigger]
  );

  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [trigger]);

  useEffect(() => {
    updateErrors('spreadsheet_id', spreadsheetIdErrorMessage);
  }, [spreadsheetIdErrorMessage, updateErrors]);

  useEffect(() => {
    updateErrors('sheet_id', sheetIdErrorMessage);
  }, [sheetIdErrorMessage, updateErrors]);

  const saveAllVariablesInRedux = useCallback(() => {
    const { spreadsheet_id, sheet_id } = getValues();

    dispatch(
      updateAction({
        actionId,
        action: {
          spreadsheet_id,
          sheet_id,
        },
      })
    );
  }, [actionId, dispatch, getValues]);

  // Debounce the onSave function to prevent multiple calls
  const debouncedOnSave = useDebouncedSave(saveAllVariablesInRedux, 500);
  const handleDebouncedChange = useHandleChange(debouncedOnSave);

  return (
    <ToolkitWrapper type={type}>
      <ContextualHelp
        title={t('dialog.google.contextual_title')}
        name={type}
        preventClose
        links={[
          {
            label: t('docs.docs'),
            url: GOOGLE_SHEET_DOCS_URL,
            icon: <Docs size={16} color="currentColor" />,
          },
        ]}
      >
        {t('dialog.google.contextual_message')}
      </ContextualHelp>

      <Input
        register={register('spreadsheet_id')}
        name="spreadsheet_id"
        label={t('dialog.google.spreadsheet_id')}
        size="small"
        error={!!errors?.spreadsheet_id}
        errorMessage={capitalizeFirstLetter(spreadsheetIdErrorMessage)}
        onChange={handleDebouncedChange}
      />

      <Input
        register={register('sheet_id')}
        name="sheet_id"
        label={t('dialog.google.google_sheet_name')}
        size="small"
        error={!!errors?.sheet_id}
        errorMessage={capitalizeFirstLetter(sheetIdErrorMessage)}
        onChange={handleDebouncedChange}
      />

      <Autocomplete
        options={autoCompleteOptions}
        control={control}
        name="input"
        label={t('dialog.google.add_columns')}
        placeholder={t('dialog.google.inputs_placeholder')}
        groupByProp="type"
        size="xs"
        iconTypeKey="type"
        freeSolo
        enableNewEntry
        onChange={handleAutoCompleteChange}
        hasError={!!errors?.input}
        errorMessage={capitalizeFirstLetter(inputErrorMessage)}
      />

      <ul className={styles.container}>
        {inputs.map((item, i: number) => {
          return (
            <DraggableItem
              key={item}
              id={item}
              index={i}
              item={item}
              moveItem={handleDragItem}
              dropItem={handleDropItem}
              deleteItem={handleDeleteItem}
              getIndex={getIndex(item)}
              disableClose={inputs.length === 1}
            />
          );
        })}
      </ul>

      {/* Restriction banners */}
      {inputs.length === MIN_COLUMNS && (
        <div className={styles.warning}>
          <Banner variant="warning" relativePosition>
            {t('dialog.errors.at_least_one', {
              0: t('actions.googlesheet.column'),
            })}
          </Banner>
        </div>
      )}
      {inputs.length === MAX_COLUMNS && (
        <div className={styles.warning}>
          <Banner variant="warning" relativePosition>
            {t('dialog.google.max_columns', {
              max: MAX_COLUMNS,
            })}
          </Banner>
        </div>
      )}
    </ToolkitWrapper>
  );
};

export default ToolkitGoogleSheet;
