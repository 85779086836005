export const getReportOptions = (ai_agents: boolean) => [
  {
    text_key: 'analytics.overview',
    link: '/overview',
  },
  {
    text_key: 'analytics.count_usage',
    link: '/usage',
  },
  {
    text_key: ai_agents
      ? 'analytics.ai_agent_effect'
      : 'analytics.brain_effect',
    link: '/brain-effectiveness',
  },
  {
    text_key: 'analytics.human_effectiveness',
    link: '/human-agents-effectiveness',
  },
  {
    text_key: 'analytics.agents_perf',
    link: '/agents-performance',
  },
  {
    text_key: 'analytics.cust_satis',
    link: '/customer-satisfaction',
  },
  {
    text_key: 'analytics.human_chat_logs',
    link: '/human-chat-logs',
  },
];
