import { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import KeyValueRow from '@/components/atoms/KeyValueTable/KeyValueRow/KeyValueRow';

type ObjectType = { [key: string]: string };

export interface KeyValueTableProps {
  obj: ObjectType;
  setObj: (obj: ObjectType) => void;
  size?: 'small' | 'medium';
  readOnly?: boolean;
}
const KeyValueTable = ({
  obj,
  setObj,
  size,
  readOnly = false,
}: KeyValueTableProps) => {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (!isEmpty(obj) && keys.length === 0) {
      setKeys(Object.keys(obj));
    }
  }, [keys.length, obj]);

  const handleUpdate = (
    oldKey: string,
    itemKey: string,
    itemValue: string,
    options?: Record<string, string | number>
  ) => {
    const { focus } = options;
    if (focus !== 'value' && keys.includes(itemKey)) {
      return;
    }

    setObj({ ...obj, [itemKey]: itemValue });
  };

  const handleRemove = (itemKey: string) => {
    const copyHeaders = { ...obj };
    delete copyHeaders[itemKey];
    setObj(copyHeaders);
    setKeys((prev) => prev.filter((x) => x !== itemKey));
  };

  const handleAdd = (itemKey: string, itemValue: string) => {
    const copyHeaders = { ...obj };
    if (keys.includes(itemKey)) {
      copyHeaders[`${itemKey}`] = itemValue;
      setObj(copyHeaders);
    } else {
      setObj({ ...obj, [`${itemKey}`]: itemValue });
      setKeys((prev) => [...prev, itemKey]);
    }
  };

  return (
    <div>
      {keys.map((x, i) => (
        <KeyValueRow
          size={size}
          key={x}
          itemKey={x}
          itemValue={obj[x]}
          index={i}
          keys={keys}
          onUpdate={handleUpdate}
          onDelete={handleRemove}
          onAdd={handleAdd}
          readOnly={readOnly}
        />
      ))}
      <KeyValueRow
        size={size}
        itemKey=""
        itemValue=""
        index={keys.length}
        keys={keys}
        onUpdate={handleUpdate}
        onDelete={handleRemove}
        onAdd={handleAdd}
        readOnly={readOnly}
      />
    </div>
  );
};

export default KeyValueTable;
