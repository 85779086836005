import { forwardRef } from 'react';

import cn from 'classnames';

import styles from './Setup.module.scss';

export const ToneButton = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, className, ...props }, ref) => {
  return (
    <button ref={ref} className={cn(styles.toneButton, className)} {...props}>
      {children}
    </button>
  );
});

ToneButton.displayName = 'ToneButton';
