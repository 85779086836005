import { Icon } from '@/models/icon';

const Entities = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 15.49A6.667 6.667 0 1 0 8 2.156 6.667 6.667 0 0 0 8 15.49ZM14.667 8.823H12M4 8.823H1.333M8 4.823V2.156M8 15.49v-2.667"
      />
    </svg>
  );
};
export default Entities;
