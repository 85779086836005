import { Icon } from '@/models/icon';

export const Inbox = ({ color = 'currentColor', size = 16 }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 8H10.6667L9.33337 10H6.66671L5.33337 8H1.33337"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.63337 3.40669L1.33337 8.00002V12C1.33337 12.3536 1.47385 12.6928 1.7239 12.9428C1.97395 13.1929 2.31309 13.3334 2.66671 13.3334H13.3334C13.687 13.3334 14.0261 13.1929 14.2762 12.9428C14.5262 12.6928 14.6667 12.3536 14.6667 12V8.00002L12.3667 3.40669C12.2563 3.18455 12.0862 2.9976 11.8753 2.86687C11.6645 2.73615 11.4214 2.66682 11.1734 2.66669H4.82671C4.57865 2.66682 4.33555 2.73615 4.12474 2.86687C3.91392 2.9976 3.74376 3.18455 3.63337 3.40669Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
