import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import useIntents from '@/hooks/useIntents';
import { RootState } from '@/models/state';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { importExpressions } from '@/redux/expressions/actions';
import {
  selectExpressions,
  selectExpressionsWithId,
} from '@/redux/expressions/selectors';
import { exportIntents, importIntents } from '@/util/file-manager';

const useExpressions = (brainId: string, intentName: string) => {
  const { t } = useTranslation();
  const { intent } = useIntents(brainId, intentName);
  const dispatch = useDispatch();
  const expressions = useSelector(selectExpressions);
  const expressionsWithId = useSelector(selectExpressionsWithId);

  const { isDirty, isRecommendationsDirty } = useSelector(
    (state: RootState) => ({
      isDirty:
        state.expressions.dirty || !!state.recommendations[brainId]?.dirty,
      isRecommendationsDirty: !!state.recommendations[brainId]?.dirty,
    }),
    shallowEqual
  );

  const isDraft = intentName === 'draft';

  const importIntent = async (file: File) => {
    try {
      const data = await importIntents(file, t);
      dispatch(importExpressions(data));
    } catch (error) {
      dispatch(addTemporalToast('error', error.message));
    }
  };

  const exportIntent = () => {
    exportIntents(intent);
  };

  return {
    expressionsWithId,
    expressions,
    isDirty,
    isRecommendationsDirty,
    importIntent,
    exportIntent,
    isDraft,
  };
};

export default useExpressions;
