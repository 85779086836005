import { ReactNode, useState } from 'react';

import { MutationStatus } from '@tanstack/react-query';

import ListSelectRow from './ListSelectRow';

export type ListSelectItem = {
  id: string | number;
  text: string;
  textRight?: string | ReactNode;
  items?: ListSelectItem[];
  child?: boolean;
};

export interface ListSelectProps {
  onSelect?: (...args) => void;
  onRowClick?: (...args) => void;
  items: ListSelectItem[];
  child?: boolean;
  secondLevelStatus?: MutationStatus;
}

const ListSelect: React.FC<ListSelectProps> = ({
  items = [],
  child,
  onSelect,
  onRowClick,
  secondLevelStatus,
}: ListSelectProps) => {
  const [selectedRow, setSelectedRow] = useState<string | number>(null);
  const onSelectClick = (...args) => {
    setSelectedRow(args[0]);
    onSelect(...args);
  };
  const onClick = (...args) => {
    if (selectedRow === args[0]) {
      if (Number.isInteger(args[1])) {
        onRowClick(...args);
      } else {
        setSelectedRow(null);
      }
    } else {
      setSelectedRow(args[0]);
      onRowClick(...args);
    }
  };

  return (
    <>
      {items.map((item: ListSelectItem) => {
        return (
          <ListSelectRow
            key={item.id}
            item={item}
            child={child}
            onSelect={onSelectClick}
            onRowClick={onClick}
            selectedRow={selectedRow}
            secondLevelStatus={secondLevelStatus}
          />
        );
      })}
    </>
  );
};

ListSelect.displayName = 'ListSelect';

export default ListSelect;
