import { Routes, Route } from 'react-router';

import ConversationsRouter from '@/components/pages/Conversations/ConversationsRouter';
import Layout from '@/components/templates/Layout/Layout';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';

import Chats from './Chats';

const ChatsRouter: React.FC = () => {
  return (
    <Layout>
      <PermissionsWrapper module="conversations">
        <Routes>
          <Route path="/" element={<Chats />} />
          <Route path="/:deskId/*" element={<ConversationsRouter />} />
        </Routes>
      </PermissionsWrapper>
    </Layout>
  );
};

export default ChatsRouter;
