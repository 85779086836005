import { forwardRef, useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import CheckBoxTable from '@/components/atoms/CheckBoxTable/CheckBoxTable';
import Input from '@/components/atoms/Input/Input';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import Select from '@/components/atoms/Select/Select';
import { getIntegrationByType } from '@/components/organisms/Integrations';
import useBrains from '@/hooks/useBrains';
import useDesks from '@/hooks/useDesks';
import useDialogs from '@/hooks/useDialogs';
import useMembers from '@/hooks/useMembers';
import { selectDialogId } from '@/redux/dialogs/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { CHANNELS_SUPPORTED } from '@/util/constants';

import DropdownTemplate from './DropdownTemplate';
import {
  containedOptions,
  collectionOptions,
  coveredOptions,
  getMessageOptions,
  getRatingOptions,
  testOptions,
  meaningfulOptions,
} from '../../constants';

import styles from './FilterDropdown.module.scss';

type Props = {
  type: string;
  index: number;
  onApply?: () => void;
  onClear?: () => void;
};

const FilterDropdown = forwardRef<HTMLInputElement, Props>(
  ({ type, index, onApply, onClear }, ref) => {
    const { t } = useTranslation();
    const { watch, setValue, control, register } = useFormContext();
    const dialogId = useSelector(selectDialogId);
    const brainId = useSelector(selectBrainId);
    const { dialogTags } = useDialogs(brainId, dialogId);
    const { brains } = useBrains();
    const { desks } = useDesks();
    const { members } = useMembers();
    const [newTags, setNewTags] = useState([]);
    const [tagSearch, setTagSearch] = useState('');

    const value = watch(`filters.${index}.${type}`);

    useEffect(() => {
      if (type === 'tags') {
        const tags = new Set();
        setNewTags((prev) => {
          [...value, ...prev].forEach((tag: string) => {
            if (!dialogTags.has(tag)) {
              tags.add(tag);
            }
          });
          return Array.from(tags);
        });
      }
    }, [dialogTags, type, value]);

    const renderDropdown = () => {
      switch (type) {
        case 'channels': {
          const channels = CHANNELS_SUPPORTED.map((channel) => {
            const Icon = getIntegrationByType(channel)?.Icon;
            return {
              text: t(`channels.${channel}`),
              checked: !!value.find(
                (val: string) => val && val.toLowerCase() === channel
              ),
              value: channel,
              icon: Icon && <Icon />,
            };
          });
          return (
            <DropdownTemplate size="small" type="table" zeroPadding>
              <CheckBoxTable
                title={t('analytics.all_channels')}
                items={channels}
                disableCollapse
                noBorder
                isRightColumnAuto
                onItemsChecked={(items) => {
                  const checkedChannels = items.reduce(
                    (acc, item) => (item.checked ? [...acc, item.value] : acc),
                    []
                  );
                  setValue(`filters.${index}.${type}`, checkedChannels);
                }}
              />
            </DropdownTemplate>
          );
        }
        case 'hasCollection':
          return (
            <DropdownTemplate
              size="small"
              title={t('common.conversation_type')}
              tooltip={t('collections.filter_tooltip')}
              type="radio"
            >
              {collectionOptions(t).map((opt) => (
                <Controller
                  name={`filters.${index}.${type}`}
                  control={control}
                  key={opt.id}
                  render={({ field: { onChange, value } }) => {
                    const onOptionChange = (event) => {
                      if (event.target.value === 'true') {
                        onChange(true);
                      } else {
                        onChange(false);
                      }
                    };
                    const isTrue = opt.value === 'true';
                    return (
                      <OptionRadioButton
                        option={opt}
                        onChange={onOptionChange}
                        isSelected={value === isTrue}
                        disableSelectedStyles
                      />
                    );
                  }}
                />
              ))}
            </DropdownTemplate>
          );
        case 'isMeaningful':
          return (
            <DropdownTemplate
              size="small"
              title={t('analytics.charts_tooltip.meaningul')}
              type="radio"
            >
              {meaningfulOptions(t).map((opt) => (
                <Controller
                  name={`filters.${index}.${type}`}
                  control={control}
                  key={opt.id}
                  render={({ field: { onChange, value } }) => {
                    const onOptionChange = (event) => {
                      if (event.target.value === 'true') {
                        onChange(true);
                      } else {
                        onChange(false);
                      }
                    };
                    const isTrue = opt.value === 'true';
                    return (
                      <OptionRadioButton
                        option={opt}
                        onChange={onOptionChange}
                        isSelected={value === isTrue}
                        disableSelectedStyles
                      />
                    );
                  }}
                />
              ))}
            </DropdownTemplate>
          );
        case 'isContained':
          return (
            <DropdownTemplate
              size="small"
              title={t('analytics.containment')}
              tooltip={t('analytics.containment_tooltip')}
              type="radio"
            >
              {containedOptions(t).map((opt) => (
                <Controller
                  name={`filters.${index}.${type}`}
                  control={control}
                  key={opt.id}
                  render={({ field: { onChange, value } }) => {
                    const onOptionChange = (event) => {
                      if (event.target.value === 'true') {
                        onChange(true);
                      } else {
                        onChange(false);
                      }
                    };
                    const isTrue = opt.value === 'true';
                    return (
                      <OptionRadioButton
                        option={opt}
                        onChange={onOptionChange}
                        isSelected={value === isTrue}
                        disableSelectedStyles
                      />
                    );
                  }}
                />
              ))}
            </DropdownTemplate>
          );
        case 'isCovered':
          return (
            <DropdownTemplate
              size="small"
              title={t('analytics.coverage')}
              tooltip={t('analytics.coverate_tooltip')}
              type="radio"
            >
              {coveredOptions(t).map((opt) => (
                <Controller
                  name={`filters.${index}.${type}`}
                  control={control}
                  key={opt.id}
                  render={({ field: { onChange, value } }) => {
                    const onOptionChange = (event) => {
                      if (event.target.value === 'true') {
                        onChange(true);
                      } else {
                        onChange(false);
                      }
                    };
                    const isTrue = opt.value === 'true';
                    return (
                      <OptionRadioButton
                        option={opt}
                        onChange={onOptionChange}
                        isSelected={value === isTrue}
                        disableSelectedStyles
                      />
                    );
                  }}
                />
              ))}
            </DropdownTemplate>
          );
        case 'isTest':
          return (
            <DropdownTemplate
              size="small"
              title={t('analytics.preview_sessions')}
              tooltip={t('analytics.preview_sessions_tooltip')}
              type="radio"
            >
              {testOptions(t).map((opt) => (
                <Controller
                  name={`filters.${index}.${type}`}
                  control={control}
                  key={opt.id}
                  render={({ field: { onChange, value } }) => {
                    const onOptionChange = (event) => {
                      if (event.target.value === 'true') {
                        onChange(true);
                      } else {
                        onChange(false);
                      }
                    };
                    const isTrue = opt.value === 'true';

                    return (
                      <OptionRadioButton
                        option={opt}
                        onChange={onOptionChange}
                        isSelected={value === isTrue}
                        disableSelectedStyles
                      />
                    );
                  }}
                />
              ))}
            </DropdownTemplate>
          );
        case 'ratings': {
          const ratings = getRatingOptions(value);
          return (
            <DropdownTemplate size="small" title={t('analytics.ratings')}>
              <CheckBoxTable
                title=""
                items={ratings}
                onlyIcon
                noBorder
                onItemsChecked={(items) => {
                  const checkedRatings = items.reduce(
                    (acc, item) => (item.checked ? [...acc, item.text] : acc),
                    []
                  );
                  setValue(`filters.${index}.${type}`, checkedRatings);
                }}
              />
            </DropdownTemplate>
          );
        }
        case 'maxConfidence':
          return (
            <div className={styles.maxConfidence}>
              <DropdownTemplate
                size="medium"
                title={t('analytics.fields.confidence')}
                type="input"
              >
                {t('analytics.show_sessions_with_confidence_less_than')}
                <Input
                  type="text"
                  maxLength={value.includes('.') ? 4 : 3}
                  register={register(`filters.${index}.${type}`)}
                  iconRight={<>%</>}
                />
              </DropdownTemplate>
            </div>
          );
        case 'minNumUserMessages': {
          const messageOptions = getMessageOptions(value, t);
          return (
            <DropdownTemplate
              size="medium"
              title={t('analytics.user_messages')}
              type="input"
            >
              {t('analytics.show_sessions_with_more_than')}
              <Select
                size="small"
                options={messageOptions}
                register={register(`filters.${index}.${type}`)}
                placeholder={t('common.select_an_option')}
              />
            </DropdownTemplate>
          );
        }
        case 'tags': {
          const tags = new Set(dialogTags);
          newTags.forEach((tag) => tags.add(tag));

          const tagArray = Array.from(tags)
            .sort()
            .map((tag) => ({
              text: tag,
              checked: !!value.find((val) => val === tag),
              hidden: !tag.toLowerCase().includes(tagSearch.toLowerCase()),
            }));
          const allHidden = tagArray.every((tag) => tag.hidden);
          return (
            <DropdownTemplate
              size="small"
              title={t('analytics.fields.tags')}
              type="table"
            >
              <div>
                <Input
                  placeholder={t('common.search')}
                  value={tagSearch}
                  onChange={(event) => {
                    setTagSearch(event.target.value);
                  }}
                />
                <>
                  {tagSearch && (
                    <button
                      onClick={() => {
                        const exists =
                          tagArray.findIndex(
                            ({ text }) => text.toLowerCase() === tagSearch
                          ) !== -1;
                        setTagSearch('');
                        if (!exists) {
                          setValue(`filters.${index}.${type}`, [
                            ...value,
                            tagSearch,
                          ]);
                        }
                      }}
                    >
                      {t('analytics.add_tag', { 0: tagSearch })}
                    </button>
                  )}
                </>
                <CheckBoxTable
                  title=""
                  items={tagArray}
                  noBorder
                  onItemsChecked={(items) => {
                    const checkedTags = items.reduce(
                      (acc, item) => (item.checked ? [...acc, item.text] : acc),
                      []
                    );
                    setValue(`filters.${index}.${type}`, checkedTags);
                  }}
                />
              </div>
              {allHidden && <h6>{t('analytics.no_results_found')}</h6>}
            </DropdownTemplate>
          );
        }
        case 'brainIds': {
          const brainItems = brains?.map((brain) => ({
            text: brain?.name,
            value: brain.brain_id,
            checked: !!value.find((val) => val.value === brain.brain_id),
          }));
          return (
            <DropdownTemplate size="small" type="table" zeroPadding>
              <CheckBoxTable
                title={t('common.brains')}
                items={brainItems}
                disableCollapse
                noBorder
                onItemsChecked={(items) => {
                  const checkedBrains = items.reduce(
                    (acc, item) =>
                      item.checked
                        ? [...acc, { value: item.value, label: item.text }]
                        : acc,
                    []
                  );
                  setValue(`filters.${index}.${type}`, checkedBrains);
                }}
              />
            </DropdownTemplate>
          );
        }
        case 'deskIds': {
          const deksItems = desks?.map((desk) => ({
            text: desk?.name,
            value: desk.desk_id,
            checked: !!value.find((val) => val.value === desk.desk_id),
          }));
          return (
            <DropdownTemplate size="small" type="table" zeroPadding>
              <CheckBoxTable
                title={t('common.environments')}
                items={deksItems}
                disableCollapse
                noBorder
                onItemsChecked={(items) => {
                  const checkedBrains = items.reduce(
                    (acc, item) =>
                      item.checked
                        ? [...acc, { value: item.value, label: item.text }]
                        : acc,
                    []
                  );
                  setValue(`filters.${index}.${type}`, checkedBrains);
                }}
              />
            </DropdownTemplate>
          );
        }
        case 'agentIds': {
          const agentItems = members?.map((member) => ({
            text: member?.name,
            value: member.user_id,
            checked: !!value.find((val) => val.value === member.user_id),
          }));
          return (
            <DropdownTemplate size="small" type="table" zeroPadding>
              <CheckBoxTable
                title={t('common.agents')}
                items={agentItems}
                disableCollapse
                noBorder
                onItemsChecked={(items) => {
                  const checkedBrains = items.reduce(
                    (acc, item) =>
                      item.checked
                        ? [...acc, { value: item.value, label: item.text }]
                        : acc,
                    []
                  );
                  setValue(`filters.${index}.${type}`, checkedBrains);
                }}
              />
            </DropdownTemplate>
          );
        }
        default:
          return null;
      }
    };

    return (
      <div className={styles.dropdown} ref={ref}>
        {renderDropdown()}
        <div className={styles.footer}>
          <Button size="xs" variant="tertiary" onClick={onClear}>
            {t('common.clear')}
          </Button>
          <Button size="xs" onClick={onApply}>
            {t('common.apply')}
          </Button>
        </div>
      </div>
    );
  }
);

FilterDropdown.displayName = 'FilterDropdown';

export default FilterDropdown;
