import { memo } from 'react';

import Typography from '@mui/material/Typography';

const Error = ({ message }) => {
  return (
    <>
      {message && (
        <Typography variant="label-regular" color="var(--icon-default-error)">
          {message}
        </Typography>
      )}
    </>
  );
};

export default memo(Error);
