import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Accept, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import useFileUpload from '@/hooks/useFileUpload';
import { UploadedFile } from '@/models/server';
import {
  addTemporalToast,
  safelyGetErrorMessage,
} from '@/modules/notifications/redux/actions';
import Directory from '@/modules/onboarding/icons/Directory';
import { FIFTEEN_MB, HUNDRED_BYTES } from '@/util/constants';
import { isVideo } from '@/util/util';
import './FileUpload.scss';

import useFileSubmit from './useFileSubmit';
import { processFiles, handleErrors, AcceptedType } from './utils';
import File from '../Icons/File';
import Trash from '../Icons/Trash';
import Loading from '../Loading/Loading';

type BaseProps = {
  message: React.ReactNode;
  messageImage?: boolean;
  maxSizeInBytes?: number;
  minSizeInBytes?: number;
  accept?: Accept;
  stream?: boolean;
  uploadPath?: string;
  parseJson?: boolean;
  onError?: (error: string) => void;
  withThumbnail?: boolean;
  noGutters?: boolean;
  restrictedNames?: string[];
  disabled?: boolean;
  isLoadingProp?: boolean;
  forceError?: boolean;
};

type MultipleUploadProps = {
  multiple: true;
  setUrls: Dispatch<
    SetStateAction<{ url: string; name: string; type?: string }[]>
  >;
  setFilesLength: Dispatch<SetStateAction<number>>;
  files: AcceptedType[];
  setFiles: Dispatch<SetStateAction<AcceptedType[]>>;
  duplicates: AcceptedType[];
  setDuplicates: Dispatch<SetStateAction<AcceptedType[]>>;
  onReplaceAll: (x: (x: AcceptedType[]) => Promise<void>) => void;
  onMergeAll: (x: (x: AcceptedType[]) => Promise<void>) => void;
  duplicatesLength: number;
};

type SingleUploadProps = {
  fileName?: string;
  onFileUploaded?: (f: UploadedFile) => void;
  isFileMode?: boolean;
  urlValue?: string;
  shouldCrop?: boolean;
  aspectRatio?: 'horizontal' | 'square';
  onFileRemoved?: () => void;
};

type Props = BaseProps & (MultipleUploadProps | SingleUploadProps);

const FileUpload = (props: Props) => {
  const {
    multiple,
    setUrls,
    setFilesLength,
    onReplaceAll,
    onMergeAll,
    setFiles,
    setDuplicates,
    duplicatesLength,
  } = props as MultipleUploadProps;

  const {
    fileName,
    onFileUploaded,
    isFileMode = false,
    urlValue,
    shouldCrop = false,
    aspectRatio,
    onFileRemoved,
  } = props as SingleUploadProps;

  const {
    message,
    maxSizeInBytes = FIFTEEN_MB,
    minSizeInBytes = HUNDRED_BYTES,
    accept,
    stream,
    uploadPath,
    parseJson,
    onError,
    withThumbnail = false,
    messageImage,
    noGutters = false,
    restrictedNames = [],
    disabled = false,
    isLoadingProp,
    forceError = false,
  } = props;

  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<string>(fileName);
  const retriesRef = useRef(0);
  const [error, setError] = useState(null);
  const { croppedFile, onAvatarUpload } = useFileUpload(aspectRatio);
  const isVid = isVideo(urlValue);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    uploadFile,
    data,
    error: uploadFileError,
    isLoading: loading,
  } = useFileSubmit({
    stream,
    uploadPath,
    parseJson,
  });
  const isUploading = isLoading || loading || isLoadingProp;
  useEffect(() => {
    if (croppedFile) {
      uploadFile(croppedFile);
    }
  }, [croppedFile, uploadFile]);

  useEffect(() => {
    setSelectedFile(fileName);
  }, [fileName]);

  useEffect(() => {
    if (data) {
      if (multiple) {
        setUrls((prev) => [
          ...prev,
          { url: data.url, name: data.name, type: data?.type },
        ]);
      }
      if (onFileUploaded) {
        onFileUploaded(data);
      }
    }
  }, [data, multiple, onFileUploaded, setUrls]);

  useEffect(() => {
    if (uploadFileError) {
      setError(uploadFileError);
    }
  }, [uploadFileError]);

  const handleOnRemoveFile = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      // Prevent file dialog box to open
      e.stopPropagation();

      setSelectedFile(null);
      if (onFileRemoved) {
        onFileRemoved();
      }
    },
    [onFileRemoved]
  );

  const handleClearError = useCallback(() => {
    if (setFiles) {
      setFiles([]);
    }
    if (setDuplicates) {
      setDuplicates([]);
    }
    if (onError) {
      onError(null);
    }
    setSelectedFile(null);
    setError(null);
  }, [onError, setDuplicates, setFiles]);

  const processAndFilterFiles = useCallback(
    async (acceptedFiles) => {
      try {
        const { nonRestrictedFiles } = await processFiles(
          acceptedFiles,
          restrictedNames
        );
        return nonRestrictedFiles;
      } catch (error) {
        if (setFiles) {
          setFiles(error.nonRestrictedFiles || []);
        }

        if (setDuplicates && error.duplicateFiles) {
          setDuplicates(error.duplicateFiles || []);
          dispatch(
            addTemporalToast(
              'info',
              t('file_upload.x_files_exist', {
                count: error.duplicateFiles.length,
              })
            )
          );
        }

        setError(error.message);

        return null;
      }
    },
    [dispatch, restrictedNames, setDuplicates, setFiles, t]
  );

  const uploadFiles = useCallback(
    async (files) => {
      try {
        if (!multiple) {
          const file = files[0];
          if (shouldCrop) {
            onAvatarUpload(undefined, file, {
              primaryButtonText: t('file_upload.upload'),
            });
          } else {
            uploadFile(file);
          }
          setSelectedFile(file.path);
        } else {
          setFilesLength(files.length);
          setIsLoading(true);
          for (const file of files) {
            uploadFile(file);
          }
          setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
        if (onError) {
          onError(error);
        }
        if (setFiles) {
          setFiles([]);
        }
        if (setDuplicates) {
          setDuplicates([]);
        }
        console.error(error);
      }
    },
    [
      multiple,
      onAvatarUpload,
      onError,
      setDuplicates,
      setFiles,
      setFilesLength,
      shouldCrop,
      t,
      uploadFile,
    ]
  );

  const processFilesForUpload = useCallback(
    async (files) => {
      const nonRestrictedFiles = await processAndFilterFiles(files);
      // if !nonRestrictedFiles, it means that the `processFiles` promise was rejected
      if (nonRestrictedFiles) {
        await uploadFiles(nonRestrictedFiles);
      }
    },
    [processAndFilterFiles, uploadFiles]
  );

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        handleErrors(
          rejectedFiles,
          dispatch,
          minSizeInBytes,
          maxSizeInBytes,
          accept
        );
        return;
      }
      await processFilesForUpload(acceptedFiles);
    },
    [processFilesForUpload, dispatch, minSizeInBytes, maxSizeInBytes, accept]
  );

  const handleMergeAll = async () => {
    onMergeAll(uploadFiles);
    setError(null);
  };

  const handleReplaceAll = async () => {
    onReplaceAll(uploadFiles);
    setError(null);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: !!withThumbnail,
    noKeyboard: !!withThumbnail,
    accept,
    maxSize: maxSizeInBytes,
    minSize: minSizeInBytes,
    disabled: isUploading || disabled,
    multiple,
    noDragEventsBubbling: true,
    onDrop,
  });

  const handleOpen = useCallback(() => {
    open();
  }, [open]);

  const renderError = () => (
    <div
      className={cn('file-selected file-selected--error', {
        noGutters,
      })}
    >
      <p className="error">
        {t('file_upload.error', { 0: safelyGetErrorMessage(error) })}
      </p>
      <p>
        <Button variant="danger" onClick={handleClearError}>
          {t('common.clear')}
        </Button>
      </p>
    </div>
  );

  const renderDuplicateError = () => (
    <div
      className={cn('duplicates', {
        noGutters,
      })}
    >
      <Typography component="p">
        {t('file_upload.x_files_exist', {
          count: duplicatesLength,
        })}
      </Typography>

      <Typography component="p" color="var(--text-default-gray)">
        {t('file_upload.replace_or_merge', {
          count: duplicatesLength,
        })}
      </Typography>
      <p className="buttons">
        <Button variant="tertiary" onClick={handleClearError}>
          {t('common.clear')}
        </Button>
        <Button variant="secondary" onClick={handleMergeAll}>
          {t('file_upload.merge_all', { count: duplicatesLength })}
        </Button>
        <Button variant="primary" onClick={handleReplaceAll}>
          {t('file_upload.replace_all', { count: duplicatesLength })}
        </Button>
      </p>
    </div>
  );

  const renderSelectedFile = () => (
    <div
      className={cn('file-selected', {
        noGutters,
      })}
    >
      <p>{selectedFile}</p>
      <p>
        <Button
          isLoading={isUploading}
          variant="secondary"
          onClick={handleOnRemoveFile}
          disabled={disabled}
        >
          {t('common.remove')}
        </Button>
      </p>
    </div>
  );

  const renderUploaded = () => {
    const handleImgError = (e) => {
      if (retriesRef.current < 4) {
        e.target.src = `https://res.cloudinary.com/dey0ylu2x/image/upload/v1607095100/carousel/image1.png`;
        e.target.alt = 'Fallback Image';
        retriesRef.current += 1;
      }
      return;
    };

    if (isFileMode) {
      return (
        <div className="isFileMode">
          <File size={36} />
          <p>{fileName}</p>
        </div>
      );
    } else {
      return isVid ? (
        <video src={urlValue} muted />
      ) : (
        <img src={urlValue} alt="media" onError={handleImgError} />
      );
    }
  };

  const renderMediaSelectedFile = () => (
    <div
      className={cn({
        'media-uploaded': true,
        'media-uploaded--empty': urlValue === '',
      })}
    >
      <input {...getInputProps()} />
      {renderUploaded()}
      <div className="media-controls">
        <Button
          onClick={handleOpen}
          size="small"
          variant="secondary"
          disabled={disabled}
        >
          {t('common.browse')}
        </Button>
        <Button
          variant="secondary"
          size="small"
          onClick={handleOnRemoveFile}
          className="remove-media"
        >
          <Trash color="var(--icon-default-blue)" />
        </Button>
      </div>
    </div>
  );

  const renderDropdown = () => (
    <>
      <input {...getInputProps()} />
      {messageImage && <Directory />}
      <p>{message}</p>
    </>
  );

  const renderMediaDropdown = () => {
    if (isUploading) {
      return <Loading />;
    }
    return (
      <>
        <input {...getInputProps()} />
        <Typography>{message}</Typography>
        <Typography>{t('rules.or_caps')}</Typography>
        <Button
          onClick={handleOpen}
          size="small"
          variant="secondary"
          disabled={disabled}
        >
          {t('common.browse')}
        </Button>
      </>
    );
  };

  if (withThumbnail) {
    return (
      <section
        className={cn('media-upload', {
          disabled: disabled,
          'media-upload--empty': urlValue === '',
          'media-upload--has-duplicates': duplicatesLength > 0,
          ['with-error']: (error && error !== 'duplicates') || forceError,
          ['isFileMode']: isFileMode,
        })}
      >
        <div {...getRootProps({ className: 'dropzone' })}>
          {error && error !== 'duplicates' && renderError()}
          {error && error === 'duplicates' && renderDuplicateError()}
          {!error && urlValue && renderMediaSelectedFile()}
          {!error && !urlValue && renderMediaDropdown()}
        </div>
      </section>
    );
  }
  return (
    <section
      className={cn('file-upload', {
        ['with-error']: (error && error !== 'duplicates') || forceError,
        disabled: disabled,
      })}
    >
      <div {...getRootProps({ className: 'dropzone' })}>
        {error && error !== 'duplicates' && renderError()}
        {error && error === 'duplicates' && renderDuplicateError()}
        {!error && selectedFile && renderSelectedFile()}
        {!error && !selectedFile && renderDropdown()}
      </div>
    </section>
  );
};

export default memo(FileUpload);
