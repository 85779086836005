import { forwardRef, ComponentProps } from 'react';

import cn from 'classnames';
import { ConnectDragSource } from 'react-dnd';

import styles from './Box.module.scss';

type BoxProps = {
  dragRef?: ConnectDragSource;
};

const Box = forwardRef<HTMLDivElement, BoxProps & ComponentProps<'div'>>(
  ({ children, className, dragRef, ...props }, ref) => {
    const classesFromProps =
      typeof className === 'string'
        ? className
        : className
          ? [...className]
          : [];

    return (
      <div
        ref={dragRef || ref}
        className={cn(classesFromProps, {
          [styles.box]: true,
        })}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Box.displayName = 'Box';

export default Box;
