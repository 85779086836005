const Rating = ({ stars }) => {
  switch (stars) {
    case 1:
      return (
        <svg
          width="112"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m8 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586L8 11.847l-4.12 2.166.787-4.586L1.333 6.18l4.607-.673L8 1.333Z"
            fill="var(--icon-default-gray)"
            stroke="var(--icon-default-gray)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m32 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586L32 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L32 1.333ZM56 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L56 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L56 1.333ZM80 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L80 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L80 1.333ZM104 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586-4.12-2.166-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L104 1.333Z"
            stroke="#C2C2C2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 2:
      return (
        <svg
          width="112"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m8 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586L8 11.847l-4.12 2.166.787-4.586L1.333 6.18l4.607-.673L8 1.333ZM32 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L32 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L32 1.333Z"
            fill="var(--icon-default-gray)"
            stroke="var(--icon-default-gray)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m56 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586L56 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L56 1.333ZM80 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L80 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L80 1.333ZM104 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586-4.12-2.166-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L104 1.333Z"
            stroke="#C2C2C2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 3:
      return (
        <svg
          width="112"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m8 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586L8 11.847l-4.12 2.166.787-4.586L1.333 6.18l4.607-.673L8 1.333ZM32 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L32 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L32 1.333ZM56 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L56 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L56 1.333Z"
            fill="var(--icon-default-gray)"
            stroke="var(--icon-default-gray)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m80 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586L80 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L80 1.333ZM104 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586-4.12-2.166-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L104 1.333Z"
            stroke="#C2C2C2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 4:
      return (
        <svg
          width="112"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m8 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586L8 11.847l-4.12 2.166.787-4.586L1.333 6.18l4.607-.673L8 1.333ZM32 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L32 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L32 1.333ZM56 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L56 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L56 1.333ZM80 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L80 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L80 1.333Z"
            fill="var(--icon-default-gray)"
            stroke="var(--icon-default-gray)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m104 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586-4.12-2.166-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L104 1.333Z"
            stroke="#C2C2C2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 5:
      return (
        <svg
          width="112"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m8 1.333 2.06 4.174 4.607.673-3.334 3.247.787 4.586L8 11.847l-4.12 2.166.787-4.586L1.333 6.18l4.607-.673L8 1.333ZM32 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L32 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L32 1.333ZM56 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L56 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L56 1.333ZM80 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L80 11.847l-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L80 1.333ZM104 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586-4.12-2.166-4.12 2.166.787-4.586-3.334-3.247 4.607-.673L104 1.333Z"
            fill="var(--icon-default-gray)"
            stroke="var(--icon-default-gray)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default Rating;
