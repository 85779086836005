import { useCallback, useState } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { businessHoursEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import {
  DEFAULT_HOURS,
  extractIntervalsFromHours,
  minutesToHours,
  timeIntervalsMaker,
  TIME_DROPDOWN_INTERVAL,
} from '@/components/pages/BusinessHours/utils';
import { BusinessHour, BusinessHours } from '@/models/businessHours';
import { OptionBase } from '@/models/common';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { selectAccountId, selectDeskId } from '@/redux/session/selectors';
import { timezones } from '@/util/timezones';
import { timezoneMaker } from '@/util/util';

type TimeSelection = OptionBase;

export const API = {
  listBusinessHours: async (deskId: string): Promise<BusinessHours> =>
    callGet(endpoints.businessHours(deskId)),

  getBusinessHour: async (
    deskId: string,
    businessHoursId: string
  ): Promise<BusinessHour> =>
    callGet(endpoints.businessHour(deskId, businessHoursId)),

  addBusinessHour: async (
    deskId: string,
    newBusinessHour: Partial<BusinessHour>
  ): Promise<BusinessHour> =>
    callPost(endpoints.businessHours(deskId), newBusinessHour),

  updateBusinessHour: async (
    deskId: string,
    businessHour: Partial<BusinessHour>
  ): Promise<BusinessHour> =>
    callPut(
      endpoints.businessHour(deskId, businessHour.business_hours_id),
      businessHour
    ),

  deleteBusinessHour: async (
    deskId: string,
    businessHoursId: string
  ): Promise<BusinessHour> =>
    callDelete(endpoints.businessHour(deskId, businessHoursId)),
} as const;

export const onBusinessHourUpdated = (
  queryClient: QueryClient,
  deskId: string,
  businessHour: BusinessHour
) => {
  queryClient.setQueryData<BusinessHour>(
    [endpoints.businessHour(deskId, businessHour.business_hours_id)],
    (prev: BusinessHour) => ({ ...prev, ...businessHour })
  );

  const queryKey = [endpoints.businessHours, deskId, 'business_hours'];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<BusinessHours>(queryKey, (prev) => ({
      business_hours: (prev?.business_hours || []).map((item) =>
        item.business_hours_id === businessHour.business_hours_id
          ? businessHour
          : item
      ),
    }));
  }
};

export const onBusinessHourCreated = (
  queryClient: QueryClient,
  deskId: string,
  businessHour: BusinessHour
) => {
  const queryKey = [endpoints.businessHours, deskId, 'business_hours'];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<BusinessHours>(queryKey, (prev) => ({
      business_hours: [
        ...(prev?.business_hours || []).filter(
          (item) => item.business_hours_id !== businessHour.business_hours_id
        ),
        businessHour,
      ],
    }));
  }
};

export const onBusinessHourRemoved = (
  queryClient: QueryClient,
  deskId: string,
  business_hours_id: string
) => {
  const queryKey = [endpoints.businessHours, deskId, 'business_hours'];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<BusinessHours>(queryKey, (prev) => ({
      business_hours: (prev?.business_hours || []).filter(
        (acc) => acc.business_hours_id !== business_hours_id
      ),
    }));
  }
};

const useBusinessHours = (businessHourId?: string) => {
  const { t } = useTranslation();
  const accountId = useSelector(selectAccountId);
  const deskId = useSelector(selectDeskId);
  const dispatch = useDispatch();
  const [hours, setHours] = useState([DEFAULT_HOURS]);
  const [initialHours, setInitalHours] = useState([DEFAULT_HOURS]);
  const [timezone, setTimezone] = useState(timezoneMaker(['Europe/London'])[0]);
  const [initialTimezone, setInitialTimezone] = useState(
    timezoneMaker(['Europe/London'])[0]
  );

  const queryClient = useQueryClient();

  const { data: businessHours, status: listStatus } = useQuery<
    BusinessHours,
    Error
  >({
    queryKey: [endpoints.businessHours, deskId, 'business_hours'],
    queryFn: () => API.listBusinessHours(deskId),
    enabled: !!accountId && !!deskId,
  });

  const { data: singleBusinessHour, status: singleBusinessHourStatus } =
    useQuery<BusinessHour, Error>({
      queryKey: [endpoints.businessHour(deskId, businessHourId)],
      queryFn: () => API.getBusinessHour(deskId, businessHourId),
      enabled: !!deskId && !!businessHourId && businessHourId !== 'draft',
      initialData: businessHours?.business_hours?.find(
        (e) => businessHourId === e.business_hours_id
      ),
    });

  const { mutate: updateBusinessHour, status: updateStatus } = useMutation<
    BusinessHour,
    Error,
    Partial<BusinessHour>
  >({
    mutationFn: (businessHour) => API.updateBusinessHour(deskId, businessHour),
    onSuccess: (resp) => {
      onBusinessHourUpdated(queryClient, deskId, resp);
      setInitalHours(hours);
      setInitialTimezone(timezone);
      dispatch(
        addTemporalToast(
          'success',
          t('business_hours.updated', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: addBusinessHour, status: addStatus } = useMutation<
    BusinessHour,
    Error,
    Partial<BusinessHour>
  >({
    mutationFn: (businessHour) => API.addBusinessHour(deskId, businessHour),
    onSuccess: (resp) => {
      onBusinessHourCreated(queryClient, deskId, resp);
      setInitalHours(hours);
      setInitialTimezone(timezone);
      dispatch(
        addTemporalToast(
          'success',
          t('business_hours.created', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteBusinessHour, status: deleteStatus } = useMutation<
    BusinessHour,
    Error,
    string
  >({
    mutationFn: (id) => API.deleteBusinessHour(deskId, id),
    onSuccess: (resp) => {
      dispatch(popModal());
      onBusinessHourRemoved(queryClient, deskId, resp.business_hours_id);
      dispatch(addTemporalToast('success', t('business_hours.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  // helper function
  const timeModifier = useCallback(
    (key: string, label: string, value: string, index: number) => {
      setHours((prev) => {
        const hourToModify = prev[index];
        return [
          ...prev.slice(0, index),
          { ...hourToModify, [key]: { label, value } },
          ...prev.slice(index + 1),
        ];
      });
    },
    []
  );

  // helper function
  const startTimeModifier = useCallback(
    (previous, index: number, from: TimeSelection, to: TimeSelection) => {
      return [
        ...previous.slice(0, index),
        {
          ...previous[index],
          from,
          to,
        },
        ...previous.slice(index + 1),
      ];
    },
    []
  );

  const handleFrequencyClick = useCallback(
    (data, index) => {
      const { label, value } = data;
      timeModifier('frequency', label, value, index);
    },
    [timeModifier]
  );

  const startTimeOptions = useCallback(() => {
    return timeIntervalsMaker(TIME_DROPDOWN_INTERVAL);
  }, []);

  const handleFromClick = useCallback(
    (data, index) => {
      const { value } = data;

      setHours((prev) => {
        const hourToModify = prev[index];
        const endLabel = minutesToHours(Number(value) + TIME_DROPDOWN_INTERVAL);
        const endValue = `${Number(value) + TIME_DROPDOWN_INTERVAL}`;
        const endTime = { label: endLabel, value: endValue };
        const startTimes = startTimeOptions();

        // handle user selecting end time greagter-equal to start time
        if (Number(hourToModify.to.value) <= Number(value)) {
          // when user selects the last value of the start time dropdown, end time will become 11:59pm
          if (value === startTimes[startTimes.length - 1].value) {
            return startTimeModifier(prev, index, data, {
              label: '11:59 PM',
              value: '1439',
            });
          }
          return startTimeModifier(prev, index, data, endTime);
        } else {
          // the normal action
          return startTimeModifier(prev, index, data, hourToModify.to);
        }
      });
    },
    [startTimeModifier, startTimeOptions]
  );

  const handleToClick = useCallback(
    (data, index) => {
      const { label, value } = data;
      timeModifier('to', label, value, index);
    },
    [timeModifier]
  );

  const handleTimezoneClick = useCallback((data) => {
    setTimezone(data);
  }, []);

  const handleRemoveClick = useCallback((index) => {
    setHours((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  }, []);

  const handleAddClick = useCallback(() => {
    setHours((prev) => [...prev, DEFAULT_HOURS]);
  }, []);

  const timezoneOptions = () => {
    const times = timezoneMaker(timezones);
    return times.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
  };

  const endTimeOptions = useCallback(
    (index) => {
      const startTime = hours[index].from.value;
      const timeIntervals = timeIntervalsMaker(TIME_DROPDOWN_INTERVAL, true);
      const indexToStartFrom = timeIntervals.findIndex(
        (x) => x.value === startTime
      );
      return timeIntervals.slice(indexToStartFrom + 1);
    },
    [hours]
  );

  const createDefaultBusinessHour = useCallback(() => {
    addBusinessHour({
      name: t('business_hours.default'),
      timezone: moment.tz.guess(),
      is_default: true,
      intervals: extractIntervalsFromHours([DEFAULT_HOURS]),
      holidays: [],
    });
  }, [addBusinessHour, t]);

  return {
    singleBusinessHour,
    isDefaultBusinessHour: singleBusinessHour?.is_default,
    singleBusinessHourStatus,
    businessHours,
    listStatus,
    updateBusinessHour,
    updateStatus,
    addBusinessHour,
    addStatus,
    deleteBusinessHour,
    deleteStatus,
    hours,
    setHours,
    handleFrequencyClick,
    handleFromClick,
    handleToClick,
    handleTimezoneClick,
    setTimezone,
    timezone,
    handleRemoveClick,
    handleAddClick,
    timezoneOptions,
    startTimeOptions,
    endTimeOptions,
    createDefaultBusinessHour,
    initialHours,
    setInitalHours,
    initialTimezone,
    setInitialTimezone,
  };
};

export default useBusinessHours;
