import { PropsWithChildren } from 'react';

import { BroadcastMain } from './BroadcastMain';
import { BroadcastSidebar } from './BroadcastSidebar';

import styles from './BroadcastLayout.module.scss';

const BroadcastLayout = ({ children }: PropsWithChildren) => {
  return <div className={styles.container}>{children}</div>;
};
BroadcastLayout.Main = BroadcastMain;
BroadcastLayout.Sidebar = BroadcastSidebar;

export default BroadcastLayout;
