interface Props {
  width?: number;
  height?: number;
}

const Logo = ({ width = 140, height = 24 }: Props) => {
  return (
    <div className="logo">
      {
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 120 18"
          width={width}
          height={height}
        >
          <rect
            x="6.35"
            y="6.58"
            width="4.897"
            height="9.257"
            rx="2.449"
            transform="rotate(-90 6.35 6.58)"
            fill="#1B66D6"
          />
          <ellipse cx="3.25" cy="12.439" rx="3.25" ry="3.439" fill="#1B66D6" />
          <ellipse cx="1.896" cy="4.07" rx="1.896" ry="2.006" fill="#1B66D6" />
          <ellipse
            cx="10.954"
            cy="12.342"
            rx="1.896"
            ry="2.006"
            fill="#1B66D6"
          />
          <path
            d="M63.547 2.034c0-.59.177-1.056.523-1.422.355-.369.798-.555 1.346-.555h6.845c.442 0 .8.379.8.847 0 .467-.358.847-.8.847h-6.87a.251.251 0 0 0-.244.258V7.7c0 .143.11.258.244.258h4.428c.443 0 .801.38.801.847 0 .468-.358.847-.8.847H65.39a.251.251 0 0 0-.244.258v5.68c0 .142.11.258.244.258h6.87c.442 0 .8.379.8.847 0 .467-.358.847-.8.847h-6.845c-.558 0-1-.188-1.345-.554-.346-.367-.524-.834-.524-1.424V2.035ZM25.457 1.396v.001l1.89 9.08a.248.248 0 0 0 .239.202.247.247 0 0 0 .238-.202l1.902-9.08.002-.006c.068-.368.25-.682.562-.944.31-.262.658-.39 1.053-.39.482 0 .876.173 1.198.521l.002.002c.33.35.494.773.494 1.284v14.858c0 .462-.354.837-.791.837-.438 0-.792-.375-.792-.837V1.864a.253.253 0 0 0-.217-.256.246.246 0 0 0-.265.2l-1.938 9.079-.003.012c-.051.309-.213.582-.51.822l-.002.002a1.428 1.428 0 0 1-1.865.014 1.444 1.444 0 0 1-.503-.847v-.003L24.2 1.807a.246.246 0 0 0-.265-.2.253.253 0 0 0-.217.257v14.858c0 .462-.355.837-.792.837-.437 0-.791-.375-.791-.837V1.864c0-.51.167-.931.505-1.282.34-.353.737-.525 1.201-.525.385 0 .73.128 1.04.39.313.263.5.578.576.949ZM57.897 6.461c.353-1.107.53-2.248.53-3.423V.894c0-.462.355-.837.792-.837.437 0 .791.375.791.837v2.144c0 1.367-.2 2.682-.601 3.947v.002l-2.842 9.128v.001c-.161.505-.39.86-.675 1.092-.284.23-.642.351-1.092.351-.45 0-.809-.122-1.092-.351-.285-.231-.514-.587-.675-1.092l-2.829-9.127v-.001a13.662 13.662 0 0 1-.578-3.95V.894c0-.462.354-.837.791-.837.437 0 .792.375.792.837v2.144c0 1.223.168 2.364.506 3.422v.001l2.853 9.243a.246.246 0 0 0 .232.178c.105 0 .199-.072.232-.178l2.865-9.243Z"
            fill="#0A3451"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m44.861 1.358.002.002c.818.86 1.225 1.901 1.225 3.136v8.62c0 1.234-.407 2.275-1.225 3.136v.001c-.812.861-1.791 1.29-2.954 1.29h-1.156c-1.162 0-2.145-.428-2.964-1.29-.812-.87-1.215-1.911-1.215-3.138V4.496c0-1.235.404-2.276 1.215-3.137.819-.87 1.803-1.302 2.964-1.302h1.156c1.162 0 2.141.432 2.952 1.3Zm-5.979 13.731.002.002c.517.54 1.145.813 1.87.813h1.155c.725 0 1.35-.273 1.859-.814.518-.541.78-1.204.78-1.975V4.496c0-.77-.262-1.432-.78-1.973-.509-.55-1.133-.828-1.859-.828h-1.156c-.726 0-1.354.279-1.87.828-.51.541-.768 1.204-.768 1.973v8.62c0 .769.257 1.431.767 1.973ZM84.886 1.358l.001.002c.819.86 1.226 1.901 1.226 3.136v8.62c0 1.234-.407 2.275-1.226 3.136v.001c-.811.861-1.79 1.29-2.953 1.29h-1.156c-1.162 0-2.146-.428-2.965-1.29-.811-.87-1.214-1.911-1.214-3.138V4.496c0-1.235.403-2.276 1.214-3.137.82-.87 1.803-1.302 2.965-1.302h1.156c1.162 0 2.14.432 2.952 1.3Zm-5.98 13.731.002.002c.517.54 1.145.813 1.87.813h1.156c.725 0 1.349-.273 1.859-.814.517-.541.78-1.204.78-1.975V4.496c0-.77-.262-1.432-.78-1.973-.509-.55-1.134-.828-1.86-.828h-1.155c-.726 0-1.354.279-1.871.828-.51.541-.767 1.204-.767 1.973v8.62c0 .769.257 1.431.767 1.973Z"
            fill="#0A3451"
          />
          <path
            d="M113.602 1.657a.251.251 0 0 0-.244.258V15.65c0 .143.109.258.244.258h1.006c.422 0 .764.362.764.809 0 .446-.342.808-.764.808h-4.083c-.422 0-.764-.362-.764-.808 0-.447.342-.809.764-.809h1.006a.251.251 0 0 0 .244-.258V1.915a.251.251 0 0 0-.244-.258h-.998c-.426 0-.772-.366-.772-.817 0-.45.346-.817.772-.817h4.067c.426 0 .772.366.772.817 0 .451-.346.817-.772.817h-.998ZM92.243 16.17c0 .749-.574 1.356-1.281 1.356-.708 0-1.282-.607-1.282-1.356 0-.749.574-1.356 1.281-1.356.708 0 1.282.607 1.282 1.356Z"
            fill="#0A3451"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M97.695 12.012c-.187.818-.28 1.655-.28 2.51v2.143c0 .463-.355.838-.792.838-.437 0-.791-.375-.791-.838v-2.143c0-1.367.2-2.683.601-3.948v-.001l2.842-9.129c.161-.505.39-.861.675-1.092.284-.23.642-.352 1.092-.352.45 0 .809.122 1.092.352.285.23.514.587.675 1.091l.001.001 2.828 9.126v.001c.386 1.284.578 2.6.578 3.95v2.144c0 .463-.354.838-.791.838-.437 0-.792-.375-.792-.838v-2.143c0-.88-.086-1.716-.261-2.51h-6.677Zm6.152-1.822-2.573-8.335a.246.246 0 0 0-.232-.178.246.246 0 0 0-.232.178l-2.583 8.335h5.62Z"
            fill="#0A3451"
          />
          <path
            d="M116.878.3h.542v1.68h.273V.3h.542V.045h-1.357V.3Zm1.685 1.68h.274v-.66c0-.354-.012-.721-.036-1.007l.334 1.288h.286l.335-1.282c-.024.323-.037.703-.037 1.002v.66h.28V.045h-.395l-.122.48c-.073.26-.14.522-.201.852-.067-.33-.14-.641-.2-.86l-.128-.472h-.39V1.98Z"
            fill="#0A3451"
          />
        </svg>
      }
    </div>
  );
};

export default Logo;
