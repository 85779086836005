import { useCallback } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Select from '@/components/atoms/Select/Select';
import Switch from '@/components/atoms/Switch/Switch';
import { Action } from '@/models/action';
import { RootState } from '@/models/state';
import { selectSelectedAction } from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { pauseActionRules } from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitActionPause.module.scss';

export const durationOptions = [];
const getLabelSeconds = (duration: number) =>
  duration === 1 ? 'second' : 'seconds';

for (let duration = 1; duration < 11; duration += 1) {
  durationOptions.push({
    value: duration,
    label: `${duration} ${getLabelSeconds(duration)}`,
  });
}

type FormType = {
  duration: string;
  show_typing: boolean;
};

const ToolkitActionPause = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { actionId, duration, show_typing } = useSelector(
    (state: RootState) => {
      const selectedAction = selectSelectedAction(state) as Extract<
        Action,
        { type: 'pause' }
      >;
      return {
        type: selectedAction.type,
        actionId: selectedAction.action_id,
        duration: selectedAction.duration,
        show_typing: selectedAction.show_typing,
      };
    },
    shallowEqual
  );

  const { getValues, register, control } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: { duration: duration.toString(), show_typing },
  });

  const onDurationChange = useCallback(() => {
    dispatch(
      updateAction({
        actionId,
        action: {
          duration: parseInt(getValues().duration, 10),
          show_typing,
        },
      })
    );
  }, [getValues, show_typing, dispatch, actionId]);

  const onTypingSwitch = useCallback(() => {
    dispatch(
      updateAction({
        actionId,
        action: {
          duration,
          show_typing: getValues().show_typing,
        },
      })
    );
  }, [dispatch, actionId, duration, getValues]);

  return (
    <ToolkitWrapper type="pause">
      <Controller
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <Select
                label={t('dialog.pause.duration')}
                required
                placeholder={t('dialog.pause.select_a_duration')}
                name="duration"
                register={register('duration', pauseActionRules.duration)}
                onChange={onDurationChange}
                options={durationOptions}
                tooltip={t('dialog.pause.duration_help')}
                size="full"
              />

              <div className={styles.switchWrapper}>
                <Switch
                  checked={value}
                  onChange={(event) => {
                    onChange(event);
                    onTypingSwitch();
                  }}
                  size="medium"
                  label={t('dialog.pause.show_typing_indicators')}
                  tooltip={t('dialog.pause.show_typing_help')}
                  reverse
                />
              </div>
            </>
          );
        }}
        control={control}
        name="show_typing"
        rules={pauseActionRules.show_typing}
      />
    </ToolkitWrapper>
  );
};

export default ToolkitActionPause;
