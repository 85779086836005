import { useCallback, useRef, useEffect } from 'react';

import { UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_CONFIRM_CHANGES } from '@/components/organisms/Modals/ModalConductor';
import { useIntegrations } from '@/hooks/useIntegrations';
import { AccordionType, Integration } from '@/models/integration';
import { setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { pushModal } from '@/redux/modals/actions';
import { selectDeskId, selectIntegrationId } from '@/redux/session/selectors';
import { delay } from '@/util/util';

export const useAccordion = <T extends Integration = Integration>(
  canEdit = true
) => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const integrationId = useSelector(selectIntegrationId);
  const { dirty } = useSelector(selectAccordion);
  const { integration } = useIntegrations<T>(deskId, integrationId);
  const dispatch = useDispatch();

  const accordions = useRef<
    Partial<{
      [key in AccordionType]: {
        ref: HTMLDivElement;
        onSubmit: () => void;
        triggerValidation: UseFormTrigger<unknown>;
      };
    }>
  >({});

  /**
   * This registers the accordion to the hook's refs
   */
  const registerAccordion = useCallback(
    (
      type: AccordionType,
      onSubmit?: () => void,
      triggerValidation?: UseFormTrigger<unknown>
    ) =>
      (el: HTMLDivElement): void => {
        accordions.current[type] = {
          ref: el,
          onSubmit,
          triggerValidation: triggerValidation ?? (async () => true),
        };
      },
    []
  );

  /**
   * This Function is called when we click on the header of an Accordion either to open or close it.
   *
   * Since the parameters are from the clicked Accordion, if another one is already expanded and has changes we may need to call its "onSubmit" function
   * @param {number} panel Id of the accordion we clicked
   * @param {function} triggerValidation Validate the active Accordion
   * @param {boolean} isExpanded If the Accordion clicked is the expanded one
   * @param {function} onSubmit onSubmit of the Accordion clicked
   */
  const toggleAccordion = useCallback(
    (panel: AccordionType) => async (_event, isExpanded: boolean) => {
      const setExpandedAndScroll = async () => {
        dispatch(setExpanded(isExpanded ? panel : false));
        if (isExpanded) {
          await delay(300);
          accordions?.current[panel]?.ref?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      };
      const currentAccordion = accordions.current[panel];

      const deleteProps = {
        title: t('prompts.unsaved_changes.title'),
        subtitle: t('prompts.unsaved_changes.message'),
        onSave: async () => {
          currentAccordion.onSubmit();
          if (await currentAccordion.triggerValidation()) {
            setExpandedAndScroll();
          }
        },
        onDiscard: async () => {
          setExpandedAndScroll();
        },
      };

      if (!canEdit || !dirty) {
        setExpandedAndScroll();
      } else {
        dispatch(pushModal(MODAL_CONFIRM_CHANGES, deleteProps));
      }
    },
    [canEdit, dirty, dispatch, t]
  );

  useEffect(() => {
    return () => {
      dispatch(setExpanded(false));
    };
  }, [dispatch]);

  useEffect(() => {
    // used for scrolling and expanding the accordion using URL fragment identifier
    const fragmentIdentifier = window.location.hash.slice(1) as AccordionType;
    if (Object.values(AccordionType).includes(fragmentIdentifier)) {
      toggleAccordion(fragmentIdentifier)(null, true);
    }
    // ignoring the dependency array because we only want to run this code on render
    // and not when toggleAccordion function changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    toggleAccordion,
    integration,
    accordions,
    registerAccordion,
  };
};
