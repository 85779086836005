import { Icon } from '@/models/icon';

const Danger = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M8 6.7499V7.99992V6.7499ZM8 10.5H8.00672H8ZM3.34569 13H12.6543C13.6889 13 14.3352 11.9581 13.8179 11.125L9.16358 3.62485C8.64628 2.79172 7.35372 2.79172 6.83642 3.62485L2.18211 11.125C1.66482 11.9581 2.3111 13 3.34569 13Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Danger;
