import { useCallback, useEffect, useMemo } from 'react';

import cn from 'classnames';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Create from '@/components/atoms/Icons/Create';
import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  PageTriggers,
  WebWidgetIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import {
  errorMessage,
  integrationRulesWebWidget,
  welcomeTriggerMessageValidator,
} from '@/util/validator';

import RegexCard from './RegexCard/RegexCard';

import styles from './StartBehavior.module.scss';

type AdvancedOption = {
  slug: string;
  trigger_message: string;
  opening_message: string;
};

type Form = {
  options: AdvancedOption[];
  advanced_messages_checkbox: boolean;
  welcome_trigger_message: string;
  welcome_screen_greeting: string;
  starters: string[];
  start_behavior_option: string;
};
const DEFAULT_START_MESSAGE = 'defaultstartmessage';
const StartBehavior = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, WebWidgetIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const { updateIntegration, updateStatus } =
    useIntegrations<WebWidgetIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );
  const welcomeTriggerMessage = integration?.config?.welcome_trigger_message;
  const dispatch = useDispatch();

  const startBehaviorOptions = useMemo(
    () => [
      {
        id: 0,
        value: 'user_input',
        label: t('integrations.web_start.user'),
      },
      {
        id: 1,
        value: 'auto_start',
        label: t('integrations.web_start.auto'),
      },
      {
        id: 2,
        value: 'welcome_screen',
        label: t('integrations.web_start.welcome'),
      },
    ],
    [t]
  );

  const prepareTriggers = (
    options: {
      opening_message: string;
      trigger_message: string;
      slug: string;
    }[]
  ): PageTriggers => {
    if (!options || options.length === 0) {
      return;
    }
    const newPageTriggers = {};
    options.forEach(
      (option) =>
        (newPageTriggers[`${option.slug?.trim()}`] = {
          opening_message: option.opening_message?.trim(),
          trigger_message: option.trigger_message?.trim(),
        })
    );
    return newPageTriggers;
  };

  const revertTriggers = (resp) => {
    if (!resp) {
      return;
    }
    const objectKeys = Object.keys(resp);
    return objectKeys.map((key) => ({
      slug: key,
      trigger_message: resp[key].trigger_message,
      opening_message: resp[key].opening_message,
    }));
  };

  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    control,
    handleSubmit,
    trigger,
    getValues,
  } = formMethods;

  const [advancedMessages, selectedOption] = useWatch({
    control,
    name: ['advanced_messages_checkbox', 'start_behavior_option'],
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  useEffect(() => {
    reset({
      welcome_trigger_message:
        integration?.config?.welcome_trigger_message || DEFAULT_START_MESSAGE,
      welcome_screen_greeting:
        integration?.config?.welcome_screen?.greeting || '',
      starters: integration?.config?.welcome_screen?.starters || ['', '', ''],
      options: revertTriggers(integration?.config?.page_triggers) || [],
      advanced_messages_checkbox: !!integration?.config?.page_triggers,
      start_behavior_option: integration?.config?.welcome_trigger_message
        ? 'auto_start'
        : integration?.config?.welcome_screen?.enabled
          ? 'welcome_screen'
          : 'user_input',
    });
  }, [
    integration?.config?.page_triggers,
    integration?.config?.welcome_screen?.enabled,
    integration?.config?.welcome_screen?.greeting,
    integration?.config?.welcome_screen?.starters,
    integration?.config?.welcome_trigger_message,
    reset,
  ]);

  const handleUpdateIntegration = useCallback(() => {
    const {
      welcome_screen_greeting,
      starters,
      options,
      advanced_messages_checkbox,
      start_behavior_option,
    } = getValues();
    const newOptions = prepareTriggers(options);
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration.config,
          welcome_trigger_message:
            start_behavior_option === 'auto_start'
              ? DEFAULT_START_MESSAGE
              : undefined,
          page_triggers:
            advanced_messages_checkbox && start_behavior_option === 'auto_start'
              ? newOptions
              : undefined,
          welcome_screen:
            start_behavior_option === 'welcome_screen'
              ? {
                  enabled: start_behavior_option === 'welcome_screen',
                  greeting: welcome_screen_greeting,
                  starters: starters?.filter((x) => !!x),
                }
              : undefined,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, getValues, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  const handleAddOption = useCallback(() => {
    if (canWrite) {
      append({
        slug: '',
        trigger_message: '',
        opening_message: '',
      });
    }
  }, [append, canWrite]);

  const handleDelete = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [dispatch, expanded, isDirty, reset, type]);

  return (
    <>
      <Accordion
        title={t('integrations.web_start.title')}
        subtitle={t('integrations.web_start.subtitle')}
        footerText={t('integrations.web_start.footer')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
      >
        <>
          <TitleSubtitle
            title={t('integrations.web_start.title2')}
            subtitle={t('integrations.web_start.subtitle2')}
          />
          <div
            className={cn(styles.radioWrapper, {
              [styles.disabled]: !canWrite,
            })}
          >
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    {startBehaviorOptions.map((option) => {
                      return (
                        <OptionRadioButton
                          option={option}
                          key={option.id}
                          isSelected={option.value === value}
                          onChange={onChange}
                        />
                      );
                    })}
                  </>
                );
              }}
              name="start_behavior_option"
              control={control}
            />
          </div>
          {selectedOption === 'user_input' && (
            <TitleSubtitle subtitle={t('integrations.web_start.user_note')} />
          )}

          {selectedOption === 'auto_start' && (
            <>
              <ContextualHelp
                title={t('integrations.web_start.help_title')}
                name={t('integrations.web_start.help_name')}
              >
                {t('integrations.web_start.help_body')}
              </ContextualHelp>

              <div className={styles.textArea}>
                <Input
                  id="welcome_trigger_message"
                  name="welcome_trigger_message"
                  label={t('integrations.web_start.trigger')}
                  placeholder={t('integrations.web_start.trigger_placeholder')}
                  size="large"
                  register={register('welcome_trigger_message', {
                    required:
                      selectedOption === 'auto_start'
                        ? t('common.required_field')
                        : undefined,
                    maxLength: {
                      value: 64,
                      message: '',
                    },
                  })}
                  trimValue
                  errorMessage={errorMessage({
                    field: errors.welcome_trigger_message,
                    maxLength: welcomeTriggerMessageValidator.maxLength,
                  })}
                  // ensure backward compatibility for users
                  // that already have a default start message
                  readOnly={
                    !canWrite || welcomeTriggerMessage === DEFAULT_START_MESSAGE
                  }
                />
                {welcomeTriggerMessage === DEFAULT_START_MESSAGE && (
                  <CopyButton
                    className={styles.copyCodeSnippet}
                    data={DEFAULT_START_MESSAGE}
                  />
                )}
              </div>

              <div className={styles.advanced}>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Checkbox
                        label={
                          <TitleSubtitle
                            title={t('integrations.web_start.advance_title2')}
                            subtitle={t(
                              'integrations.web_start.welcome_placeholder'
                            )}
                            noGutters
                          />
                        }
                        onChange={onChange}
                        checked={value}
                        sameLevel
                        disabled={!canWrite}
                      />
                    );
                  }}
                  name="advanced_messages_checkbox"
                  control={control}
                />
              </div>

              {advancedMessages && (
                <>
                  <div
                    className={styles.interactive}
                    role="button"
                    onClick={handleAddOption}
                    onKeyDown={() => {}}
                    tabIndex={0}
                  >
                    <span>
                      <Create color="var(--icon-default-blue)" />
                    </span>
                    {t('integrations.web_start.add_msg')}
                  </div>
                  <div>
                    {fields.map((option, index) => (
                      <RegexCard
                        option={option}
                        key={option.id}
                        index={index}
                        errors={errors}
                        register={register}
                        readOnly={!canWrite}
                        onDelete={() => handleDelete(index)}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          )}

          {selectedOption === 'welcome_screen' && (
            <>
              <div className={styles.textArea}>
                <TextAreaAsInput
                  id="greeting"
                  name="greeting"
                  label="headline*"
                  placeholder={t('integrations.web_start.gretting_placeholder')}
                  size="large"
                  register={register('welcome_screen_greeting', {
                    required:
                      selectedOption === 'welcome_screen'
                        ? t('common.required_field')
                        : undefined,
                    maxLength: { value: 64, message: '' },
                  })}
                  height="medium"
                  trimValue
                  readOnly={!canWrite}
                  errorMessage={errorMessage({
                    field: errors.welcome_screen_greeting,
                    maxLength:
                      integrationRulesWebWidget.welcomeTriggerMessage.maxLength,
                  })}
                />
              </div>

              <div className={styles.requiredField}>
                {t('integrations.web_start.gretting_help')}
              </div>

              <TitleSubtitle
                title={t('integrations.web_start.starters.title')}
                subtitle={t('integrations.web_start.starters.subtitle')}
              />
              <div className={styles.starter}>
                <Input
                  label={t('integrations.web_start.starters.first')}
                  trimValue
                  name={`starters.0`}
                  readOnly={!canWrite}
                  placeholder={t(
                    'integrations.web_start.starters.type_question'
                  )}
                  register={register('starters.0', {
                    maxLength: {
                      value: 64,
                      message: t('integrations.web_start.starters.max_chars', {
                        0: 64,
                      }),
                    },
                  })}
                  size="large"
                  errorMessage={
                    errors?.starters &&
                    (errors?.starters[0] as FieldError)?.message
                  }
                />
              </div>
              <div className={styles.starter}>
                <Input
                  label={t('integrations.web_start.starters.second')}
                  trimValue
                  readOnly={!canWrite}
                  name={`starters.1`}
                  placeholder={t(
                    'integrations.web_start.starters.type_question'
                  )}
                  register={register('starters.1', {
                    maxLength: {
                      value: 64,
                      message: t('integrations.web_start.starters.max_chars', {
                        0: 64,
                      }),
                    },
                  })}
                  size="large"
                  errorMessage={
                    errors?.starters &&
                    (errors?.starters[1] as FieldError)?.message
                  }
                />
              </div>
              <div className={styles.starter}>
                <Input
                  label={t('integrations.web_start.starters.third')}
                  trimValue
                  readOnly={!canWrite}
                  name={`starters.2`}
                  placeholder={t(
                    'integrations.web_start.starters.type_question'
                  )}
                  register={register('starters.2', {
                    maxLength: {
                      value: 64,
                      message: t('integrations.web_start.starters.max_chars', {
                        0: 64,
                      }),
                    },
                  })}
                  size="large"
                  errorMessage={
                    errors?.starters &&
                    (errors?.starters[2] as FieldError)?.message
                  }
                />
              </div>
            </>
          )}
        </>
      </Accordion>
    </>
  );
};

export default StartBehavior;
