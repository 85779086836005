import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import IntegrationsHeader from '@/components/organisms/Headers/IntegrationsHeader';
import {
  getIntegrationByType,
  integrationByType,
} from '@/components/organisms/Integrations';
import { TileIntegration } from '@/components/organisms/Tile';
import TileList from '@/components/organisms/Tile/TileList';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useDesks from '@/hooks/useDesks';
import { useIntegrations } from '@/hooks/useIntegrations';
import { Integration, IntegrationType } from '@/models/integration';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { pageView } from '@/segment/segment';
import { CHANNELS_SUPPORTED } from '@/util/constants';

const typeFilter = (type: string) => (integration: Integration) =>
  integration.type === type;

function IntegrationsPage() {
  const { t } = useTranslation();
  const { deskId } = useParams();
  const { desk } = useDesks(deskId);
  const { integrations } = useIntegrations(deskId);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  useTitle(t('pages.integrations', { 0: desk?.name }));

  useEffect(() => {
    pageView(PageName.INTEGRATIONS);
  }, []);

  return (
    <>
      <IntegrationsHeader />
      <PageContentWrapper newPlain readOnly={!canWrite}>
        <ContextualHelp
          title={t('integrations.contextual_help.title')}
          name="about_integrations"
        >
          {t('integrations.contextual_help.message')}
        </ContextualHelp>

        <TileList>
          {Object.keys(integrationByType)
            .filter((it) => CHANNELS_SUPPORTED.includes(it))
            .map((type: IntegrationType) => {
              const config = getIntegrationByType(type);
              return (
                <TileIntegration
                  key={type}
                  integration={integrations?.find(typeFilter(type))}
                  name={t(`channels.${type}`)}
                  Icon={config.Icon}
                  description={t(`channels_description.${type}`)}
                  disabled={!config.ConfigurationComponent}
                  skeleton={!integrations}
                  type={type}
                />
              );
            })}
        </TileList>
      </PageContentWrapper>
    </>
  );
}

export default IntegrationsPage;
