import { memo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Create from '@/components/atoms/Icons/Create';
import Sales from '@/components/atoms/Icons/Sales';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';

import styles from './TileCreation.module.scss';

type SimpleTile = {
  title?: string;
  onCreateClick: () => void;
  size?: 'small' | 'medium';
  subtitle?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
};

type TileLimitReached = {
  limitReached?: boolean;
};

type Props = SimpleTile & TileLimitReached;

function TileCreation({
  title,
  onCreateClick,
  size,
  limitReached,
  subtitle,
  icon = <Create color="var(--icon-default-blue)" />,
  className,
}: Props) {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const navigate = useNavigate();
  const canReadBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.READ)
  );

  const handleSalesClick = () => {
    if (!canReadBilling) {
      return;
    }
    navigate(`/${slug}/account/billing`, {
      state: { shouldTriggerAction: Date.now() },
    });
  };

  if (limitReached) {
    return (
      <div
        className={cn(
          styles.tile,
          styles[size],
          {
            [styles.isDisabled]: !canReadBilling,
          },
          className
        )}
        data-testid="tile-creation"
      >
        <button type="button" onClick={handleSalesClick}>
          {canReadBilling && (
            <Typography
              variant="body-semi-bold"
              className={styles.title}
              color="var(--text-default-blue)"
            >
              <Sales color="var(--icon-default-blue)" />
              {t('billing.upgrade')}
            </Typography>
          )}
          {subtitle && (
            <Typography
              className={styles.subtitle}
              color="var(--text-default-gray)"
            >
              {subtitle}
            </Typography>
          )}
        </button>
      </div>
    );
  }

  return (
    <div
      className={cn(styles.tile, styles[size], className)}
      data-testid="tile-creation"
    >
      <button type="button" onClick={onCreateClick}>
        <Typography
          variant="body-semi-bold"
          className={styles.title}
          color="var(--text-default-blue)"
        >
          {icon}
          {title || t('common.create')}
        </Typography>
        {subtitle && (
          <Typography
            className={styles.subtitle}
            color="var(--text-default-gray)"
          >
            {subtitle}
          </Typography>
        )}
      </button>
    </div>
  );
}

export default memo(TileCreation);
