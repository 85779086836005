import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Drag = ({ size = 20, color = '#8893A8' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.draggable}
      viewBox="0 0 16 16"
    >
      <path
        d="M9.333 14.667c0 .916-.75 1.666-1.666 1.666-.917 0-1.667-.75-1.667-1.666C6 13.75 6.75 13 7.667 13c.916 0 1.666.75 1.666 1.667ZM7.667 8C6.75 8 6 8.75 6 9.667c0 .916.75 1.666 1.667 1.666.916 0 1.666-.75 1.666-1.666C9.333 8.75 8.583 8 7.667 8Zm0-5C6.75 3 6 3.75 6 4.667c0 .916.75 1.666 1.667 1.666.916 0 1.666-.75 1.666-1.666C9.333 3.75 8.583 3 7.667 3Zm5 3.333c.916 0 1.666-.75 1.666-1.666 0-.917-.75-1.667-1.666-1.667C11.75 3 11 3.75 11 4.667c0 .916.75 1.666 1.667 1.666Zm0 1.667C11.75 8 11 8.75 11 9.667c0 .916.75 1.666 1.667 1.666.916 0 1.666-.75 1.666-1.666 0-.917-.75-1.667-1.666-1.667Zm0 5C11.75 13 11 13.75 11 14.667c0 .916.75 1.666 1.667 1.666.916 0 1.666-.75 1.666-1.666 0-.917-.75-1.667-1.666-1.667Z"
        fill={color}
        opacity=".8"
      />
    </svg>
  );
};
export default Drag;
