import ClickAwayListener from '@mui/material/ClickAwayListener';
import Picker, { EmojiStyle } from 'emoji-picker-react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import './EmojiPicker.scss';

enum Categories {
  SUGGESTED = 'suggested',
  SMILEYS_PEOPLE = 'smileys_people',
  ANIMALS_NATURE = 'animals_nature',
  FOOD_DRINK = 'food_drink',
  TRAVEL_PLACES = 'travel_places',
  ACTIVITIES = 'activities',
  OBJECTS = 'objects',
  SYMBOLS = 'symbols',
  FLAGS = 'flags',
}

export const categories = (t: TFunction) => [
  {
    category: Categories.SUGGESTED,
    name: t('emojiPicker.suggested'),
  },
  {
    category: Categories.SMILEYS_PEOPLE,
    name: t('emojiPicker.smileys_people'),
  },
  {
    category: Categories.ANIMALS_NATURE,
    name: t('emojiPicker.animals_nature'),
  },
  {
    category: Categories.FOOD_DRINK,
    name: t('emojiPicker.food_drink'),
  },
  {
    category: Categories.TRAVEL_PLACES,
    name: t('emojiPicker.travel_places'),
  },
  {
    category: Categories.ACTIVITIES,
    name: t('emojiPicker.activities'),
  },
  {
    category: Categories.OBJECTS,
    name: t('emojiPicker.objects'),
  },
  {
    category: Categories.SYMBOLS,
    name: t('emojiPicker.symbols'),
  },
  {
    category: Categories.FLAGS,
    name: t('emojiPicker.flags'),
  },
];

export const EmojiPicker = ({
  onEmojiClick,
  onClickAway,
  width,
  height,
  className = '',
}) => {
  const { t } = useTranslation();

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={`emoji_picker_wrapper ${className}`}>
        <Picker
          onEmojiClick={onEmojiClick}
          skinTonesDisabled
          emojiStyle={EmojiStyle.NATIVE}
          width={width}
          height={height}
          previewConfig={{ showPreview: false }}
          searchPlaceHolder={t('common.search')}
          categories={categories(t)}
        />
      </div>
    </ClickAwayListener>
  );
};
