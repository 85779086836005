import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew';
import { Banner } from '@/components/atoms/Banner/Banner';
import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import Select from '@/components/atoms/Select/Select';
import { renderDialogIcon } from '@/components/organisms/SubNav/TreeView/Item';
import useDialogs from '@/hooks/useDialogs';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import { Action } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectNodeByActionId,
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';
import { webviewActionSchema } from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitActionWebview.module.scss';

type Form = {
  url: string;
  name: string;
  label: string;
  trigger_node_id: string;
  height: string;
};

function ToolkitActionWebview() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dialogId } = useParams();

  const heightOptions = useMemo(
    () => [
      { value: 'tall', label: t('dialog.webview.tall') },
      { value: 'compact', label: t('dialog.webview.compact') },
      { value: 'full', label: t('dialog.webview.full') },
    ],
    [t]
  );

  const { actionId, brainId, node_id, ...webview } = useSelector(
    (state: RootState) => {
      const selectedAction = selectSelectedAction(state) as Extract<
        Action,
        { type: 'webview' }
      >;
      const { action_id, ...rest } = selectedAction;
      return {
        actionId: action_id,
        brainId: selectBrainId(state),
        node_id: selectNodeByActionId(state as RootState, action_id).node_id,
        ...rest,
      };
    },
    shallowEqual
  );
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));
  const { detailedOptions } = useDialogs(brainId, dialogId, node_id);

  const { eventsOptions } = useDialogs(brainId);
  const nodeOptions = eventsOptions;
  const defaultTriggerNodeId = nodeOptions.find(
    (option) => option.value === webview.trigger_node_id
  );

  const defaultValues = {
    url: webview.url,
    name: webview.name,
    label: webview.label,
    trigger_node_id: defaultTriggerNodeId?.label ?? null,
    height: webview?.height,
  };

  const {
    formState: { errors, dirtyFields },
    register,
    trigger,
    control,
    setFocus,
    getValues,
    setValue,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(webviewActionSchema) as Resolver<Form>,
  });
  useFocusOnInput('url', errors, setFocus);

  const urlErrorMessage = errors.url?.message;
  const nameErrorMessage = errors.name?.message;
  const triggerNodeIdErrorMessage = errors.trigger_node_id?.message;
  const labelErrorMessage = errors.label?.message;

  const handleAutoCompleteChange = useCallback(
    (_, option) => {
      dispatch(
        updateAction({
          actionId,
          action: {
            trigger_node_id: option?.value ?? undefined,
          },
        })
      );
    },
    [dispatch, actionId]
  );

  const handleInputChange = useCallback(
    (key: string) =>
      (
        e: ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      ) => {
        const value = e.target.value;

        dispatch(
          updateAction({
            actionId,
            action: {
              [key]: value,
            },
          })
        );
      },
    [actionId, dispatch]
  );

  const handleAutoCompleteBlur = useCallback(() => {
    const triggerNodeId = getValues('trigger_node_id');
    if (typeof triggerNodeId === 'string' && dirtyFields.trigger_node_id) {
      setValue('trigger_node_id', '');
    }
  }, [dirtyFields.trigger_node_id, getValues, setValue]);

  const updateErrors = useCallback(
    (key: string) => (value: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.webview'),
            body: capitalizeFirstLetter(value),
            type: webview.type,
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, parentNodeId, t, webview.type]
  );

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors('url')(urlErrorMessage);
  }, [urlErrorMessage, updateErrors]);
  useEffect(() => {
    updateErrors('name')(nameErrorMessage);
  }, [nameErrorMessage, updateErrors]);
  useEffect(() => {
    updateErrors('trigger_node_id')(triggerNodeIdErrorMessage);
  }, [updateErrors, triggerNodeIdErrorMessage]);
  useEffect(() => {
    updateErrors('label')(labelErrorMessage);
  }, [updateErrors, labelErrorMessage]);

  return (
    <ToolkitWrapper type="webview">
      <div className={styles.inputWrapper}>
        <TextAreaAsInput
          name="url"
          label={t('dialog.webview.webview_url')}
          error={!!errors.url}
          errorMessage={capitalizeFirstLetter(errors.url?.message)}
          placeholder={t('dialog.url_placeholder')}
          onChange={handleInputChange('url')}
          key={actionId}
          register={register('url')}
          height="medium"
        />
      </div>
      <Input
        size="small"
        name="name"
        label={t('common.title')}
        placeholder={t('dialog.webview.type_name')}
        required
        error={!!errors.name}
        errorMessage={capitalizeFirstLetter(errors.name?.message)}
        onChange={handleInputChange('name')}
        key={actionId}
        register={register('name')}
      />
      <Input
        size="small"
        name="label"
        label={t('dialog.webview.label')}
        placeholder={t('dialog.webview.label_placeholder')}
        required
        onChange={handleInputChange('label')}
        error={!!errors.label}
        errorMessage={capitalizeFirstLetter(errors.label?.message)}
        register={register('label')}
      />
      <div className={styles.inputWrapper}>
        <AutocompleteNew
          options={detailedOptions}
          control={control}
          name="trigger_node_id"
          freeSolo
          renderIconInFront={renderDialogIcon}
          label={t('dialog.trigger_node')}
          placeholder={t('dialog.jump_to.placeholder')}
          hasError={!!errors.trigger_node_id}
          errorMessage={capitalizeFirstLetter(errors.trigger_node_id?.message)}
          onChange={handleAutoCompleteChange}
          checkDisabledAgainst={node_id}
          enableNewEntry={false}
          groupByProp="dialog_name"
          groupByLabelProp={false}
          onBlur={handleAutoCompleteBlur}
        />
      </div>
      <Select
        size="small-full"
        error={!!errors.height}
        errorMessage={capitalizeFirstLetter(errors.height?.message)}
        label={t('dialog.webview.height')}
        name="height"
        onChange={handleInputChange('height')}
        options={heightOptions}
        register={register('height')}
      />
      <Banner
        variant="neutral"
        title={t('dialog.webview.banner.title')}
        relativePosition
      >
        {t('dialog.webview.banner.text')}
      </Banner>
    </ToolkitWrapper>
  );
}

export default ToolkitActionWebview;
