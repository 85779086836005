import Button from '@mui/material/Button';
import cn from 'classnames';

import Notifications from '@/components/atoms/Icons/Navigation/Notifications';

import styles from './Changelog.module.scss';
import './beamer.scss';

export const Changelog = () => {
  return (
    <Button className={cn(styles.container, 'beamer')} disableRipple>
      <Notifications size={21} />
    </Button>
  );
};
