import { useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnyObjectSchema } from 'yup';

import Input from '@/components/atoms/Input/Input';
import { submitWithTrimming, isPromise } from '@/util/util';
import { errorMessage } from '@/util/validator';

import Modal from './Modal';

import styles from './ModalEdit.module.scss';

type Props = {
  onEdit: (data: Record<string, string | number>) => void;
  fieldName: string;
  fieldValue?: string | number;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  multiline?: boolean;
  validationSchema?: AnyObjectSchema;
  resource?: string;
};

export default function ModalEdit({
  onEdit,
  fieldName,
  fieldValue = '',
  rules = {},
  validationSchema,
  resource,
}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      [fieldName]: fieldValue,
    },
    resolver: validationSchema && yupResolver(validationSchema),
  });
  const {
    formState: { errors },
    register,
  } = formMethods;

  const onSubmit = useCallback(
    async (data: Record<string, string | number>) => {
      try {
        setIsLoading(true);
        if (isPromise(onEdit)) {
          await onEdit(data);
        } else {
          onEdit(data);
        }
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    },
    [onEdit]
  );

  return (
    <Modal
      aria-labelledby={t('modals.edit', { 0: resource })}
      title={t('modals.edit', { 0: resource })}
      primaryButtonText={t('common.save')}
      onPrimarySubmit={submitWithTrimming(formMethods, onSubmit)}
      isSubmitting={isLoading}
      buttonType="submit"
      subtitle={''}
      size={'small'}
      className={''}
      primaryButtonDisable={false}
      passiveModal={false}
      autoFocus={false}
      danger={false}
      hasScrollingContent={false}
      header={false}
    >
      <div className={cn('input__container', styles.ModalEdit)}>
        {/* FIXME: translate the fieldName using it as key */}
        <Input
          name={fieldName}
          label={fieldName.replace('_', ' ')}
          register={register(fieldName, rules)}
          errorMessage={errorMessage({
            field: errors[fieldName],
            ...rules,
          })}
          placeholder={fieldValue.toString()}
          autoFocus
        />
      </div>
    </Modal>
  );
}
