import { useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import uniq from 'lodash/uniq';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Node, Node as SlateNode } from 'slate';
import { AnyObjectSchema } from 'yup';

import AutoCompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import { Banner } from '@/components/atoms/Banner/Banner';
import Chips from '@/components/atoms/Chips/Chips';
import Input from '@/components/atoms/Input/Input';
import CommandPalette from '@/components/organisms/CommandPalette/CommandPalette';
import { useMarkdownToSlate } from '@/components/organisms/RichTextEditor/hooks/useMarkdownToSlate';
import OptionsPopper, {
  OptionsPopperProps,
} from '@/components/organisms/RichTextEditor/OptionsPopper/OptionsPopper';
import RichTextEditor from '@/components/organisms/RichTextEditor/RichTextEditor';
import {
  addStringToEditor,
  richTextToMarkdown,
  findRichTextEditorErrorMessage,
  NestedObject,
} from '@/components/organisms/RichTextEditor/utils';
import { useCommandPallete } from '@/hooks/useCommandPallete';
import useDialogs from '@/hooks/useDialogs';
import { useRichTextEditor } from '@/hooks/useRichTextEditor';
import { Action } from '@/models/action';
import { OptionBase } from '@/models/common';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  getVariableLabel,
  REVERSE_VARIABLE_LABELS,
} from '@/redux/dialogs/helper';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { MAX_EMAIL_RECIPIENTS } from '@/util/constants';
import {
  capitalizeFirstLetter,
  wrapDollarSignWithCurlyBraces,
} from '@/util/util';
import {
  emailToolkitRules,
  emailToolkitSchema,
  LENGTH_1024,
  recipientSchema,
} from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

type SuggestedRecipient = OptionBase<{ type?: string }>;

interface ActionEmailForm {
  from: string;
  recipient: string | SuggestedRecipient;
  to: string[];
  subject: string;
  body: Partial<SlateNode>[] | string;
}

const ToolkitEmail = () => {
  const { t } = useTranslation();
  const { editor, triggerKey } = useRichTextEditor();

  const dispatch = useDispatch();
  const { type, actionId, to, body, subject, from } = useSelector(
    (state: RootState) => {
      const selectedAction = selectSelectedAction(state) as Extract<
        Action,
        { type: 'email' }
      >;
      const { action_id, type, to, body, subject, from } = selectedAction;
      return {
        type,
        actionId: action_id,
        to: to || [],
        body: body || '',
        subject: subject || '',
        from: from,
      };
    },
    shallowEqual
  );
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  const bodyToSlate = useMarkdownToSlate(body);
  const brainId = useSelector(selectBrainId);
  const { getContextVariables } = useDialogs(brainId);
  const options = useMemo(() => getContextVariables(), [getContextVariables]);

  const {
    searchResults,
    setSearchResults,
    searchString,
    setSearchString,
    noResultSearchLength,
    setNoResultSearchLength,
    lastSearchStringLength,
    setLastSearchStringLength,
  } = useCommandPallete({ options });

  const defaultTo = to.map((item) => item?.replace(/{{|}}/g, ''));

  const customResolver: (
    yupSchema: AnyObjectSchema
  ) => Resolver<ActionEmailForm> =
    (yupSchema) => async (values, context, options) => {
      const v = { ...values };
      if (v?.body) {
        v.body = richTextToMarkdown(values.body as Node[]);
      }

      const resolve = yupResolver(yupSchema);
      return resolve(v, context, options);
    };

  const {
    formState: { errors },
    getValues,
    setValue,
    register,
    trigger,
    control,
    clearErrors,
  } = useForm<ActionEmailForm>({
    mode: 'onChange',
    defaultValues: {
      from: from || '',
      recipient: null,
      to: defaultTo,
      subject,
    },
    resolver: customResolver(emailToolkitSchema) as Resolver<ActionEmailForm>,
  });

  // Dialog error messages
  const subjectErrorMessage = capitalizeFirstLetter(errors.subject?.message);
  const toErrorMessage = capitalizeFirstLetter(errors.to?.message);

  useEffect(() => {
    if (bodyToSlate) {
      setValue('body', bodyToSlate);
      trigger('body');
    }
  }, [bodyToSlate, setValue, trigger]);

  const handleOnRecipientDelete = useCallback(
    (rec) => {
      const newRecipients = to.filter(
        (recipient) => recipient !== wrapDollarSignWithCurlyBraces(rec)
      );

      dispatch(
        updateAction({
          actionId,
          action: {
            to: newRecipients,
          },
        })
      );
    },
    [actionId, dispatch, to]
  );

  const handleAddStringToEditor = useCallback(
    (value) => {
      addStringToEditor(
        editor,
        `{{${searchResults[value].value}}}`,
        triggerKey + searchString
      );
    },
    [editor, searchResults, searchString, triggerKey]
  );

  const updateErrors = useCallback(
    (key: string) => (value: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.email'),
            body: capitalizeFirstLetter(value),
            type,
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, parentNodeId, t, type]
  );

  const handleChange = useCallback(
    (key: string, value: string | string[]) => {
      // Prevent dispatching the same value
      if (key === 'body' && body === value) {
        return;
      }

      const updatedAction = {
        type,
        to,
        body,
        from,
        subject,
        [key]: value,
      };

      dispatch(
        updateAction({
          actionId,
          action: updatedAction,
        })
      );
    },
    [actionId, body, dispatch, from, subject, to, type]
  );

  const handleAutocompleteChange = useCallback(
    (_, option, reason) => {
      // When the user selects to create a new option from the autocomplete
      if (reason === 'selectOption' && option?.type === 'unlisted_entry') {
        const isNewEntryValid = recipientSchema.isValidSync(option.value);

        if (!isNewEntryValid) {
          setValue('recipient', undefined);

          return;
        }
      }

      if (option) {
        // Clear the input field
        setValue('recipient', {
          type: '',
          value: '',
          label: '',
        });

        const newRecipients = uniq([
          ...to,
          wrapDollarSignWithCurlyBraces(option.value),
        ]) as string[];

        setValue('to', uniq([...defaultTo, option.value]), {
          shouldValidate: true,
        });

        handleChange('to', newRecipients);
      }
    },
    [defaultTo, handleChange, setValue, to]
  );

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors('subject')(subjectErrorMessage);
  }, [subjectErrorMessage, updateErrors]);

  useEffect(() => {
    updateErrors('body')(
      findRichTextEditorErrorMessage(errors.body as NestedObject)
    );
  }, [errors.body, updateErrors]);

  useEffect(() => {
    updateErrors('to')(toErrorMessage);
  }, [toErrorMessage, updateErrors]);

  return (
    <ToolkitWrapper type={type}>
      <Input
        name="from"
        label={t('dialog.email.from')}
        placeholder={t('dialog.email.from_address')}
        readOnly
        value={t('dialog.email.from_address')}
        tooltip={t('dialog.email.from_tooltip')}
        error={!!errors.from}
        register={register('from', emailToolkitRules.from)}
        size="small"
      />

      <Box mb="var(--space-24)">
        <AutoCompleteNew
          name="recipient"
          control={control}
          tooltip={t('dialog.email.to_tooltip')}
          options={options}
          freeSolo
          enableNewEntry
          groupByProp="type"
          label={t('dialog.email.recipient')}
          placeholder={t('dialog.email.to_placeholder')}
          size="xs"
          hasError={!!errors.recipient}
          errorMessage={errors.recipient?.message}
          disabled={to?.length === MAX_EMAIL_RECIPIENTS}
          onChange={handleAutocompleteChange}
          onInputChange={() => {
            clearErrors('recipient');
            if (getValues().recipient === undefined) {
              setValue('recipient', null);
            }
          }}
          getOptionLabel={(option) => option?.label ?? option}
        />

        <Controller
          name="to"
          control={control}
          render={({ field }) => {
            return (
              <Chips
                chips={field.value.map((item) => getVariableLabel(item))}
                onClick={(recipient) => {
                  const rec = REVERSE_VARIABLE_LABELS[recipient] || recipient;

                  handleOnRecipientDelete(rec);
                  field.onChange(field.value.filter((r) => r !== rec));
                }}
              />
            );
          }}
        />
      </Box>

      {to?.length === 0 && (
        <Box mb="var(--space-24)">
          <Banner variant="critical" relativePosition>
            {t('dialog.errors.at_least_one', {
              0: t('dialog.email.recipient'),
            })}
          </Banner>
        </Box>
      )}

      <Input
        name="subject"
        placeholder={t('dialog.email_subject')}
        label={t('dialog.email.subject')}
        tooltip={t('dialog.email.subject_tooltip')}
        error={!!errors.subject}
        errorMessage={subjectErrorMessage}
        register={register('subject')}
        size="small"
        onChange={(e) => {
          handleChange('subject', e.target.value);
        }}
      />

      <Controller
        name="body"
        control={control}
        render={({ field }) => {
          return (
            field.value && (
              <CommandPalette<OptionsPopperProps>
                triggerKey={triggerKey}
                searchString={searchString}
                setSearchString={setSearchString}
                renderPalette={(props) => {
                  return (
                    <OptionsPopper
                      {...props}
                      options={options}
                      searchResults={searchResults}
                      setSearchResults={setSearchResults}
                      noResultSearchLength={noResultSearchLength}
                      setNoResultSearchLength={setNoResultSearchLength}
                      lastSearchStringLength={lastSearchStringLength}
                      setLastSearchStringLength={setLastSearchStringLength}
                    />
                  );
                }}
                optionsLength={searchResults.length}
                handleOnSelectFromProps={(value) =>
                  handleAddStringToEditor(value)
                }
                renderItem={(props) => (
                  <RichTextEditor
                    {...props}
                    editor={editor}
                    label={t('dialog.email.body')}
                    value={field.value as Node[]}
                    onChange={(value) => {
                      field.onChange(value);
                      handleChange('body', richTextToMarkdown(value));
                    }}
                    size="medium"
                    maxCharacters={2 * LENGTH_1024}
                    options={options}
                    error={
                      findRichTextEditorErrorMessage(
                        errors.body as NestedObject
                      )
                        ? {
                            message: findRichTextEditorErrorMessage(
                              errors.body as NestedObject
                            ),
                          }
                        : undefined
                    }
                    placeholder={t('dialog.email.body_placeholder')}
                  />
                )}
              />
            )
          );
        }}
      />
    </ToolkitWrapper>
  );
};

export default ToolkitEmail;
