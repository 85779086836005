import { TFunction } from 'i18next';

import { callGet } from '@/api/fetcher';

import { FACEBOOK_API_VERSION, WHATSAPP_OAUTH_CONFIG_ID } from './constants';

const FACEBOOK_ORIGIN = 'https://www.facebook.com';
const WA_EMBEDDED_SIGNUP_TYPE = 'WA_EMBEDDED_SIGNUP';
const FINISH_EVENT = 'FINISH';
const SDK_VERSION = FACEBOOK_API_VERSION;

type LoginStatusResponse = {
  authResponse: {
    code?: string;
  };
  status: string;
};

// https://developers.facebook.com/docs/whatsapp/embedded-signup/embed-the-flow#step-7--optional---session-logging
export const createSessionInfoListener =
  (
    setPhoneNumberId: (phone_number_id: string) => void,
    setWabaId: (waba_id: string) => void
  ) =>
  (event: { origin: string; data: string }) => {
    if (event.origin !== FACEBOOK_ORIGIN) return;
    try {
      const data = JSON.parse(event.data);
      if (
        data.type === WA_EMBEDDED_SIGNUP_TYPE &&
        data.event === FINISH_EVENT
      ) {
        // if the user finishes the Embedded Signup flow
        const { phone_number_id, waba_id } = data.data;
        setPhoneNumberId(phone_number_id);
        setWabaId(waba_id);
      }
    } catch (error) {
      // Don’t parse info that’s not JSON
      console.warn('Facebook event did not return JSON data', error);
    }
  };

// https://developers.facebook.com/docs/whatsapp/embedded-signup/embed-the-flow#step-3--set-up-facebook-login
const initializeFacebookLogin = () => {
  return new Promise<LoginStatusResponse>((resolve) => {
    window.FB.getLoginStatus((getStatusResponse) => {
      // If the user has completed the flow before we have to perform a logout
      if (
        getStatusResponse?.status === 'connected' &&
        window.FB.getAccessToken()
      ) {
        window.FB.logout();
      }
      // Login opens the oAuth flow pop-up window
      window.FB.login(
        (loginResponse) => {
          resolve(loginResponse);
        },
        {
          config_id: WHATSAPP_OAUTH_CONFIG_ID,
          response_type: 'code',
          override_default_response_type: true,
          extras: {
            sessionInfoVersion: 2,
          },
        }
      );
    });
  });
};

const handleLoginResponse = (
  loginResponse: LoginStatusResponse | undefined,
  setOauthCode: (code: string) => void,
  setOauthError: (error: string) => void,
  t: TFunction
) => {
  if (loginResponse?.authResponse?.code) {
    // Successfully returned the code
    setOauthCode(loginResponse.authResponse.code);
  } else {
    setOauthError(t('integrations.facebook.errors.process_interrupted'));
  }
};

export const performWhatsappOAuth = async (
  setPhoneNumberId: (phone_number_id: string) => void,
  setWabaId: (waba_id: string) => void,
  setOauthCode: (code: string) => void,
  setOauthError: (error: string) => void,
  t: TFunction
) => {
  const sessionInfoListener = createSessionInfoListener(
    setPhoneNumberId,
    setWabaId
  );

  window.addEventListener('message', sessionInfoListener);

  try {
    const response = await initializeFacebookLogin();

    handleLoginResponse(response, setOauthCode, setOauthError, t);
  } catch (error) {
    // Error during WhatsApp oAuth
    console.warn('Error when performing WhatsApp oAuth', error);
    setOauthError(
      error.message ||
        t('integrations.facebook.errors.no_code', { type: 'WhatsApp' })
    );
  } finally {
    // Remove the event listener when the OAuth process is complete
    window.removeEventListener('message', sessionInfoListener);
  }
};

// https://developers.facebook.com/docs/whatsapp/embedded-signup/embed-the-flow#step-1--load-the-facebook-javascript-sdk
export const initializeFacebookSdk = async () => {
  const appId = await callGet('/www/api/integrations/facebook/app-id');

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: appId,
      cookie: true,
      xfbml: true,
      version: SDK_VERSION,
    });
  };

  const scriptId = 'facebook-jssdk';
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = 'https://connect.facebook.net/en_US/sdk.js';

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode?.insertBefore(script, firstScript);
  }
};
