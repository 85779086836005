import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CheckboxFieldset from '@/components/atoms/Fieldset/templates/CheckboxFieldset';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import { pushModal } from '@/redux/modals/actions';

const AccountDelete = () => {
  const { t } = useTranslation();
  const { account, slug, deleteAccount, deleteStatus } = useAccount();
  const dispatch = useDispatch();

  const handleDeleteClick = () => {
    const deleteProps = {
      subtitle: (
        <Trans i18nKey="accounts.delete_subtitle" values={[account?.name]} />
      ),
      title: t('accounts.delete'),
      confirm: true,
      onDelete: () => {
        deleteAccount(null);
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  };

  return (
    <CheckboxFieldset
      key={slug}
      title={t('accounts.delete')}
      subtitle={t('accounts.delete_queue_data')}
      label={t('accounts.delete_confirm')}
      onClick={handleDeleteClick}
      isLoading={deleteStatus === 'pending'}
    />
  );
};

export default AccountDelete;
