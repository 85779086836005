import React from 'react';

const HindujaSVG: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    version="1.2"
    viewBox="0 0 1577 1582"
  >
    <style>{`.s0{fill:#00b0f0}`}</style>
    <g id="Layer_2">
      <g id="DK_grey">
        <g id="Layer_2-2">
          <g id="Layer_1-2">
            <path
              id="Layer"
              d="M755.1 259 24.9 820.3c-5.5 4.2-10.1 9.4-13.5 15.3-3.4 6-5.6 12.6-6.5 19.4-.8 6.8-.3 13.7 1.6 20.3 1.9 6.6 5 12.8 9.3 18.1 4.1 5.6 9.3 10.2 15.3 13.7 5.9 3.4 12.5 5.7 19.3 6.5 6.8.9 13.7.4 20.4-1.5 6.6-1.9 12.7-5.1 18.1-9.4l731.4-561.3c5.4-4.1 10-9.3 13.4-15.3 3.5-5.9 5.7-12.5 6.5-19.3.9-6.9.3-13.8-1.5-20.4-1.9-6.6-5.1-12.7-9.4-18.1-4.4-5.3-9.8-9.7-15.8-12.9-6.1-3.3-12.7-5.5-19.5-6.3-6.8-.9-13.7-.4-20.4 1.3-6.6 1.7-12.9 4.6-18.5 8.6z"
              className="s0"
            />
            <path
              d="M888.8 471.8c-4.2-5.5-9.4-10.2-15.4-13.6-6-3.5-12.6-5.7-19.4-6.6-6.9-.8-13.8-.3-20.5 1.6-6.6 1.8-12.8 5-18.2 9.3l-790.4 607c-5.5 4.2-10.1 9.4-13.5 15.4-3.4 6-5.7 12.6-6.5 19.4-.8 6.8-.3 13.8 1.6 20.4 1.8 6.6 5 12.8 9.3 18.2 4.2 5.4 9.4 10 15.3 13.5 6 3.4 12.5 5.6 19.4 6.4 6.8.9 13.7.3 20.3-1.5 6.6-1.9 12.7-5.1 18.1-9.4l791.8-607c5.1-4.4 9.4-9.7 12.6-15.7 3.2-6 5.2-12.5 5.9-19.3.8-6.7.2-13.5-1.5-20-1.8-6.6-4.8-12.7-8.9-18.1z"
              className="s0"
            />
            <path
              d="M11.3 645.6c4.2 5.5 9.3 10.1 15.3 13.5 5.9 3.4 12.5 5.6 19.3 6.5 6.8.8 13.8.3 20.4-1.6 6.6-1.9 12.7-5 18.1-9.3l699.2-536.2 398.8 305.3-295.9 227.5c-10.7 7.9-17.9 19.6-20.3 32.7-2.3 13.1.4 26.6 7.7 37.7 7.3 11.1 18.6 19 31.6 22.1 12.9 3.1 26.5 1.1 38-5.6q.8-.5 1.6-1.1.7-.5 1.4-1.1.8-.6 1.4-1.2.7-.7 1.3-1.4L1298.9 465l3.4-3.6 5.7-5.7c4-5.5 7-11.6 8.7-18.2 1.7-6.6 2.2-13.4 1.4-20.2-.9-6.7-3-13.2-6.3-19.2-3.2-5.9-7.6-11.2-12.9-15.5L815.3 11.2q-2.7-2.2-5.7-4-2.9-1.8-6.1-3.1-3.2-1.3-6.5-2.2-3.4-.8-6.8-1.1h-12.5q-3.4.4-6.7 1.3-3.4.8-6.5 2.2-3.2 1.3-6.2 3-3 1.8-5.7 3.9L21.5 572.3c-5.5 4.1-10 9.3-13.5 15.3-3.4 5.9-5.7 12.4-6.6 19.2-1 6.8-.6 13.8 1.1 20.4 1.7 6.6 4.7 12.9 8.8 18.4zM1489.6 679.8l-731.1 561.3c-5.5 4.1-10 9.3-13.5 15.3-3.4 5.9-5.6 12.5-6.5 19.3-.8 6.8-.3 13.7 1.5 20.3 1.8 6.6 5 12.8 9.2 18.2 4.2 5.5 9.4 10.1 15.4 13.5 5.9 3.4 12.5 5.6 19.4 6.5 6.8.8 13.7.3 20.3-1.6 6.7-1.9 12.8-5 18.2-9.3l731.1-561.1c5.5-4.2 10.1-9.4 13.5-15.4 3.5-6 5.7-12.6 6.5-19.4.9-6.8.3-13.8-1.5-20.4-1.9-6.6-5.1-12.8-9.4-18.2-4.3-5.2-9.6-9.6-15.5-12.9-6-3.3-12.5-5.4-19.2-6.2-6.8-.8-13.6-.4-20.2 1.3-6.5 1.8-12.7 4.7-18.2 8.8zM688.8 1110.7c4.2 5.5 9.4 10.1 15.3 13.5 6 3.4 12.5 5.6 19.3 6.5 6.9.8 13.8.3 20.4-1.6 6.6-1.8 12.7-5 18.1-9.3L1553.6 513c10.9-8.7 18.1-21.2 19.9-34.9 1.7-13.8-2-27.7-10.4-38.7-8.5-10.9-21-18.1-34.7-19.9-13.7-1.8-27.6 1.9-38.6 10.4L699 1037.6c-5.4 4.2-10 9.4-13.4 15.3-3.5 5.9-5.7 12.4-6.7 19.2-.9 6.8-.5 13.7 1.2 20.3 1.7 6.6 4.6 12.8 8.7 18.3z"
              className="s0"
            />
            <path
              d="M1566.3 938c-4.1-5.5-9.4-10-15.3-13.5-6-3.4-12.6-5.6-19.4-6.5-6.9-.8-13.8-.3-20.4 1.6-6.6 1.9-12.8 5.1-18.2 9.4l-699.2 536.2-398.6-306.5 295.9-227.5c10.8-8.5 17.9-20.9 19.6-34.6 1.7-13.7-2.1-27.6-10.6-38.4-8.5-10.9-20.9-18-34.6-19.7-13.7-1.7-27.5 2.1-38.4 10.6l-349.5 268.4-3.7 3.6-5.6 5.7c-3.9 5.5-6.8 11.6-8.4 18.1-1.6 6.5-2 13.3-1.2 19.9.9 6.7 3 13.1 6.2 19s7.5 11.1 12.7 15.4l484.3 372.1q2.7 2.2 5.6 4 3 1.7 6.2 3 3.2 1.4 6.5 2.2 3.4.9 6.8 1.2h13.8q3.4-.4 6.7-1.3 3.4-.9 6.5-2.2 3.2-1.3 6.2-3.1 3-1.7 5.7-3.8l731.1-561.1c5.3-4.2 9.7-9.4 12.9-15.2 3.3-5.9 5.5-12.3 6.3-19 .9-6.7.4-13.4-1.3-19.9-1.7-6.6-4.6-12.7-8.6-18.1z"
              className="s0"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HindujaSVG;
