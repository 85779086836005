import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useMembers from '@/hooks/useMembers';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { parseFilter } from '@/util/util';

import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

export type ResponseRatingType = 'avg_rating' | 'median_rating';

interface Rows {
  rows: Row[];
}

interface Row {
  agent_id: string;
  agent_name: string;
  brain_id: string;
  brain_parent_id: string;
  feedback: string;
  rating: number;
  session_id: string;
  version: number;
  user_name: string;
  external_user_id: string;
  time: string;
  desk_id: string;
}

interface ResultRow {
  agent_id: string;
  agent_name: string;
  brain_id: string;
  brain_parent_id: string;
  feedback: string;
  rating: number;
  session_id: string;
  version: number;
  user_name: string;
  external_user_id: string;
  time: string;
  desk_id: string;
}

interface CustomerRemarksHook {
  isLoading: boolean;
  error?: ApolloError;
  refetch?: () => void;
  data?: Array<ResultRow>;
}

export type MostUsedBrainsProps = {
  skip?: boolean;
  skipPrevious?: boolean;
};

const formatData = (current?: Row[]): Row[] | undefined => {
  if (!current || current.length === 0) {
    return undefined;
  }

  const result = current.reduce((acc, item) => {
    acc.push({
      agent_id: item.agent_id,
      agent_name: item.agent_name,
      brain_id: item.brain_id,
      brain_parent_id: item.brain_parent_id,
      feedback: item.feedback,
      rating: item.rating,
      session_id: item.session_id,
      version: item.version,
      user_name: item.user_name,
      external_user_id: item.external_user_id,
      desk_id: item.desk_id,
      time: moment(item.time).format('YYYY-MM-DD HH:mm:ss'),
    });
    return acc;
  }, [] as Row[]);
  return result;
};

export const RATINGS_FEEDBACKS = gql`
  query GetNormalizedRatingFeedback(
    $accountId: uuid
    $agentIds: _text
    $brainIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $deskIds: _uuid
    $endDate: timestamp
    $integrationIds: _uuid
    $isTest: Boolean
    $ratings: _int4
    $startDate: timestamp
    $limit: Int
  ) {
    rows: rating_feedback_normalized(
      limit: $limit
      args: {
        account_id: $accountId
        agent_ids: $agentIds
        brain_parent_ids: $brainIds
        brain_versions: $brainVersions
        channels: $channels
        desk_ids: $deskIds
        end_time: $endDate
        integration_ids: $integrationIds
        is_test: $isTest
        ratings: $ratings
        start_time: $startDate
      }
    ) {
      account_id
      agent_name
      brain_parent_id
      desk_id
      feedback
      rating
      session_id
      time
      user_name
    }
  }
`;

const useCustomerRemarks = (filterRatings?: number[]): CustomerRemarksHook => {
  const accountId = useSelector(selectAccountId);
  const filters = useSelector(selectFilters);
  const { t } = useTranslation();
  const { members } = useMembers();

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters.endDate),
          accountId,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error,
    refetch,
  } = useQuery<Rows, QueryVariables>(RATINGS_FEEDBACKS, {
    variables,
    skip: !accountId || !filters.filtersLoaded,
  });

  const currentRows = currentData?.rows;

  const data = useMemo(() => {
    const formatedData = formatData(currentRows);

    const result = formatedData?.reduce((acc, item) => {
      const agent_name =
        members?.find(({ user_id }) => user_id === item.agent_id)?.name ??
        item.agent_name;

      if (
        !filterRatings ||
        filterRatings.length === 0 ||
        filterRatings?.includes(item.rating)
      ) {
        acc.push({
          ...item,
          agent_name: agent_name ?? item.brain_parent_id,
          name:
            item.user_name || item.external_user_id || t('common.anonymous'),
        });
      }

      return acc;
    }, []);

    return result;
  }, [currentRows, filterRatings, members, t]);

  const onRefetch = useCallback(() => {
    refetch(variables);
  }, [refetch, variables]);

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (error) {
    return {
      isLoading: false,
      error,
      refetch: onRefetch,
    };
  }

  return { isLoading: false, data };
};

export default useCustomerRemarks;
