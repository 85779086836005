import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ChatState } from '@/models/chat';

const defaultState: ChatState = {
  messageSound: null,
  sideBarOpen: true,
  view: {
    selectedStatus: 'open',
    selectedAgent: '',
    selectedDepartment: '',
    selectedBrain: '',
    selectedSort: 'newest',
    title: 'me',
  },
};

const slice = createSlice({
  name: 'chatNew',
  initialState: defaultState,
  reducers: {
    setView: (state, action: PayloadAction<ChatState['view']>) => {
      const view = action.payload;
      state.view = { ...state.view, ...view };
    },
    setMessageSound: (state, action) => {
      const messageSound = action.payload;
      state.messageSound = messageSound;
    },
    setSideBarOpen: (state, action: PayloadAction<boolean>) => {
      const sideBarOpen = action.payload;
      state.sideBarOpen = sideBarOpen;
    },
    resetView: (state) => {
      state.view = defaultState.view;
    },
  },
});

export const { setMessageSound, setSideBarOpen, setView, resetView } =
  slice.actions;

export default slice.reducer;
