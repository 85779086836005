import { ChangeEvent } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label: string | React.ReactNode;
  checked: boolean;
  sameLevel?: boolean;
  size?: 'medium' | 'small';
  disabled?: boolean;
}

const FieldsetCheckbox: React.FC<Props> = ({
  onChange,
  label,
  checked,
  sameLevel,
  size,
  disabled,
}: Props) => {
  const muiStyles = {
    root: {
      marginTop: '-24px',
    },
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name="checkbox"
          color="primary"
          sx={sameLevel ? muiStyles : null}
          size={size}
          disabled={disabled}
        />
      }
      label={label}
      sx={{
        '& .MuiCheckbox-root.Mui-disabled': {
          color: 'var(--text-default-gray-light)',
        },
      }}
      disabled={disabled}
    />
  );
};

export default FieldsetCheckbox;
