import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '@/components/organisms/Modals/Modal';
import { useAutoBrain } from '@/hooks/useAutoBrain';
import useBrains from '@/hooks/useBrains';
import { EventName } from '@/models/segment';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';
import { submitWithTrimming } from '@/util/util';

import AutoBrainError from './AutoBrainError';
import AutoBrainLandingPage from './AutoBrainLandingPage';
import AutoBrainLoading from './AutoBrainLoading';
import AutoBrainResults from './AutoBrainResults';
import { defaultStartingText } from './util';

const ModalAutoBrain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const {
    isLoading,
    isSubmitting,
    error,
    generatedDialog,
    addDialogToBrain,
    resetResults,
    generateAutoDialog,
  } = useAutoBrain();

  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);

  useEffect(() => {
    if (generatedDialog && !result) {
      setResult(generatedDialog);
    }
  }, [generatedDialog, result]);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      prompt: '',
    },
  });

  const {
    watch,
    formState: { errors },
    register,
    control,
  } = formMethods;

  const prompt = watch('prompt');

  const onSubmit = useCallback(
    ({ prompt }: { prompt: string }) => {
      trackEvent(EventName.ClickSubmitAutoDialogPrompt);
      generateAutoDialog(`${defaultStartingText(brain?.language)}${prompt}`);
    },
    [brain?.language, generateAutoDialog]
  );

  const handleSecondarySubmit = () => {
    if (result) {
      resetResults();
      setResult(null);
    } else {
      dispatch(popModal());
      return undefined;
    }
  };

  const passiveModal = isLoading;

  const disableSaveButton =
    prompt.length < 48 - defaultStartingText(brain?.language).length ||
    prompt.length > 1200;

  const getModalProps = useCallback(
    ({ isLoading, result, error }) => {
      let data: { [key: string]: unknown } = {
        size: 'medium',
        primaryButtonText: t('common.generate'),
        secondaryButtonText: t('common.cancel'),
        onPrimarySubmit: submitWithTrimming(formMethods, onSubmit),
        primaryButtonDisable: disableSaveButton,
        title: t('auto_brain.auto_builder'),
        subtitle: t('auto_brain.auto_builder_subtitle'),
      };
      if (error) {
        data = {
          ...data,
          buttonAlignment: 'center',
          primaryButtonText: t('common.try_again'),
          primaryButtonDisable: false,
          onPrimarySubmit: () => {
            resetResults();
            setResult(null);
          },
          size: 'small',
          title: undefined,
          subtitle: undefined,
        };
      }
      if (isLoading) {
        data = { ...data, size: 'small', preventClose: true };
      }
      if (result) {
        data = {
          ...data,
          primaryButtonDisable: false,
          title: t('auto_brain.dialog_is_ready'),
          subtitle: t('auto_brain.given_description'),
          primaryButtonText: t('common.accept'),
          secondaryButtonText: t('common.back'),
          onPrimarySubmit: addDialogToBrain,
        };
      }

      return data;
    },
    [
      disableSaveButton,
      formMethods,
      addDialogToBrain,
      onSubmit,
      resetResults,
      t,
    ]
  );

  return (
    <Modal
      size={!isLoading ? 'medium' : 'small'}
      {...getModalProps({ error, result, isLoading })}
      isSubmitting={isSubmitting}
      preventSecondaryClose
      onSecondarySubmit={handleSecondarySubmit}
      cleanModal={passiveModal}
    >
      {error && <AutoBrainError error={error} />}
      {isLoading && <AutoBrainLoading />}
      {!isLoading && !result && !error && (
        <AutoBrainLandingPage
          register={register}
          control={control}
          error={errors?.prompt}
        />
      )}
      {result && !error && <AutoBrainResults prompt={prompt} data={result} />}
    </Modal>
  );
};

export default ModalAutoBrain;
