import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import ListItem from '@/components/atoms/ListItem/ListItem';
import { useAccount } from '@/hooks/useAccount';
import useFeatureFlag from '@/hooks/useFeatureFlag';

import { getReportOptions } from './constants';

import styles from './AnalyticsNavigation.module.scss';

const AnalyticsNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { slug } = useAccount();
  const { ai_agents } = useFeatureFlag();

  return (
    <div className={styles.container}>
      <ul>
        <ListItem type="title" text={t('analytics.reports')} />
        {getReportOptions(ai_agents)?.map(({ text_key, link }) => (
          <NavLink to={`/${slug}/analytics${link}`} key={text_key}>
            <ListItem
              active={location.pathname.includes(link)}
              text={t(text_key)}
            />
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default AnalyticsNavigation;
