import { memo, useCallback, useId } from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconComponent } from '@/modules/humanChat/utils';
import { isKeyEnter } from '@/util/util';

import { Item, StyledBadge } from './components';
import { MenuItem } from '../../../models';

import styles from './NavItem.module.scss';

export const NavItem = memo(
  ({
    icon: Icon,
    text,
    onClick,
    sum,
    hasNotification,
    selected = false,
    disabled = false,
  }: MenuItem & {
    selected: boolean;
    hasNotification: boolean;
    disabled: boolean;
  }) => {
    const id = useId();
    const { t } = useTranslation();
    const handleOnKeyDown = useCallback(
      (event) => {
        if (onClick && isKeyEnter(event)) {
          onClick();
        }
      },
      [onClick]
    );

    const listItemButton = (
      <Item
        disablePadding
        secondaryAction={
          <Typography
            color="var(--text-default-gray-light)"
            variant="label-regular"
          >
            {sum}
          </Typography>
        }
        customDisabled={disabled}
        className={cn(styles.item, {
          [styles.active]: selected,
        })}
      >
        <ListItemButton
          disableRipple
          onClick={onClick}
          onKeyDown={handleOnKeyDown}
          disableGutters
          disabled={disabled}
        >
          {Icon && (
            <StyledBadge
              overlap="circular"
              variant="dot"
              invisible={!hasNotification}
            >
              <ListItemIcon>
                <IconComponent icon={Icon} />
              </ListItemIcon>
            </StyledBadge>
          )}

          <ListItemText
            id={id}
            primary={text}
            primaryTypographyProps={{
              variant: 'body-regular',
              color: 'var(--text-default-black)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          />
        </ListItemButton>
      </Item>
    );

    return disabled ? (
      <Tooltip
        enterDelay={500}
        enterNextDelay={500}
        title={t('conversations.no_permission')}
      >
        <div>{listItemButton}</div>
      </Tooltip>
    ) : (
      listItemButton
    );
  }
);

NavItem.displayName = 'NavItem';
