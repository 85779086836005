import { useCallback, useMemo } from 'react';

import { ApolloError, DocumentNode, gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '@/models/state';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange, getDateRangeText } from '@/util/analytics';
import {
  numberFormatter,
  parseFilter,
  shouldUseSlowQueries,
} from '@/util/util';

import { IntervalRow, IntervalType } from './useInterval';
import { filterFiltersByUrl } from '../constants';

export const SESSIONS_COUNTS = gql`
  query SessionsCounts(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $isContained: Boolean
    $isCovered: Boolean
    $isTest: Boolean
    $minNumUserMessages: Int
    $agentIds: _text
    $events: _text
  ) {
    rows: sessions_counts(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_contained: $isContained
        is_covered: $isCovered
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
        agent_ids: $agentIds
        events: $events
      }
    ) {
      counts
    }
  }
`;

export const SESSIONS_COUNTS_FAST = gql`
  query SessionsCountsFast(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $startDate: timestamp
    $endDate: timestamp
    $isContained: Boolean
    $isCovered: Boolean
    $isTest: Boolean
    $isBrain: Boolean
  ) {
    rows: sessions_counts_fast(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        is_contained: $isContained
        is_covered: $isCovered
        is_test: $isTest
        is_brain: $isBrain
      }
    ) {
      counts
    }
  }
`;

export const USERS_COUNTS = gql`
  query UsersCounts(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
    $minNumUserMessages: Int
  ) {
    rows: users_counts(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      counts
    }
  }
`;

export const USERS_COUNTS_FAST = gql`
  query UsersCountsFast(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
  ) {
    rows: users_counts_fast(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        is_test: $isTest
      }
    ) {
      counts
    }
  }
`;

export const REQUESTS_COUNTS = gql`
  query RequestsCounts(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
    $minNumUserMessages: Int
  ) {
    rows: requests_counts(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      counts
    }
  }
`;

export const REQUESTS_COUNTS_FAST = gql`
  query RequestsCountsFast(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
  ) {
    rows: requests_counts_fast(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        is_test: $isTest
      }
    ) {
      counts
    }
  }
`;

const QUERIES = {
  dialogs: REQUESTS_COUNTS,
  users: USERS_COUNTS,
  sessions: SESSIONS_COUNTS,
  dialogs_fast: REQUESTS_COUNTS_FAST,
  users_fast: USERS_COUNTS_FAST,
  sessions_fast: SESSIONS_COUNTS_FAST,
};

const getQueryFromVariables = (type: IntervalType, variables): DocumentNode => {
  if (shouldUseSlowQueries(variables)) {
    return QUERIES[type];
  }
  return QUERIES[`${type}_fast`];
};

interface Rows {
  rows: IntervalRow[];
}

interface Usage {
  counts: number | string;
  exact_counts: number | string;
  growth: number;
  timeframe: string;
}

interface UsageHook {
  isLoading: boolean;
  isPreviousLoading?: boolean;
  currentError?: ApolloError;
  previousError?: ApolloError;
  refetchCurrent?: () => void;
  refetchPrevious?: () => void;
  data?: Usage;
}

interface Props {
  intervalProps: {
    type: IntervalType;
    value?: string;
    startDate?: string;
    endDate?: string;
    intervalInDays?: '1 day';
    is_contained?: boolean;
    is_covered?: boolean;
  };
  skip?: boolean;
  skipPrevious?: boolean;
}

const formatUsage = (
  current?: IntervalRow[],
  previous?: IntervalRow[]
): Omit<Usage, 'timeframe'> | undefined => {
  if (!current) {
    return undefined;
  }

  let currentCounts = 0;
  let previousCounts = 0;

  if (current.length > 0) {
    currentCounts = current[0].counts;
  }
  if (previous && previous.length > 0) {
    previousCounts = previous[0].counts;
  }

  let growth = 0;
  if (previous && previousCounts > 0) {
    growth = Math.round(
      ((currentCounts - previousCounts) / previousCounts) * 100
    );
  }

  return {
    counts: numberFormatter(currentCounts),
    exact_counts: numberFormatter(currentCounts, false),
    growth: previous ? growth : undefined,
  };
};

const useOverViewUsage = ({
  intervalProps,
  skip,
  skipPrevious,
}: Props): UsageHook => {
  const accountId = useSelector(selectAccountId);
  const { type, startDate, endDate, is_contained, is_covered } = intervalProps;

  const filters = useSelector((state: RootState) => {
    return {
      analytics: state.analytics.accountAnalytics,
      startDate: startDate || state.analytics.startDate,
      endDate: endDate || state.analytics.endDate,
      filtersLoaded: state.analytics.filtersLoaded,
    };
  }, shallowEqual);

  const periodInDays = moment(filters.endDate).diff(
    moment(filters.startDate),
    'days'
  );

  const previousStartDate = filters.startDate
    ? moment(filters.startDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;
  const previousEndDate = filters.endDate
    ? moment(filters.endDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters?.endDate),
          accountId,
          isCovered: is_covered ?? undefined,
          isContained: is_contained ?? undefined,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      is_contained,
      is_covered,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error: currentError,
    refetch: refetchCurrent,
  } = useQuery<Rows, QueryVariables>(getQueryFromVariables(type, variables), {
    variables,
    skip: skip || !accountId,
  });

  const {
    loading: previousLoading,
    data: previousData,
    error: previousError,
    refetch: refetchPrevious,
  } = useQuery<Rows, QueryVariables>(getQueryFromVariables(type, variables), {
    variables: {
      ...variables,
      ...getAnalyticsRange(previousStartDate, previousEndDate),
    },
    skip: skipPrevious || !filters.filtersLoaded,
  });
  const currentRows = currentData?.rows;
  const previousRows = previousData?.rows;

  const data = useMemo(() => {
    return {
      ...formatUsage(currentRows, previousRows),
      timeframe: getDateRangeText(filters.startDate, filters.endDate),
    };
  }, [currentRows, filters.endDate, filters.startDate, previousRows]);

  const onRefetchCurrent = useCallback(() => {
    refetchCurrent(variables);
  }, [refetchCurrent, variables]);

  const onRefetchPrevious = useCallback(() => {
    refetchPrevious({
      ...variables,
      startDate: previousStartDate,
      endDate: previousEndDate,
    });
  }, [previousEndDate, previousStartDate, refetchPrevious, variables]);

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (previousLoading && !skipPrevious) {
    return {
      data,
      isLoading: false,
      isPreviousLoading: true,
    };
  }

  if (currentError || previousError) {
    return {
      isLoading: false,
      currentError,
      previousError,
      refetchCurrent: onRefetchCurrent,
      refetchPrevious: onRefetchPrevious,
    };
  }

  return { isLoading: false, data };
};

export default useOverViewUsage;
