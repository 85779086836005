import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { filterFiltersByUrl } from '@/modules/analytics/constants';
import { Log, LogsQueryVariables } from '@/modules/analytics/models';
import { selectFilters } from '@/modules/analytics/redux/selectors';
import { selectAccountId, selectBrainId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { parseFilter } from '@/util/util';

import {
  LogSessionsPreview,
  LogSessionsPreviewAggregate,
  SESSIONS_AGGREGATE,
  SESSIONS_PREVIEW,
} from './useLogs';

export type HumanLogsHook = {
  isLoading?: boolean;
  previewError?: ApolloError;
  contentError?: ApolloError;
  numberOfData?: number;
  refetchPreview?: () => void;
  refetchContent?: () => void;
  loadMore?: () => Promise<ApolloQueryResult<LogSessionsPreview>> | null;
  data?: Log[];
};

const useHumanChatLogs = (limit = 25, user_id = null): HumanLogsHook => {
  const { t } = useTranslation();
  const accountId = useSelector(selectAccountId);
  const brainId = useSelector(selectBrainId);
  const filters = useSelector(selectFilters);

  const variables = Object.assign(
    {},
    {
      ...getAnalyticsRange(filters.startDate, filters?.endDate),

      accountId,
    },
    ...filters.analytics
      .filter((filter) =>
        filterFiltersByUrl(filter?.type, location?.pathname, t)
      )
      .map((filter) => ({
        [filter.type]: parseFilter(filter),
      }))
  );

  const { data: numberOfData } = useQuery<
    LogSessionsPreviewAggregate,
    LogsQueryVariables
  >(SESSIONS_AGGREGATE, {
    variables: {
      ...variables,
    },
    skip: !accountId || !!brainId || !filters?.filtersLoaded,
  });

  const {
    loading: previewLoading,
    data: previewData,
    error: previewError,
    refetch: refetchPreview,
    fetchMore,
  } = useQuery<LogSessionsPreview, LogsQueryVariables>(SESSIONS_PREVIEW, {
    variables: {
      ...variables,
      limit,
      offset: 0,
    },
    skip: !!user_id || !accountId || !!brainId || !filters?.filtersLoaded,
  });

  const finalData = previewData?.rows ?? [];

  const loadMore = () => {
    if (
      filters?.startDate &&
      // Prevent extra call with empty data
      finalData.length < numberOfData?.rows?.aggregate?.count
    ) {
      return fetchMore({
        query: SESSIONS_PREVIEW,
        variables: {
          ...variables,
          limit,
          offset: finalData.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!isEmpty(prev.rows)) {
            const finalData = [...prev.rows, ...fetchMoreResult.rows];
            return { ...fetchMoreResult, rows: finalData };
          }
          return fetchMoreResult;
        },
      });
    }
    return null;
  };

  if (previewLoading || previewError) {
    return {
      isLoading: previewLoading,
      previewError,
      refetchPreview,
    };
  }

  return {
    isLoading: false,
    data: finalData,
    numberOfData: numberOfData?.rows?.aggregate?.count,
    loadMore,
  };
};

export default useHumanChatLogs;
