import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Zendesk = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, color = '#24464A', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.087 7.752V21H0L11.087 7.752zm0-4.752c0 3.027-2.478 5.48-5.538 5.48C2.49 8.48 0 6.027 0 3h11.087zm1.826 18c0-3.027 2.478-5.48 5.538-5.48 3.059 0 5.537 2.453 5.537 5.48H12.913zm0-4.752V3H24L12.913 16.248z"
          fill={color}
        />
      </svg>
    );
  }
);

Zendesk.displayName = 'Zendesk';

export default Zendesk;
