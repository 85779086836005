import { PageName } from '@/models/segment';

import CustomerRemarks from '../components/fieldsets/CustomerRemarks/CustomerRemarks';
import RatingByDay from '../components/fieldsets/RatingByDay/RatingByDay';
import Filters from '../components/filters/AccountFilters/AccountFilters';
import useSegments from '../hooks/useSegment';

export const CustomerSatisfaction = () => {
  useSegments(PageName.ANALYTICS_CUSTOMER_SATISFACTION);
  return (
    <>
      <Filters />
      <RatingByDay />
      <RatingByDay isMoreThan3 />
      <CustomerRemarks />
    </>
  );
};
