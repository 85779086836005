import { useCallback } from 'react';

import cn from 'classnames';

import CheckboxSelected from '@/components/atoms/Icons/CheckboxSelected';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import styles from './ButtonCard.module.scss';

export interface ButtonCardProps {
  children: React.ReactNode;
  active: boolean;
  isTheme?: boolean;
  isButtonLike?: boolean;
  onClick: (number: number) => void;
  index: number;
  isDisabled?: boolean;
}

const ButtonCard = ({
  children,
  active,
  isTheme,
  isButtonLike,
  onClick,
  index,
  isDisabled,
}: ButtonCardProps) => {
  const handleClick = useCallback(
    (e) => {
      preventClickThrough(e);
      onClick(index);
    },
    [onClick, index]
  );

  const handleKeyDown = useCallback(
    (e) => {
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        onClick(index);
      }
    },
    [onClick, index]
  );

  return (
    <>
      <div
        className={cn(
          styles.buttonCard,
          { [styles.active]: active },
          { [styles.isTheme]: isTheme },
          { [styles.isButtonLike]: isButtonLike },
          { [styles.isDisabled]: isDisabled }
        )}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
      >
        {children}
        {active && (
          <span className={styles.checkbox} data-testid="buttoncard-checkbox">
            <CheckboxSelected />
          </span>
        )}
      </div>
    </>
  );
};

export default ButtonCard;
