import React, { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IntentIcon from '@/components/atoms/Icons/Intent';
import { useAccount } from '@/hooks/useAccount';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import {
  CollectionMessage,
  NodeType,
  TryIt,
  UserMessage,
} from '@/models/tryIt';
import { DisambiguationIconGray } from '@/modules/TryIt/Icons/DisambiguationIcon';
import { TriggerMessageIcon } from '@/modules/TryIt/Icons/TriggerMessageIcon';
import { selectSelectedMessage } from '@/modules/TryIt/redux/selectors';
import { TYPES, iconByType } from '@/redux/dialogs/helper';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { resolveBrainsPath } from '@/util/util';

import styles from './DetailsSection.module.scss';

export type DisambiguationDetailsSectionContentProps = {
  nodes: TryIt['selectedMessage']['nodes'];
  index: number;
  intents: NodeType['intents'];
  slug: string;
  brainId: string;
};

export const DisambiguationDetailsSectionContent = ({
  nodes,
  index,
  intents,
  slug,
  brainId,
}: DisambiguationDetailsSectionContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ai_agents } = useFeatureFlag();

  const userMessage = (nodes[index]?.messages[0] as CollectionMessage)?.text;

  const closeModal = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  return (
    <Stack data-testid="newDebugLogs">
      {userMessage && (
        <>
          <div className={styles.step}>
            <div className={styles.title}>
              <TriggerMessageIcon />

              <Typography
                variant="body-regular"
                color="var(--text-default-gray)"
                className={styles.triggerMessage}
                component="div"
              >
                {userMessage}
              </Typography>
            </div>
            <div className={styles.details}></div>
          </div>

          <div className={styles.step}>
            {intents.map((intent) => (
              <div
                className={cn(styles.title, styles.intent)}
                key={intent.intent}
              >
                <IntentIcon color="var(--icon-default-gray)" />
                <Typography
                  variant="body-regular"
                  color="var(--text-default-gray)"
                  component="div"
                >
                  {t('common.intent')}{' '}
                  <NavLink
                    to={resolveBrainsPath(
                      `/${slug}/brains/${brainId}/intents/${intent.intent}`,
                      ai_agents
                    )}
                    className={styles.link}
                    onClick={closeModal}
                  >
                    {intent.intent}
                  </NavLink>{' '}
                  {t('try_it.matched_with_confidence', {
                    percentage: (intent?.confidence * 100).toFixed(0),
                  })}
                </Typography>
              </div>
            ))}
            <div className={styles.details}></div>
          </div>
        </>
      )}
      <div className={styles.step}>
        <div className={styles.title}>
          <DisambiguationIconGray />
          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('try_it.disambiguation_triggered')}
          </Typography>
        </div>
        <div className={styles.details}></div>
      </div>
      <div className={styles.step}>
        <div className={styles.title}>
          {iconByType({
            id: 'text',
            size: 16,
            type: TYPES.ACTION,
            stepsMode: true,
          })}
          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('try_it.details.text_message_sent')}
          </Typography>
        </div>
        <div className={styles.details}></div>
      </div>
    </Stack>
  );
};

export const DisambiguationDetailsSection = () => {
  const selectedMessage = useSelector(selectSelectedMessage);
  const { slug } = useAccount();
  const brainId = useSelector(selectBrainId);

  const { nodes, index } = selectedMessage;
  const intents =
    (nodes[index]?.intents ||
      (nodes[index]?.messages?.[0] as UserMessage)?.intents) ??
    [];

  return (
    <DisambiguationDetailsSectionContent
      {...{ nodes, index, intents, slug, brainId }}
    />
  );
};
