import './InvitationSkeleton.scss';

import Skeleton from '@mui/material/Skeleton';

const InvitationSkeleton = () => {
  return (
    <div className="skeleton-fieldset">
      <div className="skeleton-fieldset__content">
        <Skeleton animation="wave" width={150} height={32} />

        <Skeleton animation="wave" width={200} height={20} />

        <div className="skeleton-fieldset__avatar">
          <Skeleton
            animation="wave"
            variant="circular"
            width={48}
            height={48}
          />
        </div>
        <div className="skeleton-fieldset__footer">
          <Skeleton animation="wave" width={500} height={48} />
        </div>
      </div>
    </div>
  );
};

export default InvitationSkeleton;
