import { useMemo } from 'react';

import {
  useMutation,
  useQuery,
  useQueryClient,
  QueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { teamsEndpoints as endpoints, membersEndpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { Teams, Team, PartialTeam } from '@/models/team';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';

const API = {
  listTeams: async (): Promise<Teams> => callGet(endpoints.teams),
  getTeam: async (teamId: string): Promise<Team> =>
    callGet(endpoints.team(teamId)),
  createTeam: async (newTeam: Partial<Team>): Promise<Team> =>
    callPost(endpoints.teams, newTeam),
  updateTeam: async (newTeam: Partial<Team>): Promise<Team> =>
    callPut(endpoints.team(newTeam.team_id), newTeam),
  deleteTeam: async (team: PartialTeam): Promise<Team> =>
    callDelete(endpoints.team(team.team_id)),
};

export const onTeamCreated = (
  queryClient: QueryClient,
  team: Team,
  accountSlug: string
) => {
  queryClient.setQueryData<Teams>([endpoints.teams, accountSlug], (prev) => ({
    teams: [
      ...(prev?.teams || []).filter((acc) => acc.team_id !== team.team_id),
      team,
    ],
  }));
};

export const onTeamUpdated = (
  queryClient: QueryClient,
  team: Team,
  accountSlug: string
) => {
  queryClient.setQueryData<Teams>([endpoints.teams, accountSlug], (prev) => ({
    teams: [
      ...(prev?.teams || []).map((t) => {
        if (t.team_id !== team.team_id) {
          return t;
        }
        return team;
      }),
    ],
  }));
};

export const onTeamDeleted = (
  queryClient: QueryClient,
  resp: PartialTeam,
  accountSlug: string
) => {
  queryClient.setQueryData<Teams>(
    [endpoints.teams, accountSlug],
    (prev: Teams) => ({
      ...prev,
      teams: prev.teams.filter((t) => t.team_id !== resp.team_id),
    })
  );
};

export const useTeams = (teamId?: string) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const accountSlug = useSelector(selectAccountSlug);

  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'teams', actions.READ)
  );

  const { data: teams, status: listStatus } = useQuery<Teams, Error>({
    queryKey: [endpoints.teams, accountSlug],
    queryFn: () => API.listTeams(),
    enabled: !!accountSlug && canRead,
  });

  const { data: team, status: getStatus } = useQuery<Team, Error>({
    queryKey: [endpoints.team(teamId), accountSlug],
    queryFn: () => API.getTeam(teamId),
    enabled: !!accountSlug && !!teamId,
  });

  const { mutate: createTeam, status: createStatus } = useMutation<
    Team,
    Error,
    Partial<Team>
  >({
    mutationFn: API.createTeam,
    onSuccess: (resp) => {
      onTeamCreated(queryClient, resp, accountSlug);
      dispatch(
        addTemporalToast('success', t('teams.created', { 0: resp?.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: updateTeam, status: updateStatus } = useMutation<
    Team,
    Error,
    Partial<Team>
  >({
    mutationFn: API.updateTeam,
    onSuccess: (resp) => {
      onTeamUpdated(queryClient, resp, accountSlug);
      dispatch(
        addTemporalToast('success', t('teams.updated', { 0: resp?.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteTeam, status: deleteStatus } = useMutation<
    PartialTeam,
    Error,
    PartialTeam
  >({
    mutationFn: API.deleteTeam,
    onSuccess: (resp) => {
      onTeamDeleted(queryClient, resp, accountSlug);
      queryClient.invalidateQueries({
        queryKey: [membersEndpoints.members, accountSlug],
      });
      dispatch(addTemporalToast('success', t('teams.team_deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const accessTeams = useMemo(
    () => teams?.teams.filter((team) => team.role_ids.length > 0),
    [teams?.teams]
  );
  const noAccessTeams = useMemo(
    () => teams?.teams.filter((team) => team.role_ids.length === 0),
    [teams?.teams]
  );

  return {
    accessTeams,
    noAccessTeams,
    teams: teams?.teams,
    listStatus,
    team,
    getStatus,
    deleteStatus,
    deleteTeam,
    createTeam,
    createStatus,
    updateTeam,
    updateStatus,
  };
};
