import { useCallback, useRef, useState } from 'react';

import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import {
  Control,
  UseFormSetValue,
  Controller,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AutoCompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew';
import Button from '@/components/atoms/Button/Button/Button';
import CommandPalette from '@/components/organisms/CommandPalette/CommandPalette';
import OptionsPopper, {
  OptionsPopperProps,
} from '@/components/organisms/RichTextEditor/OptionsPopper/OptionsPopper';
import { useBrainVariables } from '@/hooks/useBrainVariables';
import { useCommandPallete } from '@/hooks/useCommandPallete';
import {
  capitalizeFirstLetter,
  wrapDollarSignWithCurlyBraces,
} from '@/util/util';

import { SetVariablesActionForm } from '../ToolkitSetVariables';

import styles from './SetVariablesInput.module.scss';

type Props<FormType extends FieldValues = SetVariablesActionForm> = {
  control: Control<SetVariablesActionForm>;
  setValue: UseFormSetValue<FormType>;
  error?: FieldError;
  onSave: () => void;
};

// Debounce the onSave function to prevent multiple calls
const useDebouncedSave = (onSave: () => void, delay: number) => {
  const debouncedSaveRef = useRef(debounce(onSave, delay));

  const debouncedSave = useCallback(() => {
    debouncedSaveRef.current();
  }, []);

  return debouncedSave;
};

// Handle onKeyDown event for debounced onSave function
const useHandleKeyDown = (debouncedOnSave: () => void) => {
  return useCallback(() => {
    debouncedOnSave();
  }, [debouncedOnSave]);
};

const SetVariablesInput = ({ control, setValue, error, onSave }: Props) => {
  const { t } = useTranslation();
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const hasError = !isEmpty(error);
  const { variables: options } = useBrainVariables(false);
  const debouncedOnSave = useDebouncedSave(onSave, 500);
  const handleDebouncedKeyDown = useHandleKeyDown(debouncedOnSave);

  const {
    searchResults,
    setSearchResults,
    searchString,
    setSearchString,
    noResultSearchLength,
    setNoResultSearchLength,
    lastSearchStringLength,
    setLastSearchStringLength,
  } = useCommandPallete({ options });

  const updateValue = useCallback(
    (value: string) => {
      setValue('value', wrapDollarSignWithCurlyBraces(value));
      onSave();
    },
    [onSave, setValue]
  );

  return (
    <div className={styles.wrapper}>
      <Typography
        component="label"
        color="var(--text-default-gray)"
        variant="label-caps-large"
      >
        {t('dialog.set_variables.set_value.label')}
      </Typography>

      <div
        className={cn({
          [styles.inputWrapper]: true,
          [styles.hasError]: hasError,
        })}
      >
        <Controller
          name={'value'}
          control={control}
          render={({ field }) => (
            <CommandPalette<OptionsPopperProps>
              searchString={searchString}
              setSearchString={setSearchString}
              inputType="input"
              renderPalette={(props) => {
                return (
                  <OptionsPopper
                    {...props}
                    options={options}
                    searchResults={searchResults}
                    setSearchResults={setSearchResults}
                    noResultSearchLength={noResultSearchLength}
                    setNoResultSearchLength={setNoResultSearchLength}
                    lastSearchStringLength={lastSearchStringLength}
                    setLastSearchStringLength={setLastSearchStringLength}
                  />
                );
              }}
              optionsLength={searchResults.length}
              handleOnSelectFromProps={(value) => {
                const selectedValue = searchResults[value]?.value
                  ? searchResults[value].value
                  : '';

                updateValue(selectedValue);
              }}
              renderItem={(props) => {
                const { ref, onKeyDown } = props;

                return (
                  <TextareaAutosize
                    {...field}
                    ref={ref}
                    onKeyDown={(e) => {
                      onKeyDown(e);

                      handleDebouncedKeyDown();
                    }}
                    value={field.value as string}
                    minRows={2}
                    draggable={false}
                    placeholder={t(
                      'dialog.set_variables.set_value.placeholder'
                    )}
                    style={{ width: '100%', outline: 'none' }}
                  />
                );
              }}
            />
          )}
        />

        {!showAutoComplete && (
          <Button
            onClick={() => setShowAutoComplete(!showAutoComplete)}
            variant="secondary"
            size="xs"
            className={styles.btn}
          >
            {t('common.variables')}
          </Button>
        )}

        {showAutoComplete && (
          <span className={styles.autocompleteWrapper}>
            <AutoCompleteNew
              control={control}
              name={'selectedVariable'}
              size="xs"
              groupByProp="type"
              placeholder={t('common.variables')}
              options={options}
              onChange={(_, option) => {
                // if (!option) return;

                setValue(
                  'value',
                  wrapDollarSignWithCurlyBraces(option?.value),
                  { shouldValidate: true }
                );
                onSave();
              }}
              onClose={() => {
                setValue('selectedVariable', '');
                setShowAutoComplete(false);
              }}
              openOnFocus
              inputAutoFocus
              enableNewEntry={false}
              freeSolo
            />
          </span>
        )}
      </div>

      {hasError && (
        <Typography
          component="p"
          color="var(--text-default-error)"
          variant="label-regular"
        >
          {capitalizeFirstLetter(error.message)}
        </Typography>
      )}
    </div>
  );
};

export default SetVariablesInput;
