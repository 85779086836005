import { ChangeEvent, useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import ToolkitRuleNew from '@/components/organisms/Toolkit/ToolkitRule/ToolkitRule';
import ToolkitWrapper from '@/components/organisms/Toolkit/ToolkitWrapper';
import { Condition, Rule } from '@/models/node';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { generateRule } from '@/redux/dialogs/helper';
import {
  selectConditionRules,
  selectMatchOptions,
  selectSelectedCondition,
  selectSelectedNode,
} from '@/redux/dialogs/selectors';
import {
  addRule,
  removeAllRules,
  removeRule,
  updateCondition,
  updateRule,
} from '@/redux/nodes/actions';
import { capitalizeFirstLetter } from '@/util/util';
import { newConditionSchema } from '@/util/validator';

import styles from './ToolkitNodeCondition.module.scss';

type FormType = {
  name: string;
  match: Condition['match'];
};

const ToolkitNodeCondition = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rules = useSelector(selectConditionRules, shallowEqual);
  const { nodeId, conditionIndex, match, name, matchOptions, conditionId } =
    useSelector((state: RootState) => {
      const selectedNode = selectSelectedNode(state);
      const selectedMatchOptions = selectMatchOptions(state);
      const condition = selectSelectedCondition(state);

      return {
        nodeId: selectedNode.node_id,
        conditionIndex: state.nodes.selectedConditionIndex,
        match: condition.match,
        name: condition.name ?? '',
        matchOptions: selectedMatchOptions,
        conditionId: condition.condition_id,
      };
    }, shallowEqual);

  const {
    formState: { errors, isDirty },
    getValues,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: {
      name,
      match,
    },
    resolver: yupResolver(newConditionSchema) as Resolver<FormType>,
  });
  const nameErrorMessage = capitalizeFirstLetter(errors.name?.message);

  const watchValues = watch();

  // Set the form values when the selected condition changes
  // because the component is not unmounted
  useEffect(() => {
    setValue('name', name, {
      shouldValidate: true,
    });
    setValue('match', match, {
      shouldValidate: true,
    });
  }, [name, match, setValue]);

  const update = useCallback(() => {
    const values = getValues();
    dispatch(
      updateCondition({
        nodeId,
        index: conditionIndex,
        condition: { ...values },
      })
    );
  }, [dispatch, getValues, nodeId, conditionIndex]);

  const handleChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>) => {
      update();
    },
    [update]
  );

  const onAddRuleClick = useCallback(() => {
    dispatch(
      addRule({
        nodeId,
        conditionIndex,
        rule: generateRule(),
      })
    );
  }, [dispatch, nodeId, conditionIndex]);

  const onRuleChange = useCallback(
    (rule: Partial<Rule>, index: number) => {
      const newRule = {
        ...rules[index],
      };

      for (const [key, value] of Object.entries(rule)) {
        newRule[key] = value;
      }

      dispatch(
        updateRule({
          nodeId,
          conditionIndex,
          index,
          rule: { ...newRule },
        })
      );
    },
    [rules, dispatch, nodeId, conditionIndex]
  );

  const onRuleDelete = useCallback(
    (index: number) => {
      dispatch(
        removeRule({
          nodeId,
          conditionIndex,
          index,
        })
      );
    },
    [dispatch, nodeId, conditionIndex]
  );

  const handleMatchChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'else') {
        dispatch(
          removeAllRules({
            nodeId,
            conditionIndex,
          })
        );
      } else if (rules.length === 0) {
        // Add a rule when you switch from else to all or any option
        onAddRuleClick();
      }

      update();
    },
    [rules.length, update, dispatch, nodeId, conditionIndex, onAddRuleClick]
  );

  const renderRules = useCallback(() => {
    return rules.map((r, index) => (
      <ToolkitRuleNew
        key={r.rule_id}
        onChange={onRuleChange}
        onDelete={rules.length === 1 ? undefined : onRuleDelete}
        index={index}
        rule={r}
        nodeId={nodeId}
        conditionId={conditionId}
        conditionIndex={conditionIndex}
      />
    ));
  }, [conditionId, conditionIndex, nodeId, onRuleChange, onRuleDelete, rules]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    // Prevents from unnecessary redux state updates
    if (!isDirty) return;

    dispatch(
      updateDialogAlerts({
        dialogAlerts: {
          alertType: 'error',
          id: conditionId,
          title: t('actions.types.condition'),
          body: nameErrorMessage,
          type: 'condition',
          nodeId,
          index: conditionIndex,
        },
      })
    );
  }, [
    dispatch,
    conditionId,
    t,
    nameErrorMessage,
    nodeId,
    conditionIndex,
    isDirty,
  ]);

  return (
    <ToolkitWrapper type="condition">
      <Input
        register={register('name')}
        error={!!errors.name}
        errorMessage={nameErrorMessage}
        label={t('common.name')}
        onChange={handleChange}
        size="small"
      />

      <Select
        register={register('match')}
        defaultValue={match}
        label={t('dialog.condition.match_when')}
        placeholder={t('dialog.condition.type_match')}
        name="match"
        options={matchOptions}
        size="small-full"
        onChange={handleMatchChange}
      />

      {/*
        Hide the add rule button when match is set to 'else'
       */}
      {watchValues.match !== 'else' && (
        <button onClick={onAddRuleClick} className={styles.addRule}>
          <PlusCircle />
          <Typography color="var(--text-default-blue)" variant="body-semi-bold">
            {t('rules.add_rule')}
          </Typography>
        </button>
      )}

      {rules.length > 0 && <div>{renderRules()}</div>}
    </ToolkitWrapper>
  );
};

export default ToolkitNodeCondition;
