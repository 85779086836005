import Box from '@mui/material/Box';
import cn from 'classnames';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew';
import Select from '@/components/atoms/Select/Select';
import { OptionBase } from '@/models/common';
import { useSubscribers } from '@/modules/broadcast/hooks/useSubscribers';
import {
  selectBroadcast,
  selectContextVariableOptions,
  selectIsDraft,
} from '@/modules/broadcast/redux/selectors';
import { getContextVariableOptions } from '@/modules/broadcast/utils';
import { contextVariableWithBraces, urlPattern } from '@/util/validator';

import { Form, WhatsappTemplateComponent } from '../../../models';

import styles from './HeaderMediaSection.module.scss';

interface Props {
  isReadOnly: boolean;
  templateHeader: WhatsappTemplateComponent;
}

export const rules = (t) => ({
  id: {
    required: t('validation.required'),
    validate: (v: string) => {
      if (contextVariableWithBraces.test(v)) {
        return true;
      }
    },
  },
  link: {
    required: t('validation.required'),
    validate: (value: OptionBase | string) => {
      if (typeof value === 'string') {
        return urlPattern.test(value) || t('validation.invalid_url');
      }
      return (
        urlPattern.test(value.value) ||
        contextVariableWithBraces.test(value.value) ||
        t('validation.invalid_url')
      );
    },
  },
});

export const HeaderMediaSection = ({ isReadOnly }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState,
    register,
    watch,
    setValue,
    clearErrors,
    unregister,
  } = useFormContext<Form>();
  const { errors } = formState;

  const isDraft = useSelector(selectIsDraft);
  const broadcast = useSelector(selectBroadcast);
  const { subscribers } = useSubscribers(broadcast.broadcast_id);
  const draftSubscribersOptions = useSelector(selectContextVariableOptions);
  const storedSubscribersOptions = getContextVariableOptions(
    subscribers?.pages?.[0].subscribers
  );
  const options = isDraft ? draftSubscribersOptions : storedSubscribersOptions;

  const selectOptions = [
    {
      label: t('common.link'),
      value: 'link',
    },
    {
      label: 'WhatsApp ID',
      value: 'id',
    },
  ];

  const mediaType = watch('headerMediaParam.type');

  // Dynamically update the rules and value based on the mediaType
  const handleTypeChange = () => {
    setValue('headerMediaParam.value', null);
    clearErrors('headerMediaParam.value'); // Clear any errors
    unregister('headerMediaParam.value'); // Unregister the field to avoid persisting the old validation
  };

  return (
    <Box mb={2}>
      <div className={cn(styles.buttonsContainer, styles.headerType)}>
        <Select
          options={selectOptions}
          placeholder={t('common.select')}
          enablePlaceholder
          disabled={isReadOnly}
          label={t('home.content')}
          register={register('headerMediaParam.type', {
            required: t('validation.required'),
          })}
          onChange={handleTypeChange}
          error={!!errors.headerMediaParam?.type}
          errorMessage={(errors.headerMediaParam?.type as FieldError)?.message}
        />
        <div className={styles.buttonContainer}>
          <AutocompleteNew
            key={mediaType} // Forces the component to re-render with the new type
            groupByProp="type"
            control={control}
            options={options}
            name="headerMediaParam.value"
            label={t('common.type')}
            size="medium"
            placeholder="URL, ID or select a variable"
            rules={{ ...rules(t)[mediaType] }} // Apply dynamic rules based on mediaType
            hasError={!!errors.headerMediaParam?.value}
            errorMessage={errors.headerMediaParam?.value?.message}
            disabled={isReadOnly || !mediaType}
          />
        </div>
      </div>
    </Box>
  );
};
