import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import useUser from '@/hooks/useUser';
import { submitWithTrimming } from '@/util/util';
import { LENGTH_S, createInputSchema } from '@/util/validator';

type Form = {
  name: string;
};

const NameFieldset = () => {
  const { t } = useTranslation();
  const { user, updateUser, updateStatus } = useUser();
  const formMethods = useForm<Form>({
    mode: 'onChange',
  });
  const {
    register,
    formState: { errors, isDirty },
    reset,
  } = formMethods;
  const isLoading = user === undefined;

  useEffect(() => {
    if (user?.name) {
      reset({ name: user.name });
    }
  }, [user, user?.name, reset]);

  const onSubmit = useCallback(
    ({ name }: Form) => updateUser({ name }),
    [updateUser]
  );

  return (
    <FormFieldset
      title={t('profile.name.title')}
      subtitle={t('profile.name.subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateStatus === 'pending',
      }}
      onSubmit={submitWithTrimming(formMethods, onSubmit)}
      isLoading={isLoading}
      helpText={t('validation.less_than', { 0: 64 })}
    >
      <Input
        name="name"
        register={register('name', createInputSchema(LENGTH_S))}
        errorMessage={errors.name?.message}
        size="large"
      />
    </FormFieldset>
  );
};

export default NameFieldset;
