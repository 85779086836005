export const NoMessagesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="120"
      fill="none"
      viewBox="0 0 121 120"
    >
      <path
        stroke="#8FCCFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M105.5 75a10.002 10.002 0 0 1-10 10h-60l-20 20V25a10 10 0 0 1 10-10h70a10 10 0 0 1 10 10v50Z"
      />
    </svg>
  );
};
