/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';

import {
  AccountAnalytics,
  Analytics as AnalyticsState,
  AnalyticsType,
  LogsAnalytics,
} from '@/modules/analytics/models';

export interface PeriodProps {
  startDate: string | Moment;
  endDate: string | Moment;
}

export interface ApplyFiltersPayload {
  filters: AccountAnalytics[] | LogsAnalytics[];
  type: AnalyticsType;
}

export const defaultState: AnalyticsState = {
  startDate: moment().startOf('day').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  lastSeen: [],
  logs: [],
  accountAnalytics: [],
  filtersLoaded: false,
};
const resetAllFilters = (state: AnalyticsState) => {
  state.startDate = defaultState.startDate;
  state.endDate = defaultState.endDate;
  state.logs = defaultState.logs;
  state.accountAnalytics = defaultState.accountAnalytics;
  state.lastSeen = defaultState.lastSeen;
};
const slice = createSlice({
  name: 'analytics',
  initialState: defaultState,
  reducers: {
    setPeriod: (state, action: PayloadAction<PeriodProps>) => {
      const { startDate, endDate } = action.payload ?? {};

      if (moment.isMoment(startDate)) {
        state.startDate = startDate.format('YYYY-MM-DD');
      } else {
        state.startDate = startDate;
      }
      if (moment.isMoment(endDate)) {
        state.endDate = endDate.format('YYYY-MM-DD');
      } else {
        state.endDate = endDate;
      }
    },

    applyFilters: (state, action: PayloadAction<ApplyFiltersPayload>) => {
      const filters = action.payload.filters;
      const type = action.payload.type;
      if (type === AnalyticsType.LOGS) {
        state.logs = filters as AnalyticsState['logs'];
      } else if (type === AnalyticsType.ACCOUNT) {
        state.accountAnalytics = filters as AccountAnalytics[];
      }
      state.filtersLoaded = true;
    },

    addLastSeen: (state, action: PayloadAction<string>) => {
      const sessionId = action.payload;
      if (state.lastSeen.includes(sessionId)) {
        state.lastSeen.push(
          state.lastSeen.splice(state.lastSeen.indexOf(sessionId), 1)[0]
        );
      } else {
        state.lastSeen.push(sessionId);
      }
    },

    resetFilters: (state) => {
      resetAllFilters(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase('session/setAccountSlug', (state) => {
      resetAllFilters(state);
    });
  },
});

// Extract and export each action creator by name
export const { setPeriod, applyFilters, addLastSeen, resetFilters } =
  slice.actions;

// Export the reducer, either as a default or named export
export default slice.reducer;
