import { RequireAtLeastOne } from 'type-fest';

import { AgentActivity } from './rtm';

export enum PresenceStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away',
}

export interface Presence {
  agent_id: string;
  name: string;
  avatar: string;
  email: string;
  is_connected: boolean;
  last_activity_at: string | null;
  manual_status: PresenceStatus;
  status: PresenceStatus;
}

export type ListPresence = { users: Presence[] };

export type PartialPresence = RequireAtLeastOne<Partial<Presence>, 'agent_id'>;

type PresenceStateKeys =
  | 'brains'
  | 'collections'
  | 'datasources'
  | 'desks'
  | 'sessions';

type ResourceType = Extract<keyof PresenceState, PresenceStateKeys>;

export interface ViewerPayload {
  type: ResourceType;
  id: string;
  agents: AgentActivity[];
}

export interface AllViewersPayload {
  type: ResourceType;
  resources: Record<string, AgentActivity[]>;
}

export interface RemoveViewerPayload {
  type: ResourceType;
  id: string;
  user_id: string;
}

// Define the initial state shape
export interface PresenceState {
  brains: Record<string, AgentActivity[]>;
  collections: Record<string, AgentActivity[]>;
  datasources: Record<string, AgentActivity[]>;
  desks: Record<string, AgentActivity[]>;
  sessions: Record<string, AgentActivity[]>;

  // Measure the agents and their status
  agents: Presence[];
}
