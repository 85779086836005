import './Toolkit.scss';

import isNil from 'lodash/isNil';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '@/models/state';
import { NO_REQUISITE_INDEX, TYPES } from '@/redux/dialogs/helper';

import ToolkitEmpty from './ToolkitEmpty';
import ToolkitEmail from './ToolkitExtensions/ToolkitEmail/ToolkitEmail';
import ToolkitGoogleSheet from './ToolkitExtensions/ToolkitGoogleSheet/ToolkitGoogleSheet';
import ToolkitWebhook from './ToolkitExtensions/ToolkitWebhook/ToolkitWebhook';
import ToolkitMenu from './ToolkitMenu/ToolkitMenu';
import ToolkitActionClose from './ToolkitOperations/ToolkitActionClose/ToolkitActionClose';
import ToolkitActionHandover from './ToolkitOperations/ToolkitActionHandover/ToolkitActionHandover';
import ToolkitActionPause from './ToolkitOperations/ToolkitActionPause/ToolkitActionPause';
import ToolkitActionQuestion from './ToolkitOperations/ToolkitActionQuestion/ToolkitActionQuestion';
import ToolkitActionReplay from './ToolkitOperations/ToolkitActionReplay/ToolkitActionReplay';
import ToolkitActionReset from './ToolkitOperations/ToolkitActionReset/ToolkitActionReset';
import ToolkitActionTag from './ToolkitOperations/ToolkitActionTag/ToolkitActionTag';
import ToolkitJumpTo from './ToolkitOperations/ToolkitJumpTo/ToolkitJumpTo';
import ToolkitNodeCondition from './ToolkitOperations/ToolkitNodeCondition/ToolkitNodeCondition';
import ToolkitSetVariables from './ToolkitOperations/ToolkitSetVariables/ToolkitSetVariables';
import ToolkitActionCarousel from './ToolkitResponses/ToolkitActionCarousel/ToolkitActionCarousel';
import ToolkitActionMedia from './ToolkitResponses/ToolkitActionMedia';
import ToolkitActionSurvey from './ToolkitResponses/ToolkitActionSurvey/ToolkitActionSurvey';
import ToolkitActionText from './ToolkitResponses/ToolkitActionText/ToolkitActionText';
import ToolkitActionUrl from './ToolkitResponses/ToolkitActionUrl/ToolkitActionUrl';
import ToolkitActionWebview from './ToolkitResponses/ToolkitActionWebview/ToolkitActionWebview';
import ToolkitNodeEvent from './ToolkitStarters/ToolkitNodeEvent/ToolkitNodeEvent';
import ToolkitNodeIntent from './ToolkitStarters/ToolkitNodeIntent/ToolkitNodeIntent';
import ToolkitNodeUnknown from './ToolkitStarters/ToolkitNodeUnknown';

function renderNodeToolkit(nodeId: string, type: string) {
  switch (type) {
    case 'intent':
      return <ToolkitNodeIntent key={nodeId} />;
    case 'event':
      return <ToolkitNodeEvent key={nodeId} />;
    case 'unknown':
      return <ToolkitNodeUnknown key={nodeId} />;
    case 'condition':
      return <ToolkitNodeCondition key={nodeId} />;
    default:
      return <ToolkitEmpty type={type} key={nodeId} />;
  }
}

function renderActionToolkit(id: string, type: string) {
  switch (type) {
    case 'event':
      return <ToolkitJumpTo key={id} />;
    case 'text':
      return <ToolkitActionText key={id} />;
    case 'url':
      return <ToolkitActionUrl key={id} />;
    case 'webview':
      return <ToolkitActionWebview key={id} />;
    case 'webhook':
      return <ToolkitWebhook key={id} />;
    case 'image':
    case 'video':
    case 'file':
      return <ToolkitActionMedia key={id} />;
    case 'pause':
      return <ToolkitActionPause key={id} />;
    case 'reset':
      return <ToolkitActionReset key={id} />;
    case 'question':
      return <ToolkitActionQuestion key={id} />;
    case 'handover':
      return <ToolkitActionHandover key={id} />;
    case 'carousel':
      return <ToolkitActionCarousel key={id} />;
    case 'tag':
      return <ToolkitActionTag key={id} />;
    case 'email':
      return <ToolkitEmail key={id} />;
    case 'googlesheet':
      return <ToolkitGoogleSheet key={id} />;
    case 'close':
      return <ToolkitActionClose key={id} />;
    case 'survey':
      return <ToolkitActionSurvey key={id} />;
    case 'replay':
      return <ToolkitActionReplay key={id} />;
    case 'set_variables':
      return <ToolkitSetVariables key={id} />;
    default:
      return <ToolkitEmpty type={type} key={id} />;
  }
}

function Toolkit() {
  const { nodeId, actionId, type, requisiteIndex, requisiteId } = useSelector(
    (state: RootState) => {
      return {
        nodeId: state.nodes.selectedNodeId,
        actionId: state.nodes.selectedActionId,
        requisiteIndex: state.nodes.selectedRequisiteIndex,
        requisiteId: state.nodes.selectedRequisiteId,

        type:
          state.nodes.selectedRequisiteIndex === NO_REQUISITE_INDEX
            ? TYPES.NOREQUISITE
            : state.nodes.selectedType,
      };
    },
    shallowEqual
  );

  if (nodeId && !isNil(requisiteIndex)) {
    return renderActionToolkit(requisiteId, 'question');
  }
  if (nodeId) {
    return renderNodeToolkit(nodeId, type);
  }
  if (actionId) {
    return renderActionToolkit(actionId, type);
  }

  return <ToolkitMenu />;
}

export default Toolkit;
