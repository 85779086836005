import { Icon } from '@/models/icon';

const Info = ({
  size = 16,
  color = 'var(--icon-default-white)',
  backgroundColor = 'none',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width={size}
      height={size}
    >
      <circle cx="8" cy="8" r="7" fill={backgroundColor} />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.4"
        d="M7.967 10.633V7.367"
      />
      <circle cx="8.033" cy="5.367" r=".7" fill={color} />
    </svg>
  );
};

export default Info;
