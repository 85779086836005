import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ConnectionStatus from '@/components/atoms/Accordion/templates/ConnectionStatusAccordion';
import ActiveFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/ActiveFieldset';
import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import { useAccordion } from '@/hooks/useAccordion';
import { useWhatsapp } from '@/hooks/useWhatsapp';
import { AccordionType, WhatsAppIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import WhatsappConnect from './Accordion/WhatsappConnect/WhatsappConnect';
import WhatsappPreferenceSettings from './Accordion/WhatsappPreferenceSettings/WhatsappPreferenceSettings';
import WhatsappTesting from './Accordion/WhatsappTesting/WhatsappTesting';
import ReAuthFieldset from './ReAuthFieldset';
import WhatsappInfo from './WhatsappInfo/WhatsappInfo';

import styles from './Settings.module.scss';

interface Props {
  isManual: boolean;
}

const Settings = ({ isManual }: Props) => {
  const { t } = useTranslation();
  const { toggleAccordion, integration, accordions, registerAccordion } =
    useAccordion<WhatsAppIntegration>();
  const stateRef = useRef(null);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const { isConfigured, uri, shouldReauthorise, onOAuthClick, isConnecting } =
    useWhatsapp();

  return (
    <div className={styles.container}>
      <div>
        <WhatsappTesting
          integration={integration}
          toggleAccordion={toggleAccordion}
          type={AccordionType.WHATSAPP_TESTING}
          registerAccordion={registerAccordion}
        />
        {shouldReauthorise ? (
          <ReAuthFieldset
            onOAuthClick={onOAuthClick}
            isConnecting={isConnecting}
          />
        ) : (
          <ConnectionStatus
            status={integration?.status}
            conSettingsRef={
              accordions?.current[AccordionType.CONNECTION_SETTINGS]?.ref
            }
            stateRef={stateRef.current}
            toggleAccordion={toggleAccordion}
            type={AccordionType.VERIFICATION_STATUS}
            registerAccordion={registerAccordion}
          />
        )}
        {isManual && (
          <WhatsappConnect
            integration={integration}
            toggleAccordion={toggleAccordion}
            type={AccordionType.CONNECTION_SETTINGS}
            registerAccordion={registerAccordion}
          />
        )}
        <WhatsappPreferenceSettings
          integration={integration}
          toggleAccordion={toggleAccordion}
          type={AccordionType.WHATSAPP_PREFERENCE}
          registerAccordion={registerAccordion}
        />
        <div ref={stateRef}>
          <ActiveFieldset
            integration={integration}
            title={t('integrations.status.title')}
            subtitle={t('integrations.status.subtitle')}
          />
        </div>
        <DeleteFieldset integration={integration} disabled={!canWrite} />
      </div>
      {isConfigured && uri && (
        <div className={styles.infoContainer}>
          <WhatsappInfo />
        </div>
      )}
    </div>
  );
};

export default Settings;
