import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Changes, DiffType } from './Changes';

import styles from './ModalPublishVersion.module.scss';

export const VersionChanges = ({
  versionsDiff,
  loadingChanges,
  padding = true,
}) => {
  const { t } = useTranslation();

  if (loadingChanges)
    return [...Array(2)].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Skeleton key={index} height={20} />
    ));

  return (
    <ul className={cn(styles.changesWrapper, { [styles.padding]: padding })}>
      {versionsDiff.length > 0 ? (
        versionsDiff.map((version) => {
          if (version.component === 'users') {
            const allUsers = [
              ...version.added,
              ...version.modified,
              ...version.removed,
            ];
            return (
              <Changes
                key={'users-all'}
                component={version.component}
                type={'all' as DiffType}
                changes={allUsers}
              />
            );
          } else {
            const relevantKeys = Object.keys(version).filter(
              (key) => key !== 'component' && version[key].length
            );

            if (relevantKeys.length === 0) return null;

            return (
              <>
                {relevantKeys.map((key) => (
                  <Changes
                    key={key.concat(version.component)}
                    component={version.component}
                    type={key as DiffType}
                    changes={version[key]}
                  />
                ))}
              </>
            );
          }
        })
      ) : (
        <Typography color="var(--text-default-gray)">
          {t('version.no_changes')}
        </Typography>
      )}
    </ul>
  );
};
