import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';

import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import dotize from 'dotize';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import set from 'lodash/set';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import IconButton from '@/components/atoms/IconButton/IconButton';
import MoreHorizontal from '@/components/atoms/Icons/MoreHorizontal';
import Trash from '@/components/atoms/Icons/Trash';
import Input from '@/components/atoms/Input/Input';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { removeEmptyObjects } from '@/util/util';

import { userContextVariables } from './constants';
import { customInputValueRender } from './helper';
import { useVariablesSection } from './useVariablesSection';
import { LockIcon, UnlockIcon } from '../../Icons/LockIcon';
import { ToggleIcon } from '../../Icons/ToggleIcon';
import { updateContext } from '../../redux/actions';
import { selectContext } from '../../redux/selectors';
import { StateContextVariable } from '../../types';

import styles from './VariablesSection.module.scss';

const CUSTOM_AUTOCOMPLETE_SX = {
  '&.MuiAutocomplete-root': {
    '& .MuiInputBase-root': {
      border: 'none',
    },
  },
};

type ContextVariableProps = {
  variable: StateContextVariable;
  canEdit: boolean;
  onButtonClick?: () => void;
  isLast?: boolean;
};

export const ContextVariable = ({
  variable,
  canEdit,
  onButtonClick = undefined,
  isLast = false,
}: ContextVariableProps) => {
  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getVariableOption } = useVariablesSection();

  // Redux
  const storeContext = useSelector(selectContext);

  // Local state
  const [isIconHovered, setIsIconHovered] = useState(false);

  // Refs
  const inputRef = useRef(null);

  const variableOption = useMemo(
    () => getVariableOption(variable),
    [getVariableOption, variable]
  );

  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      variableKey: variableOption ?? null,
      variableValue: customInputValueRender(variable.value),
    },
  });

  // Local state
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Local variables
  const open = Boolean(anchorEl);
  const menuId = isLast ? 'context-variable-menu' : `${variable.label}-menu`;
  const buttonId = isLast
    ? 'context-variable-button'
    : `${variable.label}-button`;
  const iconProps = {
    size: 18,
    color: isIconHovered ? 'var(--icon-default-blue)' : undefined,
  };
  const showLockIcon = variable.isLocked;
  const canDeleteVariable = !variable.isLocked && !isLast && canEdit;
  const isInputDisabled = variable.isLocked && canEdit;
  const isKeyInputFilled = !!getValues('variableKey.value');

  const autocompleteOptions = useMemo(
    () =>
      userContextVariables?.map((variable) => ({
        value: variable.label,
        label: t(`auto_complete.variable_labels.${variable.label}`),
        type: t('analytics.fields.user'),
      })),
    [t]
  );

  // This code is executed when the variableOption changes
  useEffect(() => {
    setValue('variableKey', variableOption ?? null);
    setValue('variableValue', customInputValueRender(variable.value));
  }, [variableOption, variable.value, setValue, variable]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (variable.value === null && !isLast) {
    return null;
  }

  const deleteContextVariable = () => {
    const oldContext = cloneDeep(storeContext).slice(0, -1) ?? [];
    const newContext = cloneDeep(storeContext[storeContext.length - 1]) ?? {};

    set(newContext, variable.label, null);

    dispatch(updateContext([...oldContext, removeEmptyObjects(newContext)]));
  };

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  const clearInputFields = () => {
    setValue('variableKey', {
      value: null,
      label: null,
    });
    setValue('variableValue', '');
  };

  const handleBlur = () => {
    if (!canEdit) return;

    const value = getValues('variableKey.value');
    const variableValue = getValues('variableValue');

    // Prevents updating when one of the inputs is empty or value is not changed
    if (!value || !variableValue || variable.value === variableValue) return;

    const oldContext = cloneDeep(storeContext).slice(0, -1) ?? [];
    const newContext = cloneDeep(storeContext[storeContext.length - 1]) ?? {};

    merge(newContext, dotize.backward({ [value]: variableValue }));
    clearInputFields();

    if (isLast) {
      handleFocus();
    }

    dispatch(updateContext([...oldContext, newContext]));
  };

  return (
    <div className={styles.wrapper}>
      {canEdit && (
        <IconButton
          ariaLabel={t('try_it.modal.toggle_lock')}
          onClick={onButtonClick}
          onMouseOver={() => {
            setIsIconHovered(true);
          }}
          onMouseLeave={() => {
            setIsIconHovered(false);
          }}
          className={cn({
            [styles.hidden]: isLast,
          })}
        >
          {showLockIcon ? (
            <LockIcon {...iconProps} />
          ) : (
            <UnlockIcon {...iconProps} />
          )}
        </IconButton>
      )}
      <div
        className={cn({
          [styles.inputsWrapper]: true,
          [styles.fullWidth]: !isCollapsed,
        })}
      >
        <span
          className={cn({
            [styles.key]: true,
            [styles['key--cannotEdit']]: !canEdit,
            [styles['key--fullWidth']]: !isCollapsed,
            [styles.isFilled]: isKeyInputFilled && canEdit,
          })}
          data-testid="key-input-wrapper"
        >
          {canEdit ? (
            <AutocompleteNew
              disabled={isInputDisabled}
              inputRef={inputRef}
              readOnly={!canEdit || isKeyInputFilled}
              disableClearable
              clearOnBlur
              enableNewEntry
              freeSolo
              control={control}
              options={autocompleteOptions}
              name="variableKey"
              id="variableKey"
              groupByProp="type"
              groupByLabelProp={false}
              placeholder={t('dialog.set_variables.variable_name.placeholder')}
              isOptionEqualToValue={(option, value) => option?.label === value}
              getOptionLabel={(option) => option?.label ?? ''}
              width={222}
              size="xxs"
              sx={CUSTOM_AUTOCOMPLETE_SX}
            />
          ) : (
            <Input
              readOnly={!canEdit}
              size="xs"
              name="variableKey.label"
              register={register('variableKey.label')}
            />
          )}
        </span>
        <Tooltip
          arrow
          placement="left-start"
          disableHoverListener={canEdit || !isCollapsed}
          disableFocusListener={canEdit || !isCollapsed}
          title={getValues('variableValue')}
          PopperComponent={(props) => (
            <Popper
              {...props}
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10], // Adjust the second value to move the arrow vertically
                  },
                },
              ]}
            />
          )}
        >
          <span
            className={cn({
              [styles.value]: true,
              [styles['value--fullWidth']]: !isCollapsed,
            })}
            data-testid="value-input-wrapper"
          >
            {isCollapsed ? (
              <Input
                disabled={isInputDisabled}
                readOnly={!canEdit}
                size="xs"
                name="variableValue"
                onBlur={handleBlur}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleBlur();
                  }
                }}
                register={register('variableValue')}
                placeholder={t('common.type_value')}
                error={!!errors?.variableValue?.message}
              />
            ) : (
              <TextareaAutosize
                disabled={isInputDisabled}
                readOnly={!canEdit}
                className={styles.textarea}
                placeholder={t('common.type_value')}
                minRows={4}
                maxRows={6}
                {...register('variableValue')}
              />
            )}
          </span>
        </Tooltip>
      </div>

      <IconButton
        ariaLabel={t('common.settings')}
        ariaControls={open ? menuId : undefined}
        ariaHasPopUp
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        className={cn({
          [styles.hidden]: isLast,
        })}
        id={buttonId}
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreHorizontal size={18} />
      </IconButton>

      {!!anchorEl && (
        <CustomMenu
          id={menuId}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableRestoreFocus
          MenuListProps={{
            'aria-labelledby': buttonId,
          }}
        >
          <MenuItem
            onClick={() => {
              setIsCollapsed((prev) => !prev);
              setAnchorEl(null);
            }}
            disableGutters
          >
            <ToggleIcon />
            {isCollapsed ? t('common.expand') : t('common.collapse')}
          </MenuItem>

          <MenuItem
            onClick={deleteContextVariable}
            disableGutters
            className={styles.danger}
            disabled={!canDeleteVariable}
          >
            <Trash />
            {t('common.delete')}
          </MenuItem>
        </CustomMenu>
      )}
    </div>
  );
};
