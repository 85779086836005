import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import useDesks from '@/hooks/useDesks';
import { useBundles } from '@/modules/bundles/hooks/useBundles';

import { ContextBundle } from '../components/BundleSection/BundleSection';
import { selectContext } from '../redux/selectors';

export const useBundleSection = (bundle: ContextBundle) => {
  const { desks } = useDesks();
  const isBundleEmpty = bundle === null;

  const context = useSelector(selectContext);

  // We use `undefined` deskId in order to not fetch bundles, using react-query
  const { getAllBundlesFromDeskIds } = useBundles(undefined);
  const [bundles, setBundles] = useState([]);

  const selectedDeskId = desks?.find(
    (desk) => desk.name === bundle?.desk
  )?.desk_id;
  const selectedBundleId = bundles?.find(
    (b) => b.name === bundle?.bundle
  )?.bundle_id;

  const autocompleteOptions = useMemo(() => {
    return bundles.map((bundle) => {
      const correspondingDesk = desks.find(
        (desk) => desk.desk_id === bundle.desk_id
      );
      return {
        label: bundle.name,
        value: bundle.bundle_id,
        desk_name: correspondingDesk?.name || null,
      };
    });
  }, [bundles, desks]);

  useEffect(() => {
    if (desks?.length) {
      getAllBundlesFromDeskIds(desks.map((desk) => desk.desk_id)).then(
        setBundles
      );
    }
  }, [desks, getAllBundlesFromDeskIds]);

  return {
    context,
    isBundleEmpty,
    bundles,
    selectedDeskId,
    selectedBundleId,
    autocompleteOptions,
  };
};
