import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { SocketContext } from '@/contexts/rtm';
import { ServerToClientEvents, SocketEvent } from '@/models/rtm';

export const useRtmTyping = () => {
  const [isTyping, setIsTyping] = useState(false);
  const { socket } = useContext(SocketContext);
  const myTimeout = useRef<NodeJS.Timeout | null>(null); // New line

  const handleStopTyping = () => {
    setIsTyping(false);
  };

  const handleMessageCompose: ServerToClientEvents[SocketEvent.messages_compose] =
    useCallback(({ data }) => {
      if (data.author_type !== 'agent') {
        if (data.action === 'start') {
          setIsTyping(true);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          clearTimeout(myTimeout.current); // Modified line
          myTimeout.current = setTimeout(handleStopTyping, 5000); // Modified line
        } else {
          setIsTyping(false);
          clearTimeout(myTimeout.current);
        }
      }
    }, []);

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    socket?.on(SocketEvent.messages_compose, handleMessageCompose);
    return () => {
      socket?.off(SocketEvent.messages_compose, handleMessageCompose);
    };
  }, [handleMessageCompose, socket]);

  return isTyping;
};
