import { useRef, memo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import Edit from '@/components/atoms/Icons/Edit';
import Export from '@/components/atoms/Icons/Export';
import Import from '@/components/atoms/Icons/Import';
import SaveIcon from '@/components/atoms/Icons/Save';
import Trash from '@/components/atoms/Icons/Trash';
import Spinner from '@/components/atoms/Spinner/Spinner';
import useAlertSummary from '@/hooks/useAlertSummary';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { selectDialogAlerts } from '@/redux/dialogAlerts/selectors';
import { getPermissions } from '@/redux/permissions/selectors';

import AlertSummary from '../ErrorSummary/AlertSummary';
import TileMenu from '../Tile/TileMenu';

import styles from './SubHeader.module.scss';

export type SubHeaderProps<T> = {
  onDeleteClick: () => void;
  onExportClick: () => void;
  onImportClick: (e) => void;
  onSaveClick: () => void;
  onNameChange: (text: string) => void;
  onEditClick: () => void;
  disableSave: boolean;
  disableRename?: boolean;
  disableDelete?: boolean;
  title: string;
  isLoading?: boolean;
  fileExtension?: string;
  variant?: 'default' | 'intents';
  editableTextValidationSchema?: T;
  customSanitizer?: (value: string) => string;
  type?: string;
};

const SubHeader = <T,>({
  onDeleteClick,
  onExportClick,
  onImportClick,
  onSaveClick,
  onNameChange,
  onEditClick,
  disableSave,
  disableRename,
  disableDelete = false,
  title,
  isLoading = false,
  fileExtension,
  variant = 'default',
  editableTextValidationSchema,
  customSanitizer,
  type,
}: SubHeaderProps<T>) => {
  const inputRef = useRef(null);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const { t } = useTranslation();
  const { setSelectedDialogAlert } = useAlertSummary();
  const dialogAlerts = useSelector(selectDialogAlerts);
  const dialogErrors =
    dialogAlerts?.filter((alert) => alert?.alertType === 'error') || [];
  const dialogWarnings =
    dialogAlerts?.filter((alert) => alert?.alertType === 'warning') || [];

  const getMenuItems = (disabled = false) => [
    {
      name: t('common.rename'),
      icon: <Edit size={16} />,
      onClick: onEditClick,
    },
    {
      name: t('common.export'),
      icon: <Export size={16} />,
      onClick: onExportClick,
    },
    {
      name: t('common.import'),
      icon: <Import size={16} />,
      onClick: () => {
        if (canWrite) {
          inputRef.current.click();
        }
      },
    },
    {
      name: t('common.delete'),
      icon: <Trash size={16} />,
      onClick: onDeleteClick,
      type: 'delete',
      disabled,
    },
  ];

  return (
    <div
      className={cn({
        [styles.header]: true,
        [styles[`header--${variant}`]]: true,
      })}
    >
      <div className={styles.information}>
        {!title ? (
          <Skeleton height={15} width={100} />
        ) : (
          <>
            <EditableText<typeof editableTextValidationSchema>
              defaultValue={title}
              variant="subheading-semi-bold"
              canEdit={canWrite && !disableRename}
              onSubmit={onNameChange}
              validationSchema={editableTextValidationSchema}
              customSanitizer={customSanitizer}
            />

            {type === 'dialog' && (
              <>
                {dialogErrors.length > 0 && (
                  <AlertSummary
                    alertType="error"
                    items={dialogErrors}
                    selectAlertAction={setSelectedDialogAlert}
                  />
                )}
                {dialogWarnings.length > 0 && (
                  <AlertSummary
                    alertType="warning"
                    items={dialogWarnings}
                    selectAlertAction={setSelectedDialogAlert}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className={styles.actions}>
        <TileMenu
          menuItems={getMenuItems(disableRename || disableDelete)}
          direction="horizontal"
        />
        <div
          className={cn(styles.saveButton, {
            [styles.disabled]: disableSave,
          })}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              onClick={onSaveClick}
              aria-label="save"
              disabled={disableSave}
            >
              <SaveIcon
                color={
                  disableSave
                    ? 'var(--icon-disabled-gray)'
                    : 'var(--icon-default-white)'
                }
              />
            </Button>
          )}
        </div>
      </div>
      <input
        className={styles.hiddenInput}
        accept={fileExtension}
        onChange={onImportClick}
        ref={inputRef}
        type="file"
      />
    </div>
  );
};

const MemoizedSubHeader = memo(SubHeader) as typeof SubHeader;

export default MemoizedSubHeader;
