import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useParams } from 'react-router';

import BusinessHour from '@/components/pages/BusinessHours/BusinessHour';
import Desks from '@/components/pages/Desks/Desks';
import EnvironmentRouter from '@/components/pages/Desks/EnvironmentRouter';
import IntegrationRouter from '@/components/pages/Integrations/IntegrationRouter';
import { useAccount } from '@/hooks/useAccount';
import useDesks from '@/hooks/useDesks';
import Bundle from '@/modules/bundles/pages/Bundle/Bundle';
import RulesRouter from '@/modules/rules/pages/Rules/RulesRouter';
import { setDeskId } from '@/redux/session/actions';
import { selectDeskId } from '@/redux/session/selectors';

const DesksRouter: React.FC = () => {
  const { slug } = useAccount();
  const { listStatus } = useDesks();
  const { slug: urlSlug } = useParams();

  const { desks } = useDesks();
  const dispatch = useDispatch();
  const deskId = useSelector(selectDeskId);

  useEffect(() => {
    if (!deskId && !isEmpty(desks)) {
      dispatch(setDeskId(desks[0]?.desk_id));
    }
  }, [deskId, desks, dispatch]);

  if (slug !== urlSlug || listStatus !== 'success') {
    return null;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Desks />} />
        <Route path="/:deskId/*" element={<EnvironmentRouter />} />
        <Route path="/:deskId/integrations/*" element={<IntegrationRouter />} />
        <Route path="/:deskId/rules/*" element={<RulesRouter />} />
        <Route path="/:deskId/context_bundles/:bundleId" element={<Bundle />} />
        <Route
          path="/:deskId/business_hours/:businessHourId"
          element={<BusinessHour />}
        />
      </Routes>
    </>
  );
};
DesksRouter.displayName = 'DesksRouter';

export default DesksRouter;
