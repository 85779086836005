import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { closeBanner, openBanner } from '@/redux/banners/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { CONTACT_SALES_URL } from '@/util/constants';

import { useBillingState } from './useBillingState';

export const useBillingBanner = () => {
  const { t } = useTranslation();
  const accountSlug = useSelector(selectAccountSlug);
  const dispatch = useDispatch();
  let variant: 'urgent' | 'improvement' = 'urgent';
  let hasIcon = false;

  const canReadBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.READ)
  );
  const billingState = useBillingState();

  useDeepCompareEffect(() => {
    if (billingState === undefined) {
      return undefined;
    }
    let children: React.ReactNode | undefined;
    switch (billingState?.plan_type) {
      case 'trial':
        if (billingState.has_expired) {
          hasIcon = true;
          children = (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {t('billing.your_trial_expired')}{' '}
              {canReadBilling && (
                <Link
                  to={`/${accountSlug}/account/billing`}
                  style={{ marginLeft: 'var(--space-4)' }}
                >
                  {t('billing.update_your_plan')}
                </Link>
              )}
            </span>
          );
        } else if (billingState.is_expiring) {
          children = (
            <>
              {t('billing.days_left', { 0: billingState.days_left })}{' '}
              {canReadBilling && (
                <Link to={`/${accountSlug}/account/billing`}>
                  {t('billing.update_your_plan')}
                </Link>
              )}
            </>
          );
        }
        break;
      case 'custom':
        if (billingState.has_expired) {
          hasIcon = true;
          children = (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {t('billing.your_plan_expired')}{' '}
              {canReadBilling && (
                <Link
                  to={CONTACT_SALES_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: 'var(--space-4)' }}
                >
                  {t('billing.contact_sales')}
                </Link>
              )}
            </span>
          );
        } else if (billingState.is_expiring) {
          children = (
            <>
              {t('billing.days_plan_left', { 0: billingState.days_left })}{' '}
              {canReadBilling && (
                <Link
                  to={CONTACT_SALES_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('billing.contact_sales')}
                </Link>
              )}
            </>
          );
        }
        break;
      case 'stripe':
        if (billingState.is_canceled) {
          if (billingState.has_expired) {
            children = (
              <>
                {t('billing.account_expired', { 0: billingState.plan_name })}{' '}
                {canReadBilling ? (
                  <Link to={`/${accountSlug}/account/billing`}>
                    {t('billing.resume_plan')}
                  </Link>
                ) : (
                  t('billing.contact_admin')
                )}
              </>
            );
          } else if (billingState.is_expiring) {
            children = (
              <>
                {t('billing.account_days_left', {
                  0: billingState.days_left,
                  1: billingState.plan_name,
                })}{' '}
                {canReadBilling ? (
                  <Link to={`/${accountSlug}/account/billing`}>
                    {t('billing.resume_plan')}
                  </Link>
                ) : (
                  t('billing.contact_admin')
                )}
              </>
            );
          }
        } else if (!billingState.has_card) {
          if (billingState.has_expired) {
            children = (
              <>
                {t('billing.account_expired', { 0: billingState.plan_name })}{' '}
                {canReadBilling ? (
                  <Link to={`/${accountSlug}/account/billing`}>
                    {t('billing.add_card_to_continue')}
                  </Link>
                ) : (
                  t('billing.contact_admin')
                )}
              </>
            );
          } else {
            children = (
              <>
                {t('billing.account_days_left', {
                  0: billingState.days_left,
                  1: billingState.plan_name,
                })}{' '}
                {canReadBilling ? (
                  <Link to={`/${accountSlug}/account/billing`}>
                    {t('billing.add_card_to_continue')}
                  </Link>
                ) : (
                  t('billing.contact_admin')
                )}
              </>
            );
          }
        } else if (billingState.plan_id === 'starter') {
          variant = 'improvement';
          hasIcon = true;
          children = (
            <>
              {t('billing.want_analytics')}{' '}
              {canReadBilling ? (
                <Link to={`/${accountSlug}/account/billing`}>
                  {t('billing.upgrade_to_pro')}
                </Link>
              ) : (
                t('billing.contact_admin')
              )}
            </>
          );
        }
        break;

      default:
        break;
    }

    // Here's where we'll handle banners
    if (!children) {
      return undefined;
    }
    dispatch(
      openBanner({
        children,
        variant,
        dismissable: true,
        centered: true,
        hasIcon,
        padding: 'small',
      })
    );

    return () => {
      dispatch(closeBanner());
    };
  }, [dispatch, billingState]);
};
