import { useMemo } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import useDialogs from '@/hooks/useDialogs';
import useIntents from '@/hooks/useIntents';
import useWebhooks from '@/hooks/useWebhooks';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { TYPES, defaultPropsByType, iconByType } from '@/redux/dialogs/helper';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId, selectDialogId } from '@/redux/session/selectors';

import styles from './ToolkitItem.module.scss';

interface ToolkitItemProps {
  id: string;
  title: string;
  type: string;
}

const starters = ['event', 'intent', 'unknown'];

const ToolkitItem = ({ id, title, type }: ToolkitItemProps) => {
  const { t } = useTranslation();
  const canWrite = useSelector(
    (state: RootState) => getPermissions(state, 'brains', actions.WRITE),
    shallowEqual
  );
  const brainId = useSelector(selectBrainId);
  const { randomIntent } = useIntents(brainId);
  const { randomWebhook, webhooks = [] } = useWebhooks(brainId);
  const dialogId = useSelector(selectDialogId);
  const { randomNode } = useDialogs(brainId, dialogId);
  const isDisabled = useMemo(() => {
    switch (id) {
      case 'webhook': {
        return webhooks?.length === 0;
      }
      default:
        return false;
    }
  }, [id, webhooks?.length]);

  const getRandomValue = (type: string) => {
    switch (type) {
      case 'intent':
        return randomIntent?.intent;
      case 'webhook':
        return randomWebhook?.webhook_id;
      case 'webview':
      case 'event':
        return randomNode?.value;
      default:
        return undefined;
    }
  };

  const [{ isDragging }, dragRef] = useDrag({
    item: {
      id: uuidv4(),
      subType: id,
      props: defaultPropsByType(id, getRandomValue(id)),
    },
    type: id === TYPES.QUESTION ? TYPES.QUESTION : type,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Tooltip
      title={isDisabled ? t('dialog.toolkit_disabled') : null}
      arrow
      placement="top"
    >
      <div
        className={cn(styles.item, {
          [styles.isDragging]: isDragging,
          [styles.isDisabled]: isDisabled,
        })}
        ref={canWrite ? (isDisabled ? null : dragRef) : null}
      >
        {!isDragging && (
          <>
            <div
              className={cn(styles.icon, { [styles.isDragging]: isDragging })}
            >
              {iconByType({
                id,
                color: isDisabled
                  ? 'var(--text-disabled-gray)'
                  : starters.includes(id) && type !== TYPES.ACTION
                    ? 'var(--icon-default-blue)'
                    : 'var(--surface-secondary-blue-dark)',
                size: 24,
                type,
              })}
            </div>
            <Typography
              variant="label-regular"
              className={cn(styles.text, { [styles.isDragging]: isDragging })}
            >
              {t(title)}
            </Typography>
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default ToolkitItem;
