import React, { useEffect, forwardRef, useRef } from 'react';

interface Props {
  indeterminate?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const TableCheckbox: React.FC<Props> = forwardRef(
  (
    { indeterminate = false, ...rest }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return <input type="checkbox" ref={combinedRef} {...rest} />;
  }
);

TableCheckbox.displayName = 'TableCheckbox';
