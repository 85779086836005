import useFacebook from '@/hooks/useFacebook';

import Connect from './Connect';
import Settings from './Settings';

const Facebook = () => {
  const {
    integration,
    connectionStatus,
    isManual,
    previousFBConnection,
    error,
    onManualClick,
    onOAuthClick,
  } = useFacebook();

  if (!integration) {
    return null;
  }

  // If the integration is connected, or the user is managing it manually,
  // show the settings page
  if (connectionStatus === 'connected' || isManual) {
    return <Settings isManual={isManual} />;
  }

  return (
    <Connect
      error={error}
      onOAuthClick={onOAuthClick}
      onManualClick={onManualClick}
      previousFBConnection={previousFBConnection}
    />
  );
};

export default Facebook;
