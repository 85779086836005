import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Phone = forwardRef<SVGSVGElement, Icon>(
  ({ size = 14, color = 'var(--icon-default-gray)', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M22 16.92v3a2.001 2.001 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.499 19.499 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72c.127.96.362 1.903.7 2.81a2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a1.999 1.999 0 0 1 2.11-.45c.907.339 1.85.574 2.81.7A2 2 0 0 1 22 16.92Z"
        />
      </svg>
    );
  }
);

Phone.displayName = 'Phone';

export default Phone;
