import { Icon } from '@/models/icon';

const Google = ({ size = 16, color = '' }: Icon) => {
  return (
    <svg
      viewBox="0 0 24 25"
      width={size}
      height={size}
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M5.02 12.617c0-.78.133-1.527.369-2.227L1.259 7.3A11.79 11.79 0 000 12.617c0 1.911.453 3.713 1.257 5.313l4.128-3.095a6.965 6.965 0 01-.364-2.218z"
        fill={color || '#FBBC05'}
      />
      <path
        d="M12.273 5.526c1.73 0 3.292.6 4.519 1.582l3.57-3.49c-2.175-1.855-4.965-3-8.089-3C7.423.617 3.255 3.332 1.26 7.3l4.13 3.089c.952-2.83 3.665-4.864 6.884-4.864z"
        fill={color || '#EA4335'}
      />
      <path
        d="M12.273 19.708c-3.22 0-5.932-2.034-6.884-4.863l-4.13 3.088c1.996 3.968 6.164 6.684 11.014 6.684 2.993 0 5.851-1.04 7.996-2.99l-3.92-2.968c-1.107.682-2.5 1.05-4.076 1.05z"
        fill={color || '#34A853'}
      />
      <path
        d="M23.988 12.617c0-.709-.112-1.472-.28-2.182H12.274v4.637h6.582c-.329 1.58-1.225 2.796-2.507 3.587l3.92 2.968c2.254-2.048 3.72-5.098 3.72-9.01z"
        fill={color || '#4285F4'}
      />
    </svg>
  );
};

export default Google;
