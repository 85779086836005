import { PaymentMethod } from '@stripe/stripe-js';
import Stripe from 'stripe';

export type Subscription = {
  id: string;
  cancel_at_period_end: boolean;
  cancel_at: number | null;
  default_payment_method: string | null;
  current_period_end: number;
  current_period_start: number;
  status: 'active' | 'canceled';
  collection_method: string;
  days_until_due: number | null;
  quantity: number | null;
  items: {
    quantity: number;
    price: {
      id: string;
      unit_amount: number;
      currency: string;
      nickname: string;
    };
  }[];
};

export const PLAN_IDS = {
  PRO: 'pro',
  ENTERPRISE: 'enterprise',
  GROWTH: 'growth',
} as const;

export type Plan = {
  enabled: boolean;
  features: string[];
  max_desks: number;
  max_brains: number;
  max_integrations: number;
  max_seats: number;
  max_sessions: number;
  name: string;
  description: string;
  plan_id: (typeof PLAN_IDS)[keyof typeof PLAN_IDS];
  created: string;
  updated: string;
};

export type PlansResponse = {
  plans: Plan[];
};

export type CustomerResponse = Stripe.Customer;

export type PaymentMethodsResponse = {
  payment_methods: PaymentMethod[];
};

export type BillingState = {
  current_period_ends: string;
  next_payment: string;
  days_left: number;
  has_card: boolean;
  has_expired: boolean;
  is_canceled: boolean;
  is_expiring: boolean;
  plan_id: string;
  plan_name: string;
  plan_type: 'trial' | 'custom' | 'stripe';
};

export type Coupon = {
  amount_off: number;
  name: string;
  object: 'coupon';
};
export type Invoice = {
  invoice_id: string;
  status: 'paid' | 'open' | 'draft' | 'uncollectible' | 'void';
  paid: boolean;
  paid_at: number | null;
  currency: string;
  invoice_pdf?: string;
  discount?: {
    coupon: Coupon;
  };
  hosted_invoice_url?: string;
  total: number;
  tax: number;
  subtotal_excluding_tax: number;
  amount_due: number;
  period_start: number;
  period_end: number;
  lines: [
    {
      amount: number;
      quantity: number;
      proration: true;
    },
  ];
};

export type InvoicesResponse = {
  invoices?: Invoice[];
};

export interface ModalPlanData {
  title: string;
  subtitle?: string;
  body?: Array<{
    text: string;
    number?: string;
    secondaryText?: string;
  }>;
  plan_id: string;
}
