import { Dispatch, SetStateAction, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Brain from '@/components/atoms/Icons/Brain';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';

import ExternalFilter from './ExternalFilter/ExternalFilter';
import useExternalFilter from '../../../hooks/useExternalFilter';

interface Props {
  dateOpen: boolean;
  filterOpen: boolean;
  setDateOpen: Dispatch<SetStateAction<boolean>>;
  setFilterOpen: Dispatch<SetStateAction<boolean>>;
}

const BrainFilters = ({
  dateOpen,
  setDateOpen,
  setFilterOpen,
  filterOpen,
}: Props) => {
  const { brains, listStatus } = useBrains();
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();

  const updatedBrains = brains?.map(({ brain_id, name }) => ({
    name,
    brain_id,
  }));

  const filterActions = useExternalFilter({
    items: updatedBrains as { [key: string]: string }[],
    dateOpen,
    filterOpen,
    setDateOpen,
    key: 'brain_id',
    filterKey: 'brainIds',
  });
  const handleClickAway = () => {
    filterActions.setShowItems(false);
    filterActions.applyItems();
  };
  useEffect(() => {
    if (filterActions.showItems && (dateOpen || filterOpen)) {
      setDateOpen(false);
      setFilterOpen(false);
    }
  }, [
    dateOpen,
    filterOpen,
    setDateOpen,
    setFilterOpen,
    filterActions.showItems,
  ]);

  return (
    <ExternalFilter
      {...filterActions}
      itemsLength={brains?.length}
      listStatus={listStatus}
      onClickAway={handleClickAway}
      icon={<Brain color="var(--icon-default-blue)" />}
      displayValue={filterActions.getItemsDisplayValue(
        t(`brains.${ai_agents ? 'select_ai_agents' : 'select_brains'}`),
        t(`brains.all_${ai_agents ? 'ai_agents' : 'brains'}`)
      )}
      dropdownTitle={t(`brains.all_${ai_agents ? 'ai_agents' : 'brains'}`)}
    />
  );
};

export default BrainFilters;
