import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Chevron = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      viewBox="0 0 24 24"
      className={`${styles.icon} ${disabledClass}`}
      width={size}
      height={size}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      shapeRendering="geometricPrecision"
    >
      <path d="M13.5 11.25L9 6.75l-4.5 4.5" />
    </svg>
  );
};

export default Chevron;
