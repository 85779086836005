import { AccordionAction, AccordionState } from './types';

export const accordionReducer = (
  state: AccordionState,
  action: AccordionAction
): AccordionState => {
  const newState = { ...state };
  switch (action.type) {
    case 'INIT_STATE':
      return { ...action.newState };
    case 'SET_EXPANDED':
      Object.keys(newState).forEach((key) => {
        newState[key] = {
          ...newState[key],
          isExpanded: key === action.panel ? action.isExpanded : false,
        };
      });
      return newState;
    case 'SET_DIRTY':
      return {
        ...state,
        [action.panel]: { ...state[action.panel], isDirty: action.isDirty },
      };
    case 'SET_ON_SUBMIT':
      return {
        ...state,
        [action.panel]: { ...state[action.panel], onSubmit: action.onSubmit },
      };
    case 'SET_TRIGGER_VALIDATION':
      return {
        ...state,
        [action.panel]: {
          ...state[action.panel],
          triggerValidation: action.triggerValidation,
        },
      };
    default:
      return state;
  }
};
