import { useCallback, useMemo, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useClickAway } from 'react-use';

import { MODAL_FORM } from '@/components/organisms/Modals/ModalConductor';
import useLocalStorage from '@/hooks/useLocalStorage';
import { AnalyticsType } from '@/modules/analytics/models';
import { popModal, pushModal } from '@/redux/modals/actions';
import { delay, preventClickThrough, urlSafeBase64Encode } from '@/util/util';

import { formatChannelFilters } from './useContentFilters';

export type LogFilter = {
  name: string;
  query: string;
};

const useFavorites = (type: AnalyticsType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const favoritesRef = useRef(null);
  const [showFavorites, setShowFavorites] = useState(false);
  const dispatch = useDispatch();
  const [urlParams] = useSearchParams();
  const formMethods = useFormContext();

  useClickAway(favoritesRef, async () => {
    await delay(100);
    setShowFavorites(false);
  });
  const [favor, setFavorites] = useLocalStorage(
    `${type}-favorite-filters`,
    JSON.stringify([])
  );

  const favorites = useMemo(() => JSON.parse(favor), [favor]) as LogFilter[];

  const onFavoriteSave = useCallback(
    (data) => {
      if (formMethods) {
        const values = formMethods.getValues('filters');
        const filters = formatChannelFilters(values);

        const empty = isEmpty(values);

        setFavorites(
          JSON.stringify([
            ...favorites,
            {
              name: data.name,
              query: !empty ? JSON.stringify([...filters]) : '',
            },
          ])
        );

        const navigateFilters = !empty ? JSON.stringify([...filters]) : '';
        navigate(
          {
            ...window.location,
            search: `${urlParams.get('date') ? `date=${urlParams.get('date')}` : ''}${
              filters ? `&filters=${urlSafeBase64Encode(navigateFilters)}` : ''
            }`,
          },
          {
            replace: true,
          }
        );
        dispatch(popModal());
      }
    },
    [formMethods, setFavorites, favorites, urlParams, dispatch, navigate]
  );

  const onFavoriteClick = useCallback(
    (filter: LogFilter) => () => {
      const { query } = filter;
      let filters = query;
      // backwards compatible
      // removed date from saved query
      if (query.match(/^\?date=/)) {
        filters = decodeURIComponent(
          query.substring(
            query.indexOf('&filters=') + '&filters='.length,
            query.length
          )
        );
      }

      navigate(
        {
          ...window.location,
          search: `${urlParams.get('date') ? `date=${urlParams.get('date')}` : ''}${
            filters ? `&filters=${urlSafeBase64Encode(filters)}` : ''
          }`,
        },
        {
          replace: true,
        }
      );

      setShowFavorites(false);
    },
    [urlParams, navigate]
  );

  const onFavoriteDelete = useCallback(
    (index: number) => (event) => {
      preventClickThrough(event);
      favorites.splice(index, 1);
      setFavorites(JSON.stringify([...favorites]));
    },
    [favorites, setFavorites]
  );

  const onClickSaveFilters = useCallback(() => {
    const modalProps = {
      resource: t('analytics.favourite_filter'),
      fields: [
        {
          fieldName: 'name',
          fieldValue: '',
        },
      ],
      onCreate: onFavoriteSave,
    };
    dispatch(pushModal(MODAL_FORM, modalProps));
  }, [dispatch, onFavoriteSave, t]);

  return {
    ref: favoritesRef,
    showFavorites,
    setShowFavorites,
    onClickSaveFilters,
    favorites,
    onFavoriteClick,
    onFavoriteDelete,
  };
};

export default useFavorites;
