import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { entitiesEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { MODAL_CONFIRM_CHANGES } from '@/components/organisms/Modals/ModalConductor';
import { Entities, Entity, EntityValue, PartialEntity } from '@/models/entity';
import { RootState } from '@/models/state';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectEntityName } from '@/redux/session/selectors';
import { clearEntityValues, setEntity } from '@/redux/values/actions';
import { selectEntityDraft } from '@/redux/values/selectors';
import { resolveBrainsPath, sameCollection } from '@/util/util';

import { useAccount } from './useAccount';
import useDialogs from './useDialogs';
import useFeatureFlag from './useFeatureFlag';

type DraftValue = {
  id: string;
  text?: string;
} & Partial<EntityValue>;

export type EntityDraft = {
  new_entity: string;
  description: string;
  values: DraftValue[];
};

type CollectionsUpdateProps = {
  collection: string;
  new_collection: string;
};

export interface UpdateEntityProps {
  name: string;
  entity: PartialEntity | Partial<EntityDraft>;
}
export const API = Object.freeze({
  listEntities: async (brainId: string): Promise<Entities> =>
    callGet(endpoints.entities(brainId)),

  getEntity: async (brainId: string, entityName: string): Promise<Entity> =>
    callGet(endpoints.entity(brainId, entityName)),

  createEntity: async ({
    brainId,
    newEntity,
  }: {
    brainId: string;
    newEntity: PartialEntity;
  }): Promise<Entity> => callPost(endpoints.entities(brainId), newEntity),

  updateCollection: async (
    brain_id: string,
    entity: PartialEntity
  ): Promise<Entities> => callPut(endpoints.collection(brain_id), entity),

  updateEntity: async (
    brainId: string,
    { name, entity }: UpdateEntityProps
  ): Promise<Entity> => callPut(endpoints.entity(brainId, name), entity),

  deleteEntity: async ({
    brainId,
    entityName,
  }: {
    brainId: string;
    entityName: string;
  }): Promise<Entity> => callDelete(endpoints.entity(brainId, entityName)),
});

export const onEntityCreated = (
  queryClient: QueryClient,
  entity: Entity,
  brain_id: string
) => {
  queryClient.setQueryData<Entities>(
    [endpoints.entities(brain_id)],
    (prev) => ({
      entities: [...(prev?.entities || []), entity],
    })
  );
};

export const onEntityUpdated = (
  queryClient: QueryClient,
  name: string,
  entity: Entity
) => {
  queryClient.setQueryData<Entity>(
    [endpoints.entity(entity.brain_id, name)],
    (prev: Entity) => {
      if (prev) {
        return { ...prev, ...entity };
      }
      return entity;
    }
  );

  queryClient.setQueryData<Entities>(
    [endpoints.entities(entity.brain_id)],
    (prev: Entities) => ({
      entities: prev?.entities.map((item) => {
        if (item.entity === name) {
          return { ...item, ...entity };
        }
        return item;
      }),
    })
  );
};

export const onEntityRemoved = (
  queryClient: QueryClient,
  brainId: string,
  entityName: string
) => {
  queryClient.setQueryData<Entities>(
    [endpoints.entities(brainId)],
    (prev: Entities) => {
      return {
        entities: prev?.entities.filter((acc) => acc.entity !== entityName),
      };
    }
  );
  queryClient.removeQueries({
    queryKey: [endpoints.entity(brainId, entityName)],
  });
};

export const onEntityCollectionUpdated = (
  queryClient: QueryClient,
  brainId: string,
  oldCollectionName: string,
  newCollectionName: string
) => {
  queryClient.setQueryData<Entities>(
    [endpoints.entities(brainId)],
    (prev: Entities) => ({
      entities: prev?.entities.map((item) => {
        if (item.collection === oldCollectionName) {
          return { ...item, collection: newCollectionName };
        }
        return item;
      }),
    })
  );
};

const useEntities = (brainId: string, entityName?: string) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();

  const dispatch = useDispatch();
  const { slug } = useAccount();
  const { entityName: paramsEntity } = useParams();
  const { updateEntityNameAndValuesOnDialogs } = useDialogs(brainId);
  const {
    entityDraft,
    isDirty,
  }: { entityDraft: EntityDraft; isDirty: boolean } = useSelector(
    (state: RootState) => ({
      entityDraft: selectEntityDraft(state),
      isDirty: state.values.dirty,
    }),
    shallowEqual
  );
  const [initialDraft, setInitialDraft] = useState(entityDraft);
  const sessionEntity = useSelector(selectEntityName);

  const isDraft = paramsEntity === 'draft';

  useEffect(() => {
    if (!isDirty) {
      setInitialDraft(entityDraft);
    }
  }, [entityDraft, isDirty]);

  const queryClient = useQueryClient();

  const { data: entities, status: listStatus } = useQuery<Entities, Error>({
    queryKey: [endpoints.entities(brainId)],
    queryFn: () => API.listEntities(brainId),
    enabled: !!brainId,
  });

  const { data: entity, status: getStatus } = useQuery<Entity, Error>({
    queryKey: [endpoints.entity(brainId, entityName)],
    queryFn: () => {
      return API.getEntity(brainId, entityName);
    },
    enabled:
      !!brainId &&
      !!entityName &&
      !isDraft &&
      !!entities?.entities?.find((e) => e.entity === entityName),
    initialData: entities?.entities?.find((e) => entityName === e.entity),
  });
  const { mutate: updateEntity, status: updateStatus } = useMutation<
    Entity,
    Error,
    UpdateEntityProps
  >({
    mutationFn: (data) => API.updateEntity(brainId, { ...data }),
    onSuccess: (resp, variables) => {
      //Is not a collection update
      if (!('collection' in variables.entity)) {
        updateEntityNameAndValuesOnDialogs(initialDraft, entityDraft);
      }
      onEntityUpdated(queryClient, variables.name, resp);

      dispatch(
        addTemporalToast(
          'success',
          t('entity.entity_saved', { 0: resp.entity })
        )
      );
    },
    onError: (error: Error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createEntity, status: createStatus } = useMutation({
    mutationFn: API.createEntity,
    onSuccess: (resp) => {
      dispatch(setEntity(resp));
      onEntityCreated(queryClient, resp, brainId);
    },
    onError: (error: Error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });
  const { mutate: deleteEntity } = useMutation<
    Entity,
    Error,
    { brainId: string; entityName: string } | void
  >({
    mutationFn: (entity) => {
      if (entity) {
        return API.deleteEntity(entity);
      }
      return API.deleteEntity({ brainId, entityName });
    },
    onSuccess: (resp) => {
      dispatch(popModal());

      const entityToDelete = entityName ?? resp?.entity;

      const entitiesInCollection = sameCollection(
        'entities',
        'entity',
        entityToDelete,
        entities
      );

      onEntityRemoved(queryClient, brainId, entityToDelete);

      dispatch(
        addTemporalToast(
          'success',
          t('entity.entity_deleted', { 0: entityToDelete })
        )
      );
      dispatch(clearEntityValues());
      const url = resolveBrainsPath(
        `/${slug}/brains/${brainId}/entities`,
        ai_agents
      );
      if (entitiesInCollection.length === 0) {
        navigate(url);
      } else {
        navigate(`${url}/${entitiesInCollection[0].entity}`);
      }
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: updateCollection } = useMutation<
    Entities,
    Error,
    CollectionsUpdateProps
  >({
    mutationFn: (data) => API.updateCollection(brainId, data),
    onSuccess: (resp, variables) => {
      const newCollectionName = resp.entities[0].collection;

      onEntityCollectionUpdated(
        queryClient,
        brainId,
        variables.collection,
        variables.new_collection
      );

      dispatch(
        addTemporalToast(
          'success',
          t('dialog.collection_updated', { 0: newCollectionName })
        )
      );
    },
  });

  const saveEntity = useCallback(
    async (shouldRedirect = true) => {
      if (isDraft) {
        const previousCollection = entities?.entities?.find(
          (e) => e.entity === sessionEntity
        )?.collection;

        const newEntity = {
          entity: entityDraft.new_entity,
          values: entityDraft.values,
          collection: previousCollection ?? '',
        } as PartialEntity;

        return new Promise<Entity>((resolve, reject): void => {
          createEntity(
            { brainId, newEntity },
            {
              onSuccess: (resp) => {
                if (shouldRedirect) {
                  navigate(
                    resolveBrainsPath(
                      `/${slug}/brains/${brainId}/entities/${resp?.entity}`,
                      ai_agents
                    )
                  );
                }

                resolve(resp);
              },
              onError: reject,
            }
          );
        });
      }

      return new Promise<void>((resolve, reject): void => {
        const updatePayload =
          paramsEntity === entityDraft.new_entity
            ? {
                name: entityDraft.new_entity,
                entity: {
                  values: entityDraft.values,
                },
              }
            : {
                name: paramsEntity ?? entityDraft.new_entity,
                entity: {
                  new_entity: entityDraft.new_entity,
                  values: entityDraft.values,
                },
              };

        updateEntity(updatePayload, {
          onSuccess: (resp) => {
            if (resp.entity !== entityName) {
              queryClient.removeQueries({
                queryKey: [endpoints.entity(brainId, entityName)],
              });
              if (shouldRedirect) {
                navigate(
                  resolveBrainsPath(
                    `/${slug}/brains/${brainId}/entities/${resp.entity}`,
                    ai_agents
                  )
                );
              }
            }
            resolve();
          },
          onError: reject,
        });
      });
    },
    [
      brainId,
      createEntity,
      entities?.entities,
      entityDraft.new_entity,
      entityDraft.values,
      entityName,
      isDraft,
      paramsEntity,
      queryClient,
      sessionEntity,
      slug,
      updateEntity,
      navigate,
      ai_agents,
    ]
  );

  const handleSaveEntity = (shouldRedirect?: boolean) => {
    const values = entityDraft?.values;
    if (values?.length === 0) {
      setTimeout(() => {
        dispatch(
          pushModal(MODAL_CONFIRM_CHANGES, {
            onSave: () => saveEntity(shouldRedirect),
            onDiscard: () => {
              dispatch(popModal());
            },
            title: t('prompts.unsaved_changes.title'),
            subtitle: t('entity.empty'),
            secondaryText: t('common.cancel'),
            primaryText: t('common.save_anyway'),
          })
        );
      }, 0);
    } else {
      saveEntity(shouldRedirect);
    }
  };

  const createDraftEntity = useCallback(() => {
    const path = resolveBrainsPath(
      `/${slug}/brains/${brainId}/entities/draft`,
      ai_agents
    );
    navigate(path);
  }, [ai_agents, slug, brainId, navigate]);

  type CollectionUpdate = {
    id: string;
    collection: string;
  };
  const handleCollectionUpdate = useCallback(
    ({ id, collection }: CollectionUpdate) => {
      updateEntity({ name: id, entity: { collection } });
    },
    [updateEntity]
  );

  const entityNames = useMemo(
    () => entities?.entities?.map((i) => i.entity.toLowerCase()),
    [entities]
  );

  return {
    entities: entities?.entities,
    entity,
    createEntity,
    createDraftEntity,
    updateCollection,
    saveEntity,
    handleSaveEntity,
    getStatus,
    listStatus,
    createStatus,
    updateStatus,
    deleteEntity,
    updateEntity,
    handleCollectionUpdate,
    entityNames,
    navigate,
  };
};

export default useEntities;
