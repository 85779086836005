import { Icon } from '@/models/icon';

const ZoomOut = ({ size = 16, color = 'var(--icon-default-black)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M7.333 13.069a5.333 5.333 0 1 0 0-10.667 5.333 5.333 0 0 0 0 10.667ZM14 14.402l-2.9-2.9M5.333 7.736h4"
      />
    </svg>
  );
};

export default ZoomOut;
