import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import { Action } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { capitalizeFirstLetter } from '@/util/util';
import { HANDOVER_OPTIONS, handOverActionSchema } from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitActionHandover.module.scss';

type Form = {
  handover: string;
  department_id: string;
};

function ToolkitActionHandover() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { action_id, external, department_id, type } = useSelector(
    (state: RootState) =>
      selectSelectedAction(state) as Extract<Action, { type: 'handover' }>,
    shallowEqual
  );
  const parentNodeId = useSelector(selectNodeIdByActionId(action_id));

  // Default values for the form
  const defaultHandover = useMemo(() => {
    if (external) {
      return HANDOVER_OPTIONS.FACEBOOK_MESSENGER_HANDOVER;
    } else if (typeof department_id === 'string') {
      return HANDOVER_OPTIONS.ZENDESK_DEPARTMENT_ROUTING;
    } else {
      return HANDOVER_OPTIONS.DEFAULT;
    }
  }, [department_id, external]);
  const defaultDepartmentId = department_id ? department_id : '';

  const actionHandoverOptions = [
    {
      id: 0,
      value: HANDOVER_OPTIONS.DEFAULT,
      label: t('actions.handover.default.value'),
    },
    {
      id: 1,
      value: HANDOVER_OPTIONS.ZENDESK_DEPARTMENT_ROUTING,
      label: t('actions.handover.zendesk_department_routing.value'),
    },
    {
      id: 2,
      value: HANDOVER_OPTIONS.FACEBOOK_MESSENGER_HANDOVER,
      label: t('actions.handover.facebook_messenger_handover.value'),
    },
  ];

  const {
    trigger,
    register,
    unregister,
    setValue,
    formState: { errors },
    watch,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      handover: defaultHandover,
      department_id: defaultDepartmentId,
    },
    resolver: yupResolver(handOverActionSchema) as Resolver<Form>,
  });

  const watchHandover = watch('handover');

  const handleChangeHandover = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const external =
        e.target.value === HANDOVER_OPTIONS.FACEBOOK_MESSENGER_HANDOVER;
      let department_id = undefined;

      if (e.target.value === HANDOVER_OPTIONS.ZENDESK_DEPARTMENT_ROUTING) {
        department_id = t('dialog.pre-fill.department_id');
        setValue('department_id', department_id);
      }

      dispatch(
        updateAction({
          actionId: action_id,
          action: {
            external,
            department_id,
          },
        })
      );
    },
    [action_id, dispatch, setValue, t]
  );

  const handleChangeDepartmentId = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(
        updateAction({
          actionId: action_id,
          action: {
            department_id: e.target.value,
          },
        })
      );
    },
    [action_id, dispatch]
  );

  useEffect(() => {
    if (watchHandover === HANDOVER_OPTIONS.ZENDESK_DEPARTMENT_ROUTING) {
      register('department_id');
    } else {
      unregister('department_id');
    }
  }, [register, unregister, watchHandover]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    dispatch(
      updateDialogAlerts({
        dialogAlerts: {
          alertType: 'error',
          id: action_id,
          nodeId: parentNodeId,
          title: t('actions.types.replay'),
          body: capitalizeFirstLetter(errors.department_id?.message),
          type,
        },
      })
    );
  }, [
    action_id,
    dispatch,
    errors.department_id?.message,
    parentNodeId,
    t,
    type,
  ]);

  return (
    <ToolkitWrapper type="handover">
      {actionHandoverOptions.map((option, index) => (
        <div
          className={styles.option}
          key={`${actionHandoverOptions[index].id}-${actionHandoverOptions[index].value}`}
        >
          <OptionRadioButton
            option={option}
            size="medium"
            onChange={handleChangeHandover}
            isSelected={watchHandover === option.value}
            register={register('handover')}
          />
          {watchHandover === HANDOVER_OPTIONS.ZENDESK_DEPARTMENT_ROUTING &&
            watchHandover === option.value && (
              <div className={styles.nested}>
                <Banner variant="neutral" relativePosition>
                  {t('actions.handover.zendesk_department_routing.help_text')}
                </Banner>
                <div className={styles.inputContainer}>
                  <TextAreaAsInput
                    register={register('department_id')}
                    label={t('actions.handover.departement_routing')}
                    tooltip={t('actions.handover.departement_routing_tooltip')}
                    placeholder={t(
                      'actions.handover.departement_routing_placeholder'
                    )}
                    height="medium"
                    error={!!errors.department_id}
                    errorMessage={capitalizeFirstLetter(
                      errors.department_id?.message
                    )}
                    autofocus
                    trimValue
                    onChange={handleChangeDepartmentId}
                  />
                </div>
              </div>
            )}
        </div>
      ))}
    </ToolkitWrapper>
  );
}

export default ToolkitActionHandover;
