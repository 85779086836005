import cn from 'classnames';

import LongArrowUp from '../Icons/LongArrowUp';

import styles from './TileRuleHeader.module.scss';

export type Props =
  | { text: string; highPriority: true; lowPriority?: never }
  | { text: string; highPriority?: never; lowPriority: true }
  | { text: string; highPriority?: never; lowPriority?: never };

const TileHeader = ({ highPriority, lowPriority, text }: Props) => {
  if (highPriority || lowPriority) {
    return (
      <div className={styles.wrapper}>
        {highPriority && (
          <div>
            <span>{text}</span>{' '}
            <span>
              <LongArrowUp />
            </span>
          </div>
        )}
        {lowPriority && (
          <div>
            <span>{text}</span>{' '}
            <span className={cn({ [styles.arrowdown]: lowPriority })}>
              <LongArrowUp />
            </span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default TileHeader;
