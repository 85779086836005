import { Fragment } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Callout from '@/components/atoms/Callout/Callout';
import { Role } from '@/models/role';

import styles from './FooterWarning.module.scss';

interface Props {
  allRoles: string[];
  roles: Role[];
  showEmptyMessage?: boolean;
}
const FooterWarning = ({
  allRoles,
  roles,
  showEmptyMessage = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(styles.calloutHeight, {
        [styles.hasContent]: allRoles.length > 0,
      })}
    >
      {allRoles.length > 0 && (
        <Callout variant="neutral">
          {allRoles.map((role) => {
            const { description, name } =
              roles.find((r) => r.role_id === role) ?? {};
            return (
              <Fragment key={name}>
                <strong>{name}</strong>: {description}
                <br />
              </Fragment>
            );
          })}
        </Callout>
      )}
      {allRoles.length === 0 && showEmptyMessage && (
        <Callout variant="neutral">{t('teams.role_error')}</Callout>
      )}
    </div>
  );
};

export default FooterWarning;
