/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { Recommendations } from '@/models/recommendations';

export const defaultState: Recommendations = {};

const slice = createSlice({
  name: 'recommendations',
  initialState: defaultState,
  reducers: {
    setRecommendations: (state: Recommendations, action) => {
      const { brainId, recommendations } = action.payload;
      state[brainId] = {
        ...state[brainId],
        ...recommendations,
        listStatus: {
          loaded: true,
        },
        dirty: false,
      };
    },
    acceptRecommendation: (state: Recommendations, action) => {
      const { intent, brainId }: { intent: string; brainId: string } =
        action.payload;
      state[brainId].intents = state[brainId].intents.map((i) =>
        i.intent === intent ? { ...i, flag: 'accepted' } : i
      );
      state[brainId].dirty = true;
    },
    resetRecommendation: (state: Recommendations, action) => {
      const { intent, brainId }: { intent: string; brainId: string } =
        action.payload;
      if (state[brainId]) {
        state[brainId].intents = state[brainId]?.intents?.map((i) => {
          if (
            i.intent === intent &&
            i?.flag !== 'rejected' &&
            i?.flag !== 'deleted'
          ) {
            const resettedExpressions = i.expressions.map((e) => ({
              expression: e.expression,
            }));
            return {
              intent,
              confidence: i.confidence,
              expressions: resettedExpressions,
            };
          }
          return i;
        });
        state[brainId].dirty = false;
      }
    },
    resetDirty: (state: Recommendations, action) => {
      const { brainId }: { brainId: string } = action.payload;
      if (state[brainId] && state[brainId].enabled) {
        state[brainId].dirty = false;
      }
    },
    updateDraftRecommendations: (state: Recommendations, action) => {
      const { expressions, intentName, brainId } = action.payload;
      const intentIndex = state[brainId].intents.findIndex(
        (i) => i.intent === intentName
      );
      if (intentIndex >= 0) {
        state[brainId].intents[intentIndex].expressions = expressions;
        state[brainId].dirty = true;
      }
    },
  },
});

export const {
  setRecommendations,
  acceptRecommendation,
  resetRecommendation,
  updateDraftRecommendations,
  resetDirty,
} = slice.actions;

export default slice.reducer;
