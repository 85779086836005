import cn from 'classnames';

interface ConditionLineProps {
  direction: string;
  branches: number;
}

import styles from './BranchList.module.scss';
const BranchSvg = () => (
  <svg
    width="8"
    height="28"
    viewBox="0 0 8 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 1C4.5 0.723858 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723858 3.5 1H4.5ZM3.64645 27.3536C3.84171 27.5488 4.15829 27.5488 4.35355 27.3536L7.53553 24.1716C7.7308 23.9763 7.7308 23.6597 7.53553 23.4645C7.34027 23.2692 7.02369 23.2692 6.82843 23.4645L4 26.2929L1.17157 23.4645C0.976311 23.2692 0.659728 23.2692 0.464466 23.4645C0.269204 23.6597 0.269204 23.9763 0.464466 24.1716L3.64645 27.3536ZM3.5 2.625C3.5 2.90114 3.72386 3.125 4 3.125C4.27614 3.125 4.5 2.90114 4.5 2.625H3.5ZM4.5 5.875C4.5 5.59886 4.27614 5.375 4 5.375C3.72386 5.375 3.5 5.59886 3.5 5.875H4.5ZM3.5 9.125C3.5 9.40114 3.72386 9.625 4 9.625C4.27614 9.625 4.5 9.40114 4.5 9.125H3.5ZM4.5 12.375C4.5 12.0989 4.27614 11.875 4 11.875C3.72386 11.875 3.5 12.0989 3.5 12.375H4.5ZM3.5 15.625C3.5 15.9011 3.72386 16.125 4 16.125C4.27614 16.125 4.5 15.9011 4.5 15.625H3.5ZM4.5 18.875C4.5 18.5989 4.27614 18.375 4 18.375C3.72386 18.375 3.5 18.5989 3.5 18.875H4.5ZM3.5 22.125C3.5 22.4011 3.72386 22.625 4 22.625C4.27614 22.625 4.5 22.4011 4.5 22.125H3.5ZM4.5 25.375C4.5 25.0989 4.27614 24.875 4 24.875C3.72386 24.875 3.5 25.0989 3.5 25.375H4.5ZM3.5 1V2.625H4.5V1H3.5ZM3.5 5.875V9.125H4.5V5.875H3.5ZM3.5 12.375V15.625H4.5V12.375H3.5ZM3.5 18.875V22.125H4.5V18.875H3.5ZM3.5 25.375V27H4.5V25.375H3.5Z"
      fill="var(--icon-default-blue)"
    />
  </svg>
);
const BranchLine = ({ direction, branches }: ConditionLineProps) => {
  return (
    <div
      className={cn(styles.container, {
        [styles.containerRight]: direction === 'right',
        [styles.containerLeft]: direction === 'left',
        [styles.containerCenter]: direction === 'center',
        [styles.containerCenterHideAfter]:
          direction === 'center' && branches < 2,
      })}
    >
      <div
        className={cn(styles.lineContainer, {
          [styles.leftArrow]: direction === 'left',
        })}
      >
        <BranchSvg />
      </div>
    </div>
  );
};

export default BranchLine;
