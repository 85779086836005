import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import Select from '@/components/atoms/Select/Select';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import { Action } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectSelectedAction } from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter, getWebviewsUrl } from '@/util/util';
import { surveyActionSchema } from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitActionSurvey.module.scss';

const satisfactionSurveyUrl = `${getWebviewsUrl()}/common/survey`;
const customSurveyExampleUrl = 'https://www.example.com/survey/placeholder';

const SURVEY_OPTIONS = {
  SATISFACTION_SURVEY: 'Satisfaction survey',
  CUSTOM: 'custom',
} as const;

type Form = {
  survey_type: (typeof SURVEY_OPTIONS)[keyof typeof SURVEY_OPTIONS];
  url: string;
  name: string;
  label: string;
  height: string;
};

function ToolkitActionSurvey() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const typeOptions = useMemo(
    () => [
      {
        value: SURVEY_OPTIONS.SATISFACTION_SURVEY,
        label: t('dialog.survey.satisfaction_survey'),
      },
      { value: SURVEY_OPTIONS.CUSTOM, label: t('dialog.survey.custom') },
    ],
    [t]
  );

  const heightOptions = useMemo(
    () => [
      { value: 'tall', label: t('dialog.webview.tall') },
      { value: 'compact', label: t('dialog.webview.compact') },
      { value: 'full', label: t('dialog.webview.full') },
    ],
    [t]
  );

  const { actionId, ...survey } = useSelector((state: RootState) => {
    const selectedAction = selectSelectedAction(state) as Extract<
      Action,
      { type: 'survey' }
    >;
    const { action_id, ...rest } = selectedAction;
    return {
      actionId: action_id,
      brainId: selectBrainId(state),
      ...rest,
    };
  }, shallowEqual);

  const defaultValues = {
    survey_type:
      survey.url === satisfactionSurveyUrl
        ? SURVEY_OPTIONS.SATISFACTION_SURVEY
        : SURVEY_OPTIONS.CUSTOM,
    name: survey.name,
    label: survey.label,
    url: survey.url,
    height: survey?.height,
  };

  const {
    formState: { errors, dirtyFields },
    getValues,
    register,
    trigger,
    setValue,
    setFocus,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(surveyActionSchema) as Resolver<Form>,
  });
  useFocusOnInput('survey_type', errors, setFocus);
  // Update dialog errors based on the following messages:
  const urlErrorMessage = errors.url?.message;
  const nameErrorMessage = errors.name?.message;
  const labelErrorMessage = errors.label?.message;

  const onChange = useCallback(
    (key) => async () => {
      const value = getValues(key);
      const isValid = await trigger(key);

      const isDirty = !isEmpty(dirtyFields);
      if (isValid && isDirty) {
        dispatch(
          updateAction({
            actionId,
            action: {
              [key]: value,
            },
          })
        );
      }
    },
    [getValues, trigger, dirtyFields, dispatch, actionId]
  );

  const handleSurveyTypeChange = useCallback(() => {
    const surveyType = getValues('survey_type');
    const url =
      surveyType === SURVEY_OPTIONS.CUSTOM
        ? customSurveyExampleUrl
        : satisfactionSurveyUrl;

    setValue('url', url);

    dispatch(
      updateAction({
        actionId,
        action: {
          url,
        },
      })
    );

    trigger('url');
  }, [getValues, dispatch, actionId, trigger, setValue]);

  const handleChange = useCallback(
    (key: string) =>
      (
        e: ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      ) => {
        const value = e.target.value;

        dispatch(
          updateAction({
            actionId,
            action: {
              [key]: value,
            },
          })
        );
      },
    [actionId, dispatch]
  );

  const update = useCallback(
    (key: string) => (value: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: survey.node_id,
            title: t('actions.types.survey'),
            body: capitalizeFirstLetter(value),
            type: survey.type,
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, survey.node_id, survey.type, t]
  );

  useEffect(() => {
    update('name')(labelErrorMessage);
  }, [labelErrorMessage, update]);
  useEffect(() => {
    update('label')(nameErrorMessage);
  }, [nameErrorMessage, update]);
  useEffect(() => {
    update('url')(urlErrorMessage);
  }, [update, urlErrorMessage]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <ToolkitWrapper type="survey">
      <Select
        label={t('dialog.survey.survey_type')}
        name="survey_type"
        size="small-full"
        onChange={handleSurveyTypeChange}
        options={typeOptions}
        register={register('survey_type')}
      />

      {getValues('survey_type') === SURVEY_OPTIONS.CUSTOM && (
        <div className={styles.inputWrapper}>
          <TextAreaAsInput
            name="url"
            label={t('dialog.survey.survey_url')}
            placeholder={t('dialog.url_placeholder')}
            onChange={handleChange('url')}
            key={actionId}
            error={!!errors.url}
            errorMessage={capitalizeFirstLetter(errors.url?.message)}
            height="medium"
            register={register('url')}
            enableScroll
          />
        </div>
      )}

      <Input
        name="name"
        label={t('common.title')}
        placeholder={t('dialog.webview.type_title')}
        onChange={handleChange('name')}
        key={actionId}
        error={!!errors.name}
        errorMessage={capitalizeFirstLetter(errors.name?.message)}
        register={register('name')}
        size="small"
      />

      <Input
        name="label"
        label={t('dialog.webview.button_label')}
        placeholder={t('dialog.webview.label_placeholder')}
        onChange={handleChange('label')}
        error={!!errors.label}
        errorMessage={capitalizeFirstLetter(errors.label?.message)}
        register={register('label')}
        size="small"
      />

      <Select
        label={t('dialog.webview.height')}
        name="height"
        size="small-full"
        onChange={onChange('height')}
        options={heightOptions}
        register={register('height')}
      />

      <Banner
        title={t('dialog.survey.height_option')}
        variant="neutral"
        relativePosition
        dismissable={false}
      >
        {t('dialog.survey.height_option_text')}
      </Banner>
    </ToolkitWrapper>
  );
}

export default ToolkitActionSurvey;
