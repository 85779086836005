import { Icon } from '@/models/icon';

type SuccessTickProps = Icon & { strokeWidth?: number };

const SuccessTick = ({
  size = 16,
  color = 'var(--icon-default-blue)',
  strokeWidth = 1,
}: SuccessTickProps) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.333 4 6 11.333 2.667 8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default SuccessTick;
