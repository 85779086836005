import { useMemo } from 'react';

import AvatarGroup from '@mui/material/AvatarGroup';
import { useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { useRtmViewing } from '@/hooks/rtm/useRtmViewing';
import useUser from '@/hooks/useUser';
import { RootState } from '@/models/state';
import { selectLast3ViewersBySessionId } from '@/redux/presence/selectors';
import { selectSessionId } from '@/redux/session/selectors';

import styles from './ConversationViewing.module.scss';

export const ConversationViewing = () => {
  useRtmViewing();
  const sessionId = useSelector(selectSessionId);
  const { user } = useUser();

  const { viewers, count } = useSelector((state: RootState) =>
    selectLast3ViewersBySessionId(state, sessionId)
  );

  const viewersWithoutMe = useMemo(
    () => viewers.filter(({ user_id }) => user_id !== user?.user_id),
    [user?.user_id, viewers]
  );

  if (viewersWithoutMe.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <AvatarGroup
        max={3}
        total={count}
        spacing={4}
        slotProps={{
          additionalAvatar: {
            sx: {
              height: 16,
              width: 16,
              fontSize: 10,
            },
          },
        }}
      >
        {viewersWithoutMe.map(({ user_id }) => {
          return (
            <Avatar userId={user_id} size="small" key={user_id} hideStatus />
          );
        })}
      </AvatarGroup>
    </div>
  );
};
