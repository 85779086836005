import { useCallback, useEffect, useMemo, useRef } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Table from '@/components/atoms/Table/Table';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { selectBroadcastId } from '@/redux/session/selectors';

import BroadcastLayout from '../components/BroadcastLayout/BroadcastLayout';
import { BroadcastLogsHeader } from '../components/BroadcastLogsHeader';
import { SidebarBox } from '../components/BroadcastSidebar/SidebarBox/SidebarBox';
import SidebarFields from '../components/BroadcastSidebar/SidebarFields/SidebarFields';
import { useBroadcasts } from '../hooks/useBroadcasts';
import { useSubscribers } from '../hooks/useSubscribers';
import { Subscriber } from '../models';
import {
  selectDraftSubscribers,
  selectErrorCounter,
  selectIsDraft,
  selectValidSubscribers,
} from '../redux/selectors';
import { flattenKeys, getContextVariableOptions } from '../utils';

import styles from './Broadcast.module.scss';

interface RowProps {
  row: {
    original: Partial<Subscriber>;
  };
}
const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const BroadcastAudience = () => {
  const loadMoreRef = useRef();
  const oldScrollRef = useRef<number>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const draftSubscribers = useSelector(selectDraftSubscribers);
  const validSubscribers = useSelector(selectValidSubscribers);
  const isDraft = useSelector(selectIsDraft);
  const errorCounter = useSelector(selectErrorCounter);

  const broadcastId = useSelector(selectBroadcastId);
  const { t } = useTranslation();

  const { broadcast: APIbroadcast } = useBroadcasts(broadcastId);

  const { isLoading, subscribers, fetchNextPage, hasNextPage } =
    useSubscribers(broadcastId);

  const flatSubscribers = useMemo(
    () => [...(subscribers?.pages ?? [])].flatMap((page) => page.subscribers),
    [subscribers?.pages]
  );
  const finalSubscribers = useMemo(
    () => [...draftSubscribers, ...flatSubscribers],
    [draftSubscribers, flatSubscribers]
  );

  const handleNextPageFetch = useCallback(() => {
    oldScrollRef.current = bodyRef.current.scrollHeight;
    return fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    if (bodyRef.current && !isLoading) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [isLoading]);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: handleNextPageFetch,
  });

  const getColumns = useMemo(() => {
    const cols: {
      Header: string | JSX.Element;
      Cell: (data) => JSX.Element;
      accessor?: string;
      id?: string;
    }[] = [
      {
        Header: 'external_id',
        Cell: (props: RowProps) => {
          const external_id = props.row.original?.external_id;
          if (!external_id) {
            return (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <StatusBadge
                  withIcon
                  variant="warning"
                  label={t('broadcasts.missing')}
                />
              </Box>
            );
          }
          return <>{external_id}</>;
        },
      },
    ];

    const firstSubscriberContext = finalSubscribers?.[0]?.context ?? {};
    const flattenedContextKeys = flattenKeys(firstSubscriberContext);

    flattenedContextKeys.map((key) => {
      cols.push({
        Header: key,
        accessor: key,
        Cell: (props: RowProps) => {
          const value = getNestedValue(props.row.original?.context, key);
          if (!value) {
            return (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <StatusBadge
                  withIcon
                  variant="warning"
                  label={t('broadcasts.missing')}
                />
              </Box>
            );
          }
          return <div>{value}</div>;
        },
      });
    });

    cols.push({
      Header: t('broadcasts.ingestion'),
      accessor: 'status',
      Cell: (props: RowProps) => {
        const row = props.row.original;
        let errorMessage = '';
        const isInvalidNumber = row.status_code === 'INVALID';
        const isMissingVariables = row.status_code === 'MISSING_VARIABLE';
        const isInvalidEmail = row.status_code === 'INVALID_EMAIL';

        if (isInvalidNumber) {
          errorMessage = t('contacts.errors.phone');
        }
        if (isMissingVariables) {
          errorMessage = t('broadcasts.missing_variables');
        }
        if (isInvalidEmail) {
          errorMessage = t('errors.invalid_email');
        }
        const hasError =
          isInvalidNumber || isMissingVariables || isInvalidEmail;
        return (
          <Box display="flex" alignItems="center" width="100%" height="100%">
            <Tooltip arrow title={errorMessage} enterDelay={300}>
              <span>
                <StatusBadge
                  withIcon
                  variant={hasError ? 'critical' : 'success'}
                  label={hasError ? t('status.failed') : t('status.success')}
                />
              </span>
            </Tooltip>
          </Box>
        );
      },
    });

    return cols;
  }, [finalSubscribers, t]);

  const contextVariablesCount =
    getContextVariableOptions(finalSubscribers)?.length;

  const validSubscribersCount = isDraft
    ? validSubscribers.length
    : APIbroadcast?.subscribers_count + validSubscribers.length;

  return (
    <>
      <BroadcastLogsHeader title={t('broadcasts.audience')} />
      <PageContentWrapper newPlain2>
        <BroadcastLayout>
          <BroadcastLayout.Main>
            {isLoading && (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={130}
                height={28}
              />
            )}
            {finalSubscribers.length > 0 && (
              <PlainFieldset fullWidth overflown>
                <div ref={bodyRef}>
                  <Table
                    data={finalSubscribers}
                    columns={getColumns}
                    sortable
                    searchable={false}
                    filterable
                    noGutters
                  />
                </div>
                {hasNextPage && (
                  <div className={styles.skeleton} ref={loadMoreRef}>
                    <Skeleton height={40} width="100%" />
                  </div>
                )}
              </PlainFieldset>
            )}
          </BroadcastLayout.Main>

          <BroadcastLayout.Sidebar>
            <SidebarBox>
              <SidebarFields
                validSubscribersCount={validSubscribersCount}
                errorsCount={errorCounter}
                contextVariablesCount={contextVariablesCount}
              />
            </SidebarBox>
          </BroadcastLayout.Sidebar>
        </BroadcastLayout>
      </PageContentWrapper>
    </>
  );
};
