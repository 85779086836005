import { Icon } from '@/models/icon';

const Import = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
      className="icon_stroke"
      stroke={color}
    >
      <path
        strokeLinecap="round"
        d="M11 4.782h2a1 1 0 0 1 1 1V12a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5.782a1 1 0 0 1 1-1h2"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m6 6.946 2 2.473m0 0 2-2.473M8 9.419V2"
      />
    </svg>
  );
};

export default Import;
