import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Analytics from '@/components/atoms/Icons/Navigation/Analytics';
import Link from '@/components/atoms/Link/Link';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';

const DisabledAnalytics = () => {
  const navigate = useNavigate();
  const { slug, accountRole } = useAccount();
  const { t } = useTranslation();
  const body =
    accountRole !== 'owner' ? (
      t('permissions.contact_admin')
    ) : (
      <>
        <p>{t('analytics.activate')}</p>
        <p>
          <Link
            onClick={() => {
              navigate(`/${slug}/account/data-privacy`);
            }}
          >
            {t('profile.account_settings')}
          </Link>
        </p>
      </>
    );

  return (
    <PageContentWrapper fullHeight>
      <TileEmptyPage
        title={t('analytics.unavailable')}
        notClickable
        isFullHeight
        body={body}
        icon={<Analytics size={128} color="var(--icon-default-blue)" />}
      />
    </PageContentWrapper>
  );
};

export default DisabledAnalytics;
