import Badge, { BadgeProps } from '@mui/material/Badge';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import styled from '@mui/system/styled';

type ExtendedListItemProps = ListItemProps & { customDisabled: boolean };

export const Item = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'customDisabled',
})<ExtendedListItemProps>(({ customDisabled }) => ({
  '&': {
    height: 'var(--space-36)',
    padding:
      'var(--space-10) calc(var(--space-24) + 4px) var(--space-10) var(--space-16)',
    '&:hover': {
      backgroundColor: customDisabled
        ? 'inherit'
        : 'var(--surface-secondary-gray-light)',
      background: customDisabled
        ? 'inherit'
        : 'var(--surface-secondary-gray-light)',
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--surface-secondary-gray-light)',
      background: 'var(--surface-secondary-gray-light)',
    },
    ...(customDisabled ? { cursor: 'not-allowed' } : {}),
  },
  '& .MuiListItemButton-root': {
    height: 'var(--space-36)',
    paddingRight: 'var(--space-24)',
    '&:hover': {
      backgroundColor: 'transparent',
      background: 'transparent',
    },
  },
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'auto',
    marginRight: 'var(--space-8)',
    color: 'inherit',
  },
  '& .MuiListItemSecondaryAction-root': {
    right: 'calc(var(--space-24) + 2px)',
  },
}));

export const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'var(--icon-default-error)',
    color: 'var(--text-default-error)',
    right: '35%',
    height: 'var(--space-4)',
    minWidth: 'var(--space-4)',
  },
}));
