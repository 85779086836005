import { createSlice } from '@reduxjs/toolkit';

import {
  ACTIONS_VALUE_TO_LABEL,
  TRIGGERS_VALUE_TO_LABEL,
} from '@/modules/rules/constants';
import { ActionType, Rule } from '@/modules/rules/model';
import { isConditionNested } from '@/modules/rules/util';

const defaultState: Partial<Rule> = {
  name: '',
  rule_id: '',
  description: '',
  status: 'inactive',
  triggers: [],
  options: [],
  condition: {
    operator: 'or',
    conditions: [],
    isConditionOpen: false,
    isError: false,
    isHover: false,
  },
  actions: [],
  actionsOptions: [],
  dirty: false,
};

const slice = createSlice({
  name: 'rule',
  initialState: defaultState,
  reducers: {
    setDraftRule: (state, action) => {
      state.options = TRIGGERS_VALUE_TO_LABEL.map((x) => ({
        type: x,
      }));

      state.actionsOptions = ACTIONS_VALUE_TO_LABEL.map((x) => ({
        type: x,
      }));

      Object.assign(state, action.payload);
    },

    // Triggers
    addTrigger: (state, action) => {
      const { type, isOpen } = action.payload;
      state.dirty = true;
      state.triggers = [...state.triggers, { type, isOpen }];
    },
    removeTrigger: (state, action) => {
      const { type } = action.payload;
      state.dirty = true;
      const index = state.triggers.findIndex((x) => x['type'] === type);
      state.triggers = state.triggers.filter((x, i) => i !== index);
    },
    replaceTrigger: (state, action) => {
      const { type, index, delay } = action.payload;
      if (type === 'awaiting_user_reply' || type === 'awaiting_agent_reply') {
        state.triggers[index] = { type, delay_in_seconds: delay };
        state.dirty = true;
        return;
      }
      state.triggers[index] = { type };
      state.dirty = true;
    },
    revertIsTriggerOpen: (state, action) => {
      const { index, isOpenState } = action.payload;
      state.triggers[index].isOpen = isOpenState;
      if (!isOpenState) {
        state.triggers[index].isOpen = isOpenState;
      } else {
        state.triggers.forEach((trigger, idx) => {
          if (index === idx) {
            return (trigger['isOpen'] = isOpenState);
          }
          return (trigger['isOpen'] = !isOpenState);
        });
      }
    },
    isTriggerError: (state, action) => {
      const { index, errorState } = action.payload;
      if (index + 1 > state.triggers.length) {
        return;
      } else {
        state.triggers[index].isError = errorState;
      }
    },

    // Conditions
    addRuleCondition: (state, action) => {
      const { attribute, comparison, value, outerIndex } = action.payload;
      const isNested = isConditionNested(state.condition);
      if (isNested) {
        state.condition.conditions[outerIndex].conditions.push({
          attribute,
          comparison,
          value,
          isConditionOpen: true,
          isError: false,
        });
      } else {
        state.condition.conditions.push({
          attribute,
          comparison,
          value,
          isConditionOpen: true,
          isError: false,
        });
      }
    },
    isConditionOpen: (state, action) => {
      const { index, outerIndex, revertBoolean } = action.payload;
      const isNested = isConditionNested(state.condition);
      if (isNested) {
        if (!revertBoolean) {
          state.condition.conditions[outerIndex].conditions[
            index
          ].isConditionOpen = revertBoolean;
        } else {
          state.condition.conditions.forEach((conditionGroup, outerIdx) => {
            if (outerIdx === outerIndex) {
              conditionGroup.conditions.forEach((condition, idx) => {
                if (index === idx) {
                  return (condition.isConditionOpen = revertBoolean);
                }
                return (condition.isConditionOpen = !revertBoolean);
              });
            } else {
              conditionGroup.conditions.forEach(
                (condition) => (condition.isConditionOpen = !revertBoolean)
              );
            }
          });
        }
      } else {
        if (!revertBoolean) {
          state.condition.conditions[index].isConditionOpen = revertBoolean;
        } else {
          state.condition.conditions.forEach((condition, idx) => {
            if (index === idx) {
              return (condition.isConditionOpen = revertBoolean);
            }
            return (condition.isConditionOpen = !revertBoolean);
          });
        }
      }
    },
    isConditionError: (state, action) => {
      const { index, outerIndex, errorState } = action.payload;
      const isNested = isConditionNested(state.condition);

      if (isNested) {
        if (outerIndex + 1 > state.condition.conditions.length) {
          return state;
        }
        if (
          index + 1 >
          state.condition.conditions[outerIndex].conditions.length
        ) {
          return state;
        }
        state.condition.conditions[outerIndex].conditions[index].isError =
          errorState;
      } else {
        if (index + 1 > state.condition.conditions.length) {
          return;
        } else {
          state.condition.conditions[index].isError = errorState;
        }
      }
    },
    replaceRuleCondition: (state, action) => {
      const { attribute, comparison, value, index, outerIndex } =
        action.payload;
      state.dirty = true;

      const isNested = isConditionNested(state.condition);
      const newCondition = {
        attribute,
        comparison,
        value: value ? value : null,
        isError: false,
      };

      if (isNested) {
        state.condition.conditions[outerIndex].conditions[index] = newCondition;
      } else {
        state.condition.conditions[index] = newCondition;
      }
    },
    deleteRuleCondition: (state, action) => {
      const { index, outerIndex } = action.payload;
      const isNested = isConditionNested(state.condition);
      state.dirty = true;

      if (isNested) {
        if (
          state.condition.conditions.length === 2 &&
          index === 0 &&
          state.condition.conditions[outerIndex].conditions.length === 1
        ) {
          const operator =
            state.condition.conditions[outerIndex === 0 ? 1 : 0].operator;
          const conditions = [
            ...state.condition.conditions[outerIndex === 0 ? 1 : 0].conditions,
          ];
          state.condition.conditions = [];
          state.condition.conditions = conditions;
          state.condition.operator = operator;
          return;
        }
        if (
          index === 0 &&
          state.condition.conditions[outerIndex].conditions.length === 1
        ) {
          state.condition.conditions.splice(outerIndex, 1);
          return;
        }
        state.condition.conditions[outerIndex].conditions.splice(index, 1);
      } else {
        state.condition.conditions.splice(index, 1);
      }
    },
    changeConditionOperator: (state, action) => {
      const { operator, outerIndex } = action.payload;
      const isNested = isConditionNested(state.condition);
      state.dirty = true;
      if (isNested) {
        state.condition.conditions[outerIndex].operator = operator;
      } else {
        state.condition.operator = operator;
      }
    },
    changeOuterConditionOperator: (state, action) => {
      const { operator } = action.payload;
      state.dirty = true;
      state.condition.operator = operator;
    },
    changeOuterOperatorHover: (state, action) => {
      const { isHover } = action.payload;
      state.condition.isHover = isHover;
    },
    addRuleGroup: (state, action) => {
      const { attribute, comparison, value, outerIndex } = action.payload;
      state.dirty = true;
      const isNested = isConditionNested(state.condition);
      if (isNested) {
        state.condition.conditions[outerIndex + 1] = {
          operator: 'or',
          conditions: [
            {
              attribute,
              comparison,
              value,
              isConditionOpen: true,
              isError: false,
            },
          ],
        };
      } else {
        if (state.condition.conditions.length === 0) {
          state.condition.conditions[0] = {
            attribute,
            comparison,
            value,
            isConditionOpen: true,
            isError: false,
          };
        } else {
          const condition = { ...state.condition };
          state.condition.conditions = [];
          state.condition.conditions[0] = condition;
          state.condition.conditions[1] = {
            operator: 'or',
            conditions: [
              {
                attribute,
                comparison,
                value,
                isConditionOpen: true,
                isError: false,
              },
            ],
          };
        }
      }
    },

    // Actions
    addAction: (state, action) => {
      const { type, isOpen } = action.payload;
      state.dirty = true;
      state.actions = [...state.actions, { type, isOpen }];
    },
    replaceAction: (state, action) => {
      const {
        brain_parent_id,
        brain_version,
        index,
        type,
        tag,
        bundle_id,
        department_id,
        collection_id,
      } = action.payload;
      state.dirty = true;
      if (type === ActionType.ADD_TAG) {
        state.actions[index] = {
          type,
          tag,
        };
        return;
      }
      if (type === ActionType.APPLY_BUNDLE) {
        state.actions[index] = {
          type,
          bundle_id,
        };
        return;
      }
      if (type === ActionType.ASSIGN_COLLECTION) {
        state.actions[index] = {
          type,
          collection_id,
        };
        return;
      }
      if (type === ActionType.ASSIGN_DEPARTMENT) {
        state.actions[index] = {
          type,
          department_id,
        };
        return;
      }
      state.actions[index] = {
        brain_parent_id,
        brain_version,
        type,
      };
    },
    removeAction: (state, action) => {
      state.dirty = true;
      state.actions = state.actions.filter((_, i) => i !== action.payload);
    },
    revertIsActionOpen: (state, action) => {
      const { index, isOpenState } = action.payload;
      state.actions[index].isOpen = isOpenState;
      if (!isOpenState) {
        state.actions[index].isOpen = isOpenState;
      } else {
        state.actions.forEach((action, idx) => {
          if (index === idx) {
            return (action['isOpen'] = isOpenState);
          }
          return (action['isOpen'] = !isOpenState);
        });
      }
    },
    isActionError: (state, action) => {
      const { index, errorState } = action.payload;
      if (index + 1 > state.actions.length) {
        return;
      } else {
        state.actions[index].isError = errorState;
      }
    },
    // utils
    revertDirty: (state) => {
      state.dirty = false;
    },
    resetDefaultRule: () => defaultState,
  },
});

export const {
  addTrigger,
  removeTrigger,
  replaceTrigger,
  addRuleCondition,
  replaceRuleCondition,
  changeConditionOperator,
  changeOuterConditionOperator,
  deleteRuleCondition,
  addRuleGroup,
  addAction,
  replaceAction,
  setDraftRule,
  revertDirty,
  removeAction,
  isConditionOpen,
  isConditionError,
  revertIsActionOpen,
  changeOuterOperatorHover,
  isActionError,
  revertIsTriggerOpen,
  isTriggerError,
  resetDefaultRule,
} = slice.actions;

export default slice.reducer;
