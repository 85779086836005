import { Icon } from '@/models/icon';

const ToolTag = ({ size = 32, color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.4533 17.8793L17.8933 27.4393C17.6456 27.6873 17.3515 27.884 17.0278 28.0182C16.7041 28.1524 16.3571 28.2214 16.0066 28.2214C15.6562 28.2214 15.3092 28.1524 14.9855 28.0182C14.6617 27.884 14.3676 27.6873 14.12 27.4393L2.66663 15.9993V2.66602H16L27.4533 14.1193C27.95 14.619 28.2287 15.2949 28.2287 15.9993C28.2287 16.7038 27.95 17.3797 27.4533 17.8793V17.8793Z" />
      <path d="M9.33337 9.33398H9.34587" />
    </svg>
  );
};

export default ToolTag;
