import { shallowEqual, useSelector } from 'react-redux';

import { Requisite as RequisiteType } from '@/models/node';
import { RootState } from '@/models/state';
import { selectRequisites } from '@/redux/dialogs/selectors';

import Requisite from './Requisite';

import './RequisiteList.scss';

interface Props {
  nodeId: string;
}

function RequisiteList({ nodeId }: Props) {
  const requisites = useSelector(
    (state: RootState) => selectRequisites(state, nodeId),
    shallowEqual
  );

  if (!requisites || requisites.length === 0) {
    return null;
  }

  return (
    <>
      {requisites.map((requisite: RequisiteType, index: number) => (
        <Requisite
          key={requisite.requisite_id}
          nodeId={nodeId}
          requisite={requisite}
          index={index}
        />
      ))}
    </>
  );
}

export default RequisiteList;
