import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Banner } from '@/components/atoms/Banner/Banner';

import styles from './styles.module.scss';

type ContentProps = {
  children: React.ReactNode;
  fullHeight?: boolean;
  gutters?: boolean;
  newPlain?: boolean;
  newPlain2?: boolean;
  newPlain3?: boolean;
  noGutters?: boolean;
  whiteBackground?: boolean;
  readOnly?: boolean;
  hasBreadcrumbs?: boolean;
};

const PageContentWrapper = ({
  children,
  readOnly,
  hasBreadcrumbs,
  fullHeight = false,
  gutters = false,
  newPlain = false,
  newPlain2 = false,
  newPlain3 = false,
  noGutters = false,
  whiteBackground = false,
}: ContentProps) => {
  const { t } = useTranslation();
  if (readOnly) {
    return (
      <div style={{ display: 'inline' }}>
        <Banner variant="neutral" relativePosition centered padding="small">
          {t('permissions.read_only')}
        </Banner>

        <div
          className={cn(styles.content, {
            [styles.fullHeight]: fullHeight,
            [styles.newPlain]: newPlain,
            [styles.newPlain2]: newPlain2,
            [styles.newPlain3]: newPlain3,
            [styles.gutters]: gutters,
            [styles.noGutters]: noGutters,
            [styles.readOnly]: readOnly,
            [styles.whiteBackground]: whiteBackground,
            [styles.hasBreadcrumbs]: hasBreadcrumbs,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={cn(styles.content, {
          [styles.fullHeight]: fullHeight,
          [styles.newPlain]: newPlain,
          [styles.newPlain2]: newPlain2,
          [styles.newPlain3]: newPlain3,
          [styles.gutters]: gutters,
          [styles.noGutters]: noGutters,
          [styles.readOnly]: readOnly,
          [styles.whiteBackground]: whiteBackground,
          [styles.hasBreadcrumbs]: hasBreadcrumbs,
        })}
      >
        {children}
      </div>
    </>
  );
};

export default PageContentWrapper;
