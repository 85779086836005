import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon';

type Form = {
  loyalty: string;
};

export const Loyalty = () => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();

  // RHF
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<Form>({
    mode: 'onSubmit',
    values: {
      loyalty: brain?.guidelines?.loyalty || '',
    },
  });

  const onSubmit = (data: Form) => {
    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        loyalty: data.loyalty,
      },
    });
  };

  return (
    <FormCard id="ai-agent-loyalty" onSubmit={handleSubmit(onSubmit)}>
      <FormCard.Header
        title={t('ai_agents.knowledge.loyalty.title')}
        subtitle={t('ai_agents.knowledge.loyalty.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={3}
          />
        }
      />

      <FormCard.Content>
        <TextAreaAsInput
          height="large"
          label={t('ai_agents.navigation.overview')}
          {...register('loyalty', {
            maxLength: {
              value: 250,
              message: t('validation.max_length', { max: 250 }),
            },
          })}
          placeholder={t('ai_agents.knowledge.loyalty.placeholder')}
          error={!!errors.loyalty}
          errorMessage={capitalizeFirstLetter(errors.loyalty?.message)}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
