interface Props {
  active?: boolean;
}
const MoveoPulse = ({ active = true }: Props) => {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
        width="80"
        height="80"
        fill="none"
      >
        <rect width="80" height="80" fill="#D0E8FB" fillOpacity=".65" rx="40">
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="0.1;0.65;0.1"
            dur="1.1s"
            repeatCount="indefinite"
          />
        </rect>
        <rect width="54" height="54" x="13" y="13" fill="#D0E8FB" rx="27" />
        <path
          fill="#1B66D6"
          d="M41.819 35.35c-2.703 0-4.894-2.317-4.894-5.175 0-2.858 2.191-5.175 4.894-5.175h9.787c2.703 0 4.894 2.317 4.894 5.175 0 2.858-2.191 5.175-4.894 5.175H41.82ZM37.245 47.733c0 4.013-3.077 7.267-6.873 7.267-3.795 0-6.872-3.254-6.872-7.267 0-4.014 3.077-7.267 6.872-7.267 3.796 0 6.873 3.253 6.873 7.267ZM31.518 30.046c0 2.342-1.795 4.24-4.01 4.24-2.213 0-4.008-1.898-4.008-4.24 0-2.34 1.795-4.239 4.009-4.239 2.214 0 4.009 1.898 4.009 4.24ZM50.67 47.531c0 2.341-1.795 4.24-4.009 4.24-2.214 0-4.009-1.899-4.009-4.24s1.795-4.24 4.01-4.24c2.213 0 4.008 1.899 4.008 4.24Z"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      width="80"
      height="80"
      fill="none"
    >
      <rect
        width="80"
        height="80"
        y=".578"
        fill="#DEDEDE"
        fillOpacity=".5"
        rx="40"
      />
      <path
        fill="var(--icon-default-gray)"
        d="M41.819 35.928c-2.703 0-4.894-2.317-4.894-5.175 0-2.858 2.191-5.175 4.894-5.175h9.787c2.703 0 4.894 2.317 4.894 5.175 0 2.858-2.191 5.175-4.894 5.175H41.82ZM37.245 48.31c0 4.014-3.077 7.268-6.873 7.268-3.795 0-6.872-3.254-6.872-7.267 0-4.014 3.077-7.267 6.872-7.267 3.796 0 6.873 3.253 6.873 7.267ZM31.518 30.625c0 2.34-1.795 4.239-4.01 4.239-2.213 0-4.008-1.898-4.008-4.24 0-2.34 1.795-4.239 4.009-4.239 2.214 0 4.009 1.898 4.009 4.24ZM50.67 48.11c0 2.34-1.795 4.238-4.009 4.238-2.214 0-4.009-1.898-4.009-4.239s1.795-4.239 4.01-4.239c2.213 0 4.008 1.898 4.008 4.24Z"
      />
    </svg>
  );
};

export default MoveoPulse;
