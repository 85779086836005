import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const House = forwardRef<SVGSVGElement, Icon>(
  ({ size = 14, color = 'var(--icon-default-gray)', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          fillRule="evenodd"
          d="M12.46 1.408a.75.75 0 0 0-.92 0l-9 7A.75.75 0 0 0 2.25 9v11A2.75 2.75 0 0 0 5 22.75h14A2.75 2.75 0 0 0 21.75 20V9a.75.75 0 0 0-.29-.592l-9-7Zm3.29 19.842H19A1.25 1.25 0 0 0 20.25 20V9.367L12 2.95 3.75 9.367V20A1.25 1.25 0 0 0 5 21.25h3.25V12a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75v9.25Zm-6 0v-8.5h4.5v8.5h-4.5Z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
);

House.displayName = 'House';

export default House;
