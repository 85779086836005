import { useCallback, useEffect, useMemo, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Edit from '@/components/atoms/Icons/Edit';
import MemberAdd from '@/components/atoms/Icons/MemberAdd';
import MemberRemove from '@/components/atoms/Icons/MemberRemove';
import MoreHorizontal from '@/components/atoms/Icons/MoreHorizontal';
import Teammates from '@/components/atoms/Icons/Teammates';
import Trash from '@/components/atoms/Icons/Trash';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import Table from '@/components/atoms/Table/Table';
import {
  MODAL_DELETE,
  MODAL_TEAM,
  MODAL_TEAM_MEMBERS,
} from '@/components/organisms/Modals/ModalConductor';
import { useInvitations } from '@/hooks/useInvitations';
import { useRoles } from '@/hooks/useRoles';
import { useTeams } from '@/hooks/useTeams';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { Team } from '@/models/team';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { trackEvent } from '@/segment/segment';
import { ADMIN, CHAT_AGENT, CHAT_MANAGER, BUILDER } from '@/util/constants';
import { delay } from '@/util/util';

import styles from './Teams.module.scss';

export const Teams = () => {
  const { t } = useTranslation();
  const { deleteTeam } = useTeams();
  const { accountInvitations } = useInvitations();
  const { teams } = useTeams();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [options, setOptions] = useState([]);
  const { roles } = useRoles();
  const dispatch = useDispatch();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'teams', actions.WRITE)
  );

  const [selectedTeam, setSelectedTeam] = useState<Team>(null);

  useEffect(() => {
    if (teams) {
      setOptions(teams);
    }
  }, [teams]);

  const handleFilterChange = useCallback(
    (value: string) => {
      if (value === 'no_role') {
        const updatedTeams = teams.filter(
          ({ role_ids }) => role_ids.length === 0
        );

        setOptions(updatedTeams);
      } else if (value === 'all') {
        setOptions(teams);
      } else {
        const updatedTeams = teams.filter(({ role_ids }) =>
          role_ids.includes(value)
        );
        setOptions(updatedTeams);
      }
    },
    [teams]
  );

  const handleEditTeam = useCallback(() => {
    trackEvent(EventName.ClickEditAccessTeam);
    dispatch(pushModal(MODAL_TEAM, { team: selectedTeam }));

    setAnchorEl(null);
  }, [dispatch, selectedTeam]);

  const handleEditMembers = (type: 'add' | 'remove') => {
    setAnchorEl(null);
    dispatch(pushModal(MODAL_TEAM_MEMBERS, { team: selectedTeam, type }));
  };

  const handleDeleteTeam = useCallback(() => {
    const deleteProps = {
      subtitle: (
        <Trans i18nKey="teams.delete_subtitle" values={[selectedTeam?.name]} />
      ),
      title: t('teams.delete'),
      confirm: true,
      onDelete: () => {
        deleteTeam(
          { team_id: selectedTeam?.team_id },
          {
            onSuccess: () => {
              dispatch(popModal());
            },
          }
        );
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
    setAnchorEl(null);
  }, [deleteTeam, dispatch, selectedTeam?.name, selectedTeam?.team_id, t]);

  const handleClose = useCallback(async () => {
    setAnchorEl(null);
    await delay(200);
    setSelectedTeam(null);
  }, []);

  const handleClick = useCallback((event, i) => {
    setSelectedTeam(i);
    setAnchorEl(event.currentTarget);
  }, []);

  const getColumns = useMemo(
    () => [
      {
        Header: t('common.name'),
        accessor: 'name',
        Cell: (props) => {
          const { member_count, team_id } = props.row.original;
          const numberOfInvitations =
            accountInvitations?.filter((inv) => inv.team_ids.includes(team_id))
              .length ?? 0;
          const style =
            props.row.original.role_ids.length > 0 ? 'primary' : 'secondary';
          return (
            <div className={styles.listItem}>
              <div className={cn(styles.teamIcon, styles[style])}>
                <Teammates />
              </div>
              <div className={styles.infoContainer}>
                <span>{props.value}</span>
                <span>
                  {' '}
                  {t('teams.members_count', { 0: member_count })}{' '}
                  {numberOfInvitations > 0 &&
                    ` • ${numberOfInvitations} invitations`}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: t('common.description'),
        accessor: 'description',
        disableSortBy: true,
      },
      {
        Header: t('teams.roles'),
        accessor: 'role_ids',
        disableSortBy: true,
        Cell: (props) => {
          const value: string[] = props.value;
          const roleNames = value?.map((role_id: string) => {
            const name = roles?.find((role) => role.role_id === role_id)?.name;
            return name;
          });
          return roleNames?.join(', ') || '-';
        },
      },

      {
        Header: '',
        id: 'menu',
        Cell: (props) => {
          const data = props.row.original as Team;
          return (
            <IconButton
              onClick={(e) => handleClick(e, data)}
              disabled={!canWrite}
              ariaLabel={t('teams.options')}
              ariaHasPopUp
            >
              <MoreHorizontal size={24} />
            </IconButton>
          );
        },
      },
    ],
    [accountInvitations, canWrite, handleClick, roles, t]
  );

  const filterOptions = useMemo(() => {
    const options = [
      {
        value: 'all',
        label: t('roles.filters.all'),
      },
      ...(roles
        ?.filter((r) =>
          [ADMIN, CHAT_AGENT, CHAT_MANAGER, BUILDER].includes(r.role_id)
        )
        .map((r) => ({
          label: r.name,
          value: r.role_id,
        })) ?? []),
      {
        value: 'no_role',
        label: t('roles.filters.no_role'),
      },
    ];

    return options;
  }, [roles, t]) as { value: string; label: string }[];

  if (!teams) {
    return null;
  }

  return (
    <>
      <PlainFieldset fullWidth isLoading={false}>
        <Table
          data={options}
          columns={getColumns}
          sortable
          filterable
          filterOptions={filterOptions}
          onFilterChange={handleFilterChange}
          searchPlaceholder={t('teams.search_placeholder')}
        />
      </PlainFieldset>
      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        disableRestoreFocus
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleEditMembers('add')}>
          <MemberAdd />
          {t('teams.add_members')}
        </MenuItem>
        <MenuItem
          disabled={selectedTeam?.member_count == 0}
          onClick={() => handleEditMembers('remove')}
        >
          <MemberRemove />
          {t('teams.remove_members')}
        </MenuItem>
        <MenuItem onClick={handleEditTeam}>
          <Edit />
          {t('teams.edit')}
        </MenuItem>
        <MenuItem onClick={handleDeleteTeam} className={styles.danger}>
          <Trash />
          {t('teams.delete')}
        </MenuItem>
      </CustomMenu>
    </>
  );
};
