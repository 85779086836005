export const BreadcrumpIcon = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      fill="none"
      viewBox="0 0 8 8"
    >
      <circle cx="4" cy="4" r="3.5" fill={fill} stroke={stroke} />
    </svg>
  );
};
