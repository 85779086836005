import { Icon } from '@/models/icon';

const ArrowUp = ({ size = 6, color = 'var(--icon-default-black)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 10 6"
      width={size + 4}
      height={size}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path opacity="0.64" d="M7 4L4 1L1 4" />
    </svg>
  );
};

export default ArrowUp;
