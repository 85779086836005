import { ReactNode, useCallback, useState } from 'react';

import Close from '@mui/icons-material/Close';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Link from '@/components/atoms/Link/Link';
import useLocalStorage from '@/hooks/useLocalStorage';
import { selectIframeView } from '@/redux/user/selectors';

import styles from './ContextualHelp.module.scss';

export interface LinkType {
  label: string;
  url: string;
  icon?: ReactNode;
}

export interface ContextualHelpProps {
  children: React.ReactNode | string;
  name: string;
  title: string;
  links?: LinkType[];
  isSticky?: boolean;
  size?: 'medium' | 'full';
  preventClose?: boolean;
  onDismiss?: () => void;
  icon?: React.ReactNode;
}

const ContextualHelp = ({
  children,
  name,
  title,
  links,
  isSticky,
  size = 'full',
  preventClose,
  onDismiss,
}: ContextualHelpProps) => {
  // localDismiss "remembers" if banner was closed. temporalDismiss only dismisses banner if
  // the banner is sticky and will re-show after refresh
  const [localDismiss, setLocalDismiss] = useLocalStorage(
    `banners.${name}`,
    false
  );
  const [temporalDismiss, setTemporalDismiss] = useState(false);

  const handleDismiss = useCallback(() => {
    onDismiss && onDismiss();

    isSticky ? setTemporalDismiss(true) : setLocalDismiss(true);
  }, [isSticky, setLocalDismiss, onDismiss]);

  const isIframeView = useSelector(selectIframeView);

  if (localDismiss || temporalDismiss) {
    return null;
  }
  return !isIframeView ? (
    <div className={cn(styles.box, styles[size])}>
      {title && <p className={styles.title}>{title}</p>}
      <div className={cn(styles.children)}>{children}</div>
      {links && (
        <div className={styles.links}>
          {links.map((link) => {
            return (
              <Link key={link.label} href={link.url} external>
                {link?.icon && <span className={styles.icon}>{link.icon}</span>}
                {link.label}
              </Link>
            );
          })}
        </div>
      )}
      {!preventClose && (
        <div className={styles.buttonContainer}>
          <IconButton onClick={handleDismiss} ariaLabel="Close help">
            <Close fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  ) : null;
};

export default ContextualHelp;
