import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import Bundle from '@/components/atoms/Icons/Bundle';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';

export default function CreateBundleTile({ onClick, readOnly }) {
  const { t } = useTranslation();

  return (
    <TileEmptyPage
      title={t('bundles.no_bundle_tile.title')}
      notClickable
      body={
        <>
          {t('bundles.no_bundle_tile.text')} <br />{' '}
          <Button
            onClick={onClick}
            variant="tertiary"
            underline
            disabled={readOnly}
          >
            {t('bundles.no_bundle_tile.button')}
          </Button>
        </>
      }
      icon={<Bundle color="var(--icon-default-blue)" size={100} />}
    />
  );
}
