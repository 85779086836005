import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import Chip from '@/components/atoms/Chip/Chip';
import { TagAction } from '@/models/action';

import styles from './Tag.module.scss';

interface TagProps {
  action: TagAction;
}
const Tag = ({ action }: TagProps) => {
  const { t } = useTranslation();
  const { tags = [], operation = 'add' } = action;

  return (
    <>
      <p>{t(`dialog.tags.${operation}_tags`)}</p>

      {operation !== 'clear' && (
        <>
          {tags.map((tag) => (
            <Chip
              className={styles.tag}
              key={tag}
              size="small"
              label={tag}
              customVariant={operation === 'add' ? 'highlighted' : 'neutral'}
            />
          ))}

          {isEmpty(tags) && <p>{t('dialog.no_tags')}</p>}
        </>
      )}
    </>
  );
};

export default Tag;
