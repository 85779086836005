import { IntegrationType } from './integration';
import { LogsQueryVariables } from '../../client/modules/analytics/models';

/**
 * Every page should have a Camel Case name
 */
export const enum PageName {
  ACCOUNT_DELETE = 'Account Delete',
  ACCOUNT_SETTINGS = 'Account Settings',
  AI_AGENTS = 'AI Agents',
  ANALYTICS_OVERVIEW = 'Analytics Overview',
  ANALYTICS_USAGE = 'Analytics Usage',
  ANALYTICS_BRAIN_EFFECTIVENESS = 'Analytics Brain Effectiveness',
  ANALYTICS_HUMAN_EFECTIVENESS = 'Analytics Human Agent Effectiveness',
  ANALYTICS_AGENTS_PERFORMANCE = 'Analytics Agents Performance',
  ANALYTICS_ACCOUNT_CHAT_LOGS = 'Analytics Account Chat Logs',
  ANALYTICS_CUSTOMER_SATISFACTION = 'Analytics Customer Satisfaction',
  BILLING = 'Billing',
  BRAIN_LOGS = 'Brain Logs',
  BRAIN_SETTINGS = 'Brain Settings',
  BRAINS = 'Brains',
  BROADCASTS = 'Broadcasts',
  BROADCAST = 'Broadcast',
  BUNDLE = 'Bundle',
  BUNDLES = 'Bundles',
  BUSINESS_HOURS = 'Business Hours',
  CHATS = 'Chats',
  COLLECTION = 'Collection',
  COLLECTIONS = 'Collections',
  CONVERSATION = 'Conversation',
  CONVERSATIONS = 'Conversations',
  DATASOURCE = 'Datasource',
  DATASOURCES = 'Datasources',
  DATA_PROCESS = 'Data Process',
  DESK_SETTINGS = 'Desk Settings',
  DIALOG = 'Dialog',
  DIALOGS = 'Dialogs',
  ENTITIES = 'Entities',
  ENTITY = 'Entity',
  ERROR_500 = 'Error_500',
  HOME = 'Home',
  INVITATION = 'Invitation',
  INTEGRATION = 'Integration',
  INTEGRATIONS = 'Integrations',
  INTENT = 'Intent',
  INTENTS = 'Intents',
  LOGIN = 'Login',
  NOT_FOUND = 'Not Found',
  ONBOARDING = 'Onboarding',
  ONBOARDING_ERROR = 'Onboarding Error',
  PERSONAL_INVITATIONS = 'Personal Invitations',
  PERSONAL_NOTIFICATIONS = 'Personal Notifications',
  PERSONAL_PROFILE = 'Personal Profile',
  RULE = 'Rule',
  RULES = 'Rules',
  SIGNUP = 'Signup',
  UNAUTHORIZED = 'Unauthorized',
  VERSIONS = 'Versions',
  WEBHOOK = 'Webhook',
  WEBHOOK_DRAFT = 'Webhook Draft',
  WEBHOOKS = 'Webhooks',
  WELCOME = 'Welcome',
}

/**
 * The event names to send to Segment. Make sure you pick a category. Either
 * * Click - when a user clicks on something
 * * View - when a user views something
 * * Run - when a user runs a process
 * * Open - when a user opens something like a menu or modal
 * * Select - when a user selects something from a dropdown
 */

export enum EventName {
  AutoDialogCompleted = 'AutoDialog Completed',
  AutoDialogError = 'Onboarding Error',
  ClickAddTrainingPhrase = 'Click Add Training Phrase',
  ClickApplyBrain = 'Click Apply Brain',
  ClickBuildBrainGoal = 'Click Build Brain Goal',
  ClickChangelog = 'Click Changelog',
  ClickCloseOnboarding = 'Click Close Onboarding',
  ClickCopyBrainId = 'Click Copy Brain Id',
  ClickCopyCollectionId = 'Click Copy Collection Id',
  ClickCopyDatasourceId = 'Click Copy Datasource Id',
  ClickCopyDocumentId = 'Click Copy Document Id',
  ClickCreateAccessTeam = 'Click Create Access Team',
  ClickCreateAccount = 'Click Create Account',
  ClickCreateAutoDialog = 'Click Create Auto Dialog',
  ClickCreateAIAgent = 'Click Create AI Agent',
  ClickCreateBrain = 'Click Create Brain',
  ClickCreateBroadcast = 'Click Create Broadcast',
  ClickCreateCollection = 'Click Create Collection',
  ClickCreateDatasource = 'Click Create Datasource',
  ClickCreateDocument = 'Click Create Document',
  ClickEditFile = 'Click Edit File',
  ClickReplaceFile = 'Click Replace File',
  ClickCreateManualDialog = 'Click Create Manual Dialog',
  ClickCreateTeam = 'Click Create Team',
  ClickDeleteBrain = 'Click Delete Brain',
  ClickDeleteInvitation = 'Click Delete Invitation',
  ClickDeleteMember = 'Click Delete Member',
  ClickDeleteWebhook = 'Click Delete Webhook',
  ClickDocumentation = 'Click Documentation',
  ClickEditAccessTeam = 'Click Edit Access Team',
  ClickEditMember = 'Click Edit Member',
  ClickEditTeam = 'Click Edit Team',
  ClickEditWebhook = 'Click Edit Webhook',
  ClickEditDatasource = 'Click Edit Datasource',
  ClickExportDocument = 'Click Export Document',
  ClickEditCollection = 'Click Edit Collection',
  ClickEditBroadcast = 'Click Edit Broadcast',
  ClickSyncDatasource = 'Click Sync Datasource',
  ClickEmailNotification = 'Toggle Weekly Email',
  ClickExportBrain = 'Click Export Brain',
  ClickLoginWithEmail = 'Click Login with Email',
  ClickLoginWithFacebook = 'Click Login with Facebook',
  ClickLoginWithGoogle = 'Click Login with Google',
  ClickImportBrain = 'Click Import Brain',
  ClickOnboardingConnect = 'Click Connect From Onboarding',
  ClickOnboardingDatasource = 'Click Datasource From Onboarding',
  ClickOnboardingFlow = 'Click Flow From Onboarding',
  ClickOnboardingTemplate = 'Click Template From Onboarding',
  ClickOnboardingUpload = 'Click Upload From Onboarding',
  ClickPlatformStatus = 'Click Platform Status',
  ClickTransferAccount = 'Click Transfer Account',
  ClickPlayVideo = 'Click Play Video',
  ClickSharedInboxGoal = 'Click Shared Inbox Goal',
  ClickSignupWithEmail = 'Click Signup with Email',
  ClickSignupWithFacebook = 'Click Signup with Facebook',
  ClickSignupWithGoogle = 'Click Signup with Google',
  ClickStartFromScratch = 'Click Start From Scratch',
  ClickStartFromTemplate = 'Click Start From Template',
  ClickSubmitAutoDialogPrompt = 'EventName Submit Auto Dialog Prompt',
  ClickTryIt = 'Click Try It',
  UploadBroadcastFile = 'Upload Broadcast File',
  ClickBroadCastSendNow = 'Click Broadcast Send Now',
  ClickBroadCastSchedule = 'Click Broadcast Schedule',
  ClickAnalyticsLogsExport = 'Click Analytics Logs Export',
  OnboardingCompleted = 'Onboarding Completed',
  OpenHelpMenu = 'Open Help Menu',
  OpenUserMenu = 'Open User Menu',
  RunAnalyticsExport = 'Run Analytics Export',
  RunDocumentsUpload = 'Run Documents Upload',
  RunFileUpload = 'Run File Upload',
  RunOnboardingCompleted = 'Run Onboarding Completed',
  SelectAccount = 'Select Account',
  SelectOnboardingBrainLanguage = 'Select Onboarding Brain Language',
  SelectOnboardingIntegrations = 'Select Onboarding Integrations',
  SendTryItMessage = 'Send Try It Message',
  ClickUpgradePlan = 'Click Upgrade Plan',

  //Checklist
  what_is_collection = 'Checklist Create Collection',
  import_content = 'Checklist Create Datasource',
  connect_brain = 'Checklist Connect Collection',
  what_is_brain = 'Checklist Brain Video',
  create_intent = 'Checklist Create Intent',
  generate_dialog = 'Checklist Create Dialog',
  test_dialog = 'Checklist Test Dialog',
  create_version = 'Checklist Create Version',
  activate_channels = 'Checklist Activate Channel',
  update_rule = 'Checklist Activate Rule',
  analytics_overview = 'Checklist Analytics Overview',
  receive_messages = 'Checklist Receive Messages',
  completed = 'Checklist Complete',

  // Analytics
  analytics_filters = 'Analytics Filters',
}

export type EventProps = Partial<{
  brain_id: string;
  webhook_id: string;
  session_id: string;
  rule_id: string;
  desk_id: string;
  team_id: string;
  collection_id: string;
  broadcast_id: string;
  datasource_id: string;
  document_id: string;
  language: string;
  chat: boolean;
  brain: boolean;
  invitations: number;
  title: string;
  type: string;
  weekly_email: boolean;
  integration_id: string;
  pathname: string;
  step: string;
  video_url: string;
  error: string;
  datasource_url: string;
  flow: 'brain' | 'collections';
  integrations: IntegrationType[];
  number: number;
  filters: LogsQueryVariables;
}>;
