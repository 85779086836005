import { PageName } from '@/models/segment';

import Containment from '../components/fieldsets/ContainmentFieldset/ContainmentFieldset';
import Coverage from '../components/fieldsets/CoverageFieldset/CoverageFieldset';
import Filters from '../components/filters/AccountFilters/AccountFilters';
import useSegments from '../hooks/useSegment';

export const BrainEffectivenessReport = () => {
  useSegments(PageName.ANALYTICS_BRAIN_EFFECTIVENESS);
  return (
    <>
      <Filters />
      <Coverage />
      <Containment />
    </>
  );
};
