import { forwardRef } from 'react';

import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ArrowDownIcon from '@/components/atoms/Icons/ArrowDown';

import Button from '../Button/Button/Button';

import styles from './Accordion.module.scss';

export interface AccordionProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: string;
  onSubmit?: () => void;
  footerText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  expanded?: boolean;
  handleChange?: (_event, isExpanded: boolean) => void;
  disableFooter?: boolean;
  nonExpandable?: boolean;
  readOnly?: boolean;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
}

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      children,
      title,
      subtitle,
      onSubmit,
      footerText,
      disabled,
      isLoading,
      expanded,
      handleChange,
      disableFooter,
      nonExpandable,
      readOnly,
      secondaryButtonText,
      onSecondaryButtonClick,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const muiStyles = {
      accordionRoot: {
        boxSizing: 'border-box',
        backgroundColor: 'var(--surface-primary-white)',
        border: '1px solid var(--border-default-gray)',
        width: '600px',
        marginBottom: 'var(--space-16)',
        boxShadow: 'none',
        transitionDuration: '200ms',
        scrollMarginTop: 'var(--space-16)',

        '&.MuiAccordion-rounded': {
          borderRadius: 'var(--border-radius-base)',
        },

        '&::before': {
          height: '0',
        },

        '&.MuiAccordion-root.Mui-expanded:first-of-type': {
          scrollMarginTop: 'var(--space-36)',
        },

        '&.MuiAccordion-root.Mui-expanded:last-child': {
          marginBottom: 'var(--space-16)',
        },
      },

      defaultCursor: {
        pointerEvents: 'none',
      },

      summaryRoot: {
        padding: 'var(--space-24) var(--space-24) calc(var(--space-24) - 2px)',
        justifyContent: 'space-between',
        ':hover': {
          backgroundColor: 'var(--surface-secondary-gray)',
        },

        '&.MuiAccordionSummary-root.Mui-expanded': {
          borderBottom: '1px solid var(--border-default-gray)',
        },
      },

      summaryExpandedIcon: {
        padding: '0',

        '&.Mui-expanded svg': {
          stroke: 'var(--icon-default-blue)',
        },
      },

      detailsRoot: {
        padding: 'var(--space-24)',
      },

      typographyRoot: {
        lineHeight: '1.5',
        display: 'block',
      },

      hiddenIcon: {
        display: 'none',
      },
    };
    return (
      <MuiAccordion
        sx={muiStyles.accordionRoot}
        expanded={expanded}
        onChange={
          !nonExpandable
            ? (event, isExpanded) => handleChange(event, isExpanded)
            : null
        }
        TransitionProps={{ timeout: 300 }}
        ref={ref}
      >
        <AccordionSummary
          expandIcon={
            <ArrowDownIcon size={24} data-testid="expand-accordion" />
          }
          sx={{
            ...muiStyles.summaryRoot,
            ...(nonExpandable ? muiStyles.defaultCursor : {}),
            '& .MuiAccordionSummary-expandIconWrapper': {
              ...muiStyles.summaryExpandedIcon,
              ...(nonExpandable ? muiStyles.hiddenIcon : {}),
            },
          }}
        >
          <div>
            {title && (
              <Typography
                component="span"
                variant="subtitle1"
                sx={muiStyles.typographyRoot}
              >
                {title}
              </Typography>
            )}

            {subtitle && <Typography variant="body">{subtitle}</Typography>}
          </div>
        </AccordionSummary>
        <AccordionDetails sx={muiStyles.detailsRoot}>
          {children}
        </AccordionDetails>
        {!disableFooter && (
          <div
            className={cn(styles.footer, {
              [styles.hasSecondaryButton]: secondaryButtonText,
              [styles.hasFooterText]: footerText,
            })}
          >
            {footerText && (
              <Typography variant="label-regular" className={styles.footerText}>
                {footerText}
              </Typography>
            )}
            <span className={styles.footerButtons}>
              {secondaryButtonText && (
                <Button
                  onClick={onSecondaryButtonClick}
                  variant="tertiary"
                  disabled={readOnly}
                >
                  {secondaryButtonText}
                </Button>
              )}
              <Button
                onClick={onSubmit}
                disabled={disabled || readOnly}
                isLoading={isLoading}
                data-testid="accordion-button"
              >
                {t('common.save')}
              </Button>
            </span>
          </div>
        )}
      </MuiAccordion>
    );
  }
);

Accordion.displayName = 'Accordion';

export default Accordion;
