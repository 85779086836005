import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Clock = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, color = 'currentColor', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
      >
        <path
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon_stroke"
        />
        <path
          d="M12 6v6l4 2"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon_stroke"
        />
      </svg>
    );
  }
);

Clock.displayName = 'Clock';

export default Clock;
