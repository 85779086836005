import { Icon } from '@/models/icon';

const Warning = ({
  size = 18,
  color = 'var(--icon-default-warning)',
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <circle cx="9" cy="9" r="9" fill={color} />
      <path
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        d="M9.1 4.6v5.2"
      />
      <circle cx="9" cy="13.5" r=".9" fill="#fff" />
    </svg>
  );
};

export default Warning;
