import { ChangeEventHandler, useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Option } from '@/components/atoms/AutoComplete/AutoComplete';
import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import { Banner } from '@/components/atoms/Banner/Banner';
import Chips from '@/components/atoms/Chips/Chips';
import Select from '@/components/atoms/Select/Select';
import ToolkitWrapper from '@/components/organisms/Toolkit/ToolkitWrapper';
import useDialogs from '@/hooks/useDialogs';
import { Action } from '@/models/action';
import { RootState } from '@/models/state';
import {
  updateDialogAlerts,
  clearDialogAlerts,
} from '@/redux/dialogAlerts/actions';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import {
  addVariable,
  removeVariable,
  updateAction,
} from '@/redux/nodes/actions';
import { capitalizeFirstLetter, removeDollarSign } from '@/util/util';
import { resetActionSchema } from '@/util/validator';

export const RESET_OPTIONS = {
  All: 'All',
  Specific: 'Specific',
} as const;

type Form = {
  resetOption?: typeof RESET_OPTIONS.All | typeof RESET_OPTIONS.Specific;
  variable: Option | null;
};

const defaultOption = {
  value: '',
  label: '',
};

const ToolkitActionReset = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { brainId } = useParams();
  const { getContextVariables, getCustomVariables } = useDialogs(brainId);
  const contextVariables = getContextVariables(false);
  const customVariables = getCustomVariables();

  // Reset Options
  const resetOptions = useMemo(
    () => [
      {
        value: RESET_OPTIONS.All,
        label: t('dialog.reset.all_custom_variables'),
      },
      {
        value: RESET_OPTIONS.Specific,
        label: t('dialog.reset.custom_variables'),
      },
    ],
    [t]
  );

  const { variables, actionId, type, autoCompleteValue } = useSelector(
    (state: RootState) => {
      const selectedAction = selectSelectedAction(state) as Extract<
        Action,
        { type: 'reset' }
      >;

      return {
        variables: selectedAction?.variables,
        actionId: selectedAction?.action_id,
        type: selectedAction?.type,
        autoCompleteValue: selectedAction?.autoCompleteValue,
      };
    },
    shallowEqual
  );
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  const suggestedVariables = contextVariables.filter(
    (variable) => !variables?.includes(removeDollarSign(variable.value)) || []
  );

  const autoCompleteVariables = useMemo(
    () =>
      suggestedVariables.map((option) => ({
        type: t('actions.types.reset_variables'),
        label: option.label,
        value: removeDollarSign(option.value),
      })),
    [suggestedVariables, t]
  );

  const {
    formState: { errors },
    register,
    control,
    watch,
    trigger,
    setValue,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      resetOption: variables ? RESET_OPTIONS.Specific : RESET_OPTIONS.All,
      variable: autoCompleteValue ?? defaultOption,
    },
    resolver: yupResolver(resetActionSchema) as Resolver<Form>,
  });
  const variableErrorMessage = capitalizeFirstLetter(
    errors?.variable?.message || errors?.variable?.label?.message
  );

  const watchResetOption = watch('resetOption');

  // Update redux store
  const saveNewVariable = useCallback(
    (newVariable: string) => {
      // Prevent user from saving the same variable twice
      if (variables?.includes(newVariable)) {
        return;
      }

      dispatch(
        addVariable({
          actionId,
          variable: newVariable,
        })
      );
    },
    [actionId, dispatch, variables]
  );

  const handleOptionChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      const variables =
        event.target.value === RESET_OPTIONS.All
          ? undefined
          : ['example_context_variable'];

      dispatch(
        updateAction({
          actionId,
          action: {
            variables,
          },
        })
      );
    },
    [actionId, dispatch]
  );

  const onVariableDelete = useCallback(
    (variable: string) => {
      const index = variables?.indexOf(removeDollarSign(variable));
      dispatch(
        removeVariable({
          actionId,
          index,
        })
      );
    },
    [variables, dispatch, actionId]
  );

  const renderResetVariables = useCallback(() => {
    return (
      <Box mt="var(--space-4)">
        <Chips
          chips={variables?.map((variable) => `$${variable}`)}
          onClick={(variable) => {
            onVariableDelete(variable);
          }}
        />
      </Box>
    );
  }, [variables, onVariableDelete]);

  const handleAutoCompleteChange = useCallback(
    async (_, variable) => {
      if (variable?.value[0] !== '$') {
        variable.value = '$' + variable.value;
      }
      const isValidInput = await trigger('variable.value');
      if (isValidInput) {
        setValue('variable', defaultOption);
        saveNewVariable(removeDollarSign(variable.value));
      }
    },
    [saveNewVariable, setValue, trigger]
  );

  const dispatchDialogError = useCallback(
    (body: string, field) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.reset'),
            body,
            type,
            alertField: field,
          },
        })
      );
    },
    [actionId, dispatch, parentNodeId, t, type]
  );

  useEffect(() => {
    trigger();
  }, [trigger, variables]);

  // Update dialog errors
  useEffect(() => {
    if (watchResetOption === RESET_OPTIONS.Specific) {
      const body =
        variables?.length === 0
          ? t('dialog.errors.at_least_one', {
              0: t('common.context_variable').toLowerCase(),
            })
          : undefined;

      dispatchDialogError(body, 'variables');
    } else {
      dispatch(
        clearDialogAlerts({
          id: actionId,
        })
      );
    }
  }, [
    actionId,
    dispatch,
    dispatchDialogError,
    t,
    variables?.length,
    watchResetOption,
  ]);

  return (
    <ToolkitWrapper type="reset">
      <Box mb="var(--space-24)">
        <Select
          size="full"
          options={resetOptions}
          register={register('resetOption')}
          onChange={handleOptionChange}
        />
      </Box>

      {watchResetOption === RESET_OPTIONS.All ? (
        <Banner
          relativePosition
          variant="neutral"
          title={t('dialog.reset.help_title')}
          options={customVariables.map((option) => option.label)}
        />
      ) : (
        <>
          <AutocompleteNew
            freeSolo
            options={autoCompleteVariables}
            enableNewEntry
            control={control}
            name="variable"
            label={t('dialog.reset.autocomplete_label')}
            groupByProp="type"
            groupByLabelProp={false}
            placeholder={t('dialog.reset.select_variable')}
            onChange={handleAutoCompleteChange}
            hasError={!!errors.variable}
            errorMessage={variableErrorMessage}
          />

          {variables?.length === 0 && (
            <Box mt="var(--space-24)">
              <Banner variant="critical" relativePosition>
                {t('dialog.errors.at_least_one', {
                  0: t('common.context_variable').toLowerCase(),
                })}
              </Banner>
            </Box>
          )}
        </>
      )}

      {renderResetVariables()}
    </ToolkitWrapper>
  );
};

export default ToolkitActionReset;
