import { useEffect, useMemo, useState, useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { AuditLogsType } from '@/models/auditLogs';
import { RootState } from '@/models/state';
import { AnalyticsType } from '@/modules/analytics/models';
import {
  applyFilters as applyFiltersInAuditLogs,
  setPeriod as setPeriodInAuditLogs,
  resetFilterCategory,
} from '@/redux/auditLogs/actions';
import { urlSafeBase64Decode, urlSafeBase64Encode } from '@/util/util';

import { applyFilters, setPeriod } from '../redux/actions';
import { selectEndDate, selectStartDate } from '../redux/selectors';

const useFilters = (
  type: AnalyticsType | AuditLogsType,
  setShowLogs?: (value: boolean) => void
) => {
  const navigate = useNavigate();
  const [dateOpen, setDateOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [previousState, setPreviousState] = useState(null);
  const dispatch = useDispatch();

  const isAnalyticsFilter =
    type === AnalyticsType.ACCOUNT || type === AnalyticsType.LOGS;
  const filterPage = isAnalyticsFilter ? 'analytics' : 'auditLogs';

  const startDate = useSelector((state: RootState) =>
    selectStartDate(state, filterPage)
  );
  const endDate = useSelector((state: RootState) =>
    selectEndDate(state, filterPage)
  );
  const dateFilters = useMemo(
    () => ({
      startDate,
      endDate,
    }),
    [endDate, startDate]
  );
  const filters = useSelector(
    (state: RootState) => state[filterPage][type],
    shallowEqual
  );
  const [urlParams] = useSearchParams();
  const isReplay = urlParams.get('session_id');
  const prev = usePrevious(filterOpen);

  const filterParam = useMemo(
    () =>
      urlParams.get('filters')
        ? urlSafeBase64Decode(urlParams.get('filters'))
        : '',
    [urlParams]
  );
  const dateParam = useMemo(
    () =>
      urlParams.get('date') ? urlSafeBase64Decode(urlParams.get('date')) : '',
    [urlParams]
  );

  useEffect(() => {
    if (setShowLogs) {
      if (filterOpen) {
        setShowLogs(false);
      } else {
        setShowLogs(true);
      }
    }
  }, [filterOpen, setShowLogs]);

  useEffect(() => {
    if (filterParam && !isEqual(filters, JSON.parse(filterParam))) {
      dispatch(
        isAnalyticsFilter
          ? applyFilters({
              type,
              filters: JSON.parse(filterParam),
            })
          : applyFiltersInAuditLogs({
              filters: JSON.parse(filterParam),
            })
      );
    } else if (!filterParam && isEmpty(filters) && (dateParam || isReplay)) {
      dispatch(
        isAnalyticsFilter
          ? applyFilters({
              type,
              filters: [],
            })
          : applyFiltersInAuditLogs({
              filters: [],
            })
      );
    }
  }, [
    dispatch,
    filters,
    type,
    filterParam,
    dateParam,
    isReplay,
    isAnalyticsFilter,
  ]);

  if (dateParam && !isEqual(dateFilters, JSON.parse(dateParam))) {
    dispatch(
      isAnalyticsFilter
        ? setPeriod(JSON.parse(dateParam))
        : setPeriodInAuditLogs(JSON.parse(dateParam))
    );
  }

  useEffect(() => {
    if (!filterParam && !dateParam && !isReplay) {
      navigate(
        {
          ...window.location,
          search: `${
            !isEmpty(dateFilters)
              ? `date=${urlSafeBase64Encode(JSON.stringify(dateFilters))}`
              : ''
          }${
            !isEmpty(filters)
              ? `&filters=${urlSafeBase64Encode(JSON.stringify(filters))}`
              : ''
          }`,
        },
        {
          replace: true,
        }
      );
    }
  }, [dateFilters, dateParam, filterParam, filters, isReplay, navigate]);

  useEffect(() => {
    if (dateOpen && filterOpen) {
      setPreviousState(prev);
      setFilterOpen(false);
      return;
    }

    if (!dateOpen && !prev && previousState !== null) {
      setFilterOpen(previousState);
      setPreviousState(null);
    }
  }, [dateOpen, filterOpen, prev, previousState]);

  const selectedFilterCategory = useMemo<'default' | 'brain' | 'desk'>(() => {
    if (filterParam.match('"type":"deskIds"')) {
      return 'desk';
    } else if (filterParam.match('"type":"brainIds"')) {
      return 'brain';
    }
    return 'default';
  }, [filterParam]);

  const reset = useCallback(() => {
    dispatch(resetFilterCategory());

    navigate(
      {
        ...window.location,
        search: urlParams.get('date') ? `date=${urlParams.get('date')}` : '',
      },
      {
        replace: true,
      }
    );
  }, [dispatch, urlParams, navigate]);

  return {
    dateOpen,
    setDateOpen,
    filterOpen,
    setFilterOpen,
    reset,
    selectedFilterCategory,
  };
};

export default useFilters;
