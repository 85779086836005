import { Icon } from '@/models/icon';

const Connect = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 5.333a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12 14.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM5.727 9.007l4.553 2.653M10.273 4.34 5.727 6.993"
      />
    </svg>
  );
};

export default Connect;
