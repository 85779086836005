import { MutableRefObject, useEffect } from 'react';

interface IntersectionObserver {
  root?: MutableRefObject<Element>;
  target: MutableRefObject<Element>;
  onIntersect: () => void;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
}
export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = 0,
  rootMargin = '0px',
  enabled = true,
}: IntersectionObserver) {
  useEffect(() => {
    if (!enabled || !target) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          return entry.isIntersecting && onIntersect();
        }),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current, enabled]);
}
