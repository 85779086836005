import { useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import { ContextVariableOption, EntityOption } from '@/models/autocomplete';
import { selectBrainId } from '@/redux/session/selectors';

import useDialogs from './useDialogs';
import useEntities from './useEntities';

export const useBrainVariables = (includeSystemVariables = true) => {
  const [variables, setVariables] = useState<
    (ContextVariableOption | EntityOption)[]
  >([]);
  const brainId = useSelector(selectBrainId);

  const { getContextVariables } = useDialogs(brainId);
  const { entities } = useEntities(brainId);

  const entityVariables = useMemo(() => {
    return (entities ?? []).map((e) => ({
      label: `@${e.entity}`,
      value: `@${e.entity}`,
      type: 'entity',
    })) as EntityOption[];
  }, [entities]);

  const contextVariables = useMemo(
    () =>
      getContextVariables(includeSystemVariables) as ContextVariableOption[],
    [getContextVariables, includeSystemVariables]
  );

  // To avoid unnecessary re-rendering,
  // we compare the variables before and after the effect.
  useEffect(() => {
    const newVariables = [...entityVariables, ...contextVariables];
    if (!isEqual(variables, newVariables)) {
      setVariables(newVariables);
    }
  }, [contextVariables, entityVariables, variables]);

  return {
    variables,
    entityVariables,
    contextVariables,
  };
};
