import React from 'react';

import cn from 'classnames';

import styles from './ButtonGroup.module.scss';

export interface ButtonGroupProps {
  isActive?: boolean;
  onClick: () => void;
  lastItem?: React.ReactNode | string;
  children: React.ReactNode;
}
const ButtonGroup = ({
  isActive,
  onClick,
  lastItem,
  children,
}: ButtonGroupProps) => {
  return (
    <button
      className={cn(styles.button, {
        [styles.isActive]: isActive,
      })}
      onClick={onClick}
    >
      <div className={styles.content}>{children}</div>

      {lastItem && <div className={styles.lastItem}>{lastItem}</div>}
    </button>
  );
};

export default ButtonGroup;
