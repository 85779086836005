import { useCallback } from 'react';

import Box from '@mui/material/Box/Box';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import Tab from '@/components/atoms/Tab/Tab';
import Tabs from '@/components/atoms/Tabs/Tabs';
import Header from '@/components/organisms/Header/Header';
import { useAccount } from '@/hooks/useAccount';
import { selectDeskId } from '@/redux/session/selectors';

interface Props {
  handleSubmit: () => void;
  isLoading?: boolean;
  selectedTab: number;
  setTab: (index: number) => void;
  disabled?: boolean;
}

const BusinessHourHeader = ({
  handleSubmit,
  isLoading,
  selectedTab,
  setTab,
  disabled,
}: Props) => {
  const navigate = useNavigate();
  const deskId = useSelector(selectDeskId);
  const { t } = useTranslation();
  const { slug } = useAccount();

  const handleCancelClick = useCallback(() => {
    navigate(`/${slug}/environments/${deskId}/business_hours`);
  }, [deskId, navigate, slug]);

  return (
    <Header>
      <Header.BreadCrumbs />

      <Box mt={1.5}>
        <Header.Navigation>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setTab(newValue)}
          >
            <Tab value={0} label={t('business_hours.header.setup')} />
            <Tab value={1} label={t('business_hours.header.holidays')} />
          </Tabs>
          <Header.Actions>
            <Button variant="tertiary" size="small" onClick={handleCancelClick}>
              {t('common.cancel')}
            </Button>
            <Button
              size="small"
              onClick={handleSubmit}
              isLoading={isLoading}
              disabled={disabled}
            >
              {t('common.save_and_close')}
            </Button>
          </Header.Actions>
        </Header.Navigation>
      </Box>
    </Header>
  );
};

export default BusinessHourHeader;
