import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import Select from '@/components/atoms/Select/Select';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import Bar from '@/components/organisms/Analytics/Bar/Bar';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';

import { ResponseType } from '../../../hooks/useResponseTimes';
import useResponseTimesInterval from '../../../hooks/useResponseTimesInterval';
import { getOptions } from '../ResponseTimeOverview/ResponseTimeOverview';

import styles from './ResponseFieldset.module.scss';
interface Props {
  isFirstMessage?: boolean;
}
const FirstResponseFieldset = ({ isFirstMessage }: Props) => {
  const { t } = useTranslation();
  const [showPrevious, setShowPrevious] = useState(false);
  const [optionValue, setOptionValue] = useState(
    getOptions(t)[0]?.value as ResponseType
  );
  const title = isFirstMessage
    ? t('analytics.response_time_by_day_first')
    : t('analytics.response_time_by_day');

  const responseTime = useResponseTimesInterval({
    intervalProps: {
      firstMessage: isFirstMessage,
      events: '{message:send}',
    },
    skipPrevious: !showPrevious,
    type: optionValue,
  });

  const handleReload = useCallback(() => {
    if (responseTime?.currentError) {
      responseTime.refetchCurrent();
    }
    if (responseTime?.previousError) {
      responseTime.refetchPrevious();
    }
  }, [responseTime]);

  const handleOptionClick = useCallback((e) => {
    setOptionValue(e.target.value);
  }, []);

  const handlePreviousClick = () => {
    setShowPrevious((prev) => !prev);
  };

  const isError = responseTime?.currentError;
  const isEmpty =
    !responseTime?.currentError &&
    !responseTime?.data &&
    !responseTime?.isLoading;

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      error={isError}
      onReload={handleReload}
      isEmpty={isEmpty}
      isLoading={responseTime?.isLoading}
      data={responseTime?.data?.series}
    >
      <div className={styles.barContainer}>
        <Bar
          axisY={t('analytics.amount')}
          axisX={t('common.day')}
          keys={
            showPrevious && responseTime.data?.series
              ? ['primary', 'secondary']
              : ['primary']
          }
          indexBy="date"
          data={responseTime.data?.series}
          groupMode={showPrevious ? 'grouped' : undefined}
          isTime
        />
        <div className={styles.checkbox}>
          <Select
            options={getOptions(t)}
            value={optionValue}
            onChange={handleOptionClick}
            size="small"
          />
          <Checkbox
            label={<TitleSubtitle subtitle={t('analytics.compare_prev')} />}
            onChange={handlePreviousClick}
            checked={showPrevious}
            sameLevel
          />
        </div>
      </div>
    </AnalyticsFieldset>
  );
};

export default FirstResponseFieldset;
