import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import useDesks from '@/hooks/useDesks';
import { Desk } from '@/models/desk';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { submitWithTrimming } from '@/util/util';
import { deskRules, errorMessage, LENGTH_XS } from '@/util/validator';

import s from './styles.module.scss';

type Form = {
  name: string;
  description: string;
};

interface InformationProps {
  isLoading?: boolean;
  desk?: Desk;
}

const InformationFieldset = ({ isLoading = false, desk }: InformationProps) => {
  const { t } = useTranslation();
  const formMethods = useForm<Form>({ mode: 'onChange' });
  const {
    register,
    reset,
    formState: { errors, isDirty },
  } = formMethods;
  const desk_id = desk?.desk_id;
  const { updateDesk, updateStatus } = useDesks(desk_id);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'desks', actions.WRITE)
  );

  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      updateDesk({ ...form, desk_id: desk?.desk_id });
    },
    [desk?.desk_id, updateDesk]
  );

  useEffect(() => {
    if (desk?.desk_id) {
      reset({
        name: desk?.name,
        description: desk?.description,
      });
    }
  }, [desk?.name, desk?.description, reset, desk?.desk_id]);

  return (
    <div className={s.desk_settings_fields}>
      <FormFieldset
        title={t('environments.environment_name')}
        primaryButton={{
          children: t('common.save'),
          isLoading: updateStatus === 'pending',
          disabled: !isDirty,
        }}
        onSubmit={submitWithTrimming(formMethods, onSubmit)}
        isLoading={isLoading}
        helpText={t('validation.less_than', { 0: LENGTH_XS })}
      >
        <Input
          name="name"
          label={t('field.name')}
          register={register('name', deskRules(t).name)}
          errorMessage={errorMessage({
            field: errors.name,
            maxLength: deskRules(t).name.maxLength,
          })}
          placeholder=""
          readOnly={!canWrite}
        />
      </FormFieldset>
    </div>
  );
};

export default InformationFieldset;
