import HelpTooltip from '../HelpTooltip/HelpTooltip';

import styles from './Label.module.scss';

interface Props {
  label: string;
  htmlFor: string;
  tooltip?: string;
  className?: string;
  required?: boolean;
}

const Label = ({
  label,
  htmlFor,
  tooltip,
  className = '',
  required = false,
}: Props) => {
  const labelClassName = `${styles.label} ${className}`;

  return (
    <label className={labelClassName} htmlFor={htmlFor}>
      {label}
      {required && '*'}
      <HelpTooltip tooltip={tooltip} />
    </label>
  );
};

export default Label;
