import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { API, onRuleUpdated } from '@/modules/rules/hooks/useRules';
import { Rule } from '@/modules/rules/model';

export const useUpdateRules = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate: updateRule, status: updateStatus } = useMutation<
    Rule,
    Error,
    { deskId: string; rule: Partial<Rule> }
  >({
    mutationFn: ({ deskId, rule }) => API.updateRule(deskId, rule),
    onSuccess: (resp, { deskId }) => {
      onRuleUpdated(queryClient, resp, deskId);
      dispatch(
        addTemporalToast('success', t('rules.rule_updated', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return { updateRule, updateStatus };
};
