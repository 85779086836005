import { useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Banner } from '@/components/atoms/Banner/Banner';
import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Link from '@/components/atoms/Link/Link';
import Switch from '@/components/atoms/Switch/Switch';
import { useAccount } from '@/hooks/useAccount';
import { getLandingPageUrl } from '@/util/constants';

type Form = {
  use_data: boolean;
};

interface Props {
  readOnly?: boolean;
}

export const DataProcessingFieldset = ({ readOnly = false }: Props) => {
  const { t } = useTranslation();

  const { account, accountStatus, updateAccount, updateStatus } = useAccount();
  const formMethods = useForm<Form>({
    mode: 'onBlur',
    defaultValues: {
      use_data: true,
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isDirty },
    reset,
  } = formMethods;

  const isLoading = accountStatus === 'pending';

  useEffect(() => {
    if (account) {
      reset({ use_data: !account?.opt_out as boolean });
    }
  }, [account, reset]);

  const onSubmit = useCallback(
    ({ use_data }: Form) => {
      updateAccount({ opt_out: !use_data });
    },
    [updateAccount]
  );

  const subtitle = (
    <>
      <p>{t('analytics.process.field1')}</p>
      <p>{t('analytics.process.field2')}</p>
      <Link href={getLandingPageUrl('/data-processing')} external>
        {t('analytics.process.data')}
      </Link>
    </>
  );
  return (
    <FormFieldset
      title={t('analytics.process.title')}
      subtitle={subtitle}
      primaryButton={{
        children: t('common.save'),
        isLoading: updateStatus === 'pending',
        disabled: !isDirty || readOnly,
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      helpText={t('analytics.process.helper')}
    >
      <Typography color="var(--text-default-gray)" component="p" mb={0.5}>
        {t('analytics.process.label')}
      </Typography>

      <Controller
        render={({ field: { value, onChange } }) => {
          return (
            <Switch
              label={t('common.enabled')}
              checked={value}
              disabled={readOnly}
              size="medium"
              onChange={onChange}
            />
          );
        }}
        name="use_data"
        control={control}
      />
      {!getValues().use_data && (
        <Box mt={1}>
          <Banner relativePosition variant="warning">
            {t('analytics.process.warning')}
          </Banner>
        </Box>
      )}
    </FormFieldset>
  );
};
