import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const DatasourceDocument = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, color = 'var(--icon-default-gray)', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        className="icon_stroke"
      >
        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6Z" />
        <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8" />
      </svg>
    );
  }
);

DatasourceDocument.displayName = 'DatasourceDocument';

export default DatasourceDocument;
