import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Routes, Route, useParams } from 'react-router';

import Integration from '@/components/pages/Integration/Integration';
import Integrations from '@/components/pages/Integrations/Integrations';
import Layout from '@/components/templates/Layout/Layout';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { setDeskId } from '@/redux/session/actions';

const IntegrationRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { deskId } = useParams();

  useEffect(() => {
    if (deskId) {
      dispatch(setDeskId(deskId));
    }
  }, [dispatch, deskId]);

  return (
    <Layout>
      <PermissionsWrapper module="integrations">
        <Routes>
          <Route path="/:integrationId" element={<Integration />} />
          <Route path="/" element={<Integrations />} />
        </Routes>
      </PermissionsWrapper>
    </Layout>
  );
};
IntegrationRouter.displayName = 'IntegrationRouter';

export default IntegrationRouter;
