import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Resolver, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import IconizedDialog from '@/components/atoms/IconizedDialog/IconizedDialog';
import Docs from '@/components/atoms/Icons/Docs';
import Input from '@/components/atoms/Input/Input';
import { useAccount } from '@/hooks/useAccount';
import useDialogNodesCache from '@/hooks/useDialogNodesCache';
import useDialogs from '@/hooks/useDialogs';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectSelectedNode } from '@/redux/dialogs/selectors';
import { updateNode } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { EVENT_RESPONSE_DOCS_URL } from '@/util/constants';
import { capitalizeFirstLetter, resolveBrainsPath } from '@/util/util';
import { nameSchema } from '@/util/validator';

import { nodeType } from './util';
import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitNodeEvent.module.scss';

type Inputs = {
  name: string;
};

const ToolkitNodeEvent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { nodeId, name } = useSelector((state: RootState) => {
    const selectedNode = selectSelectedNode(state);
    return {
      nodeId: selectedNode.node_id,
      name: selectedNode.name ?? '',
    };
  }, shallowEqual);
  const navigate = useNavigate();
  const { slug } = useAccount();
  const brainId = useSelector(selectBrainId);
  const { findUsedNodes } = useDialogNodesCache();
  const usedIn = findUsedNodes({ brainId, name: nodeId });
  const nodesLength = usedIn?.length;
  const { dialogs } = useDialogs(brainId);
  const { ai_agents } = useFeatureFlag();

  const {
    formState: { errors },
    getValues,
    register,
    trigger,
    setFocus,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      name,
    },
    resolver: yupResolver(nameSchema) as Resolver<Inputs>,
  });

  useFocusOnInput('name', errors, setFocus);

  const handleChange = useCallback(() => {
    const values = getValues();

    dispatch(
      updateNode({
        nodeId,
        node: {
          ...values,
        },
      })
    );
  }, [getValues, dispatch, nodeId]);

  useEffect(() => {
    const nameErrorMessage = errors.name?.message;

    dispatch(
      updateDialogAlerts({
        dialogAlerts: {
          alertType: 'error',
          id: nodeId,
          title: t('dialog.node_event'),
          body: capitalizeFirstLetter(nameErrorMessage),
          type: 'event',
          alertField: 'name',
        },
      })
    );
  }, [dispatch, errors.name?.message, nodeId, t]);

  useEffect(() => {
    // Trigger validation on mount
    trigger();
  }, [trigger]);

  return (
    <ToolkitWrapper type="event">
      <Input
        register={register('name')}
        error={!!errors.name}
        errorMessage={capitalizeFirstLetter(errors.name?.message as string)}
        label={t('common.name')}
        onChange={handleChange}
        key={nodeId}
        size="small"
      />
      <ContextualHelp
        title={t('dialog.event.contextual_title')}
        name="about_events"
        preventClose
        links={[
          {
            label: t('docs.docs'),
            url: EVENT_RESPONSE_DOCS_URL,
            icon: <Docs size={16} color="currentColor" />,
          },
        ]}
      >
        {t('dialog.event.contextual_message')}
      </ContextualHelp>
      {nodesLength > 0 && (
        <div className={styles.nodes}>
          <div className={styles.title}>
            <Typography
              variant="label-caps-large"
              color="var(--text-default-gray)"
            >
              {t('dialog.event.used_by')}
            </Typography>
          </div>
          <div className={styles.subTitle}>
            <Typography
              variant="label-regular"
              color="var(--text-default-gray)"
            >
              <Trans i18nKey="dialog.event.jump_to" />
            </Typography>
          </div>
          <div className={styles.nodes__list}>
            {usedIn.map((x) => (
              <button
                key={`${x.nodeId}-${x.label}`}
                type="button"
                onClick={() =>
                  navigate(
                    resolveBrainsPath(
                      `/${slug}/brains/${brainId}/dialogs/${x.dialogId}`,
                      ai_agents
                    )
                  )
                }
                className={cn({
                  [styles['nodes__icon--event']]:
                    nodeType(x.dialogId, dialogs) === 'event',
                })}
              >
                <IconizedDialog
                  key={x.nodeId}
                  dialogName={x.label}
                  type={nodeType(x.dialogId, dialogs)}
                />
              </button>
            ))}
          </div>
        </div>
      )}
    </ToolkitWrapper>
  );
};

export default ToolkitNodeEvent;
