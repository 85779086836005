import { createSlice } from '@reduxjs/toolkit';

import { HelperState } from '@/models/helpers';

const defaultState: HelperState = {};

const slice = createSlice({
  name: 'helpers',
  initialState: defaultState,
  reducers: {
    showHelper: (state, action) => {
      const name = action.payload;
      state[name] = true;
    },
    hideHelper: (state, action) => {
      const name = action.payload;
      state[name] = false;
    },
  },
});

export const { showHelper, hideHelper } = slice.actions;
export default slice.reducer;
