import { useCallback } from 'react';

import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/models/state';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { exportAnalytics } from '@/util/file-manager';

const useExport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { startDate, endDate } = useSelector((state: RootState) => {
    return {
      startDate: state.analytics.startDate,
      endDate: state.analytics.endDate,
    };
  }, shallowEqual);

  /**
   * Exports the data of an analytics fieldset as JSON or CSV.
   *
   * The name of the file is "fieldset_title_startDate_endDate".
   *
   * In CSV files, the first row consists of the "keys" of the corresponding JSON
   *
   * We do the following data normalization:
   * - In fieldsets that use only one query no formatting is needed.
   * - In fieldsets that use multiple queries, we reorder the object keys for consistency and readability.
   *
   * For "Usage" scorecards we export one file for each card(3 total), with the card title as name.
   * @param data The data used in each fieldset component.
   * @param name The title of the fieldset used to name the file.
   * @param extension The extension of the export file.
   */
  const onExportClick = useCallback(
    (data, name, extension) => {
      if (!name) {
        const titles = [
          t('analytics.totalMessages'),
          t('analytics.totalSessions'),
          t('analytics.totalUsers'),
        ];
        for (let i = 0; i < titles.length; i++) {
          exportAnalytics(
            data[i]?.series,
            `${snakeCase(titles[i])}_${startDate}_${endDate}`,
            extension
          );
          dispatch(
            addTemporalToast('success', t('analytics.exported', { 0: name }))
          );
        }
      } else {
        let formattedData = [];
        if (!data[0]?.data) {
          formattedData = data;
        } else {
          data.forEach((item) => {
            const { id, title, growth, counts, exact_counts, label } =
              item.data;
            if (counts) {
              formattedData.push(
                omitBy(
                  {
                    id,
                    title,
                    startDate,
                    endDate,
                    growth,
                    counts: exact_counts ?? counts,
                    label,
                  },
                  isNil
                )
              );
            }
          });
        }

        exportAnalytics(
          formattedData,
          `${snakeCase(name)}_${startDate}_${endDate}`,
          extension
        );
        dispatch(
          addTemporalToast('success', t('analytics.exported', { 0: name }))
        );
      }
    },
    [dispatch, endDate, startDate, t]
  );

  return onExportClick;
};

export default useExport;
