import { VideoPlayer } from '@/components/atoms/VideoPlayer';

import Modal from './Modal';

type Props = {
  onClick: () => void;
  title?: string;
  primaryButtonText?: string;
  url: string;
  onPlayClick?: () => void;
  onClose?: () => void;
};

function ModalVideo({
  onClick,
  title,
  primaryButtonText,
  url,
  onPlayClick,
  onClose,
}: Props) {
  return (
    <Modal
      title={title}
      size="medium"
      onPrimarySubmit={onClick}
      primaryButtonText={primaryButtonText}
      buttonAlignment="center"
      hideSecondaryButton
      preventClose
      onBlur={onClose}
    >
      <div className="video__container">
        <VideoPlayer url={url} thumbnailText="" onPlayClick={onPlayClick} />
      </div>
    </Modal>
  );
}

export default ModalVideo;
