import { Icon } from '@/models/icon';

const Rocket = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
    >
      <g fill={color} clipPath="url(#rocket)">
        <path d="M10.8 7.2c-1.102 0-2-.898-2-2 0-1.102.898-2 2-2 1.102 0 2 .898 2 2 0 1.102-.898 2-2 2Zm0-3.2c-.662 0-1.2.538-1.2 1.2 0 .662.538 1.2 1.2 1.2.662 0 1.2-.538 1.2-1.2 0-.662-.538-1.2-1.2-1.2ZM.4 16a.399.399 0 0 1-.376-.536c1.231-3.385 2.526-5.102 3.846-5.102.436 0 .844.188 1.212.556.583.582.604 1.162.519 1.546-.267 1.206-1.971 2.388-5.064 3.513A.406.406 0 0 1 .4 16V16Zm3.471-4.838c-.396 0-.862.347-1.348 1.004-.477.645-.957 1.565-1.43 2.741 1.068-.43 1.926-.865 2.559-1.3.854-.586 1.106-1.04 1.168-1.317.062-.276-.038-.541-.303-.807-.216-.216-.427-.32-.647-.32l.001-.001Z" />
        <path d="M15.6 0c-2.076 0-3.926.352-5.5 1.046a9.444 9.444 0 0 0-3.3 2.39c-.166.188-.32.38-.464.571-.69.046-1.395.32-2.1.816-.595.42-1.194 1-1.78 1.726C1.474 7.768.869 8.97.843 9.02a.4.4 0 0 0 .64.462c.004-.004.387-.38 1.039-.749.537-.304 1.366-.661 2.369-.724.253.648.979 1.424 1.327 1.772.348.348 1.124 1.074 1.773 1.328-.064 1.002-.421 1.83-.725 2.368a5.375 5.375 0 0 1-.748 1.038.4.4 0 0 0 .461.64c.05-.025 1.253-.63 2.472-1.614.726-.585 1.307-1.184 1.726-1.78.496-.704.77-1.41.816-2.099.191-.142.382-.297.572-.464a9.444 9.444 0 0 0 2.389-3.3c.694-1.573 1.046-3.424 1.046-5.5V0h-.4L15.6 0ZM2.486 7.846c.762-1.106 1.928-2.478 3.241-2.91-.519.91-.788 1.746-.885 2.275-.82.054-1.62.27-2.356.635Zm5.668 5.666c.366-.735.581-1.536.635-2.355.529-.097 1.365-.366 2.273-.884-.432 1.311-1.803 2.477-2.908 3.239ZM12.035 8.6c-1.664 1.468-3.387 1.8-3.635 1.8-.002 0-.166-.003-.607-.314a8.099 8.099 0 0 1-1.01-.868 8.099 8.099 0 0 1-.868-1.01c-.311-.442-.314-.606-.314-.607 0-.248.332-1.971 1.8-3.635 1.757-1.99 4.445-3.08 7.795-3.16-.08 3.35-1.169 6.038-3.16 7.795V8.6Z" />
      </g>
      <defs>
        <clipPath id="rocket">
          <path fill={color} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Rocket;
