import { useMemo, memo, useCallback } from 'react';

import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import {
  backgroundColorGetter,
  IconsByState,
  primaryBackgroundColorGetter,
  Variants,
} from '../Icons/IconsByState';

type Props = {
  label: string;
  variant: Variants;
  withIcon?: boolean;
  tooltip?: string;
  valueBadge?: boolean;
  value?: string;
};
const StatusBadge = memo(
  ({ label, variant, withIcon, tooltip, valueBadge = false, value }: Props) => {
    const muiChipStyles = {
      '&': {
        backgroundColor: () => backgroundColorGetter(variant),
        maxWidth: 'fit-content',
        borderRadius: '40px',
        paddingLeft: withIcon && !valueBadge ? '4px' : '0px',
        '& .MuiChip-deleteIcon': {
          width: valueBadge ? 'fit-content' : '22px',
          cursor: 'default',
        },
      },
      '& .MuiChip-label': {
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 400,
      },
      '&.MuiChip-sizeSmall': {
        height: valueBadge ? '30px' : '22px',
      },
    };

    const muiBadgeStyles = useMemo(
      () => ({
        '&': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiBadge-badge': {
          position: 'relative',
          padding: '0 4px',
        },
        '& .MuiBadge-colorPrimary': {
          backgroundColor: () => primaryBackgroundColorGetter(variant),
          color: 'var(--icon-default-white)',
        },
        '& .MuiBadge-anchorOriginTopRightRectangular': {
          transform: 'translate(-3px, -3px)',
        },
      }),
      [variant]
    );

    const badgeOnTheRight = useCallback(() => {
      if (!valueBadge) {
        return null;
      }
      return (
        <Badge
          color="primary"
          badgeContent={value}
          sx={muiBadgeStyles}
          max={999999999999}
        />
      );
    }, [muiBadgeStyles, value, valueBadge]);

    return tooltip ? (
      <Tooltip arrow title={tooltip}>
        <Chip
          size={valueBadge ? 'medium' : 'small'}
          label={label}
          icon={withIcon ? IconsByState[variant] : null}
          sx={muiChipStyles}
          deleteIcon={valueBadge ? badgeOnTheRight() : null}
          onDelete={valueBadge ? () => {} : null}
        />
      </Tooltip>
    ) : (
      <Chip
        size={valueBadge ? 'medium' : 'small'}
        label={label}
        icon={withIcon ? IconsByState[variant] : null}
        sx={muiChipStyles}
        deleteIcon={valueBadge ? badgeOnTheRight() : null}
        onDelete={valueBadge ? () => {} : null}
      />
    );
  }
);

StatusBadge.displayName = 'StatusBadge';

export default StatusBadge;
