import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';

export const selectDialogAlerts = (state: RootState) =>
  state.dialogAlerts.dialogAlerts ?? [];

export const hasDialogWarning = (actionId: string) =>
  createSelector(
    selectDialogAlerts,
    (dialogWarnings) =>
      dialogWarnings.filter((action) => action.id === actionId).length > 0
  );

export const selectHasCarouselDialogErrors = (actionId: string) =>
  createSelector(selectDialogAlerts, (dialogAlerts) => {
    if (!dialogAlerts || !dialogAlerts.length) {
      return false;
    }
    return dialogAlerts?.some(
      (error) => error.type === 'carousel' && error.id === actionId
    );
  });

export const isAlertThisCardButton = (cardIndex, index) =>
  createSelector(selectDialogAlerts, (dialogAlerts) => {
    return (
      dialogAlerts.filter((alert) => {
        if (alert.type === 'carousel') {
          const [, alertButtonIndex, alertCardIndex] =
            alert.alertField.split('-');
          return (
            Number(alertButtonIndex) === index &&
            Number(alertCardIndex) === cardIndex
          );
        }
      }).length > 0
    );
  });
