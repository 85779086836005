import { useInfiniteQuery } from '@tanstack/react-query';

import { callGet } from '@/api/fetcher';
import { ConversationEventResponse } from '@/models/chat';
import { PAGINATION_LIMIT } from '@/util/constants';

export const endpoints = {
  events: (deskId: string, sessionId: string, cursor?: string) => {
    const queryParams = cursor ? `&next_cursor=${cursor}` : '';
    return `/www/api/v1/desks/${deskId}/conversations/${sessionId}/events?sort=created&limit=${PAGINATION_LIMIT}${queryParams}`;
  },
};

const API = {
  listEvents: async (
    deskId: string,
    sessionId: string,
    cursor?: { cursor: string }
  ): Promise<ConversationEventResponse> =>
    callGet(endpoints.events(deskId, sessionId, cursor.cursor)),
} as const;

export const useConversationEvents = (deskId: string, sessionId: string) => {
  const {
    data: events,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [endpoints.events(deskId, sessionId)],
    queryFn: ({ pageParam }) => API.listEvents(deskId, sessionId, pageParam),

    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
    enabled: Boolean(sessionId) && Boolean(deskId),
  });

  return {
    events: events?.pages.flatMap((page) => page.events) ?? [],
    fetchNextPage,
    hasNextPage,
  };
};
