import { RootState } from '@/models/state';

export const selectBrainId = (state: RootState): string | null =>
  state.session.brain_id;

export const selectVersion = (state: RootState): number =>
  state.session.version;

export const selectDeskId = (state: RootState): string | null =>
  state.session.desk_id;

export const selectIntegrationId = (state: RootState): string | null =>
  state.session.integration_id;

export const selectCollectionId = (state: RootState): string | null =>
  state.session.collection_id;

export const selectDatasourceId = (state: RootState): string | null =>
  state.session.datasource_id;

export const selectSessionId = (state: RootState): string | null =>
  state.session.session_id;

export const selectAccountSlug = (state: RootState): string | null =>
  state.session.account_slug;

export const selectAccountId = (state: RootState): string | null =>
  state.session.account_id;

export const selectBroadcastId = (state: RootState): string | null =>
  state.session.broadcast_id;

export const selectRuleId = (state: RootState): string | null =>
  state.session.rule_id;

export const selectBundleId = (state: RootState): string | null =>
  state.session.bundle_id;

export const selectIntentName = (state: RootState): string | null =>
  state.session.intent_name;

export const selectEntityName = (state: RootState): string | null =>
  state.session.entity_name;

export const selectDialogId = (state: RootState): string | null =>
  state.session.dialog_id;

export const selectFileUploadMetadata = (state: RootState): string => {
  if (state.session.brain_id) {
    return JSON.stringify({
      brain_id: state.session.brain_id,
      brain_version: state.session.version,
    });
  } else if (state.session.bundle_id) {
    return JSON.stringify({
      bundle_id: state.session.bundle_id,
      desk_id: state.session.desk_id,
    });
  } else {
    return JSON.stringify({ desk_id: state.session.desk_id });
  }
};

export const selectChannelUserId = (state: RootState): string | null =>
  state.session.channel_user_id;
