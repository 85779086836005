import { useEffect, useCallback, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Spam from '@/components/atoms/Icons/Spam';
import Tick from '@/components/atoms/Icons/Tick';
import Trash from '@/components/atoms/Icons/Trash';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { endpoints as convCountsEndpoints } from '@/modules/humanChat/hooks/useConversationsCounts';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { setChannelUserId, setSessionId } from '@/redux/session/actions';
import { selectDeskId } from '@/redux/session/selectors';
import { preventClickThrough } from '@/util/util';

import { useConversationsNew } from '../../hooks/useConversationsNew';
import { ConversationViewing } from '../ConversationViewing/ConversationViewing';

import styles from './ConversationHeader.module.scss';

const ConversationHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sessionId, slug } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deskId = useSelector(selectDeskId);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );
  const open = Boolean(anchorEl);
  const { conversation, getStatus, updateConversation, deleteConversation } =
    useConversationsNew({
      deskId,
      sessionId,
    });

  const channel_user_id =
    conversation?.context?.user[`${conversation?.channel}_psid`];

  const displayName =
    conversation?.context?.user?.display_name ?? t('common.anonymous');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickTrash = useCallback(() => {
    handleClose();

    updateConversation(
      { status: conversation?.status === 'trash' ? 'closed' : 'trash' },
      {
        onSuccess: () => {
          dispatch(
            addTemporalToast(
              'success',
              conversation?.status === 'trash'
                ? t('conversation.removed_from_trash')
                : t('conversation.moved_to_trash')
            )
          );
        },
      }
    );
  }, [conversation?.status, dispatch, t, updateConversation]);

  const clickDeletePermanently = useCallback(() => {
    handleClose();

    const modalProps = {
      subtitle: t('conversation.trash_warning'),
      confirm: false,
      onDelete: () => {
        deleteConversation(sessionId, {
          onSuccess: () => {
            navigate(`/${slug}/chats/${deskId}/conversations`);
            dispatch(popModal());
            queryClient.invalidateQueries({
              queryKey: [convCountsEndpoints.counts(deskId)],
            });
          },
        });
      },
    };
    dispatch(pushModal(MODAL_DELETE, modalProps));
  }, [
    deleteConversation,
    deskId,
    dispatch,
    navigate,
    queryClient,
    sessionId,
    slug,
    t,
  ]);

  const clickSpam = useCallback(() => {
    handleClose();
    updateConversation(
      {
        status: conversation?.status === 'spam' ? 'closed' : 'spam',
      },
      {
        onSuccess: () => {
          dispatch(
            addTemporalToast(
              'success',
              conversation?.status === 'spam'
                ? t('conversation.removed_from_spam')
                : t('conversation.move_to_spam')
            )
          );
        },
      }
    );
  }, [updateConversation, conversation?.status, dispatch, t]);

  const handleClickMore = (event) => {
    preventClickThrough(event);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (channel_user_id) {
      dispatch(setChannelUserId(channel_user_id));
    }
    return () => {
      dispatch(setChannelUserId(null));
    };
  }, [dispatch, channel_user_id]);

  useEffect(() => {
    if (sessionId) {
      dispatch(setSessionId(sessionId));
    }
    return () => {
      dispatch(setSessionId(null));
    };
  }, [dispatch, sessionId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.levelOne}>
        {getStatus === 'pending' ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={130}
            height={28}
          />
        ) : (
          <>
            {conversation?.context?.user?.avatar && (
              <Avatar
                src={conversation.context.user.avatar}
                alt={displayName}
                sx={{ width: 36, height: 36 }}
              />
            )}
            <Typography
              variant="subheading-semi-bold"
              data-testid="conversation-header-display-name"
            >
              {displayName ?? t('common.anonymous')}
            </Typography>
            {conversation?.context?.user?.verified && (
              <span className={styles.badge}>
                <Tooltip arrow title={t('contacts.verified_user')}>
                  <span>
                    <Tick
                      backgroundColor="var(--icon-default-success)"
                      size={14}
                    />
                  </span>
                </Tooltip>
              </span>
            )}
          </>
        )}
        <div className={styles.group}>
          <ConversationViewing />

          <IconButton
            id="more-button"
            aria-controls={open ? 'more-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickMore}
            disabled={!canWrite}
            ariaLabel={t('common.see_more_options')}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </div>
      </div>

      <CustomMenu
        id="more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableRestoreFocus
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
        keepMounted
        TransitionComponent={Grow}
      >
        <MenuItem onClick={clickSpam}>
          <Spam size={16} />
          {conversation?.status === 'spam'
            ? t('conversation.remove_from_spam')
            : t('conversation.report_spam')}
        </MenuItem>
        <MenuItem onClick={clickTrash}>
          <Trash color="var(--icon-default-black)" size={16} />
          {conversation?.status === 'trash'
            ? t('conversation.remove_from_trash')
            : t('conversation.move_to_trash')}
        </MenuItem>
        {conversation?.status === 'trash' && (
          <MenuItem onClick={clickDeletePermanently}>
            <Trash color="var(--icon-default-black)" size={16} />
            {t('conversation.delete_permanently')}
          </MenuItem>
        )}
      </CustomMenu>
    </div>
  );
};

export default ConversationHeader;
