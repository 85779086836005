import Typography, { TypographyProps } from '@mui/material/Typography';
import cn from 'classnames';

import styles from './Badge.module.scss';

type BadgeProps = {
  badgeVariant: 'green' | 'gray' | 'blue';
  text: string;
  icon?: React.ReactNode;
} & TypographyProps;

export const Badge = ({ icon, text, badgeVariant, ...rest }: BadgeProps) => {
  return (
    <Typography
      className={cn(
        styles.badge,
        styles[`badge--${badgeVariant}`],
        rest.className
      )}
      variant="label-regular"
      {...rest}
    >
      {icon}
      {text}
    </Typography>
  );
};
