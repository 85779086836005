import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import {
  teamUsersEndpoints as endpoints,
  teamsEndpoints,
  membersEndpoints,
} from '@/api/endpoints';
import { callGet, callPost, callDelete } from '@/api/fetcher';
import { Member, Members } from '@/models/member';
import { Team, Teams } from '@/models/team';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

interface User {
  user_id: string;
  team_id: string;
}

interface Users {
  users: User[];
}

interface UpdateProps {
  team_id: string;
  user_ids: string[];
  name?: string;
}

const API = {
  listUsers: async (team_id: string): Promise<Users> =>
    callGet(endpoints.users(team_id)),
  addUsers: async ({ team_id, user_ids }): Promise<Users> =>
    callPost(endpoints.users(team_id), { user_ids }),
  removeUser: async ({ team_id, user_id }): Promise<User> =>
    callDelete(endpoints.user(team_id, user_id)),
};

export const onUserAdded = (
  queryClient: QueryClient,
  resp: Users,
  team_id: string,
  accountSlug: string
) => {
  queryClient.setQueryData<Teams>(
    [teamsEndpoints.teams, accountSlug],
    ({ teams }: { teams: Team[] }) => {
      const updatedTeams = teams.map((t) => {
        if (t.team_id === team_id) {
          return {
            ...t,
            member_count: t.member_count + resp.users.length,
          };
        }
        return t;
      });
      return { teams: updatedTeams };
    }
  );
  queryClient.invalidateQueries({
    queryKey: [endpoints.users(team_id), accountSlug],
  });

  queryClient.setQueryData<Members>(
    [membersEndpoints.members, accountSlug],
    (prev: { members: Member[] }) => {
      const updatedMembers = prev?.members.map((m) => {
        if (m.user_id === resp.users[0].user_id) {
          return {
            ...m,
            team_ids: [...m.team_ids, resp.users[0].team_id],
          };
        }
        return m;
      });
      return { members: updatedMembers };
    }
  );
};

export const onUserRemoved = (
  queryClient: QueryClient,
  resp: User,
  team_id: string,
  accountSlug: string
) => {
  queryClient.setQueryData<Teams>(
    [teamsEndpoints.teams, accountSlug],
    ({ teams }: { teams: Team[] }) => {
      const updatedTeams = teams.map((t) => {
        if (t.team_id === team_id) {
          return {
            ...t,
            member_count: t.member_count - 1,
          };
        }
        return t;
      });
      return { teams: updatedTeams };
    }
  );

  queryClient.invalidateQueries({
    queryKey: [endpoints.users(team_id), accountSlug],
  });

  queryClient.setQueryData<Members>(
    [membersEndpoints.members, accountSlug],
    (prev: { members: Member[] }) => {
      const updatedMembers = prev?.members.map((m) => {
        if (m.user_id === resp.user_id) {
          return {
            ...m,
            team_ids: m.team_ids.filter((t) => t !== resp.team_id),
          };
        }
        return m;
      });

      return { members: updatedMembers };
    }
  );
};

export const useTeamUsers = (team_id?: string) => {
  const accountSlug = useSelector(selectAccountSlug);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data: users, status: listStatus } = useQuery<Users, Error>({
    queryKey: [endpoints.users(team_id), accountSlug],
    queryFn: () => API.listUsers(team_id),
    enabled: !!accountSlug && !!team_id,
  });

  const { mutateAsync: addUsers, status: updateStatus } = useMutation<
    Users,
    Error,
    UpdateProps
  >({
    mutationFn: API.addUsers,
    onSuccess: (resp, variables) => {
      onUserAdded(queryClient, resp, variables.team_id, accountSlug);
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutateAsync: removeUser, status: removeStatus } = useMutation<
    User,
    Error,
    User
  >({
    mutationFn: API.removeUser,
    onSuccess: (resp, variables) => {
      onUserRemoved(queryClient, resp, variables.team_id, accountSlug);
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    users: users?.users,
    listStatus,
    addUsers,
    removeUser,
    updateStatus,
    removeStatus,
  };
};
