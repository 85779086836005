import { useState, useCallback, useEffect } from 'react';

import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Switch from '@/components/atoms/Switch/Switch';
import useUser from '@/hooks/useUser';
import { getDocsUrl } from '@/util/constants';

const DesktopNotification = () => {
  const { t } = useTranslation();
  const { user, updateUser, updateStatus } = useUser();
  const [desktopNotifications, setDesktopNotifications] =
    useState<boolean>(true);
  const defaultValue = get(user, 'metadata.desktopNotifications', true);

  useEffect(() => {
    const notification = get(
      user,
      'metadata.desktopNotifications',
      true
    ) as boolean;
    setDesktopNotifications(notification);
  }, [user]);

  const onSubmit = useCallback(() => {
    updateUser({
      ...user,
      metadata: {
        ...user?.metadata,
        desktopNotifications,
      },
    });
  }, [desktopNotifications, updateUser, user]);

  return (
    <FormFieldset
      title={t('notifications.desktop_notifications')}
      subtitle={t('notifications.desktop_notifications_subtitle')}
      externalLink={{
        label: t('common.learn_more'),
        url: getDocsUrl('/docs/guides/allow_desktop_notifications'),
      }}
      primaryButton={{
        children: t('common.save'),
        isLoading: updateStatus === 'pending',
        disabled: defaultValue === desktopNotifications,
      }}
      onSubmit={onSubmit}
      isLoading={!user}
    >
      <Switch
        name="greeklish"
        label={t('common.enabled')}
        checked={desktopNotifications}
        disabled={!user}
        size="medium"
        onChange={() => setDesktopNotifications((c) => !c)}
      />
    </FormFieldset>
  );
};

export default DesktopNotification;
