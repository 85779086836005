import MuiTab, { TabProps } from '@mui/material/Tab';
import { To } from 'react-router';

import { Badge } from '../Badge/Badge';
import InAppHelp, { Step } from '../InAppHelp/InAppHelp';

type Props = {
  value: string | number;
  label: string;
  to?: To;
  component?: React.ElementType;
  customMargin?: string;
  tabIndex?: number;
  badge?: number;
  helpProps?: {
    step?: Step;
    icon?: React.ReactNode;
    onClose?: () => void;
    show?: boolean;
  };
};

const muiStylesBadge = {
  '& .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangular': {
    top: '10px',
    right: '2px',
  },
};

const muiStylesTab = {
  '&.MuiTab-root': {
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    fontWeight: 400,
    lineHeight: '20px',
    minHeight: 'auto',
    margin: 0,
    gap: 'var(--space-4)',
    padding: 'var(--space-16)',

    '&:hover': {
      backgroundColor: 'var(--surface-secondary-gray-light)',
    },
    '&:focus-visible': {
      outline: 'var(--border-default-blue) auto 1px',
    },

    '&::after': {
      background: 'var(--icon-default-blue)',
      borderRadius: '100%',
      content: "''",
      height: '100px',
      left: '50%',
      opacity: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      transition: '0.5s',
      transitionTimingFunction: 'ease-out',
      width: '100px',
    },
    '&:active::after': {
      height: '0',
      width: '0',
      opacity: 1,
      transition: '0s',
    },
  },
  '&.Mui-selected': {
    textShadow: '0.3px 0 var(--border-default-blue)',
    color: 'inherit',
  },
  '.Mui-TouchRipple-root': {
    display: 'none',
  },
  '.MuiTouchRipple-ripple': {
    display: 'none',
  },
};

const Tab: React.FC<TabProps & Props> = ({
  value,
  label,
  component = null,
  customMargin = 0,
  tabIndex = 0,
  badge = 0,
  helpProps,
  ...props
}) => {
  return (
    <Badge
      color="primary"
      content={badge}
      invisible={!badge}
      overlap="rectangular"
      sx={muiStylesBadge}
    >
      <InAppHelp
        placement="bottom-end"
        darkTheme
        defaultOpen={helpProps?.show}
        onClose={helpProps?.onClose}
        steps={
          helpProps?.step
            ? [helpProps.step]
            : [
                {
                  body: '',
                } as Step,
              ]
        }
      >
        <MuiTab
          tabIndex={tabIndex}
          component={component}
          value={value}
          label={label}
          badge={badge}
          data-testid={props['data-testid']}
          sx={{
            ...muiStylesTab,
            '.MuiTab-root': {
              ...muiStylesTab['.MuiTab-root'],
              margin: customMargin,
            },
          }}
          {...props}
        />
      </InAppHelp>
    </Badge>
  );
};

export default Tab;
