import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ActiveFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/ActiveFieldset';
import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import SingleInputFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/SingleInputFieldset';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import { useIntegrations } from '@/hooks/useIntegrations';
import { IntercomIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId, selectIntegrationId } from '@/redux/session/selectors';
import { integrationRulesIntercom } from '@/util/validator';

const IntercomSettings = () => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const integrationId = useSelector(selectIntegrationId);

  const { integration } = useIntegrations<IntercomIntegration>(
    deskId,
    integrationId
  );
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  return (
    <>
      <div>
        <h6>{integration?.config?.page_name}</h6>
      </div>

      <ActiveFieldset
        integration={integration}
        title={t('integrations.status.title')}
        subtitle={t('integrations.status.subtitle')}
      />

      <PlainFieldset
        title={t('integrations.intercom.assigned_agent')}
        subtitle={integration?.config.admin_name}
        isLoading={!integration?.config.admin_name}
      />

      <SingleInputFieldset
        title={t('integrations.intercom.welcome_title')}
        subtitle={t('integrations.intercom.welcome_subtitle', {
          0: integration?.config?.admin_name,
        })}
        name="welcome_trigger_message"
        integration={integration}
        validationRule={integrationRulesIntercom.welcomeTriggerMessage}
        readOnly={!canWrite}
      />
      <DeleteFieldset integration={integration} disabled={!canWrite} />
    </>
  );
};

export default IntercomSettings;
