import { useCallback, useEffect } from 'react';

import { ClickAwayListener } from '@mui/base';
import cn from 'classnames';
import { ChromePicker } from 'react-color';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import AvatarUpload from '@/components/atoms/Avatar/AvatarUpload/AvatarUpload';
import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import Input from '@/components/atoms/Input/Input';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  WebWidgetIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useBillingState } from '@/modules/billing/hooks/useBillingState';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { integrationRulesWebWidget, errorMessage } from '@/util/validator';

import ColorPickerButton from './ColorPickerButton';
import ColorThemeOptions from './ColorThemeOptions';

import styles from './WebchatAppearance.module.scss';

type Form = {
  header_title: string;
  background_color: string;
  background_color_picker: boolean;
  accent_color: string;
  accent_color_picker: boolean;
  powered_by: boolean;
  customize_theme: boolean;
  avatar_url: string;
  theme: string;
};

const WebchatAppearance = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, WebWidgetIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const deskId = integration?.desk_id;
  const integrationId = integration?.integration_id;
  const defaultBackgroundColor = '#1b66d6';
  const defaultAccentColor = '#fff';
  const { updateIntegration, updateStatus } =
    useIntegrations<WebWidgetIntegration>(deskId, integrationId);
  const dispatch = useDispatch();
  const billingState = useBillingState();
  const isTrial = billingState?.plan_type === 'trial';
  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });
  const {
    register,
    reset,
    handleSubmit,
    trigger,
    formState: { errors, isDirty },
    control,
    setValue,
  } = formMethods;

  const [backgroundPicker, accentColorPicker, customizeTheme, currentTheme] =
    useWatch({
      control,
      name: [
        'background_color_picker',
        'accent_color_picker',
        'customize_theme',
        'theme',
      ],
    });

  useEffect(() => {
    reset({
      background_color:
        integration?.config?.background_color || defaultBackgroundColor,
      background_color_picker: false,
      accent_color: integration?.config?.accent_color || defaultAccentColor,
      accent_color_picker: false,
      powered_by: integration?.config?.show_powered_by || false,
      customize_theme:
        !!integration?.config?.background_color &&
        !!integration?.config?.accent_color,
      theme: integration?.config?.theme || 'light',
      avatar_url: '',
      header_title: integration?.config?.header_title || '',
    });
  }, [
    integration?.config?.accent_color,
    integration?.config?.avatar,
    integration?.config?.background_color,
    integration?.config?.header_title,
    integration?.config?.show_powered_by,
    integration?.config?.theme,
    reset,
  ]);

  const handleUpdateIntegration = useCallback(() => {
    const {
      header_title,
      powered_by,
      background_color,
      accent_color,
      avatar_url,
      customize_theme,
      theme,
    } = formMethods.getValues();
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration?.config,
          header_title: header_title.trim() || undefined,
          theme,
          show_powered_by: powered_by,
          background_color: customize_theme ? background_color : undefined,
          accent_color: customize_theme ? accent_color : undefined,
          avatar: avatar_url || integration?.config?.avatar,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, formMethods, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [dispatch, expanded, isDirty, reset, type]);

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  const handleAvatarUpdate = useCallback(
    (data) => {
      setValue('avatar_url', data?.avatar, { shouldDirty: true });
    },
    [setValue]
  );

  return (
    <>
      <Accordion
        title={t('integrations.web_ui.title')}
        subtitle={t('integrations.web_ui.subtitle')}
        footerText={t('integrations.web_ui.footer')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
      >
        <div className={styles.wrapper}>
          <div className={styles.title}>{t('integrations.web_ui.title2')}</div>
          <Input
            name="header_title"
            register={register(
              'header_title',
              integrationRulesWebWidget.headerTitle
            )}
            errorMessage={
              errorMessage({
                field: errors['header_title'],
                maxLength: integrationRulesWebWidget.headerTitle?.maxLength,
              }) ?? errors['header_title']?.message
            }
            placeholder=""
            size="medium"
            trimValue
            readOnly={!canWrite}
          />
        </div>

        <div
          className={cn(styles.wrapper, {
            [styles.disabled]: !canWrite,
          })}
        >
          <div className={styles.title}>{t('integrations.web_ui.avatar')}</div>
          <Controller
            render={({ field: { value } }) => {
              return (
                <AvatarUpload
                  onUpdate={handleAvatarUpdate}
                  label="userAvatar"
                  avatar={value || integration?.config?.avatar}
                />
              );
            }}
            name="avatar_url"
            control={control}
          />
        </div>

        <div className={styles.wrapper}>
          <div className={styles.title}>{t('integrations.web_ui.themes')}</div>
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <ColorThemeOptions
                  onChange={onChange}
                  value={value}
                  disabled={!canWrite}
                />
              );
            }}
            name="theme"
            control={control}
          />
        </div>

        <div
          className={cn(styles.customizeTheme, {
            [styles.checked]: customizeTheme,
          })}
        >
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  label={t('integrations.web_ui.customize_theme')}
                  onChange={onChange}
                  checked={value}
                  size="small"
                  disabled={
                    currentTheme === 'dracula' ||
                    currentTheme === 'monocai' ||
                    !canWrite
                  }
                />
              );
            }}
            name="customize_theme"
            control={control}
          />
        </div>

        {customizeTheme && (
          <>
            <div className={styles.colorPickerOuter}>
              <div className={styles.colorPickerInner}>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ClickAwayListener
                        onClickAway={() => {
                          setValue('background_color_picker', false);
                        }}
                      >
                        <div className={styles.colorPicker}>
                          <div className={styles.title}>
                            {t('integrations.web_ui.primary_color')}
                          </div>
                          <ColorPickerButton
                            color={value}
                            onClick={() => {
                              setValue(
                                'background_color_picker',
                                !backgroundPicker
                              );
                            }}
                            disabled={accentColorPicker || !canWrite}
                          />
                          {backgroundPicker && (
                            <span className={styles.chromePicker}>
                              <ChromePicker
                                color={value}
                                onChange={(newColor) => {
                                  onChange(newColor.hex);
                                }}
                              />
                            </span>
                          )}
                        </div>
                      </ClickAwayListener>
                    );
                  }}
                  name="background_color"
                  control={control}
                />
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ClickAwayListener
                        onClickAway={() => {
                          setValue('accent_color_picker', false);
                        }}
                      >
                        <div className={styles.colorPicker}>
                          <div className={styles.title}>
                            {t('integrations.web_ui.text_color')}
                          </div>

                          <ColorPickerButton
                            color={value}
                            onClick={() => {
                              setValue(
                                'accent_color_picker',
                                !accentColorPicker
                              );
                            }}
                            disabled={backgroundPicker || !canWrite}
                          />
                          {accentColorPicker && (
                            <span className={styles.chromePicker}>
                              <ChromePicker
                                color={value}
                                onChange={(newColor) => {
                                  onChange(newColor.hex);
                                }}
                              />
                            </span>
                          )}
                        </div>
                      </ClickAwayListener>
                    );
                  }}
                  name="accent_color"
                  control={control}
                />
              </div>
            </div>
          </>
        )}
        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <Checkbox
                label={
                  <TitleSubtitle
                    title={t('integrations.web_ui.powered')}
                    subtitle={t('integrations.web_ui.powered_subtitle')}
                    noGutters
                  />
                }
                onChange={onChange}
                checked={value}
                disabled={!canWrite || isTrial}
              />
            );
          }}
          name="powered_by"
          control={control}
        />
      </Accordion>
    </>
  );
};

export default WebchatAppearance;
