import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';

const Checkmark = ({ checked = true, size = 16, color = '' }) => {
  const styleColor =
    color ||
    (checked ? 'var(--icon-default-success)' : 'var(--icon-default-error)');
  const style = { color: styleColor, width: size, height: size };

  return checked ? <CheckCircle style={style} /> : <Cancel style={style} />;
};

export default Checkmark;
