import Skeleton from '@mui/material/Skeleton';

import styles from './MessagePreviewSkeleton.module.scss';

const MessagePreviewSkeleton = ({ style = {} }) => {
  return (
    <div className={styles.container} style={style}>
      <Skeleton height={20} width={80} />

      <Skeleton height={20} width={120} />
      <div className={styles.footer}>
        <Skeleton height={15} width={100} />
        <Skeleton height={20} width={20} variant="circular" />
      </div>
    </div>
  );
};

export default MessagePreviewSkeleton;
