import { useCallback } from 'react';

import cn from 'classnames';
import { useDispatch } from 'react-redux';

import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import { generateOption } from '@/redux/dialogs/helper';
import { addOption } from '@/redux/nodes/actions';
import {
  DISPLAYED_TEXT_ACTION_OPTIONS,
  MAX_TEXT_ACTION_OPTIONS,
} from '@/util/constants';
import { noop, preventClickThrough } from '@/util/util';

import Option from './NewOption/Option';

import styles from './OptionList.module.scss';

interface OptionListProps {
  actionId: string;
  options: {
    label: string;
    option_id: string;
    text: string;
  }[];
  opacity: boolean;
}

function OptionList({ options = [], actionId, opacity }: OptionListProps) {
  const dispatch = useDispatch();

  const onAddActionClick = useCallback(
    (e) => {
      preventClickThrough(e);
      dispatch(
        addOption({
          actionId,
          option: generateOption(),
        })
      );
    },
    [dispatch, actionId]
  );

  if (!options || options.length === 0) {
    return null;
  }

  return (
    <div className={cn(styles.options, { [styles.opacity]: opacity })}>
      {options
        .slice(0, DISPLAYED_TEXT_ACTION_OPTIONS)
        .map(({ option_id, label }, index) => (
          <Option
            key={option_id}
            actionId={actionId}
            label={label}
            index={index}
            extendedWidth={options?.length === 1 && index === 0}
          />
        ))}
      {options.length > DISPLAYED_TEXT_ACTION_OPTIONS && (
        <div className={styles.options__item}>
          +{options.length - DISPLAYED_TEXT_ACTION_OPTIONS}
        </div>
      )}
      {options.length > 0 && (
        <button
          onClick={onAddActionClick}
          // Prevent creating a new option when navigating with keyboard
          onKeyUp={noop}
          className={cn(styles.options__item, styles['options__item--add'])}
          disabled={options.length === MAX_TEXT_ACTION_OPTIONS}
        >
          <PlusCircle />
        </button>
      )}
    </div>
  );
}

export default OptionList;
