import { Icon } from '@/models/icon';

const Build = ({ size = 28, color = 'var(--icon-default-white)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={color} clipPath="url(#buildicon)">
        <path d="m23.866 14.26-1.557-2.087.836-2.466a.672.672 0 0 0-.852-.852l-2.466.836-2.087-1.557a.672.672 0 0 0-1.074.547l.034 2.603-2.126 1.504a.672.672 0 0 0 .188 1.19l1.761.548L6.197 24.852a.672.672 0 1 0 .95.951l10.327-10.326.547 1.76a.672.672 0 0 0 1.191.19l1.504-2.127 2.603.034h.009a.672.672 0 0 0 .538-1.074Zm-3.487-.308a.67.67 0 0 0-.557.284l-.921 1.302c-.513-1.65-.486-1.645-.64-1.799-.165-.165-.19-.14-1.8-.64l1.303-.921a.673.673 0 0 0 .284-.557l-.02-1.595 1.278.954a.672.672 0 0 0 .618.098l1.51-.513-.511 1.51a.672.672 0 0 0 .098.619l.953 1.278-1.595-.02ZM12.772 8.628l-.521-.954-.522.954-.954.522.954.522.522.954.521-.954.954-.522-.954-.522ZM20.178 21.02l-.422-.773-.423.772-.771.422.771.422.423.772.422-.772.771-.422-.771-.422ZM20.771 6.772 20.35 6l-.422.772-.772.422.772.422.422.772.422-.772.772-.422-.772-.422ZM25.166 11.567l-.253-.462-.253.462-.462.252.462.253.253.462.253-.462.461-.252-.461-.253ZM25.243 17.489l-1.045.306-1.045-.306.306 1.045-.306 1.045 1.045-.306 1.045.306-.306-1.045.306-1.045Z" />
      </g>
      <defs>
        <clipPath id="buildicon">
          <path fill="#fff" d="M6 6h20v20H6z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Build;
