import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { credentialsEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost } from '@/api/fetcher';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountId } from '@/redux/session/selectors';

import { Credentials, Credential } from '../types';

export const API = Object.freeze({
  listCredentials: async (): Promise<Credentials> =>
    callGet(endpoints.credentials),

  createCredential: async (
    newCredential: Partial<Credential>
  ): Promise<Credential> => callPost(endpoints.credentials, newCredential),

  deleteCredential: async (credentialId: string): Promise<Credential> =>
    callDelete(endpoints.credential(credentialId)),
});

export const onCredentialCreated = (
  queryClient: QueryClient,
  credential: Credential
) => {
  queryClient.setQueryData<Credential>(
    [endpoints.credential(credential.credential_id)],
    (prev: Credential) => ({ ...prev, ...credential })
  );

  const queryKey = [endpoints.credentials, credential.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Credentials>(queryKey, (prev) => ({
      credentials: [
        ...(prev?.credentials || []).filter(
          (acc) => acc.credential_id !== credential.credential_id
        ),
        credential,
      ],
    }));
  }
};

export const onCredentialDelete = (
  queryClient: QueryClient,
  account_id: string,
  credential_id: string
) => {
  const queryKey = [endpoints.credentials, account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Credentials>(queryKey, (prev: Credentials) => ({
      credentials: (prev?.credentials || []).filter(
        (acc) => acc.credential_id !== credential_id
      ),
    }));
  }
  queryClient.removeQueries({
    queryKey: [endpoints.credential(credential_id)],
  });
};

const useCredentials = () => {
  const queryClient = useQueryClient();
  const accountId = useSelector(selectAccountId);
  const canReadCredentials = useSelector((state: RootState) =>
    getPermissions(state, 'credentials', actions.READ)
  );

  const { data: credentials, status: listStatus } = useQuery<
    Credentials,
    Error
  >({
    queryKey: [endpoints.credentials, accountId],
    queryFn: () => API.listCredentials(),
    enabled: !!accountId && canReadCredentials,
  });

  const { mutate: createCredential, status: createStatus } = useMutation<
    Credential,
    Error,
    Partial<Credential>
  >({
    mutationFn: (newCredential: Partial<Credential>) =>
      API.createCredential(newCredential),
    onSuccess: (resp) => {
      onCredentialCreated(queryClient, resp);
    },
  });

  const { mutate: deleteCredential, status: deleteStatus } = useMutation<
    Credential,
    Error,
    string
  >({
    mutationFn: (credential_id) => API.deleteCredential(credential_id),
    onSuccess: (resp) => {
      onCredentialDelete(queryClient, accountId, resp.credential_id);
    },
  });

  return {
    credentials: credentials?.credentials || [],
    listStatus,
    createCredential,
    createStatus,
    deleteCredential,
    deleteStatus,
  };
};

export default useCredentials;
