import { IconInterface } from '@/models/icon';

export const Gemini = ({ size = 24 }: IconInterface) => (
  <svg
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.685 11.283c0 1.839-1.127 7.123-2.092 9.81-1.126 3.13-3.338 7.473-5.256 10.316-3.137 4.65-8.79 9.863-13.922 12.84-4.69 2.722-11.373 4.931-16.138 5.335-2.435.206-3.149.731-.994.731 1.839 0 7.123 1.127 9.81 2.092 3.13 1.126 7.473 3.338 10.316 5.256 4.65 3.137 9.863 8.79 12.84 13.922 2.722 4.69 4.931 11.373 5.335 16.138.206 2.435.731 3.149.731.995 0-1.84 1.127-7.124 2.092-9.81 2.252-6.266 5.49-11.347 10.32-16.19 4.885-4.897 9.777-8.016 16.18-10.312 2.69-.965 7.974-2.091 9.81-2.091.71 0 1.29-.142 1.29-.315 0-.173-.58-.315-1.29-.315-1.839 0-7.123-1.127-9.81-2.092-3.13-1.126-7.473-3.338-10.316-5.256-4.65-3.137-9.863-8.79-12.84-13.922-2.722-4.69-4.931-11.373-5.335-16.138-.206-2.435-.731-3.149-.731-.994Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1="21.34"
        y1="41.029"
        x2="64.813"
        y2="62.607"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9168C0" />
        <stop offset=".552" stopColor="#478BD6" />
        <stop offset="1" stopColor="#1BA1E3" />
      </linearGradient>
    </defs>
  </svg>
);
