const Play = ({ size = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 20"
      width={size}
    >
      <path fill="#fff" d="M15 10 .75 19.526V.474L15 10Z" />
    </svg>
  );
};

export default Play;
