import { useEffect, useState, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTitle } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import HeadManager from '@/components/atoms/HeadManager/HeadManager';
import IconGoogle from '@/components/atoms/Icons/Google';
import Input from '@/components/atoms/Input/Input';
import Link from '@/components/atoms/Link/Link';
import Loading from '@/components/atoms/Loading/Loading';
import { Divider } from '@/components/organisms/Divider/Divider';
import useUser from '@/hooks/useUser';
import { EventName, PageName } from '@/models/segment';
import { BackgroundWrapper } from '@/modules/BackgroundWrapper/BackgroundWrapper';
import { loadUser } from '@/redux/user/actions';
import {
  selectIsAuthenticated,
  selectIsLoadingUser,
  selectIsTempUser,
  selectUserInvitation,
} from '@/redux/user/selectors';
import { pageView, trackEvent } from '@/segment/segment';
import {
  PRIVACY_POLICY_URL,
  SUBSCRIPTION_AGREEMENT_URL,
} from '@/util/constants';
import { isValidEmail } from '@/util/util';
import { emailRules } from '@/util/validator';

import styles from '../Signup.module.scss';

type SignupParams = {
  email: string;
};

const PROVIDERS = window.AUTH_PROVIDERS || 'email,google';

const HAS_GOOGLE = PROVIDERS.includes('google');
const HAS_EMAIL = PROVIDERS.includes('email');

const Signup = () => {
  const { t } = useTranslation();
  useTitle(t('pages.signup'));

  useEffect(() => {
    pageView(PageName.SIGNUP);
  }, []);

  const dispatch = useDispatch();
  const location = useLocation();

  const isLoading = useSelector(selectIsLoadingUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const hasInvitation = useSelector(selectUserInvitation);
  const isTempUser = useSelector(selectIsTempUser);
  const [loadingUser, setLoadingUser] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { loginWithMagicLink } = useUser();

  useEffect(() => {
    setLoadingUser(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isAuthenticated && !isTempUser) {
      dispatch(loadUser());
    }
  }, [dispatch, isAuthenticated, isTempUser]);

  //check if integration is present in search params
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = String(searchParams.get('to') || '/');
  const encodedRedirect = encodeURIComponent(redirectTo);

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupParams>({
    mode: 'onSubmit',
  });

  const handleGetStarted = useCallback(
    async ({ email }) => {
      const validMail = await isValidEmail(email);
      if (!validMail) {
        return setError(
          'email',
          { message: t('errors.company_email') },
          { shouldFocus: true }
        );
      }

      setIsSubmitting(true);
      const lowercaseEmail = email.toLowerCase();
      try {
        trackEvent(EventName.ClickSignupWithEmail);
        await loginWithMagicLink({
          email: lowercaseEmail,
          redirectTo: `/signup/welcome?to=${encodedRedirect}`,
        });
      } catch (error) {
        setError(
          'email',
          { message: error.rawMessage || error.message },
          { shouldFocus: true }
        );
      }

      setIsSubmitting(false);
    },

    [encodedRedirect, loginWithMagicLink, setError, t]
  );

  if (hasInvitation) {
    return <Navigate to="/invitation/details" />;
  }

  if (isTempUser) {
    const qs = redirectTo && redirectTo !== '/' ? `?to=${encodedRedirect}` : '';
    return <Navigate to={`/signup/welcome${qs}`} />;
  }

  if (isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  if (loadingUser) {
    return <Loading />;
  }

  return (
    <BackgroundWrapper
      title={t('signup.header')}
      subtitle={
        <>
          {t('signup.subheader')}{' '}
          <ReactRouterLink to="/login">
            <Typography className={styles.link} variant="subheading-semi-bold">
              {t('signup.log_in_here')}
            </Typography>
          </ReactRouterLink>
        </>
      }
      showBranding
    >
      <HeadManager
        title="Sign up to Moveo.AI and experience the future of Customer Service"
        description="Automate over 70% of your processes, personalize customer interactions and boost employee efficiency. Signup to Moveo.AI today and start your 30-day free trial."
      >
        {HAS_EMAIL && (
          <form onSubmit={handleSubmit(handleGetStarted)}>
            <Input
              size="xlarge"
              name="email"
              id="email"
              label="email"
              placeholder="Email"
              errorMessage={errors.email?.message}
              {...register('email', emailRules(t))}
            />
            <Button
              type="submit"
              size="large"
              isFullWidth
              className={styles.button}
              isLoading={isSubmitting}
            >
              {t('signup.get_started')}
            </Button>
          </form>
        )}
        {HAS_EMAIL && HAS_GOOGLE && (
          <Divider text={t('rules.or').toUpperCase()} />
        )}
        {HAS_GOOGLE && (
          <Button
            href={`/www/api/auth/google?to=${encodedRedirect}`}
            size="large"
            isFullWidth
            isLink
            variant="social"
            className={styles.social}
            onClick={() => trackEvent(EventName.ClickSignupWithGoogle)}
          >
            <IconGoogle size={24} />
            {t('signup.with', { 0: t('login.google') })}
          </Button>
        )}
        <div className={styles.termsAndConditions}>
          <Typography variant="label-regular" fontSize="10px">
            <Trans i18nKey="signup.privacy_policy">
              By submitting my personal information, I understand and agree that
              Moveo.AI may collect, process, and retain my data pursuant to the
              Moveo.AI
              <Link
                target="_blank"
                href={PRIVACY_POLICY_URL}
                rel="noopener noreferrer"
              >
                Privacy Policy.
              </Link>
            </Trans>
          </Typography>
          <Typography variant="label-regular" fontSize="10px">
            <Trans i18nKey="signup.subscription_agreement">
              By signing up with any of the above 3 options you agree to the
              <Link
                target="_blank"
                href={SUBSCRIPTION_AGREEMENT_URL}
                rel="noopener noreferrer"
              >
                Moveo Subscription Agreement.
              </Link>
            </Trans>
          </Typography>
        </div>
      </HeadManager>
    </BackgroundWrapper>
  );
};

export default Signup;
