import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export const EmptySection = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      height="calc(650px - 56px - 40px)"
      gap="var(--space-8)"
      textAlign="center"
    >
      <img
        src="/assets/modals/unavailable.png"
        alt={t('common.unavailable')}
        width="40"
        height="40"
      />
      <Typography
        variant="subheading-semi-bold"
        color="var(--text-default-gray)"
      >
        {t('try_it.empty_details_title')}
      </Typography>
      <Typography color="var(--text-default-gray)">
        {t('try_it.empty_details_subtitle')}
      </Typography>
    </Box>
  );
};
