import { Icon } from '@/models/icon';

const Link = ({ size = 16, color = 'var(--icon-default-black)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 4.667h2a3.333 3.333 0 1 1 0 6.666h-2m-4 0H4a3.332 3.332 0 0 1-2.357-5.69A3.333 3.333 0 0 1 4 4.667h2"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.333 8h5.333"
      />
    </svg>
  );
};

export default Link;
