import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AspectRatio } from '@/components/atoms/ImageCrop/ImageCrop';
import { MODAL_IMAGE_CROP } from '@/components/organisms/Modals/ModalConductor';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { pushModal } from '@/redux/modals/actions';
import { MAX_UPLOAD_SIZE } from '@/util/constants';

type Props = AspectRatio;

export type ExtraUploadConfig = {
  primaryButtonText?: string;
};

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

const useFileUpload = (aspectRatio?: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [croppedFile, setCroppedFile] = useState(null);

  const onAvatarCrop = useCallback(async (data) => {
    const reader = new FileReader();
    reader.readAsDataURL(data.avatar);
    reader.onloadend = async () => {
      const result: string = reader.result as string;
      const file = await dataUrlToFile(result, `image.png`);
      setCroppedFile(file);
    };
  }, []);

  const onAvatarUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    file?: File,
    extraConfig?: ExtraUploadConfig
  ) => {
    const fileToCrop = file ?? e.target?.files[0];
    if (fileToCrop) {
      if (fileToCrop.size > MAX_UPLOAD_SIZE) {
        dispatch(addTemporalToast('error', t('file_upload.fix_too_large')));
        e.target.value = '';
      } else {
        const imageProps = {
          image: URL.createObjectURL(fileToCrop),
          onEdit: onAvatarCrop,
          aspectRatio,
          extraConfig,
        };
        dispatch(pushModal(MODAL_IMAGE_CROP, imageProps));
      }
    }
  };
  return { onAvatarUpload, croppedFile };
};

export default useFileUpload;
