import { Icon } from '@/models/icon';

const OrderedList = ({
  size = 14,
  color = 'var(--icon-default-black)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill={color}
        d="M0 14v-.744h1.633v-.569H.817v-.743h.816v-.569H0v-.744h2.294V14H0Zm4.064-1.072v-1.312H14v1.312H4.064ZM0 8.684v-.7L1.458 6.06H0v-.743h2.294v.7L.817 7.94h1.477v.743H0Zm4.064-1.071V6.3H14v1.313H4.064Zm-3.247-4.2V.743H0V0h1.478v3.413H.817Zm3.247-1.116V.984H14v1.313H4.064Z"
      />
    </svg>
  );
};

export default OrderedList;
