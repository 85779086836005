const NotFound = () => {
  return (
    <svg
      width="498"
      height="290"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 197.909v-21.307L88.551 57.454h16.79v31.364H94.688l-53.95 85.398v1.364H144.12v22.329H13.125ZM95.881 232v-40.568l.17-9.716V57.454h24.972V232H95.881Z"
        fill="#1B66D6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M313.682 175s-.877 22.189.991 32.703c.513 2.891 1.827 5.499 3.463 7.762 3.813 5.273 2.89 8.975-3.348 7.121-5.22-1.551-12.308-3.987-21.908-7.781C276.785 204.413 313.682 175 313.682 175Z"
        fill="#1B66D6"
      />
      <ellipse cx="248.877" cy="145" rx="79.877" ry="80" fill="#1B66D6" />
      <rect
        x="199.787"
        y="156.668"
        width="15.833"
        height="26.626"
        rx="7.917"
        transform="rotate(-90 199.787 156.668)"
        fill="#fff"
      />
      <rect
        x="233.903"
        y="156.668"
        width="15.833"
        height="26.626"
        rx="7.917"
        transform="rotate(-90 233.903 156.668)"
        fill="#fff"
      />
      <rect
        x="268.017"
        y="156.668"
        width="15.833"
        height="26.626"
        rx="7.917"
        transform="rotate(-90 268.017 156.668)"
        fill="#fff"
      />
      <rect
        x="199.787"
        y="132.131"
        width="17.197"
        height="34.342"
        rx="8.599"
        transform="rotate(-90 199.787 132.131)"
        fill="#fff"
      />
      <rect
        x="199.787"
        y="183.85"
        width="17.197"
        height="34.342"
        rx="8.599"
        transform="rotate(-90 199.787 183.85)"
        fill="#fff"
      />
      <ellipse cx="266.564" cy="123.722" rx="9.539" ry="9.554" fill="#fff" />
      <ellipse cx="245.577" cy="123.723" rx="5.724" ry="5.732" fill="#fff" />
      <ellipse cx="287.546" cy="123.723" rx="5.724" ry="5.732" fill="#fff" />
      <path
        d="M353.125 197.909v-21.307l75.426-119.148h16.79v31.364h-10.653l-53.949 85.398v1.364h103.38v22.329H353.125ZM435.881 232v-40.568l.17-9.716V57.454h24.972V232h-25.142Z"
        fill="#1B66D6"
      />
    </svg>
  );
};

export default NotFound;
