import { useEffect } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import SubHeaderIntent from '@/components/organisms/SubHeader/SubHeaderIntent';
import SubNavIntent from '@/components/organisms/SubNav/SubNavIntent';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useBrains from '@/hooks/useBrains';
import useIntents from '@/hooks/useIntents';
import { PageName } from '@/models/segment';
import { selectBrainId, selectIntentName } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import styles from './Intents.module.scss';

export default function Intents() {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const intentName = useSelector(selectIntentName);
  const { intents, listStatus } = useIntents(brainId);
  const { brain } = useBrains(brainId);

  useTitle(t('pages.intents', { 0: brain?.name }));

  useEffect(() => {
    pageView(PageName.INTENTS);
  }, []);

  if (intents?.length) {
    return <Navigate to={`${intentName ?? intents[0].intent}`} replace />;
  }

  return (
    <div className={styles.wrapper}>
      <SubNavIntent />
      <SubHeaderIntent />
      <PageContentWrapper fullHeight>
        {listStatus === 'pending' && (
          <Skeleton animation="wave" variant="rectangular" height={48} />
        )}
      </PageContentWrapper>
    </div>
  );
}
