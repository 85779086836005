import { createRef, useEffect, useRef } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useMacros } from '@/hooks/useMacros';
import { Macro } from '@/models/macros';
import { setFocusIndex } from '@/redux/macros/actions';
import { focusIndexSelector } from '@/redux/macros/selectors';

import styles from '../ChatBox/ChatBox.module.scss';

interface Props {
  handleClick: (x: string) => void;
  filteredOptions: Partial<Macro>[];
}

const QuickResponses = ({ handleClick, filteredOptions }: Props) => {
  const { t } = useTranslation();

  const { listStatus } = useMacros();
  const dispatch = useDispatch();
  const focusIndex = useSelector(focusIndexSelector);

  const optionRefs = useRef([]);
  optionRefs.current = filteredOptions.map(
    (_, i) => optionRefs.current[i] ?? createRef()
  );
  useEffect(() => {
    if (optionRefs.current[focusIndex]) {
      optionRefs.current[focusIndex].current?.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [focusIndex]);

  return (
    <div className={styles.quickResponseOuterWrapper}>
      {filteredOptions?.length > 0 &&
        filteredOptions?.map(({ name, text, macro_id }, i) => (
          <div key={macro_id} ref={optionRefs.current[i]}>
            <button
              className={cn(styles.buttonOuterWrapper, {
                [styles.hover]: focusIndex === i,
              })}
              onClick={() => handleClick(text)}
              onMouseEnter={() => dispatch(setFocusIndex(i))}
            >
              <span>{name}</span>
              <span>{text}</span>
            </button>
          </div>
        ))}
      {filteredOptions?.length === 0 && listStatus !== 'pending' && (
        <div className={styles.noResults}>{t('chatBox.no_response')}</div>
      )}
      {listStatus === 'pending' && <div>{t('common.loading')}</div>}
    </div>
  );
};

export default QuickResponses;
