import { Icon } from '@/models/icon';

const Charts = ({ size = 32, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon_fill"
    >
      <circle cx="16" cy="16" r="7.5" stroke={color} />
      <path
        d="M11.68 13.313v.628h1.332v4.434H11.68V19h3.437v-.625h-1.363v-4.434h1.363v-.629H11.68Zm7.968 3.175v-.613h-2.367V13.93h2.762v-.617h-3.492V19h.73v-2.512h2.367Z"
        fill={color}
      />
    </svg>
  );
};
export default Charts;
