import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import DeskHeader from '@/components/organisms/Headers/DeskHeader';
import { SettingsNavigation } from '@/components/organisms/Navbar/SettingsNavigation/SettingsNavigation';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useDesks from '@/hooks/useDesks';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';

import CreateDeskTile from './CreateDeskTile';

export default function Desks() {
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();
  useTitle(t('pages.environments', { 0: slug }));

  const { desks, listStatus } = useDesks();
  const deskId = useSelector(selectDeskId);
  const firstDeskId = listStatus === 'success' && desks[0]?.desk_id;

  if (listStatus === 'success' && desks.length) {
    return (
      <Navigate to={`/${slug}/environments/${deskId ?? firstDeskId}`} replace />
    );
  }

  return (
    <Layout withSidebar>
      <SettingsNavigation />
      <DeskHeader />
      <PageContentWrapper fullHeight>
        <ContextualHelp
          title={t('environments.contextual.title')}
          name="desk"
          size="full"
        >
          {t('environments.contextual.body')}
        </ContextualHelp>
        <CreateDeskTile />
      </PageContentWrapper>
    </Layout>
  );
}
