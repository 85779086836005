import { ResponsiveLine, Serie } from '@nivo/line';

interface Props {
  data: Serie[];
  colors?: string[];
}
const LineAnalytics = ({ data, colors }: Props) => {
  if (!data) {
    return null;
  }
  return (
    <ResponsiveLine
      margin={{ top: 5 }}
      data={data}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      areaOpacity={0.1}
      lineWidth={1}
      isInteractive={false}
      enableArea
      areaBlendMode="normal"
      colors={colors}
      animate={false}
    />
  );
};

export default LineAnalytics;
