import { Ref, useCallback, useState } from 'react';

import { TextareaAutosize as MUITextareaAutosize } from '@mui/base/TextareaAutosize';
import cn from 'classnames';

import styles from './TextareaAutosize.module.scss';

type Props = {
  inputRef?: Ref<HTMLTextAreaElement>;
  autoFocus?: boolean;
  defaultValue: string;
  placeholder?: string;
  onKeyUp: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  dataTestId?: string;
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  hasErrors?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  shouldMinimize?: boolean;
  maxRows?: number;
};

const TextareaAutosize = ({
  inputRef = null,
  autoFocus = false,
  defaultValue,
  placeholder = '',
  onKeyUp,
  onBlur,
  dataTestId = '',
  onKeyDown,
  hasErrors = false,
  disabled = false,
  readOnly = false,
  shouldMinimize = false,
  maxRows,
}: Props) => {
  const [rows, setRows] = useState<number>(maxRows);

  const toggleTextarea = useCallback(() => {
    setRows(undefined);
  }, []);

  const handleBlur = useCallback(() => {
    if (shouldMinimize) {
      setRows(maxRows);
    }
  }, [maxRows, shouldMinimize]);

  return (
    <div
      className={cn(styles.input, {
        [styles['input--error']]: hasErrors,
        [styles['input--readOnly']]: readOnly,
        [styles['input--disabled']]: disabled,
      })}
    >
      <MUITextareaAutosize
        ref={inputRef}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={readOnly}
        maxRows={rows}
        onClick={toggleTextarea}
        onMouseDown={toggleTextarea}
        onBlurCapture={handleBlur}
        data-testid={dataTestId}
        onKeyDown={onKeyDown}
        className={styles.textarea}
      />
    </div>
  );
};

export default TextareaAutosize;
