import './HeatMap.scss';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import {
  DefaultHeatMapDatum,
  HeatMapSerie,
  ResponsiveHeatMap,
} from '@nivo/heatmap';

interface Props {
  data?: HeatMapSerie<DefaultHeatMapDatum, Record<string, never>>[];
}

const color1 = '#2f68cf';
const color2 = '#3c8bf4';
const color3 = '#74b7f9';
const color4 = '#c2defc';

const HeatMap = ({ data: mydata }: Props) => {
  if (!mydata) {
    return (
      <div className="bar-chart">
        <Skeleton variant="rectangular" width={250} height={350} />
      </div>
    );
  }

  return (
    <div className="heatmap-chart">
      <ResponsiveHeatMap
        data={mydata}
        margin={{ top: 40, right: 10, bottom: 40, left: 40 }}
        xInnerPadding={0.02}
        yInnerPadding={0.1}
        colors={{
          type: 'quantize',
          colors: [color4, color3, color2, color1],
        }}
        emptyColor="var(--icon-default-white)"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        inactiveOpacity={0.9}
        tooltip={({ cell }) => {
          return (
            <Typography
              component="div"
              variant="label-regular"
              className="heatmap-tooltip"
            >
              {cell.data.x} - {cell.serieId}: <strong>{cell.value}</strong>
            </Typography>
          );
        }}
        theme={{
          tooltip: {
            container: {
              background: 'transparent',
              border: 0,
              boxShadow: 'none',
            },
          },
        }}
        enableLabels={false}
        hoverTarget="cell"
        animate={false}
      />
    </div>
  );
};

export default HeatMap;
