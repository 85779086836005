import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
  focusIndex: 0,
  options: [],
};

const slice = createSlice({
  name: 'macros',
  initialState: defaultState,
  reducers: {
    incrementFocusIndex: (state) => {
      state.focusIndex =
        state.focusIndex === state.options.length - 1
          ? 0
          : state.focusIndex + 1;
    },
    decrementFocusIndex: (state) => {
      state.focusIndex =
        state.focusIndex === 0
          ? state.options.length - 1
          : state.focusIndex - 1;
    },
    setFocusIndex: (state, action) => {
      state.focusIndex = action.payload;
    },
    setFilteredOptions: (state, action) => {
      state.options = action.payload;
    },
    setInitialOptions: (state, action) => {
      const { macros } = action.payload;
      state.options = macros;
    },
  },
});
export const {
  incrementFocusIndex,
  decrementFocusIndex,
  setFocusIndex,
  setFilteredOptions,
  setInitialOptions,
} = slice.actions;

export default slice.reducer;
