import { Icon } from '@/models/icon';

export const Datasource = ({
  size = 24,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 65 64"
      width={size}
      height={size}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path
        stroke={color}
        d="M32.145 21.333c13.255 0 24-3.581 24-8 0-4.418-10.745-8-24-8s-24 3.582-24 8c0 4.419 10.745 8 24 8ZM56.145 32c0 4.427-10.667 8-24 8s-24-3.573-24-8"
      />
      <path
        stroke={color}
        d="M8.145 13.333v37.334c0 4.426 10.667 8 24 8s24-3.574 24-8V13.333"
      />
    </svg>
  );
};
