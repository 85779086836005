import { HTMLAttributes } from 'react';

import { Icon } from '@/models/icon';

export const DatabaseIcon = ({
  size = 24,
  color = 'inherit',
  ...rest
}: Icon & HTMLAttributes<SVGElement>) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.906 8c4.97 0 9-1.343 9-3s-4.03-3-9-3-9 1.343-9 3 4.03 3 9 3ZM21.906 12c0 1.66-4 3-9 3s-9-1.34-9-3"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.906 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"
      />
    </svg>
  );
};
