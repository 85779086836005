import TextareaAutosize from '@mui/material/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import { urlPattern } from '@/util/validator';

import { removeSpecialCharsAtEnd } from './helper';

import styles from './ContextVariableInput.module.scss';

export type ContextVariableInputProps = {
  label: string;
  value: string;
};

export const ContextVariableInput = ({
  label,
  value,
}: ContextVariableInputProps) => {
  const isValueUrl = urlPattern.test(removeSpecialCharsAtEnd(value));

  return (
    <>
      <Tooltip
        arrow
        placement="left"
        title={label}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -4],
                },
              },
            ],
          },
        }}
      >
        <Typography
          variant="label-caps-large"
          color="var(--text-default-gray-light)"
          className={styles.label}
          component="label"
          htmlFor={label}
        >
          {label}
        </Typography>
      </Tooltip>

      <TextareaAutosize
        id={label}
        name={label}
        value={value}
        readOnly
        aria-label={label}
        className={cn({
          [styles.value]: true,
          [styles.url]: isValueUrl,
        })}
        onClick={(e) => {
          if (isValueUrl) {
            e.stopPropagation();
            window.open(value, '_blank');
          }
        }}
      />
    </>
  );
};
