import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { MODAL_LANGUAGE_MODEL_CREATE } from '@/components/organisms/Modals/ModalConductor';
import {
  modelIconResolver,
  modelNameMapper,
  providerNameMapper,
} from '@/modules/developerTools/pages/languageModels/helper';
import { pushModal } from '@/redux/modals/actions';

import { ProviderName } from '../../types';

import styles from './LanguageModelTile.module.scss';

interface Props {
  provider: ProviderName;
}

export const NonConnected = ({ provider }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnClick = useCallback(() => {
    dispatch(pushModal(MODAL_LANGUAGE_MODEL_CREATE, { provider }));
  }, [dispatch, provider]);

  return (
    <div className={styles.nonConnected}>
      <span className={styles.logo}>
        {modelIconResolver(provider)}
        <Typography variant="h3-semi-bold">
          {modelNameMapper(provider)}
        </Typography>
      </span>
      <Typography
        variant="label-regular"
        color="var(--text-default-gray)"
        className={styles.subtitle}
      >
        {t('developer_tools.not_connected_subtitle', {
          model: providerNameMapper(provider),
        })}
      </Typography>
      <Button
        variant="secondary"
        size="small"
        className={styles.button}
        onClick={handleOnClick}
      >
        {t('common.configure')}
      </Button>
    </div>
  );
};
