import { useMemo } from 'react';

import { ApolloError } from '@apollo/client';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '@/models/state';
import { Analytics } from '@/modules/analytics/models';
import { Bar, GeneralRow, prepareBar } from '@/util/analytics';
import { numberFormatter } from '@/util/util';

import useInterval, { IntervalType } from './useInterval';

export interface ScoreCard {
  id?: string;
  counts?: number | string;
  exact_counts?: number;
  growth?: number;
  series?: Bar[];
}

interface ScoreCardHook {
  isLoading: boolean;
  isPreviousLoading?: boolean;
  currentError?: ApolloError;
  previousError?: ApolloError;
  refetchCurrent?: () => void;
  refetchPrevious?: () => void;
  data?: ScoreCard;
}

interface Props {
  intervalProps: {
    type: IntervalType;
  };
  skip?: boolean;
  skipPrevious?: boolean;
}

export const formatScoreCard = (
  id: string,
  currentTotal?: GeneralRow[],
  previousTotal?: GeneralRow[]
): ScoreCard | undefined => {
  if (!currentTotal) {
    return {};
  }

  const {
    newBar: newSeries,
    currentTotalCounts,
    previousTotalCounts,
  } = prepareBar({ currentTotal, previousTotal });

  let growth = 0;
  if (previousTotal) {
    if (previousTotal && previousTotalCounts > 0) {
      growth = Math.round(
        ((currentTotalCounts - previousTotalCounts) / previousTotalCounts) * 100
      );
    }
  }

  return {
    id,
    series: newSeries,
    counts: numberFormatter(currentTotalCounts),
    exact_counts: currentTotalCounts,
    growth: previousTotal ? growth : undefined,
  };
};

const useScoreCard = ({
  intervalProps,
  skip,
  skipPrevious,
}: Props): ScoreCardHook => {
  const { startDate, endDate }: Analytics = useSelector(
    (state: RootState) => state.analytics,
    shallowEqual
  );

  const periodInDays = moment(endDate).diff(moment(startDate), 'days');

  const previousStartDate = startDate
    ? moment(startDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;
  const previousEndDate = endDate
    ? moment(endDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;

  const {
    data: currentData,
    isLoading: currentLoading,
    error: currentError,
    refetch: refetchCurrent,
  } = useInterval(intervalProps, skip);

  const {
    data: previousData,
    isLoading: previousLoading,
    error: previousError,
    refetch: refetchPrevious,
  } = useInterval(
    {
      ...intervalProps,
      startDate: previousStartDate,
      endDate: previousEndDate,
      is_contained: null,
      is_covered: null,
    },
    skip || skipPrevious
  );
  const data = useMemo(
    () => formatScoreCard(intervalProps.type, currentData, previousData),
    [intervalProps.type, currentData, previousData]
  );

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (previousLoading && !skipPrevious) {
    return {
      data,
      isLoading: false,
      isPreviousLoading: true,
    };
  }

  if (currentError || previousError) {
    return {
      isLoading: false,
      currentError,
      previousError,
      refetchCurrent,
      refetchPrevious,
    };
  }
  return { isLoading: false, data };
};

export default useScoreCard;
