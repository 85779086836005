import Clear from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import { Control, Controller, Path, UseControllerProps } from 'react-hook-form';

import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import Close from '@/components/atoms/Icons/Close';
import { OptionBase } from '@/models/common';
import { preventClickThrough, isKeyEnter } from '@/util/util';

import AutocompleteOption from './AutocompleteOption';
import { getStyles } from './styles';
import { useAutocomplete } from './useAutocomplete';

import './AutoComplete.scss';

export type Option = OptionBase<{
  type?: string;
  description?: string;
  disabled?: boolean;
}>;
export interface Props<FormType> {
  onBlur?: () => void;
  error?: boolean;
  id?: string;
  onChange?: (event) => void;
  freeSolo?: boolean;
  getOptionSelected?: (option, value) => boolean;
  disableAddNew?: boolean;
  disabled?: boolean;
  placeholder?: string;
  errorMessage?: React.ReactNode | string;
  label?: string;
  labelLarge?: boolean;
  tooltip?: string;
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xlarge';
  required?: boolean;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>, value: string) => void;
  disableClearable?: boolean;
  name?: string;
  control: Control<FormType>;
  rules?: UseControllerProps<FormType>['rules'];
  getOptionLabel?: (option: Option) => string;
}

export type Simple = {
  options: Option[];
  multiple?: never;
  filterSelectedOptions?: true;
  addNewSymbol?: string;
  disablePattern: RegExp;
  variant?: never;
};

export type Multiple = {
  options: Option[] | string[];
  multiple?: true;
  filterSelectedOptions?: boolean;
  addNewSymbol?: never;
  disablePattern?: never;
  variant?: 'default' | 'primary' | 'secondary';
};

const defaultGetOptionLabel = (option) => {
  // convert to string because the label & value values returned by the orchestrator can be int
  return option?.label ? toString(option?.label) : toString(option);
};
const AutoCompleteWrapper = <FormType,>({
  options = [],
  onBlur = () => {},
  onChange = () => {},
  error = false,
  id,
  disabled = false,
  disableAddNew = false,
  placeholder = '',
  label = '',
  labelLarge,
  tooltip = '',
  required = false,
  onKeyUp,
  name,
  disableClearable = false,
  disablePattern,
  addNewSymbol = '',
  control,
  rules,
  freeSolo = false,
  multiple,
  errorMessage,
  getOptionSelected = (option, value) =>
    option?.value ? option?.value === value?.value : option === value,
  size = 'small',
  variant = 'default',
  filterSelectedOptions = !multiple,
  getOptionLabel = defaultGetOptionLabel,
}: Props<FormType> & (Simple | Multiple)) => {
  const muiStyles = getStyles(size, variant, multiple);

  const { onInputBlur, getFilterOptions, onMultipleBlur } = useAutocomplete();

  return (
    <div
      className={cn('input_autocomplete', {
        input_autocomplete__withLabel: label,
        ['input_autocomplete--withErrorMessage']: errorMessage === null,
        ['input_autocomplete--isErrorMessageVisible']: !!errorMessage,
      })}
    >
      {label && (
        <label
          className={cn({
            input_autocomplete__label: true,
            input_autocomplete__label__large: labelLarge,
          })}
          htmlFor={id}
        >
          {label}
          {required && '*'}
          <HelpTooltip tooltip={tooltip} />
        </label>
      )}
      <Controller<FormType>
        name={name as Path<FormType>}
        control={control}
        rules={rules}
        render={({
          field: {
            onChange: onControllerChange,
            onBlur: onControllerBlur,
            value,
          },
        }) => {
          return (
            <Autocomplete
              // open
              clearIcon={<Close size={12} />}
              disabled={disabled}
              disableClearable={disableClearable}
              disableCloseOnSelect={multiple}
              sx={muiStyles}
              options={options}
              id="grouped-demo"
              groupBy={(option) => option?.type?.toUpperCase() ?? ''}
              filterSelectedOptions={filterSelectedOptions}
              freeSolo={freeSolo}
              multiple={multiple}
              clearOnBlur
              isOptionEqualToValue={getOptionSelected}
              limitTags={2}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 4],
                      },
                    },
                  ],
                },
              }}
              onBlur={() => {
                onControllerBlur();
                if (onBlur) {
                  onBlur();
                }
              }}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={(option) => option?.disabled ?? false}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value={value as any}
              onChange={(
                _event,
                newValue: string | string[] | Record<string, string | boolean>
              ) => {
                if (typeof newValue === 'string') {
                  onControllerChange({ label: newValue });
                  onChange({ label: newValue });
                } else if (!multiple && newValue && newValue?.['inputValue']) {
                  // Create a new value from the user input
                  onControllerChange({ label: newValue?.['inputValue'] });
                  onChange({ label: newValue?.['inputValue'] });
                } else {
                  const updatedValue = onMultipleBlur(newValue);
                  onControllerChange(updatedValue);
                  onChange(updatedValue);
                }
              }}
              filterOptions={(optionsToFilter, params) => {
                return getFilterOptions(
                  optionsToFilter,
                  params,
                  disableAddNew,
                  disablePattern,
                  addNewSymbol
                );
              }}
              renderOption={(props, option, state) => {
                return (
                  <AutocompleteOption
                    option={option}
                    selected={state.selected}
                    multiple={multiple}
                    {...props}
                    key={option.value ?? option.label ?? option}
                  />
                );
              }}
              forcePopupIcon={(multiple && options.length > 0) || false}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.label ?? option}
                    deleteIcon={<Clear />}
                    {...getTagProps({ index })}
                    key={option.label ?? option}
                  />
                ));
              }}
              renderInput={(params) => {
                const isValueEmpty = isEmpty(value);
                const showPlaceholder = multiple
                  ? !value || (typeof value === 'object' && isValueEmpty)
                  : true;
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={showPlaceholder ? placeholder : ''}
                    inputProps={{
                      ...params.inputProps,
                      id,
                    }}
                    sx={{
                      '& input': {
                        ...muiStyles.input,
                      },
                    }}
                    error={error}
                    autoComplete="off"
                    onBlur={(e) => {
                      const newValue = onInputBlur(
                        e.target.value,
                        multiple,
                        freeSolo,
                        value
                      );
                      if (newValue) {
                        onControllerChange(newValue);
                      }
                      return e;
                    }}
                    onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      preventClickThrough(e);
                      if (onKeyUp && isKeyEnter(e)) {
                        const target = e.target as HTMLInputElement;
                        onKeyUp(e, target.value);
                      }
                    }}
                  />
                );
              }}
            />
          );
        }}
      />
      {errorMessage && (
        <p className="input_autocomplete__error">{errorMessage}</p>
      )}
    </div>
  );
};

export default AutoCompleteWrapper;
