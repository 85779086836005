import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { callGet } from '@/api/fetcher';
import { SuncoIntegration } from '@/models/integration';
import { selectAccountSlug } from '@/redux/session/selectors';
import { SUNCO_AUTHORIZATION_URL } from '@/util/constants';

import { useIntegrations } from './useIntegrations';

const createCallbackURL = () =>
  `${location.origin}/www/api/integrations/sunco/callback`;

const createOAuthURL = async (slug: string, integration: SuncoIntegration) => {
  const callbackUrl = createCallbackURL();
  const { desk_id, integration_id } = integration;

  const client_id = await callGet('/www/api/integrations/sunco/client-id');

  const queryParams = new URLSearchParams({
    redirect_uri: callbackUrl,
    response_type: 'code',
    client_id,
    state: JSON.stringify({ desk_id, integration_id, slug }),
  });
  return `${SUNCO_AUTHORIZATION_URL}?${queryParams}`;
};

export const useSunco = () => {
  const { deskId, integrationId } = useParams();
  const slug = useSelector(selectAccountSlug);
  const { integration } = useIntegrations<SuncoIntegration>(
    deskId,
    integrationId
  );

  const hasAPIkey =
    !!integration?.config?.apikey_id && !!integration?.config?.apikey_secret;

  const isInvalidAccessToken =
    integration?.status === 'invalid-authentication' ||
    integration?.status === 'missing-credentials';

  const isConnected =
    !!integration?.config?.app_id &&
    !!integration?.config?.webhook_secret &&
    (!!integration?.config?.access_token || hasAPIkey);

  const onOAuthClick = useCallback(async () => {
    const suncoUrl = await createOAuthURL(slug, integration);
    window.open(suncoUrl, '_self');
  }, [slug, integration]);

  return {
    onOAuthClick,
    integration,
    isConnected,
    isInvalidAccessToken,
  };
};
