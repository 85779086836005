import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import CreateDeskTile from '@/components/pages/Desks/CreateDeskTile';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { useAccount } from '@/hooks/useAccount';
import useDesks from '@/hooks/useDesks';
import RulesHeader from '@/modules/rules/components/RulesHeader/RulesHeader';

const RulesEmpty = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { desks, listStatus } = useDesks();

  const { slug } = useAccount();
  const { slug: urlSlug } = useParams();

  useEffect(() => {
    if (slug === urlSlug && listStatus === 'success' && desks.length > 0) {
      navigate(`/${urlSlug}/environments/${desks[0].desk_id}/rules`);
    }
  }, [desks, listStatus, slug, urlSlug, navigate]);

  return (
    <Layout>
      <PermissionsWrapper module="rules">
        <RulesHeader />
        <PageContentWrapper newPlain>
          <ContextualHelp
            title={t('rules.help_title')}
            name="rules-help"
            size="full"
          >
            {t('rules.help_body')}
          </ContextualHelp>
          <CreateDeskTile />
        </PageContentWrapper>
      </PermissionsWrapper>
    </Layout>
  );
};

export default RulesEmpty;
