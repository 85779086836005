import { useState, useEffect } from 'react';

import {
  HANDLE_REGEX,
  markdownToHtml,
} from '@/components/organisms/RichTextEditor/utils';
import { Action, TextAction } from '@/models/action';

import styles from './Text.module.scss';

const Text = ({ action }: { action: TextAction }) => {
  const { texts = [] }: { texts: string[] } = action as Extract<
    Action,
    { type: 'text' }
  >;
  const text = texts[0] || '';
  const [htmlContent, setHtmlContent] = useState('');
  useEffect(() => {
    (async () => {
      const htmlText = await markdownToHtml(text);
      setHtmlContent(
        htmlText.replace(
          HANDLE_REGEX,
          '<span style="color: var(--text-default-blue);">$&</span>'
        )
      );
    })();
  }, [text]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      className={styles.text}
    />
  );
};

export default Text;
