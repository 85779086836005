import { Icon } from '@/models/icon';

export const ChevronRight = ({
  size = 12,
  color = 'var(--icon-default-white)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 12 12"
      stroke={color}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".75"
        d="m4.5 9 3-3-3-3"
      />
    </svg>
  );
};
