import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useHomeCheckList, {
  INAPPHELP_KEYS,
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import useLocalStorage from '@/hooks/useLocalStorage';
import { RootState } from '@/models/state';
import { hideHelper, showHelper } from '@/redux/helpers/actions';
import { selectHelper } from '@/redux/helpers/selectors';
import { popModal } from '@/redux/modals/actions';

const useRuleHelper = () => {
  const { accountUserChecklist } = useHomeCheckList();
  const dispatch = useDispatch();

  const [dismissHelp, setDismissHelp] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.UPDATE_RULE],
    accountUserChecklist[AccountUserPrefsEnum.UPDATE_RULE]
  );

  useEffect(() => {
    if (
      !dismissHelp &&
      accountUserChecklist &&
      !accountUserChecklist[AccountUserPrefsEnum.UPDATE_RULE]
    ) {
      dispatch(showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.UPDATE_RULE]));
      return () => {
        dispatch(popModal());
      };
    }
  }, [accountUserChecklist, dismissHelp, dispatch]);

  const handleDismissTooltip = () => {
    setDismissHelp(true);
    dispatch(hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.UPDATE_RULE]));
  };

  const showTooltip = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.UPDATE_RULE])
  );

  return {
    handleDismissTooltip,
    showTooltip,
  };
};

export default useRuleHelper;
