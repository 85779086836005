import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { brainsEndpoints as endpoints } from '@/api/endpoints';
import { callGet, callPost } from '@/api/fetcher';
import { Brain } from '@/models/brain';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';

import { onBrainCreated } from './useBrains';
type S<T, K extends keyof T, R extends keyof T> = Pick<T, K> &
  Partial<Pick<T, R>>;

// brain_id is required, other are optional
type TemplateAsync = S<
  Brain,
  'brain_id',
  'name' | 'description' | 'language' | 'confidence_threshold'
>;

type Template = {
  id: string;
  category: string;
  integration_id: string;
  advanced?: boolean;
  languages: Record<string, { brain_id: string }>;
};

type Metadata = {
  categories: string[];
  templates: Template[];
};

export const API = Object.freeze({
  getMetadata: async (): Promise<Metadata> =>
    callGet(`/templates/metadata.json`),
  createBrain: async ({ brain_id, ...rest }: TemplateAsync): Promise<Brain> =>
    callPost(`/www/api/templates/${brain_id}`, rest),
  getTemplate: async (lang: string): Promise<Brain> =>
    callGet(endpoints.templates(lang)),
});

/**
 * Brain templates
 */
export const useTemplates = (templateId?: string) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data: metadata, status: metadataStatus } = useQuery<Metadata, Error>({
    queryKey: ['templates'],
    queryFn: API.getMetadata,
  });

  const templateBrain = async (language: string) =>
    await API.getTemplate(language);

  const {
    mutateAsync: createBrainFromTemplateAsync,
    status: createBrainFromTemplateStatus,
  } = useMutation<Brain, Error, TemplateAsync>({
    mutationFn: API.createBrain,
    onSuccess: (resp) => {
      onBrainCreated(queryClient, resp);
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const template = metadata?.templates?.find((t) => t.id === templateId);

  return {
    templateBrain,
    metadata,
    template,
    metadataStatus,
    createBrainFromTemplateAsync,
    createBrainFromTemplateStatus,
  };
};
