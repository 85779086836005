import { Icon } from '@/models/icon';

const Collections = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.667 14 8 10.667 3.333 14V3.333A1.333 1.333 0 0 1 4.667 2h6.666a1.333 1.333 0 0 1 1.334 1.333V14Z"
      />
    </svg>
  );
};

export default Collections;
