import { LinkComponent } from './LinkComponent';

export const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'link':
      return <LinkComponent {...props} />;
    case 'ol_list':
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'list_item':
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'ul_list':
    case 'bulleted-list':
      return (
        <ul
          style={{ listStyleType: 'disc', paddingLeft: '40px' }}
          {...attributes}
        >
          {children}
        </ul>
      );
    default:
      return (
        <span style={{ fontSize: '14px', display: 'block' }} {...attributes}>
          {children}
        </span>
      );
  }
};
