import { createElement, forwardRef } from 'react';

import Facebook from '@/components/atoms/Icons/Facebook';
import Front from '@/components/atoms/Icons/Front';
import IconInstagram from '@/components/atoms/Icons/Instagram';
import IconIntercom from '@/components/atoms/Icons/Intercom';
import IconSlack from '@/components/atoms/Icons/Slack';
import Sunco from '@/components/atoms/Icons/Sunco';
import IconTelegram from '@/components/atoms/Icons/Telegram';
import Viber from '@/components/atoms/Icons/Viber';
import IconWebWidget from '@/components/atoms/Icons/WebWidget';
import Whatsapp from '@/components/atoms/Icons/Whatsapp';
import Zendesk from '@/components/atoms/Icons/Zendesk';
import { Icon } from '@/models/icon';

import IntegrationFacebook from './Facebook/Facebook';
import IntegrationFront from './Front/Front';
import IntegrationIntercom from './Intercom/Intercom';
import IntegrationSunco from './Sunco/Sunco';
import IntegrationViber from './Viber/Viber';
import IntegrationWebWidget from './Web/Web';
import IntegrationWhatsapp from './Whatsapp/Whatsapp';
import IntegrationZendesk from './Zendesk/Zendesk';

interface IntegrationType {
  Icon: React.FC<Icon>;
  ConfigurationComponent?: React.FunctionComponent;
}

const iconComponents = {
  web: IconWebWidget,
  facebook: Facebook,
  front: Front,
  intercom: IconIntercom,
  sunco: Sunco,
  viber: Viber,
  whatsapp: Whatsapp,
  zendesk: Zendesk,
  instagram: IconInstagram,
  slack: IconSlack,
  telegram: IconTelegram,
};

export const IntegrationIcon = forwardRef<
  SVGSVGElement,
  Icon & { type: string }
>(({ type, ...props }, ref) => {
  const IconComponent = iconComponents[type];

  return IconComponent ? createElement(IconComponent, { ref, ...props }) : null;
});

IntegrationIcon.displayName = 'IntegrationIcon';

export const integrationByType = {
  zendesk: {
    Icon: iconComponents.zendesk,
    ConfigurationComponent: IntegrationZendesk,
  },
  sunco: {
    Icon: iconComponents.sunco,
    ConfigurationComponent: IntegrationSunco,
  },
  web: {
    Icon: iconComponents.web,
    ConfigurationComponent: IntegrationWebWidget,
  },
  facebook: {
    Icon: iconComponents.facebook,
    ConfigurationComponent: IntegrationFacebook,
  },
  instagram: {
    Icon: iconComponents.instagram,
    ConfigurationComponent: IntegrationFacebook,
  },
  whatsapp: {
    Icon: iconComponents.whatsapp,
    ConfigurationComponent: IntegrationWhatsapp,
  },
  viber: {
    Icon: iconComponents.viber,
    ConfigurationComponent: IntegrationViber,
  },
  intercom: {
    Icon: iconComponents.intercom,
    ConfigurationComponent: IntegrationIntercom,
  },
  front: {
    Icon: iconComponents.front,
    ConfigurationComponent: IntegrationFront,
  },
  slack: {
    Icon: iconComponents.slack,
  },
  telegram: {
    Icon: iconComponents.telegram,
  },
};

export const getIntegrationByType = (type: string): IntegrationType | null => {
  if (type in integrationByType) {
    return integrationByType[type];
  }
  return null;
};
