import { useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Node as SlateNode } from 'slate';

import Autocomplete from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import {
  markdownToSlatePromise,
  richTextToMarkdown,
  NestedObject,
  findRichTextEditorErrorMessage,
} from '@/components/organisms/RichTextEditor/utils';
import RichTextEditorTabs from '@/components/organisms/RichTextEditorTabs/RichTextEditorTabs';
import { useBrainVariables } from '@/hooks/useBrainVariables';
import { OptionBase } from '@/models/common';
import { Requisite, RequisiteOptionType } from '@/models/node';
import { capitalizeFirstLetter } from '@/util/util';
import { LENGTH_XXL, checkForSchema } from '@/util/validator';

import { INPUT_TYPES, isInputType } from './helper';

type FormType = Pick<Requisite, 'required' | 'validate' | 'save_as'> & {
  check_for: OptionBase;
  tabs: {
    textValue: SlateNode[];
    tabName: string;
    maxCharacters: number;
  }[];
};

interface AutocompleteOption extends OptionBase {
  type: string;
}

interface Props {
  requisite: Requisite;
  onRequisiteUpdate: (req: Requisite) => void;
  options: OptionBase[];
  updateErrors: (key: string, error: string | null) => void;
}

const QuestionCheckAndAsk = ({
  onRequisiteUpdate,
  requisite,
  updateErrors,
}: Props) => {
  const { entityVariables } = useBrainVariables(false);
  const variables = [...INPUT_TYPES, ...entityVariables];
  const { t } = useTranslation();
  const requisiteOptions: RequisiteOptionType[] = get(
    requisite,
    'actions[0].options',
    []
  );

  const textsForEachTab = useMemo(() => {
    const texts = [
      requisite?.actions?.[0]?.texts?.[0] || '',
      requisite?.reprompt?.[0]?.texts?.[0] || '',
    ];
    return texts;
  }, [requisite?.actions, requisite?.reprompt]);

  const checkIfExists = requisite?.check_for ?? requisite?.validate ?? null;
  const defaultCheckFor = checkIfExists
    ? {
        label: isInputType(checkIfExists)
          ? checkIfExists.charAt(0).toUpperCase() + checkIfExists.slice(1)
          : checkIfExists,
        value: checkIfExists,
      }
    : null;

  const {
    formState: { errors },
    control,
    trigger,
    setValue,
    watch,
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: {
      check_for: defaultCheckFor,
      tabs: [
        {
          textValue: [],
          tabName: t('actions.types.question'),
          maxCharacters: LENGTH_XXL,
        },
        {
          textValue: [],
          tabName: t('actions.types.reprompt'),
          maxCharacters: LENGTH_XXL,
        },
      ],
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(checkForSchema) as any,
  });

  useEffect(() => {
    const convertTexts = async () => {
      try {
        const convertedTexts = await Promise.all(
          textsForEachTab.map(async (text, index) => {
            const slateNodes = await markdownToSlatePromise(text);
            return {
              textValue: slateNodes,
              tabName:
                index === 0
                  ? t('actions.types.question')
                  : t('actions.types.reprompt'),
              maxCharacters: LENGTH_XXL,
            };
          })
        );
        setValue('tabs', convertedTexts);
      } catch (error) {
        console.error('Error converting texts to slate nodes:', error);
      }
    };

    convertTexts();
    // this useEffect should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const hasTabError = !!(
    findRichTextEditorErrorMessage(errors?.tabs?.[0] as NestedObject) ||
    findRichTextEditorErrorMessage(errors?.tabs?.[1] as NestedObject)
  );

  const tabsErrors = useMemo(() => {
    if (!hasTabError) {
      return undefined;
    }

    if (findRichTextEditorErrorMessage(errors?.tabs?.[1] as NestedObject)) {
      return {
        tabs: [
          {
            textValue: {
              message: undefined,
            },
          },
          {
            textValue: {
              message: findRichTextEditorErrorMessage(
                errors?.tabs?.[1] as NestedObject
              ),
            },
          },
        ],
      };
    }

    return {
      tabs: [
        {
          textValue: {
            message: findRichTextEditorErrorMessage(
              errors?.tabs?.[0] as NestedObject
            ),
          },
        },
      ],
    };
  }, [errors?.tabs, hasTabError]);

  const checkForErrorMessage = capitalizeFirstLetter(
    errors?.check_for?.message
  );

  const { fields: tabFields } = useFieldArray({
    control,
    name: 'tabs',
  });

  const handleCheckForChange = useCallback(
    (_, option: AutocompleteOption) => {
      const isSelectedOptionEntity = option?.type === 'entity';

      const newRequisite = cloneDeep(requisite);
      // When the user presses the clear button, the option is null
      set(newRequisite, 'check_for', option?.value ?? '');

      if (isSelectedOptionEntity) {
        set(newRequisite, 'validate', undefined);
      } else {
        set(newRequisite, 'validate', option?.value ?? '');
      }

      onRequisiteUpdate(newRequisite);
    },
    [onRequisiteUpdate, requisite]
  );

  const handleTabOnChange = () => {
    // Clone and Initialization
    const newRequisite = cloneDeep(requisite);
    const [questionTab, repromptTab] = watch('tabs');
    const questionText = richTextToMarkdown(questionTab.textValue);
    const repromptText = richTextToMarkdown(repromptTab.textValue);

    // Always set action type to 'text'
    set(newRequisite, 'actions[0].type', 'text');

    // Update Texts
    const isFirstEqual = isEqual(questionText, textsForEachTab[0]);
    const isSecondEqual = isEqual(repromptText, textsForEachTab[1]);

    if (!isFirstEqual) {
      set(newRequisite, 'actions[0].texts[0]', questionText);
      if (questionText === '') set(newRequisite, 'actions', undefined);
    }

    if (!isSecondEqual) {
      set(newRequisite, 'reprompt[0].texts[0]', repromptText);

      // Update Options
      if (requisiteOptions.length > 0) {
        set(newRequisite, 'reprompt[0].options', requisiteOptions);
      }
    }

    // Handle Reprompt
    if (!isSecondEqual) {
      set(newRequisite, 'reprompt[0].type', 'text');
      if (repromptText === '') set(newRequisite, 'reprompt', undefined);
    }

    // Set options or undefined based on conditions
    if (requisiteOptions.length > 0) {
      set(newRequisite, 'actions[0].options', requisiteOptions);
    }

    // Update if there are changes
    if (!isEqual(JSON.stringify(newRequisite), JSON.stringify(requisite))) {
      onRequisiteUpdate(newRequisite);
    }
  };

  // Update errors
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors('check_for', checkForErrorMessage);
  }, [checkForErrorMessage, updateErrors]);

  useEffect(() => {
    updateErrors(
      'Question',
      findRichTextEditorErrorMessage(errors?.tabs?.[0] as NestedObject)
    );
  }, [errors?.tabs, t, updateErrors]);

  useEffect(() => {
    updateErrors(
      'Reprompt',
      findRichTextEditorErrorMessage(errors?.tabs?.[1] as NestedObject)
    );
  }, [errors?.tabs, t, updateErrors]);
  return (
    <>
      <Autocomplete
        name="check_for"
        control={control}
        options={variables}
        label={t('dialog.question.check_if_exists')}
        placeholder={t('dialog.question.select_an_entity')}
        size="xs"
        groupByProp="type"
        onChange={handleCheckForChange}
        hasError={!!errors?.check_for}
        errorMessage={checkForErrorMessage}
      />

      <Box mt="var(--space-24)">
        <RichTextEditorTabs<FormType>
          tabConfig={tabFields}
          control={control}
          label={t('dialog.question.if_not_exists')}
          maxTabs={2}
          canAddDeleteTabs={false}
          controllerName="tabs"
          inputName="textValue"
          errors={tabsErrors}
          handleTabOnChange={handleTabOnChange}
        />
      </Box>
    </>
  );
};

export default QuestionCheckAndAsk;
