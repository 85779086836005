import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Routes, Route, Outlet, useParams } from 'react-router';
import { useTitle } from 'react-use';

import BrainHeader from '@/components/organisms/Headers/BrainHeader/BrainHeader';
import BrainCollection from '@/components/pages/BrainCollection/BrainCollection';
import BrainSettings from '@/components/pages/BrainSettings/BrainSettings';
import Dialog from '@/components/pages/Dialog/Dialog';
import Dialogs from '@/components/pages/Dialogs/Dialogs';
import Entities from '@/components/pages/Entities/Entities';
import Entity from '@/components/pages/Entity/Entity';
import Intent from '@/components/pages/Intent/Intent';
import Intents from '@/components/pages/Intents/Intents';
import Logs from '@/components/pages/Logs/Logs';
import { Versions } from '@/components/pages/Versions/Versions';
import Webhooks from '@/components/pages/Webhooks/Webhooks';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { useRtmBrain } from '@/hooks/rtm/useRtmBrain';
import { resetFilters } from '@/modules/analytics/redux/actions';
import { resetTryIt } from '@/modules/TryIt/redux/actions';
import { resetBrain, setBrainId } from '@/redux/session/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

import NotFound from '../NotFound/NotFound';

const BrainRouter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { brainId } = useParams();

  const slug = useSelector(selectAccountSlug);
  const title = useMemo(() => t('pages.brain', { 0: slug }), [t, slug]);
  useTitle(title);
  useRtmBrain(brainId);

  useEffect(() => {
    if (brainId) {
      dispatch(setBrainId({ brainId }));
      dispatch(resetTryIt());
    }

    return () => {
      dispatch(resetBrain());
      dispatch(resetFilters());
    };
  }, [dispatch, brainId]);

  return (
    <>
      <PermissionsWrapper module="brains" resource_id={brainId}>
        <BrainHeader />
        <Routes>
          <Route path="/" element={<Navigate to="dialogs" replace />} />
          <Route path="/intents/:intentName" element={<Intent />} />
          <Route path="/intents" element={<Intents />} />
          <Route path="/dialogs/:dialogId" element={<Dialog />} />
          <Route path="/dialogs" element={<Dialogs />} />
          <Route path="/collections" element={<BrainCollection />} />
          <Route path="/webhooks" element={<Webhooks />} />
          <Route path="/entities/:entityName" element={<Entity />} />
          <Route path="/entities" element={<Entities />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/settings" element={<BrainSettings />} />
          <Route path="/versions" element={<Versions />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Outlet />
      </PermissionsWrapper>
    </>
  );
};
BrainRouter.displayName = 'BrainRouter';

export default BrainRouter;
