export const userContextVariables = [
  {
    label: 'user.user_id',
    value: 'user.user_id',
    type: 'user',
  },
  {
    label: 'user.email',
    value: 'user.email',
    type: 'user',
  },
  {
    label: 'user.external_id',
    value: 'user.external_id',
    type: 'user',
  },
  {
    label: 'user.contact_id',
    value: 'user.contact_id',
    type: 'user',
  },
  {
    label: 'user.address',
    value: 'user.address',
    type: 'user',
  },
  {
    label: 'user.timezone',
    value: 'user.timezone',
    type: 'user',
  },
  {
    label: 'user.phone',
    value: 'user.phone',
    type: 'user',
  },
  {
    label: 'user.browser',
    value: 'user.browser',
    type: 'user',
  },
  {
    label: 'user.platform',
    value: 'user.platform',
    type: 'user',
  },
  {
    label: 'user.device',
    value: 'user.device',
    type: 'user',
  },
  {
    label: 'user.ip',
    value: 'user.ip',
    type: 'user',
  },
  {
    label: 'user.display_name',
    value: 'user.display_name',
    type: 'user',
  },
  {
    label: 'user.avatar',
    value: 'user.avatar',
    type: 'user',
  },
  {
    label: 'user.language',
    value: 'user.language',
    type: 'user',
  },
  {
    label: 'user.gender',
    value: 'user.gender',
    type: 'user',
  },
  {
    label: 'user.past_visits',
    value: 'user.past_visits',
    type: 'user',
  },
  {
    label: 'user.past_chats',
    value: 'user.past_chats',
    type: 'user',
  },
  {
    label: 'user.last_seen_at',
    value: 'user.last_seen_at',
    type: 'user',
  },
  {
    label: 'user.last_replied_at',
    value: 'user.last_replied_at',
    type: 'user',
  },
  {
    label: 'user.last_contacted_at',
    value: 'user.last_contacted_at',
    type: 'user',
  },
  {
    label: 'user.facebook_psid',
    value: 'user.facebook_psid',
    type: 'user',
  },
  {
    label: 'user.twitter_handler',
    value: 'user.twitter_handler',
    type: 'user',
  },
  {
    label: 'user.verified',
    value: 'user.verified',
    type: 'user',
  },
  {
    label: 'user.viber_psid',
    value: 'user.viber_psid',
    type: 'user',
  },
  {
    label: 'user.whatsapp_psid',
    value: 'user.whatsapp_psid',
    type: 'user',
  },
];
