import { useTranslation } from 'react-i18next';

import CodeBlock from '@/components/atoms/CodeBlock/CodeBlock';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import Link from '@/components/atoms/Link/Link';
import { Integration } from '@/models/integration';
import { getDocsUrl } from '@/util/constants';

import styles from './styles.module.scss';

interface ApiClient {
  integration?: Integration;
  callbackUrl?: string;
}

const ApiClientFieldset = ({ callbackUrl = '' }: ApiClient) => {
  const { t } = useTranslation();
  return (
    <PlainFieldset
      title={t('integrations.zendesk.api.title')}
      subtitle={t('integrations.zendesk.api.subtitle')}
      isLoading={false}
    >
      <div className={styles.plain_fieldset}>
        <div className={styles.field__label}>
          {t('integrations.zendesk.api.client_name')}
        </div>
        <div className={styles.field__data}>
          {t('integrations.zendesk.api.virtual_assistant')}
        </div>
        <div className={styles.field__label}>
          {t('integrations.zendesk.api.company')}
        </div>
        <div className={styles.field__data}>{t('common.moveo_ai')}</div>
        <div className={styles.field__label}>
          {t('integrations.callback_url')}{' '}
          <HelpTooltip tooltip={t('integrations.zendesk.api.callback_help')} />
        </div>
        <div className={styles.container}>
          <CodeBlock>{callbackUrl}</CodeBlock>
          {callbackUrl && <CopyButton data={callbackUrl} />}
        </div>
        <div className={styles.field__data}>
          {t('integrations.zendesk.api.steps')}{' '}
          <Link external icon href={getDocsUrl('/docs/integrations/zendesk')}>
            {t('integrations.zendesk.api.here')}
          </Link>
        </div>
      </div>
    </PlainFieldset>
  );
};

export default ApiClientFieldset;
