import { useCallback } from 'react';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';

import styles from './Checkboxes.module.scss';

interface CategoryCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (change: { category: string; checked: boolean }) => void;
  badge?: number;
  category: string;
}
export const CategoryCheckbox = ({
  label,
  category,
  badge,
  onChange,
  checked,
}: CategoryCheckboxProps) => {
  const handleChange = useCallback(
    (e) => {
      onChange({ category, checked: e.target.checked });
    },
    [category, onChange]
  );

  return (
    <div className={styles.templateCheckboxes}>
      <Checkbox
        onChange={handleChange}
        label={<span className={styles.label}>{label}</span>}
        checked={Boolean(checked)}
      />
      <div className={styles.badge}>{badge}</div>
    </div>
  );
};
