export const PlusIcon = ({ size = 16, color = 'var(--icon-default-gray)' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
      className="icon_fill"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.75 3a.75.75 0 0 0-1.5 0v4.25H3a.75.75 0 0 0 0 1.5h4.25V13a.75.75 0 0 0 1.5 0V8.75H13a.75.75 0 0 0 0-1.5H8.75V3Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
