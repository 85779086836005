import { ElementType } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import { StyledBadge } from '@/modules/humanChat/components/LeftSidebar/NavItem/components';
import { IconComponent } from '@/modules/humanChat/utils';

import styles from './ChatNavigationItem.module.scss';

interface Props {
  icon: ElementType | JSX.Element;
  text?: string;
  onClick: (event) => void;
  selected: boolean;
  hasNotification?: boolean;
  title?: string;
  sum?: string;
  menu?: boolean;
}

const ChatNavigationItem = ({
  icon,
  text,
  sum,
  hasNotification,
  onClick,
  selected,
  title,
  menu = false,
}: Props) => {
  return (
    <Tooltip
      title={title && `${title} > ${text}`}
      arrow
      PopperProps={{
        sx: {
          '& .MuiTooltip-arrow': {
            color: 'var(--iframe-nav-tooltip)',
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'var(--iframe-nav-tooltip)',
            color: 'var(--iframe-nav-tooltip-text)',
          },
        },
      }}
    >
      <li>
        <button
          onClick={onClick}
          className={cn(styles.item, {
            [styles.isActive]: selected,
            [styles.menu]: menu,
          })}
        >
          {icon && (
            <StyledBadge
              overlap="circular"
              variant="dot"
              invisible={!hasNotification}
            >
              <IconComponent icon={icon} />
            </StyledBadge>
          )}
          {sum && <Typography className={styles.text}>{sum}</Typography>}
        </button>
      </li>
    </Tooltip>
  );
};
export default ChatNavigationItem;
