import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';

import { PageName } from '@/models/segment';
import { pageView } from '@/segment/segment';

import AvatarFieldset from './fieldsets/Avatar/Avatar';
import NameFieldset from './fieldsets/Name/Name';
import SystemLanguageFieldset from './fieldsets/SystemLanguage/SystemLanguage';
import SystemTimezoneFieldset from './fieldsets/SystemTimezone/SystemTimezone';

const Profile = () => {
  const { t } = useTranslation();
  useTitle(t('pages.profile'));

  useEffect(() => {
    pageView(PageName.PERSONAL_PROFILE);
  }, []);

  return (
    <>
      <NameFieldset />
      <AvatarFieldset />
      <SystemLanguageFieldset />
      <SystemTimezoneFieldset />
    </>
  );
};

export default Profile;
