import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { pagerDutyNotify } from '@/api/client';
import Oops from '@/components/pages/Oops/Oops';

interface Props {
  children: React.ReactNode;
}

const MyErrorBoundary = ({ children }: Props) => {
  const location = useLocation();

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    pagerDutyNotify({
      summary: error.message,
      component_stack: info.componentStack,
      url: location.pathname,
    });
  };

  return (
    <ErrorBoundary FallbackComponent={Oops} onError={myErrorHandler}>
      {children}
    </ErrorBoundary>
  );
};

export default MyErrorBoundary;
