/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { Bundle } from '@/modules/bundles/models';

import { getNestedKeys } from '../utils';

const defaultState: Partial<Bundle> = {
  name: '',
  bundle_id: '',
  context: {},
  dirty: false,
  contextKeys: [],
  keysWithNested: [],
  keyToFocusIndex: null,
};
// contextKeys is added for keeping ordering of context the same throughout updating, adding, removing k,v pairs

const slice = createSlice({
  name: 'bundle',
  initialState: defaultState,
  reducers: {
    setDraftBundle: (state, action) =>
      (state = {
        ...action.payload,
        contextKeys: Object.keys(action.payload.context),
        keysWithNested: Object.keys(action.payload.context).reduce(
          (acc, key) => {
            const nestedKeys = getNestedKeys(key);
            return [...acc, ...nestedKeys];
          },
          [] as string[]
        ),
        keyToFocusIndex: Object.keys(action.payload.context).length,
      }),

    addContext: (state, action) => {
      const { itemKey, itemValue } = action.payload;

      if (state.contextKeys.includes(itemKey)) {
        state.context[itemKey] = itemValue;
        if (itemKey.includes('.')) {
          state.keysWithNested = [
            ...state.keysWithNested,
            ...getNestedKeys(itemKey),
          ];
        }
        state.dirty = true;
        return;
      }

      state.contextKeys.splice(state.keyToFocusIndex, 0, itemKey);
      state.context = { ...state.context, [`${itemKey}`]: itemValue };

      if (itemKey.includes('.')) {
        state.keysWithNested = [
          ...state.keysWithNested,
          ...getNestedKeys(itemKey),
        ];
      }

      state.keyToFocusIndex = state.contextKeys.length;
      state.dirty = true;
    },

    updateContext: (state, action) => {
      const { itemKey, itemValue, oldKey } = action.payload;
      const index = state.contextKeys.indexOf(oldKey);

      if (itemKey !== oldKey) {
        delete state.context[oldKey];
        state.contextKeys.splice(index, 1);
        state.contextKeys.splice(index, 0, itemKey);

        if (itemKey.includes('.')) {
          const newKeys = getNestedKeys(itemKey);
          const indexes = newKeys.map((key) =>
            state.keysWithNested.findIndex((x) => x === key)
          );
          state.keysWithNested = state.keysWithNested.filter(
            (x, index) => !indexes.includes(index)
          );
          state.keysWithNested = [...state.keysWithNested, ...newKeys];
        }
      }

      state.keyToFocusIndex = state.contextKeys.length;
      state.context = { ...state.context, [`${itemKey}`]: itemValue };

      state.dirty = true;
    },

    deleteContext: (state, action) => {
      const { itemKey } = action.payload;
      delete state.context[itemKey];
      state.keyToFocusIndex = state.contextKeys.length;
      state.contextKeys = state.contextKeys.filter((x) => x !== itemKey);

      if (itemKey.includes('.')) {
        const keysToRemove = getNestedKeys(itemKey);
        const indexes = keysToRemove.map((key) =>
          state.keysWithNested.findIndex((x) => x === key)
        );
        state.keysWithNested = state.keysWithNested.filter(
          (x, index) => !indexes.includes(index)
        );
      }

      state.dirty = true;
    },

    // utils
    revertDirty: (state, action) => {
      const { dirtyState } = action.payload;
      state.dirty = dirtyState;
    },
  },
});

export const {
  setDraftBundle,
  revertDirty,
  addContext,
  updateContext,
  deleteContext,
} = slice.actions;

export default slice.reducer;
