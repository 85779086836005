import { emojiFlagToCountryCode } from '../../helper';

export const FlagEmoji = ({ flag }: { flag: string }) => {
  const countryCode = emojiFlagToCountryCode(flag);

  return (
    <img
      src={`https://flagcdn.com/144x108/${countryCode}.png`}
      width="144"
      height="108"
      alt={countryCode}
      loading="lazy"
    />
  );
};
