import { forwardRef } from 'react';

import MaterialLink, {
  LinkProps as MaterialLinkProps,
} from '@mui/material/Link';

import LinkExternal from '../Icons/LinkExternal';

import './Link.scss';

export interface LinkProps extends MaterialLinkProps {
  icon?: boolean;
  external?: boolean;
  children?: React.ReactNode;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ external = false, icon = false, children, ...rest }, ref) => {
    const props: MaterialLinkProps = { ...rest };
    if (external) {
      props.target = '_blank';
      props.rel = 'noopener noreferrer';
    }

    return (
      <MaterialLink className="link" {...props} ref={ref}>
        {children}
        {icon && (
          <i className="link__icon">
            <LinkExternal size={16} align="bottom" />
          </i>
        )}
      </MaterialLink>
    );
  }
);

Link.displayName = 'Link';

export default Link;
