import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Select from '@/components/atoms/Select/Select';
import { getFieldOptions } from '@/components/pages/ActivityLogs/ActivityLogs';
import useBrains from '@/hooks/useBrains';
import useDesks from '@/hooks/useDesks';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { AuditLogsType } from '@/models/auditLogs';
import useFilters from '@/modules/analytics/hooks/useFilters';
import { AnalyticsType } from '@/modules/analytics/models';
import { urlSafeBase64Encode } from '@/util/util';

import ContentFilters from '../ContentFilters/ContentFilters';
import DateFilter from '../DateFilter/DateFilter';
import AgentFilters from '../ExternalFilters/AgentFilters';
import BrainFilters from '../ExternalFilters/BrainFilter';
import DeskFilters from '../ExternalFilters/DeskFilters';

import styles from './AccountFilters.module.scss';

type Props = {
  auditLogsMode?: boolean;
};

type SelectedFilterCategory = 'default' | 'brain' | 'desk' | 'ai_agent';

const Filters = ({ auditLogsMode = false }: Props) => {
  const navigate = useNavigate();
  const {
    dateOpen,
    setDateOpen,
    filterOpen,
    setFilterOpen,
    reset: resetFilterCategory,
    selectedFilterCategory: selectedFilterCategoryFromURL,
  } = useFilters(auditLogsMode ? AuditLogsType.ACCOUNT : AnalyticsType.ACCOUNT);
  const { pathname } = useLocation();
  const showAgentFilter =
    pathname?.includes('/human-agents-effectiveness') ||
    pathname?.includes('/agents-performance');
  const isCustomerSatisfaction = pathname?.includes('/customer-satisfaction');
  const showBrainFilter =
    !(showAgentFilter || isCustomerSatisfaction || auditLogsMode) ||
    (auditLogsMode && selectedFilterCategoryFromURL === 'brain');
  const showDeskFilter =
    (selectedFilterCategoryFromURL === 'desk' && auditLogsMode) ||
    !auditLogsMode;
  const [urlParams] = useSearchParams();
  const { brains } = useBrains();
  const { desks } = useDesks();
  const brainsUrl = [
    {
      type: 'brainIds',
      brainIds: brains?.map((brain) => ({
        value: brain.brain_id,
        label: brain.name,
      })),
    },
  ];
  const desksUrl = [
    {
      type: 'deskIds',
      deskIds: desks?.map((desk) => ({
        value: desk.desk_id,
        label: desk.name,
      })),
    },
  ];
  const { ai_agents } = useFeatureFlag();

  const handleSelectedFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFilterCategory = e.target.value as SelectedFilterCategory;
    resetFilterCategory();

    let filterUrl = [];
    if (selectedFilterCategory === 'desk') {
      filterUrl = desksUrl;
    } else if (selectedFilterCategory === 'brain') {
      filterUrl = brainsUrl;
    } else {
      filterUrl = [];
    }

    navigate(
      {
        ...window.location,
        search: `${urlParams.get('date') ? `date=${urlParams.get('date')}` : ''}${
          !isEmpty(filterUrl)
            ? `&filters=${urlSafeBase64Encode(JSON.stringify(filterUrl))}`
            : ''
        }`,
      },
      {
        replace: true,
      }
    );
  };

  return (
    <div className={styles.filters}>
      <DateFilter
        dateOpen={dateOpen}
        setDateOpen={setDateOpen}
        disabled={false}
      />
      {auditLogsMode && (
        <div
          className={cn(styles.selectFilter, {
            [styles['selectFilter--gray']]:
              selectedFilterCategoryFromURL === 'default',
          })}
        >
          <Select
            name="field"
            options={getFieldOptions(ai_agents)}
            size="large"
            onChange={handleSelectedFilter}
          />
        </div>
      )}
      {showDeskFilter && (
        <DeskFilters
          dateOpen={dateOpen}
          setDateOpen={setDateOpen}
          setFilterOpen={setFilterOpen}
          filterOpen={filterOpen}
        />
      )}
      {showBrainFilter && (
        <BrainFilters
          dateOpen={dateOpen}
          setDateOpen={setDateOpen}
          setFilterOpen={setFilterOpen}
          filterOpen={filterOpen}
        />
      )}
      {showAgentFilter && (
        <AgentFilters
          dateOpen={dateOpen}
          setDateOpen={setDateOpen}
          setFilterOpen={setFilterOpen}
          filterOpen={filterOpen}
        />
      )}
      {!auditLogsMode && (
        <div className={styles.contentFiltersContainer}>
          <ContentFilters
            type={AnalyticsType.ACCOUNT}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
          />
        </div>
      )}
    </div>
  );
};

export default Filters;
