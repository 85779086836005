import { ReactNode } from 'react';

import AvatarGroup from '@mui/material/AvatarGroup';

interface Props {
  children: ReactNode;
  max?: number;
  size?: number;
}
const AvatarGroups = ({ children, max = 20, size = 14 }: Props) => {
  return (
    <AvatarGroup
      max={max}
      sx={{
        '&.MuiAvatarGroup-root': {
          alignItems: 'center',
        },
        '& .MuiAvatar-root': {
          border: '1px solid var(--border-default-gray)',
        },
        '& .MuiAvatarGroup-avatar': {
          height: size,
          width: size,
          fontSize: '10px',
          marginLeft: '-4px',
        },
        '& > *': {
          marginLeft: '-4px',
        },
      }}
    >
      {children}
    </AvatarGroup>
  );
};

export default AvatarGroups;
