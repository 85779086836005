import { Icon } from '@/models/icon';

const Success = ({
  size = 16,
  color = 'var(--icon-default-success)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="7" fill={color} />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.4"
        d="m5.333 9.095 1.31 1.048a.5.5 0 0 0 .673-.045l3.78-3.936"
      />
    </svg>
  );
};

export default Success;
