import MuiChip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/system';

type CustomChipProps = ChipProps & {
  customVariant?: 'neutral' | 'highlighted' | 'subdued';
};

enum CustomChipVariants {
  neutral = 'var(--surface-secondary-gray)',
  highlighted = 'var(--surface-tertiary-blue-light)',
  subdued = 'var(--surface-secondary-gray-light)',
}

const CustomChip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'customVariant',
})(({ customVariant = 'highlighted' }: CustomChipProps) => {
  return {
    backgroundColor: CustomChipVariants[customVariant],

    '&.Mui-focusVisible': {
      backgroundColor: CustomChipVariants[customVariant],
    },
  };
});

const Chip = ({ ...props }: CustomChipProps) => {
  return <CustomChip {...props} />;
};
export default Chip;
