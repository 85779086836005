/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { Phrases } from '@/models/autoAI';

export const defaultState: Phrases = {};

const slice = createSlice({
  name: 'autoAI',
  initialState: defaultState,
  reducers: {
    setPhrases: (state: Phrases, action) => {
      const { autoExpressions } = action.payload;
      state.phrases = autoExpressions;
    },

    updatePhrases: (state: Phrases, action) => {
      const { text } = action.payload;
      state.phrases = state.phrases.filter((phrase) => phrase.text !== text);
    },
    resetPhrases: () => defaultState,
  },
});

export const { setPhrases, updatePhrases, resetPhrases } = slice.actions;

export default slice.reducer;
