/**
 * Moves an element within an array from one index to another.
 *
 * @template T - The type of elements contained within the array.
 * @param {T[]} arr - The array containing the element to be moved.
 * @param {number} fromIndex - The current index of the element to be moved.
 * @param {number} toIndex - The index to which the element should be moved.
 * @returns {T[]} - A new array with the element moved to the desired position. If the move is out of bounds, it returns the original array.
 */
export const move = <T>(arr: T[], fromIndex: number, toIndex: number): T[] => {
  if (
    fromIndex < 0 ||
    fromIndex >= arr.length ||
    toIndex < 0 ||
    toIndex >= arr.length
  ) {
    return arr; // out of bounds
  }

  const element = arr[fromIndex];

  // This will create a new array with the moved element. This avoids mutating the original array.
  const newArr = [...arr];
  newArr.splice(fromIndex, 1); // Remove the element from its original position
  newArr.splice(toIndex, 0, element); // Insert the element at the new position

  return newArr;
};
