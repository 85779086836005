import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';

export const selectFilters = createSelector(
  (state: RootState) => state.auditLogs,
  (auditLogs) => ({
    filters: auditLogs.filters,
    startDate: auditLogs.startDate,
    endDate: auditLogs.endDate,
    filtersLoaded: auditLogs.filtersLoaded,
  })
);

export const selectAuditLogFilters = (state: RootState) =>
  state.auditLogs.filters;
