import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Instagram = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none " d="M0 0h24v24H0z" />
        <path
          d="M22 7a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5V7Z"
          fill="url(#insta-icon)"
        />
        <path
          d="M14.958 5.005c2.091.02 4.016 1.815 4.037 4.037.007 1.972.007 3.944 0 5.916-.02 2.083-1.815 4.016-4.037 4.037-1.972.007-3.944.007-5.916 0-2.092-.02-4.016-1.815-4.037-4.037a833.313 833.313 0 0 1 0-5.916c.019-2.093 1.81-4.016 4.037-4.037 1.972-.007 3.944-.007 5.916 0Zm-5.889.945c-1.623.005-3.103 1.412-3.119 3.098a968.258 968.258 0 0 0 0 5.904c.015 1.605 1.388 3.082 3.098 3.098 1.968.006 3.936.006 5.904 0 1.609-.015 3.082-1.388 3.098-3.098.006-1.968.006-3.936 0-5.904-.015-1.616-1.415-3.082-3.098-3.098-1.961-.006-3.922 0-5.883 0Z"
          fill="#fff"
        />
        <path
          d="M12.024 8.5c1.618.015 3.126 1.263 3.422 2.862a3.575 3.575 0 0 1-1.027 3.178c-.935.89-2.382 1.208-3.622.754-1.386-.507-2.361-1.968-2.296-3.448C8.58 10.078 10.142 8.506 12 8.5h.024Zm-.04.947c-1.155.011-2.244.887-2.484 2.025-.243 1.151.419 2.428 1.506 2.887 1.187.502 2.72-.061 3.293-1.233.593-1.211.034-2.856-1.218-3.441a2.592 2.592 0 0 0-1.097-.238Z"
          fill="#fff"
        />
        <path
          d="M16.5 8.227a.727.727 0 0 0-.727-.727h-.046a.727.727 0 0 0-.727.727v.023c0 .199.079.39.22.53a.75.75 0 0 0 1.28-.53v-.023Z"
          fill="#fff"
        />
        <defs>
          <radialGradient
            cx="0"
            cy="0"
            gradientTransform="translate(2 21.5) scale(27.933)"
            gradientUnits="userSpaceOnUse"
            id="insta-icon"
            r="1"
          >
            <stop offset="0" stopColor="#ff8100" stopOpacity="1" />
            <stop offset=".19" stopColor="#ff7209" stopOpacity="1" />
            <stop offset=".32" stopColor="#f55e16" stopOpacity="1" />
            <stop offset=".48" stopColor="#d92938" stopOpacity="1" />
            <stop offset="1" stopColor="#9100ff" stopOpacity="1" />
          </radialGradient>
        </defs>
      </svg>
    );
  }
);

Instagram.displayName = 'Instagram';

export default Instagram;
