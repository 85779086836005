import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Stripe from 'stripe';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { countryListAllIsoData } from '@/util/countries';
import { isRequired } from '@/util/validator';

import styles from './BillingAddress.module.scss';

const countryOptions: React.ComponentPropsWithoutRef<typeof Select>['options'] =
  countryListAllIsoData.map((c) => ({
    label: c.name,
    value: c.code,
  }));

type Form = Stripe.Address;

const BillingAddressFieldset = () => {
  const { t } = useTranslation();
  const { customer, updateCustomer, updateCustomerStatus } = useBilling();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: { country: 'GR' },
  });

  const isLoading = customer === undefined;

  useEffect(() => {
    if (customer?.address) {
      reset(customer.address);
    }
  }, [customer, reset]);

  const onSubmit = useCallback(
    (form: Form) => {
      const { line2, ...rest } = form;
      return updateCustomer({
        address: { ...rest, ...(line2 ? { line2 } : {}) },
      });
    },
    [updateCustomer]
  );

  return (
    <FormFieldset
      title={t('billing.address_title')}
      subtitle={t('billing.address_subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateCustomerStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Input
        name="line1"
        label={t('field.address')}
        register={register('line1', isRequired)}
        errorMessage={errors.line1?.message}
        placeholder={t('billing.address_line_1_placeholder')}
      />
      <Input
        name="line2"
        label={t('billing.address_line2')}
        register={register('line2')}
        errorMessage={errors.line2?.message}
        placeholder={t('billing.address_line_2_placeholder')}
      />
      <Input
        name="state"
        label={t('billing.state')}
        placeholder={t('billing.state_placeholder')}
        register={register('state')}
        errorMessage={errors.state?.message}
      />
      <div className={styles.sideBySide}>
        <Input
          name="city"
          label={t('billing.city')}
          placeholder={t('billing.city_placeholder')}
          register={register('city')}
          errorMessage={errors.city?.message}
        />
        <Input
          name="postal_code"
          label={t('billing.zipcode')}
          register={register('postal_code')}
          errorMessage={errors.postal_code?.message}
        />
      </div>
      <Select
        name="country"
        label={t('field.country')}
        register={register('country')}
        errorMessage={errors.country?.message}
        options={countryOptions}
        size="full"
      />
    </FormFieldset>
  );
};

export default BillingAddressFieldset;
