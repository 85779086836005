import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Input from '@/components/atoms/Input/Input';
import MediaFieldNew from '@/components/organisms/Dialogs/MediaField/MediaField';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import { Action } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { ACCEPTED_FILE_FORMATS } from '@/redux/dialogs/helper';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import {
  ACCEPTED_IMAGE_FORMATS,
  ACCEPTED_VIDEO_FORMATS,
} from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';
import { fileActionSchemaNew } from '@/util/validator';

import ToolkitWrapper from '../ToolkitWrapper';

const ACCEPTED_FORMATS = {
  file: ACCEPTED_FILE_FORMATS,
  image: ACCEPTED_IMAGE_FORMATS,
  video: ACCEPTED_VIDEO_FORMATS,
};

export type MediaForm = {
  url: string;
  name: string;
};

// Toolkit form for image, video and file action
function ToolkitActionMedia() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { type, actionId, url, name } = useSelector((state: RootState) => {
    const selectedAction = selectSelectedAction(state) as Extract<
      Action,
      { type: 'file' | 'image' | 'video' }
    >;
    return {
      type: selectedAction.type,
      actionId: selectedAction.action_id,
      url: selectedAction.url,
      name: selectedAction.name,
    };
  }, shallowEqual);
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  const {
    formState: { errors, isDirty, dirtyFields, touchedFields },
    getValues,
    setValue,
    register,
    trigger,
    setFocus,
  } = useForm<MediaForm>({
    mode: 'onChange',
    defaultValues: {
      url,
      name,
    },
    resolver: yupResolver(fileActionSchemaNew) as Resolver<MediaForm>,
  });
  const nameErrorMessage = capitalizeFirstLetter(errors.name?.message);
  const urlErrorMessage = capitalizeFirstLetter(errors.url?.message);

  useFocusOnInput('name', errors, setFocus);

  const handleChange = useCallback(() => {
    const values = getValues();

    dispatch(
      updateAction({
        actionId,
        action: {
          ...values,
        },
      })
    );
  }, [getValues, dispatch, actionId]);

  const onFileRemoved = useCallback(() => {
    setValue('url', '');
    dispatch(
      updateAction({
        actionId,
        action: {
          url: '',
        },
      })
    );
  }, [setValue, dispatch, actionId]);

  const updateErrors = useCallback(
    (key: string, value: string) => {
      // Prevents state update without any changes
      if (!isDirty) return;

      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t(`actions.types.${type}`),
            body: value,
            type,
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, isDirty, parentNodeId, t, type]
  );

  useEffect(() => {
    trigger();
  }, [trigger, url]);

  useEffect(() => {
    updateErrors('name', nameErrorMessage);
  }, [dirtyFields, isDirty, nameErrorMessage, touchedFields, updateErrors]);

  useEffect(() => {
    updateErrors('url', urlErrorMessage);
  }, [urlErrorMessage, updateErrors]);

  return (
    <ToolkitWrapper type={type}>
      <MediaFieldNew
        error={!!errors.url}
        onChange={handleChange}
        register={register('url')}
        setValue={setValue}
        label={t('dialog.file.url')}
        name="url"
        urlValue={getValues('url')}
        onFileRemoved={onFileRemoved}
        accept={ACCEPTED_FORMATS[type]}
        isFileMode={type === 'file'}
        fileName={getValues('name')}
        errors={errors}
      />

      <Input
        register={register('name')}
        name="name"
        error={!!errors.name}
        errorMessage={capitalizeFirstLetter(errors.name?.message)}
        label={t('common.name')}
        onChange={handleChange}
        size="small"
      />
    </ToolkitWrapper>
  );
}

export default ToolkitActionMedia;
