import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { DialogActionAlert } from '@/models/dialogAlert';
import { iconByType } from '@/redux/dialogs/helper';

type Props = {
  title: string;
  body: string;
  type: DialogActionAlert['type'];
  handleClick: () => void;
};

const customStyles = {
  '&.MuiButtonBase-root': {
    padding: 'var(--space-8) var(--space-16)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'auto',
    borderRadius: '0',
    borderBottom: '1px solid var(--border-default-gray)',
    whiteSpace: 'normal',
  },
};

const AlertSummaryItem = ({ title, body, type, handleClick }: Props) => {
  const { t } = useTranslation();
  return (
    <MenuItem sx={customStyles} onClick={handleClick}>
      <Typography
        component="h4"
        color="var(--text-default-black)"
        variant="body-regular"
      >
        {iconByType({
          id: type,
          size: 16,
          color: 'var(--icon-default-gray)',
        })}
        <span>{t(title)}</span>
      </Typography>
      <Typography component="p" color="var(--text-default-gray)">
        {t(body)}
      </Typography>
    </MenuItem>
  );
};

export default AlertSummaryItem;
