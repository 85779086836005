import { Icon } from '@/models/icon';

const Bubble = ({ size = 46, color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 46"
      width={size}
      height={size}
    >
      <ellipse cx="24.069" cy="23" rx="23.769" ry="23" fill={color} />
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.773 33.241c2.392-2.775 3.83-6.345 3.83-10.241 0-8.837-7.402-16-16.534-16-9.132 0-16.535 7.163-16.535 16s7.403 16 16.535 16c2.996 0 5.806-.77 8.229-2.12a16.263 16.263 0 0 0 3.388 1.375c1.342.383 2.134-1.51 1.577-2.752a4.635 4.635 0 0 1-.344-1.114c-.058-.342-.106-.73-.146-1.148Z"
          fill="var(--icon-default-white)"
        />
        <path
          d="M15.457 22.333c-.952 0-1.723.747-1.723 1.667s.771 1.667 1.723 1.667h2.067c.95 0 1.722-.747 1.722-1.667s-.771-1.667-1.722-1.667h-2.067ZM22.346 22.333c-.95 0-1.722.747-1.722 1.667s.771 1.667 1.722 1.667h2.067c.951 0 1.723-.747 1.723-1.667s-.771-1.667-1.723-1.667h-2.067ZM29.236 22.333c-.951 0-1.722.747-1.722 1.667s.77 1.667 1.722 1.667h2.067c.951 0 1.722-.747 1.722-1.667s-.77-1.667-1.722-1.667h-2.067ZM15.457 17c-.952 0-1.723.746-1.723 1.667 0 .92.771 1.666 1.723 1.666H18.9c.952 0 1.723-.746 1.723-1.666 0-.92-.771-1.667-1.723-1.667h-3.444ZM15.457 27c-.952 0-1.723.746-1.723 1.667 0 .92.771 1.666 1.723 1.666H18.9c.952 0 1.723-.746 1.723-1.666 0-.92-.771-1.667-1.723-1.667h-3.444ZM28.003 21c1.142 0 2.067-.895 2.067-2s-.925-2-2.067-2c-1.141 0-2.067.895-2.067 2s.926 2 2.067 2ZM23.38 20.333c.76 0 1.378-.597 1.378-1.333s-.617-1.333-1.378-1.333c-.761 0-1.378.597-1.378 1.333s.617 1.333 1.378 1.333ZM32.826 20.333c.761 0 1.378-.597 1.378-1.333s-.617-1.333-1.378-1.333c-.76 0-1.378.597-1.378 1.333s.617 1.333 1.378 1.333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="var(--icon-default-white)"
            transform="translate(7.534 7)"
            d="M0 0h33.069v32H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Bubble;
