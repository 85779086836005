import { Icon } from '@/models/icon';

const Version = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.726 4.942A6.095 6.095 0 1 1 1.905 8"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.714 4.952H2.666V1.905"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        d="M8 8.656V5.99M11.173 10.053 8 8.656"
      />
    </svg>
  );
};

export default Version;
