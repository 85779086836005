import { RootState } from '@/models/state';

export const selectLanguage = (state: RootState) => state.onboarding.language;

export const selectGoal = (state: RootState) => state.onboarding.goal;

export const selectTemplateBrainId = (state: RootState) =>
  state.onboarding.brainId;

export const selectIsCollectionsFlow = (state: RootState) =>
  state.onboarding.isCollectionsFlow;
export const selectIntegrations = (state: RootState) =>
  state.onboarding.integrations;
export const selectIntegrationId = (state: RootState) =>
  state.onboarding.integrationId;
export const selectUrl = (state: RootState) => state.onboarding.url;
export const selectCreatedBrainId = (state: RootState) =>
  state.onboarding.createdBrainId;
export const selectDatasourceType = (state: RootState) =>
  state.onboarding.datasourceType;
export const selectDeskId = (state: RootState) => state.onboarding.deskId;
