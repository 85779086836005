import { Icon } from '@/models/icon';

const Share = ({ size = 24, color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#share)"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.667 12.667a3.333 3.333 0 0 0 5.026.36l2-2a3.333 3.333 0 0 0-4.713-4.714l-1.147 1.14" />
        <path d="M13.333 11.333a3.334 3.334 0 0 0-5.026-.36l-2 2a3.333 3.333 0 0 0 4.713 4.714l1.14-1.14" />
      </g>
      <defs>
        <clipPath id="share">
          <path
            fill="var(--icon-default-white)"
            transform="translate(4 4)"
            d="M0 0h16v16H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Share;
