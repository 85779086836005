import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { BrainLanguage } from '@/models/brain';
import { languageAndFlagByCountryCode } from '@/util/languageCodes';

import { FlagEmoji } from './FlagEmoji';

import styles from './TileAIAgent.module.scss';

type TileAIAgentFooterProps = {
  lastTrained: string;
  language: BrainLanguage;
};

export function TileAIAgentFooter({
  lastTrained,
  language,
}: TileAIAgentFooterProps) {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <Typography variant="label-caps-large" color="var(--text-default-gray)">
        {t('brains.last_trained')}:{' '}
        <Typography
          variant="label-caps-large"
          color="var(--text-placeholder-gray)"
        >
          {lastTrained ? moment(lastTrained).fromNow() : '-'}
        </Typography>
      </Typography>

      <FlagEmoji flag={languageAndFlagByCountryCode[language].flag} />
    </footer>
  );
}
