import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Facebook = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, backgroundColor = '#3578EB', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M12 24.001c6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12s-12 5.373-12 12c0 6.628 5.373 12 12 12z"
            fill={backgroundColor}
          />
          <path
            d="M16.671 15.471l.532-3.47h-3.328v-2.25c0-.949.464-1.875 1.956-1.875h1.513V4.922s-1.373-.234-2.686-.234c-2.74 0-4.533 1.66-4.533 4.67v2.644H7.077v3.47h3.048v8.385a12.255 12.255 0 003.75 0V15.47h2.796z"
            fill="#fff"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Facebook.displayName = 'Facebook';

export default Facebook;
