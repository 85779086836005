import { Props } from '@/models/icon';

export const SidebarIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <rect
        width="14.909"
        height="14.909"
        x=".545"
        y=".545"
        stroke={color}
        strokeWidth="1.091"
        rx=".909"
      />
      <path stroke={color} strokeWidth="1.091" d="M5.818 1.09v14.183" />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.091"
        d="M3.854 9.455 2.4 8l1.454-1.455"
      />
    </svg>
  );
};
