import { useEffect, useMemo, useState } from 'react';

export function useOptionsPopper({
  options,
  searchString,
  setSearchResults,
  lastSearchStringLength,
  setLastSearchStringLength,
  searchResults,
  setNoResultSearchLength,
  noResultSearchLength,
  handleClosePopper,
  selectedIndex,
}) {
  const [index, setIndex] = useState({ outerIndex: 0, innerIndex: 0 });

  /**
   * Groups search results by type, transforming a 1D array into a 2D array, and generates
   * two Map objects to keep track of indices during this transformation.
   *
   * The first Map 'indexMap' maps the original 1D index to the new 2D indices,
   * and the second Map 'reverseIndexMap' maps the new 2D indices back to the original 1D index.
   *
   * @complexity O(n), where n is the number of search results.
   */
  const [groupedOptions, indexMap, reverseIndexMap] = useMemo(() => {
    const results = searchResults.reduce((groups, option) => {
      const category = groups[option.type] || [];
      category.push(option);
      groups[option.type] = category;
      return groups;
    }, {});

    const grouped = Object.keys(results).map((category) => {
      return {
        label: category,
        options: results[category],
      };
    });

    const map = new Map();
    const reverseMap = new Map();
    let currentIndex = 0;

    for (let i = 0; i < grouped.length; i++) {
      for (let j = 0; j < grouped[i].options.length; j++) {
        map.set(`${currentIndex}`, {
          outerIndex: i,
          innerIndex: j,
        });
        reverseMap.set(`${i}-${j}`, `${currentIndex}`);
        currentIndex++;
      }
    }

    return [grouped, map, reverseMap];
  }, [searchResults]);

  useEffect(() => {
    const index = indexMap.get(`${selectedIndex}`);
    if (index) {
      const { outerIndex, innerIndex } = index;
      setIndex({ outerIndex, innerIndex });
    }
  }, [selectedIndex, indexMap, groupedOptions]);

  // Search Result Processing
  useEffect(() => {
    const results = options.filter((option) =>
      option.value.toLowerCase().includes(searchString.toLowerCase())
    );
    setSearchResults(results);
  }, [options, searchString, setSearchResults]);

  // Search Length Analysis
  useEffect(() => {
    if (searchString.length < lastSearchStringLength) {
      setNoResultSearchLength((prev) => Math.max(prev - 1, 0));
    } else if (
      searchString.length > lastSearchStringLength &&
      searchResults.length === 0
    ) {
      setNoResultSearchLength((prev) => prev + 1);
    }

    setLastSearchStringLength(searchString.length);
  }, [
    searchString,
    lastSearchStringLength,
    setLastSearchStringLength,
    searchResults,
    setNoResultSearchLength,
  ]);

  // Closing Popper
  useEffect(() => {
    if (noResultSearchLength > 2) {
      handleClosePopper();
    }
  }, [handleClosePopper, noResultSearchLength]);

  function getInfo(variable, dataMap) {
    const typeData = dataMap[variable.type];
    return typeData ? typeData[variable.label] || '' : '';
  }

  function getFlatIndex(outer: number, inner: number) {
    return reverseIndexMap.get(`${outer}-${inner}`) || 0;
  }

  return {
    groupedOptions,
    index,
    setIndex,
    getInfo,
    getFlatIndex,
  };
}
