import { useCallback, useMemo } from 'react';

import Fade from '@mui/material/Fade';
import MaterialModal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import IconClose from '@/components/atoms/Icons/Close';
import { popModal } from '@/redux/modals/actions';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import './Modal.scss';

const MODAL_BTN = 'modal-primary-btn';

type ModalProps = {
  children: React.ReactNode;
  onPrimarySubmit?: () => void;
  onSecondarySubmit?: () => void;
  onBlur?: () => void;
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  size?: 'small' | 'medium' | 'large';
  primaryButtonText?: string;
  secondaryButtonText?: string;
  className?: string;
  primaryButtonDisable?: boolean;
  passiveModal?: boolean;
  autoFocus?: boolean;
  danger?: boolean;
  hasScrollingContent?: boolean;
  header?: boolean;
  isSubmitting?: boolean;
  preventClose?: boolean;
  preventSecondaryClose?: boolean;
  hideBackdrop?: boolean;
  cleanModal?: boolean;
  fixedHeight?: boolean;
  disableRestoreFocus?: boolean;
  noGutters?: boolean;
  buttonAlignment?: 'center' | 'right';
  hideSecondaryButton?: boolean;
  buttonType?: 'button' | 'submit';
  isLayeredAbove?: boolean;
  footer?: React.ReactNode;
};

// This sub-component is used to render the modal content based on the buttonType prop
function ModalContent({ buttonType, fixedHeight, children }) {
  if (buttonType === 'submit') {
    return (
      <form
        className={cn('modal__content', fixedHeight ? 'fixedHeight' : null)}
      >
        {children}
      </form>
    );
  }
  return (
    <div className={cn('modal__content', fixedHeight ? 'fixedHeight' : null)}>
      {children}
    </div>
  );
}

function Modal({
  children,
  title = '',
  subtitle = '',
  size = 'small',
  onPrimarySubmit = () => {},
  onSecondarySubmit,
  onBlur,
  primaryButtonDisable = false,
  primaryButtonText,
  secondaryButtonText,
  passiveModal = false,
  autoFocus = false,
  danger = false,
  header = true,
  hasScrollingContent = false,
  isSubmitting = false,
  className = '',
  preventClose = false,
  preventSecondaryClose,
  hideBackdrop = false,
  cleanModal = false,
  fixedHeight = false,
  disableRestoreFocus = false,
  noGutters,
  buttonAlignment = 'right',
  hideSecondaryButton = false,
  buttonType = 'button',
  isLayeredAbove = false,
  footer,
}: ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Handle modal close
  const onClose = useCallback(
    (_, reason?: string) => {
      if (preventClose && reason === 'backdropClick') {
        return false;
      }

      if (onSecondarySubmit) {
        onSecondarySubmit();
      }
      if (onBlur) {
        onBlur();
      }
      if (
        !(preventSecondaryClose && reason === 'secondaryClick') &&
        !isLayeredAbove
      ) {
        // We only want to pop the modal if it's not layered above another modal
        dispatch(popModal());
      }
    },
    [
      dispatch,
      isLayeredAbove,
      onBlur,
      onSecondarySubmit,
      preventClose,
      preventSecondaryClose,
    ]
  );

  const handleOnKeyPress = useCallback(
    (e) => {
      if (isKeyEnter(e) && (autoFocus || e.target.id === MODAL_BTN)) {
        preventClickThrough(e);
        onPrimarySubmit();
      }
    },
    [autoFocus, onPrimarySubmit]
  );

  const primaryButtonTextTranslated = useMemo(
    () => primaryButtonText || t('common.create'),
    [primaryButtonText, t]
  );

  const secondaryButtonTextTranslated = useMemo(
    () => secondaryButtonText || t('common.cancel'),
    [secondaryButtonText, t]
  );

  return (
    <MaterialModal
      aria-labelledby={title as string}
      aria-describedby={subtitle as string}
      onKeyDown={handleOnKeyPress}
      disableEscapeKeyDown={preventClose}
      className="modal"
      open
      onClose={onClose}
      closeAfterTransition
      disableRestoreFocus={disableRestoreFocus}
      slotProps={{
        backdrop: {
          timeout: 100,
          style: { opacity: hideBackdrop ? '0%' : '100%' },
        },
      }}
    >
      <Fade in>
        <div className={cn('modal__container', size, className)}>
          {cleanModal && (
            <div>
              <div
                className={cn(
                  'modal__content',
                  fixedHeight ? 'fixedHeight' : null,
                  cleanModal ? 'no_margin' : null,
                  noGutters ? 'noGutters' : null
                )}
              >
                <section
                  className={cn({
                    modal__content_scrollable: hasScrollingContent,
                  })}
                >
                  {children}
                </section>
              </div>
            </div>
          )}
          {!cleanModal && (
            <>
              <div className="modal__close">
                <IconButton onClick={onClose} ariaLabel={t('modals.close')}>
                  <IconClose size={20} />
                </IconButton>
              </div>
              <ModalContent fixedHeight={fixedHeight} buttonType={buttonType}>
                {header && (
                  <header>
                    <h6>{title}</h6>
                    {subtitle && (
                      <Typography variant="body-regular">{subtitle}</Typography>
                    )}
                  </header>
                )}
                <section
                  className={cn({
                    modal__content_scrollable: hasScrollingContent,
                    modal__content_no_margin: cleanModal,
                  })}
                >
                  {children}
                </section>
                {!passiveModal && (
                  <div
                    className={cn(
                      'modal__container',
                      'button__container',
                      `align-${buttonAlignment}`
                    )}
                  >
                    {footer}
                    {!hideSecondaryButton && (
                      <div className={cn('secondaryButton')}>
                        <Button
                          onClick={(e) => {
                            onClose(e, 'secondaryClick');
                          }}
                          variant="secondary"
                          type="button"
                          size="large"
                        >
                          {secondaryButtonTextTranslated}
                        </Button>
                      </div>
                    )}
                    {onPrimarySubmit && (
                      <Button
                        id={MODAL_BTN}
                        onClick={onPrimarySubmit}
                        variant={danger ? 'danger' : 'primary'}
                        disabled={primaryButtonDisable}
                        type={buttonType}
                        isLoading={isSubmitting}
                        size="large"
                      >
                        {primaryButtonTextTranslated}
                      </Button>
                    )}
                  </div>
                )}
              </ModalContent>
            </>
          )}
        </div>
      </Fade>
    </MaterialModal>
  );
}

export default Modal;
