import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import useEntities from '@/hooks/useEntities';
import { RootState } from '@/models/state';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { importValues } from '@/redux/values/actions';
import { selectValues } from '@/redux/values/selectors';
import { exportEntities, importEntities } from '@/util/file-manager';

import useDialogs from './useDialogs';

const useValues = (brainId: string, entityName: string) => {
  const { t } = useTranslation();
  const { entity } = useEntities(brainId, entityName);
  const dispatch = useDispatch();
  const { dialogs } = useDialogs(brainId);

  const values = useSelector((state: RootState) => selectValues(state));
  const isDirty = useSelector((state: RootState) => state.values.dirty);

  const isDraft = entityName === 'draft';

  const importEntity = async (file) => {
    try {
      const data = await importEntities(file, t);
      dispatch(importValues(data));
    } catch (error) {
      dispatch(addTemporalToast('error', error.message));
    }
  };

  const exportEntity = () => {
    exportEntities(entity);
  };

  const findConnectedDialogsForValues = useCallback(
    (value: string) => {
      const usedDialogs = [];
      if (dialogs && values) {
        dialogs?.forEach((dialog) => {
          for (let i = 0; i < dialog?.nodes?.length; i++) {
            const node = dialog.nodes[i];
            for (let j = 0; j < node?.conditions?.length; j++) {
              const cond = node.conditions[j];
              for (let k = 0; k < cond.rules.length; k++) {
                const rule = cond.rules[k];
                if (
                  rule?.value &&
                  rule.name === `@${entityName}` &&
                  rule.value === value
                ) {
                  usedDialogs.push({
                    url: dialog.dialog_id,
                    label: dialog.name,
                  });
                  return;
                }
              }
            }
          }
        });
      }
      return usedDialogs;
    },
    [dialogs, entityName, values]
  );

  return {
    values,
    isDirty,
    isDraft,
    findConnectedDialogsForValues,
    importEntity,
    exportEntity,
  };
};

export default useValues;
