import { useContext, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { SocketContext } from '@/contexts/rtm';
import { ServerToClientEvents, SocketEvent } from '@/models/rtm';
import { removeViewer, setViewers } from '@/redux/presence/actions';

export const useRtmViewing = () => {
  const dispatch = useDispatch();

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleViewing: ServerToClientEvents[SocketEvent.conversations_viewing] =
      ({ data }) =>
        dispatch(
          setViewers({
            type: 'sessions',
            id: data.session_id,
            agents: data.agents,
          })
        );

    const handleViewingStop: ServerToClientEvents[SocketEvent.conversations_viewing_stop] =
      ({ user_id, data }) =>
        dispatch(
          removeViewer({
            type: 'sessions',
            id: data.session_id,
            user_id: user_id,
          })
        );

    socket?.on(SocketEvent.conversations_viewing, handleViewing);
    socket?.on(SocketEvent.conversations_viewing_stop, handleViewingStop);
    return () => {
      socket?.off(SocketEvent.conversations_viewing, handleViewing);
      socket?.off(SocketEvent.conversations_viewing_stop, handleViewingStop);
    };
  }, [dispatch, socket]);
};
