import { HTMLAttributes } from 'react';

import { Icon } from '@/models/icon';

const Collection = ({
  size = 24,
  color = 'var(--icon-default-gray)',
  ...rest
}: Icon & HTMLAttributes<SVGElement>) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m19 21-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16Z"
      />
    </svg>
  );
};

export default Collection;
