import { useCallback } from 'react';

import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Node from '@/components/organisms/Dialogs/Node/Node';
import NodePlaceholder from '@/components/organisms/Dialogs/Node/Placeholder/Placeholder';
import { useCopyPaste } from '@/hooks/useCopyPaste';
import useHotkeys from '@/hooks/useHotkeys';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { isModalOpen } from '@/redux/modals/selectors';
import { clearSelection } from '@/redux/nodes/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import './DialogEditor.scss';

const DialogEditor = () => {
  const dispatch = useDispatch();
  const { handlePaste } = useCopyPaste();
  const modalOpen = useSelector(isModalOpen);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const rootId = useSelector(
    (state: RootState) => state.nodes.nodes[0]?.node_id,
    shallowEqual
  );

  const hotkeys = {
    'mod+v': handlePaste,
  };

  useHotkeys({ hotkeys, active: !rootId && !modalOpen });

  const onClick = useCallback(
    (e) => {
      preventClickThrough(e);
      dispatch(clearSelection());
    },
    [dispatch]
  );

  const onKeyUp = useCallback(
    (e) => {
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        dispatch(clearSelection());
      }
    },
    [dispatch]
  );

  const containerClassNames = cn({
    dialog_editor__container: true,
    dialog_editor__readOnly: !canWrite,
  });

  const renderComponent = () => {
    if (rootId) {
      return <Node nodeId={rootId} />;
    } else {
      return <NodePlaceholder show parentId={rootId} />;
    }
  };

  return (
    <div
      role="presentation"
      className={containerClassNames}
      onKeyUp={onKeyUp}
      onClick={onClick}
    >
      <Scrollbars autoHide={false}>{renderComponent()}</Scrollbars>
    </div>
  );
};

export default DialogEditor;
