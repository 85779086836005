import { useCallback, useMemo } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import useBrains from '@/hooks/useBrains';
import useBusinessHours from '@/hooks/useBusinessHours';
import useCollections from '@/hooks/useCollections';
import useDatasources from '@/hooks/useDatasources';
import useDesks from '@/hooks/useDesks';
import { useIntegrations } from '@/hooks/useIntegrations';
import { useBroadcasts } from '@/modules/broadcast/hooks/useBroadcasts';
import { useBundles } from '@/modules/bundles/hooks/useBundles';
import { useRules } from '@/modules/rules/hooks/useRules';
import { selectAccountSlug } from '@/redux/session/selectors';

import styles from './Header.module.scss';

const BreadCrumbs = () => {
  const { t } = useTranslation();

  const {
    deskId,
    brainId,
    broadcastId,
    ruleId,
    integrationId,
    businessHourId,
    webhookId,
    bundleId,
    collectionId,
    datasourceId,
  } = useParams();
  const slug = useSelector(selectAccountSlug);

  const { collection } = useCollections(collectionId);
  const { datasource } = useDatasources(collectionId, datasourceId);
  const { rule } = useRules(deskId, ruleId);
  const { integration } = useIntegrations(deskId, integrationId);
  const { bundle } = useBundles(deskId, bundleId);
  const { singleBusinessHour } = useBusinessHours(businessHourId);
  const { brain } = useBrains(brainId);
  const { broadcast } = useBroadcasts(broadcastId);

  const isDefaultBusinessHour = singleBusinessHour?.is_default;
  const { desk } = useDesks(deskId);

  const location = useLocation();

  const parentType = location.pathname.split('/')[2];
  const childType = location.pathname.split('/')[4];

  const childId = location.pathname.split('/').pop();

  const deskItems = useMemo(
    () => [integrationId, ruleId, bundleId, businessHourId],
    [bundleId, businessHourId, integrationId, ruleId]
  );

  const getBreadCrumbs = useCallback(() => {
    let breadCrumbsArray = [];
    if (parentType === 'environments') {
      if (deskItems.includes(childId)) {
        breadCrumbsArray = [
          {
            label: t(`common.${childType}`),
            link: `/${slug}/${parentType}/${deskId}/${childType}`,
          },
          {
            label: desk?.name,
            link: ``,
          },
        ];
      }
      if (bundleId) {
        return [
          ...breadCrumbsArray,
          {
            label: bundle?.name,
            link: `/${slug}/${parentType}/${deskId}/${childType}/${bundleId}`,
          },
        ];
      }
      if (ruleId) {
        return [
          ...breadCrumbsArray,
          {
            label: rule?.name ?? t('common.draft'),
            link: `/${slug}/${parentType}/${deskId}/${childType}/${ruleId}`,
          },
        ];
      }
      if (businessHourId) {
        return [
          ...breadCrumbsArray,
          {
            label: isDefaultBusinessHour
              ? t('business_hours.header.default')
              : t('business_hours.header.custom'),
            link: `/${slug}/${parentType}/${deskId}/${childType}/${businessHourId}`,
          },
        ];
      }
      if (integrationId) {
        return [
          ...breadCrumbsArray,
          {
            label: capitalize(integration?.type),
            link: `/${slug}/${parentType}/${deskId}/${childType}/${integrationId}`,
          },
        ];
      }
    }

    if (broadcastId) {
      breadCrumbsArray = [
        {
          label: t(`common.${parentType}`),
          link: `/${slug}/${parentType}`,
        },
        {
          label: broadcast?.name ?? t('common.draft'),
          link: '',
        },
      ];
      if (['logs', 'audience'].includes(childType)) {
        breadCrumbsArray = [
          ...breadCrumbsArray.slice(0, 1),
          {
            label: broadcast?.name ?? t('common.draft'),
            link: `/${slug}/${parentType}/${broadcastId}`,
          },
          {
            label: t(`broadcasts.${childType}`),
            link: '',
          },
        ];
      }
    }

    if (collectionId) {
      breadCrumbsArray = [
        {
          label: t(`common.${parentType}`),
          link: `/${slug}/${parentType}`,
        },
        {
          label: collection?.name,
          link: `/${slug}/${parentType}/${collectionId}`,
        },
      ];
    }

    if (datasourceId) {
      return [
        ...breadCrumbsArray,
        {
          label: datasource?.name,
          link: `/${slug}/${parentType}/${collectionId}/${childType}/${datasourceId}`,
        },
      ];
    }
    if (webhookId) {
      return [
        {
          label: t(`common.${parentType}`),
          link: `/${slug}/${parentType}`,
        },
        {
          label: brain?.name,
          link: `/${slug}/${parentType}/${brainId}/webhooks`,
        },
        {
          label:
            webhookId === 'draft'
              ? t('webhook.header.create')
              : t('webhook.header.update'),
          link: ``,
        },
      ];
    }

    return breadCrumbsArray;
  }, [
    brain?.name,
    brainId,
    broadcast?.name,
    broadcastId,
    bundle?.name,
    bundleId,
    businessHourId,
    childId,
    childType,
    collection?.name,
    collectionId,
    datasource?.name,
    datasourceId,
    desk?.name,
    deskId,
    deskItems,
    integration?.type,
    integrationId,
    isDefaultBusinessHour,
    parentType,
    rule?.name,
    ruleId,
    slug,
    t,
    webhookId,
  ]);

  return (
    <div className={styles.breadcrumbs}>
      <Stack spacing={2}>
        <MuiBreadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{
            'li a span': {
              '&:hover': {
                textDecoration: 'underline',
                color: 'var(--text-hover-blue)',
              },
            },
          }}
        >
          {getBreadCrumbs().map((breadcrumb, index) => {
            const isLast = index === getBreadCrumbs().length - 1;
            return isLast || breadcrumb.link === '' ? (
              <Typography
                key={breadcrumb.link}
                color="var(--text-default-gray-light)"
              >
                {breadcrumb.label}
              </Typography>
            ) : (
              <Link key={breadcrumb.link} to={breadcrumb.link}>
                <Typography color="var(--text-default-blue)">
                  {breadcrumb.label}
                </Typography>
              </Link>
            );
          })}
        </MuiBreadcrumbs>
      </Stack>
    </div>
  );
};

export default BreadCrumbs;
