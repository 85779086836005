import { Icon } from '@/models/icon';

const Teammates = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M11.334 13.333h3.333V12a2 2 0 00-3.57-1.238m.237 2.571H4.667m6.667 0V12c0-.437-.085-.855-.238-1.238m0 0a3.335 3.335 0 00-6.192 0m-.237 2.571H1.334V12a2 2 0 013.57-1.238m-.237 2.571V12c0-.437.084-.855.237-1.238M10 4.667a2 2 0 11-4 0 2 2 0 014 0zm4 2a1.333 1.333 0 11-2.666 0 1.333 1.333 0 012.666 0zm-9.333 0a1.333 1.333 0 11-2.667 0 1.333 1.333 0 012.667 0z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Teammates;
