import { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { capitalizeFirstLetter } from '@/util/util';

import BroadcastHeader from '../components/BroadcastHeader/BroadcastHeader';
import BroadcastLayout from '../components/BroadcastLayout/BroadcastLayout';
import BroadcastPageSidebar from '../components/BroadcastSidebar/BroadcastPageSidebar';
import { MessageFieldset } from '../components/MessageFieldset/MessageFieldset';
import { SettingsFieldset } from '../components/SettingsFieldset/SettingsFieldset';
import { UploadFieldset } from '../components/UploadFieldset/UploadFieldset';
import { useSubscribers } from '../hooks/useSubscribers';
import { Form } from '../models';
import {
  selectBroadcast,
  selectIsDraft,
  selectIsReadOnly,
} from '../redux/selectors';
import {
  SEND_NOW,
  isScheduledToBeSentNow,
  showBroadcastBanner,
} from '../utils';

import styles from './Broadcast.module.scss';

export const Broadcast = ({ onSubmit, saveButtonLoading }) => {
  const { handleSubmit, getValues, watch } = useFormContext<Form>();

  const [buttonClicked, setButtonClicked] = useState<number>(0);
  const { broadcastId } = useParams();
  const broadcast = useSelector(selectBroadcast);
  const isReadOnly = useSelector(selectIsReadOnly);
  const isDraft = useSelector(selectIsDraft);
  const { subscribers: APISubscribers, validSubscribersCount } =
    useSubscribers(broadcastId);

  const { t } = useTranslation();

  const isScheduledButtonDisabled = useCallback(() => {
    if (isReadOnly || !getValues().template || !getValues().scheduleDate)
      return true;
    if (isDraft && validSubscribersCount === 0) {
      return true;
    }
    if (APISubscribers?.pages?.[0].subscribers?.length === 0) {
      return true;
    }
    return false;
  }, [
    APISubscribers?.pages,
    getValues,
    isDraft,
    isReadOnly,
    validSubscribersCount,
  ]);

  const sheduledButtonText =
    watch('scheduleDate') === SEND_NOW
      ? t('chatBox.send')
      : t('business_hours.schedule');

  const computedStatus = isScheduledToBeSentNow(broadcast)
    ? 'in_progress'
    : broadcast?.status;

  const { show, bannerVariant, bannerTitle, bannerSubstitle } =
    showBroadcastBanner(computedStatus, broadcast, t);

  const handleButtonClick = (formData: Form | undefined, index: number) => {
    setButtonClicked(index);
    onSubmit(formData, index);
  };
  return (
    <>
      <BroadcastHeader isReadOnly={isReadOnly} />
      <PageContentWrapper newPlain2 whiteBackground>
        <BroadcastLayout>
          <>
            <BroadcastLayout.Main>
              <div className={styles.mainContainer}>
                {show && (
                  <Box mb={5}>
                    <Banner
                      relativePosition
                      variant={bannerVariant}
                      title={bannerTitle}
                    >
                      {bannerSubstitle}
                    </Banner>
                  </Box>
                )}

                <UploadFieldset isReadOnly={isReadOnly} />
                <Divider className={styles.divider} />
                <MessageFieldset isReadOnly={isReadOnly} />
                <Divider className={styles.divider} />
                <SettingsFieldset isReadOnly={isReadOnly} />
                <div className={styles.buttonContainer}>
                  <Button
                    variant="secondary"
                    onClick={() => handleButtonClick(undefined, 0)}
                    isLoading={saveButtonLoading && buttonClicked === 0}
                    disabled={isReadOnly}
                  >
                    {t('broadcasts.save_draft')}
                  </Button>
                  <Button
                    onClick={handleSubmit((formData) =>
                      handleButtonClick(formData, 1)
                    )}
                    isLoading={saveButtonLoading && buttonClicked === 1}
                    disabled={isScheduledButtonDisabled()}
                  >
                    {capitalizeFirstLetter(sheduledButtonText)}
                  </Button>
                </div>
              </div>
            </BroadcastLayout.Main>

            <BroadcastLayout.Sidebar>
              <BroadcastPageSidebar />
            </BroadcastLayout.Sidebar>
          </>
        </BroadcastLayout>
      </PageContentWrapper>
    </>
  );
};
