import { DetailedHTMLProps, LiHTMLAttributes } from 'react';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';

import { Option } from './AutoComplete';

import styles from './AutocompleteOption.module.scss';

interface Props
  extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  option: Option | string;
  selected: boolean;
  multiple: boolean;
}

const AutocompleteOption = (props: Props) => {
  const { option, selected, multiple } = props;
  if (
    multiple &&
    option &&
    typeof option !== 'string' &&
    'description' in option
  ) {
    const descriptionArray = option.description.split('. ');
    return (
      <li {...props}>
        <div className={styles.checkboxWrapper}>
          <Checkbox checked={selected} />
          <div className={styles.wrapper}>
            <span className={styles.title}> {option?.['label']}</span>
            <span className={styles.subtitle}>
              {descriptionArray.map((x, index) => (
                <span key={x}>
                  {x}
                  {descriptionArray.length - 1 !== index && '.'}
                </span>
              ))}
            </span>
          </div>
        </div>
      </li>
    );
  }

  if (option?.['description'] && !multiple) {
    return (
      <li {...props}>
        <div className={styles.wrapper}>
          <span className={styles.title}> {option?.['label']}</span>
          <span className={styles.subtitle}> {option?.['description']}</span>
        </div>
      </li>
    );
  }

  if (multiple) {
    return (
      <li {...props}>
        <Checkbox checked={selected} />
        <span> {option?.['label'] ?? option}</span>
      </li>
    );
  }
  return (
    <li {...props}>
      <div className={styles.wrapper}>
        <span>{option?.['label'] ?? option ?? ''}</span>
      </div>
    </li>
  );
};

export default AutocompleteOption;
