import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useHomeCheckList, {
  AccountUserPrefsEnum,
  INAPPHELP_KEYS,
} from '@/hooks/useHomeCheckList';
import useLocalStorage from '@/hooks/useLocalStorage';
import { RootState } from '@/models/state';
import { hideHelper, showHelper } from '@/redux/helpers/actions';
import { selectHelper } from '@/redux/helpers/selectors';

const useBrainHeader = () => {
  const dispatch = useDispatch();

  const { accountUserChecklist } = useHomeCheckList();

  const [_, setDismissTestHelp] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.TEST_DIALOG],
    accountUserChecklist[AccountUserPrefsEnum.TEST_DIALOG]
  );

  const [dismissVersionHelp, setDismissVersionHelp] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_VERSION],
    accountUserChecklist[AccountUserPrefsEnum.CREATE_VERSION]
  );

  const showTestDialog = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.TEST_DIALOG])
  );

  const showIntentHelp = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_INTENT])
  );

  const showVersionTooltip = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_VERSION])
  );

  const [dismissIntentHelp, setDismissIntentHelp] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_INTENT],
    !(
      !accountUserChecklist[AccountUserPrefsEnum.CREATE_INTENT] &&
      accountUserChecklist[AccountUserPrefsEnum.GENERATE_DIALOG]
    )
  );

  useEffect(() => {
    if (
      accountUserChecklist[AccountUserPrefsEnum.CREATE_INTENT] &&
      !accountUserChecklist[AccountUserPrefsEnum.CREATE_VERSION] &&
      !dismissVersionHelp
    ) {
      dispatch(showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_VERSION]));
    }
  }, [accountUserChecklist, dismissVersionHelp, dispatch]);

  useEffect(() => {
    if (
      !accountUserChecklist[AccountUserPrefsEnum.CREATE_INTENT] &&
      accountUserChecklist[AccountUserPrefsEnum.TEST_DIALOG] &&
      !dismissIntentHelp
    ) {
      dispatch(showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_INTENT]));
    }
  }, [accountUserChecklist, dismissIntentHelp, dispatch]);

  const handleDismissTestHelp = useCallback(() => {
    setDismissTestHelp(true);
    dispatch(hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.TEST_DIALOG]));

    if (
      !accountUserChecklist[AccountUserPrefsEnum.CREATE_INTENT] &&
      !dismissIntentHelp
    ) {
      dispatch(showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_INTENT]));
    }
  }, [accountUserChecklist, dismissIntentHelp, dispatch, setDismissTestHelp]);

  return {
    setDismissTestHelp,
    setDismissVersionHelp,
    setDismissIntentHelp,
    showTestDialog,
    showIntentHelp,
    showVersionTooltip,
    dismissIntentHelp,
    handleDismissTestHelp,
  };
};

export default useBrainHeader;
