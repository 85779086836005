import { useEffect, useCallback } from 'react';

import { RegisterOptions, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import { useIntegrations } from '@/hooks/useIntegrations';
import { Integration } from '@/models/integration';
import { submitWithTrimming } from '@/util/util';
import { errorMessage } from '@/util/validator';

import { FieldsetProps } from '../../Fieldset';

interface Props extends FieldsetProps {
  integration: Integration;
  inputSize?: 'small' | 'medium' | 'large';
  name: string;
  validationRule?: RegisterOptions;
  readOnly?: boolean;
}

const SingleInputFieldset = ({
  integration,
  inputSize = 'large',
  name,
  validationRule = {},
  readOnly,
  ...fieldsetProps
}: Props) => {
  const { t } = useTranslation();
  const { updateIntegration, updateStatus } = useIntegrations(
    integration?.desk_id,
    integration?.integration_id
  );

  const nameConfigValue = integration?.config?.[name];
  const formMethods = useForm<{
    [name: string]: string;
  }>({
    mode: 'onChange',
    defaultValues: { [name]: nameConfigValue ?? '' },
  });
  const {
    register,
    reset,
    formState: { errors, isDirty },
  } = formMethods;

  useEffect(() => {
    if (nameConfigValue) {
      reset({ [name]: nameConfigValue ?? '' });
    }
  }, [nameConfigValue, name, reset]);

  const onSubmit = useCallback(
    (form) => {
      updateIntegration({
        ...integration,
        config: {
          ...integration.config,
          ...form,
        },
      });
    },
    [integration, updateIntegration]
  );

  return (
    <FormFieldset
      title={fieldsetProps.title}
      subtitle={fieldsetProps.subtitle}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateStatus === 'pending',
      }}
      onSubmit={submitWithTrimming(formMethods, onSubmit)}
      externalLink={fieldsetProps.externalLink}
    >
      <Input
        name={name}
        register={register(name, validationRule)}
        errorMessage={
          errorMessage({
            field: errors[name],
            minLength: validationRule?.minLength,
            maxLength: validationRule?.maxLength,
          }) ?? errors[name]?.message
        }
        placeholder=""
        size={inputSize}
        readOnly={readOnly}
      />
    </FormFieldset>
  );
};

export default SingleInputFieldset;
