import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Member } from '@/models/member';

const initialState: Member[] = [];

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setMembers: (state, action: PayloadAction<Member[]>) => {
      const payload = Array.isArray(action.payload) ? action.payload : [];
      state.splice(0, state.length, ...payload);
    },
  },
});

export const { setMembers } = membersSlice.actions;

export default membersSlice.reducer;
