import { ChangeEvent, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from '@/components/atoms/Select/Select';
import useWebhooks from '@/hooks/useWebhooks';
import { WebhookAction } from '@/models/action';
import { updateAction } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';

const Webhook = ({ action }: { action: WebhookAction }) => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const dispatch = useDispatch();
  const { webhooks = [] } = useWebhooks(brainId);
  const webhookId = action.webhook_id;
  // Available webhooks
  const options = webhooks.map(({ webhook_id, name }) => ({
    value: webhook_id,
    label: name,
  }));

  const onWebhookChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      dispatch(
        updateAction({
          actionId: action.action_id,
          action: {
            webhook_id: e.target.value,
          },
        })
      );
    },
    [action.action_id, dispatch]
  );

  return (
    <Select
      size="small-full"
      value={webhookId ?? ''}
      required
      placeholder={t('dialog.select_a_webhook')}
      name="webhook_id"
      onChange={onWebhookChange}
      options={options}
    />
  );
};

export default Webhook;
