import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import { useAccount } from '@/hooks/useAccount';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { submitWithTrimming } from '@/util/util';
import {
  errorMessage,
  accountRules,
  LENGTH_XS,
  createInputSchema,
} from '@/util/validator';

type Form = {
  name: string;
};

interface Props {
  readOnly?: boolean;
}

const NameFieldset = ({ readOnly = false }: Props) => {
  const { t } = useTranslation();
  const { account, updateAccount, updateStatus } = useAccount();
  const formMethods = useForm<Form>({ mode: 'onChange' });
  const {
    register,
    formState: { errors, isDirty },
    reset,
  } = formMethods;

  const dispatch = useDispatch();

  const isLoading = account === undefined;

  useEffect(() => {
    if (account?.name) {
      reset({ name: account.name });
    }
  }, [account?.name, reset]);

  const onSubmit = useCallback(
    ({ name }: Form) =>
      updateAccount(
        { name },
        {
          onSuccess: () => {
            dispatch(addTemporalToast('success', t('account.account_updated')));
          },
        }
      ),
    [dispatch, t, updateAccount]
  );

  return (
    <FormFieldset
      title={t('account.name.title')}
      subtitle={t('account.name.subtitle')}
      primaryButton={{
        children: t('common.save'),
        isLoading: updateStatus === 'pending',
        disabled: !isDirty,
      }}
      onSubmit={submitWithTrimming(formMethods, onSubmit)}
      isLoading={isLoading}
      helpText={t('account.slug_help')}
    >
      <Input
        name="name"
        register={register('name', createInputSchema(LENGTH_XS))}
        readOnly={readOnly}
        errorMessage={errorMessage({
          field: errors.name,
          maxLength: accountRules.name.maxLength,
        })}
        size="large"
      />
    </FormFieldset>
  );
};

export default NameFieldset;
