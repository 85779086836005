import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { sendCorrection } from '@/api/client';
import Button from '@/components/atoms/Button/Button/Button';
import CollectionIcon from '@/components/atoms/Icons/Collection';
import IntentModal from '@/components/organisms/TryIt/IntentModal/IntentModal';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { LogSessionContentRow } from '@/hooks/useLogs';
import { Collection } from '@/models/collections';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import {
  AmendPayload,
  CollectionMessage,
  ExternalUrl,
  Fragment,
  TryIt,
  UserMessage,
} from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { useDetailsSection } from '@/modules/TryIt/hooks/useDetailsSection';
import {
  selectIsTryItReplay,
  selectSelectedMessage,
  selectTryItSessionId,
} from '@/modules/TryIt/redux/selectors';
import { popModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';
import { delay } from '@/util/util';

import { DocumentsMatched } from '../../../Messages/DocumentsMatched/DocumentsMatched';
import { PossibleMatchedIntents } from '../../../Messages/PossibleMatchedIntents/PossibleMatchedIntents';
import { StandaloneWrapper } from '../StandaloneWrapper';

import styles from '../DetailsSection.module.scss';

type CollectionDetailsSectionProps = {
  content: LogSessionContentRow;
};

export type CollectionDetailsSectionContentProps = {
  nodes: TryIt['selectedMessage']['nodes'];
  index: number;
  messages: LogMessage[];
  collection: Collection;
  uniqueDocuments: Fragment[];
  slug: string;
  closeModal: () => void;
  isReplay: boolean;
  canWrite: boolean;
  session_id?: string;
  activeBrainId: string;
  external_urls?: ExternalUrl[];
};

export const CollectionDetailsSectionContent = ({
  nodes,
  index,
  messages,
  collection,
  uniqueDocuments,
  slug,
  isReplay,
  canWrite,
  session_id,
  activeBrainId,
  external_urls,
}: CollectionDetailsSectionContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tryItSessionId = useSelector(selectTryItSessionId);

  const intent =
    (nodes[index]?.messages?.[0] as UserMessage)?.intents?.[0] ||
    nodes[index]?.intents?.[0];
  const showAddTrainingPhrase = isReplay && intent?.confidence !== 1;

  const [amended, setAmended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expressionToTrain, setExpressionToTrain] = useState('');
  const [showIntentsModal, setShowIntentsModal] = useState(false);
  const userMessage = (nodes[index]?.messages[0] as CollectionMessage)?.text;
  const planner_intents = nodes[index]?.planner_intents;
  const isPlannerIntentOfftopic =
    planner_intents?.includes('offtopics') && planner_intents?.length === 1;

  const plannerIntent = planner_intents?.[0];

  const { brain } = useBrains(activeBrainId);

  const amendPayload: AmendPayload = {
    request_id:
      nodes[index].request_id || nodes[index]?.nodes_stack?.[0]?.request_id,
    task: 'standalone',
    session_id: session_id || tryItSessionId,
    language: brain?.language,
  };

  const isAmended = !!nodes[index]?.corrections?.find(
    (c) => c.task === 'standalone'
  );

  const handleDocumentIncorrectClick = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const payload = {
      ...amendPayload,
      task: 'planner' as const,
      correction: 'null',
    };
    try {
      const result = await sendCorrection(payload);
      setIsLoading(false);
      if (result.status == 'ok') {
        await delay(300);
        setAmended(true);
      }
    } catch (e) {
      setIsLoading(false);
      dispatch(addErrorTemporalToast(e));
    }
  };

  return (
    <Stack data-testid="newDebugLogs">
      {isReplay && showIntentsModal && (
        <IntentModal
          close={() => {
            setExpressionToTrain('');
            setShowIntentsModal(false);
          }}
          expression={expressionToTrain}
          brainId={activeBrainId}
        />
      )}

      <StandaloneWrapper
        userMessage={userMessage}
        amendPayload={amendPayload}
        isAmended={isAmended}
      />
      <div className={styles.step}>
        <PossibleMatchedIntents
          brainId={activeBrainId}
          isPlannerIntentOfftopic={isPlannerIntentOfftopic}
          plannerIntent={plannerIntent}
          intentName={intent?.intent}
        />
        <div className={styles.details}>
          {showAddTrainingPhrase && (
            <Box ml={1}>
              <Button
                disabled={!canWrite}
                variant="tertiary"
                onClick={() => {
                  setExpressionToTrain(userMessage);
                  setShowIntentsModal(true);
                  trackEvent(EventName.ClickAddTrainingPhrase, { session_id });
                }}
              >
                {t('try_it.details.add_training_phrase')}
              </Button>
            </Box>
          )}
        </div>
      </div>
      <div className={styles.step}>
        <div className={styles.title}>
          <CollectionIcon size={16} />

          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('collections.collection')}{' '}
            <NavLink
              target="_blank"
              rel="noopener noreferrer"
              to={`/${slug}/collections/${collection?.collection_id}`}
              className={styles.link}
            >
              {collection?.name}
            </NavLink>{' '}
            {t('try_it.triggered')}
          </Typography>
        </div>
        <div className={styles.details}></div>
      </div>
      <div className={styles.step}>
        <DocumentsMatched
          nodes={nodes}
          messages={messages}
          index={index}
          uniqueDocuments={uniqueDocuments}
          external_urls={external_urls}
          onClick={handleDocumentIncorrectClick}
          amended={amended}
          isAmending={isLoading}
        />
      </div>
    </Stack>
  );
};

export const CollectionDetailsSection = ({
  content,
}: CollectionDetailsSectionProps) => {
  const dispatch = useDispatch();
  const selectedMessage = useSelector(selectSelectedMessage);
  const { nodes, index } = selectedMessage;
  const messages = content?.messages.filter(
    (msg) => msg.event === 'message:brain_send'
  );
  const { slug } = useAccount();
  const { collection, uniqueDocuments, external_urls } = useDetailsSection(
    nodes[index]?.collection,
    messages?.[index]
  );
  const brainId = useSelector(selectBrainId);
  const closeModal = () => {
    dispatch(popModal());
  };
  const isReplay = useSelector(selectIsTryItReplay);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const { session_id, brain_id: logBrainId } = content || {};
  const activeBrainId = brainId || logBrainId;

  return (
    <CollectionDetailsSectionContent
      {...{
        nodes,
        index,
        messages,
        collection,
        uniqueDocuments,
        slug,
        closeModal,
        isReplay,
        canWrite,
        session_id,
        activeBrainId,
        external_urls,
      }}
    />
  );
};
