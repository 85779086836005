import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import styles from '@/components/atoms/Accordion/templates/ConnectionStatusAccordion.module.scss';
import PlainFooterFieldset from '@/components/atoms/Fieldset/templates/PlainFooterFieldset';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';

interface Props {
  onOAuthClick: () => void;
}

export const ReAuthFieldset = ({ onOAuthClick }: Props) => {
  const { t } = useTranslation();

  return (
    <PlainFooterFieldset
      title={
        <div className={styles.accordion_title}>
          <Typography variant="subtitle1">
            {t('integrations.con_status.title')}{' '}
          </Typography>
          <StatusBadge
            label={t('pages.unauthorized')}
            variant="warning"
            withIcon
          />
        </div>
      }
      subtitle={t('integrations.sunco.disconnected')}
      primaryButton={{
        children: t('integrations.reauthorize'),
        onClick: onOAuthClick,
      }}
      fullContentWidth
    />
  );
};
