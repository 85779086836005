interface Props {
  name: string;
  children: React.ReactNode;
}

import Typography from '@mui/material/Typography';

import styles from './ThemeButton.module.scss';

const ThemeButton = ({ name, children }: Props) => {
  return (
    <div className={styles.wrapper}>
      {children}
      <Typography
        fontFamily="var(--font-mono)"
        fontSize="var(--space-10)"
        fontWeight={400}
        letterSpacing={0}
        lineHeight="var(--space-16)"
      >
        {name}
      </Typography>
    </div>
  );
};

export default ThemeButton;
