import Typography from '@mui/material/Typography';

import { capitalizeFirstLetter } from '@/util/util';

import { TimeElementType } from '../MessageArea';

import styles from './TimeElement.module.scss';

export const TimeElement = ({ created }: Partial<TimeElementType>) => {
  return (
    <span className={styles.time} key={created}>
      <span className={styles.line}>
        <span className={styles.bar} />
        <Typography
          variant="label-semi-bold"
          color="var(--text-default-gray-light)"
          className={styles.text}
        >
          {capitalizeFirstLetter(created)}
        </Typography>
        <span className={styles.bar} />
      </span>
    </span>
  );
};
