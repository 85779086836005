import { createSlice } from '@reduxjs/toolkit';

import { PermissionsState } from '@/models/permissions';

const defaultState: PermissionsState = {
  permissions: null,
  isUserOwner: false,
};

const slice = createSlice({
  name: 'permissions',
  initialState: defaultState,
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setAccountRole: (state, action) => {
      state.isUserOwner = action.payload === 'owner';
    },
  },
});

export const { setPermissions, setAccountRole } = slice.actions;

export default slice.reducer;
