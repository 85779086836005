import React from 'react';

import Actions from './Actions';
import BreadCrumbs from './BreadCrumbs';
import HeaderBody from './HeaderBody';
import { Menu } from './Menu';
import Navigation from './Navigation';
import Title from './Title';

import styles from './Header.module.scss';

interface Props {
  children: React.ReactNode;
}

const Header = ({ children }: Props) => {
  return <div className={styles.header}>{children}</div>;
};
Header.Actions = Actions;
Header.BreadCrumbs = BreadCrumbs;
Header.Actions = Actions;
Header.Body = HeaderBody;
Header.Title = Title;
Header.Navigation = Navigation;
Header.Menu = Menu;

export default Header;
