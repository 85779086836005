import Typography from '@mui/material/Typography';

import { SetVariablesAction } from '@/models/action';
import { addDollarSign } from '@/util/util';

const Reset = ({ action }: { action: SetVariablesAction }) => {
  const { variables } = action;
  // The second condition prevents the text from showing up when the user
  // has opened the dropdown and has not yet selected a variable
  return variables?.length && variables?.[0]?.variable ? (
    <Typography color="var(--text-default-gray)" variant="label-regular">
      {addDollarSign(variables?.[0]?.variable)}
    </Typography>
  ) : null;
};

export default Reset;
