import { Icon } from '@/models/icon';

const GoogleSheet = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H12.586C12.8512 3.00006 13.1055 3.10545 13.293 3.293L18.707 8.707C18.8946 8.89449 18.9999 9.1488 19 9.414V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="8" y="10" width="3" height="2" rx="0.5" fill={color} />
      <rect x="12" y="10" width="4" height="2" rx="0.5" fill={color} />
      <rect x="8" y="13" width="3" height="2" rx="0.5" fill={color} />
      <rect x="8" y="16" width="3" height="2" rx="0.5" fill={color} />
      <rect x="12" y="13" width="4" height="2" rx="0.5" fill={color} />
      <rect x="12" y="16" width="4" height="2" rx="0.5" fill={color} />
    </svg>
  );
};

export default GoogleSheet;
