import { useCallback, useState } from 'react';

import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

export const useEmojiPicker = (editor: ReactEditor) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiButtonClick = useCallback(() => {
    setShowEmojiPicker(!showEmojiPicker);
  }, [showEmojiPicker]);

  const handleEmojiClick = useCallback(
    async (emojiObject, _event) => {
      const { emoji } = emojiObject;
      ReactEditor.focus(editor);

      // Save the current selection
      const { selection } = editor;

      if (selection) {
        // Insert the emoji at the current selection
        editor.insertText(emoji);

        // Get the point after the inserted emoji
        const newPoint = Editor.after(editor, selection.anchor, {
          unit: 'character',
        });

        // Set the new selection to the new point
        if (newPoint) {
          Transforms.setSelection(editor, {
            anchor: newPoint,
            focus: newPoint,
          });
        }
      }

      // workaround to prevent react error: Can't perform a React state update on an unmounted component
      setTimeout(() => setShowEmojiPicker(false), 200);
    },
    [editor]
  );

  return {
    showEmojiPicker,
    handleEmojiButtonClick,
    handleEmojiClick,
  };
};
