import { useCallback, useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useAccount } from '@/hooks/useAccount';
import { usePreferences } from '@/hooks/usePreferences';
import useUser from '@/hooks/useUser';
import { useUserRoles } from '@/hooks/useUserRoles';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import { selectAllAgents } from '@/redux/presence/selectors';
import { selectDeskId } from '@/redux/session/selectors';

import { useConversationsCounts } from './useConversationsCounts';
import { useNavMenu } from './useNavMenu';

export const useNav = () => {
  const { slug } = useAccount();
  const navigate = useNavigate();
  const deskId = useSelector(selectDeskId);
  const { user } = useUser();

  const { getUserDepartments } = useUserRoles(user?.user_id);
  const userDepartments = getUserDepartments(deskId);

  const { agentsExceptUser: accountAgents } = useAccount();
  const presenceAgents = useSelector(selectAllAgents);

  const agents = useMemo(() => {
    return accountAgents?.map((agent) =>
      presenceAgents?.find((a) => a?.agent_id === agent.agent_id)
    );
  }, [accountAgents, presenceAgents]);

  const { departments } = useDepartments(deskId);

  const { counts, agentCounts, departmentCounts } =
    useConversationsCounts(deskId);

  const { accountUserPrefs, updateAccountUserPreferences } = usePreferences();

  // pinned Ids from user prefernces
  const pinnedAgentsIds = useMemo(() => {
    return accountUserPrefs?.desks?.[deskId]?.pinned_agents || [];
  }, [accountUserPrefs, deskId]);

  const pinnedDepartmentsIds = useMemo(() => {
    return accountUserPrefs?.desks?.[deskId]?.pinned_departments || [];
  }, [accountUserPrefs, deskId]);

  // filter agents from `agents` that exist on accountUserPrefs pinned_agents
  const shouldComputeFavoriteAgents = accountUserPrefs?.desks?.[deskId];

  const computeFavoriteAgents = useCallback(() => {
    const filteredAgents = agents.filter((presence) =>
      pinnedAgentsIds?.some((id) => id === presence.agent_id)
    );

    return filteredAgents;
  }, [agents, pinnedAgentsIds]);

  const favoriteAgents = useMemo(
    () => (shouldComputeFavoriteAgents ? computeFavoriteAgents() : []),
    [shouldComputeFavoriteAgents, computeFavoriteAgents]
  );

  const favoriteDepartments = useMemo(() => {
    if (userDepartments) {
      return uniqBy(
        [
          ...userDepartments,
          ...(departments?.filter((department) =>
            pinnedDepartmentsIds?.some((id) => id === department.department_id)
          ) ?? []),
        ],
        'department_id'
      );
    }
    return [];
  }, [departments, pinnedDepartmentsIds, userDepartments]);

  // callbacks
  const onAgentPinClick = useCallback(
    (key: string) => {
      const index = pinnedAgentsIds.findIndex((id) => id === key);

      let newPinnedAgents;
      if (index > -1) {
        // If found, remove the agent
        newPinnedAgents = pinnedAgentsIds.filter(
          (agentKey) => agentKey !== key
        );
      } else {
        // If not found, add the new agent
        newPinnedAgents = [...pinnedAgentsIds, key];
      }

      const newDesks = {
        [deskId]: {
          ...accountUserPrefs.desks?.[deskId],
          pinned_agents: newPinnedAgents,
        },
      };

      updateAccountUserPreferences({
        ...accountUserPrefs,
        desks: newDesks,
      });
    },
    [accountUserPrefs, deskId, pinnedAgentsIds, updateAccountUserPreferences]
  );

  const onDepartmentPinClick = useCallback(
    (key: string) => {
      const index = pinnedDepartmentsIds.findIndex((id) => id === key);

      let newPinnedDepartments;
      if (index > -1) {
        newPinnedDepartments = pinnedDepartmentsIds.filter(
          (departmentKey) => departmentKey !== key
        );
      } else {
        newPinnedDepartments = [...pinnedDepartmentsIds, key];
      }

      const newDesks = {
        [deskId]: {
          ...accountUserPrefs.desks?.[deskId],
          pinned_departments: newPinnedDepartments,
        },
      };

      updateAccountUserPreferences({
        ...accountUserPrefs,
        desks: newDesks,
      });
    },
    [
      accountUserPrefs,
      deskId,
      pinnedDepartmentsIds,
      updateAccountUserPreferences,
    ]
  );

  // menus
  const { mainMenu: conversationsMenu } = useNavMenu({
    counts,
    onItemClick: (key: string) => {
      navigate(`/${slug}/chats/${deskId}/conversations?category=${key}`);
    },
  });

  const { agentMenu: teammatesMenu } = useNavMenu({
    agents,
    agentCounts,
    onPinClick: onAgentPinClick,
  });

  const handleTeammateItemClick = (key: string) => {
    key === 'brain'
      ? navigate(`/${slug}/chats/${deskId}/conversations?brain_id=any`)
      : navigate(`/${slug}/chats/${deskId}/conversations?agent_id=${key}`);
  };

  const { agentMenu: filteredTeammatesMenu } = useNavMenu({
    agents: favoriteAgents,
    agentCounts,
    onItemClick: handleTeammateItemClick,
  });

  const { departmentsMenu } = useNavMenu({
    departments,
    onPinClick: onDepartmentPinClick,
  });

  const { departmentsMenu: filteredDepartmentsMenu } = useNavMenu({
    departments: favoriteDepartments,
    departmentCounts,
    onItemClick: (key: string) => {
      navigate(`/${slug}/chats/${deskId}/conversations?department_id=${key}`);
    },
  });

  return {
    conversationsMenu,
    filteredTeammatesMenu,
    filteredDepartmentsMenu,
    teammatesMenu,
    departmentsMenu,
    pinnedAgents: pinnedAgentsIds,
    pinnedDepartments: pinnedDepartmentsIds,
  };
};
