import { ReactNode, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { SocketContext } from '@/contexts/rtm';
import { useRtmSetup } from '@/hooks/rtm/useRtmSetup';
import { SocketEvent } from '@/models/rtm';
import { selectAccountId } from '@/redux/session/selectors';

type RtmProviderProps = {
  children: ReactNode;
};

const RtmProvider = ({ children }: RtmProviderProps) => {
  const { socket, isConnected } = useRtmSetup();
  const account_id = useSelector(selectAccountId);

  useEffect(() => {
    if (account_id && isConnected) {
      socket.emit(SocketEvent.subscribe_account, { account_id });
    }
    return () => {
      if (account_id && isConnected) {
        socket.emit(SocketEvent.unsubscribe_account, { account_id });
      }
    };
  }, [account_id, isConnected, socket]);

  return (
    <SocketContext.Provider value={{ socket, isConnected }}>
      {children}
    </SocketContext.Provider>
  );
};

export default RtmProvider;
