import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Clock2 = forwardRef<SVGSVGElement, Icon>(
  ({ size = 16, color = 'currentColor', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={size}
        height={size}
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.5 4.889V8l-2.333 2.333M.5 8a7 7 0 1 0 14 0 7 7 0 0 0-14 0Z"
        />
      </svg>
    );
  }
);

Clock2.displayName = 'Clock2';

export default Clock2;
