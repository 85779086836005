import { memo, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Callout from '@/components/atoms/Callout/Callout';

import Modal from '../Modal';

type Props = {
  title: string;
  subtitle: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onConfirm: (() => Promise<void>) | (() => void);
  callout?: React.ComponentPropsWithoutRef<typeof Callout>;
  danger?: boolean;
};

function isPromise(promise) {
  return !!promise && typeof promise.then === 'function';
}

function ModalConfirmation({
  title,
  subtitle,
  callout,
  onConfirm,
  primaryButtonText,
  secondaryButtonText,
  danger = false,
}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const primaryText = primaryButtonText || t('common.confirm');
  const submitIntermediary = useCallback(async () => {
    try {
      setIsLoading(true);
      if (isPromise(onConfirm)) {
        await onConfirm();
      } else {
        onConfirm();
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }, [onConfirm]);

  return (
    <Modal
      title={title}
      subtitle={subtitle}
      onPrimarySubmit={submitIntermediary}
      primaryButtonText={primaryText}
      secondaryButtonText={secondaryButtonText}
      isSubmitting={isLoading}
      danger={danger}
    >
      {callout?.children ? <Callout {...callout} /> : <></>}
    </Modal>
  );
}

export default memo(ModalConfirmation);
