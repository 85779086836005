import { ReactElement } from 'react';

import DeleteIcon from '@mui/icons-material/Clear';
import isEmpty from 'lodash/isEmpty';

import Chip from '@/components/atoms/Chip/Chip';
import { preventClickThrough } from '@/util/util';

import styles from './Chips.module.scss';

interface ChipsProps {
  onClick: (tag: string) => void;
  icon: ReactElement;
  chips: string[];
  size?: 'small' | 'medium';
  customVariant?: 'neutral' | 'highlighted' | 'subdued';
  hideDeleteIcon?: boolean;
}

const Chips = ({
  size = 'medium',
  customVariant = 'highlighted',
  chips = [],
  icon,
  onClick,
  hideDeleteIcon = false,
}: Partial<ChipsProps>) => {
  const handleOnDelete =
    (chip: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      preventClickThrough(e);
      onClick(chip);
    };

  return (
    <div className={styles.chips}>
      {!isEmpty(chips) &&
        chips.map((chip: string, index: number) => (
          <Chip
            key={chip}
            customVariant={customVariant}
            size={size}
            onDelete={!hideDeleteIcon ? handleOnDelete(chip) : undefined}
            deleteIcon={
              <button data-testid={`delete-chip-${index}`} tabIndex={0}>
                {icon || <DeleteIcon />}
              </button>
            }
            label={chip}
          />
        ))}
    </div>
  );
};

export default Chips;
