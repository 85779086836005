import { memo, useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import ExclamationMark from '@/components/atoms/Icons/Alert';
import Tick from '@/components/atoms/Icons/Tick';

import {
  getPerformanceKey,
  MAX_THRESHOLD_PERCENTAGE,
  MIN_THRESHOLD_PERCENTAGE,
  Performance,
} from './utils';

import styles from './PerformanceBar.module.scss';

interface Props {
  phrases: number;
  optimalPhrases: number;
}

export const PerformanceBar = memo(({ phrases, optimalPhrases }: Props) => {
  const { t } = useTranslation();

  const percentage = useMemo(
    () => (phrases / optimalPhrases) * 100,
    [optimalPhrases, phrases]
  );

  const getBarText = useCallback(
    (percentage: number, optimalPhrases: number): string => {
      const performance = getPerformanceKey(percentage);
      return performance
        ? t(`intent.performance_bar.${performance}`, { 0: optimalPhrases })
        : '';
    },
    [t]
  );

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography
          variant="body-semi-bold"
          color="var(--text-default-gray)"
          className={styles.title}
        >
          {t('intent.performance_bar.title')}
        </Typography>
        <HelpTooltip
          tooltip={t('intent.performance_bar.tooltip', { 0: optimalPhrases })}
        />
      </div>
      <div className={styles.barWrapper}>
        <div className={styles.primaryBar}>
          <div
            className={cn(styles.inner, styles[getPerformanceKey(percentage)])}
            style={{
              width: `${
                percentage >= MIN_THRESHOLD_PERCENTAGE ? 100 : percentage * 2
              }%`,
            }}
          >
            {percentage <= MAX_THRESHOLD_PERCENTAGE && (
              <span className={styles.tick}>
                <Tick
                  size={14}
                  backgroundColor={
                    percentage >= MIN_THRESHOLD_PERCENTAGE
                      ? 'var(--icon-default-blue)'
                      : 'var(--border-default-gray)'
                  }
                />
              </span>
            )}
          </div>
        </div>

        {getPerformanceKey(percentage) === Performance.TOO_MANY && (
          <div className={styles.secondary}>
            <span className={styles.warning}>
              <ExclamationMark size={14} />
            </span>
          </div>
        )}
      </div>

      <Typography variant="label-regular" color="var(--text-default-gray)">
        {getBarText(percentage, optimalPhrases)}.
      </Typography>
    </div>
  );
});

PerformanceBar.displayName = 'PerformanceBar';
