import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ConnectionStatus from '@/components/atoms/Accordion/templates/ConnectionStatusAccordion';
import ActiveFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/ActiveFieldset';
import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import AdvancedSettings from '@/components/organisms/Integrations/Web/Accordion/AdvancedSettings/AdvancedSettings';
import { useAccordion } from '@/hooks/useAccordion';
import { AccordionType, FacebookIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import ManualSettings from './Accordion/ManualSettings/ManualSettings';
import StartBehavior from './Accordion/StartBehavior/StartBehavior';
import WhitelistDomains from './Accordion/WhitelistDomains/WhitelistDomains';
import MetaInfo from './MetaInfo';

import styles from './Settings.module.scss';

interface Props {
  isManual: boolean;
}

const Settings = ({ isManual }: Props) => {
  const { toggleAccordion, integration, registerAccordion, accordions } =
    useAccordion<FacebookIntegration>();
  const stateRef = useRef(null);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const { t } = useTranslation();

  const channelType = integration?.type;
  const isConfigured =
    integration?.config?.access_token && integration?.config?.page_id;

  const isAccessTokenValid = integration?.status !== 'invalid-authentication';
  return (
    <div className={styles.container}>
      <div>
        <ConnectionStatus
          type={AccordionType.VERIFICATION_STATUS}
          toggleAccordion={toggleAccordion}
          status={integration?.status}
          conSettingsRef={
            accordions?.current[AccordionType.CONNECTION_SETTINGS]?.ref
          }
          stateRef={stateRef.current}
          registerAccordion={registerAccordion}
        />
        {isManual && (
          <ManualSettings
            type={AccordionType.CONNECTION_SETTINGS}
            registerAccordion={registerAccordion}
            integration={integration}
            toggleAccordion={toggleAccordion}
          />
        )}
        {channelType === 'facebook' && (
          <>
            <StartBehavior
              type={AccordionType.START_BEHAVIOR}
              registerAccordion={registerAccordion}
              integration={integration}
              toggleAccordion={toggleAccordion}
            />
            <WhitelistDomains
              type={AccordionType.WHITELIST_DOMAINS}
              registerAccordion={registerAccordion}
              integration={integration}
              toggleAccordion={toggleAccordion}
            />
          </>
        )}
        <AdvancedSettings
          type={AccordionType.ADVANCED_SETTINGS}
          registerAccordion={registerAccordion}
          integration={integration}
          toggleAccordion={toggleAccordion}
        />
        <div ref={stateRef}>
          <ActiveFieldset
            integration={integration}
            title={t('integrations.status.title')}
            subtitle={t('integrations.status.subtitle')}
          />
        </div>
        <DeleteFieldset integration={integration} disabled={!canWrite} />
      </div>
      {isConfigured && isAccessTokenValid && (
        <div className={styles.infoContainer}>
          <MetaInfo integration={integration} />
        </div>
      )}
    </div>
  );
};

export default Settings;
