import cn from 'classnames';

import styles from './AnalyticsLoadingIcon.module.scss';

interface Props {
  size?: 'large';
}
const AnalyticsLoadingIcon = ({ size }: Props) => {
  return (
    <div
      data-testid="analytics-loading-icon"
      className={cn(styles.container, styles[size])}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default AnalyticsLoadingIcon;
