import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IntegrationType } from '@/models/integration';
import { OnboardingState } from '@/modules/onboarding/model';

type ActionType = 'ADD' | 'REMOVE';

interface IntegrationPayload {
  type: ActionType;
  integration: IntegrationType;
}

export const defaultState: OnboardingState = {
  goal: { brain: false, collections: false },
  brainId: null,
  language: '',
  integrations: ['web'],
  isCollectionsFlow: true,
  integrationId: null,
  url: null,
  createdBrainId: null,
  datasourceType: null,
  deskId: null,
};

const slice = createSlice({
  name: 'onboarding',
  initialState: defaultState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },

    setGoal: (state, action) => {
      state.goal = action.payload;
    },

    setTemplateBrainId: (state, action) => {
      state.brainId = action.payload;
    },

    setIsCollectionsFlow: (state, action) => {
      state.isCollectionsFlow = action.payload;
    },

    updateIntegrations: (state, action: PayloadAction<IntegrationPayload>) => {
      const { type, integration } = action.payload;

      if (type === 'ADD') {
        const exists = state.integrations.some(
          (existingIntegration) => existingIntegration === integration
        );
        if (!exists) {
          state.integrations.push(integration);
        }
      } else if (type === 'REMOVE') {
        state.integrations = state.integrations.filter(
          (i) => i !== integration
        );
      }
    },
    resetIntegrations: (state) => {
      state.integrations = ['web'];
    },

    setIntegrationId: (state, action) => {
      state.integrationId = action.payload;
    },

    setDeskId: (state, action) => {
      state.deskId = action.payload;
    },

    setUrl: (state, action) => {
      state.url = action.payload;
    },

    setCreatedBrainId: (state, action) => {
      state.createdBrainId = action.payload;
    },

    setDatasourceType: (state, action) => {
      state.datasourceType = action.payload;
    },

    // reset state. pass a specific key to reset only that key, if not passed, reset all
    reset: (state, action) => {
      if (action.payload) {
        state[action.payload] = defaultState[action.payload];
      } else {
        Object.assign(state, defaultState);
      }
    },
  },
});

export const {
  setLanguage,
  setGoal,
  setTemplateBrainId,
  setIsCollectionsFlow,
  reset,
  updateIntegrations,
  setIntegrationId,
  setCreatedBrainId,
  setUrl,
  setDatasourceType,
  setDeskId,
  resetIntegrations,
} = slice.actions;

export default slice.reducer;
