import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TileCreation from '@/components/organisms/Tile/TileCreation/TileCreation';
import useCollections from '@/hooks/useCollections';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { getPermissions } from '@/redux/permissions/selectors';

import { getDisabledMessage } from './util';

export const CollectionCreateTile = ({ onClick }) => {
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );
  const { billingState } = useBilling();

  const {
    collections,
    maxCollections,
    maxCollectionsReached,
    totalFragmentsReached,
  } = useCollections();

  const { t } = useTranslation();

  const canCreateCollections =
    !maxCollectionsReached && canWrite && !totalFragmentsReached;

  if (canWrite && collections) {
    return (
      <TileCreation
        title={t('collections.create_collection')}
        limitReached={maxCollectionsReached || totalFragmentsReached}
        subtitle={
          canCreateCollections ? (
            <Trans
              i18nKey="limits.create_resource"
              values={{
                0: collections?.length,
                1: maxCollections,
                2: 'collections',
              }}
            />
          ) : (
            getDisabledMessage(
              maxCollectionsReached,
              billingState?.plan_name,
              totalFragmentsReached,
              t
            )
          )
        }
        onCreateClick={onClick}
      />
    );
  }
  return null;
};
