import { Icon } from '@/models/icon';

const CursorPointer = ({
  size = 16,
  color = 'var(--icon-default-blue)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
    >
      <path
        fill="#fff"
        d="m9.681 13.361-.18-8.997-6.77 5.845 4.098.174L9.68 13.36Z"
      />
      <path
        fill={color}
        d="m2.13 10.507 8.503-7.34A.437.437 0 0 1 11.1 3.1c.155.07.257.226.26.398l.226 11.298a.446.446 0 0 1-.441.455.439.439 0 0 1-.317-.136l-2.405-2.511-1.392 3.134a.441.441 0 0 1-.583.224l-1.765-.8a.444.444 0 0 1-.222-.59l.897-2.018c.1-.224.36-.325.583-.224.222.101.321.365.221.59l-.715 1.611.96.436 1.355-3.053-.394-.411-4.97-.21a.442.442 0 0 1-.399-.3.449.449 0 0 1 .13-.485Zm5.153.1 1.399-3.152a.44.44 0 0 1 .583-.224c.222.101.321.365.222.59l-1.4 3.15 2.594 2.708-.184-9.223-6.941 5.992 3.727.158ZM11.636 2.306a.44.44 0 0 0 .403-.264l.586-1.325c.1-.225 0-.488-.222-.589a.44.44 0 0 0-.583.225l-.586 1.325a.447.447 0 0 0 .402.628ZM13.061 3.83c.053 0 .106-.01.157-.03l1.342-.517a.447.447 0 0 0 .255-.576.44.44 0 0 0-.57-.257l-1.341.518a.447.447 0 0 0 .157.862ZM8.054 3.688a.447.447 0 0 0 .18-.852l-1.31-.593a.44.44 0 0 0-.583.224c-.1.225 0 .489.222.59l1.311.592c.059.026.12.039.18.039ZM14.312 6.518a.447.447 0 0 0 .18-.852l-1.31-.593a.44.44 0 0 0-.583.225c-.1.224 0 .488.222.588l1.311.593c.059.027.12.04.18.04ZM9.56 2.247a.447.447 0 0 0 .412-.605L9.462.288a.44.44 0 0 0-.57-.258.447.447 0 0 0-.254.576l.512 1.356a.441.441 0 0 0 .412.286Z"
      />
    </svg>
  );
};

export default CursorPointer;
