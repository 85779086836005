import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router';

import Layout from '@/components/templates/Layout/Layout';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import useDesks from '@/hooks/useDesks';
import { setDeskId } from '@/redux/session/actions';
import { selectDeskId } from '@/redux/session/selectors';

import CollectionRouter from './CollectionRouter';
import Collections from './Collections';

const CollectionsRouter = () => {
  const { desks } = useDesks();
  const dispatch = useDispatch();
  const deskId = useSelector(selectDeskId);

  useEffect(() => {
    if (!deskId && !isEmpty(desks)) {
      dispatch(setDeskId(desks[0]?.desk_id));
    }
  }, [deskId, desks, dispatch]);

  return (
    <Layout>
      <PermissionsWrapper module="collections">
        <Routes>
          <Route path="/" element={<Collections />} />
          <Route path="/:collectionId/*" element={<CollectionRouter />} />
        </Routes>
      </PermissionsWrapper>
    </Layout>
  );
};

export default CollectionsRouter;
