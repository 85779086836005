import { useCallback } from 'react';

import cn from 'classnames';
import { useDrag } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ConditionLine from '@/components/atoms/Icons/ConditionLine/ConditionLine';
import ActionList from '@/components/organisms/Dialogs/Action/ActionList';
import ActionPlaceholder from '@/components/organisms/Dialogs/Action/Placeholder/Placeholder';
import ConditionBox from '@/components/organisms/Dialogs/Node/Conditions/ConditionBox/ConditionBox';
import ReorderPlaceholder from '@/components/organisms/Dialogs/Node/Conditions/ReorderPlaceholder/ReorderPlaceholder';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { Condition as ConditionType, Nodes } from '@/models/node';
import { clearDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectDialogAlerts } from '@/redux/dialogAlerts/selectors';
import { TYPES, isFinalAction } from '@/redux/dialogs/helper';
import { popModal, pushModal } from '@/redux/modals/actions';
import {
  removeCondition,
  selectCondition,
  reorderCondition,
} from '@/redux/nodes/actions';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import styles from './Condition.module.scss';

interface ConditionProps {
  condition: ConditionType;
  nodeId: string;
  name: string;
  match: string;
  index: number;
  id: string;
  position: string;
  firstActionType: string;
  isCollapsed: boolean;
  setActiveConditionIndex: (index: number) => void;
  conditionSize?: number;
  requisiteIndex?: string;
}
interface RootState {
  nodes: Nodes;
}

type DragItem = {
  name: string;
  index: number;
  type: string;
  nodeId: string;
};

function Condition({
  condition,
  name,
  match,
  index,
  id,
  nodeId,
  position,
  isCollapsed,
  setActiveConditionIndex,
  conditionSize,
}: ConditionProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSelected = useSelector(
    ({ nodes }: RootState) =>
      nodeId === nodes.selectedNodeId && index === nodes.selectedConditionIndex
  );

  const showActionPlaceholder = !isFinalAction(
    condition.actions[condition.actions.length - 1]?.type
  );
  const dialogErrors = useSelector(selectDialogAlerts);

  const onClick = useCallback(
    (e) => {
      preventClickThrough(e);
      dispatch(selectCondition({ nodeId, index }));
      if (isCollapsed) {
        setActiveConditionIndex(index);
      }
    },
    [dispatch, index, isCollapsed, nodeId, setActiveConditionIndex]
  );

  const clearConditionNestedActionsErrors = useCallback(() => {
    for (const action of condition.actions) {
      dispatch(clearDialogAlerts({ id: action.action_id }));
    }
  }, [condition.actions, dispatch]);

  // Deletes the condition
  const onDelete = useCallback(() => {
    const deleteProps = {
      subtitle: <Trans i18nKey="dialog.delete_condition" values={[name]} />,
      confirm: false,
      onDelete: () => {
        // If a condition is being deleted, set the active condition to the first condition
        setActiveConditionIndex(0);
        dispatch(removeCondition({ nodeId, index }));

        if (dialogErrors.length) {
          dispatch(clearDialogAlerts({ nodeId, index }));
          clearConditionNestedActionsErrors();
        }

        dispatch(popModal());
      },
      secondaryButtonText: t('common.cancel'),
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [
    clearConditionNestedActionsErrors,
    dialogErrors.length,
    dispatch,
    index,
    name,
    nodeId,
    setActiveConditionIndex,
    t,
  ]);

  const onKeyDown = useCallback(
    (e) => {
      if (isKeyEnter(e)) {
        dispatch(selectCondition({ nodeId, index }));
      } else if (e.key === 'Delete') {
        onDelete();
      }
    },
    [dispatch, index, nodeId, onDelete]
  );

  const handleMove = useCallback(
    (item: DragItem, dropIndex) => {
      if (item.index === dropIndex) {
        return;
      }
      dispatch(reorderCondition({ dragIndex: item.index, dropIndex, nodeId }));
    },
    [dispatch, nodeId]
  );

  const [{ isDragging }, dragRef] = useDrag({
    item: {
      name: `condition-${id}-${index}`,
      index,
      nodeId,
    },
    type: TYPES.CONDITION_BOX,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  return (
    <div className={styles.wrapper}>
      <ReorderPlaceholder
        type="condition"
        accept={TYPES.CONDITION_BOX}
        index={index}
        onMove={handleMove}
        position="left"
        nodeId={nodeId}
        isCollapsed={isCollapsed}
      />
      <section
        id={condition.condition_id}
        className={cn({
          [styles['condition-box']]: true,
          [styles['condition-box--collapsed']]: isCollapsed,
          [styles['condition-box--is-dragging']]: isDragging,
        })}
      >
        <ConditionLine
          direction={position}
          isShrinked={isCollapsed}
          conditionSize={conditionSize}
        />
        <ConditionBox
          condition={condition}
          dragRef={dragRef}
          onKeyDown={onKeyDown}
          onClick={onClick}
          isSelected={isSelected}
          match={match}
          name={name}
          id={id}
          index={index}
          isCollapsed={isCollapsed}
          onDelete={onDelete}
        />
        {!isCollapsed && (
          <>
            <ActionList nodeId={nodeId} conditionIndex={index} />
            {showActionPlaceholder && (
              <ActionPlaceholder
                nodeId={nodeId}
                show={!condition.actions}
                conditionIndex={index}
                isLast
              />
            )}
          </>
        )}
      </section>
      <ReorderPlaceholder
        type="condition"
        accept={TYPES.CONDITION_BOX}
        index={index}
        onMove={handleMove}
        nodeId={nodeId}
        position="right"
        isCollapsed={isCollapsed}
      />
    </div>
  );
}

export default Condition;
