import React from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

interface RowInfiniteLoaderProps {
  children: (index: number, style: React.CSSProperties) => React.ReactNode;
  itemCount: number;
  loadMoreItems: (startIndex: number, stopIndex: number) => void;
  isItemLoaded: (index: number) => boolean;
  getItemSize: (index: number) => number;
  className?: string;
}

export const RowInfiniteLoader = ({
  children,
  itemCount,
  loadMoreItems,
  isItemLoaded,
  getItemSize,
  className,
}: RowInfiniteLoaderProps) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <VariableSizeList
              className={className}
              estimatedItemSize={100}
              height={height}
              itemCount={itemCount}
              itemSize={getItemSize}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={width}
            >
              {({ index, style }) => <>{children(index, style)}</>}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};
