import { shallowEqual, useSelector } from 'react-redux';

import Node from '@/components/organisms/Dialogs/Node/Node';
import NodePlaceholder from '@/components/organisms/Dialogs/Node/Placeholder/Placeholder';
import { RootState } from '@/models/state';
import { selectBranchesByNodeId } from '@/redux/dialogs/selectors';

import BranchIntersection from './BranchIntersection';
import BranchLine from './BranchLine';

import styles from './BranchList.module.scss';

function getAlignment(value, branchSize) {
  if (branchSize > 2 || branchSize === 2) {
    if (value === 0) {
      return 'left';
    } else if (value === branchSize - 1) {
      return 'right';
    }
  }
  return 'center';
}
function BranchList({ nodeId }: { nodeId: string }) {
  const branches = useSelector(
    (state: RootState) => selectBranchesByNodeId(state, nodeId),
    shallowEqual
  );
  if (!branches || branches.length === 0) {
    return <NodePlaceholder parentId={nodeId} />;
  }
  return (
    <>
      <div className={styles.branchList}>
        <BranchIntersection />
        {branches.map((id, index) => (
          <div key={id} className={styles.wrapper}>
            <BranchLine
              direction={getAlignment(index, branches?.length || 0)}
              branches={branches?.length}
            />
            <Node
              key={id}
              internal
              nodeId={id}
              showBranch={index === branches.length - 1}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default BranchList;
