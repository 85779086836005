import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Autocomplete from '@/components/atoms/AutoComplete/AutoComplete';
import Input from '@/components/atoms/Input/Input';
import { useRoles } from '@/hooks/useRoles';
import { useTeamRoles } from '@/hooks/useTeamRoles';
import { useTeams } from '@/hooks/useTeams';
import { OptionBase } from '@/models/common';
import { Role } from '@/models/role';
import { Team } from '@/models/team';
import { popModal } from '@/redux/modals/actions';
import { submitWithTrimming } from '@/util/util';
import { invitationRules, errorMessage, brainRules } from '@/util/validator';

import Modal from '../Modal';
import FooterWarning from '../ModalRoleEdit/FooterWarning';

interface Form {
  roles: OptionBase[];
  team_name: string;
  team_description: string;
}

interface Props {
  team: Team;
}

const ModalTeam = ({ team }: Props) => {
  const { t } = useTranslation();
  const { team_id, name, description } = team || {};

  const formMethods = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      roles: [],
      team_name: name,
      team_description: description,
    },
  });

  const {
    control,
    setValue,
    register,
    watch,
    formState: { errors },
  } = formMethods;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { roles, availableRoles } = useRoles();
  const { createTeam, updateTeam } = useTeams();

  const { removeRole, assignRoles, roles: teamRoles } = useTeamRoles(team_id);

  useEffect(() => {
    if (teamRoles) {
      const newOptions = teamRoles?.map((r) => ({
        value: r.role_id,
        label: roles.find((role) => r.role_id === role.role_id).name,
      }));
      setValue('roles', newOptions);
    }
  }, [roles, teamRoles, setValue]);

  const onSubmit = useCallback(
    async (form: Form) => {
      setIsLoading(true);
      const newRoleids = form.roles.map((r) => r.value) as string[];

      const newTeam = {
        name: form.team_name,
        description: form.team_description,
        role_ids: newRoleids,
        team_id: team?.team_id,
      };
      if (team) {
        if (
          team.description !== form.team_description ||
          team.name !== form.team_name
        ) {
          updateTeam(newTeam, {
            onSuccess: () => {
              dispatch(popModal());
            },
          });
        }
        const addedRoles = newRoleids.filter(
          (role) => teamRoles?.findIndex((r) => r.role_id === role) === -1
        );

        if (addedRoles.length > 0) {
          await assignRoles({
            role_ids: newRoleids,
            team_id: team_id,
          });
        }
        const removedRoles = teamRoles?.filter(
          (role) => !newRoleids.includes(role.role_id)
        );

        if (removedRoles.length > 0) {
          await Promise.race(
            removedRoles.map(async (role: Role) => {
              return removeRole({
                team_id: team.team_id,
                role_id: role.role_id,
              });
            })
          );
        }

        dispatch(popModal());
      } else {
        createTeam(newTeam, {
          onSuccess: (resp) => {
            if (newTeam.role_ids.length > 0) {
              assignRoles(
                {
                  role_ids: newTeam.role_ids,
                  team_id: resp.team_id,
                },
                {
                  onSuccess: () => {
                    dispatch(popModal());
                  },
                }
              );
            }
          },
        });
      }
    },
    [
      assignRoles,
      createTeam,
      dispatch,
      removeRole,
      team,
      teamRoles,
      team_id,
      updateTeam,
    ]
  );

  const formRoles = watch('roles').map((r) => r.value);
  return (
    <Modal
      size="medium"
      title={team_id ? t('teams.edit') : t('teams.create_team')}
      onPrimarySubmit={submitWithTrimming(formMethods, onSubmit)}
      primaryButtonText={team_id ? t('common.save') : t('common.create')}
      isSubmitting={isLoading}
      autoFocus
      preventClose
    >
      <form autoComplete="off">
        <div className="input__container">
          <Input
            name="team_name"
            label={t('field.name')}
            register={register('team_name', brainRules.name)}
            errorMessage={errorMessage({
              field: errors.team_name,
              maxLength: brainRules.name.maxLength,
            })}
            placeholder={t('common.name_placeholder')}
            autoFocus
          />
        </div>
        <div className="input__container">
          <Input
            name="team_description"
            label={t('field.description')}
            register={register('team_description', brainRules.description)}
            errorMessage={errorMessage({
              field: errors.team_description,
              maxLength: brainRules.description.maxLength,
            })}
            placeholder={t('common.description_placeholder')}
          />
        </div>
        <div className="input__container">
          <Autocomplete<Form>
            control={control}
            size="medium"
            name="roles"
            label={t('teams.roles')}
            disabled={!roles}
            options={availableRoles}
            placeholder={t('roles.select')}
            multiple
            labelLarge
            disableAddNew
            variant="secondary"
            error={!!errors?.roles?.message}
            errorMessage={errorMessage({
              field: errors.roles,
            })}
            rules={!team_id ? invitationRules(t).roles : {}}
          />
        </div>
      </form>
      <FooterWarning allRoles={formRoles} roles={roles} />
    </Modal>
  );
};

export default ModalTeam;
