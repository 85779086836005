import { useState } from 'react';

import Button from '@/components/atoms/Button/Button/Button';

import fs from '..';

interface Props {
  label: string;
  title: string;
  subtitle: string;
  onClick: () => void;
  isLoading: boolean;
}

const CheckboxFieldset: React.FC<Props> = ({
  label,
  title,
  subtitle,
  onClick,
  isLoading,
}: Props) => {
  const [checked, setChecked] = useState(false);
  const onChange = () => {
    setChecked(!checked);
  };
  return (
    <fs.Fieldset title={title} subtitle={subtitle}>
      <fs.Checkbox onChange={onChange} checked={checked} label={label} />
      <fs.Footer>
        <fs.FooterAction>
          <Button
            variant="danger"
            disabled={!checked}
            onClick={onClick}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </fs.FooterAction>
      </fs.Footer>
    </fs.Fieldset>
  );
};

export default CheckboxFieldset;
