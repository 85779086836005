import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useDialogs from '@/hooks/useDialogs';
import useEntities from '@/hooks/useEntities';
import { OptionBase } from '@/models/common';
import { updateAction } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';

export const useGoogleSheet = () => {
  const dispatch = useDispatch();
  const brainId = useSelector(selectBrainId);
  const { getContextVariables } = useDialogs(brainId);
  const { entities } = useEntities(brainId);

  const updateReduxAction = (
    actionId: string,
    key: string,
    value: OptionBase<{ type: string }>[] | OptionBase
  ) => {
    if (key === 'inputs' && Array.isArray(value)) {
      dispatch(
        updateAction({
          actionId,
          action: {
            [key]: value,
          },
        })
      );
    } else if (key !== 'inputs') {
      dispatch(
        updateAction({
          actionId,
          action: {
            [key]: value,
          },
        })
      );
    }
  };

  const formattedEntities = useMemo(
    () =>
      entities?.map((e) => ({
        label: `${e.entity}`,
        value: `${e.entity}`,
        type: 'entity',
      })),
    [entities]
  );

  const contextVariables = useMemo(
    () => getContextVariables(),
    [getContextVariables]
  );

  const options = useMemo(
    () =>
      formattedEntities
        ? [...contextVariables, ...formattedEntities]
        : contextVariables,
    [formattedEntities, contextVariables]
  );

  return {
    updateReduxAction,
    options,
  };
};
