import { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import ConnectionStatus from '@/components/atoms/Accordion/templates/ConnectionStatusAccordion';
import ActiveFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/ActiveFieldset';
import SessionTimeoutFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/SessionTimeoutFieldset';
import { useAccordion } from '@/hooks/useAccordion';
import { AccordionType } from '@/models/integration';

import { SettingsAccordion } from './SettingsAccordion';
import { TicketWhenExpiredFieldset } from './TicketWhenExpiredFieldset';

export default function SuncoSettings({ integration }) {
  const { toggleAccordion, registerAccordion } = useAccordion();
  const { t } = useTranslation();
  const stateRef = useRef(null);
  return (
    <>
      <ConnectionStatus
        type={AccordionType.VERIFICATION_STATUS}
        registerAccordion={registerAccordion}
        status={integration?.status}
        toggleAccordion={toggleAccordion}
        stateRef={stateRef.current}
      />
      <SettingsAccordion
        type={AccordionType.SUNCO_SETTINGS}
        registerAccordion={registerAccordion}
        integration={integration}
        toggleAccordion={toggleAccordion}
      />
      <SessionTimeoutFieldset integration={integration} />
      <TicketWhenExpiredFieldset integration={integration} />
      <ActiveFieldset
        integration={integration}
        title={t('integrations.status.title')}
        subtitle={t('integrations.status.subtitle')}
      />
    </>
  );
}
