import { useCallback, useEffect } from 'react';

import cn from 'classnames';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Create from '@/components/atoms/Icons/Create';
import Delete from '@/components/atoms/Icons/Delete';
import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  FacebookIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { errorMessage, integrationRulesFacebook } from '@/util/validator';

import styles from './StartBehavior.module.scss';

type Form = {
  welcome_trigger_message: string;
  greeting_text: string;
  persistent_menu_options: { postback: string }[];
  persistent_menu_activation: boolean;
};

const FacebookStartBehavior = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, FacebookIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const { updateIntegration, updateStatus } =
    useIntegrations<FacebookIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );
  const dispatch = useDispatch();

  const getDefaultMenuValues = useCallback((defaultOptions: string[]) => {
    if (!defaultOptions) {
      return [{ postback: '' }];
    }
    return defaultOptions.reduce(
      function (accumulator, option) {
        const currentObject = { postback: option };
        accumulator.push(currentObject);
        return accumulator;
      },
      defaultOptions.length > 0 ? [] : [{ postback: '' }]
    );
  }, []);

  const formMethods = useForm<Form>({ mode: 'onSubmit' });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    trigger,
    control,
  } = formMethods;

  const [
    currentOptions,
    currentGreetingText,
    currentWelcomeTrigger,
    currentActivation,
  ] = useWatch({
    control,
    name: [
      'persistent_menu_options',
      'greeting_text',
      'welcome_trigger_message',
      'persistent_menu_activation',
    ],
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'persistent_menu_options',
  });

  useEffect(() => {
    reset({
      welcome_trigger_message:
        integration?.config?.welcome_trigger_message || '',
      greeting_text: integration?.config?.greeting_text || '',
      persistent_menu_options: getDefaultMenuValues(
        integration?.config?.persistent_menu_options
      ),
      persistent_menu_activation:
        integration?.config?.persistent_menu_options?.length > 0,
    });
  }, [
    getDefaultMenuValues,
    integration?.config?.greeting_text,
    integration?.config?.persistent_menu_options,
    integration?.config?.welcome_trigger_message,
    reset,
  ]);

  const handleCreate = useCallback(() => {
    if (canWrite) {
      append({ postback: '' });
    }
  }, [append, canWrite]);

  const handleDelete = useCallback(
    (index) => {
      if (canWrite) {
        remove(index);
      }
    },
    [canWrite, remove]
  );

  useEffect(() => {
    trigger(['greeting_text', 'welcome_trigger_message']);
  }, [
    trigger,
    currentWelcomeTrigger,
    currentGreetingText,
    currentActivation,
    currentOptions,
  ]);

  const handleUpdateIntegration = useCallback(() => {
    const {
      welcome_trigger_message,
      greeting_text,
      persistent_menu_options,
      persistent_menu_activation,
    } = getValues();
    const options = persistent_menu_options
      .map((option) => option.postback.trim())
      .filter((x) => x !== '');
    const uniqOptions = [...new Set(options)];
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration.config,
          welcome_trigger_message: welcome_trigger_message || undefined,
          greeting_text: greeting_text || undefined,
          persistent_menu_options:
            persistent_menu_activation && uniqOptions.length > 0
              ? uniqOptions
              : undefined,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, getValues, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  return (
    <>
      <Accordion
        title={t('integrations.facebook.start_behavior.title')}
        subtitle={t('integrations.facebook.start_behavior.subtitle')}
        footerText={t('integrations.facebook.start_behavior.footer_text')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
      >
        <TitleSubtitle
          title={t(
            'integrations.facebook.start_behavior.auto_start.welcome_trigger_message.title'
          )}
          subtitle={t(
            'integrations.facebook.start_behavior.auto_start.welcome_trigger_message.subtitle'
          )}
        />

        <div className={styles.textArea}>
          <Input
            id="welcome_trigger_message"
            name="welcome_trigger_message"
            placeholder={t(
              'integrations.facebook.start_behavior.auto_start.welcome_trigger_message.placeholder'
            )}
            size="large"
            register={register('welcome_trigger_message', {
              maxLength: {
                value: 64,
                message: t('validation.max_length', { max: 64 }),
              },
              required: {
                value: !!currentActivation,
                message: t('validation.required'),
              },
            })}
            readOnly={!canWrite}
            trimValue
            errorMessage={
              (errorMessage({
                field: errors.welcome_trigger_message,
                maxLength:
                  integrationRulesFacebook.welcomeTriggerMessage.maxLength,
              }) &&
                errors.welcome_trigger_message.message) ||
              null
            }
          />
        </div>

        <TitleSubtitle
          title={t(
            'integrations.facebook.start_behavior.auto_start.greeting_text.title'
          )}
          subtitle={t(
            'integrations.facebook.start_behavior.auto_start.greeting_text.subtitle'
          )}
        />
        <div className={styles.textArea}>
          <TextAreaAsInput
            id="greeting_text"
            name="greeting_text"
            placeholder={t(
              'integrations.facebook.start_behavior.auto_start.greeting_text.placeholder'
            )}
            size="large"
            register={register('greeting_text', {
              maxLength: {
                value: 128,
                message: t('validation.max_length', { max: 128 }),
              },
              required: {
                value: !!currentActivation,
                message: t('validation.required'),
              },
            })}
            height={'medium'}
            trimValue
            readOnly={!canWrite}
            errorMessage={
              (errorMessage({
                field: errors.greeting_text,
                maxLength: integrationRulesFacebook.greetingText.maxLength,
              }) &&
                errors.greeting_text.message) ||
              null
            }
          />
        </div>
        <div className={styles.activation}>
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  label={
                    <TitleSubtitle
                      title={t(
                        'integrations.facebook.persistent_menu.checkbox.title'
                      )}
                      subtitle={t(
                        'integrations.facebook.persistent_menu.checkbox.subtitle'
                      )}
                      noGutters
                    />
                  }
                  onChange={onChange}
                  checked={value}
                  sameLevel
                  disabled={!canWrite}
                />
              );
            }}
            name="persistent_menu_activation"
            control={control}
          />
        </div>
        <ContextualHelp
          title={t(
            'integrations.facebook.persistent_menu.contextual_help.title'
          )}
          name="persistent_menu"
          links={[
            {
              label: t(
                'integrations.facebook.persistent_menu.contextual_help.label'
              ),
              url: 'https://developers.facebook.com/docs/messenger-platform/send-messages/persistent-menu/',
            },
          ]}
        >
          {t('integrations.facebook.persistent_menu.contextual_help.message')}
        </ContextualHelp>
        {currentActivation && (
          <>
            <div>
              {fields?.map((option, index) => (
                <div
                  className={cn(styles.input, {
                    [styles.withError]:
                      errors?.persistent_menu_options?.[index]?.postback,
                  })}
                  key={option.id}
                >
                  <Input
                    key={option.id}
                    register={register(
                      `persistent_menu_options.${index}.postback`,
                      {
                        maxLength: {
                          value: 30,
                          message: t('validation.max_length', { max: 30 }),
                        },
                        required: {
                          value: !fields[0]?.postback,
                          message: t(
                            'integrations.facebook.persistent_menu.min_options'
                          ),
                        },
                      }
                    )}
                    errorMessage={
                      (errors?.persistent_menu_options &&
                        errors?.persistent_menu_options[index]?.postback
                          ?.message) ||
                      null
                    }
                    placeholder={t(
                      'integrations.facebook.persistent_menu.option_number',
                      { 0: index + 1 }
                    )}
                    size="large"
                    trimValue
                  />
                  <IconButton
                    ariaLabel={t('common.create')}
                    onClick={handleCreate}
                    disabled={
                      currentOptions[index]?.postback === '' ||
                      fields.length === 20
                    }
                  >
                    <Create />
                  </IconButton>
                  {fields.length > 1 && (
                    <IconButton
                      ariaLabel={t('common.delete')}
                      onClick={() => handleDelete(index)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </Accordion>
    </>
  );
};

export default FacebookStartBehavior;
