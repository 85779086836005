import { useCallback, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { modelNameMapper } from '@/modules/developerTools/pages/languageModels/helper';
import { ProviderName } from '@/modules/developerTools/types';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { randId } from '@/redux/dialogs/helper';
import { popModal } from '@/redux/modals/actions';
import { createLanguageModelRules } from '@/util/validator';

import Modal from '../Modal';

interface Props {
  provider: ProviderName;
  languageModelId?: string;
}

interface Form {
  apikey: string;
  model: string;
  provider: ProviderName;
  deploymentId: string;
  instanceName: string;
}

import styles from './ModalLanguageModelCreate.module.scss';

export const ModalLanguageModelCreate = ({
  provider,
  languageModelId,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    providers,
    createLanguageModel,
    createStatus,
    languageModel,
    updateLanguageModel,
    updateStatus,
    languageModels,
  } = useLanguageModels(languageModelId);

  // Opt out language models used already
  const modelOptions = useMemo(() => {
    const existingModelsSet = new Set(
      (languageModels ?? [])
        .filter((lm) => lm.provider === provider)
        .map((lm) => lm.model)
    );

    return (
      providers?.[provider]?.models
        .filter((m) => !existingModelsSet.has(m.name))
        .map((model) => ({
          label: model.name,
          value: model.name,
        })) || []
    );
  }, [languageModels, providers, provider]);

  const name = modelNameMapper(provider);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      apikey: languageModel?.apikey || '',
      model: languageModel?.model || '',
      deploymentId: languageModel?.config?.deployment || '',
      instanceName: languageModel?.config?.resource || '',
      provider,
    },
    resolver: yupResolver(createLanguageModelRules) as Resolver<Form>,
  });

  const handleCreateLanguageModel = useCallback(
    async (data: Form) => {
      // Check if the provider requires a configuration object
      const requiresConfig = provider === 'azure' || provider === 'anthropic';

      let config;

      if (requiresConfig) {
        if (provider === 'anthropic') {
          // Retrieve the selected model name
          const selectedModelName = getValues('model');

          // Find the maximum tokens for the selected model
          const maxTokens = providers?.anthropic?.models.find(
            (model) => model.name === selectedModelName
          )?.max_tokens;

          // Set the configuration for Anthropic provider
          config = { max_token: maxTokens };
        } else if (provider === 'azure') {
          // Set the configuration for Azure provider
          config = {
            deployment: data.deploymentId,
            resource: data.instanceName,
          };
        }
      }

      const modelData = {
        ...data,
        name: `${provider}-${data.model}-${randId(4)}`,
        enabled: true,
        ...(config && { config }),
      };

      createLanguageModel(modelData, {
        onSuccess: () => {
          dispatch(popModal());
        },
        onError: (error) => {
          dispatch(addErrorTemporalToast(error));
          dispatch(popModal());
        },
      });
    },
    [createLanguageModel, dispatch, getValues, provider, providers]
  );

  const handleUpdateLanguageModel = useCallback(
    async (data: Form) => {
      let updatedModelData = {
        ...languageModel,
      };

      // If the API key has been changed include it
      if (data.apikey !== languageModel?.apikey) {
        updatedModelData.apikey = data.apikey;
      }

      // For Azure provider, we can also update deployment and resource
      if (provider === 'azure') {
        updatedModelData = {
          ...updatedModelData,
          config: {
            ...languageModel?.config,
            deployment: data.deploymentId,
            resource: data.instanceName,
          },
        };
      }

      updateLanguageModel(updatedModelData, {
        onSuccess: () => {
          dispatch(popModal());
        },
        onError: (error) => {
          dispatch(addErrorTemporalToast(error));
          dispatch(popModal());
        },
      });
    },
    [dispatch, languageModel, provider, updateLanguageModel]
  );

  const onSubmit = useCallback(
    (data) => {
      languageModelId
        ? handleUpdateLanguageModel(data)
        : handleCreateLanguageModel(data);
    },
    [handleCreateLanguageModel, handleUpdateLanguageModel, languageModelId]
  );

  const onClose = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  return (
    <Modal
      size="small"
      title={t('developer_tools.create_language_model', {
        provider: name,
      })}
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('developer_tools.primary_button_text')}
      isSubmitting={createStatus === 'pending' || updateStatus === 'pending'}
      primaryButtonDisable={!isValid || !isDirty}
      onBlur={onClose}
    >
      <Input
        label={t('developer_tools.api_key')}
        register={register('apikey')}
        error={!!errors.apikey}
        errorMessage={errors.apikey?.message}
        placeholder={'xxxxxxxxxxxxxxxxx'}
      />
      <div
        className={cn(styles.inputs, {
          [styles.azure]: provider === 'azure' || provider === 'anthropic',
        })}
      >
        {languageModelId ? (
          <Input
            readOnly
            placeholder={languageModel.model}
            label={t('developer_tools.model')}
          />
        ) : (
          <Select
            label={t('developer_tools.select_model')}
            placeholder={t('common.select')}
            enablePlaceholder
            register={register('model')}
            options={modelOptions}
            size="full"
            error={!!errors.model}
            errorMessage={errors.model?.message}
          />
        )}

        {provider === 'azure' && (
          <>
            <Input
              label="Deployment ID"
              register={register('deploymentId')}
              error={!!errors.deploymentId}
              errorMessage={errors.deploymentId?.message}
              placeholder="deployment-id"
            />

            <Input
              label="Instance name"
              register={register('instanceName')}
              error={!!errors.instanceName}
              errorMessage={errors.instanceName?.message}
              placeholder="instance-name"
            />
          </>
        )}
        {(provider === 'azure' || provider === 'anthropic') && (
          <Input
            label="API version"
            readOnly
            placeholder={providers?.[provider]?.version_date}
          />
        )}
      </div>
    </Modal>
  );
};
