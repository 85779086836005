import { Icon } from '@/models/icon';

const WebhookTest = ({
  size = 100,
  color = 'var(--icon-default-blue)',
}: Icon) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 101"
      width={size}
      height={size}
    >
      <path
        d="M43.583 79.667c-6.083 8.625-17.958 10.791-26.541 4.791C8.5 78.458 6.5 66.5 12.5 57.792c3.625-5.209 9.208-8 14.917-8.25l.208 5.958c-3.792.292-7.458 2.25-9.833 5.667-4.167 6-2.875 14.083 2.833 18.125 5.75 4 13.792 2.458 17.958-3.5a13.896 13.896 0 0 0 2.334-6v-4.209l23.25-.166.291-.459c2.209-3.833 7-5.166 10.75-3 3.75 2.167 5.042 7 2.834 10.834C75.833 76.583 71 77.917 67.25 75.75a7.386 7.386 0 0 1-3.458-4.25l-16.959.083c-.458 2.792-1.541 5.5-3.25 8.084Zm30.334-29.75c10.541 1.291 18.041 10.75 16.75 21.125-1.292 10.416-10.875 17.791-21.417 16.5-6.208-.75-11.333-4.292-14.292-9.209l5.167-3c2.042 3.209 5.5 5.5 9.667 6.042 7.291.875 13.75-4.083 14.625-11.042.875-6.958-4.292-13.333-11.5-14.208a14.11 14.11 0 0 0-6.375.75L63 58.708 52.25 38.833h-.917c-4.416-.166-7.833-3.791-7.708-8.125.125-4.333 3.875-7.708 8.25-7.541 4.375.25 7.833 3.791 7.708 8.125a7.815 7.815 0 0 1-1.916 4.791l7.916 14.625c2.584-.833 5.417-1.125 8.334-.791ZM34.375 38.583c-4.167-9.791.25-21 9.875-25.083 9.667-4.083 20.833.542 25 10.333a19.189 19.189 0 0 1-.833 16.959l-5.167-3c1.75-3.375 2.042-7.5.375-11.375-2.833-6.667-10.375-9.875-16.833-7.167-6.5 2.75-9.417 10.417-6.584 17.083a13.356 13.356 0 0 0 5.5 6.292l1.625.875-12.791 20.792c.125.208.291.458.416.791 2.042 3.792.625 8.584-3.208 10.625-3.792 2.042-8.583.542-10.667-3.375-2.041-3.875-.625-8.666 3.209-10.708a7.373 7.373 0 0 1 5.125-.708l9.625-15.709c-1.959-1.791-3.625-4.041-4.667-6.625Z"
        fill={color}
      />
    </svg>
  );
};

export default WebhookTest;
