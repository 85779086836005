import { useEffect, useCallback, useState } from 'react';

import { Alert } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import Link from '@/components/atoms/Link/Link';
import Select from '@/components/atoms/Select/Select';
import Switch from '@/components/atoms/Switch/Switch';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { DISAMBIGUATION_DOCS_URL } from '@/util/constants';
import { submitWithTrimming } from '@/util/util';
import { brainRules, errorMessage } from '@/util/validator';

import styles from './styles.module.scss';

type Form = {
  disambiguation_prompt: string;
  disambiguation_options: string;
};
interface DisambiguationProps {
  brain?: Brain;
}

const DisambiguationFieldset = ({ brain }: DisambiguationProps) => {
  const { t } = useTranslation();
  const { updateBrain, updateStatus } = useBrains(brain?.brain_id);
  const [disambiguation, setDisambiguation] = useState<boolean>(false);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const formMethods = useForm<Form>({ mode: 'onChange' });
  const {
    register,
    reset,
    formState: { errors, isDirty },
  } = formMethods;
  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      updateBrain({
        ...form,
        disambiguation_options: parseInt(form.disambiguation_options, 10),
        disambiguation,
        brain_id: brain?.brain_id,
      });
    },
    [brain?.brain_id, disambiguation, updateBrain]
  );

  const disambiguationOptions = [
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ];

  useEffect(() => {
    if (brain?.brain_id) {
      reset({
        disambiguation_prompt: brain?.disambiguation_prompt,
        disambiguation_options: brain?.disambiguation_options?.toString(),
      });
      setDisambiguation(brain?.disambiguation);
    }
  }, [
    brain?.brain_id,
    brain?.disambiguation_prompt,
    brain?.disambiguation_options,
    brain?.disambiguation,
    reset,
  ]);

  const toggleDisambiguation = useCallback(() => {
    setDisambiguation(!disambiguation);
  }, [disambiguation]);

  return (
    <div className={styles.brain_settings_fields}>
      <FormFieldset
        title={t('brains.disambiguation')}
        subtitle={t('brains.disambiguation_subtitle')}
        primaryButton={{
          children: t('common.save'),
          isLoading: updateStatus === 'pending',
          disabled: !isDirty && disambiguation === brain?.disambiguation,
        }}
        onSubmit={submitWithTrimming(formMethods, onSubmit)}
        isLoading={!brain}
      >
        <div className={styles.brain_field__data}>
          <Switch
            name="disambiguation"
            label={t('brains.disambiguation')}
            checked={disambiguation}
            disabled={!brain || !canWrite}
            size="medium"
            onChange={toggleDisambiguation}
          />
        </div>

        <Input
          name="disambiguation_prompt"
          label={t('brains.disambiguation_prompt')}
          register={register(
            'disambiguation_prompt',
            brainRules.disambiguationPrompt
          )}
          errorMessage={errorMessage({
            field: errors.disambiguation_prompt,
            maxLength: brainRules.disambiguationPrompt.maxLength,
          })}
          placeholder={t('brains.disambiguation_placeholder')}
          size="large"
          readOnly={!canWrite}
        />
        <Select
          label={t('brains.disambiguation_max_options')}
          register={register('disambiguation_options')}
          name="disambiguation_options"
          options={disambiguationOptions}
          tooltip={t('brains.disambiguation_max_options_help')}
          disabled={!canWrite}
        />
        {disambiguation && (
          <Alert severity="warning">
            {t('brains.disambiguation_help')}
            <br />
            <Link href={DISAMBIGUATION_DOCS_URL} external>
              {t('common.learn_more')}
            </Link>
          </Alert>
        )}
      </FormFieldset>
    </div>
  );
};
export default DisambiguationFieldset;
