import { useSelector } from 'react-redux';

import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import { useZendesk } from '@/hooks/useZendesk';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import ZendeskOAuth from './ZendeskAuth/ZendeskOAuth';
import ZendeskSettings from './ZendeskSettings/ZendeskSettings';

export default function IntegrationZendesk() {
  const {
    integration,
    isActive,
    isUpdating,
    isUpdated,
    isConnected,
    departments,
    suggestedTags,
    updateIntegration,
    isLoadingDepartments,
    isLoadingTags,
  } = useZendesk();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  if (!integration) {
    return null;
  }

  return (
    <div>
      {isConnected && (
        <ZendeskSettings
          integration={integration}
          isActive={isActive}
          isUpdating={isUpdating}
          departments={departments}
          suggestedTags={suggestedTags}
          updateIntegration={updateIntegration}
          isLoadingDepartments={isLoadingDepartments}
          isLoadingTags={isLoadingTags}
        />
      )}
      {!isConnected && (
        <ZendeskOAuth
          integration={integration}
          isUpdated={isUpdated}
          isUpdating={isUpdating}
          updateIntegration={updateIntegration}
        />
      )}
      <DeleteFieldset integration={integration} disabled={!canWrite} />
    </div>
  );
}
