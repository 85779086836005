import { useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useParams, useNavigate } from 'react-router';

import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { useAccount } from '@/hooks/useAccount';
import { usePermissions } from '@/hooks/usePermissions';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import AnalyticsNavigation from '@/modules/analytics/components/AnalyticsNavigation/AnalyticsNavigation';
import { AnalyticsType } from '@/modules/analytics/models';
import { selectFiltersByType } from '@/modules/TryIt/redux/selectors';
import { getPermissions } from '@/redux/permissions/selectors';

import AnalyticsHeader from './components/AnalyticsHeader/AnalyticsHeader';
import DisabledAnalytics from './components/DisabledAnalytics';
import useAnalyticsHelper from './hooks/useAnalyticsHelper';
import { AgentsPerformance } from './pages/AgentsPerformance';
import { BrainEffectivenessReport } from './pages/BrainEffectivenessReport';
import { CustomerSatisfaction } from './pages/CustomerSatisfaction';
import { HumanAgentsEffectiveness } from './pages/HumanAgentsEffectiveness';
import { HumanChatLogs } from './pages/HumanChatLogs';
import { OverviewReport } from './pages/OverviewReport';
import { UsageReport } from './pages/UsageReport';
import { resetFilters } from './redux/actions';

const AnalyticsRouter: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useAccount();
  const { slug: urlSlug } = useParams();
  const { account } = useAccount();
  useAnalyticsHelper();
  const { listStatus } = usePermissions();
  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'analytics', actions.READ)
  );

  const isAnalyticsDisabled = account?.opt_out;

  const filters = useSelector(
    (state: RootState) => selectFiltersByType(state, AnalyticsType.ACCOUNT),
    isEqual
  );

  useEffect(() => {
    if (slug !== urlSlug && filters.length > 0) {
      dispatch(resetFilters());
      navigate(
        {
          ...window.location,
          search: '',
        },
        { replace: true }
      );
    }
  }, [dispatch, filters.length, navigate, slug, urlSlug]);

  if (isAnalyticsDisabled || (listStatus === 'success' && !canRead)) {
    return (
      <Layout>
        <DisabledAnalytics />
      </Layout>
    );
  }

  return (
    <Layout withSidebar>
      <PermissionsWrapper module="analytics">
        <AnalyticsNavigation />
        <AnalyticsHeader />
        <PageContentWrapper fullHeight>
          <Routes>
            <Route path="/" element={<Navigate to="overview" replace />} />
            <Route path="/overview" element={<OverviewReport />} />

            <Route path="/usage" element={<UsageReport />} />
            <Route
              path="/brain-effectiveness"
              element={<BrainEffectivenessReport />}
            />
            <Route
              path="/human-agents-effectiveness"
              element={<HumanAgentsEffectiveness />}
            />
            <Route path="/agents-performance" element={<AgentsPerformance />} />
            <Route
              path="/customer-satisfaction"
              element={<CustomerSatisfaction />}
            />
            <Route path="/human-chat-logs" element={<HumanChatLogs />} />
          </Routes>
        </PageContentWrapper>
      </PermissionsWrapper>
    </Layout>
  );
};

export default AnalyticsRouter;
