import { Icon } from '@/models/icon';

const Video = ({ size = 16, color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      width={size}
      height={size}
      fill="none"
    >
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path d="M15.86 4.28a1.853 1.853 0 0 0-1.294-1.333c-1.146-.28-5.733-.28-5.733-.28s-4.587 0-5.733.306a1.853 1.853 0 0 0-1.294 1.334A19.333 19.333 0 0 0 1.5 7.833c-.008 1.192.095 2.381.306 3.554a1.853 1.853 0 0 0 1.294 1.28c1.146.306 5.733.306 5.733.306s4.587 0 5.733-.306a1.852 1.852 0 0 0 1.294-1.334c.208-1.155.31-2.326.306-3.5a19.33 19.33 0 0 0-.306-3.553Z" />
        <path fill="#fff" d="m7.333 10.013 3.833-2.18-3.833-2.18v4.36Z" />
      </g>
    </svg>
  );
};

export default Video;
