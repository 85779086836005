const Filters = ({ size = 16, color = 'var(--icon-default-gray)' }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.667 2H1.333l5.334 6.307v4.36L9.333 14V8.307L14.667 2Z" />
    </svg>
  );
};

export default Filters;
