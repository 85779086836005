export enum Performance {
  NOT_APPLICABLE = 'not_applicable',
  POOR = 'poor',
  AVERAGE = 'average',
  GOOD = 'good',
  TOO_MANY = 'too_many',
}

export const MAX_THRESHOLD_PERCENTAGE = 150;
export const MIN_THRESHOLD_PERCENTAGE = 50;

export const getPerformanceKey = (
  percentage: number,
  minThreshold = MIN_THRESHOLD_PERCENTAGE,
  maxThreshold = MAX_THRESHOLD_PERCENTAGE
): Performance => {
  if (percentage <= 0) {
    return Performance.NOT_APPLICABLE;
  } else if (percentage < 30) {
    return Performance.POOR;
  } else if (percentage < minThreshold) {
    return Performance.AVERAGE;
  } else if (percentage <= maxThreshold) {
    return Performance.GOOD;
  } else if (percentage > maxThreshold) {
    return Performance.TOO_MANY;
  }
};
