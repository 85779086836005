export const AndroidIcon = ({ size = 16 }: { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#A7C840" clipPath="url(#android)">
        <path d="M3.332 7.774c-.414 0-.765.144-1.053.433a1.416 1.416 0 0 0-.433 1.038v6.202c0 .414.144.764.433 1.053.288.288.64.433 1.053.433a1.41 1.41 0 0 0 1.046-.433c.283-.288.425-.64.425-1.053V9.245c0-.404-.144-.75-.433-1.038a1.417 1.417 0 0 0-1.038-.433ZM15.274 2.207l1.024-1.89c.067-.125.044-.22-.072-.288-.125-.058-.221-.029-.288.086L14.899 2.02A7.09 7.09 0 0 0 12 1.413a7.09 7.09 0 0 0-2.899.607L8.063.115C7.995 0 7.899-.029 7.774.03c-.115.068-.139.163-.072.288l1.025 1.89A6.324 6.324 0 0 0 6.246 4.42a5.592 5.592 0 0 0-.923 3.108h13.34c0-1.125-.307-2.16-.923-3.108a6.35 6.35 0 0 0-2.466-2.214Zm-5.92 2.747a.54.54 0 0 1-.397.167.516.516 0 0 1-.39-.167.553.553 0 0 1-.158-.396c0-.154.053-.286.158-.397a.518.518 0 0 1 .39-.165c.154 0 .286.055.397.165.11.111.166.243.166.397a.543.543 0 0 1-.166.396Zm6.079 0a.517.517 0 0 1-.39.167.54.54 0 0 1-.396-.167.54.54 0 0 1-.166-.396c0-.154.055-.286.165-.397a.542.542 0 0 1 .397-.165.554.554 0 0 1 .548.562.553.553 0 0 1-.158.396ZM5.38 17.654c0 .442.154.817.461 1.125.308.307.683.461 1.125.461h1.067l.015 3.274c0 .414.144.765.433 1.053.288.289.634.433 1.038.433.413 0 .764-.144 1.053-.433.288-.288.433-.64.433-1.053V19.24h1.99v3.274c0 .414.144.765.433 1.053.288.289.639.433 1.052.433.413 0 .765-.144 1.053-.433.289-.288.433-.64.433-1.053V19.24h1.082c.432 0 .802-.153 1.11-.461a1.53 1.53 0 0 0 .461-1.125V8.048H5.38v9.606ZM20.668 7.774c-.404 0-.75.142-1.038.425a1.41 1.41 0 0 0-.433 1.046v6.202c0 .414.145.764.433 1.053.288.288.635.433 1.038.433.414 0 .765-.145 1.053-.433.289-.289.433-.64.433-1.053V9.245a1.41 1.41 0 0 0-.433-1.046 1.447 1.447 0 0 0-1.053-.425Z" />
      </g>
      <defs>
        <clipPath id="android">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
