import { t } from 'i18next';
import * as yup from 'yup';

import { LENGTH_M } from '@/util/validator';

export const visitorEmailSchema = yup.object().shape({
  inputField: yup
    .string()
    .email(() => t('contacts.errors.email'))
    .required(() => t('contacts.errors.email')),
});

export const visitorAddressSchema = yup.object().shape({
  inputField: yup.string().test(
    'inputField',
    () => t('contacts.errors.address'),
    (value) => value && value.length >= 5 && value.length <= LENGTH_M
  ),
});

export const visitorPhoneSchema = yup.object().shape({
  inputField: yup.string().matches(/^\+?[0-9-\s]{9,20}$/, {
    message: () => t('contacts.errors.phone'),
  }),
});
