import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Slack = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, disabled = false, ...props }, ref) => {
    const disabledClass = disabled ? styles.icon_disabled_fill : '';

    return (
      <svg
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        className={`${styles.icon_fill} ${disabledClass}`}
        width={size}
        height={size}
        fillRule="evenodd"
        clipRule="evenodd"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M5.724 15.09c0 1.388-1.057 2.514-2.362 2.514C2.058 17.604 1 16.478 1 15.09c0-1.389 1.058-2.515 2.362-2.515h2.362v2.515zM6.905 15.09c0-1.39 1.058-2.516 2.363-2.516 1.304 0 2.362 1.126 2.362 2.515v6.287c0 1.39-1.058 2.515-2.362 2.515-1.305 0-2.363-1.126-2.363-2.514v-6.288z"
          fill="#E01E5A"
        />
        <path
          d="M9.267 5.03c-1.304 0-2.362-1.126-2.362-2.515C6.905 1.126 7.963 0 9.267 0c1.305 0 2.363 1.126 2.363 2.515V5.03H9.267zM9.267 6.288c1.305 0 2.363 1.126 2.363 2.515 0 1.388-1.058 2.514-2.363 2.514H3.362C2.058 11.318 1 10.192 1 8.804c0-1.39 1.058-2.515 2.362-2.515h5.905z"
          fill="#36C5F0"
        />
        <path
          d="M18.716 8.803c0-1.39 1.057-2.515 2.362-2.515 1.304 0 2.362 1.126 2.362 2.515 0 1.388-1.058 2.514-2.362 2.514h-2.362V8.804zM17.535 8.802c0 1.39-1.058 2.515-2.363 2.515-1.304 0-2.362-1.126-2.362-2.515V2.515C12.81 1.126 13.868 0 15.172 0c1.305 0 2.363 1.126 2.363 2.515v6.287z"
          fill="#2EB67D"
        />
        <path
          d="M15.172 18.862c1.305 0 2.363 1.126 2.363 2.515 0 1.39-1.058 2.515-2.363 2.515-1.304 0-2.362-1.126-2.362-2.515v-2.515h2.362zM15.172 17.604c-1.304 0-2.362-1.126-2.362-2.515 0-1.389 1.058-2.515 2.362-2.515h5.905c1.305 0 2.362 1.126 2.362 2.515 0 1.389-1.057 2.515-2.362 2.515h-5.905z"
          fill="#ECB22E"
        />
      </svg>
    );
  }
);

Slack.displayName = 'Slack';

export default Slack;
