import { Icon } from '@/models/icon';

const Profile = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 9.333A2.667 2.667 0 1 0 8 4a2.667 2.667 0 0 0 0 5.333Z"
        className="icon_stroke"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M1.008 8.004a6.996 6.996 0 1 1 12.4 4.442 1.719 1.719 0 0 0-.53-.823c-.598-.512-1.394-.79-2.211-.79H5.333c-.817 0-1.613.278-2.21.79-.289.247-.445.54-.53.816a6.967 6.967 0 0 1-1.585-4.435ZM3.5 13.357A6.967 6.967 0 0 0 8.004 15c1.712 0 3.28-.615 4.497-1.637l-.001-.03v-.065c.001-.328.003-.65-.273-.886-.403-.345-.963-.549-1.56-.549H5.333c-.597 0-1.157.204-1.56.55-.276.236-.274.557-.273.885v.089ZM8.004.008a7.996 7.996 0 1 0 0 15.992 7.996 7.996 0 0 0 0-15.992Z"
        clipRule="evenodd"
        className="icon_fill"
      />
    </svg>
  );
};

export default Profile;
