import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';

import { Filters, AuditLogsFilters } from '@/models/auditLogs';

interface PeriodProps {
  startDate: string | Moment;
  endDate: string | Moment;
}

const defaultState: AuditLogsFilters = {
  startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  filters: [],
  filtersLoaded: false,
};

interface ApplyFiltersPayload {
  filters: Filters[];
}

const slice = createSlice({
  name: 'auditLogs',
  initialState: defaultState,
  reducers: {
    setPeriod: (state, action: PayloadAction<PeriodProps>) => {
      const { startDate, endDate } = action.payload ?? {};

      if (moment.isMoment(startDate)) {
        state.startDate = startDate.format('YYYY-MM-DD');
      } else {
        state.startDate = startDate;
      }
      if (moment.isMoment(endDate)) {
        state.endDate = endDate.format('YYYY-MM-DD');
      } else {
        state.endDate = endDate;
      }
    },
    applyFilters: (state, action: PayloadAction<ApplyFiltersPayload>) => {
      const filters = action.payload.filters;

      state.filters = filters as Filters[];

      state.filtersLoaded = true;
    },
    resetFilterCategory: (state) => {
      state.filters = [];
    },
  },
});

export const { applyFilters, setPeriod, resetFilterCategory } = slice.actions;

export default slice.reducer;
