import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DialogAlert } from '@/models/dialogAlert';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectAction,
  selectCarousel,
  selectCondition,
  selectNode,
  selectRequisite,
} from '@/redux/nodes/actions';
import { selectNodes } from '@/redux/nodes/selectors';
import { selectDeskId } from '@/redux/session/selectors';
import { scrollToElementById } from '@/util/util';

import useDesks from './useDesks';

const MAX_WPP_QUICKOPTIONS = 10;

const useAlertSummary = () => {
  const dispatch = useDispatch();
  const [selectedDialogAlert, setSelectedDialogAlert] =
    useState<DialogAlert>(null);

  const nodes = useSelector(selectNodes);
  const deskId = useSelector(selectDeskId);
  const { desk } = useDesks(deskId);

  const whatsAppImplemented = !!desk?.integrations?.find(
    (int) => int.type === 'whatsapp' && int.active
  );

  const openToolkit = useCallback(
    ({ id, type, ...rest }) => {
      switch (type) {
        case 'carousel':
          dispatch(selectCarousel({ actionId: id, index: rest.index }));
          break;
        case 'question':
          dispatch(
            selectRequisite({
              nodeId: rest.nodeId,
              index: rest.index,
              requisiteId: id,
            })
          );
          break;
        case 'condition':
          dispatch(
            selectCondition({
              nodeId: rest.nodeId,
              index: rest.index,
            })
          );
          break;
        case 'event':
        case 'intent':
          dispatch(selectNode({ nodeId: id }));
          break;
        default:
          dispatch(selectAction({ actionId: id }));
      }
    },
    [dispatch]
  );

  const checkActions = useCallback(
    (actions, node, type: string, parentId: string) => {
      actions?.forEach((action, index) => {
        if (
          action?.type === 'text' &&
          action?.options?.length > MAX_WPP_QUICKOPTIONS
        ) {
          dispatch(
            updateDialogAlerts({
              dialogAlerts: {
                id: type === 'requisite' ? parentId : action.action_id,
                type: type === 'requisite' ? 'question' : action.type,
                nodeId: node.node_id,
                body: 'dialog.warnings.whatsapp.quickoptions',
                title:
                  type === 'requisite'
                    ? 'dialog.warnings.question_title'
                    : 'dialog.warnings.text_title',
                alertType: 'warning',
                index,
              },
            })
          );
        } else {
          dispatch(
            updateDialogAlerts({
              dialogAlerts: {
                id: type === 'requisite' ? parentId : action.action_id,
                type: type === 'requisite' ? 'question' : action.type,
                nodeId: node.node_id,
                body: '',
                title: `dialog.warnings.${type === 'requisite' ? 'question' : 'text'}_title`,

                alertType: 'warning',
                index,
              },
            })
          );
        }
      });
    },
    [dispatch]
  );

  const processRequisites = useCallback(
    (requisites, node) => {
      requisites?.forEach((requisite) => {
        checkActions(
          requisite?.actions,
          node,
          'requisite',
          requisite.requisite_id
        );
      });
    },
    [checkActions]
  );

  const processConditions = useCallback(
    (conditions, node) => {
      conditions?.forEach((condition) => {
        checkActions(
          condition?.actions,
          node,
          'condition',
          condition.condition_id
        );
      });
    },
    [checkActions]
  );

  useEffect(() => {
    if (whatsAppImplemented) {
      nodes?.nodes?.forEach((node) => {
        processRequisites(node?.requisites, node);
        processConditions(node?.conditions, node);
        checkActions(node?.actions, node, 'action', node.node_id);
      });
    }
  }, [
    nodes,
    whatsAppImplemented,
    desk,
    dispatch,
    checkActions,
    processConditions,
    processRequisites,
  ]);

  useEffect(() => {
    if (!selectedDialogAlert) {
      return;
    }

    scrollToElementById(selectedDialogAlert.id);
    openToolkit(selectedDialogAlert);
    setSelectedDialogAlert(null);
  }, [selectedDialogAlert, openToolkit]);

  return { selectedDialogAlert, setSelectedDialogAlert };
};

export default useAlertSummary;
