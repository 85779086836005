import { useCallback, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { RootState } from '@/models/state';
import { setPeriod as setPeriodInAuditLogs } from '@/redux/auditLogs/actions';
import { urlSafeBase64Encode } from '@/util/util';

import { setPeriod } from '../redux/actions';
import { selectAnalyticsDates, selectAuditLogsDates } from '../redux/selectors';

export const periodOptions = [
  { title_key: 'analytics.periods.today', days: 0, single: true },
  { title_key: 'analytics.periods.yesterday', days: 1, single: true },
  { title_key: 'analytics.periods.7days', days: 6 },
  { title_key: 'analytics.periods.14days', days: 13 },
  { title_key: 'analytics.periods.lastmonth', days: 29 },
  { title_key: 'analytics.periods.custom' }, // custom period must remain last in this array
];

export const useDateFilter = (setDateOpen) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [urlParams] = useSearchParams();

  const { pathname } = useLocation();
  const isAnalyticsFilter =
    pathname?.includes('/analytics') || pathname?.includes('/logs');

  const { startDate, endDate } = useSelector((state: RootState) =>
    isAnalyticsFilter
      ? selectAnalyticsDates(state)
      : selectAuditLogsDates(state)
  );

  const [calendarState, setCalendarState] = useState({
    startDate: moment(startDate),
    endDate: moment(endDate),
  });

  const handleCalendarSave = useCallback(
    (event) => {
      event.preventDefault();
      let date: {
        startDate: string;
        endDate: string;
      };
      if (!calendarState?.endDate && calendarState?.startDate) {
        date = {
          startDate: calendarState?.startDate.format('YYYY-MM-DD'),
          endDate: calendarState?.startDate.format('YYYY-MM-DD'),
        };
        dispatch(setPeriod(date));
      } else if (!calendarState?.endDate && !calendarState?.startDate) {
        setShowCalendar(false);
        return;
      } else {
        date = calendarState?.startDate?.format('YYYY-MM-DD')
          ? {
              startDate: calendarState.startDate.format('YYYY-MM-DD'),
              endDate: calendarState.endDate.format('YYYY-MM-DD'),
            }
          : null;
        dispatch(setPeriod(date));
      }
      navigate({
        search: `${
          date ? `date=${urlSafeBase64Encode(JSON.stringify(date))}` : ''
        }${
          urlParams.get('filters') ? `&filters=${urlParams.get('filters')}` : ''
        }`,
      });
      navigate(
        {
          ...window.location,
          search: `${
            date ? `date=${urlSafeBase64Encode(JSON.stringify(date))}` : ''
          }${
            urlParams.get('filters')
              ? `&filters=${urlParams.get('filters')}`
              : ''
          }`,
        },
        {
          replace: true,
        }
      );
      setDateOpen(false);
      setShowCalendar(false);
    },
    [
      calendarState?.endDate,
      calendarState?.startDate,
      dispatch,
      navigate,
      setDateOpen,
      urlParams,
    ]
  );

  const handleCalendarClear = useCallback(
    (event) => {
      event.preventDefault();
      setCalendarState(null);
    },
    [setCalendarState]
  );

  const handlePeriodClick = useCallback(
    (period: { title_key: string; days?: number; single?: boolean }) => {
      if (!('days' in period)) {
        setCalendarState({
          startDate: moment(startDate),
          endDate: moment(endDate),
        });
        setShowCalendar((prev) => !prev);
        return;
      }
      setDateOpen(false);
      let date: {
        startDate: string;
        endDate: string;
      };
      if (period.single) {
        date = {
          startDate: moment()
            .subtract(period.days, 'days')
            .format('YYYY-MM-DD'),
          endDate: moment().subtract(period.days, 'days').format('YYYY-MM-DD'),
        };
      } else {
        date = {
          startDate: moment()
            .subtract(period.days, 'days')
            .format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        };
      }

      dispatch(
        isAnalyticsFilter ? setPeriod(date) : setPeriodInAuditLogs(date)
      );

      navigate({
        search: `date=${urlSafeBase64Encode(JSON.stringify(date))} ${
          !isEmpty(urlParams.get('filters'))
            ? `&filters=${urlParams.get('filters')}`
            : ''
        }`,
      });
    },
    [
      dispatch,
      endDate,
      isAnalyticsFilter,
      setDateOpen,
      startDate,
      urlParams,
      navigate,
    ]
  );

  return {
    startDate,
    endDate,
    setShowCalendar,
    showCalendar,
    calendarState,
    setCalendarState,
    handleCalendarSave,
    handleCalendarClear,
    handlePeriodClick,
  };
};
