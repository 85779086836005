import { Connected } from './Connected';
import { NonConnected } from './NonConnected';
import { Wrapper } from './Wrapper';
import { LanguageModel, ProviderName } from '../../types';

type Props =
  | {
      model: LanguageModel;
      provider?: never;
    }
  | {
      provider: ProviderName;
      model?: never;
    };

export const LanguageModelTile = ({ model, provider }: Props) => {
  return (
    <Wrapper isConnected={!provider}>
      {provider ? (
        <NonConnected provider={provider} />
      ) : (
        <Connected model={model} />
      )}
    </Wrapper>
  );
};
