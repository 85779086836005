import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon';

type Form = {
  overview: string;
};

export const ProductOverview = () => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();

  // RHF
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<Form>({
    mode: 'onSubmit',
    values: {
      overview: brain?.guidelines?.overview || '',
    },
  });

  const onSubmit = (data: Form) => {
    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        overview: data.overview,
      },
    });
  };

  return (
    <>
      <FormCard id="ai-agent-overview" onSubmit={handleSubmit(onSubmit)}>
        <FormCard.Header
          title={t('ai_agents.knowledge.overview.title')}
          subtitle={t('ai_agents.knowledge.overview.subtitle')}
          icon={
            <NumberIcon
              color="var(--icon-default-blue)"
              size="large"
              number={1}
            />
          }
        />

        <FormCard.Content>
          <TextAreaAsInput
            height="large"
            label={t('ai_agents.navigation.overview')}
            {...register('overview', {
              maxLength: {
                value: 250,
                message: t('validation.max_length', { max: 250 }),
              },
            })}
            placeholder={t('ai_agents.knowledge.overview.placeholder')}
            error={!!errors.overview}
            errorMessage={capitalizeFirstLetter(errors.overview?.message)}
          />
        </FormCard.Content>

        <FormCard.Footer>
          <Button
            disabled={!isDirty}
            type="submit"
            variant="secondary"
            isLoading={isSubmitting}
          >
            {t('common.save')}
          </Button>
        </FormCard.Footer>
      </FormCard>
    </>
  );
};
