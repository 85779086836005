import { useEffect, useState } from 'react';

import { Condition } from '@/models/node';
import { MAX_EXPANDED_CONDITIONS } from '@/util/constants';

const useConditionsCollapsed = (
  nodeId: string,
  conditions: Condition[] | undefined = []
) => {
  const [collapsed, setCollapsed] = useState(null);
  const [activeConditionIndex, setActiveConditionIndex] = useState(0);

  // Reset collapsed state when nodeId changes
  useEffect(() => {
    const initialCollapsedState = conditions.length > MAX_EXPANDED_CONDITIONS;
    setCollapsed(initialCollapsedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);

  return {
    collapsed,
    setCollapsed,
    activeConditionIndex,
    setActiveConditionIndex,
  };
};

export default useConditionsCollapsed;
