import { useCallback, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';

import { getRtmToken } from '@/api/client';
import {
  accountsEndpoints,
  billingEndpoints as endpoints,
} from '@/api/endpoints';
import { Brain } from '@/models/brain';
import { Socket, SocketEvent } from '@/models/rtm';
import { USER_ID_MOCK } from '@/util/constants';

import { useRtm } from './useRtm';
import {
  markAgentOffline,
  markAgentOnline,
  removeViewer,
  setAgents,
  setAllViewers,
  setViewers,
  updateAgentActivity,
} from '../../redux/presence/actions';
import {
  selectAccountId,
  selectAccountSlug,
  selectDeskId,
} from '../../redux/session/selectors';
import { selectUser } from '../../redux/user/selectors';
import { onBrainUpdated } from '../useBrains';

const SERVER_OR_CLIENT_DISCONNECT = new RegExp(/io server/);

const TEN_SECONDS = 10000;
export const useRtmSetup = () => {
  const user = useSelector(selectUser);
  const account_id = useSelector(selectAccountId);
  const dispatch = useDispatch();
  const userId = user?.user_id;
  const queryClient = useQueryClient();
  const [socket, setSocket] = useState<Socket | undefined>();
  const [isConnected, setIsConnected] = useState(false);
  const deskId = useSelector(selectDeskId);
  const slug = useSelector(selectAccountSlug);

  const { create, update, remove } = useRtm();

  useEffect(() => {
    //Establish a socket connection when the deskId is added in redux session
    if (!socket && queryClient && deskId) {
      const client = io(window.RTM_URL, {
        autoConnect: false,
        reconnection: true,
        path: '/ws',
        transports: ['websocket', 'polling'],
        reconnectionAttempts: 20,
        rememberUpgrade: true,
        auth: (cb) =>
          getRtmToken()
            .then(cb)
            .catch(() => cb({ token: 'ignore-token' })),
      }) as Socket;

      client.on('error' as never, (error) => {
        console.debug('socket.error', error);
      });
      client.on('connect_error', (error) => {
        console.debug('socket.connect_error', error);
      });

      client.on('connect', () => {
        console.debug('socket.connect', `${client.id} at ${Date.now()}`);
        setIsConnected(true);
      });

      client.on('disconnect', (reason) => {
        console.debug('socket.reconnect reason:', reason);
        setIsConnected(false);

        if (SERVER_OR_CLIENT_DISCONNECT.test(reason)) {
          // The disconnection was initiated by the server or the client, reconnect manually.
          // For all the other reasons the socket will automatically try to reconnect
          socket.connect();
        }
      });

      client.on(SocketEvent.desks_created, ({ user_id, data }) => {
        if (userId !== user_id && userId) {
          const { desk_id } = data;
          create('desks', desk_id);
        }
      });

      client.on(SocketEvent.desks_updated, ({ user_id, data }) => {
        if (userId !== user_id) {
          const { desk_id } = data;
          update('desks', desk_id);
        }
      });

      client.on(SocketEvent.desks_removed, ({ user_id, data }) => {
        if (userId !== user_id) {
          const { account_id, desk_id } = data;
          remove('desks', account_id, desk_id);
        }
      });

      client.on(SocketEvent.collections_created, async ({ user_id, data }) => {
        if (userId && userId !== user_id) {
          const { collection_id } = data;
          create('collections', collection_id);
        }
      });

      client.on(SocketEvent.collections_updated, async ({ user_id, data }) => {
        if (userId !== user_id) {
          update('collections', data.collection_id);
        }
      });

      client.on(SocketEvent.collections_removed, ({ user_id, data }) => {
        if (userId !== user_id) {
          remove('collections', data.account_id, data.collection_id);
        }
      });

      client.on(SocketEvent.broadcasts_created, async ({ user_id, data }) => {
        if (userId && userId !== user_id) {
          const { broadcast_id } = data;
          create('broadcasts', broadcast_id);
        }
      });

      client.on(SocketEvent.broadcasts_updated, async ({ user_id, data }) => {
        if (userId !== user_id) {
          update('broadcasts', data.broadcast_id);
        }
      });

      client.on(SocketEvent.broadcasts_removed, ({ user_id, data }) => {
        if (userId !== user_id) {
          remove('broadcasts', data.account_id, data.broadcast_id);
        }
      });

      client.on(SocketEvent.collections_viewing, ({ data }) => {
        const { collection_id, agents } = data;
        dispatch(
          setViewers({ type: 'collections', id: collection_id, agents: agents })
        );
      });

      client.on(SocketEvent.collections_viewing_stop, ({ user_id, data }) => {
        const { collection_id } = data;
        dispatch(
          removeViewer({ type: 'collections', id: collection_id, user_id })
        );
      });

      client.on(SocketEvent.collections_viewers, ({ data }) => {
        const { resources } = data;
        dispatch(setAllViewers({ type: 'collections', resources }));
      });

      client.on(SocketEvent.brains_created, async ({ user_id, data }) => {
        if (userId && userId !== user_id) {
          const { brain_id } = data;
          create('brains', brain_id);
        }
      });

      client.on(SocketEvent.brains_updated, async ({ user_id, data }) => {
        if (userId !== user_id) {
          update('brains', data.brain_id);
        }
      });

      client.on(SocketEvent.brains_removed, ({ user_id, data }) => {
        if (userId !== user_id) {
          remove('brains', data.account_id, data.brain_id);
        }
      });

      client.on(SocketEvent.brains_viewing, ({ data }) => {
        const { brain_id, agents } = data;
        dispatch(setViewers({ type: 'brains', id: brain_id, agents: agents }));
      });

      client.on(SocketEvent.brains_viewing_stop, ({ user_id, data }) => {
        const { brain_id } = data;
        dispatch(removeViewer({ type: 'brains', id: brain_id, user_id }));
      });

      client.on(SocketEvent.brains_viewers, ({ data }) => {
        const { resources } = data;
        dispatch(setAllViewers({ type: 'brains', resources }));
      });

      client.on(SocketEvent.collections_viewers, ({ data }) => {
        const { resources } = data;
        dispatch(setAllViewers({ type: 'collections', resources }));
      });

      client.on(SocketEvent.conversations_viewers, ({ data }) => {
        const { resources } = data;
        dispatch(setAllViewers({ type: 'sessions', resources }));
      });

      client.on(SocketEvent.brains_status, ({ data }) => {
        userId && onBrainUpdated(queryClient, data as Brain);
      });

      client.on(SocketEvent.stripe_subscription_deleted, () => {
        queryClient.invalidateQueries({
          queryKey: [endpoints.billingState, slug],
        });
        queryClient.invalidateQueries({
          queryKey: [endpoints.upcomingInvoices, slug],
        });
        queryClient.invalidateQueries({
          queryKey: [accountsEndpoints.account, slug],
        });
      });

      client.on(SocketEvent.agent_presence, ({ data }) =>
        dispatch(setAgents(data))
      );
      client.on(SocketEvent.agent_online, ({ data }) =>
        dispatch(markAgentOnline(data))
      );

      client.on(SocketEvent.agent_offline, ({ data }) =>
        dispatch(markAgentOffline(data))
      );

      client.on(SocketEvent.agent_activity, (data) =>
        dispatch(updateAgentActivity(data))
      );

      client.onAny((event, data) => console.debug(event, data));

      setSocket(client);
    }
  }, [
    create,
    deskId,
    dispatch,
    queryClient,
    remove,
    slug,
    socket,
    update,
    userId,
  ]);

  const onAction = useCallback(() => {
    if (!socket || !userId) {
      return;
    }
    socket?.volatile.emit(SocketEvent.agent_activity, {
      account_id,
      user_id: userId,
      timestamp: Date.now(),
    });
  }, [account_id, socket, userId]);

  useIdleTimer({
    onAction,
    throttle: TEN_SECONDS,
    events: ['mousemove', 'keydown'],
  });

  // Connect to the websocket on component mount and disconnect on unmount
  useEffect(() => {
    if (userId && userId !== USER_ID_MOCK) {
      socket?.connect();
    }

    return () => {
      socket?.disconnect();
    };
  }, [socket, userId]);

  return { socket, isConnected };
};
