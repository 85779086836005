import { Icon } from '@/models/icon';

const Docs = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      className="icon_stroke"
    >
      <path
        d="M2.667 13a1.667 1.667 0 0 1 1.667-1.667h9"
        stroke={color}
        strokeWidth=".667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.334 1.333h9v13.334h-9A1.667 1.667 0 0 1 2.667 13V3a1.667 1.667 0 0 1 1.667-1.667v0Z"
        stroke={color}
        strokeWidth=".667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Docs;
