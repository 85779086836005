import { Icon } from '@/models/icon';

const ThemeAuto = ({ size = 44 }: Icon) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      width={size}
      height={size}
    >
      <rect
        x=".5"
        y=".5"
        width="43"
        height="43"
        rx="3.5"
        fill="#F9F9F9"
        stroke="#8893A8"
      />
      <rect
        width="5.978"
        height="11.96"
        rx="2.989"
        transform="matrix(0 -1 -1 0 15.53 24.194)"
        fill="#fff"
      />
      <rect
        x="-1"
        y="-1"
        width="3.978"
        height="9.96"
        rx="1.989"
        transform="matrix(0 -1 -1 0 13.53 22.194)"
        stroke="#000"
        strokeOpacity=".72"
        strokeWidth="2"
      />
      <ellipse
        rx="4.199"
        ry="4.197"
        transform="matrix(-1 0 0 1 25.818 21.182)"
        fill="#fff"
      />
      <path
        d="M22.619 21.182a3.198 3.198 0 1 0 6.396-.002 3.198 3.198 0 0 0-6.396.002Z"
        stroke="#000"
        strokeOpacity=".72"
        strokeWidth="2"
      />
      <ellipse
        rx="2.449"
        ry="2.448"
        transform="matrix(-1 0 0 1 38.57 21.204)"
        fill="#fff"
      />
      <path
        d="M37.12 21.204a1.449 1.449 0 1 0 2.899 0 1.449 1.449 0 0 0-2.898 0Z"
        stroke="#000"
        strokeOpacity=".72"
        strokeWidth="2"
      />
      <rect
        x="30.061"
        y="34.639"
        width="3.978"
        height="9.96"
        rx="1.989"
        transform="rotate(-90 30.06 34.639)"
        stroke="var(--icon-default-gray)"
        strokeWidth="2"
      />
      <path
        d="M21.971 32.626a3.198 3.198 0 0 1-3.199 3.198 3.198 3.198 0 1 1 3.2-3.198ZM7.47 32.65a1.449 1.449 0 1 1-2.899-.002 1.449 1.449 0 0 1 2.898.001Z"
        stroke="var(--icon-default-gray)"
        strokeWidth="2"
      />
      <rect
        x="30.061"
        y="11.75"
        width="3.978"
        height="9.96"
        rx="1.989"
        transform="rotate(-90 30.06 11.75)"
        stroke="var(--icon-default-gray)"
        strokeWidth="2"
      />
      <path
        d="M21.971 9.737a3.198 3.198 0 0 1-3.199 3.197 3.198 3.198 0 1 1 3.2-3.197ZM7.47 9.76a1.449 1.449 0 1 1-2.899-.001 1.449 1.449 0 0 1 2.898 0Z"
        stroke="var(--icon-default-gray)"
        strokeWidth="2"
      />
    </svg>
  );
};
export default ThemeAuto;
