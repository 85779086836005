import React, { useCallback, useMemo, useReducer } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { MODAL_CONFIRM_CHANGES } from '@/components/organisms/Modals/ModalConductor';
import { pushModal } from '@/redux/modals/actions';
import { delay } from '@/util/util';

import { accordionReducer } from './reducer';
import { AccordionState } from './types';

export const useAccordionState = (
  initialState: AccordionState,
  canEdit = true
) => {
  const [state, dispatch] = useReducer(accordionReducer, initialState);
  const dispatchRedux = useDispatch();
  const { t } = useTranslation();

  const isAnyAccordionDirty = useMemo(
    () => Object.values(state).some((accordion) => accordion.isDirty),
    [state]
  );

  const setExpanded =
    (panel: string) => async (_: React.SyntheticEvent, isExpanded: boolean) => {
      const expandPanel = async () => {
        dispatch({ type: 'SET_EXPANDED', panel, isExpanded });
      };

      const scrollToPanel = async () => {
        if (isExpanded && state[panel].ref.current) {
          await delay(300);
          state[panel].ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      };

      const currentAccordion = state[panel];

      const handleUnsavedChanges = async () => {
        const modalProps = {
          title: t('prompts.unsaved_changes.title'),
          subtitle: t('prompts.unsaved_changes.message'),
          onSave: async () => {
            await currentAccordion.onSubmit();
            if (await currentAccordion.triggerValidation()) {
              await expandPanel();
              await scrollToPanel();
            }
          },
          onDiscard: async () => {
            await expandPanel();
            await scrollToPanel();
          },
        };

        dispatchRedux(pushModal(MODAL_CONFIRM_CHANGES, modalProps));
      };

      if (isAnyAccordionDirty && canEdit) {
        await handleUnsavedChanges();
      } else {
        await expandPanel();
        await scrollToPanel();
      }
    };

  const setDirty = useCallback((panel: string, isDirty: boolean) => {
    dispatch({ type: 'SET_DIRTY', panel, isDirty });
  }, []);

  const setInitState = useCallback((newState: AccordionState) => {
    dispatch({ type: 'INIT_STATE', newState });
  }, []);

  const setOnSubmit = useCallback(
    (panel: string, onSubmit: () => Promise<void> | void) => {
      dispatch({ type: 'SET_ON_SUBMIT', panel, onSubmit });
    },
    []
  );

  const setTriggerValidation = useCallback(
    (panel: string, triggerValidation: () => Promise<boolean> | boolean) => {
      dispatch({ type: 'SET_TRIGGER_VALIDATION', panel, triggerValidation });
    },
    []
  );

  return {
    state,
    setExpanded,
    setDirty,
    setInitState,
    setOnSubmit,
    setTriggerValidation,
  };
};
