import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { brainRules, errorMessage } from '@/util/validator';

import styles from './styles.module.scss';

type Form = {
  inactivity_timeout: string;
};
interface Props {
  brain?: Brain;
  maxInactivityTimeout?: number;
}

const InactivityTimeoutFieldset = ({ brain, maxInactivityTimeout }: Props) => {
  const { t } = useTranslation();
  const brain_id = brain?.brain_id;
  const { updateBrain, updateStatus } = useBrains(brain_id);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<Form>({ mode: 'onChange' });

  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      updateBrain({
        inactivity_timeout: parseInt(form?.inactivity_timeout, 10),
        brain_id: brain?.brain_id,
      });
    },
    [brain?.brain_id, updateBrain]
  );

  useEffect(() => {
    if (brain?.brain_id) {
      reset({
        inactivity_timeout: brain?.inactivity_timeout?.toString(),
      });
    }
  }, [brain?.inactivity_timeout, brain?.brain_id, reset]);

  return (
    <div className={styles.brain_settings_fields}>
      <FormFieldset
        title={t('brains.inactivity.title')}
        subtitle={t('brains.inactivity.subtitle')}
        primaryButton={{
          children: t('common.save'),
          isLoading: updateStatus === 'pending',
          disabled: !isDirty,
        }}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={!brain}
      >
        <Input
          name="inactivity_timeout"
          label={t('common.seconds')}
          register={register(
            'inactivity_timeout',
            brainRules.inactivityTimeout(maxInactivityTimeout)
          )}
          errorMessage={errorMessage({
            field: errors.inactivity_timeout,
            maxValue: maxInactivityTimeout,
            minValue: 60,
          })}
          placeholder=""
          size="small"
          type="number"
          readOnly={!canWrite}
        />
      </FormFieldset>
    </div>
  );
};
export default InactivityTimeoutFieldset;
