import { Fragment, PropsWithChildren, useCallback, useRef } from 'react';

import Skeleton from '@mui/material/Skeleton';
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';

interface Props<T = unknown> {
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<T, unknown>, Error>>;
  hasNextPage: boolean;
  skeleton?: React.ReactNode;
}

const defaultSkeleton = <Skeleton height={40} width="100%" />;

export const TableInfiniteLoader = ({
  children,
  fetchNextPage,
  hasNextPage,
  skeleton = defaultSkeleton,
}: PropsWithChildren<Props>) => {
  const loadMoreRef = useRef();

  const handleNextPageFetch = useCallback(() => {
    return fetchNextPage();
  }, [fetchNextPage]);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: handleNextPageFetch,
  });

  return (
    <>
      {children}
      {hasNextPage && <div ref={loadMoreRef}>{skeleton}</div>}
    </>
  );
};
