import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { AmendPayload } from '@/models/tryIt';
import { Standalone as StandaloneIcon } from '@/modules/TryIt/Icons/Standalone';
import { TriggerMessageIcon } from '@/modules/TryIt/Icons/TriggerMessageIcon';
import {
  selectStandaloneQuestion,
  selectIsStandaloneAltered,
} from '@/modules/TryIt/redux/selectors';

import { AmendWrapper } from '../../Messages/AmendWrapper/AmendWrapper';

import styles from './DetailsSection.module.scss';

interface Props {
  userMessage: string;
  amendPayload: AmendPayload;
  isAmended?: boolean;
}

export const StandaloneWrapper = ({
  userMessage,
  amendPayload,
  isAmended,
}: Props) => {
  const { t } = useTranslation();
  const standaloneQuestion = useSelector(selectStandaloneQuestion);
  const isStandaloneAltered = useSelector(selectIsStandaloneAltered);
  const { html } = useMarkdownToHtml(standaloneQuestion);

  return (
    <div className={styles.step}>
      {isStandaloneAltered ? (
        <>
          <div className={styles.title}>
            <TriggerMessageIcon />
            <Typography color="var(--text-default-gray)">
              {t('try_it.rephrased_by_the_virtual_agent')}
            </Typography>
          </div>
          <div className={styles.details}>
            <Box className={styles.standalone}>
              <StandaloneIcon />
              <Typography
                component="span"
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            </Box>
            <div className={styles.amendContainer}>
              <AmendWrapper
                amendPayload={amendPayload}
                position="left"
                isAmended={isAmended}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.title}>
            <TriggerMessageIcon />
            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              className={styles.triggerMessage}
              component="div"
            >
              {userMessage}
            </Typography>
          </div>
          <div className={styles.details}>
            {standaloneQuestion && (
              <div className={styles.amendContainer}>
                <AmendWrapper
                  amendPayload={amendPayload}
                  position="left"
                  isAmended={isAmended}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
