import { Icon } from '@/models/icon';

const Response = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
    >
      <path
        d="M4.667 5.333h6.666-6.666Zm0 2.667h2.666-2.666ZM8 13.333l-2.667-2.666h-2A1.333 1.333 0 0 1 2 9.333V4a1.333 1.333 0 0 1 1.333-1.333h9.334A1.333 1.333 0 0 1 14 4v5.333a1.333 1.333 0 0 1-1.333 1.334h-2L8 13.333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Response;
