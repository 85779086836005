import { useMemo } from 'react';

import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  datasourceEndpoints,
  documentsEndpoints as endpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import {
  Datasource,
  Document,
  DocumentStatus,
  Documents,
  PartialDocument,
} from '@/models/collections';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';

export const API = Object.freeze({
  listDocuments: async (
    collectionId: string,
    datasourceId: string,
    cursor?: { cursor: string },
    filter?: string
  ): Promise<Documents> =>
    callGet(
      endpoints.documents(collectionId, datasourceId, cursor?.cursor, filter)
    ),

  getDocument: async (
    collectionId: string,
    datasourceId: string,
    document_id: string
  ): Promise<Document> =>
    callGet(endpoints.document(collectionId, datasourceId, document_id)),

  createDocument: async (
    collectionId: string,
    datasourceId: string,
    document: Partial<Document>
  ): Promise<Document> =>
    callPost(endpoints.documents(collectionId, datasourceId), document),

  updateDocument: async (
    collectionId: string,
    datasource_id: string,
    { document_id, ...document }: PartialDocument
  ): Promise<Document> =>
    callPut(
      endpoints.document(collectionId, datasource_id, document_id),
      document
    ),

  deleteDocument: async (
    collectionId: string,
    datasourceId: string,
    documentId: string
  ): Promise<Document> =>
    callDelete(endpoints.document(collectionId, datasourceId, documentId)),
});

export const onDocumentUpdated = (
  queryClient: QueryClient,
  collectionId: string,
  datasourceId: string,
  document: Document
) => {
  const queryKey = [endpoints.documents(collectionId, datasourceId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData(queryKey, (prev: InfiniteData<Documents>) => {
      if (!prev) return prev;

      return {
        ...prev,

        pages: prev.pages.map((page) => ({
          ...page,
          documents: page.documents.map((acc) =>
            acc.document_id === document.document_id ? document : acc
          ),
        })),
      };
    });
  }
};

export const onDocumentCreated = (
  queryClient: QueryClient,
  collectionId: string,
  datasourceId: string,
  document: Document
) => {
  const queryKey = [endpoints.documents(collectionId, datasourceId)];
  const queryKeyDatasources = datasourceEndpoints.datasource(
    collectionId,
    datasourceId
  );

  queryClient.setQueryData<Datasource>([queryKeyDatasources], (prev) => {
    if (document.status === DocumentStatus.AVAILABLE) {
      return {
        ...prev,
        document_count: prev.document_count + 1,
      };
    } else {
      return {
        ...prev,
        document_count: prev.document_count + 1,
        [`${document.status}_count`]: prev[`${document.status}_count`] + 1,
      };
    }
  });

  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData(queryKey, (prev: InfiniteData<Documents>) => {
      if (!prev) return prev;

      const newPages = prev.pages.map((page, index) => {
        if (index === 0) {
          return {
            ...page,
            documents: [
              document,
              ...page.documents.filter(
                (acc) => acc.document_id !== document.document_id
              ),
            ],
          };
        }
        return page;
      });

      return {
        ...prev,
        pages: newPages,
      };
    });
  }
};

export const onDocumentRemoved = (
  queryClient: QueryClient,
  collectionId: string,
  datasourceId: string,
  document: Document,
  filter?: string
) => {
  const queryKey = [endpoints.documents(collectionId, datasourceId), filter];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData(queryKey, (prev: InfiniteData<Documents>) => {
      if (!prev) {
        return prev;
      }
      return {
        ...prev,
        pages: prev.pages.map((page) => ({
          ...page,
          documents: page.documents.filter(
            (acc) => acc.document_id !== document.document_id
          ),
        })),
      };
    });
  }

  queryClient.removeQueries({
    queryKey: [
      endpoints.document(collectionId, datasourceId, document.document_id),
    ],
  });

  const queryKeyDatasources = datasourceEndpoints.datasource(
    collectionId,
    datasourceId
  );

  queryClient.setQueryData<Datasource>([queryKeyDatasources], (prev) => {
    if (document.status === DocumentStatus.AVAILABLE) {
      return {
        ...prev,
        document_count: prev.document_count - 1,
      };
    } else {
      return {
        ...prev,
        document_count: prev.document_count - 1,
        [`${document.status}_count`]: prev[`${document.status}_count`] - 1,
      };
    }
  });
};

const useDocuments = (
  collectionId: string,
  datasourceId: string,
  documentId?: string,
  filter = ''
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.READ)
  );

  const {
    data: documents,
    status: listStatus,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [endpoints.documents(collectionId, datasourceId), filter],
    queryFn: async ({ pageParam }) => {
      return API.listDocuments(collectionId, datasourceId, pageParam, filter);
    },
    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
    enabled: !!collectionId && !!datasourceId && canRead,
    refetchInterval: 7 * 1000 /* 7 seconds */,
  });

  const { data: document, status: getStatus } = useQuery<Document, Error>({
    queryKey: [endpoints.document(collectionId, datasourceId, documentId)],
    queryFn: () => API.getDocument(collectionId, datasourceId, documentId),
    enabled: !!collectionId && !!datasourceId && !!documentId,
  });

  const flatDocuments = useMemo(
    () => [...(documents?.pages ?? [])].flatMap((page) => page.documents),
    [documents?.pages]
  );

  const { mutate: updateDocument, status: updateStatus } = useMutation<
    Document,
    Error,
    PartialDocument
  >({
    mutationFn: (data) => API.updateDocument(collectionId, datasourceId, data),
    onSuccess: (resp) => {
      onDocumentUpdated(queryClient, collectionId, datasourceId, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('collections.documents.updated', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createDocument, status: createStatus } = useMutation<
    Document,
    Error,
    Partial<Document>
  >({
    mutationFn: (data) => API.createDocument(collectionId, datasourceId, data),
    onSuccess: (resp) => {
      onDocumentCreated(queryClient, collectionId, datasourceId, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('collections.documents.created', { 0: resp?.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: deleteDocument,
    mutateAsync: deleteDocumentAsync,
    status: deleteStatus,
  } = useMutation<Document, Error, string>({
    mutationFn: (id) =>
      API.deleteDocument(collectionId, datasourceId, id ?? documentId),
    onSuccess: (resp) => {
      const document = flatDocuments.find(
        (d) => resp.document_id === d.document_id
      );
      onDocumentRemoved(
        queryClient,
        collectionId,
        datasourceId,
        document,
        filter
      );
      dispatch(addTemporalToast('success', t('collections.documents.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const documentNames = useMemo(
    () => flatDocuments?.map((d) => d.name),
    [flatDocuments]
  );

  return {
    documents: flatDocuments,
    fetchNextPage,
    hasNextPage,
    document,
    getStatus,
    listStatus,
    createStatus,
    deleteStatus,
    updateStatus,
    createDocument,
    deleteDocument,
    deleteDocumentAsync,
    updateDocument,
    documentNames,
  };
};

export default useDocuments;
