import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

export const IconBroadcast = forwardRef<SVGSVGElement, Icon>(
  ({ size = 16, color = 'currentColor', ...rest }, ref) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
        ref={ref}
      >
        <path
          d="M15.5 7.5a3.003 3.003 0 0 0-3-3h-2.488c-.181-.01-3.35-.234-6.369-2.765A1 1 0 0 0 2 2.5v10a1 1 0 0 0 1.643.766c2.36-1.98 4.813-2.548 5.857-2.706v1.982a1 1 0 0 0 .445.833l.688.458a1 1 0 0 0 1.523-.583l.736-2.772A3.004 3.004 0 0 0 15.5 7.5ZM3 12.496V2.5c2.676 2.245 5.414 2.813 6.5 2.953v4.092c-1.084.143-3.822.71-6.5 2.95Zm8.188.5v.007l-.688-.458V10.5h1.35l-.662 2.496ZM12.5 9.5h-2v-4h2a2 2 0 0 1 0 4Z"
          fill={color}
        />
      </svg>
    );
  }
);

IconBroadcast.displayName = 'IconBroadcast';
