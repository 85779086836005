import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Context } from '@/models/chat';
import {
  DebugStep,
  NodeType,
  TryIt as State,
  TryItCollection,
} from '@/models/tryIt';

export const defaultState: State = {
  isOpen: false,
  selectedNodeId: null,
  selectedActionId: null,
  selectedRequisiteIndex: null,
  selectedIfNoRequisite: null,
  selectedMessage: {
    nodes: [],
    context: {},
    debugLogs: [],
    index: null,
  },
  context: [{}],
  isReplay: false,
  sessionId: null,
};

const slice = createSlice({
  name: 'tryIt',
  initialState: defaultState,
  reducers: {
    toggleTryIt: (state) => ({ ...state, isOpen: !state.isOpen }),
    openTryIt: (state) => ({ ...state, isOpen: true }),
    closeTryIt: (state) => ({ ...state, isOpen: false }),
    updateSelectedMessage: (
      state,
      action: PayloadAction<{
        selectedMessage: {
          nodes: NodeType[];
          context: Context;
          debugLogs: string[];
          index: number | null;
          collection?: TryItCollection;
          steps?: DebugStep[];
        };
        isReplay?: boolean;
      }>
    ) => {
      state.selectedMessage = {
        ...state.selectedMessage,
        ...action.payload.selectedMessage,
      };
    },
    selectTryItNode: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedNodeId: action.payload,
      selectedActionId: null,
      selectedRequisiteIndex: null,
      selectedIfNoRequisite: null,
    }),
    selectTryItAction: (
      state,
      action: PayloadAction<{ node_id: string; action_id: string }>
    ) => ({
      ...state,
      selectedActionId: action.payload.action_id,
      selectedNodeId: action.payload.node_id,
      selectedRequisiteIndex: null,
      selectedIfNoRequisite: null,
    }),
    selectTryItRequisite: (
      state,
      action: PayloadAction<{
        node_id: string;
        requisite_idx: number;
        if_no_requisite?: true;
      }>
    ) => ({
      ...state,
      selectedNodeId: action.payload.node_id,
      selectedActionId: null,
      selectedIfNoRequisite: action.payload.if_no_requisite ?? null,
      selectedRequisiteIndex: action.payload.requisite_idx ?? null,
    }),
    resetSelections: (state) => ({
      ...state,
      selectedNodeId: null,
      selectedActionId: null,
      selectedRequisiteIndex: null,
      selectedIfNoRequisite: null,
    }),
    updateContext: (state, action: PayloadAction<Context[]>) => ({
      ...state,
      context: action.payload,
    }),
    updateIsReplay: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isReplay: action.payload,
    }),
    resetSelectedMessage: (state) => ({
      ...state,
      selectedMessage: {
        nodes: [],
        context: {},
        debugLogs: [],
        steps: [],
        index: null,
        collection: {},
      },
    }),
    updateSessionId: (state, action: PayloadAction<string>) => ({
      ...state,
      sessionId: action.payload,
      context: [{}],
    }),
    resetTryIt: () => defaultState,
  },
});

// Extract and export each action creator by name
export const {
  toggleTryIt,
  openTryIt,
  closeTryIt,
  updateIsReplay,
  updateSelectedMessage,
  selectTryItNode,
  selectTryItAction,
  selectTryItRequisite,
  updateContext,
  resetSelections,
  resetSelectedMessage,
  updateSessionId,
  resetTryIt,
} = slice.actions;

// Export the reducer, either as a default or named export
export default slice.reducer;
