import { useEffect } from 'react';

import Box from '@mui/material/Box/Box';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Banner } from '@/components/atoms/Banner/Banner';
import Input from '@/components/atoms/Input/Input';
import Link from '@/components/atoms/Link/Link';
import { usePreferences } from '@/hooks/usePreferences';
import { ZENDESK_DOCS_URL } from '@/util/constants';
import { datasourceRules, errorMessage } from '@/util/validator';

import { Form } from '../utils';

import styles from '../ModalDatasourceAdd.module.scss';

interface Props {
  setEnablePrimaryButton: (enabled: boolean) => void;
}

const ConfigureZendesk = ({ setEnablePrimaryButton }: Props) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isValid },
  } = useFormContext<Form>();

  const { userPreferences } = usePreferences();
  const userLanguage =
    userPreferences?.language === 'en'
      ? 'en-us'
      : userPreferences?.language || 'en-us';

  useEffect(() => {
    setEnablePrimaryButton(isValid);
  }, [isValid, setEnablePrimaryButton]);

  return (
    <div>
      <Box mb={3}>
        <Banner relativePosition variant="neutral">
          <Trans i18nKey="collections.zendesk_token_help" />{' '}
          <Link
            href={`${ZENDESK_DOCS_URL}/hc/${userLanguage}/articles/4409381383578-Where-can-I-find-my-Zendesk-subdomain`}
            external
          >
            {t('common.here')}
          </Link>
          .
        </Banner>
      </Box>

      <div className={cn('input__container', styles.input__container)}>
        <div className={styles.input__wrapper}>
          <Input
            register={register(
              'zendesk.subdomain',
              datasourceRules.zendesk.subdomain
            )}
            label={t('integrations.zendesk.auth.subdomain')}
            placeholder={t('integrations.zendesk.auth.subdomain_placeholder')}
            size="large"
            trimValue
            errorMessage={errorMessage({
              field: errors?.zendesk?.subdomain,
            })}
          />
          <Input size="large" value=".zendesk.com" readOnly tabIndex={-1} />
        </div>
        {/* TODO  update those when we  show them on another step* /}
        {/* <Input
          register={register('zendesk.email', datasourceRules.zendesk.email)}
          label={t('field.email')}
          placeholder={t('collections.type_email')}
          size="large"
          trimValue
          errorMessage={errorMessage({
            field: errors?.zendesk?.email,
          })}
        />
        <Input
          register={register(
            'zendesk.access_token',
            datasourceRules.zendesk.access_token
          )}
          label={t('collections.api_token')}
          placeholder={t('collections.zendesk_api_token')}
          size="large"
          trimValue
          errorMessage={errorMessage({
            field: errors?.zendesk?.access_token,
          })}
        /> */}
      </div>
    </div>
  );
};

export default ConfigureZendesk;
