import { ReactElement } from 'react';

import ExclamationMark from '@/components/atoms/Icons/Alert';
import Info from '@/components/atoms/Icons/Info';
import Success from '@/components/atoms/Icons/Success';

import { ErrorIcon } from './Error';

export type Variants = keyof typeof IconsByState;

export const IconsByState: Record<string, ReactElement> = {
  neutral: <Info backgroundColor="var(--surface-secondary-blue-dark)" />,
  success: <Success />,
  info: <Info backgroundColor="var(--icon-default-blue)" />,
  warning: <ExclamationMark />,
  attention: <ExclamationMark color="var(--icon-default-attention)" />,
  critical: <ErrorIcon />,
} as const;

export const backgroundColorGetter = (variant: string) => {
  switch (variant) {
    case 'neutral':
      return 'var(--surface-secondary-gray)';
    case 'success':
      return 'var(--surface-status-success-dark)';
    case 'info':
      return 'var(--surface-status-info-dark)';
    case 'warning':
      return 'var(--surface-status-warning-dark)';
    case 'attention':
      return 'var(--surface-status-attention-dark)';
    case 'critical':
      return 'var(--surface-status-critical-dark)';
    default:
      return 'var(--surface-secondary-blue-dark)';
  }
};

export const primaryBackgroundColorGetter = (variant: string) => {
  switch (variant) {
    case 'neutral':
      return 'var(--surface-secondary-blue-dark)';
    case 'success':
      return 'var(--icon-default-success)';
    case 'info':
      return 'var(--icon-default-blue)';
    case 'warning':
      return 'var(--icon-default-warning)';
    case 'attention':
      return 'var(--icon-default-attention)';
    case 'critical':
      return 'var(--icon-default-error)';
    default:
      return 'var(--surface-secondary-blue-dark)';
  }
};
