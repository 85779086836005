import { Props } from '@/models/icon';

export const AutoRouteIcon = ({
  size = 24,
  color = 'var(--icon-default-gray)',
  ...props
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M18.482 3.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-2.797 4.749a3.75 3.75 0 1 0-.756-1.295L9.278 10a3.75 3.75 0 1 0 0 4.997l5.653 3.294a3.75 3.75 0 1 0 .758-1.294l-5.654-3.295c.127-.378.197-.782.197-1.203 0-.421-.07-.826-.198-1.204L15.685 8Zm-7.284 3.326a.796.796 0 0 0 .05.085c.179.323.28.694.28 1.09a2.24 2.24 0 0 1-.332 1.177A2.249 2.249 0 0 1 4.232 12.5 2.25 2.25 0 0 1 8.4 11.325Zm7.83 8.175c0-.375.092-.728.254-1.04a.756.756 0 0 0 .113-.19 2.25 2.25 0 1 1-.366 1.23Z"
      clipRule="evenodd"
    />
  </svg>
);
