import { Icon } from '@/models/icon';

const Close = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      width={size}
      height={size}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 6 6 18M6 6l12 12"
      />
    </svg>
  );
};

export default Close;
