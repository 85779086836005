import { TypographyOptions } from '@mui/material/styles/createTypography';

const BASE_STYLES = {
  fontSize: 'var(--space-32)',
  letterSpacing: '0',
  lineHeight: '1.2',
};

const typography = {
  ['h1-regular']: {
    ...BASE_STYLES,
    fontWeight: '400',
  },
  ['h1-semi-bold']: {
    ...BASE_STYLES,
    fontWeight: '600',
  },
  ['h1-bold']: {
    ...BASE_STYLES,
    fontWeight: '700',
  },
} as TypographyOptions;

export { typography };
