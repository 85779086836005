import { Icon } from '@/models/icon';

const LongArrowUp = ({ size = 12, color = '#787878' }: Icon) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
    >
      <path
        d="M8 2v12M3 6.667 8 2 3 6.667ZM8 2l5 4.667L8 2Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LongArrowUp;
