import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ImageCrop, { AspectRatio } from '@/components/atoms/ImageCrop/ImageCrop';
import { ExtraUploadConfig } from '@/hooks/useFileUpload';
import { popModal } from '@/redux/modals/actions';

import Modal from './Modal';

type Props = {
  onEdit: (data) => void;
  image: string;
  aspectRatio?: AspectRatio;
  extraConfig?: ExtraUploadConfig;
};

export default function ModalImageCrop({
  onEdit,
  image,
  aspectRatio,
  extraConfig,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [croppedImage, setCroppedImage] = useState();

  const onImageCrop = useCallback((newImage) => {
    setCroppedImage(newImage);
  }, []);

  const onSubmit = useCallback(() => {
    const data = {
      avatar: croppedImage,
    };
    try {
      onEdit(data);
    } catch (error) {
      console.error(error);
      return;
    }
    dispatch(popModal());
  }, [dispatch, croppedImage, onEdit]);

  return (
    <Modal
      aria-labelledby={t('modals.image_crop.resize_image')}
      title={t('modals.image_crop.resize_image')}
      primaryButtonText={t('modals.image_crop.set_avatar')}
      onPrimarySubmit={onSubmit}
      {...extraConfig}
    >
      <ImageCrop
        file={image}
        onUpload={onImageCrop}
        aspectRatio={aspectRatio}
      />
    </Modal>
  );
}
