import { WebhookTestParameters } from '@/models/webhook';
export const STATUS_URL = 'https://status.moveo.ai?utm_source=console';

export const getDocsUrl = (url = '') =>
  `https://docs.moveo.ai/${url}?utm_source=console`;

export const DISAMBIGUATION_DOCS_URL =
  'https://docs.moveo.ai/docs/get_started/disambiguation_overview?utm_source=console';
export const BRAIN_DOCS_URL = getDocsUrl('/docs/get_started/brains');
export const INTENTS_DOCS_URL = getDocsUrl('/docs/get_started/intents');
export const LANGUAGE_MODELS_DOCS_URL = getDocsUrl(
  '/docs/get_started/developer_tools_language_models'
);

export const EVENT_RESPONSE_DOCS_URL =
  'https://docs.moveo.ai/docs/get_started/trigger_event?utm_source=console';
export const EVENT_RESOLVE_DOCS_URL =
  'https://docs.moveo.ai/docs/get_started/response_resolve?utm_source=console';
export const EVENT_UNKNOWN_DOCS_URL =
  'https://docs.moveo.ai/docs/get_started/trigger_unknown?utm_source=console';
export const GOOGLE_SHEET_DOCS_URL =
  'https://docs.moveo.ai/docs/get_started/response_googlesheet?utm_source=console';
export const JUMP_TO_DOCS_URL =
  'https://docs.moveo.ai/docs/get_started/response_event?utm_source=console';
export const REPLAY_RESPONSE_DOCS_URL =
  'https://docs.moveo.ai/docs/get_started/response_replay?utm_source=console';
export const CHAT_URL = 'https://docs.moveo.ai?utm_source=console';
export const SUPPORT_URL = 'https://docs.moveo.ai?utm_source=console';
export const DOCS_API_URL = 'https://docs.moveo.ai/api?utm_source=console';
export const getLandingPageUrl = (url = '') =>
  `https://moveo.ai${url}?utm_source=console`;
export const CONTACT_SALES_URL =
  'https://meetings-eu1.hubspot.com/angelos-tselioudis/moveo-meeting?utm_source=console';
export const EXCLUDED_URLS_URL =
  'https://docs.moveo.ai/docs/get_started/collections-website#excluded-urls?utm_source=console';
export const SEED_URLS_URL =
  'https://docs.moveo.ai/docs/get_started/collections-website#seed-urls?utm_source=console';
export const API_KEYS_DOCS_URL = getDocsUrl(
  '/docs/get_started/developer_tools_api_keys'
);
export const PRICING_URL =
  'https://moveo.ai/pricing?utm_source=console&utm_medium=billing';

export const PRIVACY_POLICY_URL =
  'https://moveo.ai/privacy-policy/?utm_source=console';
export const TERMS_OF_USE_URL =
  'https://moveo.ai/terms-of-use/?utm_source=console';
export const SUBSCRIPTION_AGREEMENT_URL =
  'https://moveo.ai/subscription-agreement/?utm_source=console';
export const API_HOST = 'https://api.moveo.ai?utm_source=console';
export const ZENDESK_DOCS_URL = 'https://support.zendesk.com';

export const INTERCOM_ENDPOINT = 'https://api.intercom.io';

export const WEB_CLIENT_URL = 'https://web-client.moveo.ai';

export const SIGNUP_TRIAL_IN_DAYS = parseInt(
  window.SIGNUP_TRIAL_IN_DAYS || '30'
);
export const API_URL =
  process.env.NODE_ENV === 'test' ? 'https://localhost:5000' : '';

// Set the key at runtime or use the test key
export const MAGIC_PUBLIC_KEY =
  window.MAGIC_PUBLIC_KEY || 'pk_live_7FFA71AB497A7488';

export const STRIPE_PUBLIC_KEY =
  window.STRIPE_PUBLIC_KEY === 'undefined;'
    ? undefined
    : window.STRIPE_PUBLIC_KEY;

export const CHANNELS_URL: string =
  window.CHANNELS_URL || 'https://channels.dev.moveo.ai';

export const IMAGE_TAG: string = window.IMAGE_TAG || 'local';

export const CUSTOM_INSTRUCTIONS_LENGTH: number = parseInt(
  window.CUSTOM_INSTRUCTIONS_LENGTH || '1000'
);

export const WHATSAPP_BRAIN_TEST_URL: string =
  window.WHATSAPP_BRAIN_TEST_URL ||
  'https://wa.me/15557010447?text=defaultstartmessage';

export const WHATSAPP_OAUTH_CONFIG_ID =
  window.WHATSAPP_OAUTH_CONFIG_ID || '1127552041570920';

export const ISO = 'ISO 27001:2013';
export const ISO_BLOG_POST = 'https://moveo.ai/blog/iso-27001-certification/';

let channels = [];
try {
  channels = JSON.parse(window.CHANNELS_SUPPORTED || '[]');
} catch (error) {
  console.debug('WARN: Cannot parse CHANNELS_SUPPORTED');
}
export const CHANNELS_SUPPORTED: string[] = channels;

let collectionLanguages = [];
try {
  collectionLanguages = JSON.parse(window.COLLECTION_LANGUAGES || '[]');
} catch (error) {
  console.debug('WARN: Cannot parse COLLECTION_LANGUAGES');
}
export const COLLECTION_LANGUAGES: string[] = collectionLanguages;

let uiLanguages = [];
try {
  uiLanguages = JSON.parse(window.UI_LANGUAGES || '[]');
} catch (error) {
  console.debug('WARN: Cannot parse CHANNELS_SUPPORTED');
}
export const UI_LANGUAGES: string[] = uiLanguages;

export const ACCOUNT_ID_COOKIE_KEY = 'accountId';

export const MOVEO_INTEGRATION_ID = '540e38c4-8064-40e2-9a00-cf26e7560c4a';

// UI constants
export const sidebarWidth = 200;
export const subNavWidth = 240;
export const toolkitWidth = 240;

export const roles = [
  { value: 'owner', label_key: '', disabled: true },
  { value: 'admin', label_key: 'invitations.roles.adminDesc' },
  { value: 'writer', label_key: 'invitations.roles.writerDesc' },
  { value: 'reader', label_key: 'invitations.roles.readerDesc' },
];

export const ADMIN = 'facade00-0000-4000-a000-000000000003';
export const CHAT_MANAGER = 'facade00-0000-4000-a000-000000000006';
export const CHAT_AGENT = 'facade00-0000-4000-a000-000000000005';
export const BUILDER = 'facade00-0000-4000-a000-000000000004';
export const READER = 'facade00-0000-4000-a000-000000000001';
export const WRITER = 'facade00-0000-4000-a000-000000000002';
export const BILLING = 'facade00-0000-4000-a000-000000000008';

export const systemRoles = [ADMIN, CHAT_MANAGER, BUILDER, CHAT_AGENT];

export const MAX_UPLOAD_SIZE = 10000000;

export const USER_ID_MOCK = 'fce10bf0-4031-11ee-be56-0242ac120002';
export const USER_ID_MOCK_2 = 'fce10bf0-4031-11ee-be56-0242ac120003';
export const BRAIN_ID_MOCK = '22222222-2222-2222-2222-222222222222';
export const WEBHOOK_ID_MOCK = '55555555-5555-5555-5555-555555555555';
export const DESK_ID_MOCK = '33333333-3333-3333-3333-333333333333';
export const SESSION_ID_MOCK = '77777777-7777-7777-7777-777777777777';
export const INTEGRATION_ID_MOCK = '88888888-8888-8888-8888-888888888888';
export const ACCOUNT_ID_MOCK = '00000000-0000-0000-0000-000000000000';
export const ACCOUNT_SLUG_MOCK = 'team-disney';
export const DIALOG_ID_MOCK = '44444444-4444-4444-4444-444444444444';
export const MESSAGE_ID_MOCK = '99999999-9999-9999-9999-999999999999';
export const RULE_ID_MOCK = '66666666-6666-6666-6666-666666666666';
export const MACRO_ID_MOCK = '10101010-1010-1010-1010-101010101010';
export const COLLECTION_ID_MOCK = '12121212-1212-1212-1212-121212121212';
export const DATASOURCE_ID_MOCK = '13131313-1313-1313-1313-131313131313';
export const BROADCAST_ID_MOCK = '14141414-1414-1414-1414-141414141414';

export const getZendeskAuthUrl = (s: string) =>
  `https://${s}.zendesk.com/oauth2/chat/authorizations/new`;

export const FACEBOOK_API_VERSION = process.env.FACEBOOK_API_VERSION || 'v21.0';
export const FACEBOOK_AUTHORIZATION_URL = `https://www.facebook.com/${FACEBOOK_API_VERSION}/dialog/oauth`;

export const SUNCO_AUTHORIZATION_URL = 'https://api.smooch.io/oauth/authorize';

export const FACEBOOK_SCOPES = [
  'public_profile',
  'email',
  'pages_messaging',
  'business_management',
  'pages_manage_metadata',
  'pages_read_engagement',
];

export const INSTAGRAM_SCOPES = [
  'public_profile',
  'email',
  'pages_messaging',
  'business_management',
  'pages_manage_metadata',
  'pages_read_engagement',
  'instagram_basic',
  'instagram_manage_messages',
  'pages_show_list',
];

export const INTERCOM_AUTHORIZATION_URL = 'https://app.intercom.com/oauth';

export const FRONT_AUTHORIZATION_URL =
  'https://app.frontapp.com/oauth/authorize';

export const SYSTEM_INTENTS = ['offtopics'];

export const PAGINATION_LIMIT = 300;
export const INFINITE_SCROLLING_LIMIT = 25;

export const X_MOVEO_ACCOUNT_ID = 'X-Moveo-Account-id';
export const X_MOVEO_ACCOUNT_SLUG = 'X-Moveo-Account-Slug';

export const testWebhookValues: WebhookTestParameters = {
  context: {
    user: {
      display_name: 'John Doe',
      email: 'john@example.com',
      user_id: 'V1StGXR8_Z5jdHi6B',
      external_id: 'IRFa-VaY2b',
      language: 'en',
    },
    total_months: 3,
    destination: 'England',
    'sys-unknown_counter': 0,
    'sys-user_message_counter': 1,
    'sys-channel': 'web',
    total_expenses: 500,
    tags: ['premium', 'vip'],
  },
  business_closed: false,
  lang: 'en',
  channel: 'web',
  trigger: {
    text: 'Hello, my name is George and I would like to go for erasmus',
    intents: [
      {
        intent: 'erasmus',
        confidence: 0.89,
      },
    ],
    entities: [
      {
        entity: 'firstname',
        value: 'George',
        start: 18,
        end: 36,
        confidence: 0.95,
        type: 'pattern',
      },
    ],
  },
};

export const testWebhookSchemas = [
  {
    uri: 'webhook.json',
    fileMatch: ['*'],
    schema: {
      type: 'object',
      properties: {
        context: {
          type: 'object',
          properties: {
            user: {
              type: 'object',
              properties: {
                display_name: {
                  type: 'string',
                },
                email: {
                  type: 'string',
                },
                user_id: {
                  type: 'string',
                },
                external_id: {
                  type: 'string',
                },
                language: {
                  type: 'string',
                },
              },
              required: [
                'display_name',
                'email',
                'user_id',
                'external_id',
                'language',
              ],
            },
            'sys-unknown_counter': {
              type: 'integer',
            },
            'sys-user_message_counter': {
              type: 'integer',
            },
            'sys-channel': {
              type: 'string',
            },
            tags: {
              type: 'array',
              items: {
                $ref: 'tag.json',
              },
            },
          },
          required: [
            'user',
            'sys-unknown_counter',
            'sys-channel',
            'sys-user_message_counter',
            'tags',
          ],
        },
        business_closed: {
          type: 'boolean',
        },
        lang: {
          type: 'string',
        },
        channel: {
          type: 'string',
        },
        trigger: {
          type: 'object',
          properties: {
            text: {
              type: 'string',
            },
            intents: {
              type: 'array',
              items: [
                {
                  type: 'object',
                  properties: {
                    intent: {
                      type: 'string',
                    },
                    confidence: {
                      type: 'number',
                    },
                  },
                  required: ['intent', 'confidence'],
                },
              ],
            },
            entities: {
              type: 'array',
              items: [
                {
                  type: 'object',
                  properties: {
                    entity: {
                      type: 'string',
                    },
                    value: {
                      type: 'string',
                    },
                    start: {
                      type: 'integer',
                    },
                    end: {
                      type: 'integer',
                    },
                    confidence: {
                      type: 'number',
                    },
                    type: {
                      type: 'string',
                    },
                  },
                  required: [
                    'entity',
                    'value',
                    'start',
                    'end',
                    'confidence',
                    'type',
                  ],
                },
              ],
            },
          },
          anyOf: [
            {
              required: ['text'],
              anyOf: ['intents', 'entities'],
            },
          ],
          additionalProperties: false,
        },
      },
      required: ['context', 'lang', 'channel', 'business_closed', 'trigger'],
    },
  },
  {
    uri: 'tag.json',
    schema: {
      type: 'string',
    },
  },
];

export const INTENTS_MOCK = [
  {
    intent: 'offtopics',
    brain_id: BRAIN_ID_MOCK,
    description: 'System intent',
    metadata: {},
    expressions: ['aaaa'],
    collection: '',
    created: '2020-01-02T00:00:00Z',
    updated: '2020-01-02T00:00:00',
  },
  {
    intent: 'test',
    brain_id: BRAIN_ID_MOCK,
    description: '',
    metadata: {},
    expressions: ['test', 'test'],
    collection: '',
    created: '2020-01-02T00:00:00Z',
    updated: '2020-01-02T00:00:00Z',
  },
];

export const ENTITIES_MOCK = [
  {
    entity: 'entity_name',
    brain_id: BRAIN_ID_MOCK,
    description: '',
    metadata: {},
    expressions: [],
    values: [
      {
        type: 'synonym',
        value: 'mock1',
        synonyms: ['entity1'],
      },
    ],
    fuzzy_match: true,
    collection: '',
    created: '2020-01-02T00:00:00Z',
    updated: '2020-01-02T00:00:00Z',
  },
  {
    entity: 'bbbbbb',
    brain_id: BRAIN_ID_MOCK,
    description: '',
    metadata: {},
    expressions: [],
    values: [
      {
        type: 'synonym',
        value: 'mock2',
        synonyms: ['entity2'],
      },
    ],
    fuzzy_match: true,
    collection: '',
    created: '2020-01-02T00:00:00Z',
    updated: '2020-01-02T00:00:00Z',
  },
];

export const MODERATE_EXECUTION_TIME = 5000;
export const BAD_EXECUTION_TIME = 8000;

export const languageByCountryCode: Record<string, string> = {
  en: 'English',
  es: 'Spanish',
  'pt-br': 'Brazilian Portuguese',
  pt: 'Portuguese',
  zh: 'Chinese',
  ar: 'Arabic',
  bg: 'Bulgarian',
  cs: 'Czech',
  da: 'Danish',
  de: 'German',
  el: 'Greek',
  fr: 'French',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  nl: 'Dutch',
  pl: 'Polish',
  ro: 'Romanian',
  ru: 'Russian',
  sq: 'Albanian',
  sr: 'Serbian',
  sv: 'Swedish',
  tr: 'Turkish',
};

export const MAX_UNCROPED_WEBHOOK_URL_CHARS = 55;
export const MAX_ENDING_URL_CHARS = 25;
export const THREE_DOTS_LENGTH = 3;

export const DEFAULT_COLLECTION = 'Default';

export const MIN_TRAINING_PHRASES = 5;

export const ACCEPTED_VIDEO_FORMATS = {
  'video/mp4': ['.mp4'],
  'video/webm': ['.webm'],
  'video/mpeg': ['.mpeg'],
};

export const ACCEPTED_IMAGE_FORMATS = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
};

export const autoIntentLanguages = ['en', 'pt-br', 'es', 'el', 'fr', 'de'];

export const autoDialogLanguages = ['en', 'el'];

export const MAX_CONVERSATION_TAGS = 8;

export const MAX_EMAIL_RECIPIENTS = 8;

export const MAX_EXPANDED_CONDITIONS = 3;

export const DISPLAYED_TEXT_ACTION_OPTIONS = 2;

export const MAX_TEXT_ACTION_OPTIONS = 15;

export const LAST_ACTIONS = ['replay', 'close', 'event'];

export enum PLAN_IDS {
  ENTERPRISE = 'enterprise',
  PLUS = 'plus',
  PRO = 'pro',
  STARTER = 'starter',
  TRIAL = 'trial',
}

export const FILES_BASE_URL = '/www/files';
export const FIFTEEN_MB = 15000000;
export const HUNDRED_BYTES = 100;
