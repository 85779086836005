import { HTMLAttributes } from 'react';

import { Icon } from '@/models/icon';

export const GoToResponseIcon = ({
  size = 24,
  color = 'var(--icon-default-gray)',
  ...rest
}: Icon & HTMLAttributes<SVGElement>) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11 10.5 13.5 8l2.5 2.5"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 16h3.5a2 2 0 0 0 2-2V8"
      />
    </svg>
  );
};
