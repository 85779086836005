/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';

import Editor from '@monaco-editor/react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import Input from '@/components/atoms/Input/Input';
import KeyValueTable from '@/components/atoms/KeyValueTable/KeyValueTable';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { Webhook, WebhookTestParameters } from '@/models/webhook';
import { errorMessage, webhookRules } from '@/util/validator';

import styles from './Webhook.module.scss';

type Form = {
  name: Webhook['name'];
  url: Webhook['url'];
  token: Webhook['token'];
};

interface Props {
  handleTestClick: () => void;
  tabIndex: string;
  testStatus: string;
  register: UseFormRegister<Form>;
  errors: {
    name?: FieldError;
    url?: FieldError;
    token?: FieldError;
  };
  webhook: Webhook;
  handleSetHeadersObj: (headersObj: { [key: string]: string }) => void;
  handleToggleHeaders: () => void;
  isHeadersChecked: boolean;
  handleEditorDidMount: (editor: any, monaco: any) => void;
  headersObj: { [key: string]: string };
  editorErrors: any;
  setEditorErrors: (markers: any) => void;
  defaultValue: WebhookTestParameters;
  handleEditorChange: (newValue: string) => void;
  readOnly?: boolean;
}

const ConfigSection = ({
  handleTestClick,
  tabIndex,
  testStatus,
  register,
  errors,
  webhook,
  readOnly = false,
  handleSetHeadersObj,
  handleToggleHeaders,
  isHeadersChecked,
  handleEditorDidMount,
  headersObj,
  editorErrors,
  setEditorErrors,
  defaultValue,
  handleEditorChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {tabIndex === '0' && (
        <>
          <div className={styles.testButton}>
            <Button
              variant="secondary"
              onClick={handleTestClick}
              disabled={readOnly}
              isLoading={testStatus === 'pending'}
              data-testid="send_test_button"
            >
              {t('webhook.config.send_test')}
            </Button>
          </div>
          <div className="input__container">
            <Input
              name="name"
              label={t('webhook.config.name')}
              readOnly={readOnly}
              placeholder={t('common.name_placeholder')}
              size="large"
              register={register('name', webhookRules.name)}
              errorMessage={errorMessage({
                field: errors.name,
                ...webhookRules.name,
              })}
              defaultValue={webhook?.name}
              test-id="webhook-name"
            />
          </div>
          <div className="input__container">
            <Input
              name="url"
              label={t('webhook.config.url')}
              readOnly={readOnly}
              placeholder={t('webhook.config.url_placeholder')}
              register={register('url', webhookRules.url)}
              errorMessage={errorMessage({
                field: errors.url,
                ...webhookRules.url,
              })}
              defaultValue={webhook?.url}
            />
          </div>
          <div className="input__container">
            <Input
              name="token"
              label={t('webhook.config.token')}
              readOnly={readOnly}
              placeholder={t('webhook.config.token_placeholder')}
              register={register('token', webhookRules.token)}
              errorMessage={errorMessage({
                field: errors.token,
                ...webhookRules.token,
              })}
              defaultValue={webhook?.token}
            />
          </div>
          <Checkbox
            label={
              <TitleSubtitle
                title={t('webhook.config.headers_title')}
                subtitle={t('webhook.config.headers_subtitle')}
                noGutters
              />
            }
            onChange={handleToggleHeaders}
            checked={!!isHeadersChecked}
            disabled={readOnly}
          />
          <div className={styles.headersContainer}>
            {isHeadersChecked && (
              <KeyValueTable
                obj={headersObj}
                setObj={handleSetHeadersObj}
                size="small"
                readOnly={readOnly}
              />
            )}
          </div>
        </>
      )}
      {tabIndex === '1' && (
        <>
          <div className={styles.advanced}>
            <div className={styles.title}>
              {t('webhook.config.developer_mode')}
            </div>
            <Button
              variant="secondary"
              onClick={handleTestClick}
              isLoading={testStatus === 'pending'}
              disabled={editorErrors.length > 0 || readOnly}
              data-testid="send_test_button"
            >
              {t('webhook.config.send_test')}
            </Button>
          </div>
          <span className={styles.configText}>
            {t('webhook.config.developer_mode_contextual')}
          </span>

          <div data-testid="editor" className={styles.editor}>
            <Editor
              defaultLanguage="json"
              defaultValue={JSON.stringify(defaultValue, null, 2)}
              onMount={handleEditorDidMount}
              onChange={handleEditorChange}
              theme="vs-dark"
              onValidate={(errors) => setEditorErrors(errors)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(ConfigSection);
