import { Icon } from '@/models/icon';

const StringIcon = ({
  size = 18,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9556 6.01871L9.60833 6.25735C9.55199 6.085 9.4625 5.92094 9.33987 5.76516C9.22055 5.60938 9.05814 5.48178 8.85265 5.38235C8.64716 5.28291 8.39029 5.2332 8.08205 5.2332C7.66113 5.2332 7.3098 5.32766 7.02808 5.51658C6.74635 5.70219 6.60549 5.94248 6.60549 6.23746C6.60549 6.49267 6.69995 6.69816 6.88887 6.85394C7.07779 7.00971 7.38272 7.13732 7.80364 7.23675L9.01671 7.51516C9.71936 7.67756 10.243 7.9278 10.5877 8.26587C10.9324 8.60394 11.1048 9.04309 11.1048 9.58334C11.1048 10.0407 10.9722 10.4484 10.7071 10.8064C10.4452 11.161 10.079 11.4394 9.60833 11.6416C9.141 11.8438 8.5991 11.9448 7.98262 11.9448C7.12751 11.9448 6.42983 11.7626 5.88958 11.398C5.34934 11.0301 5.0179 10.5081 4.89526 9.83192L6.33205 9.61317C6.42154 9.9877 6.60549 10.2711 6.8839 10.4633C7.16231 10.6522 7.52524 10.7467 7.97268 10.7467C8.45989 10.7467 8.84934 10.6456 9.141 10.4434C9.43267 10.2379 9.5785 9.9877 9.5785 9.69272C9.5785 9.45408 9.48901 9.25356 9.31004 9.09115C9.13437 8.92875 8.86425 8.80612 8.49967 8.72326L7.20705 8.43987C6.49446 8.27747 5.96747 8.01895 5.62609 7.66431C5.28802 7.30967 5.11899 6.86057 5.11899 6.31701C5.11899 5.86625 5.24493 5.47183 5.49683 5.13377C5.74872 4.7957 6.09673 4.5322 6.54086 4.34328C6.98499 4.15105 7.49375 4.05493 8.06714 4.05493C8.89242 4.05493 9.54204 4.23391 10.016 4.59186C10.49 4.9465 10.8032 5.42212 10.9556 6.01871Z"
        fill={color}
      />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={color} />
    </svg>
  );
};

export default StringIcon;
