/* eslint-disable @typescript-eslint/no-explicit-any */
import Slider from '@mui/material/Slider';
import { Control, Controller } from 'react-hook-form';

type Mark = {
  value: number;
  label: string;
};

interface Props {
  control?: Control<any, any>;
  marks?: Mark[];
  value?: number;
  name: string;
  className?: string;
}

const FieldsetSlider: React.FC<Props> = ({
  control,
  name,
  marks = [],
  className,
}: Props) => {
  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <Slider
            track={false}
            defaultValue={0}
            min={-1}
            step={null}
            max={1}
            marks={marks}
            valueLabelDisplay="off"
            value={field.value}
            onChange={(_, newValue) => field.onChange(newValue)}
          />
        )}
      />
    </div>
  );
};

export default FieldsetSlider;
