import { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import uniqBy from 'lodash/uniqBy';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Select from '@/components/atoms/Select/Select';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { providerNameMapper } from '@/modules/developerTools/pages/languageModels/helper';
import { ProviderName } from '@/modules/developerTools/types';
import { brainRules } from '@/util/validator';

import styles from './styles.module.scss';

type Form = {
  provider: string;
  language_model_id: string;
};

export const LanguageModelFieldset = ({ brain }: { brain: Brain }) => {
  const { t } = useTranslation();
  const { updateBrain, updateStatus } = useBrains(brain?.brain_id);
  const { languageModel, enabledModelsWithMoveo } = useLanguageModels(
    brain?.language_model_id
  );

  const uniqueProviders = uniqBy(enabledModelsWithMoveo, 'provider');

  const {
    register,
    formState: { isDirty },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      provider: languageModel?.provider || 'moveo',
      language_model_id: languageModel?.language_model_id || 'Default',
    },
  });

  useEffect(() => {
    if (languageModel) {
      setValue('provider', languageModel.provider);
      setValue('language_model_id', languageModel.language_model_id);
    }
  }, [languageModel, setValue]);

  const onSubmit = async (data: Form) => {
    const { language_model_id } = data;
    updateBrain(
      {
        brain_id: brain?.brain_id,
        language_model_id:
          language_model_id === 'Default' ? null : language_model_id,
      },
      {
        onSuccess: () => {
          reset(data);
        },
      }
    );
  };

  return (
    <div>
      <FormFieldset
        title={t('brains.llms.title')}
        primaryButton={{
          children: t('common.save'),
          isLoading: updateStatus === 'pending',
          disabled: !isDirty,
        }}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={!brain}
      >
        <div className={styles.llm_fieldset}>
          <Typography
            variant="label-caps-small"
            color={'var(--text-default-gray)'}
          >
            {t('field.llm')}
          </Typography>

          <Typography variant="body-regular" color={'var(--text-default-gray)'}>
            {`${providerNameMapper(languageModel?.provider)} - ${languageModel?.model || 'Default'}`}
          </Typography>
        </div>
        <div className={styles.llms}>
          <Select
            name="provider"
            label={t('field.provider')}
            placeholder={t('common.select_an_option')}
            register={register('provider', brainRules.provider)}
            options={uniqueProviders?.map((model) => ({
              label: providerNameMapper(model.provider as ProviderName),
              value: model.provider,
            }))}
            size="full"
            onChange={(e) => {
              const selected = e.target.value;
              const firstModelOption = uniqueProviders.find(
                (m) => m.provider === selected
              );
              setValue(
                'language_model_id',
                firstModelOption?.language_model_id
              );
            }}
          />

          <Select
            name="language_model_id"
            label={t('field.model')}
            placeholder={t('common.select_an_option')}
            register={register('language_model_id', brainRules.model)}
            options={enabledModelsWithMoveo
              ?.filter((model) => model.provider === watch('provider'))
              ?.map((model) => ({
                label: model.model,
                value: model.language_model_id as string,
              }))}
            size="full"
          />
        </div>
      </FormFieldset>
    </div>
  );
};
