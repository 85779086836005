import { useCallback, useMemo } from 'react';

import { useQueries } from '@tanstack/react-query';

import { rulesEndpoints as endpoints } from '@/api/endpoints';
import { API } from '@/modules/rules/hooks/useRules';

import useDesks from './useDesks';
type Props = {
  brainId?: string;
  brainVersion?: number;
};
export const useAllRules = ({ brainId, brainVersion }: Props = {}) => {
  const { desks } = useDesks();
  const deskIds = useMemo(() => desks?.map((desk) => desk.desk_id), [desks]);

  const queries = useQueries({
    queries:
      deskIds && deskIds.length > 0
        ? deskIds.map((deskId) => ({
            queryKey: [endpoints.rules(deskId)],
            queryFn: () => API.listRules(deskId),
          }))
        : [],
  });

  const allRules = useMemo(
    () => queries?.map((query) => query.data?.rules).flat(),
    [queries]
  );

  const rulesConnected = useMemo(() => {
    return allRules?.filter((rule) => {
      // brainVersion could be 0 which is falsy
      if (typeof brainVersion !== 'undefined') {
        return rule?.actions?.some(
          (action) =>
            action.brain_parent_id === brainId &&
            action.brain_version === brainVersion
        );
      }
      return rule?.actions?.some(
        (action) => action.brain_parent_id === brainId
      );
    });
  }, [allRules, brainId, brainVersion]);

  const getRulesConnectedToBundle = useCallback(
    (bundleId: string) => {
      return (
        allRules?.filter((rule) =>
          rule?.actions?.some(
            (action) =>
              action.type === 'apply_bundle' && action.bundle_id === bundleId
          )
        ) ?? []
      );
    },
    [allRules]
  );

  const rulesWithDeskName = useMemo(() => {
    return rulesConnected
      ?.map((rule) => {
        const desk = desks.find((desk) => desk.desk_id === rule.desk_id);
        return {
          ...rule,
          desk_name: desk?.name,
          brain_version: rule?.actions?.find(
            (action) => action.brain_parent_id === brainId
          )?.brain_version,
        };
      })
      .sort((a, b) => a.status.localeCompare(b.status));
  }, [rulesConnected, desks, brainId]);

  const status = useMemo(
    () =>
      queries.every(({ status }) => status === 'success')
        ? 'success'
        : 'pending',
    [queries]
  );

  const noRules = useMemo(
    () => rulesConnected && status !== 'pending' && rulesConnected.length === 0,
    [rulesConnected, status]
  );

  return {
    data: allRules,
    status,
    rulesConnected,
    noRules,
    rulesWithDeskName,
    getRulesConnectedToBundle,
  };
};
