import { useMemo } from 'react';

import cn from 'classnames';
import isNull from 'lodash/isNull';
import { useTranslation } from 'react-i18next';

import Table from '@/components/atoms/Table/Table';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { useBillingState } from '@/modules/billing/hooks/useBillingState';

import { TotalPayment } from './TotalPayment/TotalPayment';
import { parseInvoices } from './utils';

import styles from './styles.module.scss';

export const PlanTable = () => {
  const { t } = useTranslation();
  const { currentSubscription, upcomingInvoice, customer } = useBilling();
  const billingState = useBillingState();

  // Table columns definition
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className={styles.itemCell}>{t('billing.table.item')}</div>
        ),
        accessor: 'item',
        Cell: ({ value, row }) => (
          <div
            className={cn(styles.itemCell, {
              [styles.totalCell]: row.original.id === 'total',
            })}
          >
            {value}
          </div>
        ),
      },
      {
        Header: () => (
          <div className={styles.numericCell}>{t('billing.table.count')}</div>
        ),
        accessor: 'count',
        Cell: ({ value }) => <div className={styles.numericCell}>{value}</div>,
      },
      {
        Header: () => (
          <div className={styles.numericCell}>{t('billing.table.unit')}</div>
        ),
        accessor: 'unit_price',
        Cell: ({ value }) => <div className={styles.numericCell}>{value}</div>,
      },
      {
        Header: () => (
          <div className={styles.numericCell}>{t('billing.table.price')}</div>
        ),
        accessor: 'price',
        Cell: ({ value, row }) => (
          <div
            className={cn(styles.numericCell, {
              [styles.totalCell]: row.original.id === 'total',
            })}
          >
            {value}
          </div>
        ),
      },
    ],
    [t]
  );

  // Invoice lines from invoice
  const upcomingInvoiceLines = useMemo(
    () => upcomingInvoice?.lines || [],
    [upcomingInvoice]
  );

  // Dont continue if no subscription or no upcomingInvoice
  if (!currentSubscription || !upcomingInvoice) {
    return null;
  }
  const data = parseInvoices(upcomingInvoiceLines, billingState.plan_name);

  if (!data) {
    return null;
  }

  if (isNull(upcomingInvoice?.total)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Table data={data} columns={columns} noGutters fullWidth sortBy="index" />
      <TotalPayment
        upcomingInvoice={upcomingInvoice}
        balance={customer?.balance}
      />
    </div>
  );
};
