import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import { permissionsEndpoints as endpoints } from '@/api/endpoints';
import { callGet } from '@/api/fetcher';
import { Permissions } from '@/models/permissions';
import { selectPermissions } from '@/redux/permissions/selectors';

import { useAccount } from './useAccount';

export const API = {
  getPermissions: async (): Promise<Permissions> =>
    callGet(endpoints.permissions),
};

export const usePermissions = () => {
  const { account } = useAccount();
  const storePermissions = useSelector(selectPermissions);

  const { data: permissions, status: listStatus } = useQuery<Permissions>({
    queryKey: [endpoints.permissions, account?.account_id],
    queryFn: API.getPermissions,
    enabled: !!account?.account_id,
  });

  return {
    listStatus,
    isLoading: listStatus === 'pending' && !isNil(storePermissions),
    permissions: permissions?.permissions,
  };
};
