import { Routes, Route } from 'react-router';

import BrainRouter from '@/components/pages/Brains/BrainRouter';
import Brains from '@/components/pages/Brains/Brains';
import Layout from '@/components/templates/Layout/Layout';

import Webhook from '../Webhook/Webhook';

const BrainsRouter: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Brains />} />
        <Route path="/:brainId/*" element={<BrainRouter />} />
        <Route path="/:brainId/webhooks/:webhookId" element={<Webhook />} />
      </Routes>
    </Layout>
  );
};

export default BrainsRouter;
