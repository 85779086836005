import { Start } from './1-Start';
import { Template } from './2-Template';
import { TemplateTry } from './3-TemplateTry';
import {
  NewBrainProps,
  CommonProps,
  TemplateProps,
  TemplateTryProps,
} from './ModalBrainCreationNew';

export function WizardBrain(
  props: NewBrainProps & TemplateProps & CommonProps & TemplateTryProps
) {
  if (props.step === 1) {
    return <Start {...(props as NewBrainProps & CommonProps)} />;
  }

  if (props.step === 2) {
    return <Template {...(props as TemplateProps & CommonProps)} />;
  }

  if (props.step === 3) {
    return <TemplateTry {...(props as TemplateTryProps & CommonProps)} />;
  }
}
