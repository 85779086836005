import { useCallback, useRef } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import Alert from '@/components/atoms/Icons/Alert';
import { useAccount } from '@/hooks/useAccount';
import { useAutoBrain } from '@/hooks/useAutoBrain';
import useExpressions from '@/hooks/useExpressions';
import useIntentRecommendation from '@/hooks/useIntentRecommendation';
import useIntents from '@/hooks/useIntents';
import { TYPE } from '@/models/recommendations';
import { RootState } from '@/models/state';
import { TypingIndicator } from '@/modules/humanChat/components/Conversation/MessageArea/TypingIndicator/TypingIndicator';
import { updatePhrases } from '@/redux/auto-ai/actions';
import { appendExpressions } from '@/redux/expressions/actions';
import { selectBrainId } from '@/redux/session/selectors';

import RecommendationsTable from '../RecommendationsTable/RecommendationsTable';
import TrainingPhrase from '../TrainingPhrase/TrainingPhrase';

import styles from './TrainingPhrases.module.scss';

type Props = {
  readOnly?: boolean;
  isLoading: boolean;
};

const TrainingPhrases = ({ isLoading, readOnly = false }: Props) => {
  const { t } = useTranslation();
  const { intentName } = useParams();
  const brainId = useSelector(selectBrainId);
  const { slug } = useAccount();
  const dispatch = useDispatch();
  const firstRef = useRef<HTMLTextAreaElement>(null);
  const {
    recommendedIntent,
    updateRecommendedExpression,
    updateRecommendedExpressions,
  } = useIntentRecommendation(brainId, intentName);

  const autoExpressions = useSelector(
    (state: RootState) => state.autoAI?.phrases
  );

  const {
    getAutoExpressions,
    isLoading: isAutoLoading,
    error: autoError,
  } = useAutoBrain();
  const { expressions, expressionsWithId, isDraft } = useExpressions(
    brainId,
    intentName
  );
  const { intent, filteredExpressions } = useIntents(brainId, intentName);

  const isExistingRecommendedIntent = recommendedIntent && !!intent;

  const showRecommendations =
    isExistingRecommendedIntent && filteredExpressions.length > 0;

  const handleExpressionCreated = useCallback(
    (newText: string) => {
      if (!showRecommendations && isDraft) {
        getAutoExpressions(newText);
      }
    },
    [getAutoExpressions, isDraft, showRecommendations]
  );

  const showBanner = expressions?.length === 0;

  return (
    <>
      <TrainingPhrase
        onNewExpressionCreated={handleExpressionCreated}
        isTypingField
        readOnly={readOnly}
        id={`Intent ${intentName}`}
        slug={slug}
        key={intentName}
      />
      <Scrollbars>
        <div className={styles.wrapper}>
          {showRecommendations && (
            <RecommendationsTable
              items={filteredExpressions}
              type={TYPE.LOGS}
              onSaveClick={(text: string) => {
                updateRecommendedExpression('accept', text);
                setTimeout(() => {
                  firstRef.current?.focus();
                }, 0);
              }}
              onRejectClick={(text: string) =>
                updateRecommendedExpression('reject', text)
              }
              onSaveAllClick={() =>
                updateRecommendedExpressions('accept', filteredExpressions)
              }
              onRejectAllClick={() =>
                updateRecommendedExpressions('reject', filteredExpressions)
              }
            />
          )}
          {(!autoExpressions || autoExpressions?.length === 0) && (
            <div className={styles.loading}>
              {isAutoLoading && (
                <TypingIndicator color="var(--icon-default-purple)" />
              )}
              {autoError && (
                <div className={styles.autoError}>
                  <Alert color="var(--icon-default-error)" />
                  <Typography>{t('auto_brain.expressions_error')}</Typography>
                </div>
              )}
            </div>
          )}

          {autoExpressions?.length > 0 && !showRecommendations && (
            <RecommendationsTable
              items={autoExpressions}
              type={TYPE.AUTO}
              isLoadingNew={isAutoLoading}
              onSaveClick={(text: string) => {
                dispatch(updatePhrases({ text }));
                dispatch(
                  appendExpressions({
                    newExpressions: [text],
                  })
                );
                setTimeout(() => {
                  firstRef.current.focus();
                  firstRef.current.select();
                }, 0);
              }}
              onRejectClick={(text: string) => {
                dispatch(updatePhrases({ text }));
              }}
            />
          )}
          <div className={styles.subtitle}>
            <Typography variant="label-caps-large">
              {t('training_phrases.subtitle')}
            </Typography>
            <Typography variant="label-caps-large">
              {expressions?.length}
            </Typography>
          </div>

          {isLoading ? (
            Array(5)
              .fill('')
              // eslint-disable-next-line react/no-array-index-key
              .map((_, i) => <Skeleton key={i} height={40} width="100%" />)
          ) : (
            <>
              {expressionsWithId?.map(({ id }, index) => (
                <TrainingPhrase
                  readOnly={readOnly}
                  key={id}
                  id={id}
                  innerRef={index === 0 ? firstRef : null}
                />
              ))}
            </>
          )}
          {showBanner && (
            <Banner variant="neutral" padding="small" relativePosition>
              {t('training_phrases.banner_text')}
            </Banner>
          )}
        </div>
      </Scrollbars>
    </>
  );
};

export default TrainingPhrases;
