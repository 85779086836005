import { Icon } from '@/models/icon';

type Props = Icon & {
  extraLarge?: boolean;
};
const Environment = ({
  size = 16,
  color = 'currentColor',
  extraLarge,
}: Props) => {
  if (extraLarge) {
    return (
      <svg
        width="100"
        height="100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke={color}
      >
        <path d="M87.5 66.667V33.333a8.334 8.334 0 0 0-4.167-7.208L54.167 9.458a8.334 8.334 0 0 0-8.334 0L16.667 26.125a8.333 8.333 0 0 0-4.167 7.208v33.334a8.334 8.334 0 0 0 4.167 7.208l29.166 16.667a8.334 8.334 0 0 0 8.334 0l29.166-16.667a8.333 8.333 0 0 0 4.167-7.208Z" />
        <path d="M13.625 29 50 50.042 86.375 29M50 92V50" />
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 10.667V5.333a1.333 1.333 0 0 0-.667-1.153L8.667 1.513a1.333 1.333 0 0 0-1.334 0L2.667 4.18A1.333 1.333 0 0 0 2 5.333v5.334a1.334 1.334 0 0 0 .667 1.153l4.666 2.667a1.334 1.334 0 0 0 1.334 0l4.666-2.667A1.333 1.333 0 0 0 14 10.667Z" />
      <path d="M2.18 4.64 8 8.007l5.82-3.367M8 14.72V8" />
    </svg>
  );
};

export default Environment;
