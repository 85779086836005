import { useEffect } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import HelpCircle from '@/components/atoms/Icons/HelpCircle';
import CursorPointer2 from '@/components/atoms/Icons/Other/CursorPointer2';
import InAppHelp from '@/components/atoms/InAppHelp/InAppHelp';
import Link from '@/components/atoms/Link/Link';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import RulesHeader from '@/modules/rules/components/RulesHeader/RulesHeader';
import { useRules } from '@/modules/rules/hooks/useRules';
import { ActionType } from '@/modules/rules/model';
import { setDraftRule, revertDirty } from '@/modules/rules/redux/actions';
import { selectRule } from '@/modules/rules/redux/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { setRuleId } from '@/redux/session/actions';
import { selectDeskId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';
import { resolveBrainsPath } from '@/util/util';

import useRuleHelper from './useRuleHelper';
import Actions from '../../components/Actions/Actions';
import Condition from '../../components/Condition/Condition';
import Triggers from '../../components/Triggers/Triggers';

import styles from './Rule.module.scss';

const Rule = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );

  const dispatch = useDispatch();
  const draftRule = useSelector(selectRule);
  const location = useLocation();
  const isDraft = location.pathname.includes('draft');
  const { ruleId } = useParams();
  const deskId = useSelector(selectDeskId);
  const { rule, getStatus, isRuleLoading } = useRules(deskId, ruleId);
  const hasConditions = draftRule?.condition.conditions.length > 0;
  const hasActions = draftRule?.actions.length > 0;
  const { slug } = useAccount();
  const brainAssignment = draftRule?.actions.find(
    (action) =>
      action.type === ActionType.ASSIGN_BRAIN && action?.brain_parent_id
  );

  const { showTooltip, handleDismissTooltip } = useRuleHelper();
  const { brain } = useBrains(brainAssignment?.brain_parent_id);
  const { ai_agents } = useFeatureFlag();

  useEffect(() => {
    pageView(PageName.RULE);
  }, []);

  useTitle(t('pages.rule', { 0: slug }));

  useEffect(() => {
    if (ruleId) {
      dispatch(setRuleId(ruleId));
    }
    return () => {
      dispatch(setRuleId(null));
      dispatch(revertDirty());
    };
  }, [dispatch, ruleId, rule]);

  useEffect(() => {
    if (rule) {
      dispatch(setDraftRule({ dirty: false, ...rule }));
    }
  }, [dispatch, rule]);

  useEffect(() => {
    if (isDraft && deskId && !draftRule.name) {
      navigate(`/${slug}/environments/${deskId}/rules`);
    }
  }, [deskId, draftRule, isDraft, slug, navigate]);

  return (
    <>
      <RulesHeader />
      <PageContentWrapper newPlain gutters readOnly={!canWrite}>
        <Typography
          variant="body-semi-bold"
          color="var(--text-default-gray)"
          className={styles.description}
        >
          {t('rules.header')}
        </Typography>

        {isRuleLoading && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={130}
            height={28}
          />
        )}
        {(getStatus === 'success' || isDraft) && (
          <div className={styles.wrapper}>
            <div className={styles.rule}>
              <span className={styles.condition}>{t('rules.when')}</span>
              <Triggers />
            </div>

            <div
              className={cn(styles.rule, {
                [styles.hasCondition]: !hasConditions,
              })}
            >
              <span
                className={cn(styles.condition, {
                  [styles.hasCondition]: hasConditions,
                })}
              >
                {t('rules.if')}
              </span>

              <Condition />
            </div>
            <div
              className={cn(styles.rule, {
                [styles.hasActions]: !hasActions,
              })}
            >
              <span className={styles.condition}>{t('rules.then')}</span>
              <InAppHelp
                placement="bottom-end"
                darkTheme
                defaultOpen={showTooltip}
                onClose={handleDismissTooltip}
                steps={[
                  {
                    icon: <CursorPointer2 />,
                    body: (
                      <Typography variant="body-regular">
                        {t('version.select_new')}
                      </Typography>
                    ),
                  },
                ]}
              >
                <Actions />
              </InAppHelp>
            </div>
            {brainAssignment && brain && (
              <ContextualHelp
                name="rules-help"
                title={
                  ai_agents ? t('rules.ai_agent_help') : t('rules.brain_help')
                }
                size="full"
                preventClose
              >
                <div className={styles.contextual}>
                  <div className={styles.contextualContainer}>
                    <HelpCircle color="var(--icon-default-blue)" />
                    <p>
                      <Link
                        href={resolveBrainsPath(
                          `/${slug}/brains/${brain?.brain_parent_id}`,
                          ai_agents
                        )}
                      >
                        {brain?.name} ({t('common.draft')})
                      </Link>
                      {ai_agents
                        ? t('rules.ai_agent_help_body')
                        : t('rules.brain_help_body')}
                    </p>
                  </div>
                </div>
              </ContextualHelp>
            )}
          </div>
        )}
      </PageContentWrapper>
    </>
  );
};

export default Rule;
