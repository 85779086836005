import { Icon } from '@/models/icon';

const Documentation = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.509 2.175c.219-.219.515-.342.825-.342h8.5v9h-8.5c-.417 0-.821.12-1.167.341V3c0-.31.123-.606.342-.825ZM3.167 13a1.167 1.167 0 0 0 1.167 1.167h8.5v-2.334h-8.5A1.167 1.167 0 0 0 3.167 13Zm10.667-1.667v-10a.5.5 0 0 0-.5-.5h-9A2.167 2.167 0 0 0 2.167 3v10a2.167 2.167 0 0 0 2.167 2.167h9a.5.5 0 0 0 .5-.5v-3.334Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default Documentation;
