import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const WebWidget = forwardRef<SVGSVGElement, Icon>(
  (
    { size = 24, backgroundColor = 'var(--icon-default-blue)', ...props },
    ref
  ) => {
    return (
      <svg
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.2202 19.6809C22.9558 17.5997 24 14.9218 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C14.1742 24 16.2134 23.4218 17.9721 22.4107C18.7925 22.8782 19.64 23.2083 20.4311 23.4413C21.4049 23.7281 21.9797 22.3083 21.5757 21.377C21.4618 21.1142 21.3746 20.8353 21.326 20.5417C21.2835 20.2854 21.2486 19.9944 21.2202 19.6809Z"
            fill={backgroundColor}
          />
          <rect
            x="4.5"
            y="14"
            width="2.5"
            height="4"
            rx="1.25"
            transform="rotate(-90 4.5 14)"
            fill="var(--icon-default-white)"
          />
          <rect
            x="9.5"
            y="14"
            width="2.5"
            height="4"
            rx="1.25"
            transform="rotate(-90 9.5 14)"
            fill="var(--icon-default-white)"
          />
          <rect
            x="14.5"
            y="14"
            width="2.5"
            height="4"
            rx="1.25"
            transform="rotate(-90 14.5 14)"
            fill="var(--icon-default-white)"
          />
          <rect
            x="4.5"
            y="10"
            width="2.5"
            height="5"
            rx="1.25"
            transform="rotate(-90 4.5 10)"
            fill="var(--icon-default-white)"
          />
          <rect
            x="4.5"
            y="17.5"
            width="2.5"
            height="5"
            rx="1.25"
            transform="rotate(-90 4.5 17.5)"
            fill="var(--icon-default-white)"
          />
          <circle
            cx="14.8557"
            cy="9"
            r="1.5"
            fill="var(--icon-default-white)"
          />
          <circle cx="11.5" cy="9" r="1" fill="var(--icon-default-white)" />
          <circle cx="18.3557" cy="9" r="1" fill="var(--icon-default-white)" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="var(--icon-default-white)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

WebWidget.displayName = 'WebWidget';

export default WebWidget;
