import { Icon } from '@/models/icon';

const Alert = ({ size = 16, color = 'var(--icon-default-warning)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="7" fill={color} />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.4"
        d="M8 4.5v4.2"
      />
      <circle cx="8" cy="11.5" r=".7" fill="#fff" />
    </svg>
  );
};

export default Alert;
