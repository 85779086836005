import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Checkmark from '@/components/atoms/Icons/Checkmark';
import { MODAL_CUSTOM_GUIDELINE_CREATE } from '@/components/organisms/Modals/ModalConductor';
import { pushModal } from '@/redux/modals/actions';
import { scrollToElementById } from '@/util/util';

import { CustomGuidelineForm } from './CustomGuidelineForm';
import { CustomInstructions } from './CustomInstructions';
import { StaticKnowledge } from './StaticKnowledge';
import { useKnowledge } from '../../hooks/useKnowledge';
import { PlusIcon } from '../../icons/PlusIcon';
import { NumberIcon } from '../NumberIcon';
import { Summary } from '../Summary/Summary';

export const CustomerSupportKnowledge = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sortedCustomGuidelines, hasNoCustomGuidelines, customInstructions } =
    useKnowledge();
  const initialOrder = customInstructions ? 2 : 1;

  return (
    <>
      <Box pl="var(--space-16)">
        <Typography
          component="h3"
          variant="h3-semi-bold"
          color="var(--text-default-black)"
        >
          {t('ai_agents.knowledge.title_customer_support')}
        </Typography>
        <Typography component="p" color="var(--text-default-gray)">
          {t('ai_agents.knowledge.subtitle_customer_support')}
        </Typography>
      </Box>

      {/* We display the custom_instructions only if it's not empty
       *  This is for backward compatibility in order to not lose the
       *  custom_instructions when we switching to the new UI
       *  If you leave the custom instructions empty, it will disappear
       *  and never be shown again
       */}
      {customInstructions && <CustomInstructions />}

      {hasNoCustomGuidelines && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            variant="tertiary"
            onClick={() => {
              dispatch(pushModal(MODAL_CUSTOM_GUIDELINE_CREATE));
            }}
          >
            <PlusIcon color="currentColor" />
            {t('ai_agents.knowledge.add_guideline')}
          </Button>

          <Typography
            component="p"
            variant="label-regular"
            color="var(--text-placeholder-gray)"
            my="var(--space-16)"
          >
            {t('ai_agents.knowledge.empty_guidelines')}
          </Typography>

          <img
            width="110"
            height="62"
            src="/assets/aiAgents/empty_guidelines.png"
            alt=""
          />
        </Box>
      )}
      {sortedCustomGuidelines.map((customGuideline, index) => {
        return (
          <CustomGuidelineForm
            key={customGuideline.created_at}
            {...customGuideline}
            order={index + initialOrder}
          />
        );
      })}
    </>
  );
};

export const CustomerSupportSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sortedCustomGuidelines, hasNoCustomGuidelines, customInstructions } =
    useKnowledge();
  const initialOrder = customInstructions ? 2 : 1;

  const buttonText = hasNoCustomGuidelines
    ? t('ai_agents.knowledge.add_guideline')
    : t('ai_agents.knowledge.add_new_guideline');

  return (
    <>
      <Summary>
        {customInstructions && (
          <Summary.Step
            onClick={() => scrollToElementById(`ai-agents-custom-instructions`)}
            text={t('ai_agents.knowledge.custom_instructions.title')}
            icon={<Checkmark />}
          />
        )}

        {sortedCustomGuidelines.map((customGuideline, index) => {
          const order = index + initialOrder;
          const icon = customGuideline.body ? (
            <Checkmark />
          ) : (
            <NumberIcon number={order} />
          );

          return (
            <Summary.Step
              key={customGuideline.created_at}
              onClick={() => scrollToElementById(`custom-guideline-${order}`)}
              text={customGuideline.title}
              icon={icon}
            />
          );
        })}
        <Box>
          <Button
            noGutters
            variant="tertiary"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(pushModal(MODAL_CUSTOM_GUIDELINE_CREATE));
            }}
          >
            <PlusIcon color="currentColor" />
            {buttonText}
          </Button>
        </Box>
      </Summary>

      <StaticKnowledge />

      {/* Hide live instructions until NLP team implments it */}
      {/* <LiveInstructions /> */}
    </>
  );
};
