import {
  Control,
  FieldErrors,
  Path,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';
import { capitalizeFirstLetter } from '@/util/util';

import Webview from './CarouselWebview';

interface DefaultActionProps<FormType> {
  errors: FieldErrors<FormType>;
  updateRedux: () => void;
  actionId: string;
  register: UseFormRegister<FormType>;
  index?: number;
  cardIndex?: number;
  control: Control<FormType>;
}

const CarouselDefaultAction = <FormType,>({
  errors,
  updateRedux,
  actionId,
  register,
  index,
  cardIndex,
  control,
}: DefaultActionProps<FormType>) => {
  const { t } = useTranslation();

  const type = useWatch({
    control,
    name: 'type' as Path<FormType>,
  });

  switch (type) {
    case 'url':
      return (
        <Input
          register={register('url' as Path<FormType>)}
          onChange={updateRedux}
          error={!!errors?.['url']}
          errorMessage={capitalizeFirstLetter(errors?.['url']?.message)}
          name="url"
          placeholder={t('dialog.carousel.url_placeholder')}
          size="small"
        />
      );

    case 'phone':
    case 'postback':
      return (
        <Input
          register={register('value' as Path<FormType>)}
          error={!!errors?.['value']}
          errorMessage={capitalizeFirstLetter(errors?.['value']?.message)}
          name="value"
          onChange={updateRedux}
          placeholder={t(`dialog.carousel.${type}_placeholder`)}
          size="small"
        />
      );
    case 'webview':
      return (
        <Webview
          key={`${actionId}-webview`}
          updateRedux={updateRedux}
          control={control as Control<unknown>}
          register={register as UseFormRegister<unknown>}
          errors={errors}
          index={index}
          cardIndex={cardIndex}
        />
      );
    default:
      return null;
  }
};

export default CarouselDefaultAction;
