import React from 'react';

import cn from 'classnames';

import styles from './TileEmptyPage.module.scss';

interface Props {
  title: string;
  body?: React.ReactNode | string;
  icon?: React.ReactNode;
  notClickable?: boolean;
  isFullHeight?: boolean;
}

const TileEmptyPage = ({
  title,
  body,
  icon,
  notClickable,
  isFullHeight = false,
}: Props) => {
  return (
    <div
      className={cn(styles.tile, {
        [styles.notClickable]: notClickable,
        [styles.isFullHeight]: isFullHeight,
      })}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <p className={styles.title}>{title}</p>
      <p className={styles.body}>{body}</p>
    </div>
  );
};

export default TileEmptyPage;
