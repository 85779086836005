import { useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Select from '@/components/atoms/Select/Select';
import { usePreferences } from '@/hooks/usePreferences';
import { timezones } from '@/util/timezones';
import { isRequired } from '@/util/validator';

type Form = {
  timezone: string;
};

const SystemTimezone = () => {
  const { t } = useTranslation();
  const {
    userPreferences,
    updateUserPreferences,
    updateUserPreferencesStatus,
  } = usePreferences();
  const formMethods = useForm<Form>({
    mode: 'onChange',
    defaultValues: { timezone: 'Europe/Athens' },
  });
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = formMethods;
  const isLoading = userPreferences === undefined;

  useEffect(() => {
    if (userPreferences?.timezone) {
      reset({ timezone: String(userPreferences?.timezone) });
    }
  }, [reset, userPreferences?.timezone]);

  const onSubmit = useCallback(
    ({ timezone }: Form) => updateUserPreferences({ timezone }),
    [updateUserPreferences]
  );

  const supportedTimezones = useMemo(
    () => timezones.map((tz) => ({ value: tz, label: tz })),
    []
  );

  return (
    <FormFieldset
      title={t('profile.timezone.title')}
      subtitle={t('profile.timezone.subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateUserPreferencesStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Select
        name="timezone"
        label={t('field.timezone')}
        register={register('timezone', isRequired)}
        errorMessage={errors.timezone?.message}
        options={supportedTimezones}
        size="full"
      />
    </FormFieldset>
  );
};

export default SystemTimezone;
