import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { callGet } from '@/api/fetcher';
import { ZendeskDepartment, ZendeskIntegration } from '@/models/integration';
import { selectAccountSlug } from '@/redux/session/selectors';

import { useIntegrations } from './useIntegrations';

export const endpoints = Object.freeze({
  departments: (slug: string, deskId: string, integrationId: string) =>
    `/www/api/integrations/zendesk/${slug}/desks/${deskId}/integrations/${integrationId}/departments`,
  tags: (slug: string, deskId: string, integrationId: string) =>
    `/www/api/integrations/zendesk/${slug}/desks/${deskId}/integrations/${integrationId}/tags`,
});

const useZendesk = () => {
  const { deskId, integrationId } = useParams();
  const slug = useSelector(selectAccountSlug);
  const { integration, updateStatus, updateIntegration } =
    useIntegrations<ZendeskIntegration>(deskId, integrationId);
  const config = integration?.config;

  const isActive = Boolean(integration?.active);
  const isUpdated = updateStatus === 'success';
  const isUpdating = updateStatus === 'pending';
  const isConnected = Boolean(config?.access_token);

  const { data, isLoading: isLoadingDepartments } = useQuery<{
    departments: ZendeskDepartment[];
  }>({
    queryKey: [
      endpoints.departments(slug, deskId, integrationId),
      config?.access_token,
    ],
    queryFn: () => callGet(endpoints.departments(slug, deskId, integrationId)),
  });

  const { data: tagsData, isLoading: isLoadingTags } = useQuery<{
    tags: string[];
  }>({
    queryKey: [
      endpoints.tags(slug, deskId, integrationId),
      config?.access_token,
    ],
    queryFn: () => callGet(endpoints.tags(slug, deskId, integrationId)),
  });

  const departments = useMemo(
    () =>
      data?.departments?.map(({ name, id, enabled, description }) => {
        const d = config?.departments?.find((d) => d.id === id);
        const ccapi_id = d?.ccapi_id || undefined;
        const selected = d?.selected || '';
        return {
          name,
          id,
          enabled,
          selected,
          ccapi_id,
          description,
        } as ZendeskDepartment;
      }) ?? [],
    [config?.departments, data?.departments]
  );

  return {
    departments: departments || [],
    suggestedTags: tagsData?.tags || [],
    integration,
    isActive,
    isUpdating,
    isUpdated,
    isConnected,
    updateIntegration,
    isLoadingDepartments,
    isLoadingTags,
  };
};

export { useZendesk };
