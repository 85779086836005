import { useCallback, useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { SocketContext } from '@/contexts/rtm';
import useUser from '@/hooks/useUser';
import { SocketEvent } from '@/models/rtm';
import { API as ruleAPI } from '@/modules/rules/hooks/useRules';
import { selectDeskId } from '@/redux/session/selectors';

import { useRtm } from './useRtm';

export const endpoints = Object.freeze({
  rules: (deskId: string) => `/www/api/v1/desks/${deskId}/rules`,
});

export const useRtmDesk = () => {
  const { socket, isConnected } = useContext(SocketContext);
  const desk_id = useSelector(selectDeskId);
  const queryClient = useQueryClient();

  const { create, update, remove } = useRtm();
  const { user } = useUser();
  const userId = user?.user_id;

  const handleBusinessHourCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { business_hours_id, desk_id } = data;
        create('business_hours', desk_id, business_hours_id);
      }
    },
    [create, userId]
  );

  const handleBusinessHourUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { business_hours_id, desk_id } = data;
        update('business_hours', desk_id, business_hours_id);
      }
    },
    [update, userId]
  );

  const handleBusinessHourRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { business_hours_id, desk_id } = data;
        remove('business_hours', desk_id, business_hours_id);
      }
    },
    [remove, userId]
  );

  const handleBundleCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { bundle_id, desk_id } = data;
        create('bundles', desk_id, bundle_id);
      }
    },
    [create, userId]
  );

  const handleBundleUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { bundle_id, desk_id } = data;
        update('bundles', desk_id, bundle_id);
      }
    },
    [update, userId]
  );

  const handleBundleRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { bundle_id, desk_id } = data;
        remove('bundles', desk_id, bundle_id);
      }
    },
    [remove, userId]
  );

  const handleIntegrationCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { integration_id, desk_id } = data;
        create('integrations', desk_id, integration_id);
      }
    },
    [create, userId]
  );

  const handleIntegrationUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { integration_id, desk_id } = data;
        update('integrations', desk_id, integration_id);
      }
    },
    [update, userId]
  );

  const handleIntegrationRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { integration_id, desk_id } = data;
        remove('integrations', desk_id, integration_id);
      }
    },
    [remove, userId]
  );

  const handleRuleCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { rule_id, desk_id } = data;
        create('rules', desk_id, rule_id);
      }
    },
    [create, userId]
  );

  const handleRuleUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { rule_id, desk_id } = data;
        update('rules', desk_id, rule_id);
      }
    },
    [update, userId]
  );

  const handleRuleRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { rule_id, desk_id } = data;
        remove('rules', desk_id, rule_id);
      }
    },
    [remove, userId]
  );

  const handleRuleReordered = useCallback(
    async ({ user_id, data }) => {
      const { desk_id } = data;

      if (userId !== user_id) {
        try {
          const newRules = await queryClient.fetchQuery({
            queryKey: [endpoints.rules(desk_id)],
            queryFn: () => ruleAPI.listRules(desk_id),
            staleTime: 0,
          });

          newRules.rules.forEach((rule) => {
            update('rules', desk_id, rule.rule_id);
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    [queryClient, update, userId]
  );

  useEffect(() => {
    socket?.on(SocketEvent.business_hours_created, handleBusinessHourCreated);
    return () => {
      socket?.off(
        SocketEvent.business_hours_created,
        handleBusinessHourCreated
      );
    };
  }, [handleBusinessHourCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.business_hours_updated, handleBusinessHourUpdated);
    return () => {
      socket?.off(
        SocketEvent.business_hours_updated,
        handleBusinessHourUpdated
      );
    };
  }, [handleBusinessHourUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.business_hours_removed, handleBusinessHourRemoved);
    return () => {
      socket?.off(
        SocketEvent.business_hours_removed,
        handleBusinessHourRemoved
      );
    };
  }, [handleBusinessHourRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.bundles_created, handleBundleCreated);
    return () => {
      socket?.off(SocketEvent.bundles_created, handleBundleCreated);
    };
  }, [handleBundleCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.bundles_updated, handleBundleUpdated);
    return () => {
      socket?.off(SocketEvent.bundles_updated, handleBundleUpdated);
    };
  }, [handleBundleUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.bundles_removed, handleBundleRemoved);
    return () => {
      socket?.off(SocketEvent.bundles_removed, handleBundleRemoved);
    };
  }, [handleBundleRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.integrations_created, handleIntegrationCreated);
    return () => {
      socket?.off(SocketEvent.integrations_created, handleIntegrationCreated);
    };
  }, [handleIntegrationCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.integrations_updated, handleIntegrationUpdated);
    return () => {
      socket?.off(SocketEvent.integrations_updated, handleIntegrationUpdated);
    };
  }, [handleIntegrationUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.integrations_removed, handleIntegrationRemoved);
    return () => {
      socket?.off(SocketEvent.integrations_removed, handleIntegrationRemoved);
    };
  }, [handleIntegrationRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.rules_created, handleRuleCreated);
    return () => {
      socket?.off(SocketEvent.rules_created, handleRuleCreated);
    };
  }, [handleRuleCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.rules_updated, handleRuleUpdated);
    return () => {
      socket?.off(SocketEvent.rules_updated, handleRuleUpdated);
    };
  }, [handleRuleUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.rules_removed, handleRuleRemoved);
    return () => {
      socket?.off(SocketEvent.rules_removed, handleRuleRemoved);
    };
  }, [handleRuleRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.rules_reordered, handleRuleReordered);
    return () => {
      socket?.off(SocketEvent.rules_reordered, handleRuleReordered);
    };
  }, [handleRuleReordered, socket]);

  useEffect(() => {
    if (!socket || !desk_id || !isConnected) {
      return;
    }
    socket.emit(SocketEvent.subscribe_desk, { desk_id });
    return () => {
      socket.emit(SocketEvent.unsubscribe_desk, { desk_id });
    };
  }, [isConnected, desk_id, socket]);
};

export default useRtmDesk;
