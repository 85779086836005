import { useCallback } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import { useAccount } from '@/hooks/useAccount';
import { useAllRules } from '@/hooks/useAllRules';
import useVersions from '@/hooks/useVersions';
import { Rule } from '@/modules/rules/model';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';

import ModalDelete from './ModalDelete';
import ModalWarn from './ModalWarn';

type Props = {
  versionNumber: number;
};
const ModalVersionDelete = ({ versionNumber }: Props) => {
  const navigate = useNavigate();
  const { slug } = useAccount();
  const brainId = useSelector(selectBrainId);
  const { deleteVersion } = useVersions(brainId);
  const dispatch = useDispatch();
  const { status, rulesConnected, noRules } = useAllRules({
    brainId,
    brainVersion: versionNumber,
  });

  const handleDelete = useCallback(() => {
    deleteVersion(versionNumber, {
      onSuccess: () => {
        dispatch(popModal());
      },
    });
  }, [deleteVersion, dispatch, versionNumber]);

  const subtitle = (
    <Trans i18nKey="version.delete_warning" values={[versionNumber]} />
  );

  if (noRules) {
    return <ModalDelete subtitle={subtitle} confirm onDelete={handleDelete} />;
  }

  return (
    <ModalWarn>
      {status === 'pending' ? (
        <Box
          sx={{
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Banner<Partial<Rule>>
          relativePosition
          variant="critical"
          referenceProp="rule_id"
          references={rulesConnected}
          labelKey="name"
          onRefClick={(rule_id) => {
            const deskId = rulesConnected.find(
              (rule) => rule.rule_id === rule_id
            )?.desk_id;
            dispatch(popModal());
            navigate(`/${slug}/environments/${deskId}/rules/${rule_id}`);
          }}
        >
          <Trans
            i18nKey={'version.delete_warning_not_empty'}
            values={{ version: versionNumber }}
          />
        </Banner>
      )}
    </ModalWarn>
  );
};

export default ModalVersionDelete;
