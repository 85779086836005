import { useCallback } from 'react';

import MaterialModal from '@mui/material/Modal/Modal';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { popModal } from '@/redux/modals/actions';
import { getLandingPageUrl } from '@/util/constants';

import styles from './ModalSmallScreens.module.scss';

const ModalSmallScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  return (
    <MaterialModal open className={cn(styles.modal)}>
      <section className={cn(styles.container)}>
        <img
          width={80}
          height={61}
          src="/assets/modals/small_screen_icon.svg"
          alt=""
        />

        <div className={styles.header}>
          <Typography
            variant="h2-semi-bold"
            component="h2"
            className={cn(styles.title)}
          >
            {t('small_screen.title')}
          </Typography>
          <Typography
            variant="body-regular"
            component="p"
            className={cn(styles.description)}
          >
            {t('small_screen.subtitle')}
          </Typography>
        </div>
        <div className={styles.buttonContainer}>
          <Button href={getLandingPageUrl()} isLink className={styles.button}>
            <span>{t('small_screen.redirect')}</span>
          </Button>
          <Button
            variant="secondary"
            onClick={closeModal}
            className={styles.button}
          >
            {t('small_screen.proceed')}
          </Button>
        </div>
      </section>
    </MaterialModal>
  );
};

export default ModalSmallScreen;
