import { useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import { renderDialogIcon } from '@/components/organisms/SubNav/TreeView/Item';
import useDialogs from '@/hooks/useDialogs';
import { EventAction } from '@/models/action';
import { updateAction } from '@/redux/nodes/actions';
import { selectBrainId, selectDialogId } from '@/redux/session/selectors';
import { eventActionSchema2 } from '@/util/validator';

const JumpTo = ({ action }: { action: EventAction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brainId = useSelector(selectBrainId);
  const dialogId = useSelector(selectDialogId);
  const { detailedOptions } = useDialogs(brainId, dialogId);

  const defaultEvent = useMemo(() => {
    const option = detailedOptions.find(
      (o) => o.value === action.trigger_node_id
    );
    if (!option) {
      return null;
    }
    return option;
  }, [detailedOptions, action.trigger_node_id]);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      jump_to: defaultEvent,
    },
    resolver: yupResolver(eventActionSchema2),
  });

  const handleChange = useCallback(
    (_, option) => {
      dispatch(
        updateAction({
          actionId: action.action_id,
          action: {
            trigger_node_id: option?.value,
          },
        })
      );
    },
    [action.action_id, dispatch]
  );

  useEffect(() => {
    const option =
      detailedOptions.find(
        (option) => option.value === action?.trigger_node_id
      ) ?? null;

    setValue('jump_to', option, {
      shouldValidate: true,
    });
  }, [action?.trigger_node_id, detailedOptions, setValue]);

  return (
    <AutocompleteNew
      control={control}
      options={detailedOptions}
      name="jump_to"
      id="jump_to"
      groupByProp="dialog_name"
      groupByLabelProp={false}
      iconTypeKey="node_type"
      renderIconInFront={renderDialogIcon}
      placeholder={t('dialog.jump_to.placeholder')}
      hasError={!!errors.jump_to}
      errorMessage={errors.jump_to?.message}
      onChange={handleChange}
      getOptionDisabled={(option) => option.value === action.node_id}
      width={222}
    />
  );
};

export default JumpTo;
