import { forwardRef } from 'react';

import cn from 'classnames';

import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';

import styles from './DropdownTemplate.module.scss';

type Props = {
  size: 'small' | 'medium' | 'full';
  title?: string;
  tooltip?: string;
  children: React.ReactNode;
  type?: 'radio' | 'table' | 'input';
  zeroPadding?: boolean;
};

const DropdownTemplate = forwardRef<HTMLDivElement, Props>(
  ({ size, title, tooltip, children, type, zeroPadding = false }, ref) => {
    return (
      <div className={cn(styles[size], styles.container)} ref={ref}>
        {title && (
          <div className={styles.title}>
            {title}
            <HelpTooltip tooltip={tooltip} />
          </div>
        )}
        <div
          className={cn(styles.body, {
            [styles?.[type]]: !!type,
            [styles.zero_padding]: zeroPadding,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
);

DropdownTemplate.displayName = 'Dropdown';

export default DropdownTemplate;
