import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';

import { useAccount } from '@/hooks/useAccount';
import { PageName } from '@/models/segment';
import { pageView } from '@/segment/segment';

import { DataProcessingFieldset } from './fieldsets/DataProcessingFieldset';

export const DataPrivacy = () => {
  const { t } = useTranslation();
  const { slug, accountRole } = useAccount();

  useTitle(t('pages.data_privacy', { 0: slug }));

  const canUpdate = accountRole === 'owner';

  useEffect(() => {
    pageView(PageName.DATA_PROCESS);
  }, []);

  return (
    <>
      <DataProcessingFieldset readOnly={!canUpdate} />
    </>
  );
};
