/* eslint-disable @typescript-eslint/no-explicit-any */
import dotize from 'dotize';

/**
 * Compares two objects and returns their dotized common keys
 * @param a object
 * @param b object
 * @returns string[]
 */
export const findCommonKeys = (
  a: Record<string, any>,
  b: Record<string, any>
): string[] | [] => {
  const aKeys = Object.keys(dotize.convert(a));
  const bKeys = Object.keys(dotize.convert(b));
  const commonKeys = aKeys.filter((key) => bKeys.includes(key));
  return commonKeys;
};

/**
 * Returns all the nested keys of a dotized object key, except the passed one
 * 'foo.bar.baz' => ['foo', 'foo.bar']
 * @param key string
 * @returns string[]
 */
export const getNestedKeys = (key: string): string[] | [] => {
  const splitKey = key.split('.');
  const nestedKeys = [];
  for (let i = 0; i < splitKey.length - 1; i += 1) {
    nestedKeys.push(splitKey.slice(0, i + 1).join('.'));
  }
  return nestedKeys;
};
