import { FieldValues, UseFormTrigger } from 'react-hook-form';
import type { PartialDeep, SetRequired } from 'type-fest';

import { Statuses } from './api';
import { Context } from './chat';

export interface ZendeskDepartment {
  id: number;
  name: string;
  selected: boolean | undefined;
  enabled: boolean;
  ccapi_id?: string;
  description: string;
}

export type ZendeskConfig = Partial<{
  session_timeout: number;
  redirect_uri: string;
  access_token: string;
  refresh_token: string;
  scope: string;
  departments: ZendeskDepartment[];
  tags: string[];
  client_id: string;
  client_secret: string;
  subdomain: string;
  process_without_department: boolean;
}>;

export type SuncoConfig = Partial<{
  session_timeout: number;
  welcome_trigger_message: string;
  name: string;
  avatar: string;
  apikey_id: string;
  apikey_secret: string;
  webhook_secret: string;
  app_id: string;
  internal_integration_id: string;
  internal_integration_type: string;
  next_switchboard_id: string;
  create_ticket_on_session_expired: boolean;
  // oauth token
  access_token: string;
}>;

export type FrontConfig = Partial<{
  session_timeout: number;
  page_name: string;
  page_id: string;
  teammates: FrontTeammate[];
  teammate_id: string;
  refresh_token: string;

  // backwards compatible
  mode: 'agent' | 'system';
  sender_name: string;
  inboxes: FrontInbox[];
  tags: FrontTag[];
  tracked_tags: string[];
}>;

export type FrontTeammate = {
  id: string;
  name: string;
  status: Statuses;
};

export type FrontInbox = {
  id: number;
  name: string;
  selected?: boolean;
};

export type FrontTag = {
  id: string;
  name: string;
  highlight: string;
};

export interface FacebookPage {
  id: string;
  instagram_id?: string;
  access_token: string;
  name?: string;
  picture?: string;
  user_has_access?: boolean;
}

export type FacebookConfig = Partial<{
  session_timeout: number;
  access_token: string;
  verify_token: string;
  welcome_trigger_message: string;
  greeting_text: string;
  domains: string[];
  app_secret: string;
  page_id: string;
  public_customer_rsa_key: string;
  pages: FacebookPage[];
  page_name: string;
  is_manual: boolean;
  persistent_menu_options: string[];
}>;

export type InstagramConfig = Partial<{
  session_timeout: number;
  access_token: string;
  verify_token: string;
  welcome_trigger_message: string;
  greeting_text: string;
  domains: string[];
  app_secret: string;
  page_id: string;
  public_customer_rsa_key: string;
  pages: FacebookPage[];
  page_name: string;
  is_manual: boolean;
  facebook_page_id: string;
}>;

export type IntercomConfig = Partial<{
  session_timeout: number;
  access_token: string;
  welcome_trigger_message: string;
  page_id: string;
  page_name: string;
  admin_id: string;
  admin_name: string;
}>;

export type ViberConfig = Partial<{
  session_timeout: number;
  access_token: string;
  name: string;
  avatar: string;
  welcome_trigger_message: string;
  customer_rsa_key: string;
}>;

export type WhatsAppConfig = Partial<{
  session_timeout: number;
  access_token: string;
  verify_token?: string;
  app_secret?: string;
  phone_number_id?: string;
  prefilled_message?: string;
  include_agent_name?: boolean;
  test_numbers?: TestNumber[];
  page_id?: string;
  is_manual?: boolean;
}>;

export type TestNumber = {
  label: string;
  phone_number: string;
  rule_id?: string;
};

type WelcomeScreen = {
  enabled: boolean;
  greeting: string;
  starters: string[];
};

export type PageTriggers = {
  [key: string]: { opening_message: string; trigger_message: string };
};

type Field = {
  field: string;
  required: boolean;
};

type Disclaimer = {
  enabled: boolean;
  html: string;
};

type FormVisitorInformation = {
  headline: string;
  fields: Field[];
  disclaimer: Disclaimer;
  location: boolean;
};

type Launcher = {
  size: 'regular' | 'large';
  show: boolean;
  type: 'bubble' | 'help' | 'feedback' | 'question';
  icon_url: string;
  text: string;
};

export type WebWidgetConfig = Partial<{
  session_timeout: number;
  avatar: string;
  bubble_image: string;
  domains: string[];
  accent_color: string;
  background_color: string;
  welcome_message: string;
  welcome_trigger_message: string;
  header_title: string;
  widget_position: string;
  show_powered_by: boolean;
  window_size: string;
  icon_size: string;
  welcome_screen: WelcomeScreen;
  page_triggers: PageTriggers;
  visitor_information: FormVisitorInformation;
  launcher: Launcher;
  focus_trap: boolean;
  auto_open: boolean;
  theme: string;
  sticky_session: boolean;
  show_survey?: boolean;
  generated_public_key: string;
  language?: string;
  public_customer_rsa_key: string;
  external_urls_limit: number;
  file_upload: boolean;
  rate_responses: boolean;
}>;

export type WebWidgetInstance = {
  openWindow: () => void;
  closeWindow: () => void;
  sendMessage: (message: { text: string }) => void;
  updateContext: (data: PartialDeep<Context>) => void;
  setCSSVariables: (data: Record<string, string>) => void;
  setLocale: (locale: string) => void;
  onSessionCreated: (callback: () => void) => void;
};

export type Integration = {
  name: string;
  type: IntegrationType;
  integration_id: string;
  active: boolean;
  desk_id: string;
  created: string;
  updated: string;
  last_session_at: string;
  config: {
    session_timeout?: number;
    file_upload?: boolean;
  };
  status?: string;
};

export type IntegrationType =
  | 'zendesk'
  | 'intercom'
  | 'facebook'
  | 'viber'
  | 'web'
  | 'sunco'
  | 'instagram'
  | 'whatsapp'
  | 'front';

export interface ZendeskIntegration extends Integration {
  type: 'zendesk';
  config: ZendeskConfig;
}

export interface IntercomIntegration extends Integration {
  type: 'intercom';
  config: IntercomConfig;
}

export interface WebWidgetIntegration extends Integration {
  type: 'web';
  config: WebWidgetConfig;
}

export interface SuncoIntegration extends Integration {
  type: 'sunco';
  config: SuncoConfig;
}

export interface FacebookIntegration extends Integration {
  type: 'facebook';
  config: FacebookConfig;
}

export interface InstagramIntegration extends Integration {
  type: 'instagram';
  config: InstagramConfig;
}

export interface ViberIntegration extends Integration {
  type: 'viber';
  config: ViberConfig;
}

export interface WhatsAppIntegration extends Integration {
  type: 'whatsapp';
  config: WhatsAppConfig;
}

export interface FrontIntegration extends Integration {
  type: 'front';
  config: FrontConfig;
}

export type Integrations = {
  integrations: Integration[];
};

/**
 * IntegrationId is a type that is used to update an integration. Where
 * the integration_id and desk_id are required and the rest of the fields
 * are optional.
 */
export type IntegrationId<T extends Integration> = SetRequired<
  Partial<T>,
  'desk_id' | 'integration_id'
>;

export type NewIntegration<T extends Integration> = SetRequired<
  Partial<T>,
  'desk_id' | 'type'
>;

export type FacebookError = {
  message: string;
  url: string;
};

export enum AccordionType {
  WEB_LAUNCHER_APPEARANCE = 'launcher-appearance',
  WEB_CHAT_APPEARANCE = 'webchat-appearance',
  WEB_CHAT_POSITION = 'webchat-position',
  WEB_CONNECT_AND_SECURE = 'connect-and-secure',

  WHATSAPP_TESTING = 'whatsapp-testing',
  WHATSAPP_PREFERENCE = 'whatsapp-preference',

  FACEBOOK_MANUAL_SETTINGS = 'facebook-manual-settings',

  FRONT_PREFERENCES = 'front-preferences',
  FRONT_MODE = 'front-mode',

  VIBER_CHAT_APPEARANCE = 'viber-chat-appearance',

  SUNCO_SETTINGS = 'sunshine-conversations-settings',

  // common
  VERIFICATION_STATUS = 'verification-status',
  CONNECTION_SETTINGS = 'connection-settings',
  ADVANCED_SETTINGS = 'advanced-settings',
  WHITELIST_DOMAINS = 'whitelist-domains',
  START_BEHAVIOR = 'start-behavior',
  VISITOR_INFORMATION = 'visitor-information',
}

export type AccordionState = {
  expanded: AccordionType | false;
  dirty: boolean;
};

export type AccordionFieldsetProps<
  Form extends FieldValues,
  T = Integration,
> = {
  type: AccordionType;
  integration?: T;
  toggleAccordion?: (
    panel: AccordionType
  ) => (_event, isExpanded: boolean) => void;
  registerAccordion: (
    type: AccordionType,
    onSubmit?: () => void,
    triggerValidation?: UseFormTrigger<Form>
  ) => (el: HTMLDivElement) => void;
};

export interface WebFile {
  download_url: string;
  file_name: string;
  file_id: string;
  session_id: string;
}
