import type { RequireAtLeastOne } from 'type-fest';

import { Agent } from './account';
import { Brain } from './brain';

export const enum RoleType {
  OWNER = 'owner',
  RBAC = 'rbac',
}

export type Member = {
  role: RoleType;
  user_id: string;
  name: string;
  account_id: string;
  avatar: string;
  email: string;
  role_ids: string[];
  team_ids: string[];
};

export type Members = {
  members: Member[];
};

export type PartialMember = RequireAtLeastOne<Partial<Member>, 'user_id'>;

export type Invitation = {
  email: string;
  account_id: string;
  role: RoleType;
  account_name: string;
  account_avatar: string;
  invitation_code: string;
  user_avatar: string;
  user_id: string;
  user_name: string;
  role_ids: string[];
  team_ids: string[];
};

export type ChangeRole = {
  user_id?: string;
  role: RoleType;
  email?: string;
};

export type RespondInvitation = {
  action: 'accept' | 'cancel';
  invitation_code: string;
  account_id: string;
};

export type DeleteInvitation = {
  email: string;
};

export type NewInvitation = {
  name?: string;
  email: string;
  role: RoleType;
  role_ids: string[];
};

export type UpdateInvitation = {
  email?: string;
  role?: RoleType;
  role_ids?: string[];
  team_ids?: string[];
};

export type Invitations = {
  invitations: Invitation[];
};

export type Assignee = Partial<Brain> &
  Partial<Agent> & {
    user_id?: string;
    name: string;
    status: string;
  };
export type Assignees = Assignee[];
