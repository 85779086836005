import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Intercom = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, color = '#1F8DED', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20.8 13.195a.8.8 0 01-1.6 0V6a.8.8 0 011.6 0v7.195zm-.28 5.008c-.123.105-3.086 2.592-8.52 2.592-5.434 0-8.397-2.487-8.52-2.593a.8.8 0 011.039-1.216c.047.04 2.693 2.21 7.481 2.21 4.848 0 7.453-2.186 7.48-2.208a.8.8 0 011.04 1.215zM3.2 6a.8.8 0 011.6 0v7.195a.8.8 0 01-1.6 0V6zm4-1.6a.8.8 0 011.6 0v10.688a.8.8 0 01-1.6 0V4.4zm4-.405a.8.8 0 111.6 0v11.6a.8.8 0 01-1.6 0v-11.6zm4 .405a.8.8 0 011.6 0v10.688a.8.8 0 01-1.6 0V4.4zM21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3z" />
      </svg>
    );
  }
);

Intercom.displayName = 'Intercom';

export default Intercom;
