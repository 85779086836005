import { Icon } from '@/models/icon';

const ProfileAvatar = ({ size = 24 }: Icon) => {
  return (
    <svg
      viewBox="0 0 54 54"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.3" cy="26" r="26" fill="var(--icon-default-gray)" />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="55"
        height="54"
      >
        <circle
          cx="27.3"
          cy="26"
          r="26"
          fill="var(--icon-default-gray)"
          stroke="var(--icon-default-gray)"
          strokeWidth="2"
        />
      </mask>
      <g mask="url(#a)" fill="#D1D1D1">
        <circle cx="27.3" cy="19.5" r="10.4" />
        <path d="M27.3 36.4c-10.77 0-19.5 7.334-19.5 16.38 0 0 3.656 7.02 19.5 7.02s19.5-7.02 19.5-7.02c0-9.047-8.73-16.38-19.5-16.38z" />
      </g>
    </svg>
  );
};

export default ProfileAvatar;
