import { createSelector } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';

import { Expressions } from '@/models/expressions';
import { DraftIntent } from '@/models/intent';
import { RootState } from '@/models/state';

export const selectIntentDraft = createSelector(
  (state: RootState) => state.expressions,
  (expressionsState: Expressions) => {
    const allTexts = expressionsState.allIds
      ?.map((id) => expressionsState.byId[id].text.trim())
      ?.filter((e) => e !== '');

    const uniqTexts = uniq(allTexts);
    const intentName = expressionsState.intentName;
    const previousName = expressionsState.previousName;

    const intentDraft: DraftIntent = {
      expressions: uniqTexts,
      new_intent: intentName,
      previous_intent: previousName,
    };

    return intentDraft;
  }
);

const selectAllIds = (state: RootState) => state.expressions.allIds;
const selectById = (state: RootState) => state.expressions.byId;

export const selectExpressionsWithId = createSelector(
  [selectAllIds, selectById],
  (allIds, byId) => allIds?.map((id: string) => byId[id])
);

export const selectExpressions = createSelector(
  [selectAllIds, selectById],
  (allIds, byId) => allIds?.map((id: string) => byId[id].text)
);
