import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const LivePerson: React.FC<Icon> = ({
  size = 24,
  color = '#FF7113',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled_fill : '';

  return (
    <svg
      viewBox="0 0 24 24"
      className={`${styles.icon_fill} ${disabledClass}`}
      width={size}
      height={size}
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      style={{
        color,
      }}
    >
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 0v24h24V.001H0V0z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path d="M17.716 12.154a5.714 5.714 0 01-5.708 5.708 5.713 5.713 0 01-5.724-5.709c0-3.156 2.56-5.7 5.724-5.7 3.139 0 5.708 2.544 5.708 5.7v.001zM24 13.746v-3.154l-2.527.137a8.885 8.885 0 00-.178-.861l2.391-.843-1.206-2.921-2.28 1.099c-.15-.24-.32-.48-.49-.724l1.896-1.688-2.238-2.225-1.696 1.895a6.178 6.178 0 00-.722-.5l1.098-2.281-2.92-1.207-.83 2.405a9.734 9.734 0 00-.867-.189l.144-2.53h-3.156l.143 2.53c-.29.05-.576.112-.86.189L8.859.472 5.954 1.68l1.09 2.28a8.746 8.746 0 00-.723.5L4.627 2.566 2.4 4.79 4.292 6.48c-.174.244-.35.483-.497.723L1.521 6.103.306 9.023 2.7 9.87c-.072.284-.13.571-.174.86L0 10.592v3.154l2.527-.139c.045.285.1.565.174.848l-2.395.837L1.52 18.21l2.274-1.098c.148.25.323.489.497.733l-1.891 1.689 2.225 2.223L6.32 19.87c.244.182.475.348.717.495l-1.095 2.28 2.915 1.209.846-2.395c.282.074.564.13.858.178l-.143 2.524h3.155l-.142-2.525c.29-.046.578-.103.865-.177l.83 2.396 2.92-1.21-1.085-2.279c.244-.148.479-.314.71-.496l1.696 1.888 2.238-2.223-1.895-1.69c.17-.243.339-.47.49-.716l2.279 1.082 1.206-2.918-2.39-.836c.07-.283.133-.564.176-.849l2.528.14v-.001z" />
      </g>
    </svg>
  );
};

export default LivePerson;
