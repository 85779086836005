import { useState, useEffect } from 'react';

import he from 'he';
import markdown from 'remark-parse';
import slate from 'remark-slate';
import { Node } from 'slate';
import { unified } from 'unified';

export const useMarkdownToSlate = (markdownText = ''): Node[] => {
  const [value, setValue] = useState<Node[]>([
    { children: [{ text: markdownText }] },
  ]);

  useEffect(() => {
    const processMarkdownToSlate = async () => {
      const decodedMarkdown = he.decode(markdownText);
      try {
        if (decodedMarkdown === '') {
          setValue([{ children: [{ text: '' }] }]);
        } else {
          const result = await unified()
            .use(markdown)
            .use(slate)
            .process(decodedMarkdown);
          setValue([{ children: result.result }] as Node[]);
        }
      } catch (err) {
        console.error('Error converting markdown to slate:', err);
      }
    };

    processMarkdownToSlate();
  }, [markdownText]);
  return value;
};
