export const ConversationIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14 7.667a5.587 5.587 0 0 1-.6 2.533 5.666 5.666 0 0 1-5.067 3.133 5.585 5.585 0 0 1-2.533-.6L2 14l1.267-3.8a5.586 5.586 0 0 1-.6-2.533A5.667 5.667 0 0 1 5.8 2.6 5.587 5.587 0 0 1 8.333 2h.334A5.654 5.654 0 0 1 14 7.333v.334Z"
      />
    </svg>
  );
};
