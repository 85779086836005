import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { ConnectDragSource } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ErrorIcon } from '@/components/atoms/Icons/Error';
import ActionHeader from '@/components/organisms/Dialogs/ActionHeader/ActionHeader';
import DialogTooltip from '@/components/organisms/Dialogs/DialogTooltip/DialogTooltip';
import useDialogAlerts from '@/hooks/useDialogAlerts';
import { Condition } from '@/models/node';
import {
  selectIsIdInFlow,
  selectIsNodeConditionRequisiteSelected,
} from '@/redux/nodes/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import styles from './ConditionBox.module.scss';

interface ConditionBoxProps {
  dragRef: ConnectDragSource;
  isSelected: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  match: string;
  name: string;
  id: string;
  index: number;
  isCollapsed: boolean;
  onDelete: () => void;
  condition: Condition;
}

const ConditionBox = ({
  dragRef,
  isSelected,
  onClick,
  onKeyDown,
  name,
  id,
  index,
  isCollapsed,
  onDelete,
  condition,
}: ConditionBoxProps) => {
  const { t } = useTranslation();
  const isActionInFlow = useSelector(selectIsIdInFlow(id));
  const isAnythingSelected = useSelector(
    selectIsNodeConditionRequisiteSelected
  );
  const { hasDialogError } = useDialogAlerts(id);

  return (
    <div
      key={id}
      ref={dragRef}
      className={cn(styles.condition, {
        [styles['condition--selected']]: isSelected && !hasDialogError,
        [styles['condition--selectedError']]: isSelected && hasDialogError,
        [styles.collapsed]: isCollapsed,
        [styles.opacity]: !isActionInFlow && !isAnythingSelected,
      })}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={index}
    >
      {isCollapsed ? (
        <DialogTooltip onDelete={onDelete} type="condition">
          <Typography
            className={styles.collapsed__content}
            component="div"
            variant="body-semi-bold"
          >
            {index + 1}
          </Typography>
        </DialogTooltip>
      ) : (
        <Box position="relative" component="section" className={styles.box}>
          <div className={styles.icon}>{hasDialogError && <ErrorIcon />}</div>

          <ActionHeader
            onDelete={onDelete}
            type="condition"
            objectToCopy={condition}
            isSelected={isSelected}
            hasDialogError={hasDialogError}
          />
          <div className={styles.condition__content}>
            <Typography
              variant="label-regular"
              color="var(--text-default-gray)"
            >
              {name}
            </Typography>

            <div className={styles.condition__rules}>
              <Chip
                className={styles.tag}
                size="small"
                label={capitalizeFirstLetter(condition.match)}
                color="primary"
              />

              {condition.match !== 'else' && (
                <Typography
                  variant="label-regular"
                  color="var(--text-default-gray-light)"
                >
                  {condition.rules.length === 1
                    ? t('dialog.condition.rule.single')
                    : t('dialog.condition.rule.many', {
                        0: condition.rules.length,
                      })}
                </Typography>
              )}
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default ConditionBox;
