import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Input from '@/components/atoms/Input/Input';
import ReorderWrapper from '@/components/atoms/ReorderWrapper/ReorderWrapper';
import ToolkitAccordionWrapper from '@/components/organisms/Toolkit/ToolkitAccordionWrapper';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectNodeIdByActionId } from '@/redux/dialogs/selectors';
import { capitalizeFirstLetter } from '@/util/util';
import { optionSchema } from '@/util/validator';

type FormType = {
  label: string;
  text: string;
};

type Props = {
  actionId: string;
  option: {
    label: string;
    text: string;
  };
  onChange: (value: { [key: string]: string }, index: number) => void;
  onDelete: (index: number) => void;
  index: number;
  handleDragItem: (dragIndex: number, hoverIndex: number) => void;
  getIndex: () => number;
  onDrop: () => void;
};

const ToolkitButton = memo(
  ({
    actionId,
    option,
    onChange,
    onDelete,
    index,
    handleDragItem,
    getIndex,
    onDrop,
  }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
      formState: { errors, isDirty },
      register,
      trigger,
      getValues,
    } = useForm<FormType>({
      mode: 'onChange',
      defaultValues: {
        label: option.label ?? '',
        text: option.text ?? '',
      },
      resolver: yupResolver(optionSchema) as Resolver<FormType>,
    });

    const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

    const optionLabelText = getValues('label');
    const optionTextText = getValues('text');

    const errorLabelRef = useRef(false);
    const errorTextRef = useRef(false);

    const labelErrorMessage = useMemo(
      () => capitalizeFirstLetter(errors?.label?.message),
      [errors?.label?.message]
    );
    const textErrorMessage = useMemo(
      () => capitalizeFirstLetter(errors?.text?.message),
      [errors?.text?.message]
    );
    const hasError = !!errors?.label?.message || !!errors?.text?.message;

    const { optionOpen } = useSelector(
      (state: RootState) => ({
        optionOpen: state.nodes.selectedOptionIndex === index,
      }),
      shallowEqual
    );

    const defaultOpenState = optionOpen || hasError || isDirty;
    const [isOpen, setIsOpen] = useState(defaultOpenState);

    useEffect(() => {
      setIsOpen(defaultOpenState);
    }, [defaultOpenState]);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange({ [name]: value }, index);
      },
      [index, onChange]
    );

    const dispatchDialogError = useCallback(
      (errorMessage: string, alertField: string) => {
        dispatch(
          updateDialogAlerts({
            dialogAlerts: {
              alertType: 'error',
              id: actionId,
              nodeId: parentNodeId,
              title: t('actions.types.text'),
              body: capitalizeFirstLetter(errorMessage),
              type: 'text',
              alertField,
            },
          })
        );
      },
      [actionId, dispatch, parentNodeId, t]
    );

    const handleDelete = useCallback(() => {
      onDelete(index);
      dispatchDialogError(undefined, `label${index}`);
      dispatchDialogError(undefined, `text${index}`);
    }, [dispatchDialogError, index, onDelete]);

    const handleOpen = useCallback(() => {
      setIsOpen((prev) => !prev);
    }, []);

    const checkInitialLabelError = useCallback(async () => {
      const isValid = await trigger('label');
      if (labelErrorMessage && !isValid) {
        dispatchDialogError(labelErrorMessage, `label${index}`);
        errorLabelRef.current = true;
      }
    }, [dispatchDialogError, index, labelErrorMessage, trigger]);

    // Dialog errors handlers
    useEffect(() => {
      checkInitialLabelError();
    }, [checkInitialLabelError, trigger]);

    const removeErrorMessage = useCallback(async () => {
      const isValid = await trigger('label');
      if (isValid) {
        dispatchDialogError(undefined, `label${index}`);
        errorLabelRef.current = false;
      }
    }, [dispatchDialogError, index, trigger]);

    useEffect(() => {
      if (errorLabelRef && errorLabelRef.current) {
        removeErrorMessage();
      }
    }, [
      dispatchDialogError,
      index,
      removeErrorMessage,
      trigger,
      optionLabelText,
    ]);

    const checkInitialTextError = useCallback(async () => {
      const isValid = await trigger('text');
      if (textErrorMessage && !isValid) {
        dispatchDialogError(textErrorMessage, `text${index}`);
        errorTextRef.current = true;
      }
    }, [dispatchDialogError, index, textErrorMessage, trigger]);

    useEffect(() => {
      checkInitialTextError();
    }, [checkInitialTextError, trigger]);

    const removeErrorText = useCallback(async () => {
      const isValid = await trigger('text');
      if (isValid) {
        dispatchDialogError(undefined, `text${index}`);
        errorTextRef.current = false;
      }
    }, [dispatchDialogError, index, trigger]);

    useEffect(() => {
      if (errorTextRef && errorTextRef.current) {
        removeErrorText();
      }
    }, [dispatchDialogError, index, removeErrorText, trigger, optionTextText]);

    return (
      <ReorderWrapper
        dragId="text-button"
        index={index}
        moveItem={handleDragItem}
        getIndex={getIndex}
        placeholderHeight={36}
        dropItem={onDrop}
        canDrag={!isOpen}
      >
        <ToolkitAccordionWrapper
          title={option.label}
          handleDelete={handleDelete}
          height="small"
          onChange={handleOpen}
          expanded={isOpen}
        >
          <Input
            error={!!errors?.label}
            errorMessage={labelErrorMessage}
            label={t('common.label')}
            name="label"
            onChange={handleChange}
            placeholder={t('dialog.label_placeholder')}
            register={register('label')}
            size="small"
          />

          <Input
            error={!!errors?.text}
            errorMessage={textErrorMessage}
            label={t('common.text')}
            name="text"
            onChange={handleChange}
            placeholder={t('dialog.label_placeholder')}
            register={register('text')}
            size="small"
          />
        </ToolkitAccordionWrapper>
      </ReorderWrapper>
    );
  }
);

ToolkitButton.displayName = 'ToolkitButton';

export default ToolkitButton;
