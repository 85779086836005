import { memo, useCallback, useState, Fragment } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Autocomplete from '@/components/atoms/AutoComplete/AutoComplete';
import { useInvitations } from '@/hooks/useInvitations';
import useMembers from '@/hooks/useMembers';
import { useRoles } from '@/hooks/useRoles';
import { useTeams } from '@/hooks/useTeams';
import { useTeamUsers } from '@/hooks/useTeamUsers';
import { useUserRoles } from '@/hooks/useUserRoles';
import { RoleType } from '@/models/member';
import { popModal } from '@/redux/modals/actions';
import { errorMessage } from '@/util/validator';

import FooterWarning from './FooterWarning';
import Modal from '../Modal';

interface Props {
  user: {
    email: string;
    role_ids: string[];
    invitation_code?: string;
    user_id?: string;
    name?: string;
    role?: string;
    team_ids?: string[];
  };
}
const ModalRoleEdit = ({ user }: Props) => {
  const { t } = useTranslation();
  const { addUsers, removeUser } = useTeamUsers();
  const { members, updateMember } = useMembers();

  const { updateInvitation } = useInvitations();
  const { removeRole, assignRoles } = useUserRoles(user?.user_id);
  const { roles, availableRoles } = useRoles();

  const team_ids =
    members.find((m) => m.user_id === user?.user_id)?.team_ids ??
    user?.team_ids;
  const { teams } = useTeams();

  const defaultTeams =
    team_ids?.reduce((acc, id) => {
      const team = teams.find((t) => t.team_id === id);
      if (team) {
        acc.push({
          value: team.team_id,
          label: team.name,
        });
      }
      return acc;
    }, []) ?? [];

  const defaultRoles =
    user?.role === RoleType.RBAC
      ? roles
          .filter((r) => user?.role_ids?.includes(r.role_id))
          .map((role) => ({
            label: role.name,
            value: role.role_id,
          }))
      : [];

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      roles: defaultRoles,
      teams: defaultTeams,
    },
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const teamOptions = teams
    ?.filter((t) => t.role_ids.length > 0)
    ?.map((team) => ({
      label: team.name,
      value: team.team_id,
      description: team.description,
    }));

  const onSubmit = useCallback(
    async (form: {
      roles: { value: string }[];
      teams: { value: string }[];
    }) => {
      setIsLoading(true);
      const newRoleids = form.roles.map((r) => r.value) as string[];
      const newTeamIds = form.teams.map((r) => r.value) as string[];
      // If user has invitation, update invitation
      if (user.invitation_code) {
        updateInvitation(
          {
            email: user.email,
            role: RoleType.RBAC,
            role_ids: newRoleids,
            team_ids: newTeamIds,
          },
          {
            onSuccess: () => {
              dispatch(popModal());
            },
          }
        );
      } else {
        const removedRoled = user?.role_ids.filter(
          (role) => !newRoleids.includes(role)
        );

        if (removedRoled.length > 0) {
          await Promise.all(
            removedRoled.map(async (role_id: string) => {
              return removeRole({ user_id: user?.user_id, role_id });
            })
          );
        }
        const addedRoles = newRoleids.filter(
          (role) => !user?.role_ids.includes(role)
        );

        if (addedRoles.length > 0) {
          await assignRoles({ role_ids: newRoleids, user_id: user.user_id });
        }
        const newTeamIds = form.teams.map((r) => r.value) as string[];

        const removedTeamIds = team_ids.filter(
          (team) => !newTeamIds.includes(team)
        );

        if (removedTeamIds.length > 0) {
          await Promise.all(
            removedTeamIds.map(async (team_id: string) => {
              return removeUser({
                team_id: team_id,
                user_id: user?.user_id,
              });
            })
          );
        }

        const addedTeamIds = newTeamIds.filter(
          (team) => !team_ids.includes(team)
        );

        await Promise.all(
          addedTeamIds.map(async (team_id: string) => {
            const newTeam = {
              user_ids: [user.user_id],
              team_id,
            };

            return addUsers(newTeam);
          })
        );

        if (user?.role !== RoleType.RBAC) {
          updateMember({ user_id: user?.user_id, role: RoleType.RBAC });
        }
        dispatch(popModal());
      }
    },
    [
      addUsers,
      assignRoles,
      dispatch,
      removeRole,
      removeUser,
      team_ids,
      updateInvitation,
      updateMember,
      user.email,
      user.invitation_code,
      user?.role,
      user?.role_ids,
      user.user_id,
    ]
  );
  const rolesField = watch('roles');
  const teamsField = watch('teams');

  const isFormEmpty = rolesField.length === 0 && teamsField.length === 0;

  const memberAllRoles = rolesField.map((t) => t.value);
  const teamAllRoles =
    teamsField?.reduce((acc, t) => {
      const { role_ids } = teams.find((team) => t.value === team.team_id);
      return [...acc, ...role_ids];
    }, []) ?? [];

  const allRoles = [...new Set([...memberAllRoles, ...teamAllRoles])];

  return (
    <Modal
      size="medium"
      title={
        <>
          {t('roles.edit_access_for')}
          <span className="formattedSpan">{user.name ?? user.email}</span>
        </>
      }
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('common.save')}
      primaryButtonDisable={isFormEmpty}
      isSubmitting={isLoading}
      autoFocus
    >
      <form autoComplete="off">
        <div className="input__container">
          <Autocomplete
            control={control}
            name="roles"
            labelLarge
            label={t('onboarding.members_roles')}
            disabled={!roles}
            options={availableRoles}
            placeholder={t('onboarding.roles_placeholder')}
            multiple
            disableAddNew
            size="small"
            variant="secondary"
            error={!!errors?.roles?.message}
            errorMessage={errorMessage({
              field: errors.roles,
            })}
          />
        </div>

        <div className="input__container">
          <Autocomplete
            control={control}
            name="teams"
            labelLarge
            label={t('common.teams')}
            options={teamOptions ?? []}
            placeholder={t('teams.select_team')}
            multiple
            disableAddNew
            size="small"
            variant="secondary"
            error={!!errors?.teams?.message}
            errorMessage={errorMessage({
              field: errors.teams,
            })}
          />
        </div>
        <FooterWarning allRoles={allRoles} roles={roles} showEmptyMessage />
      </form>
    </Modal>
  );
};

export default memo(ModalRoleEdit);
