import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';

const customStyle = {
  fontSize: 12,
  verticalAlign: 'middle',
  marginLeft: 'var(--space-4)',
  color: 'var(--text-default-black)',
};

type Props = {
  tooltip: React.ReactNode | string;
};

function HelpTooltip({ tooltip = '' }: Props) {
  if (!tooltip) {
    return null;
  }
  return (
    <Tooltip arrow title={tooltip}>
      <HelpOutlineIcon style={customStyle} />
    </Tooltip>
  );
}

export default HelpTooltip;
