import React, { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './Attachments.module.scss';

const Attachments = ({ children }: PropsWithChildren) => {
  const count = React.Children.count(children);
  return (
    <div className={cn(styles.container, styles[`count--${count}`])}>
      {children}
    </div>
  );
};

export default Attachments;
