import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import MenuItem from '@mui/material/MenuItem';
import { TypographyVariant } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useIsMutating } from '@tanstack/react-query';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { documentsEndpoints as endpoints } from '@/api/endpoints';
import { callGet } from '@/api/fetcher';
import Button from '@/components/atoms/Button/Button/Button';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import FileUpload from '@/components/atoms/FileUpload/FileUpload';
import { AcceptedType } from '@/components/atoms/FileUpload/utils';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Duplicate from '@/components/atoms/Icons/Duplicate';
import Edit from '@/components/atoms/Icons/Edit';
import ExportIcon from '@/components/atoms/Icons/ExportAnalytics';
import MoreHorizontal from '@/components/atoms/Icons/MoreHorizontal';
import Replace from '@/components/atoms/Icons/Replace';
import Trash from '@/components/atoms/Icons/Trash';
import Link from '@/components/atoms/Link/Link';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Switch from '@/components/atoms/Switch/Switch';
import Table from '@/components/atoms/Table/Table';
import Header from '@/components/organisms/Header/Header';
import { TableInfiniteLoader } from '@/components/organisms/InfiniteLoader/TableInfiniteLoader';
import {
  MODAL_DATASOURCE_ADD,
  MODAL_DELETE,
} from '@/components/organisms/Modals/ModalConductor';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useCollections from '@/hooks/useCollections';
import useDatasources from '@/hooks/useDatasources';
import useDocuments from '@/hooks/useDocuments';
import {
  DatasourceStatus,
  DatasourceType,
  Document,
  DocumentStatus,
  WebsiteConfig,
} from '@/models/collections';
import { actions } from '@/models/permissions';
import { EventName, PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountId, selectAccountSlug } from '@/redux/session/selectors';
import { pageView, trackEvent } from '@/segment/segment';
import { FIFTEEN_MB, FILES_BASE_URL, HUNDRED_BYTES } from '@/util/constants';
import {
  delay,
  bytesToSize,
  capitalizeFirstLetter,
  renderCroppedURL,
  getRestrictedNames,
} from '@/util/util';
import { getEditableTextValidationSchema, LENGTH_XS } from '@/util/validator';

import { useDatasource } from './useDatasource';
import {
  ACCEPTED_DATASOURCE_FORMATS,
  PLAIN_DATASOURCE_FORMATS,
  getFilesType,
  getIcon,
  handleDuplicateFiles,
  getStatusColor,
} from './utils';
import CollectionSidebar from '../Collection/CollectionSidebar/CollectionSidebar';

import styles from './Datasource.module.scss';

const getDisabledMessage = (
  type: 'web' | 'file',
  totalFragmentsReached: boolean,
  t
) => {
  if (totalFragmentsReached && type === 'web') {
    return t('limits.webpages_fragments_reached');
  }
  if (totalFragmentsReached && type === 'file') {
    return t('limits.files_fragments_reached');
  }
  return t('collections.documents.no_permission');
};

const FILE_URL_CHARACTERS = 55;
const DATASOURCE_URL_CHARACTERS = 85;

const FILE_URL_ENDING_CHARACTERS = 25;
const DATASOURCE_URL_ENDING_CHARACTERS = 55;

const Datasource = () => {
  const isMutating = useIsMutating();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tooltipMessage, setTooltipMessage] = useState('');
  const intervalId = useRef(null);
  const [files, setFiles] = useState<AcceptedType[]>([]);
  const [duplicates, setDuplicates] = useState<AcceptedType[]>([]);
  const { collectionId, datasourceId } = useParams();
  const { totalFragmentsReached } = useCollections(collectionId);

  const [filter, setFilter] = useState('');
  const defferedFilter = useDeferredValue(filter);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );

  const canDelete = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.DELETE)
  );

  const canCreateDocument = !totalFragmentsReached && canWrite;
  const [anchorEl, setAnchorEl] = useState(null);

  const [urlParams] = useSearchParams();
  const activeDocumentId = urlParams.get('documentId');

  const { document: urlDocument } = useDocuments(
    collectionId,
    datasourceId,
    activeDocumentId
  );

  const open = Boolean(anchorEl);
  const slug = useSelector(selectAccountSlug);

  const {
    datasource,
    datasources,
    getStatus,
    updateDatasource,
    generateModalReportData,
  } = useDatasources(collectionId, datasourceId);

  const {
    documents,
    documentNames,
    deleteDocumentAsync,
    listStatus,
    fetchNextPage,
    hasNextPage,
  } = useDocuments(collectionId, datasourceId, undefined, defferedFilter);

  useEffect(() => {
    pageView(PageName.DATASOURCE);
  }, []);

  useEffect(() => {
    if (urlDocument) {
      setFilter(urlDocument.name);
    }
  }, [urlDocument]);

  useTitle(t('pages.datasource', { 0: datasource?.name }));
  const hasSeedUrls =
    (datasource?.config as WebsiteConfig)?.seed_urls?.length > 0;

  const accountId = useSelector(selectAccountId);
  const [selectedDocument, setSelectedDocument] = useState<Document>(null);

  const {
    onFileCreationClick,
    handleDocumentToggle,
    setFilesLength,
    setUrls,
    handleReplaceDuplicateFile,
    handleReplaceFileUploaded,
  } = useDatasource();

  useEffect(() => {
    return () => clearInterval(intervalId.current);
  }, []);

  const handleMenuClose = useCallback(async () => {
    setAnchorEl(null);
    await delay(200);
    setSelectedDocument(null);
  }, []);

  const handleMenuClick = useCallback(
    (event, id: string) => {
      const activeDocument = documents.find((d) => d.document_id === id);
      setSelectedDocument(activeDocument);
      setAnchorEl(event.currentTarget);
    },
    [documents]
  );

  const getColumns = useMemo(() => {
    const cols: {
      Header: string | JSX.Element;
      Cell: (data) => JSX.Element;
      accessor?: string;
      id?: string;
    }[] = [
      {
        Header: getFilesType(datasource?.type),
        accessor: 'name',

        Cell: (props) => {
          const typeSpecificUrlLimitStart =
            datasource?.type === 'files'
              ? FILE_URL_CHARACTERS
              : DATASOURCE_URL_CHARACTERS;
          const typeSpecificUrlLImitEnd =
            datasource?.type === 'files'
              ? FILE_URL_ENDING_CHARACTERS
              : DATASOURCE_URL_ENDING_CHARACTERS;

          const url = props.row.original?.external_url;
          const croppedUrl = renderCroppedURL(
            decodeURIComponent(url?.split('?')[0]),
            typeSpecificUrlLimitStart,
            typeSpecificUrlLImitEnd
          );
          return (
            <div
              id={props.row.original.document_id}
              className={cn(styles.center, {
                [styles.centerVertical]: url,
              })}
            >
              <Typography className={cn(styles.title, styles.ellipsis)}>
                {props.row.original.name}
              </Typography>
              {url && (
                <Typography variant="label-regular">
                  <Link href={url} className={styles.link} external>
                    {url.length !== croppedUrl.length ? (
                      <Tooltip arrow title={url} enterDelay={300}>
                        <span>{croppedUrl}</span>
                      </Tooltip>
                    ) : (
                      url
                    )}
                  </Link>
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        Header: t('common.status'),
        accessor: 'status',
        Cell: (props) => {
          const row: Document = props.row.original;
          let errorMessage = '';
          if (row.status === DocumentStatus.FAILED) {
            errorMessage = t(
              `collections.documents.${row.status_code}`,
              'collections.documents.doc_603'
            );
          }

          return (
            <div className={styles.center}>
              <Tooltip arrow title={errorMessage} enterDelay={300}>
                <span>
                  <StatusBadge
                    withIcon={false}
                    variant={getStatusColor(row.status)}
                    label={capitalizeFirstLetter(t(`status.${row.status}`))}
                  />
                </span>
              </Tooltip>
            </div>
          );
        },
      },
      {
        Header: (
          <span className={styles.help}>
            {t('common.enabled')}{' '}
            <HelpTooltip tooltip={t('collections.enabled_tooltip')} />
          </span>
        ),
        accessor: 'enabled',
        Cell: (props) => {
          const row: Document = props.row.original;
          return (
            <div className={styles.center}>
              <Switch
                name="active"
                checked={row.enabled}
                disabled={!canWrite}
                onChange={() => handleDocumentToggle(row)}
              />
            </div>
          );
        },
      },
    ];
    const sizeColumn = {
      Header: t('common.size'),
      accessor: 'size_bytes',
      Cell: (props) => {
        return (
          <div className={styles.center}>
            <Typography className={styles.title}>
              {bytesToSize(props.row.original.size_bytes)}
            </Typography>
          </div>
        );
      },
    };
    const menuColumn = {
      Header: '',
      id: 'menu',
      Cell: (props) => {
        const row: Document = props.row.original;

        return (
          <IconButton
            onClick={(e) => handleMenuClick(e, row.document_id)}
            ariaLabel={`Datasource options for ${row.name}`}
            ariaHasPopUp
          >
            <MoreHorizontal size={24} />
          </IconButton>
        );
      },
    };

    if (datasource?.type === DatasourceType.FILES) {
      cols.splice(2, 0, sizeColumn);
    }
    cols.push(menuColumn);

    return cols;
  }, [canWrite, datasource?.type, handleDocumentToggle, handleMenuClick, t]);

  const handleSubmit = useCallback(
    (text: string) => {
      updateDatasource(
        { ...datasource, name: text },
        {
          onSuccess: () => {
            dispatch(popModal());
          },
        }
      );
    },
    [datasource, dispatch, updateDatasource]
  );

  const handleDocumentDelete = useCallback(() => {
    setAnchorEl(null);
    const deleteProps = {
      subtitle: (
        <span className={cn(styles.ellipsis, styles.deleteModalSubtitle)}>
          <Trans
            i18nKey="collections.documents.delete_subtitle"
            values={[selectedDocument?.name]}
          />
        </span>
      ),
      title: t('collections.documents.delete_title'),
      confirm: true,
      onDelete: () => {
        deleteDocumentAsync(selectedDocument.document_id, {
          onSuccess: () => {
            dispatch(popModal());
          },
        });
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [
    deleteDocumentAsync,
    dispatch,
    selectedDocument?.document_id,
    selectedDocument?.name,
    t,
  ]);

  const onUrlImport = () => {
    trackEvent(EventName.ClickEditDatasource, {
      type: datasource?.type,
    });
    dispatch(
      pushModal(MODAL_DATASOURCE_ADD, {
        isImportWebsite: true,
        isUpdate: hasSeedUrls,
      })
    );
  };

  const onNewDocumentCreateClick = () => {
    trackEvent(EventName.ClickCreateDocument);
    onFileCreationClick();
  };

  const icon = getIcon(datasource?.type);

  const getHeaderAction = () => {
    if (datasource?.type === DatasourceType.WEBSITE) {
      return (
        <Tooltip
          arrow
          title={
            !canCreateDocument
              ? getDisabledMessage('web', totalFragmentsReached, t)
              : ''
          }
        >
          <span>
            <Button
              onClick={onUrlImport}
              disabled={!canCreateDocument}
              variant="tertiary"
            >
              {t('collections.import_urls')}
            </Button>
          </span>
        </Tooltip>
      );
    }
    if (datasource?.type === DatasourceType.FILES) {
      return (
        <Tooltip
          arrow
          title={
            !canCreateDocument
              ? getDisabledMessage('file', totalFragmentsReached, t)
              : ''
          }
        >
          <Button
            onClick={onNewDocumentCreateClick}
            disabled={!canCreateDocument}
            variant="tertiary"
          >
            {t('collections.datasources.create_file')}
          </Button>
        </Tooltip>
      );
    }

    return null;
  };

  // Filters out the current datasource name from the list of restricted values
  const restrictedValues = getRestrictedNames(datasources, datasource?.name);

  const validationSchema = getEditableTextValidationSchema(
    LENGTH_XS,
    restrictedValues,
    t('collections.datasources.datasource_name')
  );

  const editableTextProps = {
    defaultValue: datasource?.name,
    onSubmit: handleSubmit,
    variant: 'h2-semi-bold' as TypographyVariant,
    canEdit: canWrite,
    validationSchema,
  };

  const availableFiles =
    datasource?.document_count -
    datasource?.failed_count -
    datasource?.pending_count -
    datasource?.indexing_count;

  const filesData = [
    {
      label: t('common.available'),
      value: availableFiles,
    },
    {
      label: t('collections.in_progress'),
      value: datasource?.pending_count + datasource?.indexing_count,
    },
    {
      label: t('collections.datasources.failed'),
      value: datasource?.failed_count,
    },
  ];

  const getArticlePercentage = useCallback(() => {
    if (datasource?.document_count === 0) {
      return datasource?.document_count;
    }
    const percentage = (availableFiles / datasource?.document_count) * 100;
    return percentage;
  }, [availableFiles, datasource?.document_count]);

  const handleDocumentDownload = useCallback(() => {
    trackEvent(EventName.ClickExportDocument);
    setAnchorEl(null);

    const url = `${window?.location?.origin}/www/api/v1/collections/${collectionId}/datasources/${datasourceId}/documents/${selectedDocument?.document_id}/download?account_slug=${slug}`;
    window.location.href = url;
  }, [collectionId, datasourceId, selectedDocument?.document_id, slug]);

  const handleDocumentEdit = async () => {
    trackEvent(EventName.ClickEditFile);
    setAnchorEl(null);
    const data = await callGet(
      endpoints.exportDocument(
        collectionId,
        datasourceId,
        selectedDocument?.document_id
      ),
      {
        responseType: 'text',
      }
    );

    onFileCreationClick(selectedDocument, data);
  };

  const handleReplaceAll = async (
    uploadFiles: (x: AcceptedType[]) => Promise<void>
  ) => {
    const proms = duplicates.map((file) =>
      handleReplaceDuplicateFile(file, selectedDocument)
    );
    await Promise.all(proms);
    await uploadFiles(files);
    setFiles([]);
    setDuplicates([]);
  };

  const handleMergeAll = async (
    uploadFiles: (x: AcceptedType[]) => Promise<void>
  ) => {
    const renamedFiles = handleDuplicateFiles(duplicates, documentNames);
    await uploadFiles([...files, ...renamedFiles]);
    setFiles([]);
    setDuplicates([]);
  };

  return (
    <>
      <Header>
        <Header.BreadCrumbs />

        <Header.Body>
          <Header.Title
            title={
              <EditableText<typeof validationSchema> {...editableTextProps} />
            }
            icon={icon}
            isLoading={getStatus === 'pending'}
          />
          <Header.Actions>{getHeaderAction()}</Header.Actions>
        </Header.Body>
      </Header>

      <PageContentWrapper newPlain2 readOnly={!canWrite}>
        <div>
          {datasource?.type === DatasourceType.FILES && canWrite && (
            <div className={styles.fileUploadContainer}>
              <FileUpload
                message={t('collections.file_placeholder')}
                stream
                accept={ACCEPTED_DATASOURCE_FORMATS}
                parseJson={false}
                urlValue=""
                withThumbnail
                maxSizeInBytes={FIFTEEN_MB}
                minSizeInBytes={HUNDRED_BYTES}
                uploadPath={`${FILES_BASE_URL}/${accountId}/${collectionId}/${datasourceId}`}
                multiple
                setUrls={setUrls}
                setFilesLength={setFilesLength}
                restrictedNames={documentNames}
                onReplaceAll={handleReplaceAll}
                onMergeAll={handleMergeAll}
                duplicatesLength={duplicates.length}
                setDuplicates={setDuplicates}
                setFiles={setFiles}
                disabled={!canCreateDocument}
                isLoadingProp={isMutating > 0}
              />
            </div>
          )}
          <div
            className={cn(styles.main, {
              [styles.documentContainer]:
                datasource?.type === DatasourceType.FILES,
            })}
          >
            <div className={styles.tableContainer}>
              {listStatus === 'success' && documents?.length == 0 && !filter ? (
                <TileEmptyPage
                  title={t('collections.no_files', {
                    0: getFilesType(datasource?.type).toLowerCase(),
                  })}
                  notClickable
                  icon={getIcon(
                    datasource?.type,
                    'var(--icon-default-blue)',
                    100
                  )}
                />
              ) : (
                <>
                  {documents && datasource?.type && (
                    <PlainFieldset
                      fullWidth
                      overflown
                      isLoading={
                        documents?.length === 0 &&
                        datasource?.status === DatasourceStatus.PROCESSING
                      }
                    >
                      <TableInfiniteLoader
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                      >
                        <Table
                          data={documents}
                          columns={getColumns}
                          flexLayout
                          sortable
                          headerHeight="medium"
                          searchPlaceholder={t('collections.search_title')}
                          filterable
                          noGutters
                          searchable={false}
                          onAPISearch={setFilter}
                          sortBy="created"
                          defaultSearchValue={filter}
                        />
                      </TableInfiniteLoader>

                      <CustomMenu
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleMenuClose}
                      >
                        {datasource?.type === DatasourceType.FILES && (
                          <MenuItem
                            onClick={handleDocumentDownload}
                            disabled={!canDelete}
                          >
                            <ExportIcon
                              size={16}
                              color="var(--icon-default-gray)"
                            />

                            {t('collections.download')}
                          </MenuItem>
                        )}

                        <Tooltip
                          open
                          arrow
                          title={tooltipMessage ? tooltipMessage : ''}
                        >
                          <MenuItem
                            onClick={() => {
                              trackEvent(EventName.ClickCopyDocumentId, {
                                document_id: selectedDocument?.document_id,
                              });
                              const text = selectedDocument?.document_id;
                              navigator.clipboard.writeText(text);
                              setTooltipMessage(t('common.copied'));
                              intervalId.current = setTimeout(() => {
                                setTooltipMessage('');
                              }, 1200);
                            }}
                            disabled={!canWrite}
                          >
                            <Duplicate />
                            {t('collections.datasources.copy_id')}
                          </MenuItem>
                        </Tooltip>
                        {datasource?.type === DatasourceType.FILES
                          ? [
                              selectedDocument?.type === 'txt' ? (
                                <MenuItem
                                  onClick={handleDocumentEdit}
                                  disabled={!canDelete}
                                  key="edit-file"
                                >
                                  <Edit />
                                  {t('common.edit')}
                                </MenuItem>
                              ) : null,

                              <MenuItem
                                className={styles.replaceContainer}
                                onClick={() => {
                                  trackEvent(EventName.ClickReplaceFile);
                                  setAnchorEl(null);
                                }}
                                disabled={!canWrite}
                                key="replace-file"
                              >
                                <Replace />
                                {t('collections.replace')}
                                <input
                                  onChange={(e) =>
                                    handleReplaceFileUploaded(
                                      e,
                                      selectedDocument
                                    )
                                  }
                                  type="file"
                                  accept={PLAIN_DATASOURCE_FORMATS}
                                />
                              </MenuItem>,

                              <MenuItem
                                onClick={handleDocumentDelete}
                                disabled={!canDelete}
                                className={styles.danger}
                                key="delete-file"
                              >
                                <Trash />
                                {t('common.delete')}
                              </MenuItem>,
                            ]
                          : null}
                      </CustomMenu>
                    </PlainFieldset>
                  )}
                </>
              )}
            </div>

            {getStatus === 'success' && (
              <CollectionSidebar
                percentage={getArticlePercentage()}
                available={availableFiles}
                total={datasource?.document_count}
                data={filesData}
                type={getFilesType(datasource?.type)}
                modalReportData={
                  datasource?.type === 'website'
                    ? generateModalReportData()
                    : null
                }
                hasBanner={
                  datasource?.last_indexed_details?.warnings?.length > 0
                }
              />
            )}
          </div>
        </div>
      </PageContentWrapper>
    </>
  );
};

export default Datasource;
