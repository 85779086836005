import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import uniq from 'lodash/uniq';
import { useForm, Controller, Resolver } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Chips from '@/components/atoms/Chips/Chips';
import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import Switch from '@/components/atoms/Switch/Switch';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import useDesks from '@/hooks/useDesks';
import { Desk } from '@/models/desk';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { capitalizeFirstLetter, isKeyEnter } from '@/util/util';
import { humanChatFieldsetDeskSchema } from '@/util/validator';

import styles from './styles.module.scss';

type Props = {
  desk?: Desk;
};

type Form = {
  max_sessions_per_agent: number;
  tags: string[];
  tag: string;
  is_service_desk: boolean;
};

const ServiceDeskFieldset = ({ desk }: Props) => {
  const { t } = useTranslation();
  const desk_id = desk?.desk_id;
  const { updateDesk, updateStatus } = useDesks(desk_id);

  const {
    register,
    formState: { errors, isDirty, isValid, isSubmitting },
    handleSubmit,
    control,
    clearErrors,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm<Form>({
    mode: 'onChange',
    resolver: yupResolver(humanChatFieldsetDeskSchema) as Resolver<Form>,
  });

  const tags = watch('tags');

  useEffect(() => {
    if (desk) {
      reset({
        max_sessions_per_agent: desk.max_sessions_per_agent,
        is_service_desk: desk.is_service_desk || false,
        tags: desk.metadata?.conversation_tags ?? [],
        tag: '',
      });
    }
  }, [desk, reset]);

  const handleTagBlur = useCallback(() => {
    const { tag } = getValues();
    if (tag && isValid) {
      clearErrors('tag');
      setValue('tags', [...tags, tag]);
      setValue('tag', '');
    }
  }, [clearErrors, getValues, isValid, setValue, tags]);

  const handleTagKeyUp = useCallback(
    (e) => {
      if (isKeyEnter(e)) {
        handleTagBlur();
      }
    },
    [handleTagBlur]
  );

  const removeTag = (tag: string) => {
    const newTags = tags.filter((t) => t !== tag);
    setValue('tags', newTags, { shouldDirty: true });
  };

  const onSubmit = useCallback(() => {
    const { max_sessions_per_agent, is_service_desk } = getValues();

    updateDesk({
      desk_id,
      max_sessions_per_agent,
      is_service_desk,
      metadata: { ...desk?.metadata, conversation_tags: tags },
    });
  }, [desk?.metadata, desk_id, getValues, tags, updateDesk]);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'desks', actions.WRITE)
  );
  return (
    <div>
      <FormFieldset
        title={t('environments.human_chat')}
        subtitle={t('environments.human_chat_subtitle')}
        primaryButton={{
          children: t('common.save'),
          isLoading: updateStatus === 'pending',
          disabled: !canWrite || !isDirty || !isValid,
        }}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={!desk || isSubmitting}
      >
        <div className={styles.container}>
          <Controller
            name="is_service_desk"
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Switch
                label={t('common.enabled')}
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                disabled={!desk || !canWrite}
                size="medium"
              />
            )}
          />
        </div>
        {getValues().is_service_desk && (
          <>
            <div className={styles.container}>
              <TitleSubtitle
                title={t('environments.automatic_assignment')}
                subtitle={t('environments.automatic_assignment_subtitle')}
              />
              <Input
                key="max_sessions_per_agent"
                register={register('max_sessions_per_agent')}
                errorMessage={errors.max_sessions_per_agent?.message}
                placeholder="5"
                size="large"
                trimValue
                disabled={!canWrite}
                type="number"
              />
            </div>

            <TitleSubtitle
              title={t('environments.predefined_tags')}
              subtitle={t('environments.predefined_tags_subtitle')}
            />
            <Input
              name="tag"
              placeholder={t('dialog.tags.add_new')}
              error={!!errors.tag}
              size="small"
              readOnly={!canWrite}
              register={register('tag')}
              onBlur={handleTagBlur}
              tooltip={t('dialog.tags.add_new_tooltip')}
              onKeyUp={handleTagKeyUp}
              errorMessage={capitalizeFirstLetter(errors.tag?.message)}
            />
            <Chips
              chips={uniq(getValues().tags)}
              onClick={(tag) => {
                removeTag(tag);
              }}
            />
          </>
        )}
      </FormFieldset>
    </div>
  );
};

export default ServiceDeskFieldset;
