import { Icon } from '@/models/icon';

const Unknown = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      width={size}
      height={size}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 7c.4238-1.165 1.5672-2 2.912-2C11.618 5 13 6.343 13 8c0 1.4-.9866 2.575-2.3206 2.907-.4185.104-.7674.54-.7674 1.093m0 3h.0077"
      />
      <rect
        width="17.5"
        height="17.5"
        x="1.25"
        y="1.25"
        stroke={color}
        strokeWidth="1.5"
        rx="2.75"
      />
    </svg>
  );
};

export default Unknown;
