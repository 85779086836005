import { Icon } from '@/models/icon';

const Emoji = ({ size = 18, color = 'var(--icon-default-black)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 18 18"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#a_emoji)"
      >
        <path d="M9 15.667A6.667 6.667 0 1 0 9 2.334a6.667 6.667 0 0 0 0 13.333Z" />
        <path d="M6.333 10.334s1 1.333 2.667 1.333c1.666 0 2.666-1.333 2.666-1.333M7 7h.007M11 7h.007" />
      </g>
      <defs>
        <clipPath id="a_emoji">
          <path fill="#fff" d="M1 1h16v16H1z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Emoji;
