import { useCallback, useEffect, useState } from 'react';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { MODAL_AUTO_BRAIN } from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useDialogs from '@/hooks/useDialogs';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { EventName } from '@/models/segment';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { pushModal } from '@/redux/modals/actions';
import { selectDialogId, selectBrainId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';
import { autoDialogLanguages } from '@/util/constants';
import { resolveBrainsPath } from '@/util/util';

import SubNav from './SubNav';
import useSubNavDialogHelper from './useSubNavDialogHelper';
import { makeCollectionName, makeCollections } from './utils';

interface CollectionProps {
  collection: string;
  new_collection: string;
}

const SubNavDialog = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const dialogId = useSelector(selectDialogId);
  const dispatch = useDispatch();
  const { slug } = useAccount();
  const sessionDialogId = useSelector(selectDialogId);
  const { ai_agents } = useFeatureFlag();

  const { showTooltip, handleDismissTooltip } = useSubNavDialogHelper();
  const {
    dialogs,
    createDraftDialog,
    updateCollection,
    updateDialog,
    listStatus,
    createStatus,
    isDraft,
    dialogDraft,
  } = useDialogs(brainId, dialogId);
  const [lastCreatedCollection, setLastCreatedCollection] =
    useState<string>('');

  const getCollections = useCallback(() => {
    let formattedDialogs =
      dialogs
        ?.map(({ dialog_id, name, collection, nodes }) => {
          const firstNode = nodes?.[0];
          const link = resolveBrainsPath(
            `/${slug}/brains/${brainId}/dialogs/${dialog_id}`,
            ai_agents
          );

          return {
            name: dialog_id === dialogDraft.dialog_id ? dialogDraft.name : name,
            id: dialog_id,
            collection,
            link,
            type: firstNode?.type,
          };
        })
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }) || [];

    if (isDraft) {
      const previousCollection = dialogs?.find(
        (d) => d.dialog_id === sessionDialogId
      )?.collection;

      formattedDialogs = [
        {
          name: dialogDraft.name,
          id: 'draft',
          collection: previousCollection ?? '',
          link: resolveBrainsPath(
            `/${slug}/brains/${brainId}/dialogs/draft`,
            ai_agents
          ),
          type: null,
        },
        ...formattedDialogs,
      ];
    }
    const newCollections = makeCollections(formattedDialogs);
    return newCollections;
  }, [
    brainId,
    dialogDraft,
    dialogs,
    isDraft,
    sessionDialogId,
    slug,
    ai_agents,
  ]);

  const menuItems = [
    { label: t('dialog.create_empty_dialog'), value: 'manual' },
    { label: t('dialog.create_auto'), value: 'auto' },
  ];

  const isAutoBrainAvailable = autoDialogLanguages.includes(brain?.language);
  const createMenuItems = isAutoBrainAvailable ? menuItems : undefined;

  // create dialog
  const handleCreate = useCallback(() => {
    if (isAutoBrainAvailable) {
      trackEvent(EventName.ClickCreateManualDialog);
    }
    createDraftDialog();
  }, [createDraftDialog, isAutoBrainAvailable]);

  // update dialog
  const handleCollectionUpdate = useCallback(
    ({ id, collection }) => {
      updateDialog(
        { dialog_id: id, collection },
        {
          onSuccess: (resp) => {
            dispatch(
              addTemporalToast(
                'success',
                t('dialog.dialog_updated', { 0: resp.name })
              )
            );
            navigate(
              resolveBrainsPath(
                `/${slug}/brains/${brainId}/dialogs/${resp.dialog_id}`,
                ai_agents
              )
            );
          },
        }
      );
    },
    [brainId, dispatch, navigate, slug, t, updateDialog, ai_agents]
  );

  const onCreateCollection = useCallback(
    ({ id }) => {
      const collectionName = makeCollectionName(getCollections());
      updateDialog(
        {
          dialog_id: id,
          collection: collectionName,
        },
        {
          onSuccess: () => {
            setLastCreatedCollection(collectionName);
          },
        }
      );
    },
    [getCollections, updateDialog]
  );

  useEffect(() => {
    i18next.loadLanguages([brain?.language]);
  }, [brain?.language]);

  const onCollectionSubmit = useCallback(
    (data: CollectionProps) => {
      updateCollection(data);
    },
    [updateCollection]
  );

  const createMenuClick = (value: 'auto' | 'manual') => {
    if (value === 'auto') {
      dispatch(pushModal(MODAL_AUTO_BRAIN));
      trackEvent(EventName.ClickCreateManualDialog);
    } else {
      handleCreate();
    }
  };

  return (
    <SubNav
      title={t('common.dialog')}
      navTitle={t('common.dialogs')}
      skeleton={listStatus === 'pending'}
      collections={getCollections()}
      onCreateClick={handleCreate}
      onCollectionUpdate={handleCollectionUpdate}
      onCreateCollection={onCreateCollection}
      onCollectionSubmit={onCollectionSubmit}
      menuItems={createMenuItems}
      createMenuClick={createMenuClick}
      showTooltip={showTooltip}
      onTooltipDismiss={handleDismissTooltip}
      lastCreatedCollection={lastCreatedCollection}
      isCreating={createStatus === 'pending'}
      whoIsViewing={[]}
      subnavType="dialogs"
    />
  );
};

export default SubNavDialog;
