import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Input from '@/components/atoms/Input/Input';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  FacebookIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { CHANNELS_URL } from '@/util/constants';
import { errorMessage, integrationRulesFacebook } from '@/util/validator';

import styles from './ManualSettings.module.scss';

type Form = {
  page_id: string;
  access_token: string;
  verify_token: string;
  app_secret: string;
};

const ManualSettings = ({
  type,
  integration,
  registerAccordion,
  toggleAccordion,
}: AccordionFieldsetProps<Form, FacebookIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();

  const callbackUrl = `${CHANNELS_URL}/v1/${integration?.type}/${integration?.integration_id}`;

  const { updateIntegration, updateStatus } =
    useIntegrations<FacebookIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );
  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    trigger,
  } = formMethods;
  useEffect(() => {
    reset({
      page_id: integration?.config?.page_id ?? '',
      access_token: integration?.config?.access_token ?? '',
      verify_token: integration?.config?.verify_token ?? '',
      app_secret: integration?.config?.app_secret ?? '',
    });
  }, [
    integration?.config?.access_token,
    integration?.config?.app_secret,
    integration?.config?.page_id,
    integration?.config?.verify_token,
    reset,
  ]);

  const handleUpdateIntegration = useCallback(() => {
    const { page_id, access_token, verify_token, app_secret } = getValues();
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration?.config,
          page_id: page_id || '',
          access_token: access_token || '',
          verify_token: verify_token || '',
          app_secret: app_secret || '',
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, getValues, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [reset, expanded, isDirty, type]);

  return (
    <Accordion
      title={t('integrations.manual_settings.title')}
      subtitle={t('integrations.manual_settings.subtitle')}
      onSubmit={handleSubmit(handleUpdateIntegration)}
      disabled={!isDirty}
      isLoading={updateStatus === 'pending'}
      expanded={expanded === type}
      handleChange={toggleAccordion(type)}
      ref={registerAccordion(
        type,
        handleSubmit(handleUpdateIntegration),
        trigger
      )}
      readOnly={!canWrite}
    >
      <>
        <TitleSubtitle
          title={t('integrations.manual_settings.callback_url.title')}
          subtitle={t('integrations.manual_settings.callback_url.subtitle')}
        />

        <div className={styles.callback_url}>
          <Input size="large" defaultValue={callbackUrl} readOnly />
          <CopyButton className={styles.copy_url} data={callbackUrl} />
        </div>

        <TitleSubtitle
          title={t('integrations.manual_settings.page_id.title')}
          subtitle={t('integrations.manual_settings.page_id.subtitle')}
        />

        <div className={styles.text_area}>
          <Input
            id="page_id"
            name="page_id"
            placeholder=""
            size="large"
            register={register('page_id', {
              required: t('validation.required'),
              maxLength: {
                value: 64,
                message: '',
              },
            })}
            readOnly={!canWrite}
            trimValue
            errorMessage={errorMessage({
              field: errors.page_id,
              maxLength: integrationRulesFacebook.pageId.maxLength,
            })}
          />
        </div>

        <TitleSubtitle
          title={t('integrations.manual_settings.access_token.title')}
          subtitle={t('integrations.manual_settings.access_token.subtitle')}
        />

        <div className={styles.text_area}>
          <Input
            id="access_token"
            name="access_token"
            placeholder=""
            size="large"
            readOnly={!canWrite}
            register={register('access_token', {
              required: t('validation.required'),
              maxLength: {
                value: 64,
                message: '',
              },
            })}
            trimValue
            errorMessage={errorMessage({
              field: errors.access_token,
              maxLength: integrationRulesFacebook.accessToken.maxLength,
            })}
          />
        </div>

        <TitleSubtitle
          title={t('integrations.manual_settings.verify_token.title')}
          subtitle={t('integrations.manual_settings.verify_token.subtitle')}
        />

        <div className={styles.text_area}>
          <Input
            id="verify_token"
            name="verify_token"
            placeholder=""
            size="large"
            readOnly={!canWrite}
            register={register('verify_token', {
              required: t('validation.required'),
              maxLength: {
                value: 64,
                message: '',
              },
            })}
            trimValue
            errorMessage={errorMessage({
              field: errors.verify_token,
              maxLength: integrationRulesFacebook.verifyToken.maxLength,
            })}
          />
        </div>

        <TitleSubtitle
          title={t('integrations.manual_settings.app_secret.title')}
          subtitle={t('integrations.manual_settings.app_secret.subtitle')}
        />

        <div className={styles.text_area}>
          <Input
            id="app_secret"
            name="app_secret"
            placeholder=""
            size="large"
            readOnly={!canWrite}
            register={register('app_secret', {
              required: t('validation.required'),
              maxLength: {
                value: 64,
                message: '',
              },
            })}
            trimValue
            errorMessage={errorMessage({
              field: errors.app_secret,
              maxLength: integrationRulesFacebook.appSecret.maxLength,
            })}
          />
        </div>
      </>
    </Accordion>
  );
};

export default ManualSettings;
