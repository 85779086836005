import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';

import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon';
import { ToneButton } from './ToneButton';
import { ToneIcon } from '../../icons/ToneIcon';
import { ToneOfVoice } from '../../models';

import styles from './Setup.module.scss';

type Form = {
  tone_level: number;
};

const TONE_OF_VOICES: ToneOfVoice[] = [
  'creative',
  'neutral',
  'matter_of_fact',
  'professional',
];

const DEBT_COLLECTION_TONE_OF_VOICES: ToneOfVoice[] = [
  'empathetic',
  'neutral',
  'firm',
  'urgent',
];

export const ToneOfVoiceForm = ({
  number: number,
  isDebtCollection = false,
}) => {
  const brainId = useSelector(selectBrainId);
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);

  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    control,
  } = useForm<Form>({
    mode: 'onSubmit',
    values: {
      tone_level: brain?.guidelines?.tone_level ?? 0,
    },
  });

  const onSubmit = ({ tone_level }: Form) => {
    updateBrain({
      guidelines: {
        ...brain.guidelines,
        tone_level,
      },
      brain_id: brainId,
    });
  };

  return (
    <FormCard onSubmit={handleSubmit(onSubmit)} id="ai-agent-tone-of-voice">
      <FormCard.Header
        title={t('ai_agents.tone_level.title')}
        subtitle={t('ai_agents.tone_level.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={number}
          />
        }
      />
      <FormCard.Content>
        <Controller
          name="tone_level"
          control={control}
          render={({ field }) => {
            return (
              <Box display="flex" gap="var(--space-8)">
                {(isDebtCollection
                  ? DEBT_COLLECTION_TONE_OF_VOICES
                  : TONE_OF_VOICES
                ).map((tone, index) => {
                  const helperTextKey = `ai_agents.tone_level.${tone}${isDebtCollection && tone === 'neutral' ? '_dc' : ''}_helper_text`;

                  return (
                    <ToneButton
                      key={tone}
                      className={cn({
                        [styles.selected]: field.value === index - 1,
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        field.onChange(index - 1);
                      }}
                    >
                      <ToneIcon type={tone} />
                      <Typography variant="body-semi-bold">
                        {t(`ai_agents.tone_level.${tone}`)}
                      </Typography>
                      <Typography
                        color="var(--text-default-gray-light)"
                        variant="label-regular"
                      >
                        {t(helperTextKey)}
                      </Typography>
                    </ToneButton>
                  );
                })}
              </Box>
            );
          }}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
          disabled={!isDirty}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
