import { Icon } from '@/models/icon';

const Handover = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.88"
        d="M25 26.25v-2.5a5 5 0 0 0-5-5H10a5 5 0 0 0-5 5v2.5M15 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      />
    </svg>
  );
};
export default Handover;
