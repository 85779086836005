import { Icon } from '@/models/icon';

const Dot = ({ size = 16, color = 'var(--border-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8.435" r="7" fill={color} />
    </svg>
  );
};

export default Dot;
