import { Icon } from '@/models/icon';

const Eye = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
    >
      <path
        d="M.667 8.734S3.334 3.401 8 3.401c4.667 0 7.334 5.333 7.334 5.333S12.667 14.068 8 14.068c-4.666 0-7.333-5.334-7.333-5.334Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.734a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Eye;
