import Typography from '@mui/material/Typography/Typography';

import PlusCircle from '../Icons/PlusCircle';

const OptionContent = ({
  option,
  iconTypeKey,
  ariaSelected,
  t,
  renderIcons,
  unlistedEntry,
}) => {
  return (
    <>
      <span className="frontIcon">
        {renderIcons('front', ariaSelected, option[iconTypeKey])}
      </span>
      <span className="name">{option.label}</span>
      <span className="backIcon">
        {renderIcons('back', ariaSelected, option[iconTypeKey])}
      </span>
      {option.type === unlistedEntry && (
        <>
          <span className="newLabel">
            <Typography variant="body-regular">
              ({t('dialog.tags.new')})
            </Typography>
          </span>
          <span className="addLabel">
            <PlusCircle />
            <Typography variant="body-semi-bold">{t('common.add')}</Typography>
          </span>
        </>
      )}
    </>
  );
};

export default OptionContent;
