import { useCallback, useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { capitalizeFirstLetter, parseFilter } from '@/util/util';

import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

export interface Row {
  counts: number;
  channel: number;
  totalCounts: number;
}

interface Rows {
  rows: Row[];
}

export const SESSIONS_PER_CHANNEL = gql`
  query SessionsPerChannel(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $isContained: Boolean
    $isCovered: Boolean
    $isTest: Boolean
    $minNumUserMessages: Int
  ) {
    rows: sessions_per_channel(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_contained: $isContained
        is_covered: $isCovered
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      counts
      channel
    }
  }
`;

export const formatSessions = (rows) => {
  if (!rows || rows.length === 0) {
    return undefined;
  }

  const totalCounts = rows.reduce((acc, row) => {
    const { counts } = row;
    return acc + counts;
  }, 0);

  const pie = rows.map((row) => {
    const { channel, counts } = row;
    const capitalizedChannel = capitalizeFirstLetter(channel);
    return {
      id: capitalizedChannel,
      label: capitalizedChannel,
      value: Math.round((counts / totalCounts) * 100),
      count: counts,
    };
  });
  return { pie, totalCounts };
};

const useSessionsPerChannel = () => {
  const accountId = useSelector(selectAccountId);
  const filters = useSelector(selectFilters);

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters.endDate),
          accountId,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  const {
    loading,
    data: rawData,
    error,
    refetch,
  } = useQuery<Rows, QueryVariables>(SESSIONS_PER_CHANNEL, {
    variables,
    skip: !accountId || !filters.filtersLoaded,
  });

  const rows = rawData?.rows;

  const data = useMemo(() => formatSessions(rows), [rows]);

  const onRefetch = useCallback(() => {
    refetch(variables);
  }, [refetch, variables]);

  if (loading || error) {
    return {
      isLoading: loading,
      error,
      data: undefined,
      onRefetch,
    };
  }

  return {
    isLoading: loading,
    data: data?.pie,
    totalCounts: data?.totalCounts,
  };
};

export default useSessionsPerChannel;
