import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';

import Clock2 from '@/components/atoms/Icons/Clock2';

import { TimeLineBox } from './TimelineBox';

import styles from './Versions.module.scss';

export const VersionTimeline = ({ version, isLast, index, versions }) => {
  return (
    <Timeline className={styles.timeLine}>
      <TimelineItem
        position="right"
        classes={{
          missingOppositeContent: styles.missingOppositeContent,
        }}
      >
        <TimelineSeparator>
          <TimelineDot variant="outlined" className={styles.timeLineDot}>
            <Clock2 color="var(--icon-default-gray)" />
          </TimelineDot>
          {!isLast && (
            <TimelineConnector className={styles.timeLineConnector} />
          )}
        </TimelineSeparator>
        <TimelineContent className={styles.timeLineContent}>
          <TimeLineBox version={version} index={index} versions={versions} />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
