import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';

export const selectAccordion = createSelector(
  (state: RootState) => state.accordion,
  (accordion) => {
    return {
      expanded: accordion.expanded,
      dirty: accordion.dirty,
    };
  }
);
