import Loading from '@/components/atoms/Loading/Loading';
import Modal from '@/components/organisms/Modals/Modal';

import styles from './LoadingSubModal.module.scss';

const AddNumberSubModal = () => {
  return (
    <Modal size="medium" autoFocus passiveModal>
      <div className={styles.container}>
        <Loading />
      </div>
    </Modal>
  );
};

export default AddNumberSubModal;
