import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const KnowledgeBase = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, color = 'var(--icon-default-gray)', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill="none"
        className="icon_fill"
      >
        <path
          fill={color}
          fillRule="evenodd"
          d="m4.145 19.552.052-9.315H3.146V22.09c1.444-.372 2.895-.61 4.355-.62a12.216 12.216 0 0 1 4.048.638 8.644 8.644 0 0 0-2.775-1.506c-1.258-.41-2.619-.546-4.013-.426a.574.574 0 0 1-.616-.624ZM12.42 1.64a5.417 5.417 0 0 1 5.387 4.864c.759-.001 1.897.038 2.422.115a.57.57 0 0 1 .487.566v1.908h1.552c.315 0 .57.256.57.571v13.17a.572.572 0 0 1-.756.543c-1.595-.456-3.178-.759-4.748-.77-1.529-.01-3.058.26-4.594.937a.59.59 0 0 1-.325.096.574.574 0 0 1-.326-.096 10.98 10.98 0 0 0-4.594-.937c-1.568.011-3.15.314-4.748.77a.569.569 0 0 1-.748-.54V9.664c0-.315.256-.571.571-.571h1.63l.01-1.91a.572.572 0 0 1 .455-.557c.494-.104 1.636-.165 2.373-.177a5.417 5.417 0 0 1 5.382-4.809Zm5.383 6.01a5.42 5.42 0 0 1-4.803 4.79v8.576c.97-.648 1.962-1.18 2.98-1.543 1.162-.414 2.356-.61 3.594-.512V9.75a.47.47 0 0 1 0-.172V7.69c-.37-.035-1.128-.05-1.771-.04Zm-5.947 4.793a5.418 5.418 0 0 1-4.825-4.857c-.623.001-1.335.028-1.683.077l-.064 11.332c1.325-.047 2.62.12 3.84.519a9.82 9.82 0 0 1 2.732 1.39v-8.461Zm1.699 9.577a12.277 12.277 0 0 1 3.786-.553c1.46.009 2.911.249 4.356.621V10.237h-.98v9.366a.57.57 0 0 1-.703.557c-1.251-.197-2.465-.034-3.649.389-.952.34-1.888.848-2.81 1.471Zm.45-11.722h-3.17v-.534h.597V6.506h-.597v-.424h1.481c.374 0 .743-.045 1.106-.136v3.818h.582v.534Zm-1.372-6.8c.245 0 .438.072.58.212.14.14.21.333.21.58 0 .25-.12.474-.362.67a1.307 1.307 0 0 1-.843.293c-.242 0-.432-.068-.579-.202a.693.693 0 0 1-.22-.534c0-.277.12-.515.358-.718.236-.2.523-.3.856-.3Z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
);

KnowledgeBase.displayName = 'KnowledgeBase';

export default KnowledgeBase;
