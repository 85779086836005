import { memo, useCallback } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import TagIcon from '@/components/atoms/Icons/ToolTag';
import Input from '@/components/atoms/Input/Input';
import { useBilling } from '@/modules/billing/hooks/useBilling';

import styles from './Coupon.module.scss';

type Form = { coupon: string };

export const Coupon = memo(() => {
  const { t } = useTranslation();
  const { redeemPromoCode, customer, reedeemPromoCodeStatus } = useBilling();
  const { register, handleSubmit, formState, reset, setError } = useForm<Form>({
    mode: 'onChange',
    defaultValues: { coupon: '' },
  });

  const isLoading = customer === undefined;

  const onSubmit = useCallback(
    ({ coupon }: Form) => {
      redeemPromoCode(coupon, {
        onSuccess: () => {
          reset({ coupon: '' });
        },
        onError: () => {
          setError('coupon', { message: t('billing.coupon_not_valid') });
        },
      });
    },
    [redeemPromoCode, reset, setError, t]
  );

  return (
    <FormFieldset
      title={t('billing.coupon')}
      primaryButton={{
        children: t('common.apply'),
        disabled: !formState.isDirty,
        isLoading: reedeemPromoCodeStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Box mt={2}>
        <Input
          name="coupon"
          label={t('billing.code')}
          register={register('coupon')}
          errorMessage={formState.errors.coupon?.message}
          placeholder={t('billing.coupon_placeholder')}
        />
        {customer?.discount?.coupon && (
          <Typography component="p" className={styles.couponContainer}>
            <TagIcon color="var(--text-default-gray)" size={16} />{' '}
            <Typography variant="body-semi-bold">
              {customer?.discount?.coupon.name}
            </Typography>
            {t(`billing.plan_table.${customer?.discount?.coupon.object}`)}
            &nbsp;{t('billing.applied')}
          </Typography>
        )}
      </Box>
    </FormFieldset>
  );
});

Coupon.displayName = 'Coupon';
