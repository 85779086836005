import { useCallback, useMemo } from 'react';

import isNil from 'lodash/isNil';

import useUser from '@/hooks/useUser';

const useMessageSound = (sound: string) => {
  const audio = useMemo(() => new Audio(sound), [sound]);
  audio.muted = false;
  const { user } = useUser();

  const play = useCallback(() => {
    if (
      user && !isNil(user?.metadata?.chatSound)
        ? !!user?.metadata?.chatSound
        : true
    ) {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.error(error);
        });
      }
    }
  }, [audio, user]);

  return { play };
};

export default useMessageSound;
