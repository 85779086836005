import { useCallback } from 'react';

import { ResizableBox } from 'react-resizable';

import useLocalStorage from '@/hooks/useLocalStorage';

import './Resizable.scss';

interface ResizableProps {
  children: React.ReactNode;
  name: string;
  max?: number;
  min?: number;
  height: number;
  className?: string;
  defaultWidth?: number;
  onResize?: (width: number) => void;
  resizeHandles?: 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';
}
const Resizable = ({
  name,
  children,
  max = Infinity,
  min = Infinity,
  height,
  className = '',
  defaultWidth = 192,
  onResize,
  resizeHandles = 'e',
}: ResizableProps) => {
  const [itemWidth, setItemWidth] = useLocalStorage(name, defaultWidth);

  const handleResize = useCallback(
    (_: unknown, { size }) => {
      setItemWidth(size.width);
      if (onResize) {
        onResize(size.width);
      }
    },
    [onResize, setItemWidth]
  );

  return (
    <ResizableBox
      maxConstraints={[max, height]}
      minConstraints={[min, height]}
      width={itemWidth}
      height={height}
      onResize={handleResize}
      resizeHandles={[resizeHandles]}
    >
      <div
        className={className}
        style={{
          width: `${itemWidth}px`,
          height: `${height}px`,
        }}
      >
        {children}
      </div>
    </ResizableBox>
  );
};

export default Resizable;
