import { Icon } from '@/models/icon';

const GoogleChrome = ({ size = 16 }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#agooglechrome)">
        <path
          fill="#fff"
          d="M11.99 16.989a4.973 4.973 0 1 0 0-9.946 4.973 4.973 0 0 0 0 9.946Z"
        />
        <path
          fill="url(#bgooglechrome)"
          d="M7.683 14.503 3.377 7.046A9.942 9.942 0 0 0 11.99 21.96l4.306-7.458a4.97 4.97 0 0 1-6.793 1.821 4.968 4.968 0 0 1-1.82-1.82Z"
        />
        <path
          fill="url(#cgooglechrome)"
          d="M16.295 14.503 11.99 21.96a9.938 9.938 0 0 0 8.612-4.972 9.943 9.943 0 0 0-.002-9.944h-8.612a4.97 4.97 0 0 1 4.974 4.973c0 .872-.23 1.73-.667 2.486Z"
        />
        <path
          fill="#1A73E8"
          d="M11.99 15.954a3.937 3.937 0 1 0 0-7.874 3.937 3.937 0 0 0 0 7.873Z"
        />
        <path
          fill="url(#dgooglechrome)"
          d="M11.99 7.044h8.61a9.94 9.94 0 0 0-13.584-3.64 9.942 9.942 0 0 0-3.639 3.641l4.306 7.458h.001a4.97 4.97 0 0 1 4.305-7.459Z"
        />
      </g>
      <defs>
        <linearGradient
          id="bgooglechrome"
          x1="13.066"
          x2="4.454"
          y1="21.34"
          y2="6.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E8E3E" />
          <stop offset="1" stopColor="#34A853" />
        </linearGradient>
        <linearGradient
          id="cgooglechrome"
          x1="10.539"
          x2="19.15"
          y1="21.987"
          y2="7.071"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC934" />
          <stop offset="1" stopColor="#FBBC04" />
        </linearGradient>
        <linearGradient
          id="dgooglechrome"
          x1="3.377"
          x2="20.601"
          y1="8.288"
          y2="8.288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D93025" />
          <stop offset="1" stopColor="#EA4335" />
        </linearGradient>
        <clipPath id="agooglechrome">
          <path fill="#fff" d="M2 2h20v20H2z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleChrome;
