import { useCallback, useEffect, useMemo } from 'react';

import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import Input from '@/components/atoms/Input/Input';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  WhatsAppIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { errorMessage, whatsappIntegrationConfig } from '@/util/validator';

import styles from './WhatsappPreferenceSettings.module.scss';

type Form = {
  prefilled_message: string;
  include_agent_name: boolean;
};

const WhatsappPreferenceSettings = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, WhatsAppIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();

  const { updateIntegration, updateStatus } =
    useIntegrations<WhatsAppIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );
  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    control,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    trigger,
  } = formMethods;

  const { field } = useController({
    name: 'include_agent_name',
    control,
  });

  useEffect(() => {
    reset({
      include_agent_name: integration?.config?.include_agent_name ?? false,
      prefilled_message: integration?.config?.prefilled_message || '',
    });
  }, [
    integration?.config?.include_agent_name,
    integration?.config?.prefilled_message,
    reset,
  ]);

  const handleUpdateIntegration = useCallback(
    (values) => {
      updateIntegration(
        {
          ...integration,
          config: {
            ...integration.config,
            ...values,
            prefilled_message: values.prefilled_message || undefined,
          },
        },
        {
          onSuccess: () => {
            if (expanded === type) {
              dispatch(setExpanded(false));
            }
          },
        }
      );
    },
    [dispatch, expanded, integration, type, updateIntegration]
  );

  usePrompt(
    isDirty,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  const onRadioChange = useCallback(
    (event) => {
      if (event.target.value === 'true') {
        field.onChange(true);
      } else {
        field.onChange(false);
      }
    },
    [field]
  );

  const stickyOptions = useMemo(
    () => [
      {
        id: 'incl_agent-enable',
        value: 'true',
        label: (
          <TitleSubtitle
            title={t(
              'integrations.whatsapp.pref_settings.incl_agent.enable_title'
            )}
            subtitle={t(
              'integrations.whatsapp.pref_settings.incl_agent.enable_sub'
            )}
            noGutters
          />
        ),
      },
      {
        id: 'incl_agent-disable',
        value: 'false',
        label: (
          <TitleSubtitle
            title={t(
              'integrations.whatsapp.pref_settings.incl_agent.disable_title'
            )}
            subtitle={t(
              'integrations.whatsapp.pref_settings.incl_agent.disable_sub'
            )}
            noGutters
          />
        ),
      },
    ],
    [t]
  );

  return (
    <>
      <Accordion
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
        title={t('integrations.whatsapp.pref_settings.title')}
        subtitle={t('integrations.whatsapp.pref_settings.subtitle')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
      >
        <TitleSubtitle
          title={t('integrations.whatsapp.pref_settings.pre_msg.title')}
          subtitle={t('integrations.whatsapp.pref_settings.pre_msg.subtitle')}
        />
        <div className={styles.textArea}>
          <Input
            id="prefilled_message"
            name="prefilled_message"
            readOnly={!canWrite}
            placeholder={t(
              'integrations.whatsapp.pref_settings.pre_msg.pholder'
            )}
            size="large"
            register={register(
              'prefilled_message',
              whatsappIntegrationConfig.prefilled_message
            )}
            trimValue
            errorMessage={errorMessage({
              field: errors.prefilled_message,
              maxLength: whatsappIntegrationConfig.prefilled_message.maxLength,
            })}
          />
        </div>
        <TitleSubtitle
          title={t('integrations.whatsapp.pref_settings.incl_agent.title')}
          subtitle={t(
            'integrations.whatsapp.pref_settings.incl_agent.subtitle'
          )}
        />
        <div className={styles.radioGroup}>
          <OptionRadioButton
            key={`${stickyOptions[0].id}-${field.value}`}
            option={stickyOptions[0]}
            size="medium"
            onChange={onRadioChange}
            isSelected={field.value === true}
            disableSelectedStyles
            disabled={!canWrite}
          />
          <OptionRadioButton
            key={`${stickyOptions[1].id}-${field.value}`}
            option={stickyOptions[1]}
            size="medium"
            onChange={onRadioChange}
            isSelected={field.value === false}
            disableSelectedStyles
            disabled={!canWrite}
          />
        </div>
      </Accordion>
    </>
  );
};

export default WhatsappPreferenceSettings;
