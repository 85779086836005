import { Icon } from '@/models/icon';

const Question = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M9.337 7.483a.75.75 0 1 0-1.17.938l2.87 3.58-2.87 3.58a.75.75 0 1 0 1.17.938L12 13.199l2.661 3.32a.75.75 0 1 0 1.17-.938L12.96 12l2.87-3.58a.75.75 0 0 0-1.17-.938L12 10.802l-2.662-3.32Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M5.6 3.2s-1.389-.026-2 .55c-1.038.978-.611 2.505 0 3.85.5 1.1.25 2.288-.5 3.3-.199.268-.519.516-.813.708-.307.202-.363.666-.1.923.321.316.697.729.913 1.119.718 1.297 1 2.2.5 3.85-.247.815-.388 1.454 0 2.2.433.834 2 1.1 2 1.1M18.4 3.2s1.389-.026 2 .55c1.038.978.611 2.505 0 3.85-.5 1.1-.25 2.288.5 3.3.199.268.519.516.813.708.307.202.363.666.1.923-.321.316-.697.729-.913 1.119-.718 1.297-1 2.2-.5 3.85.247.815.388 1.454 0 2.2-.433.834-2 1.1-2 1.1"
      />
    </svg>
  );
};
export default Question;
