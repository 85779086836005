import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';

import { MODAL_SMALL_SCREEN } from '@/components/organisms/Modals/ModalConductor';
import { pushModal } from '@/redux/modals/actions';
import { selectIframeView } from '@/redux/user/selectors';

import useUser from './useUser';

export const useMobileWarning = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const isIframeView = useSelector(selectIframeView);
  const { user } = useUser();

  useEffect(() => {
    if (user && width < 1023 && !isIframeView) {
      dispatch(pushModal(MODAL_SMALL_SCREEN));
    }
    // we want to show this modal only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isIframeView, user]);
};
