const Separator = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="2"
      fill="none"
      viewBox="0 0 27 2"
    >
      <path stroke="#DEDEDE" d="M0 1h26.707" />
    </svg>
  );
};

export default Separator;
