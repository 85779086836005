import { Icon } from '@/models/icon';

const Survey = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => (
  <svg
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    strokeLinecap="round"
  >
    <path
      d="M20 5h2.5A2.5 2.5 0 0 1 25 7.5V25a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 5 25V7.5A2.5 2.5 0 0 1 7.5 5H10"
      strokeWidth={1.88}
      strokeLinejoin="round"
    />
    <path
      d="M18.75 2.5h-7.5c-.69 0-1.25.56-1.25 1.25v2.5c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25v-2.5c0-.69-.56-1.25-1.25-1.25Z"
      strokeWidth={1.88}
      strokeLinejoin="round"
    />
    <path
      strokeWidth={1.5}
      d="M9.75 12.25h10.5M9.75 17.25h10.5M9.75 22.25h10.5"
    />
  </svg>
);

export default Survey;
