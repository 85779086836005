import { AlertColor } from '@mui/lab';
import classNames from 'classnames';

import styles from './ProgressBar.module.scss';

type ManualProps = {
  percentage: number;
  duration?: never;
};

type AutoProps = {
  percentage?: never;
  duration: number;
};

type Props = (ManualProps | AutoProps) & {
  autoFill?: boolean;
  variant?: AlertColor;
};
const ProgressBar = ({
  percentage,
  variant = 'info',
  autoFill = false,
  duration,
}: Props) => {
  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.autoFill]: autoFill,
        [styles[variant]]: true,
      })}
    >
      <div
        className={styles.content}
        style={
          autoFill
            ? {
                animationDuration: `${duration}ms`,
              }
            : {
                width: `${percentage}%`,
              }
        }
      ></div>
    </div>
  );
};

export default ProgressBar;
