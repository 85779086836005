import { Icon } from '@/models/icon';

const Lock2 = ({ size = 17, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.375 7.833H4.04c-.736 0-1.333.597-1.333 1.334v4.666c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V9.167c0-.737-.597-1.334-1.333-1.334ZM5.375 7.833V5.167a3.333 3.333 0 1 1 6.666 0v2.666"
      />
    </svg>
  );
};
export default Lock2;
