import { Icon } from '@/models/icon';

const NumberIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M8.002 12c-.565 0-1.067-.095-1.506-.285-.436-.19-.778-.45-1.026-.78a1.83 1.83 0 0 1-.365-1.129 1.869 1.869 0 0 1 .212-.901c.145-.274.34-.502.586-.684.248-.186.525-.303.829-.354v-.053a1.647 1.647 0 0 1-.973-.612 1.767 1.767 0 0 1-.365-1.115c-.003-.4.109-.757.334-1.072.226-.314.535-.561.928-.741A3.16 3.16 0 0 1 8.002 4c.5 0 .944.091 1.334.274.393.18.703.427.928.741.228.315.343.672.343 1.072 0 .421-.126.793-.377 1.115-.248.319-.569.523-.962.612v.053c.304.05.578.168.821.354.246.182.441.41.586.684.147.274.22.574.22.901 0 .42-.124.797-.372 1.13-.249.329-.59.589-1.027.779-.433.19-.931.285-1.494.285Zm0-1.088c.291 0 .545-.049.76-.148.216-.101.383-.243.502-.426.12-.182.18-.393.183-.63a1.18 1.18 0 0 0-.194-.659 1.274 1.274 0 0 0-.513-.452 1.612 1.612 0 0 0-.738-.164c-.279 0-.527.055-.745.164-.218.109-.39.26-.517.452-.125.19-.186.41-.183.658-.003.238.056.449.175.631.12.18.286.321.502.423.218.1.474.152.768.152Zm0-3.547c.238 0 .449-.048.631-.144a1.1 1.1 0 0 0 .6-1 1.111 1.111 0 0 0-.159-.586 1.03 1.03 0 0 0-.433-.392 1.394 1.394 0 0 0-.64-.14c-.245 0-.462.047-.65.14a1.03 1.03 0 0 0-.433.392c-.101.167-.15.362-.148.586-.003.225.048.424.152.596.107.17.252.305.437.404.188.096.402.144.643.144Z"
      />
      <rect width="15" height="15" x=".5" y=".5" stroke={color} rx="7.5" />
    </svg>
  );
};
export default NumberIcon;
