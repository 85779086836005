import { Icon } from '@/models/icon';

const Export = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
      stroke={color}
      className="icon_stroke"
    >
      <path
        strokeLinecap="round"
        d="M11 5h2a1 1 0 0 1 1 1v6.218a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h2"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 3.473 8 1m0 0L6 3.473M8 1v7.42"
      />
    </svg>
  );
};

export default Export;
