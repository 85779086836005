import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { callGet } from '@/api/fetcher';
import { AuditLogs } from '@/models/auditLogs';
import { selectFilters } from '@/redux/auditLogs/selectors';
import { getAnalyticsRange } from '@/util/analytics';

type AuditLogsParams = {
  account_slug: string;
  next_cursor?: { cursor: string };
  brain_id?: string;
  desk_id?: string;
  brain_ids?: string[];
  desk_ids?: string[];
  log_type?: 'authoring' | 'other';
  start_date?: string;
  end_date?: string;
};

type SearchParams =
  | URLSearchParams
  | string[][]
  | Record<string, string | number | boolean>;

const DEFAULT_LIMIT = 30;
const ONE_MINUTE = 60 * 1000;
const getParams = (params: SearchParams) =>
  Object.entries(params).filter(
    ([key, value]) =>
      value !== undefined && key !== 'brain_ids' && key !== 'desk_ids'
  );

const appendIds = (
  queryParams: URLSearchParams,
  ids: string[],
  key: string
) => {
  if (ids?.length > 1) {
    ids.forEach((id) => {
      queryParams.append(key, id);
    });
  }

  return queryParams;
};

// Append an array of brain_ids and desk_ids to query params
const appendDeskAndBrainIds = (queryParams: URLSearchParams, params) => {
  appendIds(queryParams, params.brain_ids, 'brain_ids');
  appendIds(queryParams, params.desk_ids, 'desk_ids');
};

export const endpoints = Object.freeze({
  auditLogs: (params) => {
    if (!params.next_cursor) {
      delete params.next_cursor;
    }
    const queryParams = new URLSearchParams(getParams(params));
    appendDeskAndBrainIds(queryParams, params);
    return `/www/api/v1/audit_logs?${queryParams}&limit=${DEFAULT_LIMIT}`;
  },
});

const API = {
  listAuditLogs: async (
    slug: AuditLogsParams['account_slug'],
    next_cursor?: AuditLogsParams['next_cursor'],
    brain_id?: AuditLogsParams['brain_id'],
    desk_id?: AuditLogsParams['desk_id'],
    brain_ids?: AuditLogsParams['brain_ids'],
    desk_ids?: AuditLogsParams['desk_ids'],
    log_type?: AuditLogsParams['log_type'],
    start_date?: AuditLogsParams['start_date'],
    end_date?: AuditLogsParams['end_date']
  ): Promise<AuditLogs> => {
    return callGet(
      endpoints.auditLogs({
        account_slug: slug,
        next_cursor: next_cursor.cursor,
        brain_id,
        desk_id,
        brain_ids,
        desk_ids,
        log_type,
        start_date,
        end_date,
      })
    );
  },
};

const getCategoryFilterIds = (filters, category): string[] => {
  const selectedCategory = filters.filter(
    (filter) => filter.type === category
  )[0]?.[category];

  const selectedCategoryIds = selectedCategory?.reduce((acc, current) => {
    return [...acc, current.value];
  }, []);

  return selectedCategoryIds;
};

// Get the selected brain and desk ids from the filters
const getDeskAndBrainIds = (filters) => {
  let brainIds = getCategoryFilterIds(filters, 'brainIds');
  const brainId = brainIds?.length === 1 ? brainIds[0] : undefined;
  brainIds = brainIds?.length > 1 ? brainIds : undefined;

  let deskIds = getCategoryFilterIds(filters, 'deskIds');
  const deskId = deskIds?.length === 1 ? deskIds[0] : undefined;
  deskIds = deskIds?.length > 1 ? deskIds : undefined;

  return {
    brainId,
    brainIds,
    deskId,
    deskIds,
  };
};

export const useActivityLogs = (
  slug: string,
  log_type?: 'authoring' | 'other',
  propDeskId?: string,
  propBrainIds?: string[],
  propBrainId?: string
) => {
  const { filters, startDate, endDate } = useSelector(selectFilters);
  const { brainId, brainIds, deskId, deskIds } = getDeskAndBrainIds(filters);
  // Set the end date to the end of the day in order to include all logs for that day

  const deskFilter = propDeskId === null ? undefined : (propDeskId ?? deskId);
  const brainFilter =
    propBrainId === null ? undefined : (propBrainId ?? brainId);

  const { startDate: formattedStartDate, endDate: formattedEndDate } =
    getAnalyticsRange(startDate, endDate);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: [
        endpoints.auditLogs({
          account_slug: slug,
          log_type,
          brain_id: brainFilter,
          desk_id: deskFilter,
          brain_ids: propBrainIds ?? brainIds,
          desk_ids: deskIds,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        }),
      ],
      queryFn: ({ pageParam }) => {
        return API.listAuditLogs(
          slug,
          pageParam,
          brainFilter,
          deskFilter,
          propBrainIds ?? brainIds,
          deskIds,
          log_type,
          startDate,
          formattedEndDate
        );
      },

      getNextPageParam: ({ pagination }) => {
        if (pagination.has_more) {
          return { cursor: pagination.next_cursor };
        }
        return undefined;
      },
      initialPageParam: {} as { cursor: string | undefined },
      staleTime: ONE_MINUTE, // until RTM is ready to send us updates
      retry: 5,
    });

  const isDataEmpty = data?.pages[0]?.audit_logs?.length === 0;

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isDataEmpty,
  };
};
