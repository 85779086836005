import { AndroidIcon } from '../AndroidIcon';
import CellPhone from '../CellPhone';
import MacOs from '../MacOs';
import MicrosoftWindow from '../MicrosoftWindow';

export const PlatformIcon = ({ platform }: { platform: string }) => {
  const lowerCasePlatform = platform?.toLowerCase();

  if (
    lowerCasePlatform?.includes('mac') ||
    lowerCasePlatform?.includes('ios')
  ) {
    return <MacOs />;
  }
  if (
    lowerCasePlatform?.includes('microsoft') ||
    lowerCasePlatform?.includes('windows')
  ) {
    return <MicrosoftWindow />;
  }
  if (lowerCasePlatform?.includes('phone')) {
    return <CellPhone />;
  }
  if (lowerCasePlatform?.includes('android')) {
    return <AndroidIcon />;
  }

  return null;
};
