import { Routes, Route } from 'react-router';

import Layout from '@/components/templates/Layout/Layout';

import AIAgentRouter from './AIAgentRouter';
import AIAgents from './AIAgents';

const AIAgentsRouter: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<AIAgents />} />
        <Route path="/:brainId/*" element={<AIAgentRouter />} />
      </Routes>
    </Layout>
  );
};

export default AIAgentsRouter;
