import { Icon } from '@/models/icon';

const TickBase = ({
  color = 'var(--icon-default-white)',
  backgroundColor = '#c2c2c2',
  size = 24,
}: Icon) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      width={size}
      height={size}
    >
      <rect x=".75" y=".5" width="15" height="15" rx="7.5" fill={color} />
      <rect
        x=".75"
        y=".5"
        width="15"
        height="15"
        rx="7.5"
        stroke={backgroundColor}
      />
    </svg>
  );
};
export default TickBase;
