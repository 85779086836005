import { Icon } from '@/models/icon';

export const AudioIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 1.833H2.834A1.167 1.167 0 0 0 1.667 3v9.333A1.167 1.167 0 0 0 2.834 13.5h4.083M7.5 1.833l3.5 3.5m-3.5-3.5v3.5H11m0 2.334V5.333M8.667 8.25H4m2.625 2.333H4m1.167-4.666H4M13.258 10.246c.439.42 1.052 1.579 0 2.842m.987-3.79c.821.684 1.972 2.59 0 4.737M8 10.955v1.423a.5.5 0 0 0 .383.486l.857.206a.5.5 0 0 1 .14.057l1.806 1.085a.5.5 0 0 0 .758-.429V9.55a.5.5 0 0 0-.758-.429l-1.805 1.085a.5.5 0 0 1-.141.058l-.857.205a.5.5 0 0 0-.383.487Z"
      />
    </svg>
  );
};
