import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';

import { useAccount } from '@/hooks/useAccount';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { pageView } from '@/segment/segment';

import AvatarFieldset from './fieldsets/AccountAvatarFieldset';
import AccountIdFieldset from './fieldsets/AccountIdFieldset';
import NameFieldset from './fieldsets/AccountNameFieldset';
import SlugFieldset from './fieldsets/AccountSlugFieldset';

export const AccountSettings = () => {
  const { t } = useTranslation();
  const { slug } = useAccount();

  useTitle(t('pages.brain_settings', { 0: slug }));

  const canUpdate = useSelector((state: RootState) =>
    getPermissions(state, 'accounts', actions.UPDATE)
  );

  useEffect(() => {
    pageView(PageName.ACCOUNT_SETTINGS);
  }, []);

  return (
    <>
      <NameFieldset readOnly={!canUpdate} />
      <SlugFieldset readOnly={!canUpdate} />
      <AvatarFieldset readOnly={!canUpdate} />
      <AccountIdFieldset />
    </>
  );
};
