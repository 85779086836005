import { KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import Tags from '@/components/atoms/Tags/Tags';
import { ZendeskIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { isKeyEnter } from '@/util/util';
import { zendeskTagSchema } from '@/util/validator';

import styles from './styles.module.scss';

interface Props {
  integration?: ZendeskIntegration;
  updateIntegration: (integration: ZendeskIntegration) => void;
  isLoading: boolean;
  suggestedTags?: string[];
}

const TagsFieldset = ({
  isLoading,
  integration,
  updateIntegration,
  suggestedTags = [],
}: Props) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(integration?.config?.tags || []);
  }, [integration?.config?.tags]);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const {
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(zendeskTagSchema),
    mode: 'onChange',
    defaultValues: {
      tag: '',
    },
  });

  const onKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (isKeyEnter(e)) {
        const tag = getValues('tag');
        if (isValid && tag) {
          setTags(uniq([...tags, tag]));
          setValue('tag', '');
        }
      }
    },
    [getValues, isValid, setValue, tags]
  );

  const handleOnTagCreate = (tag: string) => {
    setTags(uniq([...tags, tag]));
  };

  const handleOnTagDelete = (tag: string) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const onSubmit = useCallback(() => {
    updateIntegration({
      ...integration,
      config: {
        ...integration?.config,
        tags,
      },
    });
  }, [integration, tags, updateIntegration]);
  return (
    <FormFieldset
      title={t('common.tags')}
      subtitle={t('integrations.zendesk.tags_subtitle')}
      isLoading={false}
      primaryButton={{
        children: t('common.save'),
        disabled:
          isLoading || isEqual(tags, integration?.config?.tags) || !canWrite,
      }}
      onSubmit={onSubmit}
    >
      <div className={styles.tags}>
        <Input
          name="tag"
          disabled={isLoading || tags.length > 50}
          label={t('integrations.zendesk.add_tag')}
          error={!!errors.tag}
          onKeyUp={onKeyUp}
          size="large"
          errorMessage={!!errors.tag && t('integrations.zendesk.error_tag')}
          autoFocus
          register={register('tag')}
          tooltip={t('integrations.zendesk.type_tag')}
          placeholder=""
          autoComplete="off"
          readOnly={!canWrite}
        />
      </div>
      <div
        className={cn(styles.tags, {
          [styles.readOnly]: !canWrite,
        })}
      >
        <Tags
          name="tags"
          label={t('common.tags')}
          tooltip={t('integrations.zendesk.tags_to_monitor')}
          tags={tags}
          placeholder={t('integrations.zendesk.monitoring_all')}
          onClick={handleOnTagDelete}
        />
      </div>
      <div className={styles.tags}>
        <Tags
          name="suggested_tags"
          label={t('integrations.zendesk.suggested_tags')}
          customVariant="neutral"
          tooltip={t('integrations.zendesk.existing_tags')}
          tags={suggestedTags}
          placeholder={t('integrations.zendesk.tags_no_suggestions')}
          icon={<AddIcon />}
          onClick={handleOnTagCreate}
        />
      </div>
    </FormFieldset>
  );
};
export default TagsFieldset;
