import { Props } from '@/models/icon';

import { ResponseLength } from '../models';

type ToneIconProps = {
  type: ResponseLength;
} & Props;

export const ResponseLengthIcon = ({
  size = 24,
  color = 'var(--icon-default-black)',
  type,
}: ToneIconProps) => {
  switch (type) {
    case 'short':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill={color}
            fillRule="evenodd"
            d="M2 10c0-.552.497-1 1.111-1H20.89C21.503 9 22 9.448 22 10s-.497 1-1.111 1H3.11C2.497 11 2 10.552 2 10ZM2 14c0-.552.497-1 1.111-1h7.778c.614 0 1.111.448 1.111 1s-.498 1-1.111 1H3.11C2.497 15 2 14.552 2 14Z"
            clipRule="evenodd"
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill={color}
            fillRule="evenodd"
            d="M2 12c0-.552.497-1 1.111-1H20.89c.614 0 1.111.448 1.111 1s-.497 1-1.111 1H3.11C2.497 13 2 12.552 2 12ZM2 8c0-.552.497-1 1.111-1H20.89C21.503 7 22 7.448 22 8s-.497 1-1.111 1H3.11C2.497 9 2 8.552 2 8ZM2 16c0-.552.497-1 1.111-1h7.778c.614 0 1.111.448 1.111 1s-.498 1-1.111 1H3.11C2.497 17 2 16.552 2 16Z"
            clipRule="evenodd"
          />
        </svg>
      );
    case 'long':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill={color}
            fillRule="evenodd"
            d="M2 14c0-.552.497-1 1.111-1H20.89c.614 0 1.111.448 1.111 1s-.497 1-1.111 1H3.11C2.497 15 2 14.552 2 14ZM2 10c0-.552.497-1 1.111-1H20.89C21.503 9 22 9.448 22 10s-.497 1-1.111 1H3.11C2.497 11 2 10.552 2 10ZM2 6c0-.552.497-1 1.111-1H20.89C21.503 5 22 5.448 22 6s-.497 1-1.111 1H3.11C2.497 7 2 6.552 2 6ZM2 18c0-.552.497-1 1.111-1h7.778c.614 0 1.111.448 1.111 1s-.498 1-1.111 1H3.11C2.497 19 2 18.552 2 18Z"
            clipRule="evenodd"
          />
        </svg>
      );
  }
};
