import { TypographyOptions } from '@mui/material/styles/createTypography';

const BASE_STYLES = {
  fontSize: 'var(--space-16)',
  letterSpacing: '0',
  lineHeight: '1.2',
} as const;

const typography = {
  ['subheading-regular']: {
    ...BASE_STYLES,
    fontWeight: '400',
  },
  ['subheading-semi-bold']: {
    ...BASE_STYLES,
    fontWeight: '600',
  },
  ['subheading-bold']: {
    ...BASE_STYLES,
    fontWeight: '700',
  },
} as TypographyOptions;

export { typography };
