import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Chip from '@/components/atoms/Chip/Chip';
import Link from '@/components/atoms/Link/Link';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import {
  emojiFlag,
  capitalizeFirstLetter,
  convertNameToTag,
} from '@/util/util';

import { TemplateBody } from './TemplateBody';
import { WHATSAPP_MANAGER_URL } from '../../constants';
import { useSubscribers } from '../../hooks/useSubscribers';
import { FieldsetStatus, Form } from '../../models';
import {
  selectBroadcast,
  selectSubscribers,
  selectValidTemplates,
} from '../../redux/selectors';
import { getComponentsFromTemplate, getVariablesLength } from '../../utils';
import StepFieldset from '../StepFieldset/StepFieldset';

interface Props {
  isReadOnly: boolean;
}
export const MessageFieldset = ({ isReadOnly }: Props) => {
  const { t } = useTranslation();
  const { control, watch, getValues, setValue, clearErrors } =
    useFormContext<Form>();
  const [stepStatus, setStepStatus] = useState<FieldsetStatus>('disabled');
  const templates = useSelector(selectValidTemplates);
  const broadcast = useSelector(selectBroadcast);
  const { subscribers: APISubscribers } = useSubscribers(
    broadcast.broadcast_id
  );

  const { append: headerAppend } = useFieldArray({
    control,
    name: 'headerParameters',
  });

  const { append: bodyAppend } = useFieldArray({
    control,
    name: 'bodyParameters',
  });

  const { name } = broadcast;

  const subscribers = useSelector(selectSubscribers);
  const templateId = watch('template')?.value;
  const template = templates.find((t) => t.id === templateId);

  const isPreviousStepValid =
    subscribers.length > 0 ||
    APISubscribers?.pages?.[0].subscribers?.length > 0;

  useEffect(() => {
    if (isPreviousStepValid && templateId) {
      setStepStatus('completed');
    }
    if (stepStatus !== 'completed' && isPreviousStepValid && !templateId) {
      setStepStatus('active');
    }
  }, [getValues, stepStatus, templateId, isPreviousStepValid]);

  const handleTemplateChange = (
    _,
    data: {
      value: string;
    }
  ) => {
    if (data.value !== templateId) {
      setValue('headerParameters', []);
      setValue('bodyParameters', []);
      setValue('headerMediaParam', null);
      clearErrors('headerParameters');
      clearErrors('bodyParameters');
      const t = templates.find((t) => t.id === data.value);

      const { header, body } = getComponentsFromTemplate(t);
      for (let i = 0; i < getVariablesLength(header); i++) {
        headerAppend({ name: null });
      }
      for (let i = 0; i < getVariablesLength(body); i++) {
        bodyAppend({ name: null });
      }
    }
  };

  const options = templates?.map((option) => ({
    label: `${emojiFlag(option.language)}   ${option.name}`,
    value: option.id,
    type: option.language,
    node_type: option.language,
  }));

  return (
    <StepFieldset
      title={capitalizeFirstLetter(t('common.message'))}
      step={2}
      status={stepStatus}
    >
      <TitleSubtitle
        title={t('broadcasts.templates')}
        size="large"
        subtitle={
          <Trans
            i18nKey="broadcasts.select_template"
            components={{
              link1: <Link href={WHATSAPP_MANAGER_URL} external />,
            }}
          />
        }
      />

      <AutocompleteNew
        control={control}
        options={options}
        name="template"
        id="template"
        placeholder={t('brains.select_a_template')}
        onChange={handleTemplateChange}
        disabled={stepStatus === 'disabled' || isReadOnly}
        noOptionsText={t('broadcasts.no_templates_available')}
      />
      {template && (
        <TemplateBody
          template={template}
          key={template.id}
          isReadOnly={isReadOnly}
        />
      )}
      <Box mt={2}>
        <TitleSubtitle
          title={t('rules.tag')}
          subtitle={t('broadcasts.select_tag')}
          size="large"
        />

        <Chip label={convertNameToTag(name)} size="medium" />
      </Box>
    </StepFieldset>
  );
};
