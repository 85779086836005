import { useCallback } from 'react';

import get from 'lodash/get';
import { usePageVisibility } from 'react-page-visibility';
import { useNavigate } from 'react-router';

import useUser from '@/hooks/useUser';
interface ShowNotificationsProps {
  url?: string;
  text: string;
}
const useDesktopNotifications = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const areNotificationsAllowed = get(
    user,
    'metadata.desktopNotifications',
    true
  );

  const isVisible = usePageVisibility();

  const showNotifications = useCallback(
    ({ url, text }: ShowNotificationsProps) => {
      if (!isVisible && areNotificationsAllowed) {
        const notification = new Notification(text, {
          icon: '/favicon-32x32.png',
        });
        notification.addEventListener('click', () => {
          window.focus();
          navigate(url);
          notification.close();
        });
      }
    },
    [areNotificationsAllowed, navigate, isVisible]
  );

  const allowNotifications = useCallback(() => {
    if ('Notification' in window && areNotificationsAllowed) {
      Notification.requestPermission();
    }
  }, [areNotificationsAllowed]);

  return {
    showNotifications,
    allowNotifications,
  };
};

export default useDesktopNotifications;
