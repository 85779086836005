import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const LocationPin = forwardRef<SVGSVGElement, Icon>(
  ({ size = 16, color = 'var(--icon-default-gray)', ...props }: Icon, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14 6.667c0 4.666-6 8.666-6 8.666s-6-4-6-8.666a6 6 0 1 1 12 0v0Z"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 8.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4v0Z"
        />
      </svg>
    );
  }
);

LocationPin.displayName = 'LocationPin';

export default LocationPin;
