import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '@/models/state';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange, getDateRangeText } from '@/util/analytics';
import { parseFilter } from '@/util/util';

import { filterFiltersByUrl } from '../constants';

export const SESSIONS_COUNTS = gql`
  query GetSessionsCount(
    $accountId: uuid
    $agentIds: _text
    $brainIds: _uuid
    $channels: _text
    $deskIds: _uuid
    $endDate: timestamp
    $isTest: Boolean
    $minNumUserMessages: Int
    $startDate: timestamp
    $limit: Int
  ) {
    rows: get_sessions_count(
      limit: $limit
      args: {
        account_id: $accountId
        agent_ids: $agentIds
        brain_parent_ids: $brainIds
        channels: $channels
        desk_ids: $deskIds
        end_time: $endDate
        is_test: $isTest
        min_num_user_messages: $minNumUserMessages
        start_time: $startDate
      }
    ) {
      num_sessions
      num_sessions_brain_send
      num_sessions_send
    }
  }
`;

type GetSessionsCountReturnKeys =
  | 'num_sessions'
  | 'num_sessions_brain_send'
  | 'num_sessions_send';

interface IntervalRow {
  num_sessions: number;
  num_sessions_brain_send: number;
  num_sessions_send: number;
}

interface Rows {
  rows: IntervalRow[];
}

const formatUsage = (
  currentRows?: IntervalRow[],
  previousRows?: IntervalRow[],
  key?: GetSessionsCountReturnKeys,
  filters?
) => {
  if (!currentRows) {
    return;
  }

  let currentCounts = 0;
  let previousCounts = 0;

  if (currentRows.length > 0) {
    currentCounts = currentRows[0][key];
  }
  if (previousRows && previousRows.length > 0) {
    previousCounts = previousRows[0][key];
  }

  let growth = 0;

  if (previousRows && previousCounts > 0) {
    growth = Math.round(
      ((currentCounts - previousCounts) / previousCounts) * 100
    );
  }

  return {
    counts: currentCounts,
    growth: previousRows ? growth : undefined,
    timeframe: getDateRangeText(filters.startDate, filters.endDate),
  };
};

type UseCountsProps = {
  skip?: boolean;
  skipPrevious?: boolean;
};

interface Usage {
  isLoading: boolean;
  isPreviousLoading?: boolean;
  currentDataError?: ApolloError;
  previousDataError?: ApolloError;
  refetchCurrent?: () => void;
  refetchPrevious?: () => void;
  data?: {
    counts: number;
    growth: number;
    timeframe: string;
  };
}

type UseCountsReturn = {
  brainConversations: Usage;
  agentConversations: Usage;
  overallConversations: Usage;
};

const useCounts = ({
  skip = false,
  skipPrevious = false,
}: UseCountsProps): UseCountsReturn => {
  const location = useLocation();

  const accountId = useSelector(selectAccountId);

  const filters = useSelector((state: RootState) => {
    return {
      analytics: state.analytics.accountAnalytics,
      startDate: state.analytics.startDate,
      endDate: state.analytics.endDate,
      filtersLoaded: state.analytics.filtersLoaded,
    };
  }, shallowEqual);

  const periodInDays = moment(filters.endDate).diff(
    moment(filters.startDate),
    'days'
  );

  // Used for "Compare with previous period" checkbox
  const previousStartDate = filters.startDate
    ? moment(filters.startDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;
  const previousEndDate = filters.endDate
    ? moment(filters.endDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;

  // GraphQL variables
  const gqlQueryVariables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters.endDate),
          accountId,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  // Get current data
  const {
    data: currentData,
    loading: currentDataLoading,
    error: currentDataError,
    refetch: refetchCurrentData,
  } = useQuery<Rows, QueryVariables>(SESSIONS_COUNTS, {
    variables: gqlQueryVariables,
    skip: skip || !accountId || !filters.filtersLoaded,
  });
  const currentRows = currentData?.rows;
  const onRefetchCurrentData = useCallback(() => {
    refetchCurrentData(gqlQueryVariables);
  }, [refetchCurrentData, gqlQueryVariables]);

  // Get previous period data
  const {
    data: previousData,
    loading: previousDataLoading,
    error: previousDataError,
    refetch: refetchPreviousData,
  } = useQuery<Rows, QueryVariables>(SESSIONS_COUNTS, {
    variables: {
      ...gqlQueryVariables,
      startDate: previousStartDate,
      endDate: previousEndDate,
    },
    skip: !accountId || skipPrevious || !filters.filtersLoaded,
  });
  const previousRows = previousData?.rows;
  const onRefetchPreviousData = useCallback(() => {
    refetchPreviousData({
      ...gqlQueryVariables,
      startDate: previousStartDate,
      endDate: previousEndDate,
    });
  }, [
    previousEndDate,
    previousStartDate,
    refetchPreviousData,
    gqlQueryVariables,
  ]);

  // Something else
  const finalData = useMemo(() => {
    return {
      agentConversations: {
        data: formatUsage(
          currentRows,
          previousRows,
          'num_sessions_send',
          filters
        ),
        isLoading: currentDataLoading,
      },
      brainConversations: {
        data: formatUsage(
          currentRows,
          previousRows,
          'num_sessions_brain_send',
          filters
        ),
        isLoading: currentDataLoading,
      },
      overallConversations: {
        data: formatUsage(currentRows, previousRows, 'num_sessions', filters),
        isLoading: currentDataLoading,
      },
    };
  }, [currentDataLoading, currentRows, filters, previousRows]);

  // Handle different loading and error states, returning appropriate objects
  if (currentDataLoading) {
    return {
      brainConversations: { isLoading: true },
      agentConversations: { isLoading: true },
      overallConversations: { isLoading: true },
    };
  }

  if (previousDataLoading && !skipPrevious) {
    return {
      brainConversations: {
        ...finalData.brainConversations,
        isLoading: false,
        isPreviousLoading: true,
      },
      agentConversations: {
        ...finalData.agentConversations,
        isLoading: false,
        isPreviousLoading: true,
      },
      overallConversations: {
        ...finalData.overallConversations,
        isLoading: false,
        isPreviousLoading: true,
      },
    };
  }

  if (currentDataError || previousDataError) {
    return {
      brainConversations: {
        isLoading: false,
        currentDataError,
        previousDataError,
        refetchCurrent: onRefetchCurrentData,
        refetchPrevious: onRefetchPreviousData,
      },
      agentConversations: {
        isLoading: false,
        currentDataError,
        previousDataError,
        refetchCurrent: onRefetchCurrentData,
        refetchPrevious: onRefetchPreviousData,
      },
      overallConversations: {
        isLoading: false,
        currentDataError,
        previousDataError,
        refetchCurrent: onRefetchCurrentData,
        refetchPrevious: onRefetchPreviousData,
      },
    };
  }

  return { ...finalData };
};

export default useCounts;
