import { ConversationStatusType, Sort } from '@/models/chat';
import { INFINITE_SCROLLING_LIMIT, PAGINATION_LIMIT } from '@/util/constants';

export const rulesEndpoints = Object.freeze({
  rules: (desk_id: string) =>
    `/www/api/v1/desks/${desk_id}/rules?limit=${PAGINATION_LIMIT}`,
  rule: (desk_id: string, rule_id: string) =>
    `/www/api/v1/desks/${desk_id}/rules/${rule_id}`,
  reorder: (desk_id: string, rule_id: string) =>
    `/www/api/v1/desks/${desk_id}/rules/${rule_id}/reorder`,
});

export const brainsEndpoints = Object.freeze({
  brains: `/www/api/v1/brains?limit=${PAGINATION_LIMIT}&sort=-last_trained`,
  brain: (id: string) => `/www/api/v1/brains/${id ?? '-'}`,
  onboarding: `/www/api/v1/onboarding/logs`,
  templates: (lang: string) => `/base-templates/${lang}.json`,
});

export const accountsEndpoints = Object.freeze({
  accounts: '/www/api/v1/accounts',
  account: '/www/api/v1/account',
  checkSlug: (id: string) => `/www/api/v1/accounts/check_slug?slug=${id}`,
  agents: '/www/api/v1/account/agents',
  transfer: '/www/api/v1/account/change-ownership',
});

export const billingEndpoints = Object.freeze({
  plans: '/www/api/v1/plans',
  subscription: '/www/api/v1/stripe/subscription',
  customer: '/www/api/v1/stripe/customer',
  taxId: '/www/api/v1/stripe/tax_id',
  billingState: '/www/api/v1/stripe/billing-state',
  paymentMethods: '/www/api/v1/stripe/payment_methods',
  attachPaymentMethod: (pmId: string) =>
    `/www/api/v1/stripe/payment_methods/${pmId}/attach`,
  detachPaymentMethod: (pmId: string) =>
    `/www/api/v1/stripe/payment_methods/${pmId}/detach`,
  pastInvoices: `/www/api/v1/stripe/invoices/past`,
  upcomingInvoices: `/www/api/v1/stripe/invoices/upcoming`,
  redeemPromoCode: '/www/api/v1/stripe/promotion_codes',
});

export const businessHoursEndpoints = {
  businessHours: (desk_id: string) =>
    `/www/api/v1/desks/${desk_id}/business-hours`,
  businessHour: (desk_id: string, business_hours_id: string) =>
    `/www/api/v1/desks/${desk_id}/business-hours/${business_hours_id}`,
} as const;

export const collectionsEndpoints = Object.freeze({
  collections: `/www/api/v1/collections?limit=${PAGINATION_LIMIT}`,
  collection: (id: string) => `/www/api/v1/collections/${id ?? '-'}`,
});

export const broadcastsEndpoints = Object.freeze({
  broadcasts: (cursor?: string, filter?: string) => {
    const queryParams = new URLSearchParams({
      limit: `${INFINITE_SCROLLING_LIMIT}`,
      sort: '-created',
    });

    if (cursor) {
      queryParams.append('next_cursor', cursor);
    }
    if (filter) {
      queryParams.append('name', filter);
    }

    const queryString = queryParams.toString();
    return `/www/api/v1/broadcasts?${queryString}`;
  },
  broadcast: (id: string) => `/www/api/v1/broadcasts/${id ?? '-'}`,
  whatsAppTemplates: (deskId: string, id: string) =>
    `/www/api/v1/desks/${deskId}/integrations/${id}/whatsapp/templates/?limit=${PAGINATION_LIMIT}`,
});

export const subscribersEndpoints = Object.freeze({
  subscribers: (
    id: string,
    cursor?: string,
    filter?: string,
    filterStatus = null
  ) => {
    const queryParams = new URLSearchParams({
      limit: `${INFINITE_SCROLLING_LIMIT}`,
      sort: 'external_id',
    });
    if (cursor) {
      queryParams.append('next_cursor', cursor);
    }
    if (filter) {
      queryParams.append('external_id', filter);
    }
    if (filterStatus) {
      queryParams.append('status', filterStatus);
    }
    const queryString = queryParams.toString();
    return `/www/api/v1/broadcasts/${id}/subscribers?${queryString}`;
  },
  subscribersUpsert: (id: string) =>
    `/www/api/v1/broadcasts/${id}/subscribers/bulk-upsert`,
  subscribersVerify: `/www/api/v1/broadcasts/verify-subscribers`,
});

type ListConversations = {
  id: string;
  status?: ConversationStatusType;
  sort?: Sort;
  cursor?: string;
  agent_id?: string;
  department_id?: string;
  brain_id?: string;
};

export const conversationsEndpoints = Object.freeze({
  conversations: ({
    id,
    status,
    sort,
    cursor,
    agent_id,
    department_id,
    brain_id,
  }: ListConversations) => {
    let queryParams = '';

    if (cursor) {
      queryParams += `&next_cursor=${cursor}`;
    }
    if (sort) {
      queryParams += `&sort=${sort}`;
    } else {
      queryParams += `&sort=-last_message_at`;
    }
    queryParams += `&limit=${INFINITE_SCROLLING_LIMIT}`;

    if (agent_id) {
      queryParams += `&agent_id=${agent_id}`;
    }
    if (department_id) {
      queryParams += `&department_id=${department_id}`;
    }
    if (brain_id) {
      queryParams += `&brain_id=${brain_id === 'brain' ? 'any' : brain_id}`;
    }

    const statusQuery = status ? `status=${status}` : '';

    return `/www/api/v1/desks/${id}/conversations?${statusQuery}${queryParams}`;
  },
  conversation: (id: string, name: string) =>
    `/www/api/v1/desks/${id ?? '-'}/conversations/${name}`,
  typing: (id: string, name: string) =>
    `/www/api/v1/desks/${id ?? '-'}/conversations/${name}/compose`,
  read: (id: string, name: string) =>
    `/www/api/v1/desks/${id ?? '-'}/conversations/${name}/read`,
});

export const datasourceEndpoints = Object.freeze({
  datasources: (collectionId: string) =>
    `/www/api/v1/collections/${collectionId}/datasources?limit=${PAGINATION_LIMIT}`,
  datasource: (collectionId: string, id: string) =>
    `/www/api/v1/collections/${collectionId}/datasources/${id ?? '-'}`,
  syncDatasource: (collectionId: string, id: string) =>
    `/www/api/v1/collections/${collectionId}/datasources/${id ?? '-'}/sync?force=true`,
});

export const desksEndpoints = Object.freeze({
  desks: `/www/api/v1/desks?limit=${PAGINATION_LIMIT}&sort=-updated`,
  desk: (id: string) => `/www/api/v1/desks/${id ?? '-'}`,
});

export const dialogsEndpoints = Object.freeze({
  dialogs: (id: string) =>
    `/www/api/v1/brains/${id}/dialogs?limit=${PAGINATION_LIMIT}`,
  dialog: (id: string, dialogId: string) =>
    `/www/api/v1/brains/${id ?? '-'}/dialogs/${dialogId}`,
  collection: (id: string) => `/www/api/v1/brains/${id}/dialogs/collections`,
});

export const documentsEndpoints = Object.freeze({
  documents: (
    collectionId: string,
    datasourceId: string,
    cursor?: string,
    filter?: string
  ) => {
    const queryParams = new URLSearchParams({
      limit: `${INFINITE_SCROLLING_LIMIT}`,
      sort: '-created',
    });

    if (cursor) {
      queryParams.append('next_cursor', cursor);
    }

    if (filter) {
      queryParams.append('name', filter);
    }
    const queryString = queryParams.toString();
    return `/www/api/v1/collections/${collectionId}/datasources/${datasourceId}/documents?${queryString}`;
  },
  document: (collectionId: string, datasourceId: string, documentId: string) =>
    `/www/api/v1/collections/${collectionId}/datasources/${datasourceId}/documents/${documentId}`,
  exportDocument: (
    collectionId: string,
    datasourceId: string,
    documentId: string
  ) =>
    `/www/api/v1/collections/${collectionId}/datasources/${datasourceId}/documents/${documentId}/download`,
});

export const entitiesEndpoints = Object.freeze({
  entities: (id: string) =>
    `/www/api/v1/brains/${id ?? '-'}/entities?limit=${PAGINATION_LIMIT}`,
  entity: (id: string, name: string) =>
    `/www/api/v1/brains/${id ?? '-'}/entities/${name ?? '-'}`,
  collection: (id: string) => `/www/api/v1/brains/${id}/entities/collections`,
});

export const integrationsEndpoints = Object.freeze({
  integrations: (deskId: string) =>
    `/www/api/v1/desks/${deskId ?? '-'}/integrations`,
  integration: (deskId: string, id: string) =>
    `/www/api/v1/desks/${deskId}/integrations/${id ?? '-'}`,
  verify: (deskId: string, integrationId: string) =>
    `/www/api/v1/desks/${deskId}/integrations/${integrationId}/verify`,
});

export const recommendationsEndpoints = Object.freeze({
  recommendations: (brainId: string) =>
    `/www/api/v1/brains/${brainId}/recommendations`,
});

export const invitationsEndpoints = Object.freeze({
  invitations: '/www/api/v1/invitations',
  userInvitations: '/www/api/v1/invitations/user',
  accountInvitations: '/www/api/v1/invitations/account',
  deleteInvitations: (email?: string) =>
    `/www/api/v1/invitations?email=${email}`,
});

export const macrosEndpoints = Object.freeze({
  macros: (desk_id: string) => `/www/api/v1/desks/${desk_id}/macros`,
  macro: (desk_id: string, macro_id: string) =>
    `/www/api/v1/desks/${desk_id}/macros/${macro_id}`,
});

export const membersEndpoints = Object.freeze({
  members: '/www/api/v1/members',
  updateMember: (userId?: string) => `/www/api/v1/members/${userId}`,
});

export const messagesEndpoints = Object.freeze({
  messages: (id: string, sessionId: string, cursor?: string) => {
    const queryParams = new URLSearchParams({
      limit: `${INFINITE_SCROLLING_LIMIT}`,
      sort: '-created',
    });
    if (cursor) {
      queryParams.append('next_cursor', cursor);
    }
    const queryString = queryParams.toString();
    return `/www/api/v1/desks/${id}/conversations/${sessionId}/messages?${queryString}`;
  },
  message: (id: string, name: string) =>
    `/www/api/v1/desks/${id}/conversations/${name}/messages`,
});

export const preferencesEndpoints = Object.freeze({
  userPrefs: '/www/api/v1/user/preferences',
  accountPrefs: '/www/api/v1/account/preferences',
  accountUserPrefs: '/www/api/v1/account/preferences/user',
});

export const rolesEndpoints = Object.freeze({
  roles: '/www/api/v1/roles',
});

export const teamsEndpoints = Object.freeze({
  teams: '/www/api/v1/teams',
  team: (team_id: string) => `/www/api/v1/teams/${team_id}`,
});

export const teamUsersEndpoints = Object.freeze({
  users: (team_id: string) => `/www/api/v1/teams/${team_id}/users`,
  user: (team_id: string, user_id: string) =>
    `/www/api/v1/teams/${team_id}/users/${user_id}`,
});

export const userEndpoints = Object.freeze({
  user: '/www/api/v1/user',
  logout: '/www/logout',
  agent: (id: string) => `/www/api/v1/agents/${id}`,
  userExists: '/www/api/auth/exists',
  magicLink: (to: string, invitation?: boolean) =>
    `/www/api/auth/magic?to=${to || '/'}${invitation ? '&invitation=true' : ''}`,
  createUser: '/www/api/auth/signup',
});

export const usersRolesEndpoints = Object.freeze({
  roles: (user_id: string) => `/www/api/v1/users/${user_id}/roles`,
  role: (user_id: string, role_id: string) =>
    `/www/api/v1/users/${user_id}/roles/${role_id}`,
  userDepartments: (id: string) => `/www/api/v1/users/${id}/departments`,
});

export const versionsEndpoints = Object.freeze({
  versions: (id: string) =>
    `/www/api/v1/brains/${id ?? '-'}/versions?sort=-created`,
  version: (id: string, v: number) =>
    `/www/api/v1/brains/${id ?? '-'}/versions/${v ?? '-'}`,
  diff: (id: string) => `/www/api/v1/brains/${id ?? '-'}/versions/diff`,
});

export const webhooksEndpoints = Object.freeze({
  webhooks: (id: string) => `/www/api/v1/brains/${id ?? '-'}/webhooks`,
  webhook: (id: string, wId: string) =>
    `/www/api/v1/brains/${id ?? '-'}/webhooks/${wId ?? '-'}`,
  test: (id: string) => `/www/api/v1/brains/${id ?? '-'}/webhooks/test`,
});

const encode = encodeURIComponent;

export const intentsEndpoints = Object.freeze({
  intents: (id: string) =>
    `/www/api/v1/brains/${id ?? '-'}/intents?limit=${PAGINATION_LIMIT}`,
  intent: (id: string, name: string) =>
    `/www/api/v1/brains/${id ?? '-'}/intents/${encode(name) ?? '-'}`,
  collection: (id: string) => `/www/api/v1/brains/${id}/intents/collections`,
});

export const bundlesEndpoints = Object.freeze({
  bundles: (desk_id: string) => `/www/api/v1/desks/${desk_id}/bundles`,
  bundle: (desk_id: string, bundle_id: string) =>
    `/www/api/v1/desks/${desk_id}/bundles/${bundle_id || '-'}`,
});

export const permissionsEndpoints = Object.freeze({
  permissions: '/www/api/v1/permissions/user',
});

export const teamsRolesEndpoints = Object.freeze({
  roles: (team_id: string) => `/www/api/v1/teams/${team_id}/roles`,
  role: (team_id: string, role_id: string) =>
    `/www/api/v1/teams/${team_id}/roles/${role_id}`,
});

export const credentialsEndpoints = Object.freeze({
  credentials: '/www/api/v1/credentials',
  credential: (credential_id: string) =>
    `/www/api/v1/credentials/${credential_id}`,
});

export const languageModelsEnpoints = {
  languageModels: '/www/api/v1/language-models',
  languageModel: (language_model_id: string) =>
    `/www/api/v1/language-models/${language_model_id}`,
  providers: '/www/api/v1/language-models/providers',
} as const;
