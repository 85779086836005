import { createContext } from 'react';

import { Socket } from '@/models/rtm';

/**
 * The socket context.
 *
 * - `socket` is a reference to the Socket.IO client
 * - `isConnected` is true if the socket is connected.
 *
 */
export const SocketContext = createContext<{
  socket: Socket;
  isConnected: boolean;
}>(null);
SocketContext.displayName = 'SocketContext';
