import { HTMLAttributes } from 'react';

import cn from 'classnames';

import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Brain = ({
  size = 16,
  color = 'currentColor',
  className,
  ...rest
}: Icon & HTMLAttributes<SVGElement>) => {
  return (
    <svg
      {...rest}
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      className={cn(styles.icon, className)}
    >
      <path
        d="M8.667 1.333L2 9.333h6l-.667 5.334 6.667-8H8l.667-5.334z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Brain;
