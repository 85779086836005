import Firefox from '../Firefox';
import GoogleChrome from '../GoogleChrome';
import Opera from '../Opera';
import Safari from '../Safari';

export const BrowserIcon = ({ browser }: { browser: string }) => {
  if (browser?.toLowerCase().includes('chrome')) {
    return <GoogleChrome />;
  }
  if (browser?.toLowerCase().includes('safari')) {
    return <Safari />;
  }
  if (browser?.toLowerCase().includes('opera')) {
    return <Opera />;
  }
  if (browser?.toLowerCase().includes('firefox')) {
    return <Firefox />;
  }

  return null;
};
