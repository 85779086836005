import { useCallback, useMemo } from 'react';

import dotize from 'dotize';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userContextVariables } from './constants';
import { selectTryItContext } from '../../redux/selectors';

// Check if a variable is a user context variable
const isUserContextVariable = (variable) => {
  return userContextVariables.some(
    (userContextVariable) => userContextVariable.label === variable.label
  );
};

export const useVariablesSection = () => {
  const tryItContext = useSelector(selectTryItContext);
  const { t } = useTranslation();

  // Format context variables for display
  const formattedContextVariables = useMemo(() => {
    const contextVariables = omit(tryItContext, ['global', 'tags']);

    const dotizedContextVariables = dotize.convert(contextVariables);

    const formattedContextVariables = Object.keys(dotizedContextVariables).map(
      (key) => {
        return {
          label: key,
          value: dotizedContextVariables[key] as string,
        };
      }
    );

    return formattedContextVariables;
  }, [tryItContext]);

  // Get the option for a variable
  const getVariableOption = useCallback(
    (variable) => {
      let label = null;

      if (variable?.label) {
        label = variable.label;
      }

      if (isUserContextVariable(variable)) {
        label = t(`auto_complete.variable_labels.${variable.label}`);
      }

      return {
        ...variable,
        label,
        value: variable.label,
      };
    },
    [t]
  );

  return { formattedContextVariables, getVariableOption };
};
