import { SVGProps } from 'react';

import { Icon } from '@/models/icon';

const Close = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  className = '',
  ...props
}: SVGProps<SVGSVGElement> & Icon) => {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={`stroke ${className}`}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
      shapeRendering="geometricPrecision"
    >
      <path d="M3.96 4l7.96 8-7.96 8M20.04 4l-7.96 8 7.96 8" />
    </svg>
  );
};

export default Close;
