export const Check = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="#F3F3F3" rx="12" />
      <path
        stroke="#1B66D6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m16 9-5.5 5.5L8 12"
      />
    </svg>
  );
};
