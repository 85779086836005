import { useCallback } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { callDelete, callGet, callPost } from '@/api/fetcher';
import {
  MODAL_FORM,
  MODAL_DELETE,
} from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import useDesks from '@/hooks/useDesks';
import useMembers from '@/hooks/useMembers';
import {
  AccountAnalytics,
  AnalyticsType,
  Channel,
} from '@/modules/analytics/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectAccountId } from '@/redux/session/selectors';
import { analyticsKeyToValue, analyticsValueToKey } from '@/util/analytics';
import { urlSafeBase64Decode, urlSafeBase64Encode } from '@/util/util';

import { periodOptions } from './useDateFilter';
import { filterFiltersByUrl } from '../constants';
import { applyFilters, setPeriod } from '../redux/actions';

type Period = '0' | '1' | '7' | '14' | '30' | 'custom';

export type Filter = Partial<{
  period: Period;
  start_date: string;
  end_date: string;
  agent_ids: string[];
  brain_ids: string[];
  desk_ids: string[];
  channels: Channel[];
  min_messages: number;
  is_preview: boolean;
}>;

type Filters = {
  filters: AccountFilter[];
};

export type AccountFilter = {
  name: string;
  created: string;
  filter: Filter;
  filter_id: string;
  account_id: string;
  user_id: string;
};
export const endpoints = Object.freeze({
  filters: `/www/api/v1/user/filters`,
  filter: (filterId: string) => `/www/api/v1/user/filters/${filterId ?? '-'}`,
});

const API = Object.freeze({
  listFilters: async (): Promise<Filters> => callGet(endpoints.filters),

  getFilter: async (filterId: string): Promise<AccountFilter> =>
    callGet(endpoints.filter(filterId)),

  exportFilter: async (filterId: string): Promise<AccountFilter> =>
    callGet(`${endpoints.filter(filterId)}?export=true`),

  createFilter: async (
    newFilter: Partial<AccountFilter>
  ): Promise<AccountFilter> => callPost(endpoints.filters, newFilter),

  deleteFilter: async (filterId: string): Promise<AccountFilter> =>
    callDelete(endpoints.filter(filterId)),
});

export const onFilterRemoved = (
  queryClient: QueryClient,
  filter_id: string,
  account_id: string
) => {
  queryClient.setQueryData<Filters>(
    [endpoints.filters, account_id],
    (prev: Filters) => ({
      filters: (prev?.filters || []).filter(
        (acc) => acc.filter_id !== filter_id
      ),
    })
  );
};

export const onFilterCreated = (
  queryClient: QueryClient,
  filter: AccountFilter
) => {
  queryClient.setQueryData<Filters>(
    [endpoints.filters, filter.account_id],
    (prev) => ({
      filters: [
        ...(prev?.filters || []).filter(
          (acc) => acc.filter_id !== filter.filter_id
        ),
        filter,
      ],
    })
  );
};

export const prepareData = (
  date: { startDate: string; endDate: string },
  filters: AccountAnalytics[]
) => {
  const today = moment();
  const startDate = moment(date.startDate);
  const endDate = moment(date.endDate);
  const yesterday = moment().subtract(1, 'days');
  let period: Period;
  let start_date: string;
  let end_date: string;

  if (today.isSame(startDate, 'day') && today.isSame(endDate, 'day')) {
    period = '0';
  } else if (
    startDate.isSame(endDate, 'day') &&
    endDate.isSame(yesterday, 'day')
  ) {
    period = '1';
  } else if (
    today.isSame(endDate, 'day') &&
    today.diff(startDate, 'days') === periodOptions[2].days
  ) {
    period = '7';
  } else if (
    today.isSame(endDate, 'day') &&
    today.diff(startDate, 'days') === periodOptions[3].days
  ) {
    period = '14';
  } else if (
    today.isSame(endDate, 'day') &&
    today.diff(startDate, 'days') === periodOptions[4].days
  ) {
    period = '30';
  } else {
    period = 'custom';
    start_date = date.startDate;
    end_date = date.endDate;
  }

  const formatFilters = (item) => {
    if (!item) {
      return;
    }
    if (item.type === 'isTest' || item.type === 'minNumUserMessages') {
      return item[item.type];
    }

    if (
      item.type === 'agentIds' ||
      item.type === 'brainIds' ||
      item.type === 'deskIds'
    ) {
      return item[item.type].map((f) => f.value);
    }
    return item[item.type].map((f) => f);
  };

  const restFilter = filters?.reduce((acc, item) => {
    return {
      ...acc,
      [analyticsKeyToValue[item.type]]: formatFilters(item),
    };
  }, {});

  const data = {
    filter: {
      period,
      start_date,
      end_date,
      ...restFilter,
    },
  };
  return data;
};

const useFavoriteFilters = (enabled?: boolean) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [urlParams] = useSearchParams();
  const accountId = useSelector(selectAccountId);
  const { brains } = useBrains();
  const { desks } = useDesks();
  const { members } = useMembers();

  const { data: filters, status: listStatus } = useQuery<Filters, Error>({
    queryKey: [endpoints.filters, accountId],
    queryFn: () => API.listFilters(),
    enabled: !!accountId && enabled,
  });

  const { mutate: createFilter, status: createStatus } = useMutation<
    AccountFilter,
    Error,
    Partial<AccountFilter>
  >({
    mutationFn: API.createFilter,
    onSuccess: (resp) => {
      onFilterCreated(queryClient, resp);
      dispatch(popModal());
      dispatch(
        addTemporalToast(
          'success',
          t('analytics.filter_created', { 0: resp?.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteFilter, status: deleteStatus } = useMutation<
    AccountFilter,
    Error,
    string
  >({
    mutationFn: (id) => API.deleteFilter(id),
    onSuccess: (resp) => {
      const name = filters?.filters.find(
        (f) => f.filter_id === resp.filter_id
      )?.name;
      onFilterRemoved(queryClient, resp.filter_id, accountId);
      dispatch(
        addTemporalToast('success', t('analytics.filter_deleted', { 0: name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const handleNewFilter = useCallback(() => {
    const dateParams = urlParams.get('date') ?? null;
    const filterParams = urlParams.get('filters') ?? null;
    const date = dateParams
      ? JSON.parse(urlSafeBase64Decode(dateParams))
      : null;
    const filters: AccountAnalytics[] = filterParams
      ? JSON.parse(urlSafeBase64Decode(filterParams))
      : null;
    const data = prepareData(date, filters);
    const modalProps = {
      resource: t('analytics.favourite_filter'),
      fields: [
        {
          fieldName: 'name',
          fieldValue: '',
        },
      ],
      onCreate: (form: { name: string }) => createFilter({ ...form, ...data }),
    };
    dispatch(pushModal(MODAL_FORM, modalProps));
  }, [createFilter, dispatch, t, urlParams]);

  const handleDeleteFilter = (index: number) => {
    const { filter_id, name } = filters?.filters[index] || {};

    const deleteProps = {
      subtitle: t('analytics.filters_delete_warning', { 0: name }),
      onDelete: () => {
        deleteFilter(filter_id, {
          onSuccess: () => {
            dispatch(popModal());
          },
        });
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  };

  const selectFavorite = useCallback(
    (filter: AccountFilter) => {
      const formatValues = (
        items,
        filterName: string,
        key: string,
        finalKey: string
      ) => {
        const data = filter?.filter[filterName].map((id) => {
          const name = items?.find((m) => m[key] === id)?.name;
          return {
            value: id,
            label: name,
          };
        });
        return {
          type: finalKey,
          [finalKey]: data,
        };
      };
      const result = Object.keys(filter?.filter)
        .map((f) => {
          if (f === 'agent_ids') {
            return formatValues(members, 'agent_ids', 'user_id', 'agentIds');
          }
          if (f === 'brain_ids') {
            return formatValues(brains, 'brain_ids', 'brain_id', 'brainIds');
          }
          if (f === 'desk_ids') {
            return formatValues(desks, 'desk_ids', 'desk_id', 'deskIds');
          }
          if (analyticsValueToKey[f]) {
            return {
              type: analyticsValueToKey[f],
              [analyticsValueToKey[f]]: filter?.filter[f],
            };
          }

          return null;
        })
        .filter(Boolean) as AccountAnalytics[];

      const { period, start_date, end_date } = filter?.filter || {};
      let date: { startDate: string; endDate: string };

      if (period === '0' || period === '1') {
        date = {
          startDate: moment()
            .subtract(Number(period), 'days')
            .format('YYYY-MM-DD'),
          endDate: moment()
            .subtract(Number(period), 'days')
            .format('YYYY-MM-DD'),
        };
      } else if (period !== 'custom') {
        date = {
          startDate: moment()
            .subtract(Number(period) - 1, 'days')
            .format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        };
      } else {
        date = {
          startDate: start_date,
          endDate: end_date,
        };
      }

      dispatch(setPeriod(date));

      dispatch(
        applyFilters({
          filters: result,
          type: AnalyticsType.ACCOUNT,
        })
      );

      navigate(
        {
          ...window.location,
          search: `${
            date.startDate
              ? `date=${urlSafeBase64Encode(JSON.stringify(date))}`
              : urlParams.get('date')
          }${
            !isEmpty(result)
              ? `&filters=${urlSafeBase64Encode(JSON.stringify(result))}`
              : ''
          }`,
        },
        {
          replace: true,
        }
      );
    },
    [brains, desks, dispatch, members, urlParams, navigate]
  );

  const updatedFilters = filters?.filters.reduce((acc, filter) => {
    const checkIfFilterApplies = Object.keys(filter.filter).filter(
      (f) => f !== 'period' && f !== 'start_date' && f !== 'end_date'
    );
    const found = checkIfFilterApplies.filter((f) => {
      return filterFiltersByUrl(analyticsValueToKey[f], location.pathname, t);
    });
    if (checkIfFilterApplies.length === found.length) {
      return [...acc, filter];
    }
    return acc;
  }, [] as AccountFilter[]);

  return {
    filters: updatedFilters,
    handleNewFilter,
    handleDeleteFilter,
    selectFavorite,
    listStatus,
    createStatus,
    deleteStatus,
  };
};

export default useFavoriteFilters;
