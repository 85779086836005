export const ErrorRequest = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 81 94"
      width="81"
      height="94"
      fill="none"
    >
      <g clipPath="url(#errorsvg)">
        <path
          fill="#1B66D6"
          d="M36.433 89.215c20.121 0 36.433-16.033 36.433-35.81 0-19.778-16.312-35.811-36.433-35.811S0 33.627 0 53.404c0 19.778 16.312 35.811 36.433 35.811Z"
          opacity=".2"
        />
        <path
          fill="#1B66D6"
          d="M2.839 83.44A24.703 24.703 0 0 1 .47 72.903a24.702 24.702 0 0 1 2.359-10.54 25.112 25.112 0 0 1 6.649-8.586 25.646 25.646 0 0 1 9.706-5.043l2.168 7.703a17.468 17.468 0 0 0-6.612 3.434 17.103 17.103 0 0 0-4.53 5.848 16.824 16.824 0 0 0-1.609 7.178c0 2.48.551 4.929 1.612 7.178L2.84 83.44Z"
        />
        <path
          fill="#0A3451"
          d="M3.264 94c-.45 0-.892-.122-1.276-.353a2.414 2.414 0 0 1-.9-.957 9.264 9.264 0 0 1-.573-7.184 9.462 9.462 0 0 1 4.762-5.478 9.735 9.735 0 0 1 7.308-.564 9.534 9.534 0 0 1 5.574 4.682 2.367 2.367 0 0 1-.308 2.658 2.433 2.433 0 0 1-.75.583 2.472 2.472 0 0 1-2.703-.323 2.402 2.402 0 0 1-.587-.742 4.631 4.631 0 0 0-1.134-1.42 4.721 4.721 0 0 0-1.6-.884 4.777 4.777 0 0 0-3.59.275c-.551.275-1.041.655-1.442 1.117a4.597 4.597 0 0 0-.897 1.575 4.544 4.544 0 0 0 .288 3.528 2.364 2.364 0 0 1-.094 2.34c-.218.35-.523.64-.887.84-.364.2-.774.306-1.19.307Z"
        />
        <path
          fill="#1B66D6"
          d="M66.317 53.165a25.492 25.492 0 0 1-12.01 5.575l-1.432-7.868a17.437 17.437 0 0 0 10.126-5.714 16.903 16.903 0 0 0 4.156-10.724l8.13.166c-.126 6.42-2.765 12.544-7.367 17.1-.516.513-1.05 1.001-1.603 1.465Z"
        />
        <path
          fill="#0A3451"
          d="M77.624 34.372a9.658 9.658 0 0 1-4.87 2.148 9.73 9.73 0 0 1-5.285-.748 9.535 9.535 0 0 1-4.056-3.412 9.302 9.302 0 0 1-1.568-5.017 2.377 2.377 0 0 1 .693-1.697 2.46 2.46 0 0 1 1.708-.724 2.463 2.463 0 0 1 1.731.671 2.38 2.38 0 0 1 .746 1.676 4.581 4.581 0 0 0 1.428 3.244 4.743 4.743 0 0 0 3.344 1.302 4.739 4.739 0 0 0 3.3-1.403 4.577 4.577 0 0 0 1.325-3.287c-.01-.636.238-1.25.688-1.706a2.46 2.46 0 0 1 1.714-.729 2.463 2.463 0 0 1 1.735.676c.465.443.732 1.049.742 1.685a9.212 9.212 0 0 1-.667 3.615 9.338 9.338 0 0 1-2.033 3.084c-.216.219-.44.426-.675.622Z"
        />
        <path
          fill="#1B66D6"
          d="M60.224.183 58.011 6.49a.268.268 0 0 0 .13.326.28.28 0 0 0 .123.033l1.493.044a.28.28 0 0 1 .22.12.27.27 0 0 1 .03.245l-2.462 6.517a.267.267 0 0 0 .125.332.28.28 0 0 0 .353-.074l5.798-7.511a.268.268 0 0 0-.117-.416l-1.737-.681a.276.276 0 0 1-.152-.147.266.266 0 0 1 0-.21L63.762.472a.268.268 0 0 0-.119-.34.28.28 0 0 0-.127-.035L60.494 0a.28.28 0 0 0-.27.183Z"
        />
        <path
          fill="#0A3451"
          d="m3.704 29.132 2.579 4.928a.222.222 0 0 1-.067.284.233.233 0 0 1-.098.041l-1.222.21a.232.232 0 0 0-.167.124.222.222 0 0 0 .005.206l2.81 5.071a.222.222 0 0 1-.065.287.232.232 0 0 1-.298-.019L1.51 34.762a.225.225 0 0 1-.066-.189.223.223 0 0 1 .112-.166l1.346-.762a.23.23 0 0 0 .108-.138.222.222 0 0 0-.025-.172L.83 29.78a.223.223 0 0 1 .057-.293.231.231 0 0 1 .1-.043l2.474-.43a.232.232 0 0 1 .243.118Z"
        />
        <path
          fill="#1B66D6"
          d="m13.725 49.154 5.39 29.967a16.679 16.679 0 0 0 5.826 9.91 17.21 17.21 0 0 0 10.944 3.914c3.901 0 7.685-1.317 10.718-3.73a16.697 16.697 0 0 0 5.94-9.53l6.599-30.532H13.725Z"
        />
        <path
          fill="#0A3451"
          d="M36.433 55.753c12.542 0 22.709-2.955 22.709-6.6 0-3.644-10.167-6.599-22.709-6.599-12.541 0-22.708 2.955-22.708 6.6 0 3.644 10.167 6.599 22.708 6.599ZM44.513 66.087H28.62c-1.506 0-2.726 1.2-2.726 2.68v7.558c0 1.48 1.22 2.68 2.726 2.68h15.894c1.506 0 2.727-1.2 2.727-2.68v-7.558c0-1.48-1.221-2.68-2.727-2.68Z"
        />
        <path
          fill="#1B66D6"
          d="M34.844 72.787h-.945c0-1.224.564-2.91 3.251-3.995 1.574-.635 1.053-2.984 1.047-3.007l.921-.208c.03.128.708 3.14-1.609 4.074-1.769.714-2.665 1.77-2.665 3.136ZM43.322 79.548c-.92-1.694-1.303-1.66-2.92-1.52-.526.047-1.181.104-1.98.104-3.58 0-3.953-2.56-3.956-2.587l.937-.117-.469.059.469-.063c.01.073.297 1.78 3.018 1.78.758 0 1.363-.054 1.897-.1 1.82-.16 2.673-.14 3.837 2.007l-.833.437Z"
        />
        <path
          fill="#fff"
          d="M32.399 73.263a.205.205 0 0 1-.093-.022l-1.021-.518a.2.2 0 0 1-.1-.116.195.195 0 0 1 .013-.152.201.201 0 0 1 .118-.098.206.206 0 0 1 .155.013l1.021.517a.2.2 0 0 1 .104.224.2.2 0 0 1-.072.11.205.205 0 0 1-.125.042ZM31.165 75.233a.205.205 0 0 1-.13-.047.198.198 0 0 1-.046-.25.201.201 0 0 1 .104-.088l1.074-.402a.206.206 0 0 1 .15.01.2.2 0 0 1 .102.108c.019.048.018.101-.002.148a.2.2 0 0 1-.106.106l-1.074.402a.205.205 0 0 1-.072.013ZM37.625 74.415c-.051 0-.1-.02-.137-.053a.198.198 0 0 1 .103-.343l1.134-.188a.205.205 0 0 1 .15.035.198.198 0 0 1 .048.276.203.203 0 0 1-.13.082l-1.134.188a.216.216 0 0 1-.034.003ZM36.795 73.066a.205.205 0 0 1-.177-.103.197.197 0 0 1 .006-.202l.613-.955a.2.2 0 0 1 .127-.091.205.205 0 0 1 .213.08.198.198 0 0 1 .003.223l-.613.956a.201.201 0 0 1-.172.092Z"
        />
        <path
          fill="#1B66D6"
          d="M48.354 72.092c-1.966 0-3.606-.528-4.888-1.576-2.368-1.936-2.622-4.924-2.632-5.05l.942-.07-.47.035.47-.035c.002.026.24 2.733 2.301 4.412 1.35 1.1 3.19 1.533 5.474 1.289l.102.923c-.431.047-.865.071-1.299.072ZM23.718 77.864l-.174-.913c3.622-.67 3.62-2.15 3.616-3.866-.002-1.155-.004-2.464 1.38-3.23 2.576-1.424 2.37-4.651 2.367-4.684l.942-.07c.012.157.26 3.846-2.845 5.564-.858.474-.9 1.228-.899 2.418.004 1.769.008 3.97-4.387 4.781Z"
        />
        <path
          fill="#fff"
          d="M51.643 51.213a.272.272 0 0 1-.054-.006c-1.023-.202-2.952-1.061-2.935-2.868a2.168 2.168 0 0 1 .25-1.018 2.21 2.21 0 0 1 .7-.789 2.547 2.547 0 0 1-.251-1.796c.386-1.976 2.41-2.322 3.426-2.229.071.007.137.041.182.095a.263.263 0 0 1-.035.373.273.273 0 0 1-.197.06c-.1-.008-2.456-.193-2.846 1.8-.115.482-.05.988.184 1.426 1.002-.476 2.375-.478 3.005-.005a.76.76 0 0 1 .328.783c-.126.646-.898.969-1.875.785a2.93 2.93 0 0 1-1.605-.861c-.226.155-.41.361-.537.602-.127.24-.191.508-.19.78-.017 1.817 2.478 2.337 2.503 2.342a.27.27 0 0 1 .164.103.262.262 0 0 1-.036.354.273.273 0 0 1-.181.069Zm-1.236-4.521c.34.312.764.523 1.221.61.644.121 1.179-.035 1.243-.363a.242.242 0 0 0-.126-.261c-.408-.307-1.47-.356-2.338.014ZM28.566 49.22a.273.273 0 0 1-.256-.182.26.26 0 0 1 .094-.296c.058-.043 1.418-1.073.645-2.323a1.516 1.516 0 0 0-.74-.65c-.337.756-1.1 1.42-1.699 1.469a.665.665 0 0 1-.647-.305.79.79 0 0 1 .017-.827c.301-.54 1.05-.944 1.78-.96.06-.002.121 0 .18.004a1.162 1.162 0 0 0-.298-1.016c-.888-.982-2.491-.061-2.507-.052a.273.273 0 0 1-.37-.092.262.262 0 0 1 .093-.364c.081-.048 2.007-1.151 3.188.156a1.68 1.68 0 0 1 .424 1.478c.439.169.806.48 1.04.882.824 1.331-.151 2.56-.782 3.023a.271.271 0 0 1-.162.054Zm-.81-3.542c-.587.018-1.122.36-1.303.684a.287.287 0 0 0-.029.296c.014.022.038.059.141.051.339-.027.894-.47 1.191-1.031Z"
        />
        <path
          fill="#1B66D6"
          d="M58.13 27.124c1.382 9.606-7.79 15.597-19.923 17.283-12.134 1.687-22.635-1.569-24.017-11.176-1.382-9.607 6.878-21.928 19.012-23.615 12.134-1.686 23.546 7.9 24.928 17.508Z"
        />
        <path
          fill="#fff"
          d="m46.49 16.85-12.497 1.737c-3.917.545-6.644 4.107-6.09 7.958.554 3.85 4.179 6.53 8.096 5.986l12.497-1.737c3.917-.545 6.644-4.107 6.09-7.958-.554-3.85-4.179-6.53-8.096-5.985Z"
        />
        <path
          fill="#1B66D6"
          d="m32.127 2.182-.969.182 1.872 9.598.969-.183-1.872-9.597Z"
        />
        <path
          fill="#0A3451"
          d="M31.781 4.755c.996 0 1.803-.793 1.803-1.772s-.807-1.772-1.803-1.772c-.996 0-1.803.793-1.803 1.772s.807 1.772 1.803 1.772ZM38.55 28.153a.816.816 0 0 1-.486-.16l-5.307-3.923a.803.803 0 0 1-.322-.526.781.781 0 0 1 .154-.595.804.804 0 0 1 .538-.31.823.823 0 0 1 .604.158l5.306 3.924a.783.783 0 0 1-.013 1.283.82.82 0 0 1-.474.15v-.001Z"
        />
        <path
          fill="#0A3451"
          d="M33.9 28.799a.82.82 0 0 1-.724-.441.784.784 0 0 1 .078-.834l3.992-5.216a.82.82 0 0 1 1.13-.152.786.786 0 0 1 .164 1.11L34.55 28.48a.805.805 0 0 1-.648.318ZM50.074 26.55a.816.816 0 0 1-.486-.16l-5.307-3.923a.8.8 0 0 1-.318-.525.782.782 0 0 1 .154-.592.807.807 0 0 1 .536-.31.823.823 0 0 1 .602.155l5.306 3.923a.783.783 0 0 1-.013 1.283.82.82 0 0 1-.474.15Z"
        />
        <path
          fill="#0A3451"
          d="M45.425 27.197a.82.82 0 0 1-.725-.44.784.784 0 0 1 .078-.835l3.992-5.216a.82.82 0 0 1 1.134-.158.786.786 0 0 1 .16 1.115l-3.992 5.217a.805.805 0 0 1-.647.317ZM42.366 34.708c.244-.317.574-.56.953-.7a2.169 2.169 0 0 1 2.236.46c.29.276.495.628.589 1.015a.525.525 0 0 1-.208.56.55.55 0 0 1-.761-.12.53.53 0 0 1-.088-.19 1.02 1.02 0 0 0-.47-.638 1.06 1.06 0 0 0-1.44.34 1.018 1.018 0 0 0-.126.777.526.526 0 0 1-.067.402.552.552 0 0 1-.745.175.534.534 0 0 1-.245-.328 2.05 2.05 0 0 1 .257-1.585c.035-.058.074-.114.115-.168Z"
        />
      </g>
      <defs>
        <clipPath id="errorsvg">
          <path fill="#fff" d="M0 0h81v94H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
