import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import HumanChat from '@/components/atoms/Icons/Navigation/HumanChat';
import Loading from '@/components/atoms/Loading/Loading';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useDesks from '@/hooks/useDesks';
import { PageName } from '@/models/segment';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import ChatsHeader from './ChatsHeader';
import CreateDeskTile from '../Desks/CreateDeskTile';

const Chats: React.FC = () => {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const { serviceDesks, desks, listStatus } = useDesks();
  const deskId = useSelector(selectDeskId);
  useTitle(t('pages.chats', { 0: slug }));
  const { slug: urlSlug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    pageView(PageName.CHATS);
  }, []);

  const handleVisitSettings = useCallback(() => {
    navigate(`/${slug}/environments/${deskId}/settings`);
  }, [deskId, navigate, slug]);

  if (slug !== urlSlug || listStatus !== 'success') {
    return null;
  }

  if (desks && desks.length === 0) {
    return (
      <>
        <ChatsHeader />
        <PageContentWrapper newPlain>
          <CreateDeskTile />
        </PageContentWrapper>
      </>
    );
  }

  // If deskId in session is a service desk , send user there , otherwise redirect to first one
  if (serviceDesks?.length > 0) {
    const isDeskService =
      serviceDesks?.filter((desk) => desk.desk_id === deskId).length > 0;
    return (
      <Navigate
        replace
        to={`/${slug}/chats/${
          isDeskService ? deskId : serviceDesks[0]?.desk_id
        }`}
      />
    );
  }

  return (
    <>
      {!serviceDesks && <Loading />}
      {serviceDesks?.length === 0 && (
        <>
          <ChatsHeader />
          <PageContentWrapper newPlain>
            <TileEmptyPage
              title={t('chats.no_human_tile.title')}
              notClickable
              body={
                <>
                  {t('chats.no_human_tile.text')}
                  <br />
                  <Button
                    onClick={handleVisitSettings}
                    variant="tertiary"
                    underline
                  >
                    {t('chats.no_human_tile.button')}
                  </Button>
                </>
              }
              icon={<HumanChat color="var(--icon-default-blue)" extraLarge />}
            />
          </PageContentWrapper>
        </>
      )}
    </>
  );
};

export default Chats;
