import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import { getIntegrationByType } from '@/components/organisms/Integrations';
import Tile from '@/components/organisms/Tile/Tile';
import { Integration } from '@/models/integration';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';

import styles from './IntegrationTile.module.scss';

interface Props {
  integration: Integration;
}
const IntegrationTile = ({ integration }: Props) => {
  const { type, name, active, integration_id } = integration ?? {};
  const config = getIntegrationByType(type);
  const Icon = config?.Icon;
  const { t } = useTranslation();
  const lastActive = integration?.last_session_at;
  const slug = useSelector(selectAccountSlug);
  const deskId = useSelector(selectDeskId);

  const link = `/${slug}/environments/${deskId}/integrations/${integration_id}`;
  return (
    <Link aria-label={name} className={styles.link} to={link}>
      <Tile className={styles.tile}>
        <div className={styles.wrapper}>
          {Icon && <Icon />}
          <div className={styles.content}>
            <div className={styles.info}>
              <Typography variant="body-semi-bold">
                {t(`channels.${type}`)}
              </Typography>
              <Typography variant="label-regular" className={styles.lastUsed}>
                {t('common.last_used')}:{' '}
                {lastActive ? moment(lastActive).fromNow() : '-'}
              </Typography>
            </div>
            <div className={styles.status}>
              {integration.status === 'verified' ? (
                <StatusBadge
                  withIcon={false}
                  variant={active ? 'success' : 'neutral'}
                  label={active ? t('rules.active') : t('rules.inactive')}
                />
              ) : (
                <Typography className={styles.setup}>
                  {t('home.finish_setup')}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </Tile>
    </Link>
  );
};

export default IntegrationTile;
