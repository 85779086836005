import { Icon } from '@/models/icon';

const Add = ({ size = 16, color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 3v10M3 8h10"
      />
    </svg>
  );
};

export default Add;
