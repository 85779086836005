import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import useMembers from '@/hooks/useMembers';

import styles from './LanguageModelTile.module.scss';
type LastUsedProps = {
  lastUsed: string | null;
  createdBy?: never;
};

type CreatedByProps = {
  lastUsed?: never;
  createdBy: string;
};

type Props = LastUsedProps | CreatedByProps;

const Wrapper = ({ children }) => {
  return <div className={styles.infoWrapper}>{children}</div>;
};

export const Info = ({ lastUsed, createdBy }: Props) => {
  const { members } = useMembers();
  let member;

  if (createdBy) {
    member = members.find((member) => member.user_id === createdBy);
  }

  const { t } = useTranslation();
  return (
    <>
      {lastUsed !== undefined ? (
        <Wrapper>
          <Typography
            variant="label-caps-small"
            color="var(--text-default-gray)"
          >
            {t('common.last_used')}
          </Typography>
          <Typography variant="label-regular" color="var(--icon-default-gray)">
            {lastUsed ? moment(lastUsed).fromNow() : '-'}
          </Typography>
        </Wrapper>
      ) : (
        <Wrapper>
          <Typography
            variant="label-caps-small"
            color="var(--text-default-gray)"
          >
            {t('common.created_by')}
          </Typography>
          <span className={styles.value}>
            <Avatar userId={createdBy} hideStatus size="small" />
            <Typography
              variant="label-regular"
              color="var(--icon-default-gray)"
            >
              {member?.name || '-'}
            </Typography>
          </span>
        </Wrapper>
      )}
    </>
  );
};
