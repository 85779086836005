import { useCallback, useEffect, useMemo, useState } from 'react';

import { Tabs } from '@mui/base/Tabs';
import isEmpty from 'lodash/isEmpty';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldValues,
  Path,
  UseFormTrigger,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Node } from 'slate';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Add from '@/components/atoms/Icons/Add';
import Close from '@/components/atoms/Icons/Close';
import Label from '@/components/atoms/Label/Label';
import OptionsPopper, {
  OptionsPopperProps,
} from '@/components/organisms/RichTextEditor/OptionsPopper/OptionsPopper';
import RichTextEditor from '@/components/organisms/RichTextEditor/RichTextEditor';
import { useCommandPallete } from '@/hooks/useCommandPallete';
import useDialogs from '@/hooks/useDialogs';
import { useRichTextEditor } from '@/hooks/useRichTextEditor';
import { selectBrainId } from '@/redux/session/selectors';
import { charCountValidation, requiredValidation } from '@/util/validator';

import { StyledTab, TabPanel, TabsList } from './components';
import CommandPalette from '../CommandPalette/CommandPalette';
import { addStringToEditor, focusEditorEnd } from '../RichTextEditor/utils';

interface Props<T extends FieldValues> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tabConfig: Record<string, any>[];
  maxTabs?: number;
  control?: Control<T>;
  append?: (value) => void;
  remove?: (index: number) => void;
  handleTabBlur?: (index?: number) => void;
  newTabProps?: Partial<FieldValues>;
  label?: string;
  canAddDeleteTabs?: boolean;
  errors?: FieldErrors<T>;
  controllerName?: string;
  inputName?: string;
  trigger?: UseFormTrigger<T>;
  handleTabOnChange?: (index: number) => void;
  shouldValidateForRequired?: boolean;
  shouldValidateForChars?: boolean;
}

export default function RichTextEditorTabs<FieldValues>({
  tabConfig,
  maxTabs = 4,
  control,
  controllerName,
  append,
  remove,
  handleTabBlur,
  newTabProps = {},
  label,
  canAddDeleteTabs = true,
  errors = {},
  inputName,
  trigger,
  handleTabOnChange,
  shouldValidateForRequired = true,
  shouldValidateForChars = true,
}: Props<FieldValues>) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const handleChange = useCallback(
    (_e, newValue: number) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  // It sets the tab to the tab with the error if there is one
  const changeToTab = errors[controllerName]?.length
    ? errors[controllerName].length - 1
    : tabValue;

  const { editors, triggerKey, addEditor, removeEditor } = useRichTextEditor();
  const brainId = useSelector(selectBrainId);
  const { getContextVariables } = useDialogs(brainId);
  const variablesOptions = useMemo(
    () => getContextVariables(),
    [getContextVariables]
  );
  const {
    searchResults,
    setSearchResults,
    searchString,
    setSearchString,
    noResultSearchLength,
    setNoResultSearchLength,
    lastSearchStringLength,
    setLastSearchStringLength,
  } = useCommandPallete({ options: variablesOptions });

  useEffect(() => {
    if (editors?.length > 0 && tabConfig[0].textValue?.length) {
      focusEditorEnd(editors[tabValue]);
    }
  }, [editors, tabConfig, tabConfig.length, tabValue]);

  const handleCloseTab = useCallback(
    (e, index: number) => {
      e.preventDefault();

      if (index === tabValue) {
        setTabValue(index - 1);
      }

      removeEditor(index);
      remove(index);

      if (handleTabBlur) {
        handleTabBlur(index);
      }
    },
    [handleTabBlur, remove, removeEditor, tabValue]
  );

  const handleAddTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the add-tab button from stealing focus
    e.currentTarget.blur();

    if (append) {
      append(newTabProps || []);
      addEditor();
      setTabValue(tabConfig.length);
    }
  };

  const handleAddStringToEditor = useCallback(
    (optionsIndex: number, editorsIndex: number) => {
      addStringToEditor(
        editors[editorsIndex],
        searchResults[optionsIndex]?.value
          ? `{{${searchResults[optionsIndex].value}}}`
          : '',
        triggerKey + searchString
      );
    },
    [editors, searchResults, searchString, triggerKey]
  );

  const handleOnBlur = useCallback(
    (index) => () => {
      if (handleTabBlur) {
        handleTabBlur(index);
      }
    },
    [handleTabBlur]
  );

  // It sets the tab to the tab with the error
  useEffect(() => {
    setTabValue(changeToTab);
  }, [changeToTab, trigger]);

  const handleOnChange = useCallback(
    (index) => {
      if (handleTabOnChange) {
        handleTabOnChange(index);
      }
    },
    [handleTabOnChange]
  );

  return (
    <>
      {label && <Label label={label} htmlFor="rich-text-editor" />}

      <Tabs value={tabValue} onChange={handleChange}>
        <TabsList>
          {tabConfig?.map(({ id, tabName }, index) => {
            return (
              <StyledTab
                key={id}
                hasError={!isEmpty(errors[controllerName]?.[index])}
                disabled={!isEmpty(errors[controllerName])}
              >
                <span className="tab_name">{tabName}</span>
                {index === tabConfig.length - 1 &&
                  canAddDeleteTabs &&
                  index !== 0 && (
                    <span
                      className="close_icon"
                      onMouseUp={(e) => handleCloseTab(e, index)}
                      tabIndex={-1}
                      role="button"
                    >
                      <Close size={10} />
                    </span>
                  )}
              </StyledTab>
            );
          })}
          {tabConfig.length < 4 &&
            tabConfig.length < maxTabs &&
            canAddDeleteTabs &&
            isEmpty(errors) && (
              <span className="add_icon">
                <IconButton
                  onClick={handleAddTab}
                  size="small"
                  ariaLabel="add-tab"
                  tooltip={t('rich_text_editor.add_variant')}
                >
                  <Add size={14} />
                </IconButton>
              </span>
            )}
        </TabsList>
        {tabConfig?.map((tab, index) => (
          <TabPanel key={tab.id} value={index} active={index === tabValue}>
            <Controller
              control={control}
              name={
                `${controllerName}.${index}.${inputName}` as Path<FieldValues>
              }
              rules={{
                validate: {
                  requiredValidation: (v) =>
                    requiredValidation(v, t, shouldValidateForRequired),
                  charsValidation: (v) =>
                    charCountValidation(
                      v,
                      tab.maxCharacters,
                      t,
                      shouldValidateForChars
                    ),
                },
              }}
              render={({ field }) => {
                return (
                  field.value && (
                    <CommandPalette<OptionsPopperProps>
                      triggerKey={triggerKey}
                      searchString={searchString}
                      setSearchString={setSearchString}
                      renderPalette={(props) => {
                        return (
                          <OptionsPopper
                            {...props}
                            options={variablesOptions}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            noResultSearchLength={noResultSearchLength}
                            setNoResultSearchLength={setNoResultSearchLength}
                            lastSearchStringLength={lastSearchStringLength}
                            setLastSearchStringLength={
                              setLastSearchStringLength
                            }
                          />
                        );
                      }}
                      optionsLength={searchResults.length}
                      handleOnSelectFromProps={(optionsIndex) =>
                        handleAddStringToEditor(optionsIndex, index)
                      }
                      renderItem={(props) => {
                        return (
                          <RichTextEditor
                            {...props}
                            trigger={trigger}
                            editor={editors[index]}
                            error={
                              errors[controllerName]?.[index]?.[
                                inputName
                              ] as FieldError
                            }
                            onBlur={handleOnBlur(index)}
                            left={index === 0}
                            right={
                              tabConfig.length === 4
                                ? index === tabConfig.length - 1
                                : null
                            }
                            index={index}
                            maxCharacters={tab.maxCharacters}
                            size="medium"
                            onChange={(value) => {
                              // Prevents the onChange from firing when the value is the same
                              if (value !== field.value) {
                                field.onChange(value);
                                handleOnChange(index);
                              }
                            }}
                            options={variablesOptions}
                            value={field.value as Node[]}
                          />
                        );
                      }}
                    />
                  )
                );
              }}
            />
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
}
