import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange, getDateRangeText } from '@/util/analytics';
import { parseFilter } from '@/util/util';

import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

interface Row {
  average_time: number;
  median_time: number;
  max_time: number;
  min_time: number;
}

interface Rows {
  rows: Row[];
}

interface Usage {
  counts: number | string;
  growth: number;
  id: string;
  timeframe: string;
}

interface ResponseTimeHook {
  isLoading: boolean;
  isPreviousLoading?: boolean;
  currentError?: ApolloError;
  previousError?: ApolloError;
  refetchCurrent?: () => void;
  refetchPrevious?: () => void;
  data?: Usage;
}

export type ResponseType =
  | 'median_time'
  | 'average_time'
  | 'min_time'
  | 'max_time';

export type ResponseTimesProps = {
  intervalProps?: {
    value?: string;
    startDate?: string;
    endDate?: string;
    intervalInDays?: '1 day';
    is_contained?: boolean;
    is_covered?: boolean;
    firstMessage?: boolean;
    events: '{message:send}' | '{message:brain_send}';
  };
  skip?: boolean;
  skipPrevious?: boolean;
  type: ResponseType;
};

const formatResponseTime = (
  current?: Row[],
  previous?: Row[]
): Omit<Usage, 'timeframe'>[] | undefined => {
  if (!current || current.length === 0) {
    return undefined;
  }

  const result = Object.keys(current[0]).reduce(
    (acc, key) => {
      if (key === '__typename' || key === 'num_responses') {
        return acc;
      }
      let growth = 0;
      if (previous && previous.length !== 0 && previous[0][key] > 0) {
        growth = Math.round(
          ((current[0][key] - previous[0][key]) / previous[0][key]) * 100
        );
      }
      acc.push({
        id: key,
        counts: current[0][key],
        growth: previous ? growth : undefined,
      });
      return acc;
    },
    [] as Omit<Usage, 'timeframe'>[]
  );

  return result;
};

export const RESPONSE_TIMES = gql`
  query ResponseTimes(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $events: _text
    $firstMessage: Boolean
    $isTest: Boolean
    $agentIds: _text
    $minNumUserMessages: Int
  ) {
    rows: response_times(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        events: $events
        first_message: $firstMessage
        is_test: $isTest
        agent_ids: $agentIds
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      average_time
      median_time
      max_time
      min_time
      num_responses
    }
  }
`;

const useResponseTimes = ({
  intervalProps,
  skip,
  skipPrevious,
  type,
}: ResponseTimesProps): ResponseTimeHook => {
  const accountId = useSelector(selectAccountId);
  const filters = useSelector(selectFilters);
  const { firstMessage, events } = intervalProps || {};

  const location = useLocation();

  const periodInDays = moment(filters.endDate).diff(
    moment(filters.startDate),
    'days'
  );

  const previousStartDate = filters.startDate
    ? moment(filters.startDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;
  const previousEndDate = filters.endDate
    ? moment(filters.endDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;

  const variables = useMemo(
    () =>
      Object.assign(
        {},
        {
          ...getAnalyticsRange(filters.startDate, filters?.endDate),
          accountId,
          firstMessage,
          events,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      events,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      firstMessage,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error: currentError,
    refetch: refetchCurrent,
  } = useQuery<Rows, QueryVariables>(RESPONSE_TIMES, {
    variables,
    skip: skip || !accountId || !filters.filtersLoaded,
  });

  const {
    loading: previousLoading,
    data: previousData,
    error: previousError,
    refetch: refetchPrevious,
  } = useQuery<Rows, QueryVariables>(RESPONSE_TIMES, {
    variables: {
      ...variables,
      ...getAnalyticsRange(previousStartDate, previousEndDate),
    },
    skip: skipPrevious || !accountId || !filters.filtersLoaded,
  });
  const currentRows = currentData?.rows;
  const previousRows = previousData?.rows;

  const data = useMemo(() => {
    const formatedData = formatResponseTime(currentRows, previousRows);
    const result = formatedData
      ?.filter((item) => item.id === type)
      .map((item) => ({
        ...item,
        id: firstMessage ? `first_${item.id}` : item.id,
        timeframe: getDateRangeText(filters.startDate, filters.endDate),
      }))[0];
    return result;
  }, [
    currentRows,
    filters.endDate,
    filters.startDate,
    firstMessage,
    previousRows,
    type,
  ]);

  const onRefetchCurrent = useCallback(() => {
    refetchCurrent(variables);
  }, [refetchCurrent, variables]);

  const onRefetchPrevious = useCallback(() => {
    refetchPrevious({
      ...variables,
      ...getAnalyticsRange(previousStartDate, previousEndDate),
    });
  }, [previousEndDate, previousStartDate, refetchPrevious, variables]);

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (previousLoading && !skipPrevious) {
    return {
      data,
      isLoading: false,
      isPreviousLoading: true,
    };
  }

  if (currentError || previousError) {
    return {
      isLoading: false,
      currentError,
      previousError,
      refetchCurrent: onRefetchCurrent,
      refetchPrevious: onRefetchPrevious,
    };
  }

  return { isLoading: false, data };
};

export default useResponseTimes;
