import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

const useToolkitControls = () => {
  const container = useRef(null);
  const [currentZoom, setCurrentZoom] = useState(1);

  const zoomPercentage = useMemo(
    () => `${Math.round(currentZoom * 100)}%`,
    [currentZoom]
  );

  // Modifies the scale and transform origin of the container based on the current zoom level.
  const modifyScale = useCallback(() => {
    if (!container?.current?.style) {
      return;
    }

    container.current.style.transform = `scale(${currentZoom})`;
    if (currentZoom > 1) {
      container.current.style['transform-origin'] = '0 0';
    } else {
      container.current.style['transform-origin'] = 'center center';
    }
  }, [currentZoom]);

  // Zoom controls
  const onZoomInClick = useCallback(() => {
    setCurrentZoom((curr) => Math.min(1.5, curr + 0.1));
  }, []);

  const onZoomOutClick = useCallback(() => {
    setCurrentZoom((curr) => Math.max(0.5, curr - 0.1));
  }, []);

  const onZoomResetClick = useCallback(() => {
    setCurrentZoom(1);
  }, []);

  // DOM modification should be done in useLayoutEffect
  useLayoutEffect(() => {
    modifyScale();

    const currentContainer = container.current;

    if (!currentContainer) {
      return;
    }

    return () => {
      currentContainer.style.transform = 'none';
      currentContainer.style['transform-origin'] = 'none';
    };
  }, [currentZoom, modifyScale]);

  return {
    container,
    zoomPercentage,
    onZoomInClick,
    onZoomOutClick,
    onZoomResetClick,
  };
};

export default useToolkitControls;
