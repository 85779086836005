import { Icon } from '@/models/icon';

const MoveoSupport = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.32679 4.44382C7.37823 4.44382 6.60239 3.66752 6.60239 2.7014C6.60239 1.73528 7.37823 0.958985 8.32679 0.958985L12.7756 0.958984C13.7242 0.958984 14.5 1.73528 14.5 2.7014C14.5 3.66752 13.7242 4.44382 12.7756 4.44382L8.32679 4.44382ZM5.74767 10.3098C5.74767 11.7767 4.56915 12.959 3.12384 12.959C1.67853 12.959 0.5 11.7767 0.5 10.3098C0.5 8.84296 1.67853 7.66071 3.12384 7.66071C4.56915 7.66071 5.74767 8.84296 5.74767 10.3098ZM3.14448 2.64577C3.14448 3.38798 2.5487 3.98276 1.82224 3.98276C1.09578 3.98276 0.5 3.38798 0.5 2.64577C0.5 1.90356 1.09578 1.30877 1.82224 1.30877C2.5487 1.30877 3.14448 1.90356 3.14448 2.64577ZM11.8501 10.2224C11.8501 10.9647 11.2543 11.5594 10.5279 11.5594C9.80142 11.5594 9.20564 10.9647 9.20564 10.2224C9.20564 9.48024 9.80142 8.88545 10.5279 8.88545C11.2543 8.88545 11.8501 9.48024 11.8501 10.2224Z"
        stroke={color}
      />
    </svg>
  );
};
export default MoveoSupport;
