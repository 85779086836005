import { useEffect, useMemo } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PageName } from '@/models/segment';
import { pageView } from '@/segment/segment';

import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

const getDateRange = (startDate: string, endDate: string) => {
  let range: string | undefined;
  if (startDate === moment().format('YYYY-MM-DD')) {
    range = 'today';
  } else if (
    startDate === endDate &&
    startDate === moment().subtract(1, 'day').format('YYYY-MM-DD')
  ) {
    range = 'yesterday';
  } else if (
    startDate === moment().subtract(6, 'day').format('YYYY-MM-DD') &&
    endDate === moment().format('YYYY-MM-DD')
  ) {
    range = 'last week';
  } else if (
    startDate === moment().subtract(13, 'day').format('YYYY-MM-DD') &&
    endDate === moment().format('YYYY-MM-DD')
  ) {
    range = 'last 14 days';
  } else if (
    startDate === moment().subtract(29, 'day').format('YYYY-MM-DD') &&
    endDate === moment().format('YYYY-MM-DD')
  ) {
    range = 'last month';
  } else {
    range = 'custom';
  }
  return range;
};

const useSegments = (pageName: PageName) => {
  const filters = useSelector(selectFilters);
  const { t } = useTranslation();

  const variables = useMemo(() => {
    return Object.assign(
      {},
      {
        dateRange: getDateRange(filters.startDate, filters?.endDate),
      },
      [
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter?.type, location?.pathname, t)
          )
          .map((filter) => filter.type),
      ]
    );
  }, [filters?.startDate, filters?.endDate, filters?.analytics, t]);

  useEffect(() => {
    pageView(pageName, { filters: variables });
  }, [pageName, variables]);
};

export default useSegments;
