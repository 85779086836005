import { Props } from '@/models/icon';

export const DragAndDropIcon = ({
  size = 24,
  color = 'var(--icon-default-gray)',
  ...props
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke={color}
        strokeDasharray="2 2"
        strokeWidth="1.5"
        d="M15.85 16.8V4.987a.2.2 0 0 0-.2-.2H3.7a.2.2 0 0 0-.2.2V16.8c0 .11.09.2.2.2h11.95a.2.2 0 0 0 .2-.2Z"
      />
      <path
        fill="#fff"
        stroke={color}
        strokeWidth="1.5"
        d="M20.524 21.3V9.487a.2.2 0 0 0-.2-.2H8.374a.2.2 0 0 0-.2.2V21.3c0 .11.09.2.2.2h11.95a.2.2 0 0 0 .2-.2Z"
      />
      <path
        fill="#fff"
        stroke={color}
        strokeWidth="1.5"
        d="m21.237 4.148 1.62 1.543a.2.2 0 0 1 .01.28l-3.119 3.375a.1.1 0 0 0 0 .136l1.794 1.95a.2.2 0 0 1-.1.33l-7.838 1.844a.2.2 0 0 1-.24-.243l2.015-7.974a.2.2 0 0 1 .332-.095l2.086 2.007a.1.1 0 0 0 .142-.003l3.016-3.143a.2.2 0 0 1 .282-.007Z"
      />
    </svg>
  );
};
