import { Icon } from '@/models/icon';

const Intent = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width={size}
      height={size}
    >
      <path
        fill={color}
        stroke={color}
        strokeWidth=".2"
        d="M6.85 13.955a6.068 6.068 0 0 1-3.145-1.67 6.068 6.068 0 0 1-1.672-3.146h.758a5.323 5.323 0 0 0 1.439 2.62 5.334 5.334 0 0 0 2.62 1.439v.757Zm5.408-5.584a.37.37 0 1 1 0-.742h2.87a.37.37 0 1 1 0 .742h-2.87ZM8.372 3.743a.37.37 0 1 1-.743 0V.871a.37.37 0 1 1 .743 0v2.872Zm0 11.386a.37.37 0 1 1-.743 0v-2.872c0-.489.743-.489.743 0v2.872ZM.872 8.37a.37.37 0 1 1 0-.742h2.87a.37.37 0 1 1 0 .742H.872Zm1.161-1.533a6.067 6.067 0 0 1 1.67-3.145 6.06 6.06 0 0 1 3.146-1.67v.758A5.324 5.324 0 0 0 4.23 4.22 5.308 5.308 0 0 0 2.79 6.838h-.758ZM9.151 2.02a6.06 6.06 0 0 1 3.146 1.671 6.057 6.057 0 0 1 1.67 3.146h-.758a5.318 5.318 0 0 0-1.439-2.621 5.314 5.314 0 0 0-2.62-1.438v-.758Zm4.817 7.118a6.064 6.064 0 0 1-1.671 3.146 6.059 6.059 0 0 1-3.146 1.67v-.757a5.343 5.343 0 0 0 4.059-4.06h.758Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M8 6.5a1.5 1.5 0 1 1 0 3.001A1.5 1.5 0 0 1 8 6.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Intent;
