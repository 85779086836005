import { Icon } from '@/models/icon';

const Condition = ({ size = 24, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50025 7.32763H5.05025C5.02264 7.32763 5.00025 7.35001 5.00025 7.37763V15.5M18.5003 7.32763H18.9503C18.9779 7.32763 19.0003 7.35001 19.0003 7.37763V15.5M7.54318 7.67849L11.6146 10.7087C11.8432 10.8788 12.1574 10.8788 12.386 10.7087L16.4574 7.67849C16.8003 7.42332 16.8003 6.91297 16.4574 6.65779L12.386 3.62759C12.1574 3.45747 11.8432 3.45747 11.6146 3.62759L7.54318 6.65779C7.20033 6.91297 7.20033 7.42332 7.54318 7.67849ZM3.04318 21.5H6.90031C7.25534 21.5 7.54316 21.2144 7.54316 20.8621V18.3103C7.54316 17.958 7.25534 17.6724 6.90031 17.6724H3.04318C2.68814 17.6724 2.40033 17.958 2.40033 18.3103V20.8621C2.40033 21.2144 2.68814 21.5 3.04318 21.5ZM17.1431 21.5H21.0003C21.3553 21.5 21.6431 21.2144 21.6431 20.8621V18.3103C21.6431 17.958 21.3553 17.6724 21.0003 17.6724H17.1431C16.7881 17.6724 16.5003 17.958 16.5003 18.3103V20.8621C16.5003 21.2144 16.7881 21.5 17.1431 21.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default Condition;
