import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Routes, Route, useParams } from 'react-router';

import Layout from '@/components/templates/Layout/Layout';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import Rules from '@/modules/rules/pages/Rules/Rules';
import { setDeskId } from '@/redux/session/actions';

import Rule from '../Rule/Rule';

const RulesRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { deskId } = useParams();

  useEffect(() => {
    if (deskId) {
      dispatch(setDeskId(deskId));
    }
  }, [dispatch, deskId]);

  return (
    <Layout>
      <PermissionsWrapper module="rules">
        <Routes>
          <Route path="/:ruleId" element={<Rule />} />
          <Route path="/" element={<Rules />} />
        </Routes>
      </PermissionsWrapper>
    </Layout>
  );
};
RulesRouter.displayName = 'RulesRouter';

export default RulesRouter;
