import { useCallback, useState } from 'react';

import Tooltip from '@mui/material/Tooltip/Tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Select from '@/components/atoms/Select/Select';
import Table from '@/components/atoms/Table/Table';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { durationFormat, numberFormatter } from '@/util/util';

import { MAX_RATING } from '../../../constants';
import useBrainsPerformance, {
  ResponseType,
} from '../../../hooks/useBrainsPerformance';
import { sortColumns } from '../MostUsed/MostUsedDesksFieldset';

import styles from '../RatingCount/RatingCount.module.scss';

export const getOptions = (t) => [
  {
    label: t('analytics.average'),
    value: 'avg_response_time',
  },
  {
    label: t('analytics.median'),
    value: 'median_response_time',
  },
  {
    label: t('analytics.minimum'),
    value: 'min_response_time',
  },
  {
    label: t('analytics.maximum'),
    value: 'max_response_time',
  },
];

const getColumns = (t, filter) => {
  const { label, value } = getOptions(t).find(({ value }) => value === filter);
  return [
    {
      Header: t('common.name'),
      accessor: 'name',
    },
    {
      Header: `${label} ${t('analytics.response_time')}`,
      accessor: value,
      sortType: sortColumns,
      Cell: ({ value }) => durationFormat(t, value, t('common.na')),
    },
    {
      Header: t('analytics.charts_tooltip.percent_contain'),
      accessor: 'containment',
      Cell: ({ value }) => `${value}%`,
    },
    {
      Header: t('analytics.charts_tooltip.percent_cover'),
      accessor: 'coverage',
      Cell: ({ value }) => `${value}%`,
    },
    {
      Header: t('analytics.avg_rating'),
      accessor: 'avg_rating',
      Cell: ({ value }) =>
        typeof value === 'number'
          ? `${parseFloat(value.toFixed(1))}/${MAX_RATING}`
          : value,
    },
    {
      Header: t('analytics.more_than3'),
      accessor: 'ratings_more_than_3',
      Cell: ({ value }) => `${value}%`,
    },
    {
      Header: t('analytics.total_ratings'),
      accessor: 'ratings',
      Cell: ({ value }) => {
        const formattedValue = numberFormatter(value);
        return (
          <Tooltip arrow title={formattedValue !== value ? value : ''}>
            <span>{formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      Header: t('analytics.total_messages'),
      accessor: 'replies',
      Cell: ({ value }) => {
        const formattedValue = numberFormatter(value);
        return (
          <Tooltip arrow title={formattedValue !== value ? value : ''}>
            <span>{formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      Header: t('analytics.participated'),
      accessor: 'participated_sessions',
      Cell: ({ value }) => {
        const formattedValue = numberFormatter(value);
        return (
          <Tooltip arrow title={formattedValue !== value ? value : ''}>
            <span>{formattedValue}</span>
          </Tooltip>
        );
      },
    },
  ];
};

const BrainPerformance = () => {
  const { t } = useTranslation();
  const [optionValue, setOptionValue] = useState(
    getOptions(t)[0]?.value as ResponseType
  );
  const { ai_agents } = useFeatureFlag();

  const { data, isLoading, error, refetchCurrent } = useBrainsPerformance({
    type: optionValue,
  });

  const handleOptionClick = useCallback((e) => {
    setOptionValue(e.target.value);
  }, []);

  return (
    <AnalyticsFieldset
      fullWidth
      title={t(`analytics.${ai_agents ? 'ai_agent_perf' : 'brain_perf'}`)}
      hasFooter={false}
      isEmpty={!data && !isLoading && !error}
      isLoading={isLoading}
      error={error}
      onReload={refetchCurrent}
      overflown
      data={data}
    >
      <div className={styles.checkbox}>
        <Select
          options={getOptions(t)}
          value={optionValue}
          onChange={handleOptionClick}
          size="small"
        />
      </div>

      {data && (
        <div className={cn(styles.tableWrapper, styles.container)}>
          <Table
            data={data}
            columns={getColumns(t, optionValue)}
            sortable
            sortBy="name"
            filterable={data.length > 10}
            overflown
          />
        </div>
      )}
    </AnalyticsFieldset>
  );
};

export default BrainPerformance;
