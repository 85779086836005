import { memo, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Input from '@/components/atoms/Input/Input';
import ReorderWrapper from '@/components/atoms/ReorderWrapper/ReorderWrapper';
import ToolkitAccordionWrapper from '@/components/organisms/Toolkit/ToolkitAccordionWrapper';
import { RequisiteOptionType } from '@/models/node';
import {
  removeRequisiteOption,
  updateRequisiteOption,
} from '@/redux/nodes/actions';
import { capitalizeFirstLetter } from '@/util/util';
import { requisiteOptionSchema } from '@/util/validator';

type Props = {
  index: number;
  expanded: boolean;
  option: RequisiteOptionType;
  requisiteId: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  dropItem: () => void;
  updateErrors: (key: string, error: string | null) => void;
};

const RequisiteOption = ({
  index,
  expanded,
  option,
  requisiteId,
  moveItem,
  dropItem,
  updateErrors,
}: Props) => {
  const getCardIndex = useCallback((index: number) => index, []);
  const [expandedState, setExpandedState] = useState(expanded);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    formState: { errors },
    getValues,
    register,
    trigger,
  } = useForm<RequisiteOptionType>({
    mode: 'onChange',
    defaultValues: {
      ...option,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(requisiteOptionSchema) as any,
  });

  const labelErrorMessage = capitalizeFirstLetter(errors?.label?.message);
  const textErrorMessage = capitalizeFirstLetter(errors?.text?.message);

  const updateOption = () => {
    dispatch(
      updateRequisiteOption({
        requisiteId,
        index,
        option: getValues(),
      })
    );
  };

  const clearOptionDialogErrors = useCallback(() => {
    if (labelErrorMessage) {
      updateErrors(`label-${option.option_id}`, undefined);
    }
    if (textErrorMessage) {
      updateErrors(`text-${option.option_id}`, undefined);
    }
  }, [labelErrorMessage, option.option_id, textErrorMessage, updateErrors]);

  const removeOption = useCallback(() => {
    clearOptionDialogErrors();

    dispatch(
      removeRequisiteOption({
        requisiteId,
        index,
      })
    );
  }, [dispatch, index, clearOptionDialogErrors, requisiteId]);

  const handleCollapse = useCallback(() => {
    setExpandedState((expanded) => !expanded);
  }, []);

  // Dialog errors
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors(`label-${option.option_id}`, labelErrorMessage);
  }, [labelErrorMessage, option.option_id, updateErrors]);

  useEffect(() => {
    updateErrors(`text-${option.option_id}`, textErrorMessage);
  }, [option.option_id, textErrorMessage, updateErrors]);

  // If there are errors, expand the accordion
  useEffect(() => {
    if (!isEmpty(errors)) {
      setExpandedState(true);
    }
  }, [errors]);

  return (
    <Box mb="var(--space-8)">
      <ReorderWrapper
        dragId="requisite-option"
        index={index}
        placeholderHeight={36}
        moveItem={moveItem}
        dropItem={dropItem}
        getIndex={() => getCardIndex(index)}
        canDrag={!expandedState}
      >
        <ToolkitAccordionWrapper
          onChange={handleCollapse}
          expanded={expandedState}
          title={option.label}
          handleDelete={removeOption}
        >
          <Input
            register={register('label')}
            error={!!errors?.label}
            errorMessage={labelErrorMessage}
            label={t('common.label')}
            name="label"
            onChange={updateOption}
            placeholder={t('dialog.carousel.button')}
            size="small"
          />

          <Box>
            <Input
              register={register('text')}
              error={!!errors?.text}
              errorMessage={textErrorMessage}
              label={t('common.text')}
              name="text"
              onChange={updateOption}
              placeholder={t('dialog.carousel.button')}
              size="small"
            />
          </Box>
        </ToolkitAccordionWrapper>
      </ReorderWrapper>
    </Box>
  );
};

export default memo(RequisiteOption);
