import { useTranslation } from 'react-i18next';

import AlertTriangle from '@/components/atoms/Icons/AlertTriangle';

import styles from './Webhook.module.scss';

const Warning = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.warning}>
      <AlertTriangle size={64} />
      <span>{t('webhook.test.warning')}</span>
    </div>
  );
};

export default Warning;
