import { useContext, useEffect, useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { SocketContext } from '@/contexts/rtm';
import { onDialogCollectionUpdated } from '@/hooks/useDialogs';
import { onEntityCollectionUpdated } from '@/hooks/useEntities';
import { onIntentCollectionUpdated } from '@/hooks/useIntents';
import useUser from '@/hooks/useUser';
import { SocketEvent } from '@/models/rtm';

import { useRtm } from './useRtm';

export const useRtmBrain = (brainId: string) => {
  const { socket, isConnected } = useContext(SocketContext);
  const { create, update, remove, rename } = useRtm();
  const { user } = useUser();
  const userId = user?.user_id;

  const queryClient = useQueryClient();

  const handleDialogCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, dialog_id } = data;
        create('dialogs', brain_id, dialog_id);
      }
    },
    [create, userId]
  );

  const handleDialogUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, dialog_id } = data;
        update('dialogs', brain_id, dialog_id);
      }
    },
    [userId, update]
  );

  const handleDialogCollectionUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, collection, new_collection } = data;
        onDialogCollectionUpdated(
          queryClient,
          brain_id,
          collection,
          new_collection
        );
      }
    },
    [userId, queryClient]
  );

  const handleEntityCollectionUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, collection, new_collection } = data;
        onEntityCollectionUpdated(
          queryClient,
          brain_id,
          collection,
          new_collection
        );
      }
    },
    [userId, queryClient]
  );

  const handleIntentCollectionUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, collection, new_collection } = data;
        onIntentCollectionUpdated(
          queryClient,
          brain_id,
          collection,
          new_collection
        );
      }
    },
    [userId, queryClient]
  );

  const handleEntityRenamed = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, entity, new_entity } = data;
        rename('entities', brain_id, entity, new_entity);
      }
    },
    [userId, rename]
  );

  const handleDialogRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, dialog_id } = data;
        remove('dialogs', brain_id, dialog_id);
      }
    },
    [userId, remove]
  );

  const handleEntityCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, entity } = data;
        create('entities', brain_id, entity);
      }
    },
    [create, userId]
  );

  const handleEntityUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, entity } = data;
        update('entities', brain_id, entity);
      }
    },
    [userId, update]
  );

  const handleEntityRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, entity } = data;
        remove('entities', brain_id, entity);
      }
    },
    [userId, remove]
  );

  const handleWebhookCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, webhook_id } = data;
        create('webhooks', brain_id, webhook_id);
      }
    },
    [create, userId]
  );

  const handleWebhookUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, webhook_id } = data;
        update('webhooks', brain_id, webhook_id);
      }
    },
    [userId, update]
  );

  const handleWebhookRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, webhook_id } = data;
        remove('webhooks', brain_id, webhook_id);
      }
    },
    [userId, remove]
  );

  const handleIntentCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, intent } = data;
        create('intents', brain_id, intent);
      }
    },
    [create, userId]
  );

  const handleIntentUpdated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, intent } = data;
        update('intents', brain_id, intent);
      }
    },
    [userId, update]
  );

  const handleIntentRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, intent } = data;
        remove('intents', brain_id, intent);
      }
    },
    [userId, remove]
  );

  const handleIntentRenamed = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, intent, new_intent } = data;
        rename('intents', brain_id, intent, new_intent);
      }
    },
    [userId, rename]
  );

  const handleVersionCreated = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, version } = data;
        create('versions', brain_id, version);
      }
    },
    [create, userId]
  );

  const handleVersionRemoved = useCallback(
    ({ user_id, data }) => {
      if (userId !== user_id) {
        const { brain_id, version } = data;
        remove('versions', brain_id, version);
      }
    },
    [remove, userId]
  );

  useEffect(() => {
    socket?.on(SocketEvent.versions_created, handleVersionCreated);
    return () => {
      socket?.off(SocketEvent.versions_created, handleVersionCreated);
    };
  }, [handleVersionCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.versions_removed, handleVersionRemoved);
    return () => {
      socket?.off(SocketEvent.versions_removed, handleVersionRemoved);
    };
  }, [handleVersionRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.intents_created, handleIntentCreated);
    return () => {
      socket?.off(SocketEvent.intents_created, handleIntentCreated);
    };
  }, [handleIntentCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.intents_updated, handleIntentUpdated);
    return () => {
      socket?.off(SocketEvent.intents_updated, handleIntentUpdated);
    };
  }, [handleIntentUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.intents_removed, handleIntentRemoved);
    return () => {
      socket?.off(SocketEvent.intents_removed, handleIntentRemoved);
    };
  }, [handleIntentRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.dialogs_created, handleDialogCreated);
    return () => {
      socket?.off(SocketEvent.dialogs_created, handleDialogCreated);
    };
  }, [handleDialogCreated, socket]);

  useEffect(() => {
    socket?.on(
      SocketEvent.entities_collection_updated,
      handleEntityCollectionUpdated
    );
    return () => {
      socket?.off(
        SocketEvent.entities_collection_updated,
        handleEntityCollectionUpdated
      );
    };
  }, [handleEntityCollectionUpdated, socket]);

  useEffect(() => {
    socket?.on(
      SocketEvent.intents_collection_updated,
      handleIntentCollectionUpdated
    );
    return () => {
      socket?.off(
        SocketEvent.intents_collection_updated,
        handleIntentCollectionUpdated
      );
    };
  }, [handleIntentCollectionUpdated, socket]);

  useEffect(() => {
    socket?.on(
      SocketEvent.dialogs_collection_updated,
      handleDialogCollectionUpdated
    );
    return () => {
      socket?.off(
        SocketEvent.dialogs_collection_updated,
        handleDialogCollectionUpdated
      );
    };
  }, [handleDialogCollectionUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.dialogs_updated, handleDialogUpdated);
    return () => {
      socket?.off(SocketEvent.dialogs_updated, handleDialogUpdated);
    };
  }, [handleDialogUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.dialogs_removed, handleDialogRemoved);
    return () => {
      socket?.off(SocketEvent.dialogs_removed, handleDialogRemoved);
    };
  }, [handleDialogRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.webhooks_created, handleWebhookCreated);
    return () => {
      socket?.off(SocketEvent.webhooks_created, handleWebhookCreated);
    };
  }, [handleWebhookCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.webhooks_updated, handleWebhookUpdated);
    return () => {
      socket?.off(SocketEvent.webhooks_updated, handleWebhookUpdated);
    };
  }, [handleWebhookUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.webhooks_removed, handleWebhookRemoved);
    return () => {
      socket?.off(SocketEvent.webhooks_removed, handleWebhookRemoved);
    };
  }, [handleWebhookRemoved, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.entities_created, handleEntityCreated);
    return () => {
      socket?.off(SocketEvent.entities_created, handleEntityCreated);
    };
  }, [handleEntityCreated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.entities_renamed, handleEntityRenamed);
    return () => {
      socket?.off(SocketEvent.entities_renamed, handleEntityRenamed);
    };
  }, [handleEntityRenamed, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.intents_renamed, handleIntentRenamed);
    return () => {
      socket?.off(SocketEvent.intents_renamed, handleIntentRenamed);
    };
  }, [handleIntentRenamed, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.entities_updated, handleEntityUpdated);
    return () => {
      socket?.off(SocketEvent.entities_updated, handleEntityUpdated);
    };
  }, [handleEntityUpdated, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.entities_removed, handleEntityRemoved);
    return () => {
      socket?.off(SocketEvent.entities_removed, handleEntityRemoved);
    };
  }, [handleEntityRemoved, socket]);

  useEffect(() => {
    if (!socket || !brainId || !isConnected) {
      return;
    }
    socket.emit(SocketEvent.subscribe_brain, { brain_id: brainId });
    return () => {
      socket.emit(SocketEvent.unsubscribe_brain, { brain_id: brainId });
    };
  }, [isConnected, brainId, socket]);
};
