import {
  ConversationEventAgent,
  ConversationEventBrain,
  ConversationEventChanges,
  ConversationEventDepartment,
  ConversationEventStatus,
  ConversationEventTag,
  ConversationEventTarget,
} from '@/models/chat';

export function isConversationEventBrain(
  target: ConversationEventTarget
): target is ConversationEventBrain {
  return 'prev_brain_id' in target;
}

export function isConversationEventAgent(
  target: ConversationEventTarget
): target is ConversationEventAgent {
  return 'prev_agent_id' in target || 'agent_id' in target;
}

export function isConversationEventDepartment(
  target: ConversationEventTarget
): target is ConversationEventDepartment {
  return 'prev_department_id' in target || 'department_id' in target;
}

export function isConversationEventChanges(
  target: ConversationEventTarget
): target is ConversationEventChanges {
  return 'changes' in target;
}

export function isConversationEventTag(
  target: ConversationEventTarget
): target is ConversationEventTag {
  return 'added_tags' in target || 'removed_tags' in target;
}

export function isConversationEventStatus(
  target: ConversationEventTarget
): target is ConversationEventStatus {
  return 'status' in target;
}
