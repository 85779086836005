import Brain from '@/components/atoms/Icons/Brain';
import { BrainTypeValues } from '@/models/brain';
import { Props } from '@/models/icon';

export const AIAgentIcon = ({
  size = 24,
  color = 'var(--icon-default-blue)',
  type = 'support',
}: Props & { type: BrainTypeValues }) => {
  if (type === 'activation') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill={color}
          d="M14.207 3.047a.5.5 0 0 0-.535.078c-1.75 1.514-3.232.78-4.95-.07-1.78-.883-3.798-1.88-6.05.07A.5.5 0 0 0 2.5 3.5V14a.5.5 0 0 0 1 0v-2.764c1.674-1.323 3.117-.61 4.778.213 1.025.507 2.129 1.053 3.313 1.053.87 0 1.783-.297 2.738-1.125a.499.499 0 0 0 .173-.375V3.5a.5.5 0 0 0-.295-.453ZM3.5 10.027v-3.16c1.053-.705 2.04-.724 3-.458v3.234c-.946-.239-1.946-.224-3 .384Zm3-6.848c.578.177 1.163.466 1.778.77.704.349 1.444.715 2.222.91v3.234c.96.266 1.946.246 3-.46v3.133c-1.054.832-2.017.857-3 .556V8.093c-1.351-.375-2.649-1.312-4-1.684v-3.23Z"
        />
      </svg>
    );
  } else if (type === 'support') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M18.927 5.124a9.697 9.697 0 0 0-6.853-2.874H12A9.75 9.75 0 0 0 2.25 12v5.25A2.25 2.25 0 0 0 4.5 19.5H6a2.25 2.25 0 0 0 2.25-2.25V13.5A2.25 2.25 0 0 0 6 11.25H3.784a8.261 8.261 0 0 1 14.08-5.07 8.193 8.193 0 0 1 2.353 5.07H18a2.25 2.25 0 0 0-2.25 2.25v3.75A2.25 2.25 0 0 0 18 19.5h2.25A2.25 2.25 0 0 1 18 21.75h-5.25a.75.75 0 1 0 0 1.5H18a3.75 3.75 0 0 0 3.75-3.75V12a9.694 9.694 0 0 0-2.823-6.876ZM6 12.75a.75.75 0 0 1 .75.75v3.75A.75.75 0 0 1 6 18H4.5a.75.75 0 0 1-.75-.75v-4.5H6ZM18 18a.75.75 0 0 1-.75-.75V13.5a.75.75 0 0 1 .75-.75h2.25V18H18Z"
        />
      </svg>
    );
  } else if (type === 'debt_collection') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill={color}
          d="M11.5 5.598V5.25C11.5 3.683 9.136 2.5 6 2.5S.5 3.683.5 5.25v2.5c0 1.306 1.64 2.343 4 2.654v.346c0 1.568 2.364 2.75 5.5 2.75s5.5-1.182 5.5-2.75v-2.5c0-1.294-1.589-2.332-4-2.652Zm3 2.652c0 .826-1.924 1.75-4.5 1.75-.233 0-.464-.008-.693-.023 1.349-.491 2.193-1.29 2.193-2.227V6.609c1.867.278 3 1.033 3 1.641Zm-10 1.14V7.905C4.997 7.969 5.498 8 6 8c.502 0 1.003-.031 1.5-.096V9.39c-.497.073-.998.11-1.5.109-.502 0-1.003-.036-1.5-.11Zm6-2.52v.88c0 .524-.776 1.088-2 1.43V7.718c.807-.196 1.49-.487 2-.848ZM6 3.5c2.576 0 4.5.924 4.5 1.75S8.576 7 6 7s-4.5-.924-4.5-1.75S3.424 3.5 6 3.5ZM1.5 7.75v-.88c.51.362 1.193.653 2 .849v1.46c-1.224-.341-2-.905-2-1.429Zm4 3v-.26a11.992 11.992 0 0 0 1.212-.011c.258.092.521.17.788.234v1.466c-1.224-.341-2-.905-2-1.429Zm3 1.64V10.9a11.486 11.486 0 0 0 3 .004v1.487c-.995.146-2.005.146-3 0Zm4-.21v-1.461c.807-.196 1.49-.487 2-.848v.879c0 .524-.776 1.088-2 1.43Z"
        />
      </svg>
    );
  } else if (type === 'engagement') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill={color}
          d="M13.76 6.25 12.68 4.375a1.75 1.75 0 0 0-2.953-.12l-1.089-1.88a1.75 1.75 0 0 0-3.03 0 1.75 1.75 0 0 0-2.598 2.25l.1.173a1.75 1.75 0 0 0-1.374 2.619l2.5 4.332a5.468 5.468 0 0 0 3.34 2.562 5.5 5.5 0 0 0 6.186-8.063v.002Zm-.416 3.915a4.5 4.5 0 0 1-8.243 1.085l-2.5-4.332a.75.75 0 0 1 1.299-.75L5.102 8.25a.5.5 0 1 0 .865-.5L3.876 4.125a.75.75 0 1 1 1.299-.75L7.125 6.75a.5.5 0 1 0 .866-.5L6.473 3.625a.75.75 0 1 1 1.299-.75L9.86 6.494a3 3 0 0 0-.347 3.787.5.5 0 0 0 .828-.562A2 2 0 0 1 10.799 7a.5.5 0 0 0 .133-.65l-.418-.725a.75.75 0 1 1 1.299-.75l1.081 1.875a4.474 4.474 0 0 1 .45 3.414v.001Zm-1.828-8.183a.5.5 0 0 1 .609-.357 3.726 3.726 0 0 1 2.283 1.75l.02.036a.498.498 0 0 1-.175.694.5.5 0 0 1-.69-.194l-.02-.036a2.73 2.73 0 0 0-1.675-1.281.5.5 0 0 1-.352-.612Zm-6.46 12.83a.5.5 0 0 1-.702.084 7.473 7.473 0 0 1-1.85-2.146.5.5 0 0 1 .866-.5 6.48 6.48 0 0 0 1.603 1.857.5.5 0 0 1 .083.706Z"
        />
      </svg>
    );
  } else if (type === 'upsell') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill={color}
          d="M14.5 13a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V3a.5.5 0 1 1 1 0v6.793l3.146-3.147a.5.5 0 0 1 .708 0L8 8.293 11.293 5H10a.5.5 0 1 1 0-1h2.5a.5.5 0 0 1 .5.5V7a.5.5 0 0 1-1 0V5.707L8.354 9.354a.5.5 0 0 1-.708 0L6 7.707l-3.5 3.5V12.5H14a.5.5 0 0 1 .5.5Z"
        />
      </svg>
    );
  }

  return <Brain color={color} size={size} />;
};
