import { useState, PropsWithChildren } from 'react';

import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import styles from './CustomDetails.module.scss';

type CustomDetailsProps = {
  title: string;
  open?: boolean;
};

export const CustomDetails = ({
  children,
  title,
  open = true,
}: PropsWithChildren<CustomDetailsProps>) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.details}>
      <button className={styles.summary} onClick={toggleOpen}>
        <Typography variant="body-semi-bold" color="var(--text-default-gray)">
          {title}
        </Typography>
        {isOpen ? (
          <ExpandMore fontSize="small" htmlColor="var(--icon-default-gray)" />
        ) : (
          <ChevronRight fontSize="small" htmlColor="var(--icon-default-gray)" />
        )}
      </button>
      {isOpen && (
        <div className={styles.content}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};
