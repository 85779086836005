import cn from 'classnames';

import styles from './LanguageModelTile.module.scss';

type Props = {
  isConnected: boolean;
  children: React.ReactNode;
};

export const Wrapper = ({ isConnected, children }: Props) => {
  return (
    <div className={cn(styles.tile, { [styles.isConnected]: isConnected })}>
      {children}
    </div>
  );
};
