import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ConnectionStatus from '@/components/atoms/Accordion/templates/ConnectionStatusAccordion';
import ActiveFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/ActiveFieldset';
import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import AdvancedSettings from '@/components/organisms/Integrations/Web/Accordion/AdvancedSettings/AdvancedSettings';
import { useAccordion } from '@/hooks/useAccordion';
import { AccordionType, ViberIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId } from '@/redux/session/selectors';

import ViberChatAppearance from './Accordion/ViberChatAppearance/ViberChatAppearance';
import ViberConnect from './Accordion/ViberConnect/ViberConnect';
import ViberInfo from './ViberInfo/ViberInfo';

import styles from './Viber.module.scss';

const IntegrationViber = () => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const stateRef = useRef(null);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const { toggleAccordion, integration, accordions, registerAccordion } =
    useAccordion<ViberIntegration>(canWrite);

  const hasAccessToken = integration?.config?.access_token?.length > 0;
  const hasName = integration?.config?.name?.length > 0;

  if (!hasAccessToken || !hasName) {
    // If access_token or name doesn't exist
    // 'unverified' is meaningless
    const status =
      integration?.status === 'unverified'
        ? 'missing-credentials'
        : integration?.status;
    return (
      <div className={styles.container}>
        <div>
          <ConnectionStatus
            type={AccordionType.VERIFICATION_STATUS}
            registerAccordion={registerAccordion}
            status={status}
            toggleAccordion={toggleAccordion}
            conSettingsRef={
              accordions?.current[AccordionType.CONNECTION_SETTINGS]?.ref
            }
            stateRef={stateRef.current}
          />
          <ViberConnect
            type={AccordionType.CONNECTION_SETTINGS}
            registerAccordion={registerAccordion}
            integration={integration}
            toggleAccordion={toggleAccordion}
          />
          <DeleteFieldset integration={integration} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <ConnectionStatus
          type={AccordionType.VERIFICATION_STATUS}
          registerAccordion={registerAccordion}
          status={integration?.status}
          toggleAccordion={toggleAccordion}
          conSettingsRef={
            accordions?.current[AccordionType.CONNECTION_SETTINGS]?.ref
          }
          stateRef={stateRef.current}
        />
        <ViberConnect
          type={AccordionType.CONNECTION_SETTINGS}
          registerAccordion={registerAccordion}
          integration={integration}
          toggleAccordion={toggleAccordion}
        />
        <ViberChatAppearance
          type={AccordionType.VIBER_CHAT_APPEARANCE}
          registerAccordion={registerAccordion}
          integration={integration}
          toggleAccordion={toggleAccordion}
        />
        <AdvancedSettings
          type={AccordionType.ADVANCED_SETTINGS}
          registerAccordion={registerAccordion}
          integration={integration}
          toggleAccordion={toggleAccordion}
        />
        <div ref={stateRef}>
          <ActiveFieldset
            integration={integration}
            title={t('integrations.status.title')}
            subtitle={t('integrations.status.subtitle')}
          />
        </div>
        <DeleteFieldset integration={integration} disabled={!canWrite} />
      </div>
      <div className={styles.infoContainer}>
        <ViberInfo
          integrationId={integration?.integration_id}
          deskId={deskId}
        />
      </div>
    </div>
  );
};

export default IntegrationViber;
