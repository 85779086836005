import { Icon } from '@/models/icon';

const ToolSlot = ({ size = 28, color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 28 28"
      width={size}
      height={size}
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect
        x=".5"
        y=".5"
        width="27"
        height="27"
        rx="2.5"
        fill={color}
        stroke={color}
      />
      <g
        clipPath="url(#clip0)"
        stroke="var(--icon-default-white)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7.333 12.333h13.334c.92 0 1.666-.746 1.666-1.666V7.333c0-.92-.746-1.666-1.666-1.666H7.333c-.92 0-1.666.746-1.666 1.666v3.334c0 .92.746 1.666 1.667 1.666zM7.333 22.333h13.334c.92 0 1.666-.746 1.666-1.666v-3.334c0-.92-.746-1.666-1.666-1.666H7.333c-.92 0-1.666.746-1.666 1.666v3.334c0 .92.746 1.666 1.667 1.666z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M24 24H4V4h20v20z" fill="var(--icon-default-white)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ToolSlot;
