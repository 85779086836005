import { configureStore } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';
import createRootReducer, { defaultState } from '@/redux/reducer';

// Get the initial state if is available in the window object
const windowState = window.MOVEO_REDUX_STATE ?? {};

export const initializeStore = (preloadedState = {}) => {
  const initialState = {
    ...defaultState,
    ...windowState,
    ...preloadedState,
  } as RootState;

  const store = configureStore<RootState>({
    preloadedState: initialState,
    reducer: createRootReducer(),
  });

  return store;
};

const store = initializeStore();

export const getStore = () => {
  return store;
};
