import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import MenuItem from '@mui/material/MenuItem';
import { TypographyVariant } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import Accordion from '@/components/atoms/Accordion/Accordion';
import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import IconButton from '@/components/atoms/IconButton/IconButton';
import LinkExternal from '@/components/atoms/Icons/LinkExternal';
import MoreHorizontal from '@/components/atoms/Icons/MoreHorizontal';
import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import Trash from '@/components/atoms/Icons/Trash';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Table from '@/components/atoms/Table/Table';
import { EmojiPicker } from '@/components/organisms/EmojiPicker/EmojiPicker';
import {
  MODAL_DELETE,
  MODAL_FORM,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import usePrompt from '@/hooks/usePrompt';
import { Agent } from '@/models/account';
import { OptionBase } from '@/models/common';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { useRules } from '@/modules/rules/hooks/useRules';
import { Rule } from '@/modules/rules/model';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { getRestrictedNames } from '@/util/util';
import {
  LENGTH_XS,
  getEditableTextValidationSchema,
  invitationRules,
} from '@/util/validator';

import { useDepartments } from '../../hooks/useDepartments';
import { Department, Form } from '../../models';
import RoutingSelect from '../RoutingSelect/RoutingSelect';

import styles from './DepartmentTile.module.scss';

interface Props {
  department: Department;
  isExpanded: boolean;
  setExpanded: (event, isExpanded: boolean) => void;
  panelId: string;
  setOnSubmit: (panelId: string, fn: () => Promise<void>) => void;
  setDirty: (panelId: string, isDirty: boolean) => void;
}
const DepartmentTile = React.forwardRef<HTMLDivElement, Props>(
  (
    { department, isExpanded, setExpanded, setOnSubmit, panelId, setDirty },
    ref
  ) => {
    const navigate = useNavigate();
    const bodyRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
    const open = Boolean(anchorEl);
    const deskId = useSelector(selectDeskId);
    const dispatch = useDispatch();
    const slug = useSelector(selectAccountSlug);

    const [selectedUser, setSelectedUser] = useState<Agent>(null);
    const formMethods = useForm<Form>({ mode: 'onSubmit' });
    const { agents } = useAccount();
    const { rules } = useRules(deskId);
    const [emojiPicker, setEmojiPicker] = useState(false);

    const canWriteDepartments = useSelector((state: RootState) =>
      getPermissions(state, 'departments', actions.WRITE)
    );

    const rulesConnectedToDepartment = useMemo(() => {
      return rules?.rules?.filter(
        (rule) =>
          rule?.actions?.some(
            (action) => action?.department_id === department.department_id
          ) && rule.status === 'active'
      );
    }, [department.department_id, rules?.rules]);

    const {
      departmentUsers,
      deleteDepartment,
      updateDepartment,
      updateStatus,
      addMembersToDepartment,
      removeMemberFromDepartment,
      addMemberStatus,
      departments,
    } = useDepartments(deskId, department.department_id);
    const {
      reset,
      formState: { isDirty },
      watch,
      control,
      handleSubmit,
      setValue,
      getValues,
    } = formMethods;
    const { t } = useTranslation();

    const formUsers = watch('users');

    const onSubmit = useCallback(
      async (data) => {
        const dpt = {
          department_id: department.department_id,
          name: department.name,
          ...data,
        };
        updateDepartment(dpt);

        const addedUserIds = data.users
          .filter(
            (user) => !departmentUsers.find((u) => u.user_id === user?.user_id)
          )
          .map((u) => u.user_id);

        const removedUsers = departmentUsers.filter(
          (user) => !data.users.find((u) => u.user_id === user?.user_id)
        );

        if (addedUserIds.length > 0) {
          addMembersToDepartment({
            department_id: department.department_id,
            user_ids: addedUserIds,
          });
        }

        await Promise.all(
          removedUsers.map(async (user) => {
            return removeMemberFromDepartment({
              department_id: department.department_id,
              user_id: user?.user_id,
            });
          })
        );
      },
      [
        addMembersToDepartment,
        department.department_id,
        department.name,
        departmentUsers,
        removeMemberFromDepartment,
        updateDepartment,
      ]
    );

    usePrompt(
      isDirty && canWriteDepartments,
      undefined,
      undefined,
      handleSubmit(onSubmit)
    );

    // registering onSubmit to the accordions state
    useEffect(() => {
      setOnSubmit(panelId, handleSubmit(onSubmit));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleSubmit]);

    useEffect(() => {
      setDirty(panelId, isDirty);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    useEffect(() => {
      if (departmentUsers && department?.routing) {
        reset({
          routing: department.routing,
          users: departmentUsers,
          name: department.name,
        });
      }
    }, [departmentUsers, department?.routing, department?.name, reset]);

    useEffect(() => {
      if (!isExpanded && isDirty) {
        reset();
      }
    }, [isDirty, isExpanded, reset]);

    const handleDepartmentDelete = useCallback(() => {
      if (rulesConnectedToDepartment.length > 0) {
        const brainWarning = (
          <Banner<Partial<Rule>>
            relativePosition
            variant="critical"
            referenceProp="rule_id"
            references={rulesConnectedToDepartment}
            labelKey="name"
            onRefClick={(id) => {
              dispatch(popModal());
              navigate(`/${slug}/environments/${deskId}/rules/${id}`);
            }}
          >
            <Trans
              i18nKey="departments.delete_warning_rules"
              values={{
                0: department?.name,
              }}
            />
          </Banner>
        );

        const warnProps = {
          title: t('common.warning'),
          children: brainWarning,
          primaryButtonText: t('common.close'),
        };

        dispatch(pushModal(MODAL_WARN, warnProps));
      } else {
        const deleteProps = {
          subtitle: (
            <Trans
              i18nKey="departments.delete_warning"
              values={[department.name]}
            />
          ),
          confirm: true,
          onDelete: () => {
            deleteDepartment(department.department_id, {
              onSuccess: () => {
                dispatch(popModal());
              },
            });
          },
        };
        dispatch(pushModal(MODAL_DELETE, deleteProps));
      }
    }, [
      deleteDepartment,
      department?.department_id,
      department?.name,
      deskId,
      dispatch,
      rulesConnectedToDepartment,
      slug,
      t,
      navigate,
    ]);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
      setSelectedUser(null);
    }, []);

    const options =
      formUsers
        ?.map(({ user_id }) => {
          const agent = agents?.find((agent) => agent.agent_id === user_id);
          return agent;
        })
        .filter(Boolean) ?? [];

    const agentOptions = useMemo(() => {
      if (!agents) return;

      const departmentUserIds = new Set(formUsers?.map((user) => user.user_id));

      return agents
        .filter((agent) => !departmentUserIds.has(agent.agent_id))
        .map((agent) => ({
          value: agent.agent_id,
          label: agent.name,
          description: agent.email,
        }));
    }, [formUsers, agents]);

    const handleMembersAdd = () => {
      const modalProps = {
        title: (
          <>
            {t('departments.add_agents_to')}{' '}
            <span className={styles.info}>{department.name}</span>
          </>
        ),
        primaryButtonText: t('common.add'),

        fields: [
          {
            fieldName: 'users',
            fieldValue: [],
            label: t('common.agents'),
            type: 'autocomplete',
            placeholder: t('common.select_an_option'),
            options: agentOptions,
            rules: invitationRules(t).roles,
          },
        ],

        onCreate: async (data: { users: OptionBase[] }) => {
          try {
            const users = data.users.map((r) => ({
              user_id: r.value,
              department_id: department.department_id,
            }));
            setValue('users', [...formUsers, ...users], {
              shouldDirty: true,
            });
          } catch (e) {
            dispatch(addErrorTemporalToast(e));
          }
          dispatch(popModal());
        },
      };
      dispatch(pushModal(MODAL_FORM, modalProps));
    };

    const handleRemoveMember = () => {
      setAnchorEl(null);
      const filteredUsers = formUsers.filter(
        (user) => user.user_id !== selectedUser.agent_id
      );
      setValue('users', filteredUsers, {
        shouldDirty: true,
      });
    };

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, a: Agent) => {
        setSelectedUser(a);
        setAnchorEl(event.currentTarget);
      },
      []
    );

    const getColumns = useMemo(
      () => [
        {
          Header: t('common.name'),
          accessor: 'name',
          width: 500,
          Cell: (props) => {
            const row: Agent = props.row.original;

            const { avatar, email } = row;
            return (
              <div className={styles.listItem}>
                <Avatar src={avatar} />
                <div className={styles.infoContainer}>
                  <span>{props.value}</span>
                  <span className={styles.email}>{email}</span>
                </div>
              </div>
            );
          },
        },

        {
          Header: '',
          id: 'menu',
          width: 50,
          Cell: (props) => {
            const row: Agent = props.row.original;

            return (
              <IconButton
                onClick={(e) => handleClick(e, row)}
                ariaLabel="Member options"
                ariaHasPopUp
              >
                <MoreHorizontal size={24} />
              </IconButton>
            );
          },
        },
      ],
      [handleClick, t]
    );

    const defaultStyle = {
      transition: 'opacity 300ms ease-in-out',
      opacity: 1,
    };

    const handleAccordionToggle = () => {
      setExpanded(null, !isExpanded);
    };

    const onTextUpdate = (text: string) => {
      setValue('name', text, { shouldDirty: true });
    };

    // Filters out the current department name from the list of restricted values
    const restrictedValues = getRestrictedNames(departments, department?.name);

    const validationSchema = getEditableTextValidationSchema(
      LENGTH_XS,
      restrictedValues,
      t('departments.department_name')
    );

    const editableTextProps = {
      defaultValue: department.name,
      onSubmit: onTextUpdate,
      variant: 'subtitle1' as TypographyVariant,
      canEdit: canWriteDepartments && isExpanded,
      validationSchema,
      className: styles.editableText,
    };

    const isConnected = rulesConnectedToDepartment?.length > 0;

    const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      // Prevents the emoji picker from opening when the accordion is collapsed
      if (isExpanded) {
        e.stopPropagation();
        setEmojiPicker(true);
      }
    };

    const title = (
      <div className={styles.titleWrapper}>
        <button
          onClick={handleIconClick}
          className={styles.icon}
          aria-disabled={!isExpanded}
        >
          {getValues('icon') || department?.icon}
        </button>

        <EditableText<typeof validationSchema> {...editableTextProps} />

        {rules && department && (
          <div className={cn(styles.accordionHeader)}>
            <StatusBadge
              variant={isConnected ? 'success' : 'neutral'}
              label={
                isConnected ? t('status.connected') : t('status.disconnected')
              }
            />
          </div>
        )}
      </div>
    );

    return (
      <div className={styles.container}>
        <Accordion
          onSubmit={handleSubmit(onSubmit)}
          disabled={!isDirty}
          isLoading={
            updateStatus === 'pending' || addMemberStatus === 'pending'
          }
          expanded={isExpanded}
          handleChange={handleAccordionToggle}
          secondaryButtonText={t('departments.delete_department')}
          onSecondaryButtonClick={handleDepartmentDelete}
          title={title}
          readOnly={!canWriteDepartments}
          ref={ref}
        >
          <Transition nodeRef={bodyRef} in={isExpanded} timeout={300}>
            <>
              {emojiPicker && (
                <EmojiPicker
                  className={styles.emojiPicker}
                  onClickAway={() => {
                    setEmojiPicker(false);
                  }}
                  onEmojiClick={({ emoji }) => {
                    setValue('icon', emoji, { shouldDirty: true });
                    setEmojiPicker(false);
                  }}
                  width="100%"
                  height={300}
                />
              )}

              {isExpanded && (
                <div ref={bodyRef} style={defaultStyle}>
                  <RoutingSelect
                    control={control}
                    department_id={department.department_id}
                  />
                  <div className={styles.labelContainer}>
                    <Typography
                      component="p"
                      variant="label-caps-large"
                      color="var(--text-default-gray)"
                    >
                      {t('common.agents')}
                    </Typography>
                    <Button
                      variant="tertiary"
                      onClick={handleMembersAdd}
                      noGutters
                      disabled={!canWriteDepartments}
                    >
                      <PlusCircle
                        color={
                          canWriteDepartments
                            ? 'var(--icon-default-blue)'
                            : 'var(--icon-default-gray)'
                        }
                      />
                      {t('departments.add_agents')}
                    </Button>
                  </div>
                  <Table
                    data={options}
                    columns={getColumns}
                    emptyMessage={t('departments.no_agents')}
                    flexLayout
                    noGutters
                    fullWidth
                  />
                  <Typography
                    mt={4}
                    component="p"
                    variant="label-caps-large"
                    className={styles.label}
                  >
                    {t('departments.connected_rules')}
                  </Typography>
                  {rulesConnectedToDepartment?.map((rule) => {
                    return (
                      <div
                        key={rule.rule_id}
                        className={styles.buttonContainer}
                      >
                        <NavLink
                          to={`/${slug}/environments/${deskId}/rules/${rule.rule_id}`}
                          className={styles.link}
                        >
                          <Typography>{rule?.name}</Typography>
                          <LinkExternal />
                        </NavLink>
                      </div>
                    );
                  })}
                  {!isConnected && (
                    <Banner
                      relativePosition
                      variant="warning"
                      customDismiss={t('common.connect')}
                      customDismissAction={() =>
                        navigate(`/${slug}/environments/${deskId}/rules`)
                      }
                    >
                      {t('departments.no_connected_rule')}
                    </Banner>
                  )}
                </div>
              )}
            </>
          </Transition>
        </Accordion>

        <CustomMenu
          id={`department-menu-${panelId}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableRestoreFocus
          MenuListProps={{
            'aria-labelledby': `department-button-${panelId}`,
          }}
        >
          <MenuItem
            onClick={handleRemoveMember}
            disableGutters
            className={styles.danger}
          >
            <Trash />
            {t('departments.remove_agent')}
          </MenuItem>
        </CustomMenu>
      </div>
    );
  }
);

DepartmentTile.displayName = 'DepartmentTile';

export default DepartmentTile;
