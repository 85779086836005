import { memo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';

import styles from '@/components/organisms/Tile/TileBusinessHours.module.scss';

type Props = {
  className?: string;
  noMargin?: boolean;
};
function TileSkeleton({ className = '', noMargin = true }: Props) {
  return (
    <div
      className={cn(className, {
        tile: true,
        tile__preview: true,
        'tile--mr0': noMargin,
      })}
      data-testid="tile-skeleton"
    >
      <div className={styles.wrapper}>
        <div className={styles.dnd}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={18}
            height={20}
          />
        </div>
        <div className={styles.info}>
          <span className={styles.name}>
            <Skeleton animation="wave" width={200} height={24} />
            <Skeleton animation="wave" width={200} height={24} />
          </span>
          <div className={styles.menu}>
            <Skeleton
              animation="wave"
              variant="circular"
              width={32}
              height={32}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TileSkeleton);
