/* eslint-disable no-shadow */

import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from 'react-use';

import { useAccount } from '@/hooks/useAccount';
import { useInvitations } from '@/hooks/useInvitations';
import { PageName } from '@/models/segment';
import { setAccountSlug } from '@/redux/session/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import InvitationFieldset from './InvitationFieldset/InvitationFieldset';
import InvitationsEmpty from './InvitationsEmpty/InvitationsEmpty';
import InvitationSkeleton from './InvitationSkeleton/InvitationSkeleton';

const Invitations = () => {
  const { t } = useTranslation();
  const { invitations, respondInvitation } = useInvitations();
  const { accounts } = useAccount();
  const accountSlug = useSelector(selectAccountSlug);
  const dispatch = useDispatch();

  useTitle(t('pages.invitations'));

  useEffect(() => {
    pageView(PageName.PERSONAL_INVITATIONS);
  }, []);

  useEffect(() => {
    if (!accountSlug && accounts?.length > 0) {
      dispatch(setAccountSlug(accounts[0].slug));
    }
  }, [accountSlug, accounts, dispatch]);

  const renderSkeleton = () => <InvitationSkeleton />;

  const renderInvitations = () => {
    if (!invitations || isEmpty(invitations)) {
      return <InvitationsEmpty />;
    }

    return invitations.map((invitation) => (
      <InvitationFieldset
        key={invitation.invitation_code}
        invitation={invitation}
        respondInvitation={respondInvitation}
      />
    ));
  };
  return <>{invitations ? renderInvitations() : renderSkeleton()}</>;
};

export default Invitations;
