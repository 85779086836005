import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';

export const selectAllMembers = (state: RootState) => state.members;

export const selectMemberById = createSelector(
  [selectAllMembers, (_, memberId) => memberId],
  (members, memberId) => {
    if (!memberId) return null;

    return members.find((m) => m.user_id === memberId);
  }
);
