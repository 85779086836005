export type RecommendationConfig = {
  // Source of data
  source_type: 'brain' | 'logs' | 'desk';
  source_brain_id?: string;
  source_logs?: string;
  source_desk_id?: string;
  enabled: boolean;
};

export type Recommendation = RecommendationConfig & {
  status: 'available' | 'unavailable' | 'training' | 'skipped';
  status_code: string;
  source_last_updated: string;
  last_trained: string;
  intents: RecommendedIntent[];
  auto_intents?: RecommendedIntent[];
  listStatus: {
    loaded: boolean;
    loading: boolean;
  };
  dirty: boolean;
};

export type PartialRecommendation = Partial<Recommendation> &
  (
    | Required<Pick<Recommendation, 'intents'>>
    | Required<Pick<Recommendation, 'auto_intents'>>
  );

export type Recommendations = {
  [key: string]: Recommendation;
};

export const enum TYPE {
  AUTO = 'auto',
  LOGS = 'logs',
}

export type RecommendedExpression = {
  expression: string;
  flag?: Flag;
  predicted_intent?: string;
  prediction_confidence?: number;
};

export type RecommendedIntent = {
  intent: string;
  flag?: Flag;
  confidence?: number;
  expressions: RecommendedExpression[];
};

export type Flag =
  | 'accepted'
  | 'dismissed'
  | 'rejected'
  | 'modified'
  | 'deleted';
