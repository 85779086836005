import { useFocused } from 'slate-react';

import Button from '@/components/atoms/Button/Button/Button';
import { preventClickThrough } from '@/util/util';

export const VariablesButton = ({ children, onClick }) => {
  const isEditorFocused = useFocused();
  const handleOnClick = (event) => {
    if (event) {
      preventClickThrough(event);
    }
    if (isEditorFocused) {
      onClick(event);
    }
  };

  return (
    <Button
      variant="secondary"
      size="xs"
      onMouseDown={handleOnClick}
      disabled={!isEditorFocused}
    >
      {children}
    </Button>
  );
};
