import { Icon } from '@/models/icon';

const Lock = ({ size = 101, color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth="2"
    >
      <path d="M68.534 41.222h-4v-14.32c0-2.744-1.756-5.442-4.278-7.406a16.092 16.092 0 0 0-9.768-3.345c-3.745 0-7.199 1.361-9.727 3.355-2.504 1.975-4.227 4.684-4.227 7.442v14.274h-4V26.948c0-3.834 2.095-7.393 5.443-10.026 3.346-2.632 7.867-4.272 12.511-4.272 4.647 0 9.192 1.63 12.561 4.252 3.371 2.623 5.485 6.17 5.485 10v14.32ZM27.316 41.452h46.345c2.221 0 3.894 1.656 3.894 3.544v40.535c0 1.888-1.673 3.544-3.894 3.544H27.316c-2.221 0-3.893-1.656-3.893-3.544V44.996c0-1.889 1.672-3.544 3.893-3.544Z" />
      <path d="M44.097 59.675c0 1.21.345 2.394.994 3.418a6.47 6.47 0 0 0 2.104 2.063V72.923c.014.89.381 1.735 1.019 2.355l.697-.717-.697.717c.637.62 1.493.965 2.382.965.89 0 1.746-.345 2.383-.965a3.358 3.358 0 0 0 1.019-2.355V65.156a6.469 6.469 0 0 0 2.01-1.916 6.376 6.376 0 0 0 .357-6.531 6.459 6.459 0 0 0-2.322-2.487 6.55 6.55 0 0 0-6.589-.177 6.468 6.468 0 0 0-2.453 2.359 6.38 6.38 0 0 0-.904 3.27Z" />
    </svg>
  );
};

export default Lock;
