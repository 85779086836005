const Build = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="439"
      height="542"
      fill="none"
      viewBox="0 0 439 542"
    >
      <path
        fill="#F5F7FF"
        d="M76.4761 330.901c-16.9619 8.702-34.2695 18.4-46.1832 34.692-3.9748 5.435-7.3944 12.034-7.0488 19.099.6442 13.175 13.8932 21.022 25.4613 21.046 11.5681.024 22.8219-4.921 34.39-4.885 5.97.018 13.4429 3.314 13.3643 10.128-.0471 4.073-2.9169 7.28-5.5772 9.992a674149.1786 674149.1786 0 0 1-61.26 62.438c-6.8026 6.934-13.8199 14.202-17.3599 23.793-3.54 9.591-2.519 22.228 4.8074 28.439 4.823 4.091 11.2276 4.629 17.2395 4.748 27.2994.556 54.4574-4.234 81.3065-9.878 94.938-19.946 188.111-50.763 277.681-91.821 16.401-7.519 34.007-16.758 41.816-34.841 9.017-20.903-.603-49.467-19.576-58.103-11.337-5.166-24.084-3.691-36.086-6.223-12.003-2.532-24.875-11.914-25.001-25.829-.199-23.023 32.468-31.76 33.406-54.759.497-12.177-9.207-22.467-19.643-25.113-10.437-2.645-21.278.544-31.479 4.199 0-.012-185.016 54.286-260.2579 92.878Z"
      />
      <path
        stroke="#F9B74B"
        strokeWidth="2"
        d="M324.628 201.249c.303-.089.574-.169.812-.24v.085l1.338-.479c10.211-3.659 20.785-6.734 30.895-4.171 10.066 2.552 19.364 12.483 18.89 24.103-.447 10.953-8.383 18.58-16.66 26.533l-.163.157c-8.192 7.872-16.687 16.105-16.584 28.118.132 14.596 13.578 24.222 25.794 26.799 4.578.966 9.283 1.358 13.905 1.744 1.51.127 3.012.252 4.498.397 6.079.591 11.963 1.502 17.476 4.013 9.17 4.174 16.164 13.214 19.751 23.861 3.584 10.64 3.716 22.748-.679 32.936-7.64 17.692-24.891 26.799-41.315 34.328-89.504 41.028-182.606 71.821-277.47 91.752l.206.979-.206-.979c-26.838 5.642-53.9018 10.41-81.0801 9.857h-.0005c-6.0215-.12-12.087-.672-16.6125-4.511h-.0002c-3.423-2.902-5.4297-7.358-6.1352-12.33-.7048-4.967-.0946-10.357 1.6193-15 3.4548-9.36 10.3134-16.485 17.1355-23.439 20.2826-20.669 40.5599-41.338 60.8372-62.007l.4229-.431c2.6266-2.678 5.8109-6.154 5.8633-10.681.0442-3.837-2.0591-6.652-4.8723-8.465-2.7904-1.798-6.3452-2.665-9.4888-2.675-5.8989-.018-11.6995 1.231-17.3759 2.454l-.0328.007c-5.7245 1.232-11.3241 2.436-16.9824 2.424-5.5755-.011-11.5694-1.913-16.231-5.393-4.6486-3.469-7.9288-8.468-8.2335-14.701l-.9988.049.9988-.049c-.3287-6.72 2.9271-13.087 6.8571-18.46l-.8072-.59.8072.59c11.7597-16.081 28.8678-25.69 45.8325-34.393 37.5424-19.255 102.5354-42.456 158.2024-60.856 27.821-9.195 53.292-17.185 71.809-22.876 9.258-2.845 16.776-5.115 21.98-6.673 2.602-.779 4.625-1.381 5.998-1.787Z"
      />
      <path
        fill="#F9B74B"
        d="m265.42 94.2612-1.014-.361c-5.071-1.8051-7.514-6.874-5.414-11.2338l20.059-41.6603c2.099-4.3599 7.994-6.4604 13.065-4.6553l1.014.361c5.07 1.805 7.513 6.8739 5.414 11.2337l-20.06 41.6604c-2.099 4.3598-7.994 6.4604-13.064 4.6553Z"
      />
      <path
        fill="#8FCCFF"
        d="m314.606 105.87-8.113-2.888c-5.07-1.805-7.513-6.8742-5.414-11.234l33.308-69.1755c2.099-4.3598 7.994-6.4604 13.065-4.6553l8.112 2.8881c5.071 1.805 7.514 6.8739 5.414 11.2337l-33.308 69.176c-2.099 4.359-7.881 6.5-13.064 4.655Z"
      />
      <path
        fill="#F9B74B"
        d="m356.451 116-2.93-1.042c-5.07-1.806-7.513-6.874-5.414-11.234l22.439-46.6017c2.099-4.3598 7.994-6.4604 13.064-4.6553l2.93 1.0429c5.07 1.8051 7.513 6.874 5.414 11.2338l-22.439 46.6013c-2.099 4.36-7.881 6.501-13.064 4.655Z"
      />
    </svg>
  );
};

export default Build;
