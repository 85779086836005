import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Robot = forwardRef<SVGSVGElement, Icon>(
  ({ size = 16, color = 'var(--icon-default-gray)', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill={color}
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M11.5 1.7c0 .56-.3 1.04-.75 1.3v1.45H17c.97 0 1.75.78 1.75 1.75v3.5h.15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-.15v4.5c0 .97-.78 1.75-1.75 1.75H3c-.97 0-1.75-.78-1.75-1.75v-4.5H1a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h.25V6.2c0-.97.78-1.75 1.75-1.75h6.25V3a1.5 1.5 0 1 1 2.25-1.3ZM2.75 6.2c0-.14.11-.25.25-.25h14c.14 0 .25.11.25.25v12c0 .14-.11.25-.25.25H3a.25.25 0 0 1-.25-.25v-12ZM7 14.25v1.5h6v-1.5H7Zm1-3.8c0 .97-.56 1.75-1.25 1.75s-1.25-.78-1.25-1.75.56-1.75 1.25-1.75S8 9.48 8 10.45Zm5.25 1.75c.69 0 1.25-.78 1.25-1.75s-.56-1.75-1.25-1.75S12 9.48 12 10.45s.56 1.75 1.25 1.75Z"
        />
      </svg>
    );
  }
);

Robot.displayName = 'Robot';

export default Robot;
