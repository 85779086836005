import { useCallback, useEffect, useMemo } from 'react';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Node } from 'slate';

import Select from '@/components/atoms/Select/Select';
import CommandPalette from '@/components/organisms/CommandPalette/CommandPalette';
import { useMarkdownToSlate } from '@/components/organisms/RichTextEditor/hooks/useMarkdownToSlate';
import OptionsPopper, {
  OptionsPopperProps,
} from '@/components/organisms/RichTextEditor/OptionsPopper/OptionsPopper';
import RichTextEditor from '@/components/organisms/RichTextEditor/RichTextEditor';
import {
  addStringToEditor,
  richTextToMarkdown,
} from '@/components/organisms/RichTextEditor/utils';
import { useCommandPallete } from '@/hooks/useCommandPallete';
import useDialogs from '@/hooks/useDialogs';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import { useRichTextEditor } from '@/hooks/useRichTextEditor';
import useWebhooks from '@/hooks/useWebhooks';
import { WebhookAction } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import {
  charCountValidation,
  errorMessage,
  LENGTH_XXL,
} from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

type WebhookActionForm = {
  webhook_id: string;
  textValue: Node[];
};

const ToolkitWebhook = () => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { getContextVariables } = useDialogs(brainId);
  const contextVariables = useMemo(
    () => getContextVariables(),
    [getContextVariables]
  );

  const {
    searchResults,
    setSearchResults,
    searchString,
    setSearchString,
    noResultSearchLength,
    setNoResultSearchLength,
    lastSearchStringLength,
    setLastSearchStringLength,
  } = useCommandPallete({ options: contextVariables });

  const dispatch = useDispatch();
  const { webhooks } = useWebhooks(brainId);
  const { editor, triggerKey } = useRichTextEditor();
  const { type, actionId, webhook_id, fallback } = useSelector(
    (state: RootState) => {
      const selectedAction = selectSelectedAction(state) as WebhookAction;
      const text = get(selectedAction, 'fallback[0].texts[0]', '');
      return {
        type: selectedAction?.type,
        actionId: selectedAction?.action_id,
        webhook_id: selectedAction?.webhook_id,
        fallback: text ?? '',
      };
    },
    shallowEqual
  );
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  const fallBackValue = useMarkdownToSlate(fallback);

  const options = useMemo(() => {
    return webhooks?.map((w) => ({
      value: w.webhook_id,
      label: w.name,
    }));
  }, [webhooks]);

  const {
    formState: { errors },
    getValues,
    setValue,
    control,
    register,
    trigger,
    setFocus,
  } = useForm<WebhookActionForm>({
    mode: 'onChange',
    defaultValues: {
      webhook_id: webhook_id,
      textValue: fallBackValue,
    },
  });
  useFocusOnInput('webhook_id', errors, setFocus);
  useEffect(() => {
    if (webhook_id) {
      setValue('webhook_id', webhook_id);
    }
  }, [setValue, webhook_id]);

  const handleChange = useCallback(() => {
    const values = getValues() as WebhookActionForm;
    const fallBackString = richTextToMarkdown(values.textValue);

    dispatch(
      updateAction({
        actionId,
        action: {
          webhook_id: values.webhook_id,
          fallback: fallBackString
            ? [
                {
                  type: 'text',
                  texts: [fallBackString],
                },
              ]
            : [],
        },
      })
    );
  }, [actionId, dispatch, getValues]);

  const handleAddStringToEditor = useCallback(
    (value) => {
      addStringToEditor(
        editor,
        `{{${searchResults[value].value}}}`,
        triggerKey + searchString
      );
    },
    [editor, searchResults, searchString, triggerKey]
  );

  useEffect(() => {
    const fallbackErrorMessage = errors?.textValue?.message;

    dispatch(
      updateDialogAlerts({
        dialogAlerts: {
          alertType: 'error',
          id: actionId,
          nodeId: parentNodeId,
          title: t('actions.types.webhook'),
          body: fallbackErrorMessage,
          type,
          alertField: 'fallback',
        },
      })
    );
  }, [actionId, dispatch, errors?.textValue?.message, parentNodeId, t, type]);

  useEffect(() => {
    // Trigger validation on mount
    trigger();
  }, [trigger]);
  return (
    <ToolkitWrapper type={type}>
      <Select
        label={t('field.webhook_name')}
        placeholder={t('dialog.select_a_webhook')}
        name="webhook_id"
        onChange={handleChange}
        register={register('webhook_id', {
          required: errorMessage({
            field: {
              type: 'required',
            },
          }),
        })}
        error={!!errors?.webhook_id}
        errorMessage={errors?.webhook_id?.message}
        options={options}
        defaultValue={webhook_id ?? ''}
        key={actionId}
        size="small-full"
      />
      <Controller
        name="textValue"
        control={control}
        rules={{
          validate: {
            charsValidation: (v) =>
              charCountValidation<WebhookActionForm>(v, LENGTH_XXL, t, true),
          },
        }}
        render={({ field }) => {
          return (
            field.value && (
              <CommandPalette<OptionsPopperProps>
                triggerKey={triggerKey}
                searchString={searchString}
                setSearchString={setSearchString}
                renderPalette={(props) => {
                  return (
                    <OptionsPopper
                      {...props}
                      options={contextVariables}
                      searchResults={searchResults}
                      setSearchResults={setSearchResults}
                      noResultSearchLength={noResultSearchLength}
                      setNoResultSearchLength={setNoResultSearchLength}
                      lastSearchStringLength={lastSearchStringLength}
                      setLastSearchStringLength={setLastSearchStringLength}
                    />
                  );
                }}
                optionsLength={searchResults.length}
                handleOnSelectFromProps={(value) =>
                  handleAddStringToEditor(value)
                }
                renderItem={(props) => (
                  <RichTextEditor
                    {...props}
                    editor={editor}
                    value={field.value as Node[]}
                    onChange={(value) => {
                      if (isEqual(value, field.value)) {
                        return;
                      }
                      field.onChange(value);
                      handleChange();
                    }}
                    size="small"
                    label={t('dialog.fallback')}
                    maxCharacters={LENGTH_XXL}
                    options={contextVariables}
                    error={errors[field.name] as FieldError}
                  />
                )}
              />
            )
          );
        }}
      />
    </ToolkitWrapper>
  );
};

export default ToolkitWebhook;
