import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { callGet } from '@/api/fetcher';
import { AgentCounts, Counts, DepartmentCounts } from '@/models/chat';

export const endpoints = {
  counts: (deskId: string) =>
    `/www/api/v1/desks/${deskId}/conversations/counts`,
  agentCounts: (deskId: string) =>
    `/www/api/v1/desks/${deskId}/conversations/agent-counts`,
  departmentCounts: (deskId: string) =>
    `/www/api/v1/desks/${deskId}/conversations/department-counts`,
} as const;

const API = {
  listCounts: async (deskId: string): Promise<Counts> =>
    callGet(endpoints.counts(deskId)),
  listAgentCounts: async (deskId: string): Promise<AgentCounts> =>
    callGet(endpoints.agentCounts(deskId)),
  listDepartmentCounts: async (deskId: string): Promise<DepartmentCounts> =>
    callGet(endpoints.departmentCounts(deskId)),
} as const;

export const useConversationsCounts = (deskId: string) => {
  const { data: counts, status: countsStatus } = useQuery({
    queryKey: [endpoints.counts(deskId)],
    queryFn: () => API.listCounts(deskId),

    enabled: !!deskId,
    staleTime: 60 * 1000,
  });

  const { data: agentCounts, status: agentCountsStatus } = useQuery({
    queryKey: [endpoints.agentCounts(deskId)],
    queryFn: () => API.listAgentCounts(deskId),

    enabled: !!deskId,
    staleTime: 60 * 1000,
  });

  const { data: departmentCounts, status: departmentCountsStatus } = useQuery({
    queryKey: [endpoints.departmentCounts(deskId)],
    queryFn: () => API.listDepartmentCounts(deskId),

    enabled: !!deskId,
    staleTime: 60 * 1000,
  });

  const agentCountsSum = useMemo(
    () =>
      agentCounts?.agents?.reduce(
        (acc, agentCount) => acc + agentCount.open,
        0
      ),
    [agentCounts?.agents]
  );

  return {
    counts,
    countsStatus,
    agentCounts,
    agentCountsSum,
    agentCountsStatus,
    departmentCounts,
    departmentCountsStatus,
  };
};
