import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { popModal } from '@/redux/modals/actions';

import Modal from './Modal';

interface Props {
  message?: string;
  title?: string;
  onConfirm: () => Promise<void>;
}

const ModalPrompt = ({ message, title, onConfirm }: Props) => {
  const { t } = useTranslation();
  const _title = title || t('prompts.unsaved_changes.title');
  const _message = message || t('prompts.leave_warning');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleConfirm = useCallback(async () => {
    setIsLoading(true);
    try {
      await onConfirm();
    } finally {
      setIsLoading(false);
      dispatch(popModal());
    }
  }, [dispatch, onConfirm]);

  return (
    <Modal
      title={title}
      onPrimarySubmit={handleConfirm}
      danger
      primaryButtonText={t('common.leave')}
      isSubmitting={isLoading}
    >
      {message}
    </Modal>
  );
};

export default ModalPrompt;
