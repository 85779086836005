import { useTranslation } from 'react-i18next';

import { InformationFieldNew } from '../InformationField/InformationFieldNew';

type Props = {
  user_info: {
    user_name: string;
    user_id: string;
    channel_user_id: string;
    external_user_id: string;
    user_email: string;
  };
};

import styles from './UserSection.module.scss';

const UserSection = ({ user_info }: Props) => {
  const { t } = useTranslation();
  const {
    user_name = t('common.anonymous'),
    user_id,
    external_user_id,
    channel_user_id,
    user_email,
  } = user_info;

  return (
    <div className={styles.wrapper}>
      <InformationFieldNew
        type="text"
        label={t('common.name')}
        value={user_name}
      />
      <InformationFieldNew
        type="text"
        label={t('analytics.fields.user_id')}
        value={user_id}
      />
      <InformationFieldNew
        type="text"
        label={t('analytics.fields.user_email')}
        value={user_email}
      />
      <InformationFieldNew
        type="text"
        label={t('analytics.fields.channel_user_id')}
        value={channel_user_id}
      />
      <InformationFieldNew
        type="text"
        label={t('analytics.fields.external_user_id')}
        value={external_user_id}
      />
    </div>
  );
};

export default UserSection;
