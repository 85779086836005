import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const LinkExternal = ({
  size = 16,
  color = 'var(--icon-default-blue)',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      viewBox="0 0 24 24"
      className={disabledClass}
      width={size}
      height={size}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      shapeRendering="geometricPrecision"
    >
      <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6" />
      <path d="M15 3h6v6" />
      <path d="M10 14L21 3" />
    </svg>
  );
};

export default LinkExternal;
