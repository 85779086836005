import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { billingEndpoints as endpoint } from '@/api/endpoints';
import { callGet } from '@/api/fetcher';
import { BillingState } from '@/modules/billing/model';
import { selectAccountSlug } from '@/redux/session/selectors';
import { SIGNUP_TRIAL_IN_DAYS } from '@/util/constants';

export const TRIAL_DAYS = SIGNUP_TRIAL_IN_DAYS;

export const getPercentage = (days_left: number) => {
  const percentage = ((TRIAL_DAYS - days_left) / TRIAL_DAYS) * 100;
  if (days_left <= 0) {
    return 100;
  }
  if (percentage >= 100 || percentage <= 0) {
    return 0;
  }
  return percentage;
};

export const useBillingState = () => {
  const accountSlug = useSelector(selectAccountSlug);
  const { data: billingState } = useQuery<BillingState>({
    queryKey: [endpoint.billingState, accountSlug],
    queryFn: () => callGet(endpoint.billingState),
    enabled: !!accountSlug,
  });

  return billingState;
};
