import { useSelector } from 'react-redux';

import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import { useIntegrations } from '@/hooks/useIntegrations';
import { IntercomIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId, selectIntegrationId } from '@/redux/session/selectors';

import IntercomRedirectFieldset from './fieldsets/IntercomRedirectFieldset';

interface Props {
  onAuthClick: () => void;
}

const IntercomOauth = ({ onAuthClick }: Props) => {
  const deskId = useSelector(selectDeskId);
  const integrationId = useSelector(selectIntegrationId);
  const { integration } = useIntegrations<IntercomIntegration>(
    deskId,
    integrationId
  );

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  return (
    <div>
      <IntercomRedirectFieldset
        onAuthClick={onAuthClick}
        disabled={!canWrite}
      />
      <DeleteFieldset integration={integration} disabled={!canWrite} />
    </div>
  );
};

export default IntercomOauth;
