import { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { TestNumber, WhatsAppIntegration } from '@/models/integration';
import { ActionType } from '@/modules/rules/model';

import useBrains from './useBrains';
import { getTestRulePayload } from './useWhatsappTesting';
import {
  useRules,
  API as rulesAPI,
  onRuleUpdated,
  onRuleRemoved,
} from '../modules/rules/hooks/useRules';

const useWhatsappTestingSettings = (deskId: string, integrationId: string) => {
  const { t } = useTranslation();

  const { rules } = useRules(deskId);
  const { brains } = useBrains();
  const queryClient = useQueryClient();

  const ruleToBrain = useMemo(() => {
    const r2b: Record<string, { name: string; version: number; id: string }> =
      {};

    rules?.rules?.forEach((rule) => {
      const action = rule?.actions?.find(
        (action) =>
          action.type === ActionType.ASSIGN_BRAIN && action?.brain_parent_id
      );
      if (action) {
        // find brain name
        const brain = brains?.find(
          (brain) => brain.brain_parent_id === action.brain_parent_id
        );

        if (brain) {
          r2b[rule.rule_id] = {
            name: brain.name,
            version: brain.version,
            id: brain.brain_parent_id,
          };
        }
      }
    });

    return r2b;
  }, [brains, rules.rules]);

  const updateConnectedRules = useCallback(
    (test_numbers: TestNumber[]) => {
      test_numbers?.forEach(async (tn) => {
        if (!tn.rule_id) {
          return;
        }

        const brainInfo = ruleToBrain[tn.rule_id];
        const existingRule = rules.rules.find(
          (rule) => rule.rule_id === tn.rule_id
        );

        const updatePayload = getTestRulePayload(
          t,
          { ...tn, desk_id: deskId, integration_id: integrationId },
          brainInfo.id,
          brainInfo.name,
          existingRule,
          true
        );
        const resp = await rulesAPI.updateRule(deskId, updatePayload);
        onRuleUpdated(queryClient, resp, deskId);
      });
    },
    [deskId, integrationId, queryClient, ruleToBrain, rules.rules, t]
  );

  const deleteUnconnectedRules = useCallback(
    (integration: WhatsAppIntegration, test_numbers: TestNumber[]) => {
      const rulesToDelete = [];

      integration?.config?.test_numbers?.forEach((existingTN) => {
        if (!test_numbers.find((tn) => tn.rule_id === existingTN.rule_id)) {
          rulesToDelete.push(existingTN.rule_id);
        }
      });

      rulesToDelete.forEach(async (rule_id) => {
        await rulesAPI.deleteRule(deskId, rule_id);
        onRuleRemoved(queryClient, deskId, rule_id);
      });
    },
    [deskId, queryClient]
  );

  return { ruleToBrain, updateConnectedRules, deleteUnconnectedRules };
};

export default useWhatsappTestingSettings;
