import { IconInterface } from '@/models/icon';

export const Anthropic = ({ size = 24 }: IconInterface) => (
  <svg
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <g clipPath="url(#aAnthropic)">
      <circle cx="50" cy="50" r="50" fill="#CC9B7A" />
      <g clipPath="url(#b)">
        <path
          d="M65.653 24H54.48l20.374 52h11.173L65.653 24Zm-32.279 0L13 76h11.393l4.166-10.92h21.315L54.041 76h11.393L45.06 24H33.374Zm-1.13 31.423 6.973-18.275 6.972 18.275H32.245Z"
          fill="#000"
        />
      </g>
    </g>
    <defs>
      <clipPath id="aAnthropic">
        <path fill="#fff" d="M0 0h100v100H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" transform="translate(13 24)" d="M0 0h74v52H0z" />
      </clipPath>
    </defs>
  </svg>
);
