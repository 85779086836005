import { useState, useMemo, useCallback } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import useBrains from '@/hooks/useBrains';
import useDesks from '@/hooks/useDesks';
import useMembers from '@/hooks/useMembers';
import useUser from '@/hooks/useUser';
import { AuditLog } from '@/models/auditLogs';

import TileActivityLog from '../TileActivityLog';

const MINIMUM_GROUPED_ACTIONS = 3;

interface Props {
  groupedLog: AuditLog & { nested: [] };
}

const TileGroupedActivityLog = ({
  groupedLog: {
    log_id,
    source_id,
    source_name,
    source_type,
    target_id,
    target_name,
    target_type,
    extra,
    verb,
    brain_id,
    desk_id,
    created,
    nested,
  },
}: Props) => {
  const isGrouped = nested.length >= MINIMUM_GROUPED_ACTIONS;
  const { t } = useTranslation();
  const [showGrouped, setShowGrouped] = useState(isGrouped);
  const { members } = useMembers();
  const { user } = useUser();
  const { desks } = useDesks();
  const { brains } = useBrains();

  // who is doing the action
  const getSubject = useCallback(
    (sourceId, sourceName, source_type) => {
      if (source_type === 'user') {
        return sourceId === user?.user_id
          ? `${t('activityLogs.you')}`
          : sourceName;
      }
      return sourceName;
    },
    [t, user?.user_id]
  );

  // memoize avatar urls in a hash table
  const memoAvatars = useMemo(() => {
    if (!members) {
      return {};
    }
    return members.reduce((acc, member) => {
      acc[member.user_id] = member.avatar || null;
      return acc;
    }, {});
  }, [members]);

  // memoize desks in a hash table
  const memoDesks = useMemo(() => {
    if (!desks) {
      return {};
    }
    return desks.reduce((acc, desk) => {
      acc[desk.desk_id] = desk.name;
      return acc;
    }, {});
  }, [desks]);

  // memoize brains
  const memoBrains = useMemo(() => {
    if (!brains) {
      return {};
    }
    return brains.reduce((acc, brain) => {
      acc[brain.brain_id] = brain.name;
      return acc;
    }, {});
  }, [brains]);

  const getAvatar = useCallback(
    (source_id) => {
      return <Avatar src={memoAvatars[source_id]} />;
    },
    [memoAvatars]
  );

  const getDesk = useCallback(
    (deskId) => {
      return memoDesks[deskId];
    },
    [memoDesks]
  );

  const getBrain = useCallback(
    (brainId) => {
      return memoBrains[brainId];
    },
    [memoBrains]
  );

  const handleToggle = () => {
    setShowGrouped((showGrouped) => !showGrouped);
  };

  return (
    <>
      <TileActivityLog
        key={log_id}
        avatar={getAvatar(source_id)}
        subject={getSubject(source_id, source_name, source_type)}
        verb={t(`activityLogs.${verb}`)}
        targetType={target_type}
        targetName={target_name}
        brain={getBrain(brain_id)}
        brainId={brain_id}
        deskId={desk_id}
        desk={getDesk(desk_id)}
        timestamp={moment(created).fromNow()}
        targetId={target_id}
        extra={extra}
        handleToggle={handleToggle}
        isGrouped={isGrouped}
        groupedItems={nested.length}
        toggleGroupedItems={showGrouped}
      />
      {!showGrouped &&
        nested.map(
          ({
            source_id,
            source_name,
            source_type,
            target_id,
            target_name,
            target_type,
            extra,
            verb,
            brain_id,
            desk_id,
            created,
            log_id,
          }) => {
            return (
              <TileActivityLog
                key={log_id}
                avatar={getAvatar(source_id)}
                subject={getSubject(source_id, source_name, source_type)}
                verb={t(String(`activityLogs.${verb}`))}
                targetType={target_type}
                targetName={target_name}
                brain={getBrain(brain_id)}
                brainId={brain_id}
                deskId={desk_id}
                desk={getDesk(desk_id)}
                timestamp={moment(created).fromNow()}
                targetId={target_id}
                extra={extra}
              />
            );
          }
        )}
    </>
  );
};

export default TileGroupedActivityLog;
