import { Icon } from '@/models/icon';

const ToolWebview = ({
  size = 48,
  color = 'var(--icon-default-blue)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        d="M1.417 5.833H14.75M13.523 2.5H2.477c-.678 0-1.227.643-1.227 1.438v8.624c0 .795.55 1.438 1.227 1.438h11.046c.678 0 1.227-.643 1.227-1.438V3.938c0-.795-.55-1.438-1.227-1.438ZM3.417 7.833h9.333M3.25 10h7.334M3.25 12h4.667"
      />
      <mask
        id="a2121"
        width="2"
        height="2"
        x="2"
        y="3"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path fill={color} d="M3.251 4a.5.5 0 1 1-1 .003.5.5 0 0 1 1-.003Z" />
      </mask>
      <g fill={color} mask="url(#a2121)">
        <path d="M3.251 4a.5.5 0 1 1-1 .003.5.5 0 0 1 1-.003Z" />
        <path d="M2.251 4.002a.5.5 0 0 1 .499-.501l.005 2a1.5 1.5 0 0 0 1.496-1.504l-2 .005Zm.499-.501A.5.5 0 0 1 3.251 4l-2 .005a1.5 1.5 0 0 0 1.504 1.496l-.005-2ZM3.251 4a.5.5 0 0 1-.499.501l-.005-2a1.5 1.5 0 0 0-1.496 1.504l2-.005Zm-.499.501a.5.5 0 0 1-.501-.499l2-.005a1.5 1.5 0 0 0-1.504-1.496l.005 2Z" />
      </g>
      <mask
        id="b"
        width="2"
        height="2"
        x="5"
        y="3"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path fill={color} d="M6.25 4a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      </mask>
      <g fill={color} mask="url(#b)">
        <path d="M6.25 4a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
        <path d="M5.25 4a.5.5 0 0 1 .5-.5v2A1.5 1.5 0 0 0 7.25 4h-2Zm.5-.5a.5.5 0 0 1 .5.5h-2a1.5 1.5 0 0 0 1.5 1.5v-2Zm.5.5a.5.5 0 0 1-.5.5v-2A1.5 1.5 0 0 0 4.25 4h2Zm-.5.5a.5.5 0 0 1-.5-.5h2a1.5 1.5 0 0 0-1.5-1.5v2Z" />
      </g>
      <mask
        id="c"
        width="2"
        height="2"
        x="3"
        y="3"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path fill={color} d="M4.75 3.995a.5.5 0 1 1-1 .01.5.5 0 0 1 1-.01Z" />
      </mask>
      <g fill={color} mask="url(#c)">
        <path d="M4.75 3.995a.5.5 0 1 1-1 .01.5.5 0 0 1 1-.01Z" />
        <path d="M3.75 4.005a.5.5 0 0 1 .495-.505l.02 2A1.5 1.5 0 0 0 5.75 3.985l-2 .02Zm.495-.505a.5.5 0 0 1 .505.495l-2 .02A1.5 1.5 0 0 0 4.265 5.5l-.02-2Zm.505.495a.5.5 0 0 1-.495.505l-.02-2A1.5 1.5 0 0 0 2.75 4.015l2-.02Zm-.495.505a.5.5 0 0 1-.505-.495l2-.02A1.5 1.5 0 0 0 4.235 2.5l.02 2Z" />
      </g>
    </svg>
  );
};

export default ToolWebview;
