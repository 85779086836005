import { useCallback } from 'react';

import { TypographyVariant } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { EditableText } from '@/components/atoms/EditableText/EditableText';
import Header from '@/components/organisms/Header/Header';
import { getRestrictedNames } from '@/util/util';
import { LENGTH_XS, getEditableTextValidationSchema } from '@/util/validator';

import { useBroadcasts } from '../../hooks/useBroadcasts';
import { IconBroadcast } from '../../icons/Broadcast';
import { actions } from '../../redux/actions';
import { selectBroadcast } from '../../redux/selectors';

interface Props {
  isReadOnly: boolean;
}
const BroadcastHeader = ({ isReadOnly }: Props) => {
  const { t } = useTranslation();
  const { broadcastId } = useParams();
  const dispatch = useDispatch();
  const { broadcasts, isBroadcastLoading } = useBroadcasts(broadcastId);

  const broadcast = useSelector(selectBroadcast);
  const restrictedValues = getRestrictedNames(
    broadcasts,
    broadcasts?.find((b) => b.broadcast_id === broadcast.broadcast_id)?.name ??
      ''
  );
  const validationSchema = getEditableTextValidationSchema(
    LENGTH_XS,
    restrictedValues,
    t('broadcasts.broadcast_name')
  );

  const handleEditNameSubmit = useCallback(
    (text: string) => {
      dispatch(actions.updateBroadcast({ name: text }));
    },
    [dispatch]
  );

  const editableTextProps = {
    defaultValue: broadcast?.name,
    onSubmit: handleEditNameSubmit,
    variant: 'h2-semi-bold' as TypographyVariant,
    canEdit: !isReadOnly,
    validationSchema,
  };

  return (
    <Header>
      <Header.BreadCrumbs />
      <Header.Body>
        <Header.Title
          title={
            <EditableText<typeof validationSchema> {...editableTextProps} />
          }
          icon={<IconBroadcast size={24} color="var(--icon-default-gray)" />}
          isLoading={isBroadcastLoading}
        />
      </Header.Body>
    </Header>
  );
};

export default BroadcastHeader;
