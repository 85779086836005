import { MutableRefObject, useCallback, useRef } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Trash from '@/components/atoms/Icons/Trash';
import TextareaAutosize from '@/components/atoms/TextareaAutosize/TextareaAutosize';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { RootState } from '@/models/state';
import * as actions from '@/redux/expressions/actions';
import { isKeyEnter, noop, resolveBrainsPath } from '@/util/util';

import styles from './TrainingPhrase.module.scss';

type Props = {
  id: string;
  text: string;
  deleteExpression: (arg: unknown) => void;
  createExpression: (arg: unknown) => void;
  updateExpression: (arg: unknown) => void;
  readOnly: boolean;
  isTypingField?: boolean;
  slug?: string;
  innerRef?: MutableRefObject<HTMLTextAreaElement>;
  onNewExpressionCreated?: (text: string) => void;
  expressions?: string[];
};

//TODO find duplicate expression
const isDuplicate = false;
const duplicateIntent = {
  intentName: '',
  brainId: '',
};

export const TrainingPhrase = ({
  id = null,
  text = '',
  deleteExpression = noop,
  createExpression = noop,
  updateExpression = noop,
  readOnly = false,
  isTypingField = false,
  slug = '',
  innerRef,
  onNewExpressionCreated,
  expressions,
}: Props) => {
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const onBlur = useCallback(
    (e) => {
      const lines = e.target.value.split(/\s*\n\s*/);
      const removedDuplicates = lines.filter(
        (line, pos, self) => self.indexOf(line) == pos
      );

      removedDuplicates.forEach((line) => {
        const newText = line.trim();
        if (text === '' && newText !== '') {
          const isDuplicate = expressions?.find(
            (e) => e.toLowerCase() === newText.toLowerCase()
          );
          if (!isDuplicate) {
            if (onNewExpressionCreated) {
              onNewExpressionCreated(newText);
            }
            createExpression({ newText });
          }
        } else if (text !== '' && newText === '') {
          deleteExpression({ id });
        } else if (text !== newText && text !== '') {
          updateExpression({ id, newText });
        }
        if (text === '') {
          inputRef.current.value = '';
        }
      });
    },
    [
      text,
      expressions,
      onNewExpressionCreated,
      createExpression,
      deleteExpression,
      id,
      updateExpression,
    ]
  );

  const onDeleteHandle = useCallback(() => {
    deleteExpression({ id });
  }, [deleteExpression, id]);

  const onKeyUp = useCallback((e) => {
    if (isKeyEnter(e)) {
      e.target.blur();
      e.target.focus();
    }
  }, []);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (isKeyEnter(e)) {
        e.preventDefault();
        return false;
      }
    },
    []
  );

  return (
    <>
      <li className={styles.training_phrase}>
        <TextareaAutosize
          shouldMinimize
          maxRows={1}
          inputRef={(node) => {
            inputRef.current = node;
            if (innerRef) {
              innerRef.current = node;
            }
          }}
          defaultValue={text}
          placeholder={t('training_phrases.placeholder')}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          readOnly={readOnly}
          hasErrors={isDuplicate}
          dataTestId={'e__input'}
          onKeyDown={onKeyDown}
        />
        {!readOnly && text && (
          <IconButton
            tabIndex={-1}
            ariaLabel={t('common.delete')}
            onClick={onDeleteHandle}
          >
            <Trash />
          </IconButton>
        )}
      </li>

      {isTypingField && isDuplicate && (
        <div className={styles.errorMessage}>
          <Typography variant="label-regular">
            {t('training_phrases.error')}{' '}
            <Link
              to={resolveBrainsPath(
                `/${slug}/brains/${duplicateIntent.brainId}/intents/${duplicateIntent.intentName}`,
                ai_agents
              )}
            >
              {duplicateIntent.intentName}
            </Link>
          </Typography>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, { id }) => {
  return {
    text: state.expressions.byId[id]?.text ?? '',
    expressions: state.expressions.allIds?.map(
      (exp: string) => state.expressions.byId[exp].text
    ),
  };
};

const mapDispatchToProps = {
  deleteExpression: actions.deleteExpression,
  createExpression: actions.createExpression,
  updateExpression: actions.updateExpression,
};

TrainingPhrase.displayName = 'TrainingPhrase';

export default connect(mapStateToProps, mapDispatchToProps)(TrainingPhrase);
