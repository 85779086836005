import { Control, FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { errorMessage, whatsappTestNumber } from '@/util/validator';

import Trash from '../Icons/Trash';
import Input from '../Input/Input';
import PhoneInput from '../Input/PhoneInput';

import styles from './TestNumber.module.scss';

type TestNumberProps<FormType> = {
  register: UseFormRegister<FormType>;
  control: Control<FormType>;
  readOnly?: boolean;
  prefix?: string;
  brainName: string;
  brainLink: string;
  onDelete: () => void;
  errors?: FieldErrors;
};

const TestNumber = <FormType,>({
  register,
  control,
  readOnly,
  prefix,
  brainName,
  brainLink,
  onDelete,
  errors,
}: TestNumberProps<FormType>) => {
  const { t } = useTranslation();
  const name = `${prefix}phone_number` as Path<FormType>;

  return (
    <div className={styles.container}>
      <div className={styles.inputs}>
        <Input
          label={t('integrations.whatsapp.testing.name')}
          {...register(
            `${prefix}label` as Path<FormType>,
            whatsappTestNumber.label
          )}
          trimValue
          readOnly={readOnly}
          errorMessage={errorMessage({
            field: errors?.label,
            maxLength: whatsappTestNumber.label.maxLength,
          })}
        />
        <PhoneInput
          label={t('integrations.whatsapp.testing.phone_num')}
          control={control}
          readOnly={readOnly}
          name={name}
          rules={whatsappTestNumber.phone_number}
          errorMessage={errorMessage({
            field: errors?.phone_number,
          })}
        />
        <div>
          <IconButton
            disabled={readOnly}
            onClick={onDelete}
            ariaLabel="delete-test-number"
          >
            <Trash />
          </IconButton>
        </div>
      </div>
      <div className={styles.footer}>
        <p className={styles.brain}>
          {t('common.brainCap')}:{' '}
          {brainName ? <NavLink to={brainLink}>{brainName}</NavLink> : '-'}
        </p>
      </div>
    </div>
  );
};

export default TestNumber;
