import { Icon } from '@/models/icon';

const BarChart = ({ size = 16, color = 'var(--icon-default-blue)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.167 13.333V6.667M8.167 13.333V2.667M4.167 13.333v-4"
      />
    </svg>
  );
};

export default BarChart;
