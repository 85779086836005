import { forwardRef, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Option } from '@/modules/rules/model';
import { addTrigger } from '@/modules/rules/redux/actions';

import styles from './OptionsModal.module.scss';

type Props = {
  options: Option[];
  index?: number;
  handleOptionClick: () => void;
};

const TriggerOptionsModal = forwardRef<HTMLDivElement, Props>(
  ({ options, index = 0, handleOptionClick }, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleClick = useCallback(
      (x) => {
        const { type } = x;
        const data = {
          type,
          isOpen:
            type === 'first_message' || type === 'any_user_message'
              ? false
              : true,
        };
        dispatch(addTrigger(data));

        handleOptionClick();
      },
      [dispatch, handleOptionClick]
    );

    return (
      <div className={styles.modal} ref={ref}>
        {options?.map((x) => (
          <span
            key={x.type}
            className={styles.option}
            onClick={() => handleClick({ type: x.type, index })}
            onKeyDown={() => handleClick({ type: x.type, index })}
            role="button"
            tabIndex={0}
          >
            {t(`rules.${x.type}`)}
          </span>
        ))}
      </div>
    );
  }
);

TriggerOptionsModal.displayName = 'TriggerOptionsModal';

export default TriggerOptionsModal;
