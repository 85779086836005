import { Icon } from '@/models/icon';

const Analytics = ({ size = 32, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M23.675 18.847A8.333 8.333 0 1 1 12.667 7.964" />
      <path d="M24.333 16A8.333 8.333 0 0 0 16 7.667V16h8.333Z" />
    </svg>
  );
};

export default Analytics;
