import { useCallback, useMemo, useState } from 'react';

import Box from '@mui/material/Box/Box';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete, {
  Option,
} from '@/components/atoms/AutoComplete/AutoComplete';
import Input from '@/components/atoms/Input/Input';
import Modal from '@/components/organisms/Modals/Modal';
import { useAccount } from '@/hooks/useAccount';
import RoutingSelect from '@/modules/departments/components/RoutingSelect/RoutingSelect';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import {
  Form,
  DepartmentRouting,
  DepartmentUser,
} from '@/modules/departments/models';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { selectDeskId } from '@/redux/session/selectors';
import { errorMessage, invitationRules, ruleRules } from '@/util/validator';

type FormType = {
  name: string;
  users: DepartmentUser[];
  routing: DepartmentRouting;
};

export const ModalDepartmentCreate = () => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const { agents } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const { createDepartment, addMembersToDepartment } = useDepartments(deskId);

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
    control,
    getValues,
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      users: [],
      routing: DepartmentRouting.BALANCED,
    },
  });
  const dispatch = useDispatch();

  const memberOptions = useMemo(
    () =>
      agents?.reduce((acc, user) => {
        acc.push({
          value: user.agent_id,
          label: user.name,
          description: user.email,
        });
        return acc;
      }, [] as Option[]),
    [agents]
  );

  const onSubmit = useCallback(
    (dpt) => {
      const department = {
        name: dpt.name,
        routing: getValues().routing,
      };
      const user_ids = dpt.users.map((m) => m.value);

      createDepartment(department, {
        onSuccess: (resp) => {
          const payload = {
            department_id: resp.department_id,
            user_ids,
          };
          addMembersToDepartment(payload, {
            onSuccess: () => {
              dispatch(popModal());
            },
            onError: (err) => {
              dispatch(addTemporalToast('error', err.message));
              setIsLoading(false);
            },
          });
        },
        onError: (err) => {
          dispatch(addTemporalToast('error', err.message));
          setIsLoading(false);
        },
      });
    },
    [addMembersToDepartment, createDepartment, dispatch, getValues]
  );

  const disabled = !isDirty || !isValid;

  return (
    <Modal
      title={t('departments.add_department')}
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('common.create')}
      primaryButtonDisable={disabled}
      autoFocus
      isSubmitting={isLoading}
    >
      <Box mt={1}>
        <form autoComplete="off">
          <Input
            name="name"
            label={t('common.name')}
            register={register('name', ruleRules.name)}
            errorMessage={errorMessage({
              field: errors.name,
              maxLength: ruleRules.name.maxLength,
            })}
            placeholder={t('common.name_placeholder')}
            autoFocus
          />
          <RoutingSelect control={control as unknown as Control<Form>} />
          <div className="input__container">
            <Autocomplete<FormType>
              control={control}
              name="users"
              label={t('common.members')}
              disabled={!agents}
              options={memberOptions}
              placeholder={t('departments.agents_placeholder')}
              multiple
              size="large"
              labelLarge
              disableAddNew
              variant="secondary"
              error={!!errors?.users?.message}
              errorMessage={errorMessage({
                field: errors.users,
              })}
              rules={invitationRules(t).roles}
            />
          </div>
        </form>
      </Box>
    </Modal>
  );
};
