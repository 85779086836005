import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ChatState, ChatStateType } from '@/models/chat';

const defaultState: ChatState = {
  activeTab: ChatState.ME,
  messageSound: null,
  sideBarOpen: false,
  view: {
    selectedStatus: 'open',
    selectedAgent: '',
    selectedDepartment: '',
    selectedBrain: '',
    selectedSort: 'newest',
    title: 'all',
  },
};

const slice = createSlice({
  name: 'chat',
  initialState: defaultState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<ChatStateType>) => {
      const activeTab = action.payload;
      state.activeTab = activeTab;
    },
    setView: (state, action: PayloadAction<ChatState['view']>) => {
      const view = action.payload;
      state.view = { ...state.view, ...view };
    },
    setMessageSound: (state, action) => {
      const messageSound = action.payload;
      state.messageSound = messageSound;
    },
    setSideBarOpen: (state, action: PayloadAction<boolean>) => {
      const sideBarOpen = action.payload;
      state.sideBarOpen = sideBarOpen;
    },
  },
});

export const { setMessageSound, setSideBarOpen, setActiveTab, setView } =
  slice.actions;

export default slice.reducer;
