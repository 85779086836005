import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Maximize from '@/components/atoms/Icons/Maximize';
import { previewIconByType } from '@/redux/dialogs/helper';
import { urlPattern, urlSchema } from '@/util/validator';

import styles from './Preview.module.scss';

const Preview = ({ action }) => {
  const { t } = useTranslation();
  const { url, name, type } = action;

  const renderImage = () => {
    switch (type) {
      case 'video':
        return <video src={url} muted />;
      case 'image':
        return <img alt={name} src={url} referrerPolicy="no-referrer" />;
      default:
        return previewIconByType(type);
    }
  };

  // Cases like https://www.google.com pass
  if (urlPattern.test(url)) {
    return (
      <div className={styles.wrapper}>
        {renderImage()}
        <a
          href={url}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
          className={styles.wrapper__label}
          rel="noopener"
        >
          <Maximize size={14} color="var(--icon-default-blue)" />
          <Typography color="var(--text-default-blue)" variant="body-semi-bold">
            {t('dialog.actions.preview')}
          </Typography>
        </a>
      </div>
    );
  }

  // Cases like {{$url}} or https://example.com/{{\$dynamicPath}}/resource pass
  if (urlSchema.isValidSync(url)) {
    return (
      <div className={styles.wrapper}>
        <Typography color="var(--text-default-blue)" variant="body">
          {url}
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.empty}>
      <Typography
        color="var(--text-default-gray-light)"
        variant="label-semi-bold"
      >
        {t('common.empty')}
      </Typography>
    </div>
  );
};

export default Preview;
