import { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { connect } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { CarouselCard } from '@/models/action';

import s from './card.module.scss';

type Props = {
  card: CarouselCard;
  className?: string;
  onPostback?: (text: string) => void;
  isReplay: boolean;
  isChat?: boolean;
};

interface RootState {
  tryIt: { isReplay: boolean };
}
const Card = ({
  className = '',
  card,
  onPostback,
  isReplay,
  isChat = false,
}: Props) => {
  const {
    media,
    buttons,
    title,
    subtitle,
    default_action: defaultAction,
  } = card;
  const resolvedMedia = useMemo(() => {
    switch (media.type) {
      case 'image':
        return <img src={media.url} alt={title} />;
      case 'video':
        return <video src={media.url} controls />;
      default:
        return null;
    }
  }, [media.type, media.url, title]);

  const getButtonProps = useCallback(
    (
      button: CarouselCard['buttons'][0]
    ): Partial<React.ComponentPropsWithoutRef<typeof Button>> => {
      if (isReplay) {
        return {};
      }
      switch (button.type) {
        case 'postback':
          return { onClick: () => onPostback && onPostback(button.value) };
        case 'url':
          return {
            isLink: true,
            href: button.url,
            target: '_blank',
            rel: 'noopener',
            onClick: (e) => (isChat ? e.preventDefault() : {}),
          };
        case 'phone':
          return {
            isLink: true,
            href: `tel:${button.value}`,
            target: '_blank',
            rel: 'noopener',
          };
        case 'webview':
          return {};
        default:
          console.error(
            `Unsupported button type with props ${JSON.stringify(
              button,
              null,
              2
            )}`
          );
          return {};
      }
    },
    [isChat, isReplay, onPostback]
  );

  const content = useMemo(
    () => (
      <div>
        <div className={cn(s.mediaContainer, { [s.isChat]: isChat })}>
          {resolvedMedia}
        </div>
        <div className={s.headingContainer}>
          <Typography
            variant="label-regular"
            component="h4"
            color="var(--text-default-black)"
          >
            {title}
          </Typography>
          <Typography
            variant="label-regular"
            component="p"
            color="var(--text-default-gray)"
          >
            {subtitle}
          </Typography>
        </div>
      </div>
    ),
    [isChat, resolvedMedia, subtitle, title]
  );

  return (
    <div
      className={cn(className, s.card, {
        [s.isChat]: isChat,
      })}
    >
      {defaultAction ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Button disabled={isChat} {...getButtonProps(defaultAction)} unstyled>
          {content}
        </Button>
      ) : (
        content
      )}
      <div className={s.buttonsContainer}>
        {buttons.map((button, i) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Button
            disabled={isChat}
            preventOverflow={isChat}
            // eslint-disable-next-line react/no-array-index-key
            key={`${i}_${button.type}`}
            {...getButtonProps(button)}
            className={cn(s.button, { [s.buttonDisabled]: isReplay })}
          >
            {button.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isReplay: state.tryIt.isReplay,
  };
};

export default connect(mapStateToProps)(Card);
