/* eslint-disable @typescript-eslint/no-explicit-any */
import fetch from 'cross-fetch';

import { API_URL, X_MOVEO_ACCOUNT_SLUG } from '@/util/constants';

declare const window: any;

type RequestOptions = RequestInit & {
  responseType?: 'json' | 'text' | 'blob' | 'arrayBuffer';
};

type ExtraOptions = {
  includeHeaders?: boolean;
};

/**
 * Convenience methods for calling the APIs
 *
 * includes .get(), .put(), .post(), and .delete() methods
 *
 * @param path - The API Path
 * @param [opts] - The options
 */
export const fetcher = async (
  path: string,
  opts: RequestOptions = {},
  extraOptions?: ExtraOptions
): Promise<any> => {
  const { includeHeaders } = extraOptions || {};

  const slugHeader: Record<string, string> = {};
  if (window.X_MOVEO_ACCOUNT_SLUG) {
    slugHeader[X_MOVEO_ACCOUNT_SLUG] = window.X_MOVEO_ACCOUNT_SLUG;
  }

  const options: RequestOptions = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Accept-Language': window._language ?? 'en',
      'Content-Type': opts.body ? 'application/json' : '',
      ...slugHeader,
    },
    responseType: 'json',
    ...opts,
  };

  try {
    const response = await fetch(`${API_URL}${path}`, options);

    if (response.ok) {
      let data: any;

      if (response.status === 204) {
        data = {};
      } else {
        switch (options.responseType) {
          case 'text':
            data = await response.text();
            break;
          case 'blob':
            data = await response.blob();
            break;
          case 'arrayBuffer':
            data = response;
            break;
          case 'json':
          default:
            data = await response.json();
            break;
        }
      }

      if (includeHeaders) {
        // Extract desired headers
        const headers: Record<string, string> = {};
        response.headers.forEach((value, key) => {
          headers[key] = value;
        });
        return { data, headers };
      }

      return data;
    }

    // Check if the response is 401 and reloading the page
    // This prevent `useQuery` errors from happening without the user realizing.
    if (response.status === 401 && !path.endsWith('auth/user')) {
      console.error('Reloading the page due to a 401 response');
      window.location.reload();
      return; // Early exit
    }

    // Attempt to parse error response
    let errorData: any;
    try {
      errorData = await response.json();
    } catch (error) {
      // in case the json cant be parsed
      throw Object.assign(
        new Error(
          `Network request failed with: ${response.status} - ${response.statusText}. Please try again later.`
        ),
        {
          statusText: response.statusText,
          statusCode: response.status,
          requestId: response.headers.get('x-request-id'),
          originalError: error,
        }
      );
    }

    const errMessage =
      errorData.error || errorData.description || JSON.stringify(errorData);
    throw Object.assign(new Error(errMessage), errorData, {
      statusText: response.statusText,
      statusCode: response.status,
      requestId: response.headers.get('x-request-id'),
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const callGet = fetcher;

export const callPost = (
  path: string,
  body: any,
  options: any = {},
  extraOptions: ExtraOptions = {}
): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'POST',
      body: JSON.stringify(body),
    }),
    extraOptions
  );

export const callUpload = (
  path: string,
  body: any,
  options: any = {
    headers: { Accept: 'application/json' },
  }
): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'POST',
      body,
    })
  );

export const callPut = (
  path: string,
  body: any,
  options: any = {}
): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
  );

export const callPatch = (
  path: string,
  body: any,
  options: any = {}
): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
  );

export const callDelete = (path: string, options: any = {}): Promise<any> =>
  fetcher(
    path,
    Object.assign(options, {
      method: 'DELETE',
    })
  );
