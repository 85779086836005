import { Icon } from '@/models/icon';

const UnorderedList = ({
  size = 14,
  color = 'var(--icon-default-black)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill={color}
        d="M.992 14c-.273 0-.506-.113-.7-.338A1.193 1.193 0 0 1 0 12.857c0-.327.097-.607.292-.84.194-.234.427-.35.7-.35.259 0 .483.116.67.35.188.233.282.513.282.84 0 .31-.094.58-.281.805-.188.225-.412.338-.671.338Zm2.897-.467v-1.4H14v1.4H3.889ZM.992 8.167c-.273 0-.506-.113-.7-.339A1.225 1.225 0 0 1 0 7c0-.327.097-.603.292-.828.194-.226.427-.339.7-.339.259 0 .483.117.67.35.188.234.282.506.282.817 0 .311-.094.583-.281.817-.188.233-.412.35-.671.35ZM3.889 7.7V6.3H14v1.4H3.889ZM.972 2.333a.867.867 0 0 1-.69-.338A1.25 1.25 0 0 1 0 1.167C0 .84.094.564.282.338A.867.867 0 0 1 .972 0c.272 0 .503.113.69.338.188.226.282.502.282.829 0 .326-.094.602-.281.828a.867.867 0 0 1-.69.338Zm2.917-.466v-1.4H14v1.4H3.889Z"
      />
    </svg>
  );
};

export default UnorderedList;
