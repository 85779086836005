import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useTitle } from 'react-use';

import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useDesks from '@/hooks/useDesks';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { setDeskId } from '@/redux/session/actions';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import DeleteDeskFieldset from './fieldsets/DeleteDeskFieldset';
import DeskIdFieldset from './fieldsets/DeskIdFieldset';
import HumanChatFieldset from './fieldsets/HumanChatFieldset';
import InformationFieldset from './fieldsets/InformationFieldset';
import KeepAliveFieldset from './fieldsets/KeepAliveFieldset';

const DeskSettings = () => {
  const slug = useSelector(selectAccountSlug);
  const navigate = useNavigate();
  const deskId = useSelector(selectDeskId);
  const { deskId: urlDeskId } = useParams();
  const dispatch = useDispatch();
  const { desk, deleteStatus } = useDesks(deskId);
  useTitle(`${desk?.name} | Settings`);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'desks', actions.WRITE)
  );
  useEffect(() => {
    pageView(PageName.DESK_SETTINGS);
  }, []);

  useEffect(() => {
    if (!!deskId && deskId !== urlDeskId) {
      dispatch(setDeskId(urlDeskId));
    }
  }, [deleteStatus, deskId, dispatch, urlDeskId]);

  useEffect(() => {
    if (deleteStatus === 'success') {
      navigate(`/${slug}/desks`);
    }
  }, [slug, navigate, deleteStatus]);

  return (
    <PageContentWrapper
      fullHeight={canWrite}
      newPlain3={!canWrite}
      readOnly={!canWrite}
    >
      <InformationFieldset desk={desk} />
      <HumanChatFieldset desk={desk} />
      <KeepAliveFieldset desk={desk} />
      <DeskIdFieldset desk={desk} />
      <DeleteDeskFieldset desk={desk} />
    </PageContentWrapper>
  );
};

export default DeskSettings;
