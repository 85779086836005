import cn from 'classnames';

import styles from './TypingIndicator.module.scss';

export const TypingIndicator = ({ color = 'var(--icon-default-blue)' }) => {
  return (
    <div className={styles.loaderWrapper} style={{ color }}>
      <div className={cn(styles.typingDot, styles.dot1)}></div>
      <div className={cn(styles.typingDot, styles.dot2)}></div>
      <div className={cn(styles.typingDot, styles.dot3)}></div>
    </div>
  );
};
