import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import {
  AllViewersPayload,
  Presence,
  PresenceState,
  PresenceStatus,
  RemoveViewerPayload,
  ViewerPayload,
} from '@/models/presence';
import { AgentActivity, AgentId } from '@/models/rtm';

const initialState: PresenceState = Object.freeze({
  brains: {},
  collections: {},
  datasources: {},
  desks: {},
  sessions: {},
  agents: [],
});

const presenceSlice = createSlice({
  name: 'presence',
  initialState: { ...initialState },
  reducers: {
    setAllViewers: (state, action: PayloadAction<AllViewersPayload>) => {
      const { type, resources } = action.payload;
      Object.keys(resources).forEach((id) => {
        if (!state[type][id]) {
          state[type][id] = [];
        }
        state[type][id] = [...resources[id]].sort(
          (a, b) => a.timestamp - b.timestamp
        );
      });
    },

    setViewers: (state, action: PayloadAction<ViewerPayload>) => {
      const { type, id, agents } = action.payload;
      if (!state[type][id]) {
        state[type][id] = [];
      }
      state[type][id] = agents.sort((a, b) => a.timestamp - b.timestamp);
    },
    removeViewer: (state, action: PayloadAction<RemoveViewerPayload>) => {
      const { type, id, user_id } = action.payload;
      if (!state[type][id]) {
        return;
      }
      state[type][id] = state[type][id]?.filter(
        (a: AgentActivity) => a.user_id !== user_id
      );
    },
    setAgents: (state, action: PayloadAction<{ agents: Presence[] }>) => {
      state.agents = action.payload.agents;
    },

    setAgentsFromPresence: (
      state,
      action: PayloadAction<{ users: Presence[] }>
    ) => {
      state.agents = action.payload.users;
    },
    setAgentStatus: (
      state,
      action: PayloadAction<{ agent_id: string; status: PresenceStatus }>
    ) => {
      const { agent_id, status } = action.payload;
      state.agents.forEach((a) => {
        if (a.agent_id === agent_id) {
          a.status = status;
        }
      });
    },
    markAgentOnline: (state, action: PayloadAction<AgentId>) => {
      state.agents.forEach((a) => {
        if (a.agent_id === action.payload.user_id) {
          a.is_connected = true;
        }
      });
    },
    markAgentOffline: (state, action: PayloadAction<AgentId>) => {
      state.agents.forEach((a) => {
        if (a.agent_id === action.payload.user_id) {
          a.is_connected = false;
        }
      });
    },
    updateAgentActivity: (state, action: PayloadAction<AgentActivity>) => {
      state.agents.forEach((a) => {
        if (a.agent_id === action.payload.user_id) {
          a.last_activity_at = moment(action.payload.timestamp).toISOString();
        }
      });
    },
  },
});

export const {
  setViewers,
  setAllViewers,
  removeViewer,
  setAgents,
  setAgentsFromPresence,
  markAgentOffline,
  markAgentOnline,
  updateAgentActivity,
  setAgentStatus,
} = presenceSlice.actions;

export default presenceSlice.reducer;
