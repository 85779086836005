import { useCallback, useEffect } from 'react';

import cn from 'classnames';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import AvatarUpload from '@/components/atoms/Avatar/AvatarUpload/AvatarUpload';
import Input from '@/components/atoms/Input/Input';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import { AccordionFieldsetProps, SuncoIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { errorMessage, suncoIntegration } from '@/util/validator';

import styles from './SettingsAccordion.module.scss';
type Form = {
  welcome_trigger_message: string;
  name: string;
  avatar_url: string;
};

export const SettingsAccordion = ({
  integration,
  type,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, SuncoIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();
  const { updateIntegration, updateStatus } = useIntegrations<SuncoIntegration>(
    integration?.desk_id,
    integration?.integration_id
  );

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    trigger,
    reset,
    setValue,
    control,
  } = useForm<Form>({
    mode: 'onChange',
  });

  const avatarUrl = useWatch({ control, name: 'avatar_url' });

  useEffect(() => {
    reset({
      name: integration?.name || '',
      welcome_trigger_message:
        integration?.config?.welcome_trigger_message || '',
      avatar_url: integration?.config?.avatar || '',
    });
  }, [
    integration?.config?.avatar,
    integration?.config?.welcome_trigger_message,
    integration?.name,
    reset,
  ]);

  const handleUpdateIntegration = useCallback(() => {
    const { name, welcome_trigger_message, avatar_url } = getValues();
    updateIntegration(
      {
        ...integration,
        name,
        config: {
          ...integration?.config,
          welcome_trigger_message: welcome_trigger_message || undefined,
          avatar: avatar_url || undefined,
        },
      },
      {
        onSettled: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, getValues, integration, type, updateIntegration]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  const handleAvatarUpdate = useCallback(
    (data) => {
      setValue('avatar_url', data?.avatar, { shouldDirty: true });
    },
    [setValue]
  );

  return (
    <Accordion
      title={t('integrations.sunco.settings.title')}
      subtitle={t('integrations.sunco.settings.subtitle')}
      disabled={!isDirty}
      isLoading={updateStatus === 'pending'}
      expanded={expanded === type}
      handleChange={toggleAccordion(type)}
      onSubmit={handleSubmit(handleUpdateIntegration)}
      readOnly={!canWrite}
      ref={registerAccordion(
        type,
        handleSubmit(handleUpdateIntegration),
        trigger
      )}
    >
      <Input
        name="sunco_name"
        register={register('name', suncoIntegration.name)}
        errorMessage={errorMessage({
          field: errors['name'],
          maxLength: suncoIntegration.name.maxLength,
        })}
        size="medium"
        trimValue
        label={t('integrations.sunco.settings.name')}
        readOnly={!canWrite}
      />
      <Input
        name="sunco_welcome_trigger_message"
        register={register(
          'welcome_trigger_message',
          suncoIntegration.welcome_trigger_message
        )}
        errorMessage={errorMessage({
          field: errors['welcome_trigger_message'],
          maxLength: suncoIntegration.welcome_trigger_message.maxLength,
        })}
        size="medium"
        trimValue
        label={t('integrations.sunco.settings.welcome_trigger_message')}
        readOnly={!canWrite}
      />
      <div
        className={cn(styles.wrapper, {
          [styles.disabled]: !canWrite,
        })}
      >
        <div className={styles.title}>{t('integrations.web_ui.avatar')}</div>
        <AvatarUpload
          onUpdate={handleAvatarUpdate}
          label="userAvatar"
          avatar={avatarUrl}
        />
      </div>
    </Accordion>
  );
};
