import { Icon } from '@/models/icon';

export const AllConversations = ({
  color = 'currentColor',
  size = 16,
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 12H2.333A1.334 1.334 0 0 1 1 10.667V5.333A1.333 1.333 0 0 1 2.333 4h9.334A1.333 1.333 0 0 1 13 5.333v5.334A1.334 1.334 0 0 1 11.667 12H8.333L5 15.333V12Z"
      />
      <path
        fill="#fff"
        d="M7 9.667H4.333A1.333 1.333 0 0 1 3 8.333V3a1.333 1.333 0 0 1 1.333-1.333h9.334A1.333 1.333 0 0 1 15 3v5.333a1.334 1.334 0 0 1-1.333 1.334h-3.334L7 13V9.667Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.333 5.667h.007m2.66 0h.007m2.66 0h.006M7 9.667H4.333A1.333 1.333 0 0 1 3 8.333V3a1.333 1.333 0 0 1 1.333-1.333h9.334A1.333 1.333 0 0 1 15 3v5.333a1.334 1.334 0 0 1-1.333 1.334h-3.334L7 13V9.667Z"
      />
    </svg>
  );
};
