import React from 'react';

import { Icon } from '@/models/icon';

export const Standalone = ({
  size = 16,
  color = 'var(--icon-default-purple)',
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#standalone)">
        <path
          d="M12.349 8.066 9.125 6.875 7.938 3.649a.995.995 0 0 0-1.868 0L4.875 6.875 1.65 8.063a.995.995 0 0 0 0 1.867l3.226 1.195 1.188 3.226a.994.994 0 0 0 1.867 0l1.195-3.226 3.226-1.188a.994.994 0 0 0 0-1.867l-.002-.004Zm-3.786 2.198a.5.5 0 0 0-.297.296L7 13.99l-1.264-3.428a.5.5 0 0 0-.298-.298L2.009 9l3.429-1.264a.5.5 0 0 0 .298-.298L7 4.008l1.264 3.428a.5.5 0 0 0 .296.297L11.99 9l-3.427 1.264ZM9 2.5a.5.5 0 0 1 .5-.5h1V1a.5.5 0 0 1 1 0v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0V3h-1a.5.5 0 0 1-.5-.5Zm6.5 3a.5.5 0 0 1-.5.5h-.5v.5a.5.5 0 0 1-1 0V6H13a.5.5 0 0 1 0-1h.5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 .5.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="standalone">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
