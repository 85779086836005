import { useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { webhooksEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import {
  Webhook,
  PartialWebhook,
  Webhooks,
  WebhookTest,
  WebhookTestResults,
} from '@/models/webhook';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getRandomElement } from '@/redux/dialogs/helper';
import { popModal } from '@/redux/modals/actions';

export const API = Object.freeze({
  listWebhooks: async (id: string): Promise<Webhooks> =>
    callGet(endpoints.webhooks(id)),
  getWebhook: async (id: string, wid: string): Promise<Webhook> =>
    callGet(endpoints.webhook(id, wid)),
  createWebhook: async (newWebhook: Partial<Webhook>): Promise<Webhook> =>
    callPost(endpoints.webhooks(newWebhook.brain_id), newWebhook),
  testWebhook: async (
    id: string,
    testData: WebhookTest
  ): Promise<WebhookTestResults> => callPut(endpoints.test(id), testData),

  updateWebhook: async ({
    brain_id,
    webhook_id,
    ...webhook
  }: PartialWebhook): Promise<Webhook> =>
    callPut(endpoints.webhook(brain_id, webhook_id), webhook),

  deleteWebhook: async (webhook: PartialWebhook): Promise<Webhook> =>
    callDelete(endpoints.webhook(webhook.brain_id, webhook.webhook_id)),
});

export const onWebhookCreated = (
  queryClient: QueryClient,
  webhook: Webhook
) => {
  queryClient.setQueryData<Webhooks>(
    [endpoints.webhooks(webhook.brain_id)],
    (prev) => ({
      webhooks: [...(prev?.webhooks || []), webhook],
    })
  );
};

export const onWebhookUpdated = (
  queryClient: QueryClient,
  webhook: Webhook
) => {
  queryClient.setQueryData<Webhooks>(
    [endpoints.webhooks(webhook.brain_id)],
    (prev: Webhooks) => {
      return {
        webhooks: prev?.webhooks.map((item) =>
          item.webhook_id === webhook.webhook_id
            ? { ...item, ...webhook }
            : item
        ),
      };
    }
  );
  queryClient.setQueryData<Webhook>(
    [endpoints.webhook(webhook.brain_id, webhook.webhook_id)],
    () => webhook
  );
};

export const onWebhookRemoved = (
  queryClient: QueryClient,
  brainId: string,
  webhook_id: string
) => {
  queryClient.setQueryData<Webhooks>(
    [endpoints.webhooks(brainId)],
    (prev: Webhooks) => ({
      webhooks: prev?.webhooks.filter((acc) => acc.webhook_id !== webhook_id),
    })
  );
};

const useWebhooks = (brainId: string, webhook_id?: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: webhooks } = useQuery<Webhooks, Error>({
    queryKey: [endpoints.webhooks(brainId)],
    queryFn: () => API.listWebhooks(brainId),
    enabled: Boolean(brainId),
  });

  const { data: webhook } = useQuery<Webhook, Error>({
    queryKey: [endpoints.webhook(brainId, webhook_id)],
    queryFn: () => API.getWebhook(brainId, webhook_id),
    initialData: webhooks?.webhooks?.find(
      (webhook) => webhook_id === webhook.webhook_id
    ),
    enabled: Boolean(brainId) && Boolean(webhook_id),
  });

  const {
    mutate: testWebhook,
    data: testResults,
    status: testStatus,
  } = useMutation<WebhookTestResults, Error, WebhookTest>({
    mutationFn: (data) => API.testWebhook(brainId, { ...data }),
    onSuccess: () => {
      dispatch(addTemporalToast('success', t('webhook.test_executed')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: updateWebhook, status: updateStatus } = useMutation<
    Webhook,
    Error,
    PartialWebhook
  >({
    mutationFn: API.updateWebhook,
    onSuccess: (resp) => {
      onWebhookUpdated(queryClient, resp);
      dispatch(addTemporalToast('success', t('webhook.webhook_updated')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createWebhook, status: createStatus } = useMutation<
    Webhook,
    Error,
    Partial<Webhook>
  >({
    mutationFn: API.createWebhook,
    onSuccess: (resp) => {
      onWebhookCreated(queryClient, resp);
      dispatch(addTemporalToast('success', t('webhook.webhook_created')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteWebhook, status: deleteStatus } = useMutation<
    Webhook,
    Error,
    string
  >({
    mutationFn: (id) =>
      API.deleteWebhook({ brain_id: brainId, webhook_id: id }),
    onSuccess: (resp) => {
      dispatch(popModal());
      onWebhookRemoved(queryClient, brainId, resp.webhook_id);
      dispatch(addTemporalToast('success', t('webhook.webhook_deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const randomWebhook = useMemo(
    () => getRandomElement(webhooks?.webhooks),
    [webhooks?.webhooks]
  );

  return {
    webhooks: webhooks?.webhooks,
    webhook,
    createStatus,
    deleteStatus,
    updateStatus,
    testResults,
    testStatus,
    createWebhook,
    deleteWebhook,
    updateWebhook,
    testWebhook,
    randomWebhook,
  };
};

export default useWebhooks;
