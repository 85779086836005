import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import Select from '@/components/atoms/Select/Select';
import TabsTable from '@/components/atoms/TabsTable/TabsTable';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { durationFormat } from '@/util/util';

import useResponseTimes, {
  ResponseType,
} from '../../../hooks/useResponseTimes';
import { getOptions } from '../ResponseTimeOverview/ResponseTimeOverview';

import styles from './TeamResponseTime.module.scss';

const TeamResponseTime = () => {
  const [showPrevious, setShowPrevious] = useState(false);
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();
  const [optionValue, setOptionValue] = useState(
    getOptions(t)[0]?.value as ResponseType
  );

  const title = t('analytics.response_time');

  const handleOptionClick = useCallback((e) => {
    setOptionValue(e.target.value);
  }, []);

  const handlePreviousClick = () => {
    setShowPrevious((prev) => !prev);
  };

  const brainConversations = useResponseTimes({
    intervalProps: { events: '{message:brain_send}' },
    skipPrevious: !showPrevious,
    type: optionValue,
  });
  const agentConversations = useResponseTimes({
    intervalProps: { events: '{message:send}' },
    skipPrevious: !showPrevious,
    type: optionValue,
  });

  const overallConversations = useResponseTimes({
    skipPrevious: !showPrevious,
    type: optionValue,
  });

  const handleReload = () => {
    brainConversations.refetchCurrent();
    agentConversations.refetchCurrent();
    overallConversations.refetchCurrent();
  };

  const items = useMemo(
    () => [
      {
        ...agentConversations,
        data: {
          ...agentConversations.data,
          counts: durationFormat(t, agentConversations.data?.counts),
          title: t('analytics.human_agents'),
        },
      },
      {
        ...brainConversations,
        data: {
          ...brainConversations.data,
          counts: durationFormat(t, brainConversations.data?.counts),
          title: t(`common.${ai_agents ? 'ai_agents' : 'brains'}`),
        },
      },
      {
        ...overallConversations,
        data: {
          ...overallConversations.data,
          counts: durationFormat(t, overallConversations.data?.counts),
          title: t('analytics.overall'),
        },
      },
    ],
    [agentConversations, ai_agents, brainConversations, overallConversations, t]
  );

  const isError =
    agentConversations?.currentError &&
    brainConversations?.currentError &&
    overallConversations?.currentError;

  const isLoading =
    agentConversations?.isLoading &&
    brainConversations?.isLoading &&
    overallConversations?.isLoading;

  const isEmpty = !items[0]?.data?.counts && !items[1]?.data?.counts;

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      error={isError}
      onReload={handleReload}
      isEmpty={isEmpty}
      isLoading={isLoading}
      data={items}
    >
      <div className={styles.checkbox}>
        <Select
          options={getOptions(t)}
          value={optionValue}
          onChange={handleOptionClick}
          size="small"
        />
        <Checkbox
          label={<TitleSubtitle subtitle={t('analytics.compare_prev')} />}
          onChange={handlePreviousClick}
          checked={showPrevious}
          sameLevel
        />
      </div>
      <TabsTable items={items} invertArrow />
    </AnalyticsFieldset>
  );
};

export default TeamResponseTime;
