import moment, { Moment } from 'moment';

import styles from './AnalyticsTooltip.module.scss';

type Props = {
  children?: React.ReactNode;
  data?: { title: string; value: string }[];
} & (
  | { isBar?: true; startDate: string; endDate: string }
  | {
      isBar?: false;
      startDate?: Moment | string;
      endDate?: Moment | string;
    }
);

const AnalyticsTooltip = ({ startDate, endDate, data, isBar }: Props) => {
  return (
    <div className={styles.container}>
      <p className={styles.date}>
        {!isBar ? (
          <>
            {!endDate ? (
              <>{moment(startDate).format('MMM DD, YYYY')}</>
            ) : (
              <>
                {moment(startDate).format('MMM DD')} -{' '}
                {moment(endDate).format('MMM DD, YYYY')}
              </>
            )}
          </>
        ) : (
          // if isBar, date does not have a year so we handle accordingly
          <>
            {!endDate ? (
              <>{startDate}</>
            ) : (
              <>
                {startDate} - {endDate}
              </>
            )}
          </>
        )}
      </p>
      {data &&
        data.map((d) => (
          <p key={d.title}>
            {d.title}: <span>{d.value}</span>
          </p>
        ))}
    </div>
  );
};

export default AnalyticsTooltip;
