import { useIntercom } from '@/hooks/useIntercom';

import IntercomOauth from './IntercomOauth';
import IntercomSettings from './IntercomSettings';

const Intercom = () => {
  const { integration, isConnected, handleOAuthClick } = useIntercom();

  if (!integration) {
    return null;
  }

  return (
    <div>
      {isConnected && <IntercomSettings />}
      {!isConnected && <IntercomOauth onAuthClick={handleOAuthClick} />}
    </div>
  );
};

export default Intercom;
