import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';

import { PageName } from '@/models/segment';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import AccountDeleteFieldset from './fieldsets/AccountDelete';

const AccountDanger = () => {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  useTitle(t('pages.account_delete', { 0: slug }));

  useEffect(() => {
    pageView(PageName.ACCOUNT_DELETE);
  }, []);

  return <AccountDeleteFieldset />;
};

export default AccountDanger;
