import { memo, useCallback, useMemo, useRef, useState } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { pdf } from '@react-pdf/renderer';
import cn from 'classnames';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useScreenshot } from 'use-react-screenshot';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import IconButton from '@/components/atoms/IconButton/IconButton';
import IconClose from '@/components/atoms/Icons/Close';
import Export from '@/components/atoms/Icons/Export';
import { ResetIcon } from '@/components/atoms/Icons/Reset';
import Loading from '@/components/atoms/Loading/Loading';
import Spinner from '@/components/atoms/Spinner/Spinner';
import Tab from '@/components/atoms/Tab/Tab';
import TabPanel from '@/components/atoms/TabPanel/TabPanel';
import Tabs from '@/components/atoms/Tabs/Tabs';
import Modal from '@/components/organisms/Modals/Modal';
import {
  RatingSection,
  SessionSection,
  UserSection,
} from '@/components/organisms/Modals/ModalTryIt/LogSections';
import useBrains from '@/hooks/useBrains';
import useLogs from '@/hooks/useLogs';
import { useModalTryIt } from '@/hooks/useModalTryIt';
import { ConversationSource } from '@/models/chat';
import { EventName } from '@/models/segment';
import { NavigationItem } from '@/models/tryIt';
import { popModal } from '@/redux/modals/actions';
import { trackEvent } from '@/segment/segment';

import { selectTryItSessionId } from '../../redux/selectors';
import ContextSection from '../ContextSection';
import { DetailsSection } from '../LogSections/DetailsSection/DetailsSection';
import { Messages } from '../Messages/Messages';
import { PdfExport } from '../PdfExport/PdfExport';

import styles from './ModalTryIt.module.scss';

type Props = {
  brainId: string;
  sessionId?: string;
  isAccountReplay?: boolean;
};

const MODAL_HEIGHT = '650px';
const MODAL_WIDTH = '1000px';

// Sub component
const ModalTryItInformation = ({ navigationItem, content, isLoading }) => {
  const userInfo = {
    user_name: content?.user_name,
    user_id: content?.user_id,
    channel_user_id: content?.channel_user_id,
    external_user_id: content?.external_user_id,
    user_email: content?.user_email,
  };
  return (
    <>
      <TabPanel value={navigationItem} index="details">
        <DetailsSection content={content} />
      </TabPanel>
      <TabPanel value={navigationItem} index={'user'}>
        <UserSection user_info={userInfo} />
      </TabPanel>
      <TabPanel value={navigationItem} index={'conversation'}>
        <SessionSection
          isContained={content?.is_contained}
          isCovered={content?.is_covered}
          isTest={content?.is_test}
          sessionId={content?.session_id}
          deskId={content?.desk_id}
          participatedBrains={content?.participated_brains}
          participatedAgents={content?.participated_agents}
          participatedRules={content?.rule_ids}
          channel={content?.channel}
          messages={content?.total_user_messages}
          startTime={content?.start_time}
          endTime={content?.end_time}
          avgConfidence={content?.avg_confidence}
          tags={content?.tags}
          isLoading={isLoading}
          expiredTime={
            content?.messages.find((m) => m?.event === 'session:expired')?.time
          }
        />
      </TabPanel>
      <TabPanel value={navigationItem} index={'context'}>
        <ContextSection />
      </TabPanel>

      <TabPanel value={navigationItem} index={'rating'}>
        <RatingSection rating={content?.rating} feedback={content?.feedback} />
      </TabPanel>
    </>
  );
};

export const ModalTryIt = memo(
  ({ brainId, sessionId, isAccountReplay }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isSession, headerItems, clearSessionIdFromUrl } = useModalTryIt(
      sessionId,
      isAccountReplay
    );
    const screenshotRef = useRef(null);
    const [_, takeScreenshot] = useScreenshot();
    const [isScreenshotLoading, setIsScreenshotLoading] = useState(false);
    const { brain } = useBrains(brainId);
    const session_id = sessionId ?? undefined;
    const { content, isLoading } = useLogs(undefined, session_id);
    const { brain: logBrain } = useBrains(content?.brain_id);

    const tryItSessionId = useSelector(selectTryItSessionId);

    const [resetClicked, setResetClicked] = useState(false);
    const [navigationItem, setNavigationItem] =
      useState<NavigationItem>('details');

    const downloadImage = async (image: string) => {
      const finalFile = <PdfExport content={content} snapshot={image} />;

      const asPdf = pdf(<></>);
      asPdf.updateContainer(finalFile);
      const blob = await asPdf.toBlob();
      saveAs(
        blob,
        `${moment().format('YYYY-MM-DD')}-session-${content.session_id}.pdf`
      );
    };

    const handleConversationDownload = () => {
      trackEvent(EventName.ClickAnalyticsLogsExport, { session_id });
      setIsScreenshotLoading(true);
      takeScreenshot(screenshotRef.current)
        .then(downloadImage)
        .catch(console.error)
        .finally(() => {
          setIsScreenshotLoading(false);
        });
    };

    const handleCloseModal = useCallback(() => {
      dispatch(popModal());
      navigate(clearSessionIdFromUrl(), {
        replace: true,
      });
    }, [dispatch, clearSessionIdFromUrl, navigate]);

    // Render methods
    const renderTitle = () => {
      if (isSession) {
        return content?.user_name ?? t('common.anonymous');
      }
      return brain?.name;
    };

    const source: ConversationSource = useMemo(
      () => ({
        deskId: content?.desk_id,
        integrationId: content?.integration_id,
        channel: content?.channel,
        sessionId: content?.session_id || tryItSessionId,
        language: logBrain?.language || brain?.language,
      }),
      [
        content?.desk_id,
        content?.integration_id,
        content?.channel,
        content?.session_id,
        tryItSessionId,
        logBrain?.language,
        brain?.language,
      ]
    );

    return (
      <Modal
        header={false}
        passiveModal
        size="large"
        noGutters
        cleanModal
        onBlur={() => {
          navigate(clearSessionIdFromUrl(), {
            replace: true,
          });
        }}
      >
        <Container
          sx={{
            display: 'flex',
            height: MODAL_HEIGHT,
            width: MODAL_WIDTH,
          }}
          disableGutters
          maxWidth="xl"
        >
          <Box component="section" className={styles.leftColumn}>
            <div className={styles.heading}>
              <Typography
                variant="body-semi-bold"
                color="var(--text-default-gray)"
                py="calc(var(--space-12) / 2)"
              >
                {renderTitle()}
              </Typography>
              <div className={styles.buttonContainer}>
                {isSession && (
                  <>
                    {isScreenshotLoading ? (
                      <Spinner className={styles.spinner} />
                    ) : (
                      <IconButton
                        onClick={handleConversationDownload}
                        ariaLabel={t('try_it.download_conversation_label')}
                        tooltip={t('try_it.download_conversation_tooltip')}
                      >
                        <Export color="var(--icon-default-blue)" />
                      </IconButton>
                    )}
                  </>
                )}
                {!isSession ? (
                  <IconButton
                    onClick={() => setResetClicked(true)}
                    ariaLabel={t('try_it.reset')}
                  >
                    <ResetIcon />
                  </IconButton>
                ) : (
                  <CopyButton className="primary" data={window.location.href} />
                )}
              </div>
            </div>

            {isSession && isLoading && <Loading />}
            {isSession && !isLoading && (
              <Messages
                brainId={brainId}
                messages={content?.messages}
                tags={content?.tags}
                isAccountReplay={isAccountReplay}
                ref={screenshotRef}
                source={source}
              />
            )}
            {!isSession && (
              <Messages
                brainId={brainId}
                resetClicked={resetClicked}
                setResetClicked={setResetClicked}
                ref={screenshotRef}
                source={source}
              />
            )}
          </Box>

          <Box component="section" className={styles.rightColumn}>
            <div className={cn(styles.heading, styles['heading--tabs'])}>
              <Tabs
                onChange={(_, newValue) => {
                  setNavigationItem(newValue);
                }}
                value={navigationItem}
              >
                {headerItems?.map((name) => (
                  <Tab
                    key={name}
                    value={name}
                    label={t(`try_it.headers.${name}`)}
                  />
                ))}
              </Tabs>

              <IconButton
                onClick={handleCloseModal}
                ariaLabel={t('modals.close')}
                className={styles.closeButton}
              >
                <IconClose size={20} />
              </IconButton>
            </div>
            <Box p="var(--space-16)">
              <ModalTryItInformation
                navigationItem={navigationItem}
                content={content}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        </Container>
      </Modal>
    );
  }
);

ModalTryIt.displayName = 'ModalTryIt';
