import { Icon } from '@/models/icon';

const Sorting = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 9.111 4.5 13m0 0-3-3.889M4.5 13V3"
        stroke={color}
        strokeWidth=".8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke={color}
        strokeWidth=".8"
        strokeLinecap="round"
        d="M8.9 3.6h6.2M8.9 6.6h5.2M8.9 9.6h3.2M8.9 12.6h1.2"
      />
    </svg>
  );
};

export default Sorting;
