import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

export const defaultState = Object.freeze({
  dialogAlerts: [],
});

const clearAlerts = (state, action) => {
  const { id, nodeId, index } = action.payload;

  if (id) {
    state.dialogAlerts = state.dialogAlerts.filter((d) => d.id !== id);
  } else if (nodeId && index !== undefined) {
    state.dialogAlerts = state.dialogAlerts.filter((d) => {
      if ('nodeId' in d && 'index' in d) {
        return !(d?.nodeId === nodeId && d?.index === index);
      }

      return true;
    });
  } else if (nodeId) {
    state.dialogAlerts = state.dialogAlerts.filter((d) => {
      if ('nodeId' in d) {
        return d?.nodeId !== nodeId;
      }
    });
  } else {
    state.dialogAlerts = [];
  }
};

const nodesSlice = createSlice({
  name: 'dialogAlerts',
  initialState: cloneDeep(defaultState),
  reducers: {
    updateDialogAlerts: (state, action) => {
      const { dialogAlerts } = action.payload;
      // If the error prop of dialogError is undefined, remove it from the array
      if (!dialogAlerts.body) {
        state.dialogAlerts = state.dialogAlerts.filter(
          (d) =>
            !(
              d.id === dialogAlerts.id &&
              d.alertField === dialogAlerts.alertField
            )
        );
        return;
      }

      // // Check if id of the dialogError already exists in dialogErrors
      const index = state.dialogAlerts.findIndex(
        (d) =>
          d.id === dialogAlerts.id && d.alertField === dialogAlerts.alertField
      );

      // If it does, replace it with the new dialogError
      if (index !== -1) {
        state.dialogAlerts[index] = dialogAlerts;
      } else {
        // Otherwise, add it to the end of the array
        state.dialogAlerts.push(dialogAlerts);
      }
    },

    clearDialogAlerts: (state, action) => clearAlerts(state, action),
  },
  extraReducers: (builder) => {
    builder.addCase('session/setDialogId', (state) => {
      const warnings = state.dialogAlerts.filter(
        (dialog) => dialog.alertType === 'warning'
      );
      warnings.forEach((warning) => clearAlerts(state, { payload: warning }));
    });
  },
});

export const { updateDialogAlerts, clearDialogAlerts } = nodesSlice.actions;

// Export the reducer, either as a default or named export
export default nodesSlice.reducer;
