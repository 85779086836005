import { AudioAction } from '@/models/action';

type Props = {
  action: AudioAction;
};

const Audio = ({ action }: Props) => {
  const { url, name } = action;
  return <audio className="action-audio" controls title={name} src={url} />;
};

export default Audio;
