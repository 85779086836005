import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { callGet } from '@/api/fetcher';
import { FrontIntegration } from '@/models/integration';
import { selectAccountSlug } from '@/redux/session/selectors';
import { FRONT_AUTHORIZATION_URL } from '@/util/constants';

import { useIntegrations } from './useIntegrations';

export const useFront = () => {
  const { deskId, integrationId } = useParams();
  const slug = useSelector(selectAccountSlug);
  const { integration, updateIntegration, updateStatus } =
    useIntegrations<FrontIntegration>(deskId, integrationId);
  const config = integration?.config;

  const isConnected = !!config?.refresh_token && !!config?.page_id;

  const onOAuthClick = useCallback(async () => {
    const client_id = await callGet('/www/api/integrations/front/client-id');
    const redirectUri = `${window?.location?.origin}/www/api/integrations/front/callback`;

    const queryParams = new URLSearchParams({
      redirect_uri: redirectUri,
      client_id,
      response_type: 'code',
      state: JSON.stringify({
        desk_id: deskId,
        integration_id: integrationId,
        slug,
      }),
    });

    const callbackUrl = `${FRONT_AUTHORIZATION_URL}?${queryParams}`;
    window.open(callbackUrl, '_self');
  }, [deskId, integrationId, slug]);

  const onUpgradeClick = useCallback(() => {
    updateIntegration({
      ...integration,
      config: {
        ...integration?.config,
        teammate_id: undefined,
        mode: 'agent',
      },
      active: false,
    });
  }, [integration, updateIntegration]);

  return {
    isConnected,
    onOAuthClick,
    onUpgradeClick,
    upgradeStatus: updateStatus,
    isActive: integration?.active,
  };
};
