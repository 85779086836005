import { PropsWithChildren } from 'react';

import cn from 'classnames';

import styles from './Header.module.scss';

interface Props {
  noGutters?: boolean;
}

const HeaderBody = ({
  children,
  noGutters = false,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={cn(styles.outerWrapper, {
        [styles.noGutter]: noGutters,
      })}
    >
      {children}
    </div>
  );
};

export default HeaderBody;
