import { Icon } from '@/models/icon';

type Props = Icon & {
  extraLarge?: boolean;
};

const HumanChat = ({
  size = 33,
  color = 'var(--icon-default-gray)',
  extraLarge,
}: Props) => {
  if (extraLarge) {
    return (
      <svg
        width="100"
        height="101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.334 41.835h.042m16.625 0h.042m16.625 0h.041m-29.208 25H20.834a8.333 8.333 0 0 1-8.333-8.333V25.17a8.333 8.333 0 0 1 8.333-8.334h58.334A8.333 8.333 0 0 1 87.5 25.17v33.333a8.334 8.334 0 0 1-8.333 8.333H58.334L37.501 87.67V66.835Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.333 14.666h.007m2.66 0h.007m2.66 0h.006m-4.673 4h-2.667A1.334 1.334 0 0 1 10 17.334V12a1.334 1.334 0 0 1 1.333-1.334h9.334A1.334 1.334 0 0 1 22 12v5.333a1.334 1.334 0 0 1-1.333 1.334h-3.334L14 22v-3.334Z" />
    </svg>
  );
};

export default HumanChat;
