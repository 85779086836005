export const getDisabledMessage = (
  maxCollectionsReached: boolean,
  planName: string,
  totalFragmentsReached: boolean,
  t
) => {
  if (maxCollectionsReached) {
    return t('limits.reached', {
      0: 'collections',
      1: planName,
    });
  }
  if (totalFragmentsReached) {
    return t('limits.collections_fragments_reached');
  }
  return t('collections.no_permission');
};

export const USAGE_PERCENTAGE_THRESHOLD = 25;
