import { Icon } from '@/models/icon';

const MemberRemove = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      viewBox="0 0 17 17"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#a)"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11.617 14.332v-1.333a2.667 2.667 0 0 0-2.666-2.667H4.284a2.667 2.667 0 0 0-2.667 2.667v1.333M6.62 7.665a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333ZM12.953 5.664l3.334 3.333M16.287 5.664l-3.334 3.333" />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(.953 .332)"
            d="M0 0h16v16H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MemberRemove;
