import { Icon } from '@/models/icon';

const Carousel = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <rect
        x="6.75"
        y="3.75"
        width="10.5"
        height="16.5"
        rx=".75"
        strokeWidth="1.5"
      />
      <path
        d="M19.5 5.25H21a1.5 1.5 0 011.5 1.5v10.5a1.5 1.5 0 01-1.5 1.5h-1.5M4.5 18.75H3a1.5 1.5 0 01-1.5-1.5V6.75A1.5 1.5 0 013 5.25h1.5"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Carousel;
