import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import { RelatedConversationsTile } from '@/components/atoms/RelatedConversations/RelatedConversationsTile';
import { useModalTryIt } from '@/hooks/useModalTryIt';
import { useRelatedConversations } from '@/modules/humanChat/hooks/useRelatedConversations';
import { isModalOpen } from '@/redux/modals/selectors';
import { selectSessionId } from '@/redux/session/selectors';

import { CustomDetails } from '../CustomDetails/CustomDetails';

import styles from './RelatedConversationsSection.module.scss';

const MAX_PREVIEWS = 2;

export const RelatedConversationsSection = () => {
  const [showAll, setShowAll] = useState(false);
  const { relatedConversations } = useRelatedConversations();
  const { t } = useTranslation();
  const sessionId = useSelector(selectSessionId);
  const [urlParams] = useSearchParams();
  const selectedSessionId = urlParams.get('session_id');
  const { handleAccountReplayClick } = useModalTryIt(selectedSessionId);
  const modalOpen = useSelector(isModalOpen);

  useEffect(() => {
    if (!selectedSessionId || modalOpen) return;
    handleAccountReplayClick();
  }, [handleAccountReplayClick, modalOpen, selectedSessionId]);

  const ref = useRef(null);

  // Collapse list when changing chat view
  useEffect(() => {
    if (sessionId !== ref.current) {
      setShowAll(false);
      ref.current = sessionId;
    }
  }, [sessionId]);

  if (!relatedConversations?.length) {
    return null;
  }

  const handleClick = () => {
    setShowAll(true);
  };

  return (
    <section className={styles.section}>
      <CustomDetails title={t('common.related_conversations')}>
        <ul className={styles.list}>
          {relatedConversations
            .slice(0, showAll ? undefined : MAX_PREVIEWS)
            .map((chat) => {
              return (
                <RelatedConversationsTile
                  key={chat.session_id}
                  session_id={chat.session_id}
                />
              );
            })}
          {!showAll && relatedConversations?.length > 2 && (
            <Button
              onClick={handleClick}
              className={styles.button}
              variant="anchor"
            >
              {t('onboarding.show_all', {
                0: '',
              })}
            </Button>
          )}
        </ul>
      </CustomDetails>
    </section>
  );
};
