import { Icon } from '@/models/icon';

const Webhooks = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className="icon_fill"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.62 4.48a2.38 2.38 0 1 1 4.654.702l.956.295a3.38 3.38 0 1 0-5.364 1.625L4.442 9.448a1.46 1.46 0 1 0 .856.518L6.963 7.22l.262-.432-.435-.257a2.379 2.379 0 0 1-1.17-2.05Zm-1.905 6.28a.46.46 0 1 1 .889.239.46.46 0 0 1-.889-.238ZM8 3.02a1.46 1.46 0 1 0 .223 2.903l1.47 2.675.241.438.439-.241a2.38 2.38 0 1 1-.504 3.8l-.694.72a3.38 3.38 0 1 0 1.17-5.605L9.1 5.44A1.46 1.46 0 0 0 8 3.021Zm-.176 1.035a.46.46 0 1 1 .352.85.46.46 0 0 1-.352-.85ZM11.52 9.42a1.46 1.46 0 0 0-1.371.96H6.539v.5a2.38 2.38 0 1 1-2.957-2.31L3.34 7.6a3.381 3.381 0 1 0 4.163 3.78h2.645a1.46 1.46 0 1 0 1.371-1.96Zm-.325 1.135a.46.46 0 1 1 .65.65.46.46 0 0 1-.65-.65Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default Webhooks;
