/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Ref,
  RefAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Popper, { PopperProps } from '@mui/material/Popper';

import { commonSymbolsRegex } from '@/util/util';

/**
 * Command Palette Component
 *
 * This is a generic component for rendering a popup filled with options
 * whenever a specific trigger key is pressed.
 *
 * It uses the Popper component from MUI, so you dont need to worry about positioning.
 * It also handles the logic for filtering the options based on a search string.
 * It also handles the logic for navigating and selecting an option.
 *
 * Please consider carefully before adding functionality to this component.
 * The aim of this component is to be generic and reusable and options agnostic.
 *
 * Use it with `useCommandPallete` hook which just holds some state.
 *
 * @param {Function} renderItem - A function that returns the input.
 *        The triggerKey handler is passed as a prop.
 * @param {Function} renderPalette - The component to be used as a palette. Needs to be a forwardRef. The following are passed as props:
 *        searchString, handleClosePopper, selectedIndex, handleOnSelectPopper.
 * @param {string} triggerKey - The key that will trigger the CommandPalette. The default is '/'.
 * @param {number} skiddingFromSource - The horizontal offset (in pixels) of the popup from its source. Default is 80.
 * @param {number} optionsLength - The total number of options.
 * @param {Function} handleOnSelectFromProps - The onSelect function, passed from the parent component.
 * @param {string} searchString - The current search string, used to filter the options displayed in the CommandPalette.
 * @param {Function} setSearchString - A function that updates the search string.
 * @param {string} inputType - 'text' is for divs, text, etc. 'input' is for input elements.
 */

interface RenderItemProps {
  onKeyDown: (e: React.KeyboardEvent) => void;
  ref: Ref<HTMLTextAreaElement> & Ref<Element>;
  handleClosePopper: () => void;
}

export interface RenderPaletteProps {
  searchString: string;
  handleClosePopper: () => void;
  selectedIndex: number;
  handleOnSelectPopper: (event: React.KeyboardEvent) => void;
}

interface CommandPaletteProps<T> {
  renderItem: (props: RenderItemProps) => React.ReactNode;
  renderPalette: (
    props: RenderPaletteProps & T & React.RefAttributes<any>
  ) => React.ReactElement<any, any>;
  triggerKey?: string;
  skiddingFromSource?: number;
  optionsLength: number;
  handleOnSelectFromProps: (index: number) => void;
  handleOnClickFromProps?: (index: number) => void;
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  inputType?: 'input' | 'text';
}

const CommandPalette = <T,>({
  renderItem,
  renderPalette,
  triggerKey = '/',
  skiddingFromSource = 80,
  optionsLength,
  handleOnSelectFromProps,
  searchString,
  setSearchString,
  inputType = 'text',
}: CommandPaletteProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);
  const [isTriggered, setIsTriggered] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>();
  const popperRef = useRef();
  const handleTriggerKeyForText = () => {
    const selection = window.getSelection();
    if (!selection) {
      return;
    }
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (!range) {
      return;
    }

    const rect = range.getBoundingClientRect();
    const getBoundingClientRect = () => rect;
    setAnchorEl({ getBoundingClientRect });
    setIsOpen(true);
    setIsTriggered(true);
  };

  const handleTriggerKeyForInput = () => {
    if (inputRef.current) {
      const start = 0;
      const end = 0;
      inputRef.current.setSelectionRange(start, end);
      setAnchorEl(inputRef.current);
      setIsOpen(true);
      setIsTriggered(true);
    }
  };

  const handleTriggerKey =
    inputType === 'input' ? handleTriggerKeyForInput : handleTriggerKeyForText;

  const handleClosePopper = useCallback(() => {
    setIsOpen(false);
    setAnchorEl(null);
    setIsTriggered(false);
    setSelectedIndex(0);
    setTimeout(() => setSearchString(''), 100);
  }, [setSearchString]);

  const handleBackspaceKey = () => {
    setSearchString((prev) => {
      const newSearchString = prev.slice(0, prev.length - 1);

      if (newSearchString.length === 0) {
        handleClosePopper();
      }

      return newSearchString;
    });
  };

  const handleArrowDownKey = (event: React.KeyboardEvent) => {
    event.preventDefault();
    setSelectedIndex((prev) => (prev + 1) % optionsLength);
  };

  const handleArrowUpKey = (event: React.KeyboardEvent) => {
    event.preventDefault();
    setSelectedIndex((prev) => (prev > 0 ? prev - 1 : optionsLength - 1));
  };

  const handleOnSelectPopper = (event: any, index?: number) => {
    event.preventDefault();
    if (index !== undefined) {
      setSelectedIndex(index);
    }
    handleOnSelectFromProps(index || selectedIndex);
    handleClosePopper();
  };

  const handleDefaultKey = (event: React.KeyboardEvent) => {
    const key = event.key;

    if (key.length === 1 && commonSymbolsRegex.test(key)) {
      setSearchString((prev) => prev + key);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isTriggered) {
      if (event.key === triggerKey && searchString.length === 0) {
        handleTriggerKey();
      }
      return;
    }

    switch (event.key) {
      case 'Backspace':
        handleBackspaceKey();
        break;
      case 'ArrowDown':
        handleArrowDownKey(event);
        break;
      case 'ArrowUp':
        handleArrowUpKey(event);
        break;
      case 'Enter':
        handleOnSelectPopper(event);
        break;
      default:
        handleDefaultKey(event);
        break;
    }
  };

  return (
    <div>
      {renderItem({
        onKeyDown: (e: React.KeyboardEvent) => handleKeyDown(e),
        ref: inputType === 'input' ? inputRef : undefined,
        handleClosePopper,
      })}
      <ClickAwayListener onClickAway={handleClosePopper}>
        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          transition
          popperRef={popperRef}
          popperOptions={{
            placement: 'bottom',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [skiddingFromSource, 10],
                },
              },
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: false,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
            ],
          }}
          sx={{ zIndex: 4 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={0}>
              {renderPalette({
                searchString,
                handleClosePopper,
                selectedIndex,
                handleOnSelectPopper,
              } as RenderPaletteProps & T & React.RefAttributes<any>)}
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </div>
  );
};

export default CommandPalette;
