/* eslint-disable no-param-reassign */

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Accordion from '@mui/material/Accordion/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import ArrowDownIcon from '@/components/atoms/Icons/ArrowDown';
import Tick from '@/components/atoms/Icons/Tick';
import ProgressBar from '@/components/atoms/ProgressBar/ProgressBar';
import Pie from '@/components/organisms/Analytics/Pie/Pie';
import { MODAL_VIDEO } from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import useHomeCheckList, {
  AccountUserPrefsEnum,
  INAPPHELP_KEYS,
} from '@/hooks/useHomeCheckList';
import { useIntegrations } from '@/hooks/useIntegrations';
import useLocalStorage from '@/hooks/useLocalStorage';
import { RootState } from '@/models/state';
import { useRules } from '@/modules/rules/hooks/useRules';
import { hideHelper, showHelper } from '@/redux/helpers/actions';
import { selectHelper } from '@/redux/helpers/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { selectIframeView } from '@/redux/user/selectors';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import { muiStyles } from './styles';

import styles from './HomeFieldset.module.scss';

const HomeFieldset = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<number>(null);
  const slug = useSelector(selectAccountSlug);
  const { brains } = useBrains();
  const deskId = useSelector(selectDeskId);
  const { integrations } = useIntegrations(deskId);
  const { rules } = useRules(deskId);

  const playedAutoModalRef = useRef(false);
  const brainId = brains?.[0]?.brain_id;
  const integrationId = useMemo(
    () =>
      integrations?.find((i) => i.type === 'web')?.integration_id ??
      integrations?.[0]?.integration_id,
    [integrations]
  );
  const { collections } = useCollections();

  const ruleId = rules.rules?.[0]?.rule_id ?? '';
  const firstCollectionId = collections?.[0]?.collection_id;

  const { markAsComplete, getSteps, accountUserChecklist } = useHomeCheckList();

  const showProductVideo = useSelector((state: RootState) =>
    selectHelper(state, INAPPHELP_KEYS[AccountUserPrefsEnum.PRODUCT_TOUR])
  );
  const [dismissVideo, setDismissVideo] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.PRODUCT_TOUR],
    accountUserChecklist[AccountUserPrefsEnum.PRODUCT_TOUR]
  );

  const [dismissBrainVideo, _] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_BRAIN],
    accountUserChecklist[AccountUserPrefsEnum.WHAT_IS_BRAIN]
  );

  const dispatch = useDispatch();

  const tiles = useMemo(
    () =>
      getSteps(
        accountUserChecklist,
        slug,
        brainId,
        deskId,
        integrationId,
        ruleId,
        firstCollectionId
      ),
    [
      getSteps,
      accountUserChecklist,
      slug,
      brainId,
      deskId,
      integrationId,
      ruleId,
      firstCollectionId,
    ]
  );

  const handleAccordionToggle = (
    e: React.SyntheticEvent<Element, Event>,
    index: number
  ) => {
    setExpanded((prev) => (prev === index ? null : index));
  };

  const updateStep1 = useCallback(() => {
    setDismissVideo(true);
    dispatch(hideHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.PRODUCT_TOUR]));
    if (!dismissBrainVideo) {
      dispatch(showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.WHAT_IS_BRAIN]));
    }
  }, [dismissBrainVideo, dispatch, setDismissVideo]);
  const isIframeView = useSelector(selectIframeView);
  const handleClick = useCallback(
    (index: number, stepIndex: number) => {
      const step = tiles[index]?.steps[stepIndex];
      if (step?.localStorageKey) {
        dispatch(showHelper(step?.localStorageKey));
      }
      if (step?.url) {
        navigate(step.url);
      } else if (step?.video && !isIframeView) {
        dispatch(
          pushModal(MODAL_VIDEO, {
            title: t(step?.label),
            url: step.video,
            onClick: () => {
              dispatch(popModal());
              if (step?.buttonUrl) {
                if (index === 0 && stepIndex == 1) {
                  updateStep1();
                }
                navigate(step?.buttonUrl);
              }
            },
            onPlayClick: () => {
              playedAutoModalRef.current = true;
              markAsComplete(step?.checklistStep);
            },
            onClose: () => {
              if (index === 0 && stepIndex == 1) {
                updateStep1();
              }
            },
            primaryButtonText: t('home.checklist.custom_flows'),
          })
        );
      }
    },
    [tiles, dispatch, navigate, t, updateStep1, markAsComplete, isIframeView]
  );

  useEffect(() => {
    if (
      !dismissVideo &&
      accountUserChecklist &&
      !accountUserChecklist[AccountUserPrefsEnum.PRODUCT_TOUR]
    ) {
      dispatch(showHelper(INAPPHELP_KEYS[AccountUserPrefsEnum.PRODUCT_TOUR]));
    }
  }, [accountUserChecklist, dismissVideo, dispatch, handleClick]);

  useEffect(() => {
    if (showProductVideo) {
      handleClick(0, 1);
      return () => {
        dispatch(popModal());
      };
    }
  }, [dispatch, handleClick, showProductVideo]);

  const handleKeyUp = useCallback(
    (e, index: number, stepIndex: number) => {
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        handleClick(index, stepIndex);
      }
    },
    [handleClick]
  );

  const { total, completed } = useMemo(
    () =>
      tiles.reduce(
        (acc, tile) => {
          tile.steps?.map((step) => {
            if (step.completed) {
              acc = {
                ...acc,
                completed: acc.completed + 1,
              };
            }
            acc = {
              ...acc,
              total: acc.total + 1,
            };
          });
          return acc;
        },
        {
          total: 0,
          completed: 0,
        }
      ),
    [tiles]
  );

  const pieData = [
    {
      id: 'total',
      value: total - completed,
    },
    {
      id: 'completed',
      value: completed,
    },
  ];

  const piePercentage = (completed / total) * 100;

  return (
    <PlainFieldset>
      <div className={styles.fieldsetHeader}>
        <Typography variant="h2-semi-bold">{t('home.title')}</Typography>
        <Typography>{t('home.subtitle')}</Typography>
        <div className={styles.progressContainer}>
          <div className={styles.progress}>
            <Pie
              hasCenterMetric={false}
              enableArcLinkLabels={false}
              enableArcLabels={false}
              data={pieData}
              colors={[
                'var(--border-default-gray)',
                'var(--icon-default-blue)',
              ]}
            />
          </div>
          <div className={styles.progressLabel}>
            <Typography variant="label-semi-bold">
              {t('home.progress')}
            </Typography>
            <Typography variant="h2-bold">
              {Math.ceil(piePercentage)}%
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.fieldsetContent}>
        {tiles.map(({ title, steps, Icon }, index) => {
          const incompleSteps = steps.filter(({ completed }) => !completed);
          const isCompleted = incompleSteps.length === 0;
          const percentage =
            ((steps.length - incompleSteps.length) / steps.length) * 100;
          return (
            <div className={styles.tile} key={title}>
              <Accordion
                expanded={expanded === index}
                sx={muiStyles.accordionRoot}
                onChange={(e) => handleAccordionToggle(e, index)}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowDownIcon size={24} data-testid="expand-accordion" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={styles.accordionHeader}>
                    <div
                      className={cn(styles.iconContainer, {
                        [styles.isCompleted]: isCompleted,
                      })}
                    >
                      <Icon
                        color={
                          isCompleted
                            ? 'var(--icon-default-white)'
                            : 'var(--icon-default-black)'
                        }
                      />
                    </div>
                    <div className={styles.headerContent}>
                      <Typography variant="subheading-semi-bold">
                        {t(title)}
                      </Typography>
                      <Typography variant="label-regular">
                        {isCompleted &&
                          t('home.steps', { count: steps.length })}
                        {!isCompleted &&
                          t('home.steps_left', { count: incompleSteps.length })}
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.progressBarContainer}>
                    <ProgressBar percentage={percentage} />
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={muiStyles.detailsRoot}>
                  <div className={styles.content}>
                    {steps.map(
                      ({ label, completed, icon, url, video }, stepIndex) => {
                        return (
                          <div
                            className={cn(styles.item, {
                              [styles.noHover]: !url && !video,
                            })}
                            key={label}
                            onClick={() => handleClick(index, stepIndex)}
                            onKeyUp={(e) => handleKeyUp(e, index, stepIndex)}
                            role="button"
                            tabIndex={0}
                          >
                            {completed ? <Tick size={16} /> : (icon ?? '')}
                            <Typography>{t(label)}</Typography>
                          </div>
                        );
                      }
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
    </PlainFieldset>
  );
};

export default HomeFieldset;
