import { KeyboardEventHandler } from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type ButtonProps = IconButtonProps & {
  ariaLabel: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  ariaHasPopUp?: boolean;
  ariaControls?: string;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  tabIndex?: number;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  onMouseOver?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

type TooltipProps = {
  tooltip?: string;
};

type Props = ButtonProps & TooltipProps;

const Button = ({
  ariaLabel,
  onClick,
  children,
  ariaHasPopUp = false,
  ariaControls,
  disabled = false,
  tooltip,
  size,
  tabIndex,
  onKeyDown,
  onMouseOver,
  onMouseLeave,
  className,
  ...props
}: Props) => {
  const renderIconButton = () => (
    <IconButton
      {...props}
      disableRipple
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      onClick={onClick}
      aria-haspopup={ariaHasPopUp}
      disabled={disabled}
      size={size}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={className}
    >
      {children}
    </IconButton>
  );

  return tooltip ? (
    <Tooltip arrow title={tooltip}>
      <span>{renderIconButton()}</span>
    </Tooltip>
  ) : (
    renderIconButton()
  );
};

export default Button;
