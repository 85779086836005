import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Close from '@/components/atoms/Icons/Close';
import { popModal } from '@/redux/modals/actions';

import Modal from '../Modal';

import styles from './ModalConfirmChanges.module.scss';

type Props = {
  onSave: () => void;
  onDiscard: () => void;
  onClose?: () => void;
  name?: string;
  subtitle?: string | React.ReactNode;
  title?: string;
  primaryText?: string;
  secondaryText?: string;
  warning?: React.ReactNode;
};

function ModalConfirmChanges({
  onSave,
  onDiscard,
  onClose,
  subtitle,
  title,
  primaryText,
  secondaryText,
  warning,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const _title = title || t('dialog.warningModal.title');
  const _subtitle = subtitle || t('dialog.warningModal.subtitle');

  const _primaryText = primaryText || t('common.save');
  const _secondaryText = secondaryText || t('common.discard');

  const handleSave = useCallback(() => {
    onSave();
    dispatch(popModal());
  }, [dispatch, onSave]);

  const handleDiscard = useCallback(() => {
    onDiscard();
    dispatch(popModal());
  }, [dispatch, onDiscard]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    dispatch(popModal());
  }, [dispatch, onClose]);

  return (
    <Modal onBlur={onClose} cleanModal noGutters>
      <div className={styles.container}>
        <div className={styles.title}>{_title}</div>
        <div className={styles.subtitle}>{_subtitle}</div>
        {warning}
        <div className={styles.buttons}>
          <Button variant="secondary" onClick={handleDiscard}>
            {_secondaryText}
          </Button>
          <Button onClick={handleSave}>{_primaryText}</Button>
        </div>
      </div>
      <button className={styles.close} onClick={handleClose}>
        <Close size={18} color="var(--icon-default-black)" />
      </button>
    </Modal>
  );
}

export default ModalConfirmChanges;
