import { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router';

import Header from '@/components/organisms/Header/Header';
import { personalSettings } from '@/components/organisms/Navbar/SettingsNavigation/constants';
import { SettingsNavigation } from '@/components/organisms/Navbar/SettingsNavigation/SettingsNavigation';
import Invitations from '@/components/pages/Invitations/Invitations';
import Notifications from '@/components/pages/Notifications/Notifications';
import Profile from '@/components/pages/Profile/Profile';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';
import { usePermissions } from '@/hooks/usePermissions';
import { setAccountRole, setPermissions } from '@/redux/permissions/actions';
import { setAccountId, setAccountSlug } from '@/redux/session/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

const ProfileRouter: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const title_key = personalSettings.find((s) =>
    location.pathname.includes(s.link)
  )?.title_key;
  const dispatch = useDispatch();
  const { account, accountRole, lastUsedAccountSlug, setLastUsedAccountSlug } =
    useAccount();
  const { permissions } = usePermissions();

  const sessionSlug = useSelector(selectAccountSlug);

  useEffect(() => {
    if (!sessionSlug) {
      dispatch(setAccountSlug(lastUsedAccountSlug));
    }
  }, [dispatch, lastUsedAccountSlug, sessionSlug]);

  useEffect(() => {
    if (account?.slug) {
      setLastUsedAccountSlug(account.slug);
    }
  }, [account?.slug, dispatch, setLastUsedAccountSlug]);

  useEffect(() => {
    if (account?.account_id) {
      dispatch(setAccountId(account?.account_id));
      dispatch(setAccountRole(accountRole));
      dispatch(setPermissions(permissions));
    }
  }, [dispatch, account?.account_id, accountRole, permissions]);

  return (
    <Layout withSidebar>
      <SettingsNavigation />
      <Header>
        <Header.Body>
          <Header.Title
            title={
              <Typography
                variant="h2-semi-bold"
                color="var(--text-default-gray)"
              >
                {t(title_key)}
              </Typography>
            }
          />
        </Header.Body>
      </Header>
      <PageContentWrapper fullHeight>
        <Routes>
          <Route path="/invitations" element={<Invitations />} />
          <Route path="/information" element={<Profile />} />
          <Route path="/notifications" element={<Notifications />} />
        </Routes>
      </PageContentWrapper>
    </Layout>
  );
};

export default ProfileRouter;
