import { useCallback } from 'react';

import { shallowEqual, useSelector } from 'react-redux';

import useDialogs from '@/hooks/useDialogs';
import { Dialogs, PartialDialog } from '@/models/dialog';
import { RootState } from '@/models/state';
import { selectDraftDialog } from '@/redux/dialogs/selectors';

import { checkCycle } from './detectCycle';

export const useDetectCycle = (brainId) => {
  const dialogDraft: PartialDialog = useSelector(
    (state: RootState) => selectDraftDialog(state),
    shallowEqual
  );

  const { dialogs } = useDialogs(brainId);

  const getDialogsWithDraft = useCallback(() => {
    if (!dialogs) {
      return dialogs;
    }

    return {
      dialogs: [
        ...dialogs.filter((d) => d.dialog_id !== dialogDraft.dialog_id),
        dialogDraft,
      ],
    } as Dialogs;
  }, [dialogDraft, dialogs]);

  const detectCycle = useCallback(
    () => checkCycle(getDialogsWithDraft() as Dialogs),
    [getDialogsWithDraft]
  );

  return { detectCycle };
};
