import { ApiError, ApiStatus } from '@/models/api';

export const defaultStatus: ApiStatus = {
  loading: false,
  loaded: false,
  error: null,
};

export const loadingStatus: ApiStatus = {
  loading: true,
  loaded: false,
  error: null,
};

export const failedStatus = (error: ApiError): ApiStatus => ({
  loading: false,
  error,
  loaded: false,
});

export const successStatus: ApiStatus = {
  loading: false,
  loaded: true,
  error: null,
};
