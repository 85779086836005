import { useTranslation } from 'react-i18next';

import PlainFooterFieldset from '@/components/atoms/Fieldset/templates/PlainFooterFieldset';

interface Props {
  type: string;
  onOAuthClick: () => void;
}

const AuthFieldset = ({ type, onOAuthClick }: Props) => {
  const { t } = useTranslation();
  return (
    <PlainFooterFieldset
      title={t('integrations.sunco.redirect.title', { type })}
      subtitle={t('integrations.sunco.redirect.subtitle', {
        type,
      })}
      primaryButton={{
        children: t('integrations.connect'),
        onClick: onOAuthClick,
      }}
      fullContentWidth
    ></PlainFooterFieldset>
  );
};

export default AuthFieldset;
