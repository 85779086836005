import { Icon } from '@/models/icon';

const Maximize = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_300_42075)">
        <path
          d="M9.97168 1.5H13.9717V5.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.97168 13.5H1.97168V9.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9714 1.5L9.30469 6.16667"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.97168 13.4999L6.63835 8.83325"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_300_42075">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.97168 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Maximize;
