import { useCallback, useEffect } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Banner } from '@/components/atoms/Banner/Banner';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import Documentation from '@/components/atoms/Icons/Global/Documentation';
import TextareaAutosize from '@/components/atoms/TextareaAutosize/TextareaAutosize';
import { Performance } from '@/components/organisms/Intents/Performance/Performance';
import TrainingPhrases from '@/components/organisms/Intents/TrainingPhrases/TrainingPhrases';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import SubHeaderIntent from '@/components/organisms/SubHeader/SubHeaderIntent';
import SubHeaderRecommended from '@/components/organisms/SubHeader/SubHeaderRecommended';
import SubNavIntent from '@/components/organisms/SubNav/SubNavIntent';
import { makeItemsName } from '@/components/organisms/SubNav/utils';
import useDialogNodes from '@/hooks/useDialogNodes';
import useExpressions from '@/hooks/useExpressions';
import useIntentRecommendation from '@/hooks/useIntentRecommendation';
import useIntents from '@/hooks/useIntents';
import useLocalStorage from '@/hooks/useLocalStorage';
import { actions } from '@/models/permissions';
import { TYPE } from '@/models/recommendations';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { resetPhrases } from '@/redux/auto-ai/actions';
import { setIntent, clearIntentExpressions } from '@/redux/expressions/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { resetRecommendation } from '@/redux/recommendations/actions';
import { selectRecommendations } from '@/redux/recommendations/selectors';
import { setIntentName } from '@/redux/session/actions';
import { pageView } from '@/segment/segment';
import { INTENTS_DOCS_URL } from '@/util/constants';

import styles from './Intent.module.scss';

const CONTEXTUAL_HELP = 'about_intents';
const Intent = () => {
  const { t } = useTranslation();
  const { brainId, intentName } = useParams();
  const recommendations = useSelector(selectRecommendations);

  const { intents, intent, isLoading, listStatus } = useIntents(
    brainId,
    intentName
  );
  const { expressions, isRecommendationsDirty, isDraft } = useExpressions(
    brainId,
    intentName
  );
  const dispatch = useDispatch();

  const [localDismiss] = useLocalStorage(`banners.${CONTEXTUAL_HELP}`, false);

  useDialogNodes({ type: 'intent' });

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  useEffect(() => {
    pageView(PageName.INTENT);
  }, []);

  const {
    recommendedIntent,
    recommendation,
    setRecommendations,
    acceptNewRecommendation,
    dismissNewRecommendation,
  } = useIntentRecommendation(brainId, intentName);

  useEffect(() => {
    if (!recommendations[brainId] && !isEmpty(recommendation)) {
      setRecommendations(recommendation, TYPE.LOGS);
    }
  }, [recommendation, setRecommendations, recommendations, brainId]);

  useEffect(() => {
    if (brainId && !isDraft) {
      dispatch(setIntentName(intentName));
    }
  }, [brainId, dispatch, intentName, isDraft]);

  useEffect(() => {
    if (intent && !isDraft) {
      dispatch(setIntent(intent));
      return () => {
        dispatch(clearIntentExpressions());
      };
    }
  }, [dispatch, intent, isDraft]);

  useTitle(t('pages.intent', { 0: intent?.intent }));

  useEffect(() => {
    return () => {
      if (isRecommendationsDirty) {
        dispatch(
          resetRecommendation({
            intent: intentName,
            brainId,
          })
        );
      }
    };
  }, [brainId, dispatch, intentName, isRecommendationsDirty]);

  useEffect(() => {
    return () => {
      if (!isDraft) {
        dispatch(resetPhrases());
      }
    };
  }, [dispatch, isDraft, intentName]);

  useEffect(() => {
    if (isDraft) {
      const newIntentName = makeItemsName(intents, 'intent', 'Intent_name');
      dispatch(
        setIntent({
          intent: newIntentName,
          expressions: [],
        })
      );
      return () => {
        dispatch(clearIntentExpressions());
      };
    }
  }, [dispatch, intents, isDraft]);

  //Dimiss a recommendation that doesn't match an intent
  const handleNewRecommendationDismiss = useCallback(() => {
    const deleteProps = {
      subtitle: (
        <Trans
          i18nKey="intent.recommendations.dismiss_subtitle"
          values={[intentName]}
        />
      ),
      confirm: false,
      onDelete: () => {
        dismissNewRecommendation();
        dispatch(popModal());
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [dismissNewRecommendation, dispatch, intentName]);

  const isNewIntentRecommendation =
    listStatus === 'success' &&
    recommendedIntent &&
    recommendedIntent.flag !== 'accepted' &&
    !intent &&
    !isLoading;

  return (
    <>
      {!canWrite && (
        <Banner variant="neutral" relativePosition centered padding="small">
          {t('permissions.read_only')}
        </Banner>
      )}
      <div
        className={cn(styles.wrapper, {
          [styles.readOnly]: !canWrite,
        })}
      >
        <SubNavIntent />
        <main
          className={cn(styles.main, {
            [styles.showHelp]: !localDismiss,
          })}
        >
          {isNewIntentRecommendation ? (
            <>
              <ContextualHelp
                title={t('intent.contextual_title')}
                name="about_intents"
                links={[
                  {
                    icon: <Documentation color="currentColor" />,
                    label: t('docs.docs'),
                    url: INTENTS_DOCS_URL,
                  },
                ]}
              >
                {t('intent.contextual_message')}
              </ContextualHelp>
              <div className={styles.contentWrapper}>
                <div className={styles.content}>
                  <div className={styles.subheader}>
                    <SubHeaderRecommended
                      title={recommendedIntent.intent}
                      onDisMissClick={handleNewRecommendationDismiss}
                      onAcceptClick={acceptNewRecommendation}
                    />
                  </div>
                  <div className={styles.banner}>
                    <Banner relativePosition>
                      {t('intent.recommendations.new_banner')}
                    </Banner>
                  </div>
                  <div className={cn(styles.expressionsWrapper, styles.isNew)}>
                    {recommendedIntent?.expressions?.map((e) => (
                      <TextareaAutosize
                        shouldMinimize
                        maxRows={1}
                        key={e.expression}
                        readOnly
                        defaultValue={e.expression}
                        onBlur={() => {}}
                        onKeyUp={() => {}}
                        onKeyDown={() => {}}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <ContextualHelp
                title={t('intent.contextual_title')}
                name="about_intents"
                links={[
                  {
                    icon: <Documentation color="currentColor" />,
                    label: t('docs.docs'),
                    url: INTENTS_DOCS_URL,
                  },
                ]}
              >
                {t('intent.contextual_message')}
              </ContextualHelp>
              <div className={styles.contentWrapper}>
                <div className={styles.content}>
                  <div className={styles.subheader}>
                    <SubHeaderIntent />
                  </div>

                  <div className={styles.expressionsWrapper}>
                    <TrainingPhrases
                      isLoading={isLoading}
                      readOnly={!canWrite}
                    />
                  </div>
                </div>
                <div className={styles.sidebar}>
                  <Performance
                    phraseCount={expressions?.length}
                    brainId={brainId}
                    intentName={intentName}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </>
          )}
        </main>
      </div>
    </>
  );
};

export default Intent;
