import { useMemo } from 'react';

import { ApolloError } from '@apollo/client';
import { Serie } from '@nivo/line';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '@/models/state';
import { GeneralRow } from '@/util/analytics';
import { numberFormatter } from '@/util/util';

import useInterval, { IntervalType } from './useInterval';

export interface LineCard {
  counts?: number | string;
  growth?: number;
  lineData?: Serie[];
  title?: string;
}

interface LineCardHook {
  isLoading: boolean;
  isPreviousLoading?: boolean;
  currentError?: ApolloError;
  previousError?: ApolloError;
  refetchCurrent?: () => void;
  refetchPrevious?: () => void;
  data?: LineCard;
}

interface Props {
  intervalProps: {
    type: IntervalType;
    startDate?: string;
    endDate?: string;
    deskIds?: string;
  };
  skip?: boolean;
  skipPrevious?: boolean;
}

export const formatLineCard = (
  type: IntervalType,
  t: TFunction,
  currentTotal?: GeneralRow[],
  previousTotal?: GeneralRow[]
): LineCard | undefined => {
  if (!currentTotal || !previousTotal) {
    return null;
  }

  let currentTotalCounts = 0;
  let previousTotalCounts = 0;

  // Prepare data arrays first
  const currentData = [];
  const previousData = [];

  // Iterate over all the results in the interval and calculate the total count
  for (let i = 0; i < currentTotal.length; i++) {
    currentData.push({
      x: i,
      y: currentTotal[i]?.counts,
    });
    previousData.push({
      x: i,
      y: previousTotal[i]?.counts,
    });
    currentTotalCounts += currentTotal[i]?.counts ?? 0;
    previousTotalCounts += previousTotal[i]?.counts ?? 0;
  }

  // After building data arrays, assign them to lineData
  const lineData = [
    {
      id: 'current',
      data: currentData,
    },
    {
      id: 'previous',
      data: previousData,
    },
  ] as Serie[];

  let growth = 0;
  if (previousTotalCounts > 0) {
    growth = Math.round(
      ((currentTotalCounts - previousTotalCounts) / previousTotalCounts) * 100
    );
  }

  const title =
    type === 'dialogs'
      ? t('analytics.total_messages')
      : t('analytics.total_conversations');

  return {
    title,
    counts: numberFormatter(currentTotalCounts),
    lineData,
    growth,
  };
};

const useLineCard = ({
  intervalProps,
  skip,
  skipPrevious,
}: Props): LineCardHook => {
  const { startDate, endDate, type, deskIds } = intervalProps;
  const { t } = useTranslation();
  const filters = useSelector((state: RootState) => {
    return {
      startDate: startDate || state.analytics.startDate,
      endDate: endDate || state.analytics.endDate,
      deskIds,
    };
  }, shallowEqual);

  const periodInDays = moment(filters.endDate).diff(
    moment(filters.startDate),
    'days'
  );

  const previousStartDate = filters.startDate
    ? moment(filters.startDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;
  const previousEndDate = filters.endDate
    ? moment(filters.endDate)
        .subtract(periodInDays + 1, 'days')
        .format('YYYY-MM-DD')
    : undefined;

  const {
    data: currentData,
    isLoading: currentLoading,
    error: currentError,
    refetch: refetchCurrent,
  } = useInterval(intervalProps, skip);

  const {
    data: previousData,
    isLoading: previousLoading,
    error: previousError,
    refetch: refetchPrevious,
  } = useInterval(
    {
      ...intervalProps,
      startDate: previousStartDate,
      endDate: previousEndDate,
    },
    skip
  );

  const data = useMemo(
    () => formatLineCard(type, t, currentData, previousData),
    [currentData, previousData, t, type]
  );

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (previousLoading && !skipPrevious) {
    return {
      data,
      isLoading: false,
      isPreviousLoading: true,
    };
  }

  if (currentError || previousError) {
    return {
      isLoading: false,
      currentError,
      previousError,
      refetchCurrent,
      refetchPrevious,
    };
  }

  return { isLoading: false, data };
};

export default useLineCard;
