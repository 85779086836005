import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Checkbox from '@/components/atoms/Checkbox/Checkbox/Checkbox';
import TabsTable from '@/components/atoms/TabsTable/TabsTable';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';

import useOverViewUsage from '../../../hooks/useOverviewUsage';

import styles from './OverviewUsageFieldset.module.scss';

const OverviewUsageFieldSet = () => {
  const { t } = useTranslation();
  const [showPrevious, setShowPrevious] = useState(false);

  const title = t('analytics.overview');

  const messages = useOverViewUsage({
    intervalProps: {
      type: 'dialogs',
    },
    skipPrevious: !showPrevious,
  });

  const sessions = useOverViewUsage({
    intervalProps: { type: 'sessions' },
    skipPrevious: !showPrevious,
  });
  const users = useOverViewUsage({
    intervalProps: { type: 'users' },
    skipPrevious: !showPrevious,
  });

  const handlePreviousClick = () => {
    setShowPrevious((prev) => !prev);
  };
  const handleReload = () => {
    messages.refetchCurrent();
    sessions.refetchCurrent();
    users.refetchCurrent();
  };

  const items = useMemo(
    () => [
      {
        ...messages,
        data: { ...messages.data, title: t('analytics.total_messages') },
      },
      {
        ...sessions,
        data: { ...sessions.data, title: t('analytics.total_conversations') },
      },
      {
        ...users,

        data: { ...users.data, title: t('analytics.total_customers') },
      },
    ],
    [messages, sessions, t, users]
  );

  const isError =
    messages?.currentError && sessions?.currentError && users?.currentError;

  const isLoading =
    messages?.isLoading && sessions?.isLoading && users?.isLoading;

  const isEmpty =
    items[0]?.data.counts === 0 &&
    items[1]?.data.counts === 0 &&
    items[2]?.data.counts === 0;

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      error={isError}
      onReload={handleReload}
      isEmpty={isEmpty}
      isLoading={isLoading}
      data={items}
    >
      <div className={styles.checkbox}>
        <Checkbox
          label={<TitleSubtitle subtitle={t('analytics.compare_prev')} />}
          onChange={handlePreviousClick}
          checked={showPrevious}
          sameLevel
        />
      </div>
      <TabsTable items={items} />
    </AnalyticsFieldset>
  );
};

export default OverviewUsageFieldSet;
