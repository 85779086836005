import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Duplicate = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      viewBox="0 0 21 21"
      className={`${styles.icon} ${disabledClass}`}
      width={size}
      height={size}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      shapeRendering="geometricPrecision"
    >
      <path d="M15 6.75H8.25a1.5 1.5 0 00-1.5 1.5V15a1.5 1.5 0 001.5 1.5H15a1.5 1.5 0 001.5-1.5V8.25a1.5 1.5 0 00-1.5-1.5z" />
      <path d="M3.75 11.25H3a1.5 1.5 0 01-1.5-1.5V3A1.5 1.5 0 013 1.5h6.75a1.5 1.5 0 011.5 1.5v.75" />
    </svg>
  );
};

export default Duplicate;
