import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccordionState, AccordionType } from '@/models/integration';

export const defaultState: AccordionState = {
  expanded: false,
  dirty: false,
};

const slice = createSlice({
  name: 'integrations',
  initialState: defaultState,
  reducers: {
    setExpanded: (state, action: PayloadAction<AccordionType | false>) => {
      state.expanded = action.payload;
    },
    setDirty: (state, action: PayloadAction<boolean>) => {
      state.dirty = action.payload;
    },
  },
});

// Extract and export each action creator by name
export const { setExpanded, setDirty } = slice.actions;

// Export the reducer, either as a default or named export
export default slice.reducer;
