import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import Loading from '@/components/atoms/Loading/Loading';
import useUser from '@/hooks/useUser';
import { logoutUser } from '@/redux/user/actions';
import { selectUser } from '@/redux/user/selectors';

function Logout() {
  const { t } = useTranslation();
  useTitle(t('pages.logout'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clearUserCache } = useUser();
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(logoutUser());
    clearUserCache();
    if (!user) {
      navigate('/login');
    }
  }, [clearUserCache, dispatch, navigate, user]);

  return <Loading label={t('login.logout_dots')} />;
}

export default Logout;
