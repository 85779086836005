import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import Link from '@/components/atoms/Link/Link';
import { useSunco } from '@/hooks/useSunco';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import AuthFieldset from './components/AuthFieldset';
import { ReAuthFieldset } from './components/ReAuthFieldset';
import SuncoSettings from './components/SuncoSettings';

const docs_url = 'https://docs.moveo.ai/docs/integrations/sunco#disconnect';

export default function IntegrationSunco() {
  const { onOAuthClick, integration, isConnected, isInvalidAccessToken } =
    useSunco();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  if (!integration) {
    return null;
  }

  const isVerified = integration?.status === 'verified';

  const subtitle = isVerified ? (
    <Trans
      i18nKey="integrations.sunco.delete_subtitle"
      components={{
        link1: <Link href={docs_url} external />,
      }}
    />
  ) : null;

  return (
    <>
      {isConnected && !isInvalidAccessToken && (
        <SuncoSettings integration={integration} />
      )}
      {!isConnected && !isInvalidAccessToken && (
        <AuthFieldset
          type="Sunshine Conversations"
          onOAuthClick={onOAuthClick}
        />
      )}
      {isConnected && isInvalidAccessToken && (
        <ReAuthFieldset onOAuthClick={onOAuthClick} />
      )}
      <DeleteFieldset
        integration={integration}
        disabled={!canWrite || isVerified}
        subtitle={subtitle}
      />
    </>
  );
}
