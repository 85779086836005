import { Dialog } from '@/models/dialog';

/**
 * Returns the type of the first node in a dialog with the given dialog ID.
 * @param {string} dialogId - The ID of the dialog to search for.
 * @returns {'intent' | 'event' | 'unknown' | undefined} The type of the first node in the dialog, or undefined if the dialog is not found or if the first node does not have a type.
 */
export const nodeType = (dialogId: string, dialogs: Dialog[]) => {
  const dialog = dialogs?.find((dialog) => dialog.dialog_id === dialogId);

  return dialog?.nodes[0].type as 'intent' | 'event' | 'unknown';
};
