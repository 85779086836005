import { Props } from '@/models/icon';

export const ToggleIcon = ({
  size = 16,
  color = 'var(--icon-default-black)',
  ...props
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      fill={color}
      d="M13.5 3.5v3a.5.5 0 0 1-1 0V4.707L9.354 7.854a.5.5 0 1 1-.708-.708L11.793 4H10a.5.5 0 1 1 0-1h3a.5.5 0 0 1 .5.5ZM6.646 9.146 3.5 12.293V10.5a.5.5 0 0 0-1 0v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1H4.207l3.147-3.146a.5.5 0 1 0-.708-.708Z"
    />
  </svg>
);
