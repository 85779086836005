import { useCallback, useState } from 'react';

import { useForm, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';
import { submitWithTrimming } from '@/util/util';
import { errorMessage } from '@/util/validator';

import Modal from './Modal';

type Props = {
  onEdit: (data: Record<string, string | number>, onError?: () => void) => void;
  title: string;
  primaryButtonText?: string;
  fields: {
    fieldName: string;
    fieldValue?: string | number;
    rules?: Exclude<
      RegisterOptions,
      'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    >;
  }[];
};
// make sure that the 'fields' you will use are included in the translations json files
export default function ModalEditMultipleValues({
  fields,
  onEdit,
  title,
  primaryButtonText,
}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<Record<string, string | number>>({
    mode: 'onChange',
    defaultValues: fields.reduce<Record<string, string | number>>((acc, e) => {
      return {
        ...acc,
        [e.fieldName]: e.fieldValue,
      };
    }, {}),
  });
  const {
    register,
    formState: { errors, isValid },
  } = formMethods;

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true);
      const onError = () => setIsLoading(false);
      onEdit(data, onError);
    },
    [onEdit]
  );

  return (
    <Modal
      aria-labelledby={title}
      title={title}
      primaryButtonText={primaryButtonText || t('common.change')}
      onPrimarySubmit={submitWithTrimming(formMethods, onSubmit)}
      isSubmitting={isLoading}
      primaryButtonDisable={!isValid}
    >
      <form autoComplete="off">
        {fields.map(({ fieldName, fieldValue, rules }, i) => (
          <div className="input__container" key={fieldName}>
            <Input
              name={fieldName}
              label={t(`common.${fieldName}`)}
              register={register(fieldName, rules)}
              errorMessage={errorMessage({
                field: errors[fieldName],
                ...rules,
              })}
              placeholder={fieldValue.toString()}
              autoFocus={i === 0}
            />
          </div>
        ))}
      </form>
    </Modal>
  );
}
