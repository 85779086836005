const FilePreview = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="48"
      fill="none"
      viewBox="0 0 59 48"
    >
      <rect width="59" height="48" fill="#8FADDA" rx="4" />
      <g filter="url(#a)">
        <rect width="34" height="34" x="13" y="7" fill="#fff" rx="8" />
        <path
          fill="#1C46B7"
          d="m18.575 17.853-.07 5.798c-.002.101.13.14.183.056L20.17 21.4v-5.489l-.248.07c-.487.14-.893.477-1.119.93a2.168 2.168 0 0 0-.229.942Z"
        />
        <path
          fill="#275C81"
          stroke="#275C81"
          d="M20.17 21.93v3.226c-1.575-.505-1.582-2.746 0-3.226Z"
        />
        <path
          fill="#EFEFEF"
          d="M20.17 12v24a1 1 0 0 0 1 1h18.222a1 1 0 0 0 1-1V17.336a1 1 0 0 0-.293-.707l-2.74-2.74-2.596-2.596a1 1 0 0 0-.707-.293H21.17a1 1 0 0 0-1 1Z"
        />
        <path
          fill="#D9D9D9"
          d="M34.036 15.356V11c.278 0 .544.11.74.306l3.16 3.16 2.15 2.15c.196.196.306.462.306.74h-4.356a2 2 0 0 1-2-2Z"
        />
        <path
          stroke="#D9D9D9"
          strokeLinecap="round"
          d="M24.07 25.156h12.566M23.925 28.622h12.567M23.925 32.089h12.567"
        />
        <path
          fill="#1C46B7"
          d="M30.881 15.911H20.17V21.4H30.88a2 2 0 0 0 2-2v-1.489a2 2 0 0 0-2-2Z"
        />
        <path
          fill="#fff"
          d="M21.667 20.109v-2.182h.818c.168 0 .309.031.422.094.115.062.201.148.26.258a.766.766 0 0 1 .088.37.77.77 0 0 1-.089.373.632.632 0 0 1-.26.257.89.89 0 0 1-.426.093h-.542v-.325h.489a.5.5 0 0 0 .24-.051.323.323 0 0 0 .14-.141.446.446 0 0 0 .045-.206.44.44 0 0 0-.046-.204.312.312 0 0 0-.14-.138.511.511 0 0 0-.241-.05h-.362v1.852h-.396Zm2.67 0h-.739v-2.182h.754c.217 0 .403.044.558.131a.876.876 0 0 1 .36.374c.084.163.126.357.126.584 0 .227-.042.422-.127.586a.876.876 0 0 1-.363.376 1.157 1.157 0 0 1-.569.13Zm-.344-.342h.325a.787.787 0 0 0 .38-.083.532.532 0 0 0 .23-.25c.05-.112.076-.251.076-.418a.987.987 0 0 0-.076-.416.523.523 0 0 0-.227-.248.753.753 0 0 0-.371-.083h-.337v1.498Zm1.779.342v-2.182h1.398v.331h-1.003v.593h.907v.331h-.907v.927h-.395Z"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="36"
          height="38"
          x="12"
          y="6"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1021_46136"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend
            in2="effect1_dropShadow_1021_46136"
            result="effect2_dropShadow_1021_46136"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1021_46136"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FilePreview;
