import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { isRequired } from '@/util/validator';

type Form = { name: string };

const CompanyNameFieldset = () => {
  const { t } = useTranslation();
  const { customer, updateCustomer, updateCustomerStatus } = useBilling();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Form>({ mode: 'onChange' });

  const isLoading = customer === undefined;

  useEffect(() => {
    if (customer?.name) {
      reset({ name: customer.name });
    }
  }, [customer, reset]);

  const onSubmit = useCallback(
    ({ name }: Form) => updateCustomer({ name }),
    [updateCustomer]
  );

  return (
    <FormFieldset
      title={t('billing.company_name')}
      subtitle={t('billing.company_name_subtitle')}
      primaryButton={{
        children: t('billing.company_change_name'),
        disabled: !isDirty,
        isLoading: updateCustomerStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Input
        name="name"
        label={t('field.name')}
        register={register('name', isRequired)}
        errorMessage={errors.name?.message}
      />
    </FormFieldset>
  );
};

export default CompanyNameFieldset;
