import { Icon } from '@/models/icon';

const AttachmentAudio = ({
  color = 'var(--icon-default-blue)',
  size = 40,
}: Icon) => {
  return (
    <svg
      viewBox="0 0 40 40"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.478 20.095a1.25 1.25 0 0 0-1.362.27l-3.383 3.385H7.5A1.25 1.25 0 0 0 6.25 25v6.25A1.25 1.25 0 0 0 7.5 32.5h3.233l3.383 3.384A1.25 1.25 0 0 0 16.25 35V21.25a1.25 1.25 0 0 0-.772-1.155ZM13.75 31.983l-1.616-1.617A1.252 1.252 0 0 0 11.25 30h-2.5v-3.75h2.5a1.252 1.252 0 0 0 .884-.366l1.616-1.617v7.716Zm10-3.858a6.335 6.335 0 0 1-3.125 5.455 1.25 1.25 0 0 1-1.25-2.16 3.827 3.827 0 0 0 0-6.59 1.25 1.25 0 0 1 1.25-2.16 6.336 6.336 0 0 1 3.125 5.455Zm9.634-15.26-8.75-8.75a1.25 1.25 0 0 0-.884-.365h-15a2.5 2.5 0 0 0-2.5 2.5v12.5a1.25 1.25 0 0 0 2.5 0V6.25H22.5v7.5A1.25 1.25 0 0 0 23.75 15h7.5v18.75h-5a1.25 1.25 0 0 0 0 2.5h5a2.5 2.5 0 0 0 2.5-2.5v-20a1.252 1.252 0 0 0-.366-.884ZM25 8.018l4.483 4.483H25V8.017Z"
        fill={color}
      />
    </svg>
  );
};

export default AttachmentAudio;
