import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useDialogs from '@/hooks/useDialogs';
import { selectBrainId } from '@/redux/session/selectors';
import { setUsedNodesCache, Types } from '@/redux/usedNodes/actions';

import useEntities from './useEntities';
import useIntents from './useIntents';

interface Props {
  type: Types;
}

/*
 * This hook is used to cache the used nodes in the dialogs
 * It runs once to load dialos or intents or entities to redux
 * To retrieve the cache, use the useDialogNodesCache hook
 */
const useDialogNodes = ({ type }: Props) => {
  const brainId = useSelector(selectBrainId);
  const dispatch = useDispatch();
  const { dialogs } = useDialogs(brainId);
  const { intents } = useIntents(brainId);
  const { entities } = useEntities(brainId);

  const ieData = type === 'intent' ? intents : entities;

  useEffect(() => {
    if (dialogs && brainId && ieData) {
      dispatch(setUsedNodesCache({ brainId, ieData, dialogs, type }));
    }
  }, [brainId, dialogs, dispatch, entities, ieData, intents, type]);
};

export default useDialogNodes;
