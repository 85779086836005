import { useCallback, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import Documentation from '@/components/atoms/Icons/Global/Documentation';
import useDesks from '@/hooks/useDesks';
import { useWebClient } from '@/hooks/useWebClient';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useBillingState } from '@/modules/billing/hooks/useBillingState';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { getDocsUrl, MOVEO_INTEGRATION_ID } from '@/util/constants';

import BillingFieldset from './BillingFieldset/BillingFieldset';

import styles from './SidebarFieldset.module.scss';

const getLinkTiles = (slug: string, deskId: string) => [
  {
    title: 'home.follow_guide',
    body: 'home.build_agent',
    buttonText: 'home.view_guide',
    url: getDocsUrl('/docs/guides/create_your_first_ai_agent'),
  },
  {
    title: 'home.configure_channels',
    body: 'home.channels_experience',
    buttonText: 'home.browse_integrations',
    url: `/${slug}/environments/${deskId}/integrations`,
  },
  {
    title: 'home.chat',
    body: 'home.view_inbox',
    buttonText: 'home.view_human_chat',
    url: `/${slug}/chats`,
  },
  {
    title: 'home.contact_support',
    body: 'home.ai_agent',
    buttonText: 'common.contact',
  },
];

const SidebarFieldset = () => {
  const navigate = useNavigate();
  const deskId = useSelector(selectDeskId);
  const slug = useSelector(selectAccountSlug);
  const billingState = useBillingState();
  const { t } = useTranslation();
  const canReadBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.READ)
  );
  const canWriteBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.WRITE)
  );
  const hasBillingPermissions = canReadBilling && canWriteBilling;

  const { initialiseWebchat, webClientInstance } = useWebClient({
    integrationId: MOVEO_INTEGRATION_ID,
    launcher: {
      show: false,
    },
  });

  useEffect(() => {
    if (webClientInstance) {
      webClientInstance.openWindow();
    }
  }, [webClientInstance]);

  const { desks, listStatus } = useDesks();
  const firstDeskId = listStatus === 'success' && desks[0]?.desk_id;

  const handleClick = useCallback(
    (index: number) => {
      const link = getLinkTiles(slug, deskId ?? firstDeskId)[index]?.url;
      if (index === 0) {
        window.open(link, '_blank');
        return;
      }
      if (index === 3) {
        initialiseWebchat();
        return;
      }
      navigate(link);
    },
    [deskId, firstDeskId, initialiseWebchat, slug, navigate]
  );

  return (
    <div className={styles.container}>
      {hasBillingPermissions && <BillingFieldset />}
      {getLinkTiles(slug, deskId ?? firstDeskId).map(
        ({ title, body, buttonText }, index) => {
          return (
            <div className={styles.fieldContainer} key={title}>
              <PlainFieldset fullWidth>
                <div className={styles.fieldContent}>
                  <Typography variant="subheading-semi-bold">
                    {t(title)}
                  </Typography>
                  <Typography>{t(body)}</Typography>
                  <Button
                    variant="secondary"
                    onClick={() => handleClick(index)}
                  >
                    {t(buttonText)}
                  </Button>
                </div>
              </PlainFieldset>
            </div>
          );
        }
      )}
      {(billingState?.plan_id !== 'trial' || !canReadBilling) && (
        <PlainFieldset fullWidth>
          <div className={styles.docsContainer}>
            <div className={styles.docsContent}>
              <span>
                <Documentation color="var(--icon-default-black)" />
                <Typography variant="subheading-semi-bold">
                  {t('docs.docs')}
                </Typography>
              </span>
              <Typography>{t('docs.resources')}</Typography>
            </div>
            <Button
              variant="secondary"
              target="_blank"
              isLink
              href={getDocsUrl()}
            >
              {t('common.view')}
            </Button>
          </div>
        </PlainFieldset>
      )}
    </div>
  );
};

export default SidebarFieldset;
