import cn from 'classnames';

import Button from '@/components/atoms/Button/Button/Button';

import fs from '..';

type ButtonProps = React.ComponentPropsWithoutRef<typeof Button>;

type Props = React.ComponentPropsWithoutRef<typeof fs.Fieldset> & {
  helpText?: React.ReactNode;
  submitLabel?: React.ReactNode;
  fullContentWidth?: boolean;
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
};

const PlainFooterFieldset = ({
  title,
  subtitle,
  children,
  helpText,
  fullContentWidth,
  isLoading: isLoadingFieldset,
  primaryButton,
  secondaryButton,
  externalLink,
  fullWidth,
}: Props) => {
  return (
    <fs.Fieldset
      title={title}
      subtitle={subtitle}
      externalLink={externalLink}
      isLoading={isLoadingFieldset}
      fullWidth={fullWidth}
    >
      <div className={cn({ fieldset__container: !fullContentWidth })}>
        {children}
      </div>
      <fs.Footer>
        {helpText && <fs.FooterStatus>{helpText}</fs.FooterStatus>}
        <fs.FooterAction>
          {secondaryButton && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Button variant="secondary" {...secondaryButton} />
          )}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Button {...primaryButton} />
        </fs.FooterAction>
      </fs.Footer>
    </fs.Fieldset>
  );
};

export default PlainFooterFieldset;
