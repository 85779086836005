import { OptionBase } from '@/models/common';

export const INPUT_TYPES: OptionBase<{
  type: 'input_type';
}>[] = [
  {
    label: 'Email',
    value: 'email',
    type: 'input_type',
  },
  {
    label: 'Phone',
    value: 'phone',
    type: 'input_type',
  },
  {
    label: 'Number',
    value: 'number',
    type: 'input_type',
  },
  {
    label: 'Url',
    value: 'url',
    type: 'input_type',
  },
  {
    label: 'Integer',
    value: 'integer',
    type: 'input_type',
  },
  {
    label: 'Text',
    value: 'text',
    type: 'input_type',
  },
];

export const isInputType = (val: string): boolean => {
  return INPUT_TYPES.some((inputType) => inputType.value === val);
};
