import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  datasourceEndpoints as endpoints,
  documentsEndpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import {
  Datasource,
  DatasourceStatus,
  DatasourceType,
  Datasources,
  PartialDatasource,
} from '@/models/collections';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';

import useHomeCheckList, { AccountUserPrefsEnum } from './useHomeCheckList';

const TWO_SECONDS = 2_000;

const emptyCollectionFiles = {
  total_count: 0,
  pending_count: 0,
  indexing_count: 0,
  failed_count: 0,
  available_count: 0,
};

const emptyFileCategories = {
  files: 0,
  website: 0,
  articles: 0,
};

export const API = Object.freeze({
  listDatasources: async (collectionId: string): Promise<Datasources> =>
    callGet(endpoints.datasources(collectionId)),

  getDatasource: async (
    collectionId: string,
    datasourceId: string
  ): Promise<Datasource> =>
    callGet(endpoints.datasource(collectionId, datasourceId)),

  createDatasource: async (
    collectionId: string,
    datasource: Partial<Datasource>
  ): Promise<Datasource> =>
    callPost(endpoints.datasources(collectionId), datasource),

  updateDatasource: async (
    collectionId: string,
    { datasource_id, ...datasource }: PartialDatasource
  ): Promise<Datasource> =>
    callPut(endpoints.datasource(collectionId, datasource_id), datasource),

  syncDatasource: async (
    collectionId: string,
    { datasource_id, ...datasource }: PartialDatasource
  ): Promise<Datasource> =>
    callPut(endpoints.syncDatasource(collectionId, datasource_id), datasource),

  deleteDatasource: async (
    collectionId: string,
    datasourceId: string
  ): Promise<Datasource> =>
    callDelete(endpoints.datasource(collectionId, datasourceId)),
});

export const onDatasourceUpdated = (
  queryClient: QueryClient,
  datasource: Datasource
) => {
  queryClient.setQueryData<Datasource>(
    [endpoints.datasource(datasource.collection_id, datasource.datasource_id)],
    (prev: Datasource) => ({ ...prev, ...datasource })
  );

  queryClient.invalidateQueries({
    queryKey: [
      documentsEndpoints.documents(
        datasource.collection_id,
        datasource?.datasource_id
      ),
    ],
  });

  const queryKey = [endpoints.datasources(datasource.collection_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Datasources>(queryKey, (prev: Datasources) => ({
      datasources: (prev?.datasources || []).map((item) =>
        item.datasource_id === datasource.datasource_id
          ? { ...item, ...datasource }
          : item
      ),
    }));
  }
};

export const onDatasourceCreated = (
  queryClient: QueryClient,
  datasource: Datasource
) => {
  const queryKey = [endpoints.datasources(datasource.collection_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Datasources>(queryKey, (prev) => ({
      datasources: [
        ...(prev?.datasources || []).filter(
          (acc) => acc.datasource_id !== datasource.datasource_id
        ),
        datasource,
      ],
    }));
  }
};

export const onDatasourceRemoved = (
  queryClient: QueryClient,
  collection_id: string,
  datasource_id: string
) => {
  const queryKey = [endpoints.datasources(collection_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Datasources>(queryKey, (prev: Datasources) => ({
      datasources: (prev?.datasources || []).filter(
        (acc) => acc.datasource_id !== datasource_id
      ),
    }));
  }
  queryClient.removeQueries({
    queryKey: [endpoints.datasource(collection_id, datasource_id)],
  });
};

const useDatasources = (collectionId: string, datasourceId?: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { markAsComplete } = useHomeCheckList();

  const [refetchInterval, setRefetchInterval] = useState<number>();

  const { data: datasources, status: listStatus } = useQuery<
    Datasources,
    Error
  >({
    queryKey: [endpoints.datasources(collectionId)],
    queryFn: () => API.listDatasources(collectionId),
    enabled: !!collectionId,
  });

  const { data: datasource, status: getStatus } = useQuery<Datasource, Error>({
    queryKey: [endpoints.datasource(collectionId, datasourceId)],
    queryFn: () => API.getDatasource(collectionId, datasourceId),
    enabled: !!collectionId && !!datasourceId,
    refetchInterval,
  });

  useEffect(() => {
    if (
      datasource?.status === DatasourceStatus.PROCESSING ||
      datasource?.pending_count > 0 ||
      datasource?.indexing_count > 0
    ) {
      setRefetchInterval(TWO_SECONDS);
    } else {
      setRefetchInterval(undefined);
    }
    queryClient.invalidateQueries({
      queryKey: [
        documentsEndpoints.documents(collectionId, datasource?.datasource_id),
      ],
    });
  }, [
    collectionId,
    datasource,
    datasource?.datasource_id,
    datasource?.status,
    queryClient,
    refetchInterval,
  ]);

  const { mutate: updateDatasource, status: updateStatus } = useMutation<
    Datasource,
    Error,
    PartialDatasource
  >({
    mutationFn: (data) => API.updateDatasource(collectionId, data),
    onSuccess: (resp) => {
      onDatasourceUpdated(queryClient, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('collections.datasources.updated', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: syncDatasource } = useMutation<
    Datasource,
    Error,
    PartialDatasource
  >({
    mutationFn: (data) => API.syncDatasource(collectionId, data),
    onSuccess: (resp) => {
      onDatasourceUpdated(queryClient, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('collections.datasources.synced', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createDatasource, status: createStatus } = useMutation<
    Datasource,
    Error,
    Partial<Datasource>
  >({
    mutationFn: (data) => API.createDatasource(collectionId, data),
    onSuccess: (resp) => {
      markAsComplete(AccountUserPrefsEnum.IMPORT_CONTENT);
      onDatasourceCreated(queryClient, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('collections.datasources.created', { 0: resp?.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: deleteDatasource,
    mutateAsync: deleteDatasourceAsync,
    status: deleteStatus,
  } = useMutation<Datasource, Error, string>({
    mutationFn: (id) => API.deleteDatasource(collectionId, id ?? datasourceId),
    onSuccess: (resp) => {
      onDatasourceRemoved(queryClient, collectionId, resp.datasource_id);
      dispatch(
        addTemporalToast('success', t('collections.datasources.deleted'))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const listDatasources = useCallback(
    async (collection_id: string): Promise<Datasources> => {
      const cachedDatasource = queryClient.getQueryData<Datasources>([
        endpoints.datasources(collection_id),
      ]);

      if (cachedDatasource) {
        return cachedDatasource;
      }
      const foundDatasources = await API.listDatasources(collection_id);

      queryClient.setQueryData<Datasources>(
        [endpoints.datasources(collection_id)],
        (prev) => ({ ...prev, ...foundDatasources })
      );

      return foundDatasources;
    },
    [queryClient]
  );

  const collectionFiles = useMemo(
    () =>
      datasources?.datasources.reduce(
        (acc, d) => {
          acc.total_count += d.document_count;
          acc.pending_count += d.pending_count;
          acc.indexing_count += d.indexing_count;
          acc.failed_count += d.failed_count;
          acc.available_count +=
            d.document_count -
            d.pending_count -
            d.indexing_count -
            d.failed_count;

          return acc;
        },
        { ...emptyCollectionFiles }
      ) ?? emptyCollectionFiles,
    [datasources?.datasources]
  );

  const fileCategories = useMemo(
    () =>
      datasources?.datasources.reduce(
        (acc, d) => {
          if (
            d.type === DatasourceType.INTERCOM_KB ||
            d.type === DatasourceType.ZENDESK_KB
          ) {
            acc.articles +=
              d.document_count -
              d.pending_count -
              d.indexing_count -
              d.failed_count;
          } else {
            acc[d.type] +=
              d.document_count -
              d.pending_count -
              d.indexing_count -
              d.failed_count;
          }
          return acc;
        },
        { ...emptyFileCategories }
      ) ?? emptyFileCategories,
    [datasources?.datasources]
  );

  const datasourceNames = useMemo(
    () => datasources?.datasources?.map((d) => d.name.toLowerCase()),
    [datasources?.datasources]
  );

  function generateModalReportData() {
    const start_time = datasource?.last_indexed_details?.start_time || null;
    const end_time = datasource?.last_indexed_details?.end_time || null;
    const warnings = datasource?.last_indexed_details?.warnings || [];
    const errors = datasource?.last_indexed_details?.errors || [];
    const document_count = datasource?.document_count || 0;

    const formatMoment = (date) => moment(date).format('DD/MM/YY HH:mm:ss');
    const diffMinutes = (start, end) =>
      moment(start).diff(moment(end), 'minutes');

    return [
      {
        title: t('collections.start'),
        value: start_time ? formatMoment(start_time) : '-',
      },
      {
        title: t('collections.end'),
        value: end_time ? formatMoment(end_time) : '-',
      },
      {
        title: t('collections.total_time'),
        value: start_time ? `${diffMinutes(start_time, end_time)} m` : '-',
      },
      {
        title: t('collections.total_urls'),
        value: `${document_count + warnings.length}`,
      },
      {
        title: t('collections.urls_ingested'),
        value: `${document_count}`,
      },
      {
        title: t('collections.urls_not_ingested'),
        value: `${warnings.length}`,
        urls: warnings,
      },
      {
        title: t('collections.urls_excluded'),
        value: `${errors.length}`,
        urls: errors,
      },
    ];
  }

  return {
    datasources: datasources?.datasources,
    collectionFiles,
    fileCategories,
    datasource,
    getStatus,
    listStatus,
    createStatus,
    deleteStatus,
    updateStatus,
    createDatasource,
    deleteDatasource,
    deleteDatasourceAsync,
    updateDatasource,
    syncDatasource,
    listDatasources,
    datasourceNames,
    generateModalReportData,
  };
};

export default useDatasources;
