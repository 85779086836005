import { useTranslation } from 'react-i18next';

type Props = {
  nodeId?: string;
  actionId?: string;
  type: string;
};

function ToolkitEmpty({ nodeId, actionId, type }: Props) {
  const { t } = useTranslation();
  return (
    <div className="toolkit">
      <div className="toolkit__header">
        <p>{t('common.empty')}</p>
      </div>
      <div className="toolkit__container">
        <p>
          {t('dialog.action_not_supported', { 0: type, 1: nodeId || actionId })}
        </p>
      </div>
    </div>
  );
}

export default ToolkitEmpty;
