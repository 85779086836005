import { useCallback, useMemo } from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { QueryVariables } from '@/modules/analytics/models';
import { selectAccountId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { parseFilter } from '@/util/util';

import { filterFiltersByUrl } from '../constants';
import { selectFilters } from '../redux/selectors';

interface Row {
  brain_parent_id: string;
  containment: number;
  coverage: number;
  avg_confidence: number;
  avg_response_time: number;
  median_confidence: number;
  median_response_time: number;
  max_response_time: number;
  min_response_time: number;
  avg_rating: number;
  ratings_more_than_3: number;
  closed_conversations: number;
  participated_sessions: number;
  ratings: number;
  replies: number;
}

interface ResultRow {
  brain_parent_id?: string;
  name?: string;
  containment: number;
  coverage: number;
  avg_confidence: number;
  avg_response_time: number;
  median_confidence: number;
  median_response_time: number;
  max_response_time: number;
  min_response_time: number;
  avg_rating: number;
  ratings_more_than_3: number;
  closed_conversations: number;
  participated_sessions: number;
  ratings: number;
  replies: number;
}
interface Rows {
  rows: Row[];
}

interface BrainsPerformanceHook {
  isLoading: boolean;
  error?: ApolloError;
  refetchCurrent?: () => void;
  data?: ResultRow[];
}

export type ResponseType =
  | 'median_response_time'
  | 'average_response_time'
  | 'min_response_time'
  | 'max_response_time';

export type BrainsPerformanceProps = {
  skip?: boolean;
  skipPrevious?: boolean;
  type: ResponseType;
};

const formatResponseTime = (
  t: (key: string) => string,
  current?: Row[],
  brains?: Brain[]
): ResultRow[] | undefined => {
  if (!current || current.length === 0 || !brains) {
    return undefined;
  }
  let deletedBrains = {
    containment: 0,
    coverage: 0,
    avg_confidence: 0,
    median_confidence: 0,
    replies: 0,
    avg_response_time: 0,
    median_response_time: 0,
    max_response_time: 0,
    min_response_time: 0,
    avg_rating: 0,
    ratings_more_than_3: 0,
    closed_conversations: 0,
    participated_sessions: 0,
    ratings: 0,
    length: 0,
  } as Partial<Row> & { name?: string; length?: number };

  const result = current.reduce((acc, item) => {
    const name = brains?.find(
      ({ brain_id }) => brain_id === item.brain_parent_id
    )?.name;

    if (name) {
      acc.push({
        brain_parent_id: item.brain_parent_id,
        name,
        containment: Math.round(item.containment * 100),
        coverage: Math.round(item.coverage * 100),
        avg_confidence: item.avg_confidence,
        median_confidence: item.median_confidence,
        replies: item.replies,
        avg_response_time: item.avg_response_time,
        median_response_time: item.median_response_time,
        max_response_time: item.max_response_time,
        min_response_time: item.min_response_time,
        avg_rating: item?.avg_rating || 0,
        ratings_more_than_3: item.ratings
          ? Math.ceil((item.ratings_more_than_3 / item.ratings) * 100)
          : 0,
        closed_conversations: item.closed_conversations,
        participated_sessions: item.participated_sessions,
        ratings: item.ratings,
      });
    } else {
      deletedBrains = {
        containment: deletedBrains.containment + item.containment,
        coverage: deletedBrains.coverage + item.coverage,
        avg_confidence: deletedBrains.avg_confidence + item.avg_confidence,
        median_confidence:
          deletedBrains.median_confidence + item.median_confidence,
        replies: deletedBrains.replies + item.replies,
        avg_response_time:
          deletedBrains.avg_response_time + item.avg_response_time,
        median_response_time:
          deletedBrains.median_response_time + item.median_response_time,
        max_response_time:
          deletedBrains.max_response_time < item.max_response_time
            ? item.max_response_time
            : deletedBrains.max_response_time,
        min_response_time:
          deletedBrains.min_response_time > item.min_response_time
            ? item.min_response_time
            : deletedBrains.min_response_time,
        avg_rating: deletedBrains?.avg_rating + item?.avg_rating,
        ratings_more_than_3:
          deletedBrains.ratings_more_than_3 + item.ratings_more_than_3,
        closed_conversations:
          deletedBrains.closed_conversations + item.closed_conversations,
        participated_sessions:
          deletedBrains.participated_sessions + item.participated_sessions,
        ratings: deletedBrains.ratings + item.ratings,
        length: deletedBrains.length + 1,
      };
    }
    return acc;
  }, [] as ResultRow[]);

  const formattedDeletedBrains = {
    containment: Math.round(
      (deletedBrains.containment / deletedBrains.length) * 100
    ),
    coverage: Math.round((deletedBrains.coverage / deletedBrains.length) * 100),
    avg_confidence: deletedBrains.avg_confidence / deletedBrains.length,
    median_confidence: deletedBrains.median_confidence / deletedBrains.length,
    replies: deletedBrains.replies,
    avg_response_time: deletedBrains.avg_response_time / deletedBrains.length,
    median_response_time:
      deletedBrains.median_response_time / deletedBrains.length,
    max_response_time: deletedBrains.max_response_time / deletedBrains.length,
    min_response_time: deletedBrains.min_response_time / deletedBrains.length,
    avg_rating: deletedBrains
      ? deletedBrains.avg_rating / deletedBrains.length || 0
      : 0,
    ratings_more_than_3: deletedBrains.length
      ? Math.ceil(
          (deletedBrains.ratings_more_than_3 / deletedBrains.ratings) * 100
        )
      : 0,
    closed_conversations: deletedBrains.closed_conversations,
    participated_sessions: deletedBrains.participated_sessions,
    ratings: deletedBrains.ratings,
    name: t('analytics.deleted_brains'),
  };
  return deletedBrains.length ? [...result, formattedDeletedBrains] : result;
};

export const BRAINS_PERFORMANCE = gql`
  query BrainsPerformance(
    $accountId: uuid
    $deskIds: _uuid
    $brainIds: _uuid
    $integrationIds: _uuid
    $brainVersions: _int4
    $channels: _text
    $startDate: timestamp
    $endDate: timestamp
    $isTest: Boolean
    $agentIds: _text
    $minNumUserMessages: Int
  ) {
    rows: brains_performance(
      args: {
        account_id: $accountId
        start_time: $startDate
        end_time: $endDate
        brain_parent_ids: $brainIds
        desk_ids: $deskIds
        integration_ids: $integrationIds
        brain_versions: $brainVersions
        channels: $channels
        is_test: $isTest
        agent_ids: $agentIds
        min_num_user_messages: $minNumUserMessages
      }
    ) {
      brain_parent_id
      containment
      coverage
      avg_confidence
      median_confidence
      avg_response_time
      avg_first_response_time
      median_response_time
      median_first_response_time
      max_response_time
      max_first_response_time
      min_response_time
      min_first_response_time
      avg_close_time
      median_close_time
      max_close_time
      min_close_time
      ratings
      avg_rating
      ratings_more_than_3
      closed_conversations
      participated_sessions
      replies
    }
  }
`;

const useBrainsPerformance = ({
  skip,
}: BrainsPerformanceProps): BrainsPerformanceHook => {
  const { t } = useTranslation();
  const accountId = useSelector(selectAccountId);
  const filters = useSelector(selectFilters);
  const { brains } = useBrains();

  const location = useLocation();

  const variables = useMemo(
    () =>
      Object.assign(
        {
          ...getAnalyticsRange(filters.startDate, filters.endDate),
          accountId,
        },
        ...filters.analytics
          .filter((filter) =>
            filterFiltersByUrl(filter.type, location.pathname)
          )
          .map((filter) => ({
            [filter.type]: parseFilter(filter),
          }))
      ),
    [
      accountId,
      filters.analytics,
      filters.endDate,
      filters.startDate,
      location.pathname,
    ]
  );

  const {
    data: currentData,
    loading: currentLoading,
    error,
    refetch: refetchCurrent,
  } = useQuery<Rows, QueryVariables>(BRAINS_PERFORMANCE, {
    variables,
    skip: skip || !accountId || !filters.filtersLoaded,
  });

  const currentRows = currentData?.rows;
  //TODO add number of ratings next to the positive one

  const data = useMemo(
    () => formatResponseTime(t, currentRows, brains),
    [t, currentRows, brains]
  );

  const onRefetchCurrent = useCallback(() => {
    refetchCurrent(variables);
  }, [refetchCurrent, variables]);

  if (currentLoading) {
    return {
      isLoading: true,
    };
  }

  if (error) {
    return {
      isLoading: false,
      error,
      refetchCurrent: onRefetchCurrent,
    };
  }

  return { isLoading: false, data };
};

export default useBrainsPerformance;
