export type CommonPanelProps = {
  onBackClick?: () => void;
  showPanel: boolean;
};

export const panelDefaultStyle = {
  transition: `transform 300ms ease-in-out`,
  transform: 'translateX(100%)', // Start off to the right
};

export const panelTransitionStyles = {
  entering: { transform: 'translateX(0%)' },
  entered: { transform: 'translateX(0%)' },
  exiting: { transform: 'translateX(100%)' },
  exited: { transform: 'translateX(100%)' },
};
