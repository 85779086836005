import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import Collection from '@/components/atoms/Icons/Collection';
import { MODAL_FORM } from '@/components/organisms/Modals/ModalConductor';
import { TileBrainSkeleton } from '@/components/organisms/Tile';
import TileCollection from '@/components/organisms/Tile/TileCollection/TileCollection';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import TileList from '@/components/organisms/Tile/TileList';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import styles from './BrainCollection.module.scss';

const BrainCollection = () => {
  const navigate = useNavigate();
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const slug = useSelector(selectAccountSlug);
  const { brain, updateBrain, getStatus: getBrainStatus } = useBrains(brainId);
  const { collection, collectionsByLanguage, isCollectionsLoading } =
    useCollections(brain?.collection_id);
  const dispatch = useDispatch();
  const { markAsComplete } = useHomeCheckList();
  const handleLinkClick = useCallback(() => {
    dispatch(popModal());
    navigate(`/${slug}/collections`);
  }, [dispatch, navigate, slug]);

  const collections = collectionsByLanguage(brain?.language) || [];

  const options = collections.map((collection) => ({
    label: collection.name,
    value: collection.collection_id,
  }));

  function renderSkeletons(n = 1) {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => <TileBrainSkeleton key={i} />)
    );
  }

  const handleKeyDown = useCallback(
    (e) => {
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        handleLinkClick();
      }
    },
    [handleLinkClick]
  );

  const handleClick = () => {
    const renameProps = {
      title: t('collections.connect_collection'),
      primaryButtonText: t('common.connect'),
      fields: [
        {
          fieldName: 'collections',
          fieldValue: '',
          type: 'select',
          placeholder: t('common.select_an_option'),
          options,
          rules: {
            required: true,
          },
        },
      ],
      banner: (
        <Banner relativePosition variant="neutral">
          {t('collections.connect_collection_banner1')}
          <span
            onClick={handleLinkClick}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={styles.link}
          >
            {t('common.collections')}
          </span>
          {t('collections.connect_collection_banner2')}
        </Banner>
      ),
      onCreate: (data: { collections: string }) => {
        updateBrain(
          {
            brain_id: brainId,
            collection_id: data.collections,
          },
          {
            onSuccess: () => {
              markAsComplete(AccountUserPrefsEnum.CONNECT_BRAIN);
              dispatch(popModal());
            },
          }
        );
      },
    };
    dispatch(pushModal(MODAL_FORM, renameProps));
  };

  const isLoading = getBrainStatus === 'pending' || isCollectionsLoading;

  return (
    <>
      <PageContentWrapper newPlain2 readOnly={!canWrite}>
        {canWrite && !collection && !isLoading && (
          <TileEmptyPage
            title={t('collections.no_connected')}
            notClickable
            body={
              <>
                {t('collections.connect_action')} <br />{' '}
                <Button onClick={handleClick} variant="tertiary">
                  {t('collections.connect_a_collection')}
                </Button>
              </>
            }
            icon={<Collection color="var(--icon-default-blue)" size={100} />}
          />
        )}
        <TileList>
          {collection && (
            <TileCollection
              collection={collection}
              key={collection?.collection_id}
              brain_id={brainId}
            />
          )}
          {isLoading && renderSkeletons()}
        </TileList>
      </PageContentWrapper>
    </>
  );
};

export default BrainCollection;
