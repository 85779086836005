import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import { Datasource } from '@/components/atoms/Icons/Datasource';
import { MODAL_FORM } from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';

import { InfoIcon } from '../../icons/InfoIcon';
import { Summary } from '../Summary/Summary';

export const StaticKnowledge = () => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);
  const slug = useSelector(selectAccountSlug);

  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { brain, updateBrain } = useBrains(brainId);
  const { markAsComplete } = useHomeCheckList();
  const { collection, collectionsByLanguage } = useCollections(
    brain?.collection_id
  );

  // Local variables
  const collections = collectionsByLanguage(brain?.language) || [];
  const options = collections.map((collection) => ({
    label: collection.name,
    value: collection.collection_id,
  }));

  // Handlers
  const handleConnectCollectionClick = () => {
    const renameProps = {
      title: t('collections.connect_collection'),
      primaryButtonText: t('common.connect'),
      fields: [
        {
          fieldName: 'collections',
          fieldValue: '',
          type: 'select',
          placeholder: t('common.select_an_option'),
          options,
          rules: {
            required: true,
          },
        },
      ],
      banner: (
        <Banner relativePosition variant="neutral">
          {t('collections.connect_collection_banner1')}
          <Link
            to={`/${slug}/collections`}
            onClick={() => {
              dispatch(popModal());
            }}
          >
            {t('common.collections')}
          </Link>
          {t('collections.connect_collection_banner2')}
        </Banner>
      ),
      onCreate: (data: { collections: string }) => {
        updateBrain(
          {
            brain_id: brainId,
            collection_id: data.collections,
          },
          {
            onSuccess: () => {
              markAsComplete(AccountUserPrefsEnum.CONNECT_BRAIN);
              dispatch(popModal());
            },
          }
        );
      },
    };
    dispatch(pushModal(MODAL_FORM, renameProps));
  };

  return (
    <>
      <Summary
        title={t('ai_agents.knowledge.static_knowledge').toUpperCase()}
        icon={
          <Tooltip
            arrow
            title={t('ai_agents.knowledge.static_knowledge_tooltip')}
          >
            <InfoIcon color="var(--icon-default-gray)" />
          </Tooltip>
        }
      >
        {collection ? (
          <>
            <Typography
              display="flex"
              alignItems="center"
              gap="var(--space-8)"
              color="var(--text-default-blue)"
            >
              <Datasource size={16} color="currentColor" />
              <Link to={`/${slug}/collections/${collection?.collection_id}`}>
                {collection?.name}
              </Link>
            </Typography>
          </>
        ) : (
          <Box
            component="button"
            display="flex"
            alignItems="center"
            gap="var(--space-8)"
            bgcolor="transparent"
            color="var(--text-default-blue)"
            onClick={handleConnectCollectionClick}
          >
            <Datasource size={16} color="currentColor" />
            <Typography color="currentColor">
              {t('collections.connect_collection')}
            </Typography>
          </Box>
        )}
      </Summary>
    </>
  );
};
