import { Icon } from '@/models/icon';

const ThemeDark = ({ size = 44 }: Icon) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      width={size}
      height={size}
    >
      <rect width="44" height="44" rx="4" fill="#202629" />
      <rect
        width="5.978"
        height="11.96"
        rx="2.989"
        transform="matrix(0 -1 -1 0 15.53 25.194)"
        fill="#21D184"
      />
      <ellipse
        rx="4.199"
        ry="4.197"
        transform="matrix(-1 0 0 1 25.818 22.182)"
        fill="#21D184"
      />
      <ellipse
        rx="2.449"
        ry="2.448"
        transform="matrix(-1 0 0 1 38.57 22.204)"
        fill="#21D184"
      />
      <rect
        x="30.061"
        y="35.639"
        width="3.978"
        height="9.96"
        rx="1.989"
        transform="rotate(-90 30.06 35.639)"
        stroke="#fff"
        strokeWidth="2"
      />
      <path
        d="M21.971 33.626a3.198 3.198 0 0 1-3.199 3.197 3.198 3.198 0 1 1 3.2-3.197ZM7.47 33.649a1.449 1.449 0 1 1-2.899-.001 1.449 1.449 0 0 1 2.898 0Z"
        stroke="#fff"
        strokeWidth="2"
      />
      <rect
        x="30.061"
        y="12.75"
        width="3.978"
        height="9.96"
        rx="1.989"
        transform="rotate(-90 30.06 12.75)"
        stroke="#fff"
        strokeWidth="2"
      />
      <path
        d="M21.971 10.737a3.198 3.198 0 0 1-3.199 3.198 3.198 3.198 0 1 1 3.2-3.198ZM7.47 10.76a1.449 1.449 0 1 1-2.899 0 1.449 1.449 0 0 1 2.898 0Z"
        stroke="#fff"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ThemeDark;
