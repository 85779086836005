import { useState } from 'react';

import Skeleton from '@mui/material/Skeleton/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import Clock from '@/components/atoms/Icons/Clock';
import Email from '@/components/atoms/Icons/Email';
import Globe from '@/components/atoms/Icons/Globe';
import House from '@/components/atoms/Icons/House';
import LocationPin from '@/components/atoms/Icons/LocationPin';
import Phone from '@/components/atoms/Icons/Phone';
import { useConversationContext } from '@/hooks/useConversationContext';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useInformationSection } from '@/modules/humanChat/hooks/useInformationSection';
import {
  visitorAddressSchema,
  visitorEmailSchema,
  visitorPhoneSchema,
} from '@/modules/humanChat/validator';
import { getPermissions } from '@/redux/permissions/selectors';

import { CustomDetails } from '../CustomDetails/CustomDetails';
import { InformationPanel } from '../Panels/InformationPanel';

import styles from './InformationSection.module.scss';

export const IconByField: Record<string, JSX.Element> = {
  email: <Email size={16} color="var(--icon-default-gray)" />,
  local_time: <Clock size={16} color="var(--icon-default-gray)" />,
  languages: <Globe size={16} color="var(--icon-default-gray)" />,
  address: <House size={16} color="var(--icon-default-gray)" />,
  phone: <Phone size={16} color="var(--icon-default-gray)" />,
  location: <LocationPin size={16} color="var(--icon-default-gray)" />,
};

const InputSkeleton = () => <Skeleton width={80} height={20} />;

export const InformationSection = () => {
  // Local state
  const [showPanel, setShowPanel] = useState(false);

  // Hooks
  const { t } = useTranslation();
  const { isLoading, updateUser } = useConversationContext();
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );
  const { items, foundItems } = useInformationSection();

  // Handlers
  const onBackClick = () => {
    setShowPanel(false);
  };

  const handleSubmit = (key: string, value: string, prevValue: string) => {
    if (prevValue === value) return;

    updateUser({
      [key]: value,
    });
  };

  const listItem = (item) => {
    switch (item.id) {
      case 'email': {
        return (
          <li
            key={item.id}
            className={cn({
              [styles.isEditable]: !isLoading,
            })}
          >
            <Tooltip arrow title={t('field.email')}>
              {IconByField.email}
            </Tooltip>

            <span>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <EditableText<typeof visitorEmailSchema>
                  defaultValue={item.text}
                  onSubmit={(value) => handleSubmit('email', value, item.text)}
                  color="var(--text-default-black)"
                  validationSchema={visitorEmailSchema}
                  placeholder={t('contacts.set_email')}
                  canEdit={canWrite}
                />
              )}
            </span>
          </li>
        );
      }

      case 'address': {
        return (
          <li
            key={item.id}
            className={cn({
              [styles.isEditable]: !isLoading,
            })}
          >
            <Tooltip arrow title={t('field.address')}>
              {IconByField.address}
            </Tooltip>
            <span>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <EditableText<typeof visitorAddressSchema>
                  defaultValue={item.text}
                  onSubmit={(value) =>
                    handleSubmit('address', value, item.text)
                  }
                  validationSchema={visitorAddressSchema}
                  color="var(--text-default-black)"
                  placeholder={t('contacts.set_address')}
                  canEdit={canWrite}
                />
              )}
            </span>
          </li>
        );
      }
      case 'phone': {
        return (
          <li
            key={item.id}
            className={cn({
              [styles.isEditable]: !isLoading,
            })}
          >
            <Tooltip arrow title={t('field.phone')}>
              {item.icon}
            </Tooltip>
            <span className={styles.time}>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <>
                  <EditableText<typeof visitorPhoneSchema>
                    defaultValue={item.text}
                    onSubmit={(value) =>
                      handleSubmit('phone', value, item.text)
                    }
                    color="var(--text-default-black)"
                    validationSchema={visitorPhoneSchema}
                    placeholder={t('contacts.set_phone')}
                    canEdit={canWrite}
                  />
                </>
              )}
            </span>
          </li>
        );
      }
      default: {
        return (
          <li key={item.id}>
            <Tooltip arrow title={t(`field.${item.id}`)}>
              {item.icon}
            </Tooltip>
            <span>{isLoading ? <InputSkeleton /> : item.text}</span>
          </li>
        );
      }
    }
  };

  return (
    <section>
      <CustomDetails title={t('common.information')}>
        <ul className={styles.infoList}>
          {foundItems.map(listItem)}

          <li>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowPanel(true);
              }}
              variant="anchor"
            >
              {t('onboarding.show_all', {
                0: '',
              })}
            </Button>
          </li>
        </ul>
      </CustomDetails>

      <InformationPanel
        showPanel={showPanel}
        onBackClick={onBackClick}
        items={items}
        pinnedItems={foundItems.map((item) => item.id)}
      />
    </section>
  );
};
