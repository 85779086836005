const defaultState = {
  skipAppVersion: false,
};
const UPDATE_LATER = 'UPDATE/LATER';
const reducer = (state = defaultState, { type }: Partial<{ type: string }>) => {
  if (type === UPDATE_LATER) return { skipAppVersion: true };
  else return state;
};

export const skipAppVersion = () => ({ type: UPDATE_LATER });
export default reducer;
