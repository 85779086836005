import { useCallback, useEffect, useState } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import Input from '@/components/atoms/Input/Input';
import PhoneInput from '@/components/atoms/Input/PhoneInput';
import Link from '@/components/atoms/Link/Link';
import Select from '@/components/atoms/Select/Select';
import Modal from '@/components/organisms/Modals/Modal';
import { useAccount } from '@/hooks/useAccount';
import useDesks from '@/hooks/useDesks';
import { useIntegrations } from '@/hooks/useIntegrations';
import { TestNumberWithDeskId } from '@/hooks/useWhatsappTesting';
import { ApiError } from '@/models/api';
import { Integration, WhatsAppIntegration } from '@/models/integration';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { errorMessage, whatsappTestNumber } from '@/util/validator';

type AddNumberSubModalProps = {
  onCreate: (pn: TestNumberWithDeskId, int: Integration) => Promise<void>;
  onCreateError: Error;
};

type Form = {
  label: string;
  phone_number: string;
  desk_id: string;
};

const AddNumberSubModal = ({
  onCreate,
  onCreateError,
}: AddNumberSubModalProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { slug } = useAccount();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<Form>();

  const desk_id = useWatch({ control, name: 'desk_id' });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { desk, desks, listStatus } = useDesks(desk_id);
  const integration_id = desk?.integrations?.find(
    (int) => int.type === 'whatsapp'
  )?.integration_id;
  const { integration } = useIntegrations<WhatsAppIntegration>(
    desk_id,
    integration_id
  );

  const showWarning =
    integration && integration?.config?.test_numbers?.length >= 4;

  const environmentOptions = desks
    ?.map((desk) => ({
      value: desk.desk_id,
      label: desk.name,
      integration_id: desk.integrations.find((int) => int.type === 'whatsapp')
        ?.integration_id,
    }))
    ?.filter((opt) => !!opt.integration_id);

  const onNumberAdd = useCallback(
    async (testNumber: Form) => {
      setIsSubmitting(true);
      try {
        await onCreate(
          { ...testNumber, integration_id } as TestNumberWithDeskId,
          integration
        );
      } finally {
        setIsSubmitting(false);
      }
    },
    [integration, integration_id, onCreate]
  );

  useEffect(() => {
    if (!onCreateError) {
      return;
    }
    const err = onCreateError as unknown as ApiError;
    if (err.statusCode === 409) {
      setError(
        `phone_number`,
        {
          type: 'custom',
          message: t('integrations.whatsapp.testing.num_conflict_error'),
        },
        { shouldFocus: true }
      );
      return;
    }
    dispatch(addErrorTemporalToast(onCreateError));
  }, [dispatch, getValues, onCreateError, setError, t]);

  return (
    <Modal
      title={t('whatsapp_test.add_num.title')}
      primaryButtonText={t('whatsapp_test.add_num.add_number')}
      onPrimarySubmit={handleSubmit(onNumberAdd)}
      primaryButtonDisable={
        listStatus === 'pending' || showWarning || !integration_id || !desk_id
      }
      isSubmitting={isSubmitting}
      hideSecondaryButton
      autoFocus
    >
      <Input
        name="label"
        label={t('integrations.whatsapp.testing.name')}
        {...register('label', whatsappTestNumber.label)}
        errorMessage={errorMessage({
          field: errors.label,
          maxLength: whatsappTestNumber.label.maxLength,
        })}
      />
      <PhoneInput
        label={t('integrations.whatsapp.testing.phone_num')}
        name="phone_number"
        control={control}
        rules={whatsappTestNumber.phone_number}
        errorMessage={errorMessage({
          field: errors.phone_number,
        })}
      />
      <Select
        name="desk_id"
        placeholder={t('try_it.select_environment')}
        label={t('environments.environment')}
        options={environmentOptions}
        size="full"
        enablePlaceholder
        errorMessage={errorMessage({
          field: errors.desk_id,
        })}
        {...register('desk_id', whatsappTestNumber.desk_id)}
      />
      {showWarning && (
        <Banner relativePosition variant="critical">
          <Trans
            i18nKey={'whatsapp_test.add_num.warning'}
            components={{
              link1: (
                <Link
                  href={`/${slug}/environments/${desk_id}/integrations/${integration_id}`}
                  onClick={() => {
                    dispatch(popModal());
                  }}
                />
              ),
            }}
          />
        </Banner>
      )}
    </Modal>
  );
};

export default AddNumberSubModal;
