import i18n from 'i18next';

import { ActionType } from '@/modules/rules/model';
export const RULES_ATTRIBUTES_LABEL_TO_VALUE = {
  in: 'in',
  out: 'out',
  language: 'language',
  timezone: 'timezone',
  'location country': 'location.country',
  city: 'location.city',
  email: 'email',
  phone: 'phone',
  'display name': 'display_name',
  browser: 'browser',
  platform: 'platform',
  'location city': 'location.city',
  'first seen': 'first_seen',
  'last seen': 'last_seen',
  'last contacted': 'last_contacted',
  'last heard from': 'last_heard_from',
  agent: 'agent_id',
  desk: 'desk_id',
  integration: 'integration_id',
  tag: 'tag',
  facebook: 'facebook',
  android: 'android',
  ios: 'ios',
  twitter: 'twitter',
  web: 'web',
  intercom: 'intercom',
  whatsapp: 'whatsapp',
  viber: 'viber',
  zendesk: 'zendesk',
  instagram: 'instagram',
  text: 'text',
  'global client_url': 'global.client_url',
  'global ref': 'global.ref',
} as const;

export const RULES_ATTRIBUTES_VALUE_TO_LABEL = {
  in: 'in',
  out: 'out',
  language: 'language',
  timezone: 'timezone',
  email: 'email',
  phone: 'phone',
  display_name: 'display name',
  browser: 'browser',
  platform: 'platform',
  city: 'location city',
  first_seen: 'first seen',
  last_seen: 'last seen',
  last_contacted: 'last contacted',
  last_heard_from: 'last heard from',
  agent_id: 'agent',
  desk_id: 'desk',
  integration_id: 'integration',
  tag: 'tag',
  text: 'text',
  facebook: 'facebook',
  android: 'android',
  ios: 'ios',
  twitter: 'twitter',
  web: 'web',
  intercom: 'intercom',
  whatsapp: 'whatsapp',
  viber: 'viber',
  zendesk: 'zendesk',
  instagram: 'instagram',
  client_url: 'customer url',
  ref: 'ref',
  country: 'country',
  channel: 'channel',
} as const;

export const RULES_CONDITON_RADIO_OPTIONS = (option) => {
  switch (option) {
    case 'business_hours':
      return [
        {
          id: 0,
          label: i18n.t('rules.in'),
          value: 'in',
        },
        {
          id: 1,
          label: i18n.t('rules.out'),
          value: 'out',
        },
      ];

    case 'language':
    case 'timezone':
    case 'location.country':
      return [
        { id: 0, label: i18n.t('rules.is'), value: 'eq' },
        { id: 1, label: i18n.t('rules.ne'), value: 'ne' },
        { id: 2, label: i18n.t('rules.unknown'), value: 'unknown' },
        { id: 3, label: i18n.t('rules.known'), value: 'known' },
      ];

    case 'first_seen':
    case 'last_seen':
    case 'last_contacted':
    case 'last_heard_from':
      return [
        { id: 0, label: i18n.t('rules.gt'), value: 'gt' },
        { id: 1, label: i18n.t('rules.ls'), value: 'ls' },
        { id: 2, label: i18n.t('rules.is'), value: 'eq' },
        { id: 3, label: i18n.t('rules.ne'), value: 'ne' },
        { id: 4, label: i18n.t('rules.unknown'), value: 'unknown' },
        { id: 5, label: i18n.t('rules.known'), value: 'known' },
      ];

    case 'agent_id':
    case 'desk_id':
    case 'integration_id':
      return [
        { id: 0, label: i18n.t('rules.is'), value: 'eq' },
        { id: 1, label: i18n.t('rules.ne'), value: 'ne' },
        { id: 2, label: i18n.t('rules.unknown'), value: 'unknown' },
        { id: 3, label: i18n.t('rules.known'), value: 'known' },
      ];

    case 'facebook':
    case 'android':
    case 'ios':
    case 'twitter':
    case 'web':
    case 'intercom':
    case 'whatsapp':
    case 'viber':
    case 'zendesk':
    case 'instagram':
    case 'channel':
      return [
        { id: 0, label: i18n.t('rules.is'), value: 'eq' },
        { id: 1, label: i18n.t('rules.ne'), value: 'ne' },
        { id: 2, label: i18n.t('rules.unknown'), value: 'unknown' },
        { id: 3, label: i18n.t('rules.known'), value: 'known' },
      ];

    case 'text':
    case 'global.ref':
    case 'global.client_url':
    case 'email':
    case 'phone':
    case 'display_name':
    case 'browser':
    case 'platform':
    case 'location.city':
      return [
        { id: 0, label: i18n.t('rules.is'), value: 'eq' },
        { id: 1, label: i18n.t('rules.ne'), value: 'ne' },
        { id: 2, label: i18n.t('rules.starts_with'), value: 'starts_with' },
        { id: 3, label: i18n.t('rules.ends_with'), value: 'ends_with' },
        { id: 4, label: i18n.t('rules.contains'), value: 'contains' },
        { id: 5, label: i18n.t('rules.not_contains'), value: 'not_contains' },
        { id: 6, label: i18n.t('rules.unknown'), value: 'unknown' },
        { id: 7, label: i18n.t('rules.known'), value: 'known' },
      ];

    case 'tag':
      return [
        { id: 0, label: i18n.t('rules.is'), value: 'eq' },
        { id: 1, label: i18n.t('rules.ne'), value: 'ne' },
      ];
  }
};

export const ACTIONS_VALUE_TO_LABEL = [
  ActionType.ASSIGN_BRAIN,
  ActionType.ASSIGN_COLLECTION,
  ActionType.ASSIGN_DEPARTMENT,
  ActionType.APPLY_BUNDLE,
  ActionType.ADD_TAG,
  ActionType.CLOSE_CONVERSATION,
];

export const TRIGGERS_VALUE_TO_LABEL = ['first_message', 'any_user_message'];

export const CONDITION_ATTRIBUTES_LABEL_TO_VALUE = {
  'rules.availability': {
    'business hours': 'business_hours',
  },
  'rules.user': {
    name: 'user.display_name',
    email: 'user.email',
    phone: 'user.phone',
    language: 'user.language',
    timezone: 'user.timezone',
    country: 'user.location.country',
    city: 'user.location.city',
    browser: 'user.browser',
    platform: 'user.platform',
  },
  'rules.conversation': {
    'from channel': 'channel.channel',
    'message content': 'message.text',
    tag: 'session.tag',
    'customer url': 'context.global.client_url',
    'referral url': 'context.global.ref',
    'agent assigned': 'session.agent_id',
  },
};

export const CONDITION_ATTRIBUTES_VALUE_TO_LABEL = {
  business_hours: 'business hours',
  'user.display_name': 'name',
  'user.email': 'email',
  'user.phone': 'phone',
  'user.language': 'language',
  'user.timezone': 'timezone',
  'user.location.country': 'country',
  'user.location.city': 'city',
  'user.browser': 'browser',
  'user.platform': 'platform',
  'session.first_seen': 'first seen',
  'session.last_seen': 'last seen',
  'session.last_contacted': 'last contacted',
  'session.last_heard_from': 'last heard from',
  'message.text': 'message content',
  'session.tag': 'tag',
  'context.global.client_url': 'customer url',
  'context.global.ref': 'referral url',
  'session.agent_id': 'agent assigned',
  'session.integration_id': 'from integration',
} as const;

export const CHANNEL_VALUE_TO_LABEL = {
  web: 'web',
  intercom: 'intercom',
  whatsapp: 'whatsapp',
  viber: 'viber',
  zendesk: 'zendesk',
  facebook: 'facebook',
  instagram: 'instagram',
} as const;

export const CONTEXT_VALUE_TO_LABEL = {
  ref: 'referral url',
  client_url: 'customer url',
};
