import { useSelector } from 'react-redux';

import DeleteFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/DeleteFieldset';
import { useIntegrations } from '@/hooks/useIntegrations';
import { FacebookError, FacebookIntegration } from '@/models/integration';
import { selectDeskId, selectIntegrationId } from '@/redux/session/selectors';

import RedirectFieldset from './fieldsets/RedirectFieldset';

interface Props {
  onOAuthClick: () => void;
  onManualClick: () => void;
  previousFBConnection?: string;
  error?: FacebookError;
  isConnecting?: boolean;
}

const FacebookOAuth = ({
  onOAuthClick,
  onManualClick,
  previousFBConnection,
  error,
  isConnecting,
}: Props) => {
  const deskId = useSelector(selectDeskId);
  const integrationId = useSelector(selectIntegrationId);
  const { integration } = useIntegrations<FacebookIntegration>(
    deskId,
    integrationId
  );

  return (
    <>
      <RedirectFieldset
        onOAuthClick={onOAuthClick}
        onManualClick={onManualClick}
        type={integration?.type}
        error={error}
        previousFBConnection={previousFBConnection}
        isConnecting={isConnecting}
      />
      <DeleteFieldset integration={integration} />
    </>
  );
};

export default FacebookOAuth;
