import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  accountsEndpoints as endpoints,
  permissionsEndpoints,
  membersEndpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { Account, Accounts, Agent, Agents } from '@/models/account';
import { RoleType } from '@/models/member';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { selectAllAgents } from '@/redux/presence/selectors';
import { setAccountSlug } from '@/redux/session/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { selectIsAuthenticated } from '@/redux/user/selectors';

import useLocalStorage from './useLocalStorage';
import useUser from './useUser';

const API = {
  listAccounts: async (): Promise<Accounts> => callGet(endpoints.accounts),
  getAccount: async (): Promise<Account> => callGet(endpoints.account),
  createAccount: async (newAccount: Partial<Account>): Promise<Account> =>
    callPost(endpoints.accounts, newAccount),
  updateAccount: async (updates: Partial<Account>): Promise<Account> =>
    callPut(endpoints.account, updates),
  deleteAccount: async (): Promise<Account> => callDelete(endpoints.account),
  checkSlug: async (id: string): Promise<boolean> => {
    try {
      await callGet(endpoints.checkSlug(id));
      return true;
    } catch (error) {
      return false;
    }
  },
  listAgents: async (): Promise<Agents> => callGet(endpoints.agents),
  transferOwnership: async (data: { new_owner_id: string }): Promise<Account> =>
    callPut(endpoints.transfer, data),
};

export const useAccount = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const accountSlug = useSelector(selectAccountSlug);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const presenceAgents = useSelector(selectAllAgents);
  const [lastUsedAccountSlug, setLastUsedAccountSlug] = useLocalStorage(
    'lastUsedAccountSlug',
    accountSlug
  );

  const { data: accounts, status: accountsStatus } = useQuery<Accounts, Error>({
    queryKey: [endpoints.accounts, user?.user_id],
    queryFn: API.listAccounts,
    enabled: isAuthenticated,
  });

  const sortedAccounts = useMemo(
    () =>
      accounts?.accounts?.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      ),
    [accounts]
  );

  const { data: account, status: accountStatus } = useQuery<Account, Error>({
    queryKey: [endpoints.account, accountSlug],
    queryFn: API.getAccount,
    enabled: isAuthenticated && !!accountSlug && !!user,
  });

  const { mutate: updateAccount, status: updateStatus } = useMutation<
    Account,
    Error,
    Partial<Account>
  >({
    mutationFn: API.updateAccount,
    onSuccess: (resp) => {
      queryClient.setQueryData<Account>(
        [endpoints.account, accountSlug],
        (prev: Account) => ({ ...prev, ...resp })
      );

      queryClient.setQueryData<Accounts>(
        [endpoints.accounts, user?.user_id],
        (prev: Accounts) => ({
          accounts: prev?.accounts.map((item) =>
            item.account_id === resp.account_id ? { ...item, ...resp } : item
          ),
        })
      );
    },
  });

  const { mutate: createAccount, status: createStatus } = useMutation<
    Account,
    Error,
    Partial<Account>
  >({
    mutationFn: API.createAccount,
    onSuccess: (resp) => {
      queryClient.setQueryData<Accounts>(
        [endpoints.accounts, user?.user_id],
        (prev) => ({
          accounts: [...(prev?.accounts || []), resp],
        })
      );
      dispatch(addTemporalToast('success', t('accounts.created')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: transferOwnership, status: transferOwnershipStatus } =
    useMutation<Account, Error, { new_owner_id: string }>({
      mutationFn: API.transferOwnership,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [endpoints.account, accountSlug],
        });
        queryClient.invalidateQueries({
          queryKey: [membersEndpoints.members, accountSlug],
        });
        queryClient.invalidateQueries({
          queryKey: [endpoints.accounts, user?.user_id],
        });
        queryClient.invalidateQueries({
          queryKey: [permissionsEndpoints.permissions, account?.account_id],
        });
      },
    });

  const { mutate: deleteAccount, status: deleteStatus } = useMutation<
    Account,
    Error
  >({
    mutationFn: API.deleteAccount,
    onSuccess: () => {
      dispatch(popModal());
      queryClient.removeQueries({ queryKey: [endpoints.account, accountSlug] });
      const newAccounts = queryClient.setQueryData<Accounts>(
        [endpoints.accounts, user?.user_id],
        (prev: Accounts) => {
          const accountsCopyArray = prev
            ? prev?.accounts.filter((acc) => acc.slug !== accountSlug)
            : [];

          return { accounts: accountsCopyArray };
        }
      );
      navigate(`/${newAccounts.accounts[0].slug}/`);
      dispatch(setAccountSlug(newAccounts.accounts[0].slug));
      dispatch(addTemporalToast('success', t('accounts.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutateAsync: checkSlugAsync } = useMutation<boolean, Error, string>({
    mutationFn: API.checkSlug,
  });

  // find the role of the user
  const member = account?.members?.find((m) => m.user_id === user?.user_id);

  // Validates if the account slug belongs to the user
  const isValidSlug = () => {
    const availableSlugs = user?.accounts?.map((account) => account.slug);
    return availableSlugs?.includes(accountSlug);
  };

  const { data: agentsIds } = useQuery<Agents, Error>({
    queryKey: [endpoints.agents, accountSlug],
    queryFn: API.listAgents,
    enabled: isAuthenticated && !!accountSlug && !!user,
  });

  const agents = useMemo(
    () =>
      presenceAgents?.reduce((acc, agent) => {
        if (agentsIds?.agent_ids.includes(agent.agent_id)) {
          acc.push({
            name: agent.name,
            agent_id: agent.agent_id,
            email: agent.email,
            avatar: agent.avatar,
          });
        }
        return acc as Agent[];
      }, []) as Agent[],
    [agentsIds, presenceAgents]
  );

  const agentsExceptUser = useMemo(() => {
    return agents?.filter((agent) => agent.agent_id !== user?.user_id);
  }, [agents, user?.user_id]);

  return {
    accounts: sortedAccounts,
    accountsStatus,
    accountId: account?.account_id,
    // Temporary while we setup slugs for all accounts
    slug: account?.slug,
    account,
    accountStatus,
    accountRole: member?.role as RoleType,
    createAccount,
    createStatus,
    deleteAccount,
    deleteStatus,
    updateAccount,
    updateStatus,
    checkSlugAsync,
    transferOwnership,
    transferOwnershipStatus,
    lastUsedAccountSlug: accounts?.accounts?.find(
      (acc) => acc.slug === lastUsedAccountSlug
    )?.slug,
    setLastUsedAccountSlug,
    isValidSlug,
    agents,
    agentsExceptUser,
  };
};
