import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Bar from '@/components/organisms/Analytics/Bar/Bar';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';

import ScoreCard, { ScoreCards } from './ScoreCard/ScoreCard';
import useScoreCard from '../../../hooks/useScoreCard';

const ScoreCardsFieldset = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(0);
  const [showPrevious, setShowPrevious] = useState(false);
  const messages = useScoreCard({
    intervalProps: {
      type: 'dialogs',
    },
    skipPrevious: !showPrevious,
  });
  const sessions = useScoreCard({
    intervalProps: { type: 'sessions' },
    skip: messages.isLoading,
    skipPrevious: !showPrevious,
  });
  const users = useScoreCard({
    intervalProps: { type: 'users' },
    skip: messages.isLoading || sessions.isLoading,
    skipPrevious: !showPrevious,
  });

  const totalMessages = t('analytics.totalMessages');
  const totalConversations = t('analytics.totalSessions');
  const totalCustomers = t('analytics.totalUsers');

  const handleReload = () => {
    messages.refetchCurrent();
    sessions.refetchCurrent();
    users.refetchCurrent();
  };

  const scoreCards = useMemo(
    () => [messages.data, sessions.data, users.data],
    [messages.data, sessions.data, users.data]
  );

  const handlePreviousClick = () => {
    setShowPrevious((prev) => !prev);
  };

  const isPreviousLoaded = typeof scoreCards[active]?.growth === 'number';

  const isError =
    messages.currentError || sessions.currentError || users.currentError;
  const isEmpty =
    !messages.isLoading && (!messages.data || messages.data.counts === 0);

  const isLoading = messages.isLoading;

  return (
    <AnalyticsFieldset
      fullWidth
      hasFooter={false}
      isEmpty={isEmpty}
      error={isError}
      isLoading={isLoading}
      onReload={handleReload}
    >
      <ScoreCards
        onPreviousClick={handlePreviousClick}
        showPrevious={showPrevious}
        data={scoreCards}
      >
        <ScoreCard
          title={totalMessages}
          active={active === 0}
          tooltip={t('analytics.totalMessagesTooltip')}
          counts={scoreCards[0]?.counts}
          exactCounts={scoreCards[0]?.exact_counts}
          growth={scoreCards[0]?.growth}
          onClick={() => {
            setActive(0);
          }}
          isPreviousLoading={messages.isPreviousLoading}
        />
        <ScoreCard
          title={totalConversations}
          active={active === 1}
          tooltip={t('analytics.totalSessionsTooltip')}
          counts={scoreCards[1]?.counts}
          exactCounts={scoreCards[1]?.exact_counts}
          growth={scoreCards[1]?.growth}
          onClick={() => {
            setActive(1);
          }}
          isPreviousLoading={sessions.isPreviousLoading}
        />
        <ScoreCard
          title={totalCustomers}
          active={active === 2}
          tooltip={t('analytics.totalUsersTooltip')}
          counts={scoreCards[2]?.counts}
          exactCounts={scoreCards[2]?.exact_counts}
          growth={scoreCards[2]?.growth}
          onClick={() => {
            setActive(2);
          }}
          isPreviousLoading={users.isPreviousLoading}
        />
      </ScoreCards>

      <Bar
        axisY={t('analytics.amount')}
        axisX={t('common.day')}
        keys={
          showPrevious && isPreviousLoaded
            ? ['primary', 'secondary']
            : ['primary']
        }
        indexBy="date"
        data={scoreCards[active]?.series}
        groupMode={showPrevious && isPreviousLoaded ? 'grouped' : undefined}
      />
    </AnalyticsFieldset>
  );
};

export default ScoreCardsFieldset;
