import { useSelector } from 'react-redux';

import { DialogAlert } from '@/models/dialogAlert';
import { selectDialogAlerts } from '@/redux/dialogAlerts/selectors';

const hasError = (id: string, dialogErrors: DialogAlert[], key: string) => {
  return dialogErrors.some(
    (error) => error[key] === id && error.alertType === 'error'
  );
};

const hasWarning = (id: string, dialogWarning: DialogAlert[], key: string) => {
  return dialogWarning.some(
    (warning) => warning[key] === id && warning.alertType === 'warning'
  );
};

const useDialogAlert = (id: string, key = 'id') => {
  const dialogAlerts = useSelector(selectDialogAlerts);

  return {
    hasDialogError: hasError(id, dialogAlerts, key),
    hasDialogWarning: hasWarning(id, dialogAlerts, key),
  };
};

export default useDialogAlert;
