import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Telegram = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
          fill="#37AEE2"
        />
        <path
          d="M9.8 17.5c-.389 0-.323-.147-.457-.517L8.2 13.221 17 8"
          fill="#C8DAEA"
        />
        <path
          d="M9.8 17.5c.3 0 .432-.137.6-.3l1.6-1.556-1.996-1.203"
          fill="#A9C9DD"
        />
        <path
          d="M10.004 14.44l4.836 3.574c.552.304.95.146 1.088-.513l1.968-9.276c.202-.808-.308-1.175-.836-.935L5.501 11.747c-.789.317-.784.757-.144.953l2.967.926 6.867-4.333c.324-.196.622-.09.377.126"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="13.504"
            y1="12.001"
            x2="15.377"
            y2="16.268"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF7FC" />
            <stop offset="1" stopColor="var(--icon-default-white)" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
);

Telegram.displayName = 'Telegram';

export default Telegram;
