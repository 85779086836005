import { useCallback, useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Input from '@/components/atoms/Input/Input';
import Modal from '@/components/organisms/Modals/Modal';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import {
  addTemporalToast,
  safelyGetErrorMessage,
} from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { isRequired } from '@/util/validator';

import WithStripeElements from './WithStripeElements/WithStripeElements';

import s from './ModalAddCard.module.scss';

const cardStyle: StripeCardElementOptions = {
  style: {
    base: {
      color: 'inherit',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      '::placeholder': {
        color: 'var(--text-default-gray-light)',
      },
    },
    invalid: {
      color: '#f00',
      iconColor: '#f00',
    },
  },
};

type Form = {
  cardholderName: string;
};

type Props = { onSuccess?: () => void };

function ModalAddCard({ onSuccess }: Props) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Form>();
  const { attachPaymentMethod, paymentMethods, updateCustomerAsync } =
    useBilling();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!elements || !stripe) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    setIsLoading(true);
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (!paymentMethod) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw error ?? new Error(t('billing.payment.cannot_be_created'));
      }
      // Attach to customer
      let makeDefault = false;
      if (isEmpty(paymentMethods)) {
        makeDefault = true;
      }
      attachPaymentMethod(paymentMethod, {
        onError: (err) => {
          throw err;
        },
        onSuccess: async () => {
          if (makeDefault) {
            await updateCustomerAsync({
              invoice_settings: { default_payment_method: paymentMethod.id },
            }).catch((err) => {
              throw err;
            });
          }
          dispatch(
            addTemporalToast('success', t('billing.payment_method_added'))
          );
          dispatch(popModal());
          if (onSuccess) {
            onSuccess();
          }
        },
      });
    } catch (error) {
      dispatch(addTemporalToast('error', safelyGetErrorMessage(error)));
      setIsLoading(false);
    }
  }, [
    elements,
    stripe,
    paymentMethods,
    attachPaymentMethod,
    t,
    dispatch,
    onSuccess,
    updateCustomerAsync,
  ]);

  return (
    <Modal
      title={t('billing.payment_add_card')}
      subtitle={t('billing.payment_enter_card_details')}
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('common.add')}
      isSubmitting={isLoading}
      autoFocus
    >
      <form>
        <Input
          label={t('billing.payment.cardholder_name')}
          register={register('cardholderName', isRequired)}
          name="cardholderName"
          errorMessage={errors.cardholderName?.message}
        />
        <label htmlFor="card-element" className={cn(s.cardLabel)}>
          <span>{t('billing.payment_credit_or_debit')}</span>
          <div className={s.cardInput}>
            <CardElement id="card-element" options={cardStyle} />
          </div>
        </label>
      </form>
    </Modal>
  );
}

export default WithStripeElements(ModalAddCard);
