import { useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Profile from '@/components/atoms/Icons/Profile';
import { DollarIcon } from '@/modules/TryIt/Icons/DollarIcon';

import { ContextVariablesPanel } from './Panels/ContextVariablesPanel';
import { OverviewPanel } from './Panels/OverviewPanel';

import styles from './RightSidebar.module.scss';

export const RightSidebar = () => {
  // Custom hooks
  const { t } = useTranslation();

  // Local state
  const [activeTab, setActiveTab] = useState(0);

  // Local variables
  const showOverviewPanel = activeTab === 0;
  const showContextVariablesPanel = activeTab === 1;

  return (
    <>
      <div className={styles.content}>
        {showOverviewPanel && <OverviewPanel />}
        {showContextVariablesPanel && <ContextVariablesPanel />}
      </div>

      <aside className={styles.sidebar}>
        <IconButton
          ariaLabel={t('human_chat.panels.show_panel', {
            panel: t('analytics.overview').toLowerCase(),
          })}
          onClick={() => setActiveTab(0)}
          className={cn({
            [styles.active]: showOverviewPanel,
          })}
          data-testid="overview-button"
        >
          <Profile />
        </IconButton>

        <IconButton
          ariaLabel={t('human_chat.panels.show_panel', {
            panel: t('common.context_variables'),
          })}
          onClick={() => setActiveTab(1)}
          className={cn({
            [styles.active]: showContextVariablesPanel,
          })}
          data-testid="context-variables-button"
        >
          <DollarIcon color="currentColor" />
        </IconButton>
      </aside>
    </>
  );
};
