import { SyntheticEvent, useCallback } from 'react';

import Typography from '@mui/material/Typography';

import Close from '@/components/atoms/Icons/Close';
import File from '@/components/atoms/Icons/File';
import { preventClickThrough } from '@/util/util';

import styles from './UploadCard.module.scss';

interface Props {
  fileName: string;
  fileType: string;
  handleClose?: (e?: SyntheticEvent) => void;
}

const UploadCard = ({ fileName, fileType, handleClose }: Props) => {
  const handleCloseButton = useCallback(
    (e) => {
      preventClickThrough(e);
      handleClose(e);
    },
    [handleClose]
  );

  return (
    <>
      <div className={styles.wrapper}>
        <File
          className={styles.file}
          size={36}
          color={'var(--icon-default-white)'}
        />

        <div className={styles.data}>
          <Typography
            variant="label-regular"
            color="var(--text-default-gray-light)"
            className={styles.name}
          >
            {fileName}
          </Typography>
          <Typography
            variant="label-regular"
            color="var(--text-default-gray-light)"
            className={styles.type}
          >
            {fileType}
          </Typography>
        </div>

        <Close
          color="var(--icon-default-white)"
          className={styles.closeIcon}
          onClick={handleCloseButton}
          onKeyDown={handleCloseButton}
        />
      </div>
    </>
  );
};

export default UploadCard;
