/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import { v4 as uuidv4 } from 'uuid';

import {
  Action,
  CarouselAction,
  ResetAction,
  SetVariablesAction,
  TextAction,
} from '@/models/action';
import { Condition, Node, Nodes, Requisite } from '@/models/node';
import { NO_REQUISITE_INDEX } from '@/redux/dialogs/helper';
import { removeEmptyFields } from '@/util/validator';

import { resetSelections } from '../../modules/TryIt/redux/actions';
import { isNodeOrActionSelected } from '../dialogs/selectors';

const actionById =
  (id) =>
  ({ action_id }) =>
    action_id === id;
const actionNotById =
  (id) =>
  ({ action_id }) =>
    action_id !== id;

const nodeById =
  (id) =>
  ({ node_id }) =>
    node_id === id;
const nodeNotById =
  (id) =>
  ({ node_id }) =>
    node_id !== id;
const nodeByParentId =
  (id: string) =>
  ({ parent_id }: Node) =>
    parent_id === id;

const findNode = (nodes: Node[], nodeId: string) =>
  nodes.find(nodeById(nodeId));

export const findAction = (actions: Action[], actionId: string) =>
  actions.find(actionById(actionId));

const findNodeChildren = (nodes: Node[], parentId: string) =>
  nodes.filter(nodeByParentId(parentId));

const findActionIndex = (actions: Action[], id: string) => {
  const index = actions.findIndex(actionById(id));
  return index === -1 ? actions.length : index;
};

const updateById = (idField: string, id: string, newValue) => (e) => {
  if (e[idField] === id) {
    return { ...e, ...newValue };
  }
  return e;
};

const findActionContainer = (
  state: Nodes,
  { nodeId, conditionIndex, requisiteIndex }
) => {
  let container: Node | Condition | Requisite = findNode(state.nodes, nodeId);

  if (!isNil(conditionIndex)) {
    container = container.conditions[conditionIndex];
  } else if (!isNil(requisiteIndex)) {
    container = container.requisites[requisiteIndex];
  }
  return container;
};

/**
 * Finds an action by id. This is very ineficient so maybe we could use a cache in the future
 * @param {Array} nodes the dialog nodes
 * @param {String} actionId  the action id
 */
export const findActionContainerById = (nodes: Node[], actionId: string) => {
  // Find action in nodes
  const n = nodes.find(({ actions }) => findAction(actions, actionId));
  if (n) {
    return n;
  }

  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];

    // Find action in conditions
    const condition = (node.conditions || []).find(({ actions }) =>
      findAction(actions, actionId)
    );
    if (condition) {
      return condition;
    }

    // Find action in requisites only if "actions" key exists
    const requisite = (node.requisites || []).find(({ actions }) =>
      findAction((actions || []) as Action[], actionId)
    );
    if (requisite) {
      return requisite;
    }
  }

  return null;
};

const findActionsNodeIdById = (nodes: Node[], actionId: string) => {
  // Find action in nodes
  const n = nodes.find(({ actions }) => findAction(actions, actionId));
  if (n) {
    return n.node_id;
  }

  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];

    // Find action in conditions
    const condition = (node.conditions || []).find(({ actions }) =>
      findAction(actions, actionId)
    );
    if (condition) {
      return node.node_id;
    }

    // Find action in requisites only if "actions" key exists
    const requisite = (node.requisites || []).find(({ actions }) =>
      findAction((actions || []) as Action[], actionId)
    );
    if (requisite) {
      return node.node_id;
    }
  }

  return null;
};

const findConditionIndexById = (nodes: Node[], conditionId: string) => {
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];

    const conditionIndex = (node.conditions || []).findIndex(
      ({ condition_id }) => condition_id === conditionId
    );
    if (conditionIndex !== -1) {
      return conditionIndex;
    }
  }

  return null;
};

/**
 * Finds a requisite by its ID within the given nodes and selected node ID.
 *
 * @param {Node[]} nodes - The array of nodes to search within.
 * @param {string} selectedNodeId - The ID of the selected node.
 * @param {string} requisiteId - The ID of the requisite to find.
 * @returns {Requisite|null} The found requisite object, or null if not found.
 */
const findRequisiteById = (
  nodes: Node[],
  selectedNodeId: string,
  requisiteId: string
): Requisite => {
  const node = findNode(nodes, selectedNodeId);
  return node.requisites.find(
    (requisite) => requisite.requisite_id === requisiteId
  );
};

// go through the conditions and rename the condition, rule and action ids
const renameConditionIds = (conditions: Condition[]) => {
  for (let i = 0; i < conditions.length; i += 1) {
    conditions[i].condition_id = uuidv4();

    for (let j = 0; j < conditions[i].rules.length; j += 1) {
      conditions[i].rules[j].rule_id = uuidv4();
    }

    for (let j = 0; j < conditions[i].actions.length; j += 1) {
      conditions[i].actions[j].action_id = uuidv4();
    }
  }
};

// go through the requisites and rename the requisite and action ids
const renameRequisiteIds = (requisites: Requisite[]) => {
  for (let i = 0; i < requisites.length; i += 1) {
    requisites[i].requisite_id = uuidv4();

    if (requisites[i].actions) {
      for (let j = 0; j < requisites[i].actions.length; j += 1) {
        requisites[i].actions[j].action_id = uuidv4();
      }
    }
  }
};

// TODO: check if the intents or event nodes exist in the brain
const renameIdFromImportedNodes = (nodes: Node[]) => {
  const nodeMap = {};
  for (let i = 0; i < nodes.length; i += 1) {
    if (nodes[i].node_id) {
      const randomId = uuidv4();
      nodeMap[nodes[i].node_id] = randomId;
      nodes[i].node_id = randomId;
    }

    if (nodes[i].parent_id) {
      nodes[i].parent_id = nodeMap[nodes[i].parent_id];
    }
    if (nodes[i].actions) {
      for (let j = 0; j < nodes[i].actions.length; j += 1) {
        nodes[i].actions[j].action_id = uuidv4();
      }
    }
    if ('conditions' in nodes[i]) {
      renameConditionIds(nodes[i].conditions);
    }

    if ('requisites' in nodes[i]) {
      renameRequisiteIds(nodes[i].requisites);
    }
  }
  return nodes;
};

// Sort the nodes based on the parent_id
export const sortNodesByParentID = (nodes: Node[]) => {
  const nodeDict = nodes.reduce(
    (acc, node) => {
      acc[node.node_id] = node;
      return acc;
    },
    {} as { [key: string]: Node }
  );

  const sortedList: Node[] = [];
  const visited = new Set<string>();

  function dfs(nodeId: string): void {
    if (visited.has(nodeId)) {
      return;
    }
    visited.add(nodeId);
    const node = nodeDict[nodeId];
    sortedList.push(node);
    nodes.forEach((child) => {
      if (child.parent_id === nodeId) {
        dfs(child.node_id);
      }
    });
  }

  // Find and process root nodes (nodes without parent_id)
  nodes.forEach((node) => {
    if (!node.parent_id) {
      dfs(node.node_id);
    }
  });

  return sortedList;
};

const resetSelection = (state: Nodes) => {
  state.selectedNodeId = null;
  state.selectedActionId = null;
  state.selectedRequisiteIndex = null;
  state.selectedOptionIndex = null;
  state.selectedConditionIndex = null;
  state.selectedCarouselIndex = null;
  state.selectedRequisiteId = null;
  state.selectedType = null;
  state.selectedFlowIds = [];
  return state;
};

export const defaultState: Nodes = Object.freeze({
  nodes: [],
  dirty: false,
  dialogId: null,
  dialogName: null,
  selectedNodeId: null,
  selectedActionId: null,
  selectedRequisiteIndex: null,
  selectedOptionIndex: null,
  selectedConditionIndex: null,
  selectedCarouselIndex: null,
  selectedRequisiteId: null,
  selectedType: null,
  selectedFlowIds: [],
});

const nodesSlice = createSlice({
  name: 'nodes',
  initialState: cloneDeep(defaultState),
  reducers: {
    addAction: (state, action) => {
      const { beforeActionId, newAction } = action.payload;
      const container = findActionContainer(state, action.payload);

      if (!container.actions) {
        container.actions = [];
      }
      const insertAt = findActionIndex(container.actions, beforeActionId);
      container.actions.splice(insertAt, 0, newAction);

      resetSelection(state);
      state.selectedType = newAction.type;
      state.selectedActionId = newAction.action_id;
      state.dirty = true;
    },

    pasteAction: (state, action) => {
      const { newAction } = action.payload;

      // handle when an action is selected
      if (state.selectedActionId) {
        const container = findActionContainerById(
          state.nodes,
          state.selectedActionId
        );
        container.actions = container.actions || [];
        const insertAt = findActionIndex(
          container.actions,
          state.selectedActionId
        );
        container.actions.splice(insertAt + 1, 0, newAction);
        state.dirty = true;
        return;
      }

      // handle when a node or requisite is selected
      if (state.selectedNodeId && isNil(state.selectedConditionIndex)) {
        const selectedNode = findNode(state.nodes, state.selectedNodeId);
        if (selectedNode) {
          selectedNode.actions = selectedNode.actions || [];
          selectedNode.actions.unshift(newAction);
          state.dirty = true;
          return;
        }
      }

      // handle when a condition is selected
      if (
        state.selectedType === 'condition' &&
        !isNil(state.selectedConditionIndex)
      ) {
        const node = findNode(state.nodes, state.selectedNodeId);
        const condition = node.conditions[state.selectedConditionIndex];
        condition.actions = condition.actions || [];
        condition.actions.unshift(newAction);
        state.dirty = true;
        return;
      }
    },

    removeAction: (state, action) => {
      const { actionId } = action.payload;
      const container = findActionContainerById(state.nodes, actionId);

      container.actions = container.actions.filter(actionNotById(actionId));

      resetSelection(state);
      state.dirty = true;
    },

    updateAction: (state, action) => {
      const { actionId } = action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      if (container) {
        container.actions = container.actions.map(
          updateById('action_id', actionId, action.payload.action)
        );
      }
      state.dirty = true;
    },

    selectAction: (state, action) => {
      const { actionId } = action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      if (!container) {
        console.debug('WARN: Selecting an action that does not exist');
        return;
      }
      const selectedAction = container.actions.find(actionById(actionId));

      if (selectedAction) {
        resetSelection(state);
        state.selectedType = selectedAction.type;
        state.selectedActionId = actionId;
      }
    },

    selectActionFromNode: (state, action) => {
      const { actionId, nodeId } = action.payload;
      const node = findNode(state.nodes, nodeId);
      const container = findActionContainerById(state.nodes, actionId);
      const selectedAction = container?.actions.find(actionById(actionId));

      if (!node) {
        console.debug('WARN: Selecting an action that does not exist');
        return;
      }

      if (selectedAction) {
        resetSelection(state);
        state.selectedType = selectedAction.type;
        state.selectedActionId = actionId;
        if (selectedAction.type === 'carousel') {
          state.selectedCarouselIndex = 0;
        }
        return;
      }

      // try to find the action in the conditions
      let selectedConditionAction: Action = null;
      const conditions = node.conditions || [];
      for (const condition of conditions) {
        const conditionAction = findAction(condition.actions, actionId);
        if (conditionAction) {
          selectedConditionAction = conditionAction;
          break;
        }
      }

      if (selectedConditionAction) {
        resetSelection(state);
        state.selectedType = selectedConditionAction.type;
        state.selectedActionId = actionId;
      }
    },

    addCondition: (state, action) => {
      const { nodeId, condition } = action.payload;

      const node = findNode(state.nodes, nodeId);

      if (!node.conditions) {
        node.conditions = [];
      }
      node.conditions.push(condition);

      resetSelection(state);
      state.selectedType = 'condition';
      state.selectedNodeId = nodeId;
      state.selectedConditionIndex = node.conditions.length - 1;
      state.selectedFlowIds = [
        ...(node.actions?.map(({ action_id }) => action_id) || []),
        ...(node.requisites?.map(({ requisite_id }) => requisite_id) || []),
        nodeId,
        condition.condition_id,
      ];
      state.dirty = true;
    },

    pasteCondition: (state, action) => {
      const { condition } = action.payload;

      // handle node or condition or requisite selected
      if (state.selectedNodeId) {
        const node = findNode(state.nodes, state.selectedNodeId);

        // if no condition index means that a node is selected
        if (isNil(state.selectedConditionIndex)) {
          node.conditions = node.conditions || [];
          node.conditions.push(condition);
          state.dirty = true;
          return;
        } else {
          node.conditions.splice(
            state.selectedConditionIndex + 1,
            0,
            condition
          );
          state.dirty = true;
          return;
        }
      } else {
        // handle selected action inside a condition
        // in this case selectedNodeId and selectedNodeIndex are null
        // so we have to find them manually

        const nodeId = findActionsNodeIdById(
          state.nodes,
          state.selectedActionId
        );

        const node = findNode(state.nodes, nodeId);

        const actionContainer = findActionContainerById(
          state.nodes,
          state.selectedActionId
        );

        const conditionIndex =
          findConditionIndexById(
            state.nodes,
            (actionContainer as Condition).condition_id
          ) || 0;

        node.conditions = node.conditions || [];
        node.conditions.splice(conditionIndex + 1, 0, condition);

        state.dirty = true;
        return;
      }
    },

    removeCondition: (state, action) => {
      const { nodeId, index } = action.payload;

      const node = findNode(state.nodes, nodeId);
      node.conditions.splice(index, 1);

      if (node.conditions.length === 0) {
        delete node.conditions;
      }

      resetSelection(state);
      state.dirty = true;
    },

    updateCondition: (state, action) => {
      const { nodeId, index, condition } = action.payload;

      const node = findNode(state.nodes, nodeId);
      node.conditions[index] = {
        ...node.conditions[index],
        ...condition,
      };

      state.dirty = true;
    },

    reorderCondition: (state, action) => {
      const { dragIndex, dropIndex, nodeId } = action.payload;
      const node = findNode(state.nodes, nodeId);
      const condition = node.conditions[dragIndex];
      node.conditions.splice(dragIndex, 1);
      node.conditions.splice(dropIndex, 0, condition);

      state.dirty = true;
    },

    selectCondition: (state, action) => {
      const { nodeId, index } = action.payload;
      const node = findNode(state.nodes, nodeId);
      resetSelection(state);

      if (!node) {
        console.debug('WARN: Selecting a node that does not exist');
        return;
      }

      state.selectedNodeId = nodeId;
      state.selectedConditionIndex = index;
      state.selectedType = 'condition';

      const sameConditionId = node.conditions[index].condition_id;

      state.selectedFlowIds = [
        ...(node.actions?.map(({ action_id }) => action_id) || []),
        ...(node.requisites?.map(({ requisite_id }) => requisite_id) || []),
        ...(node.conditions?.[index]?.actions.map(
          ({ action_id }) => action_id
        ) || []),
        sameConditionId,
        nodeId,
      ];
    },

    removeConditions: (state, action) => {
      const { nodeId } = action.payload;

      const node = findNode(state.nodes, nodeId);

      delete node.conditions;

      resetSelection(state);
      state.dirty = true;
    },

    addRule: (state, action) => {
      const { nodeId, conditionIndex, rule } = action.payload;

      const node = findNode(state.nodes, nodeId);
      const condition = node.conditions[conditionIndex];

      if (!condition.rules) {
        condition.rules = [];
      }

      condition.rules.push(rule);

      state.dirty = true;
    },

    removeRule: (state, action) => {
      const { nodeId, conditionIndex, index } = action.payload;

      const node = findNode(state.nodes, nodeId);
      const condition = node.conditions[conditionIndex];

      condition.rules.splice(index, 1);
      state.dirty = true;
    },

    removeAllRules: (state, action) => {
      const { nodeId, conditionIndex } = action.payload;

      const node = findNode(state.nodes, nodeId);
      const condition = node.conditions[conditionIndex];

      condition.rules = [];
      state.dirty = true;
    },

    updateRule: (state, action) => {
      const { nodeId, conditionIndex, index, rule } = action.payload;

      const node = findNode(state.nodes, nodeId);
      const condition = node.conditions[conditionIndex];

      condition.rules[index] = { ...condition.rules[index], ...rule };
      state.dirty = true;
    },

    addOption: (state, _action) => {
      const { actionId, option } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as TextAction;

      if (!action.options) {
        action.options = [];
      }

      action.options.push(option);

      resetSelection(state);
      state.selectedType = action.type;
      state.selectedActionId = actionId;
      state.selectedOptionIndex = action.options.length - 1;
      state.dirty = true;
    },

    removeOption: (state, _action) => {
      const { actionId, index } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as TextAction;

      action.options.splice(index, 1);

      if (action.options.length === 0) {
        delete action.options;
      }

      state.dirty = true;
    },

    updateOption: (state, _action) => {
      const { actionId, index, option } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as TextAction;

      action.options[index] = { ...action.options[index], ...option };
      state.dirty = true;
    },

    updateOptions: (state, _action) => {
      const { actionId, options } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as TextAction;

      action.options = options;
      state.dirty = true;
    },

    selectOption: (state, action) => {
      const { actionId, index } = action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const { type } = container.actions.find(actionById(actionId));

      resetSelection(state);
      state.selectedType = type;
      state.selectedActionId = actionId;
      state.selectedOptionIndex = index;
    },

    reorderOption: (state, _action) => {
      const { dragIndex, dropIndex, actionId } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as TextAction;
      const option = action.options[dragIndex];

      action.options.splice(dragIndex, 1);
      action.options.splice(dropIndex, 0, option);

      state.dirty = true;
    },

    addVariable: (state, _action) => {
      const { actionId, variable } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as ResetAction;

      if (!action.variables) {
        action.variables = [];
      }

      action.variables.push(variable);

      state.selectedType = action.type;
      state.selectedActionId = actionId;
      state.dirty = true;
    },

    updateVariable: (state, _action) => {
      const { actionId, index, variable } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as ResetAction;

      action.variables[index] = variable;
      state.dirty = true;
    },

    removeVariable: (state, _action) => {
      const { actionId, index } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as ResetAction;

      action.variables.splice(index, 1);
      if (action.variables.length === 0) {
        action.variables = [];
      }

      state.dirty = true;
    },

    setVariable: (state, _action) => {
      const { actionId, variables } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(
        container.actions,
        actionId
      ) as SetVariablesAction;

      action.variables = variables;

      state.dirty = true;
    },

    addRequisite: (state, _action) => {
      const { nodeId, requisite, actionId } = _action.payload;

      const node = findNode(state.nodes, nodeId);

      if (!node.requisites) {
        node.requisites = [];
      }
      node.requisites.push(requisite);

      resetSelection(state);
      if (actionId) {
        state.selectedActionId = actionId;
      }
      state.selectedType = node.type;
      state.selectedNodeId = nodeId;
      state.selectedRequisiteIndex = node.requisites.length - 1;
      state.dirty = true;
    },

    pasteRequisite: (state, _action) => {
      const { requisite } = _action.payload;

      // handle when a node is selected
      if (state.selectedNodeId && isNil(state.selectedRequisiteIndex)) {
        const node = findNode(state.nodes, state.selectedNodeId);
        node.requisites = node.requisites || [];
        node.requisites.push(requisite);
        state.dirty = true;
        return;
      }

      // handle when a requisite is selected
      if (state.selectedNodeId && !isNil(state.selectedRequisiteIndex)) {
        const node = findNode(state.nodes, state.selectedNodeId);
        node.requisites.splice(state.selectedRequisiteIndex + 1, 0, requisite);

        state.dirty = true;
        return;
      }

      // handle when an action is selected
      if (state.selectedActionId) {
        const nodeId = findActionsNodeIdById(
          state.nodes,
          state.selectedActionId
        );

        const node = findNode(state.nodes, nodeId);

        node.requisites = node.requisites || [];
        node.requisites.push(requisite);
        state.dirty = true;
        return;
      }
    },

    addNewRequisite: (state, _action) => {
      const { nodeId, requisite, requisiteIndex } = _action.payload;

      const node = findNode(state.nodes, nodeId);

      if (!node.requisites) {
        node.requisites = [];
      }
      node.requisites.splice(requisiteIndex, 0, requisite);

      resetSelection(state);

      state.selectedType = node.type;
      state.selectedNodeId = nodeId;
      state.selectedRequisiteIndex = requisiteIndex;
      state.selectedRequisiteId = requisite.requisite_id;
      state.dirty = true;
    },

    removeRequisite: (state, _action) => {
      const { nodeId, index } = _action.payload;
      const node = findNode(state.nodes, nodeId);

      node.requisites.splice(index, 1);

      if (node.requisites.length === 0) {
        delete node.requisites;
        delete node.if_no_requisites;
        resetSelection(state);
        state.dirty = true;
        return;
      }
      if (index <= state.selectedRequisiteIndex) {
        state.selectedRequisiteIndex = state.selectedRequisiteIndex - 1;
      }
      state.dirty = true;
    },

    updateRequisite: (state, _action) => {
      const { nodeId, index, requisite } = _action.payload;

      const node = findNode(state.nodes, nodeId);
      const newRequisite = removeEmptyFields({
        ...node.requisites[index],
        ...requisite,
      });

      node.requisites[index] = newRequisite;

      if (node.requisites[index].validate === '') {
        delete node.requisites[index].validate;
      }
      state.dirty = true;
    },

    addNewRequisiteOption: (state, _action) => {
      const { requisiteId, option } = _action.payload;

      const requisite = findRequisiteById(
        state.nodes,
        state.selectedNodeId,
        requisiteId
      );

      if (!requisite.actions) {
        requisite.actions = [{ options: [] } as TextAction];
      } else if (!requisite.actions[0].options) {
        requisite.actions[0].options = [];
      }

      requisite.actions[0].options.push(option);

      if (requisite.reprompt) {
        if (requisite.reprompt[0].options) {
          requisite.reprompt[0].options.push(option);
        } else {
          requisite.reprompt[0].options = [option];
        }
      }

      state.dirty = true;
    },

    updateRequisiteOption: (state, _action) => {
      const { requisiteId, index, option } = _action.payload;

      const requisite = findRequisiteById(
        state.nodes,
        state.selectedNodeId,
        requisiteId
      );

      const textAction = requisite.actions[0];
      textAction.options[index] = option;

      const reprompt = requisite?.reprompt?.[0];
      if (reprompt && reprompt.options) {
        reprompt.options[index] = option;
      }

      state.dirty = true;
    },

    removeRequisiteOption: (state, _action) => {
      const { requisiteId, index } = _action.payload;

      const requisite = findRequisiteById(
        state.nodes,
        state.selectedNodeId,
        requisiteId
      );

      const textAction = requisite.actions[0];
      textAction.options.splice(index, 1);

      if (textAction.options.length === 0) {
        delete textAction.options;
      }

      const reprompt = requisite?.reprompt?.[0];
      if (reprompt && reprompt.options) {
        reprompt.options.splice(index, 1);
        if (reprompt.options.length === 0) {
          delete reprompt.options;
        }
      }

      state.dirty = true;
    },

    reorderRequisiteOption: (state, _action) => {
      const { requisiteId, dragIndex, hoverIndex } = _action.payload;

      const requisite = findRequisiteById(
        state.nodes,
        state.selectedNodeId,
        requisiteId
      );

      const textAction = requisite.actions[0];
      const option = textAction.options[dragIndex];

      textAction.options.splice(dragIndex, 1);
      textAction.options.splice(hoverIndex, 0, option);

      // Update reprompt options
      if (requisite.reprompt) {
        const reprompt = requisite.reprompt[0];
        if (reprompt.options) {
          const repromptOption = reprompt.options[dragIndex];
          reprompt.options.splice(dragIndex, 1);
          reprompt.options.splice(hoverIndex, 0, repromptOption);
        }
      }

      state.dirty = true;
    },

    selectRequisite: (state, action) => {
      const { nodeId, index, requisiteId } = action.payload;
      const node = findNode(state.nodes, nodeId);

      if (!node) {
        console.debug(
          'WARN: Selecting a node (to get Requisites) that does not exist'
        );
        return;
      }
      resetSelection(state);
      state.selectedType = node.type;
      state.selectedNodeId = nodeId;

      state.selectedRequisiteId = requisiteId;
      state.selectedRequisiteIndex = index;
    },

    selectCarousel: (state, { payload }) => {
      const { actionId, index } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      if (!container) {
        console.debug('WARN: Selecting an action that does not exist');
        return;
      }
      const selectedAction = container.actions.find(actionById(actionId));
      if (selectedAction) {
        resetSelection(state);
        state.selectedType = selectedAction.type;
        state.selectedActionId = actionId;
        state.selectedCarouselIndex = index;
      }
    },

    updateSelectedCarouselIndex: (state, { payload }) => {
      const { index } = payload;
      state.selectedCarouselIndex = index;
    },

    updateCarouselCard: (state, { payload }) => {
      const { actionId, index, action } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      const currentAction = findAction(
        container.actions,
        actionId
      ) as CarouselAction;

      currentAction.cards[index] = {
        buttons: currentAction.cards[index].buttons,
        ...action,
      };
      state.dirty = true;
    },

    updateCarouselCards: (state, { payload }) => {
      const { actionId, cards } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      const currentAction = findAction(
        container.actions,
        actionId
      ) as CarouselAction;

      currentAction.cards = cards;
      state.dirty = true;
    },

    addCarouselCard: (state, { payload }) => {
      const { actionId, currentIndex, direction, newCard } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      const currentAction = findAction(
        container.actions,
        actionId
      ) as CarouselAction;

      if (direction === 'previous') {
        currentAction.cards.splice(currentIndex, 0, newCard);
      } else if (direction === 'next') {
        currentAction.cards.splice(currentIndex + 1, 0, newCard);
        state.selectedCarouselIndex = state.selectedCarouselIndex + 1;
      } else {
        currentAction.cards.push(newCard);
        state.selectedCarouselIndex = currentAction.cards.length - 1;
      }

      state.dirty = true;
    },

    moveCarouselCard: (state, { payload }) => {
      const { actionId, currentIndex, direction } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      const currentAction = findAction(
        container.actions,
        actionId
      ) as CarouselAction;

      if (direction === 'previous') {
        [
          currentAction.cards[currentIndex],
          currentAction.cards[currentIndex - 1],
        ] = [
          currentAction.cards[currentIndex - 1],
          currentAction.cards[currentIndex],
        ];
      } else {
        [
          currentAction.cards[currentIndex],
          currentAction.cards[currentIndex + 1],
        ] = [
          currentAction.cards[currentIndex + 1],
          currentAction.cards[currentIndex],
        ];
      }

      state.dirty = true;
    },

    deleteCarouselCard: (state, { payload }) => {
      const { actionId, currentIndex } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      const currentAction = findAction(
        container.actions,
        actionId
      ) as CarouselAction;

      currentAction.cards.splice(currentIndex, 1);
      state.selectedCarouselIndex =
        currentIndex === currentAction?.cards?.length && currentIndex !== 0
          ? currentIndex - 1
          : currentIndex;

      state.dirty = true;
    },

    addCardButton: (state, _action) => {
      const { actionId, cardIndex, button } = _action.payload;
      const container = findActionContainerById(state.nodes, actionId);
      const action = findAction(container.actions, actionId) as CarouselAction;

      if (!action.cards[cardIndex].buttons) {
        action.cards[cardIndex].buttons = [];
      }
      action.cards[cardIndex].buttons.push(button);

      state.selectedType = action.type;
      state.selectedActionId = actionId;
      state.dirty = true;
    },

    updateCardButton: (state, { payload }) => {
      const { actionId, cardIndex, buttonIndex, action } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      const currentAction = findAction(
        container.actions,
        actionId
      ) as CarouselAction;
      const hasDuplicatedLabels = Boolean(
        currentAction.cards[cardIndex].buttons.filter((button) => {
          if (button.id !== action.id) {
            if (button.label === action.label) {
              return true;
            }
          }
          return false;
        }).length
      );
      if (!hasDuplicatedLabels) {
        currentAction.cards[cardIndex].buttons.map(
          (button) => (button.hasDuplicatedLabels = false)
        );
      }
      action.hasDuplicatedLabels = hasDuplicatedLabels;
      currentAction.cards[cardIndex].buttons[buttonIndex] = action;
      state.dirty = true;
    },

    updateCardButtons: (state, { payload }) => {
      const { actionId, cardIndex, buttons } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      const currentAction = findAction(
        container.actions,
        actionId
      ) as CarouselAction;
      currentAction.cards[cardIndex].buttons = buttons;
      state.dirty = true;
    },

    removeCardButton: (state, { payload }) => {
      const { actionId, cardIndex, buttonIndex } = payload;
      const container = findActionContainerById(state.nodes, actionId);
      const currentAction = findAction(
        container.actions,
        actionId
      ) as CarouselAction;

      currentAction.cards[cardIndex].buttons.splice(buttonIndex, 1);

      if (currentAction.cards[cardIndex].buttons.length === 0) {
        delete currentAction.cards[cardIndex].buttons;
      }
      state.dirty = true;
    },

    moveAction: (state, _action) => {
      const { source, target } = _action.payload;

      // do nothing if the action is dropped at the same place
      if (source.actionId === target.beforeActionId) {
        return;
      }

      const sourceContainer = findActionContainer(state, source);

      const sourceAction = sourceContainer.actions.find(
        actionById(source.actionId)
      );

      const targetContainer = findActionContainer(state, target);

      sourceContainer.actions = sourceContainer.actions.filter(
        actionNotById(source.actionId)
      );

      const insertAt = findActionIndex(
        targetContainer.actions,
        target.beforeActionId
      );
      targetContainer.actions.splice(insertAt, 0, sourceAction);

      state.dirty = true;
    },
    moveRequisite: (state, _action) => {
      const { source, target } = _action.payload;
      let finalIndex = target.requisiteIndex;

      const targetNode = findNode(state.nodes, target.nodeId);
      const sourceNode = findNode(state.nodes, source.nodeId);

      // Remove the object from its current position
      const movedRequisite = sourceNode?.requisites?.splice(
        source.requisiteIndex,
        1
      )[0];

      // Update the requisiteIndex of the moved object
      // This adjustment is necessary to ensure that the moved item is
      // placed at the correct position in the target node's requisites array.
      if (
        target.nodeId === source.nodeId &&
        target.requisiteIndex > source.requisiteIndex
      ) {
        finalIndex = target.requisiteIndex - 1;
      }

      // Insert the object at the new position
      targetNode?.requisites?.splice(finalIndex, 0, movedRequisite);

      resetSelection(state);

      state.selectedType = targetNode.type;
      state.selectedNodeId = target.nodeId;
      state.selectedRequisiteIndex = target.finalIndex;
      state.selectedRequisiteId = movedRequisite.requisite_id;

      state.dirty = true;
    },
    addNode: (state, action) => {
      const { node } = action.payload;
      // check if is a new root in a non empty dialog
      if (!node.parent_id && state.nodes.length > 0) {
        state.nodes[0].parent_id = node.node_id;
        state.nodes.unshift(node);
      } else {
        state.nodes.push(node);
      }
      resetSelection(state);
      state.selectedType = node.type;
      state.selectedNodeId = node.node_id;
      state.selectedFlowIds.push(node.node_id);
      state.dirty = true;
    },

    pasteNode: (state, action) => {
      const { node } = action.payload;

      // newNode because node is not extensible
      const newNode = cloneDeep(node);

      // If there's no node in the state make newNode a root
      if (state.nodes.length === 0) {
        state.nodes.push(newNode);
      } else {
        // handle action selected

        if (state.selectedActionId) {
          const nodeId = findActionsNodeIdById(
            state.nodes,
            state.selectedActionId
          );

          const node = findNode(state.nodes, nodeId);

          // check if node is a root node
          if (!node.parent_id) {
            newNode.parent_id = node.node_id;
            state.nodes.push(newNode);
          } else {
            // if it's not root, paste it after the selected node
            // set the parent_id of newNode to be the parent_id of the selectedNode
            newNode.parent_id = node.parent_id;

            // Find the position of the selected node in the array and insert the new node after it
            const selectedIndex = state.nodes.findIndex(
              (n) => n.node_id === nodeId
            );
            state.nodes.splice(selectedIndex + 1, 0, newNode);
          }

          state.dirty = true;
          return;
        }

        // Find the selected node
        const selectedNode = findNode(state.nodes, state.selectedNodeId);

        // check if selected node is a root node
        if (!selectedNode.parent_id) {
          newNode.parent_id = selectedNode.node_id;
          state.nodes.push(newNode);
        } else {
          // set the parent_id of newNode to be the parent_id of the selectedNode
          newNode.parent_id = selectedNode.parent_id;

          // Find the position of the selected node in the array and insert the new node after it
          const selectedIndex = state.nodes.findIndex(
            (n) => n.node_id === state.selectedNodeId
          );
          state.nodes.splice(selectedIndex + 1, 0, newNode);
        }
      }

      state.dirty = true;
    },

    removeNode: (state, action) => {
      const { nodeId } = action.payload;

      const node = findNode(state.nodes, nodeId);
      const children = findNodeChildren(state.nodes, nodeId);

      if (node.parent_id || children.length === 1) {
        // point children to the grandparent
        children.forEach((child) => {
          child.parent_id = node.parent_id;
        });
        state.nodes = state.nodes.filter(nodeNotById(nodeId));
      } else {
        // root node, delete all nodes
        state.nodes = [];
      }

      resetSelection(state);
      state.dirty = true;
    },

    updateNode: (state, action) => {
      const { nodeId, node } = action.payload;
      state.nodes = state.nodes.map(updateById('node_id', nodeId, node));
      state.dirty = true;
    },

    convertNode: (state, action) => {
      const { nodeId, node } = action.payload;
      let updatedNode;
      if (node.type === 'event') {
        updatedNode = { ...node, type: 'intent' };
      } else {
        updatedNode = { ...updatedNode, intent: undefined, type: 'event' };
      }
      state.nodes = state.nodes.map(updateById('node_id', nodeId, updatedNode));
      state.dirty = true;
      state.selectedNodeId = nodeId;
    },

    moveNode: (state, action) => {
      const { nodeId, parentId } = action.payload;
      const movingNode = findNode(state.nodes, nodeId);
      const targetNode = findNode(state.nodes, parentId);

      // if movingNode from parent of targetNode becomes the child of targetNode
      if (targetNode.parent_id === movingNode.node_id) {
        targetNode.parent_id = movingNode.parent_id;
      }
      // update the parent of the movingNode
      movingNode.parent_id = parentId;

      resetSelection(state);

      state.dirty = true;
    },

    selectNode: (state, action) => {
      const { nodeId } = action.payload;
      const node = findNode(state.nodes, nodeId);
      if (!node) {
        console.debug('WARN: Selecting a node that does not exist');
        return;
      }

      resetSelection(state);
      state.selectedType = node.type;
      state.selectedNodeId = nodeId;
      state.selectedFlowIds = [
        nodeId,
        ...(node.actions?.map((action) => action.action_id) || []),
        ...(node.requisites?.map((r) => r.requisite_id) || []),
      ];
    },

    clearDialogNodes: () => defaultState,

    clearSelection: (state) => {
      resetSelection(state);
    },

    importDialog: (state, action) => {
      const sortedNodes = sortNodesByParentID(action.payload.nodes);

      state.nodes = renameIdFromImportedNodes(sortedNodes);
      state.dirty = true;
      resetSelection(state);
    },

    updateDialogData: (state, action) => {
      state.dialogName = action.payload.new_name;
      resetSelection(state);
      state.dirty = true;
    },

    setDialog: (state, action) => {
      const { nodes, dialog_id, name } = action.payload;

      state.nodes = nodes;
      state.dirty = false;
      state.dialogId = dialog_id;
      state.dialogName = name;
      resetSelection(state);
    },

    saveDialog: (state) => {
      state.dirty = false;
      resetSelection(state);
    },

    setDirty: (state, action) => {
      state.dirty = action.payload;
    },
  },
});

export const setDialogAsync = () => (dispatch, getState) => {
  const {
    selectedActionId,
    selectedNodeId,
    selectedRequisiteIndex,
    selectedIfNoRequisite,
  } = getState().tryIt;
  if (selectedNodeId && selectedRequisiteIndex === null) {
    dispatch(nodesSlice.actions.selectNode({ nodeId: selectedNodeId }));
    if (isNodeOrActionSelected(getState())) {
      dispatch(resetSelections());
    }
  }
  if (selectedActionId) {
    dispatch(
      nodesSlice.actions.selectActionFromNode({
        nodeId: selectedNodeId,
        actionId: selectedActionId,
      })
    );
    if (isNodeOrActionSelected(getState())) {
      dispatch(resetSelections());
    }
  }
  if (selectedNodeId && selectedIfNoRequisite) {
    dispatch(
      nodesSlice.actions.selectRequisite({
        nodeId: selectedNodeId,
        index: NO_REQUISITE_INDEX,
      })
    );
    if (isNodeOrActionSelected(getState())) {
      dispatch(resetSelections());
    }
  }
  if (selectedNodeId && selectedRequisiteIndex !== null) {
    dispatch(
      nodesSlice.actions.selectRequisite({
        nodeId: selectedNodeId,
        index: selectedRequisiteIndex,
      })
    );
    if (isNodeOrActionSelected(getState())) {
      dispatch(resetSelections());
    }
  }
};

// Extract and export each action creator by name
export const {
  addAction,
  pasteAction,
  removeAction,
  updateAction,
  moveAction,
  selectAction,
  setDirty,

  addNode,
  pasteNode,
  removeNode,
  updateNode,
  convertNode,
  moveNode,
  selectNode,

  addOption,
  updateOption,
  updateOptions,
  removeOption,
  selectOption,
  reorderOption,

  addVariable,
  updateVariable,
  removeVariable,
  setVariable,

  addCondition,
  pasteCondition,
  updateCondition,
  removeCondition,
  reorderCondition,
  selectCondition,

  removeConditions,

  addRule,
  updateRule,
  removeRule,
  removeAllRules,

  addRequisite,
  pasteRequisite,
  addNewRequisite,
  updateRequisite,
  addNewRequisiteOption,
  updateRequisiteOption,
  removeRequisiteOption,
  removeRequisite,
  selectRequisite,
  moveRequisite,
  reorderRequisiteOption,

  selectCarousel,
  updateSelectedCarouselIndex,
  updateCarouselCard,
  updateCarouselCards,
  addCarouselCard,
  deleteCarouselCard,
  moveCarouselCard,
  addCardButton,
  updateCardButton,
  updateCardButtons,
  removeCardButton,

  clearDialogNodes,
  updateDialogData,
  importDialog,
  setDialog,
  saveDialog,
  clearSelection,
} = nodesSlice.actions;

// Export the reducer, either as a default or named export
export default nodesSlice.reducer;
