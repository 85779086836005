import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { isRequired } from '@/util/validator';

type Form = {
  invoiceRecipientEmail: string;
};

const InvoiceRecipientFieldset = () => {
  const { t } = useTranslation();
  const { customer, updateCustomer, updateCustomerStatus } = useBilling();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<Form>({ mode: 'onChange' });

  const isLoading = customer === undefined;

  useEffect(() => {
    if (customer?.email) {
      reset({ invoiceRecipientEmail: customer.email });
    }
  }, [customer, reset]);

  const onSubmit = useCallback(
    async ({ invoiceRecipientEmail }: Form) =>
      updateCustomer({ email: invoiceRecipientEmail }),
    [updateCustomer]
  );

  return (
    <FormFieldset
      title={t('billing.invoice_recipient_title')}
      subtitle={t('billing.invoice_recipient_subtitle')}
      primaryButton={{
        children: t('billing.invoice_recipient_change_email'),
        disabled: !isDirty,
        isLoading: updateCustomerStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <Input
        name="invoiceRecipientEmail"
        label={t('field.email')}
        register={register('invoiceRecipientEmail', isRequired)}
        errorMessage={errors.invoiceRecipientEmail?.message}
      />
    </FormFieldset>
  );
};

export default InvoiceRecipientFieldset;
