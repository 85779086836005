import { useCallback, useEffect, useRef, useState } from 'react';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {
  FieldError,
  FieldValues,
  UseFormTrigger,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Node } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';

import AutoCompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew';
import Label from '@/components/atoms/Label/Label';
import { useEmojiPicker } from '@/hooks/useEmojiPicker';
import { isModalOpen } from '@/redux/modals/selectors';
import { isLeftClickEvent } from '@/util/util';

import { BoldButton } from './Buttons/BoldButton';
import { EmojiButton } from './Buttons/EmojiButton';
import { ItalicButton } from './Buttons/ItalicButton';
import { LinkButton } from './Buttons/LinkButton';
import { OrderedListButton } from './Buttons/OrderedListButton';
import { UnorderedListButton } from './Buttons/UnorderedListButton';
import { VariablesButton as Button } from './Buttons/VariablesButton';
import { Element } from './Components/Element';
import Error from './Components/Error';
import { Text } from './Components/Text';
import { Toolbar } from './Components/Toolbar';
import { getCharCount, onKeyDownHandler, extractHandleRanges } from './utils';
import { EmojiPicker } from '../EmojiPicker/EmojiPicker';

import styles from './RichTextEditor.module.scss';

interface RichTextEditorProps<T extends FieldValues> {
  placeholder?: string;
  maxCharacters?: number;
  left?: boolean;
  right?: boolean;
  size?: 'small' | 'medium' | 'large';
  onBlur?: () => void;
  label?: string;
  tooltip?: string;
  index?: number;
  value: Node[];
  onChange?: (value: Node[]) => void;
  required?: boolean;
  error?: FieldError;
  editor;
  options;
  handleClosePopper?: () => void;
  trigger?: UseFormTrigger<T>;
}

const RichTextEditor: React.FC<FieldValues> = ({
  placeholder = 'type something...',
  maxCharacters = 64,
  left = false,
  right = false,
  size = 'large',
  onBlur,
  label,
  tooltip,
  value: controlledValue,
  onChange: onControlledChange,
  required = false,
  error,
  editor,
  options,
  trigger,
  ...props
}: RichTextEditorProps<FieldValues>) => {
  const { t } = useTranslation();
  const { handleClosePopper, ...restProps } = props;
  const [value, setValue] = useState(controlledValue);
  const [charCount, setCharCount] = useState(0);
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const editorRef = useRef(null);
  const modalOpen = useSelector(isModalOpen);
  const { showEmojiPicker, handleEmojiButtonClick, handleEmojiClick } =
    useEmojiPicker(editor);
  const getEditorWidth = () => {
    if (editorRef?.current) {
      return editorRef.current.offsetWidth;
    }
    return null;
  };
  const { control, setValue: setVariablesValue } = useForm({
    defaultValues: {
      handle: { value: '', label: '', type: '' },
    },
  });
  useEffect(() => {
    setCharCount(getCharCount(controlledValue));
  }, [controlledValue]);

  const handleAutocompleteClose = useCallback(() => {
    setVariablesValue('handle', { value: '', label: '', type: '' });
    setShowAutoComplete(false);
  }, [setVariablesValue]);

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const handleAutoCompleteChange = useCallback(
    (event, option) => {
      if (event && option) {
        editor.insertText(`{{${option.value}}}`);
        setShowAutoComplete(false);
        ReactEditor.focus(editor);
      }
    },
    [editor]
  );
  const handleSlateChange = useCallback(
    (newValue) => {
      setValue(newValue);

      if (onControlledChange) {
        onControlledChange(newValue);
      }
    },
    [onControlledChange]
  );

  useEffect(() => {
    if (trigger) {
      trigger();
    }
  }, [trigger]);

  return (
    <>
      {label && (
        <Label
          label={label}
          htmlFor="rich-text-editor"
          tooltip={tooltip}
          required={required}
        />
      )}
      <div className={styles.wrapper}>
        <Slate
          editor={editor}
          initialValue={value}
          onChange={(newValue) => {
            const isEmpty = newValue.map((n) => Node.string(n)).join('') === '';

            if (isEmpty) {
              handleClosePopper();
            }
            handleSlateChange(newValue);
          }}
        >
          <div
            className={cn(styles.editable_wrapper, styles[size], {
              [styles.noLeftRadius]: left,
              [styles.noRightRadius]: right,
              [styles.error]: !isEmpty(error),
            })}
            ref={editorRef}
          >
            <Editable
              className={styles.editable}
              renderElement={(props) => <Element {...props} />}
              renderLeaf={(props) => <Text {...props} />}
              decorate={extractHandleRanges}
              placeholder={placeholder}
              onKeyDown={(event) => {
                onKeyDownHandler(event, editor);
              }}
              onBlur={() => {
                if (!modalOpen) {
                  handleBlur();
                }
              }}
              {...restProps}
            />
          </div>
          <Toolbar
            className={cn(styles.toolbar, {
              [styles.minPadding]: showAutoComplete,
              [styles.error]: !isEmpty(error),
            })}
          >
            {showAutoComplete && (
              <span className={styles.autocompleteWrapper}>
                <AutoCompleteNew
                  size="xs"
                  control={control}
                  name="handle"
                  groupByProp="type"
                  placeholder={t(
                    'dialog.set_variables.variable_name.placeholder'
                  )}
                  options={options}
                  onChange={handleAutoCompleteChange}
                  onClose={handleAutocompleteClose}
                  openOnFocus
                  inputAutoFocus
                  freeSolo
                  enableNewEntry={false}
                />
              </span>
            )}
            {!showAutoComplete && (
              <>
                <Button
                  onClick={(e) => {
                    if (isLeftClickEvent(e)) {
                      setShowAutoComplete(true);
                    }
                  }}
                >
                  {t('common.variables')}
                </Button>
                <span className={styles.buttonGroup}>
                  <BoldButton />
                  <ItalicButton />
                  <LinkButton handleBlur={handleBlur} />
                </span>
                <Divider orientation="vertical" flexItem />
                <EmojiButton onClick={handleEmojiButtonClick} />
                <Divider orientation="vertical" flexItem />
                <span className={styles.buttonGroup}>
                  <OrderedListButton />
                  <UnorderedListButton />
                </span>
              </>
            )}
          </Toolbar>
        </Slate>
        <div className={styles.infoHelper}>
          <Error message={error?.message} />
          <Typography
            variant="label-regular"
            color="var(--text-default-gray)"
            className={styles.counter}
          >
            {charCount}/{maxCharacters}
          </Typography>
        </div>
        {showEmojiPicker && (
          <EmojiPicker
            onClickAway={handleEmojiButtonClick}
            onEmojiClick={handleEmojiClick}
            width={getEditorWidth()}
            height={300}
          />
        )}
      </div>
    </>
  );
};
export default RichTextEditor;
