import { useEffect, useMemo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Routes,
  Route,
  Outlet,
  useParams,
  useLocation,
} from 'react-router';
import { useTitle } from 'react-use';

import Dialog from '@/components/pages/Dialog/Dialog';
import Dialogs from '@/components/pages/Dialogs/Dialogs';
import Entities from '@/components/pages/Entities/Entities';
import Entity from '@/components/pages/Entity/Entity';
import Intent from '@/components/pages/Intent/Intent';
import Intents from '@/components/pages/Intents/Intents';
import Logs from '@/components/pages/Logs/Logs';
import NotFound from '@/components/pages/NotFound/NotFound';
import { Versions } from '@/components/pages/Versions/Versions';
import Webhooks from '@/components/pages/Webhooks/Webhooks';
import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { useRtmBrain } from '@/hooks/rtm/useRtmBrain';
import { resetFilters } from '@/modules/analytics/redux/actions';
import { resetTryIt } from '@/modules/TryIt/redux/actions';
import { resetBrain, setBrainId } from '@/redux/session/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

import { Header } from './components/Header/Header';
import { Knowledge } from './components/Knowledge/Knowledge';
import { NavigationSidebar } from './components/NavigationSidebar/NavigationSidebar';
import { Overview } from './components/Overview/Overview';
import { Setup } from './components/Setup/Setup';

import styles from './AIAgents.module.scss';

const AIAgentRouter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { brainId } = useParams();
  const location = useLocation();
  const isFullHeight = useMemo(() => {
    return (
      location.pathname.includes('/conversation') ||
      location.pathname.includes('/review')
    );
  }, [location.pathname]);

  const slug = useSelector(selectAccountSlug);
  const title = useMemo(() => t('pages.ai_agent', { 0: slug }), [t, slug]);
  useTitle(title);
  useRtmBrain(brainId);

  useEffect(() => {
    if (brainId) {
      dispatch(setBrainId({ brainId }));
      dispatch(resetTryIt());
    }

    return () => {
      dispatch(resetBrain());
      dispatch(resetFilters());
    };
  }, [dispatch, brainId]);

  return (
    <>
      <PermissionsWrapper module="brains" resource_id={brainId}>
        <Header />

        <div className={styles.body}>
          <NavigationSidebar />

          <main
            className={cn(styles.main, {
              [styles.main__fullHeight]: isFullHeight,
            })}
          >
            <Routes>
              <Route path="/" element={<Navigate to="overview" replace />} />
              <Route
                path="/conversation/intents/:intentName"
                element={<Intent />}
              />
              <Route path="/conversation/intents" element={<Intents />} />
              <Route
                path="/conversation/dialogs/:dialogId"
                element={<Dialog />}
              />
              <Route path="/conversation/dialogs" element={<Dialogs />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/conversation/webhooks" element={<Webhooks />} />
              <Route
                path="/conversation/entities/:entityName"
                element={<Entity />}
              />
              <Route path="/conversation/entities" element={<Entities />} />
              <Route path="/setup" element={<Setup />} />

              <Route path="/knowledge" element={<Knowledge />} />

              <Route path="/review/logs" element={<Logs />} />
              <Route path="/review/versions" element={<Versions />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Outlet />
          </main>
        </div>
      </PermissionsWrapper>
    </>
  );
};
AIAgentRouter.displayName = 'AIAgentRouter';

export default AIAgentRouter;
