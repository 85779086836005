import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';

import IconButton from '@/components/atoms/IconButton/IconButton';
import MoreHorizontal from '@/components/atoms/Icons/MoreHorizontal';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { preventClickThrough } from '@/util/util';

import styles from './TileMenu.module.scss';

export interface Props {
  direction?: 'vertical' | 'horizontal';
  menuItems: {
    name: string;
    type?: string;
    icon: React.ReactNode;
    onClick: (e) => void;
    keepOpen?: boolean;
    tooltipMessage?: string;
    disabled?: boolean;
  }[];
}

const TileMenu = ({ menuItems, direction = 'vertical' }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    preventClickThrough(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    preventClickThrough(event);
    setAnchorEl(null);
  };

  return (
    <div className={styles.menu}>
      <IconButton
        ariaLabel="more"
        ariaControls="tile-menu-item"
        ariaHasPopUp
        onClick={handleClick}
      >
        {direction === 'vertical' ? (
          <MoreVertIcon />
        ) : (
          <MoreHorizontal size={20} />
        )}
      </IconButton>
      <CustomMenu
        id="tile-menu-item"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        disableRestoreFocus
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{ disablePadding: false }}
      >
        {menuItems.map((option) => (
          <Tooltip
            arrow
            title={option.tooltipMessage ? option.tooltipMessage : ''}
            key={option.name}
          >
            <MenuItem
              className={cn(styles.option, styles[option?.type])}
              key={option.name}
              disabled={option?.disabled}
              onClick={(e) => {
                if (!option.keepOpen) {
                  handleClose(e);
                }
                try {
                  option.onClick(e);
                  preventClickThrough(e);
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              {option.icon}
              <span>{option.name}</span>
            </MenuItem>
          </Tooltip>
        ))}
      </CustomMenu>
    </div>
  );
};

export default TileMenu;
