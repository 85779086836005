import { memo } from 'react';

import Skeleton from '@mui/material/Skeleton';

import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';

interface Props {
  truthness: boolean;
  text: string;
  isLoading?: boolean;
  tooltipText: string;
}

export const ContainedCovered = memo(
  ({ truthness, text, isLoading, tooltipText }: Props) => {
    return (
      <>
        {isLoading ? (
          <Skeleton variant="text" width={80} height={24} />
        ) : (
          <StatusBadge
            label={text}
            variant={truthness ? 'success' : 'attention'}
            withIcon
            tooltip={tooltipText}
          />
        )}
      </>
    );
  }
);

ContainedCovered.displayName = 'ContainedCovered';
