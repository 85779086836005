import { memo, useCallback, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Autocomplete from '@/components/atoms/AutoComplete/AutoComplete';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import { useAccount } from '@/hooks/useAccount';
import { useInvitations, API as invitationsApi } from '@/hooks/useInvitations';
import useMembers from '@/hooks/useMembers';
import { useRoles } from '@/hooks/useRoles';
import { useTeams } from '@/hooks/useTeams';
import { OptionBase } from '@/models/common';
import { RoleType } from '@/models/member';
import { popModal } from '@/redux/modals/actions';
import { invitationRules, errorMessage } from '@/util/validator';

import Modal from '../Modal';
import FooterWarning from '../ModalRoleEdit/FooterWarning';

type FormType = {
  emails: string[];
  roles: OptionBase[];
  teams: OptionBase[];
};

const ModalMemberInvite = () => {
  const { t } = useTranslation();
  const { accessTeams: teams } = useTeams();

  const { accountInvitations, createInvitation } = useInvitations();
  const { members } = useMembers();
  const [selected, setSelected] = useState('0');

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: {
      emails: [],
      roles: [],
      teams: [],
    },
  });

  const rolesField = watch('roles');
  const teamsField = watch('teams');

  const memberAllRoles = rolesField.map((t) => t.value);
  const teamAllRoles =
    teamsField?.reduce((acc, t) => {
      const { role_ids } = teams.find((team) => t.value === team.team_id);
      return [...acc, ...role_ids];
    }, []) ?? [];

  const allRoles = [...new Set([...memberAllRoles, ...teamAllRoles])];

  const formatInvitation = useCallback(
    (email: string, selectedRoles, teams) => {
      const invitation = {
        role: RoleType.RBAC,
        email: email.toLowerCase(),
        role_ids: selectedRoles?.map(({ value }) => value),
        team_ids: teams?.map(({ value }) => value),
      };
      return invitation;
    },
    []
  );

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const getValidations = useCallback(
    () => ({
      validate: {
        invitationExists: (value: string[]) => {
          const existingIndex = value.findIndex((email) => {
            const index = accountInvitations.findIndex(
              (inv) => inv.email === email
            );
            return index !== -1;
          });
          return (
            existingIndex === -1 ||
            t('invitations.already_invited', { 0: value[existingIndex] })
          );
        },
        memberExists: (value: string[]) => {
          const existingIndex = value.findIndex((email) => {
            const index = members.findIndex((inv) => inv.email === email);
            return index !== -1;
          });

          return (
            existingIndex === -1 ||
            t('invitations.already_member', { 0: value[existingIndex] })
          );
        },
        ...invitationRules(t).emails.validate,
      },
    }),
    [accountInvitations, members, t]
  );

  const { roles, availableRoles } = useRoles();
  const { account } = useAccount();

  const onSubmit = useCallback(
    async (form: {
      emails: string[];
      roles?: OptionBase[];
      teams?: OptionBase[];
    }) => {
      setIsLoading(true);

      const emails = form.emails;

      if (emails.length === 1) {
        const invitation = formatInvitation(emails[0], form.roles, form.teams);
        createInvitation(invitation, {
          onSuccess: () => {
            dispatch(popModal());
          },
        });
      } else {
        await Promise.all(
          emails.map(async (email: string) => {
            const invitation = formatInvitation(email, form.roles, form.teams);
            return invitationsApi.createInvitation(invitation);
          })
        );
        dispatch(popModal());
      }
    },
    [createInvitation, dispatch, formatInvitation]
  );

  const handleChangeSelected = (val: string) => {
    setSelected(val);
    setValue('teams', []);
    setValue('roles', []);
  };

  const teamOptions = teams?.map((team) => ({
    label: team.name,
    value: team.team_id,
    description: team.description,
  }));

  return (
    <Modal
      size="medium"
      title={
        <>
          {t('invitations.invite_members')}
          <span className="formattedSpan">{account.name}</span>
        </>
      }
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('invitations.modal.invite')}
      isSubmitting={isLoading}
      preventClose
    >
      <form autoComplete="off">
        <div className="input__container">
          <Autocomplete
            control={control}
            name="emails"
            options={[]}
            label={t('onboarding.members_emails')}
            placeholder={t('onboarding.members_emails_placeholder')}
            multiple
            disableAddNew
            labelLarge
            size="small"
            freeSolo
            error={!!errors?.emails?.message}
            errorMessage={errorMessage({
              field: errors.emails,
            })}
            rules={getValidations()}
          />
        </div>
        <p> {t('permissions.choose_access_label')}</p>
        <div className="radioWrapper">
          <OptionRadioButton
            option={{ value: '0', label: t('roles.individual'), id: 0 }}
            isSelected={selected === '0'}
            setSelected={() => handleChangeSelected('0')}
          />
          <OptionRadioButton
            option={{ value: '1', label: t('common.teams'), id: 1 }}
            isSelected={selected === '1'}
            setSelected={() => handleChangeSelected('1')}
          />
        </div>

        {selected === '0' && (
          <div className="input__container">
            <Autocomplete
              control={control}
              name="roles"
              label={t('onboarding.members_roles')}
              disabled={!roles}
              options={availableRoles}
              placeholder={t('onboarding.roles_placeholder')}
              multiple
              labelLarge
              disableAddNew
              size="small"
              variant="secondary"
              error={!!errors?.roles?.message}
              errorMessage={errorMessage({
                field: errors.roles,
              })}
              rules={invitationRules(t).roles}
            />
          </div>
        )}
        {selected === '1' && (
          <div className="input__container">
            <Autocomplete
              control={control}
              name="teams"
              variant="secondary"
              options={teamOptions}
              label={t('permissions.members_to_team')}
              placeholder={t('teams.select_team')}
              disableAddNew
              multiple
              labelLarge
              size="large"
              error={!!errors?.teams?.message}
              errorMessage={errorMessage({
                field: errors.teams,
              })}
              rules={invitationRules(t).roles}
            />
          </div>
        )}
      </form>
      <FooterWarning allRoles={allRoles} roles={roles} />
    </Modal>
  );
};

export default memo(ModalMemberInvite);
