import React from 'react';

import Box from '@mui/material/Box/Box';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';

interface Props {
  onClick: () => void;
  detectMessage?: string;
  switchMessage?: string;
}

const DetectComponent = ({ onClick, detectMessage, switchMessage }: Props) => {
  return (
    <Fade in>
      <span>
        <Banner relativePosition variant="warning" dismissable>
          {detectMessage}
          <Box textAlign="right" mt={2}>
            <Button variant="tertiary" size="small" onClick={onClick}>
              {switchMessage}
            </Button>
          </Box>
        </Banner>
      </span>
    </Fade>
  );
};

export const IntercomDetect = (props: Props) => {
  const { t } = useTranslation();
  return (
    <DetectComponent
      {...props}
      detectMessage={props.detectMessage || t('collections.intercom_detect')}
      switchMessage={props.switchMessage || t('collection.switch_intercom')}
    />
  );
};

export const ZendeskDetect = (props: Props) => {
  const { t } = useTranslation();
  return (
    <DetectComponent
      {...props}
      detectMessage={props.detectMessage || t('collections.zendesk_detect')}
      switchMessage={props.switchMessage || t('collection.switch_zendesk')}
    />
  );
};
