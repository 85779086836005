import { Icon } from '@/models/icon';

const Deploy = ({ size = 32, color = 'var(--icon-default-white)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        d="m12.79 15.472 3.777 3.986m-3.778-3.986-4.815-.726a.25.25 0 0 1-.144-.42l3.234-3.412a2 2 0 0 1 1.452-.625H17.7m-4.912 5.183 4.912-5.183m-1.134 9.17.686 4.7a.25.25 0 0 0 .42.145l3.17-3.04a2 2 0 0 0 .616-1.49l-.124-5.346m-4.768 5.03 4.768-5.03m0 0 1.043-1.102c.91-.959 1.751-1.995 2.275-3.209.526-1.22.941-2.714.139-3.249-.79-.526-2.275-.047-3.457.494-1.197.547-2.203 1.417-3.108 2.372l-.526.555m-5.878 8.107c-2.12.793-3.349 2.384-3.192 5.396a.05.05 0 0 0 .053.048c2.677-.166 4.106-.659 5.06-3.522"
      />
    </svg>
  );
};

export default Deploy;
