import { Icon } from '@/models/icon';

import styles from './styles.module.scss';

const Chat = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  disabled = false,
}: Icon) => {
  const disabledClass = disabled ? styles.icon_disabled : '';

  return (
    <svg
      viewBox="0 0 24 24"
      className={`${styles.icon} ${disabledClass}`}
      width={size}
      height={size}
      stroke={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chat;
