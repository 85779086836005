import { Icon } from '@/models/icon';

const Attachment = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1148 4.66671L5.72408 9.05738C5.59674 9.18038 5.49516 9.3275 5.42528 9.49017C5.3554 9.65285 5.31862 9.82781 5.31708 10.0048C5.31555 10.1819 5.34928 10.3575 5.41632 10.5213C5.48336 10.6852 5.58237 10.834 5.70756 10.9592C5.83275 11.0844 5.98162 11.1834 6.14548 11.2505C6.30934 11.3175 6.48491 11.3513 6.66195 11.3497C6.83899 11.3482 7.01395 11.3114 7.17662 11.2415C7.3393 11.1716 7.48642 11.0701 7.60942 10.9427L11.8854 6.55205C12.3712 6.04911 12.64 5.3755 12.6339 4.67631C12.6278 3.97712 12.3474 3.30828 11.8529 2.81386C11.3585 2.31944 10.6897 2.03899 9.99049 2.03291C9.2913 2.02684 8.61769 2.29562 8.11475 2.78138L3.83808 7.17138C3.08788 7.92159 2.66641 8.93909 2.66641 10C2.66641 11.061 3.08788 12.0785 3.83808 12.8287C4.58829 13.5789 5.6058 14.0004 6.66675 14.0004C7.72771 14.0004 8.74521 13.5789 9.49542 12.8287L13.6667 8.66671"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Attachment;
