import { useSelector } from 'react-redux';

import useDialogs from '@/hooks/useDialogs';
import {
  getActionsSelector,
  selectDrafDialogActions,
  selectDraftDialogId,
} from '@/redux/dialogs/selectors';
import { selectBrainId } from '@/redux/session/selectors';

const useNodesConnected = () => {
  const brainId = useSelector(selectBrainId);
  const { dialogs } = useDialogs(brainId);
  const allActions = getActionsSelector(dialogs);
  const drafDialogActions = useSelector(selectDrafDialogActions);
  const draftDialogId = useSelector(selectDraftDialogId);

  // Filter out actions that are not in the draft dialog
  const finalActions = allActions?.filter((action) => {
    if (action.dialog_id === draftDialogId) {
      return drafDialogActions.find(
        (draftAction) => draftAction.action_id === action.action_id
      );
    }
    return true;
  });

  const findUsedNodes = (nodeId: string) => {
    const allNodes = finalActions.reduce(
      (acc, action) => {
        if (action.trigger_node_id === nodeId) {
          const isDuplicate = acc.find(
            (item) => item.label === action.dialog_name
          );
          if (!isDuplicate) {
            return [
              ...acc,
              { dialogId: action.dialog_id, label: action.dialog_name },
            ];
          }
        }
        return acc;
      },
      [] as { dialogId: string; label: string }[]
    );
    return allNodes;
  };
  return {
    findUsedNodes,
  };
};

export default useNodesConnected;
