import { useTranslation } from 'react-i18next';

import PlainFooterFieldset from '@/components/atoms/Fieldset/templates/PlainFooterFieldset';

interface Props {
  onAuthClick: () => void;
  disabled?: boolean;
}

const FrontOauthFieldset = ({ onAuthClick, disabled }: Props) => {
  const { t } = useTranslation();
  return (
    <PlainFooterFieldset
      title={t('integrations.front.redirect_title')}
      subtitle={t('integrations.front.redirect_subtitle')}
      primaryButton={{
        children: t('integrations.intercom.redirect_connect'),
        onClick: onAuthClick,
        disabled,
      }}
      fullContentWidth
    />
  );
};

export default FrontOauthFieldset;
