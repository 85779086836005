import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import useDesks from '@/hooks/useDesks';
import { Desk } from '@/models/desk';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { deskRules } from '@/util/validator';

import styles from './styles.module.scss';

type Form = {
  keep_alive: string;
};
interface Props {
  desk?: Desk;
}

const KeepAliveFieldset = ({ desk }: Props) => {
  const { t } = useTranslation();
  const desk_id = desk?.desk_id;
  const { updateDesk, updateStatus } = useDesks(desk_id);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<Form>({ mode: 'onChange' });
  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      updateDesk({
        keep_alive: parseInt(form?.keep_alive, 10),
        desk_id: desk?.desk_id,
      });
    },
    [desk?.desk_id, updateDesk]
  );

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'desks', actions.WRITE)
  );

  useEffect(() => {
    if (desk?.desk_id) {
      reset({
        keep_alive: desk?.keep_alive?.toString(),
      });
    }
  }, [desk?.keep_alive, desk?.desk_id, reset]);

  return (
    <div className={styles.brain_settings_fields}>
      <FormFieldset
        title={t('environments.keep_alive')}
        subtitle={t('environments.keep_alive_subtitle')}
        primaryButton={{
          children: t('common.save'),
          isLoading: updateStatus === 'pending',
          disabled: !isDirty,
        }}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={!desk}
      >
        <Input
          name="keep_alive"
          label={t('environments.seconds')}
          register={register('keep_alive', deskRules(t).keepAlive as unknown)}
          errorMessage={errors.keep_alive?.message}
          placeholder=""
          size="small"
          type="number"
          readOnly={!canWrite}
        />
      </FormFieldset>
    </div>
  );
};

export default KeepAliveFieldset;
