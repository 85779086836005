import { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import uniq from 'lodash/uniq';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Chips from '@/components/atoms/Chips/Chips';
import useDialogs from '@/hooks/useDialogs';
import { OptionBase } from '@/models/common';
import { updateContext } from '@/modules/TryIt/redux/actions';
import {
  selectContext,
  selectIsTryItReplay,
} from '@/modules/TryIt/redux/selectors';
import { selectAllTags } from '@/redux/dialogs/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { MAX_CONVERSATION_TAGS } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';
import { newTagSchema } from '@/util/validator';

import { ContextSectionWrapper } from '../ContextSectionWrapper/ContextSectionWrapper';

type SuggestedTag = OptionBase<{ type?: string }>;

interface ActionTag {
  tag: SuggestedTag | string;
}

interface Props {
  tags: string[];
  canEdit: boolean;
}

const TagsSection = ({ tags = [], canEdit }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brainId = useSelector(selectBrainId);
  const { dialogs } = useDialogs(brainId);
  const allTags = selectAllTags(dialogs);
  const isReplay = useSelector(selectIsTryItReplay);

  const suggestedTags = allTags.filter((t) => !tags.includes(t));

  const storeContext = useSelector(selectContext);

  const {
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm<ActionTag>({
    mode: 'onChange',
    defaultValues: {
      tag: null,
    },
    resolver: yupResolver(newTagSchema) as Resolver<ActionTag>,
  });

  const autoCompleteTags =
    suggestedTags?.map((tab) => ({
      type: t('dialog.tags.auto_complete_group_by'),
      value: tab,
      label: tab,
    })) ?? [];

  const handleOnTagDelete = useCallback(
    (t: string) => {
      const newTags = tags.filter((tag: string) => tag !== t);
      const newContext = storeContext[storeContext.length - 1] ?? {};

      dispatch(
        updateContext([
          ...storeContext.slice(0, -1),
          {
            ...newContext,
            tags: newTags,
          },
        ])
      );
    },
    [dispatch, storeContext, tags]
  );

  const handleChange = useCallback(
    (_, option) => {
      if (option) {
        const newTags = uniq([...tags, option.label]);
        const newContext = storeContext[storeContext.length - 1] ?? {};

        dispatch(
          updateContext([
            ...storeContext.slice(0, -1),
            {
              ...newContext,
              tags: newTags,
            },
          ])
        );

        setValue('tag', {
          type: '',
          value: '',
          label: '',
        });
      }
    },
    [dispatch, setValue, storeContext, tags]
  );

  const resetTags = useCallback(() => {
    const newContext = storeContext[storeContext.length - 1] ?? {};

    dispatch(
      updateContext([
        ...storeContext.slice(0, -1),
        {
          ...newContext,
          tags: [],
        },
      ])
    );
  }, [dispatch, storeContext]);

  if ((!canEdit || isReplay) && tags.length === 0) return null;

  const hideResetIcon = !canEdit || isReplay || (tags.length === 0 && canEdit);

  return (
    <ContextSectionWrapper
      title={t('common.tags')}
      onClick={resetTags}
      hideIcon={hideResetIcon}
    >
      {canEdit && !isReplay && (
        <AutocompleteNew
          width={403}
          name="tag"
          control={control}
          tooltip={t('dialog.tags.add_new_tooltip')}
          options={autoCompleteTags}
          freeSolo
          enableNewEntry={newTagSchema.isValidSync(getValues())}
          groupByProp="type"
          groupByLabelProp={false}
          placeholder={t('rules.type_tag')}
          size="xs"
          hasError={!!errors.tag?.message}
          errorMessage={capitalizeFirstLetter(errors.tag?.message)}
          disabled={tags?.length >= MAX_CONVERSATION_TAGS}
          onChange={handleChange}
          onInputChange={(_, value) => {
            setValue('tag', value, {
              shouldValidate: true,
            });
          }}
          clearOnBlur
          getOptionLabel={(option) => option?.label ?? option}
        />
      )}

      <Chips
        chips={tags}
        onClick={(tag) => {
          handleOnTagDelete(tag);
        }}
        hideDeleteIcon={!canEdit || isReplay}
      />
    </ContextSectionWrapper>
  );
};

export default TagsSection;
