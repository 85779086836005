import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ZoomResetIcon from '@/components/atoms/Icons/ZoomReset';
import ToolkitControlsWrapper from '@/components/organisms/Dialogs/Node/ToolkitControls/ToolkitControlsWrapper/ToolkitControlsWrapper';

import ToolkitControlsZoom from './ToolkitControlsZoom';

import styles from './ToolkitControls.module.scss';

const ToolkitControls = ({
  onZoomInClick,
  onZoomOutClick,
  onZoomResetClick,
  zoomPercentage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={cn(
          styles.toolkit__controls,
          styles['toolkit__controls--zoom']
        )}
      >
        <ToolkitControlsZoom
          onZoomInClick={onZoomInClick}
          onZoomOutClick={onZoomOutClick}
          zoomPercentage={zoomPercentage}
        />
      </div>

      <div
        className={cn(
          styles.toolkit__controls,
          styles['toolkit__controls--reset']
        )}
      >
        <ToolkitControlsWrapper
          items={[
            {
              label: t('common.zoom_in'),
              icon: <ZoomResetIcon />,
              onClick: onZoomResetClick,
              shouldFocus: true,
            },
          ]}
        />
      </div>

      {/* Uncomment when keyboard shortcuts are implemented */}
      {/* <div
        className={cn(
          styles.toolkit__controls,
          styles['toolkit__controls--keyboard']
        )}
      >
        <ToolkitControlsWrapper
          items={[
            {
              label: t('common.keyboard_shortcuts'),
              icon: <KeyboardIcon />,
              shouldFocus: true,
            },
          ]}
        />
      </div> */}
    </>
  );
};

export default ToolkitControls;
