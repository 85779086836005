import { useState, useCallback, useEffect } from 'react';

import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Switch from '@/components/atoms/Switch/Switch';
import useUser from '@/hooks/useUser';

const ChatNotificationFieldset = () => {
  const { t } = useTranslation();
  const { user, updateUser, updateStatus } = useUser();
  const [chatSound, setChatSound] = useState<boolean>(true);
  const defaultValue =
    user && !isNil(user?.metadata?.chatSound)
      ? !!user?.metadata?.chatSound
      : true;

  useEffect(() => {
    if (user && !isNil(user?.metadata?.chatSound)) {
      setChatSound(!!user?.metadata?.chatSound);
    } else {
      setChatSound(true);
    }
  }, [user, user?.metadata]);

  const onSubmit = useCallback(() => {
    updateUser({
      ...user,
      metadata: {
        ...user?.metadata,
        chatSound,
      },
    });
  }, [chatSound, updateUser, user]);
  return (
    <FormFieldset
      title={t('notifications.messages')}
      subtitle={t('notifications.messages_subtitle')}
      primaryButton={{
        children: t('common.save'),
        isLoading: updateStatus === 'pending',
        disabled: defaultValue === chatSound,
      }}
      onSubmit={onSubmit}
      isLoading={!user}
    >
      <Switch
        name="greeklish"
        label={t('common.enabled')}
        checked={chatSound}
        disabled={!user}
        size="medium"
        onChange={() => setChatSound((c) => !c)}
      />
    </FormFieldset>
  );
};

export default ChatNotificationFieldset;
