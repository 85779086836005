import { RootState } from '@/models/state';

export const selectBundle = (state: RootState) => state.bundle;

export const selectBundleContextKeys = (state: RootState) =>
  state.bundle.contextKeys;

export const selectBundleKeysWithNested = (state: RootState) =>
  state.bundle.keysWithNested;

export const selectBundleContext = (state: RootState) => state.bundle.context;

export const selectIsBundleDirty = (state: RootState) => state.bundle.dirty;
