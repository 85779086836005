import { memo, useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { Presence } from '@/models/presence';

import Collection, { AsItems } from './Collection';
import { SubnavType } from '../SubNav';
import { Collections } from '../SubNavIntent';

import styles from './TreeView.module.scss';

interface CollectionProps {
  collection: string;
  new_collection: string;
}

interface TreeviewProps {
  skeleton?: boolean;
  onCollectionUpdate: ({
    id,
    collection,
  }: {
    id: string;
    collection: string;
  }) => void;
  onCollectionSubmit: (data: CollectionProps) => void;
  collections: Collections;
  lastCreatedCollection: string;
  whoIsViewing?: Presence[];
  navTitle?: string;
  subnavType?: SubnavType;
}

const TreeView = ({
  skeleton = true,
  onCollectionUpdate,
  onCollectionSubmit,
  collections,
  lastCreatedCollection,
  whoIsViewing = [],
  navTitle,
  subnavType,
}: TreeviewProps) => {
  const { t } = useTranslation();
  const recommendedCollection = t('intent.recommendations.collection');
  const hasRecommendedCollection = Object.keys(collections).includes(
    recommendedCollection
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropActionInNode = (item: any) => {
    if (item.collection !== '') {
      onCollectionUpdate({ id: item.id, collection: '' });
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: 'treeItem',
    drop: dropActionInNode,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const sortedCollections = useMemo(
    () =>
      Object.keys(collections)?.sort((a, b) => {
        if (a === recommendedCollection) {
          return -1;
        }
        if (b === recommendedCollection) {
          return 1;
        }
        if (a === '_orphans') {
          return -1;
        }
        if (b === '_orphans') {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      }),
    [collections, recommendedCollection]
  );

  const renderSkeletons = () => {
    return (
      <ul>
        {Array(7)
          .fill('')
          .map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={160}
                height={14}
                sx={{ m: 2 }}
              />
            </li>
          ))}
      </ul>
    );
  };
  return (
    <>
      {hasRecommendedCollection && (
        <div className={styles.recommendations}>
          <Collection
            key={recommendedCollection}
            nodeId={recommendedCollection}
            label={recommendedCollection}
            items={collections[recommendedCollection] as AsItems[]}
            onCollectionUpdate={onCollectionUpdate}
            onCollectionSubmit={onCollectionSubmit}
            whoIsViewing={whoIsViewing}
            subnavType={subnavType}
          />
        </div>
      )}
      <span ref={drop} className={styles.title}>
        <Typography variant="label-caps-large">
          {navTitle || t('common.items')}
        </Typography>
        {isOver && <span className={styles.dropLine} />}
      </span>
      <div className={styles.collection}>
        {skeleton && renderSkeletons()}
        {sortedCollections?.map((c: string) => {
          const statusColor = collections[c].reduce((acc, item) => {
            if (item?.needsAction && subnavType === 'intents') {
              return 'var(--icon-default-warning)';
            }
            if (item.hasSuggestions) {
              return 'var(--icon-default-blue)';
            }
            return acc;
          }, null);

          if (c !== recommendedCollection) {
            return (
              <Collection
                key={c}
                nodeId={c}
                label={c || t('common.default')}
                items={collections[c] as AsItems[]}
                onCollectionUpdate={onCollectionUpdate}
                onCollectionSubmit={onCollectionSubmit}
                statusColor={statusColor as string}
                isLastCreated={c === lastCreatedCollection}
                whoIsViewing={whoIsViewing}
                subnavType={subnavType}
                collections={collections}
              />
            );
          }
        })}
      </div>
    </>
  );
};

export default memo(TreeView);
