import { useCallback } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { clearDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectDialogAlerts } from '@/redux/dialogAlerts/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { removeAction } from '@/redux/nodes/actions';

const useDeleteAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialogErrors = useSelector(selectDialogAlerts);

  const deleteAction = useCallback(
    (type, actionId) => {
      const actionType = type === 'event' ? 'jump to' : type;
      const deleteProps = {
        subtitle: (
          <Trans i18nKey="dialog.delete_action" values={[actionType]} />
        ),
        confirm: false,
        onDelete: () => {
          dispatch(removeAction({ actionId }));

          if (dialogErrors.length) {
            dispatch(
              clearDialogAlerts({
                id: actionId,
              })
            );
          }
          dispatch(popModal());
        },
        secondaryButtonText: t('common.cancel'),
      };
      dispatch(pushModal(MODAL_DELETE, deleteProps));
    },
    [dialogErrors.length, dispatch, t]
  );

  return { deleteAction };
};

export default useDeleteAction;
