import Chain from './Chain';
import Line from './Line';

import styles from './LinkConnection.module.scss';

const LinkConnection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.line}>
        <Line />
      </div>
      <Chain />
    </div>
  );
};

export default LinkConnection;
