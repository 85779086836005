import { PropsWithChildren } from 'react';

import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectAccountSlug } from '@/redux/session/selectors';

import styles from './TileAIAgent.module.scss';

type TileAIAgentLayoutProps = {
  id: string;
  isOnboarding: boolean;
  icon?: React.ReactNode;
};

export function TileAIAgentLayout({
  children,
  id,
  isOnboarding,
  icon,
}: PropsWithChildren<TileAIAgentLayoutProps>) {
  const slug = useSelector(selectAccountSlug);
  const isTeamOnboardingAccount = slug === 'team-onboarding';

  return (
    <Link
      className={cn(styles.link, {
        [styles.disabled]: isOnboarding && !isTeamOnboardingAccount,
      })}
      to={`/${slug}/ai-agents/${id}`}
    >
      <article className={styles.layout} id={id}>
        {icon && <>{icon}</>}

        <div className={styles.content}>{children}</div>
      </article>
    </Link>
  );
}
