import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import styles from '@/components/atoms/Accordion/templates/ConnectionStatusAccordion.module.scss';
import PlainFooterFieldset from '@/components/atoms/Fieldset/templates/PlainFooterFieldset';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';

interface Props {
  onOAuthClick: () => void;
  isConnecting: boolean;
}

export const ReAuthFieldset = ({ onOAuthClick, isConnecting }: Props) => {
  const { t } = useTranslation();

  return (
    <PlainFooterFieldset
      title={
        <div className={styles.accordion_title}>
          <Typography variant="subtitle1">
            {t('integrations.con_status.title')}{' '}
          </Typography>
          <StatusBadge
            label={t('integrations.con_status.verified')}
            variant="success"
            withIcon
          />
          <StatusBadge
            label={t('integrations.con_status.action_required')}
            variant="warning"
            withIcon
          />
        </div>
      }
      helpText={t('integrations.whatsapp.reauthorise.help_text')}
      subtitle={t('integrations.whatsapp.reauthorise.subtitle')}
      primaryButton={{
        children: t('integrations.reauthorize'),
        onClick: onOAuthClick,
        isLoading: isConnecting,
      }}
      fullContentWidth
    />
  );
};

export default ReAuthFieldset;
