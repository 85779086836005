import { Icon } from '@/models/icon';

const Access = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className="icon_fill"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.05 16.821h5.25V21.3h-5.25v-4.479Zm5.45-.7h-1.016v-1.026c0-.717-.405-1.372-1.045-1.694l-.028-.014-.31.615.31-.615a1.716 1.716 0 0 0-1.61.037 1.829 1.829 0 0 0-.934 1.594v1.103h-1.016a.5.5 0 0 0-.5.5V21.5a.5.5 0 0 0 .5.5H20.5a.5.5 0 0 0 .5-.5v-4.879a.5.5 0 0 0-.5-.5Zm-1.716-.7v.7h-2.217v-1.103c0-.408.22-.784.576-.984.294-.165.652-.173.953-.022l.029.015c.404.203.659.616.659 1.068v.326Zm-1.61 4.815h1.003a.05.05 0 0 0 .05-.052l-.05-1.108c0-.01.004-.02.01-.026l.016-.015a.82.82 0 0 0 .026-1.15.761.761 0 0 0-1.108 0 .808.808 0 0 0 .034 1.142l.03.03a.014.014 0 0 1 .005.01l-.066 1.116a.05.05 0 0 0 .05.053ZM7.924 11.906a.104.104 0 0 0 .031-.175A6.121 6.121 0 0 1 11.981 1a6.121 6.121 0 1 1 0 12.242c-.657 0-1.158-.046-1.516-.1-.474-.07-.954-.169-1.423-.072a7.182 7.182 0 0 0-5.465 5.738L3.16 21.26a.579.579 0 0 1-.673.47.587.587 0 0 1-.479-.674l.417-2.451a8.735 8.735 0 0 1 5.5-6.698Zm8.878-4.786a4.834 4.834 0 0 1-4.821 4.847c-.747 0-1.585-.236-2.22-.544A4.842 4.842 0 0 1 7.16 7.12a4.834 4.834 0 0 1 4.82-4.846 4.834 4.834 0 0 1 4.822 4.846Z"
      />
    </svg>
  );
};

export default Access;
