import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import { Action } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { capitalizeFirstLetter } from '@/util/util';
import { urlValidationSchema } from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitActionUrl.module.scss';

type UrlActionForm = {
  url: string;
};

function ToolkitActionUrl() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { type, actionId, url } = useSelector((state: RootState) => {
    const selectedAction = selectSelectedAction(state) as Extract<
      Action,
      { type: 'url' }
    >;
    return {
      type: selectedAction.type,
      actionId: selectedAction.action_id,
      url: selectedAction.url,
    };
  }, shallowEqual);
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  const {
    formState: { errors },
    getValues,
    trigger,
    register,
    setFocus,
  } = useForm<UrlActionForm>({
    mode: 'onChange',
    defaultValues: {
      url,
    },
    resolver: yupResolver(urlValidationSchema) as Resolver<UrlActionForm>,
  });
  useFocusOnInput('url', errors, setFocus);

  const handleChange = useCallback(() => {
    const value = getValues('url');

    dispatch(
      updateAction({
        actionId,
        action: {
          url: value,
        },
      })
    );
  }, [getValues, dispatch, actionId]);

  const updateFn = useCallback(() => {
    const urlErrorMessage = errors.url?.message;

    dispatch(
      updateDialogAlerts({
        dialogAlerts: {
          alertType: 'error',
          id: actionId,
          nodeId: parentNodeId,
          title: t('actions.types.url'),
          body: capitalizeFirstLetter(urlErrorMessage),
          type,
        },
      })
    );
  }, [actionId, dispatch, errors.url?.message, parentNodeId, t, type]);

  useEffect(() => {
    updateFn();
  }, [updateFn]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <ToolkitWrapper type="url">
      <div className={styles.url}>
        <TextAreaAsInput
          name="url"
          label={t('field.url')}
          error={!!errors?.url}
          errorMessage={capitalizeFirstLetter(errors?.url?.message)}
          onChange={handleChange}
          key={actionId}
          height="medium"
          register={register('url')}
          placeholder={t('dialog.url_placeholder')}
          enableScroll
        />
      </div>
    </ToolkitWrapper>
  );
}

export default ToolkitActionUrl;
