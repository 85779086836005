import { memo, useCallback, useMemo } from 'react';

import cn from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { ErrorIcon } from '@/components/atoms/Icons/Error';
import Warning from '@/components/atoms/Icons/Warning';
import Placeholder from '@/components/organisms/Dialogs/Action/Placeholder/Placeholder';
import Box from '@/components/organisms/Dialogs/Box/Box';
import DialogTooltip from '@/components/organisms/Dialogs/DialogTooltip/DialogTooltip';
import { useAccount } from '@/hooks/useAccount';
import useDeleteAction from '@/hooks/useDeleteAction';
import useDialogAlerts from '@/hooks/useDialogAlerts';
import useDialogs from '@/hooks/useDialogs';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { selectTryItNode } from '@/modules/TryIt/redux/actions';
import { TYPES } from '@/redux/dialogs/helper';
import { selectFirstActionIdByNodeId } from '@/redux/dialogs/selectors';
import { addAction, moveAction, selectAction } from '@/redux/nodes/actions';
import {
  selectIsActionSelected,
  selectIsNodeConditionRequisiteSelected,
  selectIsIdInFlow,
} from '@/redux/nodes/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import {
  resolveBrainsPath,
  isKeyEnter,
  preventClickThrough,
} from '@/util/util';

import ActionHeader from '../../ActionHeader/ActionHeader';
import NewActionContent from '../ActionContent';
import OptionList from '../OptionList/OptionList';

import styles from './Action.module.scss';

const smallActions = [
  'video',
  'file',
  'url',
  'webview',
  'image',
  'survey',
  'googlesheet',
  'email',
];

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any;
  actionId: string;
  nodeId: string;
  requisiteIndex?: number;
  conditionIndex?: number;
  beforeActionId?: string;
  isAfterRequisite?: boolean;
  show?: boolean;
  actionIndex?: number;
}

const Action = memo(
  ({
    action,
    actionId,
    nodeId,
    requisiteIndex = null,
    conditionIndex = null,
    beforeActionId = null,
    show = false,
  }: Props) => {
    const { type } = action;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const brainId = useSelector(selectBrainId);

    const { deleteAction } = useDeleteAction();
    const isSelected = useSelector(selectIsActionSelected(actionId));
    const isActionInFlow = useSelector(selectIsIdInFlow(actionId));

    const isAnythingSelected = useSelector(
      selectIsNodeConditionRequisiteSelected
    );

    const { hasDialogError, hasDialogWarning } = useDialogAlerts(actionId);
    // Hide content for some actions
    const showActionContent = !['replay', 'close', 'handover'].includes(type);
    const { ai_agents } = useFeatureFlag();

    const { dialogs } = useDialogs(brainId);
    const { slug } = useAccount();
    const firstActionId = useSelector(selectFirstActionIdByNodeId(nodeId));

    const targetNode = useMemo(() => {
      if (type === 'event') {
        for (let i = 0; i < dialogs?.length; i += 1) {
          const nodeIndex = dialogs[i].nodes.findIndex(
            ({ node_id }) => action?.trigger_node_id === node_id
          );
          if (nodeIndex !== -1) {
            return {
              dialog_id: dialogs[i].dialog_id,
              nodeId: dialogs[i].nodes[nodeIndex].node_id,
            };
          }
        }
      }
      return null;
    }, [action?.trigger_node_id, type, dialogs]);

    const [_, dragRef] = useDrag({
      item: {
        actionId,
        nodeId,
        requisiteIndex,
        conditionIndex,
        move: true,
        type,
      },
      type: TYPES.ACTION,
      canDrag: () =>
        !document?.activeElement?.className?.match(/DraftEditor|input/),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const onClick = useCallback(
      (e) => {
        preventClickThrough(e);
        dispatch(selectAction({ actionId }));
      },
      [dispatch, actionId]
    );

    const onKeyUp = useCallback(
      (e) => {
        preventClickThrough(e);

        if (isKeyEnter(e)) {
          dispatch(selectAction({ actionId }));
        } else if (
          e.key === 'Delete' &&
          !e.target.className?.match(/DraftEditor/)
        ) {
          deleteAction(type, actionId);
        }
      },
      [dispatch, actionId, deleteAction, type]
    );

    const handleEventNavigate = useCallback(() => {
      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${brainId}/dialogs/${targetNode?.dialog_id}`,
          ai_agents
        )
      );
      dispatch(selectTryItNode(targetNode?.nodeId));
    }, [
      ai_agents,
      brainId,
      dispatch,
      navigate,
      slug,
      targetNode?.dialog_id,
      targetNode?.nodeId,
    ]);

    const dropActionInNode = useCallback(
      (item) => {
        if (item.move) {
          dispatch(
            moveAction({
              source: item,
              target: {
                nodeId,
                beforeActionId,
                requisiteIndex,
                conditionIndex,
              },
            })
          );
          return;
        }

        const newAction = {
          ...item.props,
          type: item.subType,
          action_id: item.id,
        };

        dispatch(
          addAction({
            beforeActionId,
            requisiteIndex,
            conditionIndex,
            newAction,
          })
        );
      },
      [nodeId, dispatch, beforeActionId, requisiteIndex, conditionIndex]
    );

    const [{ canDrop }] = useDrop({
      accept: TYPES.ACTION,
      drop: dropActionInNode,
      canDrop: () => {
        return true;
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop() || show,
      }),
    });

    const handleDeleteAction = useCallback(() => {
      deleteAction(type, actionId);
    }, [deleteAction, type, actionId]);

    return (
      <>
        <Placeholder
          nodeId={nodeId}
          beforeActionId={actionId}
          requisiteIndex={requisiteIndex}
          conditionIndex={conditionIndex}
        />

        <DialogTooltip
          onNavigate={targetNode ? handleEventNavigate : null}
          type="event"
        >
          <section
            id={actionId}
            className={cn(styles.action, {
              [styles['action--placeholder']]: canDrop,
              [styles['action--firstAction']]: firstActionId === actionId,
            })}
            // Extend the drag area to the whole action
            ref={action?.options ? dragRef : null}
          >
            <div className={cn(styles.icon)}>
              {hasDialogError ? (
                <ErrorIcon />
              ) : hasDialogWarning ? (
                <Warning size={14} />
              ) : null}
            </div>
            <Box
              ref={!action?.options ? dragRef : null}
              className={cn({
                [styles.selected]: isSelected,
                [styles.selectedWarning]: isSelected && hasDialogWarning,
                [styles.selectedError]: isSelected && hasDialogError,
                [styles.opacity]: !isActionInFlow && !isAnythingSelected,
              })}
              role="button"
              tabIndex={0}
              onKeyUp={onKeyUp}
              onClick={onClick}
            >
              <ActionHeader
                type={type}
                objectToCopy={action}
                isSelected={isSelected}
                onDelete={handleDeleteAction}
                hasDialogError={hasDialogError}
              />
              {showActionContent && (
                <div
                  className={cn(styles.action__content, {
                    [styles['action__content--small']]:
                      smallActions.includes(type),
                    [styles['action__content--medium']]: type === 'text',
                    // When the text action has at least one quick option
                    [styles['action__content--textWithOption']]:
                      type === 'text' && action?.options?.length > 0,
                  })}
                >
                  <NewActionContent action={action} />
                </div>
              )}
            </Box>
            <OptionList
              actionId={actionId}
              options={action.options}
              opacity={!isActionInFlow && !isAnythingSelected}
            />
          </section>
        </DialogTooltip>
      </>
    );
  }
);

Action.displayName = 'Action';

export default Action;
