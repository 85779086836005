import { useCallback } from 'react';

import InputBase from '@mui/material/InputBase';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Create from '@/components/atoms/Icons/Create';
import Trash from '@/components/atoms/Icons/Trash';
import { useMacros } from '@/hooks/useMacros';
import { Macro } from '@/models/macros';
import { popModal } from '@/redux/modals/actions';
import { isKeyEnter } from '@/util/util';

import styles from './ModalCannedResponses.module.scss';

const muiStyles = {
  root: {
    borderRadius: 'var(--border-radius-base)',
    border: '1px solid var(--border-default-gray)',
    height: '36px',
    paddingLeft: '8px',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-16)',
    backgroundColor: 'var(--surface-primary-white)',
    width: '60%',
    '&.Mui-focused': {
      border: '1px solid var(--border-default-blue)',
    },
  },
};

interface Props {
  handleTyping: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filteredOptions: Macro[];
  setSelectedMacro: (index: number) => void;
  selectedMacro: number;
  handleEdit: () => void;
  setShowCreateNew: (show: boolean) => void;
  changeInputTo: (value: string) => void;
}

const Responses = ({
  handleTyping,
  setShowCreateNew,
  filteredOptions,
  setSelectedMacro,
  selectedMacro,
  handleEdit,
  changeInputTo,
}: Props) => {
  const { t } = useTranslation();

  const { macros, deleteMacro } = useMacros();
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    (index) => {
      deleteMacro(filteredOptions[index].macro_id, {
        onSuccess: () => {
          setSelectedMacro(0);
        },
      });
    },
    [deleteMacro, filteredOptions, setSelectedMacro]
  );

  const handleDoubleClick = useCallback(
    (index) => {
      changeInputTo(filteredOptions[index].text);
      dispatch(popModal());
    },
    [dispatch, filteredOptions, changeInputTo]
  );

  const handleEditKeyDown = useCallback(
    (e) => {
      if (isKeyEnter(e)) {
        handleEdit();
      }
    },
    [handleEdit]
  );

  const handleDeleteKeyDown = useCallback(
    (e, index) => {
      if (isKeyEnter(e)) {
        handleDelete(index);
      }
    },
    [handleDelete]
  );

  return (
    <>
      <div className={styles.header}>
        <InputBase
          onChange={handleTyping}
          sx={muiStyles.root}
          placeholder={t('chatBox.modal.search')}
          autoFocus
        />
        <Button variant="secondary" onClick={() => setShowCreateNew(true)}>
          <Create color="var(--icon-default-blue)" />
          <span>{t('chatBox.modal.create_new')}</span>
        </Button>
      </div>
      <div className={styles.responsesWrapper}>
        <div className={styles.responses}>
          <>
            <span className={styles.title}>{t('chatBox.quick_responses')}</span>
            <span className={styles.innerWrapper}>
              {filteredOptions?.map((option, index) => (
                <span
                  key={option.macro_id}
                  role="button"
                  onClick={() => setSelectedMacro(index)}
                  onDoubleClick={() => handleDoubleClick(index)}
                  onKeyDown={() => setSelectedMacro(index)}
                  tabIndex={0}
                  className={cn(styles.response, {
                    [styles.selected]: selectedMacro === index,
                  })}
                >
                  <span className={styles.name}>{option.name}</span>
                  <span
                    role="button"
                    onClick={() => handleDelete(index)}
                    onKeyDown={(e) => handleDeleteKeyDown(e, index)}
                    tabIndex={0}
                    className={cn(styles.delete, {
                      [styles.deleteSelected]: selectedMacro === index,
                    })}
                  >
                    <Trash />
                  </span>
                </span>
              ))}
            </span>
          </>
        </div>
        <div className={styles.textSection}>
          <div
            className={cn(styles.textInnerWrapper, {
              [styles.noMacros]: macros.length === 0,
            })}
          >
            {macros.length > 0 && (
              <span className={styles.text}>{macros[selectedMacro]?.text}</span>
            )}
            {macros.length === 0 && <span>{t('chatBox.no_response')}</span>}
          </div>
          <footer>
            {macros && macros[selectedMacro] && (
              <>
                <span>
                  {t('chatBox.modal.created')}{' '}
                  {moment(macros[selectedMacro]?.created).fromNow()}
                </span>
                <span
                  onClick={handleEdit}
                  onKeyDown={(e) => handleEditKeyDown(e)}
                  role="button"
                  tabIndex={0}
                >
                  {t('chatBox.modal.edit')}
                </span>
              </>
            )}
          </footer>
        </div>
      </div>
    </>
  );
};

export default Responses;
