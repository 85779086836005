import { TryItCollection } from '@/models/tryIt';

/**
 * Removes duplicate datasources based on unique combinations of document_id and datasource_id.
 *
 * @function
 * @param {Array.<Object>} datasources - An array of datasources to filter for uniqueness.
 * @param {string} datasources[].document_id - The ID of the document.
 * @param {string} datasources[].datasource_id - The ID of the datasource.
 * @returns {Array.<Object>} An array of unique datasources based on document_id and datasource_id combination.
 */
export const removeDuplicateDocuments = (
  fragments: TryItCollection['fragments']
): TryItCollection['fragments'] => {
  if (!fragments) return [];

  const seenPairs = new Set();
  const uniqueEntries = [];

  for (const entry of fragments) {
    const pair = `${entry.document_id}-${entry.datasource_id}`;
    if (!seenPairs.has(pair)) {
      seenPairs.add(pair);
      uniqueEntries.push(entry);
    }
  }
  return uniqueEntries;
};

/**
 * Groups an array of objects by a given key within each object.
 * If the key does not exist on an item, that item will not be included in the output.
 *
 * @template T - The type of the elements in the input array.
 *
 * @param {T[]} array - The array to group.
 * @param {string} key - The key of the property to group by.
 *
 * @returns {T[][]} - An array of arrays, where each subarray contains elements
 * that are grouped by the specified key.
 */
export const groupBy = <T>(array: T[], key: string): T[][] => {
  if (!array) return [];

  const grouped = array.reduce((acc, item) => {
    if (!acc[item[key]]) {
      acc[item[key]] = [];
    }
    acc[item[key]].push(item);
    return acc;
  }, {});

  return Object.values(grouped);
};

export const isMessageFromCollection = (collection: TryItCollection) =>
  !!collection?.fragments &&
  collection.fragments.length > 0 &&
  collection?.response_code === 0;

export const muiStyles = {
  accordionRoot: {
    boxSizing: 'border-box',
    backgroundColor: 'var(--surface-primary-white)',
    border: '1px solid transparent',
    boxShadow: 'none',
    transitionDuration: '200ms',
    scrollMarginTop: 'var(--space-16)',
    cursor: 'default',
    padding: 0,

    '&::before': {
      height: '0',
    },
  },

  detailsRoot: {
    padding: 0,
    marginTop: 'var(--space-8)',
  },
};
