import IconExpand from '@/components/atoms/Icons/Expand';
import IconShrink from '@/components/atoms/Icons/Shrink';

import styles from './DialogTooltip.module.scss';

export const CollapseButton = ({ isCollapsed, onCollapse }) => (
  <button type="button" onClick={onCollapse} className={styles.iconButton}>
    {isCollapsed ? (
      <IconExpand size={16} color="var(--icon-default-white)" />
    ) : (
      <IconShrink size={16} color="var(--icon-default-white)" />
    )}
  </button>
);
