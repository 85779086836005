import { useContext, useEffect } from 'react';

import { SocketContext } from '@/contexts/rtm';
import { SocketEvent } from '@/models/rtm';

export const useRtmBroadcast = (broadcastId: string) => {
  const { socket, isConnected } = useContext(SocketContext);

  // TODO Update table of recipients with status
  // const handleSubscriberUpdate = useCallback(
  //   ({ data }: { data: Subscriber }) => {
  //     const { broadcast_id, external_id, status } = data;
  //   },
  //   []
  // );

  useEffect(() => {
    socket?.on(SocketEvent.subscriber_update, () => {});
    return () => {
      socket?.off(SocketEvent.subscriber_update, () => {});
    };
  }, [socket]);

  useEffect(() => {
    if (!socket || !broadcastId || broadcastId === 'draft' || !isConnected) {
      return;
    }
    socket.emit(SocketEvent.subscribe_broadcast, { broadcast_id: broadcastId });
    return () => {
      socket.emit(SocketEvent.unsubscribe_broadcast, {
        broadcast_id: broadcastId,
      });
    };
  }, [isConnected, broadcastId, socket]);
};
