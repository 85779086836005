import { useCallback, useEffect, useRef, useState } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import mimetypes from 'mime-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { callGet } from '@/api/fetcher';
import AttachmentIcon from '@/components/atoms/Icons/Attachment';
import AttachmentAudio from '@/components/atoms/Icons/AttachmentAudio';
import AttachmentFile from '@/components/atoms/Icons/AttachmentFile';
import AttachmentVideo from '@/components/atoms/Icons/AttachmentVideo';
import { MODAL_MEDIA_PREVIEW } from '@/components/organisms/Modals/ModalConductor';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {
  Attachment as AttachmentType,
  ConversationSource,
} from '@/models/chat';
import { pushModal } from '@/redux/modals/actions';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import styles from './Attachment.module.scss';

interface Props {
  attachment: AttachmentType;
  source: ConversationSource;
  isSingleItem?: boolean;
  bg?: 'light' | 'dark';
}

export const createMediaUrl = (file, mimeType: string) => {
  if (file) {
    // Convert ArrayBuffer data to Uint8Array before passing to Blob
    const uint8Array = new Uint8Array(file.data ?? file);
    const blob = new Blob([uint8Array], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const cleanup = () => URL.revokeObjectURL(url);

    return { url, cleanup };
  }

  return { url: null, cleanup: () => {} };
};

const getRouterUrl = (
  source: ConversationSource,
  attachment: AttachmentType
): string | null => {
  if (source?.channel === 'whatsapp') {
    return `/www/api/v1/desks/${source.deskId}/integrations/${source.integrationId}/whatsapp/media/${attachment.id}`;
  }
  if (source?.channel === 'web') {
    return `/www/attachment-url/${source.deskId}/${source.sessionId}/${attachment.id}/${attachment.filename}/${attachment.title}`;
  }

  return null;
};
export const Attachment = ({
  attachment,
  source,
  isSingleItem = true,
  bg,
}: Props) => {
  const dispatch = useDispatch();
  const [mediaUrl, setMediaUrl] = useState<string>('');
  const { t } = useTranslation();
  const routerUrl = getRouterUrl(source, attachment);
  const [showMedia, setShowMedia] = useState(false);
  const attachmentRef = useRef<HTMLDivElement>(null);
  const [renderError, setRenderError] = useState(false);
  const {
    data: file,
    isLoading,
    error,
  } = useQuery<Buffer | string>({
    queryKey: [routerUrl],
    queryFn: async () => {
      if (source?.channel === 'whatsapp') {
        return callGet(routerUrl);
      }
      const res = await callGet(routerUrl, { responseType: 'arrayBuffer' });
      return await res.arrayBuffer();
    },

    enabled: !!routerUrl && showMedia,
    staleTime: 60 * 1000,
  });

  useEffect(() => {
    if (file) {
      const { url, cleanup } = createMediaUrl(file, attachment.mime_type);
      if (url) {
        setMediaUrl(url);
      }
      return cleanup;
    }
  }, [attachment?.mime_type, file]);

  const isImage = attachment.mime_type.includes('image');
  const isAudio = attachment.mime_type.includes('audio');
  const isVideo = attachment.mime_type.includes('video');
  const isFile = attachment.type === 'file';

  const extension = mimetypes.extension(attachment.mime_type);

  const handleImageClick = useCallback(() => {
    dispatch(
      pushModal(MODAL_MEDIA_PREVIEW, {
        title: attachment.filename,
        file,
        mime_type:
          attachment.type === 'file' ? attachment.type : attachment.mime_type,
      })
    );
  }, [
    attachment?.filename,
    attachment?.mime_type,
    attachment?.type,
    dispatch,
    file,
  ]);

  useIntersectionObserver({
    target: attachmentRef,
    onIntersect: () => setShowMedia(true),
  });

  const handleKeyDown = useCallback(
    (e) => {
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        handleImageClick();
      }
    },
    [handleImageClick]
  );

  const handleErrorImage = useCallback(() => {
    if (showMedia && mediaUrl) {
      setRenderError(true);
    }
  }, [mediaUrl, showMedia]);

  const handleFileClick = () => {
    const link = document.createElement('a');
    link.href = mediaUrl;
    link.download = attachment.title || attachment.filename;
    link.click();
  };

  if (isLoading) {
    const isSingleFile = attachment.type === 'file' && isSingleItem;
    return (
      <Skeleton
        variant="rectangular"
        width="150px"
        height={isSingleFile ? '20px' : '150px'}
      />
    );
  }
  if (error || renderError) {
    return (
      <div className={styles.container}>
        <img
          className={styles.brokenImage}
          src="/assets/broken_image.png"
          alt={t('common.fallback_image')}
        />
      </div>
    );
  }

  if (isSingleItem) {
    return (
      <div
        ref={attachmentRef}
        className={cn(styles.singleContainer, {
          [styles.hasError]: renderError || error,
          [styles.isImage]: isImage,
        })}
      >
        {isImage && (
          <img
            src={mediaUrl}
            alt="Attachment"
            onError={handleErrorImage}
            onClick={handleImageClick}
            onKeyDown={handleKeyDown}
            role="presentation"
          />
        )}

        {isAudio && <audio src={mediaUrl} controls />}
        {isVideo && <video src={mediaUrl} controls />}
        {isFile && (
          <button
            className={cn(styles.link, styles[bg])}
            onClick={handleFileClick}
          >
            <AttachmentIcon
              color={
                bg === 'dark'
                  ? 'var(--icon-default-white)'
                  : 'var(--icon-default-blue)'
              }
            />
            {attachment.title || attachment.filename}
          </button>
        )}
      </div>
    );
  }

  //TODO for multiple attachments as well
  return (
    <div
      ref={attachmentRef}
      className={cn(styles.multipleContainer, {
        [styles.hasError]: renderError || error,
      })}
    >
      {isImage && (
        <img
          src={mediaUrl}
          alt="Attachment"
          onError={handleErrorImage}
          onClick={handleImageClick}
          onKeyDown={handleKeyDown}
          role="presentation"
        />
      )}
      {isAudio && (
        <button className={styles.file} onClick={handleImageClick}>
          <AttachmentAudio />
          <Typography fontWeight="800" variant="label-caps-large">
            {extension}
          </Typography>
        </button>
      )}

      {isVideo && (
        <button className={styles.file} onClick={handleImageClick}>
          <AttachmentVideo />
          <Typography fontWeight="800" variant="label-caps-large">
            {extension}
          </Typography>
        </button>
      )}
      {isFile && (
        <button className={styles.file} onClick={handleFileClick}>
          <AttachmentFile />
          <Typography fontWeight="600" variant="label-caps-large">
            {extension}
          </Typography>
        </button>
      )}
    </div>
  );
};
