import { Icon } from '@/models/icon';

const Minus = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  backgroundColor = 'var(--icon-default-blue)',
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    >
      <rect
        x="2.5"
        y="2.625"
        width="12"
        height="12"
        rx="2"
        fill={backgroundColor}
      />
      <path d="M5.5 8.625h6" />
    </svg>
  );
};
export default Minus;
