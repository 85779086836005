import { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Banner } from '@/components/atoms/Banner/Banner';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import Link from '@/components/atoms/Link/Link';
import Select from '@/components/atoms/Select/Select';
import { usePreferences } from '@/hooks/usePreferences';
import { OptionBase } from '@/models/common';
import { ZENDESK_DOCS_URL } from '@/util/constants';
import { errorMessage, datasourceRules } from '@/util/validator';

import { Form } from '../utils';

interface Props {
  languages: OptionBase[];
  setEnablePrimaryButton: (enabled: boolean) => void;
}
const ConnectZendesk = ({ languages = [], setEnablePrimaryButton }: Props) => {
  const { t } = useTranslation();
  const { userPreferences } = usePreferences();
  const userLanguage = userPreferences?.language ?? 'en-us';

  const {
    register,
    formState: { errors, isValid },
    setValue,
  } = useFormContext<Form>();

  useEffect(() => {
    if (languages.length === 1) {
      setValue('zendesk.locale', languages[0].value);
      setEnablePrimaryButton(true);
    } else {
      setEnablePrimaryButton(isValid);
    }
  }, [isValid, languages, setEnablePrimaryButton, setValue]);

  if (languages.length > 0) {
    return (
      <>
        <ContextualHelp
          title={t('collections.language_help_title')}
          name="zendesk_language_kb"
          preventClose
        >
          <Typography>
            <Trans i18nKey="collections.zendesk_locales" />{' '}
            <Link
              href={`${ZENDESK_DOCS_URL}/hc/${userLanguage}/articles/4408834328090-Localizing-help-center-content`}
              external
            >
              {t('common.here')}
            </Link>
            .
          </Typography>
        </ContextualHelp>

        <Select
          name="language"
          label={t('field.language')}
          placeholder={t('onboarding.language_placeholder')}
          register={register('zendesk.locale', datasourceRules.zendesk.locale)}
          error={!!errors?.zendesk?.locale?.message}
          errorMessage={errorMessage({
            field: errors?.zendesk?.locale,
          })}
          options={languages}
        />
      </>
    );
  }

  return (
    <Banner title={t('collections.not_found_title')} relativePosition>
      {t('collections.zendesk_not_found')}
    </Banner>
  );
};

export default ConnectZendesk;
