export const Sort = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#1B66D6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".8"
        d="M7.5 9.111 4.5 13m0 0-3-3.889M4.5 13V3"
      />
      <path
        stroke="#1B66D6"
        strokeLinecap="round"
        strokeWidth=".8"
        d="M8.9 3.6h6.2m-6.2 3h5.2m-5.2 3h3.2m-3.2 3h1.2"
      />
    </svg>
  );
};
