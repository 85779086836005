import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { rolesEndpoints as endpoints } from '@/api/endpoints';
import { callGet } from '@/api/fetcher';
import { Option } from '@/components/atoms/AutoComplete/AutoComplete';
import { actions } from '@/models/permissions';
import { Roles } from '@/models/role';
import { RootState } from '@/models/state';
import {
  getPermissions,
  selectIsUserOwner,
} from '@/redux/permissions/selectors';
import { selectAccountId } from '@/redux/session/selectors';
import { BILLING, READER, WRITER } from '@/util/constants';

const API = {
  listRoles: async (): Promise<Roles> => callGet(endpoints.roles),
};

export const useRoles = () => {
  const accountId = useSelector(selectAccountId);
  const { t } = useTranslation();

  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'roles', actions.READ)
  );

  const isUserOwner = useSelector(selectIsUserOwner);

  const { data: roles, status: listStatus } = useQuery<Roles, Error>({
    queryKey: [endpoints.roles, accountId],
    queryFn: () => API.listRoles(),
    enabled: !!accountId && canRead,
  });

  // Show all roles except READER and WRITER
  const availableRoles: Option[] = useMemo(
    () =>
      roles?.roles?.reduce((acc, role) => {
        // Exclude READER and WRITER roles, include BILLING role if isUserOwner is true
        if (role.role_id === BILLING && !isUserOwner) {
          return acc;
        }
        if (role.role_id !== READER && role.role_id !== WRITER) {
          const newRole = {
            label: role.name,
            value: role.role_id,
            description: t(
              `roles.${role.role_id}.description`,
              role.description
            ),
            type: t('onboarding.members_roles'),
          };
          acc.push(newRole);
        }
        return acc;
      }, []) ?? [],
    [isUserOwner, roles?.roles, t]
  );
  return {
    roles: roles?.roles,
    listStatus,
    availableRoles,
  };
};
