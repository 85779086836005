import { Dialogs } from '@/models/dialog';

export type DfsNode = {
  id: string;
  dialog_id?: string;
  action_id?: string;
  dialog_name?: string;
};

type PathFound = {
  path: DfsNode[];
};

export const createAdjacencyList = (dialogs: Dialogs) => {
  const adjacencyList = {};
  if (!dialogs) {
    return adjacencyList;
  }
  for (const dialog of dialogs.dialogs) {
    for (const node of dialog.nodes) {
      for (const action of node.actions) {
        if (!adjacencyList[node.node_id]) {
          adjacencyList[node.node_id] = [];
        }
        if (action.type === 'event') {
          adjacencyList[node.node_id].push({
            id: action.trigger_node_id,
            action_id: action.action_id,
            dialog_id: dialog.dialog_id,
            dialog_name: dialog.name,
          });
        }
      }
      if (node.conditions) {
        for (const condition of node.conditions) {
          for (const action of condition.actions) {
            if (!adjacencyList[node.node_id]) {
              adjacencyList[node.node_id] = [];
            }
            if (action.type === 'event') {
              adjacencyList[node.node_id].push({
                id: action.trigger_node_id,
                action_id: action.action_id,
                dialog_id: dialog.dialog_id,
                dialog_name: dialog.name,
              });
            }
          }
        }
      }
    }
  }
  return adjacencyList;
};

export const hasCycleDfs: (
  node: DfsNode,
  adjacencyList: { [key: string]: DfsNode[] },
  visited: {
    [key: string]: boolean;
  },
  exited: {
    [key: string]: boolean;
  },
  path: DfsNode[],
  pathFound: PathFound
) => boolean = (node, adjacencyList, visited, exited, path, pathFound) => {
  // If we have visited this node and we haven't exited, then we have a cycle
  const nodeId = node.id;
  if (visited[nodeId] && !exited[nodeId]) {
    pathFound.path = path.slice(1); // Copy the current stack to pathFound
    pathFound.path.push(node); // Include the current node to complete the cycle
    return true;
  }

  // If we have visited this node and have exited, we don't have a cycle
  if (visited[nodeId]) {
    return false;
  }

  visited[nodeId] = true;
  path.push(node); // Add the current node to the pathStack

  for (const neighbor of adjacencyList[nodeId]) {
    if (
      hasCycleDfs(neighbor, adjacencyList, visited, exited, path, pathFound)
    ) {
      return true;
    }
  }

  path.pop();
  exited[nodeId] = true;
  return false;
};

export const checkCycle = (dialogs: Dialogs): DfsNode[] => {
  const adjacencyList = createAdjacencyList(dialogs);
  const visited = {};
  const exited = {};
  const pathFound = { path: [] };
  try {
    const allNodes = dialogs?.dialogs.flatMap((dialog) => dialog.nodes);
    if (!allNodes) {
      return [];
    }
    for (const node of allNodes) {
      if (
        hasCycleDfs(
          {
            id: node.node_id,
          },
          adjacencyList,
          visited,
          exited,
          [],
          pathFound
        )
      ) {
        return pathFound.path;
      }
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
