import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { BroadcastAction } from '@/modules/broadcast/models';

import styles from '../MessageNode/MessageNode.module.scss';

interface Props {
  text: string;
  author_type: string;
  author_id: string;
  template?: BroadcastAction;
}

export const AgentMessage = ({
  text,
  template,
  author_type,
  author_id,
}: Props) => {
  const { html } = useMarkdownToHtml(text);

  const options = template?.components
    ?.find((component) => component.type.toLowerCase() === 'buttons')
    ?.buttons?.map((option) => ({
      text: option.text,
      label: option.text,
    }));

  return (
    <>
      <Box display="flex" alignItems="center">
        {author_type === 'agent' && <Avatar size="medium" userId={author_id} />}

        <Typography
          variant="body-regular"
          component="p"
          className={cn(styles.bubble, styles.agent)}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </Box>
      <div className={styles.optionsContainer}>
        {template &&
          options?.map((option) => (
            <button
              key={`option-${option.text}`}
              className={cn(
                styles.bubble,
                styles.option,
                styles.optionDisabled
              )}
              onClick={() => {}}
              type="button"
            >
              {option.label}
            </button>
          ))}
      </div>
    </>
  );
};

export default AgentMessage;
