import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';

import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { pageView } from '@/segment/segment';

import ChatDesktopNotification from './fieldsets/ChatDesktopNotification/ChatDesktopNotification';
import ChatNotification from './fieldsets/ChatNotification/ChatNotification';
import EmailNotification from './fieldsets/EmailNotification';

const Notifications = () => {
  const { t } = useTranslation();
  useTitle(t('pages.notifications'));

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'accounts', actions.UPDATE)
  );

  useEffect(() => {
    pageView(PageName.PERSONAL_NOTIFICATIONS);
  }, []);

  return (
    <>
      <ChatNotification />
      <ChatDesktopNotification />
      {canWrite && <EmailNotification />}
    </>
  );
};

export default Notifications;
