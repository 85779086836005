import { Icon } from '@/models/icon';

const ArrowBreadcrumb = ({
  size = 16,
  color = 'var(--icon-default-gray)',
  direction = 'left',
}: Icon) => {
  const arrowRotation = () => {
    switch (direction) {
      case 'left':
        return 'rotate(180deg)';
      case 'down':
        return 'rotate(90deg)';
      case 'up':
        return 'rotate(-90deg)';
      default:
        return 'rotate(0deg)';
    }
  };
  return (
    <svg
      viewBox="0 0 10 10"
      width={size}
      height={size}
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      xmlns="https://www.w3.org/2000/svg"
      style={{
        transform: arrowRotation(),
      }}
    >
      <path d="M.25 5c0 .414.336.75.75.75h6.19L4.47 8.47a.75.75 0 001.06 1.06l4-4a.75.75 0 000-1.06l-4-4a.75.75 0 10-1.06 1.06l2.72 2.72H1A.75.75 0 00.25 5z" />
    </svg>
  );
};

export default ArrowBreadcrumb;
