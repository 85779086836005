import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import TileHeader from '@/components/atoms/TilesHeader/TilesHeader';
import { TileSkeleton } from '@/components/organisms/Tile';
import AddNew from '@/components/organisms/Tile/AddNew';
import TileBusinessHours from '@/components/organisms/Tile/TileBusinessHours';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useBusinessHours from '@/hooks/useBusinessHours';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import styles from './BusinessHour.module.scss';

export default function BusinessHours() {
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();
  useTitle(t('pages.business_hours', { 0: slug }));
  const { businessHours, listStatus, createDefaultBusinessHour } =
    useBusinessHours();
  const deskId = useSelector(selectDeskId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'business_hours', actions.WRITE)
  );

  const handleCreateBusinessHours = useCallback(() => {
    navigate(`/${slug}/environments/${deskId}/business_hours/draft`);
  }, [deskId, slug, navigate]);

  useEffect(() => {
    if (
      listStatus === 'success' &&
      businessHours?.business_hours?.length === 0 &&
      canWrite
    ) {
      createDefaultBusinessHour();
    }
  }, [
    businessHours?.business_hours?.length,
    canWrite,
    listStatus,
    createDefaultBusinessHour,
  ]);

  useEffect(() => {
    pageView(PageName.BUSINESS_HOURS);
  }, []);

  function renderSkeletons(n = 3) {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => <TileSkeleton key={i} />)
    );
  }
  function renderTiles(isDefault = false) {
    if (isDefault) {
      return businessHours?.business_hours.map((bh) =>
        bh.is_default ? (
          <TileBusinessHours businessHour={bh} key={bh.business_hours_id} />
        ) : null
      );
    }
    return businessHours?.business_hours.map((bh) =>
      bh.is_default ? null : (
        <TileBusinessHours businessHour={bh} key={bh.business_hours_id} />
      )
    );
  }

  return (
    <PageContentWrapper
      fullHeight={canWrite}
      newPlain3={!canWrite}
      readOnly={!canWrite}
    >
      <div className={styles.container}>
        <ContextualHelp
          title={t('business_hours.contextual_help.title')}
          name="businessHours-help"
          size="full"
        >
          {t('business_hours.contextual_help.message')}
        </ContextualHelp>
        <TileHeader text={t('business_hours.tile_header.default')} />
        <span className={styles.helperText}>
          {t('business_hours.help_text.default')}
        </span>
        {!businessHours?.business_hours && renderSkeletons(1)}
        {businessHours?.business_hours && renderTiles(true)}

        <TileHeader text={t('business_hours.tile_header.custom')} />
        <div className={styles.helperText}>
          <span>{t('business_hours.help_text.custom1')}</span>
          <Link to={`/${slug}/environments/${deskId}/rules`}>
            <span> {t('business_hours.help_text.rules')} </span>
          </Link>
          <span>{t('business_hours.help_text.custom2')}</span>
        </div>

        {!businessHours?.business_hours && renderSkeletons(1)}
        {businessHours?.business_hours && renderTiles()}

        {canWrite && (
          <AddNew
            handleClick={handleCreateBusinessHours}
            text={t('business_hours.add')}
          />
        )}
      </div>
    </PageContentWrapper>
  );
}
