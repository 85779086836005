import { Anthropic } from '../../icons/Anthropic';
import { Azure } from '../../icons/Azure';
import { Gemini } from '../../icons/Gemini';
import { OpenAI } from '../../icons/OpenAI';
import { ProviderName } from '../../types';

export const modelIconResolver = (
  provider: ProviderName,
  size = 24
): JSX.Element => {
  switch (provider) {
    case 'openai':
      return <OpenAI size={size} />;
    case 'anthropic':
      return <Anthropic size={size} />;
    case 'azure':
      return <Azure size={size} />;
    case 'gemini':
      return <Gemini size={size} />;
  }
};

export const modelNameMapper = (provider: ProviderName): string => {
  switch (provider) {
    case 'openai':
      return 'OpenAI';
    case 'anthropic':
      return 'Anthropic';
    case 'azure':
      return 'Azure OpenAI';
    case 'gemini':
      return 'Gemini';
  }
};

export const providerNameMapper = (provider: ProviderName): string => {
  switch (provider) {
    case 'openai':
      return 'OpenAI';
    case 'anthropic':
      return 'Anthropic';
    case 'azure':
      return 'Azure';
    case 'gemini':
      return 'Gemini';
    case 'moveo':
      return 'Moveo.AI';
    default:
      return 'Moveo.AI';
  }
};
