import useFilters from '@/modules/analytics/hooks/useFilters';
import { AnalyticsType } from '@/modules/analytics/models';

import ContentFilters from './ContentFilters/ContentFilters';
import DateFilter from './DateFilter/DateFilter';

import './Filters.scss';

interface Props {
  type: AnalyticsType;
  setShowLogs?: (value: boolean) => void;
}

const Filters = ({ type, setShowLogs }: Props) => {
  const { dateOpen, setDateOpen, filterOpen, setFilterOpen } = useFilters(
    type,
    setShowLogs
  );
  return (
    <div className="filters">
      <DateFilter
        dateOpen={dateOpen}
        setDateOpen={setDateOpen}
        disabled={filterOpen}
      />
      <ContentFilters
        type={type}
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
      />
    </div>
  );
};

export default Filters;
