import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Bar, prepareBar } from '@/util/analytics';

import useInterval, { IntervalRow } from './useInterval';
import AnalyticsTooltip from '../components/tooltip/AnalyticsTooltip';
import { selectFilters } from '../redux/selectors';

type PieData = {
  id: string;
  label: string;
  value: number;
  count: number;
};

interface Coverage {
  pie?: PieData[];
  bar?: Bar[];
  totalCount?: number;
  PieTooltip?: React.ReactElement;
  BarTooltips?: React.ReactElement[];
  refetch?: () => void;
  unknownRefetch?: () => void;
  error?: ApolloError;
  unknownError?: ApolloError;
}
export interface CoverageHook extends Coverage {
  isLoading: boolean;
  error?: ApolloError;
}

export const formatCoverage = (
  t: (key: string) => string,
  total?: IntervalRow[],
  unknown?: IntervalRow[]
): Coverage => {
  if (!total || !unknown) {
    return {};
  }

  const pie = [
    {
      id: 'not-covered',
      label: t('analytics.total_not_covered'),
      count: 0,
      value: 0,
    },
    {
      id: 'covered',
      label: t('analytics.total_coverage'),
      count: 0,
      value: 0,
    },
  ];

  const {
    newBar: bar,
    currentTotalCounts,
    previousTotalCounts,
  } = prepareBar({
    currentTotal: total,
    previousTotal: unknown,
    shouldComputeDifference: true,
  });

  pie[0].count = previousTotalCounts;
  pie[1].count = currentTotalCounts;

  // Calculate the percentage for the pie chart
  pie[0].value = currentTotalCounts
    ? Math.floor((pie[0].count / (pie[1].count + pie[0].count)) * 100)
    : 0;
  pie[1].value = currentTotalCounts
    ? Math.ceil((pie[1].count / (pie[1].count + pie[0].count)) * 100)
    : 0;

  return { pie, bar, totalCount: currentTotalCounts };
};

const useCoverage = (): CoverageHook => {
  const total = useInterval({
    type: 'sessions',
    is_brain: true,
    is_brain_effectiveness: true,
  });
  const unknown = useInterval({
    type: 'sessions',
    is_covered: false,
    is_brain_effectiveness: true,
  });
  const { startDate, endDate } = useSelector(selectFilters);
  const { t } = useTranslation();

  if (total.isLoading || unknown.isLoading) {
    return {
      isLoading: true,
    };
  }

  if (total.error || unknown.error) {
    return {
      isLoading: false,
      error: total.error,
      unknownError: unknown.error,
      refetch: total.refetch,
      unknownRefetch: unknown.refetch,
    };
  }

  const coverage = formatCoverage(t, total?.data, unknown?.data);

  const pieTooltipData = [
    {
      title: t('analytics.charts_tooltip.percent_cover'),
      value: `${coverage.pie?.[1].value}%`,
    },
    {
      title: t('analytics.charts_tooltip.total'),
      value: `${coverage.pie?.[0].count + coverage.pie?.[1].count}`,
    },
    {
      title: t('analytics.charts_tooltip.cover'),
      value: `${coverage.pie?.[1].count}`,
    },
    {
      title: t('analytics.charts_tooltip.not_cover'),
      value: `${coverage.pie?.[0].count}`,
    },
  ];

  const PieTooltip = (
    <AnalyticsTooltip
      startDate={startDate}
      endDate={endDate}
      data={pieTooltipData}
    />
  );

  const BarTooltips = coverage?.bar?.map((bar) => {
    const [startDate, endDate] = bar.date.split(' - ');
    const tooltipData = [
      {
        title: t('analytics.charts_tooltip.percent_cover'),
        value: `${Math.ceil(
          (100 * bar['primary']) / (bar['primary'] + bar['secondary'])
        )}%`,
      },
      {
        title: t('analytics.charts_tooltip.total'),
        value: `${bar['primary'] + bar['secondary']}`,
      },
      {
        title: t('analytics.charts_tooltip.cover'),
        value: `${bar['primary']}`,
      },
      {
        title: t('analytics.charts_tooltip.not_cover'),
        value: `${bar['secondary']}`,
      },
    ];

    return (
      <AnalyticsTooltip
        key={`covered-${bar.date}`}
        isBar
        data={tooltipData}
        startDate={startDate}
        endDate={endDate}
      />
    );
  });

  return {
    isLoading: false,
    ...coverage,
    PieTooltip,
    BarTooltips,
  };
};

export default useCoverage;
