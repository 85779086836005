import { useCallback } from 'react';

import cn from 'classnames';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import styles from '@/components/organisms/Dialogs/Node/Placeholder/Placeholder.module.scss';
import { TYPES, generateRequisite } from '@/redux/dialogs/helper';
import { addNewRequisite, moveRequisite } from '@/redux/nodes/actions';

interface PlatceHolderProps {
  nodeId: string;
  requisiteIndex?: number;
  requisiteId?: string;
  show?: boolean;
  label?: string;
}

function Placeholder({
  nodeId,
  requisiteIndex,
  requisiteId,
  show = false,
  label = '',
}: PlatceHolderProps) {
  const dispatch = useDispatch();

  const dropRequisiteInNode = useCallback(
    (item) => {
      if (item.move) {
        return dispatch(
          moveRequisite({
            source: item,
            target: {
              nodeId,
              requisiteIndex,
              requisiteId,
            },
          })
        );
      }

      return dispatch(
        addNewRequisite({
          nodeId,
          requisite: generateRequisite(),
          actionId: item.id,
          requisiteIndex,
        })
      );
    },
    [dispatch, nodeId, requisiteId, requisiteIndex]
  );

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: TYPES.QUESTION,
    drop: dropRequisiteInNode,
    canDrop: (item) => {
      // Hide placeholder that is above or below itself
      if (
        nodeId === item.nodeId &&
        (requisiteIndex === item.requisiteIndex ||
          requisiteIndex === item.requisiteIndex + 1)
      ) {
        return false;
      }
      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      className={cn({
        [styles.placeholder]: true,
        [styles['placeholder--over']]: isOver,
        [styles['placeholder--can-drop']]: canDrop,
        [styles['placeholder--show']]: show,
      })}
    >
      {label && <p>{label}</p>}
    </div>
  );
}

export default Placeholder;
