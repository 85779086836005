import { Props } from '@/models/icon';

const MacOs = ({ size = 16 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M462.6 398.98c-7.72 17.96-16.98 34.37-27.5 49.53-14.45 20.62-26.38 34.93-35.5 42.93-14.17 13.05-29.32 19.64-45.6 20.06-11.64 0-25.67-3.37-42.09-10.1-16.42-6.73-31.57-9.96-45.32-9.96-14.45 0-30.02 3.37-46.58 9.96-16.7 6.73-30.02 10.24-40.27 10.52-15.57.7-31.15-6.17-46.58-20.62-9.96-8.7-22.31-23.43-37.04-44.33-15.85-22.45-29.04-48.26-39.28-77.87-10.94-31.99-16.41-62.85-16.41-92.88 0-34.37 7.44-63.98 22.31-88.67 11.64-19.92 27.22-35.64 46.58-47.14 19.5-11.5 40.41-17.4 62.99-17.82 12.35 0 28.62 3.79 48.82 11.36 20.06 7.58 32.97 11.36 38.72 11.36 4.21 0 18.52-4.49 42.93-13.47 23.01-8.28 42.37-11.64 58.36-10.38 43.07 3.51 75.48 20.48 96.95 51.07-38.58 23.29-57.52 55.98-57.24 97.93.28 32.69 12.21 59.91 35.5 81.51 10.52 9.96 22.31 17.82 35.5 23.29-3.08 8.29-6.02 16.14-9.25 23.72zM363.83 10.21c0 25.67-9.4 49.53-28.06 71.69-22.45 26.38-49.67 41.53-79.27 39.14-.42-3.09-.56-6.31-.56-9.68 0-24.55 10.66-50.93 29.74-72.39 9.54-10.94 21.61-19.92 36.2-27.22C336.47 4.59 350.36.67 363.27-.04c.28 3.37.56 6.88.56 10.25z" />
    </svg>
  );
};

export default MacOs;
