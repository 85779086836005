import { saveAs } from 'file-saver';
import moment from 'moment';
import Papa from 'papaparse';

const prepareDataForDownload = (expressions, intent) => {
  const headers = [['Intent', 'Expression']];
  expressions.forEach((item) => headers.push([intent, item]));
  return new Blob([Papa.unparse(headers)], {
    type: 'text/plain;charset=utf-8',
  });
};
export const exportIntents = ({ expressions, intent }) =>
  saveAs(prepareDataForDownload(expressions, intent), `${intent}.csv`);

export const getIntentData = (data) => {
  if (data.length === 0) {
    return null;
  }

  if (!data[0].Intent || !data[0].Expression) {
    return null;
  }

  return data
    .map((row) => {
      return {
        intent: row.Intent.trim(),
        expression: row.Expression.trim(),
      };
    })
    .filter((row) => row.intent.length !== 0 && row.expression.length < 512)
    .map((row) => row.expression);
};

export const importIntents = (file: unknown, t: (key: string) => string) =>
  new Promise((resolve, reject) =>
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const data = getIntentData(results.data);
        if (!data) {
          reject(new Error(t('file_upload.incorrect_format')));
        } else {
          resolve(data);
        }
      },
      error: (err) => {
        reject(err);
      },
    })
  );

const prepareEntityForDownload = ({ entity, values }) => {
  const headers = [['Entity', 'Type', 'Value', 'Synonyms/Patterns']];
  values.forEach((item) => {
    if (item.synonyms) {
      headers.push([entity, item.type, item.value, item.synonyms]);
    } else {
      headers.push([entity, item.type, item.value, item.patterns]);
    }
  });
  return new Blob([Papa.unparse(headers)], {
    type: 'text/plain;charset=utf-8',
  });
};

export const exportEntities = (entity) =>
  saveAs(prepareEntityForDownload(entity), `${entity.entity}.csv`);

export const getEntityData = (data) => {
  if (data.length === 0) {
    return null;
  }

  if (
    !data[0].Entity ||
    !data[0].Type ||
    !data[0].Value ||
    !data[0]['Synonyms/Patterns']
  ) {
    return null;
  }

  return data
    .filter((row) => {
      let noEmptyCells = true;
      if (
        row.Type.toLowerCase() !== 'synonym' &&
        row.Type.toLowerCase() !== 'pattern'
      ) {
        return false;
      }
      Object.values(row).forEach((val: string) => {
        if (val.trim().length === 0) {
          noEmptyCells = false;
        }
      });
      return noEmptyCells;
    })
    .map((row) => {
      if (row.Type.toLowerCase() === 'synonym') {
        return {
          type: row.Type,
          value: row.Value,
          synonyms: row['Synonyms/Patterns'].split(','),
        };
      }

      return {
        type: row.Type,
        value: row.Value,
        patterns: row['Synonyms/Patterns'].split(','),
      };
    });
};

export const importEntities = (file: unknown, t: (key: string) => string) =>
  new Promise((resolve, reject) =>
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const data = getEntityData(results.data);
        if (!data) {
          reject(new Error(t('file_upload.incorrect_format')));
        } else {
          resolve(data);
        }
      },
      error: (err) => reject(err),
    })
  );

export const exportDialog = (dialog) => {
  const blob = new Blob([JSON.stringify(dialog, null, 2)], {
    type: 'application/json',
  });

  saveAs(blob, `${dialog.name}.json`);
};

export const importDialog = (dialog, file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result as string;
      try {
        const dialogAsJson = JSON.parse(fileContent);
        resolve({ ...dialog, nodes: dialogAsJson.nodes });
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsText(file);
  });
};

export const exportBrain = (brain) => {
  const blob = new Blob([JSON.stringify(brain, null, 2)], {
    type: 'application/json',
  });

  saveAs(blob, `${brain.name}.json`);
};

export const exportAnalytics = (data, name, type) => {
  if (type === 'json') {
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/json',
    });

    saveAs(blob, `${name}.json`);

    return;
  }

  const blob = new Blob([Papa.unparse(data)], {
    type: 'text/plain;charset=utf-8',
  });

  saveAs(blob, `${name}.csv`);
};

export const exportBundle = (bundle) => {
  const blob = new Blob([JSON.stringify(bundle.context, null, 2)], {
    type: 'application/json',
  });
  const date = moment().format('DD-MM-YYYY');
  const name = bundle.name.replace(/ /g, '_');
  saveAs(blob, `${date}-${name}-bundle.json`);
};
