export default `

███╗░░░███╗░█████╗░██╗░░░██╗███████╗░█████╗░░░░░█████╗░██╗
████╗░████║██╔══██╗██║░░░██║██╔════╝██╔══██╗░░░██╔══██╗██║
██╔████╔██║██║░░██║╚██╗░██╔╝█████╗░░██║░░██║░░░███████║██║
██║╚██╔╝██║██║░░██║░╚████╔╝░██╔══╝░░██║░░██║░░░██╔══██║██║
██║░╚═╝░██║╚█████╔╝░░╚██╔╝░░███████╗╚█████╔╝██╗██║░░██║██║
╚═╝░░░░░╚═╝░╚════╝░░░░╚═╝░░░╚══════╝░╚════╝░╚═╝╚═╝░░╚═╝╚═╝

Shape the future of customer experience

WE ARE HIRING: https://moveo-ai.notion.site/Careers-at-M-veo-AI-6f7204b1e4fd49098c623f623df0592d
`;
