import { createSelector } from '@reduxjs/toolkit';
import { t } from 'i18next';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import { v4 as uuidv4 } from 'uuid';

import { Action, ResetActionOption, TextAction } from '@/models/action';
import {
  ContextVariableOption,
  LeadDataVariableOption,
  SystemVariableOption,
  UserInfoVariableOption,
} from '@/models/autocomplete';
import { Dialog, PartialDialog } from '@/models/dialog';
import {
  Condition,
  ConditionMatchOption,
  Node,
  Nodes,
  Requisite,
} from '@/models/node';
import { RootState } from '@/models/state';
import { Webhook } from '@/models/webhook';
import {
  LEAD_DATA_VARIABLES,
  SYS_CONTEXT_VARIABLES,
  USER_INFO_VARIABLES,
} from '@/redux/dialogs/helper';
import { checkForDollar, getUniqueListBy } from '@/util/util';

import { findAction, findActionContainerById } from '../nodes/actions';

export interface TreeItem {
  name: string;
  id: string;
  collection: string;
  link: string;
}
type AllActionsType = {
  action_id: string;
  type: string;
  tags?: string[];
  node_id: string;
  dialog_id: string;
  requisite_idx: number | null;
  dialog_name: string;
  if_no_requisite?: true;
  trigger_node_id?: string | null;
}[];

export const selectDraftDialog: (state: RootState) => PartialDialog =
  createSelector(
    (state: RootState) => state.nodes,
    (nodes) => {
      return {
        dialog_id: nodes.dialogId,
        nodes: nodes.nodes,
        name: nodes.dialogName,
      };
    }
  );

export const selectDraftDialogId: (state: RootState) => string = createSelector(
  (state: RootState) => state.nodes,
  (nodes) => nodes.dialogId
);

export const selectDrafDialogActions = createSelector(
  (state: RootState) => state.nodes,
  (nodes) => {
    return nodes.nodes.reduce((acc, node) => {
      return [...acc, ...node.actions];
    }, [] as Action[]);
  }
);

export const selectDialogId: (state: RootState) => string = createSelector(
  (state: RootState) => state.nodes,
  (nodes) => nodes.dialogId
);

// Dialog Editor
export const selectNodeByActionId: (
  state: RootState,
  actionId: string
) => Node | null = createSelector(
  [
    (state: RootState) => state.nodes.nodes,
    (_: RootState, actionId: string) => actionId,
  ],
  (nodes, actionId) => {
    // Find action in nodes
    const n = nodes.find(({ actions }) => findAction(actions, actionId));
    if (n) {
      return n;
    }
    for (let i = 0; i < nodes.length; i += 1) {
      const node = nodes[i];

      // Find action in conditions
      const condition = (node.conditions || []).find(({ actions }) =>
        findAction(actions, actionId)
      );
      if (condition) {
        return node;
      }
      // Find action in requisites
      const requisite = (node.requisites || []).find(({ actions }) =>
        findAction(actions || [], actionId)
      );
      if (requisite) {
        return node;
      }
    }
    return null;
  }
);

export const selectNodeById: (state: RootState, nodeId: string) => Node | null =
  createSelector(
    (state: RootState) => state.nodes.nodes,
    (_: RootState, nodeId: string) => nodeId,
    (nodes, nodeId) => nodes.find((n) => n.node_id === nodeId)
  );

export const selectRequisites = createSelector(
  selectNodeById,
  (node) => node?.requisites
);

export const selectNoRequisites = createSelector(
  selectNodeById,
  (node) => node?.if_no_requisites
);

export const selectIntentByNodeId: (
  state: RootState,
  nodeId: string
) => string | null = createSelector(
  (state: RootState) => state.nodes.nodes,
  (_: RootState, nodeId: string) => nodeId,
  (nodes, nodeId) => {
    const node = nodes.find((n) => n.node_id === nodeId);
    return node ? node.intent : null;
  }
);

export const selectRequisitesByNodeId = createSelector(
  (state: RootState) => state.nodes.nodes,
  (_: RootState, nodeId: string) => nodeId,
  (nodes: Node[], nodeId) => nodes.find((n) => n.node_id === nodeId)?.requisites
);

export const selectConditionsByNodeId: (
  state: RootState,
  nodeId: string
) => Condition[] | undefined = createSelector(
  (state: RootState) => state.nodes.nodes,
  (_: RootState, nodeId: string) => nodeId,
  (nodes: Node[], nodeId) => nodes.find((n) => n.node_id === nodeId)?.conditions
);

export const selectConditionsByNodeIdLength: (
  state: RootState,
  nodeId: string
) => number = createSelector(
  (state: RootState) => state.nodes.nodes,
  (_: RootState, nodeId: string) => nodeId,
  (nodes: Node[], nodeId) =>
    nodes.find((n) => n.node_id === nodeId)?.conditions?.length || 0
);

export const selectActions = createSelector(
  (state: RootState) => state.nodes.nodes,
  (
    _: RootState,
    props: { nodeId: string; conditionIndex: number; requisiteIndex: number }
  ) => props,
  (nodes, { nodeId, conditionIndex, requisiteIndex }) => {
    let actionContainer: Node | Condition | Requisite | undefined = nodes.find(
      (n) => n.node_id === nodeId
    );
    if (!isNil(conditionIndex) && actionContainer?.conditions) {
      actionContainer = actionContainer?.conditions[conditionIndex];
    } else if (!isNil(requisiteIndex)) {
      actionContainer = actionContainer?.requisites[requisiteIndex];
    }
    return actionContainer?.actions || [];
  }
);

export const selectRequisiteExternalActions = createSelector(
  (state: RootState) => state.nodes.nodes,
  (
    _: RootState,
    props: { nodeId: string; conditionIndex: number; requisiteIndex: number }
  ) => props,
  (nodes, { nodeId }) => {
    const actionContainer: Node | Condition | Requisite | undefined =
      nodes.find((n) => n.node_id === nodeId);

    if (actionContainer?.requisites?.length > 0) {
      const requisitesActions = actionContainer?.requisites.map(() => ({
        type: 'question',
        action_id: uuidv4(),
      }));

      return requisitesActions;
    }
    return [];
  }
);

// returns an array that contains all the actions from all the dialogs (including conditions and requisites)
export const getActionsSelector: (dialogs: Dialog[]) => AllActionsType = (
  dialogs
) => {
  const allActions = [];
  if (!dialogs) {
    return;
  }

  // Get all the actions from all the dialogs
  for (let d = 0; d < dialogs.length; d += 1) {
    for (let n = 0; n < dialogs[d].nodes.length; n += 1) {
      for (let a = 0; a < dialogs[d].nodes[n].actions?.length; a += 1) {
        const action = dialogs[d].nodes[n].actions[a];

        const tags = action.type === 'tag' ? action.tags : undefined;
        const trigger_node_id =
          action.type === 'event' ? action.trigger_node_id : null;

        allActions.push({
          action_id: dialogs[d].nodes[n].actions[a].action_id,
          type: dialogs[d].nodes[n].actions[a].type,
          tags,
          node_id: dialogs[d].nodes[n].node_id,
          dialog_id: dialogs[d].dialog_id,
          requisite_idx: null,
          trigger_node_id,
          dialog_name: dialogs[d].name,
        });
      }

      // Get all the actions from all the conditions
      const conditions = dialogs[d]?.nodes[n]?.conditions;
      if (conditions && conditions.length) {
        for (let c = 0; c < conditions?.length; c += 1) {
          for (let a = 0; a < conditions[c].actions?.length; a += 1) {
            const action = conditions[c].actions[a];
            const trigger_node_id =
              action.type === 'event' ? action.trigger_node_id : null;
            const tags = action.type === 'tag' ? action.tags : undefined;
            allActions.push({
              action_id: conditions[c].actions[a].action_id,
              type: conditions[c].actions[a].type,
              tags,
              node_id: dialogs[d].nodes[n].node_id,
              dialog_id: dialogs[d].dialog_id,
              requisite_idx: null,
              trigger_node_id,
              dialog_name: dialogs[d].name,
            });
          }
        }
      }

      // Get all the actions from all the requisites
      const requisites = dialogs[d]?.nodes[n]?.requisites;
      if (requisites && requisites.length) {
        for (let r = 0; r < dialogs[d].nodes[n].requisites?.length; r += 1) {
          for (let a = 0; a < requisites[r].actions?.length; a += 1) {
            allActions.push({
              ...pick(requisites[r].actions[a], ['action_id', 'type', 'tags']),
              node_id: dialogs[d].nodes[n].node_id,
              dialog_id: dialogs[d].dialog_id,
              requisite_idx: r,
              dialog_name: dialogs[d].name,
            });
          }
        }
      }

      const if_no_requisites = dialogs[d]?.nodes[n]?.if_no_requisites;
      if (if_no_requisites && if_no_requisites.length) {
        for (
          let r = 0;
          r < dialogs[d].nodes[n]?.if_no_requisites?.length;
          r += 1
        ) {
          allActions.push({
            action_id: if_no_requisites[r].action_id,
            type: if_no_requisites[r].type,
            tags: null,
            node_id: dialogs[d].nodes[n].node_id,
            dialog_id: dialogs[d].dialog_id,
            requisite_idx: null,
            if_no_requisite: true,
            dialog_name: dialogs[d].name,
          });
        }
      }
    }
  }
  return allActions;
};

export const selectBranchesByNodeId = createSelector(
  (state: RootState): Node[] => state.nodes.nodes,
  (_: RootState, nodeId: string) => nodeId,
  (nodes, nodeId): string[] =>
    nodes.filter((n) => n.parent_id === nodeId).map((n) => n.node_id)
);

export const selectSelectedNode = createSelector(
  (state: RootState) => state.nodes,
  ({ selectedNodeId, nodes }: Nodes) =>
    nodes.find(({ node_id }) => node_id === selectedNodeId)
);

export const selectSelectedAction: (
  state: RootState
) => Action | null | undefined = createSelector(
  ({ nodes }: { nodes: Nodes }) => nodes,
  ({ nodes, selectedActionId }: Nodes) => {
    if (selectedActionId) {
      const container: { actions: Action[] } = findActionContainerById(
        nodes,
        selectedActionId
      );
      if (container) {
        return container.actions.find((a) => a.action_id === selectedActionId);
      }
    }
    return null;
  }
);

export const selectNodes = createSelector(
  ({ nodes }: { nodes: Nodes }) => nodes,
  (_: RootState, dialogId: string) => dialogId,
  (nodes, dialogId): Node[] => {
    if (dialogId !== nodes.dialogId) {
      return [];
    }
    return nodes.nodes;
  }
);

export const isNodeOrActionSelected = createSelector(
  (state: RootState) => state.nodes,
  ({ selectedActionId, selectedNodeId }): boolean =>
    !!selectedActionId || !!selectedNodeId
);

export const selectSelectedCondition = createSelector(
  selectSelectedNode,
  (state: RootState) => state.nodes.selectedConditionIndex,
  (node, selectedConditionIndex) => {
    const conditions = node?.conditions;
    if (typeof selectedConditionIndex !== 'number') {
      return null;
    }
    if (!conditions || conditions.length < selectedConditionIndex) {
      return null;
    }
    return conditions[selectedConditionIndex];
  }
);

const matchOptions: ConditionMatchOption[] = [
  { value: 'any', label_key: 'dialog.condition.match_any', disabled: false },
  { value: 'all', label_key: 'dialog.condition.match_all', disabled: false },
  { value: 'else', label_key: 'dialog.condition.match_else', disabled: false },
];

export const selectMatchOptions = createSelector(
  selectSelectedNode,
  (state: RootState) => state.nodes.selectedConditionIndex,
  (node, selectedConditionIndex): ConditionMatchOption[] => {
    const conditions = node?.conditions ?? [];
    const elseConditionIndex = conditions.findIndex(
      (c: { match }) => c.match === 'else'
    );

    const disableElse =
      elseConditionIndex !== -1 &&
      elseConditionIndex !== selectedConditionIndex;
    const options = [...matchOptions];
    options[2].disabled = disableElse;
    return options;
  }
);

const resetOptions: ResetActionOption[] = [
  {
    value: 'all',
    label_key: 'dialog.reset.all_variables',
    disabled: false,
  },
  {
    value: 'custom',
    label_key: 'dialog.reset.custom_variables',
    disabled: false,
  },
];

export const selectResetOptions = createSelector(
  selectSelectedAction,
  (action): ResetActionOption[] => {
    const options = [...resetOptions];
    if (action?.type === 'reset') {
      const variables = action?.variables ?? [];
      // disable all variables option if there exists a reset variable
      if (variables.length > 0) {
        options[0].disabled = true;
      } else {
        options[0].disabled = false;
      }
    }
    return options;
  }
);

export const selectAllTags = (dialogs: Dialog[]): string[] => {
  const actions = getActionsSelector(dialogs);
  const tags = actions
    ?.filter((a) => a.type === 'tag')
    .reduce(
      (acc, val) => acc.concat(val.type === 'tag' ? val?.tags || [] : []),
      [] as string[]
    );
  return uniq(tags);
};

export const selectContextVariablesFromRequisites = (
  dialogs: Partial<Dialog>[]
): ContextVariableOption[] => {
  if (!dialogs) {
    return [];
  }

  const contextVariables: ContextVariableOption[] = [];
  const contextVariablesSet = new Set<string>();
  for (let i = 0; i < dialogs.length; i += 1) {
    for (let j = 0; j < dialogs[i].nodes?.length; j += 1) {
      if (dialogs[i].nodes[j]?.requisites) {
        dialogs[i].nodes[j].requisites.forEach(
          (requisite: { save_as: string; check_for?: string }) => {
            const values = [];
            if (requisite?.save_as) {
              values.push(`$${requisite.save_as}`);
            }
            if (requisite?.check_for && requisite.check_for[0] === '$') {
              values.push(requisite.check_for);
            }
            if (
              requisite?.check_for &&
              requisite?.save_as &&
              requisite.check_for[0] === '@'
            ) {
              values.push(`$${requisite.save_as}_value`);
            }
            values.forEach((value) => {
              if (!contextVariablesSet.has(value)) {
                contextVariablesSet.add(value);
                contextVariables.push({
                  value: value,
                  label: value,
                  type: 'context_variable',
                });
              }
            });
          }
        );
      }
    }
  }
  return contextVariables;
};

export const selectContextVariablesFromActions = (
  dialogs: Partial<Dialog>[]
): ContextVariableOption[] => {
  if (!dialogs) {
    return [];
  }

  const contextVariables: ContextVariableOption[] = [];
  const contextVariableValues = new Set();

  for (let i = 0; i < dialogs.length; i += 1) {
    for (let j = 0; j < dialogs[i].nodes?.length; j += 1) {
      if (dialogs[i].nodes[j]?.actions) {
        dialogs[i].nodes[j].actions.forEach((action) => {
          if (action?.type === 'reset') {
            action.variables?.forEach((variable) => {
              const varValue = `$${variable}`;

              // Skip adding to contextVariables the $live_instructions variable,
              // because it will be added later as a special variable
              if (
                !contextVariableValues.has(varValue) &&
                varValue !== '$live_instructions' &&
                varValue !== '$tags'
              ) {
                contextVariableValues.add(varValue);
                contextVariables.push({
                  value: checkForDollar(variable),
                  label: checkForDollar(variable),
                  type: 'context_variable',
                });
              }
            });
          }
        });
      }
    }
  }
  return contextVariables;
};

// Context variables created from the condition rules
export const selectContextVariablesFromConditions = (
  dialogs: Partial<Dialog>[]
): ContextVariableOption[] => {
  if (!dialogs) {
    return [];
  }

  const contextVariablesSet = new Set<string>();

  for (const dialog of dialogs) {
    for (const node of dialog?.nodes ?? []) {
      for (const condition of node?.conditions ?? []) {
        condition?.rules?.forEach((rule: { name: string }) => {
          if (rule?.name && rule.name[0] === '$') {
            contextVariablesSet.add(rule.name);
          }
        });

        condition?.actions?.forEach((action) => {
          if (action?.type === 'reset') {
            action.variables?.forEach((variable) => {
              contextVariablesSet.add(`$${variable}`);
            });
          }
        });
      }
    }
  }

  // Convert the set back to the desired array format.
  const contextVariables: ContextVariableOption[] = Array.from(
    contextVariablesSet
  ).map((name) => ({
    value: name,
    label: name,
    type: 'context_variable',
  }));

  return contextVariables;
};

export const selectContextVariablesFromWebhooks = (
  webhooks: Webhook[]
): ContextVariableOption[] => {
  if (!webhooks) {
    return [];
  }

  const contextVariablesSet: Set<string> = new Set();

  for (const webhook of webhooks) {
    if ('context' in webhook.test_schema) {
      for (const [key] of Object.entries(webhook.test_schema.context)) {
        if (!contextVariablesSet.has(key)) {
          contextVariablesSet.add(key);
        }
      }
    }
  }

  const contextVariables: ContextVariableOption[] = Array.from(
    contextVariablesSet
  ).map((key) => ({
    value: checkForDollar(key),
    label: checkForDollar(key),
    type: 'context_variable',
  }));

  return contextVariables;
};

export const noSystemVariables = (
  dialogs: Dialog[],
  draftDialog: Partial<Dialog>,
  webhooks: Webhook[]
): ContextVariableOption[] => {
  const dialogsWithDraft = [...(dialogs ?? []), draftDialog];

  const requisites = selectContextVariablesFromRequisites(dialogsWithDraft);
  const actions = selectContextVariablesFromActions(dialogsWithDraft);
  const conditions = selectContextVariablesFromConditions(dialogsWithDraft);
  const webhooksContextVariables = selectContextVariablesFromWebhooks(webhooks);
  // These serve special occassions, like the live instructions
  const reservedContextVariables: ContextVariableOption[] = [
    {
      label:
        t('dialog.set_variables.live_instructions') || '$live_instructions',
      value: '$live_instructions',
      type: 'context_variable',
    },
  ];

  const uniqueVariables = getUniqueListBy(
    [
      ...reservedContextVariables,
      ...requisites,
      ...actions,
      ...conditions,
      ...webhooksContextVariables,
    ],
    'label'
  ) as ContextVariableOption[];

  // Remove system variables from the list, because they can be contained in the conditions
  const nonSystemVariables = uniqueVariables.filter(
    (uniqueVariable) =>
      !SYS_CONTEXT_VARIABLES.some(
        (systemVariable) => uniqueVariable.label === systemVariable
      )
  );
  return nonSystemVariables;
};

// No system variables and no user variables
export const selectCustomVariables = (
  dialogs: Dialog[],
  draftDialog: Partial<Dialog>,
  webhooks: Webhook[]
): ContextVariableOption[] => {
  return noSystemVariables(dialogs, draftDialog, webhooks).filter(
    ({ label }) => !label.startsWith('$user.')
  );
};

export const selectAllVariables = (
  dialogs: Dialog[],
  draftDialog: Partial<Dialog>,
  webhooks: Webhook[]
): (
  | ContextVariableOption
  | SystemVariableOption
  | UserInfoVariableOption
  | LeadDataVariableOption
)[] => {
  if (!dialogs) {
    return [];
  }

  const systemVariables: SystemVariableOption[] = SYS_CONTEXT_VARIABLES.map(
    (sysVariable: string) => ({
      label: t(`auto_complete.variable_labels.${sysVariable}`),
      value: sysVariable,
      type: 'system_variable',
    })
  );

  const userInfoVariables: UserInfoVariableOption[] = USER_INFO_VARIABLES.map(
    (userInfoVariable: string) => ({
      label: t(`auto_complete.variable_labels.${userInfoVariable}`),
      value: userInfoVariable,
      type: 'user_info_variable',
    })
  );

  const leadDataVariables: LeadDataVariableOption[] = LEAD_DATA_VARIABLES.map(
    (leadDataVariable: string) => ({
      label: t(`auto_complete.variable_labels.${leadDataVariable}`),
      value: leadDataVariable,
      type: 'lead_data_variable',
    })
  );

  const allVariables = [
    ...leadDataVariables,
    ...userInfoVariables,
    ...systemVariables,
    ...noSystemVariables(dialogs, draftDialog, webhooks),
  ];

  return allVariables;
};

export const selectSelectedRequisiteIndex = (state: RootState): number =>
  state.nodes.selectedRequisiteIndex;

export const selectConditionRules = createSelector(
  selectSelectedCondition,
  (condition): Condition['rules'] => condition?.rules ?? []
);

export const selectFirstActionIdByNodeId = (nodeId: string) =>
  createSelector(
    (state: RootState) => state.nodes.nodes,
    (nodes) => {
      const node = nodes.find((n) => n.node_id === nodeId);

      if (node) {
        return node.actions[0]?.action_id;
      }
      return null;
    }
  );

export const selectNodeIdByActionId = (actionId: string) =>
  createSelector(
    (state: RootState) => selectNodeByActionId(state, actionId),
    (node) => node?.node_id
  );

const selectActionId = (state: RootState) =>
  selectSelectedAction(state).action_id;

// Type guard to check if the action is a TextAction
const isTextAction = (action): action is TextAction => 'texts' in action;

const selectTexts = createSelector([selectSelectedAction], (selectedAction) => {
  if (isTextAction(selectedAction)) {
    return selectedAction.texts || [];
  }
  return [];
});

const selectOptions = createSelector(
  [selectSelectedAction],
  (selectedAction) => {
    if (isTextAction(selectedAction)) {
      return selectedAction.options || [];
    }
    return [];
  }
);

export const selectActionData = createSelector(
  selectActionId,
  selectTexts,
  selectOptions,
  (actionId, texts, options) => ({
    actionId,
    texts,
    options,
  })
);

// Selector to get nodeId from the state, assuming you have a way to get it
const getNodeId = (state, nodeId) => nodeId;

// Selector to get the selected node
const getNode = createSelector(
  [getNodeId, (state) => state],
  (nodeId, state) => {
    return selectNodeById(state, nodeId);
  }
);

// Selector for actions
const getActions = createSelector([getNode], (node) => node.actions || []);

// Selector for conditions
const getConditions = createSelector(
  [getNode],
  (node) => node.conditions || []
);

// Selector to determine if the node is selected
const getIsSelected = createSelector(
  [getNodeId, (state) => state.nodes],
  (nodeId, nodesState) => {
    return (
      nodeId === nodesState.selectedNodeId &&
      nodesState.selectedRequisiteIndex === null &&
      nodesState.selectedConditionIndex === null
    );
  }
);

// Selector for last action type
const getLastActionType = createSelector(
  [getActions],
  (actions) => actions[actions.length - 1]?.type
);

// Selector to determine if the root node is unknown
const getIsRootNodeUnknown = createSelector(
  [getNode, (state) => state.nodes.nodes],
  (node, nodes) => {
    return nodes[0]?.type === 'unknown' && !node.parent_id;
  }
);

// Combined selector
export const nodeSelector = createSelector(
  [
    getNode,
    getActions,
    getConditions,
    getIsSelected,
    getLastActionType,
    getIsRootNodeUnknown,
  ],
  (
    node,
    actions,
    conditions,
    isSelected,
    lastActionType,
    isRootNodeUnknown
  ) => {
    return {
      name: node.name,
      type: node.type, // 'option' | 'condition' | 'intent' | 'event' cast removed for simplicity
      parentId: node.parent_id,
      showPlaceholder: actions.length === 0 && conditions.length === 0,
      isSelected,
      intent: node.intent,
      node,
      lastActionType,
      hasActions: actions.length > 0, // Fixed logic here based on previous feedback
      isRootNodeUnknown,
      hasConditions: conditions.length > 0,
    };
  }
);
