import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addTemporalToast } from '@/modules/notifications/redux/actions';

export const useNavigatorOnLine = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const setOnline = () => {
      dispatch(addTemporalToast('info', t('common.online')));
    };

    const setOffline = () => {
      dispatch(addTemporalToast('warning', t('common.offline')));
    };

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, [dispatch, t]);
};
