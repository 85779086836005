const ChevronDown = ({ size = 16, color = 'var(--icon-default-blue)' }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m4 6.867 4 4.266 4-4.266" />
    </svg>
  );
};

export default ChevronDown;
