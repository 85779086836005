import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import Create from '@/components/atoms/Icons/Create';
import Modal from '@/components/organisms/Modals/Modal';
import { TestNumberWithDeskId } from '@/hooks/useWhatsappTesting';

import PhoneTile from './PhoneTile/PhoneTile';

import styles from './SelectNumberSubModal.module.scss';

type SelectNumberSubModal = {
  phoneNumbers: TestNumberWithDeskId[];
  hasPermission: boolean;
  onNumberSelect: (pn: TestNumberWithDeskId) => void;
  onAddNumberClick: () => void;
};

const SelectNumberSubModal = ({
  phoneNumbers,
  hasPermission,
  onNumberSelect,
  onAddNumberClick,
}: SelectNumberSubModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      size="medium"
      title={t('whatsapp_test.select_num.title')}
      subtitle={t('whatsapp_test.select_num.subtitle')}
      passiveModal
      autoFocus
    >
      <Button
        size="small"
        variant="tertiary"
        disabled={!hasPermission}
        onClick={onAddNumberClick}
      >
        <Create
          color={hasPermission ? 'var(--icon-default-blue)' : undefined}
        />
        {t('integrations.whatsapp.testing.add_num')}
      </Button>
      <div className={styles.tileContainer}>
        {phoneNumbers.map((pn) => (
          <PhoneTile
            key={`phone-tile-${pn.phone_number}`}
            disabled={!hasPermission && !pn.rule_id}
            name={pn.label}
            phoneNumber={pn.phone_number}
            onSelect={() => onNumberSelect(pn)}
          />
        ))}
      </div>
    </Modal>
  );
};

export default SelectNumberSubModal;
