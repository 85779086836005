import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import { BUNDLE_CREATE } from '@/components/organisms/Modals/ModalConductor';
import { TileSkeleton } from '@/components/organisms/Tile';
import AddNew from '@/components/organisms/Tile/AddNew';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import TileBundle from '@/modules/bundles/components/TileBundle/TileBundle';
import { useBundles } from '@/modules/bundles/hooks/useBundles';
import TileRuleList from '@/modules/rules/components/TileRuleList';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import CreateBundleTile from './CreateBundleTile';

import styles from './Bundles.module.scss';

const Bundles = () => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const { deskId: desk_Id } = useParams();
  const slug = useSelector(selectAccountSlug);
  const dispatch = useDispatch();
  useTitle(t('pages.bundles', { 0: slug }));
  const { bundles } = useBundles(desk_Id || deskId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'bundles', actions.WRITE)
  );

  useEffect(() => {
    pageView(PageName.BUNDLES);
  }, []);

  const showCreateBundleModal = useCallback(() => {
    dispatch(pushModal(BUNDLE_CREATE));
  }, [dispatch]);

  function renderTiles() {
    return bundles?.length > 0 ? (
      bundles?.map((bundle) => (
        <TileBundle
          bundle={bundle}
          slug={slug}
          key={bundle.bundle_id}
          deskId={desk_Id || deskId}
        />
      ))
    ) : (
      <CreateBundleTile onClick={showCreateBundleModal} readOnly={!canWrite} />
    );
  }

  function renderSkeletons(n = 3) {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => <TileSkeleton key={i} />)
    );
  }

  return (
    <PageContentWrapper
      fullHeight={canWrite}
      newPlain3={!canWrite}
      readOnly={!canWrite}
    >
      <div className={styles.container}>
        <ContextualHelp
          title={t('bundles.help_title')}
          name="rules-help"
          size="full"
        >
          {t('bundles.help_body')}
        </ContextualHelp>
        <TileRuleList>
          {!bundles && renderSkeletons(3)}
          {bundles && renderTiles()}
        </TileRuleList>
        {bundles?.length > 0 && canWrite && (
          <AddNew
            handleClick={showCreateBundleModal}
            text={t('bundles.add_context_bundle')}
          />
        )}
      </div>
    </PageContentWrapper>
  );
};

export default Bundles;
