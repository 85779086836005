/* eslint-disable no-prototype-builtins */
import { useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '@/models/action';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { setClipboard } from '@/redux/clipBoard/actions';
import { clipboardSelector } from '@/redux/clipBoard/selectors';
import {
  pasteAction,
  pasteCondition,
  pasteNode,
  pasteRequisite,
} from '@/redux/nodes/actions';
import { LAST_ACTIONS } from '@/util/constants';
import {
  CopyPasteController as CP,
  CopiedObject,
} from '@/util/CopyPasteController';

export const useCopyPaste = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const copiedObjectRedux = useSelector(clipboardSelector);
  const copyToClipboard = (obj) =>
    navigator.clipboard.writeText(JSON.stringify(obj));

  const readFromClipboard = useCallback(async () => {
    try {
      const text = await navigator.clipboard.readText();

      if (text) {
        return JSON.parse(text);
      }
      return null;
    } catch (error) {
      return null;
    }
  }, []);

  const handleSuccessfulTemporalToast = useCallback(
    (type: string, actionType: string, menuOption: 'copied' | 'pasted') => {
      switch (type) {
        case 'node':
          dispatch(
            addTemporalToast(
              'success',
              t(`temporal_toast.${menuOption}`, {
                0: t('common.node'),
              })
            )
          );
          break;
        case 'action':
          dispatch(
            addTemporalToast(
              'success',
              t(`temporal_toast.${menuOption}`, {
                0: t(`actions.types.${actionType}`),
              })
            )
          );
          break;
        case 'condition':
          dispatch(
            addTemporalToast(
              'success',
              t(`temporal_toast.${menuOption}`, {
                0: t('actions.types.condition'),
              })
            )
          );
          break;
        case 'requisite':
          dispatch(
            addTemporalToast(
              'success',
              t(`temporal_toast.${menuOption}`, {
                0: t('actions.types.question'),
              })
            )
          );
          break;
        case 'unknown':
          break;
      }
    },
    [dispatch, t]
  );

  const handleCopy = useCallback(
    (obj) => {
      const objType = CP.getObjectType(obj as CopiedObject);

      if (obj.hasOwnProperty('type') && LAST_ACTIONS.includes(obj.type)) {
        dispatch(
          addTemporalToast(
            'error',
            t('temporal_toast.cannot_be_copied', {
              0: t(`actions.types.${obj.type}`),
            })
          )
        );
        return;
      }

      let modifiedObj = CP.addKey(obj, 'moveo-signature', 'moveo');
      // Remove 'parent_id' and 'intent' keys if object type is 'node'
      if (objType === 'node') {
        modifiedObj = ['parent_id', 'intent'].reduce(CP.removeKey, modifiedObj);
      }

      const isClipboardAPIAvailable =
        navigator.clipboard && navigator.clipboard.readText;

      // Copy the modified object to the clipboard
      copyToClipboard(modifiedObj);

      const actionType = (modifiedObj as Action).type;
      handleSuccessfulTemporalToast(objType, actionType, 'copied');

      // If Clipboard API is not supported, store the object in redux as a fallback
      if (!isClipboardAPIAvailable) {
        dispatch(setClipboard(modifiedObj));
      }
    },
    [dispatch, handleSuccessfulTemporalToast, t]
  );

  const hanndlePasteAction = useCallback(
    (type: string, updatedPasteData: CopiedObject) => {
      // Block pasting of actions that are not allowed
      const blockPaste = LAST_ACTIONS.includes(type);

      if (blockPaste) {
        dispatch(
          addTemporalToast(
            'error',
            t('temporal_toast.cannot_be_pasted', {
              0: t(`actions.types.${type}`),
            })
          )
        );

        return;
      }

      dispatch(pasteAction({ newAction: updatedPasteData }));
      handleSuccessfulTemporalToast('action', type, 'pasted');
    },
    [dispatch, handleSuccessfulTemporalToast, t]
  );

  const handlePaste = useCallback(async () => {
    try {
      let clipboardData = await readFromClipboard();
      let pasteData;

      if (!clipboardData && isEmpty(copiedObjectRedux)) {
        return;
      }

      if (clipboardData) {
        const isMoveoObject = CP.checkSignature(clipboardData);
        if (isMoveoObject) {
          clipboardData = CP.removeKey(clipboardData, 'moveo-signature');
          pasteData = clipboardData;
        }
      }

      if (!pasteData && !isEmpty(copiedObjectRedux)) {
        pasteData = copiedObjectRedux;
      }

      if (!pasteData) {
        return;
      }

      const updatedPasteData = CP.updateIds(pasteData as CopiedObject);
      const category = CP.getObjectType(updatedPasteData as CopiedObject);

      switch (category) {
        case 'node':
          dispatch(pasteNode({ node: updatedPasteData }));
          handleSuccessfulTemporalToast(category, null, 'pasted');
          break;
        case 'action':
          hanndlePasteAction(clipboardData.type, updatedPasteData);
          break;
        case 'condition':
          dispatch(pasteCondition({ condition: updatedPasteData }));
          handleSuccessfulTemporalToast(category, null, 'pasted');
          break;
        case 'requisite':
          dispatch(pasteRequisite({ requisite: updatedPasteData }));
          handleSuccessfulTemporalToast(category, null, 'pasted');
          break;
        case 'unknown':
          break;
      }
    } catch (error) {
      dispatch(addErrorTemporalToast(error));
    }
  }, [
    readFromClipboard,
    copiedObjectRedux,
    dispatch,
    handleSuccessfulTemporalToast,
    hanndlePasteAction,
  ]);

  return { handlePaste, handleCopy };
};
