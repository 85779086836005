import { useEffect } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useParams } from 'react-router';

import useDesks from '@/hooks/useDesks';
import usePrompt from '@/hooks/usePrompt';
import { WhatsAppIntegration } from '@/models/integration';
import { Broadcast } from '@/modules/broadcast/pages/Broadcast';
import { BroadcastLogs } from '@/modules/broadcast/pages/BroadcastLogs';
import { setBroadcastId } from '@/redux/session/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

import { useBroadcastPage } from '../hooks/useBroadcastPage';
import { useBroadcasts } from '../hooks/useBroadcasts';
import { BroadcastAudience } from '../pages/BroadcastAudience';
import { actions } from '../redux/actions';
import { selectIsDirty } from '../redux/selectors';

export const BroadcastRouter: React.FC = () => {
  const { broadcastId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { broadcast } = useBroadcasts(broadcastId);
  const { getAllIntegrations } = useDesks();
  const isDirty = useSelector(selectIsDirty);
  const { formMethods, onSubmit, isLoading, saveButtonLoading } =
    useBroadcastPage();

  const slug = useSelector(selectAccountSlug);

  useEffect(() => {
    if (broadcastId) {
      dispatch(setBroadcastId(broadcastId));
    }
    return () => {
      dispatch(setBroadcastId(null));
    };
  }, [dispatch, broadcastId]);

  useEffect(() => {
    if (broadcast) {
      dispatch(actions.setBroadcast(broadcast));
    }
  }, [broadcast, dispatch]);

  useEffect(() => {
    const broadcastChannel = broadcast?.channel;
    getAllIntegrations(broadcastChannel).then(
      (waIntegrations: WhatsAppIntegration[]) => {
        const integration = waIntegrations.find(
          (i) => i.integration_id === broadcast.integration_id
        );
        if (integration) {
          dispatch(actions.updateDeskId(integration.desk_id));
        }
      }
    );
  }, [
    broadcast?.channel,
    broadcast?.integration_id,
    dispatch,
    getAllIntegrations,
  ]);

  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);

  usePrompt(
    isDirty,
    t('common.header_prompt'),
    undefined,
    () => onSubmit(undefined, 0),
    [
      `/${slug}/broadcasts/${broadcastId}`,
      `/${slug}/broadcasts/${broadcastId}/audience`,
    ]
  );

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={130}
        height={14}
      />
    );
  }
  return (
    <FormProvider {...formMethods}>
      <Routes>
        <Route
          path="/"
          element={
            <Broadcast
              onSubmit={onSubmit}
              saveButtonLoading={saveButtonLoading}
            />
          }
        />
        <Route path="/logs" element={<BroadcastLogs />} />
        <Route path="/audience" element={<BroadcastAudience />} />
      </Routes>
    </FormProvider>
  );
};
