const SurveyPreview = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="48"
      fill="none"
      viewBox="0 0 59 48"
    >
      <rect width="59" height="48" fill="#8FADDA" rx="4" />
      <g filter="url(#a)">
        <rect width="34" height="34" x="13" y="7" fill="#fff" rx="8" />
        <path
          fill="#EFEFEF"
          stroke="#EFEFEF"
          strokeWidth=".2"
          d="m22.2 24 2.08-2H19a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H27l-4.8 2Z"
        />
        <path
          fill="#1C46B7"
          d="m21 15.5-.4 1.333H19l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6L21 15.5ZM25.5 15.5l-.4 1.333h-1.6l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6l-.4-1.333ZM30 15.5l-.4 1.333H28l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6L30 15.5ZM34.5 15.5l-.4 1.333h-1.6l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6l-.4-1.333ZM39 15.5l-.4 1.333H37l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6L39 15.5Z"
        />
        <path
          fill="#EFEFEF"
          stroke="#EFEFEF"
          strokeWidth=".2"
          d="m37.8 37-2.08-2H41a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H19a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h14l4.8 2Z"
        />
        <path
          fill="#F5BD42"
          d="m21 28.5-.4 1.333H19l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6L21 28.5ZM25.5 28.5l-.4 1.333h-1.6l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6l-.4-1.333ZM30 28.5l-.4 1.333H28l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6L30 28.5Z"
        />
        <path
          fill="#D9D9D9"
          d="m34.5 28.5-.4 1.333h-1.6l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6l-.4-1.333ZM39 28.5l-.4 1.333H37l1.2.89-.4 1.777 1.2-.889 1.2.889-.4-1.778 1.2-.889h-1.6L39 28.5Z"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="36"
          height="38"
          x="12"
          y="6"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1021_46150"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend
            in2="effect1_dropShadow_1021_46150"
            result="effect2_dropShadow_1021_46150"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1021_46150"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SurveyPreview;
