const WaveLeft = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425 600">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M424.485 0c-17.091 24.762-33.379 51.085-41.721 87.081-12.598 54.361 2.704 100.962 17.506 146.039 17.206 52.395 33.735 102.731 4.986 160.803-28.28 57.121-54.866 90.418-108.509 120.692-31.678 17.877-138.953 45.051-262.149 76.257-11.412 2.891-22.96 5.816-34.598 8.772V0h424.485Z"
        fill="#1B66D6"
        fillOpacity=".05"
      />
    </svg>
  );
};

export default WaveLeft;
