import { useEffect, useState } from 'react';

import { markdownToHtml } from '@/components/organisms/RichTextEditor/utils';

export const useMarkdownToHtml = (markdownText: string) => {
  const [html, setHtml] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const convertMarkdownToHtml = async () => {
      try {
        const result = await markdownToHtml(markdownText);

        setHtml(String(result));
      } catch (err) {
        setError(err as Error);
      }
    };

    convertMarkdownToHtml();
  }, [markdownText]);

  return { html, error };
};
