import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

export const customInputValueRender = (value: unknown): string => {
  if (value === null) return '';

  if (isObject(value)) {
    if (isEmpty(value)) {
      if (Array.isArray(value)) {
        return '[]';
      }
      if (value !== null) {
        return '{}';
      }
    }
  }

  return value.toString();
};
