import { useEffect } from 'react';

import isHotkey from 'is-hotkey';

type Props = {
  hotkeys: { [key: string]: () => void };
  active: boolean;
};

const useHotkeys = ({ hotkeys, active }: Props) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the event target is an input, textarea, or a contentEditable field
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement ||
        (event.target instanceof HTMLElement &&
          event.target.getAttribute('contentEditable') === 'true')
      ) {
        return;
      }

      Object.keys(hotkeys).forEach((hotkey) => {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          hotkeys[hotkey]();
        }
      });
    };

    if (active) {
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [hotkeys, active]);
};

export default useHotkeys;
