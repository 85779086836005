import { Icon } from '@/models/icon';

const Replace = ({ size = 16, color = 'var(--icon-default-gray)' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
      width={size}
      height={size}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill={color}
          fillRule="evenodd"
          d="m7.022 3.99-.64 3.612-.65-1.086c-1.398.623-2.183 1.651-2.3 3.232-1.148-2.228-.45-4.225 1.005-5.396l-.663-1.107 3.248.746Zm2.595-2.017h4.715c.184 0 .351.084.472.218a.78.78 0 0 1 .196.523v4.068a.782.782 0 0 1-.238.565.629.629 0 0 1-.43.175H9.617a.636.636 0 0 1-.472-.217.78.78 0 0 1-.196-.523V2.714c0-.202.075-.387.196-.522l.001-.001a.631.631 0 0 1 .47-.218Zm-7.95 8.453h4.716c.184 0 .351.083.472.217a.78.78 0 0 1 .196.523v4.068a.782.782 0 0 1-.238.565.63.63 0 0 1-.43.176H1.668a.636.636 0 0 1-.472-.218.783.783 0 0 1-.196-.524v-4.068a.782.782 0 0 1 .24-.566.623.623 0 0 1 .428-.173Zm4.488.99h-4.26v3.565h4.262v-3.564h-.002Zm7.95-8.45h-4.26V6.53h4.262V2.966h-.001ZM9.09 14.808l.64-3.611.65 1.086c1.399-.624 2.184-1.65 2.301-3.231 1.149 2.228.452 4.227-1.005 5.396l.663 1.107-3.249-.747Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M1 1.973h14v14H1z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Replace;
