import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import Input from '@/components/atoms/Input/Input';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  WhatsAppIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { errorMessage, whatsappIntegrationConfig } from '@/util/validator';

import styles from './WhatsappConnect.module.scss';

type Form = {
  access_token: string;
  verify_token: string;
  app_secret: string;
  phone_number_id: string;
  prefilled_message: string;
  include_agent_name: boolean;
};

const WhatsappConnect = ({
  integration,
  type,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, WhatsAppIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();
  const { updateIntegration, updateStatus } =
    useIntegrations<WhatsAppIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );
  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });

  const {
    register,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    trigger,
  } = formMethods;

  useEffect(() => {
    reset({
      access_token: integration?.config?.access_token || '',
      verify_token: integration?.config?.verify_token || '',
      app_secret: integration?.config?.app_secret || '',
      phone_number_id: integration?.config?.phone_number_id || '',
    });
  }, [
    integration?.config?.access_token,
    integration?.config?.app_secret,
    integration?.config?.phone_number_id,
    integration?.config?.verify_token,
    reset,
  ]);

  const handleUpdateIntegration = useCallback(
    (values: Form) => {
      updateIntegration(
        {
          ...integration,
          config: {
            ...integration.config,
            ...values,
          },
        },
        {
          onSuccess: () => {
            if (expanded === type) {
              dispatch(setExpanded(false));
            }
          },
        }
      );
    },
    [dispatch, expanded, integration, type, updateIntegration]
  );

  usePrompt(
    isDirty,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  // for reseting changes
  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  return (
    <>
      <Accordion
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
        title={t('integrations.whatsapp.con.title')}
        subtitle={t('integrations.whatsapp.con.subtitle')}
        footerText={t('integrations.whatsapp.con.footer_text')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
      >
        <TitleSubtitle
          title={t('integrations.whatsapp.con.acc_token.title')}
          subtitle={t('integrations.whatsapp.con.acc_token.subtitle')}
        />
        <div className={styles.textArea}>
          <Input
            id="access_token"
            name="access_token"
            placeholder={t('integrations.whatsapp.con.acc_token.pholder')}
            size="large"
            readOnly={!canWrite}
            register={register(
              'access_token',
              whatsappIntegrationConfig.access_token
            )}
            trimValue
            errorMessage={errorMessage({
              field: errors.access_token,
              maxLength: whatsappIntegrationConfig.access_token.maxLength,
            })}
          />
        </div>

        <TitleSubtitle
          title={t('integrations.whatsapp.con.ver_token.title')}
          subtitle={t('integrations.whatsapp.con.ver_token.subtitle')}
        />
        <div className={styles.textArea}>
          <Input
            id="verify_token"
            name="verify_token"
            placeholder={t('integrations.whatsapp.con.ver_token.pholder')}
            size="large"
            readOnly={!canWrite}
            register={register(
              'verify_token',
              whatsappIntegrationConfig.verify_token
            )}
            trimValue
            errorMessage={errorMessage({
              field: errors.verify_token,
              maxLength: whatsappIntegrationConfig.verify_token.maxLength,
            })}
          />
        </div>

        <TitleSubtitle
          title={t('integrations.whatsapp.con.app_secr.title')}
          subtitle={t('integrations.whatsapp.con.app_secr.subtitle')}
        />
        <div className={styles.textArea}>
          <Input
            id="app_secret"
            name="app_secret"
            placeholder={t('integrations.whatsapp.con.app_secr.pholder')}
            size="large"
            readOnly={!canWrite}
            register={register(
              'app_secret',
              whatsappIntegrationConfig.app_secret
            )}
            trimValue
            errorMessage={errorMessage({
              field: errors.app_secret,
              maxLength: whatsappIntegrationConfig.app_secret.maxLength,
            })}
          />
        </div>

        <TitleSubtitle
          title={t('integrations.whatsapp.con.phone_id.title')}
          subtitle={t('integrations.whatsapp.con.phone_id.subtitle')}
        />
        <div className={styles.textArea}>
          <Input
            id="phone_number_id"
            name="phone_number_id"
            placeholder={t('integrations.whatsapp.con.phone_id.pholder')}
            size="large"
            readOnly={!canWrite}
            register={register(
              'phone_number_id',
              whatsappIntegrationConfig.phone_number_id
            )}
            trimValue
            errorMessage={errorMessage({
              field: errors.phone_number_id,
              maxLength: whatsappIntegrationConfig.phone_number_id.maxLength,
            })}
          />
        </div>
      </Accordion>
    </>
  );
};

export default WhatsappConnect;
