import { Icon } from '@/models/icon';

const Star = ({
  size = 24,
  color = 'var(--icon-default-blue)',
  fill = 'none',
}) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke={color}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12.443 4.083 2.06 4.174 4.607.673-3.333 3.247.786 4.586-4.12-2.166-4.12 2.166.787-4.586L5.777 8.93l4.606-.673 2.06-4.174Z" />
    </svg>
  );
};

export default Star;

export const MenuStar = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke={color}
      d="m8 2 1.854 3.95L14 6.587 11 9.66l.708 4.34L8 11.95 4.29 14 5 9.66 2 6.587l4.146-.637L8 2Z"
    />
  </svg>
);
