import { ChangeEvent, memo } from 'react';

import fs from '@/components/atoms/Fieldset';

interface Props {
  label?: string | React.ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked: boolean;
  sameLevel?: boolean;
  size?: 'medium' | 'small';
  disabled?: boolean;
}

const Checkbox = memo(
  ({ label, onChange, checked, sameLevel, size, disabled }: Props) => {
    return (
      <fs.Checkbox
        onChange={onChange}
        checked={!!checked}
        label={label}
        sameLevel={sameLevel}
        size={size}
        disabled={disabled}
      />
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
