import { shallowEqual, useSelector } from 'react-redux';

import Placeholder from '@/components/organisms/Dialogs/Node/Placeholder/Placeholder';
import { RootState } from '@/models/state';
import { TYPES } from '@/redux/dialogs/helper';
import { selectConditionsByNodeIdLength } from '@/redux/dialogs/selectors';

function ConditionPlaceholder(props) {
  const conditionsLength = useSelector(
    (state: RootState) => selectConditionsByNodeIdLength(state, props.parentId),
    shallowEqual
  );

  return (
    <Placeholder
      {...props}
      accept={TYPES.CONDITION}
      conditionsLength={conditionsLength}
    />
  );
}

export default ConditionPlaceholder;
