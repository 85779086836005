import { useCallback, useEffect, useState } from 'react';

import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Switch from '@/components/atoms/Switch/Switch';
import { useIntegrations } from '@/hooks/useIntegrations';
import { Integration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import { FieldsetProps } from '../../Fieldset';

import styles from './styles.module.scss';

type Form = {
  active: boolean;
};
interface Props extends FieldsetProps {
  integration: Integration;
}

const ActiveFieldset = ({ integration, ...fieldsetProps }: Props) => {
  const { t } = useTranslation();
  const integration_id = integration?.integration_id;
  const desk_id = integration?.desk_id;
  const [lastActive, setLastActive] = useState(integration?.last_session_at);
  const [integrationActive, setIntegrationActive] = useState(
    integration?.active
  );

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  useEffect(() => {
    setLastActive(integration?.last_session_at);
  }, [integration?.last_session_at]);

  useEffect(() => {
    setIntegrationActive(integration?.active);
  }, [integration?.active]);

  const { control, watch, reset, handleSubmit } = useForm<Form>();

  const active = watch().active;

  const { updateIntegration, updateStatus } = useIntegrations(
    desk_id,
    integration_id
  );

  useEffect(() => {
    if (integration_id) {
      reset({ active: integration?.active });
    }
  }, [integration?.active, integration_id, reset]);

  const onSubmit = useCallback(
    (form) => {
      updateIntegration({
        ...integration,
        config: {
          ...integration?.config,
        },
        ...form,
      });
    },
    [updateIntegration, integration]
  );
  return (
    <FormFieldset
      title={fieldsetProps.title}
      subtitle={fieldsetProps.subtitle}
      primaryButton={{
        children: t('common.save'),
        disabled: integration?.active === active || !canWrite,
        isLoading: updateStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={!integration}
      helpText={t('integrations.fieldsets.active_fieldset.helpText')}
      hasBadge={true}
      integrationActive={integrationActive}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <Switch
              label={t('integrations.fieldsets.active_fieldset.active')}
              checked={value}
              disabled={!integration || !canWrite}
              size="medium"
              onChange={onChange}
            />
          );
        }}
        name="active"
        control={control}
      />

      {lastActive && (
        <div className={styles.timestamp}>
          <span className={styles.lastSession}>
            {t('integrations.fieldsets.active_fieldset.last_session')}:
          </span>
          <span className={styles.time}>{moment(lastActive).fromNow()}</span>
        </div>
      )}
    </FormFieldset>
  );
};

export default ActiveFieldset;
