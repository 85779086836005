export default function EntityImage() {
  return (
    <svg
      width="181"
      height="210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddd)">
        <g filter="url(#filter1_d)">
          <path
            d="M90.025 58.85v19.14"
            stroke="#CCD4D9"
            strokeWidth="1.312"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M40 16.247A5.247 5.247 0 0145.248 11h90.507A5.247 5.247 0 01141 16.247v23.61H40v-23.61z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141 38.254H40v18.8a5.247 5.247 0 005.247 5.247h41.548v.088c0 1.89 1.546 3.421 3.455 3.421s3.456-1.532 3.456-3.421v-.088h42.047A5.247 5.247 0 00141 57.054v-18.8z"
          fill="#fff"
        />
        <ellipse cx="90.25" cy="62.42" rx="1.728" ry="1.713" fill="#CCD4D9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.673 78.334h42.08A5.247 5.247 0 01141 83.58v25.214H40V83.58a5.247 5.247 0 015.247-5.246h41.575a3.426 3.426 0 016.851 0z"
          fill="#fff"
        />
        <path
          d="M40 107.19h101v18.801a5.247 5.247 0 01-5.247 5.247H45.247A5.247 5.247 0 0140 125.991V107.19z"
          fill="#fff"
        />
        <circle cx="90.248" cy="78.371" r="1.713" fill="#CCD4D9" />
      </g>
      <path
        d="M50 21h77M50 31h46.43M50 42h57M50 53h46.43M49 89h77M49 99h46.43M49 110h57m-57 11h46.43"
        stroke="#E4E9ED"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_ddd"
          x=".649"
          y=".506"
          width="179.702"
          height="209.433"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="39.351" />
          <feGaussianBlur stdDeviation="19.675" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.247" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1.312" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="effect2_dropShadow" result="effect3_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="57.889"
          y="27.369"
          width="85.26"
          height="103.089"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="10.494" dy="10.494" />
          <feGaussianBlur stdDeviation="20.987" />
          <feColorMatrix values="0 0 0 0 0.0156863 0 0 0 0 0.0313726 0 0 0 0 0.0392157 0 0 0 0.16 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
