import { createSelector } from '@reduxjs/toolkit';

import { actions, Module, Permission } from '@/models/permissions';
import { RootState } from '@/models/state';
import { STRIPE_PUBLIC_KEY } from '@/util/constants';

export type Permissions = {
  [key: string]: boolean | string[];
};

type Rba = {
  permission: string;
  resource_id?: string;
};

export const selectPermissions = (state: RootState) =>
  state.permissions.permissions;

export const selectIsUserOwner = (state: RootState) =>
  state.permissions.isUserOwner;

export const allowAccessIfOneResourceIsAvailable = (
  p: Permission,
  rbaCheck: Rba
) => {
  return p.resource_id && !rbaCheck?.resource_id && p.allow;
};

export const getPermissions = createSelector(
  [
    (state: RootState) => state.permissions,
    (_: RootState, module: Module) => module,
    (_: RootState, __: Module, action: actions) => action,
    (_: RootState, __: Module, ___: actions, resource_id?: string) =>
      resource_id,
  ],
  (permissions, module, action, resource_id) => {
    if (module === 'billing' && !STRIPE_PUBLIC_KEY) {
      return false;
    }
    if (permissions?.isUserOwner) {
      return true;
    }

    const rbaCheck = { permission: `${module}.${action}`, resource_id };

    const matches =
      permissions?.permissions?.filter(
        (p) =>
          p.permission === rbaCheck.permission &&
          (p.resource_id === rbaCheck.resource_id ||
            p.resource_id === '*' ||
            allowAccessIfOneResourceIsAvailable(p, rbaCheck))
      ) ?? [];

    if (matches.length === 0) {
      return false;
    }

    const denyMatch = matches.find((m) => !m.allow);

    if (denyMatch) {
      return false;
    }

    return true;
  }
);
