import { TFunction } from 'i18next';
import moment from 'moment';

import { capitalizeFirstLetter } from '@/util/util';
import {
  emailPattern,
  getEditableTextValidationSchema,
  LENGTH_XS,
} from '@/util/validator';

import {
  Broadcast,
  BroadcastActionComponent,
  BroadcastActionComponentType,
  BroadcastComponentParameter,
  BroadcastStatus,
  Form,
  Subscriber,
  WhatsappTemplate,
  WhatsappTemplateComponent,
} from './models';
import { userContextVariables } from '../TryIt/components/VariablesSection/constants';

export const SEND_NOW = 'now';

export const getVariablesLength = (
  component: WhatsappTemplateComponent | BroadcastActionComponent
) => {
  if (!component) {
    return 0;
  }

  if (component?.text) {
    const pattern = /\{\{\d+\}\}/g;
    const matches = component.text.match(pattern);
    const count = matches ? matches.length : 0;
    return count;
  }

  return 0;
};

export const broadcastRules = {
  name: (usedNames?: string[]) => ({
    validate: (value: string) => {
      try {
        getEditableTextValidationSchema(
          LENGTH_XS,
          usedNames,
          'name'
        ).validateSync({
          inputField: value,
        });
        return true;
      } catch (error) {
        return capitalizeFirstLetter(error.message);
      }
    },
  }),
  channel: {
    required: true,
  },
};

export const addPlusSignToPhone = (phone: string) => {
  if (phone[0] !== '+') {
    return `+${phone}`;
  }
  return phone;
};

export const extractContentFromContextVariable = (str: string) => {
  if (!str) {
    return null;
  }
  const regex = /\{\{\$(.*?)\}\}/;
  const match = str.match(regex);
  return match ? match[1] : null;
};

/**
 * Formats options for autocomplete input field based on the provided parameter and component type.
 *
 * @param {BroadcastComponentParameter} parameter - The parameter to format into an option, which may contain text or media attributes.
 * @param {BroadcastActionComponentType} type - The type of the component, used to determine specific formatting logic.
 * @returns {Object | null} - Returns an object representing the option with `value` and `label` keys, or `null` if conditions are not met.
 **/

export const formatOptionsFromComponent = (
  parameter: BroadcastComponentParameter,
  type: BroadcastActionComponentType
) => {
  if (!parameter?.text && type === 'body') {
    return null;
  }

  const { id, link } =
    parameter?.image || parameter?.video || parameter?.document || {};

  const value = parameter?.text || id || link;

  const textWithoutBrackets = extractContentFromContextVariable(value);
  if (textWithoutBrackets) {
    return {
      value,
      label: textWithoutBrackets,
    };
  }
  if (value) {
    return {
      value,
      label: value,
    };
  }

  return null;
};

export const getScheduleTime = (day: string, hour: string): number | string => {
  if (day === SEND_NOW) {
    return SEND_NOW;
  }
  if (day && hour) {
    const resultWithLocal = moment.tz(
      `${day} ${hour}`,
      'YYYY-MM-DD hh:mma',
      moment.tz.guess()
    );
    const resultUtc = moment(resultWithLocal).utc().valueOf();

    return resultUtc;
  } else if (day) {
    return moment.tz(day, moment.tz.guess()).startOf('day').utc().valueOf();
  } else if (hour) {
    const today = moment().format('YYYY-MM-DD');
    return moment.tz(`${today} ${hour}`, moment.tz.guess()).utc().valueOf();
  }
  return;
};

const allowedUserFields = userContextVariables.map(
  (variable) => variable.value
);

/**
 * Sets a nested property in an object given an array of keys.
 *
 * @param {Object} obj - The object in which to set the property.
 * @param {string[]} keys - An array of strings representing the keys, in order, for the nested property.
 * @param {*} value - The value to set at the specified nested property.
 */
export const setNestedProperty = (obj, keys, value) => {
  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    } else {
      acc[key] = acc[key] || {};
    }
    return acc[key];
  }, obj);
};

/**
 * Formats file context data by converting rows of data into a structured object format.
 *
 * @param {string[]} firstRow - An array of strings representing the headers/keys for each column in the data.
 * @param {string[][]} data - A 2D array where each sub-array represents a row of data.
 * @returns {Object} An object containing errorCounter and an array of fileSubscribers.
 */
export const formatFileContext = (
  firstRow: string[],
  data: string[][]
): { fileSubscribers: Partial<Subscriber>[] } => {
  const fileSubscribers: Partial<Subscriber>[] = [];

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    let hasEmptyValue = false;
    let hasInvalidEmail = false;
    //TODO can we detect if there is a column named id , to use that ? Other wise use the first
    const id = row[0]?.trim();
    const formattedRow = {
      external_id: id ? addPlusSignToPhone(id) : '',
      metadata: { source: 'file' as const },
      context: { user: {} },
    };

    for (let j = 1; j < firstRow.length; j++) {
      const key = firstRow[j]?.trim();
      const value = row[j]?.trim();
      const keys = key.split('.');
      // Allow user.key if it belongs to user context
      if (allowedUserFields.includes(key)) {
        if (key === 'user.email' && !emailPattern.test(value)) {
          hasInvalidEmail = true;
        }
        formattedRow.context.user[keys[1]] = value;
        //Save user.key in root context if it doesn't belong to user context
      } else if (key.includes('user.')) {
        formattedRow.context[keys[1]] = value;
      } else {
        setNestedProperty(formattedRow.context, keys, value);
      }
      if (!value) {
        hasEmptyValue = true;
      }
    }

    // Remove the user object if it is empty
    if (Object.keys(formattedRow.context.user).length === 0) {
      delete formattedRow.context.user;
    }
    if (
      fileSubscribers.find((s) => s.external_id === formattedRow.external_id)
    ) {
      continue;
    }
    if (hasEmptyValue) {
      fileSubscribers.push({
        ...formattedRow,
        status_code: 'MISSING_VARIABLE',
      });
    } else if (hasInvalidEmail) {
      fileSubscribers.push({
        ...formattedRow,
        status_code: 'INVALID_EMAIL',
      });
    } else {
      fileSubscribers.push(formattedRow);
    }
  }

  return { fileSubscribers };
};
const TWO_MINUTES = 2;
export const isScheduledToBeSentNow = (broadcast: Partial<Broadcast>) => {
  if (!broadcast.broadcast_id || !broadcast?.scheduled_at) {
    return false;
  }

  if (broadcast.status === 'draft') {
    return false;
  }

  const now = moment.utc();
  const date = moment.utc(broadcast.scheduled_at);

  return Math.abs(now.diff(date, 'minutes')) <= TWO_MINUTES;
};

export const calculatePercentage = (numerator, denominator) => {
  if (!denominator || !numerator) {
    return 0;
  }
  return ((numerator / denominator) * 100).toFixed(0);
};

export const showBroadcastBanner = (
  status: BroadcastStatus,
  broadcast: Partial<Broadcast>,
  t: TFunction
) => {
  if (status === 'ready' && broadcast?.scheduled_at) {
    return {
      show: true,
      bannerVariant: 'info',
      bannerTitle: t('broadcasts.banner_scheduled', {
        0: moment(broadcast.scheduled_at).local().format('YYYY-MM-DD h:mma'),
      }),
      bannerSubstitle: t('broadcasts.banner_scheduled_body'),
    };
  }
  if (status === 'sent') {
    return {
      show: true,
      bannerVariant: 'success',
      bannerTitle: t('broadcasts.banner_sent', {
        0: moment(broadcast.updated).local().format('YYYY-MM-DD h:mma'),
      }),
    };
  }
  if (status === 'in_progress') {
    return {
      show: true,
      bannerVariant: 'info',
      bannerTitle: t('broadcasts.banner_in_progress'),
    };
  }
  return {
    show: false,
  };
};

export const flattenKeys = (obj, prefix = '') =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'object' && value !== null) {
      acc.push(...flattenKeys(value, prefixedKey));
    } else {
      acc.push(prefixedKey);
    }
    return acc;
  }, []);

export const getContextVariableOptions = (
  subscribers: Partial<Subscriber>[]
) => {
  if (!subscribers || subscribers.length === 0) {
    return [];
  }
  const allKeys = Array.from(
    new Set(
      subscribers.flatMap((subscriber) => flattenKeys(subscriber.context))
    )
  );
  const options = allKeys.map((key) => ({
    label: key,
    value: `{{$${key}}}`,
    type: 'context_variable',
  }));
  return options;
};

/**
 * etermines if a given component is the HEADER and of type IMAGE | VIDEO | DOCUMENT.
 *
 * @param {BroadcastActionComponent | WhatsappTemplateComponent} c - The component to check.
 * @returns {boolean} - Returns true if the component is a media header (i.e., its type is 'header' and its format is not 'text'), otherwise false.
 *
 */

export const isMediaHeader = (
  c: BroadcastActionComponent | WhatsappTemplateComponent
) => c?.type.toLowerCase() === 'header' && c.format.toLowerCase() !== 'text';

/**
 * Formats the components of a WhatsApp template based on provided form data.
 *
 * @param {WhatsappTemplate} template - The WhatsApp template object containing components to format.
 * @param {Form} formData - The form data containing parameter values for the template components.
 * @returns {BroadcastActionComponent[]} - An array of formatted broadcast action components.
 */

export const formatComponentsFromForm = (
  template: WhatsappTemplate,
  formData: Form
): BroadcastActionComponent[] => {
  const components = template?.components.map((c) => {
    const type = `${c.type?.toLowerCase()}Parameters`;

    const items = formData[type]?.filter((item) => item.name);
    let parameters = [];
    if (!isMediaHeader(c)) {
      parameters = items?.map((param) => ({
        type: 'text',
        text: param?.name?.value || param?.name || '',
      }));
    } else {
      const mediaType = c.format.toLowerCase() as
        | 'image'
        | 'document'
        | 'video';

      const value =
        typeof formData.headerMediaParam.value === 'string'
          ? formData.headerMediaParam.value
          : formData.headerMediaParam.value?.value;

      const parameter = {
        type: mediaType,
        [mediaType]: {
          id: formData.headerMediaParam.type === 'id' ? value : undefined,
          link: formData.headerMediaParam.type === 'link' ? value : undefined,
        },
      };

      parameters.push(parameter);
    }
    const newComponent: BroadcastActionComponent = {
      ...c,
      type: c.type.toLowerCase() as BroadcastActionComponentType,
      parameters,
    };
    return newComponent;
  });
  return components;
};

export const getComponentsFromTemplate = (template: WhatsappTemplate) => {
  const header = template?.components?.find(
    (component) => component.type === 'HEADER'
  );
  const body = template?.components?.find(
    (component) => component.type === 'BODY'
  );
  const footer = template?.components?.find(
    (component) => component.type === 'FOOTER'
  );

  const buttons = template?.components?.find(
    (component) => component.type === 'BUTTONS'
  )?.buttons;

  return { header, body, footer, buttons };
};

/**
 *
 * This function replaces all occurrences of placeholders in the format `{{number}}`
 * with incremented numbers starting from `startIndex`.
 *
 * @param startIndex - The initial index to start numbering from.
 * @param  text - The text containing placeholders to update.
 */

export const startVariablesFromIndex = (startIndex: number, text: string) => {
  let currentIndex = startIndex;

  const updatedText = text.replace(
    /\{\{\d+\}\}/g,
    () => `{{${++currentIndex}}}`
  );

  return updatedText;
};

export const filterMessageWhatsappTemplate = (
  templates: WhatsappTemplate[]
) => {
  return templates?.reduce((acc, template) => {
    const { header, buttons } = getComponentsFromTemplate(template);

    if (
      template.status !== 'APPROVED' ||
      (header && header?.format !== 'TEXT')
    ) {
      return acc;
    }

    const buttonWithParams = buttons?.find((button) => button.example);

    if (buttons && buttonWithParams) {
      return acc;
    }
    return [...acc, template];
  }, [] as WhatsappTemplate[]);
};
