import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Email = forwardRef<SVGSVGElement, Icon>(
  ({ size = 16, color = 'var(--icon-default-gray)', ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="3.75"
          y="4.75"
          width="16.5"
          height="13.5"
          rx="0.75"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M4 5.5L10.9393 12.4393C11.5251 13.0251 12.4749 13.0251 13.0607 12.4393L16 9.5L20 5.5"
          stroke={color}
          strokeWidth="1.5"
        />
      </svg>
    );
  }
);

Email.displayName = 'Email';

export default Email;
