import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
} from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form';

import HelpTooltip from '../HelpTooltip/HelpTooltip';

import styles from './Input.module.scss';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string;
  error?: boolean;
  register?: UseFormRegisterReturn;
  tooltip?: string;
  readOnly?: boolean;
  ellipsis?: boolean;
  errorMessage?: React.ReactNode | string;
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xlarge';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  isLoading?: boolean;
  trimValue?: boolean;
  lowercaseLabel?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error = false,
      label,
      name = 'field',
      required = false,
      tooltip = '',
      type = 'shown',
      placeholder,
      onChange = () => {},
      id = `${name}-input`,
      register,
      errorMessage,
      onBlur,
      onClick,
      iconLeft,
      iconRight,
      size = 'medium',
      readOnly,
      ellipsis,
      isLoading,
      trimValue = false,
      lowercaseLabel = false,
      ...props
    },
    ref
  ) => {
    const hasError = error || !!errorMessage;
    return (
      <div
        className={cn(styles.input, styles[size], {
          [styles.withLabel]: label,
          [styles.disabled]: props.disabled,
        })}
      >
        {label && (
          <label
            className={cn({
              [styles.input__label]: true,
              [styles.input__label__disabled]: props.disabled,
              [styles.input__label__lowercase]: lowercaseLabel,
            })}
            htmlFor={id}
          >
            {label}
            {required && '*'}
            <HelpTooltip tooltip={tooltip} />
          </label>
        )}
        {iconLeft && (
          <div className={cn(styles.icon_left, styles[size])}>{iconLeft}</div>
        )}
        {!isLoading ? (
          <input
            type={type || 'text'}
            name={name}
            id={id}
            required={required}
            ref={ref}
            autoComplete="off"
            placeholder={placeholder}
            size={200}
            readOnly={readOnly}
            className={cn({
              [styles.input__input]: true,
              [styles.input__readOnly]: readOnly,
              [styles.input__readOnly__ellipsis]: readOnly && ellipsis,
              [styles.input__input__error]: hasError,
              [`input__${type}`]: true,
              [styles.input__input__icon_left]: !!iconLeft,
              [styles.input__input__icon_right]: !!iconRight,
              [styles[size]]: size,
              [styles.withLabel]: label,
            })}
            onClick={onClick}
            {...props}
            {...register}
            onInput={(event: ChangeEvent<HTMLInputElement>) => {
              if (
                props.maxLength &&
                event.target.value.length >= props.maxLength
              ) {
                event.target.value = event.target.value.slice(
                  0,
                  props.maxLength
                );
              }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              register?.onChange(event);
              if (onChange) {
                onChange(event);
              }
            }}
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              if (trimValue) {
                event.target.value = event.target.value?.trim();
              }
              register?.onBlur(event);
              if (onBlur) {
                onBlur(event);
              }
            }}
          />
        ) : (
          <Skeleton variant="text" height={40} />
        )}
        {iconRight && (
          <div className={cn(styles.icon_right, styles[size])}>{iconRight}</div>
        )}
        {errorMessage && (
          <p className={styles.input__input__error__message}>{errorMessage}</p>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
