import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { useForm, Controller, Resolver } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Node as SlateNode, Node } from 'slate';
import { AnyObjectSchema } from 'yup';

import CommandPalette from '@/components/organisms/CommandPalette/CommandPalette';
import { useMarkdownToSlate } from '@/components/organisms/RichTextEditor/hooks/useMarkdownToSlate';
import OptionsPopper, {
  OptionsPopperProps,
} from '@/components/organisms/RichTextEditor/OptionsPopper/OptionsPopper';
import RichTextEditor from '@/components/organisms/RichTextEditor/RichTextEditor';
import {
  addStringToEditor,
  findRichTextEditorErrorMessage,
  NestedObject,
  richTextToMarkdown,
} from '@/components/organisms/RichTextEditor/utils';
import { useCommandPallete } from '@/hooks/useCommandPallete';
import { useRichTextEditor } from '@/hooks/useRichTextEditor';
import { OptionBase } from '@/models/common';
import { Requisite } from '@/models/node';
import { updateRequisite } from '@/redux/nodes/actions';
import { capitalizeFirstLetter } from '@/util/util';
import { askOnlySchema, LENGTH_XXL } from '@/util/validator';

interface Props {
  requisite: Requisite;
  options: OptionBase[];
  updateErrors: (key: string, error: string | null) => void;
  nodeId: string;
  requisiteIndex: number;
}

type FormType = {
  ask_only?: Partial<SlateNode>[] | string;
};

const QuestionAskOnly = ({
  requisite,
  options,
  updateErrors,
  nodeId,
  requisiteIndex,
}: Props) => {
  const { t } = useTranslation();
  const { editor, triggerKey } = useRichTextEditor();
  const dispatch = useDispatch();
  const {
    searchResults,
    setSearchResults,
    searchString,
    setSearchString,
    noResultSearchLength,
    setNoResultSearchLength,
    lastSearchStringLength,
    setLastSearchStringLength,
  } = useCommandPallete({ options });
  const ifNotPresentToSlate = useMarkdownToSlate(
    requisite?.actions?.[0]?.texts[0]
  );
  const customResolver: (yupSchema: AnyObjectSchema) => Resolver<FormType> =
    (yupSchema) => async (values, context, options) => {
      const v = { ...values };
      if (v?.ask_only) {
        v.ask_only = richTextToMarkdown(values.ask_only as Node[]);
      }

      const resolve = yupResolver(yupSchema);
      return resolve(v, context, options);
    };

  const {
    formState: { errors },
    trigger,
    setValue,
    control,
  } = useForm<FormType>({
    mode: 'onChange',
    resolver: customResolver(askOnlySchema) as Resolver<FormType>,
  });

  useEffect(() => {
    if (ifNotPresentToSlate) {
      setValue('ask_only', ifNotPresentToSlate);
    }
  }, [ifNotPresentToSlate, setValue]);

  const handleRequisiteUpdate = useCallback(
    (v) => {
      const value = richTextToMarkdown(v);
      const updatedRequisite = cloneDeep(requisite);
      set(updatedRequisite, 'actions[0].texts[0]', value);

      dispatch(
        updateRequisite({
          nodeId,
          index: requisiteIndex,
          requisite: updatedRequisite,
        })
      );
    },
    [dispatch, nodeId, requisite, requisiteIndex]
  );

  const handleAddStringToEditor = useCallback(
    (value: number) => {
      addStringToEditor(
        editor,
        `{{${searchResults[value].value}}}`,
        triggerKey + searchString
      );
    },
    [editor, searchResults, searchString, triggerKey]
  );

  // Dialog errors
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors(
      t('dialog.question.ask'),
      findRichTextEditorErrorMessage(errors.ask_only as NestedObject)
    );
  }, [errors.ask_only, t, updateErrors]);

  return (
    <Controller
      name="ask_only"
      control={control}
      render={({ field }) => {
        return (
          field.value && (
            <CommandPalette<OptionsPopperProps>
              triggerKey={triggerKey}
              searchString={searchString}
              setSearchString={setSearchString}
              optionsLength={searchResults.length}
              handleOnSelectFromProps={(value) =>
                handleAddStringToEditor(value)
              }
              renderPalette={(props) => {
                return (
                  <OptionsPopper
                    {...props}
                    options={options}
                    searchResults={searchResults}
                    setSearchResults={setSearchResults}
                    noResultSearchLength={noResultSearchLength}
                    setNoResultSearchLength={setNoResultSearchLength}
                    lastSearchStringLength={lastSearchStringLength}
                    setLastSearchStringLength={setLastSearchStringLength}
                  />
                );
              }}
              renderItem={(props) => (
                <RichTextEditor
                  {...props}
                  editor={editor}
                  label={t('dialog.question.ask')}
                  value={field.value}
                  size="medium"
                  maxCharacters={LENGTH_XXL}
                  options={options}
                  error={
                    findRichTextEditorErrorMessage(
                      errors.ask_only as NestedObject
                    )
                      ? {
                          message: capitalizeFirstLetter(
                            findRichTextEditorErrorMessage(
                              errors.ask_only as NestedObject
                            )
                          ),
                        }
                      : undefined
                  }
                  onChange={(newValue) => {
                    if (newValue !== field.value) {
                      field.onChange(newValue);
                      handleRequisiteUpdate(newValue);

                      // Trigger validation
                      trigger();
                    }
                  }}
                />
              )}
            />
          )
        );
      }}
    />
  );
};

export default QuestionAskOnly;
