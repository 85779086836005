import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import Close from '@/components/atoms/Icons/Close';
import Create from '@/components/atoms/Icons/Create';
import { RootState } from '@/models/state';
import {
  isTriggerError,
  replaceTrigger,
  revertIsTriggerOpen,
} from '@/modules/rules/redux/actions';
import {
  selectRuleTriggers,
  selectTriggerIsOpen,
  selectTriggerOptions,
} from '@/modules/rules/redux/selectors';
import { isKeyEnter, preventClickThrough } from '@/util/util';
import { isValidDelayInMinutes, isValidDelayInSeconds } from '@/util/validator';

import TriggerOptionsModal from './TriggerOptionsModal';
import ConditionsModal from '../ConditionsModal/ConditionsModal';

import styles from './DropdownButton.module.scss';

const muiStyles = {
  root: {
    borderRadius: 'var(--border-radius-base)',
    border: '1px solid var(--border-default-gray)',
    height: '3px',
    paddingLeft: '16px',
    fontfamily: 'var(--font-primary)',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-16)',
    width: '100px',
    '&.Mui-focused': {
      border: '1px solid var(--border-default-blue)', // focus
    },
    '&.Mui-error': {
      border: '1px solid red',
    },
  },
};

export type ButtonProps = {
  condition?: string;
  disabled?: boolean;
  grouped?: boolean;
  addCallbackFn?: () => void;
  buttonCallbackFn?: () => void;
  closeButtonCallbackFn?: () => void;
  labelText?: string;
  index?: number;
  showCloseButton?: boolean;
  triggerType: string;
  delay_in_seconds?: string;
};

const DropdownButton: React.FC<ButtonProps> = forwardRef(
  (
    {
      disabled = false,
      grouped = false,
      closeButtonCallbackFn,
      labelText,
      index,
      showCloseButton = true,
      triggerType,
      delay_in_seconds,
    }: ButtonProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const [showTriggerOptions, setShowTriggerOptions] = useState(false);

    const isTriggerOpen = useSelector((state: RootState) =>
      selectTriggerIsOpen(state, index)
    );
    const DEFAULT_SECONDS = '10';
    const [inputHasError, setInputHasError] = useState(false);
    const [time, setTime] = useState(delay_in_seconds ?? DEFAULT_SECONDS);
    const [variant, setVariant] = useState<'normal' | 'active' | 'error'>(
      'normal'
    );
    const timeRef = useRef<HTMLInputElement>();
    const [timeUnit, setTimeUnit] = useState<string>('seconds');
    const [hasClidkedTimeUnit, setHasClickedTimeUnit] = useState(false);

    const triggers = useSelector(selectRuleTriggers);
    const triggerOptions = useSelector(selectTriggerOptions);
    const dispatch = useDispatch();

    // intersection of triggers and options
    const options = triggerOptions.filter(
      (x) => !triggers.some((t) => x['type'] === t['type'])
    );

    useEffect(() => {
      if (
        triggerType === 'awaiting_agent_reply' ||
        triggerType === 'awaiting_user_reply'
      ) {
        setTime(delay_in_seconds ?? DEFAULT_SECONDS);
      }
    }, [delay_in_seconds, triggerType]);

    useEffect(() => {
      if (
        triggerType === 'awaiting_agent_reply' ||
        triggerType === 'awaiting_user_reply'
      ) {
        if (!delay_in_seconds) {
          setVariant('error');
          dispatch(isTriggerError({ index, errorState: true }));
          return;
        }
        setVariant('normal');
      }
    }, [delay_in_seconds, dispatch, index, triggerType]);

    const handleClose = useCallback(
      (e) => {
        preventClickThrough(e);
        closeButtonCallbackFn();
      },
      [closeButtonCallbackFn]
    );

    const handleButtonClick = useCallback(
      (e) => {
        preventClickThrough(e);
        if (
          triggerType === 'first_message' ||
          triggerType === 'any_user_message'
        ) {
          return;
        }
        dispatch(revertIsTriggerOpen({ index, isOpenState: !isTriggerOpen }));
      },
      [dispatch, index, isTriggerOpen, triggerType]
    );

    const handleAddClick = useCallback(() => {
      setShowTriggerOptions(true);
    }, []);

    const handleKeyStroke = useCallback(
      (e) => {
        if (isKeyEnter(e)) {
          handleAddClick();
        }
      },
      [handleAddClick]
    );

    const handleReplaceOption = useCallback(() => {
      setShowTriggerOptions(false);
    }, []);

    const handleTimeClick = useCallback((data) => {
      setHasClickedTimeUnit(true);
      setTimeUnit(data.value);
    }, []);

    useEffect(() => {
      if (timeUnit) {
        if (timeUnit === 'seconds') {
          isValidDelayInSeconds(time)
            ? setInputHasError(false)
            : setInputHasError(true);
        } else {
          isValidDelayInMinutes(time)
            ? setInputHasError(false)
            : setInputHasError(true);
        }
      }
    }, [time, timeUnit]);

    const handleDelayApply = useCallback(() => {
      const data = {
        index,
        type:
          triggerType === 'awaiting_user_reply'
            ? 'awaiting_user_reply'
            : 'awaiting_agent_reply',
        delay: timeUnit === 'seconds' ? time : `${Number(time) * 60}`,
        isOpen: false,
      };
      dispatch(replaceTrigger(data));
    }, [dispatch, index, time, timeUnit, triggerType]);

    const handleTimeChange = useCallback((e) => {
      setTime(e.target.value);
    }, []);

    const timeDefaultValue = useCallback(() => {
      if (hasClidkedTimeUnit) {
        return timeUnit === 'seconds'
          ? delay_in_seconds
          : `${Number(delay_in_seconds) / 60}`;
      }
      return delay_in_seconds ?? DEFAULT_SECONDS;
    }, [delay_in_seconds, hasClidkedTimeUnit, timeUnit]);

    const renderConfiguration = useCallback(() => {
      if (
        triggerType === 'awaiting_user_reply' ||
        triggerType === 'awaiting_agent_reply'
      ) {
        return (
          <ConditionsModal
            title="for"
            buttonText={t('common.apply')}
            handleSubmit={handleDelayApply}
            ref={ref}
            isDirty={inputHasError}
          >
            <div className={styles.inputWrapper}>
              <InputBase
                type="number"
                inputProps={
                  timeUnit === 'seconds'
                    ? { min: 1, max: 300 }
                    : { min: 1, max: 5 }
                }
                sx={muiStyles.root}
                onChange={handleTimeChange}
                defaultValue={timeDefaultValue()}
                inputRef={timeRef}
                error={inputHasError}
              />
              {inputHasError && (
                <p className={styles.errorMessage}>
                  {timeUnit === 'minutes'
                    ? t('rules.condition.error_seconds')
                    : t('rules.condition.error_minutes')}
                </p>
              )}
              <DropdownList
                optionClick={handleTimeClick}
                options={[
                  { label: t('rules.minutes'), value: 'minutes' },
                  { label: t('rules.seconds'), value: 'seconds' },
                ]}
                size="small"
                selected={hasClidkedTimeUnit ? timeUnit : 'seconds'}
              >
                {hasClidkedTimeUnit ? timeUnit : 'seconds'}
              </DropdownList>
            </div>
          </ConditionsModal>
        );
      }

      return (
        <TriggerOptionsModal
          options={options}
          index={index}
          handleOptionClick={handleReplaceOption}
        />
      );
    }, [
      handleDelayApply,
      handleReplaceOption,
      handleTimeChange,
      handleTimeClick,
      hasClidkedTimeUnit,
      index,
      inputHasError,
      options,
      ref,
      timeDefaultValue,
      timeUnit,
      triggerType,
      t,
    ]);

    const timeConverter = useCallback((seconds: string) => {
      if (Number(seconds) % 60 === 0) {
        return Number(seconds) / 60 === 1
          ? '1 minute'
          : `${Number(seconds) / 60} minutes`;
      }
      return Number(seconds) <= 1 ? '1 second ' : `${seconds} seconds`;
    }, []);

    const buttonText = useCallback(() => {
      if (
        triggerType === 'awaiting_user_reply' ||
        triggerType === 'awaiting_agent_reply'
      ) {
        return (
          <span>
            {t(`rules.${triggerType}`)} for{' '}
            {delay_in_seconds && timeConverter(delay_in_seconds)}
          </span>
        );
      }
      return <span>{t(`rules.${triggerType}`)}</span>;
    }, [delay_in_seconds, t, timeConverter, triggerType]);
    return (
      <>
        <div
          className={cn(styles.wrapper, {
            [styles.grouped]: !grouped,
          })}
          ref={ref}
        >
          <button
            className={cn(styles.button, {
              [styles.error]: variant === 'error' && !isTriggerOpen,
              [styles.active]: variant === 'active',
              [styles.disable]: disabled,
              [styles.grouped]: grouped,
              [styles.noEvents]: showTriggerOptions,
              [styles.noClick]:
                triggerType === 'any_user_message' ||
                triggerType === 'first_message',
            })}
            onClick={handleButtonClick}
            aria-label={labelText}
          >
            {buttonText()}

            <span
              className={cn(styles.closeButton, {
                [styles.disable]: disabled,
                [styles.allEvents]:
                  triggerType === 'any_user_message' ||
                  triggerType === 'first_message',
              })}
              role="button"
              onClick={handleClose}
              onKeyDown={handleClose}
              tabIndex={0}
            >
              {showCloseButton && (
                <Close
                  size={14}
                  color={
                    variant === 'error' && !isTriggerOpen
                      ? 'var(--icon-default-error)'
                      : 'var(--icon-default-blue)'
                  }
                />
              )}
            </span>
          </button>
          {grouped && (
            <span
              className={cn(styles.create, {
                [styles.disable]: disabled,
                [styles.noEvents]: showTriggerOptions,
              })}
              role="button"
              onClick={handleAddClick}
              onKeyDown={handleKeyStroke}
              tabIndex={0}
            >
              <Create
                color={
                  variant === 'normal' || variant === 'active'
                    ? 'var(--icon-default-blue)'
                    : 'var(--icon-disabled-gray)'
                }
              />
            </span>
          )}
          {isTriggerOpen && (
            <ClickAwayListener
              onClickAway={() =>
                dispatch(revertIsTriggerOpen({ index, isOpenState: false }))
              }
            >
              {renderConfiguration()}
            </ClickAwayListener>
          )}
          {showTriggerOptions && (
            <ClickAwayListener onClickAway={() => setShowTriggerOptions(false)}>
              <TriggerOptionsModal
                options={options}
                index={index}
                handleOptionClick={() => setShowTriggerOptions(false)}
              />
            </ClickAwayListener>
          )}
        </div>
      </>
    );
  }
);

DropdownButton.displayName = 'DropdownButton';

export default DropdownButton;
