import fs from '..';
import { FieldsetProps } from '../Fieldset';

const PlainFieldset: React.FC<FieldsetProps> = ({
  title,
  subtitle,
  isLoading,
  fullWidth,
  children,
  overflown,
  addPB,
}) => {
  return (
    <fs.Fieldset
      title={title}
      subtitle={subtitle}
      isLoading={isLoading}
      fullWidth={fullWidth}
      overflown={overflown}
      addPB={addPB}
    >
      {children}
    </fs.Fieldset>
  );
};

export default PlainFieldset;
