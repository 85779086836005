import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

export const InfoIcon = forwardRef<SVGSVGElement, Icon>(
  ({ size = 16, color = 'var(--icon-default-gray)', ...rest }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
        {...rest}
        ref={ref}
      >
        <g clipPath="url(#info-icon-clip)">
          <path
            fill={color}
            fillRule="evenodd"
            d="M8 1.833a6.167 6.167 0 1 0 0 12.334A6.167 6.167 0 0 0 8 1.833ZM.833 8a7.167 7.167 0 1 1 14.334 0A7.167 7.167 0 0 1 .833 8ZM8 7.5a.5.5 0 0 1 .5.5v2.667a.5.5 0 0 1-1 0V8a.5.5 0 0 1 .5-.5Zm0-2.667a.5.5 0 0 0 0 1h.007a.5.5 0 0 0 0-1H8Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="info-icon-clip">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

InfoIcon.displayName = 'InfoIcon';
