const CursorPointerColored = () => {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4_11731)">
        <path
          d="M20.3981 26.7224L20.0354 8.72803L6.35051 20.4181L14.6329 20.7658L20.3981 26.7224Z"
          fill="#7BC5F7"
        />
        <g filter="url(#filter0_d_4_11731)">
          <path
            d="M5.88962 19.8787L19.5744 8.18864C19.7829 8.01051 20.0756 7.96841 20.3259 8.08079C20.5762 8.19312 20.7394 8.43969 20.745 8.71398L21.1076 26.7084C21.1135 27.0001 20.9403 27.2657 20.671 27.3779C20.5827 27.4146 20.4901 27.4325 20.3983 27.4325C20.2097 27.4325 20.0245 27.3572 19.8881 27.2163L16.0173 23.2169L13.7772 28.2083C13.659 28.4715 13.4003 28.6276 13.1293 28.6276C13.0322 28.6276 12.9335 28.6075 12.8392 28.5652L9.99872 27.2905C9.82696 27.2134 9.69293 27.0713 9.62598 26.8954C9.55904 26.7195 9.56472 26.5242 9.64181 26.3525L11.0845 23.1378C11.2451 22.7802 11.665 22.6203 12.0226 22.7809C12.3802 22.9415 12.5401 23.3614 12.3795 23.719L11.2274 26.2862L12.7729 26.9797L14.9544 22.1188L14.3201 21.4635L6.32088 21.1276C6.02942 21.1154 5.77504 20.926 5.67978 20.6502C5.58452 20.3742 5.66774 20.0682 5.88962 19.8787ZM14.1823 20.0368L16.4344 15.0186C16.5949 14.6609 17.0148 14.5013 17.3725 14.6617C17.7301 14.8223 17.8899 15.2422 17.7294 15.5998L15.4772 20.618L19.652 24.9315L19.3559 10.242L8.18466 19.785L14.1823 20.0368Z"
            fill="white"
          />
          <path
            d="M21.1862 6.81753C21.4575 6.81753 21.7165 6.6611 21.8344 6.39751L22.7785 4.28736C22.9386 3.92951 22.7783 3.50974 22.4205 3.34967C22.0626 3.18956 21.6429 3.34987 21.4828 3.70768L20.5387 5.81777C20.3786 6.17563 20.5389 6.5954 20.8968 6.75546C20.9909 6.79747 21.0894 6.81753 21.1862 6.81753Z"
            fill="white"
          />
          <path
            d="M23.4809 9.24387C23.5651 9.24387 23.6506 9.22884 23.734 9.19694L25.8936 8.37248C26.2598 8.23267 26.4433 7.82246 26.3035 7.45629C26.1637 7.09013 25.7535 6.90652 25.3873 7.04643L23.2277 7.87089C22.8614 8.01069 22.678 8.42091 22.8178 8.78707C22.9258 9.06992 23.1952 9.24387 23.4809 9.24387Z"
            fill="white"
          />
          <path
            d="M15.4218 9.01816C15.6932 9.01816 15.9522 8.86173 16.0701 8.59814C16.2302 8.24039 16.07 7.82052 15.7121 7.66045L13.6021 6.71639C13.2444 6.55623 12.8245 6.71659 12.6644 7.0744C12.5043 7.43215 12.6646 7.85202 13.0224 8.01209L15.1324 8.95615C15.2265 8.99825 15.3249 9.01816 15.4218 9.01816Z"
            fill="white"
          />
          <path
            d="M25.4969 13.526C25.7682 13.526 26.0272 13.3696 26.1452 13.1059C26.3053 12.7481 26.145 12.3283 25.7872 12.1682L23.6772 11.2242C23.3194 11.0641 22.8996 11.2244 22.7395 11.5822C22.5794 11.94 22.7397 12.3598 23.0975 12.5199L25.2075 13.4639C25.3016 13.506 25.4 13.526 25.4969 13.526Z"
            fill="white"
          />
          <path
            d="M17.8488 6.72405C17.933 6.72405 18.0185 6.70902 18.1018 6.67712C18.4681 6.53731 18.6515 6.1271 18.5117 5.76093L17.6873 3.60131C17.5473 3.23514 17.1373 3.05154 16.7711 3.19145C16.4049 3.33125 16.2214 3.74147 16.3612 4.10763L17.1857 6.26725C17.2937 6.5501 17.563 6.72405 17.8488 6.72405Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4_11731"
          x="-6.35913"
          y="-8.85547"
          width="44.7095"
          height="49.4829"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4_11731"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4_11731"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4_11731">
          <rect
            width="32.3354"
            height="32"
            fill="white"
            transform="matrix(-1 0 0 1 33.1677 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CursorPointerColored;
