import { useTranslation } from 'react-i18next';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import Input from '@/components/atoms/Input/Input';
import { Desk } from '@/models/desk';

import styles from './styles.module.scss';

interface Props {
  desk?: Desk;
}
const DeskIdFieldset = ({ desk }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.brain_settings_fields}>
      <PlainFieldset
        title={t('desks.environment_id')}
        isLoading={!desk?.desk_id}
      >
        <div className={styles.copy_id}>
          <Input size="large" defaultValue={desk?.desk_id} readOnly />
          <CopyButton className={styles.copy_url} data={desk?.desk_id} />
        </div>
      </PlainFieldset>
    </div>
  );
};

export default DeskIdFieldset;
