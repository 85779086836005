import i18n from 'i18next';

export const defaultStartingText = (language: string) =>
  i18n.t('auto_brain.when_a_user', { lng: language });

export const errorHandler = ({ message }) => {
  const intentConflict: boolean = message
    ?.toLowerCase()
    .includes('Intent conflict while generating new resource'.toLowerCase());

  const notGenerated: boolean = message
    ?.toLowerCase()
    .includes('Generated Empty'.toLowerCase());

  const title: string = intentConflict
    ? i18n.t('auto_brain.flow_exists')
    : notGenerated
      ? i18n.t('auto_brain.generic_error')
      : i18n.t('auto_brain.generic_error');

  const subtitle: string = intentConflict
    ? i18n.t('auto_brain.existing_intent')
    : notGenerated
      ? i18n.t('auto_brain.general_prompt')
      : i18n.t('auto_brain.high_requests');

  return {
    intentConflict,
    notGenerated,
    title,
    subtitle,
  };
};
