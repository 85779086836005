import { useCallback, useEffect, useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Autocomplete, {
  Option,
} from '@/components/atoms/AutoComplete/AutoComplete';
import useIntents from '@/hooks/useIntents';
import { RootState } from '@/models/state';
import { selectIntentByNodeId } from '@/redux/dialogs/selectors';
import { updateNode } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { isRequired } from '@/util/validator';

const clean = (input: Option) => input?.label?.replace('#', '');

type Props = {
  nodeId: string;
};

const Intent = ({ nodeId }: Props) => {
  const brainId = useSelector(selectBrainId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cleanIntents, listStatus } = useIntents(brainId);
  const intent = useSelector(
    (state: RootState) => selectIntentByNodeId(state, nodeId),
    shallowEqual
  );

  const defaultIntent = useMemo(
    () =>
      intent
        ? {
            value: intent,
            label: `#${intent}`,
          }
        : null,
    [intent]
  );

  const {
    trigger,
    control,
    getValues,
    resetField,
    formState: { errors },
  } = useForm<{ intent: Option }>({
    mode: 'onChange',
    defaultValues: { intent: defaultIntent },
  });

  useEffect(() => {
    resetField('intent', { defaultValue: defaultIntent });
  }, [defaultIntent, resetField]);

  const onChange = useCallback(async () => {
    const isValid = await trigger();
    if (isValid) {
      const option = getValues('intent');
      dispatch(
        updateNode({
          nodeId,
          node: {
            intent: clean(option),
          },
        })
      );
    }
  }, [dispatch, getValues, nodeId, trigger]);

  const options: Option[] =
    cleanIntents?.map((i) => ({
      value: i.intent,
      label: `#${i.intent}`,
    })) ?? [];

  if (listStatus === 'pending') {
    return <Skeleton animation="wave" width={100} height={32} />;
  }
  return (
    <Autocomplete
      options={options}
      filterSelectedOptions
      onChange={onChange}
      control={control}
      name="intent"
      size="xs"
      id={`intent-dialog-${nodeId}`}
      disableAddNew
      placeholder={t('intent.select_an_intent')}
      rules={isRequired}
      error={!!errors.intent}
      required
    />
  );
};

export default Intent;
