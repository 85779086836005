/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { forwardRef, ReactNode, useCallback, useEffect } from 'react';

import cn from 'classnames';

import { isKeyEnter, preventClickThrough } from '@/util/util';

import styles from './RuleActionsModal.module.scss';

interface Props {
  children?: ReactNode | string;
  handleSubmit: () => void;
  callbackFn: () => void;
  renderOverElement?: boolean;
  sideComponent?: ReactNode | string;
}

const RuleActionsModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      handleSubmit,
      callbackFn,
      renderOverElement = false,
      sideComponent,
    },
    ref
  ) => {
    const handleKeyPress = useCallback(
      (e) => {
        if (e.key === 'Escape') {
          preventClickThrough(e);
          callbackFn();
        }

        if (isKeyEnter(e)) {
          preventClickThrough(e);
          handleSubmit();
        }
      },
      [callbackFn, handleSubmit]
    );

    useEffect(() => {
      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [handleKeyPress]);

    return (
      <div
        className={cn(styles.wrapper, {
          [styles.renderOverElement]: renderOverElement,
          [styles.withSideComponent]: !!sideComponent,
        })}
        ref={ref}
      >
        <div className={styles.children}>{children}</div>
        {sideComponent && (
          <div className={styles.children}>{sideComponent}</div>
        )}
      </div>
    );
  }
);

RuleActionsModal.displayName = 'RuleActionsModal';

export default RuleActionsModal;
