import { useCallback } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { bundlesEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { Bundle, Bundles } from '@/modules/bundles/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { exportBundle as downloadBundle } from '@/util/file-manager';

import { revertDirty } from '../redux/actions';

export const API = Object.freeze({
  listBundles: async (deskId: string): Promise<Bundles> =>
    callGet(endpoints.bundles(deskId)),

  getBundle: async (deskId: string, bundleId: string): Promise<Bundle> =>
    callGet(endpoints.bundle(deskId, bundleId)),

  addBundle: async (
    deskId: string,
    newBundle: Partial<Bundle>
  ): Promise<Bundle> => callPost(endpoints.bundles(deskId), newBundle),

  updateBundle: async (
    deskId: string,
    bundle: Partial<Bundle>
  ): Promise<Bundle> =>
    callPut(endpoints.bundle(deskId, bundle.bundle_id), bundle),

  deleteBundle: async (deskId: string, bundleId: string): Promise<Bundle> =>
    callDelete(endpoints.bundle(deskId, bundleId)),
});

export const onBundleUpdated = (
  queryClient: QueryClient,
  deskId: string,
  bundle: Bundle
) => {
  queryClient.setQueryData<Bundle>(
    [endpoints.bundle(deskId, bundle.bundle_id)],
    (prev: Bundle) => ({ ...prev, ...bundle })
  );

  const queryKey = [endpoints.bundles(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Bundles>(queryKey, (prev: Bundles) => ({
      bundles: (prev?.bundles || []).map((item) =>
        item.bundle_id === bundle.bundle_id ? { ...item, ...bundle } : item
      ),
    }));
  }
};

export const onBundleCreated = (
  queryClient: QueryClient,
  deskId: string,
  bundle: Bundle
) => {
  const queryKey = [endpoints.bundles(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Bundles>(queryKey, (prev) => ({
      bundles: [
        ...(prev?.bundles || []).filter(
          (item) => item.bundle_id !== bundle.bundle_id
        ),
        bundle,
      ],
    }));
  }
};

export const onBundleRemoved = (
  queryClient: QueryClient,
  deskId: string,
  bundle_id: string
) => {
  const queryKey = [endpoints.bundles(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Bundles>(queryKey, (prev: Bundles) => ({
      bundles: (prev?.bundles || []).filter(
        (acc) => acc.bundle_id !== bundle_id
      ),
    }));
  }
};

export const useBundles = (deskId: string, bundleId?: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: bundles, status: listStatus } = useQuery<Bundles, Error>({
    queryKey: [endpoints.bundles(deskId)],
    queryFn: () => API.listBundles(deskId),
    enabled: !!deskId,
  });

  const {
    data: bundle,
    isSuccess,
    isLoading,
  } = useQuery<Bundle, Error>({
    queryKey: [endpoints.bundle(deskId, bundleId)],
    queryFn: () => API.getBundle(deskId, bundleId),
    enabled: !!bundleId && !!deskId && bundleId !== 'draft',
  });

  const { mutate: updateBundle, status: updateStatus } = useMutation<
    Bundle,
    Error,
    Partial<Bundle>
  >({
    mutationFn: (bundle) => API.updateBundle(deskId, bundle),
    onSuccess: (resp) => {
      dispatch(revertDirty({ dirtyState: false }));
      onBundleUpdated(queryClient, deskId, resp);
      dispatch(
        addTemporalToast('success', t('bundles.updated', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: addBundle, status: createStatus } = useMutation<
    Bundle,
    Error,
    Partial<Bundle>
  >({
    mutationFn: (bundle) => API.addBundle(deskId, bundle),
    onSuccess: (resp) => {
      dispatch(revertDirty({ dirtyState: false }));
      onBundleCreated(queryClient, deskId, resp);
      dispatch(
        addTemporalToast('success', t('bundles.created', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteBundle, status: deleteStatus } = useMutation<
    Bundle,
    Error,
    string
  >({
    mutationFn: (id) => API.deleteBundle(deskId, id),
    onSuccess: (resp) => {
      dispatch(popModal());
      onBundleRemoved(queryClient, deskId, resp.bundle_id);
      dispatch(addTemporalToast('success', t('bundles.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const exportBundle = async (desk_id: string, bundle_id: string) => {
    const eBundle = await callGet(
      `/www/api/v1/desks/${desk_id}/bundles/${bundle_id}`
    );
    downloadBundle(eBundle);
    dispatch(
      addTemporalToast('success', t('bundles.exported', { 0: eBundle.name }))
    );
  };

  const getAllBundlesFromDeskIds = useCallback(async (deskIds: string[]) => {
    const responses = await Promise.all(
      deskIds.map((deskId) => API.listBundles(deskId))
    );

    const allBundles = responses.map((r) => r?.bundles).flat();

    return allBundles;
  }, []);

  return {
    bundles: bundles?.bundles,
    getAllBundlesFromDeskIds: getAllBundlesFromDeskIds,
    bundle,
    isSuccess,
    listStatus,
    updateBundle,
    updateStatus,
    addBundle,
    createStatus,
    deleteBundle,
    deleteStatus,
    exportBundle,
    isLoading,
  };
};
