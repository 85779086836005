import cn from 'classnames';

import styles from './ConditionLine.module.scss';

interface ConditionLineProps {
  direction: string;
  color?: string;
  isShrinked: boolean;
  conditionSize: number;
}
const ConditionLine = ({
  direction,
  color = 'var(--icon-default-blue)',
  isShrinked,
  conditionSize,
}: ConditionLineProps) => {
  if (isShrinked) {
    return null;
  }

  if (direction === 'right' || direction === 'left') {
    return (
      <div
        className={cn(styles.container, {
          [styles.containerRight]: direction === 'right',
          [styles.containerLeft]: direction === 'left',
          [styles['containerRight--small']]:
            conditionSize < 3 && direction === 'right',
          [styles['containerLeft--small']]:
            conditionSize < 3 && direction === 'left',
          [styles.shrinked]: conditionSize < 3 && isShrinked,
        })}
      >
        <div
          className={cn(styles.lineContainer, {
            [styles.right]: direction === 'right',
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="28"
            fill="none"
          >
            <path
              fill={color}
              d="M3.5 1a.5.5 0 0 1 1 0h-1Zm.854 26.354a.5.5 0 0 1-.708 0L.464 24.172a.5.5 0 1 1 .708-.707L4 26.293l2.828-2.828a.5.5 0 1 1 .708.707l-3.182 3.182ZM4.5 1v26h-1V1h1Z"
            />
          </svg>
        </div>
      </div>
    );
  }
  return (
    <div
      className={cn(styles.container, styles.containerCenter, {
        [styles.shrinked]: isShrinked,
      })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="28"
        fill="none"
        viewBox="0 0 8 28"
      >
        <path
          fill={color}
          d="M3.5 1a.5.5 0 0 1 1 0h-1Zm.854 26.354a.5.5 0 0 1-.708 0L.464 24.172a.5.5 0 1 1 .708-.707L4 26.293l2.828-2.828a.5.5 0 1 1 .708.707l-3.182 3.182ZM4.5 1v26h-1V1h1Z"
        />
      </svg>
    </div>
  );
};
export default ConditionLine;
