import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Transition } from 'react-transition-group';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import EnvironmentIcon from '@/components/atoms/Icons/Environment';
import useDesks from '@/hooks/useDesks';
import useUser from '@/hooks/useUser';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';

import { DrawerList } from './Drawer/DrawerList';
import { NavList } from './NavList';
import { useNav } from '../../hooks/useNav';
import { usePrefetchConversations } from '../../hooks/usePrefetchConversations';
import { setView } from '../../redux/actions';
import { selectSideBarOpen, selectView } from '../../redux/selectors';

import drawerStyles from './Drawer/DrawerList.module.scss';
import styles from './Nav.module.scss';

export const Nav = () => {
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const dispatch = useDispatch();
  const { user } = useUser();
  const {
    conversationsMenu,
    filteredTeammatesMenu,
    filteredDepartmentsMenu,
    teammatesMenu,
    departmentsMenu,
    pinnedAgents,
    pinnedDepartments,
  } = useNav();
  const navigate = useNavigate();
  const [showAgents, setShowAgents] = useState(false);
  const [showDepartments, setShowDepartments] = useState(false);
  const sideBarOpen = useSelector(selectSideBarOpen);
  const { serviceDesks } = useDesks();
  const nodeRef = useRef(null);
  const drawerRef = useRef(null);
  const { departments } = useDepartments(deskId);
  const { title } = useSelector(selectView);
  usePrefetchConversations();

  useEffect(() => {
    if (user && title === 'me') {
      dispatch(setView({ selectedAgent: user?.user_id }));
    }
  }, [dispatch, title, user]);

  useEffect(() => {
    if (!sideBarOpen && (showAgents || showDepartments)) {
      setShowAgents(false);
      setShowDepartments(false);
    }
  }, [showAgents, showDepartments, sideBarOpen]);

  const options = serviceDesks?.map((desk) => ({
    label: desk.name,
    value: desk.desk_id,
    icon: <EnvironmentIcon />,
  }));

  const handleDropDownOptionChange = useCallback(
    (item: { value: string }) => {
      navigate(`/${slug}/chats/${item.value}/conversations`);
    },
    [navigate, slug]
  );

  const selectedDesk = useMemo(
    () => serviceDesks?.find((desk) => desk.desk_id === deskId)?.name,
    [deskId, serviceDesks]
  );

  const handleOnBack = useCallback(() => {
    setShowAgents(false);
    setShowDepartments(false);
  }, []);
  const hasOneEnvironment = options?.length <= 1;

  return (
    <>
      <Transition nodeRef={nodeRef} in={sideBarOpen} timeout={150}>
        {(state) => (
          <div
            className={cn(styles.leftMenu, styles[state], {
              [styles.noDepartments]: !departments?.length,
              [styles.noDesks]: !options?.length,
            })}
            ref={nodeRef}
          >
            <div className={styles.dropdown}>
              <DropdownList
                options={options}
                size="xlarge"
                optionClick={handleDropDownOptionChange}
                selected={selectedDesk}
                fullWidth
                optionsFullWidth
                disabled={hasOneEnvironment}
              >
                {deskId ? (
                  <div
                    className={cn(styles.deskLabel, {
                      [styles.isDisabled]: hasOneEnvironment,
                    })}
                  >
                    <EnvironmentIcon />
                    <span>{selectedDesk}</span>
                  </div>
                ) : (
                  <>{t('environments.no_env_yet')}</>
                )}
              </DropdownList>
            </div>
            <NavList
              headerTitle={t('conversation.conversations')}
              menu={conversationsMenu}
              noDesk={!options?.length}
            />
            <NavList
              headerTitle={t('conversation.teammates')}
              menu={filteredTeammatesMenu}
              marginTop
              onEditClick={() => setShowAgents(true)}
              noDesk={!options?.length}
            />
            <NavList
              headerTitle={t('conversation.departments')}
              menu={filteredDepartmentsMenu}
              marginTop
              onEditClick={() => setShowDepartments(true)}
              noDesk={!options?.length}
            />
          </div>
        )}
      </Transition>
      <Transition
        nodeRef={drawerRef}
        in={showAgents || showDepartments}
        timeout={150}
      >
        {(state) => (
          <div
            ref={drawerRef}
            className={cn(drawerStyles.drawer, drawerStyles[state])}
          >
            <DrawerList
              headerTitle={
                showAgents
                  ? t('conversation.teammates')
                  : t('conversation.departments')
              }
              onBackClick={handleOnBack}
              items={showAgents ? teammatesMenu : departmentsMenu}
              pinnedItems={showAgents ? pinnedAgents : pinnedDepartments}
            />
          </div>
        )}
      </Transition>
    </>
  );
};
