import { useTranslation } from 'react-i18next';

import StatusBadge from '../StatusBadge/StatusBadge';

type Props = {
  status: string;
  isLoading: boolean;
};

const StatusChip = ({ status, isLoading }: Props) => {
  const { t } = useTranslation();

  if (isLoading) {
    return null;
  }

  const isVerified = status === 'verified';
  const isVerifying = status === 'verifying';
  return (
    <StatusBadge
      label={
        isVerified
          ? t('integrations.con_status.verified')
          : isVerifying
            ? t('integrations.con_status.verifying')
            : t('integrations.con_status.action_required')
      }
      variant={isVerified ? 'success' : isVerifying ? 'info' : 'warning'}
      withIcon
    />
  );
};

export default StatusChip;
