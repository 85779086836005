import { memo, useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import FolderPlus from '@/components/atoms/Icons/FolderPlus';
import CursorPointer2 from '@/components/atoms/Icons/Other/CursorPointer2';
import InAppHelp from '@/components/atoms/InAppHelp/InAppHelp';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { actions } from '@/models/permissions';
import { Presence } from '@/models/presence';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import { Collections } from './SubNavIntent';
import TreeView from './TreeView/TreeView';

import styles from './SubNav.module.scss';

export type SubnavType = 'dialogs' | 'intents' | 'entities';

interface CollectionProps {
  collection: string;
  new_collection: string;
}

export interface SubNavProps {
  title?: string;
  skeleton?: boolean;
  onCreateClick: () => void;
  onCollectionUpdate: ({
    id,
    collection,
  }: {
    id: string;
    collection: string;
  }) => void;
  onCreateCollection?: ({ id }: { id: string }) => void;
  onCollectionSubmit: (data: CollectionProps) => void;
  menuItems?: { label: string; value: string }[];
  createMenuClick?: (value: string) => void;
  showTooltip?: boolean;
  onTooltipDismiss?: () => void;
  navTitle?: string;
  collections: Collections;
  lastCreatedCollection?: string;
  whoIsViewing?: Presence[];
  subnavType?: SubnavType;
  isCreating?: boolean;
}

const cardStyles = {
  right: '20px',
};
const arrowStyles = {
  left: '16px !important',
};

const SubNav = ({
  title,
  skeleton,
  onCreateClick,
  onCollectionUpdate,
  onCreateCollection,
  onCollectionSubmit,
  menuItems,
  createMenuClick,
  showTooltip,
  onTooltipDismiss,
  navTitle,
  collections,
  lastCreatedCollection,
  whoIsViewing = [],
  subnavType,
  isCreating,
}: SubNavProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [{ isOver }, drop] = useDrop({
    accept: 'treeItem',
    drop: onCreateCollection,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const lowerCasedTitle = title?.toLowerCase();
  const isEmpty =
    Object.keys(collections).length === 1 && !collections._orphans.length;

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const handleCreateClick = useCallback(
    (event) => {
      if (!menuItems) {
        onCreateClick();
      } else {
        setAnchorEl(event.currentTarget);
      }
      if (onTooltipDismiss && showTooltip) {
        onTooltipDismiss();
      }
    },
    [menuItems, onCreateClick, onTooltipDismiss, showTooltip]
  );

  const handleCreateMenuClick = useCallback(
    (value) => {
      setAnchorEl(null);
      createMenuClick(value);
    },
    [createMenuClick]
  );

  return (
    <div className={styles.subnav}>
      <div className={styles.actions}>
        <InAppHelp
          placement="bottom-end"
          darkTheme
          cardStyles={cardStyles}
          arrowStyles={arrowStyles}
          defaultOpen={showTooltip}
          onClose={onTooltipDismiss}
          steps={[
            {
              icon: <CursorPointer2 />,
              body: (
                <Typography variant="body-regular">
                  {t('auto_brain.try_new')}
                </Typography>
              ),
            },
          ]}
        >
          <Button
            isFullWidth
            data-testid="create-item"
            onClick={handleCreateClick}
            disabled={!canWrite}
            isLoading={isCreating}
          >
            {t('subnav.create', { 0: lowerCasedTitle })}
          </Button>
        </InAppHelp>
      </div>

      <div className={styles.content}>
        {subnavType === 'dialogs' && (
          <Box margin="0 var(--space-12)">
            <ContextualHelp
              title={t('dialog.contextual_help.title')}
              name="about_dialogs"
            >
              {t('dialog.contextual_help.message')}
            </ContextualHelp>
          </Box>
        )}

        {subnavType === 'entities' && (
          <Box margin="0 var(--space-12)">
            <ContextualHelp
              title={t('entity.contextual_help.title')}
              name="about_entities"
            >
              {t('entity.contextual_help.message')}
            </ContextualHelp>
          </Box>
        )}

        <TreeView
          skeleton={skeleton}
          onCollectionUpdate={onCollectionUpdate}
          onCollectionSubmit={onCollectionSubmit}
          collections={collections}
          lastCreatedCollection={lastCreatedCollection}
          whoIsViewing={whoIsViewing}
          navTitle={navTitle}
          subnavType={subnavType}
        />
      </div>
      {!isEmpty && (
        <div className={cn(styles.dropBox)} ref={drop}>
          <div className={cn(styles.dropInner, { [styles.dragging]: isOver })}>
            <FolderPlus />
            <Typography variant="label-regular">{t('subnav.drop')}</Typography>
          </div>
        </div>
      )}
      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {menuItems?.map(({ label, value }) => (
          <MenuItem onClick={() => handleCreateMenuClick(value)} key={value}>
            {label}
          </MenuItem>
        ))}
      </CustomMenu>
    </div>
  );
};

export default memo(SubNav);
