import { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import ConnectionStatus from '@/components/atoms/Accordion/templates/ConnectionStatusAccordion';
import ActiveFieldset from '@/components/atoms/Fieldset/templates/IntegrationsFieldset/ActiveFieldset';
import { useAccordion } from '@/hooks/useAccordion';
import {
  AccordionType,
  ZendeskDepartment,
  ZendeskIntegration,
} from '@/models/integration';

import DepartmentsFieldset from '../fieldsets/DepartmentsFieldsets';
import TagsFieldset from '../fieldsets/TagsFieldset';

interface Props {
  isActive: boolean;
  isUpdating: boolean;
  integration: ZendeskIntegration;
  departments?: ZendeskDepartment[];
  suggestedTags?: string[];
  updateIntegration: (integration: ZendeskIntegration) => void;
  isLoadingDepartments: boolean;
  isLoadingTags: boolean;
}

export default function ZendeskSettings({
  integration,
  departments,
  suggestedTags,
  updateIntegration,
  isLoadingDepartments,
  isLoadingTags,
  isUpdating,
}: Props) {
  const { toggleAccordion, registerAccordion } = useAccordion();
  const stateRef = useRef(null);
  const { t } = useTranslation();

  return (
    <div>
      <ConnectionStatus
        status={integration?.status}
        stateRef={stateRef.current}
        toggleAccordion={toggleAccordion}
        type={AccordionType.VERIFICATION_STATUS}
        registerAccordion={registerAccordion}
      />
      <DepartmentsFieldset
        isLoading={isLoadingDepartments}
        departments={departments}
        updateIntegration={updateIntegration}
        integration={integration}
        isUpdating={isUpdating}
      />
      <TagsFieldset
        integration={integration}
        updateIntegration={updateIntegration}
        isLoading={isLoadingTags}
        suggestedTags={suggestedTags}
      />
      <div ref={stateRef}>
        <ActiveFieldset
          integration={integration}
          title={t('integrations.status.title')}
          subtitle={t('integrations.status.subtitle')}
        />
      </div>
    </div>
  );
}
