import { createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import { Broadcast, Subscriber, WhatsappTemplate } from '../models';

const defaultState = {
  broadcast: {
    name: '',
  } as Partial<Broadcast>,
  subscribers: [] as Partial<Subscriber>[],
  templates: [] as WhatsappTemplate[],
  deskId: '',
  isDraft: false,
  isDirty: false,
  isSubscribersDirty: false,
  errorCounter: 0,
};

const slice = createSlice({
  name: 'broadcasts',
  initialState: defaultState,
  reducers: {
    setBroadcast: (state, action: { payload: Broadcast }) => {
      state.broadcast = action.payload;
    },
    setTemplates: (state, action: { payload: WhatsappTemplate[] }) => {
      state.templates = action.payload;
    },
    // Add your actions here
    updateBroadcast: (state, action: { payload: Partial<Broadcast> }) => {
      state.broadcast = { ...state.broadcast, ...action.payload };
      state.isDirty = true;
    },
    updateisDraft: (state, action) => {
      state.isDraft = action.payload;
    },
    addSubscribers: (state, action) => {
      const subs: Partial<Subscriber>[] = [
        ...(action.payload ?? []),
        ...(state?.subscribers ?? []),
      ];
      const uniqueSubscribers = uniqBy(subs, 'external_id');
      state.subscribers = uniqueSubscribers;
      state.isSubscribersDirty = true;
    },
    removeDraftSubscribers: (state) => {
      const filteredSubscribers = state.subscribers.filter(
        (subscriber) => subscriber.created
      );
      state.subscribers = filteredSubscribers;
      state.isSubscribersDirty = true;
    },
    updateDeskId: (state, action) => {
      state.deskId = action.payload;
    },
    revertDirty: (state) => {
      state.isDirty = false;
    },
    addErrorCounter: (state, action) => {
      state.errorCounter += action.payload;
    },
    resetErrorCounter: (state) => {
      state.errorCounter = 0;
    },
    reset: () => defaultState,
  },
});

export const { actions } = slice;

export default slice.reducer;
