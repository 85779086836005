import fs from '..';
import { FieldsetProps } from '../Fieldset';

interface Props extends FieldsetProps {
  avatar?: string;
  name?: string;
  helpText?: React.ReactNode;
  onUpdate: (avatar: { avatar?: string }) => void;
  isUserAvatar?: boolean;
  readOnly?: boolean;
}

const AvatarFieldset: React.FC<Props> = ({
  title,
  subtitle,
  isLoading,
  helpText,
  avatar = '',
  name = '',
  onUpdate,
  isUserAvatar,
  readOnly,
}: Props) => {
  return (
    <fs.Fieldset title={title} subtitle={subtitle} isLoading={isLoading}>
      <fs.Avatar
        onUpdate={onUpdate}
        label="avatar"
        name={name}
        avatar={avatar}
        isUserAvatar={isUserAvatar}
        readOnly={readOnly}
      />
      <fs.Footer>
        {helpText && <fs.FooterStatus>{helpText}</fs.FooterStatus>}
      </fs.Footer>
    </fs.Fieldset>
  );
};

export default AvatarFieldset;
