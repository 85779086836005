import { Icon } from '@/models/icon';

const Department = ({
  size = 100,
  color = 'var(--icon-default-blue)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 101 101"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        strokeWidth="2"
        d="M72.375 88H94.25v-8.593c0-2.68-.85-5.292-2.432-7.474a13.106 13.106 0 0 0-6.394-4.705 13.352 13.352 0 0 0-7.986-.192 13.138 13.138 0 0 0-6.62 4.391M72.374 88h-43.75m43.75 0v-8.593c0-2.819-.551-5.513-1.558-7.98m0 0a21.572 21.572 0 0 0-8.05-9.813A22.166 22.166 0 0 0 50.5 57.919a22.166 22.166 0 0 0-12.267 3.695 21.572 21.572 0 0 0-8.05 9.813M28.625 88H6.75v-8.593c0-2.68.85-5.292 2.432-7.474a13.106 13.106 0 0 1 6.393-4.705 13.352 13.352 0 0 1 7.987-.192 13.138 13.138 0 0 1 6.62 4.391M28.626 88v-8.593c0-2.819.551-5.513 1.558-7.98m33.442-37.202c0 3.418-1.383 6.697-3.844 9.115a13.247 13.247 0 0 1-9.281 3.775c-3.481 0-6.82-1.358-9.28-3.775a12.775 12.775 0 0 1-3.845-9.115c0-3.42 1.383-6.698 3.844-9.115a13.247 13.247 0 0 1 9.281-3.776c3.481 0 6.82 1.358 9.28 3.776a12.775 12.775 0 0 1 3.845 9.115Zm26.25 12.89c0 2.28-.922 4.465-2.563 6.077a8.831 8.831 0 0 1-6.187 2.517 8.831 8.831 0 0 1-6.187-2.517 8.517 8.517 0 0 1-2.563-6.077c0-2.279.922-4.465 2.563-6.077a8.832 8.832 0 0 1 6.187-2.516c2.32 0 4.546.905 6.187 2.517a8.517 8.517 0 0 1 2.563 6.076Zm-61.25 0c0 2.28-.922 4.465-2.563 6.077a8.831 8.831 0 0 1-6.187 2.517 8.831 8.831 0 0 1-6.187-2.517 8.517 8.517 0 0 1-2.563-6.077c0-2.279.922-4.465 2.563-6.077a8.832 8.832 0 0 1 6.187-2.516c2.32 0 4.546.905 6.187 2.517a8.517 8.517 0 0 1 2.563 6.076Z"
      />
    </svg>
  );
};

export default Department;
